import { useMutation, useQuery } from "@apollo/client";
import React, { useEffect, useState } from "react";
import {
    BodyWrapper as TransferBodyWrapper,
    FirstCol,
    Header,
    MainInfoWrapper,
    SecondCol,
    TableBodyWrapper,
} from "src/components/udb/commonStyle";
import UDBHeader from "src/components/UDBHeader";
// import { GET_BALANCE_INFO } from "src/graphql/game";
import { GET_CREDIT } from "src/graphql/member";
// import { IBalanceInfo } from "src/graphql/schema";
// import { ONE_CLICK_RECYCLE } from "src/graphql/system";
// import { IPlatformInfo } from "src/pages/Statements/balance-transfer";
import { IAppContextStore, withAppContext } from "src/store/initAppContext";
import util from "src/util/util";
import styled, { withTheme } from "styled-components";
import PlatformBalanceList, { TittleIcon } from "../../../components/member/PlatformBalanceList";
import Intl from '../../../components/common/Intl'
import SEOContents from "src/components/SEOContents";
// import { LoadingWrap } from "src/pages/loading";
// import config from "src/config/config";


interface IBalanceInfo {
    platformCode: string;
    playerName: string;
    balance: number;
    exchangeRate?: number;
    enableFloat?: boolean;
}

export interface IPlatformInfo extends Pick<IBalanceInfo, 'platformCode' | 'balance' | 'exchangeRate' | 'enableFloat'> {
    displayName: string;
    nickName?: string;
}

const ownPlatform: IPlatformInfo = {
    platformCode: 'snk',
    displayName: 'Main Wallet',
    balance: 0,
};

const platformCodeNameMap: { [code: string]: { name: string; nickName?: string } } = {
    PlayTech: { name: 'PlayTech' },
    WACS: { name: 'We are casino', },
    BTiSports: { name: 'Fixed Odds Wallet' },
    VIRTUALSPORTS: { name: 'VIRTUALSPORTS' },
    Teenpatti: { name: 'Exchange Card Games' },
    SLottery: { name: 'Lottery' }
};

interface ITransferProps {
    app: IAppContextStore;
    triggerPage: any
}

function WalletTransfer(props: any) {

    const { app: { exchangeRate, type, memberId, brandId }, theme } = props;
    // const [refresh, setRefresh] = useState(false)
    const [platformInfoList, setPlatformInfoList] = useState([])
    const [isQueryLoading, setIsQueryLoading] = useState(false)
    const [randomNum, setRandomNum] = useState(0);
    // const [isLoading, setLoading] = useState(true);
    const [mainWalletData, setmainWalletData] = useState(ownPlatform)


    const PLATFORM_CODE = ['WACS', 'AgTeenpatti', 'SUPERNOWA', 'BTISPORTS','VIRTUALSPORTS','ONEXBET']

    // const refreshTransfer = (value) => {
    //     setRefresh(!value)
    // }

    const { loading: creditLoading, error: creditError, data: creditData, refetch: refetchCredit } = useQuery(GET_CREDIT, {
        variables: { input: { currentMember: util.getTargetString({ memberId }) } },
    });

    // const {loading: balanceInfoLoading, error: balanceInfoError, data: balanceInfoData, refetch: refetchBalanceInfo}: any = useQuery(GET_BALANCE_INFO, {
    //     variables: {
    //         input: {
    //             platformCodes: []
    //         }
    //     },
    // });

    useEffect(() => {
        let list = creditData?.member?.credit;
        if (list) {
            const { limitCredit = 0, exposureCredit = 0, winlossCredit = 0 }: any = util.getValue(
                creditData,
                ['member', 'credit'],
                {},
            );
            const amount = (limitCredit + exposureCredit + winlossCredit) * exchangeRate;
            setmainWalletData(prevS=>({...prevS,balance:amount}));
        }
    }, [creditData]);

    // useEffect(() => {
    //     let list = balanceInfoData?.game?.balanceInfo;
    //     if (list) {
    //         const isQueryLoading = creditLoading || balanceInfoLoading;
    //         const balanceInfoList: IBalanceInfo[] = util.getValue(
    //             balanceInfoData,
    //             ['game', 'balanceInfo'],
    //             [],
    //         );
    //         const platformInfoList: IPlatformInfo[] = [
    //             ownPlatform,
    //             ...balanceInfoList.map(balanceInfo => {
    //                 const {
    //                     platformCode,
    //                     balance,
    //                     exchangeRate: platformExchangeRate,
    //                     enableFloat,
    //                 } = balanceInfo;
    //                 return {
    //                     displayName: util.getValue(platformCodeNameMap, [platformCode, 'name']),
    //                     nickName: util.getValue(platformCodeNameMap, [platformCode, 'nickName']),
    //                     platformCode,
    //                     balance,
    //                     exchangeRate: platformExchangeRate,
    //                     enableFloat,
    //                 };
    //             }),
    //         ];
          
    //         setPlatformInfoList(platformInfoList)
    //         setIsQueryLoading(isQueryLoading)
    //     }
    // }, [randomNum])

    const refetchCreditAndBalanceInfo = () => {
        // let value = Math.random();
        refetchCredit();
       // refetchBalanceInfo();
       // setRandomNum(value)
    };

    return (
        <MainInfoWrapper>
            <SEOContents pageName="memberFinanceTransfer" />
            <Header>
                <FirstCol>
                    <UDBHeader title="DASHBOARD@Transfer" />
                </FirstCol>
            </Header>

            <SecondCol style={{ color: brandId===46||brandId===47?'#000':"#fff", marginBottom: '15px' }}>
                <TittleIcon></TittleIcon>
                <Intl langKey="TITLE@PLATFORMBALANCE" />
            </SecondCol>

            <TableBodyWrapper>
                <PlatformBalanceList 
                    platformInfoList={platformInfoList}
                    isQueryLoading={isQueryLoading}
                    platformCode={PLATFORM_CODE}
                    ownPlatform={mainWalletData}
                    refresh={refetchCreditAndBalanceInfo} />
            </TableBodyWrapper>
        </MainInfoWrapper>
    );
}

export default withTheme(withAppContext(WalletTransfer));

//Styled Components
const BodyWrapper = styled(TransferBodyWrapper)`
  flex: 1;
  width: auto;

  label {
    width: auto;

    .checkmark {
      padding: 0em 1.5em;
    }
  }

  .processing {
    color: #ab7f3a;
  }

  .sucessful {
    color: #0ead69;
  }

  .failed {
    color: #e30e33;
  }
`;
