import React from 'react'

type Props = {}

const Yolologo = (props: Props) => {
  return (
    <svg width="32" height="10" viewBox="0 0 32 16" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g clip-path="url(#clip0_2284_7479)">
    <path d="M17.8702 5.53C19.4702 4.08 21.4502 1.91 23.8302 1.91C27.1402 1.91 29.8302 4.6 29.8302 7.91C29.8302 11.22 27.1402 13.91 23.8302 13.91C20.5202 13.91 18.0902 10.34 15.9502 7.9L15.9102 7.85C12.6802 4.11 11.2202 1.91 7.91016 1.91C4.60016 1.91 1.91016 4.59 1.91016 7.91C1.91016 11.23 4.60016 13.91 7.91016 13.91C10.4002 13.91 11.4202 12.42 13.9102 10.16" stroke="white" stroke-width="3.81" stroke-miterlimit="10"/>
    </g>
    <defs>
    <clipPath id="clip0_2284_7479">
    <rect width="31.74" height="15.81" fill="white"/>
    </clipPath>
    </defs>
    </svg>
    
  )
}

export default Yolologo
