import React, { useEffect } from 'react'
import { withAppContext } from 'src/store/initAppContext';
import styled, { withTheme } from 'styled-components';
import { LazyLoadImage } from "react-lazy-load-image-component";
import config from "src/config/config";
import PlaceholderImage from 'src/components/Placeholder';
import { theme } from 'styled-tools';
import FooterContainer from 'src/components/footer/footer';

function AviatrixSkyHighChallengePromoFun88(props: any) {
    const { app: {
        brandId
    }, theme } = props;

    useEffect(() => {
        window.scrollTo(0, 0)
    }, []);

    return (
        <>
            <InformationWrapper>
                <MainTitle>
                    <p>Gear up for an exciting three-month competition from March 1 to May 31 with a staggering <strong>₹99 LAKHS</strong> prize pool!</p>
                    <p>Compete daily for your share of the rewards—no minimum bet required!</p>
                </MainTitle>
                <SectionWrapper>
                    <ContentWrapper>
                        <LeftWrapper>
                            <h3>How to Participate:</h3>
                            <p>To enter the daily tournament, follow these two simple steps</p>
                            <TextWrapper>
                                <span>Stage&nbsp;1:</span>
                                <p>Place at least one bet using an NFT aircraft within 24 hours.</p>
                            </TextWrapper>
                            <TextWrapper>
                                <span>Stage&nbsp;2:</span>
                                <p>Claim your reward if your aircraft has won! Winnings are based on a user's overall gaming turnover—more turnover =  bigger reward!</p>
                            </TextWrapper>
                        </LeftWrapper>
                        <LazyLoadImage
                            src={`${config.imageV3Host}/static/cashsite/${theme.image.AviatrixSkyHighChallengeGirlTwo}`}
                            alt="jetxPromoStepThree"
                            effect="none"
                            placeholder={<PlaceholderImage />}
                        />
                    </ContentWrapper>
                </SectionWrapper>
                <DetailsWrapper>
                    <HeadingText>
                        <h2>Tournament Mechanics</h2>
                    </HeadingText>
                    <DetailsBodyWrapper>
                        <EachWrapper>
                            <Divider></Divider>
                            <EachBody>
                                <LazyLoadImage
                                    src={`${config.imageV3Host}/static/cashsite/${theme.image.AviatrixFlyAndPlayDetImgOne}`}
                                    alt="jetxPromoStepThree"
                                    effect="none"
                                    placeholder={<PlaceholderImage />}
                                />
                                <p>Each bet earns you points equal to the bet amount. Points accumulate and determine rankings in the real-time leaderboard displayed in the Aviatrix game interface.</p>
                            </EachBody>
                        </EachWrapper>
                        <EachWrapper>
                            <Divider></Divider>
                            <EachBody>
                                <LazyLoadImage
                                    src={`${config.imageV3Host}/static/cashsite/${theme.image.AviatrixSkyHighChallengeDetImgTwo}`}
                                    alt="jetxPromoStepThree"
                                    effect="none"
                                    placeholder={<PlaceholderImage />}
                                />
                                <p>If multiple players have the same points, the one who reached the score first ranks higher.</p>
                            </EachBody>
                        </EachWrapper>
                        <EachWrapper>
                            <Divider></Divider>
                            <EachBody>
                                <LazyLoadImage
                                    src={`${config.imageV3Host}/static/cashsite/${theme.image.AviatrixFlyAndPlayDetImgThree}`}
                                    alt="jetxPromoStepThree"
                                    effect="none"
                                    placeholder={<PlaceholderImage />}
                                />
                                <p>You can use one game account but an unlimited number of NFT aircraft to participate.</p>
                            </EachBody>
                        </EachWrapper>
                        <EachWrapper>
                            <Divider></Divider>
                            <EachBody>
                                <LazyLoadImage
                                    src={`${config.imageV3Host}/static/cashsite/${theme.image.AviatrixFlyAndPlayDetImgFour}`}
                                    alt="jetxPromoStepThree"
                                    effect="none"
                                    placeholder={<PlaceholderImage />}
                                />
                                <p>The more the game turnover, the higher your chances of winning!</p>
                            </EachBody>
                        </EachWrapper>
                    </DetailsBodyWrapper>
                </DetailsWrapper>
                <ROWrapper>
                    <DPDContent>
                        <TextSection>
                            <h3>Daily Prize Distribution</h3>
                            <p>Daily winners are selected in two stages:</p>
                            <TextWrapper>
                                <span>Stage&nbsp;1:</span>
                                <p>Players qualify based on total game turnover.</p>
                            </TextWrapper>
                            <TextWrapper>
                                <span>Stage&nbsp;2:</span>
                                <p>All qualified participants win cash prizes, with amounts based on their total gaming turnover.</p>
                            </TextWrapper>
                        </TextSection>
                        <TextSection>
                            <h3>Claiming Rewards:</h3>
                            <p>Hit the "Claim Prize" button to receive your winnings instantly!</p>
                            <p>No wagering required - use your rewards to play or withdraw! Take flight, rack up points, and win big every night!</p>
                        </TextSection>
                    </DPDContent>
                    <ROLeftWrapper>
                        <LazyLoadImage
                            src={`${config.imageV3Host}/static/cashsite/${theme.image.AviatrixSkyHighChallengeGirlOne}`}
                            alt="jetxPromoStepThree"
                            effect="none"
                            className='ImgTwo'
                            placeholder={<PlaceholderImage />}
                        />
                    </ROLeftWrapper>
                </ROWrapper>
                <AviatrixTermsAndCondition>
                    <h3>Terms & Conditions</h3>
                    <ul>
                        <li>The 'Aviatrix: Take A Flight, Win Tonight' promotion is valid for all new and existing players.</li>
                        <li>The bigger the game turnover, the greater the chances of winning.</li>
                        <li>Multiple accounts will not be eligible for this promotion. All players will undergo duplicate checking.</li>
                        <li>FUN88 reserves the right to disqualify players involved in collusion or any foul play related to this promotion.</li>
                        <li>FUN88 & Aviatrix reserve the right to amend, cancel, reclaim, or refuse any promotion at its discretion.</li>
                        <li>FUN88 general Terms & Conditions apply.</li>
                    </ul>
                </AviatrixTermsAndCondition>
            </InformationWrapper>
            <FooterContainer />
        </>
    )
}

export default withTheme(withAppContext(AviatrixSkyHighChallengePromoFun88));


// Styled Component.
const InformationWrapper = styled.div`
    width: 100%;
    height: auto;
    padding: 3rem;
    background: rgba(111, 111, 111, 0.5);
    margin: 0em;
    border-top-color: transparent;
    backdrop-filter: brightness(0.5);    
    display: flex;
    flex-direction: column;
    background-color: #EBEBEB;
`;

const MainTitle = styled.div`
    width: 90%;
    height: auto;
    margin: auto;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    padding: 2rem 0;
    gap: 1rem;
    border-radius: 9.945px;
    background: #FFF;
    margin-bottom: 2rem;

    h1 {
        color: ${theme('palette.AviatrixSkyHighChallengeTextColor')};
        text-align: center;
        font-size: 20px;
        font-style: normal;
        font-weight: 700;
        line-height: 179.5%; /* 35.9px */
        text-transform: capitalize;
        margin: 0;
    }

    p {
        color: #000;
        text-align: center;
        font-size: 16px;
        font-style: normal;
        font-weight: 500;
        line-height: 200.3%;
        text-transform: capitalize;
        margin: 0;
    }
`;

const SectionWrapper = styled.div`
    width: 90%;
    display: flex;
    justify-content: center;
    align-items: center;
    height: auto;
    margin: auto;
`;

const ContentWrapper = styled.div`
    width: 55%;
    display: flex;
    background: ${theme('palette.AviatrixSkyHighChallengeStagesbackgrond')};
    justify-content: center;
    align-items: flex-end;
    border-radius: 9.233px;
    gap: 1.5rem;

    img {
        width: 19rem;
        height: 17rem;
    }
`;

const LeftWrapper = styled.div`
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 1.5rem;
    align-self: center;
    padding: 2rem 5px 2rem 2rem;


    h3 {
        margin: 0;
        color: ${theme('palette.AviatrixSkyHighChallengeTextColor')};
        font-size: 20px;
        font-style: normal;
        font-weight: 700;
        line-height: 179.5%; /* 43.08px */
        text-transform: capitalize;
        text-align: left;
    }

    p {
        margin: 0;
        color: #000;
        font-size: 16px;
        font-style: normal;
        text-align: left;
        font-weight: 400;
        line-height: 150%; /* 24px */
        text-transform: capitalize;
    }
`;

const TextWrapper = styled.div`
    display: flex;

    span {
        color: ${theme('palette.AviatrixSkyHighChallengeTextColor')};
        font-weight: 700;
        padding-top: 1.5px;
    }

    p {
        margin: 0;
        color: #000;
        font-size: 16px;
        font-style: normal;
        font-weight: 500;
        line-height: 150.3%; /* 19.248px */
        text-align: left;
        padding-left: 0.5rem;
        text-transform: capitalize;
    }
`;

const DetailsWrapper = styled.div`
    width: 90%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 2rem 0 3rem 0;
    margin: 0;
    gap: 1.5rem;
    height: auto;
    margin: auto;
`;

const DetailsBodyWrapper = styled.div`
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: stretch;
    gap: 2rem;
`;

const EachWrapper = styled.div`
    display: flex;
    justify-content: flex-start;
    align-items: center;
    flex-direction: column;
    width: 25%;
    border-radius: 9.233px;
    border: ${theme('palette.AviatrixSkyHighChallengeTermsBorder')};
    background: ${theme('palette.AviatrixSkyHighChallengeItemsBackground')};
`;

const EachBody = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    padding: 0 1rem 1rem 1rem;
    gap: 1rem;
    position: relative;

    img {
        position: absolute;
        width: 15rem;
        top: -10%;
        left: 50%;
        transform: translateX(-50%);
        text-align: center;
    }

    p {
        margin: 0;
        padding-top: 9rem;
        color: #000;
        text-align: justify;
        font-size: 15.682px;
        font-style: normal;
        font-weight: 600;
        line-height: 150%;
        padding: 9rem 1rem 1rem 1rem;
        text-transform: capitalize;
    }
`;

const Divider = styled.div`
    height: 4px;
    width: 60%;
    border-radius: 76px;
    background: ${theme('palette.AviatrixSkyHighChallengeDividerBg')};
    box-shadow: ${theme('palette.AviatrixSkyHighChallengeDividerBoxShadow')};
`;

const HeadingText = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;

    h2 {
        margin: 0;
        color: ${theme('palette.AviatrixSkyHighChallengeTextColor')};
        text-align: center;
        font-size: 20px;
        font-style: normal;
        font-weight: 700;
        line-height: 179.5%; /* 43.08px */
        text-transform: capitalize;
    }
`;


const ROWrapper = styled.div`
    width: 90%;
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
    text-align: center;
    height: auto;
    margin: auto;
`;

const ROLeftWrapper = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    width: auto;

    img {
        width: 35rem;
        text-align: center;
        margin-bottom: -0.3rem;
    }

    .ImgTwo {
        width: 35rem;
    }
`;

const RoContent = styled.div`
    display: flex;
    flex-direction: column;
    gap: 1.5rem;
    width: 40%;
    padding-right: 2rem;
    border-radius: 9.233px;
    background: ${theme('palette.AviatrixSkyHighChallengeStagesbackgrond')};
    border: ${theme('palette.AviatrixSkyHighChallengeStagesborder')};
    padding: 3rem;
    align-self: center;
    position: relative;


    h3 {
        margin: 0;
        color: ${theme('palette.AviatrixSkyHighChallengeTextColor')};
        font-size: 20px;
        font-style: normal;
        font-weight: 700;
        line-height: 179.5%; /* 43.08px */
        text-transform: capitalize;
        text-align: left;
    }

    p {
        margin: 0;
        color: #000;
        font-size: 16px;
        font-style: normal;
        text-align: left;
        font-weight: 400;
        line-height: 150%; /* 24px */
        text-transform: capitalize;
    }
`;

const DPDContent = styled(RoContent)`
    margin-bottom: 2.5rem;
    border-radius: 9.233px;
    gap: 2.5rem;
    width: auto;

    ::before {
        content: none;
    }
`;

const TextSection = styled.div`
    width: 100%;
    display: flex;
    flex-direction: column;
    align-self: center;
    position: relative;
    gap: 1rem;
`;

const AviatrixTermsAndCondition = styled.div`
    width: 90%;
    height: auto;
    margin: auto;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    padding: 3rem 7rem;
    position: relative;
    border-radius: 9.233px;
    border: ${theme('palette.AviatrixSkyHighChallengeTermsBorder')};
    background: ${theme('palette.AviatrixSkyHighChallengeTermsBackground')};


    & li {
        color: #000;
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: 22.8px; /* 142.5% */
        text-transform: capitalize;
        list-style-type: '🔹';
        margin: 1rem 0;
        padding-left: 5px;
    }

    & h3 {
        color: ${theme('palette.AviatrixSkyHighChallengeTextColor')};
        font-size: 20px;
        font-style: normal;
        font-weight: 700;
        line-height: 179.5%; /* 53.85px */
        text-transform: capitalize;
        margin: 10px 0px 0px 15px;
    }

    .bold {
        color: #FFF;
        font-weight: 700;
    }
`;
