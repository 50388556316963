import React, { useEffect, useState, useContext } from 'react'
import {
  BodyWrapper as KycBodyWrapper,
  Button as PendingBtn,
  ButtonWrapper,
  FirstCol,
  Header,
  InputWrapper as KYCInputWrapper,
  MainInfoWrapper,
  PasswordStrength,
  ResetBtn,
  SecondCol,
  SubmitBtn,
} from 'src/components/udb/commonStyle';
import Intl from '../../../components/common/Intl';
import styled from 'styled-components';
import { BankInputField } from '../../../components/udb/Bankdetail';
import FileUpload, { FileView } from '../../../components/udb/kyc/FileUpload';
import CustomRadioButton from '../../../components/udb/kyc/CustomRadioButton';
import ProgressBar from '../../../components/udb/kyc/ProgressBar';
import { postKYCDetails, getKYCDetails, postFinancialDocument } from '../../../config/api';
import config from 'src/config/config';
import { UserContext } from '../index';
import UDBHeader from 'src/components/UDBHeader';
import { Notify } from '../../../components/Notification'
import { theme } from 'styled-tools';
import SEOContents from 'src/components/SEOContents';
import Slider from "react-slick";
import ImageSlider from 'src/components/udb/kyc/CustomDocSlider';
import WarningIconKyc from 'src/components/icon/WarningIconKyc';

type documentTypeProps = {
  docType: '' | 'ADAR' | 'PAN' | 'PASSPORT' | 'DRIVING';
  docNumber: string;
  docNumberError?: boolean;
  isDisabled: boolean;
  status: 'PENDING' | 'APPROVED' | 'REJECTED';
  viewOnly: boolean;
  progress: '25' | '75' | '50' | '100';
}

type finDocTypeProps = {
  docNumber: string;
  docNumberError?: boolean;
  isDisabled: boolean;
  viewOnly: boolean;
  file: Blob | null
}

type fsFileTypeProps = {
  isReq: boolean,
  file: Blob | null,
  type?: string;
}

const MainDocumentsType = [
  {
    value: 'General KYC',
    label: 'Document@GKYC',
    text: 'General KYC'
  },
  {
    value: 'Withdrawal KYC',
    label: 'Document@FinDoc',
    text: 'Withdrawal KYC'
  }
];

const DocumentType = [
  {
    value: 'ADAR',
    label: 'Document@AadharCard',
    text: 'Aadhar Card'
  },
  {
    value: 'PAN',
    label: 'Document@PanCard',
    text: 'Pan Card'
  },
  {
    value: 'DRIVING',
    label: 'Document@DrivingLicense',
    text: 'Driving License'
  },
  {
    value: 'PASSPORT',
    label: 'Document@Passport',
    text: 'Passport'
  }
];



const defaultFileTypeState: fsFileTypeProps = {
  isReq: true,
  file: null,
  type: "image/png"
}

const defaultDocumentTypeState: documentTypeProps = {
  docType: '',
  docNumber: '',
  docNumberError: false,
  isDisabled: false,
  status: 'PENDING',
  viewOnly: false,
  progress: "25"
}

const defaulFinDocTypeState: finDocTypeProps = {
  docNumber: '',
  docNumberError: false,
  isDisabled: false,
  viewOnly: false,
  file: null
}

export default function KYCPage() {
  const [documentType, setDocumentType] = useState(defaultDocumentTypeState);
  const [mainDocumentType, setMainDocumentType] = useState('General KYC');
  const [fsFile, setfsFile] = useState(defaultFileTypeState);
  const [bsFile, setbsFile] = useState(defaultFileTypeState);
  const [financialDoc, setFinancialDoc] = useState(defaulFinDocTypeState);
  const [financialDocArray, setFinancialDocArray] = useState<any>([])
  const { userInfo } = useContext(UserContext);
  const [resetFile, setResetFile] = useState(false);
  const [resetFinDocFile, setResetFinDocFile] = useState(false);
  const [openFinDoc, setOpenFinDoc] = useState(false);
  const [selectedDocIndex, setSelectedDocIndex] = useState(0);
  const { uuid } = userInfo;

  //check if user has already submitted KYC
  useEffect(() => {
    if (uuid) {
      getKYCDetail();
    }
  }, [uuid]);

  const getKYCDetail = () => {
    getKYCDetails(uuid).then(req => {
      //check if DB has data 
      if (req.data.success) {
        // const { data: { data = [] } } = req;
        const { kyc_details, financial_documents } = req.data.data;


        if (kyc_details.length) {
          let fsStatus: any = {}
          let bsStatus: any = {}

          if (kyc_details.length === 1) {
            fsStatus = kyc_details[0]
          }
          else {
            fsStatus = kyc_details.find(elm => elm.side === 'FRONT');
            bsStatus = kyc_details.find(elm => elm.side === 'BACK');
          }

          const { filestatus, type, documentNumber, file } = fsStatus;
          const status = (filestatus || '').toUpperCase();
          const progress = status === 'APPROVED' ? "100" : "75";

          setDocumentType({
            docType: type.toUpperCase(),
            docNumber: documentNumber,
            isDisabled: !!type,
            status: status,
            viewOnly: !!documentNumber,
            progress: progress
          });

          setfsFile({ file: file[0], isReq: true, type: file[1] });
          if (Object.keys(bsStatus).length) {
            let bfile = bsStatus.file;
            setbsFile({ file: bfile[0], isReq: true, type: bfile[1] });
          }
          else {
            setbsFile({ file: null, isReq: false, type: "image/png" });
          }
        }

        if (financial_documents) {
          setFinancialDocArray(financial_documents);
        } else {
          setFinancialDocArray([]);
        }
      }
    }).catch(e => console.log(e));
  }

  const documentTypeHandler = (e, value) => {
    setDocumentType(prevState => ({ ...prevState, docType: value }));
    setbsFile(preVProp => ({ ...preVProp, isReq: (value === 'ADAR' || value === 'DRIVING' || value === 'PASSPORT') }))
  }

  const mainDocumentTypeHandler = (e, value) => {
    setMainDocumentType(value);
  }

  const documentNumberHandler = (e) => {
    let val = e.target.value;
    const _regEx = /[!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?\s]+/; //spacial Chars
    if (!_regEx.test(val)) {
      setDocumentType(prevState => ({ ...prevState, docNumber: val, docNumberError: !val }));
    }
  }

  const financialDocNumberHandler = (e) => {
    let val = e.target.value;
    const _regEx = /[!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?\s]+/; //spacial Chars
    if (!_regEx.test(val)) {
      setFinancialDoc(prevState => ({ ...prevState, docNumber: val, docNumberError: !val }));
    }
  }

  const fsDocUploadHandler = (f) => {
    setfsFile(prevS => ({ ...prevS, file: f }));
    setResetFile(false)
  }

  const bsDocUploadHandler = (f) => {
    setbsFile(prevS => ({ ...prevS, file: f }));
    setResetFile(false)
  }


  const financialDocUploadHandler = (f) => {
    if (f !== null) {
      const selectedFile = f;
      const allowedTypes = ["image/gif", "image/png", "image/jpg", "image/jpeg"];

      if (allowedTypes.includes(selectedFile.type)) {
        setFinancialDoc(prevS => ({ ...prevS, file: f }));
        setResetFinDocFile(false)
      } else {
        Notify.error("Invalid file type! Please upload a GIF, PNG, JPG, or JPEG file.");
        setFinancialDoc(null); // Reset file input
      }
    }
  };

  const validationcheck = () => {
    let retV = true;
    const { docType, docNumber, docNumberError, viewOnly } = documentType;
    if (viewOnly) {
      retV = true;
    }
    else if (!docType) {
      retV = false;
    }
    else if (!docNumber || docNumberError) {
      retV = false;
    }
    else if (fsFile.isReq && !fsFile.file) {
      retV = false;
    }
    else if (bsFile.isReq && !bsFile.file) {
      retV = false;
    }
    return retV;
  }

  const submitHandler = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    if (validationcheck()) {
      const headers = {
        type: documentType.docType,
        number: documentType.docNumber
      }
      const formData = new FormData();
      formData.append('Ffile', fsFile.file);
      if (bsFile.isReq && bsFile.file) {
        formData.append('Bfile', bsFile.file);
      }

      postKYCDetails(formData, headers).then(res => {
        if (res.data.success) {
          Notify.success('Documents submitted successfully');
          getKYCDetail();
        }
      }).catch(e => {
        const { data } = e.response || {};
        let message = 'Something Went Wrong, Please Try Again'
        if (data && data.data.message) {
          message = data.data.message;

        }
        Notify.error(message);
      });
    }
  }

  const handleUpload = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    if (!financialDoc.file) {
      Notify.error('Please select a file');
      return;
    }
    const formData = new FormData();
    formData.append('file1', financialDoc.file);
    formData.append('document_number', financialDoc.docNumber);

    postFinancialDocument(formData).then(res => {
      let response = res.data
      if (response.success) {
        Notify.success('Document submitted successfully');
        setFinancialDoc(prevS => ({ ...prevS, file: null, docNumber: "" }));
        setResetFinDocFile(true);
        getKYCDetail();
      } else {
        Notify.error('Invalid Reciept');
      }
    }).catch(e => {
      const { data } = e.response || {};
      let message = 'Something Went Wrong, Please Try Again'
      if (data && data.error) {
        message = data.error;

      }
      Notify.error(message);
    });
  };



  const resubmitKYC = () => {
    setDocumentType(defaultDocumentTypeState);
    setfsFile(defaultFileTypeState);
    setbsFile(defaultFileTypeState);
  }

  const resetForm = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    e.preventDefault();
    resubmitKYC();
    setResetFile(true)
  }

  const resetFinDoc = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    e.preventDefault();
    setResetFinDocFile(true)
    setFinancialDoc(prevS => ({ ...prevS, file: null, docNumber: "" }));
  }

  const { docType, isDisabled, docNumber, docNumberError, viewOnly, status, progress } = documentType;
  const enableSubmit = validationcheck();
  const enableFinDocSubmit = financialDoc !== null;
  const doctypeLabel = (DocumentType.find(elm => elm.value === docType) || {}).text || 'the Document';

  const openPopup = (index) => {
    setOpenFinDoc(true);
    setSelectedDocIndex(index); // Set the clicked index
  }

  const closePopup = () => {
    setOpenFinDoc(false);
  }

  return (
    <MainInfoWrapper>
      <SEOContents pageName="memberProfileKYC" />
      <Header>
        <FirstCol>
          <UDBHeader title="DASHBOARD@KYCVERIFICATION" />
        </FirstCol>
      </Header>


      <BodyWrapper>
        <ChooseDocument className='error'>
          {MainDocumentsType.map(elm => <CustomRadioButton key={elm.value} checked={elm.value === mainDocumentType} disabled={false} name="mainDocumentType" label={<Intl langKey={elm.label} />} value={elm.value} onChange={(e) => mainDocumentTypeHandler(e, elm.value)} />)}
        </ChooseDocument>

        <KycInnerBox>
          {mainDocumentType === "General KYC" ? (
            <GeneralKYCSec>
              <GeneralKYCSecTopSec>
                <ProcessSection>
                  <ProgressBar percent={progress} />
                </ProcessSection>

                <SecondCol>
                  {status === 'REJECTED' && <TypeAgain onClick={resubmitKYC}>Try again</TypeAgain>}
                  <Button className={status === 'APPROVED' ? 'approved' : (status === 'REJECTED' ? 'rejected' : '')}><Intl langKey="Kyc@status" /> {status}</Button>

                  {status === 'APPROVED' && <ToolTipContainerApproved className="tooltipWrp">
                    <p><Intl langKey="DASHBOARD@KYCThankMsgApproved" /></p>
                    <span>
                      <Intl langKey="dashboard@toolotip" />
                    </span>
                  </ToolTipContainerApproved>
                  }
                  {status === 'REJECTED' && <ToolTipContainerRejected className="tooltipWrp">
                    <p><Intl langKey="DASHBOARD@KYCThankMsgRejected" /></p>
                    <span>
                      <Intl langKey="dashboard@toolotip" />
                    </span>
                  </ToolTipContainerRejected>
                  }
                  {status === 'PENDING' && progress !== "25" && <ToolTipContainer className="tooltipWrp">
                    <p><Intl langKey="DASHBOARD@KYCThankMsg" /></p>
                    <span>
                      <Intl langKey="dashboard@toolotip" />
                    </span>
                  </ToolTipContainer>
                  }
                </SecondCol>
              </GeneralKYCSecTopSec>

              <form onSubmit={(e) => submitHandler(e)}>
                <SubTittle>
                  {!viewOnly ? <><Intl langKey="DASHBOARD@chooseDocs" /> <PasswordStrength><Intl langKey="DASHBOARD@chooseMinimumOne" /></PasswordStrength></> : <>Selected document</>
                  }
                </SubTittle>

                <ChooseDocument className='error'>
                  {DocumentType.map(elm => <CustomRadioButton key={elm.value} checked={elm.value === docType} disabled={isDisabled} name="documentType" label={<Intl langKey={elm.label} />} value={elm.value} onChange={documentTypeHandler} />)}
                </ChooseDocument>

                <SubTittle><Intl langKey="DASHBOARD@Docsdetail" /></SubTittle>
                <InputWrapper className={docNumberError ? 'error' : ''}>
                  <BankInputField placeholder="Enter Document Number" readOnly={!!viewOnly} maxLength={30} value={docNumber} onChange={documentNumberHandler} name="documentNumber" req />
                </InputWrapper>

                <SubTittle>
                  {!viewOnly ? <><Intl langKey="DASHBOARD@DocUpload" /> <PasswordStrength><Intl langKey="DASHBOARD@DocSize" /></PasswordStrength></> : <><Intl langKey="DASHBOARD@DocUploadAfter" /></>}
                </SubTittle>

                <Upload>
                  {viewOnly ? <FileView file={fsFile.file} /> : <FileUpload name="fsdoc" isReset={resetFile} label={`${bsFile.isReq ? 'Front side of' : ''} ${doctypeLabel} (Clear image)`} isReq={fsFile.isReq} onUpload={fsDocUploadHandler} maxSizeMB={6} isDeposit={false} />}

                  {bsFile.isReq && <>
                    <DashLine />
                    {viewOnly ? <FileView file={bsFile.file} /> : <FileUpload name="bsdoc" isReset={resetFile} label={`Back side of ${doctypeLabel} (Clear image)`} isReq={bsFile.isReq} value={bsFile.file} onUpload={bsDocUploadHandler} maxSizeMB={6} isDeposit={false} />}
                  </>}
                </Upload>

                {!viewOnly && <ButtonWrapper>
                  <SubmitBtn type='submit' disabled={!enableSubmit}><Intl langKey="DASHBOARD@DocSubmit" /></SubmitBtn>
                  <ResetBtn onClick={resetForm}><Intl langKey="DASHBOARD@DocReset" /></ResetBtn>
                </ButtonWrapper>}
              </form>
            </GeneralKYCSec>
          ) : mainDocumentType === "Withdrawal KYC" ? (
            <FinancialDocSec>
              {/* {financialDocArray.length !== 5 && */}
                <form onSubmit={(e) => handleUpload(e)}>
                  <KycNote>
                      <h4><WarningIconKyc /> <Intl langKey="Kyc@NotesHead" /></h4>
                      <p> <Intl langKey="Kyc@NotesMessage1" /> </p> 
                      <ol>
                          <li><Intl langKey="Kyc@NotesMessage2" /> </li>
                          <li><Intl langKey="Kyc@NotesMessage3" /> </li>
                          <li><Intl langKey="Kyc@NotesMessage4" /> </li>
                      </ol>
                      <p><Intl langKey="Kyc@NotesMessage5" /> </p>
                  </KycNote>
                  <SubTittle><Intl langKey="DASHBOARD@Docsdetail" /></SubTittle>
                  <InputWrapper className={''}>
                    <BankInputField placeholder="Enter Document Detail" readOnly={!!financialDoc.viewOnly} maxLength={30} value={financialDoc.docNumber} onChange={financialDocNumberHandler} name="documentNumber" />
                  </InputWrapper>

                  <SubTittle>
                    <Intl langKey="DASHBOARD@DocUpload" /> <PasswordStrength><Intl langKey="DASHBOARD@DocSize" /></PasswordStrength>
                  </SubTittle>


                  <Upload>
                    <FileUpload name="fin-doc" isReset={resetFinDocFile} label={`${''} Withdrawal KYC (Clear image)`} isReq={true} onUpload={financialDocUploadHandler} maxSizeMB={6} isDeposit={false} />
                  </Upload>


                  <ButtonWrapper>
                    <SubmitBtn type='submit' disabled={!enableFinDocSubmit}><Intl langKey="DASHBOARD@DocSubmit" /></SubmitBtn>
                    <ResetBtn onClick={resetFinDoc}><Intl langKey="DASHBOARD@DocReset" /></ResetBtn>
                  </ButtonWrapper>
                </form>
              {/* } */}
              {financialDocArray.length !== 0 &&
                <>

                  <SubTittle>
                    <>Uploaded Documents</>
                  </SubTittle>
                  <UploadSecWrap>
                  {financialDocArray.slice(0, 5).map((doc: any, index) => (
                      <img 
                        key={index} 
                        src={doc.filename1} 
                        alt="financial-doc" 
                        onClick={() => openPopup(index)} 
                      />
                    ))}
                  </UploadSecWrap>
                  {openFinDoc &&
                    <ModalOverlay>
                      <ModalContainer>
                        <CloseButton onClick={closePopup}>✖</CloseButton>
                        <ImageSlider data={financialDocArray} selectedIndex={selectedDocIndex} />
                      </ModalContainer>
                    </ModalOverlay>
                  }
                </>
              }

            </FinancialDocSec>
          ) : (
            <></>
          )
          }
        </KycInnerBox>
      </BodyWrapper>

    </MainInfoWrapper>
  )
}

//Styled Components
export const BodyWrapper = styled(KycBodyWrapper)`
  width: 100%;
  flex-direction: column;
  align-items: flex-start;

  form {
      width: 70%;
  }
`;
const Button = styled(PendingBtn)`
    align-items: center;
    justify-content: center;
    background: ${theme('palette.kycBtnBg')};
    opacity: 0.8;
    border: ${theme('palette.kycBtnBorder')};
    color: ${theme('palette.kycBtnTextColor')};
    text-transform: uppercase;
    font-size: .75rem;
    padding-right: 4em;
    padding-left: 1em;
    background-repeat: no-repeat;
    background-position: center;
    background-size: ${theme('palette.kycBtnBgSize')};
    &:hover ~ .tooltipWrp { display:block}

    :after {
            content: "";
            width: 38px;
            height: 40px !important;
            display: flex;
            position: absolute;
            right: 0;
            top: 14px;
            background: ${theme('palette.kycBtnBgAfter')};
            border-radius: 4px;
            background-image: url(${config.imageV3Host}/static/cashsite/${theme('image.statusIconPending')});
            background-position: center;
            background-size: 50%;
            background-repeat: no-repeat;
        }

`;
const DashLine = styled.div`
  width: 0px;
  height: 150px;
  display: flex;
  margin: auto 10px;
  border: 1px dashed rgba(138, 158, 197, 0.97);
`;
export const Upload = styled.div`
  width: 100%;
  display: flex;
  // align-items: center;
  /* padding: 0.5rem 0rem 1.5rem 0rem; */
  padding: ${theme('palette.uploadboxMarginPadding')};
  background: ${theme('palette.uploadboxbg')};
  border-radius: 5px;
  border: 1px solid #d8cbcb;
  /* margin-bottom: 10px; */
  margin-bottom: ${theme('palette.uploadboxMarginBottom')};
  min-height: 125px;

  & svg {
        z-index: 999;
    }
    & span {
        z-index: 999;
    }
`;
const InputWrapper = styled(KYCInputWrapper)`
  width: 60%;
  > label { margin-top:0}
  &.error > label { border:1px solid red;    background: #efdfdf; color: #353535;}
`;

export const ChooseDocument = styled.div`
  display: flex;
  align-items: flex-start;
  margin-bottom: 1rem;
`;


export const SubTittle = styled.div`
  display: flex;
  letter-spacing: 0.01em;
  text-transform: capitalize;
  color:${theme('palette.nametext')};
  font-weight: 600;
  font-size: 12px;
  padding-bottom: 0.5rem;
  padding-top: 0.5rem;
`;
const Content = styled.li`
  display: flex;
  list-style: none;
  color: #BBB8ED;
  font-weight: 500;
  font-size: 14px;
  letter-spacing: 0.01em;
  text-transform: capitalize;
  padding: 10px 5px;
`;
const ProcessSection = styled.ul`
  display: flex;
  margin: 0;
  align-items: center;
  justify-content: space-around;
  padding: 0;
  width: 65%;
`;

export const ToolTipContainer = styled.div`
  background: ${theme('palette.kycToolTipPendingTop')};
  position: absolute;
  top: 55px;
  right: 0;
  width: 181px;
  font-size: 9px;
  border-radius: 3px;
  line-height:1.3;
  display:none;
  color: ${theme('palette.kycToolTipColor')};

  > p {
    padding:5px 9px;
    margin:0px
  }

  span {
    width:100%;
    display:block;
    background: ${theme('palette.kycToolTipPendingBottom')};
    padding:5px 9px;
  }
`
export const ToolTipContainerApproved = styled.div`
  background: ${theme('palette.kycToolTipApprovedTop')};
  position: absolute;
  top: 55px;
  right: 0;
  width: 181px;
  font-size: 9px;
  border-radius: 3px;
  line-height:1.3;
  color: #fff;
  display:none;

  > p {
    padding:5px 9px;
    margin:0px
  }

  span {
    width:100%;
    display:block;
    background: ${theme('palette.kycToolTipApprovedBottom')};
    padding:5px 9px;
  }
`;

export const ToolTipContainerRejected = styled.div`
  background: ${theme('palette.kycToolTipRejectedTop')};
  position: absolute;
  top: 55px;
  right: 0;
  width: 181px;
  font-size: 9px;
  border-radius: 3px;
  line-height:1.3;
  color: #fff;
  display:none;

  > p {
    padding:5px 9px;
    margin:0px
  }

  span {
    width:100%;
    display:block;
    background: ${theme('palette.kycToolTipRejectedBottom')};
    padding:5px 9px;
  }
`;

const TypeAgain = styled.span`
  font-size: .70rem;
  line-height: 11px;
  letter-spacing: 0.02em;
  text-decoration-line: underline;
  /* color: #FFFFFF; */
  color: ${theme('palette.kycToolTipRejectedTypeAgainText')};
  display:inline-block;
  margin-right:10px;
  cursor:pointer;
`

const GeneralKYCSec = styled.div`
  width: 100%;
  height: auto;
`

const FinancialDocSec = styled.div`
  width: 100%;
  height: auto;  
`

const KycInnerBox = styled(BodyWrapper)`
   background: ${theme('palette.kycInnerBoxBg')};
`

const GeneralKYCSecTopSec = styled.div`
   width: 100%;
   height: auto;
   display: flex;
   justify-content: space-between;
`

const UploadSecWrap = styled.div`
  width: 100%;
  height: auto;
  display: flex;
  margin: 5px 0px;

  & img {
    width: 150px;
    height: 100px;
    object-fit: contain;
    cursor: pointer;
  }
`

const ModalOverlay = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.847);
  display: flex;
  justify-content: center;
  align-items: flex-start;
  overflow: hidden;
  bottom: 0;
  right: 0;
  padding-top: 15%;
  backdrop-filter: blur(5px);
  z-index: 99999999;
`;

const ModalContainer = styled.div`
  border-radius: 10.027px;
  border: 1.003px solid #CDCDCD;
  background: linear-gradient(90deg, rgba(0, 0, 0, 0.846) 0%, rgba(0, 0, 0, 0.60) 100%);
  box-shadow: 0px 0px 40.107px 0px rgba(0, 0, 0, 0.40);
  padding: 20px;
  width: auto;
  text-align: center;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.3);
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`

const CloseButton = styled.button`
  position: absolute;
  top: 10px;
  right: 10px;
  background: none;
  border: none;
  font-size: 20px;
  color: white;
  cursor: pointer;
  z-index: 999;
`;


const KycNote = styled.div`
  border: 1px solid #ff0808;
  background-color: ${theme('palette.kycWithdrawalNoteBg')};
  padding: 10px;
  border-radius: 5px;
  margin: 5px 0px 10px 0px;


  & p {
    margin: 3px 0px;
    color: ${theme('palette.kycWithdrawalNoteText')};
    font-size: 13px;
    font-weight: 600;
    text-align: left;
    text-transform: capitalize;
  }

  & ol {
    margin: 3px 0px;
    padding-left: 30px;

    & li {
      color: ${theme('palette.kycWithdrawalNoteText')};
      font-size: 13px;
      font-weight: 600;
      text-align: left;
      line-height: 150%;
      text-transform: capitalize;
    }
  }

  & h4 {
    margin: 3px 0px 10px 0;
    font-size: 15px;
    text-align: left;
    color: ${theme('palette.kycWithdrawalNoteText')};
    display: flex;
    align-items: center;
    gap: 10px;
    color: ${theme('palette.kycWithdrawalNoteHead')};

    & svg {
      width: 20px;
      height: 20px;
    }
  }
`