import { useState, useEffect } from 'react';
import styled, { css } from 'styled-components';

import { useQuery, useMutation } from '@apollo/client'


// Components
import Icon from '../common/PNGIcon';

import FavouriteIcon from '../../images/FavoriteDisabled.png'

// GQL
import { TOGGLE_FAVORITE } from '../../graphql/member';

// Util
// import { withAppContext } from '~/store/initAppContext';
import util from '../../util/util';
const { ifProp }: any = require('styled-tools');
interface IProps {
    app?: {
        query: any;
    };
    eventTypeId?: number;
    marketId: string;
    isFavoriteProp: boolean;
    eventId: any;
}

interface IState {
    isFavoriteState: boolean;
    prevIsFavoriteProp: boolean;
}

export default function FavoriteButton(props: IProps) {
    const [isFavoriteState, setFavoriteState] = useState(props.isFavoriteProp)
    const [toggleFavorite, { loading, error, data }] = useMutation(TOGGLE_FAVORITE);
    const handleClick = async () => {
		const marketId = Number(props.marketId);
		const eventId = Number(props.eventId);
        const currentFavoriteState = isFavoriteState;
        // debugger
        setFavoriteState(currentFavoriteState)
        try {
            const result: any = await toggleFavorite({
                variables: {
                    input: {
                        marketId,
                        isAdd: !currentFavoriteState,
                        eventId
                    },
                },
            });
            const isSuccessful: boolean = util.getValue(result, ['data', 'member', 'favorite', 'success']);
					if (!isSuccessful) {
                        setFavoriteState(!currentFavoriteState)
                        return true
                    }
                    setFavoriteState(!currentFavoriteState)
        } catch (error) {
            setFavoriteState(currentFavoriteState)
            if(error) {
                return true
            }
            
        }
    }
    return (
        <FavoriteButtonWrapper
            isActive={isFavoriteState}
            onClick={() => handleClick()}
            disabled={loading}
        >
            <Icon name={`star-red-${isFavoriteState ? 'active' : 'inactive'}`} />
            {/* <img src={FavouriteIcon}/> */}
        </FavoriteButtonWrapper>
    );
}

// class FavoriteButton extends React.Component<IProps, IState> {
// 	static getDerivedStateFromProps(nextProps: IProps, prevState: IState) {
// 		const { isFavoriteProp } = nextProps;
// 		const { prevIsFavoriteProp } = prevState;
// 		const isFavoritePropChanged = isFavoriteProp !== prevIsFavoriteProp;
// 		return isFavoritePropChanged
// 			? {
// 					isFavoriteState: isFavoriteProp,
// 					prevIsFavoriteProp: isFavoriteProp,
// 			  }
// 			: null;
// 	}
// 	constructor(props: IProps) {
// 		super(props);
// 		this.state = {
// 			isFavoriteState: props.isFavoriteProp,
// 			prevIsFavoriteProp: props.isFavoriteProp,
// 		};
// 	}

// 	handleClick = (toggleFavoriteMutation: any): any => () => {
// 		// Code Changed - Manoj ( Added event ID and converted marketId & eventId to number)
// 		const marketId = Number(this.props.marketId);
// 		const eventId = Number(this.props.eventId);
// 		const currentFavoriteState = this.state.isFavoriteState;

// 		// 打 API 前先切換狀態
// 		this.setState(
// 			() => ({
// 				isFavoriteState: !currentFavoriteState,
// 			}),
// 			async () => {
// 				try {
// 					// 打 API
// 					const result: any = await toggleFavoriteMutation({
// 						variables: {
// 							input: {
// 								marketId,
// 								isAdd: !currentFavoriteState,
// 								eventId
// 							},
// 						},
// 					});
// 					const isSuccessful: boolean = util.getValue(result, ['data', 'member', 'favorite', 'success']);

// 					// 如果打 API 成功，但回傳的結果為失敗，切回原來的狀態
// 					if (!isSuccessful) {
// 						this.setState(() => ({
// 							isFavoriteState: currentFavoriteState,
// 						}));
// 					}
// 				} catch (err) {
// 					// 如果打 API 失敗 (例如無網路連線)，切回原來的狀態
// 					console.error('FavoriteButton Error: ', err);
// 					this.setState(() => ({
// 						isFavoriteState: currentFavoriteState,
// 					}));
// 				}
// 			},
// 		);
// 	};

// 	render(): JSX.Element {
// 		return (
// 			<Mutation mutation={TOGGLE_FAVORITE}>
// 				{(toggleFavorite: any, { loading }): JSX.Element => {
// 					return (
// 						<FavoriteButtonWrapper
// 							isActive={this.state.isFavoriteState}
// 							onClick={this.handleClick(toggleFavorite)}
// 							disabled={loading}
// 						>
// 							<Icon name={`star-red-${this.state.isFavoriteState ? 'active' : 'inactive'}`} />
// 						</FavoriteButtonWrapper>
// 					);
// 				}}
// 			</Mutation>
// 		);
// 	}
// }

// export default FavoriteButton

// Styled components
const FavoriteButtonWrapper: any = styled.button`
	background-color: transparent;
	border: 0;
	cursor: pointer;
	padding: 0;
	flex-shrink: 0;
	font-size: 14px;
    // margin: 0 0.5em;
    margin: 0.2em 0;
	display: flex;
	align-items: center;
	justify-content: center;

	${ifProp(
    'isActive',
    css`
			opacity: 1;
		`,
    css`
			opacity: 1;
			transition: opacity 0.15s ease-in;
			&:hover {
				opacity: 0.85;
				transition: opacity 0.25s ease-out;
			}
		`,
)} &:focus, &:active {
		outline: none;
	}
`;
