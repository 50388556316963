export const myAccountData47 = [
    {
        id: "1",
        question: "How to register with FUN88?",
        answer: "Follow these steps to register with FUN88:",
        lists: [
          {
            id: "1",
            content: "Choose the 'Sign Up' option placed at the top of the website.",
          },
          {
            id: "2",
            content: "Fill in all the required details.",
          },
          {
            id: "3",
            content: "Verify your phone number via OTP (One-Time Password).",
          },
          {
            id: "4",
            content: "Agree to the Terms & Conditions and then proceed by submitting the form.",
          },
          {
            id: "5",
            content: "Log in with your credentials, and you'll be ready to enjoy your favorite sports and casino games.",
          }
        ]
    },
    {
        id: "2",
        question: "What is the process for logging in to my FUN88 account ?",
        answer: "To log in to your FUN88 account, follow these simple steps.",
        lists: [
          {
            id: "1",
            content: "Use the credentials that you received during the registration process.",
          },
          {
            id: "2",
            content: "Put your username and password",
          },
          {
            id: "3",
            content: "You're ready to enjoy your online gaming journey with FUN88!",
          }
        ]
      },
      {
        id: 3,
        question: "Why choose FUN88? ",
        answer: "On FUN88, you will get the best of both worlds: exciting casino games and multiple sports betting options",
        lists: [
          {
            id: "1",
            content: "388% Welcome Bonus",
          },
          {
            id: "2",
            content: "24% Referral Bonus",
          },
          {
            id: "3",
            content: "5% Refill Bonus",
          },
          {
            id: "4",
            content: "5% Cashback Bonus",
          },
          {
            id: "5",
            content: "Other Amazing Promotions (Check out the FUN88 Bonus Page)",
          },
          {
            id: "6",
            content: "Round-the-clock Customer Support (Best in the Industry)",
          }
        ]
      },
      {
        id: "4",
        question: "How do I update my personal information?",
        answer: "To update your details, follow these simple steps:",
        lists: [
          {
            id: "1",
            content: "Log in to your FUN88 account",
          },
          {
            id: "2",
            content: "Click the drop-down arrow present beside your username",
          },
          {
            id: "3",
            content: "Choose 'Profile'",
          },
          {
            id: "4",
            content: "Move to 'Account Info'.",
          },
          {
            id: "5",
            content: "Select the Edit to update your details, then click Save.",
          }
        ]
      },
      {
        id: "5",
        question: "How do I reset my FUN88 account password?",
        answer: "To reset your FUN88 account password, follow these easy steps :",
        lists: [
          {
            id: "1",
            content: "Log in to your FUN88 account",
          },
          {
            id: "2",
            content: "Find the drop-down arrow beside your username",
          },
          {
            id: "3",
            content: "Select 'Profile'",
          },
          {
            id: "4",
            content: "Choose the Change Password option",
          },
          {
            id: "5",
            content: "Enter your current account password, new password, and confirm the new account password.",
          },
          {
            id: "6",
            content: "Click on Submit to update your password",
          }
        ]
      },
      {
        id: "6",
        question: "Where do I find my betting history on FUN88?",
        answer: "To check your betting history, follow the simple instructions as follows:",
        lists: [
          {
            id: "1",
            content: "Log in to your FUN88 account",
          },
          {
            id: "2",
            content: "Click on the drop-down arrow located next to your username.",
          },
          {
            id: "3",
            content: "Select Bet Details to see your Current Bet and Past Bets.",
          }
        ],
        redirect: ""
      },
      {
        id: "7",
        question: "Where can I find the OTP to register with FUN88? I haven't received it yet.",
        answer: "Please verify the mobile number you registered with. If the issue persists, even if your mobile number is within network coverage, please contact our customer care for further assistance.",
        lists: [],
      },
      {
        id: "8",
        question: "Where do I find my account balance on FUN88?",
        answer:
          "Once you log in to your FUN88 account, the balance will be displayed next to your username. If you cannot see your balance on the homepage after logging in, please contact our customer care for help.",
        lists: [],
      },
      {
        id: "9",
        question: "What happens if the internet connection is lost during gameplay?",
        answer:
          "If you lose your internet connection during gameplay, the active game session may end. Once you reconnect, you can continue playing new games.",
        lists: [],
      },
      {
        id: "10",
        question: "How frequently can I play on FUN88?",
        answer:
          "You can play and enjoy your favorite sports and casino games anytime. There is no limitation, as we believe in non-stop entertainment.",
        lists: []
      }
];

export const withDrawal47= [
    {
        id: "1",
        question: "How to withdraw my balance?",
        answer: "Follow these simple and quick steps to withdraw your balance:",
        lists: [
            {
                id: "1",
                content: "Click on the drop-down arrow placed next to your username.",
            },
            {
                id: "2",
                content: `Select the "Finances" option.`,
            },
            {
                id: "3",
                content: `Choose "Withdrawal."`,
            },
            {
                id: "4",
                content: `Select your preferred withdrawal method and enter the withdrawal amount."`,
            }
        ],
        redirect: ""
    },
    {
        id: "2",
        question: "Will I get my winnings directly to my bank account?",
        answer:
            "Players can transfer their winnings to their bank account upon meeting the withdrawal limit requirements.",
        lists: [],
        redirect: ""
    },
    {
        id: "3",
        question: "Is it possible to transfer money from one FUN88 account to another?",
        answer: "No. You cannot transfer your account balance from one FUN88 account to another.",
        lists: [],
        redirect: ""
    },
    {
        id: "4",
        question: "Does INR apply to all payment methods?",
        answer: "Yes. INR applies to all payment methods.",
        lists: [],
        redirect: ""
    },
    {
        id: "5",
        question: "Can I withdraw all my account balance?",
        answer: "You can withdraw the entire amount anytime if you meet the rollover requirements. However, make sure to review the withdrawal limit before proceeding.",
        lists: [],
        redirect: ""
    },
    {
        id: "6",
        question: "Can I withdraw money partially?",
        answer: "Yes. You can keep some money and take out the remaining.",
        lists: [],
        redirect: ""
    },
    {
        id: "7",
        question: "How much time will it take to withdraw my money?",
        answer: "The Insta withdrawal takes 15-20 minutes, while regular withdrawals may take 1 to 2 working days. Plus, withdrawal requests between 12:00 AM and 6:00 AM might experience extra processing time.",
        lists: [],
        redirect: ""
    },
    {
        id: "8",
        question: "Why was my withdrawal put on hold? ",
        answer: "Most withdrawals are either held or canceled due to the following reasons:",
        lists: [
            {
                id: "1",
                content: "Betting criteria was not met.",
            },
            {
                id: "2",
                content: "Withdrawal requested for a different account.",
            },
            {
                id: "3",
                content: "The withdrawal amount was incorrectly filled out in the form!",
            }
        ],
        redirect: ""
    },
    {
        id: "9",
        question: "What is the process to add a new withdrawal account?",
        answer: "Follow these easy steps to add a new withdrawal account:",
        lists: [
            {
                id: "1",
                content: "Click on the drop-down arrow placed beside your username.",
            },
            {
                id: "2",
                content: "Select the 'Finances' option.",
            },
            {
                id: "3",
                content: "Choose 'Withdrawal'. ",
            },
            {
                id: "4",
                content: "You can now add or edit your account information from here.",
            }
        ],
        redirect: ""
    }
];

export const deposit47 = [
    {
        id: "1",
        question: "How frequently can I make deposits?",
        answer: "You can deposit as many times as you want to enjoy your favorite casino games and multiple sports betting options.",
        lists: [],
        redirect: ""
    },
    {
        id: "2",
        question: "What is the minimum deposit?",
        answer: "On FUN88, INR 300 is the minimum deposit.",
        lists: [],
        redirect: ""
    },
    {
        id: "3",
        question: "Which is the best deposit option?",
        answer: "FUN88 offers various deposit options, including Fast Bank (NEFT, RTGS, and IMPS), Fast UPI, Regular UPI, and Net Banking. All of these deposit options ensure hassle-free deposits. Choose the deposit option that best suits your needs.",
        lists: [],
        redirect: ""
    },
    {
        id: "4",
        question: "What can I do if my deposit isn't credited?",
        answer: "There is no need to panic if your deposit hasn’t been credited, as your funds are safe with us. If you still cannot see the amount you deposited, contact our customer support team for further help.",
        lists: [],
        redirect: ""
    },
    {
        id: "5",
        question: "How can I make a deposit?",
        answer: "To make a deposit, follow these simple and quick steps:",
        lists: [
            {
                id: "1",
                content: "Login to your FUN88 account."
            },
            {
                id: "2",
                content: "Click on the 'Deposit' option."
            },
            {
                id: "3",
                content: "Select your preferred payment mode."
            },
            {
                id: "4",
                content: "Enter the amount and deposit."
            }
        ],
        redirect: ""
    },
    {
        id: "6",
        question: "When will my wallet be credited after the status changes to 'Initiated'?",
        answer: "Once the status indicates 'Initiated,' wait for some time for your amount to be credited to your wallet. If the account is not credited, contact our customer support team.",
        lists: [],
        redirect: ""
    },
    {
        id: "7",
        question: "What are the different deposit methods?",
        answer: "There are multiple deposit methods on FUN88, such as Fast Bank, Fast UPI, Regular UPI, and Net Banking.",
        lists: [],
        redirect: ""
    },
    {
        id: "8",
        question: "With just one deposit, can I bet on games and sports?",
        answer: "If you have a sufficient balance, you can place bets on different games and sports with a single deposit.",
        lists: [],
        redirect: ""
    },
    {
        id: "9",
        question: "Can I deposit at any time of the day?",
        answer: "Yes. You can make a deposit at any time of the day.",
        lists: [],
        redirect: ""
    },
    {
        id: "10",
        question: "Where can I find my transaction history?",
        answer: "",
        lists: [
            {
                id: "1",
                content: "Tap on the drop-down arrow placed next to your username."
            },
            {
                id: "2",
                content: "Select 'Finances.'"
            },
            {
                id: "3",
                content: "Choose the 'History' option to find out your transaction details."
            }
        ],
        redirect: ""
    }
];

export const bonusAndPromotion47 = [
    {
        id: "1",
        question: "Which are the different bonuses available on FUN88?",
        answer: "FUN88 offers different bonuses to players, such as the welcome bonus, a refill bonus, and a referral bonus. Explore the FUN88 bonus page for more details on available bonuses.",
        lists: []
    },
    {
        id: "2",
        question: "Can I claim all the bonuses on FUN88?",
        answer: "Yes. You will receive a bonus once you meet all the terms and conditions associated with that bonus.",
        lists: []
    },
    {
        id: "3",
        question: "How much is the welcome bonus on FUN88?",
        answer: "On FUN88, you get a massive 388% welcome bonus.",
        lists: []
    },
    {
        id: "4",
        question: "How much is the refill bonus on FUN88?",
        answer: "On FUN88, you get a 5% refill bonus.",
        lists: []
    },
    {
        id: "5",
        question: "How much is the referral bonus on FUN88?",
        answer: "On FUN88, you will get a 24% referral bonus.",
        lists: []
    },
    {
        id: "6",
        question: "Is there any validity for FUN88 bonuses?",
        answer: "The availability of bonuses depends on the selected bonus, and they may expire if not used within the assigned time. Check the FUN88 bonus page for more details.",
        lists: []
    },
    {
        id: "7",
        question: "What is RO on FUN88?",
        answer: "RO stands for Roll Over, which requires you to complete a Roll Over condition to access your bonus. The valid Roll Over calculation is as follows:\n\n| PL | ≥ 60% TOTAL STAKE",
        lists: []
    },
    {
        id: "8",
        question: "How much is the Roll Over?",
        answer: "Roll Over requirements vary for each promotion. Check the details of all promotions to understand the specific Roll Over for each.",
        lists: []
    },
    {
        id: "9",
        question: "How to claim the FUN88 bonus amount?",
        answer: "You don't need to claim; the bonuses will be directly credited to your account. If there is any delay while receiving the bonus amount, you can contact our customer support team.",
        lists: []
    },
    {
        id: "10",
        question: "Can I monitor the total amount of bonuses I've received?",
        answer: "",
        lists: [
            {
                id: "1",
                content: "Log in to your FUN88 account."
            },
            {
                id: "2",
                content: "Select the drop-down arrow placed next to your username."
            },
            {
                id: "3",
                content: "Select 'Promotions.'"
            },
            {
                id: "4",
                content: "View all the bonuses you have received so far."
            }
        ]
    },
    {
        id: "11",
        question: "Will I receive regular updates regarding FUN88 new promotions?",
        answer: "Yes. You will get regular updates regarding FUN88 new promotions if you visit the website. Also, follow FUN88 on different social media platforms to know the latest promotions.",
        lists: []
    }
];

export const appQuery47 = [
    {
        id: "1",
        question: "Can I use the same login credentials for both the FUN88 website and app?",
        answer: "Yes. You can use the same credentials to access your FUN88 account on the website and app.",
        lists: []
    },
    {
        id: "2",
        question: "How to sign up on the FUN88 app?",
        answer: "To sign up on the FUN88 app, follow these quick steps:",
        lists: [
            {
                id: "1",
                content: "Download the FUN88 app on your device."
            },
            {
                id: "2",
                content: "Tap on 'Sign Up.'"
            },
            {
                id: "3",
                content: "Enter the required details, including username, password, mobile number, and OTP."
            },
            {
                id: "4",
                content: "Tap 'Submit' to create your FUN88 account."
            }
        ]
    },
    {
        id: "3",
        question: "How many casino games can I enjoy on the FUN88 app?",
        answer: "The FUN88 app offers 700+ casino games, sports, virtual sports, and more for betting, the same options as the website.",
        lists: []
    },
    {
        id: "4",
        question: "Can I use the Cricket live betting option on the app?",
        answer: "Yes. The cricket live betting option is available on the FUN88 app.",
        lists: []
    },
    {
        id: "5",
        question: "How do I deposit using the FUN88 app?",
        answer: "To deposit funds on the FUN88 app, follow these easy steps:",
        lists: [
            {
                id: "1",
                content: "Download the FUN88 app on your device."
            },
            {
                id: "2",
                content: "Choose 'Deposit.'"
            },
            {
                id: "3",
                content: "Select your preferred deposit method and enter the preferred betting amount."
            },
            {
                id: "4",
                content: "Complete the deposit process to begin playing your favorite games."
            }
        ]
    },
    {
        id: "6",
        question: "How to withdraw through the FUN88 app?",
        answer: "To withdraw funds using the FUN88 app, follow these quick and easy steps:",
        lists: [
            {
                id: "1",
                content: "Install the FUN88 app on your device."
            },
            {
                id: "2",
                content: "Tap the drop-down arrow placed next to your username."
            },
            {
                id: "3",
                content: "Choose 'Withdrawal.'"
            },
            {
                id: "4",
                content: "Select your preferred withdrawal method and enter the withdrawal amount."
            }
        ]
    },
    {
        id: "7",
        question: "Is customer support on the FUN88 app available around the clock?",
        answer: "FUN88’s customer support is available 24/7 to help its players.",
        lists: []
    },
    {
        id: "8",
        question: "Can I use the UPI option to deposit on the FUN88 app?",
        answer: "Yes. In addition to other payment options, you can use the UPI payment option to make a deposit on the FUN88 app.",
        lists: []
    },
    {
        id: "9",
        question: "What is the process to download the FUN88 app?",
        answer: "",
        lists: [
            {
                id: "1",
                content: "If you are browsing FUN88 through the website, scan the QR code situated at the top right corner of the homepage or click on the 'Download' option to install the app."
            },
            {
                id: "2",
                content: "If you are browsing using your mobile device, move to the 'Menu' and select 'Download Android App.' Follow the simple instructions to install the app."
            }
        ]
    }
];

export const casinoGames47 = [
    {
        id: "1",
        question: "How many casino games are there on FUN88?",
        answer: "On FUN88, you can play and enjoy 700+ exciting and rewarding casino games.",
        lists: []
    },
    {
        id: "2",
        question: "Can I place bets on all casino games?",
        answer: "Yes. All casino games are available for betting and playing.",
        lists: []
    },
    {
        id: "3",
        question: "How do I find casino games on FUN88?",
        answer: "To find casino games on FUN88, follow these quick steps:",
        lists: [
            {
                id: "1",
                content: "Log in to your FUN88 account via the website or app."
            },
            {
                id: "2",
                content: "Move to the 'Casino,' 'Instant Games,' or 'Hot Games' sections."
            },
            {
                id: "3",
                content: "Browse the available casino games to find different exciting casino games."
            }
        ]
    },
    {
        id: "4",
        question: "Which is the best provider for casino games?",
        answer: "On FUN88, there are various casino game providers, and all of them are fantastic, providing the best casino games.",
        lists: []
    },
    {
        id: "5",
        question: "Which are the popular casino games on FUN88?",
        answer: "All the casino games provided by FUN88 are popular, with the most consistently played ones including JetX, Aviator, Roulette, Dragon Tiger, Teen Patti, Andar Bahar, Crazy Time, Dream Catcher, and many more.",
        lists: []
    },
    {
        id: "6",
        question: "Should I deposit funds into each casino game, or should I only refill my FUN88 wallet?",
        answer: "You can play any casino game or place any bet by adding funds to your FUN88 wallet.",
        lists: []
    },
    {
        id: "7",
        question: "Does FUN88 have any seasonal promotions related to casino games?",
        answer: "Yes. FUN88 offers a diverse range of seasonal bonuses related to the casino. Explore the FUN88 bonus page to find out about these exciting promotions.",
        lists: []
    },
    {
        id: "8",
        question: "Are all the casino games played in real-time?",
        answer: "Yes. All the casino games on FUN88 are played in real-time.",
        lists: []
    },
    {
        id: "9",
        question: "Is it safe to play casino games on FUN88?",
        answer: "Yes. Playing all casino games on FUN88 is completely safe and secure.",
        lists: []
    },
    {
        id: "10",
        question: "Where to find tips for casino games?",
        answer: "Go through the FUN88 casino game blogs to find out tips and strategies to enhance your gameplay.",
        lists: []
    },
    {
        id: "11",
        question: "Where to find the rules of all the casino games on FUN88?",
        answer: "To find rules for all the casino games on FUN88, follow these quick steps:",
        lists: [
            {
                id: "1",
                content: "Choose the casino game you want to play on FUN88."
            },
            {
                id: "2",
                content: "Once the game begins, find out the 'Rules' section. This section is available for all games, but the section name may change, such as 'Help,' 'Instructions,' or 'Game Rules.'"
            }
        ]
    }
];

export const sportsBetting47 = [
    {
        id: "1",
        question: "Does FUN88 have all the sports for betting?",
        answer: "FUN88 offers an array of sports options, including cricket, football, tennis, volleyball, badminton, etc. Check out the FUN88 ‘Sports’ section for all the sports events.",
        lists: []
    },
    {
        id: "2",
        question: "Is cricket betting on FUN88 available for all matches?",
        answer: "Yes. Cricket betting on FUN88 is available for all matches.",
        lists: []
    },
    {
        id: "3",
        question: "What are the different sports betting categories on FUN88?",
        answer: "There are 3 major sports betting categories on FUN88: Exchange, Sportsbook, and Premium Sportsbook.",
        lists: []
    },
    {
        id: "4",
        question: "Which is a better sports betting option - Exchange or Sportsbook?",
        answer: "Both Exchange and Sportsbook are exceptional sports betting options, offering numerous benefits to players.",
        lists: []
    },
    {
        id: "5",
        question: "Can I place sports bets on live matches?",
        answer: "Yes. You can place bets on live matches across different sports.",
        lists: []
    },
    {
        id: "6",
        question: "What are the odds for cricket matches on FUN88?",
        answer: "Before a match begins, the odds are set depending on various factors, such as teams, players, venue, weather conditions, etc. FUN88 provides odds for the overall match outcome (win, lose, draw), individual player performances (top run-scorer, top wicket-taker), and other betting markets. These odds are likely to fluctuate during the match.",
        lists: []
    },
    {
        id: "7",
        question: "Do the odds fluctuate as the match progresses?",
        answer: "Yes. The odds will fluctuate depending on the match situation.",
        lists: []
    },
    {
        id: "8",
        question: "Is it safe to place bets on live sports matches?",
        answer: "Yes. Betting on live matches is completely safe on the FUN88 platform.",
        lists: []
    },
    {
        id: "9",
        question: "How to bet on sports on FUN88?",
        answer: "To bet on sports on FUN88, follow these quick steps:",
        lists: [
            {
                id: "1",
                content: "Log in to your account."
            },
            {
                id: "2",
                content: "Move to the 'Sports' section."
            },
            {
                id: "3",
                content: "Select from Exchange, Sportsbook, or Premium Sportsbook."
            },
            {
                id: "4",
                content: "Select your desired match, betting market, and place your bet."
            }
        ]
    },
    {
        id: "10",
        question: "Where do I find previews and predictions for upcoming matches?",
        answer: "You can check out the FUN88 blogs for previews and predictions of upcoming matches.",
        lists: []
    },
    {
        id: "11",
        question: "Does FUN88 give regular updates for cricket?",
        answer: "Yes. You can stay updated on cricket by visiting the FUN88 website or following its social media platforms.",
        lists: []
    }
];

export const security47 = [
    {
        id: "1",
        question: "Is FUN88 a safe platform?",
        answer: "FUN88 is India's top, reliable, and trustworthy gaming platform. Employing advanced technology and stringent policies, FUN88 prioritizes protecting users' privacy. Hence, betting on FUN88 is completely safe and secure.",
        lists: []
    },
    {
        id: 2,
        question: "Is FUN88 licensed and compliant with regulations?",
        answer: "FUN88 is a top licensed gaming company and authorized to operate worldwide. Your information remains confidential at FUN88 and is not disclosed to any third party.",
        lists: []
    },
    {
        id: "3",
        question: "What is the reason for collecting my data during FUN88 registration?",
        answer: "FUN88 needs your personal information to confirm your identity and safeguard your account against fraud or malicious actions.",
        lists: []
    },
    {
        id: "4",
        question: "Which option among casino and sports betting offers a safer and more secure experience?",
        answer: "Betting on both casinos and sports is equally safe on FUN88. Experience next-level gambling by placing your bets on FUN88.",
        lists: []
    },
    {
        id: "5",
        question: "Do the FUN88 casino games use a Random Number Generator (RNG)?",
        answer: "Yes. FUN88 casino games employ Random Number Generators (RNGs) to guarantee fairness and unpredictable game outcomes.",
        lists: [],
        redirect: ""
    }
];