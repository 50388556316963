import React from 'react';
import styled from 'styled-components';
import { withProp } from 'styled-tools';
import Intl from "src/components/common/Intl";

// Component

// Utils
import { darken } from 'polished';
// import { diffColor } from '../../../../util/colorUtil';

// Styles

interface EmptyTableProps {
	pathname?: string;
}

const EmptyTable: React.SFC<EmptyTableProps> = (props: EmptyTableProps) => {
	const { pathname } = props;
	const showNoMarket = pathname !== undefined;
	return (
		<EmptyTableWrapper>
			<Intl langKey={showNoMarket ? 'TABLE@NO_MARKET' : 'TABLE@NO_EVENT'}/>
		</EmptyTableWrapper>
	);
};

export default EmptyTable;

export const shouldUseEmptyTable = (list: any[], ReactElement: JSX.Element) => {
	if (!Array.isArray(list) || list.length === 0) {
		return <EmptyTable />;
	} else {
		return ReactElement;
	}
};

// Styled Components
const EmptyTableWrapper: any = styled.div`
	font-size: 18px;
	font-style: bold;
	text-transform: uppercase;
	letter-spacing: 0.15em;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	border-radius: 4px;
	margin-bottom: 0;
	height: 100px;
    color: black;
	background: white;
`;
