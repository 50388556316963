import React from 'react'

type Props = {}

export default function CalenderIcon(props) {
  return (
    <svg fill="none" xmlns="" viewBox="0 0 12 14"><path d="M9.894 2.61H1.855c-.634 0-1.148.514-1.148 1.148v8.038c0 .635.514 1.149 1.148 1.149h8.039c.634 0 1.148-.514 1.148-1.149V3.758c0-.634-.514-1.149-1.148-1.149ZM8.172 1.461v2.297M3.578 1.461v2.297M.707 6.055h10.335" stroke="#4F6380" stroke-width="1.148" stroke-linecap="round" stroke-linejoin="round"/><path fill="#4F6380" d="M2.566 7.844H3.96v1.268H2.566zM2.566 9.936H3.96v1.268H2.566zM4.484 7.844h1.394v1.268H4.484zM4.484 9.936h1.394v1.268H4.484zM6.398 7.844h1.394v1.268H6.398zM6.398 9.936h1.394v1.268H6.398zM8.313 7.844h1.394v1.268H8.313zM8.313 9.936h1.394v1.268H8.313z"/></svg>
  )
}


