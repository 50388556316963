import React from 'react'
import { withAppContext } from 'src/store/initAppContext';
import styled, { withTheme } from 'styled-components';
import { CommanBgWrapper, HeadText, MainWrapper, TermsAndCondition, WelcomeBonusBody, WelcomeBonusTitle } from './WelcomeBonusFun';


type Props = {}

const CashbackBonusf88 = (props: any) => {

    const { app: { brandId }, theme } = props;
    // const domainName = brandId === 31 || brandId === 32 ? 'Yolo247' : brandId === 33 ? 'IW247' : 'Betdaily' ;

    return (
        <>
            <MainWrapper>
                {/* <SvgIcon1>
                    <ScrolIcon />
                </SvgIcon1> */}
                <CommanBgWrapper>
                    <HeadText>
                        <p>Imagine, being able to turn your losses into wins&#33; At FUN88, we&apos;re thrilled to offer you an incredible cashback bonus of up to 5% on your weekly losses—instantly credited to your wallet&#33; Enjoy the benefits based on your membership tier&#58; Blue&#58; 2%, Silver&#58; 3%, Gold&#58; 4%, Platinum&#58; 5%.</p>
                    </HeadText>
                </CommanBgWrapper>
                <CommanBgWrapper className='halfsize'>
                    <WelcomeBonusTitle>
                        <h1>Let&apos;s illustrate this with an exciting example&#58;</h1>
                        {/* <BonusTitle>
                            <p>Enjoy a bonus on every deposit according to your tier, with no roll-over requirements—it’s credited instantly! Here’s how it works:</p>
                        </BonusTitle> */}
                    </WelcomeBonusTitle>
                    <WelcomeBonusBody>
                        <p>If you experienced a loss of <strong>Rs.10,000</strong> during the week (tracked from Monday 00&#58;00&#58;01 AM to Sunday 23&#58;59&#58;59 PM), you could receive a whopping <strong>5% Cashback</strong>, which means <strong>Rs 500</strong> would be added to your main wallet&#33; </p>
                       
                    </WelcomeBonusBody>
                </CommanBgWrapper>
        
                <CommanBgWrapper  className='halfsize'>
                <TermsAndCondition>
                    <h1>Terms and conditions&#58;</h1>
                    <ol>
                        <li>Your Cashback will be credited to your Main Wallet every Monday, calculated from your weekly losses.</li>
                        <li>This promotion is based on your overall P&L on the platform, so you can maximize your returns&#33;</li>
                        <li>Cashback bonuses must be used within <strong>48 hours</strong>—dont miss out&#33;</li>
                        <li>Multiple accounts will not qualify for this promotion. All players are subject to duplicate checks, and FUN88 reserves the right to disqualify anyone found engaging in collusion or foul play.</li>
                        <li>FUN88 retains the right to amend, cancel, reclaim, or refuse any promotion at its discretion.</li>
                        <li>The bonus amount cannot be withdrawn, but any winnings from it are yours to keep&#33;</li>
                        <li>FUN88 General Terms & Conditions apply.</li>
                    </ol>
                    </TermsAndCondition>

                </CommanBgWrapper>
            </MainWrapper>
            {/* <FooterContent /> */}
        </>
    )
}

export default withAppContext(withTheme(CashbackBonusf88));

//Styled Components

