import { EGameTypeId, EGameTypeKey } from "src/util/utilModel";

const YCB = require('ycb');

const masterConfig = {
	settings: ['master'],
	gameTypeKey: '',
	gameIds: [EGameTypeId.PlayTech],
	name: '',
	displayName: '',
	langKey: '',
};

const configArray = [
	{
		dimensions: [
			{
				gameType: {
					[EGameTypeId.all]: null,
					[EGameTypeId.PlayTech]: null,
					[EGameTypeId.WeAreCasino]: null,
					[EGameTypeId.BTiSports]: null,
					[EGameTypeId.VIRTUALSPORTS]: null,
					[EGameTypeId.AgTeenpatti]: null,
					[EGameTypeId.SUPERNOWA]: null,
					[EGameTypeId.ONEXBET]: null,
					// [EGameTypeId.Jackpot]: null,
				},
			},
		],
	},
	masterConfig,
	{
		settings: [`gameType:${EGameTypeId.all}`],
		gameIds: [EGameTypeId.WeAreCasino, EGameTypeId.AgTeenpatti,EGameTypeId.SUPERNOWA,EGameTypeId.BTiSports, EGameTypeId.VIRTUALSPORTS,EGameTypeId.ONEXBET],
		name: 'ALL_GAME',
		displayName: 'All Games',
		langKey: 'GAME@ALL',
	},
	{
		settings: [`gameType:${EGameTypeId.PlayTech}`],
		gameTypeKey: EGameTypeKey.PlayTech,
		gameIds: [EGameTypeId.PlayTech],
		name: 'PLAYTECH',
		displayName: 'PlayTech',
		langKey: 'GAME@PLAYTECH',
	},
	{
		settings: [`gameType:${EGameTypeId.WeAreCasino}`],
		gameTypeKey: EGameTypeKey.WeAreCasino,
		gameIds: [EGameTypeId.WeAreCasino],
		name: 'WE_ARE_CASINO',
		displayName: 'Casinos',
		langKey: 'GAME@WE_ARE_CASINO',
	},
	{
		settings: [`gameType:${EGameTypeId.BTiSports}`],
		gameTypeKey: EGameTypeKey.BTiSports,
		gameIds: [EGameTypeId.BTiSports],
		name: 'BTI_SPORTS',
		displayName: 'Sportsbook',
		langKey: "GAME@SPORTBOOK",
	},
	{
		settings: [`gameType:${EGameTypeId.VIRTUALSPORTS}`],
		gameTypeKey: EGameTypeKey.VIRTUALSPORTS,
		gameIds: [EGameTypeId.VIRTUALSPORTS],
		name: 'VIRTUALSPORTS',
		displayName: 'Virtual Sports',
		langKey: "GAME@VIRTUALSPORTS",
	},
	{
		settings: [`gameType:${EGameTypeId.AgTeenpatti}`],
		gameTypeKey: EGameTypeKey.AgTeenpatti,
		gameIds: [EGameTypeId.AgTeenpatti],
		name: 'TEEN_PATTI',
		displayName: 'Teenpatti',
		langKey: 'GAME@TEEN_PATTI',
	},
	// {
	// 	settings: [`gameType:${EGameTypeId.Jackpot}`],
	// 	gameTypeKey: EGameTypeKey.Jackpot,
	// 	gameIds: [EGameTypeId.Jackpot],
	// 	name: 'JACKPOT',
	// 	displayName: 'Jackpot',
	// 	langKey: 'GAME@JACKPOT',
	// },
	{
		settings: [`gameType:${EGameTypeId.SUPERNOWA}`],
		gameTypeKey: EGameTypeKey.SUPERNOWA,
		gameIds: [EGameTypeId.SUPERNOWA],
		name: 'SUPERNOWA',
		displayName: 'Supernowa',
		langKey: 'GAME@SUPERNOWA',
	},
	{
		settings: [`gameType:${EGameTypeId.ONEXBET}`],
		gameTypeKey: EGameTypeKey.ONEXBET,
		gameIds: [EGameTypeId.ONEXBET],
		name: 'ONEXBET',
		displayName: 'Premium Sportsbook',
		langKey: 'GAME@ONEXBET',
	},

];

export default (gameTypeId: string) => {
	const ycbObj = new YCB.Ycb(configArray);
	const config: typeof masterConfig = ycbObj.read({ gameType: gameTypeId });
	return config;
};