import React, { useEffect } from 'react'
import { withAppContext } from 'src/store/initAppContext';
import styled, { withTheme } from 'styled-components';
import { LazyLoadImage } from "react-lazy-load-image-component";
import config from "src/config/config";
import PlaceholderImage from 'src/components/Placeholder';
import { theme } from 'styled-tools';
import FooterContainer from 'src/components/footer/footer';

function ChampionsTrophyPromoFun(props) {
    const { app: {
        brandId
    }, theme } = props;

    useEffect(() => {
        window.scrollTo(0, 0)
    }, [])

    const cardsData = [
        {
          image: "funCardImg1",
          title: "Lucky Pro Max:",
          description: "25 winners of ₹15,000 each, selected from those who placed bets on all 15 matches",
        },
        {
          image: "funCardImg2",
          title: "Lucky Pro:",
          description: "15 winners of ₹7,000 each, selected from those who placed bets on at least 7 matches.",
        },
        {
          image: "funCardImg3",
          title: "Lucky 10:",
          description: "10 winners of ₹3,000 each, selected from those who placed bets on at least 3 matches.",
        },
    ];

    const domain = (brandId == 46 || brandId == 47) ? "FUN88" : 'Website';

    return (
        <>
            <InformationWrapper>
                <TitleText>
                    <LazyLoadImage
                        src={`${config.imageV3Host}/static/cashsite/brand/6/image/championsTrophyTeamsLogos.png`}
                        alt="championsTrophyTeamsLogos"
                        effect="none"
                        placeholder={<PlaceholderImage />}
                    />
                </TitleText>
                <ImageWrapper>
                    <Container>
                        <LazyLoadImage
                            src={`${config.imageV3Host}/static/cashsite/brand/6/image/luckyChampBallImg.png`}
                            alt="championsTrophyPromoPriceList"
                            effect="none"
                            placeholder={<PlaceholderImage />}
                        />
                        <div className='worksContent'>
                            <div>
                                <h3>How it Works:</h3>
                                <p>Win prizes by placing bets on every Champions League match.</p>
                            </div>
                            <div>
                                <h3>Winners & Prizes:</h3>
                                <p><strong>100 winners</strong> with a total pool prize of <strong>₹5+ lakhs</strong> in cash prizes.</p>
                            </div>
                        </div>
                    </Container>
                </ImageWrapper>

                <PriceBreakDown>
                    <h2 className='boldText'>Prize Categories:</h2>
                    <CardContainer>
                        {cardsData.map((card, index) => (
                            <div className='borderContainer' key={index}>
                                <div>
                                <div className='cardImgWrap'><img src={`${config.imageV3Host}/static/cashsite/brand/6/image/${card.image}.png`} alt="Cards Icon" /></div>
                                <h3>{card.title}</h3>
                                <p>{card.description}</p>
                                </div>
                            </div>
                        ))}
                    </CardContainer>
                </PriceBreakDown>
            </InformationWrapper>
            <FooterContainer />
        </>
    )
}



export default withTheme(withAppContext(ChampionsTrophyPromoFun));

const CardContainer = styled.div`
    width: 833px;
    margin: 0 auto;
    display: flex;
    gap: 2rem;
    justify-content: center;

    .cardImgWrap {
        width: 100px;
        display: flex;
        align-items: center;
        justify-content: center;
    }

    img {
        max-width: 100%;
    }

    h3 {
        font-size: 15px;
        margin: 0;
        font-weight: 700;
        text-transform: capitalize;
        color: #fff;
    }

    p {
        font-weight: 600;
        font-size: 15px;
        margin: 0;
        line-height: normal;
        color: #fff;
        text-transform: capitalize;
    }

    .borderContainer {
        width: 33%;
        height: 290px;
        border-radius: 8.316px;
        border: 0.558px solid #06A6FF;
        background: #06A6FF;
        padding: 8px;

        > div {
            border-radius: 8.316px;
            border: 0.594px solid #FFF;
            display: flex;
            align-items: center;
            justify-content: center;
            flex-direction: column;
            padding: 1rem;
            height: 100%;
            text-align: center;
            gap: 7px;
        }
    }
`;

const Card = styled.div`
    background: linear-gradient(150deg, #D9D9D9 -0.64%, #F7F7F5 35.99%, #D4DBEE 44.53%, #F7F7F5 55.06%, #F7F7F5 67.11%, #D4DBEE 77.15%, #FBFBFB 85.68%);
    width: 250px;
    padding: 10px;
    text-align: center;
    color: #062365;
    padding-bottom: 3%;
    clip-path: polygon(
        0% 0%, 
        100% 0%, 
        100% 85%, 
        calc(50% + 1px) 100%,
        calc(50% - 1px) 100%,
        0% 85%
    );
  
  & img {
    width: 130px;
  }
  
  & h3 {
    margin-bottom: 10px;
    font-size: 18px;
    font-weight: bold;
    text-transform: capitalize;
  }
  
  & p {
    font-size: 14px;
    font-weight: 400;
    padding: 0 5px;
    text-transform: capitalize;
  }
`;

const Container = styled.div`
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 1.5rem 2rem;
    color: #444;
    gap: 7%;

    img {
        width: 300px;
        max-width: 100%;
    }

    .worksContent {
        display: flex;
        flex-direction: column;
        gap: 2rem;
        text-align: left;
    }

    & h3 {
        margin: 0;
        margin-bottom: 10px;
        font-size: 18px;
        font-weight: bold;
        text-transform: capitalize;
        color: #06A6FF;
    }
  
  & p {
        font-size: 14px;
        font-weight: 400;
        padding: 0;
        color: #444;
        margin: 0;
        margin-bottom: 10px;
    }
`;

const InformationWrapper = styled.div`
    width: 100%;
    height: auto;
    padding: 1.5rem 0 2rem 0;
    display: flex;
    flex-direction: column;
    background: #EBEBEB;
    overflow: hidden;

    .boldText {
        color: ${theme('palette.informationWrapperPromoBoldText')};
        font-weight: 700;
    }

    .boldTextBlack {
        color: #000;
        font-weight: 700;
    }

    h2 {
        color: #E6FF00;
        text-align: center;
        font-size: 20px;
        font-style: normal;
        font-weight: 700;
        line-height: normal;
        text-transform: capitalize;
        margin-bottom: 25px;
    }

    .bgRadius {
        width: 1120px;
        margin: 0 auto;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        border-radius: 11.188px;
        background: #FFF;
        text-align: center;
        padding-bottom: 1rem;
        margin-top: 1rem;
    }
`;

const PriceBreakDown = styled.div`
    width: 1120px;
    margin: 1rem auto;
    border-radius: 11.188px;
    background: #FFF;
    padding: 1.2rem 0 3rem 0;
`;

const TitleText = styled.div`
    width: 1120px;
    margin: 0 auto;
    border-radius: 11.188px;
    background: #FFF;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    padding: 1rem 0 1rem 0;
    margin-bottom: 1rem;

    p {
        color: #444;
        text-align: center;
        font-size: 15px;
        font-style: normal;
        font-weight: 400;
        line-height: 28px; /* 186.667% */
        text-transform: capitalize;
        margin: 0;
    }

    img {
        width: 800px;
        max-width: 100%;
    }
`;

const ImageWrapper = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
    margin-bottom: 2rem;
    width: 1120px;
    margin: 0 auto;
    border-radius: 11.188px;
    background: #FFF;
`;