import React from 'react';
import { Query } from '@apollo/react-components';
import styled, { withTheme } from 'styled-components';
import { withAppContext } from "src/store/initAppContext";
// Components
import Intl from '../../components/common/Intl';
import DatePicker from "react-datepicker";
// import DatePicker from '../../components/member/DatePicker';
import GameProfitLossTable from '../../components/member/GameProfitLossTable';
import ProfitLossTable from '../../components/member/ProfitLossTable'; 
import Bg from '../../images/iMAGE1_iexch.png'
import config from 'src/config/config';
// GQL
import { GET_PROFIT_LOSS } from '../../graphql/member';
import DatePickerIcon from 'src/images/calendar.png';

// Styled components
import groupBy from 'lodash/groupBy';
import { darken } from 'polished';
import { switchProp, theme, withProp } from 'styled-tools';
import { PageTitleName } from '../../components/common/Layout';
import { IncomeWrapper } from '../../components/common/NumberDisplay';
import { FilterSection } from '../../components/member/filterStyles';
import TypePicker from '../../components/member/TypePicker';
import EventTypeConfig from '../../config/eventTypeConfig';
import GameTypeConfig from '../../config/gameTypeConfig';
import { IOrder, ITransaction } from '../../graphql/schema';
import util from '../../util/util';
import { EEventTypeId, EGameTypeId, ETransactionType } from '../../util/utilModel';
import MemberSidebar from './member-sidebar';
import loading from '../loading';
import ProfitLossIcon from "../../images/profit_loss.png";
import Spinner from 'src/components/common/Spinner';
import {motion} from 'framer-motion';
interface IState {
	startAt: Date;
	endAt: Date;
	selectedId?: number;
}

class ProfitLoss extends React.PureComponent<any, IState> {
  constructor(props) {
    super(props)
  }
	// special在EventTypeConfig，但是inplay沒有要顯示
	// sportIds = EventTypeConfig(0).categoryIds.concat([EEventTypeId.horseracing, EEventTypeId.greyhoundracing, 100]);
  sportIds = EventTypeConfig(0).categoryIds.concat([EEventTypeId.horseracing, EEventTypeId.greyhoundracing]);
	gameIds = GameTypeConfig(EGameTypeId.all).gameIds;
	state = {
		startAt: new Date(new Date().setHours(0,0,0)),
		endAt: new Date(new Date().setHours(23,59,59)),
		selectedId: EventTypeConfig(0).categoryIds[0],
	};

	handleDayChange = (from: Date, to: Date) => {
		this.setState({
			startAt: from,
			endAt: to,
		});
	};

	handleTypeChange = (id: number) => {
		[...this.sportIds, ...this.gameIds].indexOf(id) >= 0 &&
			this.setState({
				selectedId: id,
			});
  };
  
  trying = (sportIds, gameIds, sportTransactionGroup, gameTransactionGroup) => {
    const sportInfoList = sportIds.map(id => {
      const eventConfig = EventTypeConfig(id);
      let distinctSettleNumber: string[] = [];
      let profitLoss = 0;
      util.getValue(sportTransactionGroup, [`${id}`], []).forEach(
        (t: ITransaction) => {
          const settleNumber: string = util.getValue(
            t,
            ['order', 'settleNumber'],
            '',
          );
          if (
            !distinctSettleNumber.some(
              number => number === settleNumber,
            )
          ) {
            distinctSettleNumber = [
              ...distinctSettleNumber,
              settleNumber,
            ];
            profitLoss += util.numMultiply(
              util.getValue(t, ['order', 'exchangeRate'], 0),
              t.winloss,
            );
          }
        },
      );
      return {
        name: eventConfig.displayName,
        id,
        profitLoss,
      };
    });


    const gameInfoList:any = gameIds.map((id: string) => {
      const gameConfig = GameTypeConfig(id);
      const profitLoss =
        gameTransactionGroup[id] &&
        gameTransactionGroup[id].reduce(
          (acc, current) =>
            acc +
            util.numMultiply(
              util.getValue(current, ['exchangeRate'], 0),
              current.winloss,
            ),
          0,
        );
      return {
        name: gameConfig.displayName,
        id,
        profitLoss,
      };
    });

    return (
      <TypePicker
      onTypeChange={this.handleTypeChange}
      groupedInfoList={[sportInfoList, gameInfoList]}
      isShowCredit={true}
      diffColor="DARKER"
    />
    )


  }

	render() {
		const { startAt, endAt, selectedId } = this.state;
		const sportIds = this.sportIds;
		const gameIds = this.gameIds;
		return (
			<>
			<motion.div 
        initial={{opacity: 0}}
        animate={{opacity: 1}}
        exit={{opacity: 0}}
        >
			{/* <ProfitWrapper> */}
				<Wrapper>
				
				<Query
					query={GET_PROFIT_LOSS}
					fetchPolicy="cache-and-network"
					variables={{ input: { period: { start: startAt.valueOf(), end: endAt.valueOf() } } }}
				>
					{({ loading, error, data }:any):any => {
						const transactions: ITransaction[] = util.getValue(data, ['exchange', 'profitLoss'], []);
						const sportTransactions = transactions.filter(t => t.type === ETransactionType.WS);
						const gameTransactions = Array.from(
							new Set([...transactions].filter(x => !new Set(sportTransactions).has(x))),
						);

						const sportTransactionGroup = groupBy(sportTransactions, t => {
							const order = t.order as IOrder;
							return order.categoryId;
            });
            

						const gameTransactionGroup = groupBy(gameTransactions, (t: any) => t.platform);

						// 運動與第三方遊戲使用不同的 table 顯示, 但special fancy用ProfitLossTable
						const content =
							selectedId === 100 || sportIds.includes(selectedId) ? (
								<ProfitLossTable
									loading={loading}
									error={error}
									transactions={sportTransactionGroup[selectedId]}
								/>
							) : (
								<GameProfitLossTable
									loading={loading}
									error={error}
									transferInfoList={gameTransactionGroup[selectedId]}
								/>
							);


          const handleDateChangeRaw = (e) => {
                e.preventDefault();
              }
            
              var date = new Date();
              const minDate = date.setDate((new Date()).getDate() - 90);   

						return (
							<>
							{loading && <Spinner mode="client" />}
							<MemberSidebar />
							<InnerWrapper theme={this.props.app.theme} customStyle={this.props.theme.image}>
							<Tittle theme={this.props.app.theme} customStyle={this.props.theme.palette}>
              <TittleIcon></TittleIcon>
              <Intl langKey=  "MEMBER@P2P_PROFIT_LOSS" />
							</Tittle >
							<DatePickerWrapper>
							<Tittle theme={this.props.app.theme} customStyle={this.props.theme.palette}>
							<CalenderIcon></CalenderIcon>
              <Intl langKey= "DATE_PICKER@TITLE" />
							</Tittle>
							<DateSelector>
								<BlockPick>
								<Intl langKey= "DATE_PICKER@FROM" /><DatePicker  selected={this.state.startAt}  onChange={(date: Date | null) => this.setState({startAt: date})} onChangeRaw={handleDateChangeRaw} minDate={minDate} maxDate={new Date()}/>
								</BlockPick>
								<BlockPick>
                <Intl langKey= "DATE_PICKER@TO" /><DatePicker selected={this.state.endAt} onChange={(date: Date | null) => this.setState({endAt: date})} onChangeRaw={handleDateChangeRaw} minDate={minDate} maxDate={new Date()}/>
								</BlockPick>
							</DateSelector>
           					</DatePickerWrapper>
              {/* <PLWrapper>        */}
								<Container>
                <FilterWrapper>
                {
                  this.trying(sportIds, gameIds, sportTransactionGroup, gameTransactionGroup)
                }
								</FilterWrapper>
								{content}
								</Container>
              {/* </PLWrapper>   */}
								</InnerWrapper>
							</>
						);
					}}
				</Query>
				</Wrapper>
			{/* </ProfitWrapper> */}
			</motion.div>
			</>
		);
	}
}

export default withTheme(withAppContext(ProfitLoss));

const DatePickerWrapper = styled.div`
  width: 50%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  position: relative;
  left: 50%;
  bottom: 35px;
  // top: -2%;
`;

const Tittle:any = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  
  color: ${(props:any) => (props.theme === 'Dark' ? props.customStyle['new_whitelable_dark_font_color'] : props.customStyle['secondary-background-color-new'])};  font-weight: 500;
  font-size: 15px;
  line-height: 18px;
  text-transform: capitalize;
  padding-bottom: 10px;
`;

const CalenderIcon = styled.div`
  width: 24px;
  height: 24px;
  display: flex;
  flex-direction: row;
  align-items: center;
  background-image: url(${DatePickerIcon});
  background-size: auto;
  background-repeat: no-repeat;
  margin-right: 10px;
  
`;

const DateSelector = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  position:relative;
  
  font-weight: bold;
`;


const BlockPick = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  background: ${theme('palette.new-whitelable-member-background')};
  border-radius: 5px;
  padding: 0px 10px;
  margin-right: 10px;
  text-transform: capitalize;
  color: #203974;
  font-size: 12px;

  input {
    border: none;
    background: none;
    display: flex;
    height: 47px;
    text-align: center;
  }

  .react-datepicker__day--outside-month {
  color: transparent !important;
  pointer-events: none;
}
`;

const TittleIcon = styled.div`
    width: 20px;
    height: 20px;
    display: flex;
    flex-direction: row;
    align-items: center;
    background-image: url(${ProfitLossIcon});
    background-size: auto;
    background-repeat: no-repeat;
    margin-right: 5px;
`;


const ProfitWrapper = styled.div`
 display: flex;
 
 width:100%;
`;

const Wrapper = styled.div`
  display: flex;
 
 width:100%;
`;

const InnerWrapper:any = styled.div`
  width: calc(100% - 250px);
  display: flex;
  padding: 10px;
  background: #fff;
  align-items: flex-start;
  justify-content: flex-start;
  flex-direction: column;
  padding-top: 20px;
  background-image: url('${(props:any) => (props.theme === 'Dark' ? `${config.imageS3Host}/static/snk/${props.customStyle['new_whitelable_dark_background']}` : `${config.imageS3Host}/static/snk/${props.customStyle['new_whitelable_light_background']}`)}');
  background-size: cover;
  background-position: 100%;
`;

const Container = styled.div`
	width: 100%;
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    background: #f1f4f8;
    border-radius: 5px;
    padding: 10px;
    height: 100%;
`;

const Title = styled.div`

  display: flex;
  flex-direction: row;
  align-items: center;
  color: ${theme('palette.secondary-background-color-new')};
  
  font-weight: 500;
  font-size: 15px;
  line-height: 18px;
  text-transform: capitalize;
  padding-bottom: 10px;

`;

const FilterWrapper = styled.div`
  width: 20%;
  display: flex;
  flex-direction: column;
  height :100%;
  background: ${theme('palette.new-whitelable-member-background')};
  margin-bottom: 2px;
`;

const PLWrapper = styled.div`
  display: flex;
  width:100%;
  height:100%;
`;
