import * as React from 'react';

// 讓註冊在 window 的 click event listener 比 event target 上的更早觸發
const isUsingCapture = true;

interface IProps {
	callback: (e: any) => any;
	shouldTriggerCallback?: boolean;
	children: (ref: React.RefObject<any>) => JSX.Element;
}

class ClickOutside extends React.PureComponent<IProps> {
	containerRef = React.createRef();

	handleClickOutside = (e: any) => {
		const { callback, shouldTriggerCallback } = this.props;
		const containerNode: any = this.containerRef.current;

		if (typeof callback !== 'function') {
			console.error('[ClickOutside]: callback prop should be a function');
		} else if (containerNode && !containerNode.contains(e.target) && shouldTriggerCallback) {
			callback(e);
		}
	};

	componentDidMount() {
		document.addEventListener('click', this.handleClickOutside, isUsingCapture);
	}

	componentWillUnmount() {
		document.removeEventListener('click', this.handleClickOutside);
	}

	render() {
		const { children } = this.props;
		if (typeof children !== 'function') {
			console.error('[ClickOutside]: children is expected to be a function');
			return null;
		} else {
			return children(this.containerRef);
		}
	}
}

export default ClickOutside;
