import { darken } from 'polished';
import { groupBy } from 'ramda';
import React, { PureComponent } from 'react';
import styled from 'styled-components';
import { withProp } from 'styled-tools';

// Dependent Components
import { Amount, Income } from '../../../common/NumberDisplay';
import Icon from '../../../common/PNGIcon';
// import Spinner from '~/component/common/Spinner';
import {
	ColMask,
	MobileMatchTableHeading,
	TableBodyRow,
	TableBodyWrapper,
	TableHeadRow,
	TableWrapper,
} from '../../../common/table/baseTableStyles';
import MobileOrderSlip from '../../order/orderslip/MobileOrderSlip';
import {
	InfoCol,
	InfoColContent,
	MaxExposureWrapper,
	NameCol,
	NameColContent,
	NameText,
	NameWrapper,
	OddsCol,
} from '../../table/feature/FancyTable';
import Odd from '../../table/feature/Odd';
import Intl from '../../../common/Intl';

// Context
import { withAppContext } from '../../../../store/initAppContext';
import { withMatchListContext } from '../../../../store/MatchListContext';
import { IOrderTicket, withOrderListContext } from '../../../../store/OrderListContext';

// Utils
import { calExposure } from '../../table/feature/exposureCalculate';
import { IEventInfo, IMarketInfo, IOrder } from '../../../../graphql/schema';
import util from '../../../../util/util';

interface IMultiFancyProps {
	eventInfo: IEventInfo;
	marketInfo: IMarketInfo;
	exchangeRate: number;
	isMobileWidth: boolean;
	orderList: IOrderTicket[];
	matchedList: IOrder[];
}

class MultiFancyTable extends PureComponent<IMultiFancyProps> {
	render() {
		const { eventInfo, marketInfo, exchangeRate, isMobileWidth, orderList, matchedList } = this.props;
		const {
			marketName,
			list: fancyInfoList,
			maxPerMarketExposure,
			maxPerOrderCredit,
			status: marketStatus,
			isOrder,
			description,
		} = marketInfo;

		const maxPerOrderCreditDisplay = util.numMultiply(maxPerOrderCredit || 0, exchangeRate);
		const maxPerMarketExposureDisplay = util.numMultiply(maxPerMarketExposure || 0, exchangeRate);

		// 過濾掉與這個 market 無關的注單
		const filteredOrderList = orderList.filter(order => order.marketInfo.marketId === marketInfo.marketId);
		const filteredMatchedList = matchedList.filter(matched => matched.marketId === marketInfo.marketId);

		// 計算曝險
		const initExposureBySelectionNames = Array.isArray(fancyInfoList)
			? fancyInfoList.reduce((pv, fancyInfo) => {
					if ('isRunning' in fancyInfo) {
						const { selectionName } = fancyInfo;
						selectionName && (pv[selectionName] = 0);
						return pv;
					} else {
						return pv;
					}
			  }, {} as { [selectionName: string]: number })
			: ({} as { [selectionName: string]: number });
		const orderListExposureBySelectionName = calExposure(filteredOrderList, { ...initExposureBySelectionNames });
		const matchedListExposureBySelectionName = calExposure(filteredMatchedList, {
			...initExposureBySelectionNames,
		});
		return (
			<>
				<MobileMatchTableHeading>{marketName}</MobileMatchTableHeading>
				{description && (
					<MultiDescription>
						<Icon name="info-red" />
						<div>{description}</div>
					</MultiDescription>
				)}
				<TableWrapper>
					<TableHeadRow>
						<NameCol>{marketName}</NameCol>
						<OddsCol>
							<span>
								{'BACK'}
							</span>
							<span>
								{'LAY'}
							</span>
						</OddsCol>
						<InfoCol />
					</TableHeadRow>
					<TableBodyWrapper>
						{Array.isArray(fancyInfoList) &&
							fancyInfoList.length > 0 &&
							fancyInfoList
								.sort((a: any, b: any) => {
									return a.sortPriority - b.sortPriority;
								})
								.map(fancyInfo => {
									// 確認 union MarketListType 是 FancyInfo
									if ('isRunning' in fancyInfo) {
										const {
											selectionId,
											selectionName,
											backPrice,
											backSize, 
											backStatus,
											backTotalSize,
											layPrice,
											laySize,
											layStatus,
											layTotalSize,
											isRunning,
										} = fancyInfo;
										const currentMatchedExposure = util.numMultiply(
											matchedListExposureBySelectionName[selectionName],
											exchangeRate,
										);
										const currentBetExposure = orderListExposureBySelectionName[selectionName];
										const currentExposure = currentBetExposure + currentMatchedExposure;

										return (
											<TableBodyRow key={selectionId}>
												<NameCol>
													<NameColContent>
														<NameWrapper>
															<NameText>{selectionName}</NameText>
														</NameWrapper>
														{(filteredMatchedList.length > 0 || currentExposure !== 0) && (
															<MaxExposureWrapper>
																<Income>{currentMatchedExposure}</Income>
																{filteredOrderList.length > 0 && (
																	<>
																		{' ➟ '}
																		<Income>
																			{util.numAdd(
																				currentBetExposure,
																				currentMatchedExposure,
																			)}
																		</Income>
																	</>
																)}
															</MaxExposureWrapper>
														)}
													</NameColContent>
												</NameCol>
												<OddsCol> 
													{(!isOrder || isRunning || marketStatus === 'SUSPENDED') && (
														<ColMask>
															{marketStatus === 'SUSPENDED' ? (
																<Intl langKey="TABLE@SUSPENDED" />
																// 'SUSPENDED'
															) : isRunning ? (
																// <Intl langKey="TABLE@BALL_RUNNING" />
																'BALL RUNNING'
															) : (
																''
															)}
														</ColMask>
													)}
													<Odd
														type="BACK"
														price={backPrice}
														size={backSize}
														fancyInfo={fancyInfo}
														isBest={true}
														marketInfo={marketInfo}
														eventInfo={eventInfo}
														sideStatus={backStatus}
														totalSize={backTotalSize}
													/>
													<Odd
														type="LAY"
														price={layPrice}
														size={laySize}
														isBest={true}
														fancyInfo={fancyInfo}
														marketInfo={marketInfo}
														eventInfo={eventInfo}
														sideStatus={layStatus}
														totalSize={layTotalSize}
													/>
												</OddsCol>
												<InfoCol>
													<InfoColContent>
														<span>
															<Intl langKey="TABLE@MAX_BET" />:{' '}
															<Amount fractionDigits={0}>
																{maxPerOrderCreditDisplay}
															</Amount>
														</span>
														<span>
															<Intl langKey="TABLE@MAX_MARKET" />:{' '}
															<Amount fractionDigits={0}>
																{maxPerMarketExposureDisplay}
															</Amount>
														</span>
													</InfoColContent>
												</InfoCol>
												{isMobileWidth && <MobileOrderSlip selfSelectionId={selectionId} />}
											</TableBodyRow>
										);
									} else {
										console.error(
											'[MultiFancyTable] cannot tell FancyInfo apart from SelectionInfo because property `isRunning` is missing',
										);
										return null;
									}
								})}
					</TableBodyWrapper>
				</TableWrapper>
			</>
		);
	}
}

interface IMultiFancyListProps {
	eventInfo: IEventInfo;
	multiFancyList: IMarketInfo[];
	loading: boolean;
	error: any;

	// From app context
	app: {
		isMobileWidth: boolean;
		exchangeRate: number;
	};

	// from orderListContext
	orderListContext: {
		betArray: IOrderTicket[];
	};

	matchListContext: {
		matched: IOrder[];
	};
}

class MultiFancyList extends PureComponent<IMultiFancyListProps> {
	render() {
		const {
			eventInfo,
			multiFancyList,
			loading,
			// error,
			orderListContext,
			matchListContext,
			app: { isMobileWidth, exchangeRate },
		} = this.props;
		const { betArray } = orderListContext;
		const { matched } = matchListContext;

		if (!Array.isArray(multiFancyList)) {
			return '';
		}
		if (loading) {
			return (
				<TableWrapper>
					{/* <Spinner mode="client" /> */}
					{'LOADINNG'}
				</TableWrapper>
			);
		}

		const listByFancyTypeId = groupBy(marketInfo => (marketInfo.fancyTypeId || 0).toString(), multiFancyList) || {};
		const matchOddsList = listByFancyTypeId['1'] || [];
		delete listByFancyTypeId['1'];
		const otherList = Object.values(listByFancyTypeId)
			.filter(_ => _)
			.flat();
		return [...matchOddsList, ...otherList].map(marketInfo => {
			return (
				<MultiFancyTable
					marketInfo={marketInfo}
					eventInfo={eventInfo}
					exchangeRate={exchangeRate}
					isMobileWidth={isMobileWidth}
					orderList={betArray}
					matchedList={matched}
					key={marketInfo.marketId || ''}
				/>
			);
		});
	}
}

export default withAppContext(withMatchListContext(withOrderListContext(MultiFancyList, ['betArray']), ['matched']));

const MultiDescription = styled.div`
	display: flex;
	font-size: 14px;
	padding-bottom: 5px;
	word-break: normal;
	align-items: center;
	width: 100%;
	> div {
		padding-bottom: 0.12em;
		margin-left: 0.4em;
	}
`;
