import React from 'react';
import styled from "styled-components";

// util
import EventTypeConfig from '../../../config/eventTypeConfig';
import { GET_INNER_EVENTS } from '../../../graphql/events';
import { IEventInfo, ILiveInfo, IMarketInfo, IScoreInfo } from '../../../graphql/schema';
import { withAppContext } from '../../../store/initAppContext';
import util from '../../../util/util';

// component
// import { Heading2, Heading3 } from '../../common/Headings';
import Live from '../Live'; 
import FancyTable from '../table/feature/FancyTable';
import MatchTable from '../table/feature/MatchTable';
import MultiFancyList from '../table/feature/MultiFancy';
import SubscribedMarketList from '../../subscription/SubscribedMarketList';
import { EEventTypeId } from '../../../util/utilModel';
import { color } from '../../../sass-files/variable';

import EmptyTable from '../../../components/common/table/feature/EmptyTable';
import config from '../../../config/config';
import EventNotFound from '../EventNotFound';

const {
    tabsignup,
    innerBackHeadBg,
    innerLayHeadBg,
    secondarycolor,
    innerOddsLayColor,
    innerBackLightBg,
    innerBackDarkBg,
    innerLayDarkBg,
    innerLayLightBg,
    titleHeadColor,
} = color

interface ISportListProps {
	app: { query: any; isMobileWidth: boolean; pathname: string, token: any, setState:any };
    fancyQuery: any;
	categoryId: any
	competitionId:any
	eventId:any;
	matchId: any
}
export const eventNameMap: { [num: number]: string } = {
	17: 'Multi Selection Fancy',
	1: 'Match Odds',
	2: 'Fancy',
	3: 'Completed Match',
	4: 'Tied Match',
	5: 'Over/Under 0.5 Goals',
	6: 'Over/Under 1.5 Goals',
	7: 'Over/Under 2.5 Goals',
	8: 'Over/Under 3.5 Goals',
	9: 'Over/Under 4.5 Goals',
	10: 'Over/Under 5.5 Goals',
	11: 'Over/Under 6.5 Goals',
	12: 'Over/Under 7.5 Goals',
	13: 'Over/Under 8.5 Goals',
	14: 'To Qualify',
	15: 'Innings Runs',
	16: 'Super Over',
};

const ignoreMarketType = config.env.toLocaleLowerCase() === 'prod' ? [16] : [];
const marketTypeSortOrder = [1, 17, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16,25].filter(
	id => !ignoreMarketType.includes(id),
);

class SportList extends React.PureComponent<ISportListProps, {}> {
	render() {
		const {
			app: { query, isMobileWidth, pathname, token, setState },
            fancyQuery,
			categoryId,
			competitionId,
			eventId,
		} = this.props;
		
		// const { sport, competitionId, eventId } = query;
		// const categoryId =
			// !(sport && competitionId && eventId) && fancyQuery ? fancyQuery.categoryId : Number(EEventTypeId[sport]);
			let catergoryIdNumber = Number(categoryId)
		const inputProps: any = {
			categoryId: catergoryIdNumber,
            competitionId: competitionId === 'e' ? '' : competitionId,
            eventId
			// eventId: !(sport && competitionId && eventId) && fancyQuery ? fancyQuery.eventId : eventId,
		};
		const gqlQuery = GET_INNER_EVENTS;
		const isNoEventRedirect = pathname.includes('in-play');
		// Code Changed - Manoj ( makeing Bookmaker Fasle here)	
		// const isBookmaker = util.isBookmaker();
        const isBookmaker = false
		return (
			
			<SubscribedMarketList app={this.props.app} query={gqlQuery} variables={{input: {categoryId:100, eventId: '5889'} }} option={{ isLive: false }}>
				{({ loading, error, data, subscribeToMore, variables, updateQuery }: any) => {
					const eventInfoList: IEventInfo[] = util.getValue(data, ['markets', 'list'], []);

					// 當 event 不存在時導轉頁面
					if (eventInfoList.length <= 0 && !loading) {
						if (isNoEventRedirect === true) {
							return <EmptyTable />;
						} else {
							return <EventNotFound />;
						}
					}
					const sportBaseInfo = EventTypeConfig(categoryId);
					const eventInfo = eventInfoList[0] || {};
					// 當fancy資訊為空
					const fancyTypeInfoList = eventInfo.snk || [];
					if (categoryId === EEventTypeId.SPECIAL_FANCY && fancyTypeInfoList.length <= 0) {
						if (isNoEventRedirect === true) {
							return <EmptyTable />;
						} else {
							return <EventNotFound />;
						}
					}

					// 舊 fancy，只會有一個
					// Code Changed -  Make marketType === 1 from 2
					const singleFancyList = fancyTypeInfoList.filter(fancyTypeInfo => fancyTypeInfo.marketType === 2);
					// Multi selection fancy

					const multiFancyList = fancyTypeInfoList.filter(fancyTypeInfo => fancyTypeInfo.marketType === 17 || fancyTypeInfo.marketType === 25);
					const scoreInfo = eventInfo.score || ({} as IScoreInfo);
					const liveInfo = eventInfo.live || ({} as ILiveInfo);
					const matchType = eventInfo.matchType || '';
					const betradarId = eventInfo.betradarId || '';
					const categorizedEventInfoList = categorizeEventInfoListByMarket(eventInfoList)[0] || {};
					const eventListByMarketType: any = Object.keys(categorizedEventInfoList).reduce(
						(pv, marketType) => {
							pv[marketType] = {
								loading,
								error,
								categoryId,
								dataList: [(categorizedEventInfoList as any)[marketType]],
							};
							return pv;
						},
						{} as {
							[marketType: string]: {
								loading: boolean;
								error: any;
								categoryId: number;
								dataList: IEventInfo[];
							};
						},
					);
					return (
						<GameDetailWrapper style={{ marginTop: '5px' }}>
							{eventInfo.eventName &&
								(!isMobileWidth ? (
									null
								) : (
									null
								))}
							{/* live Score */}
							<Live
								scoreInfo={scoreInfo}
								liveInfo={liveInfo}
								betradarId={betradarId}
								matchType={matchType}
								eventId={eventId}
							/>
							{marketTypeSortOrder.map(key => {
								switch (key) {
									case 2:
										return singleFancyList ? (
											<FancyTable
												eventInfo={eventInfo}
												fancyDataList={(singleFancyList || []).filter((market: any) => {
													return market.resourceFrom;
												})}
												loading={loading}
												error={error}
											/>
										) : null;
									case 17:
										return multiFancyList && isBookmaker ? (
											<MultiFancyList
												eventInfo={eventInfo}
												multiFancyList={multiFancyList}
												loading={loading}
												error={error}
											/>
										) : null;
									default:
										return isBookmaker ? (
											<></>
										) : (
											getMatchTable(
												eventListByMarketType[key],
												variables,
												gqlQuery,
												updateQuery,
												key,
											)
										);
								}
							})}
						</GameDetailWrapper>
					);
				}}
			</SubscribedMarketList>
		);
	}
}

export const getMatchTable = (propsOfMarketType: any, variables: any, gqlQuery: any, updateQuery: any, key: number) => {
	
	if (!propsOfMarketType || !propsOfMarketType.dataList || propsOfMarketType.dataList.length === 0) {
		return '';
	}
	return <MatchTable {...propsOfMarketType} key={key} />;
};

export default withAppContext(SportList);

export const categorizeEventInfoListByMarket = (eventInfoList: IEventInfo[]) => {
	return eventInfoList.map((event: IEventInfo) => {
		// Code Changed - Manoj ( removed main and made it snk below all code)
		// const { main } = event;
		const {snk} = event;
		const result: {
			[marketType: number]: IMarketInfo[];
		} = {};
		Array.isArray(snk) &&
				snk.forEach(market => {
				const marketType = market.marketType || 0;
				!result[marketType] && (result[marketType] = []);
				result[marketType].push(market);
			});

		const categorizedMarkets: any = {};
		Object.keys(result).forEach(marketType => {
			categorizedMarkets[marketType] = {
				...event,
				eventName: (eventNameMap as any)[marketType] || event.eventName,
				snk: (result as any)[marketType],
			};
		});
		return categorizedMarkets;
	});
};

const RowWrap = styled.div`
    width: 100%;
    display: flex;
    flex-direction: coloumn;
    align-items: flex-start;
    background: #EAF4FD;
    margin-bottom: 10px;
    
`;

const GameDetailWrapper = styled.div`
    width: 100%;
    padding: 5px;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 20px;
    background: ${secondarycolor};
    box-shadow: 0px 4px 4px rgba(143, 143, 143, 0.16);
`;
