import React from 'react'

type Props = {}

const IndianFlag = (props: Props) => {
    return (
        <svg width="19" height="14" viewBox="0 0 19 14" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M0.780273 1.9331C0.780273 1.38082 1.22799 0.933105 1.78027 0.933105H17.752C18.3042 0.933105 18.752 1.38082 18.752 1.93311V4.97861H0.780273V1.9331Z" fill="#FAB446" />
            <path d="M0.780273 12.0693C0.780273 12.6216 1.22799 13.0693 1.78027 13.0693H17.752C18.3042 13.0693 18.752 12.6216 18.752 12.0693V9.02383H0.780273V12.0693Z" fill="#73AF00" />
            <path d="M0.780273 9.0459H18.752V5.00039H0.780273V9.0459Z" fill="white" />
            <path d="M11.0986 5.92212L10.1416 6.77759L10.2275 6.89087L11.0986 5.92212Z" fill="#41479B" />
            <path d="M11.4706 6.43709L10.2584 6.85946L10.2943 6.99706L11.4706 6.43709Z" fill="#41479B" />
            <path d="M11.5762 7.04931L10.2938 6.99444L10.2763 7.13556L11.5762 7.04931Z" fill="#41479B" />
            <path d="M11.4708 7.63757L10.3018 7.10725L10.2328 7.2316L11.4708 7.63757Z" fill="#41479B" />
            <path d="M11.1245 8.18578L10.2642 7.23311L10.1513 7.31963L11.1245 8.18578Z" fill="#41479B" />
            <path d="M10.5986 8.52732L10.1612 7.32052L10.024 7.35807L10.5986 8.52732Z" fill="#41479B" />
            <path d="M9.98798 8.61954L10.0185 7.33626L9.8771 7.32147L9.98798 8.61954Z" fill="#41479B" />
            <path d="M9.34174 8.52051L9.93075 7.37998L9.81006 7.3048L9.34174 8.52051Z" fill="#41479B" />
            <path d="M8.8345 8.18347L9.79794 7.33523L9.71286 7.2213L8.8345 8.18347Z" fill="#41479B" />
            <path d="M8.48515 7.65192L9.70185 7.2428L9.66751 7.10482L8.48515 7.65192Z" fill="#41479B" />
            <path d="M8.36172 7.02119L9.64282 7.10198L9.66314 6.96125L8.36172 7.02119Z" fill="#41479B" />
            <path d="M8.47853 6.42418L9.64269 6.96498L9.71276 6.84125L8.47853 6.42418Z" fill="#41479B" />
            <path d="M8.8145 5.91574L9.70592 6.83938L9.81583 6.74917L8.8145 5.91574Z" fill="#41479B" />
            <path d="M9.38334 5.57258L9.81078 6.78297L9.94822 6.74655L9.38334 5.57258Z" fill="#41479B" />
            <path d="M9.97698 5.48028L9.89409 6.76124L10.0348 6.78179L9.97698 5.48028Z" fill="#41479B" />
            <path d="M10.5645 5.55672L10.066 6.73961L10.1922 6.80518L10.5645 5.55672Z" fill="#41479B" />
            <path fill-rule="evenodd" clip-rule="evenodd" d="M9.9546 8.4234C10.7128 8.4234 11.3275 7.80875 11.3275 7.05054C11.3275 6.29234 10.7128 5.67769 9.9546 5.67769C9.19639 5.67769 8.58174 6.29234 8.58174 7.05054C8.58174 7.80875 9.19639 8.4234 9.9546 8.4234ZM9.9546 8.7234C10.8785 8.7234 11.6275 7.97444 11.6275 7.05054C11.6275 6.12665 10.8785 5.37769 9.9546 5.37769C9.0307 5.37769 8.28174 6.12665 8.28174 7.05054C8.28174 7.97444 9.0307 8.7234 9.9546 8.7234Z" fill="#41479B" />
            <path d="M10.1556 7.05046C10.1556 7.14899 10.0757 7.22886 9.97722 7.22886C9.8787 7.22886 9.79883 7.14899 9.79883 7.05046C9.79883 6.95194 9.8787 6.87207 9.97722 6.87207C10.0757 6.87207 10.1556 6.95194 10.1556 7.05046Z" fill="#41479B" />
            <path fill-rule="evenodd" clip-rule="evenodd" d="M10.3311 7.04958C10.3311 7.24497 10.1727 7.40337 9.97732 7.40337C9.78193 7.40337 9.62354 7.24497 9.62354 7.04958C9.62354 6.8542 9.78193 6.6958 9.97732 6.6958C10.1727 6.6958 10.3311 6.8542 10.3311 7.04958ZM9.97732 7.30337C10.1175 7.30337 10.2311 7.18975 10.2311 7.04958C10.2311 6.90942 10.1175 6.7958 9.97732 6.7958C9.83716 6.7958 9.72354 6.90942 9.72354 7.04958C9.72354 7.18975 9.83716 7.30337 9.97732 7.30337Z" fill="#41479B" />
        </svg>


    )
}

export default IndianFlag