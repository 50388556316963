import styled from "styled-components";
import config from "src/config/config";
// Imported Components
import { useForm, Controller } from "react-hook-form";
import { LoginInput } from "src/components/Auth/Login/Login";
import { AES, pad, mode, enc } from "crypto-js";
import { useState } from "react";
import AUTH_OTP from "src/service/auth/index";
import OTPTimer from 'src/pages/auth/Signup/OTPTimer'        
import Intl from "src/components/common/Intl";
import {
  AuthButtonWrap,
  AuthSubmitBtn,
  OtpButton,
  PhoneNumberAndOtpWrap,
} from "src/components/Auth/commonStyles";
import { withAppContext } from "src/store/initAppContext";
import { resetPassword, validateandsendOtp } from "src/config/api";
import { theme } from "styled-tools";
import SEOContents from "src/components/SEOContents";

export type UserOptions = {
  userName: string;
  phoneNumber: string;
  password: string;
  otpCode: string;
};

interface IPasswordProps {
  onSendOtpHandler: (event: React.MouseEvent<HTMLElement>, value: any) => void;
  otpHandle: boolean;
  loading: boolean;
  setOtpLoader: boolean;
  showTimer: boolean;
  showResend: boolean;
  app: any;
  isSuccess?: boolean;
  parentCallback: (e: number) => void;
}

function ForgotPassword(props: IPasswordProps) {
  const { onSendOtpHandler, app: { setState, showTimer, showResend, brandId, resetSuccess } } = props;
  const {
    formState: { isValid },
    getValues,
    control,
    watch,
  } = useForm({
    mode: "onChange",
  });
  const TOKEN_SECRET_KEY = config.secretKey;
  const watchMobileNumber = watch("MobileNumber", 0);
  const watchOTPCode = watch("OTP", 0);
  const watchPassword = watch("Password", "");
  const [mobilenumber, setMobilenumber] = useState("");
  const [networkMessage, setNetworkMessage] = useState("");
  const [otpsent, setOtpsent] = useState(false);
  const [isSuccess, setIsSuccess] = useState(false);
  const [otpLoader, setOtpLoader] = useState(false);

  const VerifyIcon = `${"static/Image/check-circle.png"}`;
  const ArrowIcon = `${"static/Image/arrow-right-circle.png"}`;
  const NoneIcon = `${"static/Image/"}`;

  const Icons = { ArrowIcon, VerifyIcon, NoneIcon };

  //API to send OTP to the registered mobile number
  const sendOTP = async (formData) => {
    const info = JSON.stringify({
      "phoneNumber": watchMobileNumber.trim(),
      "phoneCountry": "in",
      "brandId":brandId
    });
    const encryptedTarget = enc.Utf8.parse(info);
    const encryptSecretKey = enc.Utf8.parse(TOKEN_SECRET_KEY);
    const authInfo = AES.encrypt(encryptedTarget, encryptSecretKey, {
      iv: enc.Utf8.parse(""),
      padding: pad.Pkcs7,
      mode: mode.CBC,
    }).ciphertext.toString();
    let data = {
      resetInfo: authInfo,
    };
    
    setOtpLoader(true);
    setOtpsent(true)
    validateandsendOtp(data)
      .then((response) => {
        setOtpLoader(false);
        setIsSuccess(true);
        setNetworkMessage(response.data.message);
        setOtpsent(true)
        if (typeof setState === 'function') {
          let _reqData = {
            showTimer: true
          }
          setState(_reqData)
        }
        setTimeout(() => {
          if (typeof setState === 'function') {
            let _reqData = {
              showTimer: false,
              showResend: true
            }
            setState(_reqData)
          }
        }, 75000);
      })
      .catch((error) => {
        setOtpLoader(false);
        setOtpsent(false)
        let errorResponse = error.response?.data
        setNetworkMessage(errorResponse?.error);
      })
  };

  //API to reset the password
  const ResetPassword = async (formData) => {
    const info = JSON.stringify({
      userName: localStorage.getItem("username"),
      phoneNumber: watchMobileNumber.trim(),
      phoneCountry: "in",
      otpCode: watchOTPCode,
      password: watchPassword,
      brandId:brandId
    });
    const encryptedTarget = enc.Utf8.parse(info);
    const encryptSecretKey = enc.Utf8.parse(TOKEN_SECRET_KEY);
    const authInfo = AES.encrypt(encryptedTarget, encryptSecretKey, {
      iv: enc.Utf8.parse(""),
      padding: pad.Pkcs7,
      mode: mode.CBC,
    }).ciphertext.toString();

    let data = {
      resetInfo: authInfo,
    };

    resetPassword(data)
      .then((response) => {
          setIsSuccess(true)
          setNetworkMessage(response.data.message);
          setTimeout(() => {
                props.parentCallback(0);
              }, 3000);
      })
      .catch((error) => {
        setIsSuccess(false)
        let errorResponse = error.response.data
        setNetworkMessage(errorResponse.error);
      })

    // try {
    //   const registerInfo = await AUTH_OTP.resetPassword(data);
    //   setIsSuccess(true)
    //   setNetworkMessage(registerInfo.data.message);
    //   setTimeout(() => {
    //     props.parentCallback(0);
    //   }, 3000);
    // } catch (error) {
    //   setIsSuccess(false)
    //   let errorResponse = error.response.data
    //   setNetworkMessage(errorResponse.error);
    // }
  };

  const formatInput = (e) => {
    // Prevent characters that are not numbers ("e", ".", "+" & "-")
    let checkIfNum;
    if (e.key !== undefined) {
      // Check if it's a "e", ".", "+" or "-"
      checkIfNum = e.key === "e" || e.key === "." || e.key === "+" || e.key === "-" || e.key === 'ArrowUp' || e.key === 'ArrowDown'  || e.key === 'E';
    }
    else if (e.keyCode !== undefined) {
      // Check if it's a "e" (69), "." (190), "+" (187) or "-" (189)
      checkIfNum = e.keyCode === 69 || e.keyCode === 190 || e.keyCode === 187 || e.keyCode === 189;
    }
    return checkIfNum && e.preventDefault();
  }

  return (
  <>
    <SEOContents pageName={"forgotPassword"}/>
      <ForgotPasswordWrap>
        <form autoComplete="off">
          <Controller
            control={control}
            name="MobileNumber"
            rules={{
              required: { value: true, message: "* Please enter Mobile Number" },
              minLength: { value: 10, message: "* Minimum Character : 10" },
              maxLength: { value: 10, message: "* Too Many Characters" }
            }}
            render={({
              field: { onChange, value },
              fieldState: { error },
            }) => (
              <>
                <PhoneNumberAndOtpWrap>
                  <p>+91</p>
                  <LoginInput
                    id="mobilenumber"
                    placeholder={<Intl langKey="SIGNUP@ENTERNUMBER" />}
                    type="number"
                    onChange={onChange}
                    onKeyDown={formatInput}
                    onWheel={(e) => e.target.blur()}
                    onKeyPress={(e) => { e.key === 'Enter' && e.preventDefault(); }}
                    value={value}
                    error={error}
                    req={true}
                    onPaste={(e)=>{
                    e.preventDefault()
                    return false;
                    }} 
                    onCopy={(e)=>{
                    e.preventDefault()
                    return false;
                    }}
                  />
                  <OtpButton
                    disabled={value?.length !== 10 || otpsent}
                    isActive={value?.length === 10}
                    type="button"
                    onClick={sendOTP}
                  >
                    {otpsent ? (
                      <>
                      <Intl langKey="AUTH@SENT" />  <img className="send-otp" src={Icons.VerifyIcon} alt="" />
                      </>
                    ) : (
                        <>
                          {
                            otpLoader ? <Intl langKey="AUTH@SENDING" /> : <> <Intl langKey="AUTH@OTP" /> <img className="send-otp" src={Icons.ArrowIcon} alt="ArrowIcon" /></>
                          }
                        </>
                      )}
                  </OtpButton>


                </PhoneNumberAndOtpWrap>
                <div className="timer" >{showTimer ? <OTPTimer /> : null}</div>

                {showResend ?
                  <div className="message">
                    <p> <Intl langKey="AUTH@RESENDOTP" /><Resendbutton onClick={(event) => onSendOtpHandler(event, getValues)}><Intl langKey="AUTH@RESEND" /></Resendbutton></p> 
                  </div>
                  : null}
                <h1>{error && error.message}</h1>
              </>
            )}
          />
          {otpsent &&
            <OTPcode>
              <Controller
                control={control}
                name="OTP"
                rules={{
                  required: { value: true, message: "* Please enter otp" },
                  minLength: { value: 6, message: "* Enter 6 digit OTP" },
                }}
                render={({ field: { onChange, value }, fieldState: { error } }) => (
                  <>
                    <LoginInput
                      id="otpcode"
                      placeholder={<Intl langKey="LOGIN@ENTEROTP" />}
                      type="number"
                      onChange={onChange}
                      onKeyDown={formatInput}
                      onWheel={(e) => e.target.blur()}
                      value={value}
                      error={error}
                      req={true}
                      onPaste={(e)=>{
                      e.preventDefault()
                      return false;
                      }} 
                      onCopy={(e)=>{
                      e.preventDefault()
                      return false;
                      }}
                    />
                    <h1>{error && error.message}</h1>
                  </>
                )}
              />
            </OTPcode>
          }
          {otpsent && (
            <>
              <Controller
                control={control}
                name="Password"
                rules={{
                  required: { value: true, message: "* Please enter password" },
                  minLength: { value: 8, message: "* Minimum Length : 8" },
                  maxLength: { value: 24, message: "* Max Length reached" },
                  pattern: {
                    value: /^(?=.*?[A-z])(?=.*?[0-9]).{8,}$/,
                    message:
                    "* Must contain at least one number, one letter, and at least 8 or more characters",
                  },
                }}
                render={({ field: { onChange, value }, fieldState: { error } }) => (
                  <>
                    <LoginInput
                      id="password"
                      placeholder={<Intl langKey="LOGIN@NEW_PASSWORD"/>}
                      type="password"
                      onChange={onChange}
                      value={value}
                      error={error}
                      req={true}
                    />
                    <h1>{error && error.message}</h1>
                  </>
                )}
              />

              <Controller
                control={control}
                name="Confirm Password"
                rules={{
                  required: { value: true, message: "* Please enter password" },
                  validate: (value) => {
                    const { Password } = getValues()
                    return Password === value || 'Password should Match'
                  },
                  minLength: { value: 8, message: "* Minimum Length : 8" },
                  maxLength: { value: 24, message: "* Max Length reached" },
                  pattern: {
                    value: /^(?=.*?[A-z])(?=.*?[0-9]).{8,}$/,
                    message:
                      "*Must contain at least one number, one letter, and at least 8 or more characters",
                  },
                }}
                render={({ field: { onChange, value }, fieldState: { error } }) => (
                  <>
                    <LoginInput
                      id="password"
                      placeholder={<Intl langKey="LOGIN@CONFIRM_NEW_PASSWORD" />}
                      type="password"
                      onChange={onChange}
                      value={value}
                      error={error}
                      req={true}
                    />
                    <h1>{error && error.message}</h1>
                  </>
                )}
              />
            </>
          )}

        
          <div className={`${isSuccess ? 'success-wrap' : 'error-wrap'}`}>
            {networkMessage?.length !== 0 && <p className='username-error1' >{networkMessage}</p>}
          </div>

          <AuthButtonWrap>
            <AuthSubmitBtn
              disabled={(isValid && otpsent ? false : true)}
              type="button"
              onClick={(e) => ResetPassword(e)}
            >
            <Intl langKey="PASSWORD@BUTTON" />
            </AuthSubmitBtn>
          </AuthButtonWrap>
        </form>
      </ForgotPasswordWrap>
    </>
  );
}
export default withAppContext(ForgotPassword);

const ForgotPasswordWrap = styled.div`
  & h1 {
    font-size: 12px;
    color: red;
    margin: 3px;
    font-weight: 500;
  }

  & .error-wrap {
    & p {
      font-size: 15px;
      text-align: center;
      color: white;
      font-weight: 500;
      background-color: #c91414;
      padding: 5px;
      border-radius: 30px;
    }
  }
  & .success-wrap {
    & p {
        font-size: 15px;
        text-align: center;
        color: white;
        font-weight: 500;
        background-color: green;
        padding: 5px;
        border-radius: 30px;
      }
  }
  .timer{
    color: #FF6060;
   display: flex;
   justify-content: flex-end;
    font-size: 13px;
    font-style: italic;
}

  .message{
    display: flex;
    justify-content: flex-end;

    p{
      color: #FF6060;
      font-size: 13px;
      font-style: italic;
      margin: 3px 0px;
    }
  }
`;


const Resendbutton = styled.button`
    color: ${theme('palette.signupResendOtpTextColor')};
    border: transparent;
    font-style: italic;
    text-decoration: underline;
    background: transparent;
    cursor: pointer

`;

const OTPcode = styled.div`
  display: block;

  button {
    cursor: pointer;
    background: #0f72e7;
  }

  button:disabled {
    background: #edf0f7;
  }
`;



