import React, { useEffect, useRef, useState } from 'react'
import styled from 'styled-components';
import { theme } from 'styled-tools';
import MyRanks from './MyRanks';
import MyBets from './Bets';
import LeaderTable from './table';
import { withAppContext } from 'src/store/initAppContext';
import { getLeaderBoardData } from 'src/config/api';
import { Notify } from '../Notification';
import cookieUtil from 'src/util/cookieUtil';
import { ECookieName } from 'src/util/utilModel';
import Intl from 'src/components/common/Intl';

const HeadObj = [
    {
        "label": "myRanks",
        "id": "MYRANK",
    },
    {
        "label": "latestBets",
        "id": "LATEST",
    },
    {
        "label": "dailyRace",
        "id": "DAILYRACE",
    },
    {
        "label": "weeklyRace",
        "id": "WEEKLYRACE",
    },
    {
        "label": "tournament",
        "id": "TOURNAMENT",
    },

];

const LeaderBoard = (props: any) => {
    const isLoggedIn = cookieUtil.get(ECookieName.COOKIE_TOKEN);
    const [activeTab, setActiveTab] = useState('LATEST');
    const [numrows, setNumrows] = useState(20);
    const [showLoader, setShowLoader] = useState(false);
    const [showPaginationLoader, setShowPaginationLoader] = useState(false);
    const [noData, setNoData] = useState(false)
    const [leaderData, setLeaderData] = useState([] as any);
    const { app: { brandId } } = props;
    
    const refElm = useRef<HTMLDivElement>();

    useEffect(() => {
        getLeaderData(activeTab, numrows);
    }, [])

    const getLeaderData = (selectedTab: string, rows: number) => {
        const param = `type=${selectedTab}&limit=${rows}&brand_id=${brandId}`;
        setShowLoader(true);
        setNoData(false);

        getLeaderBoardData(param).then(res => {
            const { data = [] } = res.data;
            setLeaderData(data);
            setShowLoader(false);
            setShowPaginationLoader(false);
            if (data.length < 1) {
                setNoData(true);
            }

        }).catch(e => {
            const { data } = e.response || {};
            setShowPaginationLoader(false);
            setShowLoader(false);
            let message = 'Something Went Wrong, Please Try Again';
            if (data && data.error) {
                message = data.error;
            }
            Notify.error(message);
        });
    }

    const setTabActive = (id: string) => {
        if (activeTab === id) { return; }
        setActiveTab(id);
        setLeaderData(id === 'LATEST' ? [] : {});
        getLeaderData(id, numrows);
    }

    const setPagination = (e: React.ChangeEvent<HTMLSelectElement>) => {
        const _select: any = e.target;
        const value = _select.value;
        setNumrows(value);
        setShowPaginationLoader(true);
        getLeaderData(activeTab, value);

    }

    return (
        <LeaderBoardWrapper ref={refElm}>

            <HeadingWrapper>
                <Heading><Intl langKey="leaderBoard" /></Heading>
            </HeadingWrapper>

            <TabSection>
                <TabWrapper>
                    {
                        HeadObj.map((elm) => {
                            if (!isLoggedIn && elm.id == 'MYRANK') {
                                return (<Tab key={elm.id} className='disabled'><Intl langKey={elm.label} /></Tab>)
                            }
                            return (<Tab key={elm.id} className={elm.id === activeTab ? 'active' : ''} onClick={() => setTabActive(elm.id)}><Intl langKey={elm.label} /></Tab>)
                        })
                    }
                </TabWrapper>

                {/* <PDDWrapper>
                    <select name='pagination' onChange={setPagination} value={numrows}>
                        <option value={10} selected={numrows === 10}>10</option>
                        <option value={20} selected={numrows === 20}>20</option>
                    </select>
                </PDDWrapper> */}
            </TabSection>

            <LeaderTableWrapper>
                {
                    noData ? (<NoData><Intl langKey="nodata" /> </NoData>) : (
                        <>
                            {activeTab === 'MYRANK' ? <MyRanks rankData={leaderData} brand={brandId} /> :
                                (activeTab === 'LATEST') ? <MyBets betData={leaderData} isloading={showLoader && !showPaginationLoader} brand={brandId} numrows={numrows} /> :
                                    <LeaderTable data={leaderData} selectedTab={activeTab} isloading={showLoader && !showPaginationLoader} brand={brandId} numrows={numrows} />
                            }
                        </>
                    )
                }
                {
                    showPaginationLoader && <Loader>Loading... </Loader>
                }
            </LeaderTableWrapper>
        </LeaderBoardWrapper>
    )
}

export default withAppContext(LeaderBoard);

//styled Component
const LeaderBoardWrapper = styled.div`
    border-radius: 10px;
    border: ${theme('palette.lbWrapperBorder')};
    background: ${theme('palette.lbWrapper')};
    color: ${theme('palette.lbWrapperColor')};
    padding: 15px 27px;
    margin: 0px 21px 21px;
    margin-top: -21px;
`;

const HeadingWrapper = styled.div`
    border-bottom: ${theme('palette.lbheadBorder')};
    display: flex;
    flex-direction: column;
    justify-content: center;
    flex-shrink: 0;
`;

const Heading = styled.h2`
    font-size: 28px;
    font-style: normal;
    font-weight: 700;
    text-transform: uppercase;
    letter-spacing: 0.05em;
    margin: 15px 0 10px;
`;

const TabSection = styled.div`
    display: flex;
    height: 48px;
    flex-shrink: 0;
    margin-top:21px;
    justify-content: space-between;
`;

const TabWrapper = styled.ul`
    border-radius: 5px;
    border: ${theme('palette.lbTabWrapperBorder')};
    background: ${theme('palette.lbTabWrapperBg')};
    display: flex;
    height: 100%;
    margin: 0;
    padding: 0 2px;
`;

const Tab = styled.li`
    height: 36px;
    flex-shrink: 0;
    padding: 10px 15px;
    display: flex;
    justify-content: center;
    align-items: center;
    color:${theme('palette.lbTabColor')};
    font-size: 14px;
    font-style: normal;
    font-weight: 700;
    line-height: 15px;
    margin:5px 2px 5px 3px;
    border: 1px solid transparent;
    cursor: pointer;
    &.active, &:hover {
        color: #fff;
        border: ${theme('palette.lbATabBorder')};
        background: ${theme('palette.lbATabBg')};
        border-radius: 5px;
    }
    &.disabled {
        color:${theme('palette.lbTabColor')};
        border: 1px solid transparent;
        background: none;
        cursor: auto;
    }
`;

const PDDWrapper = styled.div`
    width: 69px;
    height: 48px;
    flex-shrink: 0;
    border-radius: 5px;
    border: ${theme('palette.lbTabWrapperBorder')};
    background: ${theme('palette.lbTabWrapperBg')};
    position: relative;
    padding: 0 10px;
    select {
        width: 100%;
        font-size: .75rem;
        text-align: center;
        background: transparent;
        color: ${theme('palette.lbSelectColor')};
        height: 100%;
       border: none;
    }
`;

const NoData = styled.div`
    display: flex;
    padding: 10px;
    justify-content: center;
    color: red;
    border-radius: 5px;
    border: ${theme('palette.lbWrapperBorder')};
`;

const Loader = styled.div`
    display: flex;
    position: absolute;
    width: 100%;
    height: 100%;
    justify-content: center;
    align-items: center;
    background:${theme('palette.lbLoaderBg')};
`;

const LeaderTableWrapper = styled.div`
    position: relative;
    display: flex;
    flex-direction: column;
    padding:15px 0 10px;  
    min-height: 171px;
`;