import styled, { css } from 'styled-components';
import { ifProp, switchProp, theme, withProp } from 'styled-tools';

// Dependent Components
import { buttonStyle, inputStyle } from '../../../common/commonCSSStyles';

// Util
import { diffColor } from '../../../../util/colorUtil';
import { isMemberType } from '../../../../util/styleUtil';

// Styled Components
export const CloseButton = styled.button``;

export const StakeButton = styled.button`
	${buttonStyle};
	margin: 0.5em 0.25em;
	width: 100%;
	margin-right: 0.25em;
	background: transparent;
	color: white;
	padding: 0.5em 0.25em;
	font-size: 12px;
	margin: 0;
	border-radius: 99em;
	cursor: pointer;

	&:nth-of-type(4n) {
		margin-right: 0;
	}

`;

export const StakeButtonWrapper = styled.div`
	cursor: pointer;
	padding: 0.25em;
	width: 25%;
`;

export const StakeButtonContainer = styled.div`
	display: flex;
	flex-wrap: wrap;
	width: 100%;
`;

const BaseButton = styled.button`
	${buttonStyle};
	font-size: 16px;
	padding: 0.5em 0.25em;
`;

export const CancelButton = styled(BaseButton)`
	background-color: transparent;
	
	opacity: 0.75;
`;

export const SubmitButton = styled(BaseButton)`
	
	

	${ifProp(
		'disabled',
		css`
			cursor: not-allowed;
			pointer-events: none;
			background-image: none;
		
		`,
	)};
`;

export const StakeInput: any = styled.input.attrs(props => ({
	type: 'number',
}))`
	${inputStyle};
	font-size: 16px;
	text-align: center;
	width: auto;
`;

export const InputWrapper = styled.div`
	width: 50%;
	display: flex;

	&:not(:last-of-type) {
		margin-right: 1em;
	}

	> * {
		width: 100%;
	}
`;

export const InputSectionWrapper = styled.div`
	display: flex;
	margin-bottom: 1em;
`;

export const PLWrapper = styled.div``;

export const TypeLabelWrapper: any = styled.div`
	font-size: 11px;
	padding: 0.25em 0.5em;
	border-radius: 2px;


`;

// Wrapping slip type label & title (selection or marketName (for fancy))
export const TitleWrapper = styled.div`
	display: flex;
	align-items: center;

	${TypeLabelWrapper} {
		margin-right: 1em;
	}
`;

// Wrapping titleWrapper & plk
export const HeaderWrapper = styled.div`
	display: flex;
	justify-content: space-between;
	align-items: center;
	margin-bottom: 1em;
`;

export const ExternalWrapper = styled.div`
	width: 100%;
	margin: 1em;
	border-radius: 2px;
	padding: 1em;
	position: relative;
	z-index: 0;

	
`;

export const FieldLabel = styled.span`
	font-size: 11px;
	margin-bottom: 0.5em;
`;

export const MessageSection = styled.div`
	margin-bottom: 0.5em;
`;

export const MaxInfo = styled.div`
	width: 50%;
	text-align: center;
	padding: 0.25em;
`;

export const MaxSection = styled.div`
	display: flex;
	border-radius: 2px;
	margin-bottom: 0.5em;
`;
