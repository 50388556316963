import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import LoginSignupbg from '../../../images/sign-in_8.jpg';
// import LoginLogo from '../../../images/login_logo.png';
import HorseImage from '../../../images/login_horse.png';
// import { color } from '../../../sass-files/variable';
// Imported Components
// import Language from '../language';
import LoginSignupTab from '../login-signup-tab';
// import QuickLinks from '../quicklinks';
import config from 'src/config/config';

// import {color} from '../../sass-files/variable.js';

// const {menucolor} = color
// const { yellowfont } = color
// const { secondarycolor } = color

export default function LoginContent(props: any) {
    const { closeModal } = props;

    const [goTo, setGoTo] = useState({
        Home: false,
        Exchange: false,
        Casino: false,
        MyAccount: false,
    })

    const close = () => {
        closeModal(false)
    }

    return (
        <LoginContentWrapper className='loginModal'>
            <LeftBlock>
                {/* <Language /> */}
                <LoginSignupTab />
                {/* <QuickLinks /> */}
            </LeftBlock>
            {/* <span className="login_logo"></span>
                <span className="horse_bg"></span> */}
            <button className="close" type="button" onClick={() => close()}></button>
        </LoginContentWrapper>
    )
}

const LoginContentWrapper = styled.div`
    width: 90%;
    height: 100%;
    display: flex;
    align-items: center;
    flex-direction: row;
    background-image: url(${config.imageS3Host}${LoginSignupbg});
    background-size: 100% 100%;
    background-repeat: no-repeat;
    position: relative;


    .login_logo {
        width: 290px;
        height: 190px;
        display: flex;
        background-size: contain;
        background-repeat: no-repeat;
        position: absolute;
        right: -8px;
        top: 0px;
    }

    .horse_bg {
        width: 550px;
        height: 475px;
        display: flex;
        background-image: url(${config.imageS3Host}${HorseImage});
        background-size: cover;
        background-repeat: no-repeat;
        position: absolute;
        right: 0px;
        bottom: 0px;
    }
`;

const LeftBlock = styled.div`
    width: 50%;
    height: 100%;
    display: flex;
    align-items: flex-start;
    flex-direction: column;
    padding: 20px 20px 20px 60px;
    
    justify-content: center;
`;