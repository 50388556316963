import util from '../util/util';

export default class OddsUtil {
	/**
	 * 歐洲盤轉成美式盤(印度人用的是半美式，不會有負數的)
	 * 算式：100 * ( 歐洲賠率 -1 )
	 *
	 * @param {number} odd
	 * @returns
	 */
	public static EUtoAM = (odd: number) => {
		return util.numMultiply(100, util.numAdd(odd, -1));
	};
	/**
	 * 美式盤轉成歐洲盤（db都是存歐洲盤）
	 * 算式：1 + (美式賠率 / 100 )
	 *
	 * @param {number} odd
	 * @returns
	 */
	public static AMtoEU = (odd: number) => {
		return util.numAdd(util.numMultiply(odd, 0.01), 1);
	};
}
