import styled, { withTheme } from 'styled-components';
import Intl from 'src/components/common/Intl';
import { theme } from 'styled-tools';
import { withAppContext } from 'src/store/initAppContext';
import { useEffect, useState } from 'react';
import config from 'src/config/config';

const PslLeaderBoardTable = () => {
    const [scoreData, setScoreData] = useState([])
    let dataUrl = "https://betdaily-leaderboard.s3.eu-west-1.amazonaws.com/leaderboard.json"

    useEffect(() => {
        getScore()
    },[])

    const getScore = () => {
       fetch(dataUrl)
       .then((response) => response.json())
       .then(data =>{setScoreData(data)})
       .catch((err) => console.log(err)) 
    }


    
    return (
        <PslTableWrapper>
            <PslTittle>
                <Intl langKey="PSL@TableTittle" />
            </PslTittle>
            <table>
                <tr>
                    <th className='team'>Team</th>
                    <th>M</th> 
                    <th>W</th>
                    <th>L</th>
                    <th>NRR</th>
                    <th>PTS</th>
                </tr>
                {scoreData && scoreData.map((item) => (
                    <tr>
                        <td className='teamContent'>
                            <Number>{item.id}</Number>
                            <Logo><img src={`${config.imageV3Host}/static/media/${item.icon}.png`} alt={item.id}/></Logo>
                            <TeamName>{item.Team}</TeamName>
                        </td>
                        <td>{item.M}</td>
                        <td>{item.W}</td>
                        <td>{item.L}</td>
                        <td>{item.NRR}</td>
                        <td>{item.PTS}</td>
                    </tr>
               ))}
            </table>
        </PslTableWrapper>
    )
}

export default withTheme(withAppContext(PslLeaderBoardTable));

//Styled Component
const TeamName = styled.div`
    max-width: 150px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
`;

const Logo = styled.div`
    width: 38px;
    height: 38px;
    display: flex;
    align-items: center;
    justify-content: center;
`;

const Number = styled.div`
    font-weight: 500;
`;

const PslTittle = styled.p`
    width: 100%;
    text-align: center;
    font-weight: 700;
    font-size: 30px;
    text-transform: uppercase;
    color: ${theme('palette.pslTittleColor')};
    border-bottom: 2px solid #3C4043;
    text-align: center;
    margin: 0;
    padding: 10px;
`;

const PslTableWrapper = styled.div`
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    background: ${theme('palette.pslTableBg')};
    border: 2px solid #FAE027;
    border-radius: 25px;
    color: ${theme('palette.pslContentColor')};

    /* table, th, td{
        border-collapse: collapse;
    } */

    table {
        width: 100%;
        padding: 0 10px;
        border-collapse: collapse;
        font-size: 16px;
        tr {
            border-bottom: 2px solid #3C4043;

            :last-child {
                border: none;
            }
        }

        th, td {
            padding: 10px;
            text-align: center;
        }

        .team {
            text-align: left;
        }

        .teamContent {
            display: flex;
            align-items: center;
            gap: 8px;
            text-align: left;
        }
    }
`;
