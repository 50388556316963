import React, { useState, useContext } from "react";
import styled from "styled-components";
// import { color, bgcolor } from '../../../sass-files/variable';
import { ifProp, theme, withProp } from "styled-tools";

// Imported Components
import LoginContent from "./login-content";
import Modal from "../../Modal";
import Intl from "../../common/Intl";
import { withAppContext } from "src/store/initAppContext";
// const {loginbg} = bgcolor
// const {secondarycolor} = color

function Login() {
  const [modelState, setModelState] = useState(false);

  const showModal = () => {
    setModelState(true);
  };

  const hideModal = () => {
    setModelState(false);
  };

  const emitHideModel = (flag) => {
    setModelState(flag);
  };

  return (
    <div>
      <LoginButton>
        <button type="button" onClick={showModal}>
          <Intl langKey="LOGIN@TITLE" />
        </button>
      </LoginButton>
      <Modal show={modelState} handleClose={hideModal}>
        <LoginContent emitModel={emitHideModel} />
      </Modal>
    </div>
  );
}

export default withAppContext(Login);

const LoginButton = styled.div`
  transform: skew(340deg);
  // border: 1px solid #CB9640;
  margin-left: 20px;
  // background: ${theme("palette.primary-background-color-linear-new")};
  // box-shadow: 3px 5px 4px rgba(0, 0, 0, 0.25);
  background: #6778e3;
  border-radius: 5px;

  button {
    width: 80px;
    text-align: center;
    display: block;
    text-decoration: none;
    padding: 10px 0px;
    font-size: 14px;
    transform: skew(-340deg);
    color: ${theme("palette.logintextcolor-new")};
    text-transform: capitalize;
    background-color: transparent;
    border: none;
    cursor: pointer;
  }
`;

// refernce changes needed
