import React, { useState } from "react";
import { color } from 'src/sass-files/variable';
import {motion} from 'framer-motion'
//Components
import MemberSidebar from './member-sidebar';
import Intl from '../../components/common/Intl';
import { theme, withProp } from 'styled-tools';
import config from 'src/config/config';
import styled, { withTheme } from 'styled-components';
import { withAppContext } from "src/store/initAppContext";
//Images
import ChangePasswordIcon from "src/images/change_password.png";
import ShowPasswordIcon from "src/images/show_password.png";
import CheckIcon from "src/images/check.png";
import cookieUtil from "src/util/cookieUtil";
import routerUtil from "src/util/routerUtil";
import util from "src/util/util";
import { ECookieName } from "src/util/utilModel";
import { useMutation } from "@apollo/client";
import { CHANGE_PASSWORD } from "src/graphql/member";
import { useNavigate } from "react-router-dom";
import { result } from "lodash";
// import { InputField, SubmitButtonWrapper } from '../../components/common/formStyles';
import Bg from '../../images/iMAGE1_iexch.png'

const { secondarycolor } = color;

interface IState {
	oldPassword: string;
	newPassword: string;
	confirmPassword: string;
	errorMessage: string;
	isChanged?: boolean;
}

function ChangePassword (props: any, IState) {

    let navigate = useNavigate();
    const [oldPassword, setOldPassword] = useState('')
    const [newPassword, setNewPassword] = useState("")
    const [confirmPassword, setConfirmPassword] = useState('')
    const [errorMessage, setErrorMessage] = useState('')
    const [isError, setIsError] = useState('');
    const [successMessage, setSuccessMessage] = useState('');
    const [isChanged, setIsChanged] = useState(true)
    const [changePasswordMutation, { loading, error, data }] = useMutation(CHANGE_PASSWORD);
    const [passwordShown, setPasswordShown] = useState(false);
    const [isLoading, setIsLoading] = useState(false)


    const togglePasswordVisiblity = () => {
        setPasswordShown(passwordShown ? false : true);
      };

    const onInputChange = (type: string) => ({ target }: any) => {
		setIsChanged((prevState) : any =>
            ({ [type]: target.value.trim(), setIsChanged:false, setErrorMessage: ''}))
	};   


    const onSubmit = async (e:any) => {
        // debugger
        setIsError('')
        setIsLoading(true)
		if (!oldPassword || !newPassword || !confirmPassword) {
			return;
		}
		if (newPassword !== confirmPassword) {
			return;
		}
		if (newPassword.length < 8 || newPassword.length > 20) {
			return ;
		}
		// setErrorMessage("Please enter correct password")
		try {
            const resetpassword: any = await  changePasswordMutation({
				variables: {
					input: {
						oldPassword,
						newPassword,
					},
				},
			});
			const result = util.getValue(resetpassword, ['data', 'member', 'password'], {});
     

            if(result.success===true){
                cookieUtil.remove(ECookieName.COOKIE_IS_DEFAULT_PASSWORD);
                setSuccessMessage(result.message='Your Password has Successfully Changed')
              setTimeout(() => {
                navigate('/')
            }, 5000);  
            }else{
                setIsLoading(false)
                return setIsError(result.message="Failed to update password !");
            }
		} catch (err) {
            setIsLoading(false)
			console.error('ChangePasswordButton Error: ', err);
			setErrorMessage('Change password fail!');
		}
	};

			
        
        return (
            <motion.div 
            initial={{opacity: 0}}
            animate={{opacity: 1}}
            exit={{opacity: 0}}
            >
            <StatementWrapper>
                <MemberSidebar />
                <ChangePasswordWrapper theme={props.app.theme} customStyle={props.theme.image}>
                    <HeaderBlock>
                        <Tittle theme={props.app.theme} customStyle={props.theme.palette}>
                            <TittleIcon></TittleIcon>
                            <Intl langKey="CHANGE_PASSWORD@SUBMIT" />
                        </Tittle>
                    </HeaderBlock>

                    <PasswordBodyWrapper>
                    {
                            <>
                             <div style={{display:"flex", justifyContent:"center", width:"100%", alignItems: "center", color:"red", marginBottom: "20px"}}>{isError}</div>
                             <div style={{display:"flex", justifyContent:"center", width:"100%", alignItems: "center", color:"green", marginBottom: "20px"}}>{successMessage}</div>


                                <InputWrap>
                                <Intl langKey="CHANGE_PASSWORD@OLD_PASSWORD@LABEL">
                                                {(message: string) => {
                                                    return (
                                                        <><InputField type="password" value={oldPassword} onChange={e => setOldPassword(e.target.value.split(" ").join(""))} placeholder={message}></InputField><Errormsg>{(oldPassword !== oldPassword) && errorMessage}</Errormsg></>
                                                    );
                                                }}
                                                </Intl>
                                </InputWrap>

                                <InputWrap>
                                <Intl langKey="CHANGE_PASSWORD@NEW_PASSWORD@LABEL">
                                                {(message: string) => {
                                                    return (
                                                        <>
                                                        <InputField type={passwordShown ? "text" : "password"} value={newPassword} onChange={e => setNewPassword(e.target.value.split(" ").join(""))} placeholder={message}></InputField>
                                                        <ShowPassword >
                                                         <img src={ShowPasswordIcon} onClick={togglePasswordVisiblity} alt="" />
                                                         </ShowPassword>
                                                        <Minmsg><Intl langKey="CHANGE_PASSWORD@MINIMUM"/></Minmsg>
                                                        <Successrmsg>{errorMessage}</Successrmsg> 
                                                        </>
                                                    );
                                                }}
                                                </Intl>
                                </InputWrap>

                                <InputWrap>
                                    {/* <SuccessTik>
                                        <img src={CheckIcon}/>
                                    </SuccessTik> */}
                                    <Intl langKey="CHANGE_PASSWORD@CONFIRM_PASSWORD@LABEL">
                                                {(message: string) => {
                                                    return (
                                                        <><InputField type="password" value={confirmPassword} onChange={e => setConfirmPassword(e.target.value.split(" ").join(""))} placeholder={message}></InputField></>
                                                    );
                                                }}
                                                </Intl>
                                    <Minmsg><Intl langKey="CHANGE_PASSWORD@MINIMUM"/></Minmsg>
                                </InputWrap>
                                <ButtonWrapper>
                                    <SubmitButton className="submitButton" onClick={onSubmit} disabled={newPassword !== confirmPassword || newPassword.length < 8 || newPassword.length > 20 || isLoading}><Intl langKey="STATEMENT@STATUS@CONFIRM" /></SubmitButton>
                                </ButtonWrapper>                             
                            </>
                        }
                        
                    </PasswordBodyWrapper>
                </ChangePasswordWrapper>
            </StatementWrapper>
            </motion.div>
        );
}

export default withTheme(withAppContext(ChangePassword));

const SubmitButton = styled.button`
    display: flex;
    font-size: 15px;
    color: ${theme('palette.new_whitelable_dark_font_color')};
    align-items: center;
    justify-content: flex-start;
    padding: 10px 30px;
    // background-color: #786B57;
    background: ${theme('palette.changePaswrdConfrmEnable')};
    border-radius: 5px;
    cursor: pointer;
    border: ${theme('palette.changePaswrdConfrmBtnBordr')};
    :hover {
        color: ${theme('palette.new_whitelable_dark_font_color')};
    }

 :disabled{
    cursor: none;
    pointer-events: none;
    color: ${theme('palette.disabled-button-font-color')};
    // background-color: #ffffff;
    background: ${theme('palette.disabled-button-background')};
 }

`;

const ButtonWrapper = styled.div`
    // cursor: pointer;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    padding-top: 5px;
`;

const SuccessTik = styled.div`
    display: flex;
    align-items: center;
    font-size: 10px;
    color: #8c745aa6;
    align-items: center;
    position: absolute;
    bottom: 35%;
    right: 20px;
`;

const ShowPassword = styled.div`
    cursor: pointer;
    display: flex;
    align-items: center;
    font-size: 10px;
    color: #8c745aa6;
    align-items: center;
    position: absolute;
    bottom: 35%;
    right: 20px;
`;

const Minmsg = styled.div`
    display: flex;
    align-items: center;
    font-size: 10px;
    color: #8c745aa6;
    align-items: center;
    color: rgba(143, 152, 172, 0.88);
    font-size: 10px;
    
    display: none;
    position: absolute;
    bottom: -12px;
    left: 0;
`;

const InputWrap = styled.div`
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    margin-bottom: 15px;
    flex-direction: column;
    position: relative;

    :hover {
        ${Minmsg} {
            display: block;
        }
    }

    // .new_password {
    //     background-image: url(${ShowPasswordIcon});
    //     background-size: auto;
    //     background-repeat: no-repeat;
    //     background-position: 95% center;
    // }
`;

const Successrmsg = styled.div`
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    
    font-size: 10px;
    color: #03BC58;
    position: absolute;
    bottom: -12px;
    right: 0;
`;

const Errormsg = styled.div`
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    
    font-size: 10px;
    color: #ED1F1F;
    position: absolute;
    bottom: -12px;
    right: 0;
`;

const InputField = styled.input`
    width: 100%;
    display: flex;
    color: #8c745aa6;
    align-items: center;
    background: #FFFFFF;
    // border: 1px solid #D0B99F;
    BORDER:1PX SOLID #C6CFE2;
    border-radius: 5px;
    padding: 15px;
    font-size: 14px;
    
    margin-bottom: 1px;

    ::placeholder {
        // color: rgba(208, 185, 159, 0.65);
        color:#dfe5f2;
        opacity: 1; /* Firefox */
      }
      
      :-ms-input-placeholder { /* Internet Explorer 10-11 */
       color: rgba(208, 185, 159, 0.65);;
      }
      
      ::-ms-input-placeholder { /* Microsoft Edge */
       color: rgba(208, 185, 159, 0.65);;
      }
`;

const PasswordBodyWrapper = styled.div`
    width: 40%;
    display: flex;
    flex-direction: column;
    padding: 2em;
    // background: linear-gradient(180deg, rgba(253, 242, 229, 0.85) -22.78%, rgba(253, 242, 229, 0.85) 42.15%, rgba(229, 212, 185, 0.85) 118.89%);
    background: ${theme('palette.changePaswrdCardBg')};
    border-radius: 10px;
`;

const TittleIcon = styled.div`
    width: 20px;
    height: 20px;
    display: flex;
    flex-direction: row;
    align-items: center;
    background-image: url(${ChangePasswordIcon});
    background-size: auto;
    background-repeat: no-repeat;
    margin-right: 5px;
`;

const Tittle:any = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    color: ${(props:any) => (props.theme === 'Dark' ? props.customStyle['new_whitelable_dark_font_color'] : props.customStyle['secondary-background-color-new'])};    
    font-weight: 500;
    font-size: 15px;
    line-height: 18px;
    text-transform: capitalize;
    padding-bottom: 10px;
`;

const HeaderBlock = styled.div`
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    padding-bottom: 10px;
`;

const StatementWrapper = styled.div`
    width: 100%;
    display: flex;
    
`;

const ChangePasswordWrapper:any = styled.div`
    width: calc(100% - 250px);
    display: flex;
    padding: 10px;
    background: #fff;
    align-items: flex-start;
    justify-content: flex-start;
    flex-direction: column;
    padding-top: 20px;
    background-image: url('${(props:any) => (props.theme === 'Dark' ? `${config.imageS3Host}/static/snk/${props.customStyle['new_whitelable_dark_background']}` : `${config.imageS3Host}/static/snk/${props.customStyle['new_whitelable_light_background']}`)}');
    background-size: cover;
    background-position: 100%;
`;