import React from 'react'

type Props = {}

const Promo = (props: Props) => {
  return (
    <svg width="44" height="44" viewBox="0 0 44 44" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M18.9393 6.97188C16.6513 13.4824 12.5938 17.3784 10.8511 18.5126C11.2978 18.8007 11.7156 19.4688 11.8687 19.7669C12.2595 20.8368 13.1411 23.2572 13.5409 24.3797C13.9406 25.5021 14.0511 26.525 14.0563 26.8962C18.9093 26.62 25.0215 29.2142 27.471 30.5458L18.9393 6.97188Z" fill="white"/>
    <path d="M29.9611 19.2423L28.0948 14.1507C29.5093 14.1507 30.3322 15.2967 30.5669 15.8697C31.1431 17.4807 30.4031 18.7893 29.9611 19.2423Z" fill="white"/>
    <path d="M19.5287 4.85767L29.15 31.1C30.4931 29.8028 31.6735 29.118 32.0958 28.9378L22.7178 3.34416C22.4385 2.9839 21.6205 2.35527 20.5827 2.72283C19.5449 3.09038 19.4476 4.29921 19.5287 4.85767Z" fill="white"/>
    <rect x="29.917" y="12.0117" width="11.2999" height="1.46857" rx="0.734283" transform="rotate(-76.2301 29.917 12.0117)" fill="white"/>
    <rect x="31.876" y="13.4141" width="7.68492" height="1.46857" rx="0.734283" transform="rotate(-30.1316 31.876 13.4141)" fill="white"/>
    <rect x="32.8589" y="15.1953" width="7.68492" height="1.46857" rx="0.734283" transform="rotate(-3.81893 32.8589 15.1953)" fill="white"/>
    <rect x="33.0894" y="18.0039" width="11.284" height="1.46857" rx="0.734283" transform="rotate(42.2255 33.0894 18.0039)" fill="white"/>
    <path fill-rule="evenodd" clip-rule="evenodd" d="M3.18581 20.8339C2.08308 21.2259 1.50688 22.4375 1.89883 23.5403L3.78781 28.8549C4.17976 29.9576 5.39142 30.5338 6.49415 30.1419L11.3849 28.4035C12.4876 28.0116 13.0638 26.7999 12.6718 25.6972L10.7829 20.3826C10.3909 19.2799 9.17925 18.7037 8.07653 19.0956L3.18581 20.8339ZM3.73538 22.8555C3.37154 22.9867 3.18298 23.3881 3.31422 23.7519C3.44546 24.1158 3.8468 24.3043 4.21064 24.1731L9.25683 22.3529C9.62067 22.2216 9.80923 21.8203 9.67799 21.4565C9.54675 21.0926 9.14541 20.9041 8.78157 21.0353L3.73538 22.8555ZM4.11512 25.7542C3.98388 25.3903 4.17244 24.989 4.53627 24.8577L9.58247 23.0375C9.9463 22.9063 10.3476 23.0949 10.4789 23.4587C10.6101 23.8225 10.4216 24.2239 10.0577 24.3551L5.01153 26.1753C4.64769 26.3066 4.24635 26.118 4.11512 25.7542ZM5.29289 26.8455C4.92905 26.9768 4.74049 27.3781 4.87173 27.742C5.00297 28.1058 5.40431 28.2943 5.76815 28.1631L10.8143 26.3429C11.1782 26.2117 11.3667 25.8103 11.2355 25.4465C11.1043 25.0827 10.7029 24.8941 10.3391 25.0253L5.29289 26.8455Z" fill="white"/>
    <path fill-rule="evenodd" clip-rule="evenodd" d="M35.43 43.2634C39.2048 43.2634 42.2649 40.2034 42.2649 36.4286C42.2649 32.6538 39.2048 29.5938 35.43 29.5938C31.6553 29.5938 28.5952 32.6538 28.5952 36.4286C28.5952 40.2034 31.6553 43.2634 35.43 43.2634ZM37.8454 35.5217L38.1241 34.4605H37.0408C36.9914 34.1571 36.8932 33.8848 36.746 33.6436C36.7367 33.6281 36.7272 33.6129 36.7175 33.5977H37.8454L38.1317 32.5212H34.1464H33.9174H33.0394L32.7264 33.8611H34.1464C34.6096 33.8611 34.9417 33.9731 35.1427 34.1971C35.2158 34.2765 35.2759 34.3643 35.3231 34.4605H33.0012L32.7264 35.5217H35.3734C35.3537 35.5771 35.3302 35.6306 35.3031 35.682C35.2064 35.8653 35.0613 36.0103 34.8679 36.1172C34.6745 36.2241 34.434 36.2775 34.1464 36.2775H32.8561L32.8523 37.2319L35.4634 40.3392H37.2614V40.2705L34.9252 37.4113L34.9862 37.3884C35.4774 37.2815 35.8757 37.1186 36.1811 36.8998C36.489 36.6809 36.7142 36.4073 36.8567 36.079C36.9321 35.9084 36.9883 35.7226 37.0252 35.5217H37.8454Z" fill="#6778E3"/>
    <path d="M38.1248 34.4607L37.8461 35.5219H32.7271L33.0019 34.4607H38.1248ZM35.4641 40.3394L32.853 37.2321L32.8568 36.2778H34.1471C34.4347 36.2778 34.6752 36.2243 34.8686 36.1174C35.062 36.0106 35.2071 35.8655 35.3038 35.6823C35.4005 35.499 35.4501 35.2903 35.4527 35.0562C35.4501 34.705 35.347 34.4187 35.1434 34.1973C34.9424 33.9734 34.6103 33.8614 34.1471 33.8614H32.7271L33.0401 32.5215H34.1471C34.7961 32.5215 35.3369 32.6195 35.7695 32.8154C36.2047 33.0088 36.5304 33.285 36.7467 33.6438C36.9656 34.0026 37.0763 34.4302 37.0788 34.9264C37.0763 35.3667 37.0025 35.751 36.8574 36.0793C36.7149 36.4076 36.4897 36.6811 36.1818 36.9C35.8764 37.1189 35.4781 37.2817 34.9869 37.3886L34.9259 37.4115L37.2621 40.2707V40.3394H35.4641ZM38.1324 32.5215L37.8461 33.598H33.6318L33.9181 32.5215H38.1324Z" fill="white"/>
    <path d="M27.6291 34.1239C27.7046 33.3567 28.4997 32.1259 28.8878 31.6064H20.7073C20.2543 31.6064 20.1412 32.0681 20.1413 32.299C20.1466 32.9438 20.1539 34.3531 20.1413 34.8312C20.1288 35.3093 20.6188 35.4394 20.8654 35.4446H21.8252C22.102 34.5764 23.1256 34.2024 23.6028 34.1239H27.6291Z" fill="white"/>
    <path d="M27.4074 38.0083C27.2301 37.5805 27.2601 36.1209 27.2973 35.4446H23.7585C23.2804 35.4446 23.0455 35.9479 22.9878 36.1995V38.0083H27.4074Z" fill="white"/>
    <path d="M30.9148 43.2614C29.4804 42.4184 28.2725 40.3308 27.8478 39.3923H20.8654C20.2496 39.3923 20.1261 39.8432 20.1413 40.0687V42.5851C20.1413 43.2457 20.8654 43.3401 20.8654 43.2614C20.8654 43.1985 27.565 43.2352 30.9148 43.2614Z" fill="white"/>
    <path d="M8.23127 32.8401C10.0887 33.5183 11.1492 31.0835 11.4472 29.7813C12.6627 29.4765 13.4267 28.4342 13.6567 27.9512L15.0634 29.4915C16.7067 31.7779 16.2989 34.1209 15.8895 35.0066C15.5472 35.7732 14.7106 37.4135 14.1033 37.8423C13.4959 38.271 12.9869 39.3457 12.8082 39.8295L12.3393 42.1963H5.84206L7.4271 38.1995V36.7928C7.38245 36.5919 7.14132 35.9533 6.53399 35.0066C5.92666 34.0598 5.81965 32.3793 5.84206 31.6573L6.91431 31.434C7.50377 32.5236 8.03789 32.8254 8.23127 32.8401Z" fill="white"/>
    <path d="M9.85873 30.3848L8.22119 30.996L8.46868 31.4509C9.01963 31.8771 9.30464 31.5418 9.37827 31.3209L9.85873 30.3848Z" fill="white"/>
    <path d="M15.3945 38.3252L16.6035 36.5439C17.1895 37.1801 17.4079 38.2338 16.6035 39.4552C15.96 40.4324 14.7063 40.5554 14.16 40.4948C14.0456 39.8087 14.9353 38.7625 15.3945 38.3252Z" fill="white"/>
    <rect x="5.53174" y="4.43945" width="7.17236" height="7.04421" fill="white"/>
    <path d="M15.8716 7.7007L14.6893 6.41008L15.1959 4.73449L13.5693 4.08697L13.2987 2.35727L11.5522 2.4769L10.5578 1.03613L9.0371 1.90443L7.51638 1.03613L6.52199 2.4769L4.77557 2.35724L4.50494 4.08692L2.87829 4.73443L3.38492 6.41002L2.20264 7.7007L3.38492 8.99132L2.87829 10.6669L4.50494 11.3144L4.77557 13.0441L6.52199 12.9245L7.51638 14.3652L9.0371 13.497L10.5578 14.3652L11.5522 12.9244L13.2986 13.0441L13.5692 11.3144L15.1959 10.6669L14.6893 8.99132L15.8716 7.7007ZM7.59781 5.55363C8.09015 5.55363 8.48925 5.95276 8.48925 6.44508C8.48925 6.93743 8.09015 7.33652 7.59781 7.33652C7.10548 7.33652 6.70636 6.9374 6.70636 6.44508C6.70634 5.95276 7.10543 5.55363 7.59781 5.55363ZM7.60104 10.1894L6.90736 9.62951C8.2999 7.90436 9.08063 6.93714 10.4732 5.21199L11.1668 5.77191L9.38394 7.98067L7.60104 10.1894ZM10.4764 10.1145C9.98405 10.1145 9.58495 9.71542 9.58495 9.2231C9.58495 8.73075 9.98405 8.33166 10.4764 8.33166C10.9687 8.33166 11.3678 8.73075 11.3678 9.2231C11.3679 9.71539 10.9687 10.1145 10.4764 10.1145Z" fill="#6778E3"/>
    </svg>
    
  )
}

export default Promo