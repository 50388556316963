import React, { useEffect } from 'react'
import { useNavigate } from 'react-router-dom';
import config from "src/config/config";
import { withAppContext } from 'src/store/initAppContext'
import styled, { withTheme } from 'styled-components'
import { theme } from 'styled-tools';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import PlaceholderImage from 'src/components/Placeholder';
import Footer from 'src/components/footer/footer';

const EvolutionCasinoTourneyBazzi = (props) => {
    const {
        app: { brandId },
        theme,
    } = props;

    const navigate = useNavigate();

    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    const domain = brandId == 31 || brandId == 32 ? "Yolo247" : "BaaziAdda";

    return (
        <>
            <MainWrapper>
                <ContentWrapper>
                    <TitleWrapper>
                        <p>This T20 world cup, experience the joy of casino games!  <span className='boldRed'>{domain}</span> brings you an action-packed Live casino promotion in association with Evolution!</p>
                        <p>We present the bonus of the season with our casino games! Get your chips & place your bets!</p>
                    </TitleWrapper>
                    <Divider></Divider>
                    <ExpWrapper>
                        <h1>Experience Excitement with Evolution!</h1>
                        <BoxContainer>
                            <BoxContent>
                                <LazyLoadImage src={`${config.imageV3Host}/static/cashsite/${theme.image.EvolutionCasinoTourneryBazziEvlImgOne}`} effect="none" placeholder={<PlaceholderImage />} />
                                <h1>Register</h1>
                                <p>Open the door to endless thrills by creating your very own BaaziAdda account.</p>
                            </BoxContent>
                            <BoxContent>
                                <LazyLoadImage src={`${config.imageV3Host}/static/cashsite/${theme.image.EvolutionCasinoTourneryBazziEvlImgTwo}`} effect="none" placeholder={<PlaceholderImage />} />
                                <h1>Deposit</h1>
                                <p>Deposit in your account is loaded and ready for action throughout  T20 WC.</p>
                            </BoxContent>
                            <BoxContent>
                                <LazyLoadImage src={`${config.imageV3Host}/static/cashsite/${theme.image.EvolutionCasinoTourneryBazziEvlImgThree}`} effect="none" placeholder={<PlaceholderImage />} />
                                <h1>Win Big</h1>
                                <p>With each game you play, you're one step closer to achieving greatness. Showcase your skills, dominate the leader board, and claim your share of the incredible prize pool.</p>
                            </BoxContent>
                        </BoxContainer>
                    </ExpWrapper>
                    <EvolutionWrapper>
                        <EvoluLeft>
                            <h1>Evolution Casino Tourney!</h1>
                            <UnderLine></UnderLine>
                            <p>Play Lightning Dragon Tiger, Dragon Tiger and Red door Roulette and Win  additional bonuses and free bets. Total prizes are worth INR 18 lakhs!</p>
                            <p>The tournament is valid from <span className='boldRed'>2nd June to 29th June 2024!</span></p>
                            <p>This promotion is only applicable for Qualified games : please check the Promotion Schedule tables.</p>
                        </EvoluLeft>
                        <EvoluRight>
                            <LazyLoadImage src={`${config.imageV3Host}/static/cashsite/${theme.image.EvolutionCasinoTourneryBazziEvlPlayer}`} effect="none" placeholder={<PlaceholderImage />} />
                        </EvoluRight>
                    </EvolutionWrapper>
                    <GameWrapper>
                        <GameBox>
                            <h1>Red Door roulette</h1>
                            <UnderLine></UnderLine>
                            <TextContainer>
                                <TextWrapper>
                                    <span>✪</span>
                                    <p>Qualified game: Red Door Roulette</p>
                                </TextWrapper>
                                <TextWrapper>
                                    <span>✪</span>
                                    <p>Players who win on any of the numbers: <br></br> 0-01-02-03-22-23-24-31-32-33 will receive an additional prize of 100 INR.</p>
                                </TextWrapper>
                                <TextWrapper>
                                    <span>✪</span>
                                    <p>The minimum combined bet amount on numbers is 300 INR.</p>
                                </TextWrapper>
                                <TextWrapper>
                                    <span>✪</span>
                                    <p>Each player can win this promotional prize once a day.</p>
                                </TextWrapper>
                                <TextWrapper>
                                    <span>✪</span>
                                    <p>Evolution reserves the right to modify, suspend, or terminate the promotion at any time and without prior notice.</p>
                                </TextWrapper>
                            </TextContainer>
                        </GameBox>
                        <GameBoxRight>
                            <h1>Dragon Tiger & Lightning Dragon tiger</h1>
                            <UnderLine></UnderLine>
                            <TextContainer>
                                <TextWrapper>
                                    <span>✪</span>
                                    <p>Qualified game: Dragon Tiger, Lightning Dragon Tiger</p>
                                </TextWrapper>
                                <TextWrapper>
                                    <span>✪</span>
                                    <p>Player will randomly get the bonus of INR 100.</p>
                                </TextWrapper>
                                <TextWrapper>
                                    <span>✪</span>
                                    <p> There’s no minimum wager requirement.</p>
                                </TextWrapper>
                                <TextWrapper>
                                    <span>✪</span>
                                    <p>Each player can win more than 5 times Lucky Drop in one days.</p>
                                </TextWrapper>
                                <TextWrapper>
                                    <span>✪</span>
                                    <p>Evolution reserves the right to modify, suspend, or terminate the promotion at any time and without prior notice.</p>
                                </TextWrapper>
                            </TextContainer>
                        </GameBoxRight>
                    </GameWrapper>
                    <Divider></Divider>
                    <TermsAndCondition>
                        <h4>Terms & Conditions</h4>
                        <ul>
                            <li>This promotion is valid for all players.</li>
                            <li>The promotion is valid from <span className='boldWhite'>2nd June to 29th June 2024</span>.</li>
                            <li>The winners will be chosen based on highest bet amount.</li>
                            <li>No Minimum bet amount required to enter this contest</li>
                            <li>No Minimum odds to be maintained</li>
                            <li>Void/Cancelled/Draw bets, Cashed-Out bets do not count towards the deposit or bonus rollover requirements.</li>
                            <li>Multiple accounts will not be considered for this promotion eligibility / criteria. All players will be subject for duplicate checking. <span className='boldWhite'>{domain}</span> reserves the right to disqualify players if collusion or any foul play takes place in the promotion.</li>
                            <li><span className='boldWhite'>{domain}</span> reserves the right to amend, cancel and reclaim or refuse any promotion at its own discretions.</li>
                            <li><span className='boldWhite'>{domain}</span> General Terms & Conditions apply.</li>
                        </ul>
                    </TermsAndCondition>
                </ContentWrapper>
            </MainWrapper>
            <Footer />
        </>
    )
}

export default withTheme(withAppContext(EvolutionCasinoTourneyBazzi));

//Styled Components

const MainWrapper = styled.div`
    width: 98%;
    height: auto;
    border: 1px solid white;
    border-top: 0;
    display: flex;
    border-radius: 1px;
    background: rgba(111, 111, 111, 0.5);
    margin: 0em 1.2em;
    -webkit-backdrop-filter: brightness(0.5);
    backdrop-filter: brightness(0.5);
`;

const ContentWrapper = styled.div`
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background: url(${config.imageV3Host}/static/cashsite/${theme("image.EvolutionCasinoTourneryBazziBg")});
    background-position: top;
    background-size: cover;
    background-repeat: no-repeat;
    padding: 2rem;

    .lazy-load-image-background {
      display: flex !important;
      justify-content: center !important;
    }

    .secondTitle {
        padding-top: 2rem;
    }
`;

const TitleWrapper = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 73%;
    padding: 2rem 0 4rem 0;

    p {
        color: #FFF;
        text-align: center;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
        text-transform: capitalize;
    }
    
    .boldRed {
        color: #F91212;
        font-size: 14px;
        font-style: normal;
        font-weight: 700;
        line-height: normal;
        text-transform: capitalize;
    }
`

const Divider = styled.div`
    height: 2px;
    width: 100%;
    background: radial-gradient(#F91212 0%, #00000000 50%);
`;

const UnderLine = styled.div`
    height: 2px;
    width: 154px;
    background: radial-gradient(rgb(255, 255, 255) 0%, rgba(153, 153, 153, 0) 80%);
`;

const ExpWrapper = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 5rem;
    background: radial-gradient(53.7% 45.18% at 50% 0%, rgba(249, 18, 18, 0.12) 0%, rgba(2, 13, 2, 0.00) 100%);
    padding: 1.5rem 0 4rem 0;

    h1 {
        color: #F91212;
        text-align: center;
        font-size: 20px;
        font-style: normal;
        font-weight: 700;
        line-height: normal;
        text-transform: capitalize;
    }
`;

const BoxContainer = styled.div`
    display: flex;
    justify-content: center;
    align-items: stretch;
    gap: 2.5rem;
    position: relative;
`;

const BoxContent = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    border-radius: 10px;
    border: 0.5px solid #F91212;
    background: radial-gradient(circle at top, rgba(255, 0, 0, 0.30) 0%, rgba(0, 0, 0, 0.00) 70%), rgba(0, 0, 0, 0.50);
    box-shadow: 0px 0px 10px 0px rgba(255, 0, 0, 0.25);
    width: 25%;
    padding: 6.5rem 1.5rem 1.5rem 1.5rem;


    h1 {
        color: #F00;
        text-align: center;
        font-size: 18px;
        font-style: normal;
        font-weight: 700;
        line-height: normal;
        text-transform: capitalize;
    }

    p {
        color: #FFF;
        text-align: center;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
        text-transform: capitalize;
    }

    img {
        width: 9.25rem;
        position: absolute;
        top: -4rem;
    }
`;

const EvolutionWrapper = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    background: url(${config.imageV3Host}/static/cashsite/${theme("image.EvolutionCasinoTourneryBazziEvlBackground")});
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
    margin: 0 -2rem;
    padding: 2rem 0 5rem 0;
`;

const EvoluLeft = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    gap: 1.5rem;
    width: 47%;
    padding-left: 10rem;

    h1 {
        margin: 0;
        color: #F00;
        font-size: 20px;
        font-style: normal;
        font-weight: 600;
        line-height: normal;
    }

    p {
        margin: 0;
        color: #FFF;
        font-size: 14px;
        font-style: normal;
        font-weight: 500;
        line-height: 150%; /* 21px */
    }

    .boldRed {
        color: #F91212;
        font-weight: 700;
        line-height: 180%;
    }
`;

const EvoluRight = styled.div`
    display: flex;
    width: 50%;

    img {
        width: 50%;
    }
`;

const GameWrapper = styled.div`
    display: flex;
    justify-content: center;
    align-items: stretch;
    gap: 2.5rem;
    width: 100%;
    padding-bottom: 5rem;
`;

const GameBox = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    padding: 20rem 1.5rem 1.5rem 1.5rem;
    border: 1px solid #850807;
    border-radius: 10px;
    width: 33%;
    background: url(${config.imageV3Host}/static/cashsite/${theme("image.EvolutionCasinoTourneryBazziGameOne")});
    background-position: top;
    background-size: cover;
    background-repeat: no-repeat;
    gap: 1.5rem;

    h1 {
        margin: 0;
        color: #F91212;
        font-size: 20px;
        font-style: normal;
        font-weight: 700;
        line-height: normal;
        text-transform: capitalize;
    }
`;

const GameBoxRight = styled(GameBox)`
    background: url(${config.imageV3Host}/static/cashsite/${theme("image.EvolutionCasinoTourneryBazziGameTwo")});
    background-position: top;
    background-size: cover;
    background-repeat: no-repeat;
`;

const TextContainer = styled.div`
    display: flex;
    flex-direction: column;
    gap: 0.3rem;
`;

const TextWrapper = styled.div`
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;

    p {
        margin: 0;
        color: #FFF;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
        text-transform: capitalize;
        padding-left: 0.3rem;
    }

    span {
        color: #F91212;
    }
`;

const TermsAndCondition = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 2rem;
    background: radial-gradient(53.7% 45.18% at 50% 0%, rgba(249, 18, 18, 0.12) 0%, rgba(2, 13, 2, 0.00) 100%);
    width: 65%;

    & li {
        color: #FFF;
        text-align: justify;
        font-size: 18px;
        font-style: normal;
        font-weight: 400;
        line-height: 21px; /* 116.667% */
        text-transform: capitalize;
        list-style-type: decimal;
        padding-bottom: 1.5rem;

        .boldWhite {
            color: #FFF;
            font-size: 18px;
            font-style: normal;
            font-weight: 700;
            line-height: 21px;
            text-transform: capitalize;
        }
    }

    & h4 {
        color: #F91212;
        text-align: center;
        font-size: 28px;
        font-style: normal;
        font-weight: 700;
        line-height: normal;
        text-transform: capitalize;
    }
`;