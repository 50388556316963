import React from 'react'
import { Accordion, AccordionWrapper } from '../seo-content/seo_content'
import styled, { withTheme } from 'styled-components'
import { withAppContext } from 'src/store/initAppContext'
import { Link } from 'react-router-dom'

type Props = {
    app: any
}

const Funkytime88 = (props: Props) => {
    const { app: { brandId } } = props;
    return (
        <AccordionWrapper style={{ width: '100%' }}>
            <Accordion>
                <input type="checkbox" id="section1" className="accordion__input" />
                <label htmlFor="section1" className="accordion__label">
                    <h1>Funky Time Casino Game by Evolution Gaming: Play and Bet Now

                    </h1></label>
                <div className="accordion__content">

                    <div>
                        <p>Funky Time is a disco-theme <Link to={'/live-casino'}>live casino game</Link> from Evolution Gaming. This electrifying experience returns you to the golden age of bell-bottoms and platform shoes, offering a chance to win big with a vibrant atmosphere, funky music, and a thrilling real money wheel. Funky Time is sure to become your new favourite if you're a fan of live dealer casino games with a twist.</p>
                        <p>Discover Funky Time Evolution Game how to play, features, bonus games, and more at FUN88!.</p>
                        <h2>What Is Funky Time Live Casino Game?</h2>

                        <p>Funky Time is a <strong><Link to="/live-casino">live casino game</Link></strong> show that combines the exhilaration that comes with a money wheel with the groovy atmosphere of a 70s disco. Played around a &lsquo;DigiWheel,&rsquo; Funky Time&rsquo;s 25 or so segments on the wheel contain all kinds of instant wins, bonus games, and opportunities to coin some rather sizeable multiplier wins.&nbsp;</p>
                        <p>In many ways, you could call Funky Time a weird hybrid between a classic <strong>casino game</strong> like <strong><Link to={'/live-casino/live-roulette'}> live-roulette game</Link></strong> and the more interactive aspects of game shows, along with their many varied bonus features.</p>
                        <p>Here are some key features that set Funky Time apart:</p>
                        <ul>
                            <li><strong>Disco Vibes &mdash; </strong>complete immersion into the world of 70s disco with funky music, groovy animations, and colourful set design. You will be entertained and energized throughout the game, as the atmosphere is simply contagious.</li>
                            <li><strong>DigiWheel &mdash; </strong>the game's main feature is the giant digital wheel with quite a few different segments. Place your bets on the numbers, letters, or specific bonus games, and hope the wheel will stop exactly where you want.</li>
                            <li><strong>Random Multipliers &mdash; </strong>an additional element of the thrill is that the DigiWheel dynamically generates random multipliers for each segment before each spin. Furthermore, the huge multipliers may significantly increase your winnings if the wheel stops where you bet.</li>
                            <li><strong>Bonus Games &mdash; </strong>Funky Time takes gambling to a new level with special and unique bonus games where you can win even more due to the specific features and other multipliers hidden behind the animated symbols.</li>
                        </ul>
                        <p><Link to={'/promotions'}>Get Up To Rs2500 Bonus on Deposit</Link></p>
                        <h2>How to Play Funky Time Game?</h2>

                        <p>Funky Time is a straightforward game featuring a presenter akin to a TV show host, but in a live casino game setting. The game's rules are kept simple to cater to both seasoned players and newcomers to the casino scene.</p>
                        <p>It revolves around a spinning wheel with 64 different sections. Players simply wager on the section they believe is the most favorable, such as numbers, letters, and bonus segments with varying multipliers.</p>
                        <p>Getting started is a breeze:</p>
                        <ul>
                            <li>Visit a casino website offering Funky Time.</li>
                            <li>Locate the wheel and place your bet. Your game kicks off after selecting from the betting options: numbers, letters, or a bonus symbol.</li>
                            <li>Then, place your bet on the chosen segment. Unlike <strong><Link to={'/live-casino/evolution/crazy-time'}>crazy time</Link></strong>, Funky Time offers 17 betting opportunities, potentially leading to substantial profits!</li>
                            <li>Once all bets are in, the live dealer spins the wheel. If it lands on your chosen sector, you win!</li>
                            <li>That's essentially all there is to it. It's as straightforward as that!</li>
                        </ul>
                        <p>The unique disco ambiance of Funky Time sets it apart from other casino games. With live dealers and groovy disco tunes, you'll find it easy to play, bet, have a blast, and even win! Excited? Get ready to groove with Funky Time!</p>
                        <h2>Funky Time Rules, Features &amp; Gameplay</h2>
                        <p>Funky Time game by <strong><Link to={'/live-casino/evolution'}>Evolution Gaming</Link></strong> is a popular live casino game. It is based on a 1970s disco theme, random multipliers, and a generous bonus segment to take advantage of. The game is primarily centred around a digital DigiWheel that randomly generates numerous random multipliers in each round and then assigns them to random segments. You win if the wheel stops on a segment you had placed your bet on.</p>
                        <p>There are twenty-four segments in the wheel, and the default payout is 25:1. There are four bonus games in Funky Time: Bar, Stayin' Alive, Disco and VIP Disco.</p>
                        <h2>Funky Time Strategies and Tips</h2>
                        <p>Even though Funky Time is a game of chance, there are a few strategies that can help you enjoy more and potentially win more:</p>
                        <ul>
                            <li><strong>Understand the payouts: </strong>Before making a bet, familiarise yourself with the payouts for the given segment. This will enable you to make some informed decisions about your risk tolerance and play style.</li>
                            <li><strong>Balance your bet between different segments: </strong>You shouldn&rsquo;t put your whole bet on one segment. Consider having some safer bet on something likely to pop up, and a riskier bet on a lower probability segment.</li>
                            <li><strong>Utilise the &ldquo;Bet on All&rdquo; options: </strong>&ldquo;Bet on All Numbers&rdquo; and &ldquo;Bet on All Letter&rdquo; might be a good way of betting with more chances to win something with one bet!</li>
                            <li><strong>Focus on Bonus Games:</strong> If you want a more substantial prize, place bets on the bonus game segments; however, please, pay attention, that they have a lower rate of landing than numbers and letters.</li>
                            <li><strong>Manage Your Bankroll:</strong> Set your budget and don&rsquo;t chase losses; remember that you should stop at the right time.</li>
                            <li><strong>Enjoy the Experience:</strong> Funky Time is all about having fun and enjoying the vibrant atmosphere. Don't get too caught up in winning and losing &ndash; groove to the music and embrace the disco spirit!</li>
                        </ul>
                        <h2>Pros and Cons of Funky Time</h2>
                        <p>Funky Time is a live casino that brings endless joy and enthusiasm. While the game is dynamic and amusing, it still has its pros and cons. Here are some of them:</p>
                        <h3>Pros</h3>
                        <ul>
                            <li><strong>Infectious Atmosphere</strong>: Funky Time has a unique atmosphere. The funky tune, groovy visuals, and energetic live dealer create an experience that feels immersive and entertaining. The product feels really well made, and it&rsquo;s easy to get lost in the fun atmosphere.</li>
                            <li><strong>Variety</strong>: While some money <strong><Link to={'/live-casino'}>wheel games</Link></strong> feel stale, Funky Time does a great work of bringing something new to the table. There are four different bonus games, and each of them has a nice twist in it. These mini-games cater to different player personalities, which helps you enjoy the game no matter what kind of gambler you are.</li>
                            <li><strong>Potential for Big Wins: </strong>With random &lsquo;DigiWheel&rsquo; multipliers and the thrill of guessing numbers, Funky Time offers you the chance to win big. The element of the unknown and the possibility of a life-changing win make the game more exciting.</li>
                            <li><strong>Social Interaction: </strong>With a live dealer and a chat interface allowing you to interact with other players, skills, and opportunities to socialize are presented in a format previously unseen in online casino games.</li>
                            <li><strong>Easy: </strong>The game itself is also quite straightforward, making it easy for those new to the Live Game format to participate and have fun with it.&nbsp;</li>
                        </ul>
                        <h3>Cons</h3>
                        <ul>
                            <li><strong>High volatility: </strong>Funky Time is a game with a rather high level of volatility that can be either good or bad. On the one hand, big wins certainly attract players, so they will want to try playing this game. On the other hand, you can get a series of spins without winning anything. Players who want to extend their game may need to keep this fact in mind.&nbsp;</li>
                            <li><strong>Luck is key:</strong> While <strong>blackjack</strong> may be won making decisions, roulette may be won if people understand the probabilities of numbers and chambers, and poker may be won if one is good at bluffing, this game is purely random.&nbsp;</li>
                            <li><strong>Quick pace:</strong> The second disadvantage is the fast pace of the game. Some people do not like fast spins, lots of interactions, and big wins or losses they can experience in just several minutes. Thus, such players may not be very interested in this game.</li>
                            <li><strong>Limited control &ndash; </strong>As with almost every live casino game, the results of Funky Time are based on the wheel&rsquo;s spin and random multipliers. The only things you can control are how much you bet and how big you win.</li>
                            <li><strong>Technical requirements &ndash; </strong>A stable internet connection is mandatory if you want to enjoy Funky Time to the full. A bit of lag or buffering can ruin your impression of the game process, so make sure to look after these things.</li>
                        </ul>
                        <h2>Funky Time Betting Options, Bet Sizes &amp; Payouts</h2>
                        <p>You can place bets on the main round and the bonus rounds. In addition to the bets on the number 1, individual letters and each of the four Bonus games, there are two more options for easier betting:</p>
                        <ul>
                            <li>Bet on 12 letters &mdash; You place a bet on all 12 letters at once (12 bets in one)</li>
                            <li>Bet on 4 bonuses &mdash; You place a single bet on all 4 letters simultaneously (4 bets in one).</li>
                        </ul>
                        <p>Suppose the wheel stops on any of the four bonus game segments you have placed your bet. In that case, you move forward to the corresponding Bonus game round and receive a payout according to the multipliers accumulated in that Bonus game.</p>
                        <p>You can still see the bonus game even if you are not playing. Only players who bet on the corresponding bonus game bet spot can participate.</p>
                        <h2>Funky Time Return to Player (RTP) &amp; House Edge</h2>
                        <p>FUN88 has a lucrative RTP for all the Funky Time players, making playing this colourful game even more financially attractive. The over RTP of this game is approximately 95.99 %, and the house edge is approximately 4%.</p>
                        <h2>Funky Time Game Minimum &amp; Maximum Bet Size</h2>
                        <p>FUN88&nbsp; offers a range of bets that you can place while playing. With our smooth and user-friendly interface, you can easily play Funky Time online and get yourself a chance to win big.</p>
                        <p>The bet limits for this game are below:</p>
                        <Table>
                            <tbody>
                                <tr>
                                    <td>
                                        <p><strong>Bet Type</strong></p>
                                    </td>
                                    <td>
                                        <p><strong>Bet Amount</strong></p>
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        <p>Maximum Bet INR</p>
                                    </td>
                                    <td>
                                        <p>5,00,000</p>
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        <p>Minimum Bet INR</p>
                                    </td>
                                    <td>
                                        <p>10</p>
                                    </td>
                                </tr>
                            </tbody>
                        </Table>
                        <h2>How to Bet?</h2>
                        <p>Place your bet on one or multiple bet spots where you think the money wheel will stop. After the betting window, the wheel will be spun, the random multipliers will be generated, and then assigned to the random segments.</p>
                        <h2>Funky Time Bonus Games</h2>
                        <p>Funky Time&rsquo;s best part is definitely its four bonus games, and each of them takes you to a whole new world replete with stunning visuals, incredible features, and the power to get some serious multiplier action. Here&rsquo;s a closer look at the funky features:</p>
                        <h3>1. Bar Bonus</h3>

                        <p>This bonus is built as a sleek digital shelf with plenty of liquor bottles on it. Each bottle corresponds to a certain multiplier, which will generally fluctuate between 2x and 20x and can even bring you one of the three jackpots.</p>
                        <p>So, what is the Bar Bonus all about?</p>
                        <ul>
                            <li><strong>The Reel of Fortune: </strong>The bartender pulls out a reel that contains multiple segments; each of them features a specific multiplier or a &ldquo;Collect&rdquo; symbol.</li>
                            <li><strong>Spin and Collect: </strong>After each spin, you&rsquo;re awarded a random multiplier that gets added to your total. You need to get as many multipliers as you can.</li>
                        </ul>
                        <h3>2. Stayin' Alive Bonus</h3>

                        <p>This bonus game is inspired by the immortal song of the Bee Gees, &ldquo;Stayin&rsquo; Alive.&rdquo; Imagine a gigantic 9&times;10 field filled with 90 virtual balls. Every ball is not empty, it reveals either:</p>
                        <ul>
                            <li><strong>A Multiplier : </strong>Increase your winnings with modest 2x and other incredible 75x multipliers!</li>
                            <li><strong>The dreadful &ldquo;Cash Stop&rdquo; symbol : </strong>Your ball has stopped, and you do not win anymore. However, the balls right next to it will still reveal Multipliers, there are a lot of balls in the game</li>
                        </ul>
                        <p>The game is to keep uncovering Multiplier balls. Every ball drawn eliminates itself from existence. This musical extravaganza will last either until you reveal every Multiplier, and all the cash is yours, or one of your balls reveals the &ldquo;Cash Stop!&rdquo; symbols.</p>
                        <h3>3. Disco Bonus</h3>

                        <p>The Disco Bonus takes you to a vibrant pachinko-style board where neon lights and big multipliers await:</p>
                        <ul>
                            <li><strong>The Golden Ball Drop:</strong> A gold ball will be dropped down on the board from above. While it goes down, it collects multipliers that appear on the board.</li>
                            <li><strong>Multipliers Galore: </strong>All sections of the pachinko board feature a number, and that number is the multiplier. Your gains may range from the classic 2xs to the impressive 100xs.</li>
                            <li><strong>Special Features: </strong>And for some truly disco kicks, certain high-paying, brightly-lit sections of the board will deliver one of these three features:</li>
                            <ul>
                                <li><strong>Double: </strong>Multiply the next collected figure by 2xs.</li>
                                <li><strong>Triple: </strong>Pay tribute to the disco ball and multiply the next figure by 3xs.</li>
                                <li><strong>Collect: </strong>Pays out the accumulations till now and ends the feature.</li>
                            </ul>
                        </ul>
                        <p>After that, the golden ball keeps bouncing until it falls into one of the segments with the inscription Exit. Thus, the round in the bonus level ends.&nbsp;</p>
                        <h3>4. VIP Disco Bonus</h3>

                        <p>If you are a high-roller who wants to experience an even bigger disco vibe, Funky Time offers you the VIP Disco Bonus. The VIP bonus is generally an enlarged version of the Disco Bonus, with an extended playboard and a more diversified range of multipliers you can win. Imagine a huge game board covered by a rain of golden balls.&nbsp;</p>
                        <p>The golden balls are all kinds of multipliers and unique features, including the ones we have already mentioned. The VIP version is a bonus game that allows higher bets only. It can be an incredible opportunity for you to truly get your disco groove on and win.</p>
                        <h2>How to Deposit &amp; Withdraw to Play Funky Time Game Online?</h2>
                        <p>Adding money to your FUN88 wallet is a fast and easy process that barely takes time. To begin, click on deposit and choose the preferred payment method. Next, add the sum and the required details to finalise the payment. The deposited funds will be reflected in your account when the payment is verified.</p>
                        <p>Just like depositing, withdrawing is also easy. The time it takes for funds to reflect in your account varies depending on your preferred transfer mode.</p>
                        <p>FUN88 offers four withdrawal options: bank transfers, UPI transfers, and other valid payment methods. For security reasons, if you withdraw for the first time, you will need a password to initiate the process.</p>
                        <h2>Download the Funky Time App from FUN88 Today</h2>
                        <p>FUN88 can also be used on mobile phones, Android, and iOS. To download the Funky Time app from FUN88,&nbsp; follow these steps:</p>
                        <ul>
                            <li>Open "Settings" on your device, then go to "Apps &amp; Notifications".</li>
                            <li>Click "Advanced" or the menu symbol, and pick "Special app access."</li>
                            <li>Select "Install unknown apps" and give permission to the browser or file manager you plan to use to download APK files.</li>
                            <li>Go to FUN88's site, <strong><Link to={'/signup'}>create account</Link></strong>, and look for Funky Time.</li>
                            <li>On the app's page, click <strong><Link to="/app-download">Download Funky Time App</Link></strong>. Accept any messages that come up and complete the installation process.</li>
                            <li>After installation, open the application and sign in, and you can begin playing.</li>
                        </ul>
                        <p>So why wait? Get started on FUN88 today and play Funky Time.</p>
                        <p><Link to="/app-download">Download Funky Time Mobile App</Link></p>
                        <h2>Funky Time By Evolution Gaming FAQs</h2>
                        <h3>What is the difference between the regular Disco Bonus and the VIP Disco Bonus?</h3>
                        <p>The main difference lies in the potential payouts. The VIP Disco Bonus features a larger board with a wider range of multipliers, allowing for potentially bigger wins. Additionally, the VIP Disco Bonus is exclusive to players who place higher bets.</p>
                        <h3>What's the most exciting part of Funky Time?</h3>
                        <p>This depends on your preference! The vibrant atmosphere, funky music, and live dealer interaction are all big draws. However, many players find the bonus games to be the most exciting part due to their unique features and the potential for big wins.</p>
                        <h3>Is Funky Time a good game for beginners?</h3>
                        <p>Absolutely! Funky Time's clear interface and engaging gameplay make it a great choice for both seasoned players and newcomers to the world of live casino games.</p>
                        <h3>Where can I play Funky Time?</h3>
                        <p>Funky Time is available online casinos offering Evolution Gaming live dealer games. Check with your preferred online casino to see if they offer this groovy game.</p>
                        <strong> Where can I find a casino that offers Funky Time?</strong>Many online casinos offer Funky Time Evolution Games. Do some research to find a reputable online casino with a good selection of live dealer games, including Funky Time. Remember to check for any <strong><Link to={'/promotions/welcome-bonus'}>welcome bonuses</Link></strong> or promotions they might offer for new players.</div>
                </div>
            </Accordion>
        </AccordionWrapper>
    )
}

export default withTheme(withAppContext(Funkytime88));

const Table = styled.table`
    border-collapse: collapse;
    tr, td {
        border: 1px solid black;
        border-collapse: collapse;
        text-align: center;
        padding: 0.5rem 1rem;
        text-align: left;
    }
`;