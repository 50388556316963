import React, { useEffect } from 'react'
import PlaceholderImage from 'src/components/Placeholder';
import { MainInfoWrapper } from 'src/components/udb/commonStyle';
import config from 'src/config/config';
import { withAppContext } from 'src/store/initAppContext';
import styled, { css, withTheme } from 'styled-components';
import { theme } from 'styled-tools';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import Intl from 'src/components/common/Intl';
import FooterContainer from "src/components/footer/footer";
import { FifaFeverFirstSecHead, PlayNowButtonNew2, Section } from './crazyCrashPromo';
import { useNavigate } from 'react-router-dom';
import SEOContents from 'src/components/SEOContents';
import {motion} from 'framer-motion';
import { ListTypeText } from 'src/components/Bonus';

function AsiaCupBonanza(props) {
    const { app: {
        brandId
    }, theme } = props;

    const navigate = useNavigate();

    return (
        <motion.div 
        initial={{opacity: 0}}
        animate={{opacity: 1}}
        exit={{opacity: 0}}
        >
            <SEOContents pageName={"asiaCupBonanza"}/>
            <MainInfoWrapper>
                <BannerWrapper>
                    <LazyLoadImage src={`${config.imageV3Host}/static/cashsite/${theme.image.AsiaCupBonanzaPromoBanner}`} effect="none" height="100%" width="100%" placeholder={<PlaceholderImage />} />
                    <PlayNowButtonNew onClick={() => navigate("/betting-exchange/online-cricket-betting")} $propsBtn><span>Click Here</span></PlayNowButtonNew>
                </BannerWrapper>
                <ContentWrapper>
                    <NoBorderTop>
                        <ListTypeText>
                            <span><label className="starIcon">★</label><Intl langKey="PROMOTIONS@acbPromoRightText1"/></span>
                            <span><label className="starIcon">★</label><Intl langKey="PROMOTIONS@acbPromoRightText2"/></span>
                            <span>
                                <label className="starIcon">★</label>
                                <Intl langKey="PROMOTIONS@acbPromoRightText3"/>
                                <strong><Intl langKey="PROMOTIONS@acbPromoRightText4"/></strong>
                            </span>
                        </ListTypeText>
                        <AsiaCupFlagWrapper>
                            <LazyLoadImage src={`${config.imageV3Host}/static/cashsite/${theme.image.asiaCupFlags}`} effect="none" height="100%" width="100%" placeholder={<PlaceholderImage />} />
                        </AsiaCupFlagWrapper>
                    </NoBorderTop>

                    <FifaFeverFirstSecHead1 className="asiaCupTextBgBonus"><p>Bonus Packages</p></FifaFeverFirstSecHead1>
                    <Section>
                        <ImageWrapper>
                            <LazyLoadImage src={`${config.imageV3Host}/static/cashsite/${theme.image.asiaCupMobileThumb}`} effect="none" width="100%" placeholder={<PlaceholderImage />} />
                        </ImageWrapper>
                        <ProcessColumn>
                            <li>
                                <LiTextContent>
                                    <StepTittle>ASIA CUP WELCOME BONUS</StepTittle>
                                    <TextContent>Get <strong>400% Welcome Bonus</strong> upto <strong>INR 24000</strong>, when you deposit within 30 min of signing up</TextContent>
                                </LiTextContent>
                            </li>
                            <li>
                                <LiTextContent $propsAdd>
                                    <StepTittle>ASIA CUP DAILY DEPOSIT BONUS</StepTittle>
                                    <TextContent>Get <strong>double cashback</strong> on your weekly loses. Bet on every match and deposit during every match, this Asia cup to win upto <strong>10% cashback bonus</strong> every week</TextContent>
                                </LiTextContent>
                            </li>
                        </ProcessColumn>
                    </Section>
                    <FifaFeverFirstSecHead1 className="asiaCupTextBgSpecial"><p>JACKPOT</p></FifaFeverFirstSecHead1>
                    <JackPotTable>
                        <LazyLoadImage src={`${config.imageV3Host}/static/cashsite/${theme.image.yolo247Ticket}`} effect="none" height="100%" width="100%" placeholder={<PlaceholderImage />} />
                    </JackPotTable>
                    <ListTypeText>
                        <span className='textCenter'>
                        Make Daily deposit and Stand to win 
                            <strong> 20 tickets to the Cricket World Cup 2023!</strong>
                        </span>
                    </ListTypeText>
                    <TermsConditionPoints>
                        <FifaFeverFirstSecHead1 className="asiaCupTextBgTC"><p>TERMS & CONDITIONS</p></FifaFeverFirstSecHead1>

                        <ul>
                            <li><TextContent><Intl langKey="firstbet@GeneralTermsConditionFirst" /></TextContent></li>
                            <li><TextContent><Intl langKey="asiaCup@GeneralTermsConditionSecond" />
                                <StepTittle><Intl langKey="asiaCup@GeneralTermsConditionSecondTwo" /></StepTittle>
                                <Intl langKey="asiaCup@GeneralTermsConditionSecondThree" />
                                <StepTittle><Intl langKey="asiaCup@GeneralTermsConditionSecondFour" /></StepTittle>
                                <Intl langKey="asiaCup@GeneralTermsConditionSecondFive" />
                             </TextContent></li>
                            <li><TextContent><Intl langKey="asiaCup@GeneralTermsConditionThird" /></TextContent></li>
                            <li><TextContent><Intl langKey="asiaCup@GeneralTermsConditionFourth" /></TextContent></li>
                            <li><TextContent><Intl langKey="asiaCup@GeneralTermsConditionFifth" /></TextContent></li>
                            <li><TextContent><Intl langKey="asiaCup@GeneralTermsConditionSixth" /></TextContent></li>
                            <li><TextContent><Intl langKey="asiaCup@GeneralTermsConditionSeventh" /></TextContent></li>
                            <li><TextContent><Intl langKey="asiaCup@GeneralTermsConditionEighth" /></TextContent></li>
                        </ul>
                    </TermsConditionPoints>
                    <PlayNowButtonNew onClick={() => navigate("/betting-exchange/online-cricket-betting")}><span>Click Here</span></PlayNowButtonNew>
                </ContentWrapper>
                <FooterContainer />
            </MainInfoWrapper>
        </motion.div>
    )
}

export default withTheme(withAppContext(AsiaCupBonanza));

//Styled Components

const PlayNowButtonNew = styled.button<{ $propsBtn?: boolean }>`
    width: 162px;
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 38.7857px;
    background: #000;
    color: #000;
    font-size: 16px;
    font-weight: 700;
    text-transform: uppercase;
    padding: 3px;
    border: 1px solid #FF9E00;
    cursor: pointer;

    :hover {
      color: #fff;
      transition: 0.075s transform ease-in;
    }

    span {
      width: 100%;
      height: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      background: linear-gradient(225deg, #F28500 0%, #FFEC2D 49.48%, #FF9E00 100%);
      border-radius: 38.7857px;

      :hover {
        background: linear-gradient(225deg,#000000 0%,#fc9e00 49.48%,#121212 100%);
        transition: 0.075s transform ease-in;
      }
    }

    ${(props) => props.$propsBtn && css`
        position: absolute;
        right: 45%;
        bottom: 29%;
    `}
`;

const AsiaCupFlagWrapper = styled.div`
    /* flex: 1; */
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;

    img {
        max-width: 80%;
        display: flex;
        margin: 0 auto;
    }
`;


const FifaFeverFirstSecHead1 = styled(FifaFeverFirstSecHead)`
    text-transform: uppercase;
    font-size: 30px;

    &.asiaCupTextBgBonus {
        color:rgba(239, 65, 35, 1);
        background: url(${config.imageV3Host}/static/cashsite/${theme('image.asiaCupTextBgBonus')});
        height: 70px;
        width: 100%;
        background-repeat: no-repeat;
        background-position: center;
        background-size: contain;

        p {
            font-size: 27px;
            color: #FFC51B;
        }
     }

     &.asiaCupTextBgSpecial {
        background: url(${config.imageV3Host}/static/cashsite/${theme('image.asiaCupTextBgSpecial')});
        height: 70px;
        width: 100%;
        background-repeat: no-repeat;
        background-position: center;
        background-size: contain;

        p {
            font-size: 27px;
            color: #FFC51B;
        }
     }

     &.asiaCupTextBgTC {
        background: url(${config.imageV3Host}/static/cashsite/${theme('image.asiaCupTextBgT&C')});
        height: 70px;
        width: 100%;
        background-repeat: no-repeat;
        background-position: center;
        background-size: contain;

        p {
            font-size: 27px;
            color: #FFC51B;
        }
     }
`;
const LiTextContent = styled.div<{ $propsAdd?: boolean }>`
    width: 100%;
    display: flex;
    flex-direction: column;
    padding: 3rem 2rem;
    background-image: url(${config.imageV3Host}/static/cashsite/${theme('image.asiaCupBonasPackageTextBG1')});
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
    border-radius: 8.09632px;

    ${(props) => props.$propsAdd && css`
        background-image: url(${config.imageV3Host}/static/cashsite/${theme('image.asiaCupBonasPackageTextBG2')});
    `}

    p {
        margin: 0;
        margin-top: 10px;
        display: inline-block;
    }

    span {
        text-transform: uppercase;
        font-size: 18px;
    }
`;


const NoBorderTop = styled.div`
    width: 100%;
    display: flex;
    flex-direction: column;
    padding: 0rem 17rem 2.5rem 17rem;
    text-align: justify;
    align-items: flex-start;

    p {
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
        color: ${theme('palette.catch99TextContentColor')};
        font-size: 21px;
        gap: 5px;
        text-align: center;
        margin: 13px 0;
        text-align: justify;
    }
    span{
        font-size: 17px;
        text-align: justify;
        text-transform: capitalize;
        color: #FFFFFF;
        padding-bottom: 10px;
    }
`;

const TermsConditionPoints = styled.div`
    width: 70%;
    display: flex;
    flex-direction: column;
    gap: 15px;
    padding-bottom: 2rem;
    /* border: ${theme('palette.AviatorjackPotWrapperBgBorder')}; */
    border-radius: 16px;
    padding: 3rem;
    /* background: ${theme('palette.AviatorTermsConditionPointsBg')}; */

    p {
        padding-left: 1rem;
        text-transform: capitalize;
    }

    ul {
        list-style: number;
        color: #fff;
        padding-left: 35px;
        margin-top: 0;

        li {
            p{
                display: flex;
                gap: 5px;
                padding-left: 0rem;
                margin: 0 0 10px 0;
            }
        }
    }
`;

const JackPotTable = styled.div`
    width: 40%;
    display: flex;
    padding-bottom: 3rem;
    padding-top: 3rem;

    img {
        max-width: 100%;
    }
`;

export const ImageWrapper = styled.div`
     width: 30%;
    display: flex;
    padding-top: 47px;

    img {
        max-width: 100%;
    }
`;

export const TextContent = styled.p`
    display: flex;
    color: ${theme('palette.catch99TextContentColor')};
    font-size: 17px;
    text-transform: capitalize;
`;

const StepTittle = styled.span`
    display: flex;
    color: #FFC51B;
    font-size: 17px;
    font-family: 'Inter-Bold';
    display: contents;
`;

export const ProcessColumn = styled.ul`
    width: 45%;
    padding: 2rem;
    position: relative;
    justify-content: center;
    display: flex;
    flex-direction: column;
    gap: 1.5rem;

    li {
        /* border: 1.2px solid #FCF29E; */
        display: flex;
        align-items: center;
        flex-direction: row;
        gap: 1.5rem;
        border-radius: 20px;
        /* background: linear-gradient(#ffffff,#ffffff) padding-box,linear-gradient(to bottom,#f28500,#ff9e00) border-box; */
        border: 2px solid #f28500;
        background: #000;
    }

    img {
        width: 98px;
        height: 98px;
    }

    @media only screen and (min-width: 1700px){
		width: 40%;
	}	
 `;

export const ContentWrapper = styled.div`
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    background: url(${config.imageV3Host}/static/cashsite/${theme('image.asiaCupBonanzaPromoMainBG')});
    background-position: 100% top;
    background-size: cover;
    /* background-repeat: no-repeat; */
    padding: 4rem 0;
    padding-top: 0;
    
`;
export const BannerWrapper = styled.div`
    width: 100%;
    display: flex;
    transition: 700ms;
    position: relative;
    img {
        max-width: 100%;
        transition: 700ms;
    }
`;