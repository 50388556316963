
import { withAppContext } from 'src/store/initAppContext';
import { withTheme } from 'styled-components';
import { Accordion, AccordionWrapper } from '../seo-content/SeoContentHomePage39';
import { Link } from 'react-router-dom';


const SevenUpDown = (props: any) => {
    const { app: { brandId } } = props;

    return (
        <AccordionWrapper>
            <Accordion>
                <input type="checkbox" id="section1" className="accordion__input" />
                <label htmlFor="section1" className="accordion__label"><h1>7 Up 7 Down by Kingmaker

                </h1>

                </label>
                <div className="accordion__content">

                    <h2>Place Your Bet on 7 Up 7 Down Card Game By Kingmaker</h2>
                    <p>The 7 Up 7 Down is an amazing dice-based <Link to={'/live-casino'}>casino game</Link>. Depending on your experience, you can choose one of the four types of play the game offers. FUN88 collaborates with Kingmaker Games to make this fantastic dice game available digitally. In the 7 Up 7 Down game, you have to guess whether the total of the two dice will be precisely seven, less, or more than seven. If you guess correctly, you may earn double or five times your stake, depending on the wager you make. The game is designed for HTML5 and works on <Link to={'/app-download'}> mobile devices</Link>. Additionally, you can enjoy a smooth and hassle-free interface when you play on FUN88.</p>

                    <h2>How Can I Play 7 Up 7 Down on FUN88?</h2>
                    <p>FUN88 offers you a simple process to sign up or <Link to={'/signup'}> register on the platform</Link>. Henceforth, you can enjoy playing a wide range of games along with this one. This is how you can start playing 7 Up 7 Down on this platform:</p>
                    <ul>
                        <li>First, you need to create an account. Only then you will get your login credentials.</li>
                        <li>Use the search feature once you've logged in to the casino to find the game. Place a wager to start a round. After winning, you may use your earnings to play other games or keep the profit in your virtual FUN88 wallet which you can withdraw later.</li>
                    </ul>

                    <h2>Rules of 7 Up 7 Down</h2>
                    <p>The rules for the 7 Up 7 Down dice game are easy. The betting system is arranged in the front of the table, and there is the glass jar with the two dice with a lid placed over the top to prevent you from seeing the dice values. When the game begins, the dice are rolled out of the jar and onto the table, but the dice are cocked when they fall on their sides or press up against the glass jar's walls. For any winning bets, the two numbers on the dice must be pointing straight upward so they are visible to everyone.</p>
                    <p>When playing 7 Up 7 Down, the odds are stacked heavily in the house's favor, and the house margin is 16.67%. However, when the game gives the lesser 3:1 payoff on winning Lucky 7 bets, this number increases to 33.33%.</p>

                    <h2>7 Up 7 Down Game Betting Tips</h2>
                    <p>Although it is a game of luck, the following strategies may increase your odds of winning:</p>
                    <ul>
                        <li>Your likelihood of winning is 5/12 if you wager on the up or down choice, while your chance of winning is 1/6 if you wager on Lucky 7.</li>
                        <li>Pick a betting choice, then stake the minimal amount on it, and after each losing round, double your investment and continue betting on the same result if you lose. This system is called the Martingale method, and it is based on the idea that you will hit a large reward that will clear all of your prior losses.</li>
                        <li>Place a wager on Lucky 7 because it offers larger prizes, and some players wager on it more frequently because of that. Although this wager could be worthwhile, remember that it has a low probability, which increases the possibility of losing, so be careful while choosing this bet.</li>
                        <li>After watching the game for a while, try to identify any trends or patterns in the results, and use this to plan your strategy.</li>
                    </ul>

                    <h2>7 Up 7 Down RTP, Volatility, Max Win, and Demo Version</h2>
                    <p>Even for a dice game, the 7 Up 7 Down RTP rate is very high, at 97%. The medium variance offers advantages to players. The maximum win is 4x the wager, and during the Lightning Bonus, that amount can increase to 6x.</p>

                    <h2>Conclusion</h2>
                    <p>The 7 Up 7 Down game is simple and rewarding. You can use the given tips to develop your strategies and win big. This fantastic dice-based casino game is a great opportunity to enjoy and win a handsome amount. In addition to this, <Link to={'/'}>FUN88</Link> offers you an unmatched user interface to give you a never-before-experience while playing online casinos.</p>
                </div>
            </Accordion>
        </AccordionWrapper>

    )
}

export default withTheme(withAppContext(SevenUpDown));