import React from 'react';
import styled, { css } from 'styled-components';
import { ifProp, theme } from 'styled-tools';
import Intl from '../common/Intl';

// Component
interface ISwitchProps {
	// null: 中間狀態
	currentActiveState: boolean | null;
	handleClick: () => void;
	id?: string;
}

const Switch: React.SFC<ISwitchProps> = props => {
	const { currentActiveState, handleClick, id } = props;
	return (
		<SwitchWrapper
			isActive={currentActiveState}
			onClick={handleClick}
			id={id}
			data-type={currentActiveState ? 'off' : 'on'}
		>
			{/* <Label style={{ left: 0 }} isActive={currentActiveState}>
				{currentActiveState && <Intl langKey="ONE_CLICK@ON" />}
			</Label>
			<Label style={{ right: 0 }} isActive={!currentActiveState}>
				{!currentActiveState && <Intl langKey="ONE_CLICK@OFF" />}
			</Label> */}
			<Circle isActive={currentActiveState} />
		</SwitchWrapper>
	);
};

export default Switch;

// Styled components
const Label: any = styled.span`
	padding: 0 0.5em;
	position: absolute;
	top: 50%;
	transform: translateY(-55%);
	opacity: ${ifProp('isActive', 1, 0)};
`;

export const Circle: any = styled.div`
	position: absolute;
	z-index: 1;
	width: 10px;
    height: 10px;
	top: 48%;
	left: 5px;
	transform: translateY(-50%);
	background-color: #fff;
	border-radius: 50%;
	box-shadow: 0 2px 5px rgba(0, 0, 0, 0.25);
	background: #FFFFFF;
	transition: all 0.15s ease-out;
	will-change: transform;
	
	${ifProp(
		'isActive',
		css`
			left: 100%;
			transform: translateY(-50%) translateX(-100%);
		`,
		css`
			left: 0;
		`,
	)};

	${(props: any) => {
		const { isActive } = props;
		switch (isActive) {
			case true:
				return css`
					left: 90%;
					transform: translateY(-50%) translateX(-100%);
				`;
			case null:
				return css`
					left: 50%;
					transform: translateY(-50%) translateX(-50%);
				`;
			case false:
			default:
				return css`
					left: 5px;
					transform: translateY(-50%);
				`;
		}
	}};
`;

export const SwitchWrapper: any = styled.button`
	display: flex;
	justify-content: space-between;
	align-items: center;
	cursor: pointer;
	font-size: 11px;
	width: 4em;
	height: 1.75em;
	color: #fff;
	position: relative;
	z-index: 0;
	border-radius: 99em;
	border: none;
	padding: 0;
	margin-right: 5px;
	border: 2px solid rgba(176, 156, 124, 0.346);

	* {
		pointer-events: none;
	}

	&:focus {
		outline: 0;
	}

	&::before,
	&::after {
		content: '';
		display: block;
		position: absolute;
		top: -2px;
		right: -2px;
		bottom: -2px;
		left: -2px;
		border-radius: 99em;
		pointer-events: none;
	}

	/* The outline */
	&::before {
		z-index: -1;
	}

	/* The background */
	&::after {
		z-index: -2;
		box-shadow: inset 0px 0px 4px rgba(0, 0, 0, 0.35);
		background: ${ifProp(
			'isActive',
			css`
			${theme('palette.onClickRadioBtnBg')}
			`,
			'#cccccc',
		)};
		// background-color: transparent;
	}
`;
