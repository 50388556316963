const AvailableSVG = () => {
  return (
    <>
       <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 101 101" width="101" height="101" fill="none"><path d="M50.55 51.727H8.085v6.214H50.55M50.55 64.311H5.296v-6.214h4.86M4.952 23.268v-5.583l33.19-13.131 32.88 13.131v5.583H4.953ZM11.362 23.259v28.31M26.951 23.259v28.31M42.54 23.259v28.31M58.642 23.259V37.67M17.61 23.259v28.31M33.2 23.259v28.31M48.791 23.259v28.31M64.892 23.259V37.67" stroke="#fff" stroke-width="2.745" stroke-linecap="round"/><circle cx="37.987" cy="14.095" r="3.112" stroke="#fff" stroke-width="3.431"/><path d="M55.293 95.905V43.038h31.35v31.837l-9.346-8.86c-.828-.34-2.94-.564-4.77 1.266-1.831 1.83-1.478 4.008-1.072 4.868l11.878 12.657c-3.583 3.583-4.478 8.892-4.478 11.099H55.293ZM95.99 97.167v-19.18c.26-2.628-1.188-9.132-9.054-14.117M86.487 74.682l3.305 3.304" stroke="#fff" stroke-width="2.745" stroke-linecap="square"/><path d="M80.825 69.261V52c-2.466 0-3.26-2.29-3.347-3.434h-12.77c-.423 2.113-2.114 3.17-2.907 3.434v33.82c.88.264 2.818 1.41 3.523 3.876 3.904.234 11.713.563 11.713 0 0-.705 1.322-3.347 3.788-3.876v-3.61" stroke="#fff" stroke-width="2.745" stroke-linecap="round"/><path d="M79.587 65.886c-2.461-3.636-9.432-8.727-17.627 0v6.718c2.24 2.173 7.942 5.867 12.838 3.26" stroke="#fff" stroke-width="2.745" stroke-linecap="square"/><path fill="#fff" d="M69.344 53.179h3.26v3.26h-3.26zM69.344 81.387h3.26v3.26h-3.26zM45.923 87.707h3.26v3.26h-3.26zM39.542 87.707h3.26v3.26h-3.26zM33.165 87.707h3.26v3.26h-3.26zM26.94 87.707h3.26v3.26h-3.26zM26.94 81.536h3.26v3.26h-3.26zM26.94 75.366h3.26v3.26h-3.26zM26.94 69.197h3.26v3.26h-3.26z"/></svg>
    </>

  )
}

export default AvailableSVG;

export const AvailableSVGf88 = () => {
  return (
    <>
<svg xmlns="http://www.w3.org/2000/svg" width="60" height="62" viewBox="0 0 60 62" fill="none">
  <path d="M30.1829 31.8398H3.16797V35.7933H30.1829" stroke="black" stroke-width="1.74639" stroke-linecap="round"/>
  <path d="M30.1816 39.8466H1.39258V35.8931H4.48449" stroke="black" stroke-width="1.74639" stroke-linecap="round"/>
  <path d="M1.17383 13.7361V10.1845L22.2886 1.83057L43.2059 10.1845V13.7361H1.17383Z" stroke="black" stroke-width="1.74639" stroke-linecap="round"/>
  <path d="M5.25195 13.73V31.7397" stroke="black" stroke-width="1.74639" stroke-linecap="round"/>
  <path d="M15.1699 13.73V31.7397" stroke="black" stroke-width="1.74639" stroke-linecap="round"/>
  <path d="M25.0859 13.73V31.7397" stroke="black" stroke-width="1.74639" stroke-linecap="round"/>
  <path d="M35.3301 13.73V22.8987" stroke="black" stroke-width="1.74639" stroke-linecap="round"/>
  <path d="M9.22656 13.73V31.7397" stroke="black" stroke-width="1.74639" stroke-linecap="round"/>
  <path d="M19.1445 13.73V31.7397" stroke="black" stroke-width="1.74639" stroke-linecap="round"/>
  <path d="M29.0625 13.73V31.7397" stroke="black" stroke-width="1.74639" stroke-linecap="round"/>
  <path d="M39.3066 13.73V22.8987" stroke="black" stroke-width="1.74639" stroke-linecap="round"/>
  <circle cx="22.1902" cy="7.90014" r="1.97955" stroke="black" stroke-width="2.18299"/>
  <path d="M33.1992 59.9451V26.313H53.1434V46.5668L47.1973 40.9305C46.6709 40.7137 45.3268 40.5712 44.1624 41.7357C42.998 42.9001 43.223 44.2854 43.4811 44.8325L51.0375 52.8844C48.7582 55.1637 48.1883 58.5413 48.1883 59.9451H33.1992Z" stroke="black" stroke-width="1.74639" stroke-linecap="square"/>
  <path d="M59.0903 60.7491V48.5474C59.2555 46.8751 58.3346 42.7376 53.3301 39.5664" stroke="black" stroke-width="1.74639" stroke-linecap="square"/>
  <path d="M53.043 46.4434L55.1454 48.5458" stroke="black" stroke-width="1.74639" stroke-linecap="square"/>
  <path d="M49.4422 42.9949V32.0132C47.8734 32.0132 47.3691 30.5565 47.3131 29.8281H39.189C38.9202 31.1729 37.8442 31.8452 37.3398 32.0132V53.5285C37.9001 53.6966 39.1328 54.4249 39.581 55.9938C42.065 56.1432 47.033 56.3523 47.033 55.9938C47.033 55.5455 47.8734 53.8646 49.4422 53.5285V51.2313" stroke="black" stroke-width="1.74639" stroke-linecap="round"/>
  <path d="M48.6533 40.8477C47.0876 38.5344 42.653 35.2958 37.4395 40.8477V45.1217C38.8641 46.504 42.4919 48.854 45.6064 47.1952" stroke="black" stroke-width="1.74639" stroke-linecap="square"/>
  <rect x="42.1367" y="32.7642" width="2.07362" height="2.07362" fill="black"/>
  <rect x="42.1367" y="50.709" width="2.07362" height="2.07362" fill="black"/>
  <rect x="27.2383" y="54.73" width="2.07362" height="2.07362" fill="black"/>
  <rect x="23.1777" y="54.73" width="2.07362" height="2.07362" fill="black"/>
  <rect x="19.1211" y="54.73" width="2.07362" height="2.07362" fill="black"/>
  <rect x="15.1621" y="54.73" width="2.07362" height="2.07362" fill="black"/>
  <rect x="15.1621" y="50.8042" width="2.07362" height="2.07362" fill="black"/>
  <rect x="15.1621" y="46.8799" width="2.07362" height="2.07362" fill="black"/>
  <rect x="15.1621" y="42.9541" width="2.07362" height="2.07362" fill="black"/>
</svg>    </>

  )
}