
import React, { useEffect } from 'react'
import PlaceholderImage from 'src/components/Placeholder';
import { MainInfoWrapper } from 'src/components/udb/commonStyle';
import config from 'src/config/config';
import { withAppContext } from 'src/store/initAppContext';
import styled, { withTheme } from 'styled-components';
import { theme } from 'styled-tools';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import FooterContainer from "src/components/footer/footer";
import { InnerPromoWrapper, SmartSoftCenterText, SmartSoftContentWrapper } from './SmartsoftSpinPromo';
import { TeamsSection } from './ipl-matkaBD';
import { ContentWrapper1, TermsConditionPoints1 } from './ipl-promo';
import { FifaFeverFirstSecHead } from './BblBonusInner';
import IPLseo from 'src/components/SEOComponents/IPLseo';
import IPLseoBD from 'src/components/SEOComponents/IPLseoBD';



const IPLpowerPlayBonus = (props) => {

    const { app: { brandId }, theme } = props;

    const domain = brandId == 31 || brandId == 32 ? "Yolo247" : "BaaziAdda";
    const teams = [
        { imageSvg: `${config.imageV3Host}${theme.image.kkr}` },
        { imageSvg: `${config.imageV3Host}${theme.image.kxip}` },
        { imageSvg: `${config.imageV3Host}${theme.image.srh}` },
        { imageSvg: `${config.imageV3Host}${theme.image.dc}` },
        { imageSvg: `${config.imageV3Host}${theme.image.lsg}` },
        { imageSvg: `${config.imageV3Host}${theme.image.rcb}` },
        { imageSvg: `${config.imageV3Host}${theme.image.csk}` },
        { imageSvg: `${config.imageV3Host}${theme.image.mi}` },
        { imageSvg: `${config.imageV3Host}${theme.image.rr}` },
        { imageSvg: `${config.imageV3Host}${theme.image.gt}` },
    ]
    return (
        <>
            <InnerPromoWrapper>
                <Mainwrap>
                    <InfoWrap>
                        <p>
                            This IPL, be a winner everyday on {domain}! The highest bettor wins more!
                        </p>
                        <p>We present daily prizes on your cricket bets! This scheme is valid from 22nd March to 29th June.</p>
                    </InfoWrap>
                    <TeamsSection>
                        {teams.map((elm) => (
                            <LazyLoadImage src={elm.imageSvg} effect="none" height="100%" width="100%" placeholder={<PlaceholderImage />} />))}
                    </TeamsSection>
                    <Imgsection>

                        <FirstImg>

                            <img
                                src={`${config.imageV3Host}/static/cashsite/${theme.image.kkrnarine}`}
                            />
                        </FirstImg>

                        <SecomdImg>
                            <p>
                                Daily Bonus Scorecard for IPL 2024
                            </p>
                            <img
                                src={`${config.imageV3Host}/static/cashsite/${theme.image.tablePowerplay}`}
                            />
                        </SecomdImg>
                    </Imgsection>

                    <TERMS>
                        <Termsinfo>
                            <TCheadline className='terms' style={{ textTransform: 'uppercase', width: 'auto' }}>Terms & Conditions</TCheadline>
                            <ul>
                                <li><TextContent>This promotion is valid for all new & existing {domain} members.</TextContent></li>
                                <li><TextContent>The winners will be chosen on the highest bet amount!</TextContent></li>
                                <li><TextContent> Bonus amount will be issued as free bets for daily winners!</TextContent></li>
                                <li><TextContent>Multiple accounts will not be considered for this promotion. All players will be subject to duplicate checking.  {domain} reserves the right to disqualify players if any collusion or foul play takes place in the promotion. </TextContent></li>
                                <li><TextContent>This promotion cannot be used in conjunction with, or to contribute to the eligibility for, any other promotion.</TextContent></li>
                                <li><TextContent>{domain} reserves the right to amend, cancel, reclaim, or refuse any promotion at its own discretion.</TextContent></li>
                                <li><TextContent>In the event that any fraudulent activity of the player(s) has been identified, {domain} reserves the right to withhold your funds and reject your withdrawals.</TextContent></li>
                                <li><TextContent>{domain} General Terms & Conditions apply.</TextContent></li>

                            </ul>
                        </Termsinfo>
                        <ImgTerms>
                            <img src={`${config.imageV3Host}/static/cashsite/${theme.image.warner}`} className='warmer' />
                        </ImgTerms>
                    </TERMS>


                    {/* <TERMS>
                        <TermsConditionPoints1>
                            <FifaFeverFirstSecHead className='terms'>Terms & Conditions</FifaFeverFirstSecHead>
                            <ul>
                                <li><TextContent>The loss back bonus will be applied to ‘lost IPL EXCHANGE bets - for the day’, placed during the IPL  & WC 2024.</TextContent></li>
                                <li><TextContent>The bonus amount for each qualifying bet will be capped at INR 50 </TextContent></li>
                                <li><TextContent>The bonus will be credited to your {domain} account the next day</TextContent></li>
                                <li><TextContent>Bonus will be expired within 24-48 hours</TextContent></li>
                                <li><TextContent>There is no limit to the number of bets you can place to earn the 100% lossback bonus, ‘for the day’</TextContent></li>
                                <li><TextContent>The loss back bonus can be used to place future bets on {domain} as this bonus cannot be withdrawn. Only winnings can be withdrawn.</TextContent></li>
                                <li><TextContent>This promotion is valid only for the IPL.</TextContent></li>
                                <li><TextContent>{domain} reserves the right to disqualify any user found engaging in fraudulent activities.</TextContent></li>
                                <li><TextContent>{domain} General Terms & Conditions apply.</TextContent></li>
                            </ul>
                        </TermsConditionPoints1>
                    </TERMS> */}

                </Mainwrap>

            </InnerPromoWrapper>
            {/* {brandId == 31 || brandId == 32 ? <IPLseo /> :
                <IPLseoBD />} */}
            <FooterContainer />
        </>
    )
}

export default withTheme(withAppContext(IPLpowerPlayBonus));

//Styled Components

const ImgTerms = styled.div`
    width: 25%;
    img{
        width: 100%;
    }
`

const TCheadline = styled(FifaFeverFirstSecHead)`
    width: 100%;
 
    .terms{
        text-transform: uppercase;
    }
`
const Termsinfo = styled(TermsConditionPoints1)`
color: #FFF;
font-size: 18px;
font-style: normal;
font-weight: 400;
line-height: 36px; 
text-transform: capitalize;
width: 75%;
background: none;
padding-bottom: 0;
background: unset;
border: none;
backdrop-filter: none;
ul{
    padding-left: 9rem;
}

::after{
    content: none;
}

.terms{
    text-transform: uppercase;
    font-size: 30px;
font-style: normal;
font-weight: 700;
line-height: normal;
text-transform: capitalize;
background: linear-gradient(91deg, #D1AD46 2.89%, #FCF19E 52.11%, #D0AB44 99.8%);
background-clip: text;
-webkit-background-clip: text;
-webkit-text-fill-color: transparent;
}

`

const Mainwrap = styled(SmartSoftContentWrapper)`
    background: url(${config.imageV3Host}/static/cashsite/${theme("image.IPLpowerplayPromoInnerBg")});
    background-position:center;
    background-size: cover;
    background-repeat: no-repeat;
`
const TERMS = styled.div`
display: flex;
align-items: center;
justify-content: space-evenly;

.warner{
    width: 50%;
    img{
         width: 50%;
    }
}
`

const InfoWrap = styled(SmartSoftCenterText)`
padding: 3rem;
margin-bottom: 0;
    p{
        color: #FFF;
text-align: center;
font-size: 21px;
font-style: normal;
font-weight: 500;
line-height: 31.185px;
text-transform: capitalize;
    }

`

const Imgsection = styled.div`
width: 100%;
display: flex;
align-items: center;
justify-content: space-between;
padding-right:4rem;
`

const FirstImg = styled.div`
width: 30%;
img{
width: 100%;
}

`

const SecomdImg = styled.div`
width: 65%;
flex-direction: column;
    align-items: center;
    justify-content: center;
    p{
        text-align: center;
font-size: 30px;
font-style: normal;
font-weight: 700;
line-height: normal;
text-transform: capitalize;
background: linear-gradient(91deg, #D1AD46 2.89%, #FCF19E 52.11%, #D0AB44 99.8%);
background-clip: text;
-webkit-background-clip: text;
-webkit-text-fill-color: transparent;
    }
img{
width: 100%;
}


    
`

const TermsConditionPoints = styled.div`
width: 80%;
display: flex;
flex-direction: column;
gap: 15px;
padding-bottom: 2rem;

p {
padding-left: 1rem;
text-transform: capitalize;
}

ul {
list-style: number;
color: #fff;
padding-left: 35px;
margin-top: 0;

li {
    p{
        padding-left: 0rem;
        margin: 0 0 20px 0;
        font-style: normal;
        color: #FFF;
        text-align: justify;
        font-size: 20px;
        font-style: normal;
        font-weight: 600;
        line-height: normal;
        letter-spacing: -0.8px;
        text-transform: capitalize;
    }
}
}
`;

const TermsTittle = styled.h4`
color: ${theme('palette.enterJackPotTextColor')};
font-size: 2rem;
letter-spacing: 0.05em;
text-transform: uppercase;
font-style: italic;
font-family: 'Inter-Bold';
font-size: 36px;
margin-bottom: 1rem;
`;

const TermsHead = styled.div`
width: 100%;
display: flex;
flex-direction: column;
align-items: center;
text-align: center;
position: relative;
margin: 1rem 0 2rem;
.terms{
font-size: 35px;
padding-bottom: 15px;
}

:after {
content: "";
width: 107px;
height: 3px;
display: flex;
position: absolute;
bottom: 0;
background: ${theme('palette.processColumnLeftLine')};
}

p {
font-style: italic;
margin: 0 0 2rem;
}
`;

const JackPotTable = styled.div`
width: 90%;
display: flex;
padding-top: 47px;

img {
max-width: 100%;
}
`;

const TextContent = styled.p`
display: flex;
color: #ffff;
font-style: normal;
font-size: 17px;
font-size: 20px;
`;

const StepTittle = styled.span`
display: flex;
color: #F0BE21;
font-size: 17px;
font-family: 'Inter-Bold';
`;

const ProcessColumn = styled.div`
width: 60%;
padding: 2rem;
margin-left: 2rem;
position: relative;

.steps{
font-style: normal;
font-weight: 600;
font-size: 20px;
padding: 0rem;
text-align: left;
}
&::before {
content: "";
width: 2px;
height: 100%;
display: flex;
position: absolute;
left: 0;
top: 0;
background: ${theme('palette.processColumnLeftLine')};
}
`;

const EnterJackPot = styled.h1`
width: 35%;
display: flex;
align-items: center;
justify-content: flex-end;
font-size: 3rem;
background: linear-gradient(180deg, #FADA34 0%, #DE9101 100%);
background-clip: text;
-webkit-background-clip: text;
-webkit-text-fill-color: transparent;
text-align: right;
text-transform: capitalize;
font-family: 'Inter-Bold';
`;

const JackPotWrapper = styled.div`
width: 90%;
display: flex;
flex-wrap: wrap;
justify-content: center;
background: ${theme('palette.jackPotWrapperBg')};
backdrop-filter: blur(45.5px);
border: ${theme('palette.jackPotWrapperBgBorder')};
border-radius: 16px;
padding: 3rem;
margin: 2rem 0;
`;

const Catch99ContentWrapper = styled.div`
width: 100%;
display: flex;
flex-direction: column;
align-items: center;
background: url(${config.imageV3Host}/static/cashsite/${theme('image.Catch99ContentWrapperBg')});
background-position: center;
background-size: cover;
background-repeat: no-repeat;
h3{
text-align: center;
font-size: 26px;
font-style: italic;
font-weight: 700;
line-height: normal;
letter-spacing: 1.3px;
text-transform: uppercase;
background: linear-gradient(180deg, #FADA34 0%, #DE9101 100%);
background-clip: text;
-webkit-background-clip: text;
-webkit-text-fill-color: transparent;
padding-top: 20px;
}
h4{
color: #E5A20D;
text-align: center;
margin: 0;
font-size: 24px;
font-style: italic;
font-weight: 700;
line-height: normal;
}
p{
color: #FFF;
text-align: center;
font-size: 21px;
font-style: italic;
font-weight: 400;
line-height: normal;
padding: 0rem 5rem;

}

img{
padding: 0rem 10rem;
}
.m-0{
margin: 0;
}
.p-0{
padding: 0rem 20rem;
span{
    color: #E5A20D;
}
}
`;
