// Code Changed - Manoj ( removed size condition check in many place )
import isEqual from "lodash/isEqual";
import { lighten } from "polished";
import React from "react";
import styled, { css, keyframes } from "styled-components";
import { ifNotProp, ifProp, switchProp, theme, withProp } from "styled-tools";

// Component
import { Amount } from "../../../common/NumberDisplay";
import { EOddsType } from "../../../eu-content/NumberInput";

// GQL
import {
  IEventInfo,
  IFancyInfo,
  ISelectionInfo,
} from "../../../../graphql/schema";

// Store
import { IbfInfo, withAppContext } from "../../../../store/initAppContext";
import {
  IMainLayoutProviderStore,
  withMainLayoutContext,
} from "../../../../store/MainLayoutContext";
import {
  IMarketInfoStore,
  IOrderListProviderStore,
  IOrderTicket,
  withOrderListContext,
} from "../../../../store/OrderListContext";

// Util
import { diffColor } from "../../../../util/colorUtil";
import oddsUtil from "../../../../util/oddsUtil";
import util from "../../../../util/util";
import {
  EEventTypeId,
  EMarketType,
  ECookieName,
} from "../../../../util/utilModel";
import cookieUtil from "../../../../util/cookieUtil";

interface IProps {
  price: number;
  size: number;
  type: "BACK" | "LAY";
  // multi fancy 為了要知道初始total值是否為0來判斷size要不要block才會用到
  totalSize?: number;
  eventInfo: IEventInfo;
  // Code Changed ( added IMarketInfoStore instead of IMarketInfo)
  marketInfo: IMarketInfoStore;
  isBest?: boolean;
  // BetFair 盤才會有
  selectionInfo?: ISelectionInfo;
  // Fancy 盤 (包含 single & multi) 才會有
  fancyInfo?: IFancyInfo;
  // Fancy 盤才會有
  sideStatus?: "OPENED" | "SUSPENDED";

  // injected by context
  orderListContext: IOrderListProviderStore;
  mainLayoutContext: IMainLayoutProviderStore;
  app: {
    isMobileWidth: boolean;
    exchangeRate: number;
    bfInfo: IbfInfo[];
  };
  homePage?: boolean;
}

interface IState {
  isUpdating: boolean;
  keyName: string;
  size: number;
  price: number;
}

class Odd extends React.Component<IProps, IState> {
  constructor(props: IProps) {
    super(props);
    const { marketInfo, selectionInfo, fancyInfo } = props;
    const keyName = marketInfo
      ? fancyInfo
        ? `${marketInfo.marketId}_${fancyInfo.selectionId}_${props.type}`
        : selectionInfo
        ? `${marketInfo.marketId}_${selectionInfo.selectionId}_${props.type}`
        : ""
      : "";
    this.state = {
      isUpdating: false,
      keyName,
      price: props.price,
      size: props.size,
    };
  }
  updateFlag: any;
  static getDerivedStateFromProps(nextProps: IProps, prevState: IState) {
    const { price: newPrice, size: newSize } = nextProps;
    const { price: oldPrice, size: oldSize } = prevState;
    const isUpdating = newPrice !== oldPrice || newSize !== oldSize;
    return {
      isUpdating: prevState.isUpdating ? true : isUpdating,
      price: nextProps.price,
      size: nextProps.size,
    };
  }
  shouldComponentUpdate(nextProps: any, nextState: any) {
    const checkArray = [
      "price",
      "size",
      "type",
      "marketInfo",
      "selectionInfo",
      "fancyInfo",
      "isBest",
      "sideStatus",
      "orderListContext",
      "mainLayoutContext",
    ];
    const newProps = checkArray.map((key) => (nextProps as any)[key]);
    const oldProps = checkArray.map((key) => (this.props as any)[key]);
    return (
      !isEqual(newProps, oldProps) ||
      nextState.isUpdating !== this.state.isUpdating
    );
  }
  componentDidUpdate(prevProps: IProps) {
    // 閃爍效果
    this.state.isUpdating &&
      (this.updateFlag = setTimeout(() => {
        this.state.isUpdating && this.setState(() => ({ isUpdating: false }));
      }, 500));
  }

  componentWillUnmount() {
    this.updateFlag && clearTimeout(this.updateFlag);
  }

  handleClick = (event) => {
    const {
      price,
      size,
      marketInfo,
      selectionInfo,
      fancyInfo,
      type,
      eventInfo,
      sideStatus,
      app: { isMobileWidth },
      mainLayoutContext: { isBettingOpened, activateBetting },
      orderListContext: { handleListAdd, isOrderLoading },
    } = this.props;
    const { keyName } = this.state;
    // 判斷是否拿到有用的值
    const isContinueBet =
      marketInfo.marketType === EMarketType.Multi_Selection_Fancy
        ? !!price
        : !!price;
    if (
      isContinueBet &&
      !isOrderLoading &&
      !isBettingOpened &&
      sideStatus !== "SUSPENDED"
    ) {
      const orderTicket: IOrderTicket = {
        type,
        price,
        size,
        eventInfo,
        marketInfo,
        selectionInfo,
        fancyInfo,
        keyName,
      };
      handleListAdd(orderTicket);
      !isMobileWidth && !isBettingOpened && activateBetting();
    }
  };
  render(): JSX.Element {
    const {
      price,
      size,
      type,
      isBest,
      sideStatus,
      marketInfo,
      eventInfo,
      fancyInfo,
      orderListContext: { isOrderLoading },
      mainLayoutContext: { isBettingOpened },
      app: { exchangeRate, bfInfo = [] as any },
      totalSize,
      selectionInfo,
    } = this.props;
    const { isUpdating } = this.state;
    const backStatus = (selectionInfo && selectionInfo.backStatus) || "";
    const layStatus = (selectionInfo && selectionInfo.layStatus) || "";
    const { marketType, isOrder, oddType } = marketInfo;
    const { bfPt } =
      bfInfo.find((_) => _.categoryId === eventInfo.categoryId) || ({} as any);
    const { isCompetitionOrder, isEventOrder } = eventInfo;
    // 上面的值
    const upperValue =
      marketType === EMarketType.FANCY
        ? size // 這邊是代表fancy的runs
        : oddType === EOddsType.AM // Mulity Fancy & Match Odd price
        ? oddsUtil.EUtoAM(price)
        : price;
    let lowerValue: string | number;
    switch (marketType) {
      case EMarketType.FANCY:
        let exRateNormalFancy = cookieUtil.get(ECookieName.COOKIE_TOKEN)
          ? exchangeRate
          : 1;
        if (marketInfo.resourceFrom === "DRINS") {
          lowerValue = Math.floor(exRateNormalFancy * size);
        } else {
          lowerValue =
            oddType === EOddsType.AM ? oddsUtil.EUtoAM(price) : price;
        }
        break;
      case EMarketType.Multi_Selection_Fancy:
        let exRateFancy = cookieUtil.get(ECookieName.COOKIE_TOKEN)
          ? exchangeRate
          : 1;
        if (marketInfo.resourceFrom === "DRINS") {
          lowerValue = Math.floor(exRateFancy * size);
        } else {
          lowerValue = totalSize === 0 || size ? "999+" : "0";
        }
        break;
      // Code Changed - Manoj (added EMarketType.MAIN (SINCE SNK VALUE IS 1) & lowerValue = size - not sure why there is not marketType)
      // case EMarketType.MAIN:
      // 	let exRate = cookieUtil.get(ECookieName.COOKIE_TOKEN) ? exchangeRate : 1

      // 	if (bfPt === 0 || bfPt === undefined) {
      // 		lowerValue = Math.floor(exRate * (size) * 100);
      // 	} else {
      // 		lowerValue = Math.floor(exRate * (size / bfPt) * 100);
      // 	}
      // 	break;
      // Code Changed for Drinks
      case EMarketType.MAIN:
        let exRate = cookieUtil.get(ECookieName.COOKIE_TOKEN)
          ? exchangeRate
          : 1;
        if (
          bfPt === 0 ||
          bfPt === undefined ||
          marketInfo.resourceFrom === "DRINS"
        ) {
          lowerValue = Math.floor(exRate * size);
        } else {
          lowerValue = Math.floor(exRate * (size / bfPt) * 100);
        }
        break;
      // Code Change Drinks ends here
      // Code Changed - Manoj (added default & lowerValue = size - not sure why there is not marketType)
      default:
        let defautlExchangeRate = cookieUtil.get(ECookieName.COOKIE_TOKEN)
          ? exchangeRate
          : 1;
        if (marketInfo.resourceFrom === "DRINS") {
          lowerValue = Math.floor(defautlExchangeRate * size);
        } else {
          lowerValue = size;
        }
    }
    // const isEmpty = marketType === EMarketType.Multi_Selection_Fancy ? !price : !price || !size;
    // const isEmpty = marketType === EMarketType.MAIN ? !price : !price || !size;
    const isEmpty = !price || (Number(size) === 0 && Number(price) === 0);
    // Code Changed - Manoj ( removed !size  and ! price)
    const isZeroOdds = oddType === EOddsType.AM;
    const isAutoSuspend =
      marketType === EMarketType.Multi_Selection_Fancy
        ? totalSize && !Math.floor(exchangeRate * (size / bfPt) * 100)
        : !price;

    const OddsContentElement = (
      <>
        <OddsPrice type={type}>
          {oddType === EOddsType.EU ? (
            <Amount
              fractionDigits={
                marketInfo.resourceFrom === "BETFAIR" && upperValue >= 20
                  ? 0
                  : 2
              }
            >
              {upperValue}
            </Amount>
          ) : (
            <Amount
              fractionDigits={
                oddType === EOddsType.AM && Number.isInteger(upperValue) ? 0 : 2
              }
            >
              {upperValue}
            </Amount>
          )}
        </OddsPrice>

        {
          !this.props.homePage &&
            (lowerValue === 0 && marketInfo.resourceFrom === "BETFAIR" ? (
              <OddsSize type={type}>
                {typeof lowerValue === "string" ? (
                  lowerValue
                ) : lowerValue > 500000 ? (
                  "500,000+"
                ) : (
                  <Amount fractionDigits={0}>{lowerValue}</Amount>
                )}
              </OddsSize>
            ) : lowerValue !== 0 ? (
              <OddsSize type={type}>
                {typeof lowerValue === "string" ? (
                  lowerValue
                ) : lowerValue > 500000 ? (
                  "500,000+"
                ) : (
                  <Amount fractionDigits={0}>{lowerValue}</Amount>
                )}
              </OddsSize>
            ) : null)

          // 	lowerValue !== 0 ? <OddsSize type={type}>
          // 	{typeof lowerValue === 'string' ? lowerValue : <Amount fractionDigits={0}>{lowerValue}</Amount>}
          // </OddsSize> : (lowerValue === 0 && marketInfo.resourceFrom === 'BETFAIR') ? <OddsSize type={type}>
          // 	{typeof lowerValue === 'string' ? lowerValue : <Amount fractionDigits={0}>{lowerValue}</Amount>}
          // </OddsSize> : <OddsSize type={type}>
          // 	{typeof lowerValue === 'string' ? lowerValue : <Amount fractionDigits={0}>{lowerValue}</Amount>}
          // </OddsSize>
        }
      </>
    );

    return (
      <OddsWrapper
        type={type}
        onClick={(e) => this.handleClick(e)}
        // Code Changed - Manoj ( removed isEmpty)
        isEmpty={isEmpty}
        isUpdating={isUpdating}
        isBest={isBest}
        // 點擊無反應，且 UI 有提示
        isExplicitlyDisabled={
          !isOrder ||
          (eventInfo.categoryId !== EEventTypeId.fancybet &&
            (isCompetitionOrder === false || isEventOrder === false)) ||
          sideStatus === "SUSPENDED" ||
          isAutoSuspend ||
          (type === "BACK" &&
            util.getValue(marketInfo, ["backTotalSize"], 0) > 0 &&
            util.getValue(marketInfo, ["back", "size"], 0) === 0) ||
          (type === "LAY" &&
            util.getValue(marketInfo, ["layTotalSize"], 0) > 0 &&
            util.getValue(marketInfo, ["lay", "size"], 0) === 0) ||
          (type === "LAY" && layStatus === "SUSPENDED") ||
          (type === "BACK" && backStatus === "SUSPENDED") ||
          (isZeroOdds
            ? Number(upperValue) <= 0.01
            : Number(upperValue) <= 0.0001)
        }
        // 點擊無反應，但 UI 不提示
        isImplicitlyDisabled={isOrderLoading || isBettingOpened}
      >
        {/** Code Changed - Manoj (removed isEmpty since we want to show in-play lay value) */}
        {isEmpty ? <span>-</span> : OddsContentElement}
        {/* {OddsContentElement} */}
      </OddsWrapper>
    );
  }
}
export default withAppContext(
  withOrderListContext(
    withMainLayoutContext(Odd, ["isBettingOpened", "activateBetting"]),
    ["handleListAdd", "isOrderLoading"]
  )
);

const OddsPrice: any = styled.div`
  font-size: 15px;
  // color: #214076;
  color: #1f1f1f;
  // font-weight: 600;
  text-align: center;
  // margin-bottom: 0.25em;
  margin-bottom: 0.1em;
  font-weight: bold;

  @media only screen and (max-width: 1200px) {
    font-size: 13px;
  }
`;

const OddsSize: any = styled.div`
  font-size: 10px;
  text-align: center;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  width: 100%;
  color: ${theme("palette.text-dark")};
`;

const Flash = keyframes`
	25% {
		background-color: rgba(255, 255, 255, 0.85);
	}
`;

export const OddsWrapper: any = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  // width: 30%;
  width: 55px;
  /* width: 65px; */
  flex-grow: 1;
  // padding: 0.25em 0.5em;
  padding: 0.12em 0.5em;
  margin: 0px 0.15em;
  position: relative;
  z-index: 0;
  text-align: center;
  // border-radius: 2px;
  // min-height: 48px;
  min-height: 42px;
  cursor: ${ifProp("isEmpty", "not-allowed", "pointer")};

  /* background color */
  background-color: ${(props: any) => {
    const { type, isBest, isEmpty } = props;

    if (isEmpty) {
      return "#CED8EA";
    }

    switch (type) {
      case "BACK":
        return "#82cfff";
      case "LAY":
        return "#ffb5bd";
      default:
        return "";
    }
  }};

  ${ifProp(
    "isEmpty",
    css`
      pointer-events: none;
      cursor: "not-allowed";
      opacity: 0.5;
    `
  )};

  ${ifProp(
    "isUpdating",
    css`
      color: black;
      animation: ${Flash} 0.5s ease;
    `
  )};

  ${ifProp(
    "isExplicitlyDisabled",
    css`
      pointer-events: none;
      cursor: "not-allowed";
      opacity: 0.5;
      background-color: rgb(163, 163, 163);
    `
  )};

  ${ifProp(
    "isImplicitlyDisabled",
    css`
      pointer-events: none;
    `
  )};

  &:hover {
    ${ifNotProp(
      "isEmpty",
      css`
        box-shadow: 0px 0px 0px 3px
          ${switchProp("type", {
            BACK: "#19a1ef inset",
            LAY: "#b5586c inset",
          })};
        transition: 0.5s ease;
      `
    )};
  }

  @media only screen and (max-width: 1200px) {
    width: 45px;
  }
`;
