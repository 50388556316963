import { format } from 'date-fns';
import React, { createRef } from 'react';

// Dependent Components
import { ICancelOrders, IUpdateOrders, ORDER_BF_API_TIMEOUT } from '../../../../store/MatchListContext';
import { IEventInfo, IFancyInfo, IMarketInfo, IMarketSource, IOrderLimit, ISelectionInfo } from '../../../../graphql/schema';
import EventTypeConfig from '../../../../config/eventTypeConfig';
import routerUtil from '../../../../util/routerUtil';
import util from '../../../../util/util';


export interface IUnmatchedSlipProps {
	// own props
	isMobileWidth?: boolean;

	// Unmatched slip info
	categoryId: number;
	competitionId?: string | null | undefined;
	eventId: string;
	marketId: string;
	side: 'BACK' | 'LAY';
	eventName: string;
	selectionName: string;
	exchangeRate: number;
	marketType?: number | null;
	price: number;
	stake: number;
	orderNumber: string;
	betDelay?: number | null;

	// from MainLayoutContext
	activateBetting?: () => void;

	// from OrderListContext
	quickStakes: number[];
	cancelAllOrder?: () => void;

	// from MatchListContext
	cancelOrder: (params: ICancelOrders) => {};
	updateOrder: (params: IUpdateOrders) => {};
	syncUpdatedUnmatchedSlip?: (orderNumber: string, value: { price: number; size: number }) => void;
	removeUpdatedUnmatchedSlip: (orderNumber: string) => void;
	closeMobileUnmatchedSlip?: () => void;
	refetch: () => void;
	selectionId: string;
	isMatchLoading?: boolean;
	provider?: string,
	p: string
}

interface IUnmatchedSlipState {
	currentPrice: number;
	currentStake: string;
	isUpdating: boolean;
	isCanceling: boolean;
	message: string;
	propStake: number;
}

interface IUnmatchedSlipStore extends IUnmatchedSlipProps, IUnmatchedSlipState {
	// methods
	handleOddsChange: (value: string) => void;
	handleStakeChange: (e: any) => void;
	handleTrashClick: () => any;
	handleUpdateOrder: () => any;
	handleQuickStake: (stake: number) => () => void;
	handleOnClickInput: (e: any) => void;
	handleCloseMobileUnmatchedSlip: () => void;
	goToEvent: () => void;

	// others
	slipRef: any;
}

interface IUnmatchedSlipContainerProps extends IUnmatchedSlipProps {
	children: (unmatchedSlipStore: IUnmatchedSlipStore) => JSX.Element;
}

class UnmatchSlipContainer extends React.PureComponent<IUnmatchedSlipContainerProps, IUnmatchedSlipState> {
	state = {
		currentPrice: this.props.price,
		currentStake: util.numMultiply(this.props.stake, this.props.exchangeRate).toFixed(2),
		isUpdating: false,
		isCanceling: false,
		message: '',
		propStake: this.props.stake,
	};

	slipRef = createRef();

	static getDerivedStateFromProps(nextProps: IUnmatchedSlipProps, prevState: IUnmatchedSlipState) {
		const { stake: nextPropStake, exchangeRate } = nextProps;
		const { propStake: prevPropStake } = prevState;

		return nextPropStake === prevPropStake
			? null
			: {
					currentStake: util.numMultiply(nextPropStake, exchangeRate).toFixed(2),
					propStake: nextPropStake,
			  };
	}

	componentDidMount() {
		const { isMobileWidth, cancelAllOrder } = this.props;
		const slipNode: any = this.slipRef.current;

		if (isMobileWidth) {
			setTimeout(() => {
				slipNode && slipNode.scrollIntoView(false);
			}, 750);

			// 清除 betArray
			typeof cancelAllOrder === 'function' && cancelAllOrder();
		}

		window.addEventListener('keypress', this.handlePressEnter);
	}

	componentWillUnmount() {
		window.addEventListener('keypress', this.handlePressEnter);
	}

	handlePressEnter = (e: any) => {
		const slipNode: any = this.slipRef.current;
		const { key } = e;
		key === 'Enter' && slipNode && slipNode.querySelectorAll('*:focus').length > 0 && this.handleUpdateOrder();
	};

	handleTrashClick = async () => {
		const { marketId, orderNumber, refetch, removeUpdatedUnmatchedSlip, provider } = this.props;
		this.setState({ isCanceling: true });
		try {
			const payload = await this.props.cancelOrder({
				marketId,
				orderNumber,
				localCreatedTime: format(new Date(), 'yyyy-MM-dd hh:mm:ss'),
				provider,
			});

			// 清除 MatchListContext 中記錄的更新後的 unmatched slip
			removeUpdatedUnmatchedSlip(orderNumber);

			if (util.getValue(payload, 'data.exchange.cancelOrder.success')) {
				refetch();
			} else {
				// 失敗才重新讓注單出現
				this.setState({ isCanceling: false });

				// cancel失敗可能狀態已經變更了，所以2秒後重load
				setTimeout(() => refetch(), 2000);
				this.setState({ message: util.getValue(payload, 'data.exchange.cancelOrder.message') });
			}
		} catch (e) {
			this.setState({ isCanceling: false });
		}
	};

	handleUpdateOrder = async () => {
		const {
			marketId,
			orderNumber,
			exchangeRate,
			refetch,
			eventId,
			categoryId,
			selectionId,
			side,
			competitionId,
			marketType,
			isMobileWidth,
			activateBetting,
			betDelay,
			provider
		} = this.props;
		const { currentStake: stake, currentPrice: price } = this.state;
		console.log('Print me proviuder', provider, this.props)
		try {
			this.setState({ isUpdating: true });

			const payload = await this.props.updateOrder({
				side,
				selectionId,
				betfairOrderType: 'LIMIT',
				categoryId,
				eventId,
				marketId,
				orderNumber,
				competitionId: competitionId || '',
				size: Number(stake) / exchangeRate,
				price,
				marketType: marketType ? marketType : 1,
				localCreatedTime: format(new Date(), 'yyyy-MM-dd hh:mm:ss'),
				betDelay: betDelay || 0,
				provider
			});

			this.setState({ isUpdating: false });

			if (util.getValue(payload, 'success')) {
				refetch();
				// activate betting area on mobile device
				isMobileWidth && typeof activateBetting === 'function' && activateBetting();
			} else {
				util.getValue(payload, 'code') !== ORDER_BF_API_TIMEOUT &&
					this.setState({ message: util.getValue(payload, 'message') });
			}
		} catch (e) {
			this.setState({ isUpdating: false });
			console.error('update order exception', e);
		}
	};

	handleQuickStake = (stake: number) => () => {
		const { isUpdating, isCanceling } = this.state;
		!isUpdating &&
			!isCanceling &&
			this.setState(
				{ currentStake: (Number(this.state.currentStake) + Number(stake)).toFixed(2) },
				this.syncUpdatedUnmatchedSlipCallback,
			);
	};

	handleStakeChange = (e: any) => {
		if (/^[0-9]*(\.)?[0-9]*$/.test(e.target.value)) {
			this.setState({ currentStake: e.target.value }, this.syncUpdatedUnmatchedSlipCallback);
		}
	};

	handleOddsChange = (value: string) => {
		this.setState({ currentPrice: Number(value) }, this.syncUpdatedUnmatchedSlipCallback);
	};

	syncUpdatedUnmatchedSlipCallback = () => {
		const { orderNumber, syncUpdatedUnmatchedSlip } = this.props;
		const { currentPrice, currentStake } = this.state;
		typeof syncUpdatedUnmatchedSlip === 'function' &&
			syncUpdatedUnmatchedSlip(orderNumber, { price: currentPrice, size: Number(currentStake) });
	};

	goToEvent = () => {
		const { categoryId, competitionId, eventId } = this.props;
		const path = `/eu-content/${EventTypeConfig(categoryId).eventTypeKey}/${competitionId || 'e'}/${eventId}`;
		routerUtil.push(path);
	};

	handleOnClickInput(e: any) {
		e.target.select();
	}

	handleCloseMobileUnmatchedSlip = () => {
		const { isMobileWidth, closeMobileUnmatchedSlip, removeUpdatedUnmatchedSlip, orderNumber } = this.props;
		isMobileWidth
			? typeof closeMobileUnmatchedSlip === 'function' && closeMobileUnmatchedSlip()
			: console.log('[UnmatchedSlipContainer]: No mobile unmatched to close because not on mobile width!');
		removeUpdatedUnmatchedSlip(orderNumber);
	};

	render(): JSX.Element {
		// props 是 api 傳過來原始的值要乘上 exchange rate，state的是使用者看到的值則不用
		const { children, ...otherProps } = this.props;
		const unmatchedSlipStore: IUnmatchedSlipStore = {
			// props
			...otherProps,
			// state
			...this.state,

			// methods
			handleOddsChange: this.handleOddsChange,
			handleStakeChange: this.handleStakeChange,
			handleTrashClick: this.handleTrashClick,
			handleUpdateOrder: this.handleUpdateOrder,
			handleQuickStake: this.handleQuickStake,
			handleOnClickInput: this.handleOnClickInput,
			handleCloseMobileUnmatchedSlip: this.handleCloseMobileUnmatchedSlip,
			goToEvent: this.goToEvent,

			// others
			slipRef: this.slipRef,
		}
		return this.state.isCanceling ? <></> : children(unmatchedSlipStore);
	}
}

export default UnmatchSlipContainer;
