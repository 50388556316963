import React from 'react';
import styled, { css } from 'styled-components';
import { ifProp, theme, withProp } from 'styled-tools';

// Components
import { titleFont } from '../../../common/commonCSSStyles';
import Openable from '../../../common/Openable';
import Icon, { IconWrapper } from '../../../common/PNGIcon';

// Context
import { TimeZone } from '../../../../store/TimeZoneContext';

// Util
import { diffColor } from '../../../../util/colorUtil';

interface IUpcomingContainerProps {
	title: string;
	marketStartTime: number;
}

const UpcomingContainer: React.SFC<IUpcomingContainerProps> = props => {
	const { title, marketStartTime, children } = props;
	return (
		<Openable>
			{({ isOpened, toggleOpen }) => {
				return (
					<Wrapper>
						{/* <TitleWrapper onClick={toggleOpen} isOpened={isOpened}> */}
							{/** Code Changed - Manoj ( add Bookmaker test) */}
							{/* {title} - <TimeZone>{marketStartTime}</TimeZone> */}
							{/* <span>BOOK MAKER 0% COMM FAST BET</span> */}
							{/* <Icon name="chevron-up-white" /> */}
						{/* </TitleWrapper> */}
						{/** Code Changed - Manoj ( disabled isOpened) */}
						{/* {isOpened && children} */}
						{children}
					</Wrapper>
				);
			}}
		</Openable>
	);
};

export default UpcomingContainer;

// Styled

const TitleWrapper: any = styled.div`
	${titleFont};
	font-size: 12px;
	display: flex;
	justify-content: space-between;
	cursor: pointer;
	margin-bottom: 1em;
	padding: 0.75em 1em;
	position: relative;
	z-index: 0;

	${IconWrapper} {
		transition: transform 0.15s ease-in;
	}

	&::before {
		content: '';
		display: block;
		position: absolute;
		z-index: -1;
		top: 0;
		bottom: 0;
		left: 0;
		width: 2px;
		background-color: ${theme('palette.primary-background')};
		transform: scaleX(0);
	}

	&::after {
		content: '';
		display: block;
		position: absolute;
		z-index: -2;
		top: 0;
		right: 0;
		bottom: 0;
		left: 0;
		border-radius: 2px;
		background-color: ${withProp('theme.palette.body-background', diffColor(0.1))};
		color: ${theme('palette.body-foreground')};
	}

	&:hover {
		&::after {
			background-color: ${withProp('theme.palette.body-background', diffColor(0.2))};
		}

		${IconWrapper} {
			transition: transform 0.2s ease-out;
			transform: rotate(180deg);
		}
	}

	${ifProp(
		'isOpened',
		css`
			&::before {
				transform: scaleX(1);
			}

			&::after {
				opacity: 1;
			}

			${IconWrapper} {
				transition: transform 0.2s ease-out;
				transform: rotate(180deg);
			}
		`,
	)};
`;

const Wrapper = styled.div`
	width: 100%;
	// margin-bottom: 1em;
`;
