import React, { useEffect } from 'react'
import { withAppContext } from 'src/store/initAppContext';
import styled, { withTheme } from 'styled-components';
import { LazyLoadImage } from "react-lazy-load-image-component";
import config from "src/config/config";
import PlaceholderImage from 'src/components/Placeholder';
import { theme } from 'styled-tools';
import FooterContainer from 'src/components/footer/footer';

function WomensWorldCupPromo(props) {
    const { app: {
        brandId
    }, theme } = props;

    useEffect(() => {
        window.scrollTo(0, 0)
    }, [])

    const domain = (brandId == 31 || brandId == 32) ? "Yolo247" : (brandId == 39 || brandId == 40) ? "FOMO7" : 'BaaziAdda';

    return (
        <>
            <InformationWrapper>
                <Title>
                    <p>{
                        (brandId === 31 || brandId === 32) ? '100% loss back bonus' : (brandId === 39 || brandId === 40) ? 'Daily Free Bets' : 
                        'Daily lossback bonus'
                        }
                    </p>
                </Title>
                <TextContainer>
                    {
                        (brandId === 31 || brandId === 32) && 
                        <TextContent>
                            <p><span className='greenTick'>✓</span>This CWC-W, walk the red carpet like a <br /> cricket superstar only on <span className='whiteBold'>{domain}</span>!</p>
                        </TextContent>
                    }
                    {
                        (brandId === 39 || brandId === 40) ? 
                        <TextContent>
                            <p><span className='greenTick'>✓</span>Lost the last bet on Women's World Cup? No worries, <br /> <span className='whiteBold'>{domain}</span> gives you a chance to play again!</p>
                        </TextContent> :
                        <TextContent>
                            <p><span className='greenTick'>✓</span>Lost the last bet? No worries, <span className='whiteBold'>{domain}</span> <br /> gives you a chance to play again!</p>
                        </TextContent>
                    }
                </TextContainer>
                <ImageWrapper>
                    <LazyLoadImage
                      src={`${config.imageV3Host}/static/cashsite/${theme.image.WomensWorldCup2024Teams}`}
                      alt="jetxPromoStepOne"
                      effect="none"
                      placeholder={<PlaceholderImage />}
                    />
                </ImageWrapper>
                <BonusWrapper>
                    <BonusContent>
                        <LazyLoadImage
                          src={`${config.imageV3Host}/static/cashsite/${theme.image.WomensWorldCup2024GiftBox}`}
                          alt="womenWcBonus"
                          effect="none"
                          placeholder={<PlaceholderImage />}
                        />
                        <BonusText>
                            <h1>Claim your Prize</h1>
                            {(brandId === 39 || brandId === 40) ?
                                <p>For cumulative losses of the day, we'll reward you with an <span className='whiteBold'>100% additional</span> <br /> <span className='whiteBold'>loss back bonus</span> the very next day on Exchange bets.</p> : 
                                <p>For cumulative losses of the day, we'll reward you with an <span className='whiteBold'>100% additional</span> <br /> <span className='whiteBold'>loss back bonus</span> the very next day.</p>
                            }
                        </BonusText>
                    </BonusContent>
                </BonusWrapper>
                {
                    (brandId === 31 || brandId === 32) ? 
                    <WomensWCTermsAndCondition>
                        <h4>Terms & Conditions</h4>
                        <ul>
                            <li>The loss back bonus will be applied to ‘<span className='whiteBold'>lost CWC-W EXCHANGE bets</span> - for the day</li>
                            <li>The bonus amount for each qualifying bet will be capped at INR 50.</li>
                            <li>The bonus will be credited to your {domain} account the next day.</li>
                            <li>Bonus will be expired within 24-48 hours.</li>
                            <li>There is no limit to the number of bets you can place to earn the 100% lossback bonus, ‘for the day’</li>
                            <li>The loss back bonus can be used to place future bets on {domain} as this bonus cannot be withdrawn. Only winnings can be withdrawn.</li>
                            <li>This promotion is valid only for the CWC-W. {domain} reserves the right to disqualify any user found engaging in fraudulent activities.</li>
                            <li>{domain} General Terms & Conditions apply.</li>
                        </ul>
                    </WomensWCTermsAndCondition> :
                    (brandId === 39 || brandId === 40) ? 
                    <WomensWCTermsAndCondition>
                        <h4>Terms & Conditions</h4>
                        <ul>
                            <li>The loss back bonus will be applied to ‘<span className='whiteBold'>lost Women’s World Cup EXCHANGE bets</span> - for the day.</li>
                            <li>The bonus amount for each qualifying bet will be capped at INR 50.</li>
                            <li>The bonus will be credited to your {domain} account the next day.</li>
                            <li>Bonus will be expired within 24-48 hours.</li>
                            <li>There is no limit to the number of bets you can place to earn the 100% lossback bonus, ‘for the day’.</li>
                            <li>The loss back bonus can be used to place future bets on {domain} as this bonus cannot be withdrawn. Only winnings can be withdrawn.</li>
                            <li>This promotion is valid only for the Women’s World Cup ‘24.</li>
                            <li>{domain} reserves the right to disqualify any user found engaging in fraudulent activities.</li>
                            <li>{domain} General Terms & Conditions apply.</li>
                        </ul>
                    </WomensWCTermsAndCondition> :
                    <WomensWCTermsAndCondition>
                        <h4>Terms & Conditions</h4>
                        <ul>
                            <li>The loss back bonus will be applied to ‘<span className='whiteBold'>lost Women’s World Cup EXCHANGE bets</span> - for the day.</li>
                            <li>The bonus amount for each qualifying bet will be capped at INR 50.</li>
                            <li>The bonus will be credited to your {domain} account the next day.</li>
                            <li>Bonus will be expired within 24-48 hours.</li>
                            <li>There is no limit to the number of bets you can place to earn the 100% lossback bonus, ‘for the day’</li>
                            <li>The loss back bonus can be used to place future bets on {domain} as this bonus cannot be withdrawn. Only winnings can be withdrawn.</li>
                            <li>This promotion is valid only for the Women’s World Cup.</li>
                            <li>{domain} reserves the right to disqualify any user found engaging in fraudulent activities.</li>
                            <li>{domain} General Terms & Conditions apply.</li>
                        </ul>
                    </WomensWCTermsAndCondition>
                }
            </InformationWrapper>
            <FooterContainer />
        </>
    )
}



export default withTheme(withAppContext(WomensWorldCupPromo));

const InformationWrapper = styled.div`
    width: 98%;
    height: auto;
    padding: 3rem 3rem 8rem 3rem;
    border-radius: 1px;
    background: rgba(111, 111, 111, 0.5);
    margin: 0em 1.2em 0 1.2em;
    border: 1px solid #E5B358;
    border-radius: 0 0 20px 20px;
    border-top-color: transparent;
    backdrop-filter: brightness(0.5);    
    display: flex;
    flex-direction: column;
    background: url(${config.imageV3Host}/static/cashsite/${theme("image.WomensWorldCupBackground")});
    background-position: top;
    background-size: cover;
    background-repeat: no-repeat;

    .whiteBold {
        font-weight: 700;
    }
`;

const Title = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;

    p {
        color:#FFEB37;
        text-align: center;
        text-shadow: 0px 0px 10px rgba(255, 235, 55, 0.50);
        font-size: 20px;
        font-style: normal;
        font-weight: 700;
        line-height: normal;
        text-transform: capitalize;
    }
`;

const TextContainer = styled.div`
    display: flex;
    justify-content: center;
    align-items: stretch;
    gap: 2rem;
    padding: 3rem 0;
`;

const TextContent = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 14px;
    border: 2px solid #535D7E;
    background: rgba(42, 48, 69, 0.40);
    backdrop-filter: blur(6px);
    /* padding: 2rem 4rem; */
    padding: 1.5rem 4rem;
    width: 40%;

    p {
        color: #FFF;
        font-size: 15px;
        font-style: normal;
        font-weight: 400;
        line-height: 25px;
        text-transform: capitalize;
        text-align: center;

        .greenTick {
            color: #6AD035;
            font-size: 15px;
            font-style: normal;
            font-weight: 900;
            line-height: 25px; /* 166.667% */
            padding-right: 0.5rem;
        }
    }
`;

const ImageWrapper = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 1rem 0;
    text-align: center;
    width: 100%;

    img {
        width: 65%;
    }
`;

const BonusWrapper = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 2rem 0;
`;

const BonusContent = styled.div`
    display: flex;
    align-items: center;
    justify-content: flex-start;
    padding: 1rem 7rem;
    border-radius: 14px;
    border: 2px solid #535D7E;
    background: rgba(42, 48, 69, 0.40);
    backdrop-filter: blur(6px);
    gap: 3rem;
    position: relative;

    img {
        width: 50%;
    }

    ::before {
        content: '';
        position: absolute;
        background: url(${config.imageV3Host}/static/cashsite/${theme("image.WomensWorldCupBonusImage")});
        width: 9rem;
        height: 3rem;
        background-position: top;
        background-size: contain;
        background-repeat: no-repeat;
        position: absolute;
        top: -0.6rem;
        left: 50%;
        transform: translateX(-50%);
    }
`;

const BonusText = styled.div`
    display: flex;
    align-items: flex-start;
    justify-content: center;
    flex-direction: column;
    gap: 1.2rem;
    margin-left: -10rem;

    h1 {
        color: #FFEB37;
        text-shadow: 0px 0px 10px rgba(255, 235, 55, 0.50);
        font-size: 20px;
        font-style: normal;
        font-weight: 700;
        line-height: normal;
        text-transform: capitalize;
        padding: 0;
        margin: 0;
    }

    p {
        color: #FFF;
        font-size: 15px;
        font-style: normal;
        text-align: left;
        font-weight: 500;
        line-height: 150%; /* 22.5px */
        text-transform: capitalize;
        padding: 0;
        margin: 0;
    }
`; 

const WomensWCTermsAndCondition = styled.div`
    width: 100%;
    height: auto;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    gap: 10px;
    margin: 4rem 0rem 5rem 0rem;
    padding: 0 9rem 0 11rem;
    position: relative;

    & li {
        color: #FFF;
        text-align: justify;
        font-size: 15px;
        font-style: normal;
        font-weight: 400;
        line-height: 28px; /* 186.667% */
        text-transform: capitalize;
    }

    & h4 {
        color: #FFEB37;
        text-shadow: 0px 0px 12px rgba(255, 235, 55, 0.50);
        font-size: 20px;
        font-style: normal;
        font-weight: 700;
        line-height: normal;
        text-transform: capitalize;
        margin: 10px 0px 0px 20px;
    }

    .bold {
        color: #FFF;
        font-weight: 700;
    }
`;