import { useEffect, useState } from 'react';
import { GET_BALANCE_INFO, GET_PLAY_GAME } from '../graphql/game';
import { ONE_CLICK_RECYCLE } from '../graphql/system';
import { useQuery, useMutation, useLazyQuery } from '@apollo/client';
// import Spinner from '../components/common/Spinner';
import util from '../util/util';
import styled, { withTheme } from 'styled-components';
// import Super7Logo from '../images/i_exch_loader.gif';
// import Super7Logo from '../images/i_exch_Logo-client_GIF.gif';
// import Intl from '../components/common/Intl'
import { withAppContext } from 'src/store/initAppContext';
import cookieUtil from 'src/util/cookieUtil';
import { ECookieName } from 'src/util/utilModel';
import config from 'src/config/config';
import { ifProp, theme, withProp } from 'styled-tools';
// import graphqlApiUtil from 'src/util/graphqlApiUtil';
import _isEmpty from 'lodash/isEmpty';
import EventNotFound from 'src/components/eu-content/EventNotFound';
import { Player } from '@lottiefiles/react-lottie-player';

interface IProps {
	platformDetails: any;
}

interface IState {
	iframeSrc: string;
	platformCode: string;
	gameName: string;
	isLoading: boolean;
}

interface IProps {
	app: { query: any, token: any, type: string, username: string };
	gameCode: any;
}

type StyleProps = {
	animType : boolean;
}

// const _animationImg = '/static/mobile/brand/31/image/casino-coins.gif'

const _animationImg = '/static/cashsite/brand/6/json/casinoCoinAnimation25.json'

export const BalanceAnimation = ({ duration = 7400, imgSrc, ...props }) => {
	const [animationState, setAnimationState] = useState(true); 

	// Helper function to determine file type
	const isJson = (src) => src?.endsWith('.json');

	useEffect(() => {
		let t;
		clearTimeout(t);
		t = setTimeout(() => {
			setAnimationState(false)
		}, duration);

		return () => {
			clearTimeout(t)
		}

	}, []);

	return ((imgSrc && animationState) ? (
	<AnimContainer {...props} animType={isJson(imgSrc)}>
		{isJson(imgSrc) ? (
			<Player
				autoplay
				loop
				controls
				src={`${config.imageV3NonOptHost}${imgSrc}`}
				style={{ height: '80%', width: '80%' }}
			></Player>
		) : (
			<img
				src={`${config.imageV3NonOptHost}${imgSrc}`}
				alt="coin-animation"
			/>
		)}
	</AnimContainer>
) : null
	)
}

function Loading(props: any) {
	const { app: {
		brandId
	}, theme } = props

	let homeURLpath = window.location.origin;
	// const {platformCode, gameCode} = props.platformDetails 
	const [isLoading, setLoading] = useState(true);
	const [iframeUrl, setIframeUrl] = useState('')
	const [gameName, setGameName] = useState('')
	const [noGame, setNoGame] = useState(false)
	const { code, game, id, imgsrc, platformCode, type } = props.platformDetails
	const [triggerOneClickRecycle, { recycleLoading, recycleError, recycleData }]: any = useMutation(ONE_CLICK_RECYCLE)
	
	const [getIframeData, { loading, error }] = useMutation(GET_PLAY_GAME, {
		variables: {
			input: {
				platformCode,
				gameCode: code || '',
				homeURL: homeURLpath
			},
		}
	});

	//Query for Balance Info
	const { data, startPolling, stopPolling } = useQuery(GET_BALANCE_INFO, {
		variables: {
			input: {
				platformCodes: ['WACS'],
				target: util.getTargetString({ memberId: props.app.memberId, type: props.app.type }),
			},
		}
	});

	const balance = util.getValue(data, ['game', 'balanceInfo', '0', 'balance'], '-',);

	useEffect(() => {
		startPolling(7000); // poll interval
		// if(!isLoading){
		// 	stopPolling();
		// }
		return () => {
			stopPolling();
		};
	}, [balance])




	//Hitting GET_PLAY_GAME api to get the IframeURL
	useEffect(() => {
		getIframeData().then(async (response) => {
			const payload = await util.getValue(response, ['data', 'game', 'playgame'], {});
			setIframeUrl(payload.data.url)
		}).catch((error) => {
			setNoGame(true)
		})
		return () => {
			cookieUtil.get(ECookieName.COOKIE_TOKEN) && triggerOneClickRecycle()
				.then((response) => {
					const result = util.getValue(response, ['data', 'game', 'recycleTransfer'], {});
					const { success, message, data } = result;
					if (success) {
						return
					}
				})
				.catch((error) => {
				    console.error('Error in one click recycle');
				})
		}
	}, [props.platformDetails])


	if (loading) {
		return null
	}
	if (error) { return <p>Error...</p> }

	const hideSpinner = () => {
		setLoading(false)
	}

	return (
		<>{noGame ?
			<EventNotFound />
			:
			<GameContainer>
				{isLoading ? (
					<LoadingWrap> <img src={`${config.imageV3NonOptHost}/static/cashsite/${theme.image.loaderYOLO247}`} alt="logonew" /></LoadingWrap>
				) : null}

				<BalanceBar style={{ 'display': props.platformDetails.code.includes('BGAMES') ? 'block' : 'none' }}>
					<CoinBalance>
						<span>Coin Balance : {balance}</span>
					</CoinBalance>
				</BalanceBar>
				<Main>
				{!isLoading  && <BalanceAnimation imgSrc={_animationImg}  />}
					{props.modalState && <iframe
						id='iframe'
						src={iframeUrl}
						width="100%"
						height="700"
						onLoad={hideSpinner}
						frameBorder="0"
						marginHeight={0}
						marginWidth={0}
						allowFullScreen={true}
						title='GameIframe'
					/>
					}
				</Main>
			</GameContainer>
		}
		</>

	)
}

export default withTheme(withAppContext(Loading));

export const LoadingWrap = styled.div`
	position: absolute;
	display: flex;
	width: 100%;
	justify-content: center;
    align-items: center;
	/* background: ${theme('palette.secondary-background-color-linear-new')}; */
	& > img {
		width: 120px !important;
		/* height: 100px; */
	}
	height: 100vh;
`;

const GameContainer = styled.div`
	width: 100%;
	height: 100%;
	display: flex;
	flex-direction: column;
	position: relative;
`;
const Main = styled.div`
	margin-top: 0rem;
	flex: 1;
	> iframe {
		height:100%!important;
	}

`;

const BalanceBar = styled.div`
	padding: 10px;
	background: ${theme('palette.secondary-background-color-linear-new1')};
	color: white;
	flex: 0 0 40px;
	text-align: right;

`;

const CoinBalance = styled.div`
	text-align: right;
`;

const AnimContainer = styled.div<Pick<StyleProps, "animType">>`
    position: fixed;
    top: 0px;
    left: ${(props) => (props.animType ? "100px" : "0px")};
    width: 100%;
    height: 100%;
    background: rgb(0 0 0 / 10%);
    display: flex;
    justify-content: flex-end;
    flex-direction: row;
    padding-right: 70px;
    z-index: 999;

	> img {
		max-width: 60%;
		max-height: 60%;
	}

	> svg {
		max-width:100%;
		max-height:100%;
	}
`


