import React from "react";

type Props = {};

const CircleUp = (props: Props) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="9"
      height="9"
      viewBox="0 0 9 9"
      fill="none"
    >
      <rect
        x="8.2998"
        y="8.2998"
        width="8.29982"
        height="8.29982"
        rx="4.14991"
        transform="rotate(180 8.2998 8.2998)"
        fill="#505050"
      />
      <path
        d="M2.32324 3.11243L4.14968 5.18738L5.97611 3.11243"
        stroke="white"
        stroke-width="1.24497"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  );
};

export default CircleUp;
