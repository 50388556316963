import React, { useEffect } from 'react'
import { withAppContext } from 'src/store/initAppContext';
import styled, { withTheme } from 'styled-components';
import { LazyLoadImage } from "react-lazy-load-image-component";
import config from "src/config/config";
import PlaceholderImage from 'src/components/Placeholder';
import { theme } from 'styled-tools';
import FooterContainer from 'src/components/footer/footer';
import { useNavigate } from "react-router-dom";

function SuperSlotsChallengePromo(props) {
    const { app: {
        brandId
    }, theme } = props;

    useEffect(() => {
        window.scrollTo(0, 0)
    }, [])

    const navigate = useNavigate()

    const domain = (brandId == 31 || brandId == 32) ? "Yolo247" : (brandId == 39 || brandId == 40) ? "Fomo7" : (brandId == 46 || brandId == 47) ? "Fun88" : 'iw247';

    return (
        <>
            <InformationWrapper>
                <TopContainer>
                    <LeftContainer>
                        <TextWrapper>
                            <h1>Super Slots Challenge on {domain}!</h1>
                            <p>{domain} introduces cash prizes and free bets  on your favorite Slot games across providers – <span className='customColor'>Jilli, Netent, Evo Play, M play, 7 Mojos , KA Gaming, Red Tiger, BTG & No Limit City.</span></p>
                            <p>Slot games that takes your betting experience to new heights.</p>
                            <p>Brace yourself for an edge of the seat adventure , where strategy meets luck.</p>
                            <p>A total pool prize of <span className='customColor'><strong>₹ 20,00,000</strong></span> and bonus awaits you!</p>
                            <p>Get your game face on and get ready to pull the lever!</p>
                        </TextWrapper>
                        <ButtonWrapper>
                            <Button onClick={() => navigate('/slots')}>Get Started</Button>
                            <Button className='customBtn'>Learn More</Button>
                        </ButtonWrapper>
                    </LeftContainer>
                    <RightContainer>
                        <ImageGrid className="BgImg">
                            <LazyLoadImage
                                src={`${config.imageV3Host}/static/cashsite/${theme.image.SSCPromoRowOne}`}
                                alt="superSlotChallegePromotion"
                                effect="none"
                                placeholder={<PlaceholderImage />}
                            />
                            <LazyLoadImage
                                src={`${config.imageV3Host}/static/cashsite/${theme.image.SSCPromoRowTwo}`}
                                alt="superSlotChallegePromotion"
                                effect="none"
                                placeholder={<PlaceholderImage />}
                            />
                            <LazyLoadImage
                                src={`${config.imageV3Host}/static/cashsite/${theme.image.SSCPromoRowThree}`}
                                alt="superSlotChallegePromotion"
                                effect="none"
                                placeholder={<PlaceholderImage />}
                            />
                        </ImageGrid>
                    </RightContainer>
                </TopContainer>
                <MidContainer>
                    <MidRightContainer>
                        <ImageGrid className='customeGrid'>
                            <LazyLoadImage
                                src={`${config.imageV3Host}/static/cashsite/${theme.image.SSCPromoMissionImage}`}
                                alt="superSlotChallegePromotion"
                                effect="none"
                                placeholder={<PlaceholderImage />}
                                className="MissionImg"
                            />
                        </ImageGrid>
                    </MidRightContainer>
                    <MidLeftContainer>
                        <TextWrapper>
                            <h5>Embark on an thrilling ride with supermodels & rockets:</h5>
                            <p className='rightText'><span className='customColor'>Bonus:</span> Players who play Slot games across the mentioned providers are only eligible for bonus and cash prizes</p>
                            <p className='rightText'><span className='customColor'>Bet and Win:</span> Place bets and be eligible for the pool prizes and free bets!</p>
                            <p className='rightText'><span className='customColor'>Free Bets :</span> Weekly bonus and Monthly cash prizes will be announced.</p>
                        </TextWrapper>
                    </MidLeftContainer>
                </MidContainer>
                <BottomContainer>
                    <CashPrizeWrapper>
                        <ImageGrid className='CPimage'>
                            <LazyLoadImage
                                src={`${config.imageV3Host}/static/cashsite/${theme.image.SSCPromoCashPrizeImage}`}
                                alt="superSlotChallegePromotion"
                                effect="none"
                                placeholder={<PlaceholderImage />}
                            />
                        </ImageGrid>
                        <CashPrizeLeftWrapper>
                            <h2>Cash Prizes <br /> How It Works</h2>
                            <p>Follow these simple steps to join the challenge and start winning!</p>
                            <Button onClick={() => navigate('/slots')}>Get Started</Button>
                        </CashPrizeLeftWrapper>
                    </CashPrizeWrapper>
                    <BonusWrapper>
                        <ColWrapper>
                            <ColOne>
                                <ImageGrid className='cashBackImg'>
                                    <LazyLoadImage
                                        src={`${config.imageV3Host}/static/cashsite/${theme.image.SSCPromoCashPrizeIconOne}`}
                                        alt="superSlotChallegePromotion"
                                        effect="none"
                                        placeholder={<PlaceholderImage />}
                                        className="cashBackIconImg"
                                    />
                                </ImageGrid>
                                <h3>Visit {domain}</h3>
                                <p>Log in to your {domain} account and head to the Live Casino section and then choose Slots!</p>
                            </ColOne>
                            <ColOne>
                                <ImageGrid className='cashBackImg'>
                                    <LazyLoadImage
                                        src={`${config.imageV3Host}/static/cashsite/${theme.image.SSCPromoCashPrizeIconTwo}`}
                                        alt="superSlotChallegePromotion"
                                        effect="none"
                                        placeholder={<PlaceholderImage />}
                                        className="cashBackIconImg"
                                    />
                                </ImageGrid>
                                <h3>Wager</h3>
                                <p>The more you wager, the greater your chances of winning.</p>
                            </ColOne>
                        </ColWrapper>
                        <ColWrapper>
                            <ColOne>
                                <ImageGrid className='cashBackImg'>
                                    <LazyLoadImage
                                        src={`${config.imageV3Host}/static/cashsite/${theme.image.SSCPromoCashPrizeIconThree}`}
                                        alt="superSlotChallegePromotion"
                                        effect="none"
                                        placeholder={<PlaceholderImage />}
                                        className="cashBackIconImg"
                                    />
                                </ImageGrid>
                                <h3>Cash Prizes</h3>
                                <p>Our top <strong>200</strong> users with highest wager will get a pool prize of <strong>₹ 20 Lakhs</strong></p>
                            </ColOne>
                            <ColOne>
                                <ImageGrid className='cashBackImg'>
                                    <LazyLoadImage
                                        src={`${config.imageV3Host}/static/cashsite/${theme.image.SSCPromoCashPrizeIconFour}`}
                                        alt="superSlotChallegePromotion"
                                        effect="none"
                                        placeholder={<PlaceholderImage />}
                                        className="cashBackIconImg"
                                    />
                                </ImageGrid>
                                <h3>Validity</h3>
                                <p>Promotion will be valid from <span className='boldText'>15 Jan’25 to 14 Mar’25.</span></p>
                            </ColOne>
                        </ColWrapper>
                    </BonusWrapper>
                    <HeadingText><h3>CASH PRIZES</h3></HeadingText>
                    <PrizeWrapper>
                        <PrizeBox>
                            <h3>GAME</h3>
                            <LineBreaker></LineBreaker>
                            <p>Slots <br /> <span>(Jilli, Netent, Evo Play, M play, 7 Mojos , KA Gaming, Red Tiger, BTG & No Limit City)</span></p>
                        </PrizeBox>
                        <PrizeBox>
                            <h3>Period</h3>
                            <LineBreaker></LineBreaker>
                            <p>15 Jan’25 to 14 Mar’25</p>
                        </PrizeBox>
                        <PrizeBox>
                            <h3>Winners</h3>
                            <LineBreaker></LineBreaker>
                            <p>100 Every Month</p>
                        </PrizeBox>
                        <PrizeBox>
                            <h3>Pool Prize</h3>
                            <LineBreaker></LineBreaker>
                            <p><strong>₹ 10,00,000 Every Month</strong></p>
                        </PrizeBox>
                    </PrizeWrapper>
                    <WeeklyBonus>
                        <HeadingText>
                            <h3>Weekly Bonus</h3>
                            <p>Super Slots Challenge on {domain}!</p>
                        </HeadingText>
                        <BonusWrapper>
                            <ColWrapper>
                                <ColOne>
                                    <ImageGrid className='cashBackImg'>
                                        <LazyLoadImage
                                            src={`${config.imageV3Host}/static/cashsite/${theme.image.SSCPromoCashPrizeIconOne}`}
                                            alt="superSlotChallegePromotion"
                                            effect="none"
                                            placeholder={<PlaceholderImage />}
                                            className="cashBackIconImg"
                                        />
                                    </ImageGrid>
                                    <h3>Visit {domain}</h3>
                                    <p>Log in to your {domain} account and head to the Live Casino section and then choose Slots!</p>
                                </ColOne>
                                <ColOne>
                                    <ImageGrid className='cashBackImg'>
                                        <LazyLoadImage
                                            src={`${config.imageV3Host}/static/cashsite/${theme.image.SSCPromoCashPrizeIconTwo}`}
                                            alt="superSlotChallegePromotion"
                                            effect="none"
                                            placeholder={<PlaceholderImage />}
                                            className="cashBackIconImg"
                                        />
                                    </ImageGrid>
                                    <h3>Wager</h3>
                                    <p>The more you wager, the greater your chances of winning.</p>
                                </ColOne>
                            </ColWrapper>
                            <ColWrapper>
                                <ColOne>
                                    <ImageGrid className='cashBackImg'>
                                        <LazyLoadImage
                                            src={`${config.imageV3Host}/static/cashsite/${theme.image.SSCPromoCashPrizeIconThree}`}
                                            alt="superSlotChallegePromotion"
                                            effect="none"
                                            placeholder={<PlaceholderImage />}
                                            className="cashBackIconImg"
                                        />
                                    </ImageGrid>
                                    <h3>Bonus</h3>
                                    <p>Bonus will be credited weekly based on your bet amount</p>
                                </ColOne>
                                <ColOne>
                                    <ImageGrid className='cashBackImg'>
                                        <LazyLoadImage
                                            src={`${config.imageV3Host}/static/cashsite/${theme.image.SSCPromoCashPrizeIconFour}`}
                                            alt="superSlotChallegePromotion"
                                            effect="none"
                                            placeholder={<PlaceholderImage />}
                                            className="cashBackIconImg"
                                        />
                                    </ImageGrid>
                                    <h3>Validity</h3>
                                    <p>Promotion will be valid from <span className='boldText'>15 Jan’25 to 14 Mar’25.</span></p>
                                </ColOne>
                            </ColWrapper>
                        </BonusWrapper>
                        <SSCTable>
                            <ImageGrid className='bonusImg'>
                                <LazyLoadImage
                                    src={`${config.imageV3Host}/static/cashsite/${theme.image.SSCPromoBonusTable}`}
                                    alt="superSlotChallegePromotion"
                                    effect="none"
                                    placeholder={<PlaceholderImage />}
                                    className="bonusTableImg"
                                />
                            </ImageGrid>
                        </SSCTable>
                    </WeeklyBonus>
                    <SSCTermsAndConditionWrapper>
                        <SSCTermsAndCondition>
                            <h4>Terms & Conditions:</h4>
                            <ol>
                                <li>This promotion is valid for all new & existing players.</li>
                                <li>This promotion is valid from 15th Jan 2025 to 14th Mar 2025.</li>
                                <li>Results will be announced on within 5 days from the end of every promo period</li>
                                <li>Weekly bonus will be credited to your wallet every Friday</li>
                                <li>The total cash prize is ₹ 2,000,000/-</li>
                                <li>Void/Cancelled/Draw bets, Cashed-Out bets do not count towards the deposit or bonus rollover requirements.</li>
                                <li>Multiple accounts will not be considered for this promotion eligibility / criteria. All players will be subject for duplicate checking. {domain} reserves the right to disqualify players if collusion or any foul play takes place in the promotion.</li>
                                <li>{domain} reserves the right to amend, cancel and reclaim or refuse any promotion at its own discretions.</li>
                                <li>{domain} General Terms & Conditions apply</li>
                            </ol>
                        </SSCTermsAndCondition>
                    </SSCTermsAndConditionWrapper>
                </BottomContainer>
            </InformationWrapper>
            <FooterContainer />
        </>
    )
}



export default withTheme(withAppContext(SuperSlotsChallengePromo));

const InformationWrapper = styled.div`
    width: 100%;
    height: auto;
    border-radius: 1px;
    background: rgba(111, 111, 111, 0.5);
    border-top-color: transparent;
    backdrop-filter: brightness(0.5);    
    display: flex;
    flex-direction: column;
    background: rgb(24, 24, 27);
`;

const TopContainer = styled.div`
    display: flex;
    width: 100%;
    justify-content: center;
    align-items: center;
    background:rgb(24,24,27);
    background: ${theme('palette.sscPromoTopWrapperBg')};
`;

const MidContainer = styled.div`
    display: flex;
    width: 100%;
    justify-content: center;
    align-items: center;
    background: ${theme('palette.sscPromoMidWrapperBg')};
    padding: 5rem;
`;

const LeftContainer = styled.div`
    display: flex;
    width: 50%;
    justify-content: center;
    align-items: flex-start;
    flex-direction: column;
    padding: 6rem 3rem;
    gap: 3rem;
`;

const MidLeftContainer = styled(LeftContainer)`
    padding: 0 2rem 0 1rem;
`

const RightContainer = styled.div`
    display: flex;
    width: 50%;
    justify-content: flex-end;
    align-items: flex-end;
`;

const MidRightContainer = styled.div`
    display: flex;
    width: 50%;
    justify-content: center;
    align-items: center;

    .customeGrid {
        text-align: center;
    }
`;

const ImageGrid = styled.div`
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
    width: 100%;

    &.BgImg {
        background: rgb(50, 50, 56);
    }

    img {
        width: 100%;
        height: 16.5rem;
    }

    .MissionImg {
        width: 70%;
        height: 70%;
    }
`;

const TextWrapper = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    gap: 1.5rem;

    h1 {
        color: ${theme('palette.sscPromoMainHeadingColor')};
        font-size: 72px;
        font-style: normal;
        text-align: left;
        font-weight: 800;
        line-height: 108%; /* 77.76px */
        letter-spacing: -0.72px;
        width: 75%;
        margin: 0;
        padding: 0;
    }

    h5 {
        background: ${theme('palette.sscPromoHeading5Color')};
        background-clip: text;
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
        font-size: 36px;
        font-style: normal;
        font-weight: 800;
        line-height: 130%; /* 46.8px */
        margin: 0;
        padding: 0;
    }

    p {
        color: ${theme('palette.sscPromoMainParaColor')};
        font-size: 18px;
        font-style: normal;
        text-align: left;
        font-weight: 400;
        line-height: 150%; /* 27px */
        margin: 0;
        padding: 0;
    }

    .customColor {
        background: ${theme('palette.sscPromoCustomTextColor')};
        font-weight: 700;
        background-clip: text;
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
        
    }

    .rightText {
        color: ${theme('palette.sscPromoMainParaColor')};
        font-size: 36px;
        font-style: normal;
        font-weight: 800;
        line-height: 130%;
    }
`;

const ButtonWrapper = styled.div`
    display: flex;
    justify-content: center;
    align-items: flex-start;
    gap: 1rem;
`;

const Button = styled.button`
    padding: 1rem 1.5rem;
    border-radius: 8px;
    background: ${theme('palette.sscPromoButtonBg')};
    border: ${theme('palette.sscPromoButtonBorder')};
    color: ${theme('palette.sscPromoButtonTextColor')};
    text-align: center;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 160%; /* 22.4px */
    cursor: pointer;

    &.customBtn {
        color: ${theme('palette.sscPromoCustomButtonColor')};
        border: ${theme('palette.sscPromoCustomButtonBorder')};
        background: ${theme('palette.sscPromoCustomButtonBg')};
        text-align: center;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 160%; /* 22.4px */
    }
`;

const BottomContainer = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    background: ${theme('palette.sscPromoBottomWrapperBg')};
    padding: 3.5rem 0 0 0;
`;

const CashPrizeWrapper = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    gap: 3rem;

    .CPimage {
        align-items: flex-end;
        width: 50%;

        .img {
            width: 100%;
            height: 100%;
            text-align: right;
        }
    }
`;

const CashPrizeLeftWrapper = styled.div`
    display: flex;
    justify-content: center;
    align-items: flex-start;
    flex-direction: column;
    width: 50%;
    gap: 1rem;
    padding: 0 3rem 0 1rem;

    h2 {
        overflow: hidden;
        color: ${theme('palette.sscPromoCashPrizeHeadingText')};
        text-overflow: ellipsis;
        font-size: 56px;
        font-style: normal;
        font-weight: 800;
        line-height: 120%; /* 67.2px */
        margin: 0;
        padding: 0;
    }

    p {
        color: ${theme('palette.sscPromoCashPrizeParaText')};
        font-size: 18px;
        font-style: normal;
        font-weight: 400;
        line-height: 150%; /* 27px */
        margin-top: 0;
        padding: 0;
    }
`;

const BonusWrapper = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    width: 100%;
    padding: 3.5rem 0;
    gap: 3rem;
`;

const ColWrapper = styled.div`
    display: flex;
    justify-content: center;
    align-items: flex-start;
    width: 100%;
    gap: 3rem;
`;

const ColOne = styled.div`
    display: flex;
    justify-content: center;
    align-items: flex-start;
    flex-direction: column;
    gap: 1rem;
    width: 25%;

    h3 {
        overflow: hidden;
        color: ${theme('palette.sscPromoColumnHeadText')};
        text-overflow: ellipsis;
        font-size: 18px;
        font-style: normal;
        font-weight: 400;
        line-height: 150%; /* 27px */
        margin: 0;

    }

    p {
        overflow: hidden;
        color: ${theme('palette.sscPromoColumnParaText')};
        text-overflow: ellipsis;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 160%; /* 22.4px */
        margin: 0;
    }

    .boldText {
        color: ${theme('palette.sscPromoColumnParaBoldText')};
        text-overflow: ellipsis;
        
        font-size: 14px;
        font-style: normal;
        font-weight: 700;
        line-height: 160%;
    }

    .cashBackImg {
        align-items: flex-start;

        .cashBackIconImg {
            width: 2rem;
            height: 2rem;
        }
    }
`;

const HeadingText = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;

    h3 {
        color: ${theme('palette.sscPromoCrazyHeading3')};
        font-size: 59px;
        font-style: normal;
        text-align: center;
        font-weight: 900;
        line-height: 150%; /* 88.5px */
        margin: 0;
    }

    p {
        color: ${theme('palette.sscPromoCrazyParaColor')};
        text-align: center;
        
        font-size: 18px;
        font-style: normal;
        font-weight: 400;
        line-height: 150%; /* 27px */
        margin: 0;
    }
`;

const PrizeWrapper = styled.div`
    display: flex;
    justify-content: center;
    align-items: stretch;
    align-content: center;
    align-self: stretch;
    padding: 80px 32px;
    width: 100%;
    gap: 3rem;
    margin: 4rem 0 0 0;
    /* background: #000; */
    background: ${theme('palette.sscPromoPrizeWrapperBg')};
`;

const PrizeBox = styled.div`
    display: flex;
    justify-content: flex-start;
    align-items: center;
    flex-direction: column;
    gap: 1rem;
    border-radius: 20px;
    padding: 1rem;
    border: ${theme('palette.sscPromoPrizeContainerBorder')};
    background: ${theme('palette.sscPromoPrizeContainerBg')};
    box-shadow: ${theme('palette.sscPromoPrizeContainerBoxShadow')};
    width: 20%;

    h3 {
        color: ${theme('palette.sscPromoPrizeContainerTextColor')};
        font-size: 36px;
        font-style: normal;
        font-weight: 800;
        line-height: 130%; /* 46.8px */
        text-transform: uppercase;
        margin: 0;
    }

    p {
        color: ${theme('palette.sscPromoPrizeContainerTextColor')};
        text-align: center;
        font-size: 24px;
        font-style: normal;
        font-weight: 400;
        line-height: 130%; /* 31.2px */
    }

    span {
        font-size: 16px;
        color: ${theme('palette.sscPromoPrizeContainerTextColor')} !important;
        text-align: center !important;
        font-style: normal;
        font-weight: 400;
        line-height: 130%;
    }
`;

const LineBreaker = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    height: 2px;
    width: 100%;
    background: ${theme('palette.sscPromoUnderline')};
`;

const SSCTable = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;

    .bonusImg {
        justify-content: center;
        align-items: center;
        flex-direction: row;

        .bonusTableImg {
            width: 100%;
            height: 400px;
            border: ${theme('palette.sscPromoBonusTableBorder')};
            border-radius: 25px;
        }
    }
`;

const SSCTermsAndCondition = styled.div`
    width: 70%;
    height: auto;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    margin: 6rem 15% 3rem 15%;
    padding: 3rem 6rem;
    position: relative;
    border-radius: 48px;
    background: ${theme('palette.sscPromoBonusTCBg')};

    & li {
        color: #EAEAFD;
        color: ${theme('palette.sscPromoBonusTCtextColor')};
        font-size: 22px;
        font-style: normal;
        font-weight: 400;
        line-height: 140%; /* 30.8px */
        margin: 1rem 0;
    }

    & h4 {
        color: ${theme('palette.sscPromoBonusTCtextColor')};
        font-size: 56px;
        font-style: normal;
        font-weight: 800;
        line-height: 120%; /* 67.2px */
        margin: 0;
    }
`;

const WeeklyBonus = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    width: 100%;
    padding: 4rem 0 0 0;
    background: ${theme('palette.sscPromoWeeklyBonusBg')};
`;

const SSCTermsAndConditionWrapper = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    background: none;
    background: ${theme('palette.sscPromoBonusTCWrapperBg')};
`;