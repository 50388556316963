import React,{useState} from 'react';
import { InputField } from 'src/components/common/formStyles';
import styled from 'styled-components';
import { ifProp, theme, withProp } from 'styled-tools';
import { color } from '../../../sass-files/variable';
import Intl from '../../common/Intl';


const { secondarycolor } = color
function OtpContainer (props) {
	const [otpCode, setOtpCode] = useState('');
	const OtpGAuth = (event) => {
		props.googleCode(event, otpCode)
	}

	return (
		<div>
			<AuthHeading><Intl langKey="TITLE@ENTER_GOOGLE_AUTHENTICATOR_CODE" /></AuthHeading>
			<AuthWrapper>
			{/* <input onChange={e => setOtpCode(e.target.value)} placeholder="Enter Code Here" /> */}
            <Intl langKey="TITLE@ENTER_CODE_HERE">
                                                {(message: string) => {
                                                    return (
														<input
                                                            placeholder={message}
                                                            onChange={e => setOtpCode(e.target.value)} 
                                                            type={'text'}													/>
                                                    );
                                                }}
                                                </Intl>
			<button onClick={(event) => OtpGAuth(event)}><Intl langKey="TITLE@SUBMIT" /></button>
			</AuthWrapper>
		</div>
	)
}

export default OtpContainer;

const AuthHeading = styled.h1`
font-size: 14px;
text-align: center;
margin: 0;
color: ${theme('palette.overlayBGColor')};
`

const AuthWrapper = styled.div`
text-align: center;
input {
	background-color: #FFFFFF;
	border: 1px solid #E6E7E8;
	box-shadow: inset 0px 4px 4px rgb(0 0 0 / 25%), inset 11.6333px -11.6333px 11.6333px rgb(7 30 72 / 10%), inset -11.6333px 11.6333px 11.6333px rgb(255 255 255 / 10%);
	backdrop-filter: blur(23.2667px);
	// border-radius: 10px;
	padding:9px 10px 10px 75px;
	// margin-bottom: 10px;
	font-size: 16px;
}
button {
	padding: 8px 10px;
	background: ${theme('palette.overlayBGColor')};
	box-shadow: -4.43231e-16px 4px 4px rgba(203, 150, 64, 0.35), 1.42467e-15px 4px 4px rgba(2, 8, 19, 0.24), inset 4.43231e-16px 4px 4px rgba(203, 150, 64, 0.25);
	// border-radius: 20px;
	color: ${secondarycolor};
	font-size: 14px;
	text-transform: uppercase;
	font-weight: bold;
	
	margin: 5px 0px;
	cursor: pointer;
	// position: relative;
	border: none;
}
`