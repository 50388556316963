
import { withAppContext } from 'src/store/initAppContext';
import styled, { withTheme } from 'styled-components';
import { Accordion, AccordionWrapper } from '../seo-content/SeoContentHomePage39';
import { Link } from 'react-router-dom';


const JiliFortuneGemsFun88 = (props: any) => {
    const { app: { brandId } } = props;

    return (
        <AccordionWrapper>
            <Accordion>
                <input type="checkbox" id="section1" className="accordion__input" />
                <label htmlFor="section1" className="accordion__label"><h1>Bet on Fortune Gems Game by Jili Slot</h1></label>

                <div className="accordion__content">
                    <p>Fortune Gems game by Jili Gaming on FUN88 is an aesthetically appealing, mind-boggling slot game with a unique Asian theme. You get power-packed visuals and audio effects. Moreover, the grid has an ancient temple in the centre as a backdrop.</p>
                    <p>The game has its standout features and offer significant payout potential for players. A key feature of this slot is its 3x3 grid, which includes 3 reels and 3 rows, along with 5 fixed paylines. The wild mask substitutes for all symbols and pays 5x the total bet for 3 on a line and the red, blue, and green gems offer multipliers of 4x, 3x, and 2.40x the stake, respectively. Players can enable Autoplay and Turbo Mode to enhance their gameplay.</p>
                    <p>Enjoy the Fortune Gems on the FUN88 website or mobile app to discover what this game has in store.</p>

                    <h2>How To Play Fortune Gems?</h2>
                    <p>To conquer any game, first, you need to understand the basics. Fortune Gems is your classic <strong><Link to="/slots">slot game</Link></strong> with added exciting features. Let's get familiar with how to play the game:</p>

                    <h3>Reels and Symbols</h3>
                    <p>The game features five reels embellished with colourful gems. Each spin reveals a combination of symbols, including diamonds, rubies, sapphires, and emeralds.</p>

                    <h3>Paylines</h3>
                    <p>The paths across the reels that lead to winning combinations are Pay lines. The game offers multiple pay lines. Moreover, you can adjust the number of active lines before spinning.</p>

                    <h3>Winning Combinations</h3>
                    <p>When there is a match of identical symbols on an active payline, it's a winning combo. With increasing matches, payouts also increase.</p>
                    <p><strong><Link to="/promotions/welcome-bonus">Get 388% Bonus on 1st Deposit</Link></strong></p>

                    <h2>How to Bet on Fortune Gems?</h2>
                    <ol>
                        <li><strong>Log In/Sign In: <Link to={"/login"}>Access your FUN88 account</Link></strong> or sign in.</li>
                        <li><strong>Make a Deposit: </strong>Add funds to your account and navigate the app.</li>
                        <li><strong>Select The Game: </strong>Go to the game tab and select the Fortune Gems game.</li>
                        <li><strong>Place Bets and Enjoy: </strong>Place your bets and enjoy the game.</li>
                        <li><strong>Withdrawal: </strong>Quick and instant withdrawals for your wins.</li>
                    </ol>

                    <h2>Fortune Gems Game Bet Limits</h2>
                    <p>In Fortune Games, the betting range is adjustable and can be modified according to the needs of the game or player. Though the default, bets vary from &euro;0.10 to &euro;100 per spin!</p>
                    <p>The game limits for this game are below:</p>
                    <Table>
                        <tbody>
                            <tr>
                                <td>
                                    <p><strong>Bet Type</strong></p>
                                </td>
                                <td>
                                    <p><strong>Bet Amount</strong></p>
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    <p>Maximum Bet INR</p>
                                </td>
                                <td>
                                    <p>1,000</p>
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    <p>Minimum Bet INR</p>
                                </td>
                                <td>
                                    <p>1</p>
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    <p>Maximum Win for One Bet INR</p>
                                </td>
                                <td>
                                    <p>3,75,000</p>
                                </td>
                            </tr>
                        </tbody>
                    </Table>
                    
                    <h2>Rules &amp; Features of Fortune Gems</h2>
                    <p>Having an understanding of the game rules is a must for successful play. Let's understand the basics:</p>
                    <ul>
                        <li>You need to start by selecting your desired bet amount, always keep your budget and risk tolerance in mind.</li>
                        <li>Then, choose the number of pay lines you wish to activate.</li>
                        <li>Remember, the more pay lines you play, the higher your chances of landing winning combinations.</li>
                        <li>Finding the right balance between bet size and pay lines is crucial for maximising your winnings.</li>
                    </ul>

                    <h3>Coin Value</h3>
                    <p>You can choose your coin value by adjusting the bet level. Higher bets increase potential winnings but also raise the risk.</p>

                    <h3>Paylines</h3>
                    <p>You can choose how many pay lines you want to use. Keep in mind that more paylines mean more chances to win.</p>

                    <h3>Spin</h3>
                    <p>To start the reels spinning, press the &ldquo;spin&rdquo; button. Cross your fingers for winning combinations!</p>

                    <h2>Fortune Gems Bonuses And Special Features</h2>
                    <p>These Fortune Gems bonus features can immensely boost your gains. The approach is interesting and unpredictable, in which random symbols can open up free spins, boosts, and bonus rounds.</p>
                    <p>These features are not just for exciting elements but also offer rewarding opportunities to increase your winnings.</p>
                    <ul>
                        <li><strong> Wild Symbols</strong>: Keep an eye out for the wild gemstone symbol. You can use it instead of other symbols to increase your chances of winning.</li>
                        <li><strong> Scatter Symbols</strong>: Scatter symbols trigger special features. Land enough of them, and you&rsquo;ll unlock free spins or other bonuses.</li>
                        <li><strong> Progressive Jackpot</strong>: Some versions of Fortune Gems <strong><Link to="/slots/jili-slot">Jili Slot</Link></strong> have a progressive jackpot. Keep an eye on it; you might hit the big one!</li>
                    </ul>

                    <h2>Fortune Gems Return to Player (RTP), Volatility And Max Win</h2>
                    <p>The game offers a fabulous RTP of 97%. It is an excellent deal for a low-volatile game. Jili Games has an excellent reputation for its gadget-friendly slots with low variance and high payout rates, but Fortune Gems is the best performer amongst the other Jili games. A slot Fortune Gems is a low-roller game and provides a maximum win of 375x the stake.</p>

                    <h2>Advantage of Fortune Gems Online Slot</h2>
                    <ul>
                        <li>It offers a low volatile slot with 97% RTP.</li>
                        <li>The game offers guaranteed win multipliers of up to 15x.</li>
                        <li>You can place Extra Bets for more frequent high multipliers.</li>
                        <li>This is a fun, simple and fast-paced casino game.</li>
                        <li>Perfectly optimised for play on mobile.</li>
                        <li>A demo Fortune Gems version is available.</li>
                    </ul>

                    <h2>How to Deposit and Withdraw Money While Playing Fortune Gems Game Online?</h2>
                    <p>To play Fortune Gems on FUN88, you need to deposit and withdraw funds as follows:</p>
                    <ul>
                        <li><strong> Deposit</strong>: Log in to FUN88 and select the game from the game list. Choose your preferred deposit method to add funds, including UPI, Net Banking, E-Wallets, and more. You are all set to play your game.</li>
                        <li><strong> Withdraw</strong>: To withdraw, you can use the same methods as depositing: UPI, Net Banking, E-Wallets, or others.</li>
                    </ul>

                    <h2>Download the Fortune Gems Game App from FUN88 Today</h2>
                    <p>Getting started with Fortune Gems on FUN88 is easy. Follow these steps to download and install the app:</p>
                    <ol>
                        <li><strong> Visit the website</strong>: Open your web browser and go to our official website.</li>
                        <li><strong> Download the app: </strong>Find and click the <strong><Link to="/app-download">download Fortune Gems Game App</Link></strong> link for your device on the website. Depending on your smartphone or tablet, choose between Android or iOS.</li>
                        <li><strong> Install the App</strong>: Once the download is complete, open the file and follow the on-screen instructions to install the app.Are you excited to try your Fortune in Fortune Gems? <strong><Link to="/">Play on FUN88</Link></strong>.</li>
                    </ol>
                    <p><strong><Link to="/app-download">Download Fortune Gems App</Link></strong></p>
                </div>
            </Accordion>
        </AccordionWrapper>
    )
}

export default withTheme(withAppContext(JiliFortuneGemsFun88));

const Table = styled.table`
    border-collapse: collapse;
    tr, td {
        border: 1px solid black;
        border-collapse: collapse;
        text-align: center;
        padding: 0.5rem 1rem;
        text-align: left;
    }
`;