import React from 'react'
import { Accordion, AccordionWrapper } from '../seo-content/seo_content'
import { Helmet } from "react-helmet";
import { withTheme } from 'styled-components';
import { withAppContext } from 'src/store/initAppContext';
import BTIseo39 from './BTIseo39';
import BTIseoYolo from './BTIseoYolo';
import BTIseoFun88 from './BTIseoFun88';

const structuredData = (domain) => {

  const faqData = {
    "@context": "https://schema.org",
    "@type": "FAQPage",
    "mainEntity": [{
      "@type": "Question",
      "name": `Which are the popular types of Sportsbook betting on ${domain} ?`,
      "acceptedAnswer": {
        "@type": "Answer",
        "text": "Cricket, football, basketball, tennis, and kabaddi are popular types of Sportsbook betting."
      }
    }, {
      "@type": "Question",
      "name": `Does ${domain} have domestic cricket matches too?`,
      "acceptedAnswer": {
        "@type": "Answer",
        "text": `Be it international or domestic cricket matches, ${domain} hosts all kinds of cricket matches.`
      }
    }]
  };

  // Combine both data objects
  const combinedData = [faqData];

  return JSON.stringify(combinedData);
};

const BTIseo = (props) => {

  const { app: { brandId } } = props;

  let domainURL = 'https://www.yolo247.site';

  if (brandId === 32) {
    domainURL = 'https://www.yolo247.club';
  }
  else if (brandId === 33) {
    domainURL = 'https://www.iw247.com';
  }
  else if (brandId === 34) {
    domainURL = 'https://www.betdaily.club';
  }
  else if (brandId === 39) {
    domainURL = 'https://www.fomo7.com';
  } else if (brandId === 40) {
    domainURL = 'https://www.fomo7.xyz';
  }
  else if (brandId === 46) {
    domainURL = 'https://www.fun88.site';
  }
  else if (brandId === 47) {
    domainURL = 'https://www.gofun88.in';
  }

  const domainName = (brandId === 31 || brandId === 32) ? 'Yolo247' : brandId === 33 ? 'IW247' : (brandId === 39 || brandId === 40) ? 'FOMO7' : (brandId === 46 || brandId === 47) ? 'FUN88' : 'Betdaily';



  return (
    <>
      <Helmet>
        <script type="application/ld+json">
          {
            structuredData(domainURL)
          }
        </script>
      </Helmet>
      {
        brandId == 33 ? <></> : 
        (brandId == 39 || brandId == 40) ? <BTIseo39 />:
        (brandId == 46 || brandId == 47) ? <BTIseoFun88 />:
        <BTIseoYolo />
      }
    </>
  )
}

export default withTheme(withAppContext(BTIseo));