import React from 'react';
import { Query } from '@apollo/client/react/components'
import GQLPolling from '../components/GQLPolling';
import { Notify } from '../components/Notification';
import SubscribeOrderList from '../components/subscription/SubscribeOrderList';
import EventTypeConfig from '../config/eventTypeConfig';
// schema
import { CANCEL_ORDERS, GET_ORDER_LIST } from '../graphql/exchange';
import { IOrder } from '../graphql/schema';
import apiUtil from '../util/apiUtil';
import graphqlApiUtil from '../util/graphqlApiUtil';
import routerUtil from '../util/routerUtil';
import util from '../util/util';
// Context
import { withAppContext } from './initAppContext';
import cookieUtil from '../util/cookieUtil';
import { ECookieName } from '../util/utilModel';
export interface IMatchListContext {
	matchListContext: IMatchListProviderStore;
}
export interface ICancelOrders {
	marketId: string;
	orderNumber: string;
	localCreatedTime: string;
	provider: string;
}
export interface IUpdateOrders {
	marketId: string;
	orderNumber: string;
	size: number;
	price: number;
	side: string;
	selectionId: string;
	betfairOrderType: string;
	categoryId: number;
	eventId: string;
	competitionId: string;
	localCreatedTime: string;
	marketType: number;
	betDelay: number;
	provider: string;
}

interface IMatchListProviderProps {
	app: {
		exchangeRate: number;
		token: string;
		memberId: number;
	};
}

interface IMatchListProviderState {
	isMatchLoading: boolean;
	openedMobileUnmatchedSlipOrderNumber?: string;
	updatedUnmatchedSlips: { [orderNumber: string]: { price: number; size: number } };
}

export interface IMatchListProviderStore extends IMatchListProviderState {
	matched: IOrder[];
	unmatched: IOrder[];
	pending: IOrder[];
	syncUpdatedUnmatchedSlip: (orderNumber: string, value: { price: number; size: number }) => void;
	removeUpdatedUnmatchedSlip: (orderNumber: string) => void;
	cancelOrder: (params: ICancelOrders) => any;
	cancelAllUnmatchedOrder: (unmatched: IOrder[]) => any;
	updateOrder: (params: IUpdateOrders) => any;
	openMobileUnmatchedSlip: (params: IUpdateOrders) => any;
	closeMobileUnmatchedSlip: () => void;
	refetch: () => any;
	loading: boolean;
	error: any;
}

const initMatchListProviderStore: IMatchListProviderStore = {
	isMatchLoading: false,
	matched: [],
	unmatched: [],
	pending: [],
	updatedUnmatchedSlips: {},
	syncUpdatedUnmatchedSlip() {},
	removeUpdatedUnmatchedSlip() {},
	cancelAllUnmatchedOrder() {},
	cancelOrder() {},
	updateOrder() {},
	openMobileUnmatchedSlip() {},
	closeMobileUnmatchedSlip() {},
	refetch() {},
	loading: false,
	error: {},
};

export const ORDER_BF_API_TIMEOUT = 'ORDER_BF_API_TIMEOUT';

export const Context = React.createContext(initMatchListProviderStore);
class MatchListContextClass extends React.Component<IMatchListProviderProps, IMatchListProviderState> {
	state = {
		isMatchLoading: false,
		openedMobileUnmatchedSlipOrderNumber: '',
		updatedUnmatchedSlips: {},
	};

	syncUpdatedUnmatchedSlip = (orderNumber: string, value: { price: number; size: number }) => {
		const { updatedUnmatchedSlips } = this.state;
		this.setState({
			updatedUnmatchedSlips: {
				...updatedUnmatchedSlips,
				[orderNumber]: value,
			},
		});
	};

	removeUpdatedUnmatchedSlip = (orderNumber: string) => {
		const { updatedUnmatchedSlips } = this.state;
		const newUpdatedUnmatchedSlips = { ...updatedUnmatchedSlips };
		delete (newUpdatedUnmatchedSlips as any)[orderNumber];

		this.setState({
			updatedUnmatchedSlips: newUpdatedUnmatchedSlips,
		});
	};

	cancelOrder = async (params: ICancelOrders) => {
		const {
			app: { token },
		} = this.props;
		this.setState({ isMatchLoading: true });
		// Code Changed - Manoj ( added token and using spread operatir)
		// const payload = await graphqlApiUtil.clientQuery(CANCEL_ORDERS, { input: { ...params} ,  });
		const payload = await graphqlApiUtil.query(CANCEL_ORDERS, '', { input: { ...params} ,  });
		this.setState({ isMatchLoading: false });
		return payload;
	};

	cancelAllUnmatchedOrder = async (unmatched: IOrder[]) => {
		this.setState({ isMatchLoading: true });
		// Code Changed Manoj - replaced clientQuery to query and added extra param
		await graphqlApiUtil.query(CANCEL_ORDERS, '', { input: unmatched });
		const cancelUnmatchedOrders = await Promise.all(
			unmatched.map(async order => {
				const { createdAt: localCreatedTime, orderNumber, marketId, provider } = order;
				// Code Changed Manoj - replaced clientQuery to query and added extra param
				return await graphqlApiUtil.query(CANCEL_ORDERS, '', {
					input: { localCreatedTime, orderNumber, marketId, provider },
				});
			}),
		);
		this.setState({ isMatchLoading: false });
		return cancelUnmatchedOrders;
	};

	updateOrder = async (params: IUpdateOrders, unmatchedData: IOrder) => {
		const {
			app: { token },
		} = this.props;
		this.setState({ isMatchLoading: true });
		const payload = await apiUtil.fetchGQL(token)({
			action: '/exchange/updateOrder',
			data: {
				// query讓gql可以解碼過來的請求operation名稱
				query: '{exchange{updateOrder',
				...params,
				// Code Changed - Manoj ( Added token variable )
				p: token
			},
		});
		this.setState({ isMatchLoading: false });
		if (payload.success && payload.data) {
			const unmatch = Number((this.props.app.exchangeRate * payload.data.unmatch).toFixed(2));
			payload.data.match === 0
				? Notify.error(
						`PRICE:${util.numRound(payload.data.price)}, MATCH:${Math.floor(
							this.props.app.exchangeRate * payload.data.match + 0.01,
						)}, UNMATCH:${unmatch}`,
				  )
				: Notify[unmatch === 0 ? 'success' : 'warning'](
						`PRICE:${util.numRound(payload.data.price)}, MATCH:${Math.floor(
							this.props.app.exchangeRate * payload.data.match + 0.01,
						)}, UNMATCH:${unmatch}`,
				  );
		} else {
			util.getValue(payload, 'code') !== ORDER_BF_API_TIMEOUT && Notify.error('Invalid Order');
		}
		return payload;
	};

	openMobileUnmatchedSlip = (params: IUpdateOrders) => {
		const { categoryId, competitionId, eventId, orderNumber } = params;
		// 先清掉已開啟的 unmatched slip
		this.closeMobileUnmatchedSlip();

		// Router push 到內頁
		routerUtil.push(`/eu-content/${EventTypeConfig(categoryId).eventTypeKey}/${competitionId || 'e'}/${eventId}`);

		// 開啟 MobileUnmatchedSlip
		setTimeout(() => {
			this.setState({
				openedMobileUnmatchedSlipOrderNumber: orderNumber,
			});
		}, 150);
	};

	closeMobileUnmatchedSlip = () => {
		this.setState({
			openedMobileUnmatchedSlipOrderNumber: '',
		});
	};

	render(): JSX.Element {
		const { isMatchLoading, openedMobileUnmatchedSlipOrderNumber, updatedUnmatchedSlips } = this.state;
		const { app, children } = this.props;
		return (
			<>
			<GQLPolling pollingTime={10000} isOnlyUsePageVisable={!!app.token}>
				{pollingTime => (
					<Query<any>
						query={GET_ORDER_LIST}
						ssr={false}
						pollInterval={isMatchLoading ? 0 : pollingTime}
						fetchPolicy="network-only"
					>
						{({ loading, error, data, subscribeToMore, refetch }) => {
							const matchedData = util.getValue(data, ['exchange', 'orders', 'matched'], []);
							const unmatchedData = util.getValue(data, ['exchange', 'orders', 'unmatched'], []);
							const pendingData = util.getValue(data, ['exchange', 'orders', 'pending'], []);
							return (
								<SubscribeOrderList
									subscribeToMore={subscribeToMore}
									loading={loading} 
									memberId={app.memberId}
								>
									<Context.Provider
										value={{
											isMatchLoading,
											openedMobileUnmatchedSlipOrderNumber,
											matched: matchedData,
											unmatched: unmatchedData,
											pending: pendingData,
											updatedUnmatchedSlips,
											syncUpdatedUnmatchedSlip: this.syncUpdatedUnmatchedSlip,
											removeUpdatedUnmatchedSlip: this.removeUpdatedUnmatchedSlip,
											cancelOrder: this.cancelOrder,
											updateOrder: _ => this.updateOrder(_, unmatchedData),
											cancelAllUnmatchedOrder: this.cancelAllUnmatchedOrder,
											openMobileUnmatchedSlip: this.openMobileUnmatchedSlip,
											closeMobileUnmatchedSlip: this.closeMobileUnmatchedSlip,
											refetch,
											loading,
											error,
										}}
									>
										{React.cloneElement(children as any, { app })}
									</Context.Provider>
								</SubscribeOrderList>
							);
						}}
					</Query>
				)}
			</GQLPolling>
			
			</>
		);
	}
}
export const MatchListContext = withAppContext(MatchListContextClass);

export const withMatchListContext = (Child: any, keys: Array<keyof IMatchListProviderStore>): any => {
	return class MatchListHOC extends React.Component<any, any> {
		render(): JSX.Element {
			return (
				<Context.Consumer>
					{(store: any) => {
						const matchListContext = keys
							? keys.reduce((pv: any, key: keyof IMatchListProviderStore) => {
									pv[key] = store[key];
									return pv;
							  }, {})
							: store;
						return <Child {...this.props} matchListContext={matchListContext} />;
					}}
				</Context.Consumer>
			);
		}
	};
};
