import React from 'react'

type Props = {}

const RightMarkIcon = (props: Props) => (
  <svg xmlns="http://www.w3.org/2000/svg" width="15" height="13" viewBox="0 0 15 13" fill="none">
      <path d="M3.10087 11.1643C3.56306 12.1406 4.41179 13 5.31935 13H5.32776C7.05044 13 7.15969 12.0988 8.83195 8.55263C9.87396 6.34146 11.0168 4.4724 12.8571 2.46983L12.8656 2.46149C13.9076 1.41849 14.437 0.99294 14.7311 0.759307C14.8571 0.659178 14.9328 0.592426 14.9664 0.550706C14.9916 0.51733 15 0.483954 15 0.408857V0.39217L14.9916 0.375481C14.9076 0.116816 14.7227 0 14.3698 0C13.8151 0 12.8067 0.417202 11.5378 1.18485C9.06724 2.68678 7.05044 4.90629 5.32776 8.58601L5.23532 8.78626L5.11767 8.59435C3.39499 5.74069 1.93281 5.52375 1.38659 5.52375C1.18491 5.52375 1.00004 5.54878 0.865583 5.59884C0.647096 5.68228 0.117686 5.92426 0.0168458 6.34981C-0.0503809 6.6335 0.0840726 6.95058 0.411803 7.30937C1.61348 8.59435 2.43701 9.75417 3.10087 11.1643Z" fill="url(#paint0_linear_1207_36)" />
      <defs>
          <linearGradient id="paint0_linear_1207_36" x1="15" y1="-1.41328e-07" x2="14.3437" y2="13.6878" gradientUnits="userSpaceOnUse">
              <stop stop-color="#FFDB3E" />
              <stop offset="0.494792" stop-color="#FFE459" />
              <stop offset="1" stop-color="#DF9301" />
          </linearGradient>
      </defs>
  </svg>
)

export default RightMarkIcon;