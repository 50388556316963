import { useEffect } from "react";
import { withAppContext } from "src/store/initAppContext";
import styled, { withTheme } from "styled-components";
import Accordion from '../../components/Accordion/Accordion';
import SEOContents from "src/components/SEOContents";
import { Helmet } from "react-helmet";
import { appQuery39, bonusAndPromotion39, casinoGames39, deposit39, myAccountData39, security39, sportsBetting39, withDrawal39 } from "./faqFomoData";


const structuredData = (domain) => {
    const data = {
        "@context": "https://schema.org",
        "@type": "BreadcrumbList",
        "itemListElement":
            [
                {
                    "@type": "ListItem",
                    "item": `${domain}`,
                    "name": "Homepage",
                    "position": 1
                },
                {
                    "@type": "ListItem",
                    "item": `${domain}/faq/`,
                    "name": "FAQ's",
                    "position": 2
                },
            ],
        "name": "Breadcrumbs"
    }

    return JSON.stringify(data)
}

function FAQ39(props: any) {

    const { app: {
        brandId
    }, theme } = props

    let WebsiteName = ''
    let WebsiteLink = ''
    switch (props.app.brandId) {
        case 31:
            WebsiteName = 'Yolo247';
            WebsiteLink = 'www.yolo247.site'
            break;
        case 32:
            WebsiteName = 'Yolo247';
            WebsiteLink = 'www.a-yolo247.site'
            break;
        case 33:
            WebsiteName = 'IW247';
            WebsiteLink = 'www.iw247.com'
            break;
        case 34:
            WebsiteName = 'BETDAILY';
            WebsiteLink = 'www.betdaily.club'
            break;
        case 39:
            WebsiteName = 'FOMO7';
            WebsiteLink = 'www.fomo7.com'
            break;
            case 46:
                WebsiteName = 'FUN88';
                WebsiteLink = 'www.fun88.com'
                break;
    }

    let domainURL = 'https://www.yolo247.site';

    if (brandId === 32) {
        domainURL = 'https://www.yolo247.club';
    }
    else if (brandId === 33) {
        domainURL = 'https://www.iw247.com';
    }
    else if (brandId === 34) {
        domainURL = 'https://www.betdaily.club';
    }
    else if (brandId === 39) {
        domainURL = 'https://www.fomo7.com';
    } else if (brandId === 40) {
        domainURL = 'https://www.fomo7.xyz';
      }

    const { config: { showAffiliate } } = theme;

    useEffect(() => {
        window.scrollTo(0, 0)
    })

    return (
        <>
            <Helmet>
                <script type="application/ld+json">
                    {
                        structuredData(domainURL)
                    }
                </script>
            </Helmet>
            <ContentWrapper>
                <SEOContents pageName="faq" />
                <AccordionHead>My Account</AccordionHead>
                {myAccountData39.map((faq) => (
                    <Accordion
                        key={faq.id}
                        title={faq?.question}
                        content={() => ({ __html: faq?.answer })}
                        lists={faq?.lists}
                    // redirect={faq?.redirect}
                    />
                ))}

                <AccordionHead>Withdrawal</AccordionHead>
                {withDrawal39.map((faq) => (
                    <Accordion
                        key={faq.id}
                        title={faq?.question}
                        content={() => ({ __html: faq?.answer })}
                        lists={faq?.lists}
                    // redirect={faq?.redirect}
                    />
                ))}

                <AccordionHead>Deposit</AccordionHead>
                {deposit39.map((faq) => (
                    <Accordion
                        key={faq.id}
                        title={faq?.question}
                        content={() => ({ __html: faq?.answer })}
                        lists={faq?.lists}
                    // redirect={faq?.redirect}
                    />
                ))}

                <AccordionHead>Bonuses & Promotions</AccordionHead>
                {bonusAndPromotion39.map((faq) => (
                    <Accordion
                        key={faq.id}
                        title={faq?.question}
                        content={() => ({ __html: faq?.answer })}
                        lists={faq?.lists}
                    // redirect={faq?.redirect}
                    />
                ))}

                <AccordionHead>App Related Queries</AccordionHead>
                {appQuery39.map((faq) => (
                    <Accordion
                        key={faq.id}
                        title={faq?.question}
                        content={() => ({ __html: faq?.answer })}
                        lists={faq?.lists}
                    // redirect={faq?.redirect}
                    />
                ))}

                <AccordionHead>Casino Games</AccordionHead>
                {casinoGames39.map((faq) => (
                    <Accordion
                        key={faq.id}
                        title={faq?.question}
                        content={() => ({ __html: faq?.answer })}
                        lists={faq?.lists}
                    // redirect={faq?.redirect}
                    />
                ))}

                <AccordionHead>Sports Betting</AccordionHead>
                {sportsBetting39.map((faq) => (
                    <Accordion
                        key={faq.id}
                        title={faq?.question}
                        content={() => ({ __html: faq?.answer })}
                        lists={faq?.lists}
                    // redirect={faq?.redirect}
                    />
                ))}

                <AccordionHead>Security</AccordionHead>
                {security39.map((faq) => (
                    <Accordion
                        key={faq.id}
                        title={faq?.question}
                        content={() => ({ __html: faq?.answer })}
                        lists={faq?.lists}
                    // redirect={faq?.redirect}
                    />
                ))}
            </ContentWrapper>
        </>
    );
}
export default withTheme(withAppContext(FAQ39));


const AccordionHead = styled.h1`
        font-size: 15px;
        color: #f4a322;
        margin-top:10px;
        margin: 1px 0px;
        padding: 5px 15px;
        background-color: #0000008a;
        `;
const ContentWrapper = styled.div`
	max-width: 800px;
	margin: 0 auto;
	font-size: 12px;
	padding: 1em 1.25em;

    h1{
        color: #EEBC15;
        
    }

    p,
	h3,
	h4,
	h5,
	h6 {
		color: #fff;
	}

	h1 {
		font-size: 18px;
	}

	h2 {
		font-size: 16px;
	}

	h3 {
		font-size: 14px;
	}

	ul ul,
	ol {
		margin: 1em 0;
	}

	li {
        color: #fff;
	}

	a {
		color: #EEBC15;
		text-decoration: underline;
	}

    span{
        font-weight: 700;
    }
`;
