import React from 'react'
import { theme } from "styled-tools";
import styled, { withTheme } from "styled-components";
import { withAppContext } from 'src/store/initAppContext';

const DepositDetailsUpiIcon = (props) => {
  const { theme, app: { brandId } } = props;
  return (
    <>
      <svg xmlns="http://www.w3.org/2000/svg" width="13" height="12" viewBox="0 0 13 12" fill="none">
        <path d="M11.8839 3.37647L6.58636 0.0373399C6.5236 -0.00202995 6.44104 -0.00202995 6.37827 0.0373399L1.08072 3.37647C1.02395 3.41376 0.988525 3.47659 0.988525 3.54334V3.93625C0.988525 4.0442 1.07681 4.13258 1.18464 4.13258H1.77322V4.72183C1.77322 4.82977 1.86151 4.91815 1.96933 4.91815H10.9948C11.1026 4.91815 11.1909 4.82977 11.1909 4.72183V4.13258H11.7795C11.8873 4.13258 11.9756 4.0442 11.9756 3.93625V3.54334C11.9756 3.47659 11.9402 3.41376 11.8834 3.37647H11.8839ZM6.48245 3.73993C5.18573 3.70004 5.18599 1.81524 6.48245 1.77561C7.77917 1.8155 7.77891 3.7003 6.48245 3.73993Z" fill="#A8A4A4"/>
        <path d="M7.46335 8.45335V5.31055H5.50122V8.45335H7.46335Z" fill="#A8A4A4"/>
        <path d="M4.71653 8.45335V5.31055H2.75439V8.45335H4.71653Z" fill="#FF9657"/>
        <path d="M8.35369 5.41619H10.1045V8.34771H8.35369V5.41619Z" fill="#08D861" stroke="#A8A4A4" stroke-width="0.211287"/>
        <path d="M11.7797 9.63323H11.1911V9.04398C11.1911 8.93604 11.1029 8.84766 10.995 8.84766C10.49 8.84766 8.78288 8.84766 8.2482 8.84766C7.31193 8.84766 5.65268 8.84766 4.71641 8.84766C4.17808 8.84766 2.4842 8.84766 1.96958 8.84766C1.86176 8.84766 1.77347 8.93604 1.77347 9.04398V9.63323H1.18488C1.07706 9.63323 0.98877 9.72161 0.98877 9.82955V10.8117C0.98877 10.9197 1.07706 11.008 1.18488 11.008H11.78C11.8878 11.008 11.9761 10.9197 11.9761 10.8117V9.82955C11.9761 9.72161 11.8878 9.63323 11.78 9.63323H11.7797Z" fill="#A8A4A4"/>
      </svg>
      {/* {brandId === 31 || brandId === 32 ? 
      <svg xmlns="http://www.w3.org/2000/svg" width="13" height="12" viewBox="0 0 13 12" fill="none">
        <path d="M11.8839 3.37647L6.58636 0.0373399C6.5236 -0.00202995 6.44104 -0.00202995 6.37827 0.0373399L1.08072 3.37647C1.02395 3.41376 0.988525 3.47659 0.988525 3.54334V3.93625C0.988525 4.0442 1.07681 4.13258 1.18464 4.13258H1.77322V4.72183C1.77322 4.82977 1.86151 4.91815 1.96933 4.91815H10.9948C11.1026 4.91815 11.1909 4.82977 11.1909 4.72183V4.13258H11.7795C11.8873 4.13258 11.9756 4.0442 11.9756 3.93625V3.54334C11.9756 3.47659 11.9402 3.41376 11.8834 3.37647H11.8839ZM6.48245 3.73993C5.18573 3.70004 5.18599 1.81524 6.48245 1.77561C7.77917 1.8155 7.77891 3.7003 6.48245 3.73993Z" fill="#A8A4A4"/>
        <path d="M7.46335 8.45335V5.31055H5.50122V8.45335H7.46335Z" fill="#A8A4A4"/>
        <path d="M4.71653 8.45335V5.31055H2.75439V8.45335H4.71653Z" fill="#FF9657"/>
        <path d="M8.35369 5.41619H10.1045V8.34771H8.35369V5.41619Z" fill="#08D861" stroke="#A8A4A4" stroke-width="0.211287"/>
        <path d="M11.7797 9.63323H11.1911V9.04398C11.1911 8.93604 11.1029 8.84766 10.995 8.84766C10.49 8.84766 8.78288 8.84766 8.2482 8.84766C7.31193 8.84766 5.65268 8.84766 4.71641 8.84766C4.17808 8.84766 2.4842 8.84766 1.96958 8.84766C1.86176 8.84766 1.77347 8.93604 1.77347 9.04398V9.63323H1.18488C1.07706 9.63323 0.98877 9.72161 0.98877 9.82955V10.8117C0.98877 10.9197 1.07706 11.008 1.18488 11.008H11.78C11.8878 11.008 11.9761 10.9197 11.9761 10.8117V9.82955C11.9761 9.72161 11.8878 9.63323 11.78 9.63323H11.7797Z" fill="#A8A4A4"/>
      </svg> : 
      <svg
        width={9}
        height={12}
        viewBox="0 0 9 12"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        {...props}
    >
        <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M8.75721 5.87871L5.79408 0L2.50195 11.5613L8.75721 5.87837V5.87871Z"
        fill="#097939"
        />
        <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M6.64944 5.87871L3.68666 0L0.394531 11.5613L6.64944 5.87837V5.87871Z"
        fill="#ED752E"
        />
      </svg>
      } */}
    </>
  )
}

export default withTheme(withAppContext(DepositDetailsUpiIcon))