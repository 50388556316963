import React from 'react';
import styled, { css } from 'styled-components';
import Icon, { IconWrapper } from '../../common/PNGIcon';
import { SectionHeading, SectionWrapper } from '../order/Order';
import Switch from '../Switch';
import Intl from '../../common/Intl';
import { IOrderListContext, withOrderListContext } from '../../../store/OrderListContext';

// util
import cookieUtil from '../../../util/cookieUtil';
import { getGTMId } from '../../../util/gtmUtil';
import { ECookieName } from '../../../util/utilModel';
const { theme, ifProp } = require('styled-tools');

interface IOneClickState {
	isEditing: boolean;
	stakes: number[];
	selectedStakeIndex: number;
	isLoading: boolean;
	errorMessage: string;
	invalidStakeIndex: number;
}

class OneClick extends React.PureComponent<IOrderListContext, IOneClickState> {
	state = {
		isEditing: false,
		stakes: (cookieUtil.get(ECookieName.COOKIE_ONECLICKBET_STAKE) &&
			cookieUtil
				.get(ECookieName.COOKIE_ONECLICKBET_STAKE)
				.split(',')
				.map(a => Number(a))) || [50, 200, 300],
		selectedStakeIndex: 0,
		isLoading: false,
		errorMessage: '',
		invalidStakeIndex: -1,
	};

	activateEditState = () => {
		this.setState(prevState => {
			return {
				isEditing: true,
			};
		});
	};

	saveEditState = () => {
		const { stakes, selectedStakeIndex } = this.state;
		const { changeOneClickStake } = this.props.orderListContext;
		let invalidStakeIndex = -1;

		if (
			stakes.some((stake, i) => {
				invalidStakeIndex = i;
				return stake <= 0;
			})
		) {
			this.setState(
				{
					errorMessage: 'Stake must be greater than 0!',
					invalidStakeIndex,
				},
				() => {
					// 五秒後清除錯誤訊息
					setTimeout(() => {
						this.setState({ errorMessage: '', invalidStakeIndex: -1 });
					}, 5000);
				},
			);
			return;
		}

		cookieUtil.set(ECookieName.COOKIE_ONECLICKBET_STAKE, stakes.join(','));
		changeOneClickStake(Number(stakes[selectedStakeIndex]));
		this.setState(prevState => {
			return {
				isEditing: false,
			};
		});
	};

	changeSelectedStackIndex = (index: number) => (): void => {
		const { changeOneClickStake } = this.props.orderListContext;
		this.setState(prevState => ({
			selectedStakeIndex: index,
		}));
		changeOneClickStake(this.state.stakes[index]);
	};

	changeStack = (index: number) => (e: any): void => {
		const {
			target: { value },
		} = e;
		this.setState(prevState => {
			const newStakes = [...prevState.stakes];
			const newStakeValue = Number(value);
			!isNaN(newStakeValue) && (newStakes[index] = newStakeValue);
			return {
				stakes: newStakes,
				errorMessage: '',
				invalidStakeIndex: -1,
			};
		});
	};

	componentDidMount() {
		const { changeOneClickStake } = this.props.orderListContext;
		changeOneClickStake(this.state.stakes[this.state.selectedStakeIndex]);
	}
	render(): JSX.Element {
		const {
			isOneClickBetActive,
			toggleOneClickBet,
			confirmTurningOnOneClick,
			cancelTurningOnOneClick,
		} = this.props.orderListContext;
		const { errorMessage, invalidStakeIndex } = this.state;

		return (
			<SectionWrapper>
				<SectionHeading>
					<span>
						<Intl langKey="ONE_CLICK@TITLE" />
					</span>
					<Switch
						currentActiveState={isOneClickBetActive}
						handleClick={toggleOneClickBet}
						id={getGTMId(`one_click_switch`)}
					/>
				</SectionHeading>
				<ConfirmWrapper isActive={isOneClickBetActive === null}>
					<ConfirmMessageWrapper>
						<Icon name="alert-red" /> Turning on One-Click will clear all existing betslip
					</ConfirmMessageWrapper>
					<ConfirmButtonWrapper>
						<ConfirmButton onClick={cancelTurningOnOneClick}>Cancel</ConfirmButton>
						<ConfirmButton onClick={confirmTurningOnOneClick}>Confirm</ConfirmButton>
					</ConfirmButtonWrapper>
				</ConfirmWrapper>

				{isOneClickBetActive && errorMessage && (
					<ErrorMessage>
						<Icon name="circle-x-color" />
						{errorMessage}
					</ErrorMessage>
				)}

				{isOneClickBetActive && (
					<>
						<StackWrapper isEditing={this.state.isEditing}>
							<StackButtonWrapper>
								{this.state.stakes.map((stake, i: number) => {
									return (
										<StackButton
											key={i}
											onClick={this.changeSelectedStackIndex(i)}
											isSelected={this.state.selectedStakeIndex === i}
										>
											{stake}
										</StackButton>
									);
								})}
							</StackButtonWrapper>
							<EditButton onClick={this.activateEditState} id={getGTMId('one_click_edit')}>
								<EditIconWrapper>
									{/* <Icon name="feather-white" /> */}
								</EditIconWrapper>
								<Intl langKey="ONE_CLICK@EDIT" />
							</EditButton>
						</StackWrapper>
						<StackWrapper style={{ display: this.state.isEditing ? '' : 'none' }}>
							<StackButtonWrapper>
								{this.state.stakes.map((stack, i: number) => {
									return (
										<InputStack
											key={i}
											value={stack}
											onChange={this.changeStack(i)}
											isInvalid={i === invalidStakeIndex}
										/>
									);
								})}
							</StackButtonWrapper>
							<SaveButton id={getGTMId('one_click_save')} onClick={this.saveEditState}>
								<Icon name="check-red" />
								<Intl langKey="ONE_CLICK@SAVE" />
							</SaveButton>
						</StackWrapper>
					</>
				)}
			</SectionWrapper>
		);
	}
}
export default withOrderListContext(OneClick, [
	'changeOneClickStake',
	'isOneClickBetActive',
	'toggleOneClickBet',
	'confirmTurningOnOneClick',
	'cancelTurningOnOneClick',
]);

// Styled components
const ConfirmButton = styled.button`
	cursor: pointer;
	display: flex;
	justify-content: center;
	align-items: center;
	background-color: transparent;
	font-size: 13px;
	width: 50%;
	border: 0;
	padding: 0.5em 0.25em;

	&:focus {
		outline: 0;
	}

	&:hover {
	}

	&:not(:last-of-type) {
	}
`;

const ConfirmButtonWrapper = styled.div`
	display: flex;
`;

const ConfirmMessageWrapper = styled.div`
	font-size: 12px;
	display: flex;
	align-items: flex-start;
	padding: 1em 0.5em;

	${IconWrapper} {
		margin-top: 0.1em;
		margin-right: 0.5em;
	}
`;

const ConfirmWrapper: any = styled.div`
	display: ${ifProp('isActive', 'block', 'none')};
	border-radius: 2px;
`;

const ErrorMessage = styled.div`
	display: flex;
	align-items: center;
	font-size: 12px;
	margin-bottom: 0.5em;

	${IconWrapper} {
		margin-right: 0.5em;
	}
`;

const SaveButton = styled.button`
	cursor: pointer;
	display: flex;
	align-items: center;
	font-style: italic;
	font-size: 10px;
	background-color: transparent;
	border: 0;
	flex-shrink: 0;

	&:focus {
		outline: 0;
	}

	${IconWrapper} {
		font-size: 14px;
		margin-right: 0.5em;
	}
`;

const InputStack: any = styled.input.attrs(props => ({
	type: 'text',
}))`
	width: 33%; /* nullify default width from user agent */
	display: flex;
	justify-content: center;
	align-items: center;
	cursor: pointer;
	flex-basis: 100%;
	flex-grow: 1;
	margin-right: 0.5em;
	border-radius: 99em;
	padding: 0.5em;
	border: 0;
	text-align: center;
	min-width: 0;

	

	&:focus {
		outline: 0;
	}
`;

// Wrapping feather icon
const EditIconWrapper = styled.div`
	display: flex;
	justify-content: center;
	align-items: center;
	width: 2.25em;
	height: 2.25em;
	border-radius: 50%;
`;

const EditButton = styled.button`
	cursor: pointer;
	display: flex;
	align-items: center;
	font-style: italic;
	font-size: 10px;
	background-color: transparent;
	border: 0;
	flex-shrink: 0;

	&:focus {
		outline: 0;
	}

	${EditIconWrapper} {
		margin-right: 0.5em;
	}
`;

const StackButton: any = styled.button`
	display: flex;
	justify-content: center;
	align-items: center;
	cursor: pointer;
	flex-basis: 100%;
	flex-grow: 1;
	margin-right: 0.3em;
	// border-radius: 99em;
	padding: 0.5em;
	font-size: 13px;
	background: rgba(221, 232, 252, 0.97);
	border-radius: 5px;
	border: 0;
	${ifProp(
		'isSelected',
		css`
			border: 0;
		`,
		css`
			// background-color: transparent;
			background: #09285F;
		`,
	)};

	&:focus {
		outline: none;
	}
`;

// Wrapping stack buttons
const StackButtonWrapper = styled.div`
	display: flex;
	align-items: center;
	flex-grow: 1;
`;

const StackWrapper: any = styled.div`
	display: ${ifProp('isEditing', 'none', 'flex')};
	align-items: center;
	justify-content: space-between;
`;
