import { css } from 'styled-components';
import { prop, theme, withProp } from 'styled-tools';

// Dependent Components
import { IconWrapper } from './PNGIcon';

// Utils
//import { darken, lighten } from 'polished';
import { diffColor } from '../../util/colorUtil';
import media from '../../util/mediaUtil';
import { isMemberType } from '../../util/styleUtil';

// Only turn on hovered state when the pointer is "cursor"
// in order to prevent the occasion in that when user touch
// on the element, the hover state triggered instead of the click event
export const hover = (...styles: any[]) => css`
	@media screen and (pointer: fine) {
		&:hover {
			${css.apply(null, styles as any)};
		}
	}
`;

export const titleFont = css`
	font-family: ${theme('font.title-font')}, ${theme('font.title-font-style')};
`;

export const bodyFont = css`
	font-family: ${theme('font.body-font')}, ${theme('font.body-font-style')};
`;

export const shadowSM = css`
	// box-shadow: -1px 2px 6px rgba(0, 0, 0, 0.12), 1px 5px 10px rgba(0, 0, 0, 0.08);
	box-shadow: 0px 0px 8px rgba(0, 0, 0, 0.15);
`;

export const shadowMD = css`
	box-shadow: -1px 5px 12px rgba(0, 0, 0, 0.12), 1px 8px 18px rgba(0, 0, 0, 0.08);
`;

export const shadowLG = css`
	box-shadow: 4px 8px 14px rgba(0, 0, 0, 0.08), -2px 3px 8px rgba(0, 0, 0, 0.14);
`;

export const shadowXL = css`
	box-shadow: -4px 10px 16px rgba(0, 0, 0, 0.16), 8px 20px 28px rgba(0, 0, 0, 0.12);
`;

export const shadowBottom1F = css`
	box-shadow: 0 3px 5px rgba(0, 0, 0, 0.1);
`;
export const shadow1F = css`
	box-shadow: 0 3px 12px rgba(0, 0, 0, 0.12), -3px 0px 6px rgba(0, 0, 0, 0.06);
`;
export const shadow2F = css`
	box-shadow: 2px 5px 8px rgba(0, 0, 0, 0.08), -2px -1px 6px rgba(0, 0, 0, 0.12);
`;
export const shadowLeft2F = css`
	box-shadow: -2px -1px 6px rgba(0, 0, 0, 0.18);
`;
export const shadow3F = css`
	box-shadow: 2px 2px 10px rgba(0, 0, 0, 0.3), -1px 0px 4px rgba(0, 0, 0, 0.08);
`;

export const panelStyle = css`
	border-radius: 4px;
	background-color: ${withProp('theme.palette.panel-background', diffColor(0.075))};
	color: ${theme('palette.panel-foreground')};
	overflow: hidden;
	border: 1px solid #fff;
`;

export const agentPanelStyle = css`
	background-color: ${theme('palette.panel-background')};
	background-image: linear-gradient(
		to bottom right,
		${theme('palette.panel-background')},
		${withProp('theme.palette.panel-background', diffColor(0.1))}
	);
	color: ${theme('palette.panel-foreground')};
	overflow: hidden;
	padding: 1em;
`;

export const buttonStyle = css`
	display: flex;
	justify-content: center;
	align-items: center;
	border-radius: 2px;
	border: 0;
	cursor: pointer;

	&:focus {
		outline: 0;
	}

	> ${IconWrapper} {
		margin-right: 0.5em;
	}
`;

export const inputStyle = css`
	padding: 0.5em 0.25em;
	border: 0;
	// background-color: ${withProp('theme.palette.panel-background', diffColor(0.1))};
	background: #EDF0F7;
	border-radius: 2px;

	&:focus {
		outline: 0;
		box-shadow: 0 0 0 1px ${theme('palette.primary-background')};
	}
`;

export const scrollbarStyle = css`
	&::-webkit-scrollbar {
		width: 6px;
		height: 6px;
		background-color: none;
		${media.lg`
			width: 0;
		`};
	}

	&::-webkit-scrollbar-track {
		background-color: none;
	}

	&::-webkit-scrollbar-track-piece {
		background-color: none;
	}

	&::-webkit-scrollbar-thumb {
		background-color: none;
		border-radius: 15em;
	}

	/* For FireFox */
	scrollbar-width: thin;
	scrollbar-color: ${theme('palette.scrollbar-color')} rgba(128, 128, 128, 0.5);

	&:hover {
		&::-webkit-scrollbar-track-piece {
			background-color: rgba(128, 128, 128, 0.03);
		}

		&::-webkit-scrollbar-thumb {
			background-color: ${theme('palette.scrollbar-color')};
		}
	}
`;

export const scrollbarContainerStyle = css`
	${scrollbarStyle};
	overflow-x: hidden;
	overflow-y: auto;
	/* Only supported in Chrome & Safari */
	@supports (overflow-y: overlay) {
		overflow-y: overlay;
	}
	-webkit-overflow-scrolling: touch;

	scroll-behavior: smooth;

	/* Remove the outline in Chrome when clicking on the scrollbar container */
	&:focus {
		outline: 0;
	}
`;

export const anchorStyle = css`
	color: ${theme('palette.primary-background')};
	position: relative;
	cursor: pointer;
	text-decoration: underline;
`;

export const transparentContainer = css`
	border-radius: 4px;
    position: relative;
	z-index: 0;
	min-height: 3em;
	${media.md`
		margin-top: calc(25px + .5em);
	`}

    &::before {
        content: '${prop('heading')}';
        padding: 0 1em;
        position: absolute;
		z-index: -1;
        top: 0;
        left: 1em;
        transform: translateY(-50%);
        background-color: ${theme('palette.body-background')};
        font-size: 13px;
        text-transform: uppercase;

		${media.md`
			font-size: 16px;
			/* color: ${withProp('theme.palette.heading-color', diffColor(0.023))}; */
			background: transparent;
			width: 100%;
			left: 0;
			text-align: center;
			transform: translateY(0);
			top: calc(-25px - .5em);
			font-weight: bold;

		`}
    }

	
	${isMemberType(
		'CLIENT',
		css`
			border: 1px solid ${withProp('theme.palette.body-background', diffColor(0.15))};
			&::before {
				color: ${withProp('theme.palette.primary-background', diffColor(0.023))};
			}
			${media.md`
				border: none;
			`};
		`,
		css`
			border: 1px solid ${withProp('theme.palette.body-background', diffColor(0.1))};
			&::before {
				color: ${theme('palette.primary-background')};
			}
		`,
	)}
`;

/**
 * iOS browser 中，非 button/input 元素沒有 click 事件
 * 需加上 cursor: pointer 讓元素有 click 事件
 */
export const iOSClickableFix = css`
	@media screen and (pointer: coarse) {
		cursor: pointer;
	}
`;

export const viewportMask = css`
	${iOSClickableFix};
	position: fixed;
	top: 0;
	right: 0;
	bottom: 0;
	left: 0;
	z-index: 0;
`;

// font of gradient colors
export const GradientFont = css`
	/* background: ${theme('palette.primary-gradient')};
	-webkit-background-clip: text;
	-webkit-text-fill-color: transparent;
	box-decoration-break: clone; */
	color: ${withProp('theme.palette.primary-background', diffColor(0.05))};
`;
