import Head from 'next/head';
import React, { PureComponent } from 'react';
import styled, { css } from 'styled-components';
import { ifProp, theme } from 'styled-tools';
import config from '../../../config/config';
import InnerHTML from 'dangerously-set-html-content'
import Intl from '../../common/Intl'
// Utils
import media, { breakpoints } from '../../../util/mediaUtil';
import 'src/static/sportradar.css';
interface ISportRadarProps {
	betradarId: string;
	categoryId: number;
	isUsingCricketScoreIFrame?: boolean;
	// 是否要開啟浮動功能
	isUsingFloat: boolean;
	// 為產出多個script 準備
	categoryKey?: string;
}

interface ISportRadarState {
	isLoaded: boolean;
	isFloating: boolean;
}

class SportRadar extends PureComponent<ISportRadarProps, ISportRadarState> {
	state = {
		isLoaded: false,
		isFloating: false,
	};

	componentDidMount() {
		this.setState({
			isLoaded: true,
		});
	}

	toggleFloat = () => {
		this.setState(
			prevState => {
				return {
					isFloating: !prevState.isFloating,
				};
			},
			() => {
				// Trigger the widget to reposition layout
				window.dispatchEvent(new Event('resize'));
			},
		);
	};

	render() {
		const { betradarId, categoryId, isUsingCricketScoreIFrame, categoryKey } = this.props;
		const { isLoaded, isFloating } = this.state;
		const sportRadarConfig = getSportRadarConfig(Number(betradarId), categoryId, isUsingCricketScoreIFrame);
		// const html:any = getSIRScript(sportRadarConfig, categoryKey)
		// console.log('whats ', html)
		const html = `
		<Head>
    <script>
		${getSIRScript(sportRadarConfig, categoryKey).__html}
	</script>
	</Head>
  `
		return (
			<>
				{isLoaded && sportRadarConfig && (
					// <Head>
					// 	<div dangerouslySetInnerHTML={getSIRScript(sportRadarConfig, categoryKey)} />
					// </Head>
					<InnerHTML html={html}  />
				)}

				<SIRWrapper isFloating={isFloating}>
					<div className={`sr-widget sr-widget${categoryKey}`} />
					{this.props.isUsingFloat && (
						<FloatButton onClick={this.toggleFloat}>{isFloating ? <Intl langKey="BETRADAR@CLOSE" /> : <Intl langKey="BETRADAR@FLOAT" />}</FloatButton>
					)}
				</SIRWrapper>
			</>
		);
	}
}

export default SportRadar;

interface SportRadarConfig {
	matchId: number;
	// 上方標題列，內容為聯賽名稱，預設 true
	disableWidgetHeader?: boolean;
	// 滾球動畫，關閉後 layout 強制變為 single
	disablePitch?: boolean;
	// single: 線圖 + 滾球動畫縱向排列
	// double: 線圖 + (滾球動畫 + 計分板 (score card) 橫向排列)
	// topdown: 線圖 + 滾球動畫 + 計分板縱向排列
	layout?: 'single' | 'double' | 'topdown';
	// 是否顯示線圖
	detailedScoreboard?: 'enable' | 'disable';
	// 線圖樣式
	momentum?: 'compact' | 'bars' | 'extended' | 'disable';
	// 線圖位置要顯示線圖還是比分
	activeSwitcher?: 'momentum' | 'scoreDetails';
	// 上方比分 (非 score card)
	scoreboard?: 'compact' | 'extended' | 'disable';
	// 收合範圍
	collapseTo?: 'disable' | 'scoreboard' | 'momentum';
	// 初始收合狀態
	expanded?: boolean;
	// tab 位置
	tabsPosition?: 'top' | 'bottom';
	// 顯示的 tabs
	tabs?: {
		statistics: boolean;
		headToHead: boolean;
		standings: boolean;
		timeline: boolean;
		lineups: boolean;
	};
	// logo 連結
	pitchBgImage?: string;
	adsFrequency?: 'high' | 'medium' | 'low' | false;
	//
}

const getSportRadarConfig = (
	matchId: number,
	categoryId: number,
	isUsingCricketScoreIFrame?: boolean,
): SportRadarConfig | undefined => {
	switch (categoryId) {
		// Football
		case 1:
			return {
				matchId,
				layout: 'double',
				scoreboard: 'extended',
				detailedScoreboard: 'enable',
				activeSwitcher: 'scoreDetails',
				momentum: 'compact',
				tabsPosition: 'top',
				adsFrequency: false,
				expanded: false,
				collapseTo: 'scoreboard',
			};
		// tennis
		case 2:
			return {
				matchId,
				layout: 'double',
				scoreboard: 'extended',
				detailedScoreboard: 'enable',
				activeSwitcher: 'scoreDetails',
				momentum: 'compact',
				tabsPosition: 'top',
				adsFrequency: false,
				expanded: false,
				collapseTo: 'scoreboard',
			};
		// cricket
		case 4:
			return {
				matchId,
				layout: 'double',
				scoreboard: isUsingCricketScoreIFrame ? 'disable' : 'compact',
				activeSwitcher: 'scoreDetails',
				detailedScoreboard: 'enable',
				momentum: 'compact',
				tabsPosition: 'top',
				adsFrequency: false,
				expanded: false,
				collapseTo: 'scoreboard',
			};
		default:
			console.error(`[SportRadar]: categoryId is expected to be 1, 2 or 4, but got ${categoryId}`);
			return;
			
	}
};

const getSIRScript:any = (sportRadarConfig: SportRadarConfig, categoryKey?: string) => {
	return {
		__html: `
			(function(a,b,c,d,e,f,g,h,i){a[e]||(i=a[e]=function(){(a[e].q=a[e].q||[]).push(arguments)},i.l=1*new Date,i.o=f,
			g=b.createElement(c),h=b.getElementsByTagName(c)[0],g.async=1,g.src=d,g.setAttribute("n",e),h.parentNode.insertBefore(g,h)
			)})(window,document,"script", "https://ws-cdn001.akamaized.net/d1cb26b628c3350c7c71e5646ce2b7a6/widgetloader", "SIR", {
				theme: false, // using custom theme
				language: "en"
			});

			SIR("addWidget", ".sr-widget${categoryKey}", "match.lmtPlus", ${JSON.stringify(sportRadarConfig)});
		`,
	};
};

// Styled components
const FloatButton = styled.button`
	position: absolute;
	z-index: 1;
	left: 0.5em;
	bottom: 0.5em;
	background-color: white;
	border: 0;
	display: flex;
	justify-content: center;
	align-items: center;
	box-shadow: 0 2px 5px rgba(0, 0, 0, 0.25);
	border-radius: 2px;

	&:focus {
		outline: 0;
	}

	${media.sm`
		display: none;
	`};
`;

const SIRWrapper: any = styled.div`
	display: flex;
	justify-content: center;
	position: relative;
	z-index: 0;
	width: 100%;
	background-color: ${theme('palette.header-background')};

	${ifProp(
		'isFloating',
		css`
			@media screen and (min-width: ${breakpoints.sm}px) {
				width: 300px;
				position: fixed;
				bottom: 1em;
				right: 1em;
				box-shadow: 0 2px 5px rgba(0, 0, 0, 0.15);
				z-index: 999;
			}
		`,
	)};

	.sr-widget {
		max-width: none;
		margin: 0;
		width: 100%;
	}

	/* 下方 expand bar */
	.sr-bb .sr-lmt-plus__footer-wrapper {
		min-height: 32px;
	}

	/* 下方 expand bar 裡的按鈕 */
	.sr-lmt-plus.sr-lmt-plus--large .sr-lmt-plus__expand-wrapper,
	.sr-bb .sr-lmt-plus__expand-wrapper {
		max-width: 96px;
		border-radius: 2px;
		height: calc(100% - 4px);
		background-color: rgba(128, 128, 128, 0.15);

		&:hover {
			background-color: rgba(128, 128, 128, 0.3);
		}
	}
`;
