import React from 'react'

type Props = {}

export default function ApprovedIcon(props) {
  return (
    <svg fill="none" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16"><circle cx="8.409" cy="8.497" r="7.5" fill="#0EAD69"/><path d="m12.695 5.282-5.893 5.893-2.678-2.678" stroke="#fff" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/></svg>
  )
}


