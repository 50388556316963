import  { useEffect } from 'react'
import PlaceholderImage from 'src/components/Placeholder';
import config from 'src/config/config';
import { withAppContext } from 'src/store/initAppContext';
import styled, { withTheme } from 'styled-components';
import { theme } from 'styled-tools';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import FooterContainer from "src/components/footer/footer";



const CricketCashbackMarathon = (props) => {
    const { app: { brandId
    }, theme } = props;

    useEffect(() => {
        window.scrollTo(0, 0)
    }, [])

    const domain = brandId === 31 || brandId === 32 ? 'Yolo247' : brandId === 33 ? 'iw247' : 'Betdaily';

    return (
        <>
            <InnerPromoWrapper>
                    <CricketCMContentWrapper>
                        <p className='mt-5'>
                           Embark on a thrilling cricket journey through January to March, soaking in the excitement of Super Smash, SA20, BPL, ILT20 & many more upcoming sporting events. {domain} presents the cricket cashback marathon, infusing the swagger of cricket into every wager.
                        </p>
                        <CricketCMCupBox>
                            <h4>Daily Delights, Weekly Wonders</h4>
                            <p>
                                Get ready for heart-pounding moments with our dazzling promotion. Deposit daily and enjoy a turbocharged weekly double cashback of up to 10%! Immerse yourself in the thrill, turning each match into a celebration of your cricket betting finesse.
                            </p>

                            <CricketCMCupBoxInner>
                                <CricketCMCupBoxInnerLeft>
                                    <LazyLoadImage src={`${config.imageV3Host}/static/cashsite/${theme.image.cricketCMPromoCupLeft}`} effect="none" width="100%" placeholder={<PlaceholderImage />} />
                                </CricketCMCupBoxInnerLeft>
                                <CricketCMCupBoxInnerRight>
                                     <h4>🏏 <span className='headViolet'>How It's Woven into Glory:</span></h4>
                                     <p>
                                        <span>Knock Daily Wagers for Six: </span>Mirror the cricket legends by making a daily deposit from Monday to Sunday, echoing the excitement of iconic cricket tournaments like Super Smash, SA20, BPL, ILT20, or any other game you fancy.
                                     </p>
                                     <p>
                                        <span>Celebrate Like a Maestro: </span>Deposit successfully every day of the week and qualify for the additional cashback bonus – your victorious moment in the enchanting realm of cricket betting.
                                     </p>
                                     <p>
                                        <span>Magic from Losses: </span>At each week's end, transform losses into magic with our sensational cashback bonus. Avail up to an incredible 10% cashback on your net losses – a game-changing move in the world of online cricket & casino betting.
                                     </p>
                                </CricketCMCupBoxInnerRight>
                            </CricketCMCupBoxInner>
                        </CricketCMCupBox>

                        <CricketCMTextSec>
                            <h4>🎁 <span className='headViolet'>Monday's Joyous Gift</span></h4>
                            <p>
                                 Kickstart your week with a joyous gift! The cashback bonus will be promptly showered into your account every Monday, offering a remarkable opportunity to elevate your earnings and dive back into the cricket festivities with renewed enthusiasm.
                            </p>
                            <h6>
                                 Don't miss the chance to make this cricket season a symphony of joy. Join {domain} now and revel in the delight of cricket betting at its most exquisite!
                            </h6>
                        </CricketCMTextSec>

                        <CricketCMPrizeSec>
                               <h4>Daily Deposit Bonus Scoreboard for Blue, Silver, Gold & Platinum Tiers</h4>
                               <LazyLoadImage src={`${config.imageV3Host}/static/cashsite/${theme.image.circketCMPromoTable}`} effect="none" width="100%" placeholder={<PlaceholderImage />} />
                        </CricketCMPrizeSec>

                        <CricketCMTerms>
                            <CricketCMTermsLeft>
                               <LazyLoadImage src={`${config.imageV3Host}/static/cashsite/${theme.image.circketCMPromoTCHead}`} effect="none" width="100%" placeholder={<PlaceholderImage />} />
                            </CricketCMTermsLeft>
                            <CricketCMTermsRight>
                               <LazyLoadImage src={`${config.imageV3Host}/static/cashsite/${theme.image.cricketCMTCHeading}`} effect="none" width="100%" placeholder={<PlaceholderImage />} />
                               <ol>
                                    <li>This promotion is valid for all new & existing {domain} members.</li>
                                    <li>This bonus amount will be capped at INR 7,000.</li>
                                    <li>Bonus amount will be issued into your Main wallet as cashback bonus every Monday only if you have successfully deposited all 7 days in a week (Mon-Sun).</li>
                                    <li>Multiple accounts will not be considered for this promotion. All players will be subject to duplicate checking. {domain} reserves the right to disqualify players if any collusion or any foul play takes place in the promotion.</li>
                                    <li>This promotion cannot be used in conjunction with, or to contribute to the eligibility for, any other promotion.</li>
                                    <li>{domain} reserves the right to amend, cancel and reclaim or refuse any promotion at its own discretion.</li>
                                    <li>In the event, any fraudulent activity of the player(s) has been identified, {domain} reserves the right to withhold your funds and reject your withdrawals.</li>
                                    <li>{domain} General Terms & Conditions apply.</li>
                                </ol>
                            </CricketCMTermsRight>
                        </CricketCMTerms>
                    </CricketCMContentWrapper>
            </InnerPromoWrapper>
            <FooterContainer />
        </>
    )
}

export default withTheme(withAppContext(CricketCashbackMarathon));

//Styled Components
const CricketCMContentWrapper = styled.div`
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background: url(${config.imageV3Host}/static/cashsite/${theme('image.cricketCMPromoInnerBg')});
    background-position: top;
    background-size: cover;
    background-repeat: no-repeat;
    gap: 2em;

    h3 {
        text-align: center;
        font-size: 26px;
        font-style: italic;
        font-weight: 700;
        line-height: normal;
        letter-spacing: 1.3px;
        text-transform: uppercase;
        background: linear-gradient(180deg, #FADA34 0%, #DE9101 100%);
        background-clip: text;
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
        padding-top: 20px;
    }

    p {
        color: #FFF;
        text-align: center;
        font-size: 21px;
        font-style: italic;
        font-weight: 400;
        line-height: normal;
    }

    .m-0 {
        margin: 0;
    }

    .mt-5 {
        margin-top: 2em;
        width: 90%;
    }

    .p-0 {
        padding: 0rem 20rem;

        span{
            color: #E5A20D;
        }
    }

    a {
        width: 100%;
        height: auto;

        & span {
            display: flex !important;
            justify-content: center !important;

            & img {
                width: 60%;
                height: 80%;
            }
        }
    }

    .lazy-load-image-background {
        display: flex !important;
        justify-content: center !important;
    }

    .container {
        max-width: 1620px;
        margin: auto;
        display: flex;
        flex-direction: column;
        align-items: center;
    }
`;


const InnerPromoWrapper = styled.div`
    width: 98%;
    height: auto;
    border: 1px solid white;
    border-radius: 1px;
    background: rgba(111,111,111,0.5);
    margin: 0em 1.2em;
    -webkit-backdrop-filter: brightness(0.5);
    backdrop-filter: brightness(0.5);
`

const CricketCMCupBox = styled.div`
   width: 90%;
   height: auto;
   border-radius: 30px;
   border: 4px solid #FCFAFF;
   background: radial-gradient(127.44% 76.28% at 25% 58.29%, #8F2E8A 0%, #471251 17.75%, #161429 34.26%, #060C19 100%);
   padding: 2em;

   & > h4 {
        text-align: center;
        font-size: 22px;
        font-style: normal;
        font-weight: 700;
        line-height: normal;
        letter-spacing: 1.3px;
        text-transform: uppercase;
        background: linear-gradient(180deg, #E62ED4 0%, #9F00AF 100%);
        background-clip: text;
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
   }

   & p {
        color: #FFF;
        text-align: center;
        font-size: 21px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
   }
`

const CricketCMCupBoxInner = styled.div`
    width: 95%;
    height: auto;
    display: flex;
    gap: 15px;
    align-items: center;
    margin-top: 1em;
`

const CricketCMCupBoxInnerLeft = styled.div`
    width: 50%;
    height: auto;

    & img {
        width: 60%;
        height: auto;
    }
`

const CricketCMCupBoxInnerRight = styled.div`
   width: 50%;
   height: auto;


    & .headViolet {
        text-align: left;
        font-size: 22px;
        font-style: normal;
        font-weight: 700;
        line-height: normal;
        letter-spacing: 1.3px;
        text-transform: uppercase;
        background: linear-gradient(180deg, #E62ED4 0%, #9F00AF 100%);
        background-clip: text;
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
    }

    & p {
        color: #FFF;
        font-size: 18px;
        font-style: normal;
        font-weight: 400;
        line-height: 24.5px;
        text-align: left;

        & span {
            color: #DFB44B;
            text-align: justify;
            font-size: 20px;
            font-style: normal;
            font-weight: 600;
            line-height: 24.5px; /* 122.5% */
        }
    }
`

const CricketCMTextSec = styled.div`
    width: 90%;
    display: flex;
    flex-direction: column;
    height: auto;
    justify-content: center;
    align-items: center;
    margin: 2em 0em;



    & .headViolet {
        text-align: center;
        font-size: 22px;
        font-style: normal;
        font-weight: 700;
        line-height: normal;
        letter-spacing: 1.3px;
        text-transform: uppercase;
        background: linear-gradient(180deg, #E62ED4 0%, #9F00AF 100%);
        background-clip: text;
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
    }

    & p {
        color: #FFF;
        font-size: 18px;
        font-style: normal;
        font-weight: 400;
        line-height: 24.5px;
        text-align: center;
        margin: 5px 0px;
    }

    & h6 {
        color: #DFB44B;
        text-align: center;
        font-size: 16px;
        font-style: italic;
        font-weight: 400;
        line-height: normal;
        margin: 5px 0px;
    }
`


const CricketCMPrizeSec = styled.div`
   width: 90%;
   height: auto;
   border-radius: 30px;
   border: 4px solid #FCFAFF;
   background: radial-gradient(247.98% 127.05% at 7.62% 10%, #161429 0%, #010914 100%);
   display: flex;
   flex-direction: column;
   justify-content: center;
   padding: 2em;
   gap: 10px;

   & h4 {
        text-align: center;
        font-size: 22px;
        font-style: normal;
        font-weight: 700;
        line-height: normal;
        letter-spacing: 1.3px;
        text-transform: uppercase;
        background: linear-gradient(180deg, #E62ED4 0%, #9F00AF 100%);
        background-clip: text;
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
        margin: 5px 0px;
    }

    & img {
        width: 100%;
        height: auto;
    }
`

const CricketCMTerms = styled.div`
    width: 90%;
    height: auto;
    display: flex;
    gap: 10px;
    align-items: center;
`

const CricketCMTermsLeft = styled.div`
     width: 40%;
     height: auto; 

     & img {
        width: 100%;
        height: auto;
     }
`

const CricketCMTermsRight = styled.div`
    width: 50%;
    height: auto;

    & img {
        width: 60%;
        height: auto;
    }

    & li {
        color: #FFF;
        text-align: justify;
        font-size: 17px;
        font-style: normal;
        font-weight: 600;
        line-height: 165%;
        letter-spacing: -0.8px;
        text-transform: capitalize;

    }
`