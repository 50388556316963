import React, { useState, useEffect } from 'react';
import config from 'src/config/config';
import { withAppContext } from 'src/store/initAppContext';
import styled, { withTheme } from 'styled-components'
import { theme } from 'styled-tools';

export const getUserTime = (timestamp) => {
  const mint = 60000;
  const maxReqMint = mint * 30;
  const secondsDiff = Date.parse(timestamp) - Date.now();
  const validdMints = secondsDiff + maxReqMint;
  let balanceMins = validdMints / mint;
  return balanceMins || 0;
}

type TimerDepositProps = {
  app: any;
  theme: any;
  created: any;
}

const TimerDeposit = (props: TimerDepositProps) => {
  const { app: { }, theme, created } = props;
  const [minutes, setMinutes] = useState(0);
  const [seconds, setseconds] = useState(0);
  const { config: { showFun88TimeCounter } } = theme;

  useEffect(() => {
    let t = setInterval(() => {
      let balanceMins: any = getUserTime(created);
      let minutesTime = Math.floor(balanceMins);
      let secondsTime = Math.floor((balanceMins - minutesTime) * 60);
      setMinutes(minutesTime);
      setseconds(secondsTime);
      if (balanceMins <= 0) {
        clearInterval(t)
      }
    }, 1000)

    return () => {
      clearInterval(t)
    }
  }, []);

  return (
    <>
      {
        showFun88TimeCounter ?
        <>
          {minutes <= 0 ? <></> :
            <MainWrapperMore>
              <ImgDiv>
                <img src={`${config.imageV3Host}/static/cashsite/${theme.image.iplLogo}`} />
                <img src={`${config.imageV3Host}/static/cashsite/${theme.image.ipltext}`} className='textImg' />
              </ImgDiv>
              <TimeDetailsMore>
                <span>OFFER ENDS IN</span>
                <TimerDivDepMore>
                  <span>00:{minutes}:{seconds < 10 ? '0' : ''}{seconds}</span>
                </TimerDivDepMore>
                <span>MINUTES</span>
              </TimeDetailsMore>
            </MainWrapperMore>
          }
        </>
        :
        <>
          {minutes <= 0 ? <></> :
            <MainWrapper>
                <img src={`${config.imageV3Host}/static/cashsite/${theme.image.iplLogo}`} />
                <img src={`${config.imageV3Host}/static/cashsite/${theme.image.ipltext}`} />
              <TimeDetails>
                <span>OFFER ENDS IN</span>
                <TimerDivDep>
                  <span>00:{minutes}:{seconds < 10 ? '0' : ''}{seconds}</span>
                </TimerDivDep>
                <span>MINUTES</span>
              </TimeDetails>
            </MainWrapper>
          }
        </>
      }
    </>
  );
}

export default withTheme(withAppContext(TimerDeposit))

const ImgDiv = styled.div`
  width: calc(100% - 131px);
  height: 100%;
  /* min-height: 109px; */
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 5px 10px 5px 5px;
  background-image: url("https://opt-v3-files.raksahb.com/static/cashsite/brand/6/image/depositToggleBGfun88New.jpg");
  background-repeat: no-repeat;
  background-size: cover;
  background-position: left;
  border-top-left-radius: 5px;
  border-bottom-left-radius: 5px;

  .textImg {
    /* width: calc(100% - 76px); */
    width: 75%;
    padding-left: 5px;
  }
`

export const TimerDivDep = styled.div`
  color: ${theme('paletee.timertextspan')};
  font-size: 25px;
  font-weight: bolder;
`

export const TimerDivDepMore = styled(TimerDivDep)`
  color: red;
`

export const MainWrapper = styled.div`
    width: 47%;
    min-height: 109px;
    background-image: url(${config.imageV3Host}/static/cashsite/${theme('image.depositToggleBG')});
    background-repeat: no-repeat;
    background-size: cover;
    border-radius: 11px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 3px 6px 3px 3px;
    img{
        width: 24%;
    }
    .text{
      img{
        width: 30%;
      }
    }
`


const MainWrapperMore = styled(MainWrapper)`
    padding: 0;
    border-radius: 5px;
    background-image: none;
    height: 85px;
    min-height: auto;
    /* min-height: 85px;
    max-height: 70px; */
`

export const TimeDetails = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-transform: uppercase;
  color: ${theme('paletee.timertext')};
  font-weight: 700;
  font-size: 17.2762px;
`

export const TimeDetailsMore = styled(TimeDetails)`
  width: 131px;
  /* min-height: 109px; */
  height: 100%;
  background-color: #c7e8ff;
  /* padding: 10px; */
  text-align: center;
  border-top-right-radius: 5px;
  border-bottom-right-radius: 5px;
  font-size: 15px;
  padding: 0;
  
`