import React, { useEffect } from 'react'
import { withAppContext } from 'src/store/initAppContext';
import styled, { withTheme } from 'styled-components';
import { LazyLoadImage } from "react-lazy-load-image-component";
import config from "src/config/config";
import PlaceholderImage from 'src/components/Placeholder';
import { theme } from 'styled-tools';
import FooterContainer from 'src/components/footer/footer';

function MinesLegendTrophy(props) {
    const { app: {
        brandId
    }, theme } = props;

    useEffect(() => {
        window.scrollTo(0, 0)
    }, [])

    const domain = brandId == 31 || brandId == 32 ? "Yolo247" : (brandId == 39 || brandId == 40) ? "FOMO7" : 'iw247';

    return (
        <>
            <InformationWrapper>
                <TitleText>
                    <h6><span className={(brandId == 31 || brandId == 32) ? "white" : "yellow"}>Are you ready to become a legend?</span> Join the thrilling Mines Legend Trophy competition from <span className={(brandId == 31 || brandId == 32) ? "white" : "yellow"}>1st July to 31st July 2024</span></h6>
                    <h6>only on <span className={(brandId == 31 || brandId == 32) && "white"}>{domain}</span> and show off your skills for a chance to win massive rewards and eternal glory!</h6>
                </TitleText>
                {(brandId == 39 || brandId == 40) ? (
                    <>
                        <Divider></Divider>
                        <LoyaltyWrapper>
                            <HeadingText className='titleText'>
                                <h1>Why Play for the Mines Legend Trophy?</h1>
                            </HeadingText>
                            <UnderLine></UnderLine>
                        </LoyaltyWrapper>
                    </>
                ) : (
                    <h1 className='yoloTitle'>Why Play for the Mines Legend Trophy?</h1>
                )}
                <JoiningWrapper>
                    <JoiningContainer>
                        <JoiningContent className={(brandId == 31 || brandId == 32) && "yoloBox1"}>
                            <LeftContent>
                                <h1 className={(brandId == 31 || brandId == 32) && 'boldMines'}>Exhilarating Gameplay</h1>
                                <p>Experience the rush of uncovering tiles while avoiding mines for huge rewards.</p>
                            </LeftContent>
                            <LazyLoadImage
                                src={`${config.imageV3Host}/static/cashsite/${theme.image.MinesLegendTrophyImgOne}`}
                                alt="MinesLegend"
                                effect="none"
                                placeholder={<PlaceholderImage />}
                            />
                        </JoiningContent>
                        <JoiningContent className={(brandId == 31 || brandId == 32) && "yoloBox2"}>
                            <LeftContent>
                                <h1 className={(brandId == 31 || brandId == 32) && 'boldMines'}>Legendary Prizes</h1>
                                <p>Stand a chance to win exclusive bonuses.</p>
                            </LeftContent>
                            <LazyLoadImage
                                src={`${config.imageV3Host}/static/cashsite/${theme.image.MinesLegendTrophyImgTwo}`}
                                alt="MinesLegend"
                                effect="none"
                                placeholder={<PlaceholderImage />}
                            />
                        </JoiningContent>
                    </JoiningContainer>
                    <JoiningContainer>
                        <JoiningContent className={(brandId == 31 || brandId == 32) && "yoloBox3"}>
                            <LeftContent>
                                <h1 className={(brandId == 31 || brandId == 32) && 'boldMines'}>Fair and Transparent</h1>
                                <p>Enjoy provably fair gameplay that ensures a level playing field for everyone.</p>
                            </LeftContent>
                            <LazyLoadImage
                                src={`${config.imageV3Host}/static/cashsite/${theme.image.MinesLegendTrophyImgThree}`}
                                alt="MinesLegend"
                                effect="none"
                                placeholder={<PlaceholderImage />}
                            />
                        </JoiningContent>
                        <JoiningContent className={(brandId == 31 || brandId == 32) && "yoloBox4"}>
                            <LeftContent>
                                <h1 className={(brandId == 31 || brandId == 32) && 'boldMines'}>Competition</h1>
                                <p>Compete against players from all over India and rise to the top of the leaderboard</p>
                            </LeftContent>
                            <LazyLoadImage
                                src={`${config.imageV3Host}/static/cashsite/${theme.image.MinesLegendTrophyImgFour}`}
                                alt="MinesLegend"
                                effect="none"
                                placeholder={<PlaceholderImage />}
                            />
                        </JoiningContent>
                    </JoiningContainer>
                </JoiningWrapper>
                <HeaddingWrapper>
                    <HeadingText className='titleText'>
                        <h1 className={(brandId == 31 || brandId == 32) && 'yoloTitle'}>How to Compete:</h1>
                    </HeadingText>
                    {(brandId == 39 || brandId == 40) && <UnderLine></UnderLine>}
                </HeaddingWrapper>
                <BoxContainer>
                    <BoxContent>
                        <LazyLoadImage src={`${config.imageV3Host}/static/cashsite/${theme.image.MinesLegendRegisterImgOne}`} effect="none" placeholder={<PlaceholderImage />} />
                        <h1>Register Today</h1>
                        <p>Sign up for the Mines Legend Trophy competition and prepare for the ultimate challenge</p>
                    </BoxContent>
                    <BoxContent className='yellowBg'>
                        <LazyLoadImage src={`${config.imageV3Host}/static/cashsite/${theme.image.MinesLegendRegisterImgTwo}`} effect="none" placeholder={<PlaceholderImage />} className="imgTwo" />
                        <h1>Set Your Bet Amount</h1>
                        <p>Minimum bet amount is just INR 10 !!</p>
                    </BoxContent>
                    <BoxContent className={(brandId == 31 || brandId == 32) ? 'redBg' : 'blueBg'}>
                        <LazyLoadImage src={`${config.imageV3Host}/static/cashsite/${theme.image.MinesLegendRegisterImgThree}`} effect="none" placeholder={<PlaceholderImage />} className="imgThree" />
                        <h1>Pick the Number of Mines</h1>
                        <p>Increase the difficulty for higher rewards.</p>
                    </BoxContent>
                    <BoxContent className={(brandId == 31 || brandId == 32) ? 'orangeBg' : 'redBg'}>
                        <LazyLoadImage src={`${config.imageV3Host}/static/cashsite/${theme.image.MinesLegendRegisterImgFour}`} effect="none" placeholder={<PlaceholderImage />} />
                        <h1>Uncover Tiles</h1>
                        <p>Click to reveal gems, avoid mines, and watch your winnings grow. Cash out before hitting a mine!</p>
                    </BoxContent>
                </BoxContainer>
                <HeaddingWrapper>
                    <HeadingText className='titleText'>
                        <h1 className={(brandId == 31 || brandId == 32) && 'yoloTitle'}>Prize Allocation</h1>
                    </HeadingText>
                    {(brandId == 39 || brandId == 40) && <UnderLine></UnderLine>}
                </HeaddingWrapper>
                <ROWrapper>
                    <LazyLoadImage
                        src={`${config.imageV3Host}/static/cashsite/${theme.image.MinesLegendTable}`}
                        alt="jetxPromoStepThree"
                        effect="none"
                        placeholder={<PlaceholderImage />}
                    />
                    <p>*T&C : Winning bonus amount will be announced & added in your {domain} wallet on 3rd August 2024</p>
                </ROWrapper>
                {(brandId == 39 || brandId == 40) ? (
                    <Termsandconditions>
                        <TermsandconditionsHeading>
                            <h1>Terms & Conditions</h1>
                        </TermsandconditionsHeading>
                        <UnderLine></UnderLine>
                        <TermsandconditionsBody>
                            <ol>
                                <li>This promotion is valid for all new & existing players for the month of July 2024.</li>
                                <li><span className='yellow'>777 Winners </span> will be announced on <span className='yellow'>3rd August 2024 </span>on {domain} social media handles ( Instagram & Telegram) and your respective F7 inbox.</li>
                                <li>This promotion is only applicable on MINES games of any provider.</li>
                                <li>Void/Cancelled/Draw bets, Cashed-Out bets do not count towards the deposit or bonus rollover requirements.</li>
                                <li>Multiple accounts will not be considered for this promotion eligibility / criteria. All players will be subject for duplicate checking. {domain} reserves the right to disqualify players if collusion or any foul play takes place in the promotion.</li>
                                <li>{domain} reserves the right to amend, cancel and reclaim or refuse any promotion at its own discretions.</li>
                                <li>{domain} General Terms & Conditions apply</li>
                            </ol>
                        </TermsandconditionsBody>
                    </Termsandconditions>
                ) : (
                    <MinesTermsAndCondition>
                        <h4>Terms & Conditions</h4>
                        <ul>
                            <li>This promotion is valid for all new & existing players for the month of July 2024.</li>
                            <li><span className='bold'>777 Winners </span> will be announced on <span className='bold'>3rd August 2024 </span>on {domain} social media handles ( Instagram & Telegram) and your respective {domain} inbox.</li>
                            <li>This promotion is only applicable on MINES games of any provider.</li>
                            <li>Void/Cancelled/Draw bets, Cashed-Out bets do not count towards the deposit or bonus rollover requirements.</li>
                            <li>Multiple accounts will not be considered for this promotion eligibility / criteria. All players will be subject for duplicate checking. {domain} reserves the right to disqualify players if collusion or any foul play takes place in the promotion.</li>
                            <li>{domain} reserves the right to amend, cancel and reclaim or refuse any promotion at its own discretions.</li>
                            <li>{domain} General Terms & Conditions apply</li>
                        </ul>
                    </MinesTermsAndCondition>
                )}
            </InformationWrapper>
            <FooterContainer />
        </>
    )
}



export default withTheme(withAppContext(MinesLegendTrophy));

export const InformationWrapper = styled.div`
    width: 98%;
    height: auto;
    padding: 3rem 3rem 8rem 3rem;
    border-radius: 1px;
    background: rgba(111, 111, 111, 0.5);
    margin: 0em 1.2em 0 1.2em;
    border: 1px solid #E5B358;
    border-radius: 0 0 20px 20px;
    border-top-color: transparent;
    backdrop-filter: brightness(0.5);    
    display: flex;
    flex-direction: column;
    background: url(${config.imageV3Host}/static/cashsite/${theme("image.MinesLegendTrophyBackground")});
    background-position: top;
    background-size: cover;
    background-repeat: no-repeat;

    .yoloTitle {
        margin-top: 0;
        text-align: center;
        font-size: 25px;
        font-style: normal;
        font-weight: 700;
        line-height: 35px; /* 140% */
        text-transform: capitalize;
        background: linear-gradient(90deg, #3CB9FC 31.85%, #3CB9FC 68.07%);
        background-clip: text;
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
    }
`;

const TitleText = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    padding: 1rem 0 4rem 0;

    h6 {
        margin: 0;
        padding: 0;
        color: #FFF;
        font-size: 16px;
        font-style: normal;
        font-weight: 500;
        line-height: 160.3%;
        text-transform: capitalize;
    }

    .yellow {
        color: #FEBA3B;
        font-weight: 700;
    }

    .white {
        color: #FFF;
        font-weight: 700;
    }
`;

const LoyaltyWrapper = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background: radial-gradient(37.24% 47.13% at 50% 0%, #051829 0%, rgba(1, 4, 6, 0.00) 100%);
    backdrop-filter: blur(6.849999904632568px);
    padding: 3rem;
    margin: 0 -2rem;

    .joiningTxt {
        padding-top: 1.5rem;
    }

    .titleText {
        padding: 1rem 0 0.2rem 0;
        h1 {
            margin-top: 0;
        }
    }
`;

const HeaddingWrapper = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 3rem;
    margin: 0 -2rem;

    .joiningTxt {
        padding-top: 1.5rem;
    }

    .titleText {
        padding: 1rem 0 0.2rem 0;
        h1 {
            margin-top: 0;
        }
    }

    .yoloTitle {
        text-align: center;
        font-size: 25px;
        font-style: normal;
        font-weight: 700;
        line-height: 35px; /* 140% */
        text-transform: capitalize;
        background: linear-gradient(90deg, #3CB9FC 31.85%, #3CB9FC 68.07%);
        background-clip: text;
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
    }
`;

const HeadingText = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;

    h1 {
        text-align: center;
        font-size: 25px;
        font-style: normal;
        font-weight: 600;
        line-height: normal;
        letter-spacing: 1.25px;
        background: linear-gradient(180deg, #FED546 18.06%, #FE7C23 91.67%);
        background-clip: text;
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
    }

    p {
        color: #FFF;
        text-align: center;
        font-family: Inter;
        font-size: 16px;
        font-style: normal;
        font-weight: 500;
        line-height: 120.3%; /* 19.248px */
    }
`;

export const UnderLine = styled.div`
    height: 2px;
    width: 184px;
    background: radial-gradient(#FFF 0%, rgba(153, 153, 153, 0.00) 80%);
`;

export const Divider = styled.div`
    height: 2px;
    width: 100%;
    background: radial-gradient(#1182C1 0%, #00000000 50%);
`;

const JoiningWrapper = styled.div`
    display: flex;
    justify-content: center;
    align-items: stretch;
    flex-direction: column;
    margin: 0 -3rem;
`;

const JoiningContainer = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: stretch;
    gap: 3rem;
    padding-top: 2rem;

    .yoloBox1 {
        border-radius: 8px;
border: 1px solid #FFC86A;
background: linear-gradient(101deg, #EC5D58 0%, #F4C451 99.91%);
    }

    .yoloBox2 {
        border-radius: 8px;
border: 1px solid #FF49C4;
background: linear-gradient(101deg, #6627C5 0%, #BA368F 99.91%);
    }

    .yoloBox3 {
        border-radius: 8px;
border: 1px solid #A3E2FF;
background: linear-gradient(101deg, #215FE1 0%, #57C4F6 99.91%);
    }

    .yoloBox4 {
        border-radius: 8px;
        border: 1px solid #FEFF94;
        background: linear-gradient(101deg, #87A500 0%, #FFFE00 99.91%);
    }
`;

const JoiningContent = styled.div`
    padding: 2rem 3rem;
    width: 40%;
    padding: 2rem 17% 2rem 2rem;
    border-radius: 10px;
    display: flex;
    border: 0.8px solid rgba(255, 255, 255, 0.50);
    background: radial-gradient(136.33% 139.78% at 0% 0%, rgba(254, 186, 59, 0.00) 63.5%, rgba(254, 186, 59, 0.20) 100%), radial-gradient(136.33% 139.78% at 0% 0%, rgba(255, 95, 31, 0.20) 0%, rgba(255, 95, 31, 0.00) 53.5%), rgba(255, 255, 255, 0.05);
    backdrop-filter: blur(12.5px);
    gap: 1rem;
    position: relative;

    p {
        margin: 0;
        color: #FFF;
        font-size: 18px;
        font-style: normal;
        font-weight: 500;
        line-height: 23px; /* 127.778% */
    }

    span {
        color: #FEBA3B;
    }

    img {
        width: 50%;
    }

    img {
        position: absolute;
        width: 10rem;
        top: 0.5rem;
        right: 8%;
    }
`;

const LeftContent = styled.div`
    display: flex;
    flex-direction: column;

    p {
        margin: 0;
        color: #FFF;
        font-size: 18px;
        font-style: normal;
        font-weight: 500;
        line-height: 23px; /* 127.778% */
    }

    h1 {
        color: #FEBA3B;
        font-size: 20px;
        font-style: normal;
        font-weight: 700;
        line-height: 35px; /* 175% */
        text-transform: capitalize;
    }

    .boldMines {
        color: #FFF;
    }
`;

const BoxContainer = styled.div`
    display: flex;
    justify-content: center;
    align-items: stretch;
    gap: 3.5rem;
    position: relative;

    .yellowBg {
        border-radius: 20px;
        border-bottom: 2px solid rgba(253, 205, 0);
        background: linear-gradient(180deg, rgba(176, 176, 176, 0.10) 70%, rgba(253, 205, 0, 0.40) 100%);
        backdrop-filter: blur(12.5px);
    }

    .blueBg {
        border-radius: 20px;
        border-bottom: 2px solid rgba(1, 224, 255);
        background: linear-gradient(180deg, rgba(176, 176, 176, 0.10) 70%, rgba(1, 224, 255, 0.40) 100%);
        backdrop-filter: blur(12.5px);
    }

    .redBg {
        border-radius: 20px;
        border-bottom: 2px solid rgba(255, 80, 108);
        background: linear-gradient(180deg, rgba(176, 176, 176, 0.10) 70%, rgba(255, 80, 108, 0.50) 100%);
        backdrop-filter: blur(12.5px);
    }

    .orangeBg {
        border-radius: 20px;
        border-bottom: 2px solid rgba(254, 110, 0);
        background: linear-gradient(180deg, rgba(176, 176, 176, 0.10) 70%, rgba(254, 110, 0, 0.50) 100%);
        backdrop-filter: blur(12.5px);  
    }
`;

const BoxContent = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    width: 20%;
    padding: 3.5rem 1.5rem 1.5rem 1.5rem;
    border-radius: 20px;
    border-bottom: 2px solid rgb(190 72 254);
    background: linear-gradient(180deg, rgba(176, 176, 176, 0.10) 70%, rgba(190, 72, 254, 0.40) 100%);
    backdrop-filter: blur(12.5px);


    h1 {
        color: #FFF;
        text-align: center;
        font-size: 20px;
        font-style: normal;
        font-weight: 700;
        line-height: normal;
        text-transform: capitalize;
    }

    p {
        color: #FFF;
        text-align: center;
        font-size: 18px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
    }

    img {
        width: 7rem;
    }
`;

const ROWrapper = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    position: relative;
    flex-direction: column;

    img {
        width: 90%;
    }

    p {
        color: #FFF;
        text-align: center;
        font-size: 16px;
        font-style: italic;
        font-weight: 400;
        line-height: 160.3%; /* 25.648px */
    }
`;

const Termsandconditions = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    border-radius: 30px;
    width: 60%;
    height: auto;
    margin: 3rem 20% 3rem 20%;
    padding: 5rem 3rem;
    position: relative;
    background: linear-gradient(#020405, rgba(0, 0, 0, 0.935), #020405);
    background-clip: padding-box;
    border: 2px solid transparent;

    ::after {
        position: absolute;
        top: -2px; bottom: -2px;
        left: -2px; right: -2px;
        background: linear-gradient(90deg, #53C1FF, black, #53C1FF, black, #53C1FF);
        content: '';
        z-index: -1;
        border-radius: 30px;
    }
`;

const MinesTermsAndCondition = styled.div`
    width: 100%;
    height: auto;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    gap: 10px;
    margin: 3rem 0rem 5rem 0rem;
    padding: 0 9rem 0 4rem;
    position: relative;

    & li {
        color: #FFF;
        text-align: justify;
        font-size: 18px;
        font-style: normal;
        font-weight: 400;
        line-height: 30px; /* 166.667% */
        text-transform: capitalize;
    }

    & h4 {
        font-size: 25px;
        font-style: normal;
        font-weight: 700;
        line-height: 35px; /* 140% */
        text-transform: capitalize;
        background: linear-gradient(90deg, #3CB9FC 31.85%, #3CB9FC 68.07%);
        background-clip: text;
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
        margin: 10px 0px 0px 15px;
    }

    .bold {
        color: #FFF;
        font-weight: 700;
    }
`;

const TermsandconditionsHeading = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;    
    padding-bottom: 2rem;

    h1 {
        margin: 0;
        padding: 0;
        text-align: center;
        font-size: 25px;
        font-style: normal;
        font-weight: 600;
        line-height: normal;
        letter-spacing: 1.25px;
        background: linear-gradient(180deg, #FED546 18.06%, #FE7C23 121.53%);
        background-clip: text;
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
    }
`;

const TermsandconditionsBody = styled.div`
    ol {
        display: flex;
        flex-direction: column;
        justify-content: center;
        gap: 1rem;
        padding-top: 1rem;
    }

    li {
        color: #FFF;
        font-size: 16px;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
    }

    .yellow {
        color: #FEBA3B;
        font-weight: 700;
    }
`;