import React, { useEffect } from 'react'
import config from 'src/config/config';
import { withAppContext } from 'src/store/initAppContext';
import styled, { withTheme } from 'styled-components';
import { theme } from 'styled-tools';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import {  FifaFeverFirstSecHead, TextContent } from './BblBonusInner';
import FooterContainer from "src/components/footer/footer";
import { ContentWrapper } from './aviatorBonusPage';
import { ItalicTextFull, OffersSection, TermsConditionPoints } from './crazyCrashPromo';
import IPLseo from 'src/components/SEOComponents/IPLseo';
import { InnerPromoWrapper } from './SmartsoftSpinPromo';
import IPLseoBD from 'src/components/SEOComponents/IPLseoBD';

const IplBettingDhamaka = (props) => {

    const { app: { brandId }, theme } = props;
    useEffect(() => {
        window.scrollTo(0, 0)
    }, [])

    const domain = brandId == 31 || brandId == 32 ? "Yolo247" : "IW247";

    return (
        <>
            <InnerPromoWrapper>
                <ContentWrapper1>
                    <InfoWrap>
                        <TeamsSection>
                            <img src={`${config.imageV3Host}/static/cashsite/${theme.image.teams}`} />
                        </TeamsSection>
                        <TextDiv>
                            This IPL, embark on the ultimate cricketing adventure: Daily prizes, Daily loss backs & Weekly cashbacks on each electrifying match! Be a part of {domain} variety of cricketing offers – Exchange, Fancy, Sportsbook & Premium Sportsbook!  Get set to win!
                        </TextDiv>
                    </InfoWrap>
                    <FifaFeverFirstSecHead className="enterPromo">Embark on a Cricketing adventure</FifaFeverFirstSecHead>
                    <ImgSection>
                        <LazyLoadImage src={`${config.imageV3Host}/static/cashsite/${theme.image.entryrule1}`} />
                    </ImgSection>
                    <OffersSection>
                        <ItalicTextFull className='marginZero readMoreOffer'>Read more about the offer below:</ItalicTextFull>
                    </OffersSection>
                    <ImgSection className='infosec'>
                        <LazyLoadImage src={`${config.imageV3Host}/static/cashsite/${theme.image.iplticket}`} className="ipl1" />
                        <InfoDiv1>
                            <p className='paragraph_head'>
                                Super Over Loss Back!
                            </p>
                            <p className='paragraph1'>
                                This IPL, walk the red carpet like a cricket superstar only on {brandId == 31 || brandId == 32 ? <span style={{ color: '#8C8AFF' }}>yolo<span style={{ color: '#FFD085' }}>247</span>!</span> : "IW247"}
                            </p>
                            <p className='paragraph2'>
                                We present the offer of the year for all cricket crazy fans! Lost the last match? No worries, {brandId == 31 || brandId == 32 ? <span style={{ color: '#8C8AFF' }}>yolo<span style={{ color: '#FFD085' }}>247</span>!</span> : "IW247"}  gives you a chance to play again!
                            </p>
                            <p className='paragraph3'>
                                <span className='phead'>Claim your Prize :</span> For cumulative losses of the day, we'll reward you with an 100% additional loss back bonus the very next day.
                            </p>
                        </InfoDiv1>
                    </ImgSection>
                    <TERMS>
                        <TermsConditionPoints1>
                            <FifaFeverFirstSecHead className='terms'>Terms & Conditions</FifaFeverFirstSecHead>
                            <ul>
                                <li><TextContent>The loss back bonus will be applied to ‘lost IPL EXCHANGE bets - for the day’, placed during the IPL  & WC 2024.</TextContent></li>
                                <li><TextContent>The bonus amount for each qualifying bet will be capped at INR 50 </TextContent></li>
                                <li><TextContent>The bonus will be credited to your {domain} account the next day</TextContent></li>
                                <li><TextContent>Bonus will be expired within 24-48 hours</TextContent></li>
                                <li><TextContent>There is no limit to the number of bets you can place to earn the 100% lossback bonus, ‘for the day’</TextContent></li>
                                <li><TextContent>The loss back bonus can be used to place future bets on {domain} as this bonus cannot be withdrawn. Only winnings can be withdrawn.</TextContent></li>
                                <li><TextContent>This promotion is valid only for the IPL.</TextContent></li>
                                <li><TextContent>{domain} reserves the right to disqualify any user found engaging in fraudulent activities.</TextContent></li>
                                <li><TextContent>{domain} General Terms & Conditions apply.</TextContent></li>
                            </ul>
                        </TermsConditionPoints1>
                    </TERMS>
                </ContentWrapper1>
            </InnerPromoWrapper>
         {/* { brandId==31 ||brandId==32?  <IPLseo />:
            <IPLseoBD/>} */}
            <FooterContainer />
        </>
    )
}

export default withTheme(withAppContext(IplBettingDhamaka));

const TERMS = styled.div`
display: flex;
    align-items: center;
    justify-content: flex-start;
    padding-left: 5rem;
    ::after{
            content: 'none';
            background: url(${config.imageV3Host}/static/cashsite/${theme('image.iplTrophy')});
            position: absolute;
            right: 14%;
            height: 600px;
    width: 100%;
    background-size: contain;
    background-position: right;
    background-repeat: no-repeat;
                }
`
const InfoWrap = styled.div`
display: flex;
position: relative;
width: 100%;
    align-items: center;
    justify-content: space-evenly;
    padding-bottom:4rem;

    /* ::after{
            content: 'none';
            background: url(${config.imageV3Host}/static/cashsite/${theme('image.coinr')});
            position: absolute;
            left: 20%;
    bottom: -21%;
    height: 100px;
            width: 100%;
            background-size: contain;
            background-position: left;
            background-repeat: no-repeat;
                }  */
`

const InfoDiv1 = styled.div`
width: 60%;
position: relative;
::after{
            content: '';
            background: url(${config.imageV3Host}/static/cashsite/${theme('image.coin1')});
            position: absolute;
            width: 121px !important;
            right: 105px;
    height: 100px;
    background-size: contain;
    background-position: left;
    background-repeat: no-repeat;
    bottom: 113%;
                } 

.paragraph_head{
font-size: 27px;
font-style: normal;
font-weight: 700;
line-height: normal;
text-transform: capitalize;
    background: linear-gradient(91deg, #D1AD46 2.89%, #FCF19E 52.11%, #D0AB44 99.8%);
background-clip: text;
-webkit-background-clip: text;
-webkit-text-fill-color: transparent;
}
   
   .paragraph1{
    color: #FFF;
text-align: justify;
font-size: 20px;
font-style: normal;
font-weight: 400;
text-transform: capitalize;
padding-bottom: 1.5rem;
   }
   .paragraph2{
    color: #FFF;
text-align: justify;
font-size: 20px;
font-style: normal;
font-weight: 400;
text-transform: capitalize;
padding-bottom: 1rem;
   }
   .paragraph3{
    color: #FFF;
text-align: justify;
font-size: 20px;
font-style: normal;
font-weight: 400;
text-transform: capitalize;
span{
    .phead{
        color:'#F2E18A'!important;
    }
    
}
   }

   &.phead{
    
}
`

export const TermsConditionPoints1 = styled(TermsConditionPoints)`
z-index: 9;
position: relative;
width: 60%;
border-radius: 26px;
border: 2.434px solid #a19223;
background: linear-gradient(180deg, rgba(255, 255, 255, 0.05) 0%, rgba(255, 255, 255, 0.00) 100%);
backdrop-filter: blur(44.78728103637695px);

`

export const BetnowBTN1 = styled.button`
margin-top: 4rem;
    background: linear-gradient(183.17deg, #CEA63D 0%, #FDF3A0 50.52%, #CFA840 100%), #EF4123;
border: 2px solid #FCF29E;
box-shadow: 0px 0px 30px rgba(247, 233, 147, 0.5);
border-radius: 38.7857px;
right: 44%;
bottom: 2%;
padding: 18px 50px;
cursor:pointer;
font-size: 25.8571px;
line-height: 31px;
display: flex;
align-items: center;
letter-spacing: 0.01em;
text-transform: uppercase;
color: #000000;
font-weight: 700;`

export const ContentWrapper1 = styled(ContentWrapper)`
background: url(${config.imageV3Host}/static/cashsite/${theme('image.iplBGpromo')});
background-repeat: no-repeat;
background-size: cover;
background-position: center;


.infosec{
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    gap:3rem;
    padding-top: 3rem;
}

.ipl1{
      width:31%;

    }
    .ipl2{
        width:55%
    }

    .table{
        padding:2rem 19rem 2rem 19rem;
        position: relative;
        img{
            width: 100%;
        }
        ::before{
            content: '';
            background: url(${config.imageV3Host}/static/cashsite/${theme('image.linesleft')});
            position: absolute;
            left: 0%;
            top:-5%;
            /* height: 1289px; */
            width: 100%;
            background-size: contain;
            background-position: left;
            background-repeat: no-repeat;
                }
    }

    .enterPromo{
        background: url(${config.imageV3Host}/static/cashsite/${theme('image.crazyCrashEnterHead')});
        height: 100px;
        width: 270px;
        background-repeat: no-repeat;
        background-position: center;
        background-size: contain;
        font-weight: 700;
font-size: 30px;
line-height: 36px;
text-align: center;
text-transform: uppercase;;
        white-space: nowrap;
        color:#D1AD46

        
     }



     .priceAlloc{
        background: url(${config.imageV3Host}/static/cashsite/${theme('image.crazyCrashPrizeHead')});
        height: 100px;
        width: 200px;
        background-repeat: no-repeat;
        background-position: center;
        background-size: contain;
        font-size: 30px;
line-height: 36px;
text-align: center;
text-transform: uppercase;
white-space: nowrap;
        color:#D1AD46

     }

     
     .terms{
        background: url(${config.imageV3Host}/static/cashsite/${theme('image.crazyCrashTCHead')});
        height: 100px;
        width: 200px;
        background-repeat: no-repeat;
        background-position: center;
        background-size: contain;
        font-size: 30px;
        display: flex;
        align-items: center;
        text-align: center;
text-transform: uppercase;
white-space: nowrap;
        color:#D1AD46
     }

     .lastImg{
        margin-top: 4rem;
     }
`
const ImgSection = styled.div`
width: 100%;
display: flex;
align-items: center;
        justify-content: space-around;
    flex-direction: row;
    padding: 0rem 10rem 4rem 10rem;
    flex-wrap: wrap;
    position: relative;
    img{
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: space-around;
    }
    
    
    /* ::before{
            content: 'none';
            background: url(${config.imageV3Host}/static/cashsite/${theme('image.coin1')});
            position: absolute;
            left: 18%;
    bottom: 0%;
    height: 100px;
            width: 100%;
            background-size: contain;
            background-position: left;
            background-repeat: no-repeat;
                }

                ::after{
            content: 'none';
            background: url(${config.imageV3Host}/static/cashsite/${theme('image.coinr')});
            position: absolute;
            right: -81%;
    bottom: -21%;
    height: 100px;
            width: 100%;
            background-size: contain;
            background-position: left;
            background-repeat: no-repeat;
                } */

`

const TextDiv = styled.div`
width: 40%;
position: relative;
font-size: 18px;
text-align: left;
text-transform: capitalize;
line-height: 29px;
font-style: normal;
    font-weight: 200;

/* padding: 5rem 14rem 2rem 14rem; */
color: #FFFFFF;
span{
/* font-family: 'Inter'; */
font-style: normal;
font-weight: 700;
font-size: 19px;
text-align: center;
text-transform: capitalize;
color: #DABA57;
}


::after{
            content: '';
            background: url(${config.imageV3Host}/static/cashsite/${theme('image.coin1')});
            position: absolute;
            width: 121px !important;
    right: 102px;
    height: 100px;
            width: 100%;
            background-size: contain;
            background-position: left;
            background-repeat: no-repeat;
            top: 156px
                } 
`

const TeamsSection = styled.div`
width: 40%;
display: flex;
flex-wrap: wrap;

img{
    width:100%; 
    height: 100%;
    
}
`