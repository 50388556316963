import React, { useEffect, useRef, useState } from 'react';
import styled from 'styled-components';

// component
import { theme } from 'styled-tools';
import CenterPortal from '../common/CenterPortal';
import { shadow2F } from '../common/commonCSSStyles';

const idleCallback: { [str: string]: (isStop: boolean) => void } = {};
let outIsSessionStop: boolean;
// 用不同的id讓不同元件的callback一起執行
export const useIdleTimeout = (id: string) => {
	const [isSessionStop, setIsSessionStop] = useState(false);
	idleCallback[id] = setIsSessionStop;
	return {
		isSessionStop: outIsSessionStop,
		setIsSessionStop: (isStop: boolean) => {
			outIsSessionStop = isStop;
			Object.values(idleCallback).map(callback => callback(isStop));
		},
	};
};
const IdleTimeout: React.FC<any> = () => {
	const idleTimerId = useRef(0);
	const { isSessionStop, setIsSessionStop } = useIdleTimeout('0');

	const toggleSessionStop = (isStop: boolean) => {
		setIsSessionStop(isStop);
	};
	useEffect(() => {
		const maxIdleTime = 30 * 60 * 1000;
		const clearListener = () => {
			!!idleTimerId && window.clearTimeout(idleTimerId.current);
			window.removeEventListener('mousemove', handleUserMove);
			window.removeEventListener('click', handleUserMove);
			window.removeEventListener('scroll', handleUserMove);
		};
		const handleUserMove = (e: any) => {
			!!idleTimerId && window.clearTimeout(idleTimerId.current);
			idleTimerId.current = window.setTimeout(() => {
				toggleSessionStop(true);
				clearListener();
			}, maxIdleTime);
		};

		window.addEventListener('mousemove', handleUserMove);
		window.addEventListener('click', handleUserMove);
		window.addEventListener('scroll', handleUserMove);

		return clearListener;
	}, [isSessionStop]);

	const hideDialog = () => {
		toggleSessionStop(false);
	};

	return (
		<>
			{isSessionStop ? (
				<CenterPortal onOverlayClick={() => hideDialog()}>
					<Wrapper>
						<Text>
							You have been inactive for a long time,
							<br />
							your session is expired.
							<br />
							Confirm to renew session.
						</Text>
						<ConfirmButton onClick={() => hideDialog()}>Confirm</ConfirmButton>
					</Wrapper>
				</CenterPortal>
			) : (
				''
			)}
		</>
	);
};
export default IdleTimeout;

const Wrapper = styled.div`
	${shadow2F};
	width: 400px;
	max-width: 90%;
	display: flex;
	flex-direction: column;
	border-radius: 2px;
	background: ${theme('palette.body-background')};
	padding: 1.5em 2em;
`;
const Text = styled.div`
	color: ${theme('palette.sidebar-heading')};
	font-size: 14px;
	font-weight: 600;
	padding: 0 12px 15px 12px;
	text-align: center;
`;
const ConfirmButton = styled.button`
	font-size: 13px;
	color: ${theme('palette.text-light')};
	background: ${theme('palette.primary-background')};
	border-radius: 3px;
	border: 0;
	padding: 0.5em 1em;
	cursor: pointer;

	&:focus {
		outline: 0;
	}
`;
