import React, { useEffect } from 'react'
import { withAppContext } from 'src/store/initAppContext';
import styled, { withTheme } from 'styled-components';
import { LazyLoadImage } from "react-lazy-load-image-component";
import config from "src/config/config";
import PlaceholderImage from 'src/components/Placeholder';
import { theme } from 'styled-tools';
import FooterContainer from 'src/components/footer/footer';
import Slider from "react-slick";

function WeeklyLeaderboardFun(props) {
    const { app: {
        brandId
    }, theme } = props;

    useEffect(() => {
        window.scrollTo(0, 0)
    }, [])

    const settings = {
        loop: true,
        arrows: true,
        autoplay: false,
        dots: true,
        infinite: true,
        speed: 500,
        slidesToShow: 5,
        slidesToScroll: 5,
        initialSlide: 0,
        centerPadding: '20px'
    };

    return (
        <>
            <InformationWrapper>
                    <TitleText>
                        <div className=''>
                            <p className='purple-strong'>Weekly Leaderboard Challenge - Win Your Share of ₹7,00,000!</p>
                            <p className='purple-strong'>Bigger Bets, Bigger Wins! Climb to the Top and Secure Massive Rewards!</p>
                        </div>
                        <div className=''>
                            <p>Wager and climb to the top of the <strong>Weekly Leaderboard</strong> and grab a share of the <strong>₹7,00,000 Prize Pool!</strong> </p>
                            <p>The Top 20 players with the highest bet amount for the week will take home huge prizes</p>
                            <p><strong>Weekly Prize Pool: ₹7,00,000</strong></p>
                            <p>The more you wager, the better your ranking - only the top 20 players will win!</p>
                        </div>
                    </TitleText>
                <ImageWrapper>
                    <Container>
                          {/* <MainTitle>How to Participate</MainTitle> */}
                          <LazyLoadImage
                                src={`${config.imageV3Host}/static/cashsite/${theme.image.weeklyLBFirstSec}`}
                                alt="How to participate"
                                effect="none"
                                placeholder={<PlaceholderImage />}
                           />
                    </Container>
                </ImageWrapper> 

                <PriceBreakDown>
                    <MainTitle>weekly prize amount</MainTitle>
                    {(brandId == 39 || brandId == 40) ? <UnderLine className='paddingTopBtm'></UnderLine> : ""}
                    <SliderWrapper>
                        <Slider {...settings}>
                                <div><img src={`${config.imageV3Host}/static/cashsite/${theme.image.weeklyLBPrize1}`}/></div>
                                <div><img src={`${config.imageV3Host}/static/cashsite/${theme.image.weeklyLBPrize2}`}/></div>
                                <div><img src={`${config.imageV3Host}/static/cashsite/${theme.image.weeklyLBPrize3}`}/></div>
                                <div><img src={`${config.imageV3Host}/static/cashsite/${theme.image.weeklyLBPrize4}`}/></div>
                                <div><img src={`${config.imageV3Host}/static/cashsite/${theme.image.weeklyLBPrize5}`}/></div>
                                <div><img src={`${config.imageV3Host}/static/cashsite/${theme.image.weeklyLBPrize6}`}/></div>
                                <div><img src={`${config.imageV3Host}/static/cashsite/${theme.image.weeklyLBPrize7}`}/></div>
                                <div><img src={`${config.imageV3Host}/static/cashsite/${theme.image.weeklyLBPrize8}`}/></div>
                                <div><img src={`${config.imageV3Host}/static/cashsite/${theme.image.weeklyLBPrize9}`}/></div>
                                <div><img src={`${config.imageV3Host}/static/cashsite/${theme.image.weeklyLBPrize10}`}/></div>
                                <div><img src={`${config.imageV3Host}/static/cashsite/${theme.image.weeklyLBPrize11}`}/></div>
                                <div><img src={`${config.imageV3Host}/static/cashsite/${theme.image.weeklyLBPrize12}`}/></div>
                                <div><img src={`${config.imageV3Host}/static/cashsite/${theme.image.weeklyLBPrize13}`}/></div>
                                <div><img src={`${config.imageV3Host}/static/cashsite/${theme.image.weeklyLBPrize14}`}/></div>
                                <div><img src={`${config.imageV3Host}/static/cashsite/${theme.image.weeklyLBPrize15}`}/></div>
                                <div><img src={`${config.imageV3Host}/static/cashsite/${theme.image.weeklyLBPrize16}`}/></div>
                                <div><img src={`${config.imageV3Host}/static/cashsite/${theme.image.weeklyLBPrize17}`}/></div>
                                <div><img src={`${config.imageV3Host}/static/cashsite/${theme.image.weeklyLBPrize18}`}/></div>
                                <div><img src={`${config.imageV3Host}/static/cashsite/${theme.image.weeklyLBPrize19}`}/></div>
                                <div><img src={`${config.imageV3Host}/static/cashsite/${theme.image.weeklyLBPrize20}`}/></div>
                        </Slider>
                    </SliderWrapper>
                </PriceBreakDown>

                <KeyDetailsSec>
                    <MainTitle>Key Details</MainTitle>
                    <KeyDetailsSecWrap>
                        <KeyDetailsSecBox>
                             <p>
                             Bonuses expire within
                             48 hours - use them fast!
                             </p>
                        </KeyDetailsSecBox>
                             
                        <KeyDetailsSecBox>
                               <p>
                                  No wagering requirements - enjoy your rewards immediately!
                              </p>
                        </KeyDetailsSecBox>

                        <KeyDetailsSecBox>
                              <p>
                                Leaderboard resets weekly - a new chance to win every week!
                              </p>
                        </KeyDetailsSecBox>

                        <KeyDetailsSecBox>
                              <p>
                                  FUN88 General Terms and Conditions apply.
                              </p>
                        </KeyDetailsSecBox>
                    </KeyDetailsSecWrap>
                </KeyDetailsSec>
                
            </InformationWrapper>
            <FooterContainer />
        </>
    )
}



export default withTheme(withAppContext(WeeklyLeaderboardFun));


const UnderLine = styled.div`
    height: 2px;
    width: 184px;
    background: radial-gradient(#FFF 0%, rgba(153, 153, 153, 0.00) 80%);

    &.paddingTopBtm {
        margin: 0 auto;
        margin-bottom: 2rem;
    }

    &.marginTop {
        margin: 0 auto;
        margin-top: 1rem;
    }
`;

const SliderWrapper = styled.div`
    width: 85%;
    margin: 0 auto;

    .slick-slide {
        padding: 0 15px;
    }

    .slick-slide img {
        display: block;
        width: 100%;
        transition: transform .5s; /* Animation */

        :hover {
            transform: scale(0.8);
            border: 3px solid #ffffff;
            border-radius: 1rem;
        }

        :focus-visible {
            outline: none;
        }
        :focus {
            outline: none;
        }
    }

    .slick-prev {
        left: -65px;
        top: 43%;
    }

    .slick-next {
        right: -25px;
        top: 43%;
    }

    .slick-prev:before {
        content: "";
        width: 60px;
        height: 60px;
        display: inline-block;
        background: url(${config.imageV3Host}/static/cashsite/brand/6/image/weeklyLBSliderLeft.png);
        background-size: cover;
        background-position:center center;
        background-repeat: no-repeat;
        opacity: unset;
    }

    .slick-next:before {
        content: "";
        width: 60px;
        height: 60px;
        display: inline-block;
        background: url(${config.imageV3Host}/static/cashsite/brand/6/image/weeklyLBSliderRight.png);
        background-size: cover;
        background-position:center center;
        background-repeat: no-repeat;
        opacity: unset;
    }

    .slick-dots {
        bottom: -40px;

        li button:before {
            color: #ebebeb;
            font-size: 15px;
            opacity: unset;
        }

        li.slick-active button:before {
            opacity: unset;
            color: ${theme('palette.slickActiveButtonClr')};
        }
    }
`;

const InformationWrapper = styled.div`
    width: 98%;
    height: auto;
    padding: 1.5rem 0 4rem 0;
    margin: 0em 1.2em 0 1.2em;
    border: 1px solid #fff;  
    display: flex;
    flex-direction: column;
    background: #EBEBEB;
    overflow: hidden;

    .boldText {
        color: ${theme('palette.informationWrapperPromoBoldText')};
        font-weight: 700;
    }

    .boldTextLite {
        color: ${theme('palette.informationWrapperPromoBoldText')};
        font-weight: 400;
    }

    .shadowLine {
        background: radial-gradient(37.24% 47.13% at 50% 0%, #051829 0%, rgba(1, 4, 6, 0.00) 100%);
        -webkit-backdrop-filter: blur(6.849999904632568px);
        backdrop-filter: blur(6.849999904632568px);
        width: 100%;
        padding-top: 2rem;
    }

    .purple-strong {
        color: #000;
        text-align: center;
        font-size: 15px;
        font-style: normal;
        font-weight: 700;
        line-height: 25px; /* 166.667% */
        text-transform: capitalize;
        margin: 3px 0px;
    }

    h2 {
        color: #E6FF00;
        text-align: center;
        font-size: 23px;
        font-style: normal;
        font-weight: 700;
        line-height: normal;
        text-transform: capitalize;
        background: ${theme('palette.LiveJackPot2025InformationWrapperH2Text')};
        background-clip: ${theme('palette.LiveJackPot2025InformationWrapperH2TextClip')};
        -webkit-background-clip: ${theme('palette.LiveJackPot2025InformationWrapperH2WebkitClip')};
        -webkit-text-fill-color: ${theme('palette.LiveJackPot2025InformationWrapperH2TextWebkitFill')};
    }
`;

const PriceBreakDown = styled.div`
    width: 70%;
    padding: 2rem 0 4rem 0;
    position: relative;
    background-color: #fff;
    /* padding: 1.5em; */
    border-radius: 8px;
    margin: 1rem auto 0 auto;
`;



const TitleText = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    /* padding: ${theme('palette.liveJackpotTittleTextPadding')}; */
    position: relative;
    gap: 15px;
    margin-bottom: 2rem;
    background-color: #fff;
    padding: 1.5em;
    border-radius: 8px;
    width: 70%;
    margin: auto;


    p {
        color: #000;
        text-align: center;
        font-size: 15px;
        font-style: normal;
        font-weight: 400;
        line-height: 28px; /* 186.667% */
        text-transform: capitalize;
        margin: 0;
    }
`;

const ImageWrapper = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    margin-bottom: 2rem;
    position: relative;
    background-color: #fff;
    padding: 1.5em;
    border-radius: 8px;
    width: 70%;
    margin: 1em auto 0 auto;
`;


const Container = styled.div`
    width: 70%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    margin-top: 2em;

    & img {
      width: 90%;
      height: auto;
    }

`;

const MainTitle = styled.h4`
        color: #06A6FF;
        text-align: center;
        font-size: 20px;
        font-style: normal;
        font-weight: 700;
        line-height: 35px; /* 175% */
        text-transform: capitalize;
        margin: 3px 0px 15px 0px;
`


const KeyDetailsSec = styled.div`
    display: flex;
    flex-direction: column;
    margin: 2rem auto;
    width: 70%;
    height: auto;
    background-color: #fff;
    padding: 1em 0;
    border-radius: 8px;
    
`

const KeyDetailsSecWrap = styled.div`
    display: flex;
    justify-content: center;
    gap: 10px;
`

const KeyDetailsSecBox = styled.div`
    width: 23%;
    height: 200px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 3px;
    border: 0.5px solid #8CD6FF;
    background: #06A6FF;
    backdrop-filter: blur(12.5px);

    & p {
        color: #fff;
        text-align: center;
        font-size: 17px;
        font-style: normal;
        font-weight: 400;
        line-height: 20px; /* 133.333% */
        text-transform: capitalize;
        margin: 3px 0px;
        width: 80%;
    }
`