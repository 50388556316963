import React from 'react'

type Props = {}

const BetdailyAppStepSixIcon = (props: Props) => {
  return (
    <svg
    width={48}
    height={48}
    viewBox="0 0 48 48"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <g filter="url(#filter0_b_702_28)">
      <circle cx={24} cy={24} r={24} fill="url(#paint0_linear_702_28)" />
      <circle cx={24} cy={24} r={24} fill="url(#paint1_linear_702_28)" />
    </g>
    <g clipPath="url(#clip0_702_28)">
      <path
        d="M34.9162 17.506C34.143 13.7541 31.0344 11 27.4489 11C24.5303 11 21.8674 12.8315 20.5877 15.6385C19.9764 15.4009 19.3283 15.2774 18.6774 15.2774C15.7904 15.2774 13.3992 17.6291 13.0369 20.6641C10.2061 21.0168 8 23.6398 8 26.8122C8 30.2242 10.5516 33 13.6879 33H33.8376C37.787 33 41.0001 29.5047 41.0001 25.2082C41.0004 21.3402 38.3736 18.0768 34.9163 17.506H34.9162ZM33.8376 31.3172H13.6879C11.4045 31.3172 9.54685 29.2963 9.54685 26.8122C9.54685 24.3281 11.3953 22.3171 13.6707 22.3067C13.6963 22.3089 13.7218 22.3105 13.7477 22.3111C13.9538 22.3204 14.156 22.229 14.3045 22.0709C14.453 21.9125 14.5365 21.6959 14.5365 21.47C14.5365 21.4634 14.5365 21.4568 14.5364 21.4502C14.5437 18.973 16.3985 16.9603 18.6774 16.9603C19.364 16.9603 20.0187 17.1389 20.6234 17.4914C20.8205 17.6068 21.0544 17.6229 21.2635 17.5364C21.4727 17.4498 21.6373 17.2689 21.7146 17.0403C22.5957 14.4339 24.9002 12.6828 27.449 12.6828C30.4725 12.6828 33.0711 15.1373 33.4936 18.3917C33.5448 18.7863 33.8433 19.0875 34.2088 19.1138C37.1499 19.3237 39.4536 22.0006 39.4533 25.2081C39.4533 28.5766 36.9341 31.317 33.8378 31.317L33.8376 31.3172Z"
        fill="white"
      />
      <path
        d="M28.8808 19.4254C28.8245 19.1979 28.654 19.1479 28.5006 19.3145L27.8815 19.988C25.9333 17.9354 22.8152 17.9525 20.8902 20.0467C20.2073 20.7908 19.7403 21.7249 19.5396 22.7479C19.4505 23.2023 19.717 23.6494 20.1347 23.7464C20.1891 23.7592 20.2434 23.7652 20.2968 23.7652C20.6539 23.7652 20.975 23.4943 21.0525 23.0991C21.1908 22.394 21.5132 21.7499 21.9845 21.2363C23.3059 19.7992 25.4428 19.7813 26.788 21.178L26.1533 21.8685C25.9999 22.0344 26.046 22.2207 26.255 22.2818L29.4606 23.2158C29.6703 23.2763 29.7954 23.1399 29.7398 22.9121L28.8809 19.4256L28.8808 19.4254Z"
        fill="white"
      />
      <path
        d="M28.9024 24.3594C28.4844 24.2629 28.0736 24.5519 27.9845 25.0063C27.8459 25.7108 27.5235 26.3551 27.0516 26.869C26.4028 27.5753 25.5395 27.9642 24.621 27.9642C23.7024 27.9642 22.8762 27.5893 22.2316 26.9104L22.8468 26.2413C22.9996 26.0737 22.9539 25.8878 22.7445 25.8269L19.5394 24.8932C19.3303 24.8324 19.2046 24.9688 19.2611 25.1966L20.1195 28.683C20.1751 28.9112 20.3466 28.9607 20.4997 28.7943L21.1377 28.1005C22.0746 29.0969 23.3082 29.6472 24.6208 29.6472C25.9527 29.6472 27.2045 29.0829 28.1457 28.0584C28.829 27.3145 29.2963 26.3807 29.4969 25.3582C29.5863 24.9036 29.3198 24.4565 28.9023 24.3595L28.9024 24.3594Z"
        fill="white"
      />
    </g>
    <defs>
      <filter
        id="filter0_b_702_28"
        x={-4150}
        y={-4150}
        width={8348}
        height={8348}
        filterUnits="userSpaceOnUse"
        colorInterpolationFilters="sRGB"
      >
        <feFlood floodOpacity={0} result="BackgroundImageFix" />
        <feGaussianBlur in="BackgroundImageFix" stdDeviation={2075} />
        <feComposite
          in2="SourceAlpha"
          operator="in"
          result="effect1_backgroundBlur_702_28"
        />
        <feBlend
          mode="normal"
          in="SourceGraphic"
          in2="effect1_backgroundBlur_702_28"
          result="shape"
        />
      </filter>
      <linearGradient
        id="paint0_linear_702_28"
        x1={0}
        y1={0}
        x2={47.0669}
        y2={2.39871}
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#6F6DE6" />
        <stop offset={0.489583} stopColor="#5451D4" />
        <stop offset={1} stopColor="#312DB9" />
      </linearGradient>
      <linearGradient
        id="paint1_linear_702_28"
        x1={24}
        y1={0}
        x2={24}
        y2={48}
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#B21318" />
        <stop offset={1} stopColor="#7D0204" />
      </linearGradient>
      <clipPath id="clip0_702_28">
        <rect width={33} height={22} fill="white" transform="translate(8 11)" />
      </clipPath>
    </defs>
  </svg>
  )
}

export default BetdailyAppStepSixIcon