import React, { useEffect } from 'react'
import { withAppContext } from 'src/store/initAppContext';
import styled, { withTheme } from 'styled-components';
import { LazyLoadImage } from "react-lazy-load-image-component";
import config from "src/config/config";
import PlaceholderImage from 'src/components/Placeholder';
import { theme } from 'styled-tools';
import FooterContainer from 'src/components/footer/footer';

function ChampionsTrophyPromo(props) {
    const { app: {
        brandId
    }, theme } = props;

    useEffect(() => {
        window.scrollTo(0, 0)
    }, [])

    const cardsData = [
        {
          image: "cardImg1",
          title: "How It Works",
          description:
            "Place bets on Champions League matches and climb the leaderboard to win prizes.",
        },
        {
          image: "cardImg2",
          title: "Eligible Bets",
          description:
            "All bets placed on Champions League matches through Exchange, B2B, and Sportsbook, Saba are counted.",
        },
        {
          image: "cardImg3",
          title: "Winners & Prizes",
          description:
            "A loyalty-based leaderboard with 50 winners and ₹25+ lakhs in cash prizes up for grabs.",
        },
    ];

    const domain = (brandId == 31 || brandId == 32) ? "Yolo247" : (brandId == 39 || brandId == 40) ? "FOMO7" : 'iw247';
    const { config: {showYolo}} = theme;

    return (
        <>
            <InformationWrapper>
                <ImageWrapper>
                    <LazyLoadImage
                        src={`${config.imageV3Host}/static/cashsite/brand/6/image/championsTrophyTeamsLogos.png`}
                        alt="championsTrophyTeamsLogos"
                        effect="none"
                        placeholder={<PlaceholderImage />}
                    />
                </ImageWrapper>
                {showYolo ? null : <Divider></Divider>}
                {showYolo ? <ImageWrapper>
                    <CardContainer>
                        {cardsData.map((card, index) => (
                            <Card key={index}>
                                <div className='cardImgWrap'><img src={`${config.imageV3Host}/static/cashsite/brand/6/image/${card.image}.png`} alt="Cards Icon" /></div>
                                <h3>{card.title}</h3>
                                <p>{card.description}</p>
                            </Card>
                        ))}
                    </CardContainer>
                </ImageWrapper> : 
                <Container>
                    <div className='borderContainer'>
                        <div>
                            <LazyLoadImage
                                src={`${config.imageV3Host}/static/cashsite/brand/6/image/cardImg3.png`}
                                alt="championsTrophyPromoPriceList"
                                effect="none"
                                placeholder={<PlaceholderImage />}
                            />
                            <div className='worksContent'>
                                <h3>How it Works:</h3>
                                <p>Place as many bets as possible on Champions League matches and <br /> win free bets on Slots / Instant Game and Live Casino games.</p>
                            </div>
                        </div>
                    </div>
                    
                </Container>}

                {showYolo ? <PriceBreakDown>
                    <LazyLoadImage
                        src={`${config.imageV3Host}/static/cashsite/brand/6/image/championsTrophyPromoPriceList.png`}
                        alt="championsTrophyPromoPriceList"
                        effect="none"
                        placeholder={<PlaceholderImage />}
                    />
                </PriceBreakDown> : 
                <PriceBreakDownFomo>
                    <h2 className=''>Winnings</h2>
                    <UnderLine></UnderLine>    
                    <LazyLoadImage
                        src={`${config.imageV3Host}/static/cashsite/brand/6/image/championsTrophyPromoPriceListFomo.png`}
                        alt="championsTrophyPromoPriceList"
                        effect="none"
                        placeholder={<PlaceholderImage />}
                    />
                </PriceBreakDownFomo>}
            </InformationWrapper>
            <FooterContainer />
        </>
    )
}



export default withTheme(withAppContext(ChampionsTrophyPromo));

const InformationWrapper = styled.div`
    width: 98%;
    height: auto;
    padding: 1.5rem 0 8rem 0;
    margin: 0em 1.2em 0 1.2em;
    border: 1px solid #fff;  
    display: flex;
    flex-direction: column;
    background: url(${config.imageV3Host}/static/cashsite/${theme("image.championsTrophyPromoBgImg")});
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
    overflow: hidden;

    .shadowLine {
        background: radial-gradient(37.24% 47.13% at 50% 0%, #051829 0%, rgba(1, 4, 6, 0.00) 100%);
        -webkit-backdrop-filter: blur(6.849999904632568px);
        backdrop-filter: blur(6.849999904632568px);
        width: 100%;
        padding-top: 2rem;
    }

    h2 {
        color: #E6FF00;
        text-align: center;
        font-size: 23px;
        font-style: normal;
        font-weight: 700;
        line-height: normal;
        text-transform: capitalize;
        background: linear-gradient(156deg, #FED546 27.4%, #FE7C23 105.07%);
        background-clip: text;
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
        margin-bottom: 0.8rem;
    }

    img {
        max-width: 100%;
    }
`;

const PriceBreakDownFomo = styled.div`
    width: 700px;
    margin: 0 auto;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding-top: 2rem;
`;

const Divider = styled.div`
    height: 1px;
    width: 100%;
    background: radial-gradient(#1182C1 0%, #00000000 50%);
`;

const PriceBreakDown = styled.div`
    width: 1089px;
    margin: 0 auto;
    display: flex;
    align-items: center;
    justify-content: center;
    padding-top: 2rem;
`;

const Title = styled.div`
  font-size: 14px;
  margin-top: 10px;

  &.textHighlight {
    font-size: 16px;
    font-weight: bold;
    color: ${theme('palette.informationWrapperPromoBoldText')};
  }
`;

const ImageWrapper = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    width: 870px;
    justify-content: center;
    text-align: center;
    margin: 2rem auto;
`;

const Container = styled.div`
    width: 833px;
    margin: 0 auto;
    display: flex;
    align-items: center;
    justify-content: center;
    padding-top: 2rem;
    background: radial-gradient(37.24% 47.13% at 50% 0%, #051829 0%, rgba(1, 4, 6, 0.00) 100%);
    -webkit-backdrop-filter: blur(6.849999904632568px);
    backdrop-filter: blur(6.849999904632568px);

    img {
        width: 135px;
    }

    h3 {
        font-size: 20px;
        background: linear-gradient(180deg, #FED546 18.06%, #FE7C23 121.53%);
        background-clip: text;
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
        margin: 0;
    }

    p {
        color: #FFF;
        font-weight: 400;
        font-size: 15px;
        margin: 0;
        line-height: 22px;
    }

    .borderContainer {
        width: 100%;
        border-radius: 5.5px;
        border: 0.369px solid #feba3b73;
        background: #000;
        padding: 2px;

        > div {
            border-radius: 5.5px;
            border: 0.393px solid #feba3b73;
            display: flex;
            align-items: center;
            justify-content: center;
            gap: 2rem;
            padding: 1rem;
        }
    }

    .worksContent {
        display: flex;
        flex-direction: column;
        gap: 1rem;
        justify-content: center;
        text-transform: capitalize;
    }
`;

const UnderLine = styled.div`
    height: 2px;
    width: 184px;
    background: radial-gradient(#FFF 0%, rgba(153, 153, 153, 0.00) 80%);
    margin-bottom: 2rem;
`;

const CardContainer = styled.div`
    display: flex;
    gap: 2rem;
    justify-content: center;
`;

const Card = styled.div`
    background: linear-gradient(150deg, #D9D9D9 -0.64%, #F7F7F5 35.99%, #D4DBEE 44.53%, #F7F7F5 55.06%, #F7F7F5 67.11%, #D4DBEE 77.15%, #FBFBFB 85.68%);
    width: 250px;
    padding: 10px;
    text-align: center;
    color: #062365;
    padding-bottom: 3%;
    clip-path: polygon(
        0% 0%, 
        100% 0%, 
        100% 85%, 
        calc(50% + 1px) 100%,
        calc(50% - 1px) 100%,
        0% 85%
    );

    .cardImgWrap {
        width: 100%;
        height: 124px;
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 1rem;
        background: linear-gradient(180deg, #1045AD 10.72%, #1854CC 147.11%);
    }
  
  & img {
    width: 130px;
  }
  
  & h3 {
    margin-bottom: 10px;
    font-size: 18px;
    font-weight: bold;
    text-transform: capitalize;
  }
  
  & p {
    font-size: 14px;
    font-weight: 400;
    padding: 0 5px;
    text-transform: capitalize;
  }
`;