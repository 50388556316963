import styled from 'styled-components';
import { viewportMask } from './commonCSSStyles';

// Styled Components

const ViewportMask = styled.div`
	${viewportMask};
`;

export default ViewportMask;
