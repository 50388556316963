import React, { useEffect, useRef, useState } from 'react'
import styled from 'styled-components';
import { theme } from 'styled-tools';
import CurrencyIcon from './svg/Currency';
import config from 'src/config/config';
import GamesIcon from './svg/GamesIcon';
import GraphIcon from './svg/GraphIco';
import Modal from 'src/components/Modal';
import CasinoGames from 'src/components/eu-content/casino-page/casino-slider-Games';
import Intl from 'src/components/common/Intl';
import { getLeaderBoardData } from 'src/config/api';
import { Notify } from '../Notification';
import { toLocalNumber } from './MyRanks';

type DataProps = {
    GameCode?: string
    Platform?: string
    multiplier: string
    orderCredit: string
    username: string
    winloss: string
    categoryId?: number
    competitionId?: number
    eventId?: number
    eventName?: string
    marketId?: string
    side?: string
    gameName?: string
    createdAt: string
}

const getGameCode = (game = '') => {
    let gameName;
    try {
        gameName = game.split('-').slice(1).join(' ')

    } catch (error) {
        gameName = game;
    }
    return gameName;
}

export const ShimmerEff = () => {
    return (
        <tbody>
            <tr className='shimmer'>
                <td colSpan={5}></td>
            </tr>
            <tr className='shimmer'>
                <td colSpan={5}></td>
            </tr>
            <tr className='shimmer'>
                <td colSpan={5}></td>
            </tr>
            <tr className='shimmer'>
                <td colSpan={5}></td>
            </tr>
            <tr className='shimmer'>
                <td colSpan={5}></td>
            </tr>
        </tbody>
    )
}

export default function MyBets(props: any) {
    let { betData = [], isloading, brand, numrows } = props;
    const [modal, setModal] = useState(false);
    const [selectedGame, setSelectedGame] = useState([]);
    const [betsData, setBetsData] = useState(betData);
    const [records, setRecords] = useState(numrows);
    const refElm = useRef<HTMLDivElement>();
    const tRef = useRef<any>(0);
    const tRef1 = useRef<any>(0)
    const delay = 20000; //10 Seconds

    useEffect(() => {
        //Clear all Interval
        clearInterval(tRef.current);
        clearInterval(tRef1.current);

        if (Array.isArray(betData) && betData.length) {
            if (records !== numrows) {
                setRecords(numrows);
            }
            setBetsData(betData);
            //Add Data Fetching Functionality
            try {
                const sectionWrapper = refElm.current;
                const observer = new IntersectionObserver((entries) => {
                    if (entries[0].isIntersecting) {
                       getLatestBetsData();
                       tRef.current = setInterval(() => { getLatestBetsData() }, delay);
                    }
                    else {
                        clearInterval(tRef.current);
                    }
                });

                observer.observe(sectionWrapper);

            } catch (error) {
                console.log('IntersectionObserver issue');
                getLatestBetsData();
                tRef.current = setInterval(() => { getLatestBetsData() }, delay);
            }
        }

        return () => {
            clearInterval(tRef.current);
            clearInterval(tRef1.current);
            // clearTimeout(t);
        };

    }, [betData]);


    const getLatestBetsData = () => {
        const param = `type=LATEST&limit=${numrows}&brand_id=${brand}`;
        getLeaderBoardData(param).then(res => {
            const { data = [] } = res.data;
            clearInterval(tRef1.current);
            updateBetsState(data);
        }).catch(e => {
            const { data } = e.response || {};
            let message = 'Something Went Wrong, Please Try Again';
            if (data && data.error) {
                message = data.error;
            }
            Notify.error(message);
        });

    }

    const updateBetsState = (data: any) => {
        clearInterval(tRef1.current);
        if ((!Array.isArray(betData) || betData.length <= 5) || (!Array.isArray(data) || data.length <= 0)) {
            return;
        }

        let _data = [...data];
        let _length = _data.length <= 10 ? 10 : _data.length;
        let _delay = Math.round(delay / _length);

        tRef1.current = setInterval(() => {
            let firstRecord = _data.shift();
            setBetsData((prevState) => {
                prevState.pop();
                return [firstRecord, ...prevState];
            });

            if (_data.length <= 0) {
                clearInterval(tRef1.current);
            }

        }, _delay);

    }

    const handleClose = () => setModal(false);

    const toggleModal = (gameName: any) => {
        const { platformCode, code } = gameName;
        if (platformCode === 'EXCHANGE' || !code) {
            return;
        }
        else {
            setSelectedGame([gameName])
            setModal(!modal);
        }
    }

    const _gfc = brand === 46 || brand === 47 ? '#000' : '#fff';
    const _user = brand === 46 || brand === 47 ? 'u47' : '';

    return (
        <SectionWrapeer ref={refElm}>
            <Table>
                <thead>
                    <tr>
                        <th><Intl langKey="user" /></th>
                        <th><Intl langKey="gameMatch" /></th>
                        <th><Intl langKey="betAmount" /></th>
                        <th><Intl langKey="multiplierOdds" /></th>
                        <th><Intl langKey="payout" /></th>
                    </tr>
                </thead>

                {!isloading && betsData.length >= 1 ? (<tbody>
                    {
                        betsData.map((elm: DataProps, i: number) => {
                            const {
                                GameCode,
                                Platform,
                                multiplier,
                                orderCredit,
                                username,
                                winloss,
                                categoryId,
                                competitionId,
                                eventId,
                                eventName,
                                marketId,
                                side,
                                gameName,
                                createdAt
                            } = elm;

                            const _gameType = competitionId ? 'exchange' : '';
                            const _gameName = gameName ? gameName : eventName;
                            const _isPositive = Number(winloss) >= 0;
                            const _graphType = _isPositive ? "Up" : "down";
                            const _winloss = winloss && Math.abs(Number(winloss));
                            const _symbol = _isPositive ? ' ' : '-';
                            const _gameData = {
                                game: _gameName,
                                platformCode: Platform,
                                code: GameCode,
                            }
                            const ukey = Date.now();

                            return (
                                <tr key={`${i}_${ukey}`}>
                                    <td><User className={`${_user} ${_isPositive ? '' : 'disabled'}`}><UserName>{username}</UserName></User></td>
                                    <td><Match onClick={() => toggleModal(_gameData)}><GamesIcon type={_gameType} />{_gameName}</Match></td>
                                    <td><BetAmount>₹{toLocalNumber(orderCredit)}</BetAmount></td>
                                    <td><Multiplier className={_isPositive ? '' : 'disabled'}><GraphIcon type={_graphType} upColor={_gfc} />{multiplier}x</Multiplier></td>
                                    <td><Payout className={_isPositive ? '' : 'disabled'}>
                                        {/* <CurrencyIcon /> */}
                                        {_symbol}₹{toLocalNumber(_winloss)}
                                        </Payout></td>
                                </tr>
                            )
                        })
                    }

                </tbody>) : <ShimmerEff />
                }
            </Table>

            <Modal show={modal} handleClose={handleClose}>
                <CasinoGames modalState={modal} data={selectedGame} open={'true'} />
            </Modal>

        </SectionWrapeer>
    )
}


//styled Component

export const SectionWrapeer = styled.div`
    border-radius: 5px;
    border: ${theme('palette.lbTabWrapperBorder')};
    background: ${theme('palette.lbSectionWrapBg')};
    padding: 6px 10px 10px;
`;

export const Table = styled.table`
    table-layout: fixed;
    width: 100%;
    border-collapse: collapse;
    font-size: 14px;
    font-style: normal;
    tbody tr {
        border-bottom: ${theme('palette.lbTRBoarder')};
        height: 48px;
        &:nth-child(odd){
            td {background-color:${theme('palette.lbTRoddBg')}}
            
        }
        &:nth-child(even){
            td {
                background-color:${theme('palette.lbTRevenBg')};
            }
            
        }
    }

    th {
        color:${theme('palette.lbTHColor')};
        font-size: 12px;
        font-style: normal;
        font-weight: 500;
        line-height: 15px;
        text-align: left;
        padding: 10px 12px;   
    }
    td {
        text-align: left;
        color:${theme('palette.lbTDColor')};
        padding: 6px 12px;
        font-size: 14px;
        font-weight: 500;
        &.noRecord {
            text-align: center;
            color: red;
        };
        &:first-child{
            border-radius: 6px 0 0 8px;
        }
        &:last-child{
            border-radius: 0px 6px 8px 0px;
        }

    }

    tr.shimmer {
        td{
            animation: identifier 1.8s linear infinite;
            background: ${theme('palette.lbshimmer')};
            background-size: 1300px 100%;

            @keyframes identifier {
                0% { background-position: -1300px 0;}
                100% {background-position: 1300px 0;}
            } 

        }
       
    }
`;

export const User = styled.span`
    display: flex;
    background: url('${(props: any) => `${config.imageV3Host}/static/icon/png/userA.png`}') no-repeat 0 center;
    background-size: 19px 23px;
    line-height:23px;
    padding-left: 25px;
    justify-content: flex-start;
    align-items: center;
    &.disabled {
        background: url('${(props: any) => `${config.imageV3Host}/static/icon/png/userN.png`}') no-repeat 0 center;
        color:${theme('palette.lbPayoutDisabled')};
    }
    &.u47 {
        background: url('${(props: any) => `${config.imageV3Host}/static/icon/png/userAB.png`}') no-repeat 0 center;
    }
    &.u47.disabled {
        background: url('${(props: any) => `${config.imageV3Host}/static/icon/png/userA.png`}') no-repeat 0 center;
    }
`;

export const UserName : any = styled.span`
    display: block;
    width:100%;
    overflow-x: auto;
`;


export const Match = styled.span`
     display: flex;
     line-height:1;
     justify-content: flex-start;
     align-items: center;
     gap: 5px;
     text-transform: capitalize;
     cursor: pointer;
`;

export const BetAmount = styled(User)`
    background: url('${(props: any) => `${config.imageV3Host}/static/icon/png/wagered.png`}') no-repeat 0 center; 
    font-weight:700;
    &.disabled {
        background: url('${(props: any) => `${config.imageV3Host}/static/icon/png/wagered.png`}') no-repeat 0 center; 
    }

    background:none;
    padding-left: 0px;
`;

const Multiplier = styled(Match)`
    cursor: auto;
    &.disabled {
        color:${theme('palette.lbPayoutDisabled')};
    }
`;

export const Payout = styled(Match)`
    color: #0CD437;
    cursor: auto;
    font-weight: 700;
    &.disabled {
        color:${theme('palette.lbPayoutDisabled')};
    }
`;
