import React from 'react'

type Props = {}

export default function UserNameIconFun88(props) {

  return (
    <svg width="22" height="22" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clip-path="url(#clip0_2406_15263)">
        <path d="M19.2252 4.5459H3.16642C2.11642 4.5459 1.31348 5.34884 1.31348 6.39884V16.2812C1.31348 17.3312 2.11642 18.1341 3.16642 18.1341H19.2252C20.2752 18.1341 21.0782 17.3312 21.0782 16.2812V6.39884C21.0782 5.34884 20.2752 4.5459 19.2252 4.5459ZM19.8429 16.2812C19.8429 16.6518 19.5958 16.8988 19.2252 16.8988H3.16642C2.79583 16.8988 2.54877 16.6518 2.54877 16.2812V6.39884C2.54877 6.02825 2.79583 5.78119 3.16642 5.78119H19.2252C19.5958 5.78119 19.8429 6.02825 19.8429 6.39884V16.2812Z" fill="black" />
        <path d="M9.83688 10.2896C9.89864 10.0426 9.96041 9.73376 9.96041 9.4867C9.96041 8.12788 8.84864 7.01611 7.48982 7.01611C6.13099 7.01611 5.01923 8.12788 5.01923 9.4867C5.01923 9.79553 5.08099 10.0426 5.14276 10.2896C4.33982 10.6602 3.78394 11.4632 3.78394 12.4514V15.0455C3.78394 15.4161 4.03099 15.6632 4.40158 15.6632H10.5781C10.9486 15.6632 11.1957 15.4161 11.1957 15.0455V12.4514C11.1957 11.4632 10.6398 10.6602 9.83688 10.2896ZM7.48982 8.25141C8.16923 8.25141 8.72511 8.80729 8.72511 9.4867C8.72511 10.1661 8.16923 10.722 7.48982 10.722C6.81041 10.722 6.25452 10.1661 6.25452 9.4867C6.25452 8.80729 6.81041 8.25141 7.48982 8.25141ZM9.96041 14.4279H5.01923V12.4514C5.01923 11.8955 5.38982 11.4632 5.88393 11.4014C6.31629 11.772 6.87217 12.0191 7.48982 12.0191C8.10746 12.0191 8.66335 11.772 9.0957 11.4014C9.58982 11.5249 9.96041 11.9573 9.96041 12.4514V14.4279ZM17.9898 8.25141H13.0486C12.6781 8.25141 12.431 8.49847 12.431 8.86905C12.431 9.23964 12.6781 9.4867 13.0486 9.4867H17.9898C18.3604 9.4867 18.6075 9.23964 18.6075 8.86905C18.6075 8.49847 18.3604 8.25141 17.9898 8.25141ZM17.9898 10.722H13.0486C12.6781 10.722 12.431 10.9691 12.431 11.3396C12.431 11.7102 12.6781 11.9573 13.0486 11.9573H17.9898C18.3604 11.9573 18.6075 11.7102 18.6075 11.3396C18.6075 10.9691 18.3604 10.722 17.9898 10.722ZM17.9898 13.1926H13.0486C12.6781 13.1926 12.431 13.4396 12.431 13.8102C12.431 14.1808 12.6781 14.4279 13.0486 14.4279H17.9898C18.3604 14.4279 18.6075 14.1808 18.6075 13.8102C18.6075 13.4396 18.3604 13.1926 17.9898 13.1926Z" fill="black" />
      </g>
      <defs>
        <clipPath id="clip0_2406_15263">
          <rect width="21" height="21" fill="white" transform="translate(0.695801 0.839844)" />
        </clipPath>
      </defs>
    </svg>
  )
}


