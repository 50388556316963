import React from 'react'

type Props = {}

const AddbankRed = (props: Props) => {
  return (
<svg width="44" height="44" viewBox="0 0 44 44" fill="none" xmlns="http://www.w3.org/2000/svg">
<rect x="5.41748" y="19.5908" width="5.34854" height="12.0671" rx="1.06157" fill="white"/>
<path d="M0.055235 16.4705V12.8535C0.055235 12.3406 0.055235 12.0762 0.460821 11.8583C6.23342 8.75756 17.8799 2.48461 18.2848 2.19882C18.6897 1.91302 19.2483 2.07974 19.477 2.19882C24.7811 5.05548 35.6132 10.8939 36.5087 11.3943C37.4042 11.8947 37.649 12.431 37.6594 12.6366V16.4268C37.6594 17.8751 36.9429 18.2293 36.5846 18.2254H35.6907H1.70561C0.273185 18.2254 0.00851682 17.0554 0.055235 16.4705Z" fill="white"/>
<path fill-rule="evenodd" clip-rule="evenodd" d="M18.9267 33.0196L1.12749 33.0195C0.5412 33.0195 0.065918 33.4948 0.065918 34.0811V38.5999C0.065918 39.1862 0.541197 39.6614 1.12748 39.6614L21.6548 39.6615C20.1691 37.8001 19.1901 35.5165 18.9267 33.0196Z" fill="white"/>
<path fill-rule="evenodd" clip-rule="evenodd" d="M21.5507 23.7842V20.6504C21.5507 20.0641 21.0754 19.5889 20.4891 19.5889H17.2637C16.6774 19.5889 16.2021 20.0641 16.2021 20.6504V30.5944C16.2021 31.1807 16.6774 31.656 17.2637 31.656H18.856C18.856 28.6897 19.8617 25.9584 21.5507 23.7842Z" fill="white"/>
<rect x="29.9331" y="26" width="2.70443" height="10.571" rx="1.35222" fill="white"/>
<rect x="26" y="32.6377" width="2.70443" height="10.571" rx="1.35222" transform="rotate(-90 26 32.6377)" fill="white"/>
<path fill-rule="evenodd" clip-rule="evenodd" d="M42.6259 31.6567C42.6259 37.6286 37.7848 42.4697 31.813 42.4697C25.8411 42.4697 21 37.6286 21 31.6567C21 25.6849 25.8411 20.8438 31.813 20.8438C37.7848 20.8438 42.6259 25.6849 42.6259 31.6567ZM29.9339 27.3522C29.9339 26.6054 30.5393 26 31.2861 26C32.0329 26 32.6383 26.6054 32.6383 27.3522V29.9337H35.219C35.9658 29.9337 36.5712 30.5391 36.5712 31.286C36.5712 32.0328 35.9658 32.6382 35.219 32.6382H32.6383V35.2188C32.6383 35.9656 32.0329 36.571 31.2861 36.571C30.5393 36.571 29.9339 35.9656 29.9339 35.2188V32.6382H27.3524C26.6056 32.6382 26.0002 32.0328 26.0002 31.286C26.0002 30.5391 26.6056 29.9337 27.3524 29.9337H29.9339V27.3522Z" fill="#B21318"/>
</svg>

  )
}

export const AddBankOrange = (props: Props) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="56" height="55" viewBox="0 0 56 55" fill="none">
      <rect x="7.5918" y="24.2197" width="6.7567" height="15.2441" rx="1.34106" fill="white" />
      <path d="M0.81679 20.2783V15.709C0.81679 15.0611 0.81679 14.727 1.32916 14.4518C8.62158 10.5347 23.3343 2.61018 23.8458 2.24915C24.3573 1.88812 25.0631 2.09872 25.352 2.24915C32.0525 5.85792 45.7365 13.2335 46.8677 13.8657C47.999 14.4978 48.3083 15.1753 48.3215 15.435V20.2232C48.3215 22.0527 47.4163 22.5002 46.9636 22.4952H45.8344H2.90167C1.09212 22.4952 0.757772 21.0173 0.81679 20.2783Z" fill="white" />
      <path fill-rule="evenodd" clip-rule="evenodd" d="M24.655 41.1846L2.17114 41.1846C1.43049 41.1846 0.830079 41.785 0.830079 42.5256L0.830078 48.2341C0.830078 48.9747 1.43049 49.5752 2.17113 49.5752L28.1016 49.5752C26.2247 47.2238 24.9879 44.339 24.655 41.1846Z" fill="white" />
      <path fill-rule="evenodd" clip-rule="evenodd" d="M27.9715 29.518V25.5579C27.9715 24.8172 27.3711 24.2168 26.6305 24.2168H22.5559C21.8153 24.2168 21.2148 24.8172 21.2148 25.5579V38.1199C21.2148 38.8605 21.8153 39.4609 22.5559 39.4609H24.5684C24.5684 35.7143 25.8385 32.2643 27.9715 29.518Z" fill="white" />
      <rect x="38.5605" y="32.3164" width="3.41646" height="13.3542" rx="1.70823" fill="white" />
      <rect x="33.5938" y="40.7021" width="3.41646" height="13.3542" rx="1.70823" transform="rotate(-90 33.5938 40.7021)" fill="white" />
      <path fill-rule="evenodd" clip-rule="evenodd" d="M54.595 39.4626C54.595 47.0067 48.4793 53.1224 40.9352 53.1224C33.3911 53.1224 27.2754 47.0067 27.2754 39.4626C27.2754 31.9184 33.3911 25.8027 40.9352 25.8027C48.4793 25.8027 54.595 31.9184 54.595 39.4626ZM38.5625 34.0246C38.5625 33.0812 39.3273 32.3164 40.2707 32.3164C41.2142 32.3164 41.979 33.0812 41.979 34.0246V37.2858H45.2377C46.1812 37.2858 46.946 38.0506 46.946 38.9941C46.946 39.9375 46.1812 40.7023 45.2377 40.7023H41.979V43.9623C41.979 44.9058 41.2142 45.6706 40.2707 45.6706C39.3273 45.6706 38.5625 44.9058 38.5625 43.9623V40.7023H35.3C34.3566 40.7023 33.5918 39.9375 33.5918 38.9941C33.5918 38.0506 34.3566 37.2858 35.3 37.2858H38.5625V34.0246Z" fill="url(#paint0_linear_2022_17240)" />
      <defs>
        <linearGradient id="paint0_linear_2022_17240" x1="40.9352" y1="25.8027" x2="40.9352" y2="53.1224" gradientUnits="userSpaceOnUse">
          <stop stop-color="#FF9C64" />
          <stop offset="1" stop-color="#D14C02" />
        </linearGradient>
      </defs>
    </svg>
  )
}

export default AddbankRed;