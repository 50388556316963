import { AES, enc, mode, pad, } from 'crypto-js';
import React from 'react';

import config from '../config/config';
import { VALIDATE } from '../graphql/login';
import { GET_USERINFO } from '../graphql/member';
import cookieUtil from '../util/cookieUtil';
import graphqlApiUtil from '../util/graphqlApiUtil';
import util from '../util/util';
import { ECookieName } from '../util/utilModel';

const getRedirectWithCheckValidUser = async (ctx: any, memberId: number, originalMemberId: number, type: string) => {
	let isRedirect = false;
	let isValid = true;
	const context = ctx;
	const isTest = context.query.test;
	const token = cookieUtil.get(ECookieName.COOKIE_TOKEN, ctx);
	// server side判斷使用者帳號是否還存在
	if (config.isAuthCheck && !util.isClient && !isTest && context.pathname !== '/_error') {
		const payload =
			memberId &&
			(await graphqlApiUtil.query(
				VALIDATE,
				{ res: context.res, req: context.req },
				{
					input: {
						memberId: Number(originalMemberId),
						token,
					},
				},
			));
		if (memberId && util.getValue(payload, ['data', 'member', 'validate', 'success'])) {
			if (context.pathname === config.pageRedirect.withoutPermissionPage) {
				isRedirect = true;
			}
		} else {
			isValid = false;
			cookieUtil.remove(ECookieName.COOKIE_MEMBERID, context);
			cookieUtil.remove(ECookieName.COOKIE_IS_DEFAULT_PASSWORD, context);
			cookieUtil.remove(ECookieName.COOKIE_IS_HAD_AUTH, context);
			if (context.pathname !== config.pageRedirect.withoutPermissionPage) {
				isRedirect = true;
			}
		}
	}
	return { isRedirect, isValid };
};

const getUserInfoFromCookie = (ctx: any) => {
	const username = cookieUtil.get(ECookieName.COOKIE_USERNAME, ctx) || '';
	const memberId = Number(cookieUtil.get(ECookieName.COOKIE_MEMBERID, ctx));
	const originalMemberId = Number(cookieUtil.get(ECookieName.COOKIE_ORIGINAL_MEMBERID, ctx));
	const exchangeRate = Number(cookieUtil.get(ECookieName.COOKIE_EXCHANGE_RATE, ctx));
	let type = cookieUtil.get(ECookieName.COOKIE_ACCOUNT_TYPE, ctx);
	// 為了在type為空的時候給他預設值,再抓網址來切分type要帶 agent or client
	if (!type) {
		const hostRef = util.isClient ? window.location.host : ctx.req.headers.host;
		hostRef.indexOf('agent') === 0 ? (type = 'MASTER') : (type = 'CLIENT');
	}
	const isSubAccount = Boolean(cookieUtil.get(ECookieName.COOKIE_IS_SUBACCOUNT, ctx) /* 可能為 '1' or '' */);
	let bfInfo;
	try {
		bfInfo = JSON.parse(decodeURIComponent(cookieUtil.get(ECookieName.COOKIE_BFPT_INFO, ctx)) || '[]');
	} catch (e) {
		bfInfo = [];
	}
	return { username, memberId, exchangeRate, type, isSubAccount, bfInfo, originalMemberId };
};


// iframe的時候，若無memberId則不能看到任何畫面，開發時不用檢查auth
const getIframeUserInfo = async (ctx: any) => {
	const { query = {} } = ctx;
	// This place was changed by - Manoj 
	// const token = query.p
	const token = cookieUtil.get(ECookieName.COOKIE_TOKEN, ctx) || query.p || 'manojjjjj';
	let persona;
	try {
		const encryptedHexTarget = enc.Hex.parse(query.persona);
		const encryptSecretKey = enc.Utf8.parse(config.secretKey);
		persona = AES.decrypt(enc.Base64.stringify(encryptedHexTarget), encryptSecretKey, {
			iv: enc.Utf8.parse(''),
			padding: pad.Pkcs7,
			mode: mode.CBC,
		}).toString(enc.Utf8);
		// persona = JSON.parse(
		// 	AES.decrypt(decodeURIComponent(query.persona) || '{}', config.secretKey).toString(enc.Utf8),
		// );
	} catch (e) {
		persona = {};
	}

	const payload =
		(await graphqlApiUtil.query(
			GET_USERINFO,
			{ res: ctx.res, req: ctx.req },
			{
				input: {
					token: cookieUtil.get(ECookieName.COOKIE_TOKEN, ctx) || query.p || 'ambuuu',
				},
			},
		)) || {};

	const result = util.getValue(payload, 'data.member.userInfo', {});
	!result.memberId &&
		config.isAuthCheck &&
		ctx.pathname !== config.pageRedirect.withoutPermissionPage &&
		redirectPage(ctx, config.pageRedirect.withoutPermissionPage);
	result.orderLimit &&
		cookieUtil.set(ECookieName.COOKIE_ORDER_LIMIT, JSON.stringify(result.orderLimit || []), undefined, ctx);
	return { ...result, persona, personaString: query.persona || '', token };
};



const redirectPage = (context: any, path: string) => {
	return util.isClient ? (window.location.href = path) : context.res.redirect(path);
};

const checkUserTypeWithPage = (context: any, type: string) => {
	if (!type) {
		return;
	}
	// CLIENT AGENT需要另外阻擋的頁面
	const disallowPage = {
		CLIENT: ['/agent/'],
		AGENT: ['/exchange/'],
	};

	const isNotAllow = disallowPage[type === 'CLIENT' ? 'CLIENT' : 'AGENT'].some(
		page => context.pathname.indexOf(page) > -1,
	);
	return isNotAllow && redirectPage(context, config.pageRedirect.withoutPermissionPage);
};

const getUserInfoFromCookieAndValidUser = async (ctx: any) => {
	const userInfo = getUserInfoFromCookie(ctx);
	const token = cookieUtil.get(ECookieName.COOKIE_TOKEN, ctx);
	// const { isRedirect, isValid } = await getRedirectWithCheckValidUser(
	// 	ctx,
	// 	userInfo.memberId,
	// 	userInfo.originalMemberId,
	// 	userInfo.type,
	// );
	// return { ...userInfo, isRedirect, isValid, token };
	return { ...userInfo, token }
};

const getUserInfo = async (ctx: any) => {
	const { query = {} } = ctx;
	const userInfo =
		query.test === '1'
			? {
				memberId: 25,
				username: 'betclient',
				exchangeRate: 1,
				type: 'CLIENT',
			}
			: query.p || config.isIframe
				? await getIframeUserInfo(ctx)
				: await getUserInfoFromCookieAndValidUser(ctx);
	return { ...userInfo };
};

let __info__: any;
// const withUserInfo: (Child: any) => React.ReactNode = (Child: any) =>
// 	class WithUserInfo extends React.Component<any, any> {
// 		constructor(props: any) {
// 			super(props);
// 			util.isClient && props && (__info__ = props.payload);
// 		}
// 		static async getInitialProps(ctx: any = {}): Promise<any> {
// 			const childProps = Child.getInitialProps ? await Child.getInitialProps(ctx) : {};
// 			const context = ctx.ctx;
// 			const { query } = childProps;
// 			const langArray = ['en-us'];
// 			let lang = cookieUtil.get(ECookieName.COOKIE_LANG, context);
// if (langArray.indexOf(lang) < 0) {
// 	cookieUtil.set(ECookieName.COOKIE_LANG, langArray[0], 30, context);
// 	lang = langArray[0];
// }
// 			// string -> number
// const defaultTimeZone = config.isIframe
// 	? parseFloat(util.getValue(context, ['query'], {}).timeZone)
// 	: parseFloat(cookieUtil.get(ECookieName.COOKIE_TIME_ZONE, context));
// let payload;
// if (util.isClient && __info__ && !query.first) {
// 	payload = __info__;
// } else {
// 	payload = await getUserInfo(context);
// 	payload.memberId && util.isClient && (__info__ = payload);
// }

// checkUserTypeWithPage(context, payload.type);
// return { ...childProps, ...payload, lang, defaultTimeZone, payload };
// 		}

// 		render(): JSX.Element {
// 			const {
// 				query = {},
// 				payload: { isValid, isRedirect, type },
// 			} = this.props;

// 			if (isRedirect && util.isClient) {
// 				window.location.href = isValid
// 					? type === 'CLIENT'
// 						? config.pageRedirect.euPage
// 						: config.pageRedirect.agentPage
// 					: config.pageRedirect.withoutPermissionPage;
// 			}
// 			console.log('props',this.props);
// 			console.log('state',this.state)

// 			return (
// 				<>
// 					<Child {...this.props} {...this.state} />
// 				</>
// 			);
// 		}
// 	};

const withUserInfo = Child => {
	class WithUserInfo extends React.Component {
		constructor(props) {
			super(props);
			util.isClient && props && (__info__ = props.payload);
		}
		static async getInitialProps() {
			const context = '';
			const query = {}
			const langSelected = cookieUtil.get(ECookieName.COOKIE_LANG, context);
			const langArray = [langSelected];
			let lang = cookieUtil.get(ECookieName.COOKIE_LANG, context);
			if (langArray.indexOf(lang) < 0) {
				cookieUtil.set(ECookieName.COOKIE_LANG, langArray[0], 30, context);
				lang = langArray[0];
			}

			const defaultTimeZone = config.isIframe ? parseFloat(util.getValue(context, ['query'], {}).timeZone)
				: parseFloat(cookieUtil.get(ECookieName.COOKIE_TIME_ZONE, context));


			let payload;
			if (util.isClient && __info__ && !query['first']) {
				payload = __info__;
			}
			else {
				payload = await getUserInfo(context);
				payload.memberId && util.isClient && (__info__ = payload);
			}
			// checkUserTypeWithPage(context, payload.type);
			return { ...payload, lang, query, defaultTimeZone };
		}
		
		render() {
			return <Child {...this.props} />
		}
	}
	return WithUserInfo
}

export default withUserInfo;
