import React from "react";

type Props = {};

export default function MyDashboardIcon({}: Props) {
  return (
    <svg fill="none" xmlns="" viewBox="0 0 25 24">
      <path
        d="M10.672 3h-7v7h7V3ZM21.672 3h-7v7h7V3ZM21.672 14h-7v7h7v-7ZM10.672 14h-7v7h7v-7Z"
        stroke="#fff"
        stroke-width="2"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  );
}

export  function MyDashboardIconf88({}: Props) {
  return (
    <svg fill="none" xmlns="" viewBox="0 0 25 24">
      <path
        d="M10.672 3h-7v7h7V3ZM21.672 3h-7v7h7V3ZM21.672 14h-7v7h7v-7ZM10.672 14h-7v7h7v-7Z"
        stroke="#000"
        stroke-width="2"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  );
}
