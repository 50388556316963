import React from 'react'

type Props = {}

const AppDwnldAnimationSvg = (props: Props) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 738 710"><path stroke="#6778E3" stroke-miterlimit="10" stroke-width="102.025" d="M365.448 440.58c-10.189 58.6-16.902 139.172-65.901 180.094-68.145 56.911-165.734 45.485-217.672-25.486-51.937-70.971-38.765-174.9 29.38-231.812 68.145-56.911 174.19-22.147 256.533-6.624l1.608.384c125.183 24.655 189.761 46.723 257.906-10.189 68.145-56.911 81.473-160.626 29.379-231.812C604.587 43.95 507.155 32.738 439.01 89.65c-51.263 42.813-48.883 92.298-64.685 183.569"/></svg>
  )
}

export default AppDwnldAnimationSvg;