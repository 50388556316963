import React from 'react'
import styled from 'styled-components';
import {
    BodyWrapper as MessageBodyWrapper,
    FirstCol,
    Header,
    MainInfoWrapper,
} from 'src/components/udb/commonStyle';
import Intl from 'src/components/common/Intl';
import UDBHeader from 'src/components/UDBHeader';
import config from 'src/config/config';
import { Tittle } from './Auth/commonStyles';
import Breadcrumb from './udb/Breadcrumb';
import { theme } from 'styled-tools';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import PlaceholderImage from 'src/components/Placeholder';
import SEOContents from './SEOContents';

type Props = {}

const AffiliateHome = (props: Props) => {
    return (
        <MainInfoWrapper>
            <SEOContents pageName="affiliate"/>
            <AffiliateWrapper>
                <HeaderContainer>
                    <FirstCol>
                    <Tittle>
                        <Intl langKey="DASHBOARD@AFFILIATE" />
                    </Tittle>
                    <Breadcrumb />
                    </FirstCol>
                </HeaderContainer>
                <BodyWrapper>
                    {/* <Title> <Intl langKey='TABLE@COMING_SOON' /></Title> */}
                    <LazyLoadImage src={`${config.imageV3Host}/static/media/ComingSoonDesktopVersion.jpg`} effect="none" height="100%" width="100%"  placeholder={<PlaceholderImage/>}/>
                </BodyWrapper>
            </AffiliateWrapper>
        </MainInfoWrapper>
    )
}

export default AffiliateHome;

const BodyWrapper = styled(MessageBodyWrapper)`
    width: 100%;
    justify-content: center;
    background: none;
    box-shadow: none;
    border-radius: 5px;
    padding: 0em;

    img {
        max-width: 100%;
        object-fit: cover;
        height: 100%;
        border-radius: 5px;;
    }
`;
const AffiliateMain = styled.div`
    padding: 2rem;
`
const AffiliateWrapper = styled.div`
    height: auto;
    min-height: 100vh;
    max-height: fit-content;
    box-shadow: 0px 0px 100px rgba(0, 7, 66, 0.77);
    width: 100%;
    padding: 3rem;
    /* background-image: url(${config.imageV3Host}/static/cashsite/${theme('image.bonusBg')}); */
    background-repeat: no-repeat;
    background-size: cover;
    `


const HeaderContainer = styled.div`
    display: block;
    width:60%;
`
  
