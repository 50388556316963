import gql from 'graphql-tag';

import { eventInfo, innerEventInfo } from './events';
import { orderGroup } from './exchange';
import { creditInfo } from './member';

export const SUBSCRIBE_MARKET_LIST = gql`
	subscription liveList($input: MarketListInput!) {
		marketList(input: $input) {
			...eventInfo
		}
	}
	${eventInfo}
`;

export const SUBSCRIBE_EVENT_INFO = gql`
	subscription liveList($input: MarketListInput!) {
		marketList(input: $input) {
			...innerEventInfo
		}
	}
	${innerEventInfo}
`;
export const SUBSCRIBE_EVENT_FAVORITE = gql`
	subscription memberFavorite($input: MemberFavoriteInput!) {
		memberFavorite(input: $input) {
			...eventInfo
		}
	}
	${eventInfo}
`;

export const MEMBER_ORDER = gql`
	subscription memberOrder($input: MemberOrderInput!) {
		memberOrder(input: $input) {
			...orderGroup
		}
	}
	${orderGroup}
`;

export const SELECTION_INFO = gql`
	subscription selectionInfo($input: [SelectionInfoInput!]!) {
		selectionInfo(input: $input) {
			marketId
			selectionId
			price
			side
		}
	}
`;

export const MEMBER_INFO = gql`
	subscription memberInfo($input: MemberInfoInput!) {
		memberInfo(input: $input) {
			credit {
				...creditInfo
			}
			isValid
		}
	}
	${creditInfo}
`;
