import React from 'react';
import SEOContents from 'src/components/SEOContents';
import { ContentWrapper as MLCContentWrapper, MainInfoWrapper } from 'src/components/udb/commonStyle';
import config from 'src/config/config';
import { withAppContext } from 'src/store/initAppContext';
import styled, { withTheme } from 'styled-components';
import { theme } from 'styled-tools';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import PlaceholderImage from 'src/components/Placeholder';
import FooterContainer from "src/components/footer/footer";
import Intl from 'src/components/common/Intl';
import { Link, useNavigate } from 'react-router-dom';


const MajorLeagueCricketBetting = (props) => {
  const { app: { brandId }, theme } = props;
  const navigate = useNavigate();
  return (
    <>
      <MainInfoWrapper>
        <SEOContents pageName={"majorLeagueCricketBetting"} />
        <BannerWrapper>
          <button onClick={()=>navigate('/betting-exchange/online-cricket-satta-bazar')} className='clickBtn'><LazyLoadImage src={`${config.imageV3Host}/static/cashsite/${theme.image.MLCPromoPageBanner}`} effect="none" height="100%" width="100%" placeholder={<PlaceholderImage />} /></button>
        </BannerWrapper>
        <ContentWrapper>
          <Row>
            <ImgBlock><LazyLoadImage src={`${config.imageV3Host}/static/cashsite/${theme.image.WithTrophyImg}`} effect="none" height="100%" width="100%" placeholder={<PlaceholderImage />} /></ImgBlock>
            <SmallBlock>
              <TittleSmall>Explore the world of MLC Betting in India!</TittleSmall>
              <TableWrapper>
                <TableHead>Table of Contents</TableHead>
                <TableBody>Overview</TableBody>
                <TableBody>Popularity of MLC Betting</TableBody>
                <TableBody>MLC Format, Teams & Venues</TableBody>
                <TableBody>MLC League Players & Captains</TableBody>
                <TableBody>MLC Schedule & Live Streaming</TableBody>
                <TableBody>Why Yolo247 is the best site for MLC Betting?</TableBody>
                <TableBody>MLC Betting Options & Rules</TableBody>
                <TableBody>Best MLC Betting App</TableBody>
                <TableBody>How to get started with MLC Online Betting? </TableBody>
              </TableWrapper>
            </SmallBlock>
          </Row>

          <Coloumn>
            <TittleH2>Overview of Major League Cricket | Prerequisite for MLC Betting</TittleH2>
            <ItalicText>
              Cricket doesn’t feature in the list of top 10 popular sports in the United States of America. This list is going to change in the near future, as cricket is introduced to the American audience in the best possible manner in the form of Major League Cricket. <br /> <br />
              The inaugural edition of Major League Cricket, which is a much-awaited US T20 League, features 6 teams. The league starts on July 14, 2023, Friday (6 AM IST).<br /> <br />
              Though Major League Cricket has franchises owned by Indian conglomerates, MLC will stand as an American counterpart to the Indian Premier League.
            </ItalicText>
          </Coloumn>

          <Coloumn>
            <TittleH3>Changing the Landscape of Cricket </TittleH3>
            <NormalText>MLC is a historic league, as it will transform the way cricket is played and viewed. To understand the significance of Major League Cricket, we must look at how Football is structured.</NormalText>
            <RowWhiteBlock>
              <BorderWhite>When it comes to football, clubs are more important than region or nation.</BorderWhite>
              <BorderWhite>Clubs have a stronger contract for players.</BorderWhite>
              <BorderWhite>When it comes to cricket, it is the other way around. All major teams are nationspecific.</BorderWhite>
              <BorderWhite>Clubs don’t have any authority over players’ choices about picking a league or dropping out of a league.</BorderWhite>
            </RowWhiteBlock>
            <NormalText>This scenario will change once Major League Cricket hits the screens. What can we expect? What do many cricket enthusiasts speculate?</NormalText>
            <RowWhiteBlock>
              <BorderWhiteLong>Almost all the major clubs will have contracts with specific players.</BorderWhiteLong>
              <BorderWhiteLong>Those players will be playing for the same franchise all around the world. Eg: MI has a team in the IPL & in the MLC. So, players, who are under the contract, can’t go and play for any other franchise.</BorderWhiteLong>
              <BorderWhiteLong>This will increase the viewership and popularity of the game; however, it will restrict players from exploring other opportunities.</BorderWhiteLong>
              <BorderWhiteLong>As of now, many cricketers are neutral, as this situation is new to the cricketing community. </BorderWhiteLong>
            </RowWhiteBlock>
            <NormalText>We can come to a conclusion only after two or three more MLC league editions. On the other hand, MLC betting will flourish regardless of the contracts between players and franchises.</NormalText>
          </Coloumn>

          <Coloumn>
            <TittleH2>Scope for Major league cricket betting online | Contribution of Yolo247</TittleH2>
            <ItalicText>
              As the game remains the same, the betting markets and options will not undergo any changes. The future of MLC betting is bright and promising. <br /><br />
              Yolo247 is now making MLC online betting available for all matches.  <br /><br />
              MLC matches will be treated exclusively, and odds will be set for individual matches. In special markets, the final winner prediction will also be added. In fancy markets, in-play bets will be shown.
            </ItalicText>
          </Coloumn>

          <Coloumn>
            <TittleH3>Top 3 Reasons why MLC online betting is popular in India </TittleH3>
            <NormalTextColored>There are various factors and reasons that make MLC Betting a lucrative opportunity all over the world. Here, we have listed the top 3 reasons why MLC online betting is popular in India. </NormalTextColored>
            <RowWhiteBlock>
              <NoBorderWhiteLong>
                <SpanText>Familiarity</SpanText>
                <NoBorderWhiteLongText>The rivalry of IPL teams continues to entertain now, from the US. The big stars are back on the ground.<br /><br />
                  Knowing the teams well is the primary factor in building a successful cricket betting journey. It helps in making informed betting decisions. MLC enables each cricket enthusiast to pick a team or moment, predict the outcome, and win exciting rewards. </NoBorderWhiteLongText>
              </NoBorderWhiteLong>
              <NoBorderWhiteLong>
                <SpanText>Accessibility</SpanText>
                <NoBorderWhiteLongText>Viewers may watch MLC for free or for a minimal subscription charge through service providers, making it easier for people to watch match proceedings and place bets accordingly. </NoBorderWhiteLongText>
              </NoBorderWhiteLong>
              <NoBorderWhiteLong>
                <SpanText>Profitability</SpanText>
                <NoBorderWhiteLongText>The success of online IPL betting tells us about the vast opportunity that lies behind MLC. The MLC betting odds will be even more transparent.</NoBorderWhiteLongText>
              </NoBorderWhiteLong>
            </RowWhiteBlock>
            <NormalText>In addition to all these reasons, Yolo247 makes MLC betting faster and smarter. Major League Cricket is undoubtedly going to be a hit among online betting enthusiasts.</NormalText>
          </Coloumn>

          <Coloumn>
            <TittleH2>MLC Cricket League Format, Teams & Venues</TittleH2>
            <ItalicText>MLC Cricket league will follow the ICC cricket rules for each game. It will be super over as a tiebreaker.</ItalicText>
            <TittleH3White>MLC League Format </TittleH3White>
            <NormalText>MLC will be a T20 league. Each team will play a 20-over innings, unless it is shortened due to rain. </NormalText>
            <RowWhiteBlock>
              <BorderWhiteSmallBox>11 Players per team</BorderWhiteSmallBox>
              <BorderWhiteSmallBox>20 Overs per team</BorderWhiteSmallBox>
              <BorderWhiteSmallBox>6 overs of Powerplay</BorderWhiteSmallBox>
            </RowWhiteBlock>

            <TittleH3White>MLC Teams | Six Teams for Major League Cricket</TittleH3White>
            <NormalText>There are 6 teams named after 6 prominent regions. The MLC team names are as follows: </NormalText>

            <RowWhiteBlocFlexWrap>
              <BorderWhiteSmallBox>Los Angeles Knight Riders</BorderWhiteSmallBox>
              <BorderWhiteSmallBox>MI New York</BorderWhiteSmallBox>
              <BorderWhiteSmallBox>San Francisco Unicorns</BorderWhiteSmallBox>
              <BorderWhiteSmallBox>Seattle Orcas </BorderWhiteSmallBox>
              <BorderWhiteSmallBox>Texas Super Kings</BorderWhiteSmallBox>
              <BorderWhiteSmallBox>Washington Freedom</BorderWhiteSmallBox>
            </RowWhiteBlocFlexWrap>

            <NormalText>We have four franchises from the Indian Premier League lineage. Los Angeles Knight Riders is owned by Knight Riders group of the IPL, while MI New York is owned by the Mumbai Indians founders. </NormalText>
            <NormalText>Texas Super Kings have already become popular because of the ‘Chennai Super Kings’ tag. Fans expect Dhoni to visit the US to watch any one of the matches. However, there is no official communication from the CSK management yet. GMR group, which owns the Delhi Capitals, runs the Seattle Orcas team.</NormalText>
            <TittleH3White>MLC League Venues | Two Venues for the Best US T20 League</TittleH3White>
            <NormalText>Due to travel convenience, all the matches are scheduled to happen in just two locations. There are in total 19 matches, including the final. All these matches will happen across two stadiums.</NormalText>
            <RowWhiteBlock>
              <BorderWhiteSmallBox>Grand Prairie Stadium in Dallas</BorderWhiteSmallBox>
              <BorderWhiteSmallBox>Church Street Park in Morrisville</BorderWhiteSmallBox>
            </RowWhiteBlock>
            <NormalText>Grand Prairie Stadium was formerly known as the Ball Park or AirHogs stadium. Many notable baseball games and soccer games were played at this venue. Only in November 2020, this ground was converted to a fully advanced cricket stadium. It houses 7200 seats, which the management is planning to increase for the next season.</NormalText>
            <NormalText>Notably, all the seats are booked within 24 hours for the first match.</NormalText>
            <NormalText>On the other hand, Church Street Park is a much smaller ground in terms of seating capacity. In 2022, this cricket ground underwent an extensive renovation with state-ofthe-art facilities. The ground management is planning to host matches for ICC T20 Men’s World Cup 2024. </NormalText>
            <NormalText>Both of these grounds are batsman-friendly, and we can expect 200+ scores quite often.</NormalText>
          </Coloumn>

          <Coloumn>
            <TittleH2>MLC League All Team Players & Captains</TittleH2>
            <ItalicText>Each MLC league team has a minimum of 16 players, and maximum of 19 players in their roster. As many news channels claim, one team can have 6 international players in the playing 11.<br/><br/>
            Find the Major League Cricket Players for each team.</ItalicText>
            <RowWhiteBlocFlexWrap>
              <CustomBlock>
                <BlockTittle>Los Angeles Knight Riders Players </BlockTittle>
                <ItalicText>Sunil Narine, Martin Guptill, Unmukt Chand, Gajanand Singh, Rilee Rossouw, Jaskaran Malhotra (wk), Corne Dry, Saif Badar, Lockie Ferguson, Spencer Johnson, Adam Zampa, Ali Khan, Nitish Kumar, Jason Roy, Shadley van Schalkwyk, Andre Russell, Ali Sheikh and Bhaskar Yadram.</ItalicText>
              </CustomBlock>

              <CustomBlock>
                <BlockTittle>MI New York Players</BlockTittle>
                <ItalicText>Kieron Pollard, Jason Behrendorff, Rashid Khan, Trent Boult, Nicholas Pooran, Dewald Brevis, Kagiso Rabada, Tim David, Ehsan Adil, Saideep Ganesh, Shayan Jahangir (wk), Hammad Azam, Nosthush Kenjige, Sarabjit Ladda, Monank Patel (wk), Kyle Phillip, Jessy Singh, Steven Taylor, and David Wiese.</ItalicText>
              </CustomBlock>

              <CustomBlock>
                <BlockTittle>San Francisco Unicorns Players</BlockTittle>
                <ItalicText>Aaron Finch, Corey Anderson, Marcus Stoinis, Haris Rauf, Matthew Wade (wk), Lungi Ngidi, Qais Ahmad, Finn Allen, Amila Aponso, Chaitanya Bishnoi, Brody Couch, Mackenzie Harvey, Shadab Khan, Sanjay Krishnamurthi, Carmi Le Roux, Smit Patel (wk), Liam Plunkett, Tajinder Singh, and David White.</ItalicText>
              </CustomBlock>

              <CustomBlock>
                <BlockTittle>Seattle Orcas Players</BlockTittle>
                <ItalicText>Quinton de Kock (wk), Heinrich Klaasen, Angelo Perera, Wayne Parnell, Andrew Tye, Shimron Hetmyer, Dwaine Pretorius, Nauman Anwar, Cameron Gannon, Shehan Jayasuriya, Aaron Jones, Izharulhaq Naveed, Nisarg Patel, Shubham Ranjane, Dasun Shanaka, Harmeet Singh, Matthew Tromp, Phani Simhadri, and Imad Wasim.</ItalicText>
              </CustomBlock>

              <CustomBlock>
                <BlockTittle>Texas Super Kings Players </BlockTittle>
                <ItalicText>Faf du Plessis, Dwayne Bravo, Devon Conway, David Miller, Mitchell Santner, Daniel Sams, Cody Chetty, Gerald Coetzee, Milind Kumar, Lahiru Milantha, Rusty Theron, Mohammad Mohsin, Saiteja Mukkamalla, Sami Aslam, Calvin Savage, Zia Shahzad and Cameron Stevenson</ItalicText>
              </CustomBlock>

              <CustomBlock>
                <BlockTittle> Washington Freedom Players</BlockTittle>
                <ItalicText>Moises Henriques, Wanindu Hasaranga, Anrich Nortje, Marco Jansen, Mukhtar Ahmed, Saad Ali, Akhilesh Bodugum, Justin Dill, Ben Dwarshuis, Andries Gous, Sujith Gowda, Adam Milne, Josh Philippe, Dane Piedt, Obus Pienaar, Glenn Phillips, Usman Rafiq, Saurabh Netravalkar, and Akeal Hosein.</ItalicText>
              </CustomBlock>
            </RowWhiteBlocFlexWrap>
          </Coloumn>

          <Coloumn>
            <TittleH2>Major League Cricket Team Captains</TittleH2>
            <ItalicText>The captains of all the MLC teams are listed.</ItalicText>
            <TableBlock>
              <TableHeadBlock>
                <HeadText>MLC Team Name</HeadText>
                <HeadText>Captain Name</HeadText>
              </TableHeadBlock>
              <TableBodyBlock>
                <BodyText>Texas Super Kings</BodyText>
                <BodyText>Faf Du Plessis</BodyText>
              </TableBodyBlock>
              <TableBodyBlock>
                <BodyText>Los Angeles Knight Riders</BodyText>
                <BodyText>Sunil Narine</BodyText>
              </TableBodyBlock>
              <TableBodyBlock>
                <BodyText>Washington Freedom</BodyText>
                <BodyText>Moises Henriques</BodyText>
              </TableBodyBlock>
              <TableBodyBlock>
                <BodyText>Seattle Orcas</BodyText>
                <BodyText>Wayne Parnell</BodyText>
              </TableBodyBlock>
              <TableBodyBlock>
                <BodyText>San Francisco Unicorns</BodyText>
                <BodyText>Aaron Finch</BodyText>
              </TableBodyBlock>
              <TableBodyBlock>
                <BodyText>MI New York</BodyText>
                <BodyText>Kieron Pollard</BodyText>
              </TableBodyBlock>
            </TableBlock>
          </Coloumn>

          <Coloumn>
            <TittleH2>MLC League Cricket Schedule & Live Streaming</TittleH2>
            <ItalicText>Out of 19 matches, 12 will be held at Grand Prairie Stadium, and the remaining matches will be played at Church Street Park. <br/><br/>
            Each team will play 5 matches, against all the other teams. There are no groups or pools in Major League Cricket. <br/><br/>
            Go through the Major League Cricket Schedule.</ItalicText>

            <TableBlock style={{ width: '80%' }}>
              <TableHeadBlock>
                <HeadText>Date</HeadText>
                <HeadText>Match</HeadText>
                <HeadText>Venue</HeadText>
                <HeadText>Timing</HeadText>
              </TableHeadBlock>
              <TableBodyBlock>
                <BodyText>July 14</BodyText>
                <BodyText>Texas Super Kings vs Los Angeles Knight Riders</BodyText>
                <BodyText>Grand Prairie Stadium, Texas</BodyText>
                <BodyText>6:00 AM</BodyText>
              </TableBodyBlock>
              <TableBodyBlock>
                <BodyText>July 15</BodyText>
                <BodyText>MI New York vs San Francisco Unicorns</BodyText>
                <BodyText>Grand Prairie Stadium, Texas</BodyText>
                <BodyText>2:00 AM</BodyText>
              </TableBodyBlock>
              <TableBodyBlock>
                <BodyText>July 15</BodyText>
                <BodyText>Seattle Orcas vs Washington Freedom</BodyText>
                <BodyText>Grand Prairie Stadium, Texas</BodyText>
                <BodyText>6:00 AM</BodyText>
              </TableBodyBlock>
              <TableBodyBlock>
                <BodyText>July 16</BodyText>
                <BodyText>San Francisco Unicorns vs Seattle Orcas</BodyText>
                <BodyText>Grand Prairie Stadium, Texas</BodyText>
                <BodyText>6:00 AM</BodyText>
              </TableBodyBlock>
              <TableBodyBlock>
                <BodyText>July 17</BodyText>
                <BodyText>Texas Super Kings vs Washington Freedom</BodyText>
                <BodyText>Grand Prairie Stadium, Texas</BodyText>
                <BodyText>2:00 AM</BodyText>
              </TableBodyBlock>
              <TableBodyBlock>
                <BodyText>July 17</BodyText>
                <BodyText>Los Angeles Knight Riders vs MI New York</BodyText>
                <BodyText>Grand Prairie Stadium, Texas</BodyText>
                <BodyText>6:00 AM</BodyText>
              </TableBodyBlock>
              <TableBodyBlock>
                <BodyText>July 18</BodyText>
                <BodyText> Texas Super Kings vs MI New York</BodyText>
                <BodyText>Grand Prairie Stadium, Texas</BodyText>
                <BodyText>6:00 AM</BodyText>
              </TableBodyBlock>
              <TableBodyBlock>
                <BodyText>July 19</BodyText>
                <BodyText>Los Angeles Knight Riders vs San Francisco Unicorns</BodyText>
                <BodyText>Grand Prairie Stadium, Texas</BodyText>
                <BodyText>6:00 AM</BodyText>
              </TableBodyBlock>
              <TableBodyBlock>
                <BodyText>July 21</BodyText>
                <BodyText>Washington Freedom vs Los Angeles Knight Riders</BodyText>
                <BodyText>Church Street Park, Morrisville</BodyText>
                <BodyText>3:00 AM</BodyText>
              </TableBodyBlock>
              <TableBodyBlock>
                <BodyText>July 22</BodyText>
                <BodyText>Seattle Orcas vs Texas Super Kings</BodyText>
                <BodyText>Church Street Park, Morrisville</BodyText>
                <BodyText>3:00 AM</BodyText>
              </TableBodyBlock>
              <TableBodyBlock>
                <BodyText>July 23</BodyText>
                <BodyText>Washington Freedom vs San Francisco Unicorns</BodyText>
                <BodyText>Church Street Park, Morrisville</BodyText>
                <BodyText>3:00 AM</BodyText>
              </TableBodyBlock>
              <TableBodyBlock>
                <BodyText>July 23</BodyText>
                <BodyText>Los Angeles Knight Riders vs Seattle Orcas</BodyText>
                <BodyText>Church Street Park, Morrisville</BodyText>
                <BodyText>11:00 AM</BodyText>
              </TableBodyBlock>
              <TableBodyBlock>
                <BodyText>July 24</BodyText>
                <BodyText>MI New York vs Washington Freedom</BodyText>
                <BodyText>Church Street Park, Morrisville</BodyText>
                <BodyText>3:00 AM</BodyText>
              </TableBodyBlock>
              <TableBodyBlock>
                <BodyText>July 25</BodyText>
                <BodyText>San Francisco Unicorns vs Texas Super Kings</BodyText>
                <BodyText>Church Street Park, Morrisville</BodyText>
                <BodyText>3:00 AM</BodyText>
              </TableBodyBlock>
              <TableBodyBlock>
                <BodyText>July 26</BodyText>
                <BodyText>MI New York vs Seattle Orcas</BodyText>
                <BodyText>Church Street Park, Morrisville</BodyText>
                <BodyText>3:00 AM</BodyText>
              </TableBodyBlock>
              <TableBodyBlock>
                <BodyText>July 28</BodyText>
                <BodyText>Eliminator</BodyText>
                <BodyText>Grand Prairie Stadium, Texas</BodyText>
                <BodyText>2:00 AM</BodyText>
              </TableBodyBlock>
              <TableBodyBlock>
                <BodyText>July 28</BodyText>
                <BodyText>Qualifier 1</BodyText>
                <BodyText>Grand Prairie Stadium, Texas</BodyText>
                <BodyText>6:00 AM</BodyText>
              </TableBodyBlock>
              <TableBodyBlock>
                <BodyText>July 28</BodyText>
                <BodyText>Qualifier 2</BodyText>
                <BodyText>Grand Prairie Stadium, Texas</BodyText>
                <BodyText>6:00 AM</BodyText>
              </TableBodyBlock>
              <TableBodyBlock>
                <BodyText>July 31</BodyText>
                <BodyText>Final</BodyText>
                <BodyText>Grand Prairie Stadium, Texas</BodyText>
                <BodyText>6:00 AM</BodyText>
              </TableBodyBlock>
            </TableBlock>
          </Coloumn>

          <Coloumn>
            <TittleH2>Where to watch live streaming of MLC league cricket?</TittleH2>
            <ItalicText>In India, Viacom holds the MLC broadcasting partnership.</ItalicText>
            <ul>
              <li><ItalicText>Live streaming will be on JioCinema</ItalicText></li>
              <li><ItalicText>Tune in to Sports18 for TV coverage</ItalicText></li>
            </ul>
            <ItalicText>Sports consumption keeps expanding with the addition of new technologies and services.</ItalicText>
          </Coloumn>

          <Coloumn>
            <TittleH2>Why Yolo247 is the best site for MLC Betting?</TittleH2>
            <ItalicText>Yolo247 offers the best online cricket betting experience for Major League Cricket.</ItalicText>
            <RowWhiteBlocFlexWrap>
              <CustomBlock>
                <BlockTittleRightHoriz>Simple Registration <br/> Process</BlockTittleRightHoriz>
                <ItalicText>The registration process on Yolo247 to bet on Major League Cricket is simpler and straightforward. Betting on MLC is made easier and safer on Yolo247.</ItalicText>
              </CustomBlock>

              <CustomBlock>
                <BlockTittleRightHoriz>Faster Withdrawal & <br/> Deposit</BlockTittleRightHoriz>
                <ItalicText>For any betting journey, withdrawal is the final step. All is well that ends well. On Yolo247, you will get the smoothest withdrawal. Even the deposits are instant, making it easy to place your bets on time.</ItalicText>
              </CustomBlock>

              <CustomBlock>
                <BlockTittleRightHoriz>More Betting Options</BlockTittleRightHoriz>
                <ItalicText>Get all the available betting options for MLC on one platform. You can predict the overall champion, individual match winner, highest run scorer, highest wicket-taker, and other outcomes. Fancy bets are also available.</ItalicText>
              </CustomBlock>

              <CustomBlock>
                <BlockTittleRightHoriz>Available for all MLC <br/> matches</BlockTittleRightHoriz>
                <ItalicText>You can get the best odds for MLC on Yolo247. Bet on MLC and place your bets on any match, as all MLC match bets are available on the platform.</ItalicText>
              </CustomBlock>

              <CustomBlock>
                <BlockTittleRightHoriz>24x7 Customer Support</BlockTittleRightHoriz>
                <ItalicText>Here comes the best part of placing bets on Yolo247, for Major League Cricket. As the league is new, you may have questions and doubts at the beginning of the season. Yolo247 customer service is available 24x7 to ensure all your queries are answered instantly.</ItalicText>
              </CustomBlock>

              <CustomBlock>
                <BlockTittleRightHoriz>Exciting Rewards</BlockTittleRightHoriz>
                <ItalicText>Get the best MLC betting offers on Yolo247. It is applicable for both existing and new users and available until the end of the season.</ItalicText>
              </CustomBlock>

              <CustomBlockFullWidth>
                <BlockTittleRightHoriz>MLC Live Score, Updates & Tips</BlockTittleRightHoriz>
                <ItalicText>You can get the MLC cricket livescore, live streaming, match updates, and MLC betting tips for free. For every match, you get an in-depth prediction.</ItalicText>
              </CustomBlockFullWidth>
              <ItalicText>The best site for MLC betting must have the features mentioned above, and Yolo247 checks all the boxes. Yolo247 is highly valued and celebrated for MLC betting in India, as you can get both Betting Exchange and Sportsbook options.</ItalicText>
            </RowWhiteBlocFlexWrap>
          </Coloumn>

          <Coloumn>
            <TittleH2>MLC Betting Options on Yolo247</TittleH2>
            <ItalicText>Among the numerous betting options available for Major League Cricket betting, there are a few key bets that are popular.<br/><br/>
            Many online gamblers prefer these bets for their consistent winnings.</ItalicText>

            <RowWhiteBlocFlexWrap>
              <CustomBlock>
                <BlockTittleRightVertical>Winner of the Toss</BlockTittleRightVertical>
                <ItalicText>It is to predict which team will win the toss. A team can opt to bowl or bat, so that is immaterial. This bet deals with the Toss Winner alone. </ItalicText>
              </CustomBlock>

              <CustomBlock>
                <BlockTittleRightVertical>Match Winner Bet on Major League Cricket</BlockTittleRightVertical>
                <ItalicText>Match Winner prediction is a classic example of Straight Bets. Based on the odds provided, you can place your bets. If your prediction is right, you win.</ItalicText>
              </CustomBlock>

              <CustomBlock>
                <BlockTittleRightVertical>Top Bowler or Batsman</BlockTittleRightVertical>
                <ItalicText>This bet is player-specific. You can guess who will get the most wickets or runs, for a particular match or for the overall season. </ItalicText>
              </CustomBlock>

              <CustomBlock>
                <BlockTittleRightVertical>Special Market Bets</BlockTittleRightVertical>
                <ItalicText>Special Market bets are exclusively organized for Major League Cricket, wherein you can predict which team will lift the trophy. </ItalicText>
              </CustomBlock>

              <CustomBlockFullWidth>
                <BlockTittleRightHoriz> Fancy Bets for MLC Online Betting</BlockTittleRightHoriz>
                <ItalicText>All in-play bets are available. How many runs would be scored in a particular over, how many runs a team would score within the powerplay, and how many fours would be hit during an innings are some of the examples.</ItalicText>
              </CustomBlockFullWidth>
              <ItalicText>In addition to all these betting options, you will have other betting options during the live match. Don’t miss out on making use of the bonuses and special campaigns.</ItalicText>
            </RowWhiteBlocFlexWrap>

            <TittleH2>MLC Betting Rules</TittleH2>
            <ItalicText>On Yolo247, MLC betting rules are transparent. Regardless of the amount you choose to bet, the rules are the same.</ItalicText>
            <RowWhiteBlock>
              <BorderWhite>Place bets before or during the match for straight bets.</BorderWhite>
              <BorderWhite>MLC betting odds will change during a match, based on the match situation.</BorderWhite>
              <BorderWhite>Soon after the match, your winnings will be added to your wallet.</BorderWhite>
              <BorderWhite>You can withdraw anytime.</BorderWhite>
            </RowWhiteBlock>
            <ItalicText>Understand how the MLC odds work, before you begin betting.</ItalicText>
          </Coloumn>

          <Coloumn>
            <TittleH2>Best MLC Betting App in India</TittleH2>
            <ItalicText>Yolo247 remains the best MLC betting app in India, due to our commitment to customercentricity and user-friendly approach. <br/><br/>
              We strive hard to make online betting for Major League Cricket safer and smoother, available and accessible for everyone.
            </ItalicText>

            <RowWhiteBlocFlexWrap>
              <CustomBlock>
                <BlockTittleRightVertical>Easy to Install</BlockTittleRightVertical>
                <ItalicText>The Yolo247 MLC betting app is easy to use and install. Scan the QR code to download the Yolo247 app, register or login and start placing your bets.</ItalicText>
              </CustomBlock>

              <CustomBlock>
                <BlockTittleRightVertical>Faster Loading</BlockTittleRightVertical>
                <ItalicText>Since the app is already downloaded and installed, the loading speed is faster. Your MLC betting experience will be enhanced as you can do it without any lags.</ItalicText>
              </CustomBlock>

              <CustomBlock>
                <BlockTittleRightVertical>Best User Interface</BlockTittleRightVertical>
                <ItalicText>Navigation is the key. As Yolo247 provides betting options for many popular sports and all of the best casino games, you may need to shift from one window to another. You can do it effortlessly on the Yolo247 betting app.</ItalicText>
              </CustomBlock>

              <CustomBlock>
                <BlockTittleRightVertical>All options available</BlockTittleRightVertical>
                <ItalicText>Since the app is a mirrored version of the Yolo247 website, you will not miss out on any information or option or offer.</ItalicText>
              </CustomBlock>

              <CustomBlockFullWidth>
                <BlockTittleRightHoriz>Smooth Synchronization</BlockTittleRightHoriz>
                <ItalicText>All your data, winnings, and history are synched on a real-time basis, so you don’t need to worry about it. You can anytime get back to the website version as per your preference.</ItalicText>
              </CustomBlockFullWidth>
              <ItalicText>There should be no doubt about which is better for MLC online betting: Yolo247 site or Yolo247 app. Both are great, and it depends on your preference.</ItalicText>
            </RowWhiteBlocFlexWrap>
          </Coloumn>

          <Coloumn>
            <TittleH2>How to get started with MLC Online Betting?</TittleH2>
            <ItalicText>It is easier to start betting on MLC cricket league on Yolo247. Go through the 5-step process.</ItalicText>

            <RowWhiteBlocFlexWrap>
              <CustomBlock>
                <BlockTittleRightVertical>Step 1 - Register on <br/>Yolo247</BlockTittleRightVertical>
                <ItalicText>Just the basic information is required to create your account on Yolo247. Set up a username and password.</ItalicText>
              </CustomBlock>

              <CustomBlock>
                <BlockTittleRightVertical>Step 2 - Deposit the amount of<br/> your choice</BlockTittleRightVertical>
                <ItalicText>The minimum deposit amount is Rs.100. Start small and increase the amount as and when you understand the process well.</ItalicText>
              </CustomBlock>

              <CustomBlock>
                <BlockTittleRightVertical>Step 3 - Go to ‘Cricket<br/> Betting’ section</BlockTittleRightVertical>
                <ItalicText>Once you have registered, you have to login with your credentials. You can find the ‘Cricket’ under ‘Sports’ category. </ItalicText>
              </CustomBlock>

              <CustomBlock>
                <BlockTittleRightVertical>Step 4 - Pick any of the MLC<br/> matches</BlockTittleRightVertical>
                <ItalicText>You can place bets on all Major League Cricket matches. All match odds will be available on Yolo247.</ItalicText>
              </CustomBlock>

              <CustomBlockFullWidth>
                <BlockTittleRightHoriz>Step 5 - Place your bets. Win & withdraw.</BlockTittleRightHoriz>
                <ItalicText>Placing bets will take just a few seconds. Once placed, you can also watch the live match in a separate window on Yolo247.</ItalicText>
              </CustomBlockFullWidth>
              <ItalicText>If your prediction is right, bingo! Collect your profit instantly after the match. <br/>Start betting on MLC online on Yolo247.</ItalicText>
            </RowWhiteBlocFlexWrap>
            <button onClick={()=>navigate('/betting-exchange/online-cricket-satta-bazar')} className='clickBtn'>
              <span>Click Here</span>
            </button>
          </Coloumn>
        </ContentWrapper>
      </MainInfoWrapper>
      <FooterContainer />
    </>
  )
}

export default withTheme(withAppContext(MajorLeagueCricketBetting));

//Styled Components
const BodyText = styled.div`
  width: 50%;
  height: 55px;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  padding: 12px 10px;
  background: linear-gradient(#22272e,#22272e) 50% 50%/calc(100% - 2px) calc(100% - 2px) no-repeat,linear-gradient(0deg,transparent 0%,#505050 100%);
  -webkit-backdrop-filter: blur(13.690354347229004px);
  backdrop-filter: blur(13.690354347229004px);
  color: #FFF;
  text-transform: capitalize;
  font-weight: 700;
`;

const HeadText = styled.div`
  width: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  padding: 10px;
  background: linear-gradient(123deg,#D3D3D3 0%,#FFF 50.78%,#DDD 100%);
  color: #000;
  letter-spacing: 0.639px;
  text-transform: uppercase;
  font-weight: 800;
`;

const TableBodyBlock = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const TableHeadBlock = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 1px;
`;

const TableBlock = styled.div`
  width: 50%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

const BlockTittle = styled.h3`
  width: 310px;
  display: flex;
  align-items: center;
  font-size: 24px;
  font-style: normal;
  font-weight: 600;
  text-transform: capitalize;
  color: #FFF;
  /* text-align: left; */
  margin: 0;
  position: relative;

  ::before {
    content: "";
    width: 15px;
    height: 61px;
    background: linear-gradient(123deg, #D3D3D3 0%, #FFF 50.78%, #DDD 100%);
    position: absolute;
    left: -30px;
  }
`;

const BlockTittleRightHoriz = styled(BlockTittle)`
  width: 100%;

  ::before {
    content: "";
    width: 109px;
    height: 21px;
    left: unset;
    right: 0;
  }
`;

const BlockTittleRightVertical = styled(BlockTittle)`
  width: 100%;
  position: static;

  ::before {
    width: 13px;
    height: 63px;
    left: unset;
    right: 20px;
    top: 0;
  }
`;

const CustomBlock = styled.div`
  width: 40%;
  height: 285px;
  display: flex;
  flex-direction: column;
  text-align: left;
  align-items: flex-start;
  padding: 2rem;
  /* background: linear-gradient(139deg,rgba(255,255,255,0.14) 0%,rgba(255,255,255,0.07) 100%); */
  background: linear-gradient(#22272e,#22272e) 50% 50%/calc(100% - 2px) calc(100% - 2px) no-repeat,linear-gradient(0deg,transparent 0%,#505050 100%);
  backdrop-filter: blur(13.690354347229004px);
`;

const CustomBlockFullWidth = styled(CustomBlock)`
    width: 81%;
    height: 200px;
    text-align: center;
    justify-content: center;

    h3 {
          justify-content: center;

          ::before {
            display: none;
          }
    }
`;

const NoBorderWhiteLongText = styled.p`
  text-align: justify;
  letter-spacing: -1px;
  padding: 1rem;
  margin: 0;
`;

const SpanText = styled.span`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  background: linear-gradient(123deg, #D3D3D3 0%, #FFF 50.78%, #DDD 100%);
  padding: 1rem;
  font-size: 24px;
  color: #18080E;
  font-style: normal;
  font-weight: 600;
  text-transform: capitalize;
`;

const RowWhiteBlock = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 1.5rem;
`;

const RowWhiteBlocFlexWrap = styled(RowWhiteBlock)`
  flex-wrap: wrap;
`;

const BorderWhite = styled.div`
  width: 25%;
  height: 170px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 1rem;
  text-align: justify;
  letter-spacing: -1px;
  font-size: 18px;
  font-style: italic;
  font-weight: 300;
  text-transform: capitalize;
  color: #fff;
  padding: 2rem 1rem;
  background: linear-gradient(139deg, rgba(255, 255, 255, 0.14) 0%, rgba(255, 255, 255, 0.07) 100%);
  backdrop-filter: blur(13.690354347229004px);
  border-top: 1rem solid #fff;
`;

const BorderWhiteLong = styled(BorderWhite)`
  height: 230px;
`;

const NoBorderWhiteLong = styled(BorderWhiteLong)`
  height: 360px;
  border: none;
  padding: 0;
`;

const BorderWhiteSmallBox = styled(BorderWhite)`
  width: 30%;
  height: 100px;
  text-align: center;
  align-items: center;
`;

const NormalText = styled.p`
  display: flex;
  justify-content: center;
  font-size: 18px;
  text-transform: capitalize;
  color: #fff;
  margin-top: 0;
`;

const NormalTextColored = styled(NormalText)`
  color: ${theme('palette.MLCTittleColor')};
`;

const TittleH3 = styled.h3`
  font-size: 24px;
  font-style: normal;
  font-weight: 600;
  text-transform: capitalize;
  text-align: center;
  color: ${theme('palette.MLCTittleColor')};
`;
const TittleH3White = styled(TittleH3)`
  color: #fff;
`;

const ItalicText = styled.p`
  display: flex;
  justify-content: center;
  font-size: 18px;
  font-style: italic;
  font-weight: 300;
  text-transform: capitalize;
  color: #EBECF2;
`;

const TittleH2 = styled.h2`
  font-size: 30px;
  font-style: normal;
  font-weight: 700;
  text-transform: capitalize;
  text-align: center;
  color: ${theme('palette.MLCTittleColor')};
  position: relative;
  display: flex;
  justify-content: center;

  ::after {
    content: "";
    width: 103px;
    height: 2px;
    background: #F7F7F7;
    position: absolute;
    bottom: -20px;
  }
`;

const Coloumn = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 1rem;
  text-align: center;
  padding: 1rem 0;

  ul {
    margin: 0;
    padding: 0;

    li {
      color: #fff;
    }

    p {
      margin: 5px 0;
    }
  }

  .clickBtn {
    width: 142px;
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50.5px;
    /* background: linear-gradient(134deg, #ADADAD 0%, #FFF 50%, #B6B6B6 100%); */
    background: linear-gradient(360deg,#EA0049 -4.14%,#001DDD 84.48%);
    color: #18080E;
    font-family: 'parimatchregularregular';
    font-size: 20px;
    font-style: normal;
    font-weight: 400;
    text-transform: uppercase;
    padding: 3px;
    border: none;
    cursor: pointer;

    :hover {
      color: #fff;
    }

    span {
      width: 100%;
      height: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      background: linear-gradient(134deg,#ADADAD 0%,#FFF 50%,#B6B6B6 100%);
      border-radius: 50.5px;

      :hover {
        background: linear-gradient(180deg,#3448ca -4.14%,#EA0049 84.48%);
      }
    }
  }
`;

const TableBody = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 10px;
  /* background: linear-gradient(139deg, rgba(255, 255, 255, 0.14) 0%, rgba(255, 255, 255, 0.07) 100%); */
  background: linear-gradient(#22272e, #22272e) 50% 50%/calc(100% - 2px) calc(100% - 2px) no-repeat, linear-gradient(0deg, transparent 0%, #505050 100%);
  backdrop-filter: blur(13.690354347229004px);
  color: #FFF;
  text-transform: capitalize;
  font-weight: 700;
`;

const TableHead = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 10px;
  background: linear-gradient(123deg, #D3D3D3 0%, #FFF 50.78%, #DDD 100%);
  color: #000;
  letter-spacing: 0.639px;
  text-transform: uppercase;
  font-weight: 800;
`;

const TableWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 2px;
  text-align: center;
`;

const TittleSmall = styled.h4`
  margin: 0;
  font-size: 20px;
  font-weight: 500;
  color: ${theme('palette.MLCTittleColor')};
`;

const SmallBlock = styled.div`
  width: 40%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 1rem;
`;

const ImgBlock = styled.div`
  width: 60%;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const Row = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 3rem;
  padding-bottom: 2rem;
`;

const BannerWrapper = styled.div`
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;

    button {
      border: none;
      background: none;
      padding: 0;
      margin: 0;
      cursor: pointer;
    }
`;

const ContentWrapper = styled(MLCContentWrapper)`
  background: url(${config.imageV3Host}/static/cashsite/${theme('image.MLCPromoContentBg')});
  background-size: cover;
  background-repeat: no-repeat;
  background-position: 100%;
  padding: 3rem 6rem;
  text-transform: capitalize;

  img {
    max-width: 100%;
  }
`;