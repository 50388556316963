
import React from 'react'
import styled from 'styled-components';
import { color } from '../../sass-files/variable';
// Imported Components
import LoginForm from './login/login-form';
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import 'react-tabs/style/react-tabs.css';
import Signup_Desktop from 'src/images/signupNewImage.png'
import config from 'src/config/config';
import Intl from '../common/Intl';

import { ifProp, theme, withProp } from 'styled-tools';

const { tablogin } = color
const { tabsignup } = color
const { secondarycolor } = color

export default function LoginSignupTab(props:any) {
    const {emitModel} = props;
    return (
        <TabWrapper>
            <Tabs>
                <TabList>
                    <Tab><Intl langKey="LOGIN@TITLE" /></Tab>
                    <Tab>
                        {/* <Intl langKey="SIGNUP@TITLE" /> */}
                        </Tab>
                </TabList>

                <TabPanel>
                    <LoginForm emitModel={emitModel}/>
                </TabPanel>
                <TabPanel>
                    {/* <h2>Register With Us</h2>
                    <p>Reach Us On Our Whatsapp <br /> Number</p>
                    <span className="whatsApp">+3819765656</span> */}
                    <div className="signUpimage"></div>
                </TabPanel>
            </Tabs>
        </TabWrapper>
    )
}


const TabWrapper = styled.div`
    display: flex;
    align-items: center;
    flex-direction: column;

    ${Tab} {
        width: 100%;
        display: flex;
        align-items: flex-start;
        text-align: left;
    }

    .react-tabs {
        width: 92%;
        box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
        backdrop-filter: blur(101.933px);
        
    }

    .react-tabs__tab {
        width: 100%;
        text-transform: capitalize;
        font-size: 30px;
        
        color: ${theme('palette.primary-background-color-new')};
        padding: 5px 20px;
        text-transform: uppercase;
        padding-bottom: 0px;
        background: ${theme('palette.login-background-color-new')};
        text-align: center;
        border-radius: 5px 5px 0px 5px;
        border-bottom: 1px solid #aaa;
        border-color: transparent;
        cursor: initial;
    }

    .react-tabs__tab:focus {
        box-shadow: none;
        border-color: none;
        outline: none;
    }

    .react-tabs__tab-list {
        margin: 0;
        padding-bottom: 1px;
        border-bottom: none;
        display:flex
    }

    .react-tabs__tab-panel--selected {
        display: block;
        background: ${secondarycolor};
        border-radius: 0px 0px 5px 5px;
        height: 428px;
        color:${tabsignup};
        padding-top:1px;
    }

    .react-tabs__tab--selected {
        /* color: ${tablogin};
        background: ${secondarycolor};
        border-bottom: none; */
    }

    .signUpimage{
        background-image:url(${config.imageS3Host}${Signup_Desktop});
        background-size: 500px 386px;
        background-repeat: no-repeat;
        background-position: 100% -10px;
        overflow: hidden;
        color: transparent;
        width:100%;
        height: 90%;
        position: absolute;
        bottom:-58px;
    }

    p{
        color:${secondarycolor};
        
        font-size:22px;
        z-index: 2;
        position:absolute;
        left:170px;
        top:155px;
    }

    .whatsApp{
        color:${secondarycolor};
        font-family: 'Salsa';
        font-size:30px;
        z-index: 2;
        position:absolute;
        left:190px;
        top:235px;
    }
`;