import gql from 'graphql-tag';

export const GET_PLAY_GAME = gql`
	mutation playGame($input: GamePlayGameInput!) {
		game {
			playgame(input: $input) {
				success
				code
				message
				data {
					url
				}
			}
		}
	}
`;

export const GET_BALANCE_INFO = gql`
	query getBalanceInfo($input: BalanceIntoInput!) {
		game {
			balanceInfo(input: $input) {
				platformCode
				playerName
				balance
				exchangeRate
				enableFloat
				pendingStatus
				depositPending
				withdrawPending
			}
		}
	}
`;

export const TRANSFER = gql`
	mutation transfer($input: GameTransferInput!) {
		game {
			transfer(input: $input) {
				success
				code
				message
				data {
					amount
					transactionNumber
				}
			}
		}
	}
`;
