import { format } from 'date-fns';
import React from 'react';
import styled, { css } from 'styled-components';
import { theme, withProp } from 'styled-tools';
import { Amount, Income } from '../../components/common/NumberDisplay';
import Openable from '../../components/common/Openable';
import {
	Col,
	Row,
	TableBodyRow,
	TableBodyWrapper,
	TableHeadRow,
	TableWrapper,
} from '../../components/common/table/baseTableStyles';
import { EOddsType } from '../../components/eu-content/NumberInput';
import Intl from '../common/Intl';
import { MobileHead, StatusText, TimeText as DetailTimeText, TypeLabel } from './membertablestyles';

// Store
import { TimeZone } from '../../store/TimeZoneContext';

// Utils
import { IOrder } from '../../graphql/schema';
import { diffColor } from '../../util/colorUtil';
import media from '../../util/mediaUtil';
import oddsUtil from '../../util/oddsUtil';
import util from '../../util/util';
import { EMarketType } from '../../util/utilModel';

// Components
const Total: React.SFC<{ type: 'back' | 'lay' | 'yes' | 'no' | 'commission' | 'net' }> = props => {
	const { type, children } = props;
	let labelLangKey: string;
	switch (type.toLowerCase()) {
		case 'back':
			labelLangKey = 'Back subtotal';
			break;
		case 'lay':
			labelLangKey = 'Lay Subtotal';
			break;
		case 'yes':
			labelLangKey = 'Yes Subtotal';
			break;
		case 'no':
			labelLangKey = 'No Subtotal';
			break;
		case 'commission':
			labelLangKey = 'Commission';
			break;
		case 'net':
			labelLangKey = 'Net Market Total';
			break;
		default:
			labelLangKey = 'Not Available';
			break;
	}
	return (
		<TotalWrapper type={type}>
			<TotalLabel>
				<Intl langKey={labelLangKey} />
			</TotalLabel>
			<TotalAmount>
				<Amount>{children}</Amount>
			</TotalAmount>
		</TotalWrapper>
	);
};

export interface ITotalInfo {
	back: number;
	lay: number;
	commission: number;
	net: number;
}

interface IProps {
	orders: IOrder[];
	totalInfo: ITotalInfo;
}

const DetailTable: React.SFC<IProps> = (props: IProps) => {
	const { orders = [], totalInfo } = props;
	if (!orders[0]) {
		return <div />;
	}

	const isFancy = util.getValue(orders, ['0', 'marketType'], '') === EMarketType.FANCY;
	return (
		<DetailWrapper>
			<DetailTableWrapper>
				<>
					<DetailTableHeadRow>
						<PlacedCol>
							<span>Placed</span>
						</PlacedCol>
						<SelectionCol>
							<span>Selection</span>
						</SelectionCol>
						<BetIdCol>
							<span>Bet ID</span>
						</BetIdCol>
						<TypeCol>
							<span>Type</span>
						</TypeCol>
						<OddsCol>
							<span>Odds</span>
						</OddsCol>
						<StakeCol>
							<span>Stake</span>
						</StakeCol>
						<ProfitLossCol>
							<span>P/L Exchange</span>
						</ProfitLossCol>
						<StatusCol>
							<span>Status</span>
						</StatusCol>
					</DetailTableHeadRow>
					<TableBodyWrapper>
						{orders.map(o => {
							const exchangeRate = util.getValue(o, ['exchangeRate'], 1);
							const profitLoss = util.numMultiply(util.getValue(o, ['winlossCredit'], 0), exchangeRate);
							const remarkStr: string = util.getValue(o, ['clientRemark'], '');
							const remarks = (remarkStr && remarkStr.split('||')) || [];
							return (
								<Openable key={o.orderNumber}>
									{({ isOpened, toggleOpen }) => {
										return (
											<DetailTableBodyRow>
												<PlacedCol>
													<MobileHead>
														<Intl langKey="DETAIL_TABLE@PLACED" />
													</MobileHead>
													<TimeText>
														<TimeZone format="HH:mm:ss">{o.createdAt}</TimeZone>
													</TimeText>
												</PlacedCol>
												<SelectionCol>
													<MobileHead>
														<Intl langKey="DETAIL_TABLE@SELECTION" />
													</MobileHead>
													{o.selectionName}
												</SelectionCol>
												<BetIdCol>
													<MobileHead>
														<Intl langKey="DETAIL_TABLE@TRANSACTION_NUMBER" />
													</MobileHead>
													<BetIdContentWrapper>
														{o.orderNumber}
														{remarks && remarks.length > 0 && (
															<RemarkButton onClick={toggleOpen}>Remark</RemarkButton>
														)}
													</BetIdContentWrapper>
												</BetIdCol>
												<TypeCol>
													<MobileHead>
														<Intl langKey="DETAIL_TABLE@TYPE" />
													</MobileHead>
													<TypeLabel>{o.side}</TypeLabel>
												</TypeCol>
												<OddsCol>
													<MobileHead>
														<Intl langKey="DETAIL_TABLE@ODDS" />
													</MobileHead>
													{/* {o.marketType === EMarketType.FANCY
														? oddsUtil.EUtoAM(o.price).toFixed(2)
														: o.price.toFixed(2)} */}
													{o.oddType === EOddsType.AM
														? oddsUtil.EUtoAM(o.price).toFixed(2)
														: o.price.toFixed(2)}
												</OddsCol>
												<StakeCol>
													<MobileHead>
														<Intl langKey="DETAIL_TABLE@STAKE" />
													</MobileHead>
													<Amount>{util.numMultiply(o.stake, exchangeRate)}</Amount>
												</StakeCol>
												<ProfitLossCol>
													<MobileHead>
														<Intl langKey="DETAIL_TABLE@P&L" />
													</MobileHead>
													{profitLoss === 0 ? '0' : <Income>{profitLoss}</Income>}
												</ProfitLossCol>
												<StatusCol>
													<MobileHead>
														<Intl langKey="DETAIL_TABLE@STATUS" />
													</MobileHead>
													<StatusText>
														{profitLoss === 0 ? o.status : profitLoss > 0 ? 'WON' : 'LOST'}
													</StatusText>
												</StatusCol>
												{isOpened && (
													<RemarkRow>
														<RemarkListWrapper heading="Remark">
															<RemarkListHeader>Remark</RemarkListHeader>
															<div>
																{Array.isArray(remarks) &&
																	remarks.map((remark, i) => {
																		const remarkInfo =
																			(remark && remark.split('|')) || [];

																		if (remarkInfo.length <= 0) {
																			return <span />;
																		}
																		const date =
																			remarkInfo[0] || new Date().toISOString();
																		return (
																			<RemarkListItem key={`${date}${i}`}>
																				<RemarkContent>
																					{(remarkInfo[1] &&
																						remarkInfo[1].split(':')[1]) ||
																						''}
																				</RemarkContent>
																				<RemarkTime>
																					<TimeZone format="yyyy/MM/dd">
																						{o.createdAt}
																					</TimeZone>
																					<TimeZone format="HH:mm:ss">
																						{o.createdAt}
																					</TimeZone>
																				</RemarkTime>
																			</RemarkListItem>
																		);
																	})}
															</div>
														</RemarkListWrapper>
													</RemarkRow>
												)}
											</DetailTableBodyRow>
										);
									}}
								</Openable>
							);
						})}

						<DetailTableFooterRow>
							<Operator isFancy={isFancy} totalInfo={totalInfo} />
						</DetailTableFooterRow>
					</TableBodyWrapper>
				</>
			</DetailTableWrapper>
		</DetailWrapper>
	);
};

export default DetailTable;

interface OperatorProps {
	isFancy: boolean;
	totalInfo: ITotalInfo;
}
export const Operator: React.SFC<OperatorProps> = props => {
	return (
		<>
			<Total type={props.isFancy ? 'yes' : 'back'}>{props.totalInfo.back.toFixed(2)}</Total>
			<TotalOperator>+</TotalOperator>
			<Total type={props.isFancy ? 'no' : 'lay'}>{props.totalInfo.lay.toFixed(2)}</Total>
			<TotalOperator>-</TotalOperator>
			<Total type="commission">{props.totalInfo.commission.toFixed(2)}</Total>
			<TotalOperator>=</TotalOperator>
			<Total type="net">{props.totalInfo.net.toFixed(2)}</Total>
		</>
	);
};

// Styled Components
const TimeText = styled(DetailTimeText)`
	font-size: 12px;
	color: #35495E;
`;

const RemarkButton = styled.button`
	font-size: 11px;
	background-color: transparent;
	padding: 0.25em 0.5em;
	border-radius: 2px;
	cursor: pointer;
	border: 1px solid #FF6B85;
    color: #FF6B85;
    text-decoration: underline;

	&:focus {
		outline: 0;
	}
`;

const RemarkTime = styled.div`
	/* flex-basis: 25%; */
	display: flex;
	justify-content: flex-end;

	> span:not(:last-child) {
		margin-right: 0.25em;
	}
`;

const RemarkContent = styled.div`
	/* flex-basis: 75%; */
	color: #000;
    font-weight: 500;
`;

const RemarkListHeader = styled.div`
	padding: 0.5em;
    background-color: #797898;
    color: white;
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;
`;

const RemarkListItem = styled.div`
	display: flex;
	align-items: flex-start;
	justify-content: center;
	padding: 0.5em;
	background: #edebeb;
	border-bottom-right-radius: 5px;
    border-bottom-left-radius: 5px;
    gap: 13px;
`;

const RemarkListWrapper: any = styled.div`
	width: 100%;
	border-radius: 2px;
`;

const RemarkRow = styled.div`
	width: 100%;
	flex-shrink: 0;
	display: flex;
	padding: 0.5em;
`;

const BetIdContentWrapper = styled.div`
	display: flex;
	font-size: 11px;
	align-items: center;
	flex-direction: column;
	gap: 2px;
`;

const PlacedCol = styled(Col)`
	flex-basis: 64px;
	flex-shrink: 0;
	align-items: center;

	${media.lg`
		justify-content: space-between;
		flex-basis: auto;
	`};
`;

const SelectionCol = styled(Col)`
	flex-basis: 16%;
	justify-content: center;
	align-items: center;

	${media.lg`
		justify-content: space-between;
		order: -1;
		background-color: pink;
	`};
`;

const BetIdCol = styled(Col)`
	flex-basis: 18%;
	align-items: center;
	justify-content: center;
	flex-grow: 1;

	/* Because the id could be very long, use width instead of flex basis to force the size */
	width: 18%;
	font-size: 11px;

	${media.lg`
		width: auto;
		justify-content: space-between;
	`};
`;

const TypeCol = styled(Col)`
	flex-basis: 64px;
	justify-content: center;
	align-items: center;
	flex-shrink: 0;

	${media.lg`
		justify-content: space-between;
		flex-basis: auto;
	`};
`;

const OddsCol = styled(Col)`
	flex-basis: 10%;
	justify-content: center;
	align-items: center;

	${media.lg`
		justify-content: space-between;
	`};
`;

const StakeCol = styled(Col)`
	flex-basis: 12%;
	justify-content: center;
	align-items: center;

	${media.lg`
		justify-content: space-between;
	`};
`;

const ProfitLossCol = styled(Col)`
	flex-basis: 12%;
	justify-content: center;
	align-items: center;

	${media.lg`
		justify-content: space-between;
	`};
`;

const StatusCol = styled(Col)`
	flex-basis: 12%;
	justify-content: center;
	align-items: center;

	${media.lg`
		justify-content: space-between;
	`};
`;

const DetailTableWrapper = styled(TableWrapper)`
	background-color: ${theme('palette.panel-background')};
	box-shadow: none;
	border: 1px solid ${withProp('theme.palette.panel-background', diffColor(0.1))};
`;

const DetailTableHeadRow = styled(TableHeadRow)`
	background: #797899;
	font-size: 12px;
	font-weight: 500;
    text-transform: capitalize;
`;

const DetailTableBodyRow = styled(TableBodyRow)`
	flex-wrap:wrap;
	font-size: 12px;
	color: #35495E;
	${media.lg`
		flex-direction: column;
	`};
	
`;

const DetailTableFooterRow = styled(Row)`
	justify-content: center;
	align-items: center;
	padding: 0.5em;
`;

const DetailWrapper = styled.div`
	width: 100%;
	position:relative
`;

const TotalLabel = styled.span`
	font-size: 10px;
	margin-bottom: 0.25em;
	font-style: italic;
`;
const TotalAmount = styled.span`
	font-size: 16px;
	font-weight: bold;

	${media.sm`
		font-size: 14px;
	`};
`;
const TotalWrapper: any = styled.div`
	display: flex;
	flex-direction: column;
	align-items: center;

	${(props: any) => {
		const { type } = props;
		switch (type) {
			case 'back':
			case 'yes':
				return css`
					color: rgb(21, 185, 255);
				`;
			case 'lay':
			case 'no':
				return css`
					color: rgb(236, 85, 119);
				`;
			default:
				return '';
		}
	}};
`;
const TotalOperator = styled.span`
	font-size: 16px;
	font-weight: bold;
	margin: 0 1em;

	${media.sm`
		font-size: 12px;
		margin: 0 0.5em;
	`};
`;
