import React from 'react'
import styled from 'styled-components';
import { theme } from 'styled-tools';
import CurrencyIcon from './svg/Currency';
import config from 'src/config/config';
import Intl from 'src/components/common/Intl';

export const toLocalNumber = (number:number|string) => {
    try {
        return Number(number).toLocaleString()  
    } catch (error) {
        return number;
    }  
}

const MyRanks = (props: any) => {
    const { rankData: { my_ranks = {} }, brand } = props;
    const {
        daily_race_rank = 0,
        daily_race_to = 0,
        tournament_rank = 0,
        tournament_to = 0,
        weekly_race_rank = 0,
        weekly_race_to = 0
    } = my_ranks;

    const _boxClassN = brand === 46 || brand === 47 ? 'u47' : '';

    return (
        <MyRanksWrapper>
            <BoxWrapper className={_boxClassN} >
                <Heading ><Intl langKey="dailyWagered" /></Heading>
                <Turnover>
                    {/* <CurrencyIcon /> */}
                    <span>₹{toLocalNumber(daily_race_to)}</span></Turnover>
                <Rank><Intl langKey="yourRank" /> : <span> {daily_race_rank}</span></Rank>
                <IconWrapper className='money'></IconWrapper>
            </BoxWrapper>

            <BoxWrapper className={_boxClassN}>
                <Heading><Intl langKey="weeklyWagered" /></Heading>
                <Turnover>
                    {/* <CurrencyIcon /> */}
                    <span>₹{toLocalNumber(weekly_race_to)}</span></Turnover>
                <Rank><Intl langKey="yourRank" /> : <span> {weekly_race_rank}</span></Rank>
                <IconWrapper className='trophy'> </IconWrapper>
            </BoxWrapper>

            <BoxWrapper className={_boxClassN}>
                <Heading><Intl langKey="tournamentWagered" /></Heading>
                <Turnover>
                    {/* <CurrencyIcon /> */}
                    <span>₹{toLocalNumber(tournament_to)}</span></Turnover>
                <Rank><Intl langKey="yourRank" /> : <span> {tournament_rank}</span></Rank>
                <IconWrapper className='award'> </IconWrapper>
            </BoxWrapper>
        </MyRanksWrapper>
    )
}

export default MyRanks;


//styled Component
const MyRanksWrapper = styled.div`
    display: flex;
`;

const BoxWrapper = styled.div`
    width: 323px;
    height: 319px;
    flex-shrink: 0;
    border-radius: 8px;
    border: ${theme('palette.lbBoxWrapperBorder')};
    background:${theme('palette.lbBoxWrapperBg')};
    padding: 20px 24px;
    margin-right: 12px;
    position: relative;
    &.u47 {
        background: url('${(props: any) => `${config.imageV3Host}/static/snk/brand/31/image/myrankbg.png`}') no-repeat 0 0;
        background-size: cover;
        border:none;
    }
    & > p {
        font-style: normal;
        line-height: 20.38px; 
        font-size: 14px;
        font-weight: 500;
        padding: 0;
        margin: 0;
        display: flex;
        align-items: center;
    }
`;

export const Heading = styled.p`
    color:${theme('palette.lbBoxHeadColor')};
`;

export const Turnover = styled.p`
    color: #FFF;
    font-size: 21px;
    font-weight: 700;
    & > span {
        font-weight: 700;
        /* padding-left: 5px; */
    }
`;

export const Rank = styled.p`
    color:${theme('palette.lbBoxRankColor')};
    padding-top: 2px!important;
    > span {
        color: #fff;
        padding-left: 3px;
        font-weight: 700;
    }
`;

const IconWrapper = styled.div`
    width: 190px;
    height: 177px;
    position: absolute;
    bottom: 42px;
    right: 30px;
    background-repeat: no-repeat;
    background-position: right bottom;
    &.money {
        background-image: url('${(props: any) => `${config.imageV3Host}/static/snk${props.theme.image.lbMoneyLg}`}');
    }
    &.trophy {
        background-image: url('${(props: any) => `${config.imageV3Host}/static/snk${props.theme.image.lbTrophyLg}`}');
    }
    &.award {
        background-image: url('${(props: any) => `${config.imageV3Host}/static/snk${props.theme.image.lbAwardLg}`}');
    }
`;