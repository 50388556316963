import React from 'react'

type Props = {}

function GreenTick({ }: Props) {
    return (
        <svg width="12" height="9" viewBox="0 0 12 9" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M1.7652 4.92014L4.71564 7.87053L10.5859 2" stroke="#08B933" stroke-width="1.5" stroke-linecap="square" />
        </svg>

    )
}

export default GreenTick;