import React, { useState, useEffect } from 'react'
import {
    SvgIcon,
    TableBodyWrapper,
    TableHeadRow,
    TableWrapper,
    TypeCol,
} from 'src/components/udb/commonStyle';
import { DescriptionCol } from 'src/pages/udb/betdetails/MyBetsTable';
import Intl from '../common/Intl';
import media from '../../util/mediaUtil';
import groupBy from 'lodash/groupBy';
import styled, { css, withTheme } from 'styled-components';
import { ifProp, theme } from 'styled-tools';
import { Col } from '../common/table/baseTableStyles';
import CircleUp from '../icon/CircleUp';
import EmptyRow from '../common/table/feature/EmptyRow';
import { TableHeads } from './TurnoverStatementTable';
import { withAppContext } from "src/store/initAppContext";

type StyleProps = {
    status?: string;
    isOpened?: boolean;
    displayState?: boolean;
}

export type exchangeTurnoverDatas = {
    TurnOver: number;
    event_id: number;
    event_name: string;
    markets: any;
    settle_date: string;
    settle_time: string;
    winloss: number;
}

type filteredExchangeDataProps = {
    filteredExchangeData: {
        exchange: exchangeTurnoverDatas[];
        exchange_turnover: string
    }
}

const TurnoverExhangeTable = ({ filteredExchangeData }: filteredExchangeDataProps) => {
    const [toggleState, setToggleState] = useState('');
    const [tableData, setTableData] = useState({});

    useEffect(() => {
        var resultData = groupBy(filteredExchangeData.exchange, "settle_date");
        setTableData(resultData)
    }, [filteredExchangeData.exchange])

    const toggleDetails = (eventID) => {
        setToggleState(preVState => preVState === eventID ? '' : eventID)
    }

    const dataWiseRecords = Object.keys(tableData);

    return (
        <TurnoverTableWrapper>
            <TableHeadRow style={{ backgroundColor: "#ffffff", boxShadow: "0px 0px 5px rgb(19 14 74 / 40%)" }}>
                <DescriptionCols>
                    <TableHeads><Intl langKey="TUNOVERStatement@EventName" /></TableHeads>
                </DescriptionCols>
                <TypeCol>
                    <TableHeads><Intl langKey="TUNOVERStatement@Winloss" /></TableHeads>
                </TypeCol>
                <TypeCol>
                    <TableHeads><Intl langKey="TUNOVERStatement@Turnover" /></TableHeads>
                </TypeCol>
                <TypeCol>
                    <TableHeads><Intl langKey="TUNOVERStatement@Date" /></TableHeads>
                </TypeCol>
                <TypeCol>
                    <TableHeads><Intl langKey="TUNOVERStatement@SettleTime" /></TableHeads>
                </TypeCol>
            </TableHeadRow>

            <TableBodyWrapper style={{ padding: "0px" }}>
                {dataWiseRecords.length > 0 && dataWiseRecords.map((date) => {
                    const records = tableData[date] || [];
                    return (
                        <>
                            <TableDateSec>{date}</TableDateSec>
                            {records.length && records.map((record) => (
                                <React.Fragment key={record.event_id}>
                                    <TableSubheadRow isOpened={toggleState === record.event_id}>
                                        <EventNameAndIcon isOpened={toggleState === record.event_id} onClick={() => toggleDetails(record.event_id)}>
                                            <span>{record.event_name}</span>
                                            {record.markets.length >= 0 &&
                                                <SvgIcon><CircleUp /></SvgIcon>
                                            }
                                        </EventNameAndIcon>

                                        <TypeCol>
                                            <WinLossText status={record.winloss > 0 ? "won" : "fail"}>{Number(record.winloss).toFixed(2)}</WinLossText>
                                        </TypeCol>
                                        <TypeCol>
                                            <TableSubheadTexts>{Number(record.TurnOver).toFixed(2)}</TableSubheadTexts>
                                        </TypeCol>
                                        <TypeCol>
                                            <TableSubheadTexts>{record.settle_date}</TableSubheadTexts>
                                        </TypeCol>
                                        <TypeCol>
                                            <TableSubheadTexts>{record.settle_time}</TableSubheadTexts>
                                        </TypeCol>
                                    </TableSubheadRow>

                                    <TableDetailsSec displayState={toggleState === record.event_id}>
                                        <TableDetailsSecHead>
                                            <SelectionCol>
                                                <span><Intl langKey="TUNOVERStatement@Market" /></span>
                                            </SelectionCol>
                                            <BetIdCol>
                                                <span><Intl langKey="TUNOVERStatement@Winloss" /></span>
                                            </BetIdCol>
                                            <PlacedCol>
                                                <span><Intl langKey="TUNOVERStatement@Turnover" /></span>
                                            </PlacedCol>
                                        </TableDetailsSecHead>
                                        <TableDetailsSecBody>
                                            {record.markets.length > 0 &&
                                                record.markets.map((market) => (
                                                    <TableDetailsSecBodyRow>
                                                        <SelectionCol>
                                                            <TableSubheadTexts style={{ fontWeight: 600 }}>{market.market_name}</TableSubheadTexts>
                                                        </SelectionCol>
                                                        <BetIdCol>
                                                            <TableDetailsSecBodyWinLoss status={market.winloss > 0 ? "won" : "fail"}>{Number(market.winloss).toFixed(2)}</TableDetailsSecBodyWinLoss>
                                                        </BetIdCol>
                                                        <PlacedCol>
                                                            <TableSubheadTexts style={{ fontWeight: 700 }}>{Number(market.TurnOver).toFixed(2)}</TableSubheadTexts>
                                                        </PlacedCol>
                                                    </TableDetailsSecBodyRow>
                                                ))}
                                        </TableDetailsSecBody>
                                    </TableDetailsSec>
                                </React.Fragment>
                            ))}
                        </>
                    )
                })}
            </TableBodyWrapper>
            {!dataWiseRecords.length && <EmptyRow />}
        </TurnoverTableWrapper>
    )
}

export default withTheme(withAppContext(TurnoverExhangeTable))

// Styled components`
export const MarketColContentWrapper = styled.div`
	display: flex;
	flex-direction: column;
	align-items: flex-start;
	color: ${theme('palette.betDetailsTableTextColor')};
    font-size: 12px;

	> div {      
		justify-content: flex-start;
    	width: 170px;
		font-size: 12px;
		text-align: left;
		font-weight: 600;
	}
`;
export const TableSubheadRow = styled.div<Pick<StyleProps, "isOpened">>`
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
	font-weight: bold;
	text-transform: uppercase;
	color: #524976;
	margin: 0px;
	font-size: 13px;
	border-radius: 3px;
    padding: 5px 5px;

    ${ifProp(
    /* Default is circle-up-chevron */
    'isOpened',
    css`
			background-color: #A6C2F940;
		`,
    css`
		 	background: #ffffff;
		`,
)};
`;

const TurnoverTableWrapper = styled(TableWrapper)`
    margin: 10px 15px 0px 0px;
    background-color: transparent !important;
    border: none;
`
const EventNameAndIcon = styled.div<Pick<StyleProps, "isOpened">>`
    display: flex;
	align-items: center;
	cursor: pointer;
    flex-basis: 32%;
    max-width: 100%;
    width: 170px;
    justify-content: space-between;

    & span {
        font-size: 12px;
        font-weight: bold;
        color:#505050;
        text-align: left;
        padding-left: 5px;
    }
	* {
		pointer-events: none;
	}
	> ${SvgIcon} {
		transition: transform 0.25s ease;
		will-change: transform;
		margin-left: 1em;
		opacity: 0.65;
		font-size: 10px;
        border-radius: 50px;
	}
    
    ${ifProp(
    /* Default is circle-up-chevron */
    'isOpened',
    css`
			 svg {
				transform: rotate(180deg) !important;
			}
            max-width: 210px;
            width: 170px;
            justify-content: space-between;
		`,
    css`
		  svg {
				transform: rotate(0deg) !important;
			}
            max-width: 210px;
            width: 170px;
            justify-content: space-between;

		`,
)};
`
const TableSubheadTexts = styled.span`
   font-size: 12px;
   color: #505050;
`
const WinLossText = styled.div<Pick<StyleProps, "status">>`
      font-size: 11px;
      color: ${props => props.status === "won" ? "#ffffff" : props.status === "fail" ? "#ffffff" : "#505050"};
      background-color: ${props => props.status === "won" ? "#03BC58" : "#EC476B"};
      padding: 5px;
`
const TableDetailsSec = styled.div<Pick<StyleProps, "displayState">>`
    display: ${props => props.displayState ? "block" : "none"};;
    width: 100%;
    height: auto;
    margin: 0px 0px 10px 0px;
`
const TableDetailsSecHead = styled.div`
    background: #000000;
	font-size: 12px;
	font-weight: 500;
    text-transform: capitalize;
    padding: 10px;
    border-radius:10px 10px 0px 0px;
    width: 100%;
    display: flex;
    justify-content: space-between;
`
const TableDetailsSecBody = styled.div`
   width: 100%;
   height: auto;
   box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
   border-radius: 0px 0px 10px 10px;
`
const TableDetailsSecBodyRow = styled.div`
        display:flex;
        justify-content: space-between;
        padding: 10px 5px;
        /* border-radius: 0px 0px 10px 10px; */
        background-color: #FFFFFF;


        :nth-child(even) {
            background: #F0EFF3;
         }
`
const PlacedCol = styled(Col)`
	flex-basis: 25%;
	flex-shrink: 0;
	align-items: center;
    color: white;
    justify-content: flex-end;

	${media.lg`
		justify-content: space-between;
		flex-basis: auto;
	`};
`;

const SelectionCol = styled(Col)`
	flex-basis: 25%;
	justify-content: flex-start;
	align-items: center;
    color: white;

	${media.lg`
		justify-content: space-between;
		order: -1;
		background-color: pink;
	`};
`;

const BetIdCol = styled(Col)`
	flex-basis: 25%;
	align-items: center;
	justify-content: center;
	flex-grow: 1;
    color: white;

	/* Because the id could be very long, use width instead of flex basis to force the size */
	width: 7%;
	font-size: 11px;

	${media.lg`
		width: auto;
		justify-content: space-between;
	`};
`;

const DescriptionCols = styled(DescriptionCol)`
     max-width: 210px;
     width: 170px;
`
const TableDetailsSecBodyWinLoss = styled.span<Pick<StyleProps, "status">>`
    color: ${props => props.status === "won" ? "#03BC58" : "#EC476B"};
    font-size: 12px;
`
const TableDateSec = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    font-weight: bold;
    text-transform: uppercase;
    justify-content: center;
    color: #625e5e;
    background: linear-gradient(90deg,rgb(83 112 157 / 0%) 0%,rgb(209 213 215) 57.17%,rgb(130 158 201 / 0%) 100%);
    padding: 5px;
    font-size: 13px;
    border-radius: 3px;
    margin: 10px 0px 5px 0px;
`;