import React, { Component } from "react";
import styled from "styled-components";
// images
// import SliderArrow from "../../images/chevron-down.png";
// import LOGOUT from "../../images/log-out.png"
import cookieUtil from 'src/util/cookieUtil';
import util from 'src/util/util';
import { ECookieName } from 'src/util/utilModel';
import { withAppContext } from "src/store/initAppContext";
import config from "src/config/config";
import { theme } from "styled-tools";
import TelLangIcon from "../icon/TelLangIcon";
// import DownArrow from "src/images/svg/chevron-down.svg";

class Language extends Component<any, any> {
    constructor(props) {
        super(props);
        this.state = {
          selectedLang : false
        }
        this.languageChangeToEng = this.languageChangeToEng.bind(this)
        this.languageChangeToHin = this.languageChangeToHin.bind(this)
        this.languageChangeToTel = this.languageChangeToTel.bind(this)
    } 

   

    handleLogout = (e) => {
      cookieUtil.remove(ECookieName.COOKIE_TOKEN);
      window.location.href = '/';
  }

  handlecasinostatement = (e) => {
    window.location.href = '/CasinoStatement';
  }

  handleaccountstatement = (e) => {
    window.location.href = '/AccountStatement';
  }

  // languageChange = () => {
  //   let langArray = ['hindi-ind', 'en-us', 'tamil-ind'];
  //   let selectedIndex = cookieUtil.get(ECookieName.COOKIE_LANG) === 'hindi-ind' ? false : true
  //   let lang = langArray[selectedIndex ? 0 : 1]; 
  //   cookieUtil.remove(ECookieName.COOKIE_LANG);
  //   cookieUtil.set(ECookieName.COOKIE_LANG, langArray[selectedIndex ? 0 : 1], 30);
  //   const {setState} = this.props.app || {};
  //   if(typeof setState === 'function') {
  //       let _reqData = {
  //             lang 
  //         }
  //         setState(_reqData)
  //     }
  //   this.setState({selectedLang: selectedIndex }, () => {
  //     window.location.reload();
  //   })
    
  //   }

  languageChangeToEng = () => {
    // let langArray = ['en-us', 'hindi-ind', 'tamil-ind'];
    let selectedIndex = cookieUtil.get(ECookieName.COOKIE_LANG) === 'en-us' ? false : true
    // let lang = langArray[selectedIndex ? 0 : 1]; 
    cookieUtil.remove(ECookieName.COOKIE_LANG);
    cookieUtil.set(ECookieName.COOKIE_LANG, 'en-us', 30);
    // const {setState} = this.props.app || {};
    // if(typeof setState === 'function') {
    //     let _reqData = {
    //         lang 
    //     }
    //     setState(_reqData)
    // }
   
    this.setState({selectedLang: selectedIndex });
    window.location.reload();
 
}

languageChangeToHin = () => {
  // let langArray = ['en-us', 'hindi-ind', 'tamil-ind'];
  let selectedIndex = cookieUtil.get(ECookieName.COOKIE_LANG) === 'hindi-ind' ? false : true
  // let lang = langArray[selectedIndex ? 0 : 1]; 
  cookieUtil.remove(ECookieName.COOKIE_LANG);
  cookieUtil.set(ECookieName.COOKIE_LANG,'hindi-ind', 30);
  // const {setState} = this.props.app || {};
  // if(typeof setState === 'function') {
  //     let _reqData = {
  //         lang 
  //     }
  //     setState(_reqData)
  // }
  this.setState({selectedLang: selectedIndex });
  window.location.reload();

}

languageChangeToTel = () => {
  // let langArray = ['en-us', 'hindi-ind', 'tamil-ind'];
  let selectedIndex = cookieUtil.get(ECookieName.COOKIE_LANG) === 'tel' ? false : true
  // let lang = langArray[selectedIndex ? 0 : 1]; 
  cookieUtil.remove(ECookieName.COOKIE_LANG);
  cookieUtil.set(ECookieName.COOKIE_LANG,'tel', 30);
  // const {setState} = this.props.app || {};
  // if(typeof setState === 'function') {
  //     let _reqData = {
  //         lang 
  //     }
  //     setState(_reqData)
  // }
  this.setState({selectedLang: selectedIndex });
  window.location.reload();

}


  render = () => {
    return (
      <MainWrapper>
        
        <DropDownLi theme={this.props.app.theme}>
          {/* <img className="first_flag" src={(cookieUtil.get(ECookieName.COOKIE_LANG) === '') ? USFLAG : (cookieUtil.get(ECookieName.COOKIE_LANG) === 'hindi-ind') ? INDFLAG : USFLAG}/>      */}
          {/* {
               (()=>
              {
              switch(cookieUtil.get(ECookieName.COOKIE_LANG))
              {
                case 'en-us':
                  return <span><img className="first_flag" src={`${config.imageS3Host}${USFLAG}`}/></span>;
                case 'hindi-ind':
                  return <span><img className="second_flag" src={`${config.imageS3Host}${INDFLAG}`} /></span>;
                case null :
                  return <span><img className="first_flag" src={`${config.imageS3Host}${USFLAG}`} /></span>;
                default :
                  return <span><img className="first_flag" src={`${config.imageS3Host}${USFLAG}`} /></span>;    
              }
            })()
          } */}
          {/* <Dropbtn theme={this.props.app.theme}>
            {
              (cookieUtil.get(ECookieName.COOKIE_LANG) === 'en-us') ? 'EN' : (cookieUtil.get(ECookieName.COOKIE_LANG) === 'hindi-ind') ? 'HIN' : 'EN'
            }
          </Dropbtn> */}
          {/* <DropDownContent theme={this.props.app.theme}>
            <DropDwnColumn onClick={() => this.languageChangeToEng()} theme={this.props.app.theme}>
              <img className="dropdwn_flag" src={`${config.imageS3Host}${USFLAG}`}/>
              <SubA theme={this.props.app.theme}>EN</SubA>
            </DropDwnColumn>
            <DropDwnColumn onClick={() => this.languageChangeToHin()} theme={this.props.app.theme}>
              <img className="dropdwn_flag" src={`${config.imageS3Host}${INDFLAG}`}/>
              <SubA theme={this.props.app.theme}>HIN</SubA>
            </DropDwnColumn>
          </DropDownContent> */}
          {/* <img className="drop_down" src={DownArrow}/> */}
          <Button onClick={() => this.languageChangeToEng()} theme={this.props.app.theme}>
              <img className="dropdwn_flag" src={`${config.imageV3Host}/static/media/englishFlag.png`} alt="english" />
              <SubA>English</SubA>
          </Button>
          <Button onClick={() => this.languageChangeToHin()} theme={this.props.app.theme}>
            <img className="dropdwn_flag" src={`${config.imageV3Host}/static/media/indiaFlag.png`} alt="Hindi" />
            <SubA>Hindi</SubA>
          </Button>
          <Button onClick={() => this.languageChangeToTel()} theme={this.props.app.theme}>
            {/* <TelLangIcon /> */}
            <img className="dropdwn_flag" src={`${config.imageV3Host}/static/media/telugu_lang_icon.png`} alt="Telugu" />
            <SubA>Telugu</SubA>
          </Button>
        </DropDownLi>
        

      </MainWrapper>
    );
  };
}

export default  withAppContext(Language);

const MainWrapper = styled.ul`
  list-style-type: none;
  margin: 0;
  padding: 0;
  // overflow: hidden;
//   background-color: #333;
  height: 100%;
  display: flex;
  align-items: center;
  /* margin-left: 14px; */
  /* background: rgba(90, 89, 117, 1); */
  // clip-path: polygon(22% 0%, 100% 0%, 100% 100%, 0% 100%);
  position: relative;

  /* :before {
    content: "";
    width: 27px;
    height: 100%;
    position: absolute;
    left: -14px;
    background-color: #5a5975;
    transform: skew(-20deg);
    z-index: 2;
    } */
`;

const StyledLi = styled.li`
  float: left;
`;

const Dropbtn = styled.div`
    display : flex;
    justify-content: center;
    align-items: center;
    // color: ${(props) => (props.theme == 'Dark' ? '#FFF' : '#000')};
    color: #fff;
    font-weight: 500;
    text-align: center;
    padding: 14px 0px;
    text-decoration: none;
    width:auto;
    line-height: 2;
    padding-left: 3px;
`;

const DropDownContent = styled.div`
  /* display: block; */
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: row;
  /* position: absolute; */
  // background: ${(props) => (props.theme == 'Dark' ? 'linear-gradient(176deg, rgb(4 41 92), rgb(0 13 46))' : '#E5E5E5')};
  // max-width: 150px;
  /* background: #595874; */
  /* width: 117px; */
  /* box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2); */
  z-index: 2;
  overflow: visible;
  /* top: 69px; */
  z-index: 999999999;

  .dropdwn_flag {
      width:25px;
      height:25px;
  }

  // a{
  //     font-size: small;
  // }
`;

const DropDwnColumn = styled.div `
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    padding: 2px;
    /* border-bottom: 1px solid #c5c3c3; */
    border-radius: 5px;
    cursor: pointer;
    margin-right: 3px;

    &:hover {
      /* background-color: ${(props) => (props.theme == 'Dark' ? 'linear-gradient(176deg, rgb(4 41 92), rgb(0 13 46))' : '#f1f1f1')}; */
      background: #D9D9D9;
      -webkit-animation: none;
      -webkit-appearance: none;
      -webkit-transform: none;
    }

    :hover a {
      color: #000;
    }
`;

const Button = styled.button`
    width: 50px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    border-radius: 5px;
    cursor: pointer;
    padding: 2px;
    background: transparent;
    border: none;
    gap: 3px;

    &:hover {
      /* background-color: ${(props) => (props.theme == 'Dark' ? 'linear-gradient(176deg, rgb(4 41 92), rgb(0 13 46))' : '#f1f1f1')}; */
      background: #D9D9D9;
    }

    :hover a {
      color: #000;
    }

    .dropdwn_flag {
      width:25px;
      height:25px;
    }
    > svg {
      width: 25px;
      height: 25px;
    }
`;

const DropDownLi = styled(StyledLi)`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: row;
  font-size: 14px;
  /* width: 90px; */
  height: 100%;
  // width: 210px;
  position: relative;
  z-index: 3;


  &:hover {
    // background-color: ${(props) => (props.theme == 'Dark' ? 'linear-gradient(176deg, rgb(4 41 92), rgb(0 13 46))' : '#f9f9f9')};
    background-color: transparent;
  }

  &:hover ${DropDownContent} {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    right: 0px;
  }

  .first_flag {
    width:25px;
    height:25px;
  }

  .second_flag {
    width:25px;
    height:25px;
  }

  .drop_down {
    width: 15px;
    height: 15px;
    margin-left: 5px;
  }
`;

const StyledA = styled.a`
  display: inline-block;
  color: white;
  text-align: center;
  padding: 14px 16px;
  text-decoration: none;
  &:hover {
    background-color: red;
  }
`;

const SubA = styled.a`
  font-weight: bold;
  text-decoration: none;
  display: block;
  font-size: 12px;
  color: ${theme('palette.headerDropDownLanguageTextColor')};
  /* text-align: left; */
  /* padding-left: 5px; */

  // &:hover {
  //   // color: ${(props) => (props.theme == 'Dark' ? '#CB9640' : null)};
  //   color: ${(props) => (props.theme == 'Dark' ? '#CB9640' : '#000')};
  // }
`;