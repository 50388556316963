import React, { PureComponent, useCallback, useEffect, useState } from "react";
import { color } from 'src/sass-files/variable';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { GET_AGENTREPORT } from 'src/graphql/events';
import util from "src/util/util";
import styled, { withTheme } from 'styled-components';
import { Col, TableHeadRow, TableWrapper } from "src/components/common/table/baseTableStyles";
// import Intl from '../../components/common'
import { TimeZone, TimeZoneConsumer } from "src/store/TimeZoneContext";
import MemberSidebar from './member-sidebar';
import CasinoTittle from 'src/images/casino_statement.png';
import DatePickerIcon from 'src/images/calendar.png';
import Spinner from "src/components/common/Spinner";
import Pagination from "src/components/common/Pagination";
import { IAppContextStore, withAppContext } from '../../store/initAppContext';
import EmptyRow from "src/components/common/table/feature/EmptyRow"; 
import {motion} from 'framer-motion';
import Bg from '../../images/iMAGE1_iexch.png' 
import moment from "moment";
import { addDays, getTime } from "date-fns";
import Intl from '../../components/common/Intl';
import { theme, withProp } from 'styled-tools';
import { PageTitleName } from "src/components/common/Layout";
import { FilterSection } from "src/components/member/filterStyles";
import { MemberTableBodyRow } from "src/components/member/membertablestyles";
import { Query } from "@apollo/react-components";
import config from 'src/config/config';
const { secondarycolor } = color;

interface props {
  memberId: number;
  type: string;
}

const STATUS_CODE = [
	{
		id: 0,
		value: 'Bet'
	},
	{
		id: 1,
		value: 'Confirmed'
	},
	{
		id: 2,
		value: 'Settled'
	},
	{
		id: 3,
		value: 'Cancel'
	},
	{
		id: 4,
		value: 'Delete'
	}
]




interface IProps {
	app: IAppContextStore;
  targetMember: { target: { memberId: number; type: string }; persona: string };
  theme:any
}

interface IState {
	startAt: Date;
	endAt: Date;
	page: number;
}

class CasinoStatement extends PureComponent<IProps, IState> {
	constructor(props) {
		super(props)
		this.state = {
			startAt: new Date(new Date().setHours(0, 0, 0)),
			endAt: new Date(new Date().setHours(23, 59, 59)),
			page: 1
		}
	}

	handleDayChange = (from: Date, to: Date) => {
		this.setState({
			startAt: from,
			endAt: to,
		});
	};

  handleDateChangeRaw = (e) => {
         e.preventDefault();
    }

	setStatus = (code) => {
		let data = STATUS_CODE.filter((status) => {
			if(status.id === code) {
				return status.value
			}else {
				return ''
			}
		})
		return data[0].value
		}

		handleChangePageIndex = (index: number) => {
			this.setState({
				page: index,
			});
      window.scrollTo(0,0)
		};	

	render() {
		const { startAt, endAt, page } = this.state;
		const {
			app: { memberId, type },
		} = this.props;

    var date = new Date();
    const minDate = date.setDate((new Date()).getDate() - 90);
		return (
			// iOS Safari iFrame 無法調整寬度，因此加上外層來處理橫向滾動
			<>
      <StatementWrapper>
        <MemberSidebar/>
        <CasinoStatementWrapper theme={this.props.app.theme} customStyle={this.props.theme.image}>
				<Tittle theme={this.props.app.theme} customStyle={this.props.theme.palette}>
             <TittleIcon></TittleIcon>
              
             <Intl langKey="MEMBER@CASINO_STATEMENT" />           
        </Tittle>
				<DatePickerWrapper>
               <Tittle theme={this.props.app.theme} customStyle={this.props.theme.palette}>
                 <CalenderIcon></CalenderIcon>
                 <Intl langKey= "DATE_PICKER@TITLE" />
               </Tittle>
               <DateSelector>
                 <BlockPick>
                 <Intl langKey= "DATE_PICKER@FROM" /> <DatePicker selected={startAt} onChange={(date: Date | null) => this.setState({startAt: date})} onChangeRaw={this.handleDateChangeRaw} minDate={minDate} maxDate={new Date()}/>
                 </BlockPick>
                 <BlockPick>
                 <Intl langKey= "DATE_PICKER@TO" /> <DatePicker selected={endAt} onChange={(date: Date | null) => this.setState({endAt: date})} onChangeRaw={this.handleDateChangeRaw} minDate={minDate} maxDate={new Date()}/>
                 </BlockPick>

                 {/* <SearchButtonWrapper>
                 Search
               </SearchButtonWrapper> */}
               </DateSelector>
             </DatePickerWrapper>
				<TimeZoneConsumer>
					{({ currentTimeZone }) => {
						return (
							<Query
								query={GET_AGENTREPORT}
								ssr={false}
								variables={{
									input: {
										startDate: new Date(util.fixedFrom(startAt).getTime() - currentTimeZone),
										endDate: new Date(util.fixedTo(endAt).getTime() - currentTimeZone),
										count: 20,
										page: page || 1,
										venderPlayer: "",
										platformId: null,
										// gameCode: null,
										productTypeId: 2,
										status: -1,
										target: util.getTargetString({ memberId: memberId, type: type }),
										persona: "",
										// providerId: null
									},
								}}
							>
								{({ data, loading }): any => {
									const casinoState: any = util.getValue(data, ['agentReport', 'thirdPartyOrder'], []);
									if (loading) {
										return <Spinner mode="client" />;
									}
									return (
										<>
								<StatementTableWrapper>
             <HeadingRow>
               <TypeCol>
                 <span> <Intl langKey="CASINO_STATEMENT_TABLE@PROVIDER" /></span>
               </TypeCol>
             <TypeCol>
                 <span><Intl langKey="CASINO_STATEMENT_TABLE@REFID" /></span>
               </TypeCol>
               <TypeCol>
                 <span><Intl langKey="CASINO_STATEMENT_TABLE@STAKE" /></span>
               </TypeCol>
               <ProfitLossCol>
                 <span><Intl langKey="CASINO_STATEMENT_TABLE@WINLOSS" /></span>
               </ProfitLossCol>
               <TypeCol>
                 <span><Intl langKey="CASINO_STATEMENT_TABLE@COMMISION" /></span>
               </TypeCol>
               <TypeCol>
                 <span><Intl langKey="CASINO_STATEMENT_TABLE@GAME" /></span>
               </TypeCol>
               <TypeCol>
                 <span><Intl langKey="CASINO_STATEMENT_TABLE@STATUS" /></span>
               </TypeCol>
               <TimeCol>
                 <span><Intl langKey="CASINO_STATEMENT_TABLE@PLACEDTIME" /></span>
               </TimeCol>
               <TimeCol>
                 <span><Intl langKey="CASINO_STATEMENT_TABLE@SETTLEDTIME" /></span>
               </TimeCol>
             </HeadingRow>


             <TableBodyWrapper>
             { casinoState.orderInfo?.length > 0 ? (
           casinoState.orderInfo?.map((game, index) => { 
            //  debugger
                  return (
                    <BodyRow>
                      <TypeCol>
                        {game.provider}
                      </TypeCol>
                      <TypeCol>
                        {game.wagerId}
                      </TypeCol>
                      <TypeCol>
                        {game.stake.toFixed(2)}
                      </TypeCol>
                      <ProfitLossCol>
                        {game.winloss.toFixed(2)}
                      </ProfitLossCol>
                      <TypeCol>
                        {game.commission === null ? '0.00' : game.commission.toFixed(2)}
                      </TypeCol>
                      <TypeCol>
                        {game.gameName}
                      </TypeCol>
                      <TypeCol>
                        {
                        this.setStatus(game.status)
                        }
                      </TypeCol>
                      <TimeCol>
                        <TimeZone format="yyyy-MM-dd HH:mm:ss">
                          {game.vendorBetTime}
                        </TimeZone>
                      </TimeCol>
                      <TimeCol className="timelast">
                        <TimeZone format="yyyy-MM-dd HH:mm:ss">
                          {game.vendorSettleTime === null ? 'NA' : game.vendorSettleTime}
                        </TimeZone>
                      </TimeCol>
                    </BodyRow>
                  )
                })
              ): (
               <EmptyRow />
              )
            }
            </TableBodyWrapper>
            </StatementTableWrapper>
											<DownWrapper>
											<Pagination
												numberPerPage={20}
												totalLength={casinoState.totalData || 0}
												onClick={this.handleChangePageIndex}
												currentIndex={page}
											/>
											</DownWrapper>
										</>
									)
								}}
							</Query>
						);
					}}
				</TimeZoneConsumer>
        </CasinoStatementWrapper>
        </StatementWrapper>	
			</>
		);
	}
}
export default withTheme(withAppContext(CasinoStatement));

const BodyRow = styled.div`
  width:100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-size: 12px;
  text-transform: capitalize;
  background: ${secondarycolor};
  color: #505050;
  border-bottom: none;
  margin-bottom: 5px;
`;

const StatementTableWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  // background: #FDF2E5;
  background:#f1f4f8;
  border-radius: 5px;
  padding: 10px;
`;

export const SearchButtonWrapper = styled.button`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  background: linear-gradient(180deg, #D0B99F 0%, #DFC7A3 100%);
  border-radius: 5px;
  padding: 15px 30px;
  color: ${secondarycolor};
  border: none;

  :hover {
    background: linear-gradient(180deg, #DFC7A3 0%, #D0B99F 100%);
  }
`;

const BlockPick = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  background: ${theme('palette.new-whitelable-member-background')};
  border-radius: 5px;
  padding: 0px 10px;
  margin-right: 10px;
  text-transform: capitalize;
  color: ${theme('palette.secondary-background-color-new')};
  font-size: 12px;

  input {
    border: none;
    background: none;
    display: flex;
    height: 47px;
    text-align: center;
  }

  .react-datepicker__day--outside-month {
  color: transparent !important;
  pointer-events: none;
}
`;

const CalenderIcon = styled.div`
  width: 24px;
  height: 24px;
  display: flex;
  flex-direction: row;
  align-items: center;
  background-image: url(${DatePickerIcon});
  background-size: auto;
  background-repeat: no-repeat;
  margin-right: 10px;
`;

const DatePickerWrapper = styled.div`
  /* width: 50%; */
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  position: relative;
  left: 50%;
  bottom: 3%;
`;

const IncomeLloss = styled.div`
  width: 63px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: #FFD5DA;
  border-radius: 5px;
  padding: 13px 10px;
  font-weight: 500;
  font-size: 12px;
  color: #D64052;
  margin-left: 10px;
`;

const TotalLoss = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  background: #EEF7FF;
  border-radius: 3px;
  font-size: 13px;
  text-transform: capitalize;
  color: #013766;
  padding: 10px;
  margin-right: 20px;
`;

const Income = styled.div`
  width: 63px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: rgba(56, 188, 125, 0.28);
  border-radius: 5px;
  padding: 13px 10px;
  color: #269D64;
  font-weight: 500;
  font-size: 12px;
  margin-left: 10px;
`;

const TotalProfit = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  background: #EEF7FF;
  border-radius: 3px;
  font-size: 13px;
  text-transform: capitalize;
  color: #013766;
  padding: 10px;
  margin-right: 20px;
`;

const ProfitLossSection = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`;

const TittleIcon = styled.div`
  width: 20px;
  height: 20px;
  display: flex;
  flex-direction: row;
  align-items: center;
  background-image: url(${CasinoTittle});
  background-size: auto;
  background-repeat: no-repeat;
  margin-right: 5px;
`;

const Tittle:any = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  color: ${(props:any) => (props.theme === 'Dark' ? props.customStyle['new_whitelable_dark_font_color'] : props.customStyle['secondary-background-color-new'])};  
  font-weight: 500;
  font-size: 15px;
  line-height: 18px;
  text-transform: capitalize;
  padding-bottom: 10px;
`;

const AccountContent = styled.div`
  // width: 50%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
`;

const HeaderBlock = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  padding-bottom: 10px;
`;

const CasinoStatementWrapper:any = styled.div`
  width: calc(100% - 250px);
  display: flex;
  padding: 10px;
  background: #fff;
  align-items: flex-start;
  justify-content: flex-start;
  flex-direction: column;
  padding-top: 20px;
  background-image: url('${(props:any) => (props.theme === 'Dark' ? `${config.imageS3Host}/static/snk/${props.customStyle['new_whitelable_dark_background']}` : `${config.imageS3Host}/static/snk/${props.customStyle['new_whitelable_light_background']}`)}');
  background-size: cover;
  background-position: 100%;
`;

const StatementWrapper = styled.div`
  width: 100%;
  display: flex;
  
`;

const DateSelector = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  position:relative;
  
  font-weight: bold;
`;

const Statement = styled.div`
  position: relative; 
  top:200px; 
  width:100%;
  
  font-size:14px;
`;

const TypeCol = styled(Col)`
	flex-basis: 10%;
	justify-content: center;
  align-items: flex-start;
  padding: 12px 5px;

`;

const ProfitLossCol = styled(Col)`
	flex-basis: 16%;
	justify-content: center;
`;

const TimeCol = styled(Col)`
  flex-basis: 10%;
  padding: 12px 5px;
`;

const TableBodyWrapper = styled.div`
  width:100%;
  display: flex;
  flex-direction: column;
  overflow-y: overlay;
  // height: 100vh;
`;

const HeadingRow = styled.div`
    width:100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    font-size: 14px;
    font-weight: bold;
    text-transform: capitalize;
    letter-spacing: 0.66px;
    // background: #D0B99F;
    background:${theme('palette.secondary-background-color-new')};
    color: ${secondarycolor};
    border-bottom: none;
    box-shadow: rgb(0 0 0 / 10%) 0px 3px 5px;
`;

const DownWrapper = styled.div`
	margin-top: 15px;
	width: 100%;
	display: flex;
	margin-bottom: 10px;
`;

