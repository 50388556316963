import React from 'react';
import styled from 'styled-components';
import { withProp } from 'styled-tools';

// Component
import Intl from '../../../common/Intl';

// Util
import { diffColor } from '../../../../util/colorUtil';

const EmptyRow: React.SFC = () => {
	return (
		<EmptyRowContent>
			<span><Intl langKey="TABLE@NO_CONTENT" /></span>
		</EmptyRowContent>
	);
};

export default EmptyRow;

export const shouldUseEmptyRow = (list: any[], ReactElement: JSX.Element) => {
	if (!Array.isArray(list) || list.length === 0) {
		return <EmptyRow />;
	} else {
		return ReactElement;
	}
};

// Styled Component
const EmptyRowContent = styled.div`
	font-size: 16px;
    color: #8b8b8b;
	padding: 1em;
	text-align: center;
	font-family: 'Inter';
`;
