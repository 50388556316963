import React, { useEffect } from 'react'
import { withAppContext } from 'src/store/initAppContext';
import styled, { css, withTheme } from 'styled-components';
import { LazyLoadImage } from "react-lazy-load-image-component";
import config from "src/config/config";
import PlaceholderImage from 'src/components/Placeholder';
import { theme } from 'styled-tools';
import FooterContainer from 'src/components/footer/footer';

function IPLJackpotPromoFun882k25(props: any) {
    const { app: {
        brandId
    }, theme } = props;

    useEffect(() => {
        window.scrollTo(0, 0)
    }, [])

    return (
        <>
            <InformationWrapper>
                <Container>
                    <Section $propsAdd>
                        <LazyLoadImage
                            src={`${config.imageV3Host}/static/cashsite/${theme.image.iPLJckpotDesktopTitleYolo}`}
                            alt="Promotion"
                            effect="none"
                            placeholder={<PlaceholderImage />}
                            className="secOneImg"
                            width="100%"
                        />
                    </Section>
                    <Section>
                        <p>The IPL 2025 season just got even more exciting!</p>
                        <p className='brandColor'>Stay on the Leaderboard every IPL week and secure your spot in the ultimate ₹1 Cr Jackpot Draw!</p>
                    </Section>
                    <Section className='relative'>
                        <h3 className='qualifyText'>How to Qualify?</h3>
                        <LazyLoadImage
                            src={`${config.imageV3Host}/static/cashsite/${theme.image.IPLJackPtHowtoQualifyImg}`}
                            alt="Promotion"
                            effect="none"
                            placeholder={<PlaceholderImage />}
                            className="secThreeImg"
                            width="100%"
                        />
                    </Section>
                    <Section>
                        <div className='textLine'>Are you ready to make this IPL season unforgettable? <br />
                        Start betting now and claim your chance to win BIG! in?</div>
                    </Section>
                </Container>
            </InformationWrapper>
            <FooterContainer />
        </>
    )
}

export default withTheme(withAppContext(IPLJackpotPromoFun882k25));

// Styled Component.
const Container = styled.div`
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 1rem;
    max-width: 1280px;
    margin: 0 auto;
`;

const InformationWrapper = styled.div`
    width: 100%;
    height: auto;
    padding: 1rem;
    margin: 0em;
    display: flex;
    flex-direction: column;
    background-color: #EBEBEB;

    p {
        font-size: 15px;
        color: #444;
        text-align: center;
        margin: 0;
    }

    .brandColor {
        color: ${theme('palette.IPLJackpotPromo2k25PromoBrandColor')};
        font-weight: 700;
        line-height: 25px; 
    }

    .brandXColor {
        display: inline-block;
        background: ${theme('palette.SmartSoftPriceDropPromoBrandXColor')};
        background-clip: text;
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
    }

    .secTwoImg {
        width: 100%;
        /* height: 810px; */
        max-width: 100%;
    }

`;

const Section = styled.div<{ $propsAdd?: boolean }>`
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background: #FFF;
    padding: 2rem;
    border-radius: 9.945px;

    ${(props) => props.$propsAdd && css`
        padding: 1rem 0;
    `}

    &.relative {
        position: relative;
    }

    .qualifyText {
        color: #06A6FF;
        font-size: 20px;
        text-align: center;
        text-transform: capitalize;
        margin-bottom: 2rem;
        font-family: 'Inter-Bold';
    }

    .secOneImg {
        width: 100%;
        max-width: 100%;
        display: flex
    }

    .secThreeImg {
        max-width: 100%;
        width: 872px;
        margin: 0 auto;
        display: block;
    }

    .textLine {
        max-width: 100%;
        display: inline-block;
        text-align: center;
        font-size: 20px;
        font-style: normal;
        font-weight: 700;
        color: #06A6FF;
        font-family: 'Inter-Bold';
    }
`;
