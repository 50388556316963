import { css, InterpolationValue } from 'styled-components';
import util from './util';

/*
    - Desktop-first
    - Based on flex box
*/

export type Size = 'xxl' | 'xl' | 'lg' | 'md' | 'sm' | 'xs';
export type Breakpoints = Record<Size, number>;
export type Gutters = Record<Size, number>;

export let breakpoints: Breakpoints = {
    xxl: 1600,
    xl: 1200,
    lg: 992,
    md: 768,
    sm: 576,
    xs: 320,
};

export let gutters: Gutters = {
    xxl: 16,
    xl: 16,
    lg: 12,
    md: 8,
    sm: 8,
    xs: 4,
};

// MediaQueryLists
export const mql = (Object.keys(breakpoints) as Size[]).reduce(
    (pv, breakpoint) => {
        pv[breakpoint] = util.isClient
            ? window.matchMedia(`screen and (max-width: ${breakpoints[breakpoint] - 1}px)`)
            : ({} as any);
        return pv;
    },
    {} as { [key in Size]: MediaQueryList },
);

// Code Changed -  Manoj ( changes made below)
// type MediaTag = (...args: any[]) => InterpolationValue[];
// type Media = Record<Size, MediaTag>;

// // Desktop-first, use `max-width`
// const media: Media = (Object.keys(breakpoints) as Size[]).reduce(
// 	(mediaObj: Media, size: Size): Media => {
// 		mediaObj[size] = (...args: any[]): InterpolationValue[] => css`
// 			@media screen and (max-width: ${breakpoints[size] - 1}px) {
// 				${css.apply(null, args)};
// 			}
// 		`;
// 		return mediaObj;
// 	},
// 	// tslint:disable-next-line:no-object-literal-type-assertion
// 	{} as Media,
// );


type MediaTag = (...args: any[]) => any[];
type Media = Record<Size, MediaTag>;

// Desktop-first, use `max-width`
const media: Media = (Object.keys(breakpoints) as Size[]).reduce(
    (mediaObj: Media, size: Size): Media => {
        mediaObj[size] = (...args: any[]): any => css`
			@media screen and (max-width: ${breakpoints[size] - 1}px) {
				${css.apply(null, args)};
			}
		`;
        return mediaObj;
    },
    // tslint:disable-next-line:no-object-literal-type-assertion
    {} as Media,
);


/**
 * Create horizontal margins for row containers
 */
export function createRowMargin(): string {
    return (Object.keys(gutters) as Size[])
        .map(
            (size: Size): string => {
                // the first item should be `xxl`
                // because current media query is desktop-first
                // use the largest vw as default setting
                // (without been nested in @media)
                if (size === 'xxl') {
                    return `
                    margin-left: -${gutters[size] / 2}px;
                    margin-right: -${gutters[size] / 2}px;
                `;
                } else {
                    return media[size]`
                    margin-left: -${gutters[size] / 2}px;
                    margin-right: -${gutters[size] / 2}px;
                `.join('');
                }
            },
        )
        .join('\n');
}

// for Col container
/**
 * Create inner padding (gutter) for column containers
 * @param gutterObj {Gutters}
 */
export function createColPadding(): string {
    return (Object.keys(gutters) as Size[])
        .map(
            (size: Size, i: number): string => {
                if (size === 'xxl') {
                    return `
                    padding-left: ${gutters[size] / 2}px;
                    padding-right: ${gutters[size] / 2}px;
                `;
                } else {
                    return media[size]`
                    padding-left: ${gutters[size] / 2}px;
                    padding-right: ${gutters[size] / 2}px;
                `.join('');
                }
            },
        )
        .join('\n');
}

export default media;
