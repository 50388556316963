import React, { useState, useEffect } from "react";
import { useQuery, useMutation } from '@apollo/client'
import styled from "styled-components";
import { ifProp, switchProp, theme } from 'styled-tools';
import { color } from '../../../sass-files/variable';
import FavoriteButton from '../../eu-content/FavoriteButton';
import { GET_INNER_EVENTS } from '../../../graphql/events';
import util from '../../../util/util';
//Images
import BlueShirt from 'src/images/blue_shirt.png';
import GreenShirt from 'src/images/green_shirt.png';
import Ledger from 'src/images/ledger.png';
import InPlayFavoriteIcon from 'src/images/favoriteIcon_active.png';
import FavoriteIconNormal from 'src/images/star_normal.png';
import TabDoubleOpen from 'src/images/tab_double_open.png';
import EventInnerTable from "../table/EventInnerTable";
import SportTable from '../table/SportList'
const {
    tabsignup,
    innerBackHeadBg,
    innerLayHeadBg,
    secondarycolor,
    innerOddsLayColor,
    innerBackLightBg,
    innerBackDarkBg,
    innerLayDarkBg,
    innerLayLightBg,
    titleHeadColor,
} = color

// interface ITypeTitleProps {
// 	type: 'BACK' | 'LAY';
// 	label: string;
// 	overround: number | undefined | null;
// }

// export const TypeTitle = (props: ITypeTitleProps) => {
// 	return (
// 		<TypeTitleWrapper>
// 			<OverroundLabel type={props.type}>{props.label}</OverroundLabel>
// 		</TypeTitleWrapper>
// 	);
// };

interface InnerMarketList {
    categoryId?: any,
    competitionId?:any,
    eventId?:any
    matchId?:any
}

export default function GameDetailsTable(props: InnerMarketList) {
    return (
        <>
        <SportTable categoryId={props.categoryId} competitionId={props.competitionId} eventId={props.eventId} matchId={props.matchId}/>
        </>
       
    )
}

//Fancy Market Styled Css
const MaxSection = styled.div`
    display: flex;
    flex-direction: column;
    font-size: 10px;
    letter-spacing: 0.05em;
    text-transform: capitalize;
    position: absolute;
    top: 0;
    right: 10px;
`;

const MaxInfo = styled.div`
    display: flex;
    flex-direction: row;
`;

const Amount = styled.div`
    display: flex;
`;

const Price = styled.div`
    font-size: 12px;
    text-align: center;
    letter-spacing: 0.1em;
    text-transform: uppercase;
    color: ${titleHeadColor};
    font-weight: 500;
    line-height: 14px;
    margin-bottom: 2px;
`;

const Size = styled.div`
    font-size: 10px;
    text-align: center;
    letter-spacing: 0.1em;
    text-transform: uppercase;
    color: rgba(33, 64, 118, 0.75);
    line-height: 10px;
`;

const FancyLay = styled.div`
    width: 100px;
    height: 32px;
    padding: 3px 10px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background-color: ${innerLayLightBg};
    letter-spacing: 0.1em;
    font-size: 12px;
    font-weight: 500;
    margin: 0px 1px;
    color: ${innerOddsLayColor}
`;

const FancyBack = styled.div`
    width: 100px;
    height: 32px;
    padding: 3px 10px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background-color: ${innerBackLightBg};
    letter-spacing: 0.1em;
    font-size: 12px;
    font-weight: 500;
    margin: 0px 1px;
    color: ${innerOddsLayColor}
`;

const FancyTeamCol = styled.div`
    width:40%;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    flex-direction: row;
    position: relative;
    padding-left: 15px;
`;

const Updation = styled.div`
    font-size: 13px;
    letter-spacing: 0.07em;
    text-transform: capitalize;
    color: #002150;
    padding-left: 20px;
`;

const FavoriteNormal = styled.div`
    width: 15px;
    height: 15px;
    background-image: url(${FavoriteIconNormal});
    background-size: auto;
    background-repeat: no-repeat;
    background-position: center;
    cursor: pointer;
    border-radius: 5px;
`;

const FancyTab = styled.div`
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    background: linear-gradient(90deg, #4BC2F4 43.81%, #2298CA 100%);
    color: ${secondarycolor};
    padding: 20px 10px; 
`;

//Bookmaker Market Styled Css
const OddsLayDark = styled.div`
    width: 50px;
    height: 30px;
    padding: 5px 10px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex: auto;
    background-color: ${innerLayDarkBg};
    letter-spacing: 0.1em;
    font-size: 12px;
    font-weight: 500;
    margin: 0px 1px;
    color: ${innerOddsLayColor};

    // :nth-child(1) {
    //     margin-left: 0px;
    // }
`;

const OddsLayLight = styled.div`
    width: 50px;
    height: 30px;
    padding: 5px 10px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex: auto;
    background-color: ${innerLayLightBg};
    letter-spacing: 0.1em;
    font-size: 12px;
    font-weight: 500;
    margin: 0px 1px;
    color: ${innerOddsLayColor}
`;

const OddsBackDark = styled.div`
    width: 50px;
    height: 30px;
    padding: 5px 10px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex: auto;
    background-color: ${innerBackDarkBg};
    letter-spacing: 0.1em;
    font-size: 12px;
    font-weight: 500;
    margin: 0px 1px;
    color: ${innerOddsLayColor}
`;

const OddsBackLight = styled.div`
    width: 50px;
    height: 30px;
    padding: 5px 10px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex: auto;
    background-color: ${innerBackLightBg};
    letter-spacing: 0.1em;
    font-size: 12px;
    font-weight: 500;
    margin: 0px 1px;
    color: ${innerOddsLayColor}
`;

const MatchOddsLay = styled.div`
    width: 49%;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    flex-direction: row;
    padding: 5px;
    background: linear-gradient(167.94deg, #EAF1FF 3.01%, #DDE9FF 149.47%);
    margin-left: 1px;
    margin-bottom: 2px;
`;

const MatchOddsBack = styled.div`
    width: 49%;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    flex-direction: row;
    padding: 5px;
    background: linear-gradient(167.94deg, #EAF1FF 3.01%, #DDE9FF 149.47%);
    margin-right: 1px;
    margin-bottom: 2px;
`;

const OddsColBody = styled.div`
    width: 60%;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: row;
    flex-wrap: wrap;
    position: relative;
`;

const TeamCol = styled.div`
    width:40%;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: row;
    position: relative;

    ul {
        margin: 0;
        list-style: none;
        padding-left: 20px;

        :after {
            content: "";
            width: 1px;
            height: 30px;
            background: rgba(9,40,95,0.7);
            position: absolute;
            top: 5px;
            right: 50px;
        }
    }

    .team {
        font-size: 13px;
        color: #002150;
        letter-spacing: 0.07em;
        text-transform: capitalize;
        padding-bottom: 5px;
    }

    .loss_point {
        font-size: 13px;
        color: #FF1515;
        letter-spacing: 0.07em;
        text-transform: capitalize;
        

        img {
            padding-right: 5px;
        }
    }
`;

const GameIconBlue = styled.div`
    width: 20px;
    height: 22px;
    display: flex;
    background-image: url(${BlueShirt});
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
    margin: 0px 10px;
`;

const GameIconGreen = styled.div`
    width: 20px;
    height: 22px;
    display: flex;
    background-image: url(${GreenShirt});
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
    margin: 0px 10px;
`;

const Row = styled.div`
    width: 100%;
    display: flex;
    align-items: center;
    flex-direction: row;
    background-color: ${secondarycolor};
    padding: 5px 0px;
    margin-bottom: 5px;
`;

const TableBody = styled.div`
    width: 100%;
    display: flex;
    align-items: center;
    flex-direction: column;
`;

const BackTypeTitle = styled.div`
    width: 100px;
    height: 40px;
    padding: 5px 10px;
    display: flex;
    align-items: center;
    justify-content: center;
    background: ${innerBackHeadBg};
    letter-spacing: 0.07em;
    text-transform: capitalize;
    font-size: 13px;
    
    margin-right: 1px;
    color:#3CACEC;
`;

const LayTypeTitle = styled.div`
    width: 100px;
    height: 40px;
    padding: 5px 10px;
    display: flex;
    align-items: center;
    justify-content: center;
    background: ${innerLayHeadBg};
    letter-spacing: 0.07em;
    text-transform: capitalize;
    font-size: 13px;
    
    margin-left: 1px;
    color:#fd6c8d;
`;

const OddsColHead = styled.div`
    width: 60%;
    display: flex;
    align-items: center;
    justify-content: center;
`;

const OverroundLabel: any = styled.div`
	font-size: 10px;
	padding: 2px;
    margin-top: 2px;
    color: #000;
	background: ${switchProp('type', {
    BACK: '#61B2FB',
    LAY: '#FF99B0',
})};
`;

// Back/Lay + overround
const TypeTitleWrapper = styled.div`
	display: flex;
	flex-direction: column;
	align-items: center;
	font-size: 11px;
	margin: 0 0.5em;
`;

const MarketTittle = styled.div`
    width: 40%;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    color: rgba(255, 255, 255, 0.65);
    font-size: 10px;
    text-transform: uppercase;
    padding-left: 20px;
`;

const TableHead = styled.div`
    width: 100%;
    display: flex;
    flex-direction: row;
    background-color: ${tabsignup}; 
`;

const TabBody = styled.div`
    width: calc(100% - 25%);
    display: flex;
    flex-direction: column;
`;

const TabOpenIcon = styled.div`
    width: 25px;
    height: 25px;
    background-image: url(${TabDoubleOpen});
    background-size: auto;
    background-repeat: no-repeat;
    background-position: center;
    cursor: pointer;
    margin: 0px 10px;
    border-radius: 5px;

    :hover {
        background-color: #9c1312;
    }
`;

const FavoriteIcon = styled.div`
    width: 25px;
    height: 25px;
    background-image: url(${InPlayFavoriteIcon});
    background-size: auto;
    background-repeat: no-repeat;
    background-position: center;
    cursor: pointer;
    border-radius: 5px;

    :hover {
        background-color: #c31918;
    }
`;

const Tittle = styled.div`
    display: flex;
    line-height: 18px;
    text-transform: uppercase;
`;

const BookmakerTab = styled.div`
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    background: linear-gradient(90deg, #F02B2B 43.81%, #BE1616 100%);
    color: ${secondarycolor};
    padding: 20px 10px; 
`;

const TabSection = styled.div`
    width: 25%;
    display: flex;
    flex-direction: coloumn;
    align-items: center;
    background: ${secondarycolor};
`;

const RowWrap = styled.div`
    width: 100%;
    display: flex;
    flex-direction: coloumn;
    align-items: flex-start;
    background: #EAF4FD;
    margin-bottom: 10px;
    
`;

const GameDetailWrapper = styled.div`
    width: 100%;
    padding: 5px;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 20px;
    background: ${secondarycolor};
    box-shadow: 0px 4px 4px rgba(143, 143, 143, 0.16);
`;