import isEqual from 'lodash/isEqual';
import React from 'react';
// util
import EventTypeConfig from '../../../../config/eventTypeConfig';
import cookieUtil from '../../../../util/cookieUtil';
import oddsUtil from '../../../../util/oddsUtil';
import routerUtil from '../../../../util/routerUtil';
import util from '../../../../util/util';
import { ECookieName, EMarketType } from '../../../../util/utilModel';

// type
import { IEventInfo, IFancyInfo, IMarketInfo, IMarketSource, ISelectionInfo } from '../../../../graphql/schema';
import { IOrderTicket } from '../../../../store/OrderListContext';
import { marketInfo } from 'src/graphql/events';

export enum ESlipState {
	Betting,
	Error,
	Expired,
}

export interface ISlipProps {
	keyName: string;
	type: 'BACK' | 'LAY';
	quickStakes: number[];
	handleTrashClick: () => void;
	selectionInfo?: ISelectionInfo;
	fancyInfo?: IFancyInfo;
	marketInfo: IMarketInfo;
	eventInfo: IEventInfo;
	price: number;
	placeOrder: (
		info: IOrderTicket,
		input: {
			size: number;
			price: number;
		},
	) => void;
	isOrderLoading: boolean;
	placingOrderKeyName: string;
	handlePriceSizeChange: any;
	currentPrice?: number;
	currentSize?: number;
	betResult: any;
	marketSource?: IMarketSource;
	exchangeRate: number;
	isHidden?: boolean;
	children: (slipStore: ISlipStore) => JSX.Element;
	activateBetting: () => void;
	isMobileWidth?: boolean;
	placeOrderAction: (trigger:any) => void;
	isUserLoggedIn?:any;
	saveQuickStakes: (quickStakes: number[]) => void;
}

interface ISlipState {
	slipState: ESlipState;
	orderLimit: IOrderLimit;
	liveOddsChangeType: 1 | 0 | -1;
	isLoading: boolean;
}

export interface ISlipStore
	extends Pick<
			ISlipProps,
			| 'type'
			| 'isHidden'
			| 'eventInfo'
			| 'marketInfo'
			| 'selectionInfo'
			| 'fancyInfo'
			| 'exchangeRate'
			| 'price'
			| 'currentPrice'
			| 'currentSize'
			| 'quickStakes'
			| 'isOrderLoading'
			| 'handleTrashClick'
			| 'placingOrderKeyName'
			| 'keyName'
			| 'isUserLoggedIn'
			| 'saveQuickStakes'
		>,
		ISlipState {
	// methods
	handleOddsChange: (value: string) => void;
	handleStakeChange: (e: any) => void;
	handleOnClickInput: (e: any) => void;
	handleQuickStake: (stake: number) => () => void;
	handleBetting: () => void;
	goToEvent: (path: string) => () => void;

	// others
	message: string;
	eventPath: string;
	isFancyType: boolean;
	priceDisplay: number;
	slipRef: any;
}

interface IOrderLimit {
	eventTypeId: number;
	minOrder: number;
	maxOrder: number;
	maxMarket: number;
}

const defaultOrderLimit = {
	minorder: 0,
	maxorder: 99999999,
	maxmarket: 99999999,
};

export class OrderSlipContainer extends React.Component<ISlipProps, ISlipState> {
	constructor(props: ISlipProps) {
		super(props);
		const { eventInfo } = props;
		let orderLimits = [];
		try {
			orderLimits = JSON.parse(cookieUtil.get(ECookieName.COOKIE_ORDER_LIMIT) || '[]');
		} catch (e) {}
		
		const orderLimit = Array.isArray(orderLimits)
			? orderLimits.find((item: IOrderLimit) => Number(item.eventTypeId) === eventInfo.categoryId ) || defaultOrderLimit
			: defaultOrderLimit;
		this.state = {
			orderLimit,
			slipState: ESlipState.Betting,
			liveOddsChangeType: 0,
			isLoading: false,
		};
	}

	slipRef = React.createRef();

	static getDerivedStateFromProps(nextProps: ISlipProps, prevState: ISlipState) {
		const message = nextProps.betResult && nextProps.betResult.message;
		if (message) {
			return { slipState: ESlipState.Error };
		}
		return null;
	}
	shouldComponentUpdate(nextProps: ISlipProps, nextState: ISlipState) {
		const { handleTrashClick: oldHandleTrashClick, ...oldData }: any = this.props;
		const { handleTrashClick: newHandleTrashClick, ...newData }: any = nextProps;

		return !isEqual(oldData, newData) || this.state.isLoading !== nextState.isLoading;
	}
	componentDidMount() {
		const { isMobileWidth } = this.props;
		const slipNode: any = this.slipRef.current;
		// 桌面新增的 slip 捲動至畫面頂端
		// 手機新增的 slip 不捲動
		// setTimeout(() => {
		// 	slipNode && !isMobileWidth && slipNode.scrollIntoView({behavior: "smooth", block: "start", inline: "nearest"});
		//    // slipNode && !isMobileWidth && slipNode.scrollIntoView({behavior: "smooth", block: "end", inline: "nearest"});

		// });

		window.addEventListener('keypress', this.handlePressEnter);
	}

	componentWillUnmount() {
		window.removeEventListener('keypress', this.handlePressEnter);
	}

	componentDidUpdate(prevProps: ISlipProps) {
		const { price: newLiveOdds = 0 } = this.props;
		const { price: oldLiveOdds = 0 } = prevProps;
		if (newLiveOdds !== oldLiveOdds) {
			this.setState({ liveOddsChangeType: newLiveOdds > oldLiveOdds ? 1 : -1 });
		}
	}

	handlePressEnter = (e: any) => {
		const slipNode: any = this.slipRef.current;
		const { key } = e;
		key === 'Enter' && slipNode && slipNode.querySelectorAll('*:focus').length > 0 && this.handleBetting();
	};

	handleStakeChange = (e: any) => {
		const {resourceFrom, maxPerOrderCredit, maxPerMarketExposure} = this.props.marketInfo
		const { orderLimit } = this.state;
		if (/^[0-9]*(\.)?[0-9]*$/.test(e.target.value)) {
			// 因為有小數點，因此儲存時候是紀錄string包含小數點
			const compareStake = Number(e.target.value);
			let storeStake = e.target.value;
			//As discussed with Sam, we no need to add FE check for price
			// if(resourceFrom === EMarketType.BOOKMAKER) {
			// 	compareStake > maxPerOrderCredit * this.props.exchangeRate && (storeStake = maxPerOrderCredit * this.props.exchangeRate);
			// }else {
			// 	compareStake > orderLimit.maxOrder * this.props.exchangeRate && (storeStake = orderLimit.maxOrder * this.props.exchangeRate);
			// }			
			// Code Changed - Manoj ( Removed stake check)
			// compareStake > orderLimit.maxOrder * this.props.exchangeRate && (storeStake = orderLimit.maxOrder * this.props.exchangeRate);

			// compareStake < orderLimit.minOrder && (storeStake = orderLimit.minOrder);
			this.props.handlePriceSizeChange({ price: this.props.currentPrice, size: storeStake });
		}
	};

	handleOddsChange = (value: string) => {
		this.props.handlePriceSizeChange({ price: value, size: this.props.currentSize });
	};

	handleQuickStake = (stake: number) => () => {
		const {resourceFrom, maxPerOrderCredit, maxPerMarketExposure} = this.props.marketInfo
		// 下注時不允許更新輸入
		if (this.state.isLoading) {
			return;
		}
		const { orderLimit } = this.state;
		const increased = Number(stake) || 0;
		let finalSize = Number(this.props.currentSize) + increased;
		// if(resourceFrom === EMarketType.BOOKMAKER) {
		// 	finalSize > maxPerOrderCredit * this.props.exchangeRate && (finalSize = maxPerOrderCredit * this.props.exchangeRate);
		// }else {
		// 	finalSize > orderLimit.maxOrder * this.props.exchangeRate && (finalSize = orderLimit.maxOrder * this.props.exchangeRate);
		// }	
		// finalSize > orderLimit.maxOrder * this.props.exchangeRate && (finalSize = orderLimit.maxOrder * this.props.exchangeRate);
		 this.props.handlePriceSizeChange({
			price: this.props.currentPrice,
			size: finalSize,
		});
	};

	handleBetting = async () => {
		
		const { activateBetting, placeOrder, currentSize, currentPrice, handleTrashClick, placeOrderAction, isUserLoggedIn } = this.props;
		
		if (this.state.isLoading || !currentSize || currentSize <= 0 || !currentPrice) {
			return;
		}
		
		placeOrderAction(isUserLoggedIn + 1);
		this.setState({ isLoading: true });
		const payload = await placeOrder(this.props as any, {
			size: currentSize,
			price: currentPrice,
		});
		
		const isSuccess = util.getValue(payload, ['success'], false);
	
		if (isSuccess && activateBetting) {
			// 下注後會讓betslip打開
			// activateBetting();
			handleTrashClick();
		}
	
		this.setState({ isLoading: false });
	};

	goToEvent = (path: string) => () => {
		typeof path === 'string' && path.length > 0 && routerUtil.push(path);
	};


	handleOnClickInput(e: any) {
		e.target.select();
	}

	render(): JSX.Element {
		const {
			quickStakes = [],
			marketInfo,
			selectionInfo,
			fancyInfo,
			currentPrice,
			currentSize,
			type,
			price,
			eventInfo,
			betResult = {} as any,
			isOrderLoading,
			exchangeRate,
			isHidden = false,
			children,
			handleTrashClick,
			placingOrderKeyName,
			keyName,
			placeOrderAction,
			isUserLoggedIn,
			saveQuickStakes
		} = this.props;
		const { marketType, resourceFrom } = marketInfo;
		const { slipState, liveOddsChangeType, isLoading, orderLimit } = this.state;
		const message = betResult.message;
		const isFancyType = marketType === EMarketType.FANCY || marketType === EMarketType.Multi_Selection_Fancy;
		const priceDisplay = isFancyType ? oddsUtil.EUtoAM(price) : price;
		const { categoryId, competitionId = 'e', eventId } = eventInfo;

		let eventPath: string;
		if (typeof categoryId !== 'number') {
			console.warn('[Orderslip]: cannot get categoryId from eventInfo');
			eventPath = '';
		} else {
			eventPath = `/eu-content/${EventTypeConfig(categoryId).name}/${competitionId}/${eventId}`;
		}

		const slipStore: ISlipStore = {
			// from props
			keyName,
			type,
			isHidden,
			eventInfo,
			marketInfo,
			selectionInfo,
			fancyInfo,
			exchangeRate,
			price,
			currentPrice,
			currentSize,
			quickStakes,
			isOrderLoading,
			handleTrashClick,
			placingOrderKeyName,
			saveQuickStakes,

			// from sate
			slipState,
			liveOddsChangeType,
			isLoading,
			orderLimit,

			// methods
			handleOddsChange: this.handleOddsChange,
			handleStakeChange: this.handleStakeChange,
			handleOnClickInput: this.handleOnClickInput,
			handleQuickStake: this.handleQuickStake,
			goToEvent: this.goToEvent,
			handleBetting: this.handleBetting,

			// others
			message,
			eventPath,
			isFancyType,
			priceDisplay,
			slipRef: this.slipRef,
			isUserLoggedIn
		};

		return children(slipStore);
	}
}

export const getAmountWithKorM = (amount: number) => {
	const mAmount = amount / 1000000;
	const kAmount = amount / 1000;
	return mAmount >= 1 ? `${mAmount}M` : kAmount >= 1 ? `${kAmount}K` : amount.toString();
};
