import React, { useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import config from "src/config/config";
import styled from "styled-components";
import FileUploadIcon from "src/components/icon/FileUploadIcon";
import { postTxnReceipt } from "src/config/api";
import { Notify } from "../Notification";
import moment from "moment";
import Intl from "src/components/common/Intl";


export const WarningPendingModal = () => {
    const [openPopup, setOpenPopup] = useState(true)

    const navigate = useNavigate();

    const onClose = () => {
        setOpenPopup(false)
    }

    const redirectToWithdrawal = () => {
        navigate(0)
    }

    if (!openPopup) return null;

    return (
        <ModalOverlay>
            <ModalContainer>
                <WarningIconWrap>
                    <img src={`${config.imageV3Host}/static/cashsite/brand/6/image/withdrawalPendingNew.png`} alt="" />
                </WarningIconWrap>
                <Title><Intl langKey="withdrawal@PendingPopupHead" /></Title>
                <Divider />
                <Message>
                      <Intl langKey="withdrawal@PendingPopupMessage" />
                </Message>
                <Button primary onClick={() => redirectToWithdrawal()}><Intl langKey="withdrawal@PendingPopupBtn" /></Button>
            </ModalContainer>
        </ModalOverlay>
    )
};

export const WarningVerifyAccModal = ({ transaction_sn }) => {
    const [openPopup, setOpenPopup] = useState(true)

    const onClose = () => {
        setOpenPopup(false)
    }

    if (!openPopup) return null;

    return (
        <ModalOverlay>
            <ModalContainer>
                <CloseButton onClick={onClose}>✖</CloseButton>
                <WarningIconWrap>
                    <img src={`${config.imageV3Host}/static/cashsite/brand/6/image/warningIconWithdrawal.png`} alt="" />
                </WarningIconWrap>
                <Title>Warning!</Title>
                <Message className="messageHead">
                    The Account number of your deposit doesnt match with the current one you have used.
                </Message>
                <Divider />
                <Message>
                    Please use the Account Number Matching with the following transaction <b>{transaction_sn}</b> to proceed withdraw.
                </Message>
                <Button primary onClick={onClose}>Okay</Button>
            </ModalContainer>
        </ModalOverlay>
    )
};

export const MaxLimitModal = ({upload_receipt_attempts, setShowDocVerify}) => {
    const [openPopup, setOpenPopup] = useState(true)


    const onClose = () => {
        setOpenPopup(false);
        setShowDocVerify(true)
    }

    const handleChatStart = () => {
        if (window.$zoho?.salesiq?.chat) {
            window.$zoho.salesiq.chat.start();
        } else {
            console.warn("Zoho SalesIQ is not yet loaded.");
        }
    };

    if (!openPopup) return null;

    return (
        <ModalOverlay>
            <ModalContainer>
                <WarningIconWrap>
                    <img src={`${config.imageV3Host}/static/cashsite/brand/6/image/withdrawalSupportNew.png`} alt="" />
                </WarningIconWrap>
                <Title> <Intl langKey="withdrawal@MaxLimitHead" /> </Title>
                <Divider />
                <Message>
                    <Intl langKey="withdrawal@MaxLimitMessage" />
                </Message>
                <Button primary onClick={handleChatStart}>
                     <Intl langKey="withdrawal@MaxLimitBtn" />
                </Button>
                {(upload_receipt_attempts === 1 || upload_receipt_attempts === 2) && <Button onClick={onClose}><Intl langKey="withdrawal@Retry" /></Button>}
            </ModalContainer>
        </ModalOverlay>
    )
};

export const DocumentVerificationModal = ({ transaction_sn, date, upload_receipt_attempts, max_deposit_txn_amount }) => {
    const [openPopup, setOpenPopup] = useState(true)

    const onClose = () => {
        setOpenPopup(false)
    }

    const navigate = useNavigate();


    const [file, setFile] = useState<File | null>(null);
    const fileInputRef = useRef<HTMLInputElement>(null); 

    const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        if (event.target.files && event.target.files.length > 0) {
            const selectedFile = event.target.files[0];
            const allowedTypes = ["image/gif", "image/png", "image/jpg", "image/jpeg"];
    
            if (allowedTypes.includes(selectedFile.type)) {
                setFile(selectedFile);
            } else {
                Notify.error("Invalid file type! Please upload a GIF, PNG, JPG, or JPEG file.");
                event.target.value = ""; // Reset file input
            }
        }
    };
    

    const clearFile = (e) => {
        e.stopPropagation();
        setFile(null);

        // Reset the file input field
        if (fileInputRef.current) {
            fileInputRef.current.value = "";
        }
    }


    const handleUpload = async (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        if (!file) {
            Notify.error('Please select a file');
            return;
        }
        const formData = new FormData();
        formData.append('txn_image', file);

        postTxnReceipt(formData).then(res => {
            let response = res.data.data
            if(response.status && response.status === "REJECTED"){
                setOpenPopup(false);
                Notify.error('Invalid Reciept');
                setTimeout(() => {
                    navigate(0)
                },100)
            } else {
                setOpenPopup(false);
                Notify.success('Reciept Submitted');
                setTimeout(() => {
                    navigate(0)
                },100)
            }
            setFile(null);
        }).catch(e => {
            const { data } = e.response || {};
            let message = 'Something Went Wrong, Please Try Again'
            if (data && data.error) {
                message = data.error;
            }
            Notify.error(message);
        });
    };


    if (!openPopup) return null;

    return (
        <ModalOverlay>
            <ModalContainer>
                <WarningIconWrap>
                    <img src={`${config.imageV3Host}/static/cashsite/brand/6/image/withdrawalUploadNew.png`} alt="" />
                </WarningIconWrap>
                {/* <Title> <Intl langKey="withdrawal@DocUploadHead" /> </Title> */}
                <Divider />
                <Message>
                   <Intl langKey="withdrawal@DocUploadMessage1" /> ({transaction_sn}) <Intl langKey="withdrawal@DocUploadMessage3" /> <b>{max_deposit_txn_amount ? Number(max_deposit_txn_amount).toFixed(2) : ""}</b>   <Intl langKey="withdrawal@DocUploadMessage2" />  ({moment.utc(date).local().format('DD-MMM-YYYY')} - {moment.utc(date).local().format('h:mm A')})
                </Message>
                <Message>
                     <Intl langKey="withdrawal@DocUploadMessage" />
                </Message>

                <Message>
                    <Intl langKey="withdrawal@Attempts" /> { 3 - upload_receipt_attempts} <Intl langKey="withdrawal@Attempts2" />
                </Message>

                <UploadContainer>
                    <Label onClick={(e) => file && e.preventDefault()}> 
                        <UploadIcon>
                            <FileUploadIcon />
                            {file ? file.name : <Intl langKey="withdrawal@DocUploadHead" />}
                        </UploadIcon>
                        
                        <FileInput type="file" onChange={handleFileChange} />
                        {file && <button className="clearFileBtn" onClick={(e) => clearFile(e)}>✖</button>}
                    </Label>

                </UploadContainer>
                <Button disabled={file ? false : true} primary onClick={(e: any) => handleUpload(e)}> <Intl langKey="withdrawal@DocUploadBtn" /></Button>
            </ModalContainer>
        </ModalOverlay>
    )
};

export const NoMaxAccountModal = ({ transaction_sn, date, max_deposit_txn_amount }: any) => {
    const [openPopup, setOpenPopup] = useState(true)

    const navigate = useNavigate();

    const onClose = () => {
        setOpenPopup(false)
    }

    const redirectToAddBank = () => {
        navigate("/member/profile/bankdetails")
    }

    const handleChatStart = () => {
        if (window.$zoho?.salesiq?.chat) {
            window.$zoho.salesiq.chat.start();
        } else {
            console.warn("Zoho SalesIQ is not yet loaded.");
        }
    };

    if (!openPopup) return null;

    return (
        <ModalOverlay>
            <ModalContainer>

                <WarningIconWrap>
                    <img src={`${config.imageV3Host}/static/cashsite/brand/6/image/warningIconWithdrawal.png`} alt="" />
                </WarningIconWrap>
                {/* <Title>
                    <Intl langKey="withdrawal@NoMaxAccHead" />
                </Title> */}
                <Divider />

                <Message>
                <Intl langKey="withdrawal@NoMaxAccMessage" /> ({transaction_sn}) of <b>{max_deposit_txn_amount ? Number(max_deposit_txn_amount).toFixed(2) : ""}</b> <Intl langKey="withdrawal@NoMaxAccMessage1" /> {moment.utc(date).local().format('DD-MMM-YYYY')} - {moment.utc(date).local().format('h:mm A')} <Intl langKey="withdrawal@NoMaxAccMessage2" />
                <br />
                </Message>
            
                <Button primary onClick={() => redirectToAddBank()}><Intl langKey="withdrawal@NoMaxAccBtn" /></Button>
                <Button className="chat-button" onClick={handleChatStart}><Intl langKey="withdrawal@NoMaxAccBtn2" /></Button>
            </ModalContainer>
        </ModalOverlay>
    )
};


//warning popup
const ModalOverlay = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.847);
  display: flex;
  justify-content: center;
  align-items: flex-start;
  overflow: hidden;
  bottom: 0;
  right: 0;
  padding-top: 15%;
  backdrop-filter: blur(5px);
`;

const ModalContainer = styled.div`
  border-radius: 10.027px;
  border: 1.003px solid #CDCDCD;
  background: linear-gradient(90deg, rgba(0, 0, 0, 0.846) 0%, rgba(0, 0, 0, 0.60) 100%);
  box-shadow: 0px 0px 40.107px 0px rgba(0, 0, 0, 0.40);
  padding: 20px;
  width: 350px;
  text-align: center;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.3);
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;


  & .messageHead {
    text-align: center;
    font-size: 14.038px;
    font-style: normal;
    font-weight: 700;
    line-height: 170.5%; /* 23.934px */
    letter-spacing: 0.14px;
    background: linear-gradient(360deg, #FFE500 20%, #FF5600 80%);
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
  }

  & .chat-button {
    font-size: 10px;
    text-align: left;
    font-weight: 400;
    color: #fff;
    cursor: pointer;
    width: 100%;
    text-decoration: underline;
    border: none;
    outline: none;
    padding: 5px 0px;
  }

  &.textCenter {
    text-align: center;
  }
`;

const CloseButton = styled.button`
  position: absolute;
  top: 10px;
  right: 10px;
  background: none;
  border: none;
  font-size: 20px;
  color: white;
  cursor: pointer;
`;


const Title = styled.h2`
    background: linear-gradient(360deg, #FFE500 20%, #FF5600 80%);
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    margin-bottom: 5px;
    text-align: center;
    font-size: 18px;
    font-style: normal;
    font-weight: 700;
    line-height: 170.5%; /* 41.03px */
    letter-spacing: 0.241px;
    margin: 3px 0px;
`;

const Message = styled.p`
    color: #ccc;
    font-size: 14px;
    margin-bottom: 15px;
    text-align: left;
    width: 100%;
`;


const Button = styled.button<{ primary?: boolean }>`
  width: 100%;
  padding: 10px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  margin-top: 10px;
  color: #FFF;
  text-align: center;
  font-size: 13.5px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  letter-spacing: 0.125px;
  text-transform: capitalize;
  
  ${({ primary }) =>
        primary
            ? `background: linear-gradient(180deg, #0C8512 0%, #4FC247 100%); &:hover { background: #218838; }`
            : `background: none; &:hover { background: none; }`
    }

      :disabled {
        cursor: not-allowed;
        opacity: 0.8;
      }
`;

const WarningIconWrap = styled.div`
    & img {
        width: 40%;
        height: auto;
    }
`

const Divider = styled.div`
    background: linear-gradient(to right, rgba(255, 255, 255, 0), rgba(255, 255, 255, 1), rgba(255, 255, 255, 0));
    height: 1px;
    width: 80%;
    margin-top: 2%;
`

const UploadContainer = styled.div`
  display: flex;
  align-items: center;
  background: white;
  border-radius: 8px;
  overflow: hidden;
  width: 100%;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2);
  margin: 10px 0px;
`;

const Label = styled.label`
  display: flex;
  align-items: center;
  width: 75%;
  padding: 10px;
  flex: 1;
  cursor: pointer;
  justify-content: space-between;


& .clearFileBtn {
    border: none;
    outline: none;
    background-color: white;
    z-index: 999;
    font-size: 20px;
    cursor: pointer;
}
`;

const UploadIcon = styled.span`
  margin-right: 8px;
  font-size: 14px;
  color: #6B7A90;
  font-size: 9.938px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  text-transform: capitalize;
  display: flex;
  align-items: center;
  gap: 5px;
`;

const FileInput = styled.input`
  display: none;
`;
