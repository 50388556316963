import React from 'react'

type Props = {}

const DollorIcon = (props: Props) => {
  return (
    <svg
        width={35}
        height={36}
        viewBox="0 0 35 36"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        {...props}
    >
        <g clipPath="url(#clip0_18_507)">
        <path
            d="M21.9534 7.30347H13.3464L10.1592 2.52259C9.68177 1.8031 10.1928 0.848267 11.0535 0.848267H24.2463C25.107 0.848267 25.6181 1.8031 25.1406 2.52259L21.9534 7.30347ZM13.3464 9.45521H21.9534C22.2089 9.62331 22.498 9.81159 22.8275 10.02C26.6401 12.4609 34.8638 17.7192 34.8638 28.8208C34.8638 32.3846 31.9724 35.276 28.4086 35.276H6.89124C3.32743 35.276 0.436035 32.3846 0.436035 28.8208C0.436035 17.7192 8.6597 12.4609 12.4723 10.02C12.7951 9.81159 13.0909 9.62331 13.3464 9.45521ZM18.9948 15.3725C18.9948 14.6328 18.3896 14.0276 17.6499 14.0276C16.9103 14.0276 16.3051 14.6328 16.3051 15.3725V16.3139C15.794 16.4282 15.283 16.6097 14.8123 16.8854C13.8777 17.4435 13.0708 18.4185 13.0775 19.8373C13.0842 21.2023 13.8844 22.063 14.7384 22.5741C15.478 23.0179 16.3992 23.3003 17.1322 23.5155L17.2465 23.5491C18.0937 23.8046 18.7123 24.0063 19.1292 24.2686C19.4722 24.4837 19.5192 24.6317 19.526 24.8199C19.5327 25.1562 19.4049 25.3579 19.1292 25.526C18.793 25.7344 18.2618 25.8622 17.6903 25.842C16.9439 25.8151 16.2446 25.5798 15.3301 25.2705C15.1754 25.2167 15.014 25.1629 14.8459 25.1091C14.1399 24.8737 13.3801 25.257 13.1447 25.9563C12.9094 26.6556 13.2927 27.4222 13.992 27.6575C14.1197 27.6979 14.2609 27.745 14.4021 27.7988C14.9602 27.9938 15.6058 28.2157 16.2984 28.3636V29.3588C16.2984 30.0984 16.9035 30.7036 17.6432 30.7036C18.3829 30.7036 18.988 30.0984 18.988 29.3588V28.4308C19.526 28.3165 20.0639 28.1282 20.548 27.8257C21.5096 27.2272 22.2358 26.2051 22.2156 24.7998C22.1955 23.4348 21.4289 22.5539 20.5615 22.0025C19.7882 21.5184 18.8199 21.2225 18.0668 20.9939L18.0197 20.9804C17.1591 20.7182 16.5472 20.5299 16.1168 20.2744C15.7672 20.066 15.7604 19.9449 15.7604 19.8239C15.7604 19.5751 15.8546 19.3868 16.1773 19.1985C16.5404 18.9834 17.0918 18.8556 17.623 18.8623C18.2685 18.8691 18.9813 19.0103 19.721 19.212C20.4405 19.4003 21.1734 18.9766 21.3684 18.2572C21.5634 17.5377 21.133 16.8047 20.4136 16.6097C19.9765 16.4954 19.4923 16.3811 18.9948 16.2937V15.3725Z"
            fill="#FBBF24"
        />
        </g>
        <defs>
        <clipPath id="clip0_18_507">
            <path
            d="M0.436035 0.848267H34.8638V35.276H0.436035V0.848267Z"
            fill="white"
            />
        </clipPath>
        </defs>
    </svg>
  )
}

export default DollorIcon