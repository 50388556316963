import React from 'react'

type Props = {}

const Done = (props: Props) => {
  return (
    <svg width="41" height="41" viewBox="0 0 41 41" fill="none" xmlns="http://www.w3.org/2000/svg">
<circle cx="20.5398" cy="20.7644" r="20.1159" fill="#0EAD69"/>
<path d="M32.0351 12.1436L16.2298 27.9489L9.0455 20.7647" stroke="white" stroke-width="4.59793" stroke-linecap="round" stroke-linejoin="round"/>
</svg>

  )
}

export default Done