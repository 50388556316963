import React, { useState } from 'react';
import { theme, withProp } from 'styled-tools';
import { GET_BALANCE_INFO } from '../../graphql/game';
import { useQuery } from '@apollo/client'
import styled, { css, withTheme } from 'styled-components';
import { withAppContext } from "src/store/initAppContext";
import TransferIcon from "src/images/transfer_icon.png";
import util from '../../util/util';
import { ONE_CLICK_RECYCLE } from '../../graphql/system';
import { useMutation } from '@apollo/client';
import Icon, { IconWrapper } from '../common/PNGIcon';
import config from 'src/config/config';
import Intl from '../common/Intl';
import _isEmpty from 'lodash/isEmpty';
import { IPlatformInfo } from 'src/pages/Statements/balance-transfer';
// import RefreshIcon from 'src/images/transfer_refresh.png';
// import MemberSidebar from '../../pages/Statements/member-sidebar';
// import { Query } from 'react-apollo';
// import { darken } from 'polished';
// import { diffColor } from '../../util/colorUtil';
// import media from '../../util/mediaUtil';
// import { isMemberType } from '../../util/styleUtil';
// import Spinner from "src/components/common/Spinner";
// import Bg from '../../images/iMAGE1_iexch.png';
// import DownArrow from "src/images/chevron-down.png";
// import UpArrow from "src/images/chevron-Up.png";
// import graphqlApiUtil from '../../util/graphqlApiUtil';
// Dependent Components
// import { transparentContainer } from '../../components/common/commonCSSStyles';
// import { Amount } from '../../components/common/NumberDisplay';
// import loading, { LoadingWrap } from 'src/pages/loading';
// import { GET_CREDIT } from 'src/graphql/member';
// import { ECookieName } from 'src/util/utilModel';
// import cookieUtil from 'src/util/cookieUtil';
// import { UserContext } from 'src/pages/udb';
// import WacsBalance from './WacsBalance';

const MainIcon = `${'/static/snk/brand/1/icon/png/wallet'}`
const AgIcon = `${'/static/snk/brand/1/icon/png/AgIcon'}`
const WacsIcon = `${'/static/snk/brand/1/icon/png/WacsIcon'}`
const SuperIcon = `${'/static/snk/brand/1/icon/png/SuperIcon'}`
const BtiIcon = `${'/static/snk/brand/1/icon/png/BtiIcon'}`
const VirtualSportsIcon = `${'/static/snk/image/Virtual_SportsIcon'}`
const STPLogo = `${'/static/snk/image/STP_Logo'}`
const PREMIUM_SPORTSBOOK_LOGO = `${'/static/snk/image/PREMIUM_SPORTSBOOK_LOGOO'}`
const PREMIUM_SPORTSBOOK_FUN = `${'/static/snk/image/B2BLogo'}`


interface IProps {
	platformInfoList: IPlatformInfo[];
	isQueryLoading: boolean;
	platformCode: any
	ownPlatform: any
	refresh: any
	app: any
	theme: any
}
interface Props {
	propsState: boolean;
}

const BalanceTransferTemplate = ({ info, brandCheck }) => {
	let IconName = {}
	let DisplayName = ''
	switch (info) {
		case "WACS":
			IconName = WacsIcon;
			DisplayName = 'CASINOS';
			break;
		case "AgTeenpatti":
			IconName = AgIcon;
			DisplayName = 'TEENPATTI';
			break;
		case "SUPERNOWA":
			IconName = SuperIcon;
			DisplayName = 'SUPERNOWA';
			break;
		case "BTISPORTS":
			IconName = BtiIcon;
			DisplayName = 'SPORTSBOOK';
			break;
		case "VIRTUALSPORTS":
			IconName = VirtualSportsIcon;
			DisplayName = 'VIRTUAL SPORTS';
			break;
		case "ONEXBET":
			IconName = brandCheck ? PREMIUM_SPORTSBOOK_FUN : PREMIUM_SPORTSBOOK_LOGO;
			DisplayName = brandCheck ? 'B2B' :'PSB';
			break;
	}
	const { loading, data, error } = useQuery(GET_BALANCE_INFO, {
		variables: { input: { platformCodes: [info] } },
	});

	// if (loading) {
	// 	return (
	// 		<h4>Loading...</h4>
	// 	)
	// }
	if (error) {
		return null
	}
	const balanceInfoList: any = util.getValue(
		data,
		['game', 'balanceInfo'],
		[],
	);
	return (
		<SecondaryItemWrapper>
			<WalletIcon src={`${config.imageS3Host}${IconName}.png`} />
			<MainWallet propsState={true}>{balanceInfoList && balanceInfoList.length > 0 && balanceInfoList[0].platformCode === 'SLottery' ? 'Jackpot' : DisplayName}</MainWallet>
			<AmountWrapper>
				<MainAmount propsState={true}>
					{balanceInfoList && balanceInfoList.length > 0 ? balanceInfoList[0].balance : 0}
					<BalanceText>
						<Intl langKey="TITLE@BALANCE" />
					</BalanceText>
				</MainAmount>
			</AmountWrapper>
		</SecondaryItemWrapper>
	);
}

function PlatformBalanceList(props: any) {
	const [triggerOneClickRecycle, { recycleLoading, recycleError, recycleData }]: any = useMutation(ONE_CLICK_RECYCLE)
	const { platformInfoList, platformCode } = props;
	const [disabled, setDisabled] = useState(false)
	const [isLoading, setLoading] = useState(false);
	const { app: { brandId }, theme:{config:{showOnlyOnFun}} } = props;
	const [disableRefresh, setDisableRefresh] = useState(false);
	const loader = document.querySelector('.globalLoader');
	const disabledDuration = 30000;


	const refreshWallets = (showloader) => {
		if (showloader && loader) { loader.classList.add('globalLoaderShow') }
		setLoading(true);
		setDisableRefresh(true);
		props.refresh()
		setTimeout(() => {
			setLoading(false);
			if (showloader && loader) { loader.classList.remove('globalLoaderShow') }
		},20);

		setTimeout(()=>setDisableRefresh(false), disabledDuration)

	}

	const walletTransfer = () => {
		if (loader) { loader.classList.add('globalLoaderShow') }
		setDisabled(true);
		triggerOneClickRecycle()
			.then((response) => {
				const result = util.getValue(response, ['data', 'game', 'recycleTransfer'], {});
				const { success, message, data } = result;
				//if (loader) { loader.classList.add('globalLoaderShow') }
				if (success) {
					refreshWallets(false);
					if (loader) { loader.classList.remove('globalLoaderShow') }
					setTimeout(() => {
						setDisabled(false)
					}, disabledDuration)

				}
			})
			.catch((error) => {
				console.log('Error in one click recycle')
				if (loader) { loader.classList.remove('globalLoaderShow') }
			})
	}

	return (
		<StatementWrapper>
			<BalanceTransferWrapper theme={props.app.theme} customStyle={props.theme.image}>
				<BalanceBodyWrapper>

					<HeadSection>
						<MainItemWrapper>
							<MainWallet propsState={false}>
								<Icon name="main_wallet" />
								{props.ownPlatform.displayName}
							</MainWallet>
							<AmountWrapper>
								<MainAmount propsState={false}>{props.ownPlatform.balance.toFixed(2)}</MainAmount>
							</AmountWrapper>
						</MainItemWrapper>

						{!isLoading && platformCode.map((elm, i) => <BalanceTransferTemplate info={elm} key={i} brandCheck={showOnlyOnFun}/>)}

					</HeadSection>
					<ButtonSection>

						<TransferBtn disabled={disabled} onClick={walletTransfer}><Intl langKey="TITLE@TRANSFER_TO_MAINWALLET" /></TransferBtn>

						<RefreshBtn disabled={disableRefresh} onClick={() => refreshWallets(true)}></RefreshBtn>
					</ButtonSection>


				</BalanceBodyWrapper>
			</BalanceTransferWrapper>
		</StatementWrapper>

	)
};


export default withTheme(withAppContext(PlatformBalanceList));

//Styled Components
const BalanceText = styled.div`
	color: #000000;
	font-size: 11px;
	font-weight: 600;
`;

export const RefreshBtn = styled.button`
    width: 39px;
    height: 39px;
    display: flex;
    flex-direction: row;
    align-items: center;
	background: rgba(255, 255, 255, 0.8);
	background-image: url(${config.imageV3Host}/static/cashsite/${theme('image.refreshIcon')});
    background-size: 60%;
    background-repeat: no-repeat;
    /* background-color: ${theme('palette.secondary-background-color-new')}; */
    padding: 10px;
    border: none;
    background-position: center;
    cursor: pointer;
	border: 1px solid #423FC1;
	box-shadow: 0px 0px 40px rgba(16, 11, 73, 0.4);
	border-radius: 3px;
	:disabled{
		cursor: not-allowed;
	   }
`;

export const TransferBtn = styled.button`
    display: flex;
    align-items: center;
    padding: 10px;
    font-size: 13px;
    text-transform: capitalize;
    color: #fff;
	/* color:${theme('palette.homeAdvText')}; */
    border: none;
    cursor: pointer;
    margin-right: 10px;
	/* background: ${theme('palette.secondary-background-color-new')}; */
	background: ${theme('palette.secondary-background-color-linear-new1')};
	border: 1px solid #FFFFFF;
	/* box-shadow: 0px 0px 40px rgba(33, 30, 85, 0.4); */
	border-radius: 3px;

	:disabled{
     cursor: not-allowed;
	}
    
`;


export const ButtonSection = styled.div`
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
`;

export const HeadSection = styled.div`
	width: 100%;
    display: flex;
    flex-direction: row;
	flex-wrap: wrap;
    align-items: center;
    justify-content: space-between;
	gap: 10px;
    padding-bottom: 20px;
    margin-bottom: 20px;
    border-bottom: ${theme('palette.borderbottomBG')};
`;

export const MainWallet = styled.div<Pick<Props, 'propsState'>>`
	display: flex;
    align-items: center;
    gap: 10px;
    /* text-transform: capitalize; */
	text-transform: ${props => (props.propsState ? "uppercase" : "capitalize")};
    font-size: 13px;
	font-weight: 600;
	color: #000000;

	${IconWrapper} {
		width: 2.25em;
    	height: 2.25em;
	}
`;

export const MainAmount = styled.div<Pick<Props, 'propsState'>>`
	display: flex;
	align-items: center;
	justify-content: center;
	flex-direction: column;
	min-width: 117px;
    /* background: rgba(255, 255, 255, 0.5); */
	background: ${props => (props.propsState ? "rgba(255, 255, 255, 0.5)" : "")};
    /* border: 1px solid #FFFFFF; */
	border: ${props => (props.propsState ? "1px solid #FFFFFF" : "")};
    /* border-radius: 25px; */
	border-radius: ${props => (props.propsState ? "25px" : "")};
	color: #0EAD69;
	font-weight: 600;
    font-size: 15px;
	padding: 3px;
`;


export const AmountWrapper = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
`;

const SecondaryItemWrapper = styled.div`
	width: 49%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    padding: 10px;
	background:${theme('palette.walletTransfer')};
	 /* radial-gradient(50% 50% at 50% 50%, rgba(255, 255, 255, 0.9) 0%, rgba(255, 255, 255, 0.9) 100%); */
	border: 1px solid #FFFFFF;
	border-radius: 3px;
	height:62px;
`;

export const MainItemWrapper = styled.div`
	/* width: 180px; */
	width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    padding: 10px;
    /* margin-right: 20px; */
	background:${theme('palette.walletTransfer')};
	border: 1px solid #FFFFFF;
	border-radius: 3px;
	
	.default {
    background: rgba(255, 255, 255, 0.97);
    border: 1px solid rgba(255, 255, 255, 0.97);
	}
	
	.selected {
    background: linear-gradient(180deg, #F7FFFA 0%, #D8F7E6 100%);
    border: 1px solid #1A9669;
	}
`;

const WalletIcon = styled.img`
	width: 30px;
	height: 30px;
`;

export const BalanceBodyWrapper = styled.div`
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: centre;
    justify-content: space-between;
    /* padding: 3em; */
    /* background: rgba(238, 247, 255, 0.37); */
	/* background: ${theme('palette.secondary-background-color-linear-new')}; */
	/* background-color: ${(props: any) => (props.theme === 'Dark' ? theme('palette.transferDark') : theme('palette.transferLight'))};
    border: 1px solid #D6E9FB; */
    border-radius: 3px;
`;

export const TittleIcon = styled.div`
    width: 20px;
    height: 20px;
    display: flex;
    flex-direction: row;
    align-items: center;
    background-image: url(${TransferIcon});
    background-size: auto;
    background-repeat: no-repeat;
    margin-right: 5px;
`;

export const Tittle: any = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
	color: ${(props: any) => (props.theme === 'Dark' ? theme('new_whitelable_dark_font_color') : theme('palette.secondary-background-color-new'))};
    
    font-weight: 500;
    font-size: 15px;
    line-height: 18px;
    text-transform: capitalize;
    padding-bottom: 10px;
`;

export const HeaderBlock = styled.div`
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    padding-bottom: 10px;
`;

export const StatementWrapper = styled.div`
    width: 100%;
    display: flex;
    
`;

export const BalanceTransferWrapper: any = styled.div`
    width: 100%;
    display: flex;
    padding: 10px;
    /* background: #fff; */
    align-items: flex-start;
    justify-content: flex-start;
    flex-direction: column;
	padding-top: 20px;
	/* background-size: cover;
	background-position: 100%; */
`;


// const AmountWrapper = styled.div`
// 	${isMemberType(
// 		'CLIENT',
// 		css`
// 			color: ${withProp('theme.palette.primary-background', darken(0.1))};
// 		`,
// 		css`
// 			color: ${theme('palette.primary-background')};
// 		`,
// 	)};

// 	${media.md`
// 	color: ${theme('palette.primary-background')};
// 	font-weight: bold;
// 	`};
// `;

// const ItemWrapper = styled.div`
// 	display: flex;
// 	padding: 1.25em 1.5em;
// 	padding-left: 30px;
// 	justify-content: space-between;
// 	position: relative;
// 	flex-direction: column;

// 	&:not(:last-of-type) {
// 		border-bottom: 1px dashed #aaa;
// 		/* border-bottom: 1px solid ${withProp('theme.palette.body-background', diffColor(0.1))}; */
// 	}

// 	&:nth-of-type(2n) {
// 		/* background-color: ${withProp('theme.palette.body-background', diffColor(0.05))}; */
// 		background: transparent;
// 	}

// 	::before{
// 		content: "";
// 		position: absolute;
// 		width: 7px;
// 		height: 7px;
// 		background: ${theme('palette.primary-background')};
// 		left: 8px;
// 		top: calc(1.25em + 6px);
// 	}
// `;
