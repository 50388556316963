import React from 'react';

// Dependent Component
import Icon from '../../../common/PNGIcon';
import Spinner from '../../../common/Spinner';
import { ESlipState } from './OrderSlipContainer';
import UnmatchedSlipContainer, {
	IUnmatchedSlipProps,
} from './UnmatchedSlipContainer';
import Intl from '../../../common/Intl';
import {
	ActionButtonContainer,
	CancelButtonWrapper,
	ConfirmSection,
	FieldGroupWrapper,
	FieldLabel,
	FieldWrapper,
	IconButtonWrapper,
	InputField,
	InputSection,
	MessageWrapper,
	PlaceButtonWrapper,
	SlipEventName,
	SlipHeadingWrapper,
	SlipMask,
	SlipSelectionName,
	SlipTitleWrapper,
	SlipWrapper,
} from './slipStyles';

const SimpleUnmatchedSlip: React.SFC<IUnmatchedSlipProps> = props => {
	return (
		<UnmatchedSlipContainer {...props}>
			{unmatchedSlipStore => {
				const {
					// container props
					side,
					competitionId,
					eventId,
					selectionName,
					eventName,
					orderNumber,
					isMatchLoading,
					provider,

					// container state
					isUpdating,
					isCanceling,
					currentPrice,
					currentStake,
					message,

					// container methods
					goToEvent,
					handleTrashClick,
					handleUpdateOrder,
				} = unmatchedSlipStore;
				return (
					<SlipWrapper type={side}>
						{/* 有單正在更新時不給操作 */}
						{isMatchLoading && <SlipMask />}
						{isCanceling && <Spinner mode="client" />}
						{!isUpdating && message && (
							<MessageWrapper type={ESlipState.Error}>
								<Icon name="circle-x-color" />
								{message}
							</MessageWrapper>
						)}
						<SlipHeadingWrapper type={side}>
							<SlipTitleWrapper>
								<SlipEventName onClick={goToEvent} hasPath={competitionId && eventId}>
									{eventName}
								</SlipEventName>
								<SlipSelectionName>{selectionName}</SlipSelectionName>
							</SlipTitleWrapper>
							{!orderNumber.includes('unmatched') && (
								<IconButtonWrapper onClick={handleTrashClick}>
									<Icon name="x-orderslip" />
								</IconButtonWrapper>
							)}
						</SlipHeadingWrapper>
						<InputSection type={side}>
							<FieldGroupWrapper>
								<FieldWrapper>
									<FieldLabel>
										<Intl langKey="GLOBAL@ODDS" />
									</FieldLabel>
									<InputField value={currentPrice} type="number" readOnly />
								</FieldWrapper>
								<FieldWrapper>
									<FieldLabel>
										<Intl langKey="GLOBAL@STAKE" />
									</FieldLabel>
									<InputField value={currentStake} type="number" readOnly />
								</FieldWrapper>
							</FieldGroupWrapper>
						</InputSection>
						<ConfirmSection>
							<ActionButtonContainer isActive={true}>
								<PlaceButtonWrapper
									onClick={handleUpdateOrder}
									disabled={
										!currentStake ||
										parseFloat(currentStake) <= 0 ||
										!orderNumber ||
										orderNumber.includes('unmatched')
									}
								>
									<Icon name="circle-arrow-right-white" />
									{/* TODO: all intl */}
									Update Bet
								</PlaceButtonWrapper>
								<CancelButtonWrapper onClick={handleTrashClick}>
									<Icon name="trash-orderslip" />
									<Intl langKey="ORDERSLIP@CANCEL_ORDER" />
								</CancelButtonWrapper>
							</ActionButtonContainer>
						</ConfirmSection>
					</SlipWrapper>
				);
			}}
		</UnmatchedSlipContainer>
	);
};

export default SimpleUnmatchedSlip;
