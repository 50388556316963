import { useEffect } from 'react'
import PlaceholderImage from 'src/components/Placeholder';
import config from 'src/config/config';
import { withAppContext } from 'src/store/initAppContext';
import styled, { withTheme } from 'styled-components';
import { theme } from 'styled-tools';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import FooterContainer from "src/components/footer/footer";


const JetSetWithJetXPromo = (props) => {
    const { app: { brandId
    }, theme } = props;

    useEffect(() => {
        window.scrollTo(0, 0)
    }, [])

    const domain = (brandId == 31 || brandId == 32) ? "Yolo247" : (brandId == 39 || brandId == 40) ? 'FOMO7' : 'BaaziAdda';

    return (
        <>
            <InnerPromoWrapper>
                <Catch99ContentWrapper>
                    <JetXCRTopSec>
                        <JetXSetWrapper>
                            <JetXTopSecInnerBox>
                                <h4>Get Ready to for a rocket ride</h4>
                                <span className='underlines'></span>
                                <p>
                                    From November 1st to December 31st, 2024, get ready for the electrifying excitement of JetX! Experience daily adrenaline rushes with thrilling bonus four times throughout these two months. Each bonus rain gives you a chance to claim your share of FREE bets worth ₹7 lakhs! With a massive giveaway totaling ₹56 Lacs in enticing free bets over two months, this is your opportunity to ride the storm and elevate your betting game to new heights. Don’t miss out—join us and take flight with JetX!
                                </p>
                            </JetXTopSecInnerBox>
                            <JetXTopSecInnerBox>
                                <h4>Bonus Drops</h4>
                                <span className='underlines'></span>
                                <p>
                                    The {domain} clouds are set to burst with thrilling bonuses eight times over the next two months, creating an electrifying betting experience. Stay tuned to {domain}, as each bonus shower will bring a delightful surprise! Hint: Expect these surprises every week
                                </p>
                            </JetXTopSecInnerBox>
                        </JetXSetWrapper>
                        <JetXSetWrapperRight>
                            <JetXTopSecInnerBox>
                                <h4>Claim Your Free Bets</h4>
                                <span className='underlines'></span>
                                <p>
                                    Complete the required turnover and get ready for the {domain} JetX tournament. Once you’ve met the criteria, your well-earned free bets will be waiting for you!
                                </p>
                            </JetXTopSecInnerBox>
                            <JetXTopSecInnerBox>
                                <h4>Two-Month Weather Extravaganza</h4>
                                <span className='underlines'></span>
                                <p>
                                    JetX tournament isn’t just a one-day event—it’s a two-month-long spectacle! With a colossal total giveaway of ~₹56 lacs, each day brings a fresh chance to win big. Get ready to ride the waves of excitement and make the most of this extraordinary betting adventure!
                                </p>
                            </JetXTopSecInnerBox>
                        </JetXSetWrapperRight>
                    </JetXCRTopSec>
                    <JetXCRFourthSec>
                        <JetXCRFourthTop>
                            <JetXCRTopLeft>
                                <h2>With each bonus drop, INR 700,000 will be distributed upon completion of the following criteria</h2>
                                <span className='underlines'></span>
                            </JetXCRTopLeft>
                            <JetXCRTopRight>
                                <LazyLoadImage src={`${config.imageV3Host}/static/cashsite/${theme.image.jetxPromoPlane1}`} alt="jetxPromoIllus" effect="none" placeholder={<PlaceholderImage />} />
                            </JetXCRTopRight>
                        </JetXCRFourthTop>
                        <JetXFourthBottom>
                            <LazyLoadImage src={`${config.imageV3Host}/static/cashsite/${theme.image.JetSetWithjetxPromoTable}`} alt="jetxPromoTable" effect="none" placeholder={<PlaceholderImage />} />
                        </JetXFourthBottom>
                    </JetXCRFourthSec>
                    <JetXCRFinalSec>
                        <JetXCRFinalSecLeft>
                            <LazyLoadImage src={`${config.imageV3Host}/static/cashsite/${theme.image.jetxPromoPlane2}`} alt="jetxPromoPlane2" effect="none" placeholder={<PlaceholderImage />} />
                        </JetXCRFinalSecLeft>
                        <JetXCRFinalSecRight>
                            <JetXCRTermsTitle>
                                <LazyLoadImage src={`${config.imageV3Host}/static/cashsite/${theme.image.jetxPromoTC}`} alt="jetxPromoT&C" effect="none" placeholder={<PlaceholderImage />} />
                                <h6>Terms & Conditions</h6>
                            </JetXCRTermsTitle>
                            <JetXTermsBody>
                                <ol>
                                    <li>This promotion is exclusively for the game JetX & JetX 3 from November 1st to December 31st, 2024</li>
                                    <li>Bonus drops will occur every week.</li>
                                    <li>To be eligible, bets must achieve a minimum cashout of 1.90X.</li>
                                    <li>The minimum turnover required to qualify for the competition is INR 3 lacs.</li>
                                    <li>Each Bonus drop will have a prize pool of INR 7 lakhs, distributed among winners based on the highest turnover achievements.</li>
                                    <li>The total prize pool for all eight drops is INR 56 lacs.</li>
                                    <li>Multiple accounts will not be considered for promotion eligibility. Duplicate checking will be conducted, and {domain} reserves the right to disqualify players if collusion or foul play is detected.</li>
                                    <li>{domain} reserves the right to amend, suspend, or cancel the promotion at any time without prior notice. General Terms & Conditions apply.</li>
                                </ol>
                            </JetXTermsBody>
                        </JetXCRFinalSecRight>
                    </JetXCRFinalSec>
                </Catch99ContentWrapper>
            </InnerPromoWrapper>
            <FooterContainer />
        </>
    )
}

export default withTheme(withAppContext(JetSetWithJetXPromo));


//Styled Components

const Catch99ContentWrapper = styled.div`
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background: url(${config.imageV3Host}/static/cashsite/${theme('image.jetxPromoInnerBg')});
    background-position: top;
    background-size: cover;
    background-repeat: no-repeat;
    gap: 2em;

    .m-0 {
        margin: 0;
    }
    .p-0 {
        padding: 0rem 20rem;

        span{
            color: #E5A20D;
        }
    }

    a {
        width: 100%;
        height: auto;

        & span {
            display: flex !important;
            justify-content: center !important;

            & img {
                width: 60%;
                height: 80%;
            }
        }
    }

    .lazy-load-image-background {
        display: flex !important;
        justify-content: center !important;
    }
`;


const InnerPromoWrapper = styled.div`
    width: 98%;
    height: auto;
    /* border: 1px solid white; */
    border-radius: 1px;
    background: rgba(111,111,111,0.5);
    margin: 0em 1.2em;
    -webkit-backdrop-filter: brightness(0.5);
    backdrop-filter: brightness(0.5);
`

const JetXCRTopSec = styled.div`
    padding-top: 15rem;
    height: auto;
    width: 85%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-direction: column;
    gap: 3rem;
    margin-bottom: 10rem;
`

const JetXSetWrapper = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
`;

const JetXSetWrapperRight = styled(JetXSetWrapper)`
    justify-content: space-around;
`;

const JetXTopSecInnerBox = styled.div`
    width: 25%;
    height: auto;
    padding: 2em;
    border-radius: 35px;
    background: linear-gradient(180deg, rgba(0, 0, 0, 0.10) 0%, rgba(255, 255, 255, 0.00) 100%, rgba(0, 0, 0, 0.00) 100%);
    backdrop-filter: blur(40.39555358886719px);
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;

    & h4 {
        color: #FEDE11;
        font-size: 1.4375rem;
        font-style: italic;
        font-weight: 900;
        line-height: 1.59375rem; /* 110.87% */
        text-transform: capitalize;
        text-align: left;
        margin: 5px 0px;
    }

    & p {
        color: #FFF;
        text-align: justify;
        font-size: 0.875rem;
        font-style: normal;
        font-weight: 500;
        line-height: 128.5%; /* 1.12438rem */
        text-align: justify !important;
        padding: 0em;
    }

    & .underlines {
        width: 30%;
        height: 2px;
        background-color: #FEDE11;
        margin-top: 1em;
    }
`;

const JetXCRFourthSec = styled.div`
    background: 
        radial-gradient(57.02% 91.16% at 0% 0%,rgba(27,99,239,0.50) 0%,rgba(21,22,22,0.13) 80%),
        radial-gradient(circle at 0 100%, #0D0D17 0, #0D0D17 33px, transparent 33px) 100% 0%/35px 35px no-repeat,
        radial-gradient(circle at 100% 0, #0D0D17 0, #0D0D17 33px, transparent 33px) 0% 100%/35px 35px no-repeat,
        radial-gradient(circle at 0 0, #0D0D17 0, #0D0D17 33px, transparent 33px) 100% 100%/35px 35px no-repeat,
        linear-gradient(#0D0D17, #0D0D17) 50% 50%/calc(100% - 4px) calc(100% - 70px) no-repeat,
        linear-gradient(#0D0D17, #0D0D17) 50% 50%/calc(100% - 70px) calc(100% - 4px) no-repeat,
        linear-gradient(transparent 12%, #fede11 100%);

    border-radius: 35px;
    backdrop-filter: blur(40.39555358886719px);
    width: 65%;
    display: flex;
    flex-direction: column;
    gap: 20px;
    height: auto;
    padding: 6rem 4em;
    margin: 3em 0em;
    position: relative;

    & > .underlines {
        width: 6.5em;
        height: 2px;
        background-color: #FEDE11;
        margin-top: 1em;
    }
    .underlines {
        width: 6.5em;
        height: 2px;
        background-color: #FEDE11;
        margin-top: 0.5em;
    }

    & h2 {
        color: #FEDE11;
        text-align: left;
        font-size: 1.4375rem;
        font-style: italic;
        font-weight: 800;
        line-height: 1.59375rem; /* 110.87% */
        text-transform: capitalize;
        margin: 3px 0px;
    }

    & p {
        color: #FFF;
        font-size: 0.875rem;
        font-style: italic;
        font-weight: 400;
        line-height: normal;
        margin: 3px 0px;
    }

    & h6 {
        color: #FFF;
        text-align: justify;
        font-size: 0.875rem;
        font-style: normal;

        font-weight: 500;
        line-height: 120.5%; /* 0.86188rem */
        margin: 3px 0px;
        border-radius: 0.3125rem;
        background: #4E535F;
        padding: 5px;
    }

    & img {
        width: 100%;
        height: auto;
    } 
`

const JetXCRFourthTop = styled.div`
   display: flex;
   width: 100%;
`;

const JetXCRTopLeft = styled.div`
    width: 60%;
    display: flex;
    flex-direction: column;
    gap: 10px;
`;

const JetXCRTopRight = styled.div`
    width: 40%;
    position: absolute;
    right: -8rem;
    top: -8rem;

    & img {
        width: 550px;
        height: auto;
    } 
`;

const JetXFourthBottom = styled.div` 
    width: 100%;
    height: auto;
    margin-top: 1em;

    & img {
        width: 100%;
        height: auto;
    } 
`;

const JetXCRFinalSec = styled.div`
    width: 100%;
    height: auto;
    display: flex;
    justify-content: flex-end;
    gap: 10px;
    margin: 2em 0em;
    position: relative;
    padding-right: 12%;
`;

const JetXCRFinalSecLeft = styled.div`
     width: 40%;
     height: auto;
     position: absolute;
     left: 14vw;
     top: 0px;

    & img {
        width: 750px;
        height: auto;
    } 

    @media only screen and (min-width: 1500px){
        left: 20vw;
    }

    @media only screen and (min-width: 1900px){
        left: 24vw;
    }
`;

const JetXCRFinalSecRight = styled.div`
    width: 40%;
    height: auto;
    padding: 1em;
    display: flex;
    flex-direction: column;
    align-items: end;
    background: 
        radial-gradient(59.66% 59.66% at 0% 6.02%,rgba(132,92,255,0.72) 0%,rgba(12,12,22,0.2) 100%),
        radial-gradient(circle at 0 100%, #03040A 0, #03040A 33px, transparent 33px) 100% 0%/35px 35px no-repeat,
        radial-gradient(circle at 100% 0, #03040A 0, #03040A 33px, transparent 33px) 0% 100%/35px 35px no-repeat,
        radial-gradient(circle at 0 0, #03040A 0, #03040A 33px, transparent 33px) 100% 100%/35px 35px no-repeat,
        linear-gradient(#03040A, #03040A) 50% 50%/calc(100% - 4px) calc(100% - 70px) no-repeat,
        linear-gradient(#03040A, #03040A) 50% 50%/calc(100% - 70px) calc(100% - 4px) no-repeat,
        linear-gradient(transparent 12%, #AA4B8E 100%);
        
    border-radius: 35px;
`;

const JetXCRTermsTitle = styled.div`
   padding: 1em;

   & img {
        width: 100%;
        height: auto;
    } 

    h6 {
        color: #FFF;
        text-align: center;
        font-size: 1.10781rem;
        font-style: normal;
        font-weight: 700;
        line-height: 1.48819rem; /* 134.333% */
        letter-spacing: 0.09969rem;
        text-transform: capitalize;
        margin: 3px 0px;
    }
`;

const JetXTermsBody = styled.div`
    width: 100%;
    height: auto;
  
    & ol {
        padding: 1em 2em;
        margin: 0px;
    }

     & li {
        color: #FFF;
        text-align: justify;
        font-size: 0.875rem;
        font-style: normal;
        font-weight: 600;
        line-height: normal;
        letter-spacing: -0.035rem;
        text-transform: capitalize;
        margin: 15px 0px;
     }
`;