import isEqual from 'lodash/isEqual';
import React, { Fragment, useState } from 'react';
import styled, { css } from 'styled-components';
import { ifProp, theme } from 'styled-tools';
import isEmpty from 'lodash/isEmpty';
// Dependent components
import { Amount, Income } from '../../../common/NumberDisplay';
import Openable from '../../../common/Openable';
import Icon, { IconWrapper } from '../../../common/PNGIcon';
// import Spinner from '~/component/common/Spinner';
import TabDoubleOpen from 'src/images/tab_double_open.png';
import { color } from 'src/sass-files/variable';
import {
	Col,
	ColMask,
	MobileMatchTableHeading,
	TableBodyRow,
	TableBodyWrapper,
	TableHeadRow,
	TableWrapper,
} from '../../../common/table/baseTableStyles';
import MobileOrderSlip from '../../order/orderslip/MobileOrderSlip';
import SpecialFancyConfig from '../../SpecialFancyConfig';
import Odd from '../../table/feature/Odd';
import Intl from '../../../common/Intl';

// Context
import { IMatchListContext, withMatchListContext } from '../../../../store/MatchListContext';
import { IOrderListContext, IOrderTicket, withOrderListContext } from '../../../../store/OrderListContext';

// Utils
import { calFancyExposure } from '../../table/feature/exposureCalculate';
import { IEventInfo, IFancyInfo, IMarketInfo } from '../../../../graphql/schema';
import { withAppContext } from '../../../../store/initAppContext';
import media from '../../../../util/mediaUtil';
import { isMemberType } from '../../../../util/styleUtil';
import util from '../../../../util/util';

const {
	tabsignup,
	innerBackHeadBg,
	innerLayHeadBg,
	secondarycolor,
	innerOddsLayColor,
	innerBackLightBg,
	innerBackDarkBg,
	innerLayDarkBg,
	innerLayLightBg,
	titleHeadColor,
} = color

interface IFancyTableRowProps {
	selectionInfoList: IFancyInfo[];
	betArray: IOrderTicket[];
	matchedArray: any;
	marketInfo: IMarketInfo;
	eventInfo: IEventInfo;
	// from withAppContext
	app: {
		exchangeRate: number;
		isMobileWidth: boolean;
	};
}
class FancyTableRowComponent extends React.Component<IFancyTableRowProps> {
	shouldComponentUpdate(nextProps: any) {
		const {
			marketInfo: oldMarket,
			matchedArray: oldMatchedArray,
			betArray: oldBetArray,
			selectionInfoList: oldSelectionArray,
		}: any = this.props;
		const {
			marketInfo: newMarket,
			matchedArray: newMatchedArray,
			betArray: newBetArray,
			selectionInfoList: newSelectionArray,
		}: any = nextProps;
		if (!isEqual(oldSelectionArray, newSelectionArray)) {
			return true;
		}
		if (!isEqual(oldMarket, newMarket)) {
			return true;
		}

		return !isEqual({ oldMatchedArray, oldBetArray }, { newMatchedArray, newBetArray });
	}
	render() {
		const { selectionInfoList, betArray = [], matchedArray = [], marketInfo, eventInfo, app } = this.props;
		const { isOrder, maxPerOrderCredit, maxPerMarketExposure, marketName, status: marketStatus } = marketInfo;
		const currentBetExposure = betArray.reduce((pv: number, item: any) => {
			const type = item.type || item.side;
			const size = item.currentSize || item.stake;
			const price = item.currentPrice || item.odds;
			if (!price || !size) {
				return pv;
			}
			if (type === 'BACK') {
				pv = util.numAdd(pv, size * -1);
			} else if (type === 'LAY') {
				pv = util.numAdd(pv, util.numMultiply(size, util.numAdd(price, -1)) * -1);
			}
			return pv;
		}, 0);
		const matchedExposureList = calFancyExposure(matchedArray);
		const currentMatchedExposure = matchedExposureList.reduce((pv: any, item: any) => {
			const exposure = util.numMultiply(item.exposure, app.exchangeRate);
			!pv && (pv = exposure);
			exposure < pv && (pv = exposure);
			return pv;
		}, 0);
		const currentExposure = util.numAdd(currentBetExposure, currentMatchedExposure);
		const oddsGroup = selectionInfoList.map((fancyInfo, i) => {
			const {
				// Code Change - Manoj (added back and lay)
				back,
				lay,
				selectionId,
				backPrice,
				backValue,
				layPrice,
				layValue,
				isRunning,
				layStatus,
				backStatus,
			} = fancyInfo;
			return (
				<Fragment key={`${marketInfo.marketId}_${selectionId}_${i}`}>
					{(!isOrder || isRunning || marketStatus === 'SUSPENDED') && (
						<ColMask>
							{marketStatus === 'SUSPENDED' ? (
								<Intl langKey="TABLE@SUSPENDED" />
								// 'SUSPENDED'
							) : isRunning ? (
								<Intl langKey="TABLE@BALL_RUNNING" />
								// 'BALL RUNNING'
							) : (
								''
							)}
						</ColMask>
					)}
					<OddsGroupWrapper>
						<OddBlock>
							<Odd
								key={`${marketInfo.marketId}_${selectionId}_${i}_NO`}
								// Code Change - Manoj (instead of layPrice)
								price={isEmpty(fancyInfo) ? 0 : `${util.getValue(fancyInfo, 'lay[0].price')}`}
								size={layValue}
								type="LAY"
								fancyInfo={fancyInfo}
								marketInfo={marketInfo}
								eventInfo={eventInfo}
								isOrder={isOrder}
								isBest={true}
								sideStatus={layStatus}
							/>
						</OddBlock>
						<OddBlock>
							<Odd
								key={`${marketInfo.marketId}_${selectionId}_${i}_YES`}
								// Code Change - Manoj (instead of backPrice)
								price={isEmpty(fancyInfo) ? 0 : `${util.getValue(fancyInfo, 'back[0].price')}`}
								size={backValue}
								type="BACK"
								fancyInfo={fancyInfo}
								marketInfo={marketInfo}
								eventInfo={eventInfo}
								isOrder={isOrder}
								isBest={true}
								sideStatus={backStatus}
							/>
						</OddBlock>



					</OddsGroupWrapper>
				</Fragment>
			);
		});

		const maxPerOrderCreditDisplay = util.numMultiply(maxPerOrderCredit || 0, app.exchangeRate);
		const maxPerMarketExposureDisplay = util.numMultiply(maxPerMarketExposure || 0, app.exchangeRate);
		return (
			<TableBodyRow>
				<Openable>
					{delegation => {
						const { isOpened, toggleOpen } = delegation;
						return (
							<TableBodyMore>
								<TopRow>
									<NameCol>
										<NameColContent>
											<NameWrapper isShow={matchedArray.length > 0}>
												<NameText>{marketName}</NameText>
												<PLButton onClick={toggleOpen}>
													<Icon name="circle-down-chevron" />
												</PLButton>
											</NameWrapper>
											{(currentMatchedExposure !== 0 || currentExposure !== 0) && (
												<MaxExposureWrapper>
													<Intl langKey="TABLE@MAX_EXPOSURE" />:
													{/* {'MAX EXPOSURE'}:  */}
													<Income>{currentMatchedExposure}</Income>
													{betArray.length > 0 && (
														<>
															{' ➟ '}
															<Income>
																{util.numAdd(currentBetExposure, currentMatchedExposure)}
															</Income>
														</>
													)}
												</MaxExposureWrapper>
											)}
										</NameColContent>
									</NameCol>
									<OddsCol>{oddsGroup}</OddsCol>
									<InfoCol>
										<InfoColContent>
											<span>
												<Intl langKey="TABLE@MAX_BET" />:{' '}
												{/* {'MAX BET'}: */}
												<Amount fractionDigits={0}>{maxPerOrderCreditDisplay}</Amount>
											</span>
											<span>
												<Intl langKey="TABLE@MAX_MARKET" />:{' '}
												{/* {'MAX MARKET'}: */}
												<Amount fractionDigits={0}>{maxPerMarketExposureDisplay}</Amount>
											</span>
										</InfoColContent>
									</InfoCol>
								</TopRow>
								{app.isMobileWidth && <MobileOrderSlip selfMarketId={marketInfo.marketId} />}
								{isOpened && (
									<OpenableRow>
										<DetailRow>
											{/* <DetailPlaceholderCol /> */}
											<DetailTableCol>
												<DetailTableWrapper>
													<DetailTableHeadRow>
														<RunsCol>
															<Intl langKey="GLOBAL@RUNS" />
															{/* {'RUNS'} */}
														</RunsCol>
														<PLCol>
															<Intl langKey="GLOBAL@PL" />
															{/* {'PL'} */}
														</PLCol>
													</DetailTableHeadRow>
													<TableBodyWrapper>
														{matchedExposureList.map((item: any, index: number) => {
															return (
																<TableBodyRow key={index}>
																	<RunsCol>{item.describe}</RunsCol>
																	<PLCol>
																		<Income>
																			{util.numMultiply(
																				item.exposure,
																				app.exchangeRate,
																			)}
																		</Income>
																	</PLCol>
																</TableBodyRow>
															);
														})}
													</TableBodyWrapper>
												</DetailTableWrapper>
											</DetailTableCol>
										</DetailRow>
									</OpenableRow>
								)}
							</TableBodyMore>
						);
					}}
				</Openable>
			</TableBodyRow>
		);
	}
}

const FancyTableRow = withAppContext(FancyTableRowComponent);

interface IFancyTableProps extends IMatchListContext, IOrderListContext {
	loading: boolean;
	error: any;
	fancyDataList: IMarketInfo[];
	eventInfo: IEventInfo;
}

const FancyTable: React.FC<IFancyTableProps> = props => {
	const { loading, fancyDataList, eventInfo } = props;
	const { eventId } = eventInfo;
	const { betArray } = props.orderListContext;
	const { matched } = props.matchListContext;
	const [showMatchInfo, setMatchInfo] = useState(true)
	if (loading) {
		return (
			<TableWrapper>
				{/* <Spinner mode="client" /> */}
				{'LOADING'}
			</TableWrapper>
		);
	}

	const marketBetArray = betArray.reduce((pv: any, order: any) => {
		!pv[order.marketInfo.marketId] && (pv[order.marketInfo.marketId] = []);
		pv[order.marketInfo.marketId].push(order);
		return pv;
	}, {});

	const marketMatchedArray = matched.reduce((pv: any, order: any) => {
		!pv[order.marketId] && (pv[order.marketId] = []);
		pv[order.marketId].push(order);
		return pv;
	}, {});

	const toggleMatchInfo = () => {
		setMatchInfo(!showMatchInfo)
	}

	if (!Array.isArray(fancyDataList) || fancyDataList.length === 0) {
		return <></>;
	} else {
		return (
			<SpecialFancyConfig>
				{(sfConfig: any[]) => {
					const events = sfConfig.reduce((res: any[], item: any) => [...res, ...item.events], []);
					const event = events.find((item: any) => item.id === eventId);
					// const tableHeading = event ? event.tableHeading : <Intl langKey="TABLE@SESSION_RUNS" />;
					const tableHeading = event ? event.tableHeading : 'FANCY';
					return (
						<>
							{/* <MobileMatchTableHeading>
								<Icon name={'logo-icon-red'} />
								{tableHeading}
							</MobileMatchTableHeading> */}
							<RowWrap>
								<TabBody>

									<TableHeadRow>
										<ToggleContent onClick={() => toggleMatchInfo()}>
											{showMatchInfo ? <Icon name="minus-square" /> : <Icon name="plus-square" />}
										</ToggleContent>
										<SlantDiv>
											<ActiveBM>
												<MatchContent>
													<NameCol>{tableHeading}</NameCol>
												</MatchContent>
												{/* Active Bm - 
								<span>(06/10)</span> */}
											</ActiveBM>
										</SlantDiv>
										<OddsCol>
											<LayTypeTitle>
												<Intl langKey="GLOBAL@NO" />
												{/* {'No'} */}
											</LayTypeTitle>
											<BackTypeTitle>
												<Intl langKey="GLOBAL@YES" />
												{/* {'Yes'} */}
											</BackTypeTitle>

										</OddsCol>
										{/* <InfoCol /> */}
									</TableHeadRow>
									{
										showMatchInfo &&
										<TableBodyWrapper>
											{/* {loading && <Spinner mode="light" />} */}


											<>
												{fancyDataList &&
													fancyDataList
														// 0的時候要排在最後面
														.sort((a, b) =>
															!a.sortOrder || !b.sortOrder
																? (b.sortOrder || 0) - (a.sortOrder || 0)
																: a.sortOrder - b.sortOrder,
														)
														.map((marketInfo, i) => {
															// FancyInfo[]
															const selectionInfoList = marketInfo.list;
															return (
																<FancyTableRow
																	selectionInfoList={selectionInfoList}
																	betArray={
																		marketInfo.marketId
																			? marketBetArray[marketInfo.marketId]
																			: []
																	}
																	matchedArray={
																		marketInfo.marketId
																			? marketMatchedArray[marketInfo.marketId]
																			: []
																	}
																	marketInfo={marketInfo}
																	eventInfo={eventInfo}
																	key={`${marketInfo.marketId}_${i}`}
																/>
															);
														})}
											</>


										</TableBodyWrapper>
									}

								</TabBody>
							</RowWrap>
						</>
					);
				}}
			</SpecialFancyConfig>
		);
	}
};

export default withMatchListContext(withOrderListContext(FancyTable, ['betArray']), [
	'matched',
	'unmatched',
	'loading',
	'error',
]);

// Styled Components


const MatchContent = styled.div`
	margin-left: 10px;
`

const ToggleContent = styled.div`
position: absolute;
right: 15px;
top: 15px;
z-index: 99;
cursor: pointer;
img {
	width: 20px;
	height: 20px;
}
`

const SlantDiv = styled.div`
height: auto;
background-color: black;
background-color: ${theme('palette.headerBgColor')};
width: 300px;
line-height: 28px;
position: relative;
color: black !important;
	&::after {
		width:50px;
		background-color: ${theme('palette.headerBgColor')};
		position:absolute;
		height:100%;
		content:' ';
		right:-22px;
		top:0;
	  	transform: skew(45deg);
	}
`

const TableBodyMore = styled.div`
	width: 100%;
	display: flex;
	align-items: center;
	justify-content: flex-end;
	flex-wrap: wrap;
`;

const OpenableRow = styled.div`
	width: 45.3%;
    display: flex;
`;

const TopRow = styled.div`
	width: 100%;
    display: flex;
`;

const OddBlock = styled.div`
	width: 16.4%;
    display: flex;
`;

const ActiveBM = styled.div`
	display: flex;
	align-items: center;
	letter-spacing: 0.07em;
	text-transform: uppercase;
	color: rgba(255, 255, 255);
	font-weight: 500;
	font-size: 12px;
	padding-left: 20px;

	span {
		color: #FFFFFF;
	}
`;

const BackTypeTitle = styled.div`
	// width: 20%;
	width: 16.01%;
    height: 40px;
    padding: 5px 10px;
    display: flex;
    align-items: center;
    justify-content: center;
    // background: ${innerBackHeadBg};
    letter-spacing: 0.07em;
    text-transform: capitalize;
    font-size: 13px;
    
    margin-right: 1px;
	color:#0375CC;

`;

const LayTypeTitle = styled.div`
	// width: 20%;
	width: 16.01%;
    height: 40px;
    padding: 5px 10px;
    display: flex;
    align-items: center;
    justify-content: center;
    // background: ${innerLayHeadBg};
    letter-spacing: 0.07em;
    text-transform: capitalize;
    font-size: 13px;
    
    margin-left: 1px;
	color:#E03C3C;
`;

const TabOpenIcon = styled.div`
    width: 25px;
    height: 25px;
    background-image: url(${TabDoubleOpen});
    background-size: auto;
    background-repeat: no-repeat;
    background-position: center;
    cursor: pointer;
    margin: 0px 10px;
    border-radius: 5px;
`;

const RowWrap = styled.div`
    width: 100%;
    display: flex;
    flex-direction: coloumn;
    align-items: flex-start;
    background: #EAF4FD;
    margin-bottom: 10px;
    
`;

const TabBody = styled.div`
    width: 100%;
    display: flex;
    flex-direction: column;
`;

const TabSection = styled.div`
	width: 25%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    background: linear-gradient(90deg, #F02B2B 43.81%, #BE1616 100%);
    color: ${secondarycolor};
	padding: 20px 10px; 
	font-size: 14px;
`;

export const NameCol = styled(Col)`
	flex-basis: 35%;
	padding-left: 2em;
	align-items: center;
	min-width: 0;
    font-size: 14px;

	${media.lg`
        flex-basis: 50%;
		padding-left: 1em;

		${isMemberType(
	'AGENT',
	css`
				background-color: ${theme('palette.sidebar-background')};
			`,
)}
    `};

	${media.sm`
		flex-basis: 55%
	`};
`;

const OddsGroupWrapper = styled.div`
	display: flex;
	flex-grow: 1;
	width: 100%;
	justify-content: center;

	&:not(:last-child) {
		margin-bottom: 0.25em;
	}
`;

// Wrapping one or more OddsGroupWrapper, laying out vertically
export const OddsCol = styled(Col)`
	// /* decorations */
	// border-right: 1px solid rgba(128, 128, 128, 0.25);
	// border-left: 1px solid rgba(128, 128, 128, 0.25);

	// /* for aligning ColMask */
	// position: relative;
	// z-index: 0;

	// /* As a flex container */
	// flex-direction: row;
	// align-items: center;
	// justify-content: space-evenly;
	// flex-wrap: wrap;

	// /* as a flex item */
	// flex-basis: calc(43% / 2);

	flex-basis: 65%;
	justify-content: center;
	position: relative;
	min-width: 0;
	padding: 0;

	${media.lg`
		flex-basis: 50%;
		border-right: 0;

		${isMemberType(
	'AGENT',
	css`
				background-color: ${theme('palette.sidebar-background')};
			`,
)}
	`};

	${media.sm`
		flex-basis: 45%
	`};
	> span {
		width: 50%;
		display: flex;
		justify-content: center;
	}
`;

export const InfoCol = styled(Col)`
	flex-basis: calc(43% / 2);
	position: absolute;
    right: 5%;

	${media.lg`
		display: none;
    `};
`;

// Col Content

export const NameText = styled.span`
	/* overflow: hidden; */
	/* text-overflow: ellipsis; */
	/* white-space: nowrap; */
	// font-size: 14px;
	font-size: 12px;
`;

export const NameWrapper: any = styled.span`
	font-weight: normal;
	display: flex;
	align-items: center;

	button {
		display: ${ifProp('isShow', 'flex', 'none')};
		font-size: 14px;
		margin-left: auto;
		border: none;
		background: none;
	}
`;

export const MaxExposureWrapper = styled.span`
	font-size: 12px;
	margin-top: 0.25em;
	display: flex;
	align-items: center;

	/* IncomeWrapper */
	> span {
		margin-left: 0.5em;
	}

	${media.sm`
		display: block;
		> span {
			display: block;
			margin-top: 0.25em;
			margin-left: 0;
		}
	`};
`;
export const NameColContent = styled.div`
	display: flex;
	flex-direction: column;
	width: 100%;
`;

export const InfoColContent = styled.div`
	display: flex;
	flex-direction: column;
	font-size: 11px;
	text-align: right;
`;

// Detail table
const DetailRow = styled(TableBodyRow)`
	/* 強制斷行 */
	width: 100%;
	display: flex;
	// border-top: 1px solid rgba(128, 128, 128, 0.2);
	
`;

const DetailPlaceholderCol = styled(Col)`
	flex-basis: 57%;

	${media.lg`
        display: none;
    `};
`;

const DetailTableCol = styled(Col)`
	flex-basis: calc(43%);
	// border-left: 1px solid rgba(128, 128, 128, 0.2);
	flex-direction: column;
	flex-grow: 1;
`;

const RunsCol = styled(Col)`
	flex-basis: 50%;
	justify-content: center;
	border-right: 1px solid rgba(128, 128, 128, 0.2);
	font-weight: bold;
`;

const PLCol: any = styled(Col)`
	flex-basis: 50%;
	justify-content: center;
`;

// Customize detail table styles
const DetailTableWrapper = styled(TableWrapper)`
	margin-bottom: 0;
	box-shadow: none;
	border: 1px solid rgba(128, 128, 128, 0.2);
`;

const DetailTableHeadRow = styled(TableHeadRow)`
	${media.lg`
		/* Cancel removal */
        display: flex;
	`};
	
	${RunsCol} {
		color: #fff;
	}

	${PLCol} {
		color: #fff;
	}
`;

const PLButton = styled.button`
	all: unset;
	margin-left: auto;
	cursor: pointer;
	flex-shrink: 0;
`;
