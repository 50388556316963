import React, { useEffect } from 'react'
import { withAppContext } from 'src/store/initAppContext';
import styled, { withTheme } from 'styled-components';
import { LazyLoadImage } from "react-lazy-load-image-component";
import config from "src/config/config";
import PlaceholderImage from 'src/components/Placeholder';
import { theme } from 'styled-tools';
import FooterContainer from 'src/components/footer/footer';

function GalaxsysTournament(props: any) {
    const { app: {
        brandId
    }, theme } = props;

    const { showOnlyOnFomo } = theme.config;

    useEffect(() => {
        window.scrollTo(0, 0)
    }, [])

    const domain = (brandId == 31 || brandId == 32) ? "YOLO247" : (brandId == 39 || brandId == 40) ? "FOMO7" : 'iW247';

    return (
        <>
            <InformationWrapper>
    
                  <TopContentWrap>
                       <Title>{showOnlyOnFomo ? "Galaxsys IPL Challenge" : "Galaxsys Tournament: Climb the Ranks & Win Big!"}</Title>
                       <p className='mb-10'><span>Get Ready for the Ultimate Showdown!</span></p>
                       <p className='mb-10'>
                          Brace yourself for an electrifying tournament, running from <strong>March 15 - May 31</strong>, where excitement meets massive rewards! 
                          With a <br /> whopping <span>₹45,00,000</span> prize pool and 520 winners, every bet brings you closer to victory.
                       </p>
                       <p className='mt-10'>
                         Play, compete, and claim your share—the more you play, the bigger the rewards!
                       </p>
                  </TopContentWrap>
                  <MaxWrap>
                  <HTCSecWrap>
                   
                      <HTCSecLeft>
                          <Title>How to Compete?</Title>
                          <HTCPoint><img src={`${config.imageV3Host}/static/cashsite/brand/6/image/tickIconYellow.png`} alt="" /><p> Earn 1 point for every ₹10 wagered.</p></HTCPoint>
                          <HTCPoint><img src={`${config.imageV3Host}/static/cashsite/brand/6/image/tickIconYellow.png`} alt="" /><p> Play any of the following featured games: Rocketon, Maestro, Crash, Cash Show, Crasher, Starlight, Tower Rush.</p></HTCPoint>
                          <HTCPoint><img src={`${config.imageV3Host}/static/cashsite/brand/6/image/tickIconYellow.png`} alt="" /><p> Place higher bets for the chance to win bigger rewards, with no minimum multiplier required.</p></HTCPoint>
                          <HTCPoint><img src={`${config.imageV3Host}/static/cashsite/brand/6/image/tickIconYellow.png`} alt="" /><p> Check out your progress on the Galaxsys real-time Leaderboard! You can view your total points and see where you rank.</p></HTCPoint>
                      </HTCSecLeft>
                      <HTCSecRight>
                             <LazyLoadImage
                                src={`${config.imageV3Host}/static/cashsite/${theme.image.galaxsysTourFirstIllus}`}
                                alt="Eligible Games"
                                effect="none"
                                placeholder={<PlaceholderImage />}
                            />
                      </HTCSecRight>
              
                  </HTCSecWrap>
                  </MaxWrap> 
                  <HTCSmallSecs>
                        <HTCSmallBox>
                             <LazyLoadImage
                                src={`${config.imageV3Host}/static/cashsite/${theme.image.galaxsysTour02Illus}`}
                                alt="Eligible Games"
                                effect="none"
                                placeholder={<PlaceholderImage />}
                            />
                            <p>
                                Play more, climb higher, and grab your share of ₹45,00,000!
                            </p>
                        </HTCSmallBox>
                        <HTCSmallBox>
                             <LazyLoadImage
                                src={`${config.imageV3Host}/static/cashsite/${theme.image.galaxsysTour03Illus}`}
                                alt="Eligible Games"
                                effect="none"
                                placeholder={<PlaceholderImage />}
                            />
                            <p>
                               The race to the top starts March 15
                            </p>
                        </HTCSmallBox>
                  </HTCSmallSecs>

                  <AYITitle><Title>Are you in?</Title></AYITitle>
                  
                  <PrizeDetailsWrap>
                       <h3>Tournament Breakdown</h3>
                       <p>The competition is split into 6 batches, each with its own prize pool:</p>
                       <PrizeBoxWrap>
                             <LazyLoadImage
                                src={`${config.imageV3Host}/static/cashsite/${theme.image.galaxsysTourPrizeTable01}`}
                                alt="prize"
                                effect="none"
                                placeholder={<PlaceholderImage />}
                            />
                             <LazyLoadImage
                                src={`${config.imageV3Host}/static/cashsite/${theme.image.galaxsysTourPrizeTable02}`}
                                alt="prize"
                                effect="none"
                                placeholder={<PlaceholderImage />}
                            />
                             <LazyLoadImage
                                src={`${config.imageV3Host}/static/cashsite/${theme.image.galaxsysTourPrizeTable03}`}
                                alt="prize"
                                effect="none"
                                placeholder={<PlaceholderImage />}
                            />
                             <LazyLoadImage
                                src={`${config.imageV3Host}/static/cashsite/${theme.image.galaxsysTourPrizeTable04}`}
                                alt="prize"
                                effect="none"
                                placeholder={<PlaceholderImage />}
                            />
                             <LazyLoadImage
                                src={`${config.imageV3Host}/static/cashsite/${theme.image.galaxsysTourPrizeTable05}`}
                                alt="prize"
                                effect="none"
                                placeholder={<PlaceholderImage />}
                            />
                             <LazyLoadImage
                                src={`${config.imageV3Host}/static/cashsite/${theme.image.galaxsysTourPrizeTable06}`}
                                alt="prize"
                                effect="none"
                                placeholder={<PlaceholderImage />}
                            />
                       </PrizeBoxWrap>
                  </PrizeDetailsWrap>

                  <TermsAndConditionWrap>
                      <Title>Terms & Conditions</Title>
                      <ul>
                        <li>The {showOnlyOnFomo ? "Galaxsys IPL Challenge" : "Galaxsys Tournament"} promotion is valid for all new and existing players.</li>
                        <li>Multiple accounts will not be eligible for this promotion. All players will undergo duplicate checking.</li>
                        <li>{domain} reserves the right to disqualify players involved in collusion or any foul play related to this promotion.</li>
                        <li>{domain} reserves the right to amend, cancel, reclaim, or refuse any promotion at its discretion.</li>
                        <li>{domain} General Terms & Conditions apply.</li>
                      </ul>
                  </TermsAndConditionWrap>
    
            </InformationWrapper>
            <FooterContainer />
        </>
    )
}

export default withTheme(withAppContext(GalaxsysTournament));


// Styled Component.
const InformationWrapper = styled.div`
    width: 98%;
    height: auto;
    padding: 4rem 0rem 4rem 0rem;
    background: rgba(111, 111, 111, 0.5);
    margin: 0em 1.2em 0 1.2em;
    border: 1px solid #fff;
    border-top-color: transparent;
    backdrop-filter: brightness(0.5);    
    display: flex;
    flex-direction: column;
    background: url(${config.imageV3Host}/static/cashsite/${theme("image.galaxsysTourMainInnerBg")});
    background-position: top !important;
    background-size: cover;
    background-repeat: no-repeat;


    .mb-10 {
       margin-bottom: 20px;
    }

    .mt-10 {
       margin-top: 20px;
    }
`;

const Title = styled.h2`
    color: ${theme('palette.galaxyTourText')};
    text-align: center;
    font-size: 25px !important;
    font-style: normal;
    font-weight: 700 !important;
    line-height: 179.5%; /* 50.26px */
    text-transform: capitalize;
    margin: 2px 0px;
`


const TopContentWrap = styled.div`
  width: 90%;
  margin: auto;
  height: auto;
  padding: 10px 0px;
  max-width: 1400px;

  & h2 {
    color: ${theme('palette.galaxyTourText')};
    text-align: center;
    font-size: 25px;
    font-style: normal;
    font-weight: 700;
    line-height: 179.5%; /* 50.26px */
    text-transform: capitalize;
    margin: 2px 0px;
  }

  & p {
    color: #FFF;
    text-align: center;
    font-size: 17px;
    font-style: normal;
    font-weight: 500;
    line-height: 150%; /* 27px */
    text-transform: capitalize;
    margin: 2px 0px;

    & span {
      color: #FEDD10;
      font-weight: 700;
    }
  }
`

const HTCSecWrap = styled.div`
  display: flex;
  justify-content: center;
  gap: 5px;
  margin: 7em auto 2em auto;
  width: 100%;
  height: auto;
  border-radius: 20px;
  border: 2px solid ${theme('palette.galaxyTourBorder')};
  background: linear-gradient(135deg, rgba(255, 255, 255, 0.10) 0%, rgba(220, 145, 255, 0.10) 100%);
  position: relative;
`

const HTCSecLeft = styled.div`
  width: 55%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  padding: 30px;
  gap: 10px;

   p {
    color: #FAFAFF;
    font-size: 17.599px;
    font-style: normal;
    font-weight: 700;
    line-height: 150%; /* 26.398px */
    text-transform: capitalize;
    margin: -6px 0px 5px 0px;

  }
`

const HTCSecRight = styled.div`
  width: 45%;

  & img {
    width: 530px;
    height: 514px;
    object-fit: contain;
    position: absolute;
    right: 0;
    top: -80px;
  }
`

const HTCPoint = styled.div`
  display: flex;
  align-items: flex-start;
  gap: 10px;
  padding-left: 20px;
  margin-bottom: 20px;

  & img {
      width: 15px;
      height: 15px;
    }
`

const HTCSmallSecs = styled.div`
  width: 90%;
  margin: auto;
  height: auto;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 5rem;
  gap: 15px;
`

const HTCSmallBox = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 200px;
  height: 170px;
  border-radius: 20px;
  border: 2px solid ${theme('palette.galaxyTourBorder')};
  background: linear-gradient(135deg, rgba(255, 255, 255, 0.10) 0%, rgba(220, 145, 255, 0.10) 100%); 
  justify-content: center;
  padding: 10px;
 

  & img {
    width: 85%;
    height: auto;
    object-fit: contain;
    margin-top: -90px;
  }

  & p {
    color: #FFF;
    text-align: center;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 145%; /* 23.2px */
    text-transform: capitalize;
    margin: 2px 0px;
  }
`

const AYITitle = styled.div`
  margin: 5rem 0 2rem 0;
  width: 100%;
  height: auto;
  border: 2px solid #FEDD10;
  border-right: none;
  border-left: none;
  background: linear-gradient(135deg, rgba(255, 255, 255, 0.10) 0%, rgba(220, 145, 255, 0.10) 100%);
`

const PrizeDetailsWrap = styled.div`
  width: 90%;
  margin: auto;
  height: auto;

  h3 {
    color: #FEDD10;
    text-align: center;
    font-size: 24px;
    font-style: normal;
    font-weight: 800;
    line-height: 179.5%; /* 50.26px */
    text-transform: capitalize;
    margin: 1px 0px;
  }

  & p {
    color: #FFF;
    text-align: center;
    font-size: 18px;
    font-style: normal;
    font-weight: 500;
    line-height: 150%; /* 27px */
    text-transform: capitalize;
    margin: 1px 0px;
  }
`

const PrizeBoxWrap = styled.div`
  margin: 2rem auto 2rem auto;
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  justify-content: center;
  gap: 10px;
  max-width: 1400px;

  & span {
    width: 48%;
    height: auto;
    margin: 2em 0;

    & img {
      width: 97%;
    }
  }
`

const TermsAndConditionWrap = styled.div`
  width: 90%;
  margin: 2rem auto 2rem auto;
  max-width: 1400px;
  height: auto;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 15px 10px;
  border-radius: 20px;
  backdrop-filter: blur(2px);
  border: 2px solid ${theme('palette.galaxyTourTermsBorder')};
  background: linear-gradient(135deg, rgba(255, 255, 255, 0.03) 0%, rgba(220, 145, 255, 0.03) 100%);

  & ul {
    padding-left: 30px;
    list-style-type: "🔹";

    & li {
        color: #FFF;
        text-align: justify;
        font-size: 18px;
        font-style: normal;
        font-weight: 400;
        line-height: 175%;
        text-transform: capitalize;
        margin: 5px 0px;
    }
  }

  & h2 {
    padding-left: 15px;
  }
`

const MaxWrap = styled.div`
    width: auto;
    max-width: 1400px;
    margin: 0 auto;
`