import React, { Component } from 'react'
import cookieUtil from 'src/util/cookieUtil';
import { ECookieName } from 'src/util/utilModel';
import {
  useLocation,
  useNavigate,
  useParams,
  Navigate
} from "react-router-dom";


export default function ProtectedRoute({ children }: { children: JSX.Element }) {
  const memberID = cookieUtil.get(ECookieName.COOKIE_MEMBERID);
  const token = cookieUtil.get(ECookieName.COOKIE_TOKEN);
  let auth = !!(token && memberID);
  let location = useLocation();

  if (!auth) {
    return <Navigate to="/login" state={{ from: location }} replace />;
  }

  return children;
}



// function withRouter(Component) {
//   function ComponentWithRouterProp(props) {
//     let location = useLocation();
//     let navigate = useNavigate();
//     let params = useParams();
//     return (
//       <Component
//         {...props}
//         router={{ location, navigate, params }}
//       />
//     );
//   }

//   return ComponentWithRouterProp;
// }

// const ProtectedRoute = ({ children }) => {
//   const auth = cookieUtil.get(ECookieName.COOKIE_IS_DEFAULT_PASSWORD) !== '1';
  
//   return auth ? children : <Navigate to="/changepassword" />
// }

// export default withRouter(ProtectedRoute);

