import React,{Component} from 'react';
import { Link } from "react-router-dom";
import config from 'src/config/config';
import styled, { css, withTheme } from 'styled-components';
import { ifProp, theme, withProp } from 'styled-tools';
import {color} from '../../sass-files/variable';
// import GamstopLogo from 'src/images/gamestop_png.png';
// import BegambleAwareLogo from 'src/images/be-gamble-awarewhite.png';
// import GameCareLogo from 'src/images/gamcare_logo.png';
import Intl from '../common/Intl'
import { Tittle as FooterTittle } from '../udb/commonStyle';
import { withAppContext } from 'src/store/initAppContext';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import PlaceholderImage from 'src/components/Placeholder';

function FooterContainer(props: any) {
    const { app: { brandId }, theme } = props
    const { config: { showFooterNetbanking,showPartners,showFooterastropay,showBlog,showNews,showAffiliate,showPress,ShowPrincipalAwardLogo,showjoshlogo, showOnlyOnFomo, showFun88Sponcers,sponsorship, customercare, showhelpLink } } = theme;
    const hostname = window.location.hostname;
 
    return (
            <Container>
                <CopyRight>
                {ShowPrincipalAwardLogo &&
                    <>
                    {(showOnlyOnFomo) ? (
                        <SponsersWrapperFomo>
                            <div className='fomoSponsorColumn'>
                                <Link to="/sponsors">
                                    <LogoContain $propsAdd className="fomosponsor">
                                        <Tittle $propsAdd className='fomosponsorMargin'><Intl langKey="FOOTER@PrincipleSponcerFomo" /></Tittle>
                                        <LazyLoadImage src={`${config.imageV3Host}/static/media/principleSponcerFomo.png`} effect="none" width="50%" placeholder={<PlaceholderImage />} alt="Oficial Principle Sponsor" />
                                    </LogoContain>
                                    <CenterLine></CenterLine>
                                    <LogoContain $propsAdd>
                                        <Tittle $propsAdd><Intl langKey="FOOTER@OficialTittleSponcer" /></Tittle>
                                        <LazyLoadImage src={`${config.imageV3Host}/static/media/oficialTittleSponcer.png`} effect="none" width="80%" placeholder={<PlaceholderImage />} alt="Oficial Tittle Sponsor" />
                                    </LogoContain>
                                    <CenterLine></CenterLine>
                                    <LogoContain $propsAdd>
                                        <Tittle><Intl langKey="FOOTER@OfficialOverseasBackSponsor" /></Tittle>
                                        <LazyLoadImage src={`${config.imageV3Host}/static/media/officialOverseasBackSponsorF.png`} effect="none" width="70%" placeholder={<PlaceholderImage />} alt="Official Overseas Back Sponsor" />
                                    </LogoContain>
                                    <CenterLine></CenterLine>
                                    <LogoContain $propsAdd>
                                        <Tittle><Intl langKey="FOOTER@PrincipalSponsors" /></Tittle>
                                        <LazyLoadImage src={`${config.imageV3Host}/static/media/principalSponsorsFomodesk.png`} effect="none" width="80%" placeholder={<PlaceholderImage />} alt="principal sponsors" />
                                    </LogoContain>
                                </Link>
                            </div>
                            <div className='sponsorColumn'>
                                <Link to="/sponsors">
                                    <LogoContain $propsAdd>
                                        <Tittle><Intl langKey="FOOTER@PrinciplePartner" /></Tittle>
                                        <LazyLoadImage src={`${config.imageV3Host}/static/media/principlePartnerFomoNew.png`} effect="none" width="80%" placeholder={<PlaceholderImage />} alt="Principle Partner" />
                                    </LogoContain>
                                    <CenterLine></CenterLine>
                                    <LogoContain $propsAdd>
                                        <Tittle><Intl langKey="FOOTER@AssociatePartner" /></Tittle>
                                        <LazyLoadImage src={`${config.imageV3Host}/static/media/associatePartnerFomoNew.png`} effect="none" width="80%" placeholder={<PlaceholderImage />} alt="Associate Partner" />
                                    </LogoContain>
                                    <CenterLine></CenterLine>
                                    <LogoContain $propsAdd>
                                        <Tittle><Intl langKey="FOOTER@Associatesponsors" /></Tittle>
                                        <LazyLoadImage src={`${config.imageV3Host}/static/media/associateSponsorFomo.png`} effect="none" width="80%" placeholder={<PlaceholderImage />} alt="Associate sponsor" />
                                    </LogoContain>
                                </Link>
                            </div>
                        </SponsersWrapperFomo>
                    ) : (
                        <>
                            {showPartners ?
                                <SponsersWrapperFomo>
                                    <div className='sponsorColumn'>
                                        <Link to="/sponsors">
                                            <LogoContain $propsAdd>
                                                <Tittle><Intl langKey="FOOTER@PrincipalSponsorsOne" /></Tittle>
                                                <LazyLoadImage src={`${config.imageV3Host}/static/media/PrincipleSponsor23Yolo.png`} effect="none" width="40%" placeholder={<PlaceholderImage />} alt="yolo Principle Sponsor" />
                                            </LogoContain>
                                            <CenterLine></CenterLine>
                                            <LogoContain>
                                                <Tittle><Intl langKey="FOOTER@PrincipalSponsorsTwo" /></Tittle>
                                                <FooterLogoWrap>
                                                    <div>
                                                        <LazyLoadImage src={`${config.imageV3Host}/static/media/DubaiCapitalsLogoNew.png`} width="100px" effect="none" placeholder={<PlaceholderImage />} alt="yolo Principle Sponsor" />
                                                        <LazyLoadImage src={`${config.imageV3Host}/static/media/yoloGames.png`} effect="none" width="60%" placeholder={<PlaceholderImage />} alt="yolo Principle Sponsor" />
                                                    </div>
                                                </FooterLogoWrap>
                                            </LogoContain>
                                            <CenterLine></CenterLine>
                                            <LogoContain $propsAdd>
                                                <Tittle><Intl langKey="FOOTER@PrincipalSponsorsThree" /></Tittle>
                                                <LazyLoadImage src={`${config.imageV3Host}/static/media/GwaliorCheetahsEngSponsor.png`} effect="none" width="60%" placeholder={<PlaceholderImage />} alt="yolo Principle Sponsor" />
                                            </LogoContain>
                                        </Link>
                                    </div>
                                    <div className='sponsorColumn'>
                                        <Link to="/sponsors">
                                            <LogoContain>
                                                <Tittle><Intl langKey="FOOTER@SeriesGoldSponsorOne" /></Tittle>
                                                <FooterLogoWrap>
                                                    <div>
                                                        <LazyLoadImage src={`${config.imageV3Host}/static/media/SeriesgoldSponnsorYolo.png`} width="75%" effect="none" placeholder={<PlaceholderImage />} alt="yolo Series Gold Sponsor" />
                                                    </div>
                                                </FooterLogoWrap>
                                            </LogoContain>
                                            <CenterLine></CenterLine>
                                            <LogoContain $propsAdd>
                                                <Tittle><Intl langKey="FOOTER@SeriesGoldSponsorTwo" /></Tittle>
                                                <LazyLoadImage src={`${config.imageV3Host}/static/media/SeriesgoldSponnsor2024Yolo.png`} effect="none" width="65%" placeholder={<PlaceholderImage />} alt="yolo Series Gold Sponsor" />
                                            </LogoContain>
                                            <CenterLine></CenterLine>
                                            <LogoContain $propsAdd>
                                                <Tittle><Intl langKey="FOOTER@EngagementPartnerYolo" /></Tittle>
                                                <LazyLoadImage src={`${config.imageV3Host}/static/media/EngagementPartnerYolo2025.png`} effect="none" width="100%" placeholder={<PlaceholderImage />} alt="yolo Engagement Partner" />
                                            </LogoContain>
                                        </Link>
                                    </div>
                                </SponsersWrapperFomo>
                                : <></>
                            }
                        </>
                    )}
                    {showFun88Sponcers &&
                        <SponsersWrapperFun>
                            <Link to="/sponsors">
                                <LogoContain $propsAdd>
                                    <LazyLoadImage src={`${config.imageV3Host}/static/media/funTitleSponsorSeason10.png`} effect="none" width="100%" placeholder={<PlaceholderImage />} alt="Sponsor" />
                                    <FomoTittle><Intl langKey="FOOTER@TitleSponsorSeason10" /></FomoTittle>
                                    <FomoSubTittle>Dabang Delhi K.C (DKCC)</FomoSubTittle>
                                </LogoContain>
                                <LogoContain $propsAdd>
                                    <LazyLoadImage src={`${config.imageV3Host}/static/media/officialGlobalAmbassador2023-25.png`} effect="none" placeholder={<PlaceholderImage />} alt="Sponsor" />
                                    <FomoTittle><Intl langKey="FOOTER@OfficialGlobalAmbassador2023-25" /></FomoTittle>
                                    <FomoSubTittle>Dale Steyn</FomoSubTittle>
                                </LogoContain>
                                <LogoContain $propsAdd>
                                    <LazyLoadImage src={`${config.imageV3Host}/static/media/officialShirtSponsorEPLfrom2017-20.png`} effect="none" placeholder={<PlaceholderImage />} alt="Sponsor" />
                                    <FomoTittle><Intl langKey="FOOTER@OfficialShirtSponsorEPLFrom2017-20" /></FomoTittle>
                                    <FomoSubTittle>Newcastle United F.C</FomoSubTittle>
                                </LogoContain>
                                <LogoContain $propsAdd>
                                    <LazyLoadImage src={`${config.imageV3Host}/static/media/globalBrandAmbassador2023-24.png`} effect="none" placeholder={<PlaceholderImage />} alt="Sponsor" />
                                    <FomoTittle><Intl langKey="FOOTER@GlobalBrandAmbassador2023-24" /></FomoTittle>
                                    <FomoSubTittle>Iker Casillas</FomoSubTittle>
                                </LogoContain>
                                <LogoContain $propsAdd>
                                    <LazyLoadImage src={`${config.imageV3Host}/static/media/officialBettingPartnerSince2012.png`} effect="none" placeholder={<PlaceholderImage />} alt="Sponsor" />
                                    <FomoTittle><Intl langKey="FOOTER@OfficialBettingPartnerSince2012" /></FomoTittle>
                                    <FomoSubTittle>Tottenham Hotspur F.C</FomoSubTittle>
                                </LogoContain>
                                <LogoContain $propsAdd>
                                    <LazyLoadImage src={`${config.imageV3Host}/static/media/officialShirtSponsorBurnleyFC2015.png`} effect="none" placeholder={<PlaceholderImage />} alt="Sponsor" />
                                    <FomoTittle><Intl langKey="FOOTER@OfficialShirtSponsorBurnleyFCin2015" /></FomoTittle>
                                    <FomoSubTittle>Burnley F.C</FomoSubTittle>
                                </LogoContain>
                            </Link>
                        </SponsersWrapperFun>
                    }
                    </>
                }

                <Certificate>
                    <Tittle><Intl langKey="FOOTER@CERTIFICATIONS" /></Tittle>
                    {(brandId == 31 || brandId == 32) && <CertificateTitle><CertificateText><Intl langKey="FOOTER@CERTIFICATIONSTEXT" /></CertificateText></CertificateTitle>}
                    <CertificateImg>
                        <LazyLoadImage src={`${config.imageV3Host}/static/media/${theme.image.FooterGcbCertificateImg}`} effect="none" alt="gcb certificate" placeholder={<PlaceholderImage />} visibleByDefault={false} width="70%" />
                    </CertificateImg>
                </Certificate>

                    <Tittle><Intl langKey="FOOTER@GAMINGPARTNERS"/></Tittle>
                    <Link to="/live-casino/ezugi">
                        {<LazyLoadImage src={`${config.imageV3Host}/static/media/${theme.image.ezugiLogoNov24}`} effect="none" placeholder={<PlaceholderImage/>} visibleByDefault={false}/>}
                    </Link>
                    <Link to="/live-casino/ag"><LazyLoadImage src={`${config.imageV3Host}/static/media/aGLogoNew.png`} effect="none" placeholder={<PlaceholderImage/>} visibleByDefault={false}/></Link>
                    <Link to="/live-casino/betgames"><LazyLoadImage src={`${config.imageV3Host}/static/media/betGamesLogoNew.png`} effect="none" placeholder={<PlaceholderImage/>} visibleByDefault={false}/></Link>
                    <Link to="/live-casino/evolution">
                        {<LazyLoadImage src={`${config.imageV3Host}/static/media/${theme.image.evolutionGamesNov24}`} effect="none" placeholder={<PlaceholderImage/>} visibleByDefault={false}/>}
                    </Link>
                    {/* <Link to="/live-casino/betsoft"><LazyLoadImage src={`${config.imageV3Host}/static/media/betSoftLogoNew.png`} effect="none" placeholder={<PlaceholderImage/>} visibleByDefault={false}/></Link> */}
                    <Link to="/slots/netent"><LazyLoadImage src={`${config.imageV3Host}/static/media/netentLogoNew.png`} effect="none" placeholder={<PlaceholderImage/>} visibleByDefault={false}/></Link>
                    <Link to="/live-casino/tvbet">
                        <LazyLoadImage src={`${config.imageV3Host}/static/media/${theme.image.tvbetLogoNov24}`}effect="none" placeholder={<PlaceholderImage/>} visibleByDefault={false}/>
                    </Link>
                    <Link to="/live-casino/super-spade">
                        <LazyLoadImage src={`${config.imageV3Host}/static/media/${theme.image.superSpadeLogoNov24}`} effect="none" placeholder={<PlaceholderImage/>} visibleByDefault={false}/>
                    </Link>
                    {/* <Link to="/slots/pragmatic"><LazyLoadImage src={`${config.imageV3Host}/static/media/pragmaticplayCasinosNew.png`} effect="none" placeholder={<PlaceholderImage/>} visibleByDefault={false}/></Link> */}
                    {/* <Link to="/slots/spinmatic"><LazyLoadImage src={`${config.imageV3Host}/static/media/spinmaticLogoNew.png`} effect="none" placeholder={<PlaceholderImage/>} visibleByDefault={false}/></Link> */}
                  <Link to="/live-casino/supernowa"><LazyLoadImage src={`${config.imageV3Host}/static/media/supernowaLogoNew.png`} effect="none" placeholder={<PlaceholderImage />} visibleByDefault={false} /></Link>
                   {/* <Link to="/live-casino/sexy-gaming"><LazyLoadImage src={`${config.imageV3Host}/static/media/sexygaminglogo.png`} effect="none" placeholder={<PlaceholderImage/>} visibleByDefault={false}/></Link> */}
                    <Link to="/sportsbook"><LazyLoadImage src={`${config.imageV3Host}/static/media/BTILogoNew.png`} effect="none" placeholder={<PlaceholderImage/>} visibleByDefault={false}/></Link>
                    <Link to="/live-casino/7mojos"><LazyLoadImage src={`${config.imageV3Host}/static/media/7mojosIcon.png`} effect="none" placeholder={<PlaceholderImage/>} visibleByDefault={false}/></Link>
                    <Link to="/instant-games/spribe">
                        <LazyLoadImage src={`${config.imageV3Host}/static/media/${theme.image.spribeLogoFooterNov24}`} effect="none" placeholder={<PlaceholderImage/>} visibleByDefault={false}/>
                    </Link>
                </CopyRight>

                <CopyRight>
                    <Tittle><Intl langKey="FOOTER@PAYMENTMETHODS"/></Tittle>
                    {showFooterNetbanking && <NonClick><LazyLoadImage src={`${config.imageV3Host}/static/media/${theme.image.internetBankingNewNov24}`} effect="none" placeholder={<PlaceholderImage/>} visibleByDefault={false}/></NonClick>}
                    <NonClick><LazyLoadImage src={`${config.imageV3Host}/static/media/${theme.image.upiLogoNewNov24}`} effect="none" placeholder={<PlaceholderImage/>} visibleByDefault={false}/></NonClick>
                    <NonClick><LazyLoadImage src={`${config.imageV3Host}/static/media/${theme.image.gpayLogoNewNov24}`} effect="none" placeholder={<PlaceholderImage/>} visibleByDefault={false}/></NonClick>
                    <NonClick><LazyLoadImage src={`${config.imageV3Host}/static/media/paytmLogoNew.png`} effect="none" placeholder={<PlaceholderImage/>} visibleByDefault={false}/></NonClick>
                   {showFooterastropay && <NonClick><LazyLoadImage src={`${config.imageV3Host}/static/media/astropayLogoNew.png`} effect="none" placeholder={<PlaceholderImage/>} visibleByDefault={false}/></NonClick>}
                </CopyRight>

                <CopyRight>
                    <Tittle><Intl langKey="FOOTER@CONNECTWITHUS"/></Tittle>
                    <div className="socialMediaWrapper">
                    <a href={brandId==33 || brandId ==34 ? "https://www.facebook.com/iw247official" : 
                    (brandId == 39 || brandId == 40) ? 'https://www.facebook.com/fomo7.comofficial' : 
                    (brandId == 46 || brandId == 47) ? 'https://www.facebook.com/Fun88India' :  
                    'https://www.facebook.com/yolo247in'} target="_blank"> 
                        <LazyLoadImage src={`${config.imageV3Host}/static/media/FacebookIcon.svg`} effect="none" placeholder={<PlaceholderImage/>} visibleByDefault={false}/>
                        </a>
                        {showjoshlogo && <a href={brandId==33 || brandId ==34 ? 'https://share.myjosh.in/profile/3059c2d0-b9c1-4eed-b493-8b57ae397fe1?u=0x264dd8e597944a86' : (brandId == 39 || brandId == 40) ? 'https://share.myjosh.in/profile/a42d070d-0361-4c36-8049-343c9b1ce5d5?u=0x6fd11362a1c063fe' : 'https://share.myjosh.in/profile/e8aa1ac4-1807-40cf-b237-8af587fea618?u=0x66048681f02789dc' }target="_blank">
                            <LazyLoadImage src={`${config.imageV3Host}/static/media/joshAppIcon.png`} effect="none" placeholder={<PlaceholderImage/>} visibleByDefault={false}/>
                        </a>}

                        <a href={brandId==33 || brandId ==34 ? "https://www.instagram.com/iw247official/" : 
                        (brandId == 39 || brandId == 40) ? 'https://www.instagram.com/fomo7_com?igsh=MW90czJkOTl5NXZ4Nw==' : 
                        (brandId == 46 || brandId == 47) ? 'https://www.instagram.com/fun88indofficial/' :
                        'https://www.instagram.com/yolo247_in'} target="_blank"> 
                        <LazyLoadImage src={`${config.imageV3Host}/static/media/InstIcon.svg`} effect="none" placeholder={<PlaceholderImage/>} visibleByDefault={false}/>
                        </a>

                        <a href={brandId==33 || brandId ==34 ? "https://x.com/iw247official" : 
                        (brandId == 39 || brandId == 40) ? 'https://x.com/FOMO7official' : 
                        (brandId == 46 || brandId == 47) ? 'https://x.com/Fun88India' : 
                        'https://twitter.com/Yolo247Official'} target="_blank"> 
                        <LazyLoadImage src={`${config.imageV3Host}/static/media/TwitterIcon.svg`} effect="none" placeholder={<PlaceholderImage/>} visibleByDefault={false}/>
                        </a>

                        <a href={brandId==33 || brandId ==34 ? "https://www.youtube.com/@iW247Official" : 
                        (brandId == 39 || brandId == 40) ? 'https://www.youtube.com/channel/UCt4FXXC494EcHrA6wtnqcng' : 
                        (brandId == 46 || brandId == 47) ? 'https://www.youtube.com/@fun88india' : 
                        'https://www.youtube.com/@yolo247.official/' } target="_blank"> 
                        <LazyLoadImage src={`${config.imageV3Host}/static/media/YoutubeIcon.svg`} effect="none" placeholder={<PlaceholderImage/>} visibleByDefault={false}/>
                        </a>

                        <a href={brandId==33 || brandId ==34 ? "https://t.me/Baaziadda" : 
                        (brandId == 39 || brandId == 40) ? 'https://t.me/fomo7official' : 
                        (brandId == 46 || brandId == 47) ? 'https://t.me/fun88indiachannel' : 
                        'https://t.me/Yolo247'} target="_blank"> 
                        <LazyLoadImage src={`${config.imageV3Host}/static/media/TelegramIcon.svg`} effect="none" placeholder={<PlaceholderImage/>} visibleByDefault={false}/>
                        </a>

                        <a href={brandId==33 || brandId ==34 ? "https://whatsapp.com/channel/0029VayYatB11ulOO5EOw830" : 
                        (brandId == 39 || brandId == 40) ? 'https://whatsapp.com/channel/0029Vaj0hwb30LKXnWS0de0g' : 
                        (brandId == 46 || brandId == 47) ? 'https://whatsapp.com/channel/0029VaXc6B0BKfhw2Fo7UH0Q' : 
                        'https://whatsapp.com/channel/0029VanJL8D5EjxyYpKdI11r'} target="_blank"> 
                        <LazyLoadImage src={`${config.imageV3Host}/static/media/whatsApp_icon_desk.png`} effect="none" placeholder={<PlaceholderImage/>} visibleByDefault={false}/>
                        </a>
                    </div>
                </CopyRight>

                <CopyRight>
                    <a href="https://www.gamstop.co.uk/" target="_blank"><LazyLoadImage src={`${config.imageV3Host}/static/media/gamestopLogo.png`} effect="none"  placeholder={<PlaceholderImage/>} visibleByDefault={false}/></a>
                    <a href="https://www.begambleaware.org/" target="_blank"><LazyLoadImage src={`${config.imageV3Host}/static/media/${theme.image.beAwareLogoNov24}`} effect="none"   placeholder={<PlaceholderImage/>} visibleByDefault={false}/></a>
                    <a href="https://www.gamcare.org.uk/" target="_blank"><LazyLoadImage src={`${config.imageV3Host}/static/media/${theme.image.gamcareLogoNov24}`} effect="none"  placeholder={<PlaceholderImage/>} visibleByDefault={false}/></a>
                    <Span><LazyLoadImage src={`${config.imageV3Host}/static/media/${theme.image.eighteenPlusLogoNov24}`} effect="none"  placeholder={<PlaceholderImage/>}/></Span>
                    <Span><Intl langKey="FOOTER@COPYRIGHT"/></Span>
                </CopyRight>

                {/* <CopyRight>
                    Yolo247 is a brand managed by SNK Entertainment N.V. (10692), a Curacao registered company whose registered address is 7 Abraham De Veerstraat, Curacao. <br/> SNK Entertainment N.V. Limited is licensed and regulated in Curacao by the Gaming Curacao.
                </CopyRight> */}
                <NewLogoWrapper>
                     {/* <LazyLoadImage src={`${config.imageV3Host}/static/cashsite/brand/6/icon/Gaming-Curacao-Logo.png`} effect="none" placeholder={<PlaceholderImage/>} visibleByDefault={false}/> */}
                </NewLogoWrapper>

                <PolicyWrapper>
                <Link to="/terms-conditions">	 <Intl langKey="FOOT@TERMSANDCONDITIONS" />  |  </Link>
                <Link to="/responsible-gambling">  <Intl langKey="FOOT@RESPONSIBLEGAMBLING" />  | </Link>
                <Link to="/privacy-policy">	  <Intl langKey="FOOT@PRIVACYPOLICY" />  | </Link>
                {/* <Link to="/disconnection-policy">  <Intl langKey="FOOT@DISCONNECTPOLICY" /> | </Link> */}
                <Link to="/kyc-policy">  <Intl langKey="FOOT@KYCPOLICY" />  | </Link>
                <Link to="/exclusion-policy">  <Intl langKey="FOOT@EXCLUSIONPOLICY" />  | </Link>
                <Link to="/aml-policy"> <Intl langKey="FOOT@AMLpolicy" /> |  </Link>
                <Link to="/about-us">  <Intl langKey="FOOT@ABOUTUS" />  | </Link>
                <Link to="/faq">  <Intl langKey="FOOT@FAQ" />  | </Link>
                {showAffiliate && <Link to='/affiliate'><Intl langKey="Header@Affiliate" /></Link> }
               {showBlog && <a href={`https://${hostname}/blog/`}> | <Intl langKey="FOOT@blog" /> </a> }
               {showNews && <a href="https://www.yolo247.news/"> | <Intl langKey="FOOT@news" /> </a> }
               {showPress && <a href={`https://${hostname}/press/`}> | <Intl langKey="FOOT@Press" /> </a> }
               {sponsorship && <a href={`https://${hostname}/sponsorship/`}> | <Intl langKey="FOOT@sponsorship" /> </a> }
               {customercare && <a href={`${customercare}`}> | <Intl langKey="FOOT@customercare" /> </a> }
               {showhelpLink && <a href='https://www.gofun88.in/help/'> | <Intl langKey="FOOT@help" /> </a> }


                    {/* <Link to="/rules-regulations">  <Intl langKey="FOOT@RULES" /> |</Link>
                    <Link to="/restricted-territories">  <Intl langKey="FOOT@RESTRICTEDTERRITORIES" /> </Link> */}
                </PolicyWrapper>
            </Container>
    )
}

export default withTheme(withAppContext(FooterContainer));

//Styled Components

const CenterLine = styled.div`
    /* width: 2px; */
    height: 106px;
    padding: 0 1px;
    background: linear-gradient(180deg, #E6A238 0%, #FDEF9C 47.92%, #DB921A 100%);
    align-self: center;
`;

const LogoContain = styled.div<{ $propsAdd?: boolean }>`
    width: 50%;
    display: flex;
    flex-direction: column;
    align-items: center;
    /* gap: 1rem; */
    padding: 1rem;
    padding-top: 0;
    transition: all 0.2s ease-in-out 0s;
    justify-content: center;

    img {
      max-width: 100%;

        ${(props) => props.$propsAdd && css`
            width: 202px;
        `}
    }

    .fomosponsorMargin {
        margin-bottom: 1.5rem;
    }
`;

const SponsersWrapper = styled.div`
    width: 50%;
    display: flex;
    align-items: flex-start;
    justify-content: space-around;
    padding: 1rem;
    padding-top: 0;
    transition: all 0.2s ease-in-out 0s;
`;

const SponsersWrapperFun = styled.div`
    width: 100%;
    display: flex;
    align-items: flex-end;
    justify-content: space-around;
    padding: 1rem;
    padding-top: 0;
    transition: all 0.2s ease-in-out 0s;

    a {
        width: 100% !important;
        align-items: flex-end !important;
    }
`;

const SponsersWrapperFomo = styled.div`
    width: 90%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 1rem;
    padding-top: 0;
    transition: all 0.2s ease-in-out 0s;

    .sponsorColumn, .fomoSponsorColumn {
        display: flex;
        align-items: flex-start;

        .fomosponsor {
            padding-bottom: 2.5rem;
        }

        a {
            width: 100% !important;
            align-items: flex-start !important;
        }
    }

    .fomoSponsorColumn {
        width: 70%;
    }
`;

const NonClick = styled.div`
    width: 8%;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 5px 10px;
    border-radius: 5px;
    transition: all 0.2s ease-in-out 0s;
`;

const CertificateImg = styled(NonClick)`
    width: 13%;
    padding: 5px 15px;

    a {
        width: 100% !important;
    }
`;

const Tittle = styled(FooterTittle)<{ $propsAdd?: boolean; }>`
    width: 100%;
    color: ${theme('palette.footerTittleColor')};;
    text-transform: capitalize;
    font-weight: 500;
    font-size: 16px;
    text-align: center;
    margin-bottom: 10px;

    ${props => props.$propsAdd &&
    css`
        font-size: 14px;
    `};
`;

const FomoTittle = styled(FooterTittle)`
    width: 100%;
    color: ${theme('palette.footerTittleColor')};
    text-transform: capitalize;
    font-weight: 500;
    font-size: 16px;
    text-align: center;
    margin-bottom: 10px;
    font-weight: bold;
`;

const FomoSubTittle: any = styled.div`
    width: 100%;
    color: #888;
    text-transform: capitalize;
    font-weight: 500;
    font-size: 15px;
    text-align: center;
    margin-bottom: 10px;
    font-weight: bold;
`;

const Container: any = styled.div`
    width: 100%;
    background: ${theme('palette.footerbgColor')};
    position: relative;
`;

const CopyRight = styled.div`
    width: 100%;
    display: flex;
	align-items:center;
	justify-content: center;
    flex-wrap: wrap;
    color: ${theme('palette.footerDefaultColor')};
    font-size: 15px;
    text-align: center;
	line-height: 20px;
	padding-left: 10px;
    padding: 30px;
    padding-bottom: 10px;
    margin: 0 auto;

	a {
        /* width: 122px; */
        width: 8%;
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 5px 10px;
        border-radius: 5px;
		transition: all 0.2s ease-in-out 0s;
	}

	a:hover {
        background-color: ${theme('palette.footerLogoHoverBg')};
	}

    img {
        max-width: 100%;
        transition: all 0.2s ease-in-out 0s;
    }

    .socialMediaWrapper {
        width: auto;
        display: flex;
        align-items: center;
        justify-content: center;

        a {
            width: 35px;
            height: 35px;
            margin: 0px 5px;
            display: flex;
            padding: 0;

            img {
                width: 100%;
                border: ${theme('palette.footerSocialMediaBorder')};
                border-radius: 20px;
            }

            :hover {
                background-color: transparent;

                img {
                    border: ${theme('palette.footerSocialMediaBorderHover')};
                }
            }
        }
    }
`;

const Certificate = styled(CopyRight)`
    padding: 5px 0 30px 0;
`;

const PolicyWrapper = styled.div`
    font-size: 15px;
	display: flex;
	justify-content:center;
	padding-bottom:20px;
	align-items: center;
	padding-left: 10px;
    width: 100%;
    margin: 0 auto;
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    line-height: 18px;

    a {
        color: ${theme('palette.footerPolicyTextColor')};
        font-size: 13px;
        /* opacity: 0.8; */
        opacity: ${theme('palette.footerPolicyTextOpacity')};
        padding:5px;
        text-decoration: none;
        position: relative;

        :after {
            left: 0;
            bottom: -2px;
            position: absolute;
            width: 0%;
            margin: 0 auto;
            height: 1px;
            background: ${theme('palette.footerPolicyTextHoverLine')};
            content: '';
            position: absolute;
            right: 0;
        }
    }

    a:hover {
            /* opacity: 1;
            -webkit-transition: all 0.2s ease 0s;
		    transition: all 0.2s ease-in-out 0s;
            text-decoration: underline; */

            :after {
                width: 90%;
                transition-duration: 0.5s;
                -webkit-transition-duration: 0.5s;
            }
        }
`;

const Span = styled.div`
  padding-right:10px;
  font-size:13px;
  text-transform: capitalize;
  
  img {
    width: 34px;
    height: 34px;
    margin: 0px 10px;
  }
`;

const NewLogoWrapper = styled.div`
    width: auto;
    height: 100%;
    display: flex;
    justify-content: center;
    margin: 10px 0px;

    & img {
        width: 140px;
        height: 60px;
        object-fit: contain;
    }
`;

const FooterLogoWrap = styled.div`
    display: flex;
    gap: 1.2em;
    justify-content: center;
    align-items: center;

    & > div{
        display: flex;
        flex-direction: column;
        align-items: center;
    }
`

const CertificateTitle = styled(Tittle)`
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
`;

const CertificateText = styled.p`
    width: 51%;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
`;