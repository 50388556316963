import React from 'react';
import styled from 'styled-components';
import { withProp, theme } from 'styled-tools';
// Dependent Components
import { Income } from '../../components/common/NumberDisplay';
import Spinner from '../../components/common/Spinner';
import { Col, TableBodyWrapper, TableHeadRow, TableWrapper } from '../../components/common/table/baseTableStyles';
import EmptyRow from '../../components/common/table/feature/EmptyRow';
import { MemberTableBodyRow, MobileHead } from '../../components/member/membertablestyles';
import { TimeZone } from '../../store/TimeZoneContext';

// Util
import { ITransaction } from '../../graphql/schema';
import { diffColor } from '../../util/colorUtil';
import media from '../../util/mediaUtil';
import util from '../../util/util';
import { ETransferType } from '../../util/utilModel';
import { parseISO } from 'date-fns'

interface IGamePLTableProps {
	transferInfoList: ITransaction[];
	loading: boolean;
	error: any;
}

const GameProfitLossTable: React.SFC<IGamePLTableProps> = props => {
	const { transferInfoList, loading, error } = props;

	return (
		<TableWrapper>
			<TableHeadRow>
				<Wrapper>
					<TimeCol>Created Time</TimeCol>
					<TransferTypeCol>Transfer Type</TransferTypeCol>
					<AmountCol>Amount</AmountCol>
				</Wrapper>
			</TableHeadRow>
			<TableBodyWrapper>
				{error ? (
					<div>Error</div>
				) : loading ? (
					<TableBodyWrapper>
						<Spinner mode="client" />
					</TableBodyWrapper>
				) : !!transferInfoList && transferInfoList.length > 0 ? (
					transferInfoList.map(info => {
						const { createdAt, winloss } = info;

						return (
							<MemberTableBodyRow key={createdAt}>
								<TimeCol>
									<MobileHead>Created Time</MobileHead>
									<TimeZone>{createdAt}</TimeZone>
								</TimeCol>
								<TransferTypeCol>
									<MobileHead>Transfer Type</MobileHead>
									{winloss > 0 ? ETransferType.deposit : ETransferType.withdraw}
								</TransferTypeCol>
								<AmountCol>
									<MobileHead>Amount</MobileHead>
									<Income>
										{util.numMultiply(util.getValue(info, ['exchangeRate'], 0), winloss)}
									</Income>
								</AmountCol>
							</MemberTableBodyRow>
						);
					})
				) : (
					<EmptyRow />
				)}
			</TableBodyWrapper>
		</TableWrapper>
	);
};

export default GameProfitLossTable;

// Styled Components
const TimeCol = styled(Col)`
	/* flex-basis: 25%; */
	flex-basis: 32%;
    font-size: 14px;

	${media.lg`
		background-color: ${withProp('theme.palette.panel-background', diffColor(0.1))};
		justify-content: space-between;
	`};
`;

const TransferTypeCol = styled(Col)`
	/* flex-basis: 25%; */
	flex-basis: 32%;
    font-size: 14px;

	${media.lg`
		justify-content: space-between;
	`};
`;

const AmountCol = styled(Col)`
	/* flex-basis: 50%; */
	flex-basis: 32%;
    font-size: 14px;

	${media.lg`
		justify-content: space-between;
	`};
`;

const Wrapper = styled.div`
	background: ${theme('palette.secondary-background-color-linear-new')};
    color: #fff;
    border-bottom: none;
    display: flex;
    justify-content: space-evenly;
    width: 100%;
    box-shadow: rgb(0 0 0 / 10%) 0px 3px 5px;
`;
