import React, { useEffect } from 'react'
import { withAppContext } from 'src/store/initAppContext';
import styled, { withTheme } from 'styled-components';
import { LazyLoadImage } from "react-lazy-load-image-component";
import config from "src/config/config";
import PlaceholderImage from 'src/components/Placeholder';
import { theme } from 'styled-tools';
import FooterContainer from 'src/components/footer/footer';
import Slider from "react-slick";
import { useNavigate } from 'react-router-dom';

interface Game {
    id: number;
    image: string;
    url: string;
    gameName: string;
};

const GAMES_LIST: Game[] = [
    { id: 1, image: "brand/6/image/JJLPGameList02.png", url: "/slots/jili-slot/super-ace", gameName: "Super Ace" },
    { id: 2, image: "brand/6/image/JJLPGameList03.png", url: "/slots/jili-slot/money-coming", gameName: "Money Coming" },
    { id: 3, image: "brand/6/image/JJLPGameList04.png", url: "/slots/jili-slot/boxing-king", gameName: "Boxing King" },
    { id: 4, image: "brand/6/image/JJLPGameList05.png", url: "/slots/jili-slot/fortune-gems", gameName: "Fortune Gems" },
    { id: 5, image: "brand/6/image/JJLPGameList06.png", url: "/slots/jili-slot/fortune-gems 2", gameName: "Fortune Gems 2" },
    { id: 6, image: "brand/6/image/JJLPGameList07.png", url: "/slots/jili-slot/fortune-gems 3", gameName: "Fortune Gems 3" },
    { id: 7, image: "brand/6/image/JJLPGameList08.png", url: "/slots/jili-slot/crazy777", gameName: "Crazy777", },
    { id: 8, image: "brand/6/image/JJLPGameList09.png", url: "/slots/jili-slot/super ace deluxe", gameName: "Super Ace Deluxe" },
    { id: 9, image: "brand/6/image/JJLPGameList10.png", url: "/slots/jili-slot/money coming expand bets", gameName: "Money Coming Expand Bets" },
    { id: 10, image: "brand/6/image/JJLPGameList11.png", url: "/slots/jili-slot/golden-empire", gameName: "Golden Empire" },
];

const SLIDER_SETTINGS = {
    loop: true,
    arrows: true,
    autoplay: true,
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 6,
    slidesToScroll: 6,
    initialSlide: 0,
    centerPadding: '20px'
};

function JiliJackpotLeaguePromo(props: any) {
    const { app: {
        brandId
    }, theme } = props;

    const navigate = useNavigate();

    useEffect(() => {
        window.scrollTo(0, 0)
    }, []);

    const handleGameRedirect = (e: React.MouseEvent, url: string) => {
        e.preventDefault();
        url && navigate(url);
    };

    const CheckMarkIcon = () => {
        const color = (brandId == 31 || brandId == 32) ? '#2943D9' : (brandId == 39 || brandId == 40) ? '#D92961' : '#D9292C';
        return (
            <svg xmlns="http://www.w3.org/2000/svg" width="27" height="27" viewBox="0 0 27 27" fill="none">
                <rect x="0.157364" y="0.0296631" width="26.8527" height="26.8527" fill="white" />
                <path d="M22.215 8.15452L9.72026 20.1692L3.99351 14.6624L5.46164 13.2507L9.72026 17.3357L20.7469 6.7428L22.215 8.15452Z" fill={color} />
            </svg>
        )
    };

    const RenderGameSlider = () => (
        <Slider {...SLIDER_SETTINGS}>
            {GAMES_LIST.map((game, index) => (
                <GameSlider key={index}>
                    <EachGame>
                        <LazyLoadImage
                            src={`${config.imageV3Host}/static/cashsite/${game.image}`}
                            alt={game.gameName}
                            effect="none"
                            placeholder={<PlaceholderImage />}
                            onClick={(e) => handleGameRedirect(e, game.url)}
                            role="button"
                            tabIndex={0}
                        />
                    </EachGame>
                    <p>{game.gameName}</p>
                </GameSlider>
            ))}
        </Slider>
    );

    const domain = (brandId == 31 || brandId == 32) ? "YOLO247" : (brandId == 39 || brandId == 40) ? "FOMO7" : 'IW247';

    return (
        <>
            <InformationWrapper>
                <HeaderSection>
                    <LazyLoadImage
                        src={`${config.imageV3Host}/static/cashsite/${theme.image.JiliSpinWinLeagueTextOne}`}
                        alt="Jili jackpot league"
                        effect="none"
                        placeholder={<PlaceholderImage />}
                    />
                    <LazyLoadImage
                        src={`${config.imageV3Host}/static/cashsite/${theme.image.JiliSpinWinLeagueGirl}`}
                        alt="Jili jackpot league"
                        effect="none"
                        placeholder={<PlaceholderImage />}
                    />
                    <PromoDescription>
                        <p>Log in and play any featured games to grab 20 Free Spins — <strong>no extra steps are required!</strong></p>
                        <p><strong>Spin, win, and unlock</strong> massive rewards while enjoying thrilling gameplay.</p>
                    </PromoDescription>
                </HeaderSection>
                <GameSection>
                    <LazyLoadImage
                        src={`${config.imageV3Host}/static/cashsite/${theme.image.JiliSpinWinLeagueWhiteBgOne}`}
                        alt="Jili jackpot league"
                        effect="none"
                        className="topImg"
                        placeholder={<PlaceholderImage />}
                    />
                    <SliderContainer>
                        <LazyLoadImage
                            src={`${config.imageV3Host}/static/cashsite/${theme.image.JiliSpinWinLeagueGamesTitle}`}
                            alt="Jili jackpot league"
                            effect="none"
                            className="textImg"
                            placeholder={<PlaceholderImage />}
                        />
                        <SliderWrapper>
                            <RenderGameSlider />
                        </SliderWrapper>
                    </SliderContainer>
                    <LazyLoadImage
                        src={`${config.imageV3Host}/static/cashsite/${theme.image.JiliSpinWinLeagueWhiteBgTwo}`}
                        alt="Jili jackpot league"
                        effect="none"
                        className="bottomImg"
                        placeholder={<PlaceholderImage />}
                    />
                </GameSection>
                <InfoSections>
                    <CheckmarkSection>
                        <LazyLoadImage
                            src={`${config.imageV3Host}/static/cashsite/${theme.image.JiliSpinWinLeagueTextTwo}`}
                            alt="Jili jackpot league"
                            effect="none"
                            placeholder={<PlaceholderImage />}
                        />
                        <CheckmarkContainer>
                            <CheckmarkItem>
                                <CheckMarkIcon />
                                <p>Log in and play any of the games mentioned above.</p>
                            </CheckmarkItem>
                            <CheckmarkItem>
                                <CheckMarkIcon />
                                <p>Instantly receive 20 Free Spins—winnings go into your Bonus Pool.</p>
                            </CheckmarkItem>
                            <CheckmarkItem>
                                <CheckMarkIcon />
                                <p>Once all spins are used, the wagering phase begins.</p>
                            </CheckmarkItem>
                            <CheckmarkItem>
                                <CheckMarkIcon />
                                <p>Meet the 5x wagering requirement to unlock and claim your accumulated rewards!</p>
                            </CheckmarkItem>
                        </CheckmarkContainer>
                    </CheckmarkSection>
                    <VerticalDivider />
                    <CheckmarkSection>
                        <LazyLoadImage
                            src={`${config.imageV3Host}/static/cashsite/${theme.image.JiliSpinWinLeagueTextThree}`}
                            alt="Jili jackpot league"
                            effect="none"
                            className="textImgThree"
                            placeholder={<PlaceholderImage />}
                        />
                        <CheckmarkContainer>
                            <CheckmarkItem>
                                <CheckMarkIcon />
                                <p>instant Free Spins – Log in and start spinning!</p>
                            </CheckmarkItem>
                            <CheckmarkItem>
                                <CheckMarkIcon />
                                <p>Risk-Free Gameplay – Play top JILI slots without spending a dime.</p>
                            </CheckmarkItem>
                            <CheckmarkItem>
                                <CheckMarkIcon />
                                <p>Bonus Pool System – Winnings from Free Spins go into the Bonus Pool, ready to be unlocked.</p>
                            </CheckmarkItem>
                            <CheckmarkItem>
                                <CheckMarkIcon />
                                <p>Wager & Win – Keep playing to meet the wagering requirement and claim your full rewards.</p>
                            </CheckmarkItem>
                            <CheckmarkItem>
                                <CheckMarkIcon />
                                <p>Limited-Time Offer – Don’t miss out on this exclusive promotion!</p>
                            </CheckmarkItem>
                        </CheckmarkContainer>
                    </CheckmarkSection>
                </InfoSections>
                <RulesSection>
                    <LazyLoadImage
                        src={`${config.imageV3Host}/static/cashsite/${theme.image.JiliSpinWinLeagueGraph}`}
                        alt="Jili jackpot league"
                        effect="none"
                        placeholder={<PlaceholderImage />}
                    />
                    <RulesContent>
                        <h2>Important Rules!</h2>
                        <ul>
                            <li>Spin Bonus winnings are collected in the Bonus  Pool, not credited directly to your balance.</li>
                            <li>Once Free Spins are used, players must enter the wagering phase and continue betting to progress.</li>
                            <li>Complete the 5x wagering requirement to claim all winnings from the Bonus Pool.</li>
                            <li>The Spin Bonus has an expiration date—use it before it expires!</li>
                            <li>Rewards are limited—no further bonuses will be credited once the quota is reached.</li>
                            <li>Each player can claim this offer only once— duplicate claims are not allowed.</li>
                        </ul>
                    </RulesContent>
                </RulesSection>
                <TermsSection>
                    <LazyLoadImage
                        src={`${config.imageV3Host}/static/cashsite/${theme.image.JiliSpinWinLeagueTermsText}`}
                        alt="Jili jackpot league"
                        effect="none"
                        placeholder={<PlaceholderImage />}
                    />
                    <TermsList>
                        {(brandId == 33 || brandId == 34) ? (
                            <ul>
                                <li>This promotion is valid for all new and existing players.</li>
                                <li>The greater the amount of game turnover, the greater the chances of winning.</li>
                                <li>Multiple accounts will not be eligible for this promotion. All players will undergo duplicate checking. {domain} reserves the right to disqualify players involved in collusion or any foul play related to this promotion.</li>
                                <li>{domain} & JILI reserves the right to amend, cancel, reclaim, or refuse any promotion at its discretion.</li>
                                <li>General Terms & Conditions of {domain} apply.</li>
                            </ul>
                        ) : (
                            <ul>
                                <li>The JILI: Spin & Wheel promotion is valid for all new and existing players.</li>
                                <li>The bigger the game turnover, the greater the chances of winning.</li>
                                <li>Multiple accounts will not be eligible for this promotion. All players will undergo duplicate checking.</li>
                                <li>{domain} reserves the right to disqualify players involved in collusion or any foul play related to this promotion.</li>
                                <li>{domain} & JILI Games reserve the right to amend, cancel, reclaim, or refuse any promotion at its discretion.</li>
                                <li>{domain} General Terms & Conditions apply.</li>
                            </ul>
                        )}
                    </TermsList>
                </TermsSection>
            </InformationWrapper>
            <FooterContainer />
        </>
    )
}

export default withTheme(withAppContext(JiliJackpotLeaguePromo));

//Styled Component.
const InformationWrapper = styled.div`
    width: 98%;
    height: auto;
    padding: 1.5rem 0 0 0;
    margin: 0 1.2em 0 1.2em;
    display: flex;
    flex-direction: column;
    background: url(${config.imageV3Host}/static/cashsite/${theme("image.JiliSpinWinLeagueBackground")});
    background-position: top;
    background-size: cover;
    background-repeat: no-repeat;
    overflow: hidden;
    gap: 0;
`;

const SliderWrapper = styled.div`
    width: 95%;
    margin: 0 auto;

    .slick-slide {
        padding: 1rem;
    }
    
    .slick-slide img {
        display: block;
        width: 100%;
        transition: transform .5s; /* Animation */
    }

    .slick-prev {
        left: -25px;
        top: 43%;
        cursor: pointer;
    }

    .slick-next {
        right: -25px;
        top: 43%;
        cursor: pointer;
    }

    .slick-prev:before {
        content: "";
        width: 53px;
        height: 59px;
        display: inline-block;
        background: url(${config.imageV3Host}/static/cashsite/${theme("image.JiliSpinWinLeagueIconLeft")});
        background-size: cover;
        background-position:center center;
        background-repeat: no-repeat;
        opacity: unset;
        position: absolute;
        top: -15rem;
        left: 2rem;
    }

    .slick-next:before {
        content: "";
        width: 53px;
        height: 59px;
        display: inline-block;
        background: url(${config.imageV3Host}/static/cashsite/${theme("image.JiliSpinWinLeagueIconRight")});
        background-size: cover;
        background-position:center center;
        background-repeat: no-repeat;
        opacity: unset;
        position: absolute;
        top: -15rem;
        right: 2rem;
    }

    .slick-dots {
        bottom: -40px;

        li button:before {
            color: transparent;
            font-size: 15px;
            opacity: unset;
        }

        li.slick-active button:before {
            opacity: unset;
            color: transparent;
        }
    }
`;

const HeaderSection = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 4rem;

    img {
        width: 100%;
    }
`;

const PromoDescription = styled.div`
    display: flex;
    justify-content: center;
    align-items: flex-start;
    flex-direction: column;
    transform: rotate(-5.18deg);
    padding: 2rem;
    padding-left: 0;
    gap: 1rem;

    p {
        width: 80%;
        color: #FFF;
        text-align: justify;
        font-size: 19.074px;
        font-style: normal;
        font-weight: 500;
        line-height: 150%;
        text-transform: capitalize;
        margin: 0;
    }
`;

const GameSection = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    background-color: #FFF;

    img {
        width: 100%;
    }

    .bottomImg {
        margin-bottom: -1rem;
    }

    .topImg {
        margin-top: -6.25rem;
    }
`;

const SliderContainer = styled.div`
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    position: relative;
    text-align: center;

    .textImg {
        width: 50%;
        padding: 1rem 0;
    }
`;

const GameSlider = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    transform: skew(-5.18deg) rotate(-5.18deg);

    p {
        background: ${theme('palette.JiliJackpotLeagueGameTextBg')};
        background-clip: text;
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
        text-align: center;
        font-size: 19.074px;
        font-style: normal;
        font-weight: 700;
        line-height: 150%;
        text-transform: capitalize;
    }
`;

const EachGame = styled.div`
    display: flex;
    position: relative;
    border: ${theme('palette.JiliJackpotLeagueGameImageBorder')};
    border-radius: 20px;
    overflow: hidden;

    img {
        width: 100%;
        cursor: pointer;
    }

    ::after {
        content: "";
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background: ${theme('palette.JiliJackpotLeagueGamesBg')};
        pointer-events: none;
    }
`;

const InfoSections = styled.div`
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    padding: 4rem;
`;

const CheckmarkSection = styled.div`
    display: flex;
    justify-content: center;
    align-items: flex-start;
    flex-direction: column;
    width: 45%;

    img {
        width: 50%;
    }

    .textImgThree {
        width: 65%;
    }
`;

const VerticalDivider = styled.div`
    width: 3px;
    height: 472px;
    background: #FFF;
    transform: rotate(-5.18deg);
    margin: 0 1rem;
    align-self: flex-end;
`;

const CheckmarkContainer = styled.div`
    display: flex;
    justify-content: center;
    align-items: flex-start;
    flex-direction: column;
    transform: rotate(-5.18deg);
    gap: 1.5rem;
`;

const CheckmarkItem = styled.div`
    display: flex;
    justify-content: center;
    align-items: flex-start;
    gap: 1rem;

    svg {
        flex-shrink: 0;
    }

    p {
        color: #FFF;
        font-size: 17.599px;
        font-style: normal;
        font-weight: 700;
        line-height: 150%;
        text-transform: capitalize;
        margin: 0;
    }
`;

const RulesSection = styled.div`
    width: 100%;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    padding: 2rem 10rem 0 0;
    position: relative;

    img {
        width: 30rem;
        height: auto;
        position: absolute;
        left: 0;
        bottom: -5rem;
    }
`;

const RulesContent = styled.div`
    width: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    background: #FFF;
    gap: 1rem;
    padding: 1rem 0;
    transform: rotate(-5.18deg);

    h2 {
        width: 100%;
        background: ${theme('palette.JiliJackpotLeagueRulesHeadBg')};
        color: ${theme('palette.JiliJackpotLeagueRulesHeadTextColor')};
        font-size: 54.061px;
        font-style: normal;
        font-weight: 900;
        line-height: 100%;
        text-transform: uppercase;
        text-align: center;
        margin: 0.5rem 0 0 0;
        padding: 1rem;
    }

    ul {
        padding-right: 2rem;
    }

    ul li {
        color: ${theme('palette.JiliJackpotLeagueTextColor')};
        font-size: 17.599px;
        font-style: normal;
        font-weight: 700;
        line-height: 150%;
        text-transform: capitalize;
        padding-bottom: 1rem;
        list-style: none;
        position: relative;
        padding-left: 20px;
    }

    ul li::before {
        content: "";
        position: absolute;
        left: 0;
        top: 9px;
        width: 10px;
        height: 10px;
        background: linear-gradient(180deg, #FFCA37 0%, #F09400 100%);
        border-radius: 50%;
    }
`;

const TermsSection = styled.div`
    display: flex;
    align-items: flex-end;
    justify-content: center;
    padding: 20% 5rem 3rem 5rem;
    background: #FFF;
    background: url(${config.imageV3Host}/static/cashsite/${theme("image.JiliSpinWinLeagueTCbackground")});
    background-position: top;
    background-size: cover;
    background-repeat: no-repeat;
    height: auto;
    gap: 2rem;

    img {
        width: 35rem;
        height: auto;
    }
`;

const TermsList = styled(RulesContent)`
    background: none;
    padding-top: 0;

    ul li {
        padding-bottom: 1.5rem;
    }

    ul {
        border-left: ${theme('palette.JiliJackpotLeagueRulesBorderLeft')};
    }

    ul li:last-child {
        padding-bottom: 0;
    }
`;