import React from 'react'

type Props = {}

export default function DeleteIcon({ }: Props) {
  return (
    <svg fill="none" xmlns="" viewBox="0 0 16 16"><g clip-path="url(#a)" stroke="#E8E8E8" stroke-width="1.2" stroke-linecap="round" stroke-linejoin="round"><path d="M2.13 4.464h11.25M5.256 4.464v-1.25a1.25 1.25 0 0 1 1.25-1.25h2.5a1.25 1.25 0 0 1 1.25 1.25v1.25m1.875 0v8.75a1.25 1.25 0 0 1-1.25 1.25H4.63a1.25 1.25 0 0 1-1.25-1.25v-8.75h8.75ZM6.506 7.589v3.75M9.006 7.589v3.75" /></g><defs><clipPath id="a"><path fill="#fff" transform="translate(.256 .714)" d="M0 0h15v15H0z" /></clipPath></defs></svg>
  )
}

export const XCircleIcon = (props) => {
  return (<svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M7.99992 14.6667C11.6818 14.6667 14.6666 11.6819 14.6666 8.00001C14.6666 4.31811 11.6818 1.33334 7.99992 1.33334C4.31802 1.33334 1.33325 4.31811 1.33325 8.00001C1.33325 11.6819 4.31802 14.6667 7.99992 14.6667Z" stroke="#B7B5CD" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
    <path d="M10 6L6 10" stroke="#B7B5CD" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
    <path d="M6 6L10 10" stroke="#B7B5CD" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
  </svg>)
}

export const DeleteIconFun88 = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="15" height="15" viewBox="0 0 15 15" fill="none">
      <path d="M1.875 3.75H3.125H13.125" stroke="#7C7C7C" stroke-width="1.2" stroke-linecap="round" stroke-linejoin="round" />
      <path d="M5 3.75V2.5C5 2.16848 5.1317 1.85054 5.36612 1.61612C5.60054 1.3817 5.91848 1.25 6.25 1.25H8.75C9.08152 1.25 9.39946 1.3817 9.63388 1.61612C9.8683 1.85054 10 2.16848 10 2.5V3.75M11.875 3.75V12.5C11.875 12.8315 11.7433 13.1495 11.5089 13.3839C11.2745 13.6183 10.9565 13.75 10.625 13.75H4.375C4.04348 13.75 3.72554 13.6183 3.49112 13.3839C3.2567 13.1495 3.125 12.8315 3.125 12.5V3.75H11.875Z" stroke="#7C7C7C" stroke-width="1.2" stroke-linecap="round" stroke-linejoin="round" />
      <path d="M6.25 6.875V10.625" stroke="#7C7C7C" stroke-width="1.2" stroke-linecap="round" stroke-linejoin="round" />
      <path d="M8.75 6.875V10.625" stroke="#7C7C7C" stroke-width="1.2" stroke-linecap="round" stroke-linejoin="round" />
    </svg>
  )
}