import { darken } from 'polished';
import React from 'react';
import styled, { css } from 'styled-components';
import { ifProp, prop, theme, withProp } from 'styled-tools';

// Components
import Intl from '../common/Intl';

// Util
import { getGTMId } from '../../util/gtmUtil';
import media from '../../util/mediaUtil';
import { isMemberType } from '../../util/styleUtil';
import { shadowBottom1F } from './commonCSSStyles';

// U: union type, such as 'foo' | 'bar' | 'baz'
interface ISegmentedControlProps<U> {
	segments: U[];
	initialSegment?: U | 'empty';
	children: (state: ISegmentedControlState<U>) => React.ReactNode;
	allowEmptySegment?: boolean;
	langKeys?: string[];
	id?: any;
}

interface ISegmentedControlState<U> {
	currentSegment: U | 'empty';
}

export default class SegmentedControl<U> extends React.PureComponent<
	ISegmentedControlProps<U>,
	ISegmentedControlState<U>
> {
	state = {
		currentSegment: this.props.initialSegment || this.props.segments[0],
	};

	onClickSegment = (index: number) => () => {
		const { allowEmptySegment } = this.props;
		const { currentSegment } = this.state;
		const targetSegment = this.props.segments[index];

		if (allowEmptySegment && currentSegment === targetSegment) {
			this.setState(() => ({
				currentSegment: 'empty',
			}));
		} else {
			this.setState(() => ({
				currentSegment: this.props.segments[index],
			}));
		}
	};

	render() {
		const { segments, children, langKeys, id } = this.props;
		const { currentSegment } = this.state;
		const amount = (segments && segments.length) || 0;

		return (
			<>
				<SegmentedControlWrapper amount={segments.length}>
					{segments.map((segment: any, i) => {
						return (
							<SegmentedWrapper
								key={segment}
								onClick={this.onClickSegment(i)}
								isActive={segments[i] === currentSegment}
								isMybets={segments[i] === currentSegment}
								amount={amount}
								id={getGTMId(`${id}_${segment.toLowerCase()}`)}
							>
								{Array.isArray(langKeys) && langKeys[i] ? <Intl langKey={langKeys[i]} /> : segment}
							</SegmentedWrapper>
						);
					})}
				</SegmentedControlWrapper>
				{children(this.state)}
			</>
		);
	}
}

// Styled components
export const SegmentedWrapper: any = styled.div`
	width: 33.33%;
	height: 45px;
	display: flex;
	align-items: center;
	justify-content: center;
	position: relative;
	padding-left: 0px;
	cursor: pointer;
	user-select: none;
	font-size: 14px;
	color: rgba(0, 0, 0, 0.8);
	background: rgba(255,255,255,0.8);
	/* border: 1px solid #423FC1; */
	/* box-shadow: 0px 0px 40px rgb(16 11 73 / 40%); */
	border-radius: 3px;

	:last-child {
		margin-right: 0;
	}

	&:not(:last-of-type) {
		// border-right: 1px solid rgba(128, 128, 128, 0.2);
		/* &::before{
			content:'';
			display: block;
			position: absolute;
			top: 0;
			bottom: 0;
			right: 0;
			width: 1px;
			background-color: rgba(128, 128, 128, 0.2);
		} */
	}

	${ifProp(
	'isActive',
	css`
			/* color: ${theme('palette.secondary-background-color-new')}; */
			/* background: ${theme('palette.account-background')};*/
			background: ${theme('palette.secondary-background-color-linear-new1')};

			border: 1px solid #FFFFFF;
			box-shadow: 0px 0px 40px rgba(37, 36, 45, 0.4);
			color: #fff;
			
			&:hover {
				/* color: ${theme('palette.secondary-background-color-new')}; */
				color: #fff;
			}
		`,
	css`
			${isMemberType(
		'CLIENT',
		css`
					background-color: ${withProp('theme.palette.panel-background', darken(0.025))};
				`,
		css`
				background-color: transparent;
				
				`,
	)};
		`,
)};

	${media.md`
	font-size: 14px;
	`};
`;

export const SegmentedControlWrapper: any = styled.div`
	display: flex;
	justify-content: space-between;
    align-items: center;
	flex: 1;
	// border-radius: 4px;
	overflow: hidden;
	margin-bottom: 1em;
	${media.md`
		border: none;
		// box-shadow: 0px 0px 14px rgba(0,0,0,0.08);
		margin-bottom: 0.5em;
	`};
`;