import initApollo from '../store/initApollo';

// util
import cookieUtil from './cookieUtil';
import eventUtil from './eventUtil';
import { ECookieName, EEventEmit } from './utilModel';

export default class graphqlApiUtil {
    /**
     * The parameters used by apollo client to call query
     * Only one operation (query/ mutation/ ...) can be performed at a time
     *
     * @static
     * @param {*} gql
     * @param {*} [args]
     * @returns {Promise<any>}
     * @memberof graphqlApiUtil
     */
    public static async clientQuery(gql: any, args?: any): Promise<any> {
        return new Promise((resolve: any, reject: any) => {
            eventUtil.emit(EEventEmit.EVENT_GRAPHQL_QUERY, {
                gql,
                args,
                cb: (data: any) => {
                    return resolve(data);
                },
            });
        });
    }

    /**
     * apollo can be used for calling anywhere (you must bring the language e.q. en-us)
     * If you want to play on the server and need to judge the device, remember to pass in ctx, otherwise the device will not be judged
     *
     * @static
     * @param {*} gql
     * @param {any} ctx
     * @param {*} [args]
     * @returns {Promise<any>}
     * @memberof graphqlApiUtil
     */
    public static async query(gql: any, ctx: any, args?: any, token?: string): Promise<any> {
        try {
            const client = initApollo({
                memberId: ctx.memberId,
                lang: ctx.lang,
                res: ctx.res,
                req: ctx.req,

                // Sometimes the token comes from the query string
                token: token || cookieUtil.get(ECookieName.COOKIE_TOKEN),
            });

            const mapObj = {
                query: 'query',
                mutation: 'mutate',
            };
            const queryParam: any = {
                query: gql,
                fetchPolicy: 'no-cache',
                errorPolicy: 'all',
            };
            const operation = gql.definitions[0].operation;
            queryParam[operation] = gql;

            if (args) {
                queryParam.variables = args;
            }
            const payload: any = await client[(mapObj as any)[operation]](queryParam);
            return payload;
        } catch (error) {
            return {};
        }
    }

    public static client(ctx: any) {
        const token = cookieUtil.get(ECookieName.COOKIE_TOKEN);
        return initApollo({
            memberId: ctx.memberId,
            lang: ctx.lang,
            res: ctx.res,
            req: ctx.req,
            token,
        });
    }
}
