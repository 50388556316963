export const color = {
	menucolor: '#203974',
	secondarycolor: '#fff',
	yellowfont: '#D7A04A',
	tablogin: '#BA8C40',
	tabsignup: '#09285F',
	exchangeTableHeadBg: '#113492',
	homeExchangeBgColor: '#EBEBEB',
	loginbg: '#fff',
	titleHeadColor: '#214076',
	primaryColor: '#000',
	innerGameTittle: '#DEC59D',
	innerBackHeadBg: '#A7D8FD',
	innerLayHeadBg: '#ffc9c9',
	innerBackLightBg: 'rgba(3, 132, 251, 0.65)',
	innerBackDarkBg: '#48a2f5',
	innerOddsLayColor: '#214076',
	innerLayDarkBg: '#FF99B0',
	innerLayLightBg: 'rgba(255, 153, 176, 0.8)',

	dashboardYellowFont: '#FFD085',
}

export const bgcolor = {
	// loginbg: 'linear-gradient(200.75deg, #DEC59D -219.1%, rgba(209, 132, 10, 0.984962) 303.75%, rgba(221, 183, 123, 0.976842) 303.8%, rgba(221, 183, 123, 0.936148) 303.85%, rgba(221, 183, 123, 0.863228) 303.9%, rgba(221, 183, 123, 0.758266) 303.96%, rgba(221, 183, 123, 0.576608) 304.01%, rgba(221, 183, 123, 0.278125) 304.06%, rgba(221, 183, 123, 0) 304.11%)',
	loginbg: 'linear-gradient(197.7deg, #EDCA91 -3.99%, #D0A96F 89.33%)',
	signupbg: 'linear-gradient(197.7deg, #EDCA91 -3.99%, #D0A96F 89.33%)',
	// signupbg: 'linear-gradient(200.75deg, #DEC59D -219.1%, rgba(209, 132, 10, 0.984962) 303.75%, rgba(221, 183, 123, 0.976842) 303.8%, rgba(221, 183, 123, 0.936148) 303.85%, rgba(221, 183, 123, 0.863228) 303.9%, rgba(221, 183, 123, 0.758266) 303.96%, rgba(221, 183, 123, 0.576608) 304.01%, rgba(221, 183, 123, 0.278125) 304.06%, rgba(221, 183, 123, 0) 304.11%)',
	loginbghover: 'linear-gradient(200.75deg, #ff9d00 -219.1%, rgba(209, 132, 10, 0.984962) 303.75%, rgba(221, 183, 123, 0.976842) 303.8%, rgba(221, 183, 123, 0.936148) 303.85%, rgba(221, 183, 123, 0.863228) 303.9%, rgba(221, 183, 123, 0.758266) 303.96%, rgba(221, 183, 123, 0.576608) 304.01%, rgba(221, 183, 123, 0.278125) 304.06%, rgba(221, 183, 123, 0) 304.11%)',
	signupbghover: '#d7a764'
}

// export const yellowTittle = {
// 	color: '#FFD085',
// 	fontSize: '18px',
//     fontWeight: '700',
//     letterSpacing: '0.03em',
//     textTransform: 'capitalize',
// }

// export const theme = {
// 	sectionMixin:
// 	  'background: white; border-radius: 5px; border: 1px solid blue;',
//   }