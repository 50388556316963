import React, { createRef } from 'react';
import util from '../../util/util';

const getIsInViewport = (element: any, offset = 0) => {
	if (!element || !element.getBoundingClientRect) {
		return util.isClient ? false : true;
	}
	const top = element.getBoundingClientRect().top;
	return top + offset >= 0 && top - offset <= window.innerHeight * 2;
};

interface IUpdateInViewProps {
	// 當此容器捲動時，偵測目標元素是否在 viewport 內
	scrollableContainerId: string;
	children: (ref: React.RefObject<any>) => React.ReactNode;
}

interface IUpdateInViewState {
	isInView: boolean;
	refObj: React.RefObject<any>;
}

export default class UpdateInView extends React.Component<IUpdateInViewProps, IUpdateInViewState> {
	checkIsInView = util.throttle(() => {
		const { current } = this.state.refObj;

		if (!this.state.isInView) {
			getIsInViewport(current) && this.setState({ isInView: true });
		}
	}, 1000 / 20);

	constructor(props: IUpdateInViewProps) {
		super(props);
		const refObj = createRef();

		this.state = {
			isInView: false,
			refObj,
		};
	}

	static getDerivedStateFromProps(nextProps: IUpdateInViewProps, prevState: IUpdateInViewState) {
		const { current } = prevState.refObj;
		const isInView = getIsInViewport(current);
		return { isInView };
	}

	componentDidMount() {
		const { scrollableContainerId } = this.props;
		const container = document.getElementById(scrollableContainerId);
		!container && console.warn('[UpdateInView]: cannot find scroll container!');
		container && container.addEventListener('scroll', this.checkIsInView);
		this.checkIsInView();
	}

	shouldComponentUpdate() {
		return this.state.isInView;
	}

	componentWillUnmount() {
		const { scrollableContainerId } = this.props;
		const container = document.getElementById(scrollableContainerId);
		container && container.removeEventListener('scroll', this.checkIsInView);
	}
	render() {
		const { children } = this.props;
		const { refObj } = this.state;
		return children(refObj);
	}
}
