// Code Changed - Manoj (  Added isAcceptAllPrice to order place )
import { format } from 'date-fns';
import React from 'react';

// Component
import { EOddsType } from '../components/eu-content/NumberInput';
import { calExposure } from '../components/eu-content/table/feature/exposureCalculate';
import { Notify } from '../components/Notification';
// Gql
import { GET_ORDER_LIST } from '../graphql/exchange';
import { IEventInfo, IFancyInfo, IMarketInfo, IMarketSource, IOrderLimit, ISelectionInfo } from '../graphql/schema';
import { IbfInfo } from '../store/initAppContext';

// Util
import apiUtil from '../util/apiUtil';
import cookieUtil from '../util/cookieUtil';
import graphqlApiUtil from '../util/graphqlApiUtil';
import oddsUtil from '../util/oddsUtil';
import util from '../util/util';
import { ECookieName, EMarketType } from '../util/utilModel';

// Context
import { IMatchListProviderStore, ORDER_BF_API_TIMEOUT, withMatchListContext } from './MatchListContext';

export interface IInput {
	size: number;
	price: number;
}

export interface IOrderTicket {
	type: 'BACK' | 'LAY';
	price: number;
	size: number;
	eventInfo: IEventInfo;
	marketInfo: IMarketInfo;
	selectionInfo?: ISelectionInfo;
	fancyInfo?: IFancyInfo;

	// size price原始值，currentPrice currentSize修改後的值
	currentPrice?: number;
	currentSize?: number;

	// `{marketId}_{selectionId}_{type}`
	keyName: string;
}

// Code Changed - Manoj ( removed this statemement)
export interface IMarketInfoStore extends IMarketInfo {
	// Code Changed - Manoj ( You need to add something here if there is any error in ODDS)
}



type PickedMatchListContextProp = 'matched' | 'unmatched' | 'closeMobileUnmatchedSlip';

interface IOrderListProviderProps {
	app: {
		exchangeRate: number;
		memberId: number;
		isMobileWidth: number;
		bfInfo: IbfInfo[];
		betBufferSec: number;
		token: string;
		brandId: any
	};
	matchListContext: Pick<IMatchListProviderStore, PickedMatchListContextProp>;
}

interface IOrderListProviderState {
	betArray: IOrderTicket[];
	hiddenBetArray: string[];
	isOneClickBetActive: boolean | null;
	isOrderLoading: boolean;
	oneClickStake: number;
	orderLimits: IOrderLimit[];
	betResults: any;
	oneClickOrderKey: string;
	oneClickOrderMarketSource: IMarketSource | null;
	quickStakes: number[];
	// 正在下的單
	placingOrderKeyName: string;
	isAcceptAllPrice: boolean | null;
	isUserLoggedIn: number
}

export interface IOrderListProviderStore extends IOrderListProviderState {
	// app
	exchangeRate: number;

	betBufferSec: number;

	// Methods
	removeBetEvent: (keys: string[]) => void;
	toggleOneClickBet: () => void;
	changeOneClickStake: (stake: number) => void;
	placeOrder: (info: IOrderTicket, input: IInput) => void;
	handleListAdd: (info: IOrderTicket) => void;
	handlePriceSizeChange: (idx: number) => (input: IInput) => void;
	placeAllOrder: () => void;
	cancelAllOrder: () => void;
	saveQuickStakes: (quickStakes: number[]) => void;
	confirmTurningOnOneClick: () => void;
	cancelTurningOnOneClick: () => void;
	toggleAllBookmakerOrder: () => void;

	isUserLoggedIn: any;
	placeOrderAction: (trigger:any) => void;
}

const initOrderProps: IOrderListProviderStore = {
	// app
	exchangeRate: 1,
	betBufferSec: 1,

	// provider state
	betArray: [],
	hiddenBetArray: [],
	isOneClickBetActive: cookieUtil.get(ECookieName.COOKIE_IS_ONE_CLICK_ACTIVE) === 'true' ? true : false,
	isAcceptAllPrice: false,
	isOrderLoading: false,
	oneClickStake: 0,
	orderLimits: [],
	betResults: {},
	oneClickOrderKey: '',
	oneClickOrderMarketSource: null,
	quickStakes: [],
	placingOrderKeyName: '',

	// methods
	removeBetEvent() {},
	toggleOneClickBet() {},
	changeOneClickStake() {},
	placeOrder() {},
	handleListAdd() {},
	handlePriceSizeChange: () => () => {},
	placeAllOrder() {},
	cancelAllOrder() {},
	saveQuickStakes() {},
	confirmTurningOnOneClick() {},
	cancelTurningOnOneClick() {},
	toggleAllBookmakerOrder() {},
	placeOrderAction() {},

	isUserLoggedIn: 0
};
export const Context = React.createContext(initOrderProps);
const Language = cookieUtil.get(ECookieName.COOKIE_LANG)
// 下注時為 API 來回時間提供緩衝
export const BET_BUFFER_SECOND = 1;

class OrderList extends React.Component<IOrderListProviderProps, IOrderListProviderState> {
	constructor(props: IOrderListProviderProps) {
		super(props);
		let orderLimits = [];
		try {
			orderLimits = JSON.parse(cookieUtil.get(ECookieName.COOKIE_ORDER_LIMIT) || '[]');
		} catch (e) {}

		this.state = {
			betArray: [],
			hiddenBetArray: [],
			isOneClickBetActive: cookieUtil.get(ECookieName.COOKIE_IS_ONE_CLICK_ACTIVE) === 'true' ? true : false,
			isAcceptAllPrice: cookieUtil.get(ECookieName.COOKIE_IS_ACCEPT_ALL) === 'true' ? true : false,
			isOrderLoading: false,
			placingOrderKeyName: '',
			oneClickStake: 0,
			orderLimits,
			betResults: {},
			oneClickOrderKey: '',
			oneClickOrderMarketSource: null,
			quickStakes: cookieUtil.get(ECookieName.COOKIE_STAKE_CHOOSE)
				? cookieUtil
						.get(ECookieName.COOKIE_STAKE_CHOOSE)
						.split(',')
						.map(num => Number(num))
				: [50, 100, 500, 1000, 5000, 10000, 25000],

			isUserLoggedIn: 0
		};
	}
	changeOneClickStake = (oneClickStake: number) => {
		this.setState({ oneClickStake });
	};

	saveQuickStakes = (quickStakes: number[]) => {
		this.setState({ quickStakes });
		cookieUtil.set(ECookieName.COOKIE_STAKE_CHOOSE, quickStakes.join(','));
	};

	handlePriceSizeChange = (idx: number) => ({ price, size }: any) => {
		const newBetArray: any = [...this.state.betArray];
		const newBetResults: any = { ...this.state.betResults };
		newBetArray[idx].currentPrice = price;
		newBetArray[idx].currentSize = size;
		newBetResults[newBetArray[idx].keyName] && (newBetResults[newBetArray[idx].keyName] = undefined);
		this.setState({ betArray: newBetArray, betResults: newBetResults });
	};
	checkBetLimitMessage = (info: IOrderTicket, size: number) => {
		const { matched, unmatched } = this.props.matchListContext;
		const { marketInfo, eventInfo, currentSize = 0 } = info;
		let orderLimits = [];
		try {
			orderLimits = JSON.parse(cookieUtil.get(ECookieName.COOKIE_ORDER_LIMIT) || '[]');
		} catch (e) {}

		const orderLimit: IOrderLimit = (Array.isArray(orderLimits) &&
			orderLimits.find((item: IOrderLimit) => Number(item.eventTypeId) === eventInfo.categoryId)) || {
			minOrder: 0,
			maxOrder: 99999999,
			maxMarket: 99999999,
		};
		// calculate this marketId's exposure
		const orders: any[] = matched.concat(unmatched).filter(order => marketInfo.marketId === order.marketId);
		const betSize = (currentSize || size) / this.props.app.exchangeRate;
		orders.push({ ...info, currentSize: betSize });
		const selectionNames = orders.reduce((pre, item) => {
			const { fancyInfo = {}, selectionInfo = {} } = item;
			const name = selectionInfo.name || fancyInfo.selectionName;
			pre[name] = 0;
			return pre;
		}, {});
		const exposure = calExposure(orders, selectionNames);
		const maxExposure = Math.min(...Object.keys(exposure).map(_ => exposure[_]));
		if (info.marketInfo.marketType !== EMarketType.FANCY && (orderLimit.minOrder * 100 || 0) - betSize * 100 > 0.5) {
			return 'Bet limit is less than minimum bet limit.';
		} else if (maxExposure - (orderLimit.maxMarket || 99999999999) > 0.5) {
			return 'Bet size is greater than max market bet limit.';
		}
		return;
	};

	orderAction = async (info: IOrderTicket, { size, price, isOneClickOrder = false, isAcceptAllPrice = false}: any) => {
		const {
			app: { token },
		} = this.props;
		const { marketInfo, selectionInfo, fancyInfo, type, eventInfo } = info;
		const { marketType, marketSource, marketId, betDelay } = marketInfo;
		const { competitionId, categoryId, eventId } = eventInfo;
		const isFancy = !!fancyInfo;
		
		const targetInfo = isFancy ? fancyInfo : selectionInfo;
		if (!targetInfo) {
			console.error('[orderAction] fancyInfo or selectionInfo is invalid');
			return null;
		}

		const { selectionId } = targetInfo;
		const betLimitCheckMessage = this.checkBetLimitMessage(info, size);

		// 紀錄正在下單的 keyName
		this.setState({ placingOrderKeyName: info.keyName });
		const payload = betLimitCheckMessage
			? {
					message: betLimitCheckMessage,
					success: false,
			  }
			: await apiUtil.fetchGQL(token)({
					action: '/exchange/placeOrder',
					data: {
						// query讓gql可以解碼過來的請求operation名稱
						query: '{exchange{placeOrder',
						side: type.toUpperCase(),
						selectionId,
						marketId,
						categoryId,
						competitionId,
						eventId,
						price: Number(price),
						size: size / this.props.app.exchangeRate,
						// for normal fancy 'runs'
						sideValue:
							// 確認 targetInfo 是否為 fancy
							'isRunning' in targetInfo
								? type.toUpperCase() === 'BACK'
									? targetInfo.backValue
									: targetInfo.layValue
								: null,
						betfairOrderType: 'LIMIT',
						isOneClickOrder,
						marketType,
						// Code Changed - Manoj ( removed marketSource and added provider variable)
						// marketSource,
						localCreatedTime: format(new Date(), 'yyyy-MM-dd hh:mm:ss'),
						betDelay,
						// Code Changed - Manoj (added provider)
						provider: marketInfo.resourceFrom,
						p: token,
						isAcceptAllPrice,
					},
			  });

		// 清除正在下單的單號
		this.setState({ placingOrderKeyName: '' });
		const result = payload || {};
		// 下注失敗，存下失敗的message
		if (!result.success && util.getValue(result, 'code') !== ORDER_BF_API_TIMEOUT) {
			const obj = {
				[info.keyName || `${marketId}_${selectionId}_${type}`]: {
					message:
						result.message || util.getValue(payload, ['errors', '0', 'message']) || 'Place order error',
				},
			};

			this.setState({ betResults: { ...this.state.betResults, ...obj } });
		} else {
			this.removeBetEvent([info.keyName]);
		}
		return payload;
	};

	placeOrderAction = async (trigger: any) => {
		if(!this.props.app.token) {
			this.setState({isUserLoggedIn: trigger})
		}else {
			this.setState({isUserLoggedIn: trigger})
		}
	}

	placeOrder = async (info: IOrderTicket, { size, price, isOneClickOrder = false, isAcceptAllPrice = this.state.isAcceptAllPrice, eventInfo}: any) => {
		if(!this.props.app.token) {
			return;
		}
		if (this.state.isOrderLoading) {
			return;
		}
		if (eventInfo === 'WICKET')
		{
			return;
		}
		// Enabling one-click bet for Bookmaker -John
		// if (eventInfo === 'BOOKMAKER')
		// {
		// 	return;
		// }
		this.state.isOneClickBetActive && console.log('one click bet event, add bet event', this.state.oneClickStake);
		this.state.isAcceptAllPrice && console.log('isAcceptAllPrice event enabled, add bet event', this.state.isAcceptAllPrice);
		const response = await this.waitUntilFinish(
			() => this.orderAction(info, { size, price, isOneClickOrder, isAcceptAllPrice }),
			info,
		);
		const { marketInfo } = info;
		const { oddType } = marketInfo;
		if (response.success && response.data) {
			const unmatch = Number((this.props.app.exchangeRate * response.data.unmatch).toFixed(2));
			response.data.match === 0
				? Notify.error(
						`PRICE:${
							oddType === EOddsType.AM
								? oddsUtil.EUtoAM(response.data.price)
								: util.numRound(response.data.price)
						}, MATCH:${(this.props.app.exchangeRate * response.data.match).toFixed(2)}, UNMATCH:${unmatch}`,
				  )
				: Notify[unmatch === 0 ? 'success' : 'warning'](
						`PRICE:${
							oddType === EOddsType.AM
								? oddsUtil.EUtoAM(response.data.price)
								: util.numRound(response.data.price)
						}, MATCH:${(this.props.app.exchangeRate * response.data.match).toFixed(2)}, UNMATCH:${unmatch}`,
				  );
		} else {
			util.getValue(response, 'code') !== ORDER_BF_API_TIMEOUT &&
				Notify.error(response.message !== null ? response.message : 'Invalid Order');
		}

		return response;
	};

	waitUntilFinish = async (cb: any, betInfo?: any) => {
		const {
			app: { betBufferSec, token },
		} = this.props;
		this.setState({ isOrderLoading: true });
		// Code Changed  - Manoj ( betDelay details changed here )
		const { eventInfo: { betDelay = 10 } = {}, selectionInfo, fancyInfo } = betInfo;
		// 如果 betDelay 大於 2 秒，偷加延遲秒數
		const actualDelay = betDelay > 2 ? betDelay + betBufferSec : 2;

		const selectionId = (selectionInfo && selectionInfo.selectionId) || (fancyInfo && fancyInfo.selectionId);
		const timerId = setTimeout(() => {
			// 如果還在 loading
			if (this.state.isOrderLoading) {
				// 1. 隱藏這張 order slip
				this.setState({
					hiddenBetArray: [selectionId, ...this.state.hiddenBetArray],
					isOrderLoading: false,
				});

				// 2. 這張注單寫入 unmatched slip
				const client = graphqlApiUtil.client({ memberId: this.props.app.memberId });
				const data = client.readQuery({ query: GET_ORDER_LIST });

				data.exchange.orders.unmatched.unshift({
					categoryId: betInfo.eventInfo.categoryId,
					competitionId: betInfo.eventInfo.competitionId,
					eventId: betInfo.eventInfo.eventId,
					eventName: betInfo.eventInfo.eventName,
					exchangeRate: betInfo.exchangeRate,
					marketId: betInfo.marketInfo.marketId,
					marketName: betInfo.marketInfo.marketName,
					marketStartTime: '',
					orderNumber: `unmatched_placeholder_${Date.now()}`,
					selectionId,
					side: betInfo.type,
					price: betInfo.currentPrice,
					stake:
						(this.state.isOneClickBetActive ? this.state.oneClickStake : betInfo.currentSize) /
						this.props.app.exchangeRate,
					updatedAt: Date.now(),
					__typename: 'Order',
					provider: betInfo.marketInfo.resourceFrom,
					p: token
				});
				client.writeQuery({
					query: GET_ORDER_LIST,
					data,
				});
			}
		}, actualDelay * 1000);
		const payload = await cb();
		clearInterval(timerId);
		// 取消隱藏注單
		this.setState({
			isOrderLoading: false,
			hiddenBetArray: [
				...this.state.hiddenBetArray.slice(0, this.state.hiddenBetArray.indexOf(selectionId)),
				...this.state.hiddenBetArray.slice(this.state.hiddenBetArray.indexOf(selectionId) + 1),
			],
		});
		return payload;
	};

	waitUntilAllFinish = async (cb: any) => {
		this.setState({ isOrderLoading: true });
		await cb();
		this.setState({ isOrderLoading: false });
	};

	placeAllOrder = async () => {
		if(!this.props.app.token) {
			this.placeOrderAction(this.props.app.brandId++)
			return;
		}
		const successKeys: string[] = [];
		await this.waitUntilAllFinish(() =>
			util.runSerial(
				this.state.betArray.map((bet, idx) => async () => {
					const response = await this.orderAction(bet, {
						size: bet.currentSize,
						price: bet.currentPrice,
					});
					if (response.success) {
						successKeys.push(bet.keyName);
					}
					return response;
				}),
			),
		);
		this.removeBetEvent(successKeys);
	};

	cancelAllOrder = () => {
		this.setState({ betArray: [], betResults: {} });
	};

	handleOneClickOrder = async (info: IOrderTicket) => {
		this.setState({
			oneClickOrderKey: info.keyName,
			oneClickOrderMarketSource: util.getValue(info, ['marketInfo', 'marketSource']),
		});
		
		const payload = await this.placeOrder(info, {
			size: this.state.oneClickStake,
			price: info.price,
			isOneClickOrder: true,
			eventInfo: util.getValue(info, ['marketInfo', 'resourceFrom']),
		});
		// payload.success ? Notify.success(payload.message) : Notify.error(payload.message);
         
		  let marketName = util.getValue(info, ['marketInfo', 'resourceFrom'])
		  
		  if (marketName === 'WICKET') {
			this.setState({ isOneClickBetActive: false },() => {
			Notify.error((Language === 'hindi-ind') ? 'फैंसी बाजार १ -क्लिक बेट शर्त की अनुमति नहीं देता है' : 'Fancy market does not allow oneclick bet')
			})
			// Notify.error((Language === 'hindi-ind') ? 'फैंसी बाजार १ -क्लिक बेट शर्त की अनुमति नहीं देता है' : 'Fancy market does not allow oneclick bet')
			// Notify.error('Fancy market does not allow oneclick bet')
		}
		// Enabling one-click bet for Bookmaker -John
		// if (marketName === 'BOOKMAKER') {
		// 	this.setState({ isOneClickBetActive: false },() => {
		// 	Notify.error((Language === 'hindi-ind') ? 'बुकमेकर बाजार १ -क्लिक बेट शर्त की अनुमति नहीं देता है' : 'Bookmaker market does not allow oneclick bet')
		// 	})
		// 	// Notify.error((Language === 'hindi-ind') ? 'बुकमेकर बाजार १ -क्लिक बेट शर्त की अनुमति नहीं देता है' : 'Bookmaker market does not allow oneclick bet')
		// 	// Notify.error('Bookmaker market does not allow oneclick bet')
		// }


		this.setState({
			oneClickOrderKey: '',
			oneClickOrderMarketSource: null,
			betArray: [],
			betResults: {},
		});

		return payload;
	};

	handleListAdd = async (info: IOrderTicket): Promise<any> => {
		const {
			app: { isMobileWidth },
			matchListContext: { closeMobileUnmatchedSlip },
		} = this.props;
		const { isOneClickBetActive, oneClickStake } = this.state;
		const { eventInfo } = info;
		const defaultOrderLimit = {
			minOrder: '',
			maxOrder: 99999999,
			maxMarket: 99999999,
			eventTypeId: '1',
		};
		const orderLimit: any =
			this.state.orderLimits.find((item: any) => item.eventTypeId === eventInfo.categoryId) || defaultOrderLimit;
		const currentSize = isOneClickBetActive ? oneClickStake : orderLimit.minOrder;
		const data = {
			...info,
			currentPrice: info.price,
			currentSize,
		};
		// Code Changed - Manoj ( fix for multi order selection)
		// 清除已開啟的 MobileUnmatchedSlip
		closeMobileUnmatchedSlip();
	
		// 重複的不添加
		if (!this.state.betArray.some((item: IOrderTicket) => item.keyName === data.keyName)) {
			// 行動裝置寬度時只會顯示一筆單
			const betArray = isMobileWidth ? [data] : [data, ...this.state.betArray];
			this.setState({ betArray });
		} else if(this.state.betArray.length !== 0 && this.state.betArray.some((item: IOrderTicket) => item.keyName === data.keyName && item.currentPrice !== data.price)) {
				const arr = this.state.betArray.map((each , index) => {
					let obj = each
						if(each.keyName === data.keyName) {
						obj.currentPrice = data.currentPrice
						}
						return obj
				})
				this.setState({betArray: arr})
			}
		



		// this.setState({betArray: [data]})
		
		if (this.state.isOneClickBetActive) {
			return this.handleOneClickOrder(info);
		}
	};

	removeBetEvent = (keys: string[]) => {
		const newArray = this.state.betArray.filter(item => keys.indexOf(item.keyName) === -1);
		const newBetResult = { ...this.state.betResults };
		keys.map(key => {
			delete newBetResult[key];
		});
		this.setState({ betArray: newArray, betResults: newBetResult });
	};

	toggleOneClickBet = () => {
		const { isOneClickBetActive, betArray } = this.state;
		!isOneClickBetActive && betArray.length > 0
			? this.setState({
					// lock betslip
					isOrderLoading: true,
					isOneClickBetActive: null,
			  })
			: this.setState(prevState => {
					return { isOneClickBetActive: !prevState.isOneClickBetActive };
			  }, () => {
				  cookieUtil.set(ECookieName.COOKIE_IS_ONE_CLICK_ACTIVE, this.state.isOneClickBetActive.toString(), 30)
			  });
	};

	toggleAllBookmakerOrder = () => {
		const { isAcceptAllPrice, betArray } = this.state;
		!isAcceptAllPrice && betArray.length > 0
			? this.setState({
					// lock betslip
					isOrderLoading: false,
					// isOneClickBetActive: null,
					isAcceptAllPrice: true 
			  })
			: this.setState(prevState => {
					return { isAcceptAllPrice: !prevState.isAcceptAllPrice };
			  }, () => {
				cookieUtil.set(ECookieName.COOKIE_IS_ACCEPT_ALL, this.state.isAcceptAllPrice.toString(), 30)
			});
	}

	confirmTurningOnOneClick = () => {
		this.setState({
			betArray: [],
			isOrderLoading: false,
			isOneClickBetActive: true,
		});
	};

	cancelTurningOnOneClick = () => {
		this.setState({
			isOrderLoading: false,
			isOneClickBetActive: false,
		});
	};

	render(): JSX.Element {
		const {
			app: { exchangeRate, betBufferSec },
		} = this.props;
		const store: IOrderListProviderStore = {
			// app
			exchangeRate,
			betBufferSec,

			// state
			betArray: this.state.betArray,
			oneClickStake: this.state.oneClickStake,
			isOrderLoading: this.state.isOrderLoading,
			placingOrderKeyName: this.state.placingOrderKeyName,
			hiddenBetArray: this.state.hiddenBetArray,
			betResults: this.state.betResults,
			oneClickOrderKey: this.state.oneClickOrderKey,
			quickStakes: this.state.quickStakes,
			orderLimits: this.state.orderLimits,
			oneClickOrderMarketSource: this.state.oneClickOrderMarketSource,

			// methods
			removeBetEvent: this.removeBetEvent,
			toggleOneClickBet: this.toggleOneClickBet,
			isOneClickBetActive: this.state.isOneClickBetActive,
			isAcceptAllPrice: this.state.isAcceptAllPrice,
			changeOneClickStake: this.changeOneClickStake,
			placeOrder: this.placeOrder,
			handleListAdd: this.handleListAdd,
			handlePriceSizeChange: this.handlePriceSizeChange,
			placeAllOrder: this.placeAllOrder,
			cancelAllOrder: this.cancelAllOrder,
			saveQuickStakes: this.saveQuickStakes,
			confirmTurningOnOneClick: this.confirmTurningOnOneClick,
			cancelTurningOnOneClick: this.cancelTurningOnOneClick,
			toggleAllBookmakerOrder: this.toggleAllBookmakerOrder,

			isUserLoggedIn: this.state.isUserLoggedIn,
			placeOrderAction: this.placeOrderAction
		};
		return <Context.Provider value={store}>{this.props.children}</Context.Provider>;
	}
}
const matchListContextProps: PickedMatchListContextProp[] = ['matched', 'unmatched', 'closeMobileUnmatchedSlip'];
export const OrderListContext = withMatchListContext(OrderList, matchListContextProps);

export interface IOrderListContext {
	orderListContext: IOrderListProviderStore;
}
export const withOrderListContext = (Child: any, keys: Array<keyof IOrderListProviderStore>): any => {
	return class OrderListHOC extends React.Component<any, any> {
		render(): JSX.Element {
			return (
				<Context.Consumer>
					{(store: IOrderListProviderStore) => {
						const orderListContext = keys
							? keys.reduce((pv: any, key: keyof IOrderListProviderStore) => {
									pv[key] = store[key];
									return pv;
							  }, {})
							: store;
						return <Child {...this.props} orderListContext={orderListContext} />;
					}}
				</Context.Consumer>
			);
		}
	};
};
