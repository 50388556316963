import React from 'react'

type Props = {}

const NormalWithdrawalIcon = (props: Props) => {
  return (
<svg width="77" height="77" viewBox="0 0 57 57" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M36.1056 23.8493L35.3176 26.7182L20.7734 26.6686L21.5724 23.7997L36.1056 23.8493ZM28.7373 40.7907L21.0966 31.5361L21.0939 29.1029L24.8862 29.1158C25.7922 29.1189 26.549 28.9717 27.1565 28.6742C27.7713 28.3694 28.2367 27.9435 28.5528 27.3966C28.8762 26.8424 29.0391 26.1926 29.0417 25.4473C29.0455 24.3294 28.7198 23.4295 28.0645 22.7477C27.4092 22.0659 26.3582 21.7226 24.9114 21.7177L20.7904 21.7036L21.6568 18.3198L24.923 18.3309C26.7643 18.3372 28.2868 18.6274 29.4905 19.2014C30.7015 19.7682 31.6048 20.5677 32.2004 21.6C32.8034 22.6324 33.1025 23.839 33.0978 25.22C33.0936 26.4402 32.8671 27.5282 32.4181 28.4839C31.9691 29.4395 31.265 30.2226 30.3057 30.8331C29.3464 31.4436 28.0919 31.8485 26.5421 32.0479L26.4105 32.0912L33.3083 40.609L33.3077 40.8063L28.7373 40.7907ZM36.1462 18.3692L35.3362 21.2819L23.5431 21.1978L24.353 18.329L36.1462 18.3692Z" fill="white"/>
<circle cx="28.4026" cy="28.4816" r="20.1272" transform="rotate(24.1827 28.4026 28.4816)" stroke="white" stroke-width="2.80814"/>
</svg>

  )
}

export default NormalWithdrawalIcon