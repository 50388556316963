import { MEMBER_ORDER } from '../../graphql/subscription';
import util from '../../util/util';
import Subscribe from './Subscribe'
import { useIdleTimeout } from '../eu-content/IdleTimeout';
import { any } from 'prop-types';

// Code Changed - Manoj (add ISubscribeOrderListProps interface and injected inside SubscribeOrderList)
interface ISubscribeOrderListProps {
	subscribeToMore?:any,
	loading?:any,
	memberId?:any
}

const SubscribeOrderList: React.FC<ISubscribeOrderListProps> = (props: any) => {
	const { isSessionStop } = useIdleTimeout('2');
	// subscribe資料格式
	const subDataStructure = ['data', 'memberOrder'];
	// query資料格式
	const originDataStructure = ['exchange', 'orders'];
	const subscribeParams = {
		// subscribe資料如何對應的query資料的格式
		formatMarket: (previous: any, subscribeData: any) => {
			return {
				[originDataStructure[0]]: {
					__typename: previous[originDataStructure[0]].__typename,
					[originDataStructure[1]]: util.getValue(subscribeData, subDataStructure, []),
				},
			};
		},
		subDataStructure,
		originDataStructure,
	};

	const memberId = props.memberId;
	if (!memberId || isSessionStop) {
		return <>{props.children}</>;
	}
	return <Subscribe {...props} {...subscribeParams} query={MEMBER_ORDER} variables={{ input: { memberId } }} />;
};

export default SubscribeOrderList;
