import React, { useEffect } from 'react'
import { withAppContext } from 'src/store/initAppContext';
import styled, { withTheme } from 'styled-components';
import { LazyLoadImage } from "react-lazy-load-image-component";
import config from "src/config/config";
import PlaceholderImage from 'src/components/Placeholder';
import { theme } from 'styled-tools';
import FooterContainer from 'src/components/footer/footer';
import { useNavigate } from 'react-router-dom';

function CrazyInstantChallengeFun(props) {
    const { app: {
        brandId
    }, theme } = props;

    useEffect(() => {
        window.scrollTo(0, 0)
    }, [])

     const navigate = useNavigate()

    const gamesList = [
        { image: "brand/6/image/CICGameList01.png", url: "/instant-games/spribe/aviator" },
        { image: "brand/6/image/CICGameList02.png", url: "/instant-games/plinkox" },
        { image: "brand/6/image/CICGameList03.png", url: "/instant-games/galaxsys/rocketon" },
        { image: "brand/6/image/CICGameList04.png", url: "/instant-games/cricketx" },
        { image: "brand/6/image/CICGameList05.png", url: "/instant-games/helicopterx" },
        { image: "brand/6/image/CICGameList06.png", url: "/instant-games/cappadocia" },
        { image: "brand/6/image/CICGameList07.png", url: "/instant-games/taxi" },
        { image: "brand/6/image/CICGameList08.png", url: "/instant-games/comet-crash" },
        { image: "brand/6/image/CICGameList09.png", url: "/instant-games/spribe/mines" },
        { image: "brand/6/image/CICGameList10.png", url: "/instant-games/penalty-shootout" },
        { image: "brand/6/image/CICGameList11.png", url: "/instant-games/maestro" },
        { image: "brand/6/image/CICGameList12.png", url: "/instant-games/footballx" },
        { image: "brand/6/image/CICGameList13.png", url: "/instant-games/smartsoft/balloon" },
        { image: "brand/6/image/CICGameList14.png", url: "/instant-games/daredevil" },
        { image: "brand/6/image/CICGameList15.png", url: "/instant-games/archeo" },
        { image: "brand/6/image/CICGameList16.png", url: "/instant-games/gold-rush" }
    ];
    

    const redirectToGame = (e, url) => {
        e.preventDefault();
       if(url){
        navigate(url);
       } else {
        return;
       }
    }

    return (
        <>
            <InformationWrapper>
                <CICFirstSecWrap>
                    <ImgWrap>
                        <LazyLoadImage
                                src={`${config.imageV3Host}/static/cashsite/${theme.image.CICFirstSecBGYolo}`}
                                alt="Instant"
                                effect="none"
                                placeholder={<PlaceholderImage />}
                        />
                    </ImgWrap>
                    <CICFirstSecContent>
                      <h2>Fun Instant Challenge 2025</h2>
                      <ul>
                        <li>Win a pool prize of <b>INR 46 Lakhs</b>.</li>
                        <li>All You Need To Do Is Play Any Of Your Favourite Instant Games <br />from <b>15th JAN - 15th MAR 2025</b> to enter this challenge.</li>
                        <li><b>140 lucky players</b> stand a chance to win!</li>
                        <li>Winners will be declared weekly on <b>FUN88 social media handles</b>.</li>
                        <li>Crazy bonus will be credited as bonus in your <b>FUN88 wallet</b>.</li>
                      </ul>
                      </CICFirstSecContent>
                </CICFirstSecWrap>
                <CICGamesSecWrap>
                     <h2>Games <span>(16)</span></h2>
                     <CICGamesList>
                            {gamesList.map((game, i) => (
                                <LazyLoadImage
                                        key={i}
                                        src={`${config.imageV3Host}/static/cashsite/${game.image}`}
                                        alt="bigBashBigCashImage"
                                        effect="none"
                                        placeholder={<PlaceholderImage />}
                                        onClick={(e) => redirectToGame(e, game.url)}
                                    />
                            ))}
                     </CICGamesList>
                </CICGamesSecWrap>

                <CICFeaturesWrap>
                    <CICFeaturesBox>
                        {/* <LazyLoadImage
                            src={`${config.imageV3Host}/static/cashsite/${theme.image.CICSteps01}`}
                            alt="Instant"
                            effect="none"
                            placeholder={<PlaceholderImage />}
                        /> */}
                        <CICFeaturesBoxContent>
                            <CICMainHeadWrap>
                                <h2>Championship Leaderboard</h2>
                            </CICMainHeadWrap>

                            <ul>
                                <li>Get ready for an adrenaline-packed competition! Each week marks a Crazy Round in the Championship Leaderboard, with a total of <b>4 Crazy Rounds</b> throughout the campaign.</li>
                                <li>At the end of each week, the prize pool is displayed on promo page with <b>top players</b> current standings.</li>
                                <li>A total of <b>140 players</b> have a chance to win crazy bonuses during these rounds.</li>
                            </ul>
                        </CICFeaturesBoxContent>
                    </CICFeaturesBox>

                    <CICFeaturesBox>
                        {/* <LazyLoadImage
                            src={`${config.imageV3Host}/static/cashsite/${theme.image.CICSteps02}`}
                            alt="Instant"
                            effect="none"
                            placeholder={<PlaceholderImage />}
                        /> */}
                        <CICFeaturesBoxContent>
                            <CICMainHeadWrap>
                                <h2>Telegram Bonus Drop</h2>
                            </CICMainHeadWrap>

                            <ul>
                                <li>Stay connected and win big with our <b>Telegram Bonus</b> Drops!</li>
                                <li><b>Weekly Promotions: </b> Exclusive promo bonuses for the top 10 ranks delivered straight to our Telegram community.</li>
                                <li><b>Weekly Free Bets:</b> Don't miss your chance to grab free bets every week and enhance your gameplay.</li>
                            </ul>
                        </CICFeaturesBoxContent>
                    </CICFeaturesBox>

                    <CICFeaturesBox>
                        {/* <LazyLoadImage
                            src={`${config.imageV3Host}/static/cashsite/${theme.image.CICSteps03}`}
                            alt="Instant"
                            effect="none"
                            placeholder={<PlaceholderImage />}
                        /> */}
                        <CICFeaturesBoxContent>
                            <CICMainHeadWrap>
                                <h2>Turbo Bonus Leaderboard</h2>
                            </CICMainHeadWrap>

                            <ul>
                                <li>Take your betting to the next level with the <b>Turbo Bonus Leaderboard!</b></li>
                                <li>10 Consecutive Winning Bets: Earn a special bonus worth <b>500 INR</b>.</li>
                                <li>20 Consecutive Winning Bets: Unlock a special bonus worth <b>1,000 INR</b>.</li>
                                <li>30 Consecutive Winning Bets: Claim a special bonus worth <b>3,000 INR</b>.</li>
                                <li>This feature encourages consistent play and adds an <b>exciting layer</b> of quick rewards for <b>our players</b></li>
                            </ul>
                        </CICFeaturesBoxContent>
                    </CICFeaturesBox>

                    <CICFeaturesBox>
                        {/* <LazyLoadImage
                            src={`${config.imageV3Host}/static/cashsite/${theme.image.CICSteps04}`}
                            alt="Instant"
                            effect="none"
                            placeholder={<PlaceholderImage />}
                        /> */}
                        
                        <CICFeaturesBoxContent>
                            <CICMainHeadWrap>
                                <h2>Weekly Cashback Offer</h2>
                            </CICMainHeadWrap>

                            <ul>
                                <li>Enjoy a <b>10% cashback</b> on your weekly losses!</li>
                                <li>Deposit <b>every day and play</b> to your heart's content.</li>
                                <li>If your net profit as of <b>Sunday 12:00 AM</b> is negative, you'll receive up to <b>10% cashback</b> on your losses.</li>
                                <li>This offer ensures you always have something to look forward to on <b>FUN88</b>, even during a tough week.</li>
                            </ul>
                        </CICFeaturesBoxContent>
                    </CICFeaturesBox>
                </CICFeaturesWrap>

                <CICPrizeDetailsWrap>
                    <h2>Championship Leaderboard FUN Instant <br /> challenge 46 Lakhs</h2>
                    <p>Aviator, Mines, PlinkoX, Penalty shoot out, Rocket on, Masetro, CricketX, Footballx, HelicopterX, Balloon,  Cappadocia, daredevil, taxiride.</p>
                    <ImageWrapper>
                        <LazyLoadImage
                            src={`${config.imageV3Host}/static/cashsite/${theme.image.CICPrizeTable}`}
                            alt="Instant"
                            effect="none"
                            placeholder={<PlaceholderImage />}
                        />
                    </ImageWrapper>
                </CICPrizeDetailsWrap>


                    <CICTermsAndCondition>
                        <CICMainHeadWrap>
                            <h2>Terms & Conditions</h2>
                        </CICMainHeadWrap>
                        <ol>
                            <li>This promotion is valid for all new and existing players.</li>
                            <li>
                                Fun Instant Challenge is valid from 15th JAN -15th MAR 2025. Leaders will be announced weekly on FUN88 telegram channel.
                            </li>
                            <li>For a bet to be valid, a minimum cash-out of 1.30 is required.</li>
                            <li>
                                Void, cancelled, or draw bets, as well as cashed-out bets, do not count towards the deposit or bonus rollover requirements.
                            </li>
                            <li>
                                Multiple accounts will not be considered for promotion eligibility/criteria. All players will be subject to duplicate checking. <br />
                                FUN88 reserves the right to disqualify players if collusion or any foul play is detected in the promotion.
                            </li>
                            <li>This promotion cannot be used in conjunction with, or contribute to the eligibility for, any other promotion.</li>
                            <li>FUN88 reserves the right to amend, cancel, reclaim, or refuse any promotion at its discretion.</li>
                            <li>FUN88 General Terms & Conditions apply.</li>
                        </ol>
                    </CICTermsAndCondition> 
            </InformationWrapper>
            <FooterContainer />
        </>
    )
}



export default withTheme(withAppContext(CrazyInstantChallengeFun));

const InformationWrapper = styled.div`
    width: 100%;
    height: auto;
    padding: 1.5rem 3rem 8rem 3rem;
    border-radius: 1px;
    background: #ebebeb;
    margin: 0em;
    border-top-color: transparent;
    backdrop-filter: brightness(0.5);    
    display: flex;
    flex-direction: column;

    h1 {
        color: #E6FF00;
        text-align: center;
        font-size: 20px;
        font-style: normal;
        font-weight: 700;
        line-height: normal;
        text-transform: capitalize;
    }
`;

const ImageWrapper = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    padding-bottom: 3rem;

    img {
        width: 90%;
    }
`;


const CICMainHeadWrap = styled.div`
    width: 85%;
    margin-left: 4%;

    & h2 {
        color: #06A6FF;
        font-size: 20px;
        font-style: normal;
        font-weight: 600;
        line-height: normal;
        margin: 3px 0px;
    }
`

const CICTermsAndCondition = styled.div`
    height: auto;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    padding: 3rem;
    border-radius: 22.706px;
    border: 0.908px solid #FFF;
    background: #FFF;
    width: 90%;
    margin: auto;

    & ol {
        padding-left: 15px;
    }

    & li {
        color: #000;
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: 22.8px; /* 142.5% */
        text-transform: capitalize;
        margin: 1rem 0;
    }

    & h4 {
        color: #06A6FF;
        font-size: 20px;
        font-style: normal;
        font-weight: 700;
        line-height: 35px; /* 175% */
        text-transform: capitalize;
        margin: 10px 0px 0px 15px;
    }

    & ${CICMainHeadWrap} {
      margin-left: 0%;
      width: 40%;
    }
`;

const CICFirstSecWrap = styled.div`
    width: 90%;
    height: auto;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 11.188px;
    background: #FFF;
    margin: auto;
    padding: 15px 0px 0px 0px;
    
`

const CICFirstSecContent = styled.div`
    width: 50%;
    height: auto;

    & h2 {
        color: #06A6FF;
        font-size: 22px;
        font-style: normal;
        font-weight: 600;
        line-height: normal;
        margin: 3px 0px;
    }

    & ul {
            display: flex;
            flex-direction: column;
            gap: 20px;
            justify-content: space-between;
            padding-left: 15px;
            width: 80%;

        li {
            color: #444;
            font-size: 14.243px;
            font-style: normal;
            font-weight: 400;
            line-height: 150%;
            text-transform: capitalize;
            margin: 3px 0px;
        }
}
`

const CICGamesSecWrap = styled.div`
        display: flex;
        flex-direction: column;
        width: 90%;
        margin: auto;
        height: auto;
        gap: 5px;
        align-items: center;
        margin-top: 20px;
        border-radius: 11.188px;
        background: #FFF;
        padding: 20px 0px 30px 0px;

        & h2 {
            color: #06A6FF;
            font-size: 22px;
            font-style: normal;
            font-weight: 600;
            line-height: normal;
            margin: 3px 0px;
    


        & span {
            font-size: 18px;
        }
    }
`

const  CICGamesList = styled.div`
    display: flex;
    flex-wrap: wrap;
    width: 90%;
    height: auto;
    justify-content: center;
    gap: 3px;
    margin-top: 20px;

    & img {
        width: 100%;
        height: 140px;
        object-fit: contain;
        cursor: pointer;
    }
`

const CICFeaturesWrap = styled.div`
    width: 100%;
    height: auto;
    display: flex;
    flex-wrap: wrap;
    gap: 15px;
    margin-top: 3em;
    justify-content: center;
`

const CICFeaturesBox = styled.div`
    border-radius: 11.706px;
    background: #fff;
    width: 45%;
    display: flex;
    justify-content: flex-start;
    flex-direction: column;
    position: relative;
    padding: 30px 15px;

    & img {
        width: 80%;
        height: 300px;
        z-index: 999;
        position: relative;
        object-fit: contain;
        margin: -115px auto 0px auto;
    }

   

    & ul li {
        color: #444;
        font-size: 14.243px;
        font-style: normal;
        font-weight: 400;
        line-height: 150%; /* 21.365px */
        text-transform: capitalize;
        margin: 15px 0px;

        & span {
            color: #000;
            font-family: 700;
        }
    }
`



const CICFeaturesBoxContent = styled.div`
    
`

const CICPrizeDetailsWrap = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 5em;

    & h2 {
        color: #06A6FF;
        text-align: center;
        font-size: 22px;
        font-style: normal;
        font-weight: 600;
        line-height: normal;
        margin: 3px 0px;
    }

    & p {
        color: #444;
        text-align: center;
        font-size: 14.243px;
        font-style: normal;
        font-weight: 600;
        line-height: 150%; /* 21.365px */
        text-transform: capitalize;
        margin: 6px 0px 15px 0px;
    }
`

const ImgWrap = styled.div`
    width: 40%;

    & img {
        width: 100%;
        height: auto;
        object-fit: contain;
    }
`