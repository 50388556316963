import React from 'react'
import { withAppContext } from 'src/store/initAppContext'

type props = {
    iconName: string;
    brandId: any
}

const QuikLinkIcons = (props: any) => {
    const { app: { brandId }, iconName } = props;

    if (iconName === "infinite")
        return (
            <>

                {brandId === 46 || brandId === 47 ? <svg xmlns="http://www.w3.org/2000/svg" width="28" height="28" viewBox="0 0 30 15" fill="none">
                    <path d="M16.63 5.31833C18.0967 3.98917 19.9117 2 22.0933 2C25.1275 2 27.5933 4.46583 27.5933 7.5C27.5933 10.5342 25.1275 13 22.0933 13C19.0592 13 16.8317 9.7275 14.87 7.49083L14.8333 7.445C11.8725 4.01667 10.5342 2 7.5 2C4.46583 2 2 4.45667 2 7.5C2 10.5433 4.46583 13 7.5 13C9.7825 13 10.7175 11.6342 13 9.5625" stroke="white" stroke-width="3.4925" stroke-miterlimit="10" />
                </svg> : 
                <svg xmlns="http://www.w3.org/2000/svg" width="28" height="28" viewBox="0 0 30 15" fill="black" >
                    <g clip-path="url(#clip0_1428_341)">
                        <path d="M30.0001 8.41202C29.8523 8.61753 29.8781 8.86837 29.8304 9.09732C29.2092 12.0745 26.6967 14.4633 23.6678 14.904C20.9628 15.2978 18.6567 14.4836 16.8427 12.4535C14.6719 10.0241 12.5661 7.53762 10.4196 5.08633C9.43792 3.96578 8.1785 3.54617 6.72509 3.83529C5.28419 4.12207 4.32124 5.00584 3.88318 6.40379C3.09154 8.9301 5.20361 11.4619 7.84214 11.186C8.91695 11.0735 9.79307 10.614 10.49 9.79747C10.9015 9.31533 11.3271 8.84492 11.7338 8.35888C11.8574 8.2112 11.9177 8.22136 12.0373 8.36123C12.7664 9.21375 13.5048 10.0577 14.2401 10.9055C14.3004 10.9743 14.3903 11.0266 14.2863 11.1454C13.4243 12.1253 12.6123 13.1482 11.471 13.8483C6.97462 16.6067 1.03814 13.9436 0.138556 8.75506C0.0751942 8.38936 0.0423398 8.0221 0.0212191 7.65249C-0.00772408 7.53528 -0.00772408 7.41728 0.0212191 7.30007C0.153419 4.66749 1.30254 2.60144 3.52334 1.16598C6.53109 -0.777391 10.677 -0.24603 13.0605 2.42093C15.25 4.87144 17.3801 7.37509 19.5383 9.85295C21.429 12.0237 24.9131 11.4923 25.9801 8.87227C27.0377 6.27642 24.9171 3.47271 22.126 3.76652C21.052 3.87983 20.1758 4.3393 19.4804 5.15822C19.076 5.6341 18.6599 6.09826 18.2562 6.57492C18.1428 6.70932 18.0779 6.76168 17.9331 6.59055C17.208 5.73568 16.4672 4.89332 15.7327 4.04627C15.6717 3.97594 15.5848 3.92359 15.6889 3.80716C16.5415 2.84602 17.3355 1.83565 18.4541 1.13785C22.8285 -1.5924 28.7117 0.851073 29.8178 5.87321C29.8828 6.16702 29.9453 6.46318 29.9516 6.76637C29.9892 6.85701 29.9633 6.95234 29.9712 7.04533C29.9852 7.15004 29.9399 7.26022 30.0017 7.36102V7.94552C29.9579 8.00412 29.9579 8.06273 30.0017 8.12055V8.4128L30.0001 8.41202Z" fill="#000000" />
                    </g>
                    <defs>
                        <clipPath id="clip0_1428_341">
                            <rect width="30" height="15" fill="white" />
                        </clipPath>
                    </defs>
                </svg>}
            </>
        )


    if (iconName === "History") {
        return (
            <>
                {brandId === 46 || brandId === 47 ? <svg xmlns="http://www.w3.org/2000/svg" width="28" height="28" viewBox="0 0 26 26" fill="none">
                    <circle cx="13" cy="13" r="13" fill="white" />
                    <path d="M6.32832 11.3539C6.77611 11.0988 7.1645 10.8771 7.55288 10.6563C7.76204 10.5375 7.96879 10.4129 8.18231 10.3022C8.6731 10.0477 9.24842 10.2114 9.52087 10.6766C9.79331 11.1413 9.65709 11.736 9.18514 12.0272C8.70739 12.3219 8.21322 12.591 7.72581 12.8697C7.02924 13.2682 6.33315 13.6682 5.63464 14.0629C5.01777 14.4116 4.44534 14.2421 4.12169 13.6136C3.457 12.3224 2.79472 11.0297 2.13583 9.73561C1.89092 9.25496 1.98367 8.75886 2.3619 8.45115C2.85656 8.04876 3.57439 8.19029 3.88741 8.7632C4.11735 9.18443 4.32844 9.61581 4.57674 10.0979C4.63374 9.97714 4.67093 9.90564 4.70282 9.83125C6.03027 6.73049 8.33737 4.791 11.6483 4.15964C16.6016 3.21526 21.4269 6.56238 22.3582 11.5166C23.2862 16.4526 20.097 21.2363 15.1833 22.2778C11.3348 23.0937 7.33647 21.331 5.33949 17.938C4.93227 17.2462 5.26124 16.4975 6.0351 16.355C6.40947 16.2859 6.81138 16.4927 7.05387 16.897C7.46689 17.5849 7.96831 18.1979 8.58035 18.7162C9.8136 19.7601 11.2319 20.3388 12.8511 20.4441C16.3939 20.6735 19.6115 18.2442 20.353 14.7319C21.2129 10.6611 18.4797 6.8155 14.5534 6.13149C11.0029 5.51317 7.57123 7.54782 6.42879 10.9553C6.39256 11.064 6.37228 11.178 6.32832 11.3539Z" fill="#06A6FF" />
                    <path d="M12.2745 11.1814C12.2745 10.5065 12.2687 9.83215 12.2764 9.1573C12.2822 8.66021 12.6145 8.23897 13.0517 8.14816C13.5323 8.04816 14.0057 8.26699 14.1883 8.70514C14.2565 8.8689 14.2758 9.06261 14.2767 9.24328C14.2845 10.3437 14.2845 11.4437 14.2767 12.5441C14.2753 12.7364 14.3352 12.8741 14.4709 13.0088C15.3332 13.8615 16.1921 14.7175 17.0442 15.5807C17.6278 16.172 17.3906 17.0923 16.6085 17.2942C16.2645 17.3831 15.9448 17.3072 15.6945 17.0599C14.6439 16.0208 13.6009 14.974 12.5585 13.9267C12.3498 13.7165 12.2745 13.4484 12.274 13.1567C12.274 12.4982 12.2745 11.8398 12.2745 11.1814Z" fill="#06A6FF" />
                </svg> : 
                <svg xmlns="http://www.w3.org/2000/svg" width="28" height="28" viewBox="0 0 24 22" fill="none">
                    <g clip-path="url(#clip0_805_276)">
                        <path d="M4.93678 9.21042C5.44576 8.92051 5.88721 8.66849 6.32865 8.41757C6.5664 8.2825 6.8014 8.14085 7.04408 8.01511C7.60193 7.72576 8.25586 7.91189 8.56553 8.44063C8.8752 8.96883 8.72037 9.64472 8.18393 9.97581C7.64091 10.3107 7.07922 10.6166 6.52522 10.9334C5.73347 11.3863 4.94227 11.841 4.14833 12.2895C3.44718 12.686 2.79654 12.4932 2.42867 11.7789C1.67316 10.3113 0.920397 8.842 0.171477 7.37106C-0.106897 6.82475 -0.00147691 6.26086 0.428438 5.91111C0.990677 5.45374 1.80658 5.61462 2.16237 6.2658C2.42373 6.74459 2.66367 7.2349 2.94589 7.78286C3.01067 7.64559 3.05295 7.56433 3.08919 7.47978C4.59801 3.95536 7.22033 1.75088 10.9836 1.03326C16.6137 -0.0401568 22.0982 3.76428 23.1568 9.39545C24.2116 15.0058 20.5867 20.4431 15.0016 21.6269C10.6273 22.5542 6.08267 20.5507 3.81285 16.6941C3.35 15.9079 3.72391 15.0568 4.6035 14.8948C5.02903 14.8163 5.48584 15.0513 5.76147 15.5109C6.23092 16.2928 6.80085 16.9895 7.49651 17.5787C8.89826 18.7652 10.5103 19.4229 12.3508 19.5426C16.3776 19.8034 20.0349 17.0422 20.8777 13.05C21.855 8.42306 18.7484 4.05199 14.2857 3.27452C10.2501 2.57172 6.34952 4.88437 5.05099 8.75744C5.00981 8.88098 4.98675 9.01056 4.93678 9.21042Z" fill="#0d0d0d" />
                        <path d="M11.6948 9.01389C11.6948 8.24684 11.6882 7.48033 11.697 6.71327C11.7035 6.14827 12.0813 5.66948 12.5782 5.56625C13.1245 5.45259 13.6626 5.70132 13.8701 6.19933C13.9476 6.38547 13.9695 6.60565 13.9706 6.811C13.9794 8.0618 13.9794 9.31204 13.9706 10.5628C13.969 10.7814 14.0371 10.9379 14.1913 11.091C15.1714 12.0602 16.1477 13.0331 17.1162 14.0143C17.7795 14.6864 17.5099 15.7324 16.6209 15.9619C16.23 16.0629 15.8665 15.9767 15.5821 15.6956C14.3879 14.5145 13.2025 13.3247 12.0176 12.1343C11.7804 11.8954 11.6948 11.5907 11.6942 11.2591C11.6942 10.5107 11.6942 9.76174 11.6942 9.01335L11.6948 9.01389Z" fill="#000000" />
                    </g>
                    <defs>
                        <clipPath id="clip0_805_276">
                            <rect width="23.322" height="20.8" fill="black" transform="translate(0.0175781 0.852051)" />
                        </clipPath>
                    </defs>
                </svg>}
            </>
        )
    }

    if (iconName === "liveCasino") {
        return (
            <>
                {brandId === 46 || brandId === 47 ? <svg xmlns="http://www.w3.org/2000/svg" width="28" height="28" viewBox="0 0 22 26" fill="none">
                    <path d="M13 18C13.6296 18.8395 14.5075 19.4597 15.5092 19.7726C16.5108 20.0855 17.5856 20.0753 18.5811 19.7434C19.5767 19.4116 20.4426 18.7749 21.0562 17.9236C21.6698 17.0722 22 16.0494 22 15C22 13.872 21.612 12.842 20.982 12.006L21 12L11 0L1 12L1.018 12.006C0.361088 12.8662 0.0035766 13.9177 0 15C0 16.0494 0.330187 17.0722 0.943789 17.9236C1.55739 18.7749 2.4233 19.4116 3.41886 19.7434C4.41442 20.0753 5.48916 20.0855 6.49084 19.7726C7.49252 19.4597 8.37035 18.8395 9 18L10 16.668V21C10 24 5 24 5 24C4.73478 24 4.48043 24.1054 4.29289 24.2929C4.10536 24.4804 4 24.7348 4 25C4 25.2652 4.10536 25.5196 4.29289 25.7071C4.48043 25.8946 4.73478 26 5 26H17C17.2652 26 17.5196 25.8946 17.7071 25.7071C17.8946 25.5196 18 25.2652 18 25C18 24.7348 17.8946 24.4804 17.7071 24.2929C17.5196 24.1054 17.2652 24 17 24C17 24 12 24 12 21V16.668L13 18Z" fill="white" />
                </svg> : 
                <svg width="26" height="26" viewBox="0 0 26 26" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <g clip-path="url(#clip0_3603_61)">
                        <path d="M16.0398 22.5499C19.6021 22.5499 22.4898 19.6621 22.4898 16.0999C22.4898 12.5377 19.6021 9.6499 16.0398 9.6499C12.4776 9.6499 9.58984 12.5377 9.58984 16.0999C9.58984 19.6621 12.4776 22.5499 16.0398 22.5499Z" stroke="black" stroke-miterlimit="10" />
                        <path d="M16.11 25.4799C21.1634 25.4799 25.26 21.3833 25.26 16.3299C25.26 11.2765 21.1634 7.17993 16.11 7.17993C11.0566 7.17993 6.95996 11.2765 6.95996 16.3299C6.95996 21.3833 11.0566 25.4799 16.11 25.4799Z" stroke="black" stroke-miterlimit="10" />
                        <path d="M18.17 6.31C16.84 2.91 13.53 0.5 9.65 0.5C4.6 0.5 0.5 4.6 0.5 9.65C0.5 13.33 2.68 16.51 5.81 17.96" stroke="black" stroke-miterlimit="10" />
                        <path d="M14.4598 6.26006C13.3898 4.78006 11.6498 3.81006 9.67979 3.81006C6.41979 3.81006 3.77979 6.45006 3.77979 9.71006C3.77979 11.4901 4.56979 13.0901 5.81979 14.1701" stroke="black" stroke-miterlimit="10" />
                        <path d="M20.6602 11.6L22.4902 9.77002" stroke="black" stroke-miterlimit="10" />
                        <path d="M16.04 9.65006V7.06006" stroke="black" stroke-miterlimit="10" />
                        <path d="M22.4902 16.1001H25.2502" stroke="black" stroke-miterlimit="10" />
                        <path d="M20.6602 20.6101L22.7102 22.6601" stroke="black" stroke-miterlimit="10" />
                        <path d="M16.04 22.5601V25.4801" stroke="black" stroke-miterlimit="10" />
                        <path d="M11.42 20.6101L9.47998 22.5501" stroke="black" stroke-miterlimit="10" />
                        <path d="M9.58996 16.1001H6.95996" stroke="black" stroke-miterlimit="10" />
                        <path d="M16.1099 12.4299V19.8699" stroke="black" stroke-miterlimit="10" />
                        <path d="M10.9902 8.17995C10.9902 8.17995 10.0602 6.96995 9.75023 6.19995C9.75023 6.19995 8.09023 9.02995 6.49023 9.76995C6.49023 9.76995 7.79024 10.41 8.27024 10.89" stroke="black" stroke-linecap="round" stroke-linejoin="round" />
                        <path d="M11.4202 11.6001L9.66016 9.84009" stroke="black" stroke-linecap="round" stroke-linejoin="round" />
                        <path d="M13.9799 2.82007H13.4599C13.2887 2.82007 13.1499 2.95886 13.1499 3.13007V3.65007C13.1499 3.82128 13.2887 3.96007 13.4599 3.96007H13.9799C14.1511 3.96007 14.2899 3.82128 14.2899 3.65007V3.13007C14.2899 2.95886 14.1511 2.82007 13.9799 2.82007Z" fill="black" />
                        <path d="M6.01018 2.82007H5.49018C5.31897 2.82007 5.18018 2.95886 5.18018 3.13007V3.65007C5.18018 3.82128 5.31897 3.96007 5.49018 3.96007H6.01018C6.18138 3.96007 6.32018 3.82128 6.32018 3.65007V3.13007C6.32018 2.95886 6.18138 2.82007 6.01018 2.82007Z" fill="black" />
                        <path d="M2.39982 9.22998H1.87982C1.70862 9.22998 1.56982 9.36877 1.56982 9.53998V10.06C1.56982 10.2312 1.70862 10.37 1.87982 10.37H2.39982C2.57103 10.37 2.70982 10.2312 2.70982 10.06V9.53998C2.70982 9.36877 2.57103 9.22998 2.39982 9.22998Z" fill="black" />
                        <path d="M5.99016 15.5901H5.47016C5.29895 15.5901 5.16016 15.7289 5.16016 15.9001V16.4201C5.16016 16.5913 5.29895 16.7301 5.47016 16.7301H5.99016C6.16136 16.7301 6.30016 16.5913 6.30016 16.4201V15.9001C6.30016 15.7289 6.16136 15.5901 5.99016 15.5901Z" fill="black" />
                        <path d="M3.21977 16.1601C3.21977 16.1601 4.25977 15.5201 3.51977 14.3701C2.77977 13.2201 1.88977 11.4701 1.88977 11.4701C1.88977 11.4701 1.35977 11.3701 0.759766 11.8301" stroke="black" stroke-miterlimit="10" />
                        <path d="M0.709961 8.15992C0.709961 8.15992 1.72996 8.59992 2.35996 7.38992C3.10996 5.95992 3.65996 4.71992 3.65996 4.71992C3.65996 4.71992 3.60996 3.90992 2.88996 3.66992" stroke="black" stroke-miterlimit="10" />
                        <path d="M7.23003 0.959961C7.23003 0.959961 7.20003 2.17996 8.56003 2.18996C9.92003 2.19996 11.42 2.23996 11.42 2.23996C11.42 2.23996 12.26 2.02996 12.2 1.26996" stroke="black" stroke-miterlimit="10" />
                        <path d="M16.1098 3.38989C16.1098 3.38989 15.3198 4.21989 16.0498 5.52989L16.5198 6.19989" stroke="black" stroke-miterlimit="10" />
                        <path d="M17.7598 13.8101H15.5598C15.5598 13.8101 14.4698 13.8601 14.4698 15.0701C14.4698 16.2801 15.5598 16.1601 15.5598 16.1601H16.5198C16.5198 16.1601 17.7298 16.2201 17.7298 17.3701C17.7298 18.5201 16.5198 18.5801 16.5198 18.5801H14.2998" stroke="black" stroke-linejoin="round" />
                        <path d="M4.80002 21.49L6.01002 22.15L4.80002 22.81L4.14002 24.02L3.48002 22.81L2.27002 22.15L3.48002 21.49L4.14002 20.28L4.80002 21.49Z" stroke="black" stroke-linecap="round" stroke-linejoin="round" />
                        <path d="M22.3498 3.14994L23.5598 3.80994L22.3498 4.46994L21.6898 5.67994L21.0298 4.46994L19.8198 3.80994L21.0298 3.14994L21.6898 1.93994L22.3498 3.14994Z" stroke="black" stroke-linecap="round" stroke-linejoin="round" />
                    </g>
                    <defs>
                        <clipPath id="clip0_3603_61">
                            <rect width="25.76" height="25.98" fill="white" />
                        </clipPath>
                    </defs>
                </svg>}
            </>
        )
    }

    if (iconName === "Message") {
        return (
            <>
                {brandId === 46 || brandId === 47 ? <svg xmlns="http://www.w3.org/2000/svg" width="28" height="28" viewBox="0 0 21 17" fill="none">
                    <path d="M0 6.3V14.7C0 15.257 0.221249 15.7911 0.615076 16.1849C1.0089 16.5787 1.54305 16.8 2.1 16.8H18.9C19.457 16.8 19.9911 16.5787 20.3849 16.1849C20.7787 15.7911 21 15.257 21 14.7V6.3L10.5 10.5L0 6.3Z" fill="white" />
                    <path d="M2.1 0C1.54305 0 1.0089 0.221249 0.615076 0.615076C0.221249 1.0089 0 1.54305 0 2.1V4.2L10.5 8.4L21 4.2V2.1C21 1.54305 20.7787 1.0089 20.3849 0.615076C19.9911 0.221249 19.457 0 18.9 0H2.1Z" fill="white" />
                </svg> : 
                <svg width="25" height="25" viewBox="0 0 25 25" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M4.98828 4.51074H20.9883C22.0883 4.51074 22.9883 5.41074 22.9883 6.51074V18.5107C22.9883 19.6107 22.0883 20.5107 20.9883 20.5107H4.98828C3.88828 20.5107 2.98828 19.6107 2.98828 18.5107V6.51074C2.98828 5.41074 3.88828 4.51074 4.98828 4.51074Z" stroke="black" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                    <path d="M22.9883 6.51074L12.9883 13.5107L2.98828 6.51074" stroke="black" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                </svg>}
            </>
        )
    }

    return null;
}

export default withAppContext(QuikLinkIcons)

