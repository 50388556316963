import React, { useState, useContext, useEffect } from 'react';
//import { GlobalApiContext } from '../../../store/GlobalAppContext'
import styled from 'styled-components';
import { useMutation } from '@apollo/client';
import { format } from 'date-fns';
import { color } from '../../../sass-files/variable';
import UserIcon from '../../../images/user_icon.png';
import PasswordIcon from '../../../images/password_icon.png';
import LoginIcon from '../../../images/login_icon.png';
import OtpContainer from './OtpContainer';
import { AES, pad, mode, enc } from 'crypto-js';
import { LOGIN } from '../../../graphql/login';
import config from '../../../config/config'

import cookieUtil from '../../../util/cookieUtil';
//import ipQSUtil from '../../../util/ipQSUtil';
import util from '../../../util/util';
import { ECookieName } from '../../../util/utilModel';
import { withAppContext } from '../../../store/initAppContext';
// import { Link } from "react-router-dom";
// import routerUtil from 'src/util/routerUtil';
import { useNavigate } from "react-router-dom";
// import { result } from 'lodash';
import Intl from '../../common/Intl';

import { ifProp, theme, withProp } from 'styled-tools';


import initApollo from '../../../store/initApollo';
const { yellowfont } = color
const { menucolor } = color
const { secondarycolor } = color

const TOKEN_SECRET_KEY = config.secretKey;

function LoginForm(props: any) {
    let navigate = useNavigate();
    const [isError, setIsError] = useState(null);
    const [isShow, setIsShow] = useState(false);

    const { emitModel } = props;
    const [login, { loading, error, data }] = useMutation(LOGIN);
    // const { handleLogin, handleUserInfo} = useContext(GlobalApiContext)
    const [formData, setFormData] = useState({
        username: '',
        password: '',
        isRememberMe: false,
        validAge: false,
    })
    const [isDefaultPassword, setIsDefaultPassword] = useState(false);
    const [apiState, setapiState] = useState({
        localMessageLangKey: null,
        responseMessage: '',
        isError: false,
        isLoaded: false,
    });
    // console.log("yearChangeHandler",yearOld)

    useEffect(() => {
        const deviceIdKeys = window.document.cookie
            .split('; ')
            .map((_: string) => _.split('=')[0])
            .filter((_: string) => _.indexOf('device_id_') === 0);

        deviceIdKeys.forEach((key: string) => {
            cookieUtil.remove(key, null, { domain: `.${(global as any).location.host}` });
        });

        setRememberPassword()

    }, []);

    const setRememberPassword = () => {
        const username = window.sessionStorage.getItem("isUserNameRemember")
        const password = window.sessionStorage.getItem("isUserPasswordRemember")
        setFormData({ ...formData, username, password })
    }

    const triggerRememberMe = () => {
        let remember = formData.isRememberMe
        setFormData({ ...formData, isRememberMe: !remember })
    }

    const eighteenPlus = () => {
        let plus = formData.validAge
        setFormData({ ...formData, validAge: !plus })
    }

    const setSuccessCookie = (data: any, username: string) => {
        cookieUtil.set(ECookieName.COOKIE_USERNAME, username, 30);
        cookieUtil.set(ECookieName.COOKIE_MEMBERID, data.memberId, 30);
        cookieUtil.set(ECookieName.COOKIE_ORIGINAL_MEMBERID, data.originalMemberId, 30);
        cookieUtil.set(ECookieName.COOKIE_ORDER_LIMIT, JSON.stringify(data.orderLimit), 30);
        // cookieUtil.set(ECookieName.COOKIE_LANG, 'en-us', 30);
        cookieUtil.set(ECookieName.COOKIE_EXCHANGE_RATE, data.exchangeRate, 30);
        cookieUtil.set(ECookieName.COOKIE_ACCOUNT_TYPE, data.type);
        cookieUtil.set(ECookieName.COOKIE_TOKEN, data.token);
        cookieUtil.set(ECookieName.COOKIE_IS_SUBACCOUNT, data.isSubAccount ? '1' : '');
        cookieUtil.set(ECookieName.COOKIE_ACCOCIATIONS, JSON.stringify(data.associations || []));
        cookieUtil.set(ECookieName.COOKIE_USERINFO, JSON.stringify(data || {}));
        cookieUtil.set(
            ECookieName.COOKIE_BFPT_INFO,
            JSON.stringify(
                data.betfairInfo.map((_: { betfairPt: number; categoryId: number }) => ({
                    bfPt: _.betfairPt,
                    categoryId: _.categoryId,
                })),
            ),
            30,
        );

        const { setState } = props.app || {};

        if (typeof setState === 'function') {
            let _reqData = {
                username: username,
                memberId: data.memberId,
                token: data.token,
                exchangeRate: data.exchangeRate,
                lang: 'en-us',
                originalMemberId: data.originalMemberId,
                bfInfo: data.betfairInfo.map((_: { betfairPt: number; categoryId: number }) => ({
                    bfPt: _.betfairPt,
                    categoryId: _.categoryId,
                })),
                orderLimit: data.orderLimit,
                type: data.type
            }
            setState(_reqData);
            try {
                initApollo({
                    memberId: _reqData.memberId,
                    lang: _reqData.lang,
                    token: _reqData.token,
                    type: _reqData.type,
                    username: _reqData.username
                });

            } catch (err) {
                console.log('err@', err)
            }
        }
    };
    const successLogin = (data: any) => {

        if (!data.isChangedPassword) {
            cookieUtil.set(ECookieName.COOKIE_IS_DEFAULT_PASSWORD, '1', 30);
            setIsDefaultPassword(true);
            return;
        }

        setTimeout(() => {
            emitModel(false)
            window.location.reload();
        }, 100);
    };

    const onSubmit = async (event, authotp?: any) => {
        event.preventDefault();

        const { username, password, isRememberMe, validAge } = formData;

        if (!username || !password) {
            if (!username) {
                setIsError(<Intl langKey="LOGIN@ENTERUSERNAME" />)
            } else {
                setIsError(<Intl langKey="LOGIN@ENTERPASSWORD" />)
            }
            return;
        }
        if (!formData.validAge) {
            setIsError(<Intl langKey="LOGIN@18YEARS" />)
            return;
        }
        // if(!username || !password || !)

        if (isRememberMe) {
            window.sessionStorage.setItem("isUserNameRemember", username);
            window.sessionStorage.setItem("isUserPasswordRemember", password);
        }
        const { app: {
            brandId
        } } = props
        const subDomain = window.document ? window.location.host.split('.')[0] : '';
        const isAgentSite = ['agent', 'admin'].some(_ => subDomain.includes(_));
        const info = JSON.stringify({ username: username.trim(), password: password.trim(), brandId, isAgentSite, authotp });
        const encryptedTarget = enc.Utf8.parse(info)
        const encryptSecretKey = enc.Utf8.parse(TOKEN_SECRET_KEY)
        const authInfo = AES.encrypt(encryptedTarget, encryptSecretKey, {
            iv: enc.Utf8.parse(''),
            padding: pad.Pkcs7,
            mode: mode.CBC,
        }).ciphertext.toString();


        try {
            const loginInfo = await login({
                variables: {
                    input: {
                        authInfo,
                        deviceType: window.innerWidth > 576 ? 1 : 2,
                        localCreatedTime: format(new Date(), 'yyyy-MM-dd hh:mm:ss'),
                    },
                },
            });

            const payload = util.getValue(loginInfo, ['data', 'member', 'login'], {});

            if (payload.success) {
                // setIsError('You have successfully logged in')
                //handleLogin()
                const { data } = payload;
                // handleUserInfo(data)
                if (data.isHadAuth && payload.code === 'MEMBER_LOGIN_2FA_CODE_NOT_PRESENT') {
                    cookieUtil.remove(ECookieName.COOKIE_IS_HAD_AUTH);
                    cookieUtil.set(ECookieName.COOKIE_IS_HAD_AUTH, 'true', 30);
                    setIsShow(true)
                    // setSuccessCookie(data, username);
                    // successLogin(data);
                }
                else {
                    setSuccessCookie(data, username);
                    successLogin(data);
                    // cookieUtil.remove(ECookieName.COOKIE_IS_HAD_AUTH);
                    // cookieUtil.set(ECookieName.COOKIE_IS_HAD_AUTH, 'false', 30);
                    // cookieUtil.set(ECookieName.COOKIE_IS_HAD_AUTH, 'false', 30);
                    if (data.type !== 'CLIENT') {
                        cookieUtil.set(ECookieName.COOKIE_IS_HAD_AUTH, '1', 30);
                    }

                    if (data.isChangedPassword) {
                        setSuccessCookie(data, username);
                        successLogin(data);
                    }
                    else {
                        // setSuccessCookie(data, username);
                        cookieUtil.set(ECookieName.COOKIE_IS_DEFAULT_PASSWORD, '1', 30);
                        setIsDefaultPassword(true);
                        navigate('/changepassword')
                    }
                }
            } else {

                setIsError(<Intl langKey="LOGIN@ERROR_MESSAGE" />)

                setapiState({

                    localMessageLangKey: <Intl langKey="LOGIN@ERROR_MESSAGE" />, //added 'LOGIN@ERROR_MESSAGE'
                    responseMessage: '', //util.getValue(loginInfo, ['data', 'member', 'login', 'message']),
                    isError: true,
                    isLoaded: true,
                });
            }


        } catch (error) {
            setapiState(() => {
                return {
                    localMessageLangKey: <Intl langKey="LOGIN@ERROR_MESSAGE" />,
                    responseMessage: '',
                    isError: true,
                    isLoaded: true,
                };
            });
            console.error('Login failed: ', error);
        }
    }

    return (
        <>
            <div style={{ display: "flex", justifyContent: "center", width: "100%", alignItems: "center", color: "red", padding: "30px 0px" }}>{isError}</div>

            <LoginFormWrapper className='loginModal' >
                <form onSubmit={onSubmit}>
                    <label><Intl langKey="LOGIN@USERNAME@LABEL" /></label>
                    <span className="user">
                        <input type="text" value={formData.username} onChange={(e) => setFormData({ ...formData, username: e.target.value })} />
                    </span>

                    <label><Intl langKey="LOGIN@PASSWORD@LABEL" /></label>
                    <span className="password">
                        <input type="password" value={formData.password} onChange={(e) => setFormData({ ...formData, password: e.target.value })} />
                    </span>




                    <LoginFooter>
                        {/* <div><input type="checkbox"></input>Remember me</div>
                    <div><input type="checkbox"></input>I am over 18 year Old</div> */}
                        <div className="container">
                            <input type="radio" name="rememberme" checked={formData.isRememberMe} onClick={() => triggerRememberMe()} />
                            <span className="checkmark"></span>
                            <Intl langKey="LOGIN@REMEMBER_ACCOUNT" />
                        </div>
                        <div className="container">
                            <input type="radio" name="validage" checked={formData.validAge} onClick={() => eighteenPlus()} />
                            <span className="checkmark"></span>
                            <Intl langKey="LOGIN@CHECKBOX" />
                        </div>
                    </LoginFooter>
                    <button className="login_btn"><Intl langKey="LOGIN@TITLE" /></button>
                    {/* <a href="#" className="forgot">Forgot your password ?</a> */}
                </form>

            </LoginFormWrapper>
            {isShow && (
                <OtpContainer
                    googleCode={(event, authotp) => {
                        onSubmit(event, authotp)
                    }}
                />
            )}

        </>
    )
}

export default withAppContext(LoginForm);
const LoginFormWrapper = styled.div`
    display: flex;
    align-items: center;
    flex-direction: row;
    

    form {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        width: 100%;
        // padding: 50px;
        padding: 0px 50px ;

        .login_btn {
            width: 100%;
            padding: 5px 10px;
            background: ${theme('palette.Button-background-color-new')};
            box-shadow: -4.43231e-16px 4px 4px rgba(203, 150, 64, 0.35), 1.42467e-15px 4px 4px rgba(2, 8, 19, 0.24), inset 4.43231e-16px 4px 4px rgba(203, 150, 64, 0.25);
            border-radius: 20px;
            color: ${secondarycolor};
            font-size: 20px;
            text-transform: uppercase;
            font-weight: bold;
            
            margin: 20px 0px 5px 0px;
            cursor: pointer;
            position: relative;
            border: 1px solid ${theme('palette.primary-background-color-new')};

        }

        .login_btn:hover {
            background: ${theme('palette.secondary-background-color')};
            transition: .4s;
            border: 1px solid ${theme('palette.primary-background-color-new')};
        }

        .login_btn:before {
            content: "";
            display: inline-block;
            width: 45px;
            height: 40px;
            background-image: url(${LoginIcon});
            background-size: 45%;
            position: absolute;
            z-index: 99;
            background-position: center;
            background-repeat: no-repeat;
            left: 0;
            top: 0;
            box-shadow: 2px 0px 2px rgba(115, 112, 108, 0.5);
        }

        .forgot {
            font-size: 13px;
            text-transform: uppercase;
            color: ${menucolor};
            padding-top: 20px;
        }

        .forgot:hover {
            color: ${yellowfont};
        }

        label {
            font-size: 18px;
            text-transform: uppercase;
            /* color: ${theme('palette.primary-background-color-new')}; */
            color: ${theme('palette.livetvColor')};
            
            font-weight: 500;
            padding-bottom: 10px;
        }

        .user, .password {
            width: 100%;
            position: relative;
        }

        .user input, .password input {
            width: 100%;
            background-color: #FFFFFF;
            border: 1px solid #E6E7E8;
            box-shadow: inset 0px 4px 4px rgb(0 0 0 / 25%), inset 11.6333px -11.6333px 11.6333px rgb(7 30 72 / 10%), inset -11.6333px 11.6333px 11.6333px rgb(255 255 255 / 10%);
            backdrop-filter: blur(23.2667px);
            border-radius: 10px;
            padding: 16px 10px 10px 75px;
            margin-bottom: 10px;
            font-size: 16px;
        }

        .user:before {
            content: "";
            display: inline-block;
            width: 75px;
            height: 45px;
            background-image: url(${UserIcon});
            background-size: 30%;
            background-repeat: no-repeat;
            background-color: ${theme('palette.Button-background-color-new')};
            position: absolute;
            z-index: 99;
            background-position: center;
            border-radius: 10px 0px 0px 10px;
            // box-shadow: 4px 0px 4px rgb(0 0 0 / 25%), 1.42467e-15px 23.2667px 46.5333px rgb(2 8 19 / 24%), inset -4.43231e-16px 4px 4px #1d4d9e, inset 4.43231e-16px 4px 4px #1c56b9;
        }

        .password:before {
            content: "";
            display: inline-block;
            width: 75px;
            height: 45px;
            background-image: url(${PasswordIcon});
            background-size: 30%;
            background-repeat: no-repeat;
            background-color: ${theme('palette.Button-background-color-new')};
            position: absolute;
            z-index: 99;
            background-position: center;
            border-radius: 10px 0px 0px 10px;
            // box-shadow: 4px 0px 4px rgb(0 0 0 / 25%), 1.42467e-15px 23.2667px 46.5333px rgb(2 8 19 / 24%), inset -4.43231e-16px 4px 4px #1d4d9e, inset 4.43231e-16px 4px 4px #1c56b9;
        }
    }
`;

const LoginFooter = styled.div`
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-evenly;
    flex-direction: row;

   

    // Custom Radio button styling
    
     .container {
        display: block;
        align-items: center;
        font-size: 14px;
        color: ${menucolor};
        position: relative;
        padding-left: 25px;
        margin-top: 16px;
        cursor: pointer;
        -webkit-user-select: none;
        -moz-user-select: none;
        -ms-user-select: none;
        user-select: none;
        
    }
      
      /* Hide the browser's default radio button */
      .container input {
        position: absolute;
        opacity: 0;
        cursor: pointer;
        left: 0;
        z-index: 999;
        width: 100%;
        height: 100%;
        bottom: 0;
        top: 0;
        width:51%;
      }
      
      /* Create a custom radio button */
      .checkmark {
        position: absolute;
        top: 2px;
        left: 0;
        height: 17px;
        width: 17px;
        background-color: ${secondarycolor};
        border: 1px solid ${yellowfont};
        border-radius: 50%;
      }
      
      /* On mouse-over, add a grey background color */
      .container:hover input ~ .checkmark {
        background-color: ${secondarycolor};
      }
      
      /* When the radio button is checked, add a blue background */
      .container input:checked ~ .checkmark {
        background-color: ${secondarycolor};
        border: 1px solid ${yellowfont};
      }
      
      /* Create the indicator (the dot/circle - hidden when not checked) */
      .checkmark:after {
        content: "";
        position: absolute;
        display: none;
      }
      
      /* Show the indicator (dot/circle) when checked */
      .container input:checked ~ .checkmark:after {
        display: block;
      }
      
      /* Style the indicator (dot/circle) */
      .container .checkmark:after {
            top: 2px;
            left: 2px;
            width: 11px;
            height: 11px;
            border-radius: 50%;
            background: ${yellowfont};
      }
`;
