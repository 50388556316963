import React, { useEffect } from 'react'
import { withAppContext } from 'src/store/initAppContext';
import styled, { withTheme } from 'styled-components';
import { LazyLoadImage } from "react-lazy-load-image-component";
import config from "src/config/config";
import PlaceholderImage from 'src/components/Placeholder';
import { theme } from 'styled-tools';
import FooterContainer from 'src/components/footer/footer';

function BigBashBigCashPromo(props) {
    const { app: {
        brandId
    }, theme } = props;

    useEffect(() => {
        window.scrollTo(0, 0)
    }, [])

    const domain = (brandId == 31 || brandId == 32) ? "Yolo247" : (brandId == 39 || brandId == 40) ? "FOMO7" : 'iw247';

    return (
        <>
            <InformationWrapper>
                {(brandId == 31 || brandId == 32) ?
                    <TitleText>
                        <p>This Big Bash, play like a cricket superstar only on <strong>{domain}</strong>!</p>
                        <p>Presenting the offer of the year for all cricket fans! Lost the last match? No worries, <strong>{domain}</strong> gives you a chance to play every match!</p>
                    </TitleText> :
                    <TitleText>
                        <p>This Big Bash, walk the red carpet like a cricket superstar only on <strong>{domain}</strong>!</p>
                        <p>We present the offer of the year for all cricket fans! Every day is a chance to win on <strong>{domain}</strong>.</p>
                    </TitleText>
                }
                <ImageWrapper>
                    <LazyLoadImage
                        src={`${config.imageV3Host}/static/cashsite/${theme.image.BigBashBigCashTeamImage}`}
                        alt="bigBashBigCashTeams"
                        effect="none"
                        placeholder={<PlaceholderImage />}
                    />
                </ImageWrapper>
                <h1>{(brandId == 31 || brandId == 32) ? 'Experience the thrill of daily bonus – 100% Loss back' : 'Experience the thrill of rewards – 100% Loss back'}</h1>
                <ContentWrapper>
                    <Container>
                        <Content>
                            <LazyLoadImage
                                src={`${config.imageV3Host}/static/cashsite/${theme.image.BigBashBigCashImageOne}`}
                                alt="bigBashBigCashImage"
                                effect="none"
                                placeholder={<PlaceholderImage />}
                            />
                            <TextWrapper>
                                <h5>{(brandId == 31 || brandId == 32) ? 'Put on the Jersey' : 'Warm-up'}</h5>
                                {(brandId == 31 || brandId == 32) ?
                                    <p>If you're not already part of the {domain} family, sign up for an account. Existing members can log in with ease!</p> :
                                    <p>If you're not already part of the {domain} family, sign up now. Existing members can log in with ease!</p>
                                }
                            </TextWrapper>
                        </Content>
                        <Content>
                            <LazyLoadImage
                                src={`${config.imageV3Host}/static/cashsite/${theme.image.BigBashBigCashImageTwo}`}
                                alt="bigBashBigCashImage"
                                effect="none"
                                placeholder={<PlaceholderImage />}
                                className='ImageSize'
                            />
                            <TextWrapper>
                                <h5>{(brandId == 31 || brandId == 32) ? 'Pad Up' : 'Bowl at Top Speed'}</h5>
                                {(brandId == 31 || brandId == 32) ?
                                    <p>Deposit in your {domain} account. Keep it loaded and ready for action throughout the month.</p> :
                                    <p>Deposit in your account. Top it up and ready for action throughout the Big Bash.</p>
                                }
                            </TextWrapper>
                        </Content>
                    </Container>
                    <Container>
                        <Content>
                            <LazyLoadImage
                                src={`${config.imageV3Host}/static/cashsite/${theme.image.BigBashBigCashImageThree}`}
                                alt="bigBashBigCashImage"
                                effect="none"
                                placeholder={<PlaceholderImage />}
                            />
                            <TextWrapper>
                                <h5>{(brandId == 31 || brandId == 32) ? 'play' : 'York the batsman'}</h5>
                                {(brandId == 31 || brandId == 32) ?
                                    <p>Dive into the excitement of the Cricket matches by placing your bets on our cutting-edge Exchange platform.</p> :
                                    <p>Dive into the excitement of the  tournament by placing your bets on our cutting-edge exchange platform. </p>
                                }
                            </TextWrapper>
                        </Content>
                        <Content>
                            <LazyLoadImage
                                src={`${config.imageV3Host}/static/cashsite/${theme.image.BigBashBigCashImageFour}`}
                                alt="bigBashBigCashImage"
                                effect="none"
                                placeholder={<PlaceholderImage />}
                            />
                            <TextWrapper>
                                <h5>Claim your prize</h5>
                                <p>For cumulative losses of the day, we'll reward you with an 100% additional loss back bonus the very next day.</p>
                            </TextWrapper>
                        </Content>
                    </Container>
                </ContentWrapper>
                {(brandId == 31 || brandId == 32) ?
                    <BigBashTermsAndCondition>
                        <h4>Terms & Conditions</h4>
                        <ul>
                            <li>The loss back bonus will be applied to ‘lost Big Bash EXCHANGE bets - for the day’, placed during the tournament</li>
                            <li>The bonus amount for each qualifying bet will be capped at INR 50.</li>
                            <li>The bonus will be credited to your {domain} account the next day.</li>
                            <li>Bonus will be expired within 24-48 hours.</li>
                            <li>There is no limit to the number of bets you can place to earn the 100% Daily bonus, ‘for the day’.</li>
                            <li>The daily bonus can be used to place future bets on {domain} as this bonus cannot be withdrawn. Only winnings can be withdrawn.</li>
                            <li>This promotion is valid only for the  Big Bash ‘24 tournament.</li>
                            <li>{domain} reserves the right to disqualify any user found engaging in fraudulent activities.</li>
                            <li>{domain} General Terms & Conditions apply</li>
                        </ul>
                    </BigBashTermsAndCondition> :
                    <BigBashTermsAndCondition>
                        <h4>Terms & Conditions</h4>
                        <ul>
                            <li>The loss back bonus will be applied to ‘lost Big Bash EXCHANGE bets - for the day’, placed during Big Bash.</li>
                            <li>The bonus amount for each qualifying bet will be capped at INR 50.</li>
                            <li>The bonus will be credited to your <strong>{domain}</strong> account the next day.</li>
                            <li>Bonus will be expired within 24-48 hours.</li>
                            <li>There is no limit to the number of bets you can place to earn the 100% loss back bonus, ‘for the day’.</li>
                            <li>The loss back bonus can be used to place future bets on <strong>{domain}</strong> as this bonus cannot be withdrawn. Only winnings can be withdrawn.</li>
                            <li>This promotion is valid only for Big Bash 2024.</li>
                            <li><strong>{domain}</strong> reserves the right to disqualify any user found engaging in fraudulent activities.</li>
                            <li><strong>{domain}</strong> General Terms & Conditions apply.</li>
                        </ul>
                    </BigBashTermsAndCondition>
                }
            </InformationWrapper>
            <FooterContainer />
        </>
    )
}



export default withTheme(withAppContext(BigBashBigCashPromo));

const InformationWrapper = styled.div`
    width: 98%;
    height: auto;
    padding: 1.5rem 3rem 8rem 3rem;
    border-radius: 1px;
    background: rgba(111, 111, 111, 0.5);
    margin: 0em 1.2em 0 1.2em;
    border: 1px solid #E5B358;
    border-radius: 0 0 20px 20px;
    border-top-color: transparent;
    backdrop-filter: brightness(0.5);    
    display: flex;
    flex-direction: column;
    background: url(${config.imageV3Host}/static/cashsite/${theme("image.BigBashBigCashBackground")});
    background-position: top;
    background-size: cover;
    background-repeat: no-repeat;

    h1 {
        color: #E6FF00;
        text-align: center;
        font-size: 20px;
        font-style: normal;
        font-weight: 700;
        line-height: normal;
        text-transform: capitalize;
    }
`;

const TitleText = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    padding: 1rem 0 3rem 0;

    p {
        color: #FFF;
        text-align: center;
        font-size: 15px;
        font-style: normal;
        font-weight: 400;
        line-height: 28px; /* 186.667% */
        text-transform: capitalize;
        margin: 0;
    }
`;

const ImageWrapper = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    padding-bottom: 3rem;

    img {
        width: 55%;
    }
`;

const ContentWrapper = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    gap: 5rem;
    padding-top: 5rem;
`;

const Container = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 10rem;
`;

const Content = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    width: 30%;

    img {
        width: 90%;
    }

    .ImageSize {
        width: 80%;
    }
`;

const TextWrapper = styled.div`
    display: flex;
    justify-content: center;
    align-items: flex-start;
    flex-direction: column;
    gap: 1rem;

    h5 {
        font-size: 15px;
        font-style: normal;
        font-weight: 700;
        line-height: normal;
        text-transform: capitalize;
        color: #E6FF00;
        margin: 0;
        padding: 0;
    }

    p {
        color: #FFF;
        font-size: 15px;
        font-style: normal;
        font-weight: 400;
        line-height: 18px; /* 120% */
        margin: 0;
        padding: 0;
    }
`;

const BigBashTermsAndCondition = styled.div`
    height: auto;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    padding: 5rem;


    & li {
        color: #FFF;
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: 22.8px; /* 142.5% */
        text-transform: capitalize;
        margin: 1rem 0;
    }

    & h4 {
        color: #E6FF00;
        font-size: 20px;
        font-style: normal;
        font-weight: 700;
        line-height: 35px; /* 175% */
        text-transform: capitalize;
        margin: 10px 0px 0px 15px;
    }
`;