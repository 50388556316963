import React from 'react';
import styled from 'styled-components';

// Utils
import config from '../../../config/config';

interface ICricketScoreIFrameProps {
	eventId: string;
}

const CricketScoreIFrame: React.SFC<ICricketScoreIFrameProps> = props => {
	const { eventId } = props;
	return <CricketScoreIFrameWrapper src={`${config.cricketScoreHost}${eventId}`} id="cricket-score-iframe" />;
};

export default CricketScoreIFrame;

// Styled Components
const CricketScoreIFrameWrapper = styled.iframe`
	display: block;
	background-color: white;
	width: 100%;
	border: 0;
	height: 200px;
`;
