import React from 'react'

type Props = {}

export const AddBankBlue = (props: Props) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="44" height="44" viewBox="0 0 44 44" fill="none">
      <rect x="5.41797" y="19.5898" width="5.34854" height="12.0671" rx="1.06157" fill="white" />
      <path d="M0.0557233 16.47V12.853C0.0557233 12.3401 0.0557233 12.0757 0.461309 11.8578C6.23391 8.75707 17.8803 2.48412 18.2853 2.19833C18.6902 1.91254 19.2488 2.07925 19.4775 2.19833C24.7816 5.05499 35.6137 10.8934 36.5092 11.3938C37.4046 11.8942 37.6495 12.4305 37.6599 12.6361V16.4264C37.6599 17.8746 36.9434 18.2288 36.5851 18.2249H35.6912H1.70609C0.273674 18.2249 0.0090051 17.055 0.0557233 16.47Z" fill="white" />
      <path fill-rule="evenodd" clip-rule="evenodd" d="M18.9272 33.0191L1.12798 33.019C0.541688 33.019 0.0664062 33.4943 0.0664062 34.0806V38.5994C0.0664062 39.1857 0.541685 39.6609 1.12797 39.6609L21.6553 39.661C20.1696 37.7996 19.1906 35.516 18.9272 33.0191Z" fill="white" />
      <path fill-rule="evenodd" clip-rule="evenodd" d="M21.5517 23.7833V20.6495C21.5517 20.0632 21.0764 19.5879 20.4901 19.5879H17.2647C16.6784 19.5879 16.2031 20.0632 16.2031 20.6495V30.5934C16.2031 31.1797 16.6784 31.655 17.2647 31.655H18.857C18.857 28.6887 19.8627 25.9574 21.5517 23.7833Z" fill="white" />
      <rect x="29.9336" y="25.9995" width="2.70443" height="10.571" rx="1.35222" fill="white" />
      <rect x="26" y="32.6372" width="2.70443" height="10.571" rx="1.35222" transform="rotate(-90 26 32.6372)" fill="white" />
      <path fill-rule="evenodd" clip-rule="evenodd" d="M42.6259 31.6562C42.6259 37.6281 37.7848 42.4692 31.813 42.4692C25.8411 42.4692 21 37.6281 21 31.6562C21 25.6844 25.8411 20.8433 31.813 20.8433C37.7848 20.8433 42.6259 25.6844 42.6259 31.6562ZM29.9339 27.3517C29.9339 26.6049 30.5393 25.9995 31.2861 25.9995C32.0329 25.9995 32.6383 26.6049 32.6383 27.3517V29.9332H35.219C35.9658 29.9332 36.5712 30.5387 36.5712 31.2855C36.5712 32.0323 35.9658 32.6377 35.219 32.6377H32.6383V35.2183C32.6383 35.9651 32.0329 36.5705 31.2861 36.5705C30.5393 36.5705 29.9339 35.9651 29.9339 35.2183V32.6377H27.3524C26.6056 32.6377 26.0002 32.0323 26.0002 31.2855C26.0002 30.5387 26.6056 29.9332 27.3524 29.9332H29.9339V27.3517Z" fill="#06A6FF" />
    </svg>
  )
}

export const ViewBankBlue = (props: Props) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="44" height="44" fill="none" viewBox="0 0 44 41"><rect width="5.349" height="12.067" x="5.977" y="17.59" fill="#fff" rx="1.062" /><path fill="#fff" d="M.614 14.47v-3.617c0-.513 0-.777.406-.995C6.793 6.758 18.439.484 18.844.198c.405-.285.963-.119 1.192 0 5.304 2.857 16.136 8.695 17.032 9.196.895.5 1.14 1.037 1.15 1.242v3.79c0 1.449-.716 1.803-1.074 1.799H2.265c-1.433 0-1.697-1.17-1.65-1.755Z" /><path fill="#fff" fill-rule="evenodd" d="m19.486 31.02-17.8-.001c-.586 0-1.061.475-1.061 1.062v4.518c0 .587.475 1.062 1.062 1.062h20.527a12.78 12.78 0 0 1-2.728-6.642ZM22.11 21.784V18.65c0-.587-.475-1.062-1.061-1.062h-3.226c-.586 0-1.061.475-1.061 1.061v9.944c0 .587.475 1.062 1.061 1.062h1.593c0-2.966 1.005-5.697 2.694-7.871Z" clip-rule="evenodd" /><path fill="#06A6FF" d="M43.593 29.657c0 5.972-4.841 10.813-10.813 10.813s-10.813-4.841-10.813-10.813 4.84-10.813 10.813-10.813c5.972 0 10.813 4.84 10.813 10.813Z" /><g fill="#fff" clip-path="url(#a)"><path d="M32.78 31.482a1.827 1.827 0 1 0 0-3.653 1.827 1.827 0 0 0 0 3.653Z" /><path d="M39.603 29.501a7.623 7.623 0 0 0-6.823-4.868 7.622 7.622 0 0 0-6.823 4.868.456.456 0 0 0 0 .31 7.622 7.622 0 0 0 6.823 4.869 7.622 7.622 0 0 0 6.823-4.868.457.457 0 0 0 0-.31Zm-6.823 3.124a2.968 2.968 0 1 1 0-5.937 2.968 2.968 0 0 1 0 5.937Z" /></g><defs><clipPath id="a"><path fill="#fff" d="M25.473 22.348h14.614v14.614H25.473z" /></clipPath></defs></svg>
  )
}

export const FaqBlue = (props: Props) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="34" height="44" viewBox="0 0 34 44" fill="none">
      <path d="M24.3908 26.7412H32.0989C32.061 26.6972 31.8154 26.4504 31.1353 25.8156C30.4552 25.1809 30.6378 24.4176 30.8141 24.1152L24.3908 26.7412Z" fill="#06A6FF" />
      <path d="M33.5585 17.8915C33.5585 22.7882 29.589 26.7577 24.6923 26.7577C19.7957 26.7577 15.8262 22.7882 15.8262 17.8915C15.8262 12.9949 19.7957 9.02539 24.6923 9.02539C29.589 9.02539 33.5585 12.9949 33.5585 17.8915Z" fill="#06A6FF" />
      <path d="M1.75533 34.9901H9.75223C10.2627 34.1563 11.2799 32.4524 11.2654 32.3072C11.2509 32.162 6.22598 30.9763 3.71534 30.4016L2.4086 31.9808C3.25072 32.9245 3.11037 33.5719 2.93492 33.7776L1.75533 34.9901Z" fill="#06A6FF" />
      <path d="M13.9005 18.6875C12.5174 17.796 10.8701 17.2783 9.10232 17.2783C4.20593 17.2783 0.236328 21.2479 0.236328 26.1443C0.236328 31.0411 4.20593 35.0107 9.10232 35.0107C13.9542 35.0107 17.8962 31.1132 17.9679 26.2781C15.6736 24.4446 14.141 21.742 13.9005 18.6875Z" fill="#06A6FF" />
      <rect x="3.9707" y="22.9189" width="9.2529" height="2.36788" rx="1.18394" fill="white" />
      <rect x="3.9707" y="26.9976" width="9.2529" height="2.36788" rx="1.18394" fill="white" />
      <path fill-rule="evenodd" clip-rule="evenodd" d="M13.872 2.83663L12.8194 0.749453L12.8192 0.749023H21.0136L19.9337 2.83663H13.872ZM12.0388 3.63521C11.6758 2.90913 11.0285 1.62266 10.5885 0.749023H5.60591C4.9261 0.749023 4.375 1.30012 4.375 1.97993V16.1346C5.70044 15.5977 7.15451 15.3013 8.67972 15.3013C10.5804 15.3013 12.3707 15.7616 13.9391 16.5739C14.5858 11.1468 19.3249 6.93382 25.0752 6.93382C26.6043 6.93382 28.062 7.23172 29.3901 7.77115V1.97993C29.3901 1.30012 28.839 0.749023 28.1592 0.749023H23.1956L21.8212 3.63521C21.6095 4.05869 20.9355 4.90565 19.9337 4.90565C18.9319 4.90565 15.4751 4.84516 13.872 4.81491C13.4485 4.79676 12.4889 4.53541 12.0388 3.63521ZM4.375 37.1219V42.2319C4.375 42.9117 4.9261 43.4628 5.60591 43.4628H28.1592C28.839 43.4628 29.3901 42.9117 29.3901 42.2319V28.7544H25.1317L25.0752 28.7545L25.0188 28.7544H23.6487V28.6671C22.2785 28.4977 20.9855 28.0877 19.8158 27.482C19.2235 32.453 15.1977 36.4054 10.1062 37.0346V37.1219H8.73658L8.67972 37.1221L8.62287 37.1219H4.375ZM14.5295 40.3945C14.0672 40.3945 13.6924 40.7693 13.6924 41.2316C13.6924 41.6939 14.0672 42.0687 14.5295 42.0687H19.2375C19.6998 42.0687 20.0746 41.6939 20.0746 41.2316C20.0746 40.7693 19.6998 40.3945 19.2375 40.3945H14.5295Z" fill="white" />
      <path d="M23.3322 19.3526V19.1355C23.3322 18.6378 23.3714 18.2412 23.4498 17.9456C23.5282 17.6501 23.6444 17.4133 23.7982 17.2354C23.952 17.0544 24.1405 16.8915 24.3637 16.7468C24.5567 16.6201 24.7286 16.4979 24.8794 16.3803C25.0333 16.2627 25.1539 16.1375 25.2414 16.0048C25.3318 15.8721 25.3771 15.7213 25.3771 15.5524C25.3771 15.4016 25.3409 15.2689 25.2685 15.1543C25.1961 15.0397 25.0981 14.9507 24.9744 14.8874C24.8508 14.824 24.7136 14.7924 24.5627 14.7924C24.3999 14.7924 24.2491 14.8301 24.1103 14.9055C23.9746 14.9809 23.8645 15.0849 23.7801 15.2176C23.6986 15.3503 23.6579 15.5041 23.6579 15.6791H21.3416C21.3476 15.0155 21.4984 14.4772 21.794 14.064C22.0896 13.6478 22.4817 13.3431 22.9703 13.1501C23.4589 12.9541 23.9957 12.856 24.5808 12.856C25.2263 12.856 25.8023 12.9511 26.309 13.1411C26.8157 13.3281 27.2154 13.6116 27.5079 13.9916C27.8005 14.3686 27.9468 14.8406 27.9468 15.4076C27.9468 15.7726 27.8834 16.0938 27.7568 16.3713C27.6331 16.6457 27.4597 16.8885 27.2365 17.0996C27.0163 17.3077 26.7584 17.4978 26.4629 17.6697C26.2457 17.7963 26.0632 17.9275 25.9154 18.0633C25.7677 18.196 25.6561 18.3483 25.5807 18.5202C25.5053 18.6891 25.4676 18.8942 25.4676 19.1355V19.3526H23.3322ZM24.4361 22.3928C24.0862 22.3928 23.7861 22.2707 23.5358 22.0264C23.2885 21.779 23.1663 21.479 23.1693 21.1261C23.1663 20.7822 23.2885 20.4882 23.5358 20.2439C23.7861 19.9996 24.0862 19.8774 24.4361 19.8774C24.7678 19.8774 25.0604 19.9996 25.3137 20.2439C25.5701 20.4882 25.6998 20.7822 25.7028 21.1261C25.6998 21.3613 25.638 21.5755 25.5173 21.7685C25.3997 21.9585 25.2459 22.1108 25.0559 22.2254C24.8659 22.337 24.6593 22.3928 24.4361 22.3928Z" fill="white" />
    </svg>
  )
}

export const PromoBlue = (props: Props) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="44" height="44" viewBox="0 0 44 44" fill="none">
      <path d="M19.0883 7.36055C16.8003 13.871 12.7428 17.767 11 18.9012C11.4467 19.1894 11.8646 19.8575 12.0176 20.1555C12.4085 21.2254 13.29 23.6459 13.6898 24.7683C14.0896 25.8908 14.2 26.9137 14.2052 27.2849C19.0582 27.0087 25.1705 29.6028 27.62 30.9345L19.0883 7.36055Z" fill="white" />
      <path d="M30.11 19.631L28.2437 14.5394C29.6582 14.5394 30.4811 15.6854 30.7158 16.2584C31.2921 17.8694 30.5521 19.178 30.11 19.631Z" fill="white" />
      <path d="M19.6776 5.24634L29.2989 31.4886C30.642 30.1914 31.8224 29.5067 32.2447 29.3265L22.8667 3.73283C22.5874 3.37257 21.7694 2.74395 20.7316 3.1115C19.6938 3.47905 19.5965 4.68788 19.6776 5.24634Z" fill="white" />
      <rect x="29.916" y="12.0117" width="11.2999" height="1.46857" rx="0.734283" transform="rotate(-76.2301 29.916 12.0117)" fill="white" />
      <rect x="31.875" y="13.4146" width="7.68492" height="1.46857" rx="0.734283" transform="rotate(-30.1316 31.875 13.4146)" fill="white" />
      <rect x="32.8594" y="15.1953" width="7.68492" height="1.46857" rx="0.734283" transform="rotate(-3.81893 32.8594 15.1953)" fill="white" />
      <rect x="33.0898" y="18.0039" width="11.284" height="1.46857" rx="0.734283" transform="rotate(42.2255 33.0898 18.0039)" fill="white" />
      <path fill-rule="evenodd" clip-rule="evenodd" d="M3.18532 20.8339C2.0826 21.2259 1.5064 22.4375 1.89834 23.5403L3.78732 28.8549C4.17927 29.9576 5.39094 30.5338 6.49366 30.1419L11.3844 28.4035C12.4871 28.0116 13.0633 26.7999 12.6714 25.6972L10.7824 20.3826C10.3904 19.2799 9.17876 18.7037 8.07604 19.0956L3.18532 20.8339ZM3.73489 22.8555C3.37105 22.9867 3.18249 23.3881 3.31373 23.7519C3.44497 24.1158 3.84631 24.3043 4.21015 24.1731L9.25634 22.3529C9.62018 22.2216 9.80874 21.8203 9.6775 21.4565C9.54626 21.0926 9.14492 20.9041 8.78108 21.0353L3.73489 22.8555ZM4.11463 25.7542C3.98339 25.3903 4.17195 24.989 4.53579 24.8577L9.58198 23.0375C9.94582 22.9063 10.3472 23.0949 10.4784 23.4587C10.6096 23.8225 10.4211 24.2239 10.0572 24.3551L5.01104 26.1753C4.64721 26.3066 4.24587 26.118 4.11463 25.7542ZM5.2924 26.8455C4.92856 26.9768 4.74 27.3781 4.87124 27.742C5.00248 28.1058 5.40382 28.2943 5.76766 28.1631L10.8138 26.3429C11.1777 26.2117 11.3662 25.8103 11.235 25.4465C11.1038 25.0827 10.7024 24.8941 10.3386 25.0253L5.2924 26.8455Z" fill="white" />
      <path d="M27.6296 34.1244C27.7051 33.3572 28.5002 32.1264 28.8883 31.6069H20.7078C20.2548 31.6069 20.1417 32.0686 20.1418 32.2995C20.1471 32.9443 20.1544 34.3536 20.1418 34.8317C20.1292 35.3098 20.6193 35.4398 20.8659 35.4451H21.8257C22.1025 34.5769 23.1261 34.2029 23.6033 34.1244H27.6296Z" fill="white" />
      <path d="M27.4079 38.0088C27.2305 37.581 27.2606 36.1214 27.2978 35.4451H23.759C23.2809 35.4451 23.046 35.9484 22.9883 36.2V38.0088H27.4079Z" fill="white" />
      <path d="M30.9153 43.2619C29.4809 42.4189 28.273 40.3313 27.8483 39.3928H20.8659C20.2501 39.3928 20.1266 39.8437 20.1418 40.0691V42.5856C20.1418 43.2462 20.8659 43.3406 20.8659 43.2619C20.8659 43.199 27.5655 43.2357 30.9153 43.2619Z" fill="white" />
      <path d="M8.23175 32.8396C10.0891 33.5178 11.1496 31.083 11.4477 29.7808C12.6632 29.476 13.4272 28.4337 13.6572 27.9507L15.0639 29.491C16.7072 31.7774 16.2994 34.1204 15.89 35.0061C15.5477 35.7727 14.7111 37.4131 14.1038 37.8418C13.4964 38.2705 12.9873 39.3452 12.8087 39.829L12.3398 42.1958H5.84255L7.42759 38.199V36.7923C7.38294 36.5914 7.1418 35.9528 6.53447 35.0061C5.92714 34.0593 5.82014 32.3788 5.84255 31.6568L6.91479 31.4335C7.50426 32.5232 8.03838 32.8249 8.23175 32.8396Z" fill="white" />
      <path d="M9.85824 30.3853L8.2207 30.9965L8.46819 31.4514C9.01914 31.8776 9.30415 31.5423 9.37778 31.3214L9.85824 30.3853Z" fill="white" />
      <path d="M15.395 38.3252L16.604 36.5439C17.19 37.1801 17.4084 38.2338 16.604 39.4552C15.9605 40.4324 14.7068 40.5554 14.1604 40.4948C14.0461 39.8087 14.9358 38.7625 15.395 38.3252Z" fill="white" />
      <rect x="5.53125" y="4.43994" width="7.17236" height="7.04421" fill="white" />
      <path d="M15.8721 7.7007L14.6898 6.41008L15.1964 4.73449L13.5697 4.08697L13.2991 2.35727L11.5527 2.4769L10.5583 1.03613L9.03759 1.90443L7.51687 1.03613L6.52248 2.4769L4.77606 2.35724L4.50543 4.08692L2.87878 4.73443L3.38541 6.41002L2.20312 7.7007L3.38541 8.99132L2.87878 10.6669L4.50543 11.3144L4.77606 13.0441L6.52248 12.9245L7.51687 14.3652L9.03759 13.497L10.5583 14.3652L11.5527 12.9244L13.2991 13.0441L13.5697 11.3144L15.1964 10.6669L14.6897 8.99132L15.8721 7.7007ZM7.59829 5.55363C8.09064 5.55363 8.48974 5.95276 8.48974 6.44508C8.48974 6.93743 8.09064 7.33652 7.59829 7.33652C7.10597 7.33652 6.70685 6.9374 6.70685 6.44508C6.70682 5.95276 7.10592 5.55363 7.59829 5.55363ZM7.60152 10.1894L6.90785 9.62951C8.30038 7.90436 9.08112 6.93714 10.4737 5.21199L11.1673 5.77191L9.38442 7.98067L7.60152 10.1894ZM10.4769 10.1145C9.98454 10.1145 9.58544 9.71542 9.58544 9.2231C9.58544 8.73075 9.98454 8.33166 10.4769 8.33166C10.9692 8.33166 11.3683 8.73075 11.3683 9.2231C11.3684 9.71539 10.9692 10.1145 10.4769 10.1145Z" fill="#06A6FF" />
      <circle cx="35.8348" cy="36.4286" r="6.83483" fill="#06A6FF" />
      <path d="M38.3978 34.9392L38.1191 36.0004H33L33.2749 34.9392H38.3978ZM35.7371 40.818L33.126 37.7106L33.1298 36.7563H34.4201C34.7076 36.7563 34.9481 36.7028 35.1415 36.596C35.335 36.4891 35.48 36.344 35.5767 36.1608C35.6734 35.9775 35.7231 35.7689 35.7256 35.5347C35.7231 35.1835 35.62 34.8972 35.4164 34.6758C35.2153 34.4519 34.8832 34.3399 34.4201 34.3399H33L33.313 33H34.4201C35.069 33 35.6098 33.098 36.0424 33.2939C36.4776 33.4874 36.8034 33.7635 37.0197 34.1223C37.2385 34.4811 37.3493 34.9087 37.3518 35.4049C37.3493 35.8452 37.2754 36.2295 37.1304 36.5578C36.9879 36.8861 36.7627 37.1597 36.4547 37.3785C36.1493 37.5974 35.751 37.7603 35.2599 37.8671L35.1988 37.89L37.535 40.7493V40.818H35.7371ZM38.4054 33L38.1191 34.0765H33.9047L34.191 33H38.4054Z" fill="white" />
    </svg>
  )
}