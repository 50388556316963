import React, { useState } from 'react';
// import { Query } from 'react-apollo';
import styled from 'styled-components';
// import { withProp } from 'styled-tools';
import { useQuery } from '@apollo/client';
// import DatePicker from "react-datepicker";
import Intl from '../../../components/common/Intl';
import { theme } from 'styled-tools';

// Styled Components
import { Amount, Income } from '../../../components/common/NumberDisplay';
import Openable from '../../../components/common/Openable';
// import { Col,TableBodyWrapper, TableWrapper } from '../../../components/common/table/baseTableStyles';
import EmptyRow from '../../../components/common/table/feature/EmptyRow';
import { EOddsType } from '../../../components/eu-content/NumberInput';
import DatePickerIcon from 'src/images/calendar.png';
import {
    BodyWrapper as HistoryBodyWrapper,
    FirstCol,
    Header,
    MainInfoWrapper,
    SecondCol,
    Select,
    TableBodyWrapper,
    TableHeadRow,
    TableWrapper,
    TypeCol,
    SvgIcon,
    TableBodyRow as MoreAlignment,
    TableFooter,
    OpenWrapper,
    DetailMainWrapper,
    OpenWrapperContent,
    EventNameWrap
} from 'src/components/udb/commonStyle';

import {
    DateText,
    DetailWrapper,
    EventNameWithChevron,
    MemberTableBodyRow,
    MobileHead,
    RemarkItemTime,
    RemarkItemWrapper,
    RemarkSectionTitle,
    RemarkSectionWrapper,
    SelectionInfoWrapper,
    StatusText,
    TimeText,
    TypeLabel,
} from '../../../components/member/membertablestyles';
import { TimeZone } from '../../../store/TimeZoneContext';

// Utils
// import { diffColor } from '../../util/colorUtil';
// import media from '../../util/mediaUtil';
import oddsUtil from '../../../util/oddsUtil';
import util from '../../../util/util';

// graph
import { GET_MY_ORDERS } from '../../../graphql/member';
import { IOrder, IOrderGroup } from '../../../graphql/schema';
import { EMarketType } from '../../../util/utilModel';
import Spinner from 'src/components/common/Spinner';
import { Col } from 'src/components/common/table/baseTableStyles';

export enum EHistoryTableType {
    current = 'CURRENT',
    past = 'PAST',
}

export enum EHistoryFilterType {
    matched = 'MATCHED',
    unmatched = 'UNMATCHED',
    both = 'BOTH',
}

const Description: React.SFC<{
    eventName: string;
    marketName: string;
    selectionName: string;
    orderNumber: string;
    marketType: number;
    matchedTime: string | number | Date;
    isSettled: boolean;
    remark: string;
    type: string;
    mode: string;
}> = props => {
    const {
        eventName,
        marketName,
        selectionName,
        matchedTime,
        orderNumber,
        isSettled,
        remark,
        marketType = EMarketType.MAIN,
        type,
        mode,
    } = props;
    return (
        <Openable>
            {({ isOpened, toggleOpen }) => {
                const hasRemark = !!remark;
                const remarks = (hasRemark && remark.split('||')) || [];
                return (
                    <>
                        <EventNameWithChevron
                            onClick={toggleOpen}
                            isOpened={isOpened}
                            withRemark={hasRemark}
                            historyFilterType={type}
                            historyTableType={mode}
                        >
                        <EventNameWrap>{eventName}</EventNameWrap> 
                        </EventNameWithChevron>
                        {isOpened && (
                            <OpenWrapper>
                                <OpenWrapperContent>
                                    <SelectionInfoWrapper>
                                        {marketType === EMarketType.MAIN || EMarketType.Multi_Selection_Fancy ? (
                                            <>
                                                <span>{marketName}</span>
                                                <span>{selectionName}</span>
                                            </>
                                        ) : (
                                            <span>
                                                {selectionName} - runs ({marketName})
                                            </span>
                                        )}
                                    </SelectionInfoWrapper>
                                    <DetailMainWrapper>
                                    <DetailWrapper>
                                        <span>
                                            Bet Number
                                        </span>
                                        <span className='orderBoldOne'> {orderNumber}</span>
                                    </DetailWrapper>
                                    <DetailWrapper>
                                        <span>
                                            {isSettled ? <span>Placed</span> : <span>Matched</span>}
                                        </span>
                                        <span>
                                            <TimeZone format="dd/MM/yyyy HH:mm:ss">{matchedTime}</TimeZone>
                                        </span>
                                    </DetailWrapper>
                                    </DetailMainWrapper>
                                    {Array.isArray(remarks) &&
                                        remarks.map((r, i) => {
                                            const remarkInfo = (r && r.split('|')) || [];

                                            if (remarkInfo.length <= 0) {
                                                return <span />;
                                            }
                                            const date = remarkInfo[0] || (new Date().toISOString());
                                            return (
                                                <RemarkSectionWrapper>
                                                    <RemarkSectionTitle>Remarks</RemarkSectionTitle>
                                                    <div>
                                                        <RemarkItemWrapper>
                                                            {(remarkInfo[1] && remarkInfo[1].split(':')[1]) || ''}
                                                            <RemarkItemTime>
                                                                <TimeZone format="dd/MM/yyyy">{date}</TimeZone>
                                                                {/* {` ${remarkInfo[1]}`} */}
                                                            </RemarkItemTime>
                                                        </RemarkItemWrapper>
                                                    </div>
                                                </RemarkSectionWrapper>
                                            );
                                        })}
                                    </OpenWrapperContent>
                            </OpenWrapper>
                        )}
                    </>
                );
            }}
        </Openable>
    );
};

interface IHistoryTableProps {
    mode: EHistoryTableType.current | EHistoryTableType.past | 'empty';
    query: {
        from: Date;
        to: Date;
        type: string;
    };
}

function showStatus(status: string) {
    switch (status) {
        case 'SETTLED':
            return 'SETTLING';

        default:
            return status;
    }
}

 export default function MyBetsTable(props: IHistoryTableProps) {
    const {
        mode,
        query: {type, from, to },
    } = props;

    let timeColLangKey: any;
    switch (mode) {
        case EHistoryTableType.current:
				timeColLangKey = <Intl langKey= 'HISTORY_TABLE@PLACED' />;
				break;
			case EHistoryTableType.past:
				timeColLangKey = <Intl langKey= 'HISTORY_TABLE@SETTLED' />;
				break;
			default:
				console.warn('[HistoryTable]: mode prop is invalid!');
				timeColLangKey = 'TIME';
    }
    const isSettled = mode === EHistoryTableType.past;


    const { data, loading, error } = useQuery(GET_MY_ORDERS, {
        variables: {
            input: { isSettled, period: { start: from.valueOf(), end: to.valueOf() } },
        }
    });



    const orderGroup: IOrderGroup = util.getValue(data, ['exchange', 'myOrders'], {
        matched: [],
        unmatched: [],
    });
     let orders = isSettled
         ? orderGroup.matched
         : type === EHistoryFilterType.both
             ? [...orderGroup.matched, ...orderGroup.unmatched].sort((a, b) => b.createdAt - a.createdAt)
             : type === EHistoryFilterType.matched
                 ? orderGroup.matched
                 : orderGroup.unmatched;
     orders = orders.slice().sort((a, b) => b.settledAt - a.settledAt)            

     const handleDateChangeRaw = (e) => {
         e.preventDefault();
     } 

    var date = new Date();
    const minDate = date.setDate((new Date()).getDate() - 90);  
    return (
        <>
            {loading && <Spinner mode="agent" /> }
            {/* Table Section */}
            <TableWrapper>
                <TableHeadRow>
                    <DescriptionCol>
                    <span><Intl langKey="ACCOUNT_STATEMENT_TABLE@DESCRIPTION"/></span>
                    </DescriptionCol>
                    <TypeCol>
                        <span>{timeColLangKey}</span>
                    </TypeCol>
                    <TypeCol>
                    <span><Intl langKey="ACCOUNT_STATEMENT_TABLE@TYPE"/></span>
                    </TypeCol>
                    <TypeCol>
                    <span><Intl langKey="DETAIL_TABLE@ODDS"/></span>
                    </TypeCol>
                    <TypeCol>
                    <span><Intl langKey="DETAIL_TABLE@STAKE"/></span>
                    </TypeCol>
                    {mode === EHistoryTableType.current && (
                        <>
                           <TypeCol>
                                <span><Intl langKey="HISTORY_TABLE@LIABILITY"/></span>
                            </TypeCol>
                            <TypeCol>
                                <span><Intl langKey="HISTORY_TABLE@POTENTIAL_PROFIT"/></span>
                            </TypeCol>
                        </>
                    )}
                    {mode === EHistoryTableType.past && (
                        <>
                              <TypeCol>
                                <span><Intl langKey="MEMBER@P2P_PROFIT_LOSS"/></span>
                            </TypeCol>
                            <TypeCol>
                                <span><Intl langKey="HISTORY_TABLE@STATUS"/></span>
                            </TypeCol>
                        </>
                    )}
                </TableHeadRow>

                <TableBodyWrapper>
                {(error ? (
						<div>error</div>
					) : 
                    (orders.map((item: IOrder, i: number) => (
                        <TableBodyRow key={i} className="tableBody">
                            <DescriptionCol>
                                <Description
                                    mode={mode}
                                    type={type}
                                    eventName={item.eventName}
                                    marketName={item.marketName}
                                    selectionName={item.selectionName}
                                    marketType={item.marketType || EMarketType.MAIN}
                                    orderNumber={item.orderNumber}
                                    remark={item.clientRemark || ''}
                                    matchedTime={isSettled ? item.createdAt : item.updatedAt}
                                    isSettled={isSettled}
                                />
                            </DescriptionCol>
                            <TypeCol>
                                <MobileHead>
                                    <span>{timeColLangKey}</span>
                                </MobileHead>
                                <TimeColContentWrapper>
                                    <DateText>
                                        <TimeZone format={'dd/MM/yyyy'}>
                                            {isSettled ? item.settledAt : item.createdAt}
                                        </TimeZone>
                                    </DateText>
                                    {/* &nbsp; */}
                                    <TimeText>
                                        <TimeZone format={'HH:mm:ss'}>
                                            {isSettled ? item.settledAt : item.createdAt}
                                        </TimeZone>
                                    </TimeText>
                                </TimeColContentWrapper>
                            </TypeCol>
                            <TypeCol>
                                <MobileHead>
                                    <span>TYPE</span>
                                </MobileHead>
                                <TypeLabel>
                                    {[EMarketType.MAIN, EMarketType.Multi_Selection_Fancy].includes(
                                        item.marketType || -1,
                                    )
                                        ? item.side
                                        : item.side === 'BACK'
                                            ? 'YES'
                                            : 'NO'}
                                </TypeLabel>
                            </TypeCol>
                            <TypeCol>
                                <MobileHead>
                                    <span>ODDS</span>
                                </MobileHead>
                                {item.oddType === EOddsType.AM
                                    ? oddsUtil.EUtoAM(item.price).toFixed(2)
                                    : item.price.toFixed(2)}
                            </TypeCol>
                            <TypeCol>
                                <MobileHead>
                                    <span>STAKE</span>
                                </MobileHead>
                                <Amount>{util.numMultiply(item.stake, item.exchangeRate)}</Amount>
                            </TypeCol>
                            {!isSettled ? (
                                // Current
                                <>
                                    <TypeCol>
                                        <MobileHead>
                                            <span>LIABILITY</span>
                                        </MobileHead>
                                        {item.liability ? (
                                            <Income>
                                                {util.numMultiply(item.liability, item.exchangeRate)}
                                            </Income>
                                        ) : (
                                            '-'
                                        )}
                                    </TypeCol>
                                    <TypeCol>
                                        <MobileHead>
                                            <span>POTENTIAL PROFIT</span>
                                        </MobileHead>
                                        {item.winlossCredit ? (
                                            <Income>
                                                {util.numMultiply(item.winlossCredit, item.exchangeRate)}
                                            </Income>
                                        ) : item.status === 'VOIDED' ? (
                                            '0'
                                        ) : (
                                            '-'
                                        )}
                                    </TypeCol>
                                </>
                            ) : (

                                <>
                                    <TypeCol>
                                        <MobileHead>
                                            <span>PROFIT LOSS</span>
                                        </MobileHead>
                                        {item.status === 'CHECKOUT' && item.winlossCredit ? (
                                            <Income>
                                                {util.numMultiply(item.winlossCredit, item.exchangeRate)}
                                            </Income>
                                        ) : (
                                            '-'
                                        )}
                                    </TypeCol>
                                    <TypeCol>
                                        <MobileHead>
                                            <span>STATUS</span>
                                        </MobileHead>
                                        <StatusText>
                                            {item.status !== 'CHECKOUT'
                                                ? showStatus(item.status || '')
                                                : (item.winlossCredit || 0) > 0
                                                    ? 'WON'
                                                    : 'LOST'}
                                        </StatusText>
                                    </TypeCol>
                                </>
                            )}
                        </TableBodyRow>
                        
                    ))
                    ))}
                    {orders.length === 0 && <EmptyWrapper><EmptyRow/></EmptyWrapper>}
                </TableBodyWrapper>

            </TableWrapper>
        </>
    )
}

const TableBodyRow = styled(MoreAlignment)`
    align-items: flex-start;
`;

export const DateSelector = styled.div`
  position:relative;
  top:3px;
  
  font-weight: bold;
  display:inline-flex;
`;

export const TimeCol = styled(Col)`
	flex-basis: 10%;
`;

export const DescriptionCol = styled(Col)`
	flex-basis: 32%;
    display: flex;
    flex-direction: column;
    /* position: relative; */

    span {
		text-align: left;
	}
`;

// export const TypeCol = styled(Col)`
// 	flex-basis: 10%;
// 	justify-content: center;
// 	align-items: flex-start;

// `;

export const OddsCol = styled(Col)`
	flex-basis: 10%;
	justify-content: center;
`;

export const StakeCol = styled(Col)`
	flex-basis: 10%;
	justify-content: center;

`;

export const LiabilityCol = styled(Col)`
	flex-basis: 12%;
	justify-content: center;
`;

export const ProfitCol = styled(Col)`
	flex-basis: 20%;
	justify-content: center;
	text-align: center;
`;

export const ProfitLossCol: any = styled(Col)`
	flex-basis: 16%;
	justify-content: center;
`;

export const StatusCol = styled(Col)`
	flex-basis: 16%;
	justify-content: center;
`;

export const TimeColContentWrapper = styled.div`
	display: flex;
	flex-direction: column;
`;

const StatementTableWrapper = styled.div`
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    font-size: 14px;
    /* font-weight: bold; */
    text-transform: capitalize;
    letter-spacing: 0.66px;
    // background: #FDF2E5;
    background:#f4f8fc;
    color: #fff;
    border-bottom: none;
    box-shadow: rgb(0 0 0 / 10%) 0px 3px 5px;
    flex-wrap: wrap;
`;

// const TableHeadRow = styled.div`
//     width: 100%;
//     display: flex;
//     align-items: center;
//     justify-content: space-between;
//     font-size: 14px;
//     font-weight: bold;
//     text-transform: capitalize;
//     letter-spacing: 0.66px;
//     // background: #D0B99F;
//     background: ${theme('palette.secondary-background-color-new')};
//     color: #fff;
//     border-bottom: none;
//     box-shadow: rgb(0 0 0 / 10%) 0px 3px 5px;
// `;

const DatePickerWrapper = styled.div`
  width: 50%;
  display: flex;
  flex-direction: column;
  align-items: centre;
  justify-content: flex-start;
  position: absolute;
  right: 0;
  top: 12px;
`;

const BlockPick = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  background: #E9F1F8;
  border-radius: 5px;
  padding: 0px 10px;
  margin-right: 10px;
  text-transform: capitalize;
  color: #203974;
  font-size: 12px;

  input {
    border: none;
    background: none;
    display: flex;
    height: 47px;
    text-align: center;
  }

  .react-datepicker__day--outside-month {
  color: transparent !important;
  pointer-events: none;
}
`;

const Tittle = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  color: #013766;
  
  font-weight: 500;
  font-size: 15px;
  line-height: 18px;
  text-transform: capitalize;
  padding-bottom: 10px;
`;

const CalenderIcon = styled.div`
  width: 24px;
  height: 24px;
  display: flex;
  flex-direction: row;
  align-items: center;
  background-image: url(${DatePickerIcon});
  background-size: auto;
  background-repeat: no-repeat;
  margin-right: 10px;
`;

const EmptyWrapper = styled.div`
    width: 100%;
    /* justify-content: center;  */
    text-transform: capitalize;
    letter-spacing: 0.66px;
    // background: #FDF2E5;
    border-bottom: none;
    position:relative;
`;
