import React, { useState, useRef } from 'react'
import {
    SecondCol,
    FirstCol,
    Header,
    MainInfoWrapper,
    TableBodyWrapper,
    TableHeadRow,
    TableBodyRow,
    TableWrapper,
    TypeCol,
    TableFooter,
} from 'src/components/udb/commonStyle';
import Intl from '../../../components/common/Intl'
import styled from 'styled-components';

import DatePickerIcon from 'src/images/calendar.png';
import { useQuery } from '@apollo/client';
import { GET_AGENTREPORT } from 'src/graphql/events';
import util from 'src/util/util';
import { TimeZone, TimeZoneConsumer } from 'src/store/TimeZoneContext';
import { Query } from '@apollo/react-components';
import Spinner from 'src/components/common/Spinner';
import { Col } from 'src/components/common/table/baseTableStyles';
import EmptyRow from 'src/components/common/table/feature/EmptyRow';
import Pagination from 'src/components/common/Pagination';
import { IAppContextStore, withAppContext } from 'src/store/initAppContext';
import DatePickerUdb from 'src/components/udb/DatePickerUdb';
import DatePicker from 'src/components/udb/DatePickerUdb';
import UDBHeader from 'src/components/UDBHeader';
import SEOContents from 'src/components/SEOContents';


const STATUS_CODE = [
    {
        id: 0,
        value: 'Bet'
    },
    {
        id: 1,
        value: 'Confirmed'
    },
    {
        id: 2,
        value: 'Settled'
    },
    {
        id: 3,
        value: 'Cancel'
    },
    {
        id: 4,
        value: 'Delete'
    },
    {
        id: 5,
        value: 'CashOut'
    }
]

interface props {
    app: IAppContextStore;
    targetMember: { target: { memberId: number; type: string }; persona: string };
    theme:any
  }
  
const STPStatement = (props: any) => {
    const [startAt, setStartAt] = useState(new Date(new Date().setHours(0, 0, 0)));
    const [endAt, setEndAt] = useState(new Date(new Date().setHours(23, 59, 59)));
    const [page, setPage] = useState(1);
    const devRef = useRef<HTMLDivElement>(null);

    const {
        app: { memberId, type },
    } = props;

    const setStatus = (code) => {
        let data = STATUS_CODE.filter((status) => {
            if (status.id === code) {
                return status.value
            } else {
                return ''
            }
        })
        return data[0].value
    }

    const handleChangePageIndex = (index: number) => {
        setPage(index)
        devRef.current.scrollIntoView();
    };

    function truncate(str, n){
        return (str && str.length !== 0 && str.length > n) ? str.substr(0, n-1) : str;
        };
    return (
        <MainInfoWrapper ref={devRef}>
            <SEOContents pageName="memberSTPStatement" />
            <Header>
                <FirstCol>
                    <UDBHeader title="SIDEBAR@STP" />
                </FirstCol>
                <SecondCol>
                    <DatePickerUdb setStartAt={setStartAt} setEndAt={setEndAt} />
                </SecondCol>
            </Header>
            <TimeZoneConsumer>
                {({ currentTimeZone }) => {
                    return (
                        <Query
                            query={GET_AGENTREPORT}
                            ssr={false}
                            variables={{
                                input: {
                                    startDate: new Date(util.fixedFrom(startAt).getTime() - currentTimeZone),
                                    endDate: new Date(util.fixedTo(endAt).getTime() - currentTimeZone),
                                    count: 20,
                                    page: page || 1,
                                    venderPlayer: "",
                                    platformId: null,
                                    platform: 'STP',
                                    productTypeId: 2,
                                    status: -1,
                                    target: util.getTargetString({ memberId: memberId, type: type }),
                                    persona: "",
                                },
                            }}
                        >
                            {({ data, loading }): any => {
                                const casinoState: any = util.getValue(data, ['agentReport', 'thirdPartyOrder'], []);
                                if (loading) {
                                    return <Spinner mode="client" />;
                                }
                                return (
                                    <>
                                        <TableWrapper>
                                            <TableHeadRow>
                                                <TypeCol>
                                                    <span> <Intl langKey="CASINO_STATEMENT_TABLE@PROVIDER" /></span>
                                                </TypeCol>
                                                <RefIdCol>
                                                    <span><Intl langKey="CASINO_STATEMENT_TABLE@REFID" /></span>
                                                </RefIdCol>
                                                <TypeCol>
                                                    <span><Intl langKey="CASINO_STATEMENT_TABLE@STAKE" /></span>
                                                </TypeCol>
                                                <ProfitLossCol>
                                                    <span><Intl langKey="CASINO_STATEMENT_TABLE@WINLOSS" /></span>
                                                </ProfitLossCol>
                                                <TypeCol>
                                                    <span><Intl langKey="CASINO_STATEMENT_TABLE@COMMISION" /></span>
                                                </TypeCol>
                                                <TypeCol>
                                                    <span><Intl langKey="CASINO_STATEMENT_TABLE@GAME" /></span>
                                                </TypeCol>
                                                <TypeCol>
                                                    <span><Intl langKey="CASINO_STATEMENT_TABLE@STATUS" /></span>
                                                </TypeCol>
                                                <TimeCol>
                                                    <span><Intl langKey="CASINO_STATEMENT_TABLE@PLACEDTIME" /></span>
                                                </TimeCol>
                                                <TimeCol>
                                                    <span><Intl langKey="CASINO_STATEMENT_TABLE@SETTLEDTIME" /></span>
                                                </TimeCol>
                                            </TableHeadRow>


                                            <TableBodyWrapper>
                                                {casinoState.orderInfo && casinoState.orderInfo.length > 0 ?
                                                    casinoState.orderInfo.map((game) => {
                                                        return (
                                                            <TableSportsBodyRow>
                                                                <TypeCol>
                                                                    {game.provider}
                                                                </TypeCol>
                                                                <RefIdCol>
                                                                    {game.wagerId}
                                                                </RefIdCol>
                                                                <TypeCol>
                                                                    {game.stake.toFixed(2)}
                                                                </TypeCol>
                                                                <ProfitLossCol>
                                                                    <WinLossResult className={game.winloss > 0 ? 'win': 'loss'}>{game.winloss.toFixed(2)}</WinLossResult>
                                                                </ProfitLossCol>
                                                                <TypeCol>
                                                                    {game.commission === null ? '0.00' : game.commission.toFixed(2)}
                                                                </TypeCol>
                                                                <TypeCol data-tip={game.gameName} data-for="gamename" className='customTypeCol'>
                                                                    {truncate(game.gameName, 40)}
                                                                    <p className='customToolTip'>{game.gameName}</p>
                                                                </TypeCol>
                                                                <TypeCol>
                                                                    {
                                                                        setStatus(game.status)
                                                                    }
                                                                </TypeCol>
                                                                <TimeCol>
                                                                    <TimeZone format="yyyy-MM-dd HH:mm:ss">
                                                                        {game.vendorBetTime}
                                                                    </TimeZone>
                                                                </TimeCol>
                                                                <TimeCol className="timelast">
                                                                    <TimeZone format="yyyy-MM-dd HH:mm:ss">
                                                                        {game.vendorSettleTime === null ? 'NA' : game.vendorSettleTime}
                                                                    </TimeZone>
                                                                </TimeCol>
                                                            </TableSportsBodyRow>
                                                        )
                                                    }
                                                    ) : (
                                                        <EmptyRow />
                                                    )
                                                }
                                            </TableBodyWrapper>
                                            <TableFooter>
                                                <Pagination
                                                    numberPerPage={20}
                                                    totalLength={casinoState.totalData || 0}
                                                    onClick={handleChangePageIndex}
                                                    currentIndex={page}
                                                />
                                            </TableFooter>
                                        </TableWrapper>
                                    </>
                                )
                            }}
                        </Query>
                    );
                }}
            </TimeZoneConsumer>
        </MainInfoWrapper >
    )
}

export default withAppContext(STPStatement);

export const DateSelector = styled.div`
  position:relative;
  top:110px;
  left: 25px;
  
  font-weight: bold;
  display:inline-flex;
`;






// const TypeCol = styled(Col)`
// 	flex-basis: 10%;
// 	justify-content: center;
//   align-items: flex-start;
//   padding: 12px 5px;

// `;

const ProfitLossCol = styled(Col)`
	flex-basis: 11%;
    justify-content: center;
`;
export const RefIdCol = styled(Col)`
	flex-basis: 20%;
    // justify-content: center;

    @media only screen and (min-width: 1600px) {
        flex-basis: 12%;
    }
`;

const WinLossResult = styled.div`
    background: #EE6172;
    border-radius: 2px;
    padding: 2px 4px;
    color: #fff;
    &.win {
        background:#38BC7D
    }
    
`;

const TimeCol = styled(Col)`
  flex-basis: 10%;
  padding: 12px 0px;
  justify-content: center;
`;

// const TableBodyWrapper = styled.div`
//   width:100%;
//   display: flex;
//   flex-direction: column;
//   overflow-y: overlay;
//   // height: 100vh;
// `;

const TableSportsBodyRow = styled(Col)`
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-radius: 5px;
    color: #53537B;
    letter-spacing: 0.03em;
    text-transform: capitalize;
    font-size: 12px;
    box-shadow: 0px 0px 50px rgba(16, 12, 74, 0.4);
    background: rgba(255, 255, 255, 0.25);
    border-radius: 3px;
    margin-top: 0.5em;
    padding: 0px 8px;
    position: relative;

    .customTypeCol {
        position: relative;

        .customToolTip {
            color: #fff;
            width: 200px;
            position: absolute;
            bottom: 0;
            left: 0px;
            display: none;
            z-index: 999;
            font-style: normal;
            font-weight: 500;
            font-size: 10px;
            line-height: 15px;
            background: #6778e3;
            border-radius: 5px;
            padding: 2%;
            transition: ease-in 3s;
            transition-delay: 1s;
        }

        /* &:hover {
            .customToolTip {
                display: block;   
            }   
        } */
    }

`;


