import { useEffect } from "react"
import PlaceholderImage from 'src/components/Placeholder';
import { MainInfoWrapper } from 'src/components/udb/commonStyle';
import config from 'src/config/config';
import { withAppContext } from 'src/store/initAppContext';
import styled, { withTheme } from 'styled-components';
import { theme } from 'styled-tools';
import Intl from 'src/components/common/Intl';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import { BannerWrapper, FifaFeverFirstSecHead, StepTittle, TermsConditionWrapper, TextContent } from "./BblBonusInner";
import FooterContainer from "src/components/footer/footer";
import PslLeaderBoardTable from "src/components/psl-leader-board";
import BetdailySEO from "src/components/SEOComponents/betdailySEO";


const PSLPromo = (props) => {
    const { app: {
        brandId }, theme } = props;
    const svgGroups = [
        { imageSvg: `${config.imageV3Host}/static/cashsite/${theme.image.pslsvg1}` },
        { imageSvg: `${config.imageV3Host}/static/cashsite/${theme.image.pslsvg2}` },
        { imageSvg: `${config.imageV3Host}/static/cashsite/${theme.image.pslsvg3}` },
        { imageSvg: `${config.imageV3Host}/static/cashsite/${theme.image.pslsvg4}` },
        { imageSvg: `${config.imageV3Host}/static/cashsite/${theme.image.pslsvg5}` },
        { imageSvg: `${config.imageV3Host}/static/cashsite/${theme.image.pslsvg6}` },
        { imageSvg: `${config.imageV3Host}/static/cashsite/${theme.image.pslsvg7}` },
        { imageSvg: `${config.imageV3Host}/static/cashsite/${theme.image.pslsvg8}` },
        { imageSvg: `${config.imageV3Host}/static/cashsite/${theme.image.pslsvg9}` },
        { imageSvg: `${config.imageV3Host}/static/cashsite/${theme.image.pslsvg10}` },
        { imageSvg: `${config.imageV3Host}/static/cashsite/${theme.image.pslsvg11}` },
        { imageSvg: `${config.imageV3Host}/static/cashsite/${theme.image.pslsvg12}` },
        { imageSvg: `${config.imageV3Host}/static/cashsite/${theme.image.pslsvg13}` },
        { imageSvg: `${config.imageV3Host}/static/cashsite/${theme.image.svgtc}` },
    ]

    useEffect(() => {
        window.scrollTo(0, 0);
    }, [])

    return (
        <><MainInfoWrapper>
            <BannerWrapper1>
                {/* {brandId === 33 || brandId === 34 ? 
                    <Scorecard><LazyLoadImage src={`${config.imageV3Host}${theme.image.scorecard}`} effect="none" height="100%" width="100%" placeholder={<PlaceholderImage />} />
                    </Scorecard>:<></>
                } */}
                {brandId === 33 || brandId === 34 ? 
                    <Scorecard><PslLeaderBoardTable/></Scorecard>:<></>
                }
                {svgGroups.map((elm) => (
                    <LazyLoadImage src={elm.imageSvg} effect="none" height="100%" width="100%" placeholder={<PlaceholderImage />} />))}
            </BannerWrapper1>
           { (brandId ==33 || brandId==34) && <BetdailySEO/>}
        </MainInfoWrapper>
           
            <FooterContainer /></>)
}
export default withTheme(withAppContext(PSLPromo))

export const BannerWrapper1 = styled(BannerWrapper)`
    display: flex;
    flex-direction: column;
    align-items: center;
    img{
        margin-top: -4px;
    }
`

export const Scorecard = styled.div`
    /* width: 441px; */
    min-width: 40%;
    /* min-height: 325px; */
    position: absolute;
    top: 30%;

    @media only screen and (min-width: 1600px) {
        min-width: 32%;
    }
`