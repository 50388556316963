import gql from 'graphql-tag';

export const oddsInfo = gql`
	fragment oddsInfo on OddsInfo {
		price
		size
	}
`;

export const inningInfo = gql`
	fragment inningInfo on InningInfo {
		runs
		overs
		wickets
	}
`;

export const cricketInfo = gql`
	fragment cricketInfo on CricketInfo {
		inning1 {
			...inningInfo
		}
		inning2 {
			...inningInfo
		}
	}
	${inningInfo}
`;

export const scoreType = gql`
	fragment scoreType on ScoreType {
		name
		score
		games
		sets
		cricket {
			...cricketInfo
		}
	}

	${cricketInfo}
`;

export const scoreInfo = gql`
	fragment scoreInfo on ScoreInfo {
		categoryId
		home {
			...scoreType
		}
		away {
			...scoreType
		}
	}
	${scoreType}
`;

export const liveInfo = gql`
	fragment liveInfo on LiveInfo {
		streamName
		url
		videoSource
	}
`;

export const runnerInfo = gql`
	fragment runnerInfo on RunnerInfo {
		jockeyName
		age
		trainerName
		form
		daysSinceLastRun
		jockeyClaim
		wearing
		coloursFilename
	}
`;

// Code Change - Manoj ( FancyInfo) to SnkSelectionInfo
export const fancyInfo = gql`
	fragment fancyInfo on SnkSelectionInfo {
		selectionId
		# ( Code Changed - Manoj (selectionName) to name)
		name
		# ( Code Changed - Manoj (backPrice) to back)
		#backPrice
		back {
			price
			size
		}
		backValue
		backStatus
		# ( Code Changed - Manoj (layPrice) to lay)
		#layPrice
		lay {
			price
			size
		}
		layValue
		layStatus
		isRunning
		# ( Code Changed - Manoj (sortPriority) to priority)
		# sortPriority
		priority
		backSize
		laySize
		backTotalSize
		layTotalSize
	}
`;

export const selectionInfo = gql`
	fragment selectionInfo on SelectionInfo {
		name
		selectionId
		jockeyName
		status
		priority
		runner {
			...runnerInfo
		}
	}
	${runnerInfo}
`;

export const marketInfo = gql`
	fragment marketInfo on MarketInfo {
		marketId
		marketName
		marketType
		marketSource
		marketStartTime
		isOrder
		totalBack
		totalLay
		totalMatched
		totalAvailable
		isFavorite
		status
		betDelay
		maxPerOrderCredit
		maxPerMarketExposure
		oddType
		description
		sortOrder
		# Code Changed - Manoj ( Commented fancy since its not coming from backend)
		# fancyTypeId
		resourceFrom
		list {
			... on SelectionInfo {
				...selectionInfo
			}
		# Code Change - Manoj ( FancyInfo) to SnkSelectionInfo	
			... on SnkSelectionInfo {
				...fancyInfo
			}
		}
		# Code Change - Manoj ( Added Max and Min market )
		BookmakerMaxPerOrderCredit
		BookmakerMaxPerMarketExposure
	}
	${selectionInfo}
	${fancyInfo}
`;

export const innerEventInfo = gql`
	fragment innerEventInfo on EventInfo {
		eventId
		categoryName
		betradarId
		categoryId
		eventName
		competitionId
		competitionName
		openDate
		marketStartTime
		isInplay
		matchType
		dataTime
		isCompetitionOrder
		isEventOrder
		main {
			...marketInfo
		}
		#( Code Changed - Manoj (commented fancy))
		# fancy {
		# 	...marketInfo
		# }
		snk {
			...marketInfo
		}
		score {
			...scoreInfo
		}
		live {
			...liveInfo
		}
	}
	${marketInfo}
	${scoreInfo}
	${liveInfo}
`;

export const eventInfo = gql`
	fragment eventInfo on EventInfo {
		eventId
		categoryName
		categoryId
		eventName
		competitionId
		competitionName
		openDate
		marketStartTime
		isInplay
		dataTime
		isCompetitionOrder
		isEventOrder
		main {
			...marketInfo
		}
		# Code Changed - Manoj ( Commented fancy since its not coming from backend)
		# fancy {
		# 	...marketInfo
		# }
		snk {
			...marketInfo
		}
		score {
			...scoreInfo
		}
		timeElapsed
	}
	${marketInfo}
	${scoreInfo}
`;

export const autoSetting = gql`
	fragment autoSetting on AutoSetting {
		categoryName
		categoryId
		enableSecond
		enableMatched
		orderSecond
		orderMatched
		enableSecondFancy
		enableMatchedFancy
		orderSecondFancy
		orderMatchedFancy
	}
`;

export const GET_EVENTS: any = gql`
	query marketsList($input: MarketEventInput!) {
		markets {
			list(input: $input) {
				...eventInfo
			}
		}
	}
	${eventInfo}
`;

export const GET_HOT_EVENTS: any = gql`
	query hotMarketList($input: MarketEventInput!) {
		markets {
			hot(input: $input) {
				...eventInfo
			}
		}
	}
	${eventInfo}
`;

export const GET_INNER_EVENTS: any = gql`
	query marketsInnerList($input: MarketEventInput!) {
		markets {
			list(input: $input) {
				...innerEventInfo
			}
		}
	}
	${innerEventInfo}
`;

export const SEARCH_EVENTS: any = gql`
	query searchEvents($input: SearchInput!) {
		markets {
			search(input: $input) {
				categoryId
				competitionId
				eventId
				marketName
				eventName
			}
		}
	}
`;

export const simpleEventInfo = gql`
	fragment simpleEventInfo on EventInfo {
		eventId
		categoryId
		betradarId
		eventName
		categoryName
	}
`;
export const SIMPLE_INPLAY_EVENT: any = gql`
	query inplayMarket {
		markets {
			list(input: { isInplay: true }) {
				...simpleEventInfo
			}
		}
	}
	${simpleEventInfo}
`;

export const SIMPLE_HOT_EVENT: any = gql`
	query hotMarketList {
		markets {
			hot(input: { categoryId: 0 }) {
				...simpleEventInfo
			}
		}
	}

	${simpleEventInfo}
`;

export const GET_AGENTREPORT = gql`
	query agentReportList($input: ThirdPartyStatementInput!) {
		agentReport {
			thirdPartyOrder(input: $input) {
				totalData
				totalPage
				orderInfo {
					agent
					vendorPlayerName
					wagerId
					platform
					platformId
					roundId
					turnover
					winloss
					gameCode
					gameName
					productTypeId
					vendorBetTime
					vendorSettleTime
					status
					eventId
					yourBet
					# leagueName
					# awayTeam
					# homeTeam
					# lineTypeName
					provider
					providerId
					stake
					# displayRate
					tpDisplayRate
					commission
					charges
					firstbet
					cashout
				}
			}				
		}
	}		
`;

export const GET_BANNERS = gql`
  query baners($input: BanersInput!) {
    menu {
      baners(input: $input) {
        data
      }
    }
  }
`;
