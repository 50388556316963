import React from 'react'

export default function CopyIcon() {
    return (
        <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M13.3215 6.99053L13.3216 6.99062C13.3772 7.04502 13.4214 7.10999 13.4516 7.18172L13.9125 6.98772L13.4516 7.18172C13.4818 7.25342 13.4974 7.33044 13.4974 7.40824C13.4974 7.40826 13.4974 7.40828 13.4974 7.4083V12.1558C13.4974 12.3107 13.4359 12.4593 13.3263 12.5689C13.2168 12.6784 13.0682 12.7399 12.9132 12.7399H8.57659C8.42167 12.7399 8.27308 12.6784 8.16353 12.5689C8.05398 12.4593 7.99243 12.3107 7.99243 12.1558V5.65081C7.99243 5.49588 8.05398 5.3473 8.16353 5.23775C8.27308 5.1282 8.42166 5.06665 8.57659 5.06665H11.1162C11.1162 5.06665 11.1162 5.06665 11.1162 5.06665C11.2688 5.06669 11.4154 5.12643 11.5245 5.2331C11.5245 5.23311 11.5245 5.23313 11.5245 5.23314L13.3215 6.99053Z" stroke="#FFD085" stroke-linecap="round" stroke-linejoin="round" />
            <mask id="path-2-inside-1_2338_12" fill="white">
                <path d="M12.6617 13.1108V14.195C12.6617 14.4825 12.5475 14.7583 12.3442 14.9616C12.1408 15.1649 11.8651 15.2792 11.5775 15.2792H7.2409C6.95336 15.2792 6.6776 15.1649 6.47428 14.9616C6.27096 14.7583 6.15674 14.4825 6.15674 14.195V8.23211C6.15674 7.94457 6.27096 7.66881 6.47428 7.46549C6.6776 7.26217 6.95336 7.14795 7.2409 7.14795H8.32506" />
            </mask>
            <path d="M13.6617 13.1108C13.6617 12.5586 13.214 12.1108 12.6617 12.1108C12.1094 12.1108 11.6617 12.5586 11.6617 13.1108H13.6617ZM6.15674 14.195L5.15674 14.195L6.15674 14.195ZM7.2409 7.14795V6.14795V7.14795ZM8.32506 8.14795C8.87735 8.14795 9.32506 7.70023 9.32506 7.14795C9.32506 6.59566 8.87735 6.14795 8.32506 6.14795V8.14795ZM11.6617 13.1108V14.195H13.6617V13.1108H11.6617ZM11.6617 14.195C11.6617 14.2173 11.6528 14.2387 11.6371 14.2545L13.0513 15.6687C13.4421 15.2779 13.6617 14.7478 13.6617 14.195H11.6617ZM11.6371 14.2545C11.6213 14.2703 11.5999 14.2792 11.5775 14.2792V16.2792C12.1303 16.2792 12.6604 16.0596 13.0513 15.6687L11.6371 14.2545ZM11.5775 14.2792H7.2409V16.2792H11.5775V14.2792ZM7.2409 14.2792C7.21858 14.2792 7.19717 14.2703 7.18139 14.2545L5.76718 15.6687C6.15803 16.0596 6.68815 16.2792 7.2409 16.2792V14.2792ZM7.18139 14.2545C7.16561 14.2387 7.15674 14.2173 7.15674 14.195L5.15674 14.195C5.15674 14.7478 5.37632 15.2779 5.76718 15.6687L7.18139 14.2545ZM7.15674 14.195V8.23211H5.15674V14.195H7.15674ZM7.15674 8.23211C7.15674 8.20979 7.16561 8.18838 7.18139 8.1726L5.76718 6.75839C5.37632 7.14924 5.15674 7.67936 5.15674 8.23211H7.15674ZM7.18139 8.1726C7.19717 8.15682 7.21858 8.14795 7.2409 8.14795V6.14795C6.68815 6.14795 6.15803 6.36753 5.76718 6.75839L7.18139 8.1726ZM7.2409 8.14795H8.32506V6.14795H7.2409V8.14795Z" fill="#FFD085" mask="url(#path-2-inside-1_2338_12)" />
            <circle cx="10.0771" cy="9.92285" r="8.92285" stroke="#FFD085" />
        </svg>
    )
}


export function CopyIconf88() {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
        <path d="M13.5441 6.83062L13.5442 6.83071C13.5999 6.88511 13.6441 6.95008 13.6743 7.0218L14.1351 6.82781L13.6743 7.02181C13.7045 7.09351 13.72 7.17053 13.7201 7.24833C13.7201 7.24835 13.7201 7.24837 13.7201 7.24839V11.9959C13.7201 12.1508 13.6585 12.2994 13.549 12.4089C13.4394 12.5185 13.2908 12.58 13.1359 12.58H8.79925C8.64432 12.58 8.49574 12.5185 8.38618 12.4089C8.27663 12.2994 8.21509 12.1508 8.21509 11.9959V5.4909C8.21509 5.33597 8.27663 5.18739 8.38619 5.07784C8.49574 4.96828 8.64432 4.90674 8.79925 4.90674H11.3388C11.3389 4.90674 11.3389 4.90674 11.3389 4.90674C11.4915 4.90677 11.638 4.96652 11.7471 5.07319C11.7471 5.0732 11.7472 5.07321 11.7472 5.07322L13.5441 6.83062Z" stroke="#046DFE" stroke-linecap="round" stroke-linejoin="round"/>
        <mask id="path-2-inside-1_2406_15317" fill="white">
          <path d="M12.8844 12.9512V14.0353C12.8844 14.3229 12.7701 14.5986 12.5668 14.802C12.3635 15.0053 12.0877 15.1195 11.8002 15.1195H7.46356C7.17602 15.1195 6.90026 15.0053 6.69694 14.802C6.49362 14.5986 6.37939 14.3229 6.37939 14.0353V8.07244C6.37939 7.78491 6.49362 7.50914 6.69694 7.30582C6.90026 7.10251 7.17602 6.98828 7.46356 6.98828H8.54772"/>
        </mask>
        <path d="M13.8844 12.9512C13.8844 12.3989 13.4367 11.9512 12.8844 11.9512C12.3321 11.9512 11.8844 12.3989 11.8844 12.9512H13.8844ZM6.37939 14.0353L5.37939 14.0353L6.37939 14.0353ZM7.46356 6.98828V5.98828V6.98828ZM8.54772 7.98828C9.1 7.98828 9.54772 7.54057 9.54772 6.98828C9.54772 6.436 9.1 5.98828 8.54772 5.98828V7.98828ZM11.8844 12.9512V14.0353H13.8844V12.9512H11.8844ZM11.8844 14.0353C11.8844 14.0577 11.8755 14.0791 11.8597 14.0948L13.2739 15.5091C13.6648 15.1182 13.8844 14.5881 13.8844 14.0353H11.8844ZM11.8597 14.0948C11.8439 14.1106 11.8225 14.1195 11.8002 14.1195V16.1195C12.353 16.1195 12.8831 15.8999 13.2739 15.5091L11.8597 14.0948ZM11.8002 14.1195H7.46356V16.1195H11.8002V14.1195ZM7.46356 14.1195C7.44124 14.1195 7.41983 14.1106 7.40404 14.0948L5.98983 15.5091C6.38069 15.8999 6.9108 16.1195 7.46356 16.1195V14.1195ZM7.40404 14.0948C7.38826 14.0791 7.37939 14.0577 7.37939 14.0353L5.37939 14.0353C5.37939 14.5881 5.59898 15.1182 5.98983 15.5091L7.40404 14.0948ZM7.37939 14.0353V8.07244H5.37939V14.0353H7.37939ZM7.37939 8.07244C7.37939 8.05012 7.38826 8.02872 7.40404 8.01293L5.98983 6.59872C5.59898 6.98957 5.37939 7.51969 5.37939 8.07244H7.37939ZM7.40404 8.01293C7.41983 7.99715 7.44124 7.98828 7.46356 7.98828V5.98828C6.9108 5.98828 6.38069 6.20786 5.98983 6.59872L7.40404 8.01293ZM7.46356 7.98828H8.54772V5.98828H7.46356V7.98828Z" fill="#046DFE" mask="url(#path-2-inside-1_2406_15317)"/>
        <circle cx="10.2998" cy="9.7627" r="8.92285" stroke="#046DFE"/>
      </svg>
    )
}
