import React, { useEffect, useRef, useState } from "react";
import styled, { withTheme } from "styled-components";
import Intl from "src/components/common/Intl";
import config from "src/config/config";
import { AES, pad, mode, enc } from 'crypto-js';
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import { useForm, Controller } from "react-hook-form";
import { useLocation, useNavigate } from 'react-router-dom';
import { setProfilePassword, verifyOtpWithdrawalPassword } from '../../../config/api'
import { Notify } from '../../../components/Notification';
// import alertIcon from '../../images/alert-circle.png'
import { PasswordInputField } from "src/components/udb/Password";
import WithdrawalPassword from "./WithdrawalPassword";
import { BodyWrapper as PasswordBodyWrapper, ButtonWrapper, FirstCol, Header, MainInfoWrapper, ResetBtn, SubmitBtn } from "src/components/udb/commonStyle";
import UDBHeader from "src/components/UDBHeader";
import { theme } from "styled-tools";
import SEOContents from "src/components/SEOContents";



//react hook forms is used here------------------------------------
type FormValues = {
  curentPassWord: any;
  newProfilePassWord: any;
  confirmNewProfilePassWord: any;
  phoneNumber: any,
  phoneOtp: any,
  withdrawalPassword: any,
  confirmWithdrawalPassword: any,
  newWithdrawalPassword: any,
  confirmNewWithdrawalPassword: any,
};

const TOKEN_SECRET_KEY = config.secretKey;

function Password() {

  //const [title, setTitle] = useState();
  //const [isLoading, setIsLoading] = useState(false);
  //const [isError, setIsError] = useState("");
  //const [successMessage, setSuccessMessage] = useState('');

  //const [formstep, SetFormStep] = useState(0);
  //const [phoneValue, setPhoneValue] = useState('')
  const [disable, setDisable] = useState(true);
  const [tabIndex, setTabIndex] = useState(0);
  let navigate = useNavigate();
  const { state } = useLocation();


  useEffect(() => {
    (state?.['previousPath'] === '/member/finance/withdrawal') ? setTabIndex(1) : setTabIndex(0)
  }, [])

  const [formData, setFormData] = useState({
    curentPassWord: "",
    newProfilePassWord: "",
    confirmNewProfilePassWord: "",
    phoneNumber: "",
    phoneOtp: "",
    withdrawalPassword: "",
    confirmWithdrawalPassword: "",
    newWithdrawalPassword: "",
    confirmNewWithdrawalPassword: "",

  });


  const {
    handleSubmit,
    formState: { isValid, errors },
    reset,
    watch,
    control
  } = useForm<FormValues>({
    mode: "all",
    defaultValues: {
      curentPassWord: "",
      newProfilePassWord: "",
      confirmNewProfilePassWord: "",
      phoneNumber: "",
      phoneOtp: "",
      withdrawalPassword: "",
      confirmWithdrawalPassword: "",
      newWithdrawalPassword: "",
      confirmNewWithdrawalPassword: ""
    },
  });

  const curentPassWord = useRef({});
  curentPassWord.current = watch("curentPassWord", "");
  const newprofilePassword = useRef({});
  newprofilePassword.current = watch("newProfilePassWord", "");
  // handleEnter function is when we press enter the cursor should come down to next input field------------------------------
  const handleEnter = (event) => {
    if (event.key.toLowerCase() === "enter") {
      const form = event.target.form;
      const index = [...form].indexOf(event.target);
      form.elements[index + 1].focus();
      event.preventDefault();
    }
  };
  // token key we are using to encrypt the data which is passed down as payload for the api----------------------------------
  function onSubmit({ curentPassWord, newProfilePassWord }) {

    const info = JSON.stringify({ old_password: curentPassWord.trim(), new_password: newProfilePassWord.trim() });
    const encryptedTarget = enc.Utf8.parse(info)
    const encryptSecretKey = enc.Utf8.parse(TOKEN_SECRET_KEY)
    const changePasswordInfo = AES.encrypt(encryptedTarget, encryptSecretKey, {
      iv: enc.Utf8.parse(''),
      padding: pad.Pkcs7,
      mode: mode.CBC,
    }).ciphertext.toString();

    let bodyParameter = {
      "data": changePasswordInfo
    };

    setProfilePassword(JSON.stringify(bodyParameter)).then((response) => {
      Notify.success('Your Password has Successfully Changed');
      reset()
      setDisable(true)
    })
      .catch((error) => {
        if (error.response.data.data.message === "Old and new password can't be same") {
          Notify.error("Old and new password can't be same")
        } else {
          Notify.error('Old Password is wrong !!')
        }
      })
    setDisable(true)

  };


  const resetHandle = () => {
    reset({ curentPassWord: '', newProfilePassWord: '', confirmNewProfilePassWord: '', phoneOtp: "", withdrawalPassword: "", confirmWithdrawalPassword: "", newWithdrawalPassword: '', confirmNewWithdrawalPassword: '' });
  };

  return (
    <MainInfoWrapper>
      <SEOContents pageName="memberProfileChangePassword"/>
      <Header>
        <FirstCol>
          <UDBHeader title="DASHBOARD@PASSWORD" />
        </FirstCol>
      </Header>
      <TabWrapper>
        <Tabs selectedIndex={tabIndex} onSelect={(index) => setTabIndex(index)}>
          <TabList>
            <Tab><Intl langKey="DASHBOARD@ProfilePAssword" /></Tab>
            {/* <Tab><Intl langKey="DASHBOARD@Withdrwal" /></Tab> */}
          </TabList>

          <BodyWrapper>
            <TabPanel>
              <form onSubmit={handleSubmit(onSubmit)}>


                <Controller
                  control={control}
                  name='curentPassWord'
                  rules={{
                    required: { value: true, message: 'Please Enter Current Account Password' },
                    minLength: { value: 8, message: "* Min 8 Characters" },
                    maxLength: { value: 16, message: "* Max 16 Characters" }
                  }}
                  render={({ field: { onChange, value, onBlur }, fieldState: { error } }) =>
                    <PasswordInputField
                      id="curentPassWord"
                      placeholder="Current Account Password"
                      type="password"
                      onChange={onChange}
                      onKeyPress={handleEnter}
                      onBlur={onBlur}
                      value={value}
                      error={error}
                      req={true}
                      name={'curentPassWord'}
                    />
                  }
                />

                <Controller
                  control={control}
                  name='newProfilePassWord'
                  rules={{
                    required: { value: true, message: "Please Enter New Account Password" },
                    minLength: { value: 8, message: "* Minimum Length : 8" },
                    maxLength: { value: 24, message: "* Max Length reached" },
                    pattern: {
                      value: /^(?=.*?[A-z])(?=.*?[0-9]).{8,}$/,
                      message:
                      "Must contain at least one number, one letter, and at least 8 or more characters",
                    },
                  }}
                  render={({ field: { onChange, value, onBlur }, fieldState: { error } }) =>
                    <PasswordInputField
                      id="newProfilePassWord"
                      placeholder="New Account Password"
                      type="password"
                      onChange={onChange}
                      onKeyPress={handleEnter}
                      onBlur={onBlur}
                      value={value}
                      error={error}
                      req={true}
                      name={'newProfilePassWord'}
                    />
                  }
                />

                <Controller
                  control={control}
                  name='confirmNewProfilePassWord'
                  rules={{
                    required: { value: true, message: 'Please Confirm New Account Password' },
                    minLength: { value: 8, message: "* Min 8 Characters" },
                    maxLength: { value: 16, message: "* Max 16 Characters" },
                    validate: value => value === newprofilePassword.current || "The passwords do not match !!"
                  }}
                  render={({ field: { onChange, value, onBlur }, fieldState: { error } }) =>
                    <PasswordInputField
                      id="confirmNewProfilePassWord"
                      placeholder="Confirm New Account Password"
                      type="password"
                      onChange={onChange}
                      onKeyPress={handleEnter}
                      onBlur={onBlur}
                      value={value}
                      error={error}
                      req={true}
                      name={'confirmNewProfilePassWord'}
                    />
                  }
                />


                <ButtonWrapper>
                  <SubmitBtn
                    type="submit"
                    disabled={!isValid}
                  >
                    <Intl langKey="DASHBOARD@submit" />
                  </SubmitBtn>
                  <ResetBtn type="button" onClick={resetHandle}>
                    <Intl langKey="DASHBOARD@reset" />
                  </ResetBtn>
                </ButtonWrapper>
              </form>
            </TabPanel>

            {/* <TabPanel>
              <WithdrawalPassword />
            </TabPanel> */}
          </BodyWrapper>
        </Tabs>
      </TabWrapper>
    </MainInfoWrapper>
  );
}
export default Password;

//Styled Components
const BodyWrapper = styled(PasswordBodyWrapper)`
  width: 100%;

  .react-tabs__tab-panel--selected {
      width: 50%;

      form {
        width: 100%;
      }
  }
`;

const TabWrapper = styled.div`
    .react-tabs__tab-list {
      display: flex;
      gap: 10px;
      border-bottom: none;
      margin: 0 0 15px;
    }

    .react-tabs__tab {
      background: linear-gradient(266.91deg, #FFFFFF 0%, #EFEFF7 100%);
      border: 1px solid #FFFFFF;
      border-radius: 3px;
      color: #3E3999;
      letter-spacing: 0.01em;
      text-transform: capitalize;
      /* font-weight: 600; */
      font-size: 14px;
      padding: 12px 12px;
      position: relative;
    }

    .react-tabs__tab--selected {
      background: ${theme('palette.secondary-background-color-linear-new')};   
      color: #FFFFFF;
      border-right: 1px solid #ffffff2b;
      border-bottom: 1px solid #fdfdff;
      border-left: 1px solid #ffffff85;
      border-top: 0;

      ::before {
        content: "";
        width: 0; 
        height: 0; 
        position: absolute;
        bottom: -8px;
        border-left: 8px solid transparent;
        border-right: 8px solid transparent;
        border-top : ${theme('palette.borderTopBG')};
       
      }
      ::after{
        border-bottom:none;
        width: 0; 
        height: 0; 
        position: absolute;
        bottom: -8px;
        border-left: 8px solid transparent;
        border-right: 8px solid transparent;
      }
    }
`;

