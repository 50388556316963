import React, { useEffect } from 'react'
import { withAppContext } from 'src/store/initAppContext';
import styled, { withTheme } from 'styled-components';
import { LazyLoadImage } from "react-lazy-load-image-component";
import config from "src/config/config";
import PlaceholderImage from 'src/components/Placeholder';
import { theme } from 'styled-tools';
import FooterContainer from 'src/components/footer/footer';

function EvolutionRouletteCup(props) {
    const { app: {
        brandId
    }, theme } = props;

    useEffect(() => {
        window.scrollTo(0, 0)
    }, [])

    const domain = (brandId == 31 || brandId == 32) ? "Yolo247" : (brandId == 39 || brandId == 40) ? "FOMO7" : 'iw247';

    return (
        <>
            <InformationWrapper>
                {(brandId == 39 || brandId == 40) ? (
                    <TitleText>
                        <h6>Get ready to be electrified by our mind-blowing promotion {domain} Crazy Cup, featuring an eye-popping prize pool of <span className="orange"> INR 800,000</span> waiting just for you!</h6>
                        <h6>Brace yourself for an adrenaline-pumping adventure as you compete for an incredible weekly prize of <span className="orange"> INR 100,000 over 8 weeks.</span></h6>
                        <h5>Dive into the thrilling world of Crazy wheels and seize your chance to win! <br /> With {domain}, the excitement never stops!</h5>
                    </TitleText>
                ) : (
                    <TitleText>
                        <h6>Get ready to be electrified by our mind-blowing promotion {domain} Roulette Cup, featuring an eye-popping prize pool of <span className="yellow">INR 12,00,000</span> waiting just for you!</h6>
                        <h6>Brace yourself for an adrenaline-pumping adventure as you compete for an incredible weekly prize of <span className="yellow"> INR 150,000 over 8 weeks.</span></h6>
                        <h5>Dive into the thrilling world of Roulette tables and seize your chance to win! <br /> With {domain}, the excitement never stops!</h5>
                    </TitleText>
                )}
                {(brandId == 39 || brandId == 40) ? 
                <FomoBodyWrapper>
                    <LeftWrapper>
                        <RoContent>
                            <h1 className='fomoheadText'>EVOLUTION CRAZY CUP</h1>
                            <UnderLine></UnderLine>
                            <TextWrapper>
                                <span className='fomoBullet'>✦</span>
                                <p>Top 206 players will get a share of the weekly 100,000 INR prize pool! Total prize pool would be 800,000 INR</p>
                            </TextWrapper>
                            <TextWrapper>
                                <span className='fomoBullet'>✦</span>
                                <p>The winners are based on the players who wager in Evolution qualified games during the promotion from 1st November – 31st December 2024</p>
                            </TextWrapper>
                            <TextWrapper>
                                <span className='fomoBullet'>✦</span>
                                <p>The Tournament winners are based on the highest wager amount</p>
                            </TextWrapper>
                            <TextWrapper>
                                <span className='fomoBullet'>✦</span>
                                <p>Winners will be determined on a weekly basis</p>
                            </TextWrapper>
                            <TextWrapper>
                                <span className='fomoBullet'>✦</span>
                                <p>This promotion is only applicable for Qualified games: please check the Promotion Schedule tables.</p>
                            </TextWrapper>
                            <TextWrapper>
                                <span className='fomoBullet'>✦</span>
                                <p>There are 199 prizes worth INR 100,000 which will be given to players weekly during the promotion period</p>
                            </TextWrapper>
                        </RoContent>
                    </LeftWrapper>
                    <RightWrapper>
                        <LazyLoadImage
                            src={`${config.imageV3Host}/static/cashsite/${theme.image.EvolutionRouletteImg}`}
                            alt="jetxPromoStepThree"
                            effect="none"
                            className = "fomoImage"
                            placeholder={<PlaceholderImage />}
                        />
                    </RightWrapper>
                </FomoBodyWrapper> :
                <BodyWrapper>
                    <LeftWrapper>
                        <RoContent>
                            <h1>Experience Excitement with Evolution!</h1>
                            <TextWrapper>
                                <span>✪</span>
                                <p>Top <strong>206 players</strong> will get a share of the weekly <strong>150,000 INR prize pool</strong>! Total prize pool would be <strong>12,00,000 INR</strong></p>
                            </TextWrapper>
                            <TextWrapper>
                                <span>✪</span>
                                <p>The winners are based on the players who wager in Evolution qualified games during the promotion from <strong>1st November – 31st December 2024</strong></p>
                            </TextWrapper>
                            <TextWrapper>
                                <span>✪</span>
                                <p>The Tournament winners are based on the highest wager amount Winners will be determined on a weekly basis</p>
                            </TextWrapper>
                            <TextWrapper>
                                <span>✪</span>
                                <p>This promotion is only applicable for Qualified games: please check the Promotion Schedule tables.</p>
                            </TextWrapper>
                            <TextWrapper>
                                <span>✪</span>
                                <p>There are <strong>206 prizes</strong> worth <strong>INR 150,000</strong> which will be given to players weekly during the promotion period</p>
                            </TextWrapper>
                        </RoContent>
                    </LeftWrapper>
                    <RightWrapper>
                        <LazyLoadImage
                            src={`${config.imageV3Host}/static/cashsite/${theme.image.EvolutionRouletteImg}`}
                            alt="jetxPromoStepThree"
                            effect="none"
                            className='yoloImgAnimation'
                            placeholder={<PlaceholderImage />}
                        />
                    </RightWrapper>
                </BodyWrapper>}
                <HeaddingWrapper>
                    <HeadingText className='titleText'>
                        <h1 className={(brandId == 31 || brandId == 32) && 'yoloTitle'}>{(brandId === 39 || brandId === 40) ? "FOMO7 Crazy Cup  Prize Allocation (Based on Highest wageing amount)" : "Prize Allocation"}</h1>
                    </HeadingText>
                    {(brandId == 39 || brandId == 40) && <UnderLine></UnderLine>}
                </HeaddingWrapper>
                <ROWrapper className={(brandId == 39 || brandId == 40) && 'fomoTableBg'}>
                    <LazyLoadImage
                        src={`${config.imageV3Host}/static/cashsite/${theme.image.EvolutionRouletteTable}`}
                        alt="jetxPromoStepThree"
                        effect="none"
                        placeholder={<PlaceholderImage />}
                    />
                    {!(brandId === 39 || brandId === 40) && <p>Prize Allocation (Based on Highest wageing amount)</p>}
                    {
                        (brandId == 39 || brandId == 40) ? <h3 className='fomoPrize'>This incredible promotion boasts a weekly prize of INR 1.0 Lacs & a total prize pool of INR 8 LACS <br /> spread out over Eight thrilling weeks!!</h3> :
                            <h3>This incredible promotion boasts a weekly prize of INR 1.5 Lacs & a total prize pool of INR 12 LACS <br /> spread out over Eight thrilling weeks!!</h3>
                    }
                    {
                        (brandId == 39 || brandId == 40) ?
                        <>
                            <h5>Qualified games:</h5>
                            <h4><b>Evolution : Crazy Time, Funky Time, Money Wheel, Monopoly Live</b></h4>
                        </>
                        : <h4>Qualified games: <b>Evolution Lightning Roulette, Auto-Roulette, Roulette (Evolution (live), XXXtreme Lightning Roulette, Red <br /> Door Roulette, Immersive Roulette, Hindi Roulette, First Person Roulette, Speed Auto Roulette, Auto-Roulette VIP)</b></h4>
                    }
                </ROWrapper>
                {(brandId == 39 || brandId == 40) ? (
                    <Termsandconditions>
                        <TermsandconditionsHeading>
                            <h1>Terms & Conditions</h1>
                        </TermsandconditionsHeading>
                        <UnderLine></UnderLine>
                        <TermsandconditionsBody>
                            <ol>
                                <li>This promotion is valid for all new & existing players.</li>
                                <li>The winners of each week will remain eligible for prizes in the following weeks as well.</li>
                                <li>Winners will be announced in the week after the promo within 3 working days on {domain} social media handles (Instagram, Telegram) and your respective inbox.</li>
                                <li>This promotion is only applicable for Qualified games: please check the Promotion Schedule tables.</li>
                                <li>Winnings will be added to wallets as bonus every week.</li>
                                <li>Void/Cancelled/Draw bets, Cashed-Out bets do not count towards the deposit or bonus rollover requirements.</li>
                                <li>Multiple accounts will not be considered for this promotion eligibility / criteria. All players will be subject for duplicate checking. {domain} reserves the right to disqualify players if collusion or any foul play takes place in the promotion.</li>
                                <li>{domain} & EVOLUTION reserves the right to amend, cancel and reclaim or refuse any promotion at its own discretions.</li>
                                <li>{domain} General Terms & Conditions apply.</li>
                            </ol>
                        </TermsandconditionsBody>
                    </Termsandconditions>
                ) : (
                    <EvoluRouletteTermsAndCondition>
                        <h4>Terms & Conditions</h4>
                        <ul>
                            <li>This promotion is valid for all new & existing players.</li>
                            <li>The winners of each week will remain eligible for prizes in the following weeks as well.</li>
                            <li>Winners will be announced in the week after the promo within 3 working days on {domain} social media handles (Instagram, Telegram) and your respective inbox.</li>
                            <li>This promotion is only applicable for Qualified games: please check the Promotion Schedule tables.</li>
                            <li>Winnings will be added to wallets as bonus every week.</li>
                            <li>Void/Cancelled/Draw bets, Cashed-Out bets do not count towards the deposit or bonus rollover requirements.</li>
                            <li>Multiple accounts will not be considered for this promotion eligibility / criteria. All players will be subject for duplicate checking. {domain} reserves the right to disqualify players if collusion or any foul play takes place in the promotion.</li>
                            <li>{domain} & EVOLUTION reserves the right to amend, cancel and reclaim or refuse any promotion at its own discretions.</li>
                            <li>{domain} General Terms & Conditions apply.</li>
                        </ul>
                    </EvoluRouletteTermsAndCondition>
                )}
            </InformationWrapper>
            <FooterContainer />
        </>
    )
}



export default withTheme(withAppContext(EvolutionRouletteCup));

const InformationWrapper = styled.div`
    width: 98%;
    height: auto;
    padding: 3rem 3rem 8rem 3rem;
    border-radius: 1px;
    background: rgba(111, 111, 111, 0.5);
    margin: 0em 1.2em 0 1.2em;
    border: 1px solid #E5B358;
    border-radius: 0 0 20px 20px;
    border-top-color: transparent;
    backdrop-filter: brightness(0.5);    
    display: flex;
    flex-direction: column;
    background: url(${config.imageV3Host}/static/cashsite/${theme("image.EvolutionRouletteCupBackground")});
    background-position: top;
    background-size: cover;
    background-repeat: no-repeat;

    .yoloTitle {
        margin-top: 0;
        text-align: center;
        font-size: 25px;
        font-style: normal;
        font-weight: 700;
        line-height: 35px; /* 140% */
        text-transform: capitalize;
        background: linear-gradient(180deg, #FFE500 18.06%, #FFE500 91.67%);
        background-clip: text;
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
        margin-bottom: 0;
    }

    .fomoTableBg {
        background: url(${config.imageV3Host}/static/cashsite/${theme("image.EvolutionRouletteTableBg")});
        background-position: top;
        background-size: cover;
        background-repeat: no-repeat;
    }
`;

const TitleText = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    padding: 1rem 0 4rem 0;

    h6 {
        margin: 0;
        padding: 0;
        color: #FFF;
        font-size: 15px;
        font-style: normal;
        font-weight: 500;
        line-height: 160.3%;
        text-transform: capitalize;
    }

    h5 {
        color: #FFF;
        text-align: center;
        font-size: 15px;
        font-style: normal;
        font-weight: 400;
        line-height: 28px; /* 186.667% */
        text-transform: capitalize;
    }

    .yellow {
        color: #FFE500;
        font-weight: 700;
    }

    .orange {
        color: #FEBA3B;
        font-weight: 700;
    }
`;

const BodyWrapper = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    gap: 10rem;
`;

const FomoBodyWrapper = styled(BodyWrapper)`
    background: url(${config.imageV3Host}/static/cashsite/${theme("image.EvolutionRouletteImgBg")});
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
    gap: 3rem;

    h1 {
        background: linear-gradient(180deg, #FED546 18.06%, #FE7C23 91.67%);
        background-clip: text;
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
        margin-bottom: 0.25rem !important;
    }

    .fomoBullet {
        color: #FEBA3B;
    }
`;

const LeftWrapper = styled.div`
    display: flex;
    flex-direction: row;
    width: 45%;
`;

const RightWrapper = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    position: relative;

    img {
        width: 25rem;
    }

    .fomoImage {
        width: 40rem;
    }

    img.yoloImgAnimation {
        top: 50%;
        left: 50%;
        -webkit-animation:spin 10s linear infinite;
        -moz-animation:spin 10s linear infinite;
        animation:spin 10s linear infinite;
    }

    @-moz-keyframes spin {
        100% { -moz-transform: rotate(360deg); }
    }

    @-webkit-keyframes spin {
        100% { -webkit-transform: rotate(360deg); }
    }
    
    @keyframes spin {
        100% {
            -webkit-transform: rotate(360deg);
            transform:rotate(360deg);
        }
    }
`;

const RoContent = styled.div`
    display: flex;
    flex-direction: column;
    gap: 1rem;
    width: 100%;

    h1 {
        margin: 0 0 1.5rem 0;
        color: #FFE500;
        font-size: 20px;
        font-style: normal;
        font-weight: 700;
        line-height: normal;
        text-transform: capitalize;
    }

    .fomoheadText {
        font-size: 25px;
    }
`;

const TextWrapper = styled.div`
    display: flex;

    span {
        color: #FFE500;
        font-weight: 700;
    }

    p {
        margin: 0;
        color: #FFF;
        font-size: 16px;
        font-style: normal;
        font-weight: 500;
        line-height: 150.3%; /* 19.248px */
        text-align: left;
        padding-left: 0.5rem;
        text-transform: capitalize;
    }
`;

const HeaddingWrapper = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 3rem;
    margin: 0 -2rem;

    .joiningTxt {
        padding-top: 1.5rem;
    }

    .titleText {
        padding: 1rem 0 0.2rem 0;
        h1 {
            margin-top: 0;
        }
    }

    .yoloTitle {
        text-align: center;
        font-size: 25px;
        font-style: normal;
        font-weight: 700;
        line-height: 35px; /* 140% */
        text-transform: capitalize;
        margin-bottom: 0;
    }
`;

const HeadingText = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;

    h1 {
        text-align: center;
        font-size: 25px;
        font-style: normal;
        font-weight: 600;
        line-height: normal;
        letter-spacing: 1.25px;
        background: linear-gradient(180deg, #FED546 18.06%, #FE7C23 91.67%);
        background-clip: text;
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
    }

    p {
        color: #FFF;
        text-align: center;
        font-family: Inter;
        font-size: 16px;
        font-style: normal;
        font-weight: 500;
        line-height: 120.3%; /* 19.248px */
    }
`;

const UnderLine = styled.div`
    height: 2px;
    width: 184px;
    background: radial-gradient(#FFF 0%, rgba(153, 153, 153, 0.00) 80%);
`;

const ROWrapper = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    position: relative;
    flex-direction: column;

    img {
        width: 70%;
    }

    p {
        color: #FFF;
        text-align: center;
        font-size: 16px;
        font-style: italic;
        font-weight: 400;
        line-height: 160.3%; /* 25.648px */
    }

    h3 {
        color: #FFE500;
        text-align: center;
        font-size: 16px;
        font-style: italic;
        font-weight: 700;
        line-height: 26px; /* 162.5% */
        text-transform: capitalize;
    }

    h3.fomoPrize {
        color: #FEBA3B;
        text-align: center;
        font-style: normal;
        padding-top: 1rem;
    }

    h4 {
        color: #FFF;
        text-align: center;
        font-size: 15px;
        font-style: normal;
        font-weight: 400;
        line-height: 23px; /* 153.333% */
        text-transform: capitalize;
    }

    h5 {
        color: #FFF;
        text-align: center;
        font-size: 16px;
        font-style: italic;
        font-weight: 400;
        line-height: 160.3%; /* 25.648px */
        text-transform: capitalize;
    }
`;

const Termsandconditions = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    border-radius: 30px;
    width: 80%;
    height: auto;
    margin: 3rem 10% 3rem 10%;
    padding: 5rem 3rem;
    position: relative;
    background: linear-gradient(#020405, rgba(0, 0, 0, 0.935), #020405);
    background-clip: padding-box;
    border: 2px solid transparent;

    ::after {
        position: absolute;
        top: -2px; bottom: -2px;
        left: -2px; right: -2px;
        background: linear-gradient(90deg, #53C1FF, black, #53C1FF, black, #53C1FF);
        content: '';
        z-index: -1;
        border-radius: 30px;
    }
`;

const EvoluRouletteTermsAndCondition = styled.div`
    width: 100%;
    height: auto;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    gap: 10px;
    margin: 3rem 0rem 5rem 0rem;
    padding: 0 9rem 0 4rem;
    position: relative;

    & li {
        color: #FFF;
        text-align: justify;
        font-size: 18px;
        font-style: normal;
        font-weight: 400;
        line-height: 30px; /* 166.667% */
        text-transform: capitalize;
    }

    & h4 {
        font-size: 25px;
        font-style: normal;
        font-weight: 700;
        line-height: 35px; /* 140% */
        text-transform: capitalize;
        color: #FFE500;
        margin: 10px 0px 0px 15px;
    }

    .bold {
        color: #FFF;
        font-weight: 700;
    }
`;

const TermsandconditionsHeading = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;    
    padding-bottom: 2rem;

    h1 {
        margin: 0;
        padding: 0;
        text-align: center;
        font-size: 25px;
        font-style: normal;
        font-weight: 600;
        line-height: normal;
        letter-spacing: 1.25px;
        background: linear-gradient(180deg, #FED546 18.06%, #FE7C23 91.67%);
        background-clip: text;
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
    }
`;

const TermsandconditionsBody = styled.div`
    ol {
        display: flex;
        flex-direction: column;
        justify-content: center;
        gap: 1rem;
        padding-top: 1rem;
    }

    li {
        color: #FFF;
        font-size: 16px;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
        text-transform: capitalize;
    }

    .yellow {
        color: #FEBA3B;
        font-weight: 700;
    }
`;