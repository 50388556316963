import { IEventInfo } from '../../graphql/schema';

const modifyNewDataWithFavorite = (eventList: IEventInfo[], favoriteList: string[]) => {
	return eventList.map(event => {
		const main = event.main
			? event.main.map(market => {
					const isFavorite = favoriteList.indexOf((market && market.marketId) || '') > -1;
					return {
						...market,
						isFavorite,
						status: (market && market.status) || '',
					};
			  })
			: [];
		return { ...event, main };
	});
};
const getNewDataWithOldDataFavoriteInfo = (oldData: IEventInfo[], newData: IEventInfo[]) => {
	const favoriteList: string[] = [];
	oldData.map(event => {
		const main = event.main || [];
		main.map(market => {
			market && market.isFavorite && favoriteList.push(market.marketId || '');
		});
	});
	return modifyNewDataWithFavorite(newData, favoriteList);
};

function notEmpty<TValue>(value: TValue | null | undefined): value is TValue {
	return value !== null && value !== undefined;
}

export default (oldData: IEventInfo[], newData: IEventInfo[], variables: { eventId: string; [str: string]: any }) => {
	const modifyNewData = getNewDataWithOldDataFavoriteInfo(oldData, newData);
	const oldDataEventIds = oldData.map(event => event.eventId);

	// 內頁的時候，不添加eventId(訂閱異常的時候會吐其他的eventId)，用參數是否有eventId來對應
	const newAddList: IEventInfo[] = variables.eventId
		? []
		: modifyNewData.reduce((pv, newEvent) => {
				if (oldDataEventIds.indexOf(newEvent.eventId) < 0) {
					pv.push(newEvent);
				}
				return pv;
		  }, [] as IEventInfo[]);

	const modifiedOldData = oldData.reduce((list, oldEvent: IEventInfo) => {
		const modifyInfo = modifyNewData.reduce(
			(pv, newEvent) => {
				if (newEvent.eventId === oldEvent.eventId) {
					// BF凍結，暫時註解
					// const fancyList = newEvent.fancy || [];
					// const isAllMarketClose =
					// 	newEvent.main.every(market =>
					// 		['close', 'inactive'].some(_ => _ === market.status.toLowerCase()),
					// 	) && fancyList.length === 0;

					// if (isAllMarketClose) {
					if (false) {
						pv.isDel = true;
					} else {
						// 因為 pubsub 只更新主盤，所以須保留 polling 的 betfair fancy data(在 main 裡)
						list.push({
							...newEvent,
							main: !!oldEvent.main
								? oldEvent.main
										.map(old => {
											const oldMarketId = (old && old.marketId) || '';
											const newMain = newEvent.main.find(n => {
												const mainMarketId = (n && n.marketId) || '';
												if (!!mainMarketId && !!oldMarketId && mainMarketId === oldMarketId) {
													return true;
												}
												return false;
											});

											// 如果market狀態是close/inactive則不顯示此筆資料
											if (
												['close', 'inactive'].some(_ =>
													newMain ? _ === newMain.status.toLowerCase() : false,
												)
											) {
												return null;
											}
											return newMain ? newMain : old;
										})
										.filter(notEmpty)
								: [],
						});
						pv.isUpdated = true;
					}
				}
				return pv;
			},
			{ isDel: false, isUpdated: false },
		);

		!modifyInfo.isDel && !modifyInfo.isUpdated && list.push(oldEvent);
		return list;
	}, [] as IEventInfo[]);

	return [...newAddList, ...modifiedOldData];
};
