import React from 'react'

type Props = {}

const CloseWithdrawIcon = (props: Props) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="15" height="14" viewBox="0 0 15 14" fill="none">
        <path d="M3.03231 13.3035L7.71251 8.6234L12.3928 13.3035L12.6371 13.548L12.8816 13.3035L13.8368 12.3485L14.0813 12.104L13.8368 11.8595L9.15663 7.17927L13.8368 2.49909L14.0813 2.25462L13.8368 2.01013L12.8816 1.05502L12.6371 0.810547L12.3928 1.05502L7.71251 5.73525L3.03231 1.05502L2.78782 0.810547L2.54335 1.05502L1.58823 2.01013L1.34375 2.25462L1.58823 2.49909L6.26849 7.17927L1.58823 11.8595L1.34375 12.104L1.58823 12.3485L2.54335 13.3035L2.78782 13.548L3.03231 13.3035Z" fill="white" stroke="white" stroke-width="0.617399"/>
    </svg>
  )
}

export default CloseWithdrawIcon
