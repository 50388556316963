import React from 'react';
import styled, { css } from 'styled-components';
import ClickOutside from '../../common/ClickOutside';
import { panelStyle, shadowSM } from '../../common/commonCSSStyles';
import Icon, { IconWrapper } from '../../common/PNGIcon';
import Intl from '../../common/Intl';
import { diffColor } from '../../../util/colorUtil';
import { getGTMId } from '../../../util/gtmUtil';
import { isMemberType } from '../../../util/styleUtil';
import { withProp } from 'styled-tools';
const { ifProp, theme } = require('styled-tools');

class DefaultStake extends React.PureComponent<any, any> {
	constructor(props: any) {
		super(props);
		this.state = {
			quickStakes: this.props.quickStakes || [],
		};
	}
	save = () => {
		this.props.onSave(this.state.quickStakes);
	};
	handleEditQuickStake = (idx: number) => (e: any) => {
		const newQuickStake = [...this.state.quickStakes];
		newQuickStake[idx] = Number(e.target.value.replace(/[^\d]/, ''));
		this.setState({ quickStakes: newQuickStake });
	};
	render() {
		return (
			<ClickOutside callback={this.props.changeEditingState(true)} shouldTriggerCallback={this.props.isActive}>
				{(containerRef: any) => (
					<EditStakeWrapper isActive={this.props.isActive} ref={containerRef}>
						{this.state.quickStakes.map((stake: number, i: number) => {
							return (
								<EditStackInputWrapper key={i}>
									<EditStackInput value={stake} onChange={this.handleEditQuickStake(i)} />
								</EditStackInputWrapper>
							);
						})}
						<SaveButton onClick={this.save} id={getGTMId('default_stake_save')}>
							<Icon name="check-white" />
							<Intl langKey="ORDERSLIP@SAVE" />
							{/* Save */}
						</SaveButton>
					</EditStakeWrapper>
				)}
			</ClickOutside>
		);
	}
}

export default DefaultStake;

const EditStakeWrapper: any = styled.div`
	${panelStyle};
	${shadowSM};
	display: ${ifProp('isActive', 'flex', 'none')};
	flex-wrap: wrap;
	justify-content: space-evenly;
	align-items: center;
	margin-bottom: 1em;
	padding: 0.8em;
	border: 1px solid rgba(128, 128, 128, 0.2);

`;

const EditStackInputWrapper = styled.div`
	display: flex;
	// width: 25%;
	width: 21%;
	padding: 0.2em;
`;
const EditStackInput = styled.input.attrs(props => ({
	type: 'text',
}))`
	border-radius: 99em;
	width: 100%;
	padding: 0.5em 0.5em;
	text-align: center;
	border: none;
	font-size: 13px;
	background: linear-gradient(180deg,rgba(255,255,255,0.88) 0%,rgba(214,214,214,0.45) 100%);
	box-shadow: inset 0px 4px 4px rgb(0 0 0 / 25%);
	border-radius: 5px;

	&:focus {
		outline: 0;
	}
`;

const SaveButton = styled.button`
	width: 21%;
    padding: 0.45em 0.5em;
	display: flex;
	align-items: center;
	border: 0;
	// width: 100%;
	justify-content: center;
	cursor: pointer;
	font-size: 13px;
    background: #03BC58;
	border-radius: 5px;
	color: #fff;

	img {
		width: 13px;
		height: 13px;
	}

	&:focus {
		outline: 0;
	}

	${IconWrapper} {
		margin-right: 0.5em;
	}
	&:hover {
	}
`;
