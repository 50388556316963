import React from "react";

type Props = {};

export default function Telegram({}: Props) {
  return (
    <svg
      width="21"
      height="21"
      viewBox="0 0 21 21"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M10.5 21C16.299 21 21 16.299 21 10.5C21 4.70101 16.299 0 10.5 0C4.70101 0 0 4.70101 0 10.5C0 16.299 4.70101 21 10.5 21Z"
        fill="url(#paint0_linear_805_216)"
      />
      <path
        d="M3.91071 11.0135C5.13816 10.3374 6.50832 9.77312 7.78853 9.20595C9.99099 8.27696 12.2022 7.36407 14.4357 6.51419C14.8703 6.36938 15.6511 6.22779 15.7277 6.87176C15.6857 7.78334 15.5133 8.68958 15.395 9.59581C15.0947 11.5887 14.7477 13.5748 14.4093 15.5611C14.2927 16.2228 13.4639 16.5653 12.9335 16.1419C11.659 15.281 10.3747 14.4284 9.11642 13.5476C8.70425 13.1288 9.08646 12.5273 9.45457 12.2282C10.5043 11.1937 11.6176 10.3148 12.6124 9.22682C12.8808 8.57878 12.0879 9.12492 11.8263 9.29228C10.3892 10.2826 8.9873 11.3334 7.47215 12.2038C6.69821 12.6298 5.79617 12.2657 5.02259 12.028C4.32898 11.7408 3.31257 11.4515 3.91064 11.0136L3.91071 11.0135Z"
        fill="white"
      />
      <defs>
        <linearGradient
          id="paint0_linear_805_216"
          x1="14.0009"
          y1="3.5007"
          x2="8.75087"
          y2="15.75"
          gradientUnits="userSpaceOnUse"
        >
          <stop stop-color="#37AEE2" />
          <stop offset="1" stop-color="#1E96C8" />
        </linearGradient>
      </defs>
    </svg>
  );
}
