import React, { Component } from "react";
import styled from "styled-components";
import { ifProp, theme, withProp } from "styled-tools";
import { color } from "../../../sass-files/variable";
import PopularTableBodyContent from "./popular-tablebody";
import InPlayLiveIcon from "../../../images/inplay_live.png";
import Intl from "../../common/Intl";

const { tabsignup } = color;
const { secondarycolor } = color;
const { exchangeTableHeadBg } = color;

class PopularSection extends Component {
  render() {
    return (
      <InPlayWrapper>
        <InPlayHeader>
          <PopulatTittle>
            <span className="tab-name">
              <Intl langKey="TITLE@POPULAR" />
            </span>
          </PopulatTittle>
        </InPlayHeader>
        <TittleWrapper></TittleWrapper>
        <InPlayTable>
          <PopularTableBodyContent />
        </InPlayTable>
      </InPlayWrapper>
    );
  }
}

export default PopularSection;

export const InPlayHeader = styled.div`
  // width: 85%;
  width: 250px;
  // margin-top : 5px;
  position: absolute;
  left: 0;
  top: 0;
  padding: 14px 10px;
  /* background: ${theme("palette.secondary-background-color-linear-new")}; */
  background: ${theme('palette.InPlayHeaderBgNew')};


  :after {
    content: "";
    width: 45px;
    height: 100%;
    /* background: ${theme("palette.secondary-background-color-linear-new-after")}; */
    background: ${theme('palette.InPlayHeaderBgNewAfter')};
    position: absolute;
    top: 0;
    right: -14px;
    transform: skew(18deg);
    border-top-right-radius: 10px;
    border-bottom-right-radius: 10px;
  }
`;

export const PopulatTittle = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;

  .tab-name {
    font-size: 15px;
    color: ${theme("palette.InPlayHeaderTittle")};
    font-weight: 500;
    text-transform: uppercase;
    display: flex;
    align-items: center;
  }

  .italics {
    margin: 0;
    padding: 0;
    color: #e64c3c;
    letter-spacing: 0.07em;
    text-decoration-line: underline;
    text-transform: capitalize;
    font-size: 12px;
    padding-left: 20px;
  }
`;

const TeamHead = styled.div`
  width: 240px;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  padding-left: 20px;

  @media only screen and (max-width: 1440px) {
    width: 180px;
  }
`;

const InPlayHead = styled.div`
  width: 120px;
  display: flex;
  align-items: center;
  justify-content: center;

  .live_icon {
    width: 13px;
    height: 13px;
    background-image: url(${InPlayLiveIcon});
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
    margin-right: 5px;
  }

  text {
    color: #5ffbc3;
  }
`;

const OddsHead = styled.div`
  width: 400px;
  display: flex;
  align-items: center;
  justify-content: space-around;
`;

const ManyMoreHead = styled.div`
  width: 120px;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const FancyHead = styled.div`
  width: 350px;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const TableHead = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: space-between;
  padding: 10px 20px;
  color: #ced8ea;
  font-size: 13px;
  padding-right: 0;
  background-color: ${exchangeTableHeadBg};
`;

const InPlayTable = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: space-between;
  margin: 0px;
`;

const TittleWrapper = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  flex-direction: row;
  padding-top: 45px;
`;

const InPlayWrapper = styled.div`
  width: 100%;
  display: flex;
  // align-items: center;
  flex-direction: column;
  position: relative;

  .table_tittle {
    // width: calc(100% - 350px);
    width: 100%;
    display: flex;
    align-items: center;
    flex-direction: row;
    justify-content: space-between;
    padding: 0px 20px;
    margin: 0px;
    /* background-color: ${tabsignup}; */
    background: ${theme("palette.secondary-background-color-shade")};
    box-shadow: ${theme("palette.inPlayWrapperTableTittleBoxShadow")};
    padding-right: 0;
    border-radius: 5px;

    li {
      color: ${theme("palette.TableTittleContentColor")};
      font-size: 12px;
      list-style: none;
      letter-spacing: 0.1em;
      text-transform: uppercase;
      display: flex;
      align-items: center;
      justify-content: center;
      font-weight: bold;
    }

    li.match {
      // width: 240px;
      justify-content: flex-start;
      padding-left: 20px;

      // @media only screen and (max-width: 1440px) {
      //     width: 180px;
      // }
    }

    li.status {
      width: 120px;
    }

    // li.clickodd {
    //     width: 400px;
    // }

    li.option {
      width: 120px;
    }

    // .parallax {
    //     width: 350px;
    //     color: #DEC59D;
    //     font-family: 'poppinsregular';
    //     font-size: 13px;
    //     color: #fff;
    //     background: linear-gradient(180deg, #DEC59D 0%, #D7AE6D 100%);
    //     padding: 10px 20px;
    //     text-align: center;
    //     position: relative;
    //     text-transform: uppercase;
    // }

    .parallax:after {
      content: "";
      width: 20px;
      height: 42px;
      // background: linear-gradient(180deg, #DEC59D 0%, #D7AE6D 100%);
      // background: #DEC59D;
      position: absolute;
      top: 0;
      left: -8px;
      transform: skew(-20deg); /* UNSKEW */
    }
  }
`;
