import React, { useEffect } from 'react'
import { LazyLoadImage } from "react-lazy-load-image-component";
import config from "src/config/config";
import PlaceholderImage from 'src/components/Placeholder';
import styled, { withTheme } from 'styled-components'
import { theme } from 'styled-tools'
import { withAppContext } from 'src/store/initAppContext';
import QuestionMarkIcon from 'src/components/icon/QuestionMarkIcon';
import DollorIcon from 'src/components/icon/DollorIcon';
import FooterContainer from 'src/components/footer/footer';
import { Link } from 'react-router-dom';

const HundredLossbackBonus25 = (props: any) => {

    const { app: {
        brandId
    }, theme } = props;

    useEffect(() => {
        window.scrollTo(0, 0)
    }, [])

    const domain = (brandId == 31 || brandId == 32) ? "YOLO247" : (brandId == 39 || brandId == 40) ? "FOMO7" : 'iW247';

        
  return (
    <>
    <InformationWrapper>
        
            <HLBFirstSec>
               <MaxWrap>
                    <HBLFirstSecLeft>
                        <h2>GET ADDITIONAL <br />100% LOSSBACK</h2>
                        <h4>on IPL Exchange Bets EVERYDAY! 🏏</h4>
                        <p>The Indian Premier League has arrived, and we're rolling out the red carpet for all cricket enthusiasts.</p>
                        <button><Link to="/betting-exchange/online-cricket-betting">Claim Your Bonus Now</Link></button>
                    </HBLFirstSecLeft>

                    <HBLFirstSecRight>
                            <LazyLoadImage
                                src={`${config.imageV3Host}/static/cashsite/${theme.image.HundredLBStatue01}`}
                                alt="prize"
                                effect="none"
                                placeholder={<PlaceholderImage />}
                            /> 
                    </HBLFirstSecRight>
                </MaxWrap>
            </HLBFirstSec>

            <HBLSecondSec>
                <h4>How to Play and Win</h4>
                <HBLSecondSecBoxWrap>
                    <HBLSecondSecBox>
                            <QuestionMarkIcon />
                            <h3>Place Exchange Bets</h3>
                            <p>Dive into the excitement of the IPL matches by placing your bets on our cutting-edge Exchange platform.</p>
                    </HBLSecondSecBox>

                    <HBLSecondSecBox>
                            <DollorIcon />
                            <h3>Claim Your 100% Cashback</h3>
                            <p>For cumulative losses of the day, we'll reward you with a 100% additional Lossback Bonus the very next day.</p>
                    </HBLSecondSecBox>
                </HBLSecondSecBoxWrap>
            </HBLSecondSec>

            <HBLThirdWrap> 
                    <LazyLoadImage
                        src={`${config.imageV3Host}/static/cashsite/${theme.image.HundredLBStatue02}`}
                        alt="prize"
                        effect="none"
                        placeholder={<PlaceholderImage />}
                    />
                    <LazyLoadImage
                        src={`${config.imageV3Host}/static/cashsite/${theme.image.HundredLBStatue03}`}
                        alt="prize"
                        effect="none"
                        placeholder={<PlaceholderImage />}
                    />
            </HBLThirdWrap>

            <HBLFourthWrap>
                <h3>Terms and Conditions</h3>
                <HBLFourthWrapTermsSec>
                    <ul>
                        <li>The Lossback Bonus will be applied to those lost IPL bets placed during IPL 2025 daily.</li>
                        <li>The Lossback Bonus amount for each qualifying bet will be capped at ₹50.</li>
                        <li>The Lossback Bonus will be credited to your {domain} account the following day.</li>
                        <li>The Lossback Bonus will expire within 24-36 hours.</li>
                        <li>There is no limit to the number of bets you can place to earn the 100% Lossback Bonus.</li>
                        <li>You cannot withdraw the Lossback Bonus but can use it to place bets on {domain}.</li>
                        <li>The Lossback Bonus promotion is valid only for IPL 2025.</li>
                        <li>{domain} reserves the right to amend, cancel, reclaim, or refuse any promotions at its discretion.</li>
                        <li>{domain} General Terms & Conditions apply.</li>
                    </ul>
                </HBLFourthWrapTermsSec>
            </HBLFourthWrap>
    </InformationWrapper>
    <FooterContainer />
    </>
  )
}

export default withTheme(withAppContext(HundredLossbackBonus25))


// Styled Component.
const InformationWrapper = styled.div`
    width: 98%;
    height: auto;
    padding: 4rem 0rem 4rem 0rem;
    background: rgba(111, 111, 111, 0.5);
    margin: 0em 1.2em 0 1.2em;
    border: 1px solid #fff;
    border-top-color: transparent;
    backdrop-filter: brightness(0.5);    
    display: flex;
    flex-direction: column;
    background: url(${config.imageV3Host}/static/cashsite/${theme("image.HundredLBInnerBg")});
    background-position: top !important;
    background-size: cover;
    background-repeat: no-repeat;


    .mb-10 {
       margin-bottom: 20px;
    }

    .mt-10 {
       margin-top: 20px;
    }
`;

const HLBFirstSec = styled.div`
    width: 100%;
    height: auto;
    margin-top: 2rem;
    background: url(${config.imageV3Host}/static/cashsite/${theme("image.HundredLBFirstSecBg")});
    background-position: bottom !important;
    background-size: cover;
    background-repeat: no-repeat;
    display: flex;
    justify-content: center;
    gap: 10px;
    padding: 0% 5%;
`

const HBLFirstSecLeft = styled.div`
    width: 60%;
    display: flex;
    flex-direction: column;
    gap: 10px;
    justify-content: center;

    & h2 {
        color: #FBBF24;
        font-size: 60px;
        font-style: normal;
        font-weight: 700;
        line-height: 68.856px; /* 100% */
        margin: 2px 0px;
    }

    & h4 {
        color: #FFF;
        font-size: 30px;
        font-style: normal;
        font-weight: 600;
        line-height: 34.428px; /* 100% */
        margin: 2px 0px;
    }

    & p {
        color: #FFF;
        font-size: 18px;
        font-style: normal;
        font-weight: 400;
        line-height: 19.127px; /* 100% */
        margin: 2px 0px;
    }

    & button {
        border-radius: 9562.313px;
        border: 0px solid #E5E7EB;
        background: #FBBF24;
        outline: none;
        padding: 10px 5px;
        color: #1E3A8A;
        text-align: center;
        font-size: 19.127px;
        font-style: normal;
        font-weight: 700;
        line-height: normal;
        margin: 1px 0px;
        width: 260px;
        height: auto;
        cursor: pointer;

        & a {
            color: #1E3A8A;
            text-decoration: none;
        }

        :hover {
            background: #ffb700e1;
        }
    }
`

const HBLFirstSecRight = styled.div`
    width: 40%;
    display: flex;
    align-items: center;
    justify-content: center;

    & span {
        width: 60%;
    }

    & img {
        width: 100%;
        height: auto;
    }
`


const HBLSecondSec = styled.div`
    width: 100%;
    height: auto;
    background: #B60101;
    padding: 2rem 1rem 3rem 1rem;
    margin-top: 2rem;

    & h4 {
        text-align: center;
        color: #FFF;
        font-size: 25px;
        font-style: normal;
        font-weight: 700;
        line-height: 34.428px; /* 100% */
        margin: 2px 0px 15px 0px;
    }

`

const HBLSecondSecBoxWrap = styled.div`
    display: flex;
    justify-content: flex-start;
    gap: 30px;
    max-width: 1400px;
    margin: 0 auto;
`

const HBLSecondSecBox = styled.div`
    border-radius: 15.301px;
    border: 0px solid #E5E7EB;
    background: #1F3B90;
    padding: 25px 20px;
    color: #fff;
    width: 45%;

    & h3 {
        color: #FFF;
        font-size: 20px;
        font-style: normal;
        font-weight: 700;
        line-height: normal;
        margin: 2px 0px;
    }

    & p {
        color: #FFF;
        font-size: 15px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
        margin: 8px 0px 2px 0px;
    }
`

const HBLThirdWrap = styled.div`
    width: 80%;
    display: flex;
    justify-content: center;
    gap: 10px;
    margin: 2rem auto 0rem auto;
    max-width: 1400px;

    & span {
        width: 70%;
        text-align: center;
    }

    & img {
        width: 70%;
    }
`

const HBLFourthWrap = styled.div`
    width: 100%;
    height: auto;
    margin-top: 2rem;
    max-width: 1400px;
    margin: 2rem auto 0 auto;
    

    & h3 {
        color: #FBBF24;
        text-align: center;
        font-size: 30px;
        font-style: normal;
        font-weight: 700;
        line-height: 34.428px; /* 100% */
        margin: 1rem 0;
    }
`

const HBLFourthWrapTermsSec = styled.div`
    border-radius: 22.952px;
    border: 0px solid #E5E7EB;
    background: #FBBF24;
    width: 75%;
    margin: auto;
    padding: 1rem;


    & ul {
        padding-left: 30px;
        list-style-type: "🔹";

        & li {
            color: #010406;
            font-size: 15.301px;
            font-style: normal;
            font-weight: 400;
            line-height: 170%; /* 100% */
            margin: 4px 0px;
        }
    }
`

const MaxWrap = styled.div`
    width: auto;
    max-width: 1400px;
    margin: 0 auto;
    display: flex;
    justify-content: center;
    gap: 10px;
`