import gql from 'graphql-tag';

const sportInfo: any = gql`
	fragment sportInfo on SportInfo {
		eventId
		name
		inplay
		marketName
		marketStartTime
		matchId
	}
`;

const competitionInfo: any = gql`
	fragment competitionInfo on CompetitionInfo {
		name
		count
		competitionId
		events {
			...sportInfo
		}
	}
	${sportInfo}
`;

const subCategoryInfo: any = gql`
	fragment subCategoryInfo on SubCategoryInfo {
		competitions {
			...competitionInfo
		}
		events {
			...sportInfo
		}
	}
	${competitionInfo}
	${sportInfo}
`;

const sportsInfo: any = gql`
	fragment sportsInfo on SportsInfo {
		name
		count
		categoryId
		subCategory {
			...subCategoryInfo
		}
	}
	${subCategoryInfo}
`;

export const GET_MENU: any = gql`
	query getMenu {
		menu {
			category {
				...sportsInfo
			}
		}
	}
	${sportsInfo}
`;
