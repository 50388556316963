import React, { useState } from 'react'
import { SecondCol, FirstCol, Header, MainInfoWrapper, BodyWrapper } from 'src/components/udb/commonStyle'
import Intl from '../../../components/common/Intl';
import styled from 'styled-components';
import DatePicker from "react-datepicker";
import DatePickerIcon from 'src/images/calendar.png';
import { GET_PROFIT_LOSS } from 'src/graphql/member';
import { useQuery } from '@apollo/client';
import { EEventTypeId, EGameTypeId, ETransactionType } from 'src/util/utilModel';
import util from 'src/util/util';
import { IOrder, ITransaction } from 'src/graphql/schema';
import groupBy from 'lodash/groupBy';
import ProfitLossTable from 'src/components/member/ProfitLossTable';
import GameProfitLossTable from 'src/components/member/GameProfitLossTable';
import EventTypeConfig from '../../../config/eventTypeConfig';
import GameTypeConfig from '../../../config/gameTypeConfig';
import TypePicker from '../../../components/member/TypePicker';
import DatePickerUdb from 'src/components/udb/DatePickerUdb';
import UDBHeader from 'src/components/UDBHeader';
import { BodyWrapperInherited } from 'src/pages/udb/betdetails/MyBets';
import SEOContents from 'src/components/SEOContents';

function ProfitLossDetails(props) {
  const sportIds = EventTypeConfig(0).categoryIds.concat([EEventTypeId.horseracing, EEventTypeId.greyhoundracing]);
  const gameIds = GameTypeConfig(EGameTypeId.all).gameIds;
  const [startAt, setStartAt] = useState(new Date(new Date().setHours(0, 0, 0)));
  const [endAt, setEndAt] = useState(new Date(new Date().setHours(23, 59, 59)));
  const [selectedId, setSelectedId] = useState(EventTypeConfig(0).categoryIds[0]);

  var date = new Date();
  const minDate = date.setDate((new Date()).getDate() - 90);

  const handleTypeChange = (id: number) => {
    [...sportIds, ...gameIds].indexOf(id) >= 0 &&
      setSelectedId(id)
  };

  const trying = (sportIds, gameIds, sportTransactionGroup, gameTransactionGroup) => {
    //As per new requirement we need to remove volleyball do adding filter
    const sportInfoList = sportIds.filter(id=>id!==12).map(id => {
      const eventConfig = EventTypeConfig(id);
      let distinctSettleNumber: string[] = [];
      let profitLoss = 0;
      util.getValue(sportTransactionGroup, [`${id}`], []).forEach(
        (t: ITransaction) => {
          const settleNumber: string = util.getValue(
            t,
            ['order', 'settleNumber'],
            '',
          );
          if (
            !distinctSettleNumber.some(
              number => number === settleNumber,
            )
          ) {
            distinctSettleNumber = [
              ...distinctSettleNumber,
              settleNumber,
            ];
            profitLoss += util.numMultiply(
              util.getValue(t, ['order', 'exchangeRate'], 0),
              t.winloss,
            );
          }
        },
      );
      return {
        name: eventConfig.displayName,
        id,
        profitLoss,
      };
    });

    const gameInfoList: any = gameIds.map((id: string) => {
      const gameConfig = GameTypeConfig(id);
      const profitLoss =
        gameTransactionGroup[id] &&
        gameTransactionGroup[id].reduce(
          (acc, current) =>
            acc +
            util.numMultiply(
              util.getValue(current, ['exchangeRate'], 0),
              current.winloss,
            ),
          0,
        );
      return {
        name: gameConfig.displayName,
        id,
        profitLoss,
      };
    });

    return (
      <TypePicker
        onTypeChange={handleTypeChange}
        groupedInfoList={[sportInfoList, gameInfoList]}
        isShowCredit={true}
        diffColor="DARKER"
      />
    )


  }

  const { data, loading, error } = useQuery(GET_PROFIT_LOSS, {
    variables: {
      input: { period: { start: startAt.valueOf(), end: endAt.valueOf() } },
    }
  });

  const transactions: ITransaction[] = util.getValue(data, ['exchange', 'profitLoss'], []);
  const sportTransactions = transactions.filter(t => t.type === ETransactionType.WS);
  const gameTransactions = Array.from(
    new Set([...transactions].filter(x => !new Set(sportTransactions).has(x))),
  );

  const sportTransactionGroup = groupBy(sportTransactions, t => {
    const order = t.order as IOrder;
    return order.categoryId;
  });

  const gameTransactionGroup = groupBy(gameTransactions, (t: any) => t.platform);

  const content =
    selectedId === 100 || sportIds.includes(selectedId) ? (
      <ProfitLossTable
        loading={loading}
        error={error}
        transactions={sportTransactionGroup[selectedId]}
      />
    ) : (
      <GameProfitLossTable
        loading={loading}
        error={error}
        transferInfoList={gameTransactionGroup[selectedId]}
      />
    );
  return (
    <MainInfoWrapper>
      <SEOContents pageName="memberP&L" />
      <Header>
        <FirstCol>
          <UDBHeader title="DASHBOARD@PROFITLOSS" />
        </FirstCol>
        <SecondCol>
          <DatePickerUdb setStartAt={setStartAt} setEndAt={setEndAt} />
        </SecondCol>
      </Header>
      {/* <BodyWrapperInherited> */}
      <Container>
        <FilterWrapper>
          {
            trying(sportIds, gameIds, sportTransactionGroup, gameTransactionGroup)
          }
        </FilterWrapper>
        {content}
      </Container>
      {/* </BodyWrapperInherited> */}
    </MainInfoWrapper>
  )
}

export default ProfitLossDetails

export const DateSelector = styled.div`
  position:relative;
  top:110px;
  left: 25px;
  
  font-weight: bold;
  display:inline-flex;
`;

const DatePickerWrapper = styled.div`
  width: 50%;
  display: flex;
  flex-direction: row;
  align-items: centre;
  justify-content: flex-start;
  position: absolute;
  right: 0;
  top: 12px;
`;

const BlockPick = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  background: #E9F1F8;
  border-radius: 5px;
  padding: 0px 10px;
  margin-right: 10px;
  text-transform: capitalize;
  color: #203974;
  font-size: 12px;

  input {
    border: none;
    background: none;
    display: flex;
    height: 47px;
    text-align: center;
  }

  .react-datepicker__day--outside-month {
  color: transparent !important;
  pointer-events: none;
}
`;

const CalenderIcon = styled.div`
  width: 24px;
  height: 24px;
  display: flex;
  flex-direction: row;
  align-items: center;
  background-image: url(${DatePickerIcon});
  background-size: auto;
  background-repeat: no-repeat;
  margin-right: 10px;
`;

const Container = styled.div`
	width: 100%;
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    background: #f1f4f8;
    border-radius: 5px;
    height: 100%;
`;

const FilterWrapper = styled.div`
  width: 24%;
  display: flex;
  flex-direction: column;
  height :100%;
  background: rgba(255, 255, 255, 0.41);
  margin-bottom: 2px;
  margin-right: 10px;
`;