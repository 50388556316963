import React from 'react';
import styled, { css } from 'styled-components';

// Components
import Icon, { IconWrapper } from '../common/PNGIcon';
import { SectionHeading, SectionWrapper } from '../eu-content/order/Order';
import Switch from '../eu-content/Switch';
import Intl from '../common/Intl';
import { IOrderListContext, withOrderListContext } from '../../store/OrderListContext';

// util
import cookieUtil from '../../util/cookieUtil';
import { getGTMId } from '../../util/gtmUtil';
import { ECookieName } from '../../util/utilModel';
const { theme, ifProp } = require('styled-tools');


interface IOneClickState {
	isEditing: boolean;
	stakes: number[];
	selectedStakeIndex: number;
	isLoading: boolean;
	errorMessage: string;
	invalidStakeIndex: number;
}

class OneClick extends React.PureComponent<IOrderListContext, IOneClickState> {
	constructor(props) {
		super(props)
		this.state = {
			isEditing: false,
			stakes: (cookieUtil.get(ECookieName.COOKIE_ONECLICKBET_STAKE) &&
				cookieUtil
					.get(ECookieName.COOKIE_ONECLICKBET_STAKE)
					.split(',')
					.map(a => Number(a))) || [50, 200, 300],
			selectedStakeIndex: 0,
			isLoading: false,
			errorMessage: '',
			invalidStakeIndex: -1,
		};
	}
	
	activateEditState = () => {
		this.setState(prevState => {
			return {
				isEditing: true,
			};
		});
	};

	saveEditState = () => {
		const { stakes, selectedStakeIndex } = this.state;
		const { changeOneClickStake } = this.props.orderListContext;
		let invalidStakeIndex = -1;

		if (
			stakes.some((stake, i) => {
				invalidStakeIndex = i;
				return stake <= 0;
			})
		) {
			this.setState(
				{
					errorMessage: 'Stake must be greater than 0!',
					invalidStakeIndex,
				},
				() => {
					// 五秒後清除錯誤訊息
					setTimeout(() => {
						this.setState({ errorMessage: '', invalidStakeIndex: -1 });
					}, 5000);
				},
			);
			return;
		}

		cookieUtil.set(ECookieName.COOKIE_ONECLICKBET_STAKE, stakes.join(','));
		changeOneClickStake(Number(stakes[selectedStakeIndex]));
		this.setState(prevState => {
			return {
				isEditing: false,
			};
		});
	};

	changeSelectedStackIndex = (index: number) => (): void => {
		const { changeOneClickStake } = this.props.orderListContext;
		this.setState(prevState => ({
			selectedStakeIndex: index,
		}));
		cookieUtil.set(ECookieName.COOKIE_SELECTED_ONCLICKBET, index.toString(), 30);
		changeOneClickStake(this.state.stakes[index]);
	};

	changeStack = (index: number) => (e: any): void => {
		const {
			target: { value },
		} = e;
		this.setState(prevState => {
			const newStakes = [...prevState.stakes];
			const newStakeValue = Number(value);
			!isNaN(newStakeValue) && (newStakes[index] = newStakeValue);
			return {
				stakes: newStakes,
				errorMessage: '',
				invalidStakeIndex: -1,
			};
		});
	};

	componentDidMount() {
		const { changeOneClickStake } = this.props.orderListContext;
		changeOneClickStake(this.state.stakes[Number(cookieUtil.get(ECookieName.COOKIE_SELECTED_ONCLICKBET))]);
		let selectedIndex = Number(cookieUtil.get(ECookieName.COOKIE_SELECTED_ONCLICKBET));
		this.setState({selectedStakeIndex: selectedIndex})

	}
	render(): JSX.Element {
		const {
			isOneClickBetActive,
			toggleOneClickBet,
			confirmTurningOnOneClick,
			cancelTurningOnOneClick,
		} = this.props.orderListContext;
		const { errorMessage, invalidStakeIndex } = this.state;
		return (
			<SectionWrapperOneclip>
				<SectionHeading>
					<Switch
						currentActiveState={isOneClickBetActive}
						handleClick={toggleOneClickBet}
						id={getGTMId(`one_click_switch`)}
					/>
					<span>
						<Intl langKey="ONE_CLICK@TITLE" />
						{/* One Click Bet */}
					</span>
				</SectionHeading>
				<ConfirmWrapper isActive={isOneClickBetActive === null}>
					<ConfirmMessageWrapper>
						<Icon name="alert-red" /> Turning on One-Click will clear all existing betslip
					</ConfirmMessageWrapper>
					<ConfirmButtonWrapper>
						<ConfirmButton onClick={cancelTurningOnOneClick}>Cancel</ConfirmButton>
						<ConfirmButton onClick={confirmTurningOnOneClick}>Confirm</ConfirmButton>
					</ConfirmButtonWrapper>
				</ConfirmWrapper>

				{isOneClickBetActive && errorMessage && (
					<ErrorMessage>
						<Icon name="circle-x-color" />
						{errorMessage}
					</ErrorMessage>
				)}

				{isOneClickBetActive && (
					<>
						<StackWrapper isEditing={this.state.isEditing}>
							<StackButtonWrapper>
								{this.state.stakes.map((stake, i: number) => {
									return (
										<StackButton
											key={i}
											onClick={this.changeSelectedStackIndex(i)}
											isSelected={this.state.selectedStakeIndex === i}
										>
											{stake}
										</StackButton>
									);
								})}
							</StackButtonWrapper>
							<EditButton onClick={this.activateEditState} id={getGTMId('one_click_edit')}>
								<Intl langKey="ONE_CLICK@EDIT" />
								<EditIconWrapper>
									<Icon name="feather-white" />
								</EditIconWrapper>
								{/* Edit */}
							</EditButton>
						</StackWrapper>
						<StackWrapper style={{ display: this.state.isEditing ? '' : 'none' }}>
							<StackButtonWrapper>
								{this.state.stakes.map((stack, i: number) => {
									return (
										<InputStack
											key={i}
											value={stack}
											onChange={this.changeStack(i)}
											isInvalid={i === invalidStakeIndex}
										/>
									);
								})}
							</StackButtonWrapper>
							<SaveButton id={getGTMId('one_click_save')} onClick={this.saveEditState}>
								<Intl langKey="ONE_CLICK@SAVE" />
								<Icon name="check-red" />
								{/* Save */}
							</SaveButton>
						</StackWrapper>
					</>
				)}
			</SectionWrapperOneclip>
		);
	}
}
export default withOrderListContext(OneClick, [
	'changeOneClickStake',
	'isOneClickBetActive',
	'toggleOneClickBet',
	'confirmTurningOnOneClick',
	'cancelTurningOnOneClick',
	'oneClickStake'
]);

// Styled components
const SectionWrapperOneclip = styled.div `
	background: transparent;
	width: 100%;

	${SectionHeading} {
		width: 70%;
		display: flex;
		align-items: center;
		justify-content: flex-start;
		padding: 5px 0px !important;
		background: transparent;
		color: ${theme('palette.secondary-background-color-new')};
		font-size: 11px;
		text-transform: capitalize;
		// margin-left: 13px;
	}
`;

const ConfirmButton = styled.button`
	cursor: pointer;
	display: flex;
	justify-content: center;
	align-items: center;
	background-color: transparent;
	font-size: 13px;
	width: 50%;
	border: 0;
	padding: 0.5em 0.25em;

	&:focus {
		outline: 0;
	}

	&:hover {
		color: ${theme('palette.primary-background')};
	}

	&:not(:last-of-type) {
		border-right: 1px solid rgba(128, 128, 128, 0.25);
	}
`;

const ConfirmButtonWrapper = styled.div`
	display: flex;
	border-top: 1px solid rgba(128, 128, 128, 0.25);
`;

const ConfirmMessageWrapper = styled.div`
	font-size: 12px;
	display: flex;
	align-items: flex-start;
	padding: 1em 0.5em;

	${IconWrapper} {
		margin-top: 0.1em;
		margin-right: 0.5em;
	}
`;

const ConfirmWrapper: any = styled.div`
	display: ${ifProp('isActive', 'block', 'none')};
	background-color: ${theme('palette.panel-background')};
	border-radius: 2px;
	border: 1px solid rgba(128, 128, 128, 0.25);
`;

const ErrorMessage = styled.div`
	display: flex;
	align-items: center;
	font-size: 12px;
	color: ${theme('palette.error-text')};
	margin-bottom: 0.5em;

	${IconWrapper} {
		margin-right: 0.5em;
	}
`;

const SaveButton = styled.button`
	cursor: pointer;
	display: flex;
	align-items: center;
	font-style: italic;
	font-size: 10px;
	// background-color: transparent;
	border: 0;
	flex-shrink: 0;
	// color: ${theme('palette.body-foreground')};
	color: #fff;
	background: #03BC58;
	border-radius: 5px;
	width: 25%;
    padding: 0.5em;
    justify-content: center;

	&:focus {
		outline: 0;
	}

	${IconWrapper} {
		font-size: 14px;
		margin-left: 0.5em;
	}
`;

const InputStack: any = styled.input.attrs(props => ({
	type: 'text',
}))`
	// width: 33%; /* nullify default width from user agent */
	width: 25%;
	display: flex;
	justify-content: center;
	align-items: center;
	cursor: pointer;
	// flex-basis: 100%;
	// flex-grow: 1;
	margin-right: 0.5em;
	border-radius: 99em;
	padding: 0.5em;
	border: 0;
	// box-shadow: inset 0 0 3px rgba(0, 0, 0, 0.25);
	caret-color: ${theme('palette.primary-background')};
	color: ${theme('palette.text-dark')};
	text-align: center;
	min-width: 0;
	background: rgba(235, 235, 235, 0.97);
	border-radius: 5px;
	box-shadow: inset 0px 4px 4px rgba(0, 0, 0, 0.25);

	${ifProp(
		'isInvalid',
		css`
			border: 1px solid ${theme('palette.error-text')};
		`,
	)};

	&:focus {
		outline: 0;
	}
`;

// Wrapping feather icon
const EditIconWrapper = styled.div`
	display: flex;
	justify-content: center;
	align-items: center;
	width: 2.25em;
	height: 2.25em;
	border-radius: 50%;
	/* background-color: ${theme('palette.primary-background')}; */
	background: ${theme('palette.button-edit-stake')};
`;

const EditButton = styled.button`
	cursor: pointer;
	display: flex;
	align-items: center;
	justify-content: center;
	font-style: normal;
	font-size: 10px;
	// background-color: transparent;
	border: 0;
	flex-shrink: 0;
	color: ${theme('palette.body-foreground')};
	background: ${theme('palette.button-edit-stake')};
    border-radius: 5px;

	&:focus {
		outline: 0;
	}

	// ${EditIconWrapper} {
	// 	margin-left: 0.5em;
	// }
`;

const StackButton: any = styled.button`
	width: 25%;
	display: flex;
	justify-content: center;
	align-items: center;
	margin-right: 0.3em;
	padding: 0.5em;
	font-size: 13px;
	border-radius: 5px;
	border: 0;
	cursor: pointer;
	${ifProp(
		'isSelected',
		css`
			border: 0;
			background: ${theme('palette.StackButtonActiveBg')};
			color: ${theme('palette.StackButtonActiveColor')};
		`,
		css`
			background: ${theme('palette.StackButtonBg')};
			color: ${theme('palette.StackButtonColor')};
		`,
	)};

	&:focus {
		outline: none;
	}
`;

// Wrapping stack buttons
const StackButtonWrapper = styled.div`
	display: flex;
	align-items: center;
	flex-grow: 1;
`;

const StackWrapper: any = styled.div`
	display: ${ifProp('isEditing', 'none', 'flex')};
	align-items: center;
	justify-content: space-between;
	background: ${theme('palette.StackWrapperBg')};
	box-shadow: ${theme('palette.StackWrapperBoxShadow')};
    border-radius: 5px;
    padding: 10px;
    padding-top: 5px;
`;















// import React, { useContext } from "react";
// import { Switch } from "react-router";
// import  styled from "styled-components";
// import BetSlipInner from "./betslip-inner";
// import Login from "../login-signup/login";
// import MyBetInner from "../BetSlip/MyBet-innerpage";
// import MyBetEventDate from "../BetSlip/MyBetsEventDate";

// // images
// import BetslipBackground from "../../images/Rectangle 211.png";
// import Mybet from "../../images/Rectangle 45.png";
// import BetslipImage from "../../images/Betslip empty image.png";
// import WhatsappIcon from "../../images/whatsapp 1.png";
// import Shutterstock from "../../images/shutterstock.jpg";
// import Xicon from "../../images/x.png";
// import Minimize from "../../images/minimize-2.png";
// import Vol from "../../images/volume-2.png";
// import Vector from "../../images/Vector.png";
// import BBBlivegroup from "../../images/Group 8409.png";
// import BufferIcon from "../../images/loading 1.png";
// import util from "src/util/util";
// import { GlobalApiContext } from '../../store/GlobalAppContext'



// const BetSlip =()=>{
//   const  {isAuth, isIframe} = useContext(GlobalApiContext)
//     return(
//          <MainWrapper>


//              {/* <Wrapper1>
//                  <div className="betslip">BET SLIP</div>
//                  <div className="mybet">MY BETS</div>
                 
//              </Wrapper1> */}

//              <Wrapper2>
//                <div className="topswitches">
//                  <Switch1>

//                  <label className="switch">
//                  <input type="checkbox"/>
//                  <span className="slider round"></span>
//                  </label>
//                  <h5>1-click bet</h5>

//                  </Switch1>

//                  <Switch1>

//                      <label className="switch">
//                      <input type="checkbox"/>
//                      <span className="slider round"></span>
//                      </label>
//                      <h5>Accept any odds from bm</h5>


//                  </Switch1>

//                  </div>


            
//              </Wrapper2>

//              <Wrapper4>
//                <h5>click on any odds to place bet</h5>
//              </Wrapper4>

//              <Wrapper5>
//                  <img src={WhatsappIcon}/>
//                  <p>for services connect us at</p>
//              </Wrapper5>

//              {/* {isAuth && ( */}
              
//                 {/* <LiveTvWrapper>
//                     <TVIFrame
//                         src={`https://livingstreamvdo.com?${util.liveStreamEncodeURl()}`}
//                         width={'100%'}
//                         height={'100%'}
//                         scrolling={'no'}
//                     />
//                 </LiveTvWrapper> */}
//             {/* )} */}

//              {/* <Wrapper6>
//                  <Sub1>
//                     <img src={Shutterstock}/>
//                  </Sub1>
//                  <Sub2>
//                    <img src={Vol}/>
//                    <img src={Minimize}/>
//                    <img src={Xicon}/>
//                 </Sub2>
//                 <Sub3>
//                     <h5>Pakistan vs WestIndies</h5>
//                     <img src={Vector}/>
//                 </Sub3>

//                 <Sub4>
//                     <div>

//                     <img src={BBBlivegroup}/>
//                     <img src={BufferIcon}/>
//                     <h5>loading...</h5>

//                     </div>
                    
                 
//                 </Sub4>
//              </Wrapper6> */}
             
            


//          </MainWrapper>

//     )
// };
// export default BetSlip;

// const MainWrapper = styled.div`
  
// width:100%;
// border: 1px solid #EBEBEB; 
// background: #fff;
// `;

// const Wrapper1 =styled.div`
//  display:flex;
//  width: 100%;
//  background: #fff;
//  height: 40px;
//  position: relative;
//  top: 5px;
  
//  .betslip{
//      padding: 9px 0px 0px 30px;
//      background-image: url(${BetslipBackground});
//      background-repeat:no-repeat;
//      color:#fff;
//      width:35%;
//      cursor:pointer;

     
    
//  }

//  .mybet{
//      padding:8px 0px 0px 25px;
//      background-image: url(${Mybet});
//      background-repeat:no-repeat;
//      color:#214076;
//      margin-left:10px;
//      width:38%;
//      cursor:pointer;
//  }
// `;
// const Wrapper2 =styled.div`
//     padding: 20px 10px;
//     background: #F8F8F8;

//   .topswitches{
//     display: flex;
//     justify-content: space-between;
//     align-items: center;
//   }
// .switch {
//     position: relative;
//     display: inline-block;
//     width: 28px;
//     height: 18px;
//   }
  
//   /* Hide default HTML checkbox */
//   .switch input {
//     opacity: 0;
//     width: 0;
//     height: 0;
//   }
  
//   /* The slider */
//   .slider {
//     position: absolute;
//     cursor: pointer;
//     top: 0;
//     left: 0;
//     right: 0;
//     bottom: 0;
//     background-color: grey;
//     -webkit-transition: .4s;
//     transition: .4s;
//   }
  
//   .slider:before {
//     position: absolute;
//     content: "";
//     height: 10px;
//     width: 9px;
//     left: 3px;
//     bottom: 4px;
//     background-color: white;
//     -webkit-transition: .4s;
//     transition: .4s;
//   }
  
//   input:checked + .slider {
//     background-color: #DEC59D;
//   }
  
//   input:focus + .slider {
//     box-shadow: 0 0 1px #DEC59D;
//   }
  
//   input:checked + .slider:before {
//     -webkit-transform: translateX(15px);
//     -ms-transform: translateX(15px);
//     transform: translateX(15px);
//   }
  
//   /* Rounded sliders */
//   .slider.round {
//     border-radius: 34px;
//   }
  
//   .slider.round:before {
//     border-radius: 50%;
//   }`;
// const Wrapper3 =styled.div`
//   display: flex;
//   background:#EBEBEB;
//   padding: 15px;

// h5{
//     width: 50%;
//     align-items: center;
//     display: flex;
//     justify-content: center;
//     text-transform: uppercase;
//     text-align: left;
//     font-weight: bold;
//     letter-spacing: 0.1em;
//     text-transform: uppercase;
//     color: rgba(138, 158, 197, 0.97);
//     font-size: 11px;
//     
// }
// img{
//     width: 50%;
//     align-items: center;
//     display: flex;
//     justify-content: center;
//     padding: 40px 0px 35px 0px;
// }
// `;
// const Wrapper4 =styled.div`
//   display:flex;
//   align-items: center;
//   width:100%;
//   justify-content: center;


//   h5{
//     padding: 10px;
//     font-weight: 500;
//     text-transform: uppercase;
//     letter-spacing: 1px;
//     word-spacing: 2px;
//     color: #fff;
//     border-radius: 0;
//     font-size: 11px;
//     background: linear-gradient(180deg,#DEC59D 0%,#D7AE6D 100%);
//     border: 0.15px solid #F8F8F8;
//     margin: 0;
//     width: 100%;
//     text-align: center;
//   }
    

// `;
// const Wrapper5 =styled.div`
//   display:flex;
//   justify-content: center;
//   align-items: center;
//   background: linear-gradient(180.01deg, #E5E5E5 0%, #FFFFFF 100%);
//   padding: 10px 5px;
  
//   img{
//     height:30px;
//   }

//   p {
//     font-weight: 200;
//     text-transform: uppercase;
//     letter-spacing: 1px;
//     word-spacing: 2px;
//     cursor:pointer;
//     text-decoration: underline;
//     color: #214076;
//     padding-left: 10px;
//     
//   }
// `;
// const Wrapper6 = styled.div`

// position: relative;

// `;

// const Sub1 = styled.div`
//   width: 100%;

//   img {
//     width: 100%;
//   }

// `;
// const Sub2 = styled.div`

//     position: absolute;
//     top: 0;
//     right: 0;


// img{
//     padding: 5px 5px 5px 10px;
// }
// `;

// const Sub3 = styled.div`
// height:40px;
// position: absolute;
// display: flex;
// align-items: center;
// padding: 0px 0px 0px 15px;
// top: 0;
// left: 0;


// h5{
//     color: #fff;
//     font-size: 11px;
//     font-weight: 400;
// }

// img{
//    padding-left: 10px;
// }
// `;

// const Sub4 = styled.div`

//   position: absolute;
//     color: #fff;
//     top: 30%;
//     left: 0;
//     right: 0;
//     bottom: 0;

// div{
//     align-items: center;
//     justify-content: center;
//     display: flex;
//     flex-direction: column;
// }


// h5{
//     text-transform: uppercase;
//     font-style: italic;

// }
// img{
//     padding-bottom:5px;
// }
// `;
// const Switch1 = styled.div`
// display:flex;
// justify-content: space-between;

// h5{
//     margin:0px;
//     padding:0px;
//     position: relative;
//     // right: 30px;
//     font-weight: 100;
//     text-transform: uppercase;
// }
// `;
// const Switch2 = styled.div``;
// const Switch3 = styled.div`
//     display:flex;
//     position: relative;
//     top: 15px;

// h5{
//     margin:0px;
//     padding:0px;
//     position: relative;
//     font-weight: 100;
//     text-transform: uppercase;
//     padding: 1px 0px 0px 8px;
// }
// `;

// const LiveTvWrapper = styled.div`
//   display:flex;
//   justify-content: center;
//   align-items: center;
//   background:#E5E5E5;
//   background: linear-gradient(180.01deg, #E5E5E5 0%, #FFFFFF 100%);
//   padding: 20px 10px;
//   height: 300px;
// `;

// const TVIFrame = styled.iframe`
// 	transform: translateY(59px);
// 	background: #fff;
// 	border: none;
// 	border-bottom: 2px solid black;
// 	z-index: 2;
//   position: relative;
//   bottom: 50px;
// `;