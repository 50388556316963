import React, { Component } from "react";
import styled, { withTheme } from "styled-components";
import { AnimatePresence, motion } from "framer-motion";
// Component
import Header from "src/components/header";
import withSystem from "./HOC/withSystem";
// import CustomHead from "./CustomHead";
import CasinoBg from "./images/iexch_casino_bgone.jpg";
import {
  AppContextProvider,
  withAppContext,
  // AppContext,
  // IAppContextStore,
  IAssociations,
  IbfInfo,
  getBrandId,
} from "./store/initAppContext";

import withUserInfo from "./HOC/withUserInfo";
import withApollo from "./HOC/withApollo";
import NotificationCenter from "./components/Notification";
import {ETimeZone } from "./util/utilModel";
import config from "./config/config";
import { theme } from "styled-tools";
import PageRouter from './router/index';
import 'src/fonts/fonts.scss';
import GlobalLoaderImg from './components/GlobalLoaderImg';
import SEOContents from "./components/SEOContents";
import FNQSchema from "./components/fnq_schema";

interface IProps {
  Component: any;
  ctx: any;
  pageProps: any;
  i18n: any;
  query: any;
  showkey: string;
  isStartFromServer: boolean;
  perf: string;
  lang: string;
  config: any;
  pageConfig: any;
  domain: string;
  pathname: string;
  username: string;
  memberId: number;
  view: string;
  exchangeRate: number;
  defaultTimeZone: ETimeZone;
  type: string;
  iframeToken: string;
  associations: IAssociations[];
  persona: IAssociations;
  personaString: string;
  isSubAccount: string;
  bfInfo: IbfInfo[];
  ip: string;
  betBufferSec: number;
  token: string;
  isMobileWidth: boolean;
  brandId: number;
  theme: any;
}

class App extends Component<IProps, any> {
  constructor(props: any) {
    super(props);
  } 

  render() {
    let _props = this.props;  
    const homePage = window.location.pathname === '/';

    return (
      <AppContextProvider initData={_props}>
       {homePage && <SEOContents pageName={"default"}/>}
       <FNQSchema brandId={getBrandId()}/>
       {/* Notify - Don't Remove NotificationCenter */}
        <NotificationCenter
          alignment={{
            horizontal: "right",
            vertical: "bottom",
            // vertical: this.state.isMobileWidth ? 'bottom' : 'bottom',   如果以後有需要區分通知跳的位置
          }}
          isMobile={false}
        />
        <MainWrapper>
            <Header />
            <AnimatePresence>
              <motion.div
                initial={{ opacity: 0 }}
                animate={{ opacity: 1 }}
                exit={{ opacity: 0 }}
              >
                <BodyWrapper>
                  <PageRouter />
                </BodyWrapper>
              </motion.div>
            </AnimatePresence>
          {/* </Router> */}
        </MainWrapper>
       {/* DO NOT REMOVE GlobalLoaderImg Component as it is being used for global loader*/}
        <div style={{display:'none'}}> <GlobalLoaderImg /></div>
       
      </AppContextProvider>
    );
  }
}

export default withTheme(withApollo(withUserInfo(withAppContext(withSystem(App)))));

const MainWrapper = styled.div`
  width: 100%;
  // background-image: url(${config.imageS3Host}${CasinoBg});   // needs changes here
  /* background-image: url(${config.imageS3Host}/static/snk/${theme('image.BackgroundImage')}); */
  /* background-repeat: no-repeat;
  background-size: cover; */
  font-family: 'Inter';
  background: ${theme('palette.mainWrapperBgColor')};
`;

const BodyWrapper = styled.div`
  /* max-width: 1466px; */
  margin: 30px auto 0px;
  width: 100%;
`;
