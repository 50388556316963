import { darken, lighten } from 'polished';
import styled, { css } from 'styled-components';
import { ifProp, switchProp, theme, withProp } from 'styled-tools';
import { diffColor } from '../../../../util/colorUtil';

// Components
import { IconWrapper } from '../../../common/PNGIcon';
import { ESlipState } from './OrderSlipContainer';
import media from 'src/util/mediaUtil';

// Styles
import { buttonStyle, inputStyle, panelStyle, shadowMD, shadowSM } from '../../../common/commonCSSStyles';

// Util
import { isMemberType } from '../../../../util/styleUtil';

// Styled components
export const IconButtonWrapper = styled.button`
	background-color: transparent;
	border: 0;
	display: flex;
	justify-content: center;
	align-items: center;
	opacity: 0.75;
	cursor: pointer;
	padding: 0;
	font-size: 12px;
	${IconWrapper} {
		font-size: 2em;
	}

	* {
		pointer-events: none;
	}

	&:focus {
		outline: none;
	}

	&:hover {
		opacity: 1;
	}
`;

export const SlipEventName: any = styled.span`
	font-size: 12px;
	color: #35495E;
	display:inline-block;
    max-width:180px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;

	${ifProp(
	'hasPath',
	css`
			cursor: pointer;

			&:hover {
				text-decoration: underline;
			}
		`,
)};

	// ${media.xxl`
	// 	max-width: 140px;
	// `};

	@media only screen and (max-width: 1400px) {
		max-width: 140px;
	}
`;

export const SlipSelectionName = styled.span`
	font-size: 13px;
	color: #203974;
	text-transform: capitalize;
	font-weight: bold;
    max-width: 180px;
    white-space: nowrap;
    overflow: hidden;
	text-overflow: ellipsis;
	display: inline-block;

	// ${media.xxl`
	// 	max-width: 140px;
	// `};

	@media only screen and (max-width: 1400px) {
		max-width: 140px;
	}
`;

export const SlipTitleWrapper = styled.div`
	display: flex;
	flex-direction: column;
`;

// Wrapping Title (eventName, selection) and trash icon
export const SlipHeadingWrapper: any = styled.div`
	display: flex;
	align-items: center;
	// padding: 0.5em 1em 0.25em;
	justify-content: space-between;
	padding: 0 8px;
	background: #A7D8FD;
	
	height: 40px;

	${(props: any) => {
		switch (props.type) {
			case 'BACK':
				return css`
					background: #A7D8FD;
				`;
			case 'LAY':
				return css`
					background: #FFD6D6;

				`;
			default:
				return '';
		}
	}};

	${switchProp('type', {
		BACK: css`
			${isMemberType(
			'CLIENT',
			css`
					background-color: #A7D8FD;
				`,
		)};
		`,
		LAY: css`
			${isMemberType(
			'CLIENT',
			css`
					background-color: #ffd6d6;
				`,
		)};
		`,
	})};

	${SlipTitleWrapper} {
		color: ${switchProp('type', {
		// BACK: withProp('theme.palette.back-text-light', darken(0.18)),
		BACK: "#000000",
		LAY: theme('palette.lay-text-light'),
	})};
	}

	${IconButtonWrapper} {
		margin-right: 1em;

		&:first-of-type {
			margin-left: auto;
		}

		&:last-of-type {
			margin-right: 0;
		}
	}
`;

export const ProfitText: any = styled.span`
	font-size: 11px;
	color: #219642;
	padding: 0.25em;
	
	display: flex;
	flex-direction: column;
`;

export const InputField: any = styled.input.attrs(props => ({
	type: 'text',
}))`
	${inputStyle};
	text-align: center;
	width: 6.6em;
	flex-grow: 1;
`;

export const FieldLabel = styled.span`
	font-size: 13px;
	// margin-bottom: 0.5em;
	color: #35495E;
	
	/* padding-bottom: 3px; */
`;

export const LiveOdds: any = styled.span`
	font-size: 11px;
	
`;

// For odds label, wrapping label and live odds
export const OddsLabelWrapper = styled.div`
	display: flex;
	justify-content: space-between;
	font-size: 13px;
	color: #35495E;
	
	padding-bottom: 3px;
`;

// Wrapping label, input field
export const FieldWrapper = styled.div`
	display: flex;
	flex-direction: column;
	margin-right: 0.5em;
`;

// Wrapping fields
export const FieldGroupWrapper = styled.div`
	display: flex;
`;

// Wrapping field group, check button
export const InputSection: any = styled.div`
	display: flex;
	justify-content: space-between;
	align-items: center;
	padding: 0.25em 1em 0.5em;
	padding-top: 10px;
    padding-bottom: 10px;

	${ifProp(
	'isDisabled',
	css`
			pointer-events: none;
			opacity: 0.5;
		`,
)};
`;

export const StakeButton = styled.button`
	font-size: 12px;
	padding: 0.5em;
	text-align: center;
	// background-color: transparent;
	background: ${theme('palette.BetStakeBtnBG')};
	border: 0.25px solid rgba(138, 158, 197, 0.97);
	border-radius: 2px;
	// border-radius: 99em;
	// margin-right: 1em;
	width: 100%;
	display: flex;
    align-items: center;
    justify-content: center;

	cursor: pointer;

	&:focus {
		outline: 0;
	}
`;

export const StakeButtonWrapper = styled.div`
	// width: 25%;
	width: 18%;
	padding: 0.15em;
	padding-right: 5px;
`;

export const StakeSection = styled.div`
	display: flex;
	flex-wrap: wrap;
	margin: 0.25em 0;
	align-items: center;
    justify-content: center;
`;

export const ConfirmSection = styled.div`
	// padding: 0 0.5em;
`;

export const SlipMask = styled.div`
	position: absolute;
	top: 0;
	right: 0;
	bottom: 0;
	left: 0;
	z-index: 1;
`;

export const SlipWrapper: any = styled.div`
	${panelStyle};
	${shadowSM};
	// display: ${ifProp('isHidden', 'none', 'flex')};
	font-size: 14px;
	flex-direction: column;
	margin-bottom: 1em;
	position: relative;
	z-index: 0;
	overflow: hidden;
	display: block;

	${(props: any) => {
		switch (props.type) {
			case 'BACK':
				return css`
					border-top: 4px solid #78BBFD;
				`;
			case 'LAY':
				return css`
					border-top: 4px solid #FA7290;
				`;
			default:
				return '';
		}
	}};
	// border-width: 3px;
	// border-style: solid;


`;

export const MessageWrapper: any = styled.div`
	display: flex;
	align-items: center;
	font-size: 12px;
	padding: 0.5em 1em;

	${IconWrapper} {
		margin-right: 1em;
	}

	${(props: any) => {
		switch (props.type) {
			case ESlipState.Error:
				return css`
		
				`;
			case ESlipState.Expired:
				return css`
					background-color: #000;
					color: #6a696b;
				`;
			default:
				return '';
		}
	}};
`;

export const PlaceButtonWrapper = styled.button`
	${buttonStyle};
	padding: 1em 0.5em;
	width: 45%;
	font-size: 13px;
	margin-right: 0.2em;
	background: #31C68F;
	border-radius: 3px;
	color: #fff;

	* {
		pointer-events: none;
	}

	&:active {
		box-shadow: inset 0px 0px 15px rgba(0, 0, 0, 0.5);

	}

	${ifProp(
	'disabled',
	css`
			cursor: not-allowed;
			pointer-events: none;
			background-image: none;
			color: #fff;
			background: #BEC5D0;
		`,
)};
`;

export const PlaceAllButtonWrapper = styled(PlaceButtonWrapper)`
	${shadowSM};
	transition: transform 0.2s ease;
	font-size: 13px;
	background: #31C68F;
	color:#fff;

	// width: 45%;
	* {
		pointer-events: none;
	}

	&:hover {
		transform: translateY(-1px);
		${shadowMD};
	}
	&:active {
		box-shadow: inset 0px 0px 15px rgba(0, 0, 0, 0.5);
	}

	${ifProp(
	'disabled',
	css`
			cursor: not-allowed;
			pointer-events: none;
			background-image: none;
			color: #fff;
			background: #BEC5D0;
		`,
)};
`;

export const CancelButtonWrapper = styled.button`
	font-size: 13px;
	${buttonStyle};
	// background-color: transparent;
	background: #F4F4F4;
	border-radius: 3px;
	padding: 0.5em;
	margin-left: 0.2em;
	width: 45%;
	border: 1px solid hsl(245, 2%, 40%);
	// opacity: 0.75;
	text-shadow: 0 1px 0 rgba(0, 0, 0, 0);
	color: #687488;

	* {
		pointer-events: none;
	}
	${IconWrapper} {
		opacity: 0.8;
	}
	${ifProp(
	'disabled',
	css`
			cursor: not-allowed;
			pointer-events: none;
			background-image: none;
			color: #687488;
			background: #F4F4F4;
		`,
)};
`;

export const CancelUpdateButtonWrapper = styled.button`
	${buttonStyle};
	background-color: transparent;
	font-size: 12px;
`;

export const CancelAllButtonWrapper = styled(CancelUpdateButtonWrapper)`
	font-size: 13px;
	padding: 0.5em;
	// text-transform: uppercase;
	width: 45%;
	background: #7A818D;
	color: #fff;
	${ifProp(
	'disabled',
	css`
			opacity: 0.75;
			cursor: not-allowed;
		`,
)};
`;
export const CancelAllUnmatchButtonWrapper = styled(CancelUpdateButtonWrapper)`
	font-size: 13px;
	padding: 0.5em;
	margin: 0.6em 0;
	width: 100%;
	border: 1px solid #e43b67;
	${ifProp(
	'disabled',
	css`
			opacity: 0.75;
			cursor: not-allowed;
		`,
)};
`;
export const UpdateButtonWrapper = styled.button`
	${buttonStyle};
	background-color: transparent;
	border: 1px solid;

	font-size: 12px;
	padding: 0.5em;
	flex-grow: 1;

`;

export const ActionButtonContainer: any = styled.div`
	display: ${ifProp('isActive', 'flex', 'none')};
	flex-direction: ${ifProp('isColumn', 'column', 'flex')};
	justify-content: space-between;
    margin: 0.5em 0;
	margin-bottom: 0em;
	background: #EDF0F7;
	padding: 10px;
	/* > *:not(:last-child) {
		margin-bottom: 0.5em;
	} */
`;

export const PlaceOrderButton: any = styled.button`
	display: flex;
	justify-content: center;
	align-items: center;
	border: 0;
	background-color: transparent;
	font-size: 18px;
	padding: 0;
	${ifProp(
	'isClickable',
	css`
			cursor: pointer;
		`,
	css`
			cursor: not-allowed;
			pointer-events: none;
			opacity: 0.5;
		`,
)};

	&:focus {
		outline: 0;
	}
`;

export const FancyMarketInfoWrapper = styled.div``;

export const FancyMarketInfoSection = styled.div`
	font-size: 11px;
	// background-color: "#FFFFFF";
	// background: #e5f3fe;
	color: ${theme('palette.panel-foreground')};
	display: flex;
	flex-direction: column;
	// align-items: normal;
	align-items: flex-end;
	// padding: 10px;
	// padding-left: 20px;
	padding-left: 0px;
	position: relative;
	
	${(props: any) => {
		switch (props.type) {
			case 'BACK':
				return css`
					background: #A7D8FD;
				`;
			case 'LAY':
				return css`
					background: #FFD6D6;

				`;
			default:
				return '';
		}
	}};
	:before {
		content: "";
		width: 23px;
		height: 100%;
		// background: #e5f3fe;
		display: flex;
		position: absolute;
		left: -12px;
		top: 0;
		transform: skew(340deg);
		${(props: any) => {
		switch (props.type) {
			case 'BACK':
				return css`
						background: #A7D8FD;
					`;
			case 'LAY':
				return css`
						background: #FFD6D6;
	
					`;
			default:
				return '';
		}
	}};
	}

	.max_section {
		// width: 50%;
		// padding: 0.5em 1em;
		display: flex;
		padding: 1px 0px;
		color: #576471;
		font-size: 12px;
	}
`;
