import { format as dateFormat } from 'date-fns';
import React, { createContext, PureComponent } from 'react';

// Utils
import cookieUtil from '../util/cookieUtil';
import { ECookieName, ETimeZone } from '../util/utilModel';

interface ITimeZoneProviderProps {
	defaultTimeZone: ETimeZone;
}

interface ITimeZoneProviderState {
	currentTimeZone: ETimeZone;
}

interface ITimeZoneProviderStore extends ITimeZoneProviderState {
	updateCurrentTimeZone: (timeZone: ETimeZone) => void;
}

const initStore: ITimeZoneProviderStore = {
	currentTimeZone: ETimeZone.SYSTEM,
	updateCurrentTimeZone(timeZone: ETimeZone) {},
};

const TimeZoneContext = createContext(initStore);

export class TimeZoneProvider extends PureComponent<ITimeZoneProviderProps, ITimeZoneProviderState> {
	state = {
		currentTimeZone:
			typeof this.props.defaultTimeZone === 'number' &&
			this.props.defaultTimeZone >= 0 &&
			this.props.defaultTimeZone <= 12
				? this.props.defaultTimeZone
				: ETimeZone.SYSTEM,
	};
	updateCurrentTimeZone = (timeZone: ETimeZone) => {
		this.setState({ currentTimeZone: timeZone });
		cookieUtil.set(ECookieName.COOKIE_TIME_ZONE, `${timeZone}`, 30);
	};

	render() {
		const { children } = this.props;
		const store = {
			...this.state,
			updateCurrentTimeZone: this.updateCurrentTimeZone,
		};
		return <TimeZoneContext.Provider value={store}>{children}</TimeZoneContext.Provider>;
	}
}

export const TimeZoneConsumer = TimeZoneContext.Consumer;

type TimeZoneRenderProp = (targetTimeZoneOffsetSystem: number, currentTimeZone: number) => React.ReactNode;
type DateValue = string | number | Date;

interface ITimeZoneProps {
	// Should be UTC
	children: DateValue | TimeZoneRenderProp;
	// Use Date fns format
	format?: string;
}

export const TimeZone: React.SFC<ITimeZoneProps> = props => {
	const { children, format = 'yyyy-MM-dd HH:mm:ss' } = props;
	const childrenType = typeof children;
	let timestamp: number;

	if (childrenType === 'string' || childrenType === 'number' || (children as any) instanceof Date) {
		timestamp = new Date(children as DateValue).getTime();

		if (timestamp <= 0 || isNaN(timestamp)) {
			console.warn('[TimeZone]: children prop is not a valid time');
			return null;
		}
	} else if (childrenType !== 'function') {
		console.warn('[TimeZone]: children prop should be a string, number, Date instance or a render prop function');
		return null;
	}

	return (
		<TimeZoneConsumer>
			{timeZone => {
				const { currentTimeZone } = timeZone;

				const targetTimeZoneOffsetSystem = (currentTimeZone - ETimeZone.SYSTEM) /* in hour */ * 60 * 60 * 1000;

				const offsetTimestamp = timestamp + targetTimeZoneOffsetSystem;

				return typeof children === 'function'
					? (children as TimeZoneRenderProp)(targetTimeZoneOffsetSystem, currentTimeZone)
					: dateFormat(offsetTimestamp, format);
			}}
		</TimeZoneConsumer>
	);
};
