//import gql from 'graphql-tag';
import { gql} from '@apollo/client'

const order = gql`
	fragment order on Order {
		eventName
		price
		side
		stake
		selectionName
		marketId
		marketName
		updatedAt
		marketStartTime
		eventId
		marketType
		selectionId
		categoryId
		competitionId
		orderNumber
		exchangeRate
		betDelay
		oddType
		provider
	}
`;

export const orderGroup = gql`
	fragment orderGroup on OrderGroup {
		matched {
			...order
		}
		unmatched {
			...order
		}
		pending {
			...order
		}
	}
	${order}
`;

export const statusResponse = gql`
	fragment statusResponse on ResponseInterface {
		success
		code
		message
	}
`;

export const GET_ORDER_LIST = gql`
	query getOrderList {
		exchange {
			orders {
				...orderGroup
			}
		}
	}
	${orderGroup}
`;

export const PLACE_ORDER = gql`
	mutation placeOrdersBetting($input: PlaceOrdersInput!) {
		exchange {
			placeOrder(input: $input) {
				...statusResponse
			}
		}
	}
	${statusResponse}
`;

export const CANCEL_ORDERS = gql`
	mutation cancelOrder($input: CancelOrdersInput!) {
		exchange {
			cancelOrder(input: $input) {
				...statusResponse
			}
		}
	}
	${statusResponse}
`;

export const UPDATE_ORDERS = gql`
	mutation updateOrder($input: UpdateOrderInput!) {
		exchange {
			updateOrder(input: $input) {
				...statusResponse
			}
		}
	}
	${statusResponse}
`;
