import isEqual from "lodash/isEqual";
import React, { useContext, useState, useEffect } from "react";
import { useQuery } from "@apollo/client";
// import Spinner from '../common/Spinner'
import { useIdleTimeout } from "../eu-content/IdleTimeout";
import { IEventInfo } from "../../graphql/schema";
import {
  SUBSCRIBE_EVENT_FAVORITE,
  SUBSCRIBE_EVENT_INFO,
  SUBSCRIBE_MARKET_LIST,
} from "../../graphql/subscription";
//import { AppContext } from '../../store/initAppContext';
import util from "../../util/util";
import marketListFormat from "./marketListFormat";
import Subscribe from "./Subscribe";
import { GET_INNER_EVENTS } from "../../graphql/events";
import cookieUtil from "src/util/cookieUtil";
import { ECookieName } from "src/util/utilModel";
// Code Changed - Manoj (  Code for marketList polling Frequency )

const RECONNECT_WS_TIME_DIFF = 1000;
const MARKET_LIST_POLLING_TIME = 1000;

interface ISubscribeMarketProps {
  variables: any;
  query: any;
  loading: boolean;
  subscribeToMore: any;
  isFavorite: boolean;
  reconnectWS: any;
  data: any;
  isMobileWidth?: boolean;
}

interface ISubscribeMarketState {
  isReloading: boolean;
  // for comparison
  prevData: any;
}

class SubscribeMarket extends React.Component<
  ISubscribeMarketProps,
  ISubscribeMarketState
> {
  timerId: any;
  static getDerivedStateFromProps(
    nextProps: ISubscribeMarketProps,
    prevState: ISubscribeMarketState
  ) {
    const { isMobileWidth } = nextProps;
    if (!isMobileWidth) {
      return null;
    }

    // 如果 data 有更新，關閉 isReloading state
    const { data } = nextProps;
    const { prevData } = prevState;

    return data !== prevData
      ? {
          isReloading: false,
          prevData: data,
        }
      : null;
  }

  constructor(props: ISubscribeMarketProps) {
    super(props);
    this.state = {
      isReloading: false,
      prevData: this.props.data,
    };
  }

  shouldComponentUpdate(
    nextProps: ISubscribeMarketProps,
    nextState: ISubscribeMarketState
  ) {
    const newList = util.getValue(nextProps, ["data", "markets", "list"], []);
    const oldList = util.getValue(this.props, ["data", "markets", "list"], []);
    const newFirstEvent = newList[0] || {};
    const oldFirstEvent = oldList[0] || {};
    const newDataTime = newFirstEvent.dataTime || 1;
    const oldDataTime = oldFirstEvent.dataTime || 0;

    // checks
    const isEventChanged = ["categoryId", "competitionId", "eventId"].some(
      (key) => newFirstEvent[key] !== oldFirstEvent[key]
    );
    const isListChanged = newList.length !== oldList.length;
    const isSamePage = isEqual(this.props.variables, nextProps.variables);

    return (
      !isSamePage ||
      newDataTime >= oldDataTime ||
      isEventChanged ||
      isListChanged ||
      nextState.isReloading
    );
  }

  toggleReloading = () => {
    this.setState(
      {
        isReloading: true,
      },
      () => {
        this.timerId = setTimeout(() => {
          this.setState({
            isReloading: false,
          });
        }, 2000);
      }
    );
  };

  componentDidMount() {
    const { isMobileWidth } = this.props;
    isMobileWidth &&
      document.addEventListener("visibilitychange", () => {
        document.visibilityState === "visible" && this.toggleReloading();
      });
  }

  componentWillUnmount() {
    window.removeEventListener("focus", this.toggleReloading);
    this.timerId && clearTimeout(this.timerId);
  }

  render() {
    const { isReloading } = this.state;
    if (isReloading) {
      return <h4>Loading...</h4>;
    }
    // subscribe資料格式
    const subDataStructure = [
      "data",
      this.props.isFavorite ? "memberFavorite" : "marketList",
    ];
    // query資料格式
    const originDataStructure = ["markets", "list"];
    const subscribeParams = {
      // subscribe資料如何對應的query資料的格式
      formatMarket: (previous: any, subscribeData: any) => {
        const oldData: IEventInfo[] = util.getValue(
          previous,
          originDataStructure,
          []
        );
        const newData: IEventInfo[] = util.getValue(
          subscribeData,
          subDataStructure,
          []
        );
        const oldDataTime = util.getValue(previous, [
          ...originDataStructure,
          "0",
          "dataTime",
        ]);
        const newDataTime = util.getValue(subscribeData, [
          ...subDataStructure,
          "0",
          "dataTime",
        ]);
        if (newDataTime < oldDataTime) {
          oldDataTime - newDataTime > RECONNECT_WS_TIME_DIFF &&
            this.props.reconnectWS();
          return previous;
        }
        return {
          [originDataStructure[0]]: {
            __typename: previous[originDataStructure[0]].__typename,
            [originDataStructure[1]]: marketListFormat(
              oldData,
              newData,
              this.props.variables.input
            ),
          },
        };
      },
      subDataStructure,
      originDataStructure,
    };
    return this.props.children;
  }
}

interface IProps {
  query: any;
  variables: any;
  option?: any;
  children?: (params: any) => React.ReactNode;
  app?: any;
}

const SubscribedMarketList: React.FC<IProps> = (props) => {
  const [clientTime, setClientTime] = useState("");
  const { isSessionStop } = useIdleTimeout("1");
  const reconnectWS = () => {
    setClientTime(Date.now().toString());
  };
  // const { isMobileWidth, token } = useContext(AppContext);
  // console.log(useContext(AppContext))
  const isBookmaker = util.isBookmaker();
  // Code Changed - Manoj (IsBookmaker removed from newVariables - input)
  // Code Changed - Manoj ( GET_EVENTS Code is Here)
  const newVariables = { input: { ...props.variables.input } };

  let pollIntervalSelection = util.getMulValue(props, "query.definitions[0].name.value")[0] === "marketsList" ? 1500 : 1000;

  const pollIntervalNotLoggedin = util.getMulValue(props, "query.definitions[0].name.value")[0] ==="marketsList" ? 50000 : 50000;

  const { loading, error, data, subscribeToMore, variables, updateQuery } =
    useQuery(props.query, {
      variables: { input: { ...props.variables.input } },
      pollInterval: cookieUtil.get(ECookieName.COOKIE_TOKEN)
        ? pollIntervalSelection
        : pollIntervalNotLoggedin,
      fetchPolicy: "no-cache",
    });
  useEffect(() => {
    if (error) {
      console.log(error);
    }
    if (loading && !util.getValue(data, ["markets"])) {
      console.log("Loading");
    }
    subscribeMarketListData();
  }, [data]);

  const subscribeMarketListData = () => {
    let subscribeQuery =
      util.getMulValue(props, "query.definitions[0].name.value")[0] ===
      "marketsList"
        ? SUBSCRIBE_MARKET_LIST
        : SUBSCRIBE_EVENT_INFO;
    const { isFavorite, ...tempInput } = variables.input;
    let newInput = tempInput;
    if (isFavorite) {
      subscribeQuery = SUBSCRIBE_EVENT_FAVORITE;
      newInput = {
        eventId: util
          .getValue(data, ["markets", "list", "snk"], [])
          .map((_: any) => _.eventId),
      };
    }
    return isSessionStop ? (
      props.children &&
        props.children({
          loading,
          error,
          data,
          subscribeToMore,
          variables: newVariables,
          updateQuery,
        })
    ) : (
      <SubscribeMarket
        variables={{
          ...variables,
          input: {
            ...newInput,

            // clientTime用來刷新schema，讓websocket重新訂閱
            clientTime,
          },
        }}
        query={subscribeQuery}
        loading={loading}
        subscribeToMore={subscribeToMore}
        isFavorite={isFavorite}
        reconnectWS={reconnectWS}
        {...props.option}
        data={data}
        // isMobileWidth={isMobileWidth}
      >
        {props.children
          ? props.children({
              loading,
              error,
              data,
              subscribeToMore,
              variables,
              updateQuery,
            })
          : ""}
      </SubscribeMarket>
    );
  };
  // Code Changed - Manoj ( Hude Changed initialized query resut inside result and showed it in { } format)
  return (
    <>{!loading && subscribeMarketListData()}</>
    // <Query
    // 	query={GET_INNER_EVENTS}
    // 	variables={newVariables}
    // 	fetchPolicy="network-only"
    // 	pollInterval={50000}
    // >
    // 	{(result):any => {
    // 		const {loading, error, data, subscribeToMore, variables, updateQuery} = result
    // 		if (error) {
    // 			console.log(error);
    // 		}
    // 		if (loading && !util.getValue(data, ['markets'])) {
    // 			return 'loading';
    // 		}
    // 		let subscribeQuery =
    // 			util.getMulValue(props, 'query.definitions[0].name.value')[0] === 'marketsList'
    // 				? SUBSCRIBE_MARKET_LIST
    // 				: SUBSCRIBE_EVENT_INFO;
    // 		const { isFavorite, ...tempInput } = variables.input;
    // 		let newInput = tempInput;
    // 		if (isFavorite) {
    // 			subscribeQuery = SUBSCRIBE_EVENT_FAVORITE;
    // 			newInput = {
    // 				eventId: util.getValue(data, ['markets', 'list', 'snk'], []).map((_: any) => _.eventId),
    // 			};
    // 		}
    // 		return isSessionStop ? (
    // 			props.children &&
    // 			props.children({
    // 				loading,
    // 				error,
    // 				data,
    // 				subscribeToMore,
    // 				variables: newVariables,
    // 				updateQuery,
    // 			})
    // 		) : (
    // 				<SubscribeMarket
    // 					variables={{
    // 						...variables,
    // 						input: {
    // 							...newInput,

    // 							// clientTime用來刷新schema，讓websocket重新訂閱
    // 							clientTime,
    // 						},
    // 					}}
    // 					query={subscribeQuery}
    // 					loading={loading}
    // 					subscribeToMore={subscribeToMore}
    // 					isFavorite={isFavorite}
    // 					reconnectWS={reconnectWS}
    // 					{...props.option}
    // 					data={data}
    // 					// isMobileWidth={isMobileWidth}
    // 				>
    // 					{props.children
    // 						? props.children({
    // 							loading,
    // 							error,
    // 							data,
    // 							subscribeToMore,
    // 							variables,
    // 							updateQuery,
    // 						})
    // 						: ''}
    // 				</SubscribeMarket>
    // 			);
    // 	}}
    // </Query>
  );
};
export default SubscribedMarketList;
