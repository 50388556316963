import { useLocation } from 'react-router-dom';
import FNQSchemaData from '../static/fnqschema';
import { Helmet } from 'react-helmet';

const FNQSchema = ({ brandId }) => {
  const location = useLocation();
  const { pathname } = location;

  const data = FNQSchemaData(brandId, pathname);

  return data ? (
        <>
          <Helmet>
            <script
              key="structured-FAQ-data"
              type="application/ld+json"
            >{data}</script>
          </Helmet>
        </>
      ) : null
};

export default FNQSchema;

