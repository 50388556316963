import React, { useState } from 'react';
import styled from 'styled-components';
import CloseIcon from '../images/close.png';
import { useQuery, useMutation, useLazyQuery } from '@apollo/client'
import { ONE_CLICK_RECYCLE } from 'src/graphql/system';
import { useEffect } from 'react';
import util from 'src/util/util';
// import { LOGIN } from 'src/graphql/login';
// import LoginContent from './login-signup/login/login-content';
// import Login from './login-signup/login';
// import LoginSignupTab from './login-signup/login-signup-tab';
// import { props } from 'ramda';
import cookieUtil from 'src/util/cookieUtil';
import { ECookieName } from 'src/util/utilModel';
// import { GET_PLAY_GAME } from 'src/graphql/game';


const Modal = ({handleClose, show, children}) => {
  const showHideClassName = show ? "modal display-block" : "modal display-none";
  const [triggerOneClickRecycle, { recycleLoading, recycleError, recycleData }]: any = useMutation(ONE_CLICK_RECYCLE);
 
  const modelHandler = () => {
      
  if(cookieUtil.get(ECookieName.COOKIE_TOKEN)){
    triggerOneClickRecycle()
    .then((response) => {

      const result = util.getValue(response, ['data', 'game', 'recycleTransfer'], {});
      const { success, message, data } = result;
      if (success) {
        return  
      }
    })
    .catch((error) => {
      console.log('Error in one click recycle')
     
    })

  }
  else
  {
    return 
  }

  }

  const stopGameHandler =()=>{
   
    modelHandler();
    handleClose();
    
  }

  return (
    <Popup className='modelCustomPopup'>
      <div className={showHideClassName}>
        <section className="modal-main">
          {children}
          <button className="close" type="button" onClick={stopGameHandler}></button>
        </section>
      </div>
    </Popup>
  );
};

export default Modal;

const Popup = styled.div`
    .modal {
      position: fixed;
      top: 0;
      left: 0;
      width:100%;
      height: 100%;
      background: rgb(0 0 0 / 74%);
      
    }
    
    .modal-main {
      // background: white;
      background:#000;
      width: 100%;
      height: 83%;
      top:50%;
      left:50%;
      transform: translate(-50%,-50%);
      position: relative;
      display: flex;
      align-items: center;
      justify-content: center;
    }
    
    .display-block {
      display: block;
      z-index: 999;
    }
    
    .display-none {
      display: none;
    }

    .close {
      width:60px;
      height: 60px;
      background-image: url(${CloseIcon});
      background-size: cover;
      background-repeat: no-repeat;
      background-color: transparent;
      cursor: pointer;
      border: none;
      display: inline-block;
      margin: 0;
      padding: 0;
      position: absolute;
      right: 10px;
      top: 10px;
    }
  `;