import React from 'react'

type Props = {}

const WarningIconKyc = (props: Props) => {
  return (
    <svg
    width={27}
    height={24}
    viewBox="0 0 27 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M26.0932 16.3156L17.9768 2.79931C16.9056 1.01544 15.2851 0 13.4998 0C11.7145 0 10.0939 1.01544 9.02274 2.78559L0.906394 16.3156C0.302131 17.3173 0 18.3602 0 19.3482C0 20.1304 0.192265 20.8988 0.576796 21.5712C1.45572 23.1081 3.15865 24 5.25983 24H21.7397C23.8409 24 25.5438 23.1081 26.4228 21.5712C26.8073 20.8988 26.9996 20.1715 26.9996 19.4031C27.0133 18.3877 26.7112 17.331 26.0932 16.3156ZM13.4998 6.77873C14.6671 6.77873 15.6284 7.73928 15.6284 8.90566C15.6284 10.072 14.6808 11.0326 13.4998 11.0326C12.3325 11.0326 11.3711 10.072 11.3711 8.90566C11.3711 7.72556 12.3187 6.77873 13.4998 6.77873ZM15.8207 18.3877C15.7795 18.4425 14.722 19.6501 12.9779 19.6501H12.7719C12.0715 19.6089 11.4947 19.307 11.1514 18.7856C10.7257 18.1407 10.657 17.2624 10.9866 16.2882L11.5497 14.6141C11.8655 13.681 11.5634 13.53 11.3986 13.4477L11.2063 13.4202C10.863 13.4202 10.3823 13.6261 10.2313 13.7084C10.0939 13.777 9.9154 13.7496 9.80553 13.6124C9.7094 13.4751 9.7094 13.2967 9.81927 13.1732C9.86047 13.1184 11.0141 11.8148 12.868 11.9245C13.5684 11.9657 14.1452 12.2676 14.4886 12.789C14.928 13.434 14.983 14.3122 14.6534 15.3002L14.0903 16.9743C13.7744 17.9074 14.0766 18.0583 14.2414 18.1407L14.4336 18.1681C14.777 18.1681 15.2576 17.9623 15.4087 17.8937C15.5598 17.8113 15.7246 17.8525 15.8344 17.9897C15.9306 18.0858 15.9306 18.2642 15.8207 18.3877Z"
      fill="#FFB237"
    />
  </svg>
  )
}

export default WarningIconKyc