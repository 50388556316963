import React from 'react'

type Props = {}

const BetdailyAppStepTwoIcon = (props: Props) => {
  return (
    <svg
    width={48}
    height={48}
    viewBox="0 0 48 48"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <g filter="url(#filter0_b_702_18)">
      <circle cx={24} cy={24} r={24} fill="url(#paint0_linear_702_18)" />
      <circle cx={24} cy={24} r={24} fill="url(#paint1_linear_702_18)" />
    </g>
    <g clipPath="url(#clip0_702_18)">
      <path
        d="M40 17.8536C40 17.8536 24.6788 23.0706 23.4788 23.6851C22.2788 24.2996 21.8434 25.6993 22.5065 26.8115C23.1695 27.9237 24.6797 28.3272 25.8797 27.7127C27.0797 27.0982 40 17.8536 40 17.8536V17.8536Z"
        fill="white"
      />
      <path
        d="M26.4345 27.3258C27.4039 26.4273 27.4039 24.9706 26.4345 24.0721C25.4651 23.1737 23.8934 23.1737 22.9241 24.0721C21.9547 24.9706 21.9547 26.4273 22.9241 27.3258C23.8934 28.2243 25.4651 28.2243 26.4345 27.3258Z"
        fill="white"
      />
      <path
        d="M34.1762 23.4214C33.8434 23.6565 33.6866 24.0467 33.7676 24.4271C33.9105 25.0987 33.9859 25.7926 33.9859 26.5031C33.9859 26.5504 33.9857 26.5975 33.985 26.6446C33.9797 27.0117 34.3023 27.3111 34.6984 27.3111H38.6979C39.0735 27.3111 39.3826 27.0411 39.4102 26.694C39.4362 26.3643 39.4496 26.0314 39.4496 25.6955C39.4496 24.1268 39.1636 22.6201 38.6377 21.2173C38.4976 20.8436 38.0053 20.7052 37.6666 20.9462C36.6095 21.6985 35.4008 22.5564 34.1762 23.4216V23.4214Z"
        fill="white"
      />
      <path
        d="M35.4532 25.9374C35.4267 25.414 35.3641 24.8932 35.2651 24.3788C35.9997 23.8596 36.7735 23.3114 37.581 22.7377C37.8381 23.6988 37.9676 24.6883 37.9676 25.6951C37.9676 25.7756 37.9667 25.8565 37.9649 25.9374H35.4532Z"
        fill="white"
      />
      <path
        d="M22.4586 15.7714C25.7689 15.7714 28.7533 17.0646 30.8558 19.1359C31.1556 19.4312 31.6196 19.5271 32.0294 19.3852C33.4067 18.9075 34.7629 18.4405 35.9558 18.0311C36.3537 17.8944 36.4941 17.4364 36.2291 17.1293C33.5307 14.0028 29.3803 12 24.7248 12C16.5926 12 10 18.1103 10 25.6476C10 26.0682 10.0212 26.4841 10.0615 26.895C10.0821 27.1041 10.2728 27.2634 10.4994 27.2634C10.7487 27.2634 10.9475 27.0716 10.9386 26.8405C10.9336 26.7127 10.9311 26.5842 10.9311 26.4554C10.9311 20.5548 16.0921 15.7714 22.4583 15.7714H22.4586Z"
        fill="white"
      />
    </g>
    <defs>
      <filter
        id="filter0_b_702_18"
        x={-4150}
        y={-4150}
        width={8348}
        height={8348}
        filterUnits="userSpaceOnUse"
        colorInterpolationFilters="sRGB"
      >
        <feFlood floodOpacity={0} result="BackgroundImageFix" />
        <feGaussianBlur in="BackgroundImageFix" stdDeviation={2075} />
        <feComposite
          in2="SourceAlpha"
          operator="in"
          result="effect1_backgroundBlur_702_18"
        />
        <feBlend
          mode="normal"
          in="SourceGraphic"
          in2="effect1_backgroundBlur_702_18"
          result="shape"
        />
      </filter>
      <linearGradient
        id="paint0_linear_702_18"
        x1={0}
        y1={0}
        x2={47.0669}
        y2={2.39871}
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#6F6DE6" />
        <stop offset={0.489583} stopColor="#5451D4" />
        <stop offset={1} stopColor="#312DB9" />
      </linearGradient>
      <linearGradient
        id="paint1_linear_702_18"
        x1={24}
        y1={0}
        x2={24}
        y2={48}
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#B21318" />
        <stop offset={1} stopColor="#7D0204" />
      </linearGradient>
      <clipPath id="clip0_702_18">
        <rect
          width={30}
          height={16}
          fill="white"
          transform="translate(10 12)"
        />
      </clipPath>
    </defs>
  </svg>
  )
}

export default BetdailyAppStepTwoIcon