import React, { useContext, useEffect, useState } from 'react';
import styled, { keyframes } from 'styled-components';
import ProgressRing from './ProgressRing';
import { Context } from '../../../store/OrderListContext';
import { IMarketInfo, IMarketSource } from '../../../graphql/schema';
import { marketInfo } from 'src/graphql/events';
import { EMarketType } from 'src/util/utilModel';


interface ICountDownProps {
	startFrom?: number | null;
	isFancy?: boolean;
	isBookmaker?: boolean;
}

// interface ICountDownState {
// 	currentTime: number;
// }
// const isFancy={marketInfo.marketSource === IMarketSource.Snk}

const CountDown = ({ startFrom, isFancy, isBookmaker }: ICountDownProps) => {
	const { betBufferSec } = useContext(Context);
	const [currentTime, setCurrentTime] = useState(
		typeof startFrom === 'number'
			? isFancy
				? // fancy 不作調整
				  startFrom
				: // 非 fancy，小於 2 秒內設為 2 秒
				  startFrom >= 2
					? startFrom
					: 2
			: // 沒有給數字，預設為 5 秒
			  5,
	);
	useEffect(() => {
		let interval: any = null;
		const latency = currentTime > 2 && !isFancy ? betBufferSec : 0;
		const intervalTime = ((currentTime + latency) / currentTime) * 1000;
		interval = window.setInterval(() => {
			setCurrentTime(prevTime => {
				const nowTime = prevTime - 1;
				if (nowTime > 1) {
					return nowTime;
				} else {
					window.clearInterval(interval);
					return 1;
				}
			});
		}, intervalTime);
		return () => window.clearInterval(interval);
	}, []);


	return (
		<SpinnerWrapper>
			<CountDownWrapper>{isFancy || isBookmaker ? null : currentTime}</CountDownWrapper>
			<ProgressRingWrapper>
				{/* <ProgressRing /> */}
				<Loader />
			</ProgressRingWrapper>
		</SpinnerWrapper>	
	);
};

// class CountDown extends PureComponent<ICountDownProps, ICountDownState> {
// 	constructor(props: ICountDownProps) {
// 		super(props);
// 		const { startFrom, isFancy } = props;
// 		const currentTime =
// 			typeof startFrom === 'number'
// 				? isFancy
// 					? // fancy 不作調整
// 					  startFrom
// 					: // 非 fancy，小於 2 秒內設為 2 秒
// 					  startFrom >= 2
// 						? startFrom
// 						: 2
// 				: // 沒有給數字，預設為 5 秒
// 				  5;

// 		this.state = {
// 			currentTime,
// 		};
// 	}

// 	intervalId: any;
// 	componentDidMount() {
// 		const { isFancy } = this.props;
// 		const { currentTime: initialTime } = this.state;
// 		// 延遲時間，每一輪偷偷加秒數，兩秒內不加 / Fancy 不加
// 		const latency = initialTime > 2 && !isFancy ? BET_BUFFER_SECOND : 0;
// 		const intervalTime = ((initialTime + latency) / initialTime) * 1000;

// 		this.intervalId = window.setInterval(() => {
// 			this.setState(prevState => {
// 				const { currentTime: prevTime } = prevState;
// 				const currentTime = prevTime - 1;

// 				if (currentTime > 1) {
// 					return {
// 						currentTime,
// 					};
// 				} else {
// 					window.clearInterval(this.intervalId);
// 					return {
// 						currentTime: 1,
// 					};
// 				}
// 			});
// 		}, intervalTime);
// 	}

// 	componentWillUnmount() {
// 		window.clearInterval(this.intervalId);
// 	}
// 	render() {
// 		const { currentTime } = this.state;
// 		return (
// 			<SpinnerWrapper>
// 				<CountDownWrapper>{currentTime}</CountDownWrapper>
// 				<ProgressRingWrapper>
// 					<ProgressRing />
// 				</ProgressRingWrapper>
// 			</SpinnerWrapper>
// 		);
// 	}
// }

export default React.memo(CountDown);

// Styled Components

const Pulse = keyframes`
	0% {
		transform: scale(0.8);
	}

	50% {
		transform: scale(1.2);
	}

	100% {
		transform: scale(0.8);
	}
`;

const CountDownWrapper = styled.div`
	font-weight: bold;
	color: #09285F;
	font-size: 35px;
	animation-name: ${Pulse};
	animation-duration: 1.5s;
	animation-timing-function: ease;
	animation-delay: 0s;
	animation-iteration-count: infinite;
	animation-direction: normal;
	animation-fill-mode: none;
	animation-play-state: running;
`;

const ProgressRingWrapper = styled.div`
	position: absolute;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
	/* border: 5px solid #dcbc8ba3;
	border-radius: 100px; */
`;

export const SpinnerWrapper: any = styled.div` 
	position: ${(props: any) => (props.isWholePage ? 'fixed' : 'absolute')};
	z-index: 10000;
	top: 0;
	right: 0;
	bottom: 0;
	left: 0;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	background-color: #ebebeb5e;

	&::before {
		content: '';
		display: block;
		position: absolute;
		z-index: -1;
		top: 0;
		right: 0;
		bottom: 0;
		left: 0;
	}
`;

const Loader = styled.div`
  	
  border: 10px solid #bfbfbf;
  border-radius: 50%;
  border-top: 10px solid #09285F;
  width: 100px;
  height: 100px;
  -webkit-animation: spin 2s linear infinite; /* Safari */
  animation: spin 2s linear infinite;


/* Safari */
@-webkit-keyframes spin {
  0% { -webkit-transform: rotate(0deg); }
  100% { -webkit-transform: rotate(360deg); }
}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}

`;
