import React, { useEffect } from 'react'
import { withAppContext } from 'src/store/initAppContext';
import styled, { withTheme } from 'styled-components';
import { LazyLoadImage } from "react-lazy-load-image-component";
import config from "src/config/config";
import PlaceholderImage from 'src/components/Placeholder';
import { theme } from 'styled-tools';
import FooterContainer from 'src/components/footer/footer';

function IPLJackpotPromo2k25(props: any) {
    const { app: {
        brandId
    }, theme } = props;

    useEffect(() => {
        window.scrollTo(0, 0)
    }, [])

    return (
        <>
            <InformationWrapper>
                <Section>
                    <LazyLoadImage
                        src={`${config.imageV3Host}/static/cashsite/${theme.image.iPLJckpotDesktopTitleYolo}`}
                        alt="Promotion"
                        effect="none"
                        placeholder={<PlaceholderImage />}
                        className="secOneImg"
                        width="100%"
                    />
                </Section>
                <MaxWidthContainer>
                    <p>The IPL 2025 season just got even more exciting!</p>
                    <p className='brandColor'>Stay on the Leaderboard every IPL week and secure your spot in <br /> the ultimate ₹1 Cr Jackpot Draw!</p>
                </MaxWidthContainer>
                <Section className='relative'>
                    <h3 className='qualifyText'>How to Qualify?</h3>
                    {brandId === 39 || brandId === 40 && <UnderLine></UnderLine>}
                    <LazyLoadImage
                        src={`${config.imageV3Host}/static/cashsite/${theme.image.IPLJackPtHowtoQualifyImg}`}
                        alt="Promotion"
                        effect="none"
                        placeholder={<PlaceholderImage />}
                        className="secThreeImg"
                        width="100%"
                    />
                </Section>
                <Section>
                    <div className='textLine'>Are you ready to make this IPL season unforgettable? <br />
                    Start betting now and claim your chance to win BIG! </div>
                </Section>
            </InformationWrapper>
            <FooterContainer />
        </>
    )
}

export default withTheme(withAppContext(IPLJackpotPromo2k25));


// Styled Component.
const UnderLine = styled.div`
    height: 1px;
    width: 134px;
    background: radial-gradient(#f7e3c0 0%, rgba(153, 153, 153, 0.00) 80%);
    position: absolute;
    top: 100px;
`;

const MaxWidthContainer = styled.div`
    width: 803px;
    height: 125px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 10px;
    margin: 0 auto;
    padding: 10px;
    margin-top: 1.3rem;
    background: url(${config.imageV3Host}/static/cashsite/${theme("image.IPLJackpotPromoSectn2Bg")});
    background-position: top;
    background-size: contain;
    background-repeat: no-repeat;
    text-transform: capitalize;
`;

const Section = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 0;

    &.relative {
        position: relative;

        &:after {
            content: "";
            width: 191px;
            height: 178px;
            display: inline-block;
            background: url(${config.imageV3Host}/static/cashsite/brand/6/image/iplJackPotBails.png);
            background-position: top;
            background-size: contain;
            background-repeat: no-repeat;
            position: absolute;
            right: 10px;
            top: 30px;
        }

        &:before {
            content: "";
            width: 191px;
            height: 178px;
            display: inline-block;
            background: url(${config.imageV3Host}/static/cashsite/brand/6/image/iplJackPotBailsLeft.png);
            background-position: top;
            background-size: contain;
            background-repeat: no-repeat;
            position: absolute;
            left: 40px;
            bottom: -30px;
        }
    }

    .qualifyText {
        color: ${theme('palette.IPLJackpotPromo2k25PromoBrandColor')};
        font-size: 20px;
        text-align: center;
        text-transform: capitalize;
        position: absolute;
        top: 40px;
        font-family: 'Inter-Bold';
    }

    .secOneImg {
        width: 100%;
        /* height: 420px; */
        max-width: 100%;
    }

    .secThreeImg {
        /* width: 1177px; */
        max-width: 100%;
        /* padding-top: 4rem; */
    }

    .textLine {
        max-width: 100%;
        display: inline-block;
        text-align: center;
        margin: 0 auto;
        /* padding-top: 6rem; */
        padding-bottom: 5rem;
        font-size: 20px;
        font-style: normal;
        font-weight: 700;
        text-transform: capitalize;
        /* background: ${theme('palette.IPLJackpotPromo2k25BgColor')};
        background-clip: text;
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent; */
        color: ${theme('palette.IPLJackpotPromo2k25PromoBrandColor')};
        font-family: 'Inter-Bold';
    }
`;

const InformationWrapper = styled.div`
    width: 98%;
    height: auto;
    /* padding: 3rem 3rem 8rem 3rem; */
    padding: 3rem 0 0 0;
    background: rgba(111, 111, 111, 0.5);
    margin: 0em 1.2em 0 1.2em;
    border: 1px solid #fff;
    border-top-color: transparent;
    backdrop-filter: brightness(0.5);    
    display: flex;
    flex-direction: column;
    background: url(${config.imageV3Host}/static/cashsite/${theme("image.IPLJackpotPromo2k25BgImg")});
    background-position: bottom;
    background-size: cover;
    background-repeat: no-repeat;
    padding-top: 1rem;
    border-bottom: 0;

    p {
        font-size: 15px;
        color: #fff;
        text-align: center;
        margin: 0;
    }

    .brandColor {
        color: ${theme('palette.IPLJackpotPromo2k25PromoBrandColor')};
        font-weight: 700;
        line-height: 25px; 
    }

    .secTwoImg {
        width: 100%;
        /* height: 810px; */
        max-width: 100%;
    }
`;
