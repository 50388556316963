
import { withAppContext } from 'src/store/initAppContext';
import styled, { withTheme } from 'styled-components';
import { Accordion, AccordionWrapper } from '../seo-content/SeoContentHomePage39';
import { Link } from 'react-router-dom';


const JiliCrazy777Fun88 = (props: any) => {
    const { app: { brandId } } = props;

    return (
        <AccordionWrapper>
            <Accordion>
                <input type="checkbox" id="section1" className="accordion__input" />
                <label htmlFor="section1" className="accordion__label"><h1>Crazy777 by Jili Slot</h1></label>

                <div className="accordion__content">
                    <h2>Place Your Bet on Crazy777 Jili Slot Game</h2>
                    <p>Crazy 777 is undoubtedly a super fun and rewarding game. It is a classic single-line online slot game with an intriguing RTP, a great bonus, and jackpots, which makes it a favourite choice of casino enthusiasts.</p>
                    <p>FUN88 offers a simple and pleasant Crazy 777 playing experience with wonderful graphics and a user-friendly interface. The rules are easy to grasp, and as long as you focus on the symbols, you can immediately get the bonus once the connection is successful.</p>

                    <h2>How To Play Crazy777 Game</h2>
                    <p>Playing Crazy 777 is easy as the breeze; this <strong><Link to="/slots">slot game</Link></strong> has only one single pay line. Spin the same symbol from left to right and get the bonus.</p>
                    <ul>
                        <li><strong> The calculations:</strong> Odds x your betting amount.</li>
                        <li><strong> Special spins: </strong>You can find these in the up-right corner, and these spins can give you extra points depending on symbols and points corresponding to them of the particular round. You can randomly spin double bonuses, extra bonuses, and free respins.</li>
                        <li><strong> User-friendly interface: </strong>The best part of this slot game is that the players don't need to go back and forth; all the information is available, and the bonuses of all symbol combinations are listed at the top, making it convenient for players.</li>
                    </ul>

                    <h2>How to Place a Bet on Crazy777 JILI Slot Game?</h2>
                    <ol>
                        <li><strong> Log In/Sign In: </strong>Access your account by <strong><Link to="/login">logging in</Link></strong> or signing in.</li>
                        <li><strong> Make a Deposit: </strong>Add funds to your account and navigate the app.</li>
                        <li><strong> Select The Game: </strong>Go to the game tab and select the Crazy 777 slot game.</li>
                        <li><strong> Place Bets and Enjoy: </strong>Place your bets and enjoy the game.</li>
                        <li><strong> Withdrawal: </strong>Taking your winning home with quick withdrawal.</li>
                    </ol>

                    <h2>Crazy777 Game Minimum &amp; Maximum Bet Size</h2>
                    <p>Note that Crazy 777 allows different minimum and maximum bets. It is a slot machine that helps high bets.</p>
                    <p>The game limits for this game are below:</p>
                    <Table>
                        <tbody>
                            <tr>
                                <td>
                                    <p><strong>Bet Type</strong></p>
                                </td>
                                <td>
                                    <p><strong>Bet Amount</strong></p>
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    <p>Maximum Bet INR</p>
                                </td>
                                <td>
                                    <p>1,920</p>
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    <p>Minimum Bet INR</p>
                                </td>
                                <td>
                                    <p>0.60</p>
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    <p>Maximum Win Odds</p>
                                </td>
                                <td>
                                    <p>100,000X</p>
                                </td>
                            </tr>
                        </tbody>
                    </Table>

                    <h2>How to Win a Crazy 777 Slot?</h2>
                    <p>Crazy 777 is a game for all; with a remarkable minimum and maximum investment range, one can experiment and bet the amount accordingly.</p>
                    <p>Notably, a single-line slot machine offers many bonuses that players can use for their benefit.</p>
                    <p>In simpler terms, a player can strategise accordingly to use their own money to make maximum winnings.</p>
                    <p>Moreover, the players have an option to alter their investment at any point in time. As previously mentioned, Crazy 777 has a high RTP and low volatility, so you have better chances of getting frequent <strong><Link to="/rewards">rewards</Link></strong> with a lower wind line.</p>

                    <h2>Rules &amp; Features of the Crazy 777 Game</h2>
                    <p>The rules and features are crazy simple to follow and adapt to. These are as follows:</p>
                    <p>You win by landing 3 matching symbols across the single pay line, and the top-tier triple blazing 7s symbol pays 1,000x your stake for 3-oak wins. However, all wins can be boosted differently thanks to the bonus symbols on the particular reel 4.</p>

                    <h3>Win Multiplier</h3>
                    <p>The 4th reel might land on a win multiplier symbol of x2, x5, or x10, and this multiplier will be applied to your current line win.</p>

                    <h3>Stake Multiplier</h3>
                    <p>Landing $$+ or $$$+ symbols on reel 4 at the same time as you land a line win on the regular reels triggers the Stake Multiplier feature. The double dollar sign symbol awards a payout doubling your current stake, while the triple dollar sign triples your stake.</p>

                    <h3>Respins Feature</h3>
                    <p>Landing the Respin symbol on reel 4 simultaneously as you land a line win on the regular reels awards between 1 and 5 respins. Each respin lands you the same winning combo that triggered the feature, which effectively means your initial win can be boosted by up to x5.</p>

                    <h2>Bonuses and Special Features</h2>
                    <p>You win by landing three matching symbols across the single pay line, and the top-tier triple blazing 7s symbol pays 1,000x your stake for 3-oak wins. However, all wins can be boosted differently thanks to the bonus symbols on the particular reel 4.</p>
                    <p><strong>Reels, Pay Lines: </strong>3&times;1 single-line reels, one pay line. It is also worth mentioning that it is a slot machine that allows high bets, ranging from &euro;0.60 to &euro; 1920. For slot machines, it is a type that will enable high bets.</p>
                    <p><strong>Special Runner: </strong>At the very right is a particular reel. When the previous reel reaches a connection reward, additional rewards will be given according to the symbol of the specific reel.</p>

                    <h2>Crazy777 Game Return to Player (RTP), Volatility, and Max Win</h2>
                    <p>Crazy 777 offers an RTP of 99%, and it has low to medium volatility, which means it scores 2 out of 5 on the in-game volatility scale, making it apt for everyday gaming. The Crazy 777 maximum win is 3333x your stake. According to research and stats, this is solid for a classic slot machine with this volatility level.</p>

                    <h2>Advantages of Crazy777 Game Online Slot</h2>
                    <ul>
                        <li>It offers a nostalgic Vegas-style slot machine experience.</li>
                        <li>Offers winning multipliers up to x10.</li>
                        <li>You can get up to 5 win respins via the 4th reel.</li>
                        <li>Also, you can get up to x3 stake multipliers from reel 4.</li>
                        <li>A chance of winning up to 3,333x your stake.</li>
                    </ul>

                    <h2>How to Deposit and Withdraw While Playing Crazy777 Slot Game Online?</h2>
                    <p>To play Crazy 777 on FUN88, you need to deposit and withdraw funds as follows:</p>
                    <ul>
                        <li><strong> Deposit</strong>: Log in to <strong><Link to="/">FUN88</Link></strong> and select the game from the game list. Choose your preferred deposit method to add funds, including UPI, Net Banking, E-Wallets, and more. You are all set to play your game.</li>
                        <li><strong> Withdraw</strong>: To withdraw, you can choose from the same methods used for depositing: UPI, Net Banking, E-Wallets, or others. Ensure your account details are correctly updated to facilitate smooth transactions.</li>
                    </ul>

                    <h2>Download the Crazy777 Game App from FUN88 Today.</h2>
                    <p>Getting started with Crazy 777 on FUN88 is easy. Follow these steps to download and install the app:</p>
                    <ol>
                        <li><strong> Visit the URL</strong>: Open your web browser and to our official website.</li>
                        <li><strong> Download the app: </strong>Find and click the <strong><Link to="/app-download">Download Crazy777 Game App</Link></strong> for your device on the website. Depending on your smartphone or tablet, choose between Android or iOS.</li>
                        <li><strong> Install the App</strong>: Once the download is complete, open the file and follow the on-screen instructions to install the app.</li>
                    </ol>
                </div>
            </Accordion>
        </AccordionWrapper>
    )
}

export default withTheme(withAppContext(JiliCrazy777Fun88));

const Table = styled.table`
    border-collapse: collapse;
    tr, td {
        border: 1px solid black;
        border-collapse: collapse;
        text-align: center;
        padding: 0.5rem 1rem;
    }
`;