import React from "react";

type Props = {};

const CircleDown = (props: Props) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="11"
      height="11"
      viewBox="0 0 11 11"
      fill="none"
    >
      <g clip-path="url(#clip0_7_10485)">
        <rect
          x="0.339844"
          y="0.19043"
          width="10"
          height="10"
          rx="5"
          fill="white"
        />
        <path
          d="M3.13916 3.94043L5.33973 6.44043L7.54031 3.94043"
          stroke="#505050"
          stroke-width="1.5"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
      </g>
      <defs>
        <clipPath id="clip0_7_10485">
          <rect
            x="0.339844"
            y="0.19043"
            width="10"
            height="10"
            rx="5"
            fill="white"
          />
        </clipPath>
      </defs>
    </svg>
  );
};

export default CircleDown;
