import React, { useEffect, useState } from 'react';

// Dependent Components

import { Amount, Income } from '../../../common/NumberDisplay';
import Icon from '../../../common/PNGIcon';
import NumberInput, { EOddsType } from '../../NumberInput';
import CountDown from '../CountDown';
import { ESlipState, ISlipProps, OrderSlipContainer } from './OrderSlipContainer';
import Intl from '../../../common/Intl';
import { theme } from 'styled-tools';
import { withAppContext } from '../../../../store/initAppContext';
import {
	ActionButtonContainer,
	CancelButtonWrapper,
	ConfirmSection,
	FancyMarketInfoSection,
	FieldGroupWrapper,
	FieldLabel,
	FieldWrapper,
	IconButtonWrapper,
	InputField,
	InputSection,
	LiveOdds,
	MessageWrapper,
	OddsLabelWrapper,
	PlaceButtonWrapper,
	ProfitText,
	SlipEventName,
	SlipHeadingWrapper,
	SlipMask,
	SlipSelectionName,
	SlipTitleWrapper,
	SlipWrapper,
	StakeButton,
	StakeButtonWrapper,
	StakeSection,
} from './slipStyles';

// Utils
import { IMarketSource } from '../../../../graphql/schema';
import { calculateProfitLoss } from '../../../../util/formulae';
import { getGTMId } from '../../../../util/gtmUtil';
import oddsUtil from '../../../../util/oddsUtil';
import util from '../../../../util/util';
import { ECookieName, EMarketType } from '../../../../util/utilModel';
import styled, { css } from 'styled-components';
import cookieUtil from 'src/util/cookieUtil';
// import LoginContent from 'src/components/login-signup/login/login-content';
// import Modal from 'src/components/Modal';
import IconWrapper from '../../../common/PNGIcon';
import EditIcon from 'src/images/edit_stake.png';
import EditCancelIcon from 'src/images/cancel_stake.png';
import { ifProp } from 'styled-tools';
import EditDefaultStake from '../EditDefaultStake';
import { SectionHeading, SectionWrapper } from '../Order';
import { IOrderListContext } from 'src/store/OrderListContext';

const OrderSlip: React.SFC<ISlipProps> = props => {
	// const [modelState, setModelState] = useState(false)
	const isAuth = cookieUtil.get(ECookieName.COOKIE_TOKEN);
	const [setMax, setMaxSlider]: any = useState(0);
	const [isEditingStake, setIsEditingStake] = useState(false);
	const [quickStakes, setQuickStakes] = useState(cookieUtil.get(ECookieName.COOKIE_STAKE_CHOOSE)
		? cookieUtil
			.get(ECookieName.COOKIE_STAKE_CHOOSE)
			.split(',')
			.map(num => Number(num))
		: [50, 100, 500, 1000, 5000, 10000, 25000])
	const setMaxBetSlider: any = (type, exchangeRate, selectedPrice, maxPerOrderCredit, maxPerMarketExposure) => {
		let availableCredit = Number(cookieUtil.get(ECookieName.COOKIE_CREDIT))
		let maxCredit = Number(util.numMultiply(maxPerOrderCredit || 0, exchangeRate))
		let maxMarket = util.numMultiply(maxPerMarketExposure || 0, exchangeRate)
		let currentPrice = selectedPrice
		let sliderMax = maxCredit
		if (type === 'YES' || type === 'NO') {
			currentPrice = currentPrice / 100
		}
		if (type === 'BACK' || type === 'YES') {
			if (availableCredit < maxCredit && ((availableCredit * (currentPrice - 1)) < maxMarket)) {
				sliderMax = availableCredit
			} else if (availableCredit * (currentPrice - 1) > maxMarket) {
				sliderMax = maxMarket / (currentPrice - 1)
				sliderMax = (sliderMax > maxCredit) ? maxCredit : sliderMax
			}

		} else if (type === 'LAY' || type === 'NO') {
			if (maxCredit * (currentPrice - 1) > availableCredit) {
				sliderMax = availableCredit / (currentPrice - 1)
				sliderMax = (sliderMax > maxCredit) ? maxCredit : sliderMax
			} else if (availableCredit > maxMarket && maxMarket < maxCredit) {
				sliderMax = maxMarket
			}
		}
		setMaxSlider(sliderMax.toFixed(2))
		return sliderMax.toFixed(2)
	}

	const setCurrentValue = (size) => {
		if (Number(size) > Number(setMax)) {
			let max = setMax
			return max
		} else {
			return size
		}
	}

	// const emitHideModel = (flag) => { 
	// 	setModelState(flag)
	//   };

	// const hideModal = () => {
	// 	setModelState(false);
	//  };

	const changeEditingState = (state: boolean) => () => {
		setIsEditingStake(state)
	};

	const saveQuickStakes = (quickStakes: number[]) => {
		setQuickStakes(quickStakes)
		cookieUtil.set(ECookieName.COOKIE_STAKE_CHOOSE, quickStakes.join(','));
	};

	const saveAndClose = (quickStakes: number[]) => {
		saveQuickStakes(quickStakes);
		setIsEditingStake(false)
	};


	return (
		<OrderSlipContainer {...props}>
			{slipStore => {
				const {
					isHidden,
					isFancyType,
					isOrderLoading,
					keyName,
					placingOrderKeyName,
					message,
					slipState,
					type,
					eventPath,
					eventInfo,
					marketInfo,
					selectionInfo,
					fancyInfo,
					priceDisplay,
					exchangeRate,
					price,
					currentPrice,
					currentSize,
					liveOddsChangeType,
					// quickStakes,
					isLoading,
					slipRef,
					goToEvent,
					handleOddsChange,
					handleStakeChange,
					handleOnClickInput,
					handleQuickStake,
					handleBetting,
					handleTrashClick,
					orderLimit,
					isUserLoggedIn,
					saveQuickStakes
				} = slipStore;

				const EditStakeButton: React.SFC<{ onClick: () => void; isDisplayed: boolean; id?: string }> = props => {
					const { onClick, isDisplayed, id } = props;
					return (
						<EditStakeButtonWrapper onClick={onClick} isDisplayed={isDisplayed} id={id}>
							{
								isDisplayed ? <> <Intl langKey="ORDERSLIP@EDIT" />
									<img src={EditIcon} alt="EditIcon" /> </> : <> <Intl langKey="ORDERSLIP@CANCEL" />
									<img src={EditCancelIcon} alt="EditCancelIcon" /> </>
							}
						</EditStakeButtonWrapper>
					);
				};

				const CancelEditStakeButton: React.SFC<{ onClick: () => void; isDisplayed: boolean; id?: string }> = props => {
					const { onClick, isDisplayed, id } = props;
					return (
						<EditStakeButtonWrapper onClick={onClick} isDisplayed={isEditingStake && isDisplayed} id={id}>
							<Intl langKey="ORDERSLIP@CANCEL" />
							{/* Cancel Editing */}
							<img src={EditCancelIcon} alt="EditCancelIcon" />
							{/* <Icon name="x-edit-stake-red" /> */}
						</EditStakeButtonWrapper>
					);
				};

				return (
					<SlipWrapper type={type} ref={slipRef} isHidden={isHidden}>
						{isOrderLoading &&
							(placingOrderKeyName === keyName ? (
								// 正在下的單
								<CountDown
									startFrom={marketInfo.betDelay}
									isFancy={marketInfo.marketType === EMarketType.FANCY}
									isBookmaker={marketInfo.resourceFrom === EMarketType.BOOKMAKER}
								/>
							) : (
								// 其他佇列的單 disable
								<SlipMask />
							))}
						{!isOrderLoading && message && (
							<MessageWrapper type={slipState}>
								<Icon
									name={
										slipState === ESlipState.Error
											? 'circle-x-color'
											: slipState === ESlipState.Expired
												? 'alert'
												: ''
									}
								/>
								<Intl langKey={message} />
							</MessageWrapper>
						)}

						<SlipHeadingWrapper type={type}>
							<SlipTitleWrapper>
								<SlipSelectionName>
									{marketInfo.marketType === EMarketType.FANCY
										? `${marketInfo.marketName}@${priceDisplay}`
										: marketInfo.marketType === EMarketType.Multi_Selection_Fancy
											? fancyInfo && fancyInfo.selectionName
											: selectionInfo && selectionInfo.name}
								</SlipSelectionName>
								<SlipEventName onClick={goToEvent(eventPath)} hasPath={eventPath.length > 0}>
									{eventInfo.eventName}
								</SlipEventName>
							</SlipTitleWrapper>
							{/* <IconButtonWrapper onClick={handleTrashClick} id={getGTMId(`cancel_order_x_${keyName}`)}>
								<Icon name="x-orderslip" />
							</IconButtonWrapper> */}
							<FancyMarketInfoSection>
								<div className="max_section">
									<Intl langKey="TITLE@MAXBET" />{' '}
									<Maxamount>
										<Amount fractionDigits={0}>
											{marketInfo.resourceFrom === EMarketType.BOOKMAKER ? util.numMultiply(Math.min(marketInfo.BookmakerMaxPerOrderCredit, marketInfo.maxPerOrderCredit) || 0, exchangeRate) : util.numMultiply(Math.min(marketInfo.maxPerOrderCredit, orderLimit.maxOrder) || 0, exchangeRate)}
										</Amount>
									</Maxamount>
								</div>
								<div className="max_section">
									<Intl langKey="TITLE@MAXMARKET" />{' '}
									<Maxamount>
										<Amount fractionDigits={0}>
											{marketInfo.resourceFrom === EMarketType.BOOKMAKER ? util.numMultiply(Math.min(marketInfo.BookmakerMaxPerMarketExposure, marketInfo.maxPerMarketExposure,) || 0, exchangeRate) : util.numMultiply(Math.min(marketInfo.maxPerMarketExposure, orderLimit.maxMarket) || 0, exchangeRate)}
										</Amount>
									</Maxamount>
								</div>
							</FancyMarketInfoSection>
						</SlipHeadingWrapper>
						{/** Code Changed - Manoj isFancytype && ( added for FancyMarketInfoSection) */}


						<InputSection isDisabled={slipState === ESlipState.Expired} type={type}>
							<FieldGroupWrapper>
								<FieldWrapper>
									<OddsLabelWrapper>
										<Intl
											langKey={
												marketInfo.marketType === EMarketType.FANCY
													? 'GLOBAL@RUNS'
													: 'GLOBAL@ODDS'
											}
										>
											{(msg: string) => {
												return <FieldLabel>{msg}</FieldLabel>;
											}}
										</Intl>
										{/* {!isFancyType && (
											<LiveOdds type={type}>
												{price || '-'}
												{['↑', '↓'][[1, -1].indexOf(liveOddsChangeType)] || ''}
											</LiveOdds>
										)} */}
									</OddsLabelWrapper>
									{marketInfo.marketType === EMarketType.FANCY ? (
										<GainingWrap>
											<InputField
												value={
													type === 'BACK'
														? fancyInfo && fancyInfo.backValue
														: fancyInfo && fancyInfo.layValue
												}
												readOnly
											/>
										</GainingWrap>
									) : marketInfo.marketType === EMarketType.Multi_Selection_Fancy ? (

										<InputField
											value={
												type === 'BACK'
													? fancyInfo &&
													(marketInfo.oddType === EOddsType.AM
														? oddsUtil.EUtoAM(fancyInfo.backPrice)
														: fancyInfo.backPrice)
													: fancyInfo &&
													(marketInfo.oddType === EOddsType.AM
														? oddsUtil.EUtoAM(fancyInfo.layPrice)
														: fancyInfo.layPrice)
											}
											readOnly
										/>

									) : marketInfo.marketType === 25 ? (
										<InputField
											value={

												(marketInfo.oddType === EOddsType.AM
													? oddsUtil.EUtoAM(currentPrice)
													: currentPrice)

											}
											readOnly
										/>
									) : (
										<NumberInput initialNumber={currentPrice} oddType={marketInfo.oddType} onInputChange={handleOddsChange} />
									)}
								</FieldWrapper>
								<FieldWrapper>
									<FieldLabel>
										{/* <Intl langKey="GLOBAL@STAKE" /> */}
										{/* <Intl
											langKey={
												marketInfo.marketType === EMarketType.FANCY
													? 'GLOBAL@STAKE'
													: ''
											}
										>

										</Intl> */}
										<OddsLabelWrapper>
											<Intl
												langKey={
													marketInfo.marketType === EMarketType.FANCY
														? 'GLOBAL@STAKE'
														: 'GLOBAL@STAKE'
												}
											>
												{(msg: string) => {
													return <FieldLabel>{msg}</FieldLabel>;
												}}
											</Intl>
										</OddsLabelWrapper>
									</FieldLabel>
									<Intl langKey="BETSLIP@MIN">
										{(message: string) => {
											return (
												<InputField
													placeholder={`${message + (orderLimit.minOrder * exchangeRate).toFixed(2)}`}
													value={currentSize}
													onChange={handleStakeChange}
													type="number"
													pattern="\d*"
													onClick={handleOnClickInput}
													step="any"
												/>
											);
										}}
									</Intl>
								</FieldWrapper>
								<FieldWrapperProfit type={type}>
									<EstProfit>
										<Intl
											langKey={
												type === 'BACK'
													// ? 'GLOBAL@PROFIT'
													? 'Est.Profit'
													: type === 'LAY'
														// ? 'GLOBAL@LOSS'
														? 'Est. Liablity'
														: 'GLOBAL@PLACEHOLDER'
											}
										>
											{(msg: string) => {
												return <FieldLabel>{msg}</FieldLabel>;
											}}
										</Intl>
									</EstProfit>
									<ProfitText>
										<Intl langKey={type === 'BACK' ? 'GLOBAL@PROFIT' : 'GLOBAL@LIABILITY'}>
											{(msg: string) => {
												return <FieldLabel>{msg}</FieldLabel>;
											}}
										</Intl>
										<Income>
											{calculateProfitLoss(currentPrice || 0, currentSize || 0, type)}
										</Income>
									</ProfitText>
								</FieldWrapperProfit>
							</FieldGroupWrapper>
						</InputSection>
						<ConfirmSection>
							<StakeSection>
								{quickStakes.map((stake, i) => {
									return (
										<StakeButtonWrapper key={i} onClick={handleQuickStake(stake)}>
											<StakeButton>+{stake}</StakeButton>
										</StakeButtonWrapper>
									);
								})}
								<StakeButtonWrapper onClick={() => handleStakeChange({ target: { value: '0' } })}>
									<StakeButton>
										<Intl langKey="ORDERSLIP@CLEAR" />
										{/* Clear */}
									</StakeButton>
								</StakeButtonWrapper>
								{
									isEditingStake ? <StakeEdit
										onClick={changeEditingState(false)}
										// isDisplayed={isEditingStake}
										id={getGTMId('default_stake_cancel')}
									>Cancel</StakeEdit> : <StakeEdit
										onClick={changeEditingState(true)}
										id={getGTMId('default_stake_edit')}
									>Edit Stake</StakeEdit>
								}


								<EditDefaultStake
									onSave={saveAndClose}
									quickStakes={quickStakes}
									isActive={isEditingStake}
									changeEditingState={changeEditingState}
								/>


							</StakeSection>
							{/* 
							<Wrapper3Fourth>
								{/* <div className="slider-parent">
									<div className="sliderdiv"> 
										<button className="minrange" onClick={() => handleStakeChange({ target: { value: '0' } })}>Min</button>
										<input type="range" value={currentSize} min={(orderLimit.minOrder * exchangeRate).toFixed(2)} max={setMaxBetSlider(type,exchangeRate,currentPrice, marketInfo.maxPerOrderCredit, marketInfo.maxPerMarketExposure)}  step="1" onChange={(event) => handleStakeChange({ target: { value: Number(event.target.value) } })}/>
										<button className="maxrange" onClick={handleQuickStake(setMax)}>Max</button>
									</div>
								</div>
							</Wrapper3Fourth> */}

							<ActionButtonContainer isActive={true}>

								<CancelButtonWrapper
									onClick={handleTrashClick}
									id={getGTMId(`cancel_order_${keyName}`)}
								// disabled={!currentSize || currentSize <= 0 || isLoading}

								>
									<Icon name="trash" />
									<Intl langKey="ORDERSLIP@CANCEL_ORDER" />
									{/* Cancel */}
								</CancelButtonWrapper>

								<PlaceButtonWrapper
									onClick={handleBetting}
									disabled={!currentSize || currentSize <= 0 || isLoading || !isAuth}
									id={getGTMId(`place_order_${keyName}`)}
								>
									<Icon name="check-white" />
									<Intl langKey="ORDERSLIP@PLACE" />
									{/* Place Bet */}
								</PlaceButtonWrapper>

							</ActionButtonContainer>
						</ConfirmSection>
						{/* <Modal show={modelState} handleClose={hideModal}>
           				 <LoginContent emitModel={emitHideModel} />
       					</Modal>  */}
					</SlipWrapper>
				);
			}}
		</OrderSlipContainer>
	);
};

export default withAppContext(OrderSlip);

const Wrapper3Fourth = styled.div`
	display: flex;
	align-item: center;
	justify-content: center;
	width: 100%;
	padding: 0px 20px;
	padding-bottom: 10px;

	.slider-parent{
		width: 100%;
		background: #F8F8F8;
		box-shadow: inset 0px 1px 3px rgb(0 0 0 / 17%);
		border-radius: 10px;
		margin-top: 10px;
		padding-top: 5px;
	}
 
	.sliderdiv{
		display: flex;
		padding: 5px 10px 12px 10px;

		input{
			width: 100%;
		}
		button {
			border:none;
		}
	}

	.minrange {
		background: #67B6FF;
		padding: 3px 10px;
		color: #fff;
		font-size: 12px;
		font-weight: normal;
		display: flex;
		align-items: center;
		height: fit-content;
		margin: 0px;
		padding-top: 2px;
		margin-top: 3px;
		border-radius: 2px;
		line-height: 12px;
		cursor: pointer;
		border: none;
	}

	.maxrange {
		background: #0384FB;
		padding: 3px 10px;
		color: #fff;
		font-size: 12px;
		font-weight: normal;
		display: flex;
		align-items: center;
		height: fit-content;
		margin: 0px;
		padding-top: 2px;
		margin-top: 3px; 
		border-radius: 2px;
		line-height: 12px;
		cursor: pointer;
		border: none;
	}

`;


const FieldWrapperProfit: any = styled.div`
	
	border-radius: 3px 0px 0px 3px;
	padding: 5px 5px;
	position: absolute;
    right: 0;

	${(props: any) => {
		switch (props.type) {
			case 'BACK':
				return css`
					background: #DAEDFF;
					/* background: #84C9FF; */
				`;
			case 'LAY':
				return css`
					background: #FFDFE1;
				`;
			default:
				return '';
		}
	}};
`;

const EstProfit = styled.div`
	color: #576471;
	font-size: 11px;
	text-transform: capitalize;
`;

const GainingWrap = styled.div`
	background: #09285F;
`;

const Maxamount = styled.div`
	color: #203974;
`;

const EditStakeButtonWrapper: any = styled.button`
	font-size: 14px;
	display: ${ifProp('isDisplayed', 'flex', 'none')};
	align-items: center;
	background-color: transparent;
	border: 0;
	cursor: pointer;
	color: #09285F;
	color: ${ifProp('isDisplayed', '#09285F', '#D22149')};
	text-decoration: underline;
	font-weight: bold;

	img {
		width: 10px;
		height: 10px;
		margin-left: 4px;
	}

	&:focus {
		outline: 0;
	}

	${IconWrapper} {
		margin-left: 0.5em;
	}
`;

const StakeEdit = styled.button`
	font-size: 12px;
    padding: 0.5em;
	text-align: center;
	background: ${theme('palette.BetStakeBtnBG')};
    border: 0.25px solid rgba(138,158,197,0.97);
    border-radius: 2px;
`

const ButtonWrapper = styled.div`

`;