import { useEffect } from 'react'
import PlaceholderImage from 'src/components/Placeholder';
import config from 'src/config/config';
import { withAppContext } from 'src/store/initAppContext';
import styled, { withTheme } from 'styled-components';
import { theme } from 'styled-tools';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import FooterContainer from "src/components/footer/footer";
import YellowTickIcon from 'src/components/icon/YellowTickIcon';


const JetXCelestialRush = (props) => {
    const { app: { brandId
    }, theme } = props;

    useEffect(() => {
        window.scrollTo(0, 0)
    }, [])

    const domain = brandId == 31 || brandId == 32 ? "Yolo247" : 'iw247';

    return (
        <>
            <InnerPromoWrapper>
                <Catch99ContentWrapper>
                    <JetXCRTopSec>
                        <JetXTopSecInnerBox>
                            <h4>JetX-Soar to Betting Excellence with {domain}!</h4>
                            <span className='underlines'></span>
                            <p>
                                Get ready for an adrenaline-fueled journey like never before! {domain} is proud to introduce JetX, a game that takes your betting experience to new heights. Brace yourself for an intergalactic adventure, where your wits and instincts will be your best companions. A total pool prize of INR 500,000 awaits the top JetX wagers, ready to be claimed by those who soar the highest!
                            </p>
                        </JetXTopSecInnerBox>

                        <JetXTopSecInnerBox>
                            <h4>Why JetX?</h4>
                            <span className='underlines'></span>
                            <p>
                                JetX isn't just a game; it's a pulsating fusion of speed, strategy, and excitement. Picture yourself racing through the cosmos, making split-second decisions that could skyrocket your winnings. This isn't your average game; this is JetX, where the thrill never takes a back seat.
                            </p>
                        </JetXTopSecInnerBox>
                    </JetXCRTopSec>

                    <JetXCRSecondSec>
                        <h2>Embark on Your Galactic Betting Odyssey</h2>
                        <span className='underlines'></span>
                        <JetXSecBoxWrap>
                            <JetxSecondSecBox>
                                <JetxSecondSecBoxTop>
                                    <LazyLoadImage src={`${config.imageV3Host}/static/cashsite/${theme.image.jetxPromoStepOne}`} alt="jetxPromoStepOne" effect="none" placeholder={<PlaceholderImage/>}/>
                                    <span className='underlines'></span>
                                    <h3>Unleash the Turbo Boost</h3>
                                </JetxSecondSecBoxTop>
                                <p>
                                    Engage the turbo boost and navigate through the cosmic challenges. The faster you go, the bigger the rewards! Strap in for an exhilarating ride where every decision counts.
                                </p>
                            </JetxSecondSecBox>

                            <JetxSecondSecBox className='yellowBg'>
                                <JetxSecondSecBoxTop>
                                    <LazyLoadImage src={`${config.imageV3Host}/static/cashsite/${theme.image.jetxPromoStepTwo}`} alt="jetxPromoStepTwo" effect="none" placeholder={<PlaceholderImage/>}/>
                                    <span className='underlines'></span>
                                    <h3>Strategy Meets Speed</h3>
                                </JetxSecondSecBoxTop>
                                <p>
                                    JetX is not just about speed; it's about strategy. Plot your course wisely, dodge obstacles, and seize opportunities to emerge as the ultimate betting maverick. The galaxy is your playground; conquer it with your strategic prowess.
                                </p>
                            </JetxSecondSecBox>

                            <JetxSecondSecBox className='bluewBg'>
                                <JetxSecondSecBoxTop>
                                    <LazyLoadImage src={`${config.imageV3Host}/static/cashsite/${theme.image.jetxPromoStepThree}`} alt="jetxPromoStepThree" effect="none" placeholder={<PlaceholderImage/>}/>
                                    <span className='underlines'></span>
                                    <h3>Wager and Win</h3>
                                </JetxSecondSecBoxTop>
                                <p>
                                    Place your bets as you navigate through the cosmic arena. The stakes are high, and the rewards are astronomical. Will you emerge as the top pilot, claiming victory and stacking up winnings?
                                </p>
                            </JetxSecondSecBox>
                        </JetXSecBoxWrap>
                    </JetXCRSecondSec>

                    <JetXCRThirdSec>
                        <h2>How to JetX with {domain} ?</h2>
                        <span className='underlines'></span>
                        <LazyLoadImage src={`${config.imageV3Host}/static/cashsite/${theme.image.jetxPromoIllus}`} alt="jetxPromoIllus" effect="none" placeholder={<PlaceholderImage/>}/>
                    </JetXCRThirdSec>

                    <JetXCRFourthSec>
                        <JetXCRFourthTop>
                            <JetXCRTopLeft>
                                <h2>Ready to JetX? Buckle Up for the Ride of a Lifetime at {domain} !</h2>
                                <span className='underlines'></span>
                                <p>
                                    Don't miss out on the cosmic excitement. Join {domain} now and experience JetX – where betting meets intergalactic thrill!
                                </p>
                                <h6><span><YellowTickIcon /></span> The more you wager, the greater are your chances of winning big</h6>
                                <h6><span><YellowTickIcon /></span> Every week, our top 150 users with the highest wagers will share a phenomenal pool prize of 1.25 lakh INR. The more you bet, the closer you get to claiming your piece of this substantial weekly reward!</h6>
                                <h6><span><YellowTickIcon /></span> Total pool prize of INR 500,000 will be won by the highest Jetx wagers</h6>
                                <h6><span><YellowTickIcon /></span> Promotion valid only for the month of December 2023</h6>
                            </JetXCRTopLeft>
                            <JetXCRTopRight>
                                <LazyLoadImage src={`${config.imageV3Host}/static/cashsite/${theme.image.jetxPromoPlane1}`} alt="jetxPromoIllus" effect="none" placeholder={<PlaceholderImage/>}/>
                            </JetXCRTopRight>
                        </JetXCRFourthTop>

                        <JetXFourthBottom>
                            <LazyLoadImage src={`${config.imageV3Host}/static/cashsite/${theme.image.jetxPromoTable}`} alt="jetxPromoTable" effect="none" placeholder={<PlaceholderImage/>}/>
                        </JetXFourthBottom>
                    </JetXCRFourthSec>

                    <JetXCRFinalSec>
                        <JetXCRFinalSecLeft>
                            <LazyLoadImage src={`${config.imageV3Host}/static/cashsite/${theme.image.jetxPromoPlane2}`} alt="jetxPromoPlane2" effect="none" placeholder={<PlaceholderImage/>}/>
                        </JetXCRFinalSecLeft>

                        <JetXCRFinalSecRight>
                            <JetXCRTermsTitle>
                                <LazyLoadImage src={`${config.imageV3Host}/static/cashsite/${theme.image.jetxPromoTC}`} alt="jetxPromoT&C" effect="none" placeholder={<PlaceholderImage/>}/>
                                <h6>Terms & Conditions</h6>
                            </JetXCRTermsTitle>
                            <JetXTermsBody>
                                <ol>
                                    <li>This promotion is valid for all new & existing players.</li>
                                    <li>Winners will be announced every week on {domain} social media handles (Instagram) and your respective inbox.</li>
                                    <li>This promotion is only applicable for JETX & JETX3.</li>
                                    <li>Void/Cancelled/Draw bets, Cashed-Out bets do not count towards the deposit or bonus rollover requirements.</li>
                                    <li>Multiple accounts will not be considered for this promotion eligibility/criteria. All players will be subject to duplicate checking. {domain} reserves the right to disqualify players if collusion or any foul play takes place in the promotion.</li>
                                    <li>{domain} reserves the right to amend, cancel, and reclaim or refuse any promotion at its own discretion.</li>
                                    <li>{domain} General Terms & Conditions apply.</li>
                                </ol>
                            </JetXTermsBody>
                        </JetXCRFinalSecRight>
                    </JetXCRFinalSec>
                </Catch99ContentWrapper>
            </InnerPromoWrapper>
            <FooterContainer />
        </>
    )
}

export default withTheme(withAppContext(JetXCelestialRush));

//Styled Components

const Catch99ContentWrapper = styled.div`
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background: url(${config.imageV3Host}/static/cashsite/${theme('image.jetxPromoInnerBg')});
    background-position: top;
    background-size: cover;
    background-repeat: no-repeat;
    gap: 2em;

    .m-0 {
        margin: 0;
    }
    .p-0 {
        padding: 0rem 20rem;

        span{
            color: #E5A20D;
        }
    }

    a {
        width: 100%;
        height: auto;

        & span {
            display: flex !important;
            justify-content: center !important;

            & img {
                width: 60%;
                height: 80%;
            }
        }
    }

    .lazy-load-image-background {
        display: flex !important;
        justify-content: center !important;
    }
`;


const InnerPromoWrapper = styled.div`
    width: 98%;
    height: auto;
    border: 1px solid white;
    border-radius: 1px;
    background: rgba(111,111,111,0.5);
    margin: 0em 1.2em;
    -webkit-backdrop-filter: brightness(0.5);
    backdrop-filter: brightness(0.5);
`

const JetXCRTopSec = styled.div`
   height: 80vh;
   width: 85%;
   display: flex;
   justify-content: space-between;
   align-items: center;
`
const JetXTopSecInnerBox = styled.div`
    width: 25%;
    height: auto;
    padding: 2em;
    border-radius: 35px;
    background: linear-gradient(180deg, rgba(0, 0, 0, 0.10) 0%, rgba(255, 255, 255, 0.00) 100%, rgba(0, 0, 0, 0.00) 100%);
    backdrop-filter: blur(40.39555358886719px);
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;

    & h4 {
        color: #FEDE11;
        font-size: 1.4375rem;
        font-style: italic;
        font-weight: 900;
        line-height: 1.59375rem; /* 110.87% */
        text-transform: capitalize;
        text-align: left;
        margin: 5px 0px;
    }

    & p {
        color: #FFF;
        text-align: justify;
        font-size: 0.875rem;
        font-style: normal;
        font-weight: 500;
        line-height: 128.5%; /* 1.12438rem */
        text-align: left !important;
        padding: 0em;
    }

    & .underlines {
        width: 30%;
        height: 2px;
        background-color: #FEDE11;
        margin-top: 1em;
    }
`

const JetXCRSecondSec = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 90%;
    height: auto;
    margin: 1em 0em;

    & .underlines {
        width: 4.5em;
        height: 2px;
        background-color: #FEDE11;
        margin-top: 1em;
    }

    & h2 {
        color: #FEDE11;
        text-align: center;
        font-size: 1.4375rem;
        font-style: italic;
        font-weight: 800;
        line-height: 1.59375rem; /* 110.87% */
        text-transform: capitalize;
        margin: 3px 0px;
    }
`

const JetxSecondSecBox = styled.div`
    width: 25%;
    padding: 2em 2.5em;
    position: relative;
    border-radius: 10px;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 1em 0em;
    gap: 15px;
    background: radial-gradient(circle at 100% 100%, #0f1655 0, #0f1655 33px, transparent 33px) 0% 0%/35px 35px no-repeat,
            radial-gradient(circle at 0 100%, #0f1655 0, #0f1655 33px, transparent 33px) 100% 0%/35px 35px no-repeat,
            radial-gradient(circle at 100% 0, #0f1655 0, #0f1655 33px, transparent 33px) 0% 100%/35px 35px no-repeat,
            radial-gradient(circle at 0 0, #0f1655 0, #0f1655 33px, transparent 33px) 100% 100%/35px 35px no-repeat,
            linear-gradient(#0f1655, #0f1655) 50% 50%/calc(100% - 4px) calc(100% - 70px) no-repeat,
            linear-gradient(#0f1655, #0f1655) 50% 50%/calc(100% - 70px) calc(100% - 4px) no-repeat,
            linear-gradient(transparent 12%, #f273c4 100%);
    border-radius: 35px;

    &.yellowBg {
        background: radial-gradient(circle at 100% 100%, #0f1655 0, #0f1655 33px, transparent 33px) 0% 0%/35px 35px no-repeat,
            radial-gradient(circle at 0 100%, #0f1655 0, #0f1655 33px, transparent 33px) 100% 0%/35px 35px no-repeat,
            radial-gradient(circle at 100% 0, #0f1655 0, #0f1655 33px, transparent 33px) 0% 100%/35px 35px no-repeat,
            radial-gradient(circle at 0 0, #0f1655 0, #0f1655 33px, transparent 33px) 100% 100%/35px 35px no-repeat,
            linear-gradient(#0f1655, #0f1655) 50% 50%/calc(100% - 4px) calc(100% - 70px) no-repeat,
            linear-gradient(#0f1655, #0f1655) 50% 50%/calc(100% - 70px) calc(100% - 4px) no-repeat,
            linear-gradient(transparent 12%, #fede11 100%);
        border-radius: 35px;

        & h3 {
            color: #FEDE11 !important;
        }

        & .underlines {
            background-color: #FEDE11 !important;
        }
    }

    &.bluewBg {
        background: radial-gradient(circle at 100% 100%, #0f1655 0, #0f1655 33px, transparent 33px) 0% 0%/35px 35px no-repeat,
            radial-gradient(circle at 0 100%, #0f1655 0, #0f1655 33px, transparent 33px) 100% 0%/35px 35px no-repeat,
            radial-gradient(circle at 100% 0, #0f1655 0, #0f1655 33px, transparent 33px) 0% 100%/35px 35px no-repeat,
            radial-gradient(circle at 0 0, #0f1655 0, #0f1655 33px, transparent 33px) 100% 100%/35px 35px no-repeat,
            linear-gradient(#0f1655, #0f1655) 50% 50%/calc(100% - 4px) calc(100% - 70px) no-repeat,
            linear-gradient(#0f1655, #0f1655) 50% 50%/calc(100% - 70px) calc(100% - 4px) no-repeat,
            linear-gradient(transparent 12%, #6fdbcb 100%);
        border-radius: 35px;

        & h3 {
            color: #6FDBCB !important;
        }

        & .underlines {
            background-color: #6FDBCB !important;
        }
    }

    & p {
        margin-top: 0;
        color: #FFF !important;
        text-align: justify;
        font-size: 0.875rem;
        font-style: normal;
        font-weight: 500;
        line-height: 128.5%; /* 1.12438rem */
    }
    
`

const JetXSecBoxWrap = styled.div`
    display: flex;
    justify-content: center;
    gap: 2em;
`

const JetxSecondSecBoxTop = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10px;
  margin-top: 1rem;

  & h3 {
        margin: 3px 0px;
        color: #E664BC;
        font-size: 1.4375rem;
        font-style: italic;
        font-weight: 800;
        line-height: 1.59375rem; /* 110.87% */
        text-transform: capitalize;
    }

    & .underlines {
        width: 2px;
        height: 3em;
        background-color: #E664BC;
        margin-top: 1em;
        text-align: center;
    }
`

const JetXCRThirdSec = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    height: auto;
    margin: 2rem 0rem;

    & .underlines {
        width: 4.5em;
        height: 2px;
        background-color: #FEDE11;
        margin-top: 1em;
    }

    & h2 {
        color: #FEDE11;
        text-align: center;
        font-size: 1.4375rem;
        font-style: italic;
        font-weight: 800;
        line-height: 1.59375rem; /* 110.87% */
        text-transform: capitalize;
        margin: 3px 0px;
    }

    & img {
        width: 100%;
        height: auto;
    }
`

const JetXCRFourthSec = styled.div`
       background: radial-gradient(circle at 100% 100%, #0f1655 0, #0f1655 33px, transparent 33px) 0% 0%/35px 35px no-repeat,
            radial-gradient(circle at 0 100%, #0f1655 0, #0f1655 33px, transparent 33px) 100% 0%/35px 35px no-repeat,
            radial-gradient(circle at 100% 0, #0f1655 0, #0f1655 33px, transparent 33px) 0% 100%/35px 35px no-repeat,
            radial-gradient(circle at 0 0, #0f1655 0, #0f1655 33px, transparent 33px) 100% 100%/35px 35px no-repeat,
            linear-gradient(#0f1655, #0f1655) 50% 50%/calc(100% - 4px) calc(100% - 70px) no-repeat,
            linear-gradient(#0f1655, #0f1655) 50% 50%/calc(100% - 70px) calc(100% - 4px) no-repeat,
            linear-gradient(transparent 12%, #fede11 100%);
        border-radius: 35px;
        backdrop-filter: blur(40.39555358886719px);
        width: 65%;
        display: flex;
        flex-direction: column;
        gap: 20px;
        height: auto;
        padding: 2em;
        margin: 3em 0em;
        position: relative;


        & > .underlines {
            width: 6.5em;
            height: 2px;
            background-color: #FEDE11;
            margin-top: 1em;
        }

        .underlines{
            width: 6.5em;
            height: 2px;
            background-color: #FEDE11;
            margin-top: 0.5em;
        }

    & h2 {
        color: #FEDE11;
        text-align: left;
        font-size: 1.4375rem;
        font-style: italic;
        font-weight: 800;
        line-height: 1.59375rem; /* 110.87% */
        text-transform: capitalize;
        margin: 3px 0px;
    }

    & p {
        color: #FFF;
        font-size: 0.875rem;
        font-style: italic;
        font-weight: 400;
        line-height: normal;
        margin: 3px 0px;
    }

    & h6 {
        color: #FFF;
        text-align: justify;
        font-size: 0.875rem;
        font-style: normal;
        font-weight: 500;
        line-height: 120.5%; /* 0.86188rem */
        margin: 3px 0px;
        border-radius: 0.3125rem;
        background: #4E535F;
        padding: 5px;
    }

    & img {
        width: 100%;
        height: auto;
    } 
`

const JetXCRFourthTop = styled.div`
   display: flex;
   width: 100%;
`

const JetXCRTopLeft = styled.div`
    width: 60%;
    display: flex;
    flex-direction: column;
    gap: 10px;
`

const JetXCRTopRight = styled.div`
     width: 40%;
     position: absolute;
     right: -60px;
     top: 30px;

     & img {
        width: 550px;
        height: auto;
    } 
`
const JetXFourthBottom = styled.div` 
        width: 100%;
        height: auto;
        margin-top: 1em;

        & img {
            width: 100%;
            height: auto;
        } 
`

const JetXCRFinalSec = styled.div`
    width: 100%;
    height: auto;
    display: flex;
    justify-content: flex-end;
    gap: 10px;
    margin: 2em 0em;
    position: relative;
    padding-right: 12%;
`

const JetXCRFinalSecLeft = styled.div`
     width: 40%;
     height: auto;
     position: absolute;
     left: 14vw;
     top: 0px;

    & img {
        width: 750px;
        height: auto;
    } 

    @media only screen and (min-width: 1500px){
        left: 20vw;
    }

    @media only screen and (min-width: 1900px){
        left: 24vw;
    }
`

const JetXCRFinalSecRight = styled.div`
    width: 40%;
    height: auto;
    padding: 1em;
    display: flex;
    flex-direction: column;
    align-items: end;
    background: radial-gradient(circle at 100% 100%, #0f1655 0, #0f1655 33px, transparent 33px) 0% 0%/35px 35px no-repeat,
            radial-gradient(circle at 0 100%, #0f1655 0, #0f1655 33px, transparent 33px) 100% 0%/35px 35px no-repeat,
            radial-gradient(circle at 100% 0, #0f1655 0, #0f1655 33px, transparent 33px) 0% 100%/35px 35px no-repeat,
            radial-gradient(circle at 0 0, #0f1655 0, #0f1655 33px, transparent 33px) 100% 100%/35px 35px no-repeat,
            linear-gradient(#0f1655, #0f1655) 50% 50%/calc(100% - 4px) calc(100% - 70px) no-repeat,
            linear-gradient(#0f1655, #0f1655) 50% 50%/calc(100% - 70px) calc(100% - 4px) no-repeat,
            linear-gradient(transparent 12%, #f273c4 100%);
    border-radius: 35px;
`

const JetXCRTermsTitle = styled.div`
   padding: 1em;

   & img {
        width: 100%;
        height: auto;
    } 

    h6 {
        color: #FFF;
        text-align: center;
        font-size: 1.10781rem;
        font-style: normal;
        font-weight: 700;
        line-height: 1.48819rem; /* 134.333% */
        letter-spacing: 0.09969rem;
        text-transform: capitalize;
        margin: 3px 0px;
    }
`
const JetXTermsBody = styled.div`
    width: 100%;
    height: auto;
  
    & ol {
        padding: 1em 2em;
        margin: 0px;
    }

     & li {
        color: #FFF;
        text-align: justify;
        font-size: 0.875rem;
        font-style: normal;
        font-weight: 600;
        line-height: normal;
        letter-spacing: -0.035rem;
        text-transform: capitalize;
        margin: 15px 0px;
     }
`