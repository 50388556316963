import React from 'react'

type Props = {}

const ViewBankRed = (props: Props) => {
  return (
    <svg width="44" height="44" viewBox="0 0 44 44" fill="none" xmlns="http://www.w3.org/2000/svg">
    <rect x="5.97803" y="19.5908" width="5.34854" height="12.0671" rx="1.06157" fill="white"/>
    <path d="M0.615782 16.4705V12.8535C0.615782 12.3406 0.615782 12.0762 1.02137 11.8583C6.79397 8.75756 18.4404 2.48461 18.8453 2.19882C19.2502 1.91302 19.8089 2.07974 20.0376 2.19882C25.3417 5.05548 36.1737 10.8939 37.0692 11.3943C37.9647 11.8947 38.2095 12.431 38.22 12.6366V16.4268C38.22 17.8751 37.5034 18.2293 37.1451 18.2254H36.2513H2.26615C0.833732 18.2254 0.569064 17.0554 0.615782 16.4705Z" fill="white"/>
    <path fill-rule="evenodd" clip-rule="evenodd" d="M19.4872 33.0196L1.68803 33.0195C1.10175 33.0195 0.626465 33.4948 0.626465 34.0811V38.5999C0.626465 39.1862 1.10174 39.6614 1.68803 39.6614L22.2154 39.6615C20.7297 37.8001 19.7507 35.5165 19.4872 33.0196Z" fill="white"/>
    <path fill-rule="evenodd" clip-rule="evenodd" d="M22.1117 23.7846V20.6504C22.1117 20.0641 21.6364 19.5889 21.0502 19.5889H17.8248C17.2385 19.5889 16.7632 20.0641 16.7632 20.6504V30.5944C16.7632 31.1807 17.2385 31.656 17.8247 31.656H19.4173C19.4173 28.6899 20.4229 25.9587 22.1117 23.7846Z" fill="white"/>
    <path d="M43.5937 31.6567C43.5937 37.6286 38.7526 42.4697 32.7807 42.4697C26.8089 42.4697 21.9678 37.6286 21.9678 31.6567C21.9678 25.6849 26.8089 20.8438 32.7807 20.8438C38.7526 20.8438 43.5937 25.6849 43.5937 31.6567Z" fill="#B21318"/>
    <g clip-path="url(#clip0_201_478)">
    <path d="M32.7813 33.4826C33.7902 33.4826 34.6081 32.6647 34.6081 31.6558C34.6081 30.647 33.7902 29.8291 32.7813 29.8291C31.7724 29.8291 30.9546 30.647 30.9546 31.6558C30.9546 32.6647 31.7724 33.4826 32.7813 33.4826Z" fill="white"/>
    <path d="M39.6041 31.5011C39.067 30.1117 38.1345 28.9101 36.9219 28.0449C35.7093 27.1797 34.2698 26.6888 32.7812 26.6328C31.2927 26.6888 29.8531 27.1797 28.6406 28.0449C27.428 28.9101 26.4955 30.1117 25.9584 31.5011C25.9221 31.6014 25.9221 31.7113 25.9584 31.8116C26.4955 33.201 27.428 34.4026 28.6406 35.2678C29.8531 36.133 31.2927 36.6239 32.7812 36.6799C34.2698 36.6239 35.7093 36.133 36.9219 35.2678C38.1345 34.4026 39.067 33.201 39.6041 31.8116C39.6404 31.7113 39.6404 31.6014 39.6041 31.5011ZM32.7812 34.6248C32.1941 34.6248 31.6202 34.4507 31.1321 34.1245C30.6439 33.7984 30.2634 33.3347 30.0387 32.7923C29.8141 32.2499 29.7553 31.6531 29.8698 31.0772C29.9844 30.5014 30.2671 29.9725 30.6822 29.5573C31.0974 29.1422 31.6263 28.8595 32.2021 28.7449C32.7779 28.6304 33.3748 28.6892 33.9172 28.9139C34.4596 29.1385 34.9232 29.519 35.2494 30.0072C35.5756 30.4953 35.7497 31.0692 35.7497 31.6564C35.7485 32.4433 35.4354 33.1976 34.8789 33.754C34.3225 34.3105 33.5682 34.6236 32.7812 34.6248Z" fill="white"/>
    </g>
    <defs>
    <clipPath id="clip0_201_478">
    <rect width="14.6139" height="14.6139" fill="white" transform="translate(25.4746 24.3486)"/>
    </clipPath>
    </defs>
    </svg>
    
  )
}

export const ViewBankOrange = (props: Props) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="56" height="55" viewBox="0 0 56 55" fill="none">
      <rect x="7.74023" y="24.2197" width="6.7567" height="15.2441" rx="1.34106" fill="white" />
      <path d="M0.965228 20.2783V15.709C0.965228 15.0611 0.965228 14.727 1.4776 14.4518C8.77002 10.5347 23.4827 2.61018 23.9942 2.24915C24.5058 1.88812 25.2115 2.09872 25.5004 2.24915C32.201 5.85792 45.8849 13.2335 47.0162 13.8657C48.1474 14.4978 48.4567 15.1753 48.4699 15.435V20.2232C48.4699 22.0527 47.5647 22.5002 47.1121 22.4952H45.9828H3.05011C1.24056 22.4952 0.906209 21.0173 0.965228 20.2783Z" fill="white" />
      <path fill-rule="evenodd" clip-rule="evenodd" d="M24.8071 41.1846L2.32153 41.1846C1.58088 41.1846 0.980469 41.785 0.980469 42.5256V48.2341C0.980469 48.9747 1.58088 49.5752 2.32152 49.5752L28.2538 49.5752C26.3768 47.2238 25.14 44.339 24.8071 41.1846Z" fill="white" />
      <path fill-rule="evenodd" clip-rule="evenodd" d="M28.1219 29.518V25.5579C28.1219 24.8172 27.5215 24.2168 26.7809 24.2168H22.7063C21.9656 24.2168 21.3652 24.8172 21.3652 25.5579V38.1199C21.3652 38.8605 21.9656 39.4609 22.7063 39.4609H24.7188C24.7188 35.7143 25.9889 32.2643 28.1219 29.518Z" fill="white" />
      <path d="M55.2591 39.4635C55.2591 47.0076 49.1434 53.1233 41.5993 53.1233C34.0552 53.1233 27.9395 47.0076 27.9395 39.4635C27.9395 31.9194 34.0552 25.8037 41.5993 25.8037C49.1434 25.8037 55.2591 31.9194 55.2591 39.4635Z" fill="url(#paint0_linear_2022_17215)" />
      <g clip-path="url(#clip0_2022_17215)">
        <path d="M41.6007 41.7697C42.8752 41.7697 43.9083 40.7365 43.9083 39.462C43.9083 38.1875 42.8752 37.1543 41.6007 37.1543C40.3262 37.1543 39.293 38.1875 39.293 39.462C39.293 40.7365 40.3262 41.7697 41.6007 41.7697Z" fill="white" />
        <path d="M50.2201 39.2672C49.5415 37.512 48.3635 35.9941 46.8317 34.9011C45.2999 33.8081 43.4813 33.1879 41.6009 33.1172C39.7204 33.1879 37.9019 33.8081 36.37 34.9011C34.8382 35.9941 33.6602 37.512 32.9816 39.2672C32.9358 39.3939 32.9358 39.5327 32.9816 39.6595C33.6602 41.4147 34.8382 42.9326 36.37 44.0256C37.9019 45.1186 39.7204 45.7388 41.6009 45.8095C43.4813 45.7388 45.2999 45.1186 46.8317 44.0256C48.3635 42.9326 49.5415 41.4147 50.2201 39.6595C50.2659 39.5327 50.2659 39.3939 50.2201 39.2672ZM41.6009 43.2133C40.8592 43.2133 40.1342 42.9934 39.5175 42.5813C38.9008 42.1693 38.4201 41.5836 38.1363 40.8984C37.8525 40.2132 37.7782 39.4592 37.9229 38.7317C38.0676 38.0043 38.4248 37.3361 38.9492 36.8117C39.4737 36.2872 40.1418 35.9301 40.8693 35.7854C41.5967 35.6407 42.3507 35.715 43.0359 35.9988C43.7211 36.2826 44.3068 36.7633 44.7189 37.3799C45.1309 37.9966 45.3508 38.7217 45.3508 39.4633C45.3493 40.4574 44.9537 41.4104 44.2508 42.1133C43.5479 42.8162 42.5949 43.2118 41.6009 43.2133Z" fill="white" />
      </g>
      <defs>
        <linearGradient id="paint0_linear_2022_17215" x1="41.5993" y1="25.8037" x2="41.5993" y2="53.1233" gradientUnits="userSpaceOnUse">
          <stop stop-color="#FF9C64" />
          <stop offset="1" stop-color="#D04B00" />
        </linearGradient>
        <clipPath id="clip0_2022_17215">
          <rect width="18.4615" height="18.4615" fill="white" transform="translate(32.3691 30.2305)" />
        </clipPath>
      </defs>
    </svg>
  )
}

export default ViewBankRed;