import { darken, lighten, transparentize } from "polished";
import styled, { css } from "styled-components";
import { ifProp, theme, withProp } from "styled-tools";
// Styles
import {
  shadow2F,
  shadowBottom1F,
  shadowLG,
  titleFont,
} from "../../common/commonCSSStyles";
import { color } from "../../../sass-files/variable";
// Utils
import { diffColor } from "../../../util/colorUtil";
import media from "../../../util/mediaUtil";
import { isMemberType } from "../../../util/styleUtil";

const { secondarycolor, tabsignup } = color;

// Extend this component and define the width you want
export const Col = styled.div`
  display: flex;
  padding: 0.5em;
`;

export const Row = styled.div<{ inner?: any }>`
  display: flex;
`;

// gradient
export const TableHeadRow: any = styled(Row)`
  position: relative;
  // top:140px;
  ${titleFont};
  font-size: 11px;
  font-weight: bold;
  text-transform: uppercase;
  letter-spacing: 0.66px;
  background-color: ${theme("palette.InPlayTableHeadRowBg")};
  justify-content: space-between;
  color: ${theme("palette.InPlayTableHeadRowColor")};

  ${isMemberType(
    "CLIENT",
    css`
      background: ${theme("palette.base-table-head-row")};
      color: ${theme("palette.table-head-text")};
      border-bottom: none;
      // z-index: 2;
      ${shadowBottom1F};
    `,
    css`
      background-image: linear-gradient(
        to top,
        ${theme("palette.sidebar-background")},
        ${withProp("theme.palette.sidebar-background", diffColor(0.15))}
      );
      color: ${theme("palette.text-light")};
    `
  )};

  ${media.lg`
		display: none;
	`};
`;

export const TableSubheadRow = styled(Row)`
  ${titleFont};
  background-color: ${theme("palette.table_subhead_background")};
  color: ${theme("palette.table_subhead_foreground")};
  border-bottom: 1px solid ${theme("palette.primary-background")};
  font-size: 12px;
  font-weight: bold;
  text-transform: uppercase;
  padding: 0.5em 1em;
  justify-content: center;
  background: #f4f8fc;
  // background:#fff;
  color: #505050;
  border: none;
  margin: 5px 0px;
  font-size: 15px;
`;

// Wrapping Col components
export const TableBodyRow = styled(Row)`
  width: 100%;
  display: flex;
  align-items: left;
  background: ${secondarycolor};
  border-radius: 0px 0px 20px 0px;
  // padding: 10px 20px;
  // padding: 5px;
  font-size: 13px;
  justify-content: space-evenly;
  // justify-content: flex-end;
  padding-right: 0;
  margin-bottom: 2px;
  position: relative;
  &:not(:last-of-type) {
    border-bottom: 1px solid rgba(128, 128, 128, 0.2);
  }
  // > div {
  // 	z-index: 2;
  // }

  &:hover {
    ${isMemberType(
      "CLIENT",
      css`
        

        ${media.md`
				&::before{
					display: none;
				}
				`};
      `,
      css`
        // background-color: ${withProp(
          "theme.palette.panel-background",
          diffColor(0.025)
        )};
        background-color: #ebf3ff;
      `
    )};
  }

  &:nth-of-type(2n) {
    // background-color: ${withProp(
      "theme.palette.panel-background",
      diffColor(0.035)
    )};
    // background: #F8F8F8;
    background: #fff;
  }

  &:nth-of-type(1n):hover {
    background-color: #ebf3ff;
  }

  ${media.lg`
		${isMemberType(
      "CLIENT",
      css`
        background-color: ${theme("palette.panel-background")};

        &:nth-of-type(2n) {
          background-color: ${withProp(
            "theme.palette.panel-background",
            diffColor(0.035)
          )};
        }
      `,
      css`
        background-color: ${theme("palette.sidebar-background")};

        &:nth-of-type(2n) {
          background-color: ${withProp(
            "theme.palette.sidebar-background",
            diffColor(0.035)
          )};
        }
      `
    )}
	`};

  ${media.md`
	&:nth-of-type(2n) {
		background-color: rgba(255,255,255,.5);
	}
	`};
`;

export const TableBodyWrapper = styled.div`
  position: relative;
  min-height: 3em;
  width: 100%;
`;

export const TableWrapper: any = styled.div`
  width: 100%;
  ${shadow2F};
  display: flex;
  flex-direction: column;
  background: #ffffff;

  ${ifProp(
    "isNoMargin",
    css`
      margin-bottom: 0;
      :first-child {
        border-radius: 4px;
      }
    `,
    css`
      margin-bottom: 1.5em;
      border-radius: 4px;
      &:last-child {
        margin-bottom: 0.8em;
      }
      ${media.md`
			margin-bottom: 1em;
			&:last-child {
			margin-bottom: 0;
		}
			`}
    `
  )};

  ${media.lg`
		border: 1px solid "#D0D0D0";
		border-radius: 0;
	`};

  ${media.md`
		background: rgba(255,255,255,.5);
		border-radius: 5px;
		border: none;
		overflow: hidden;
	`};
`;

// Cover particular column
export const ColMask: any = styled.div`
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 1;
  background-color: ${withProp(
    "theme.palette.panel-background",
    diffColor(0.023)
  )};
  text-transform: uppercase;
  letter-spacing: 1px;
  /* color: ${theme("palette.primary-background")}; */
  color: ${theme("palette.oddsSuspendedColor")};
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 12px;
  font-weight: bold;
  background-color: ${theme("palette.TableSuspendedMarketBg")};
`;

export const MobileMatchTableHeading = styled.h5`
  color: ${withProp("theme.palette.primary-background", diffColor(0.023))};
  margin-top: 0;
  margin-bottom: 1em;
  font-size: 14px;
  display: none;

  ${media.lg`
		display: flex;
		align-items: center;

		> div {
			margin-right: 0.5em;
		}

		${isMemberType(
      "CLIENT",
      css`
        color: ${withProp(
          "theme.palette.primary-background",
          diffColor(0.023)
        )};
      `
    )}

	`};
  ${media.md`
		margin-bottom: 0.5em;
	`};
`;

export const MobileMatchTableSubHeading = styled(MobileMatchTableHeading)`
  font-size: 12px;
`;

export const TableHeadCol = styled.div`
  display: flex;
  padding: 5px 0.8em;
  text-align: center;
`;

export const SubTableHeadRow = styled(TableHeadRow)`
  border-top: 1px solid ${theme("palette.table-head-foreground")};
`;
