import React, { useEffect } from 'react'
import { MainInfoWrapper } from 'src/components/udb/commonStyle';
import config from 'src/config/config';
import { withAppContext } from 'src/store/initAppContext';
import styled, { withTheme } from 'styled-components';
import { theme } from 'styled-tools';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import PlaceholderImage from 'src/components/Placeholder';
import cookieUtil from 'src/util/cookieUtil';
import FooterContainer from "src/components/footer/footer";
import Intl from 'src/components/common/Intl';

const IplCasinoCarnival = (props) => {
    const { app: { brandId }, theme } = props;
    return (
        <>
        <MainInfoWrapper>
            <BannerWrapper>
                <LazyLoadImage src={`${config.imageV3Host}/static/cashsite/${theme.image.CasinoCarnvlBanner}`} effect="none" height="100%" width="100%" placeholder={<PlaceholderImage />} />
            </BannerWrapper>
            <ContentWrapper>
                <BorderWrapper>
                    <TextWrapper>
                        <h1>Ultimate Gaming <br/> Experience</h1>
                        <ul>
                            <li>
                                <Intl langKey="casinoCarnivaltextline1"/>
                                <span><Intl langKey="casinoCarnivaltextline2"/></span> 
                                <Intl langKey="casinoCarnivaltextline3"/> <span>
                                <Intl langKey="casinoCarnivaltextline4"/>
                                </span><Intl langKey="casinoCarnivaltextline5"/>
                            </li>
                            <li>
                                <Intl langKey="casinoCarnivaltextline6"/>
                                <span><Intl langKey="casinoCarnivaltextline7"/></span> 
                                <Intl langKey="casinoCarnivaltextline8"/>
                            </li>
                            <li>
                                <Intl langKey="casinoCarnivaltextline9"/>
                            </li>
                        </ul>
                    </TextWrapper>
                    <TableImg>
                        <LazyLoadImage src={`${config.imageV3Host}/static/cashsite/${theme.image.CasnoCarnvlTable}`} effect="none" height="100%" width="100%" placeholder={<PlaceholderImage />} />
                    </TableImg>
                </BorderWrapper>
                <TermsConditionPoints>
                    <StepTittle><Intl langKey="CasinoCarnival@TermsConditionTittle"/></StepTittle>
                    <ul>
                        <li><TextContent><Intl langKey="CasinoCarnival@TermsConditionFirst"/></TextContent></li>
                        <li><TextContent><Intl langKey="CasinoCarnival@TermsConditionSecond"/></TextContent></li>
                        <li><TextContent><Intl langKey="CasinoCarnival@TermsConditionThird"/></TextContent></li>
                        <li><TextContent><Intl langKey="CasinoCarnival@TermsConditionFourth"/></TextContent></li>
                        <li><TextContent><Intl langKey="CasinoCarnival@TermsConditionFifth"/></TextContent></li>
                        <li><TextContent><Intl langKey="CasinoCarnival@TermsConditionSixth"/></TextContent></li>
                        <li><TextContent><Intl langKey="CasinoCarnival@TermsConditionSeventh"/></TextContent></li>
                        <li><TextContent><Intl langKey="CasinoCarnival@TermsConditionEighth"/></TextContent></li>
                    </ul>
                </TermsConditionPoints>
            </ContentWrapper>
        </MainInfoWrapper>
        <FooterContainer/>
        </>
    )
}

export default withTheme(withAppContext(IplCasinoCarnival));

//Styled Components
const TextContent = styled.p`
    display: flex;
    color: ${theme('palette.catch99TextContentColor')};
    font-size: 17px;
`;

const StepTittle = styled.h3`
    display: flex;
    justify-content: center;
    background: ${theme('palette.IplCasinoCarnivalStepTittleColor')};
    font-size: 36px;
    font-family: 'Inter-Bold';
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    background-clip: text;
    text-fill-color: transparent;
    text-transform: uppercase;
    font-style: italic;
    position: relative;

    ::after {
        content: "";
        width: 107px;
        height: 3px;
        background: linear-gradient(90.55deg, #D1AD46 2.89%, #FCF19E 52.11%, #D0AB44 99.8%);
        position: absolute;
        bottom: -10px;
    }
`;

const TermsConditionPoints = styled.div`
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 15px;
    padding-bottom: 2rem;
    padding: 3rem;

    p {
        padding-left: 1rem;
        text-transform: capitalize;
    }

    ul {
        list-style: number;
        color: #fff;
        padding-left: 35px;
        margin-top: 0;

        li {
            p{
                display: flex;
                gap: 5px;
                padding-left: 0rem;
                margin: 0 0 10px 0;
            }
        }
    }
`;

const TableImg = styled.div`
    display: flex;
    align-items: center;
`;

const TextWrapper = styled.div`
    display: flex;
    align-items: center;
    gap: 5rem;

    ul {
        width: 500px;
        display: flex;
        flex-direction: column;
        align-items: center;
        gap: 1.5rem;
        list-style: none;
        padding: 0;
        position: relative;

        li {
            text-align: left;
            display: inline-block;
            font-size: 18px;
            color: #fff;
            text-transform: capitalize;

            span{
                display: inline-block;
                padding: 0 5px;
                font-size: 18px;
                text-transform: capitalize;
                color: #FFD084;
            }
        }

        ::before {
            content: "";
            width: 3px;
            height: 100%;
            background: linear-gradient(180deg, #D1AD46 0%, #F9EC97 100%);
            position: absolute;
            left: -2.4rem;
        }
    }

    h1 {
        font-weight: 700;
        font-size: 51px;
        text-align: right;
        text-transform: capitalize;
        background: ${theme('palette.CasnoCarnvlH1Bg')};
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
        background-clip: text;
        text-fill-color: transparent;
    }
`;

const BorderWrapper = styled.div`
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 2rem;
    background: ${theme('palette.CasnoCarnvlBorderWrapperBg')};
    mix-blend-mode: normal;
    border: ${theme('palette.CasnoCarnvlBorderWrapperBorder')};
    backdrop-filter: blur(45.5px);
    border-radius: 16px;
    padding: 2rem;
`;

const ContentWrapper = styled.div`
    width: 100%;
    padding: 0 4rem;
    background: url(${config.imageV3Host}/static/cashsite/${theme('image.CasnoCarnvlContentBg')});
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
`;

const BannerWrapper = styled.div`
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
`;