import React from 'react'
import { ApolloProvider } from '@apollo/client'
import {ApolloProvider as ApolloProvider2,
    Query,} from '@apollo/react-components';
import initApollo from '../store/initApollo';

const withApollo = (App: any) => {

    return class Apollo extends React.Component {
        constructor(props) {
            super(props);
            this.state = {
            }

            this.getInitialProps();
        }

        getInitialProps = async () => {
            let props = await App.getInitialProps();
            this.setState({ ...props })
        }



        // static async getInitialProps(ctx: any): Promise<any> {
        //     const { Component, router }: any = ctx;

        //     let appProps: any = {};
        //     if (App.getInitialProps) {
        //         appProps = await App.getInitialProps(ctx);
        //     }

        //     // Run all GraphQL queries in the component tree
        //     // and extract the resulting data
        //     const { lang, memberId, username, originalMemberId, token } = appProps;
        //     const apollo = initApollo({
        //         lang,
        //         memberId,
        //         originalMemberId,
        //         username,
        //         res: ctx.ctx.res,
        //         req: ctx.ctx.req,
        //         token,
        //     });

        //     // Extract query data from the Apollo store
        //     const apolloState = apollo.cache.extract();

        //     return {
        //         ...appProps,
        //         apolloState,
        //     };
        // }

        render(): JSX.Element {
            const { lang, memberId, username, originalMemberId, token, brandId }: any = this.state;
            const apolloClient = initApollo(
                {
                    lang,
                    memberId,
                    username,
                    originalMemberId,
                    token,
                    brandId
                }
            );
            return (
                <ApolloProvider2 client={apolloClient}>
                <ApolloProvider client={apolloClient}>
                    <App {...this.state} />
                </ApolloProvider>
                </ApolloProvider2>
            )
        }
    };
}

export default withApollo;

