import { useEffect } from "react";
import FooterContainer from "src/components/footer/footer";
import { withAppContext } from "src/store/initAppContext";
import styled, { withTheme } from "styled-components";


interface IProps {
	platformDetails: any;
	// gameCode: any;
}

interface IState {
	iframeSrc: string;
	platformCode: string;
	gameName: string;
	isLoading: boolean;
}

interface IProps {
	app: { query: any, token: any, type: string, username: string };
	gameCode: any;
}


function DisconnectionPolicy(props: any){

    const {app: {
        brandId
    }, theme} = props

    useEffect(() => {
        window.scrollTo(0,0)
    })

    return(
        <>
        <ContentWrapper>
        <h1>DISCONNECT POLICY</h1>
            <p>Communication problems over the internet may cause sudden disconnection to the game play.{props.app.brandId === 51 ? 'SUPER7' : props.app.brandId === 52 ? 'IEXCH' : props.app.brandId === 53 ? 'STRIKERS 365' : ''}  is committed to protect our players from 
               unjustifiable loss while eliminating the possibility of intentional disconnection and system abuse.
            </p>
            <p>As we cannot guarantee uninterrupted connection due to factors such as poor reception or weak internet connections, our software has been 
                designed to cope with such situations. Kindly read and understand the following disconnection policy:
            </p>
        <ol>    
            <li>
                Players must accept the risk of disconnection and should attempt to log back in and continue playing as quickly as possible. Check with your 
                Internet Service Provider about how to reduce the risk of disconnection.
            </li>    
            <li>
                Should disconnection occur before a bet is placed successfully, the bet will not be valid.
            </li>
            <li>
                Should disconnection occur after a bet is placed successfully, the bet will be valid and results will be determined by the game play. After 
                reconnecting, the results will be shown in the game history table.
            </li>
            <li>
                Players may contact Customer Service to verify placed bet and game results but will not in any way alter the results of the bet.
            </li>
            <li>
                Should players experience continuous interruptions to the game play, please contact Customer Service for further investigation.
            </li>
            <li>    
            {props.app.brandId === 51 ? 'SUPER7' : props.app.brandId === 52 ? 'IEXCH' : props.app.brandId === 53 ? 'STRIKERS 365' : ''} reserves the right to modify, alter, discontinue, cancel, refuse, or void this policy at its sole discretion.
            </li>
        </ol>    
        <h2>1. Live Casino</h2>    
            <p>The conditions for the online Live Casino tables will be as follows:
            </p>
            <p>
            A.If disconnection occurs before a bet is placed in any of the choices within the live casino tables; the bet will not be valid, and no adjustments will 
            be made in the player’s balance. This includes betting on either Player, Banker, Tie, and/or Pair.
            </p>
            <p>
            B.If disconnection occurs after a bet is placed in any of the choices within the live casino tables; the bet will be valid, and the exact bet amount will 
            be adjusted in the player’s balance. This includes betting on either Player, Banker, Tie, and/or Pair.
            </p>
        <h2>2. Live Roulette</h2>
            <p>
                The conditions for the online Live Roulette tables will be as follows:
            </p>
            <p>
            A.If disconnection occurs before a bet is placed in any of the number within the Roulette table; the bet will not be valid, 
            and no adjustments will be made in the player’s balance. This includes betting on any number, color, even, odd, and/or number range.
            </p>
            <p>
            B.If disconnection occurs after a bet is placed in any of the number within the Roulette table; the bet will be valid, and the exact bet amount will be 
            adjusted in the player’s balance. This includes betting on any number, color, even, odd, and/or number range.
            </p>
        <h2>3. Live Card Games</h2>
            <p>
                The conditions for the online Live Exchange Card tables will be as follows:
            </p>
            <p>
            A.If disconnection occurs before a bet is placed in either of the choices within any of the live/exchange card games tables; the bet will not be valid, 
            and no adjustments will be made in the player’s balance. This includes betting on Andar or Bahar.
            </p>
            <p>
            B.If disconnection occurs after a bet is placed in any of the number within the Roulette table; the bet will be valid, and the exact bet amount will be 
            adjusted in the player’s balance. This includes betting on Andar or Bahar.
            </p>
        <h2>4. Online Slots/Bingo/Other games</h2>
            <p>
                The conditions for the online slot /bingo/other games will be as follows:
            </p>
            <p>
            A.If disconnection occurs before you press spin or before you place the bet; the bet will not be valid, and no adjustments will be made in the player’s 
            balance.
            </p>
            <p>  
            B.If disconnection occurs after you press spin or after you place the bet; the bet will be valid, and the exact bet amount will be adjusted in the 
            player’s balance.
            </p>

            </ContentWrapper>
            <FooterContainer/>
        </>
    )



}



export default withTheme(withAppContext(DisconnectionPolicy));

const ContentWrapper = styled.div`
	max-width: 800px;
	margin: 0 auto;
	font-size: 12px;
	padding: 5.5em 1.25em;

	h1,
	h2,
	h3,
	h4,
	h5,
	h6 {
		color: #000;
		margin-top: 1.23em;
	}

	h1 {
		font-size: 18px;
	}

	h2 {
		font-size: 16px;
	}

	h3 {
		font-size: 14px;
	}

	ul ul,
	ol {
		margin: 1em 0;
	}

	li {
		margin: 0.75em 0;
	}

	a {
		color: #00FF;
		text-decoration: underline;
	}
`;