import styled, { css } from 'styled-components';
import { ifProp, theme, withProp } from 'styled-tools';

import Icon, { IconWrapper } from '../common/PNGIcon';
import { TableBodyRow } from '../common/table/baseTableStyles';


// Styles
import { titleFont } from '../common/commonCSSStyles';

// Utils
import { darken } from 'polished';
import { diffColor } from '../../util/colorUtil';
import { getGTMId } from '../../util/gtmUtil';
import media from '../../util/mediaUtil';
import { isMemberType } from '../../util/styleUtil';

//images
import { SvgIcon } from '../udb/commonStyle';
import CircleDown from '../icon/CircleDown';

export const RemarkHint: React.SFC = () => {
	return <RemarkHintWrapper>Remark</RemarkHintWrapper>;
};

// Components
export const EventNameWithChevron: React.SFC<{
	onClick: () => void;
	isOpened: boolean;
	isShow?: boolean;
	withRemark?: boolean;
	historyFilterType: string;
	historyTableType: string;
}> = ({
	onClick,
	isOpened = false,
	isShow = true,
	withRemark = false,
	children,
	historyFilterType: type,
	historyTableType: mode,
}) => {
	return (
		<EventNameWrapper onClick={onClick} isOpened={isOpened} id={getGTMId(`member_items_detail`)}>
			<CustomWidth>{children}</CustomWidth>
			{isShow && 
			// <Icon name="circle-up-chevron" />
			// <ExpandDetail src={expandIcon}/>
			<SvgIcon><CircleDown /></SvgIcon>
			}
			{withRemark && <RemarkHint />}
		</EventNameWrapper>
	);
};

export const TypeLabel: React.SFC<{ children: string }> = props => {
	const { children } = props;
	let text = '';
	if (typeof children === 'string') {
		// capitalize
		text = children.charAt(0).toUpperCase() + children.slice(1).toLowerCase();
	}
	return <TypeText>{text}</TypeText>;
};

// Styled Components
const CustomWidth = styled.div`
	width: 140px;
`;
// Information styles
export const DateText = styled.span`
	margin-bottom: 0.25em;
	color: #53537B;
`;

export const ExpandDetail =styled.img`
	width: 12px;
	height: 12px;
	margin-left: 5px;
`;

export const TimeText = styled.span`
	font-size: 13px;
	/* font-style: italic; */
	color: #53537B;
`;

// Wrapping event name &
export const EventNameWrapper: any = styled.div`
	display: flex;
	align-items: center;
	font-weight: bold;
	color:#203974;
	cursor: pointer;
	* {
		pointer-events: none;
	}
	> ${SvgIcon} {
		transition: transform 0.25s ease;
		will-change: transform;
		margin-left: 1em;
		opacity: 0.65;
		font-size: 10px;
	}


	${ifProp(
		/* Default is circle-up-chevron */
		'isOpened',
		css`
			 svg {
				transform: rotate(180deg) !important;
			}
		`,
		css`
		  svg {
				transform: rotate(0deg) !important;
			}
		`,
	)};

	${isMemberType(
		'CLIENT',
		css`
			color: ${theme('palette.secondary-background-color-new')};
			${media.md`
				font-size: 14px;
				color: CurrentColor;
			`};
		`,
		css`
			color: ${theme('palette.primary-background')};
		`,
	)};
`;

export const SelectionInfoWrapper = styled.div`
	display: flex;
	flex-direction: column;
	/* padding: 0.5em; */
	padding: 4px 10px;
	background: rgba(42, 40, 117, 0.12);
	border-radius: 3px;
	/* color: ${theme('palette.panel-foreground')}; */
	color: #130F48;
	/* border: 1px solid rgba(128, 128, 128, 0.2); */

	> *:not(:last-child) {
		/* margin-bottom: 0.25em; */
		color: #5c5d7e;
	}
	${media.md`
	background-color: rgba(255,255,255,.6);
	padding: .5em .8em;
	`};

	span {
		text-align: left;
	}
`;

export const DetailWrapper = styled.div`
	display: flex;
	flex-wrap: wrap;
	justify-content: space-between;

	> *:first-child {
		/* font-weight: bold; */
		/* margin-right: 0.25em; */
		margin-right: 30px;
		color: rgb(98 95 95 / 97%);
	}

	> *:last-child {
		/* margin-left: auto; */
		opacity: 0.85;
	}

	.orderBoldOne {
		color: #373737;
		font-weight: 600;
		opacity: unset;
	}
`;

export const TypeText: any = styled.span`
	font-size: 13px;
	display: flex;
	justify-content: center;
	-webkit-box-pack: center;
	border-radius:2px;
	

	${(props: any) => {
		const { children } = props;

		if (typeof children !== 'string') {
			console.error('[TypeText]: the children prop is expected to be a string');
		}

		const text = children.toLowerCase();

		switch (text) {
			case 'back':
				return css`
					color: rgb(32,57,116);
					background-color: rgb(110,201,252);
					border-radius: 2px;
					padding: 1px 2px;
    				width: 33px;
				`;
			case 'lay':
				return css`
					color:#A61224;
					background-color:#FF93A0;
					border-radius: 2px;
					padding: 1px 2px;
    				width: 33px;
				`;
			case 'yes':
				return css`
					color:rgb(32,57,116) ;
				`;
			case 'no':
				return css`
					color: rgb(236,85,119);
				`;
			default:
				console.error('[TypeText]: children prop should be either "back", "lay", "yes" or "no"!');
				return '';
		}
	}};
`;

export const StatusText: any = styled.div`
	font-weight: bold;

	${(props: any) => {
		const { children } = props;

		if (typeof children !== 'string') {
			console.error('[StatusText]: children prop should be a string!');
			return '';
		}

		const text = children.toLocaleLowerCase();

		if (text === 'won') {
			return css`
				color: ${theme('profit')};
			`;
		} else if (text === 'lost') {
			return css`
				color: ${theme('loss')};
			`;
		} else {
			/* console.error('[StatusText]: children prop should be "won" or "lost'); */
			return '';
		}
	}};
`;

// 只在小尺寸裝置上使用
export const MobileHead: any = styled.div`
	${titleFont};
	font-weight: bold;
	display: none;
	font-size: 13px;
	${media.lg`
		display: block;
	`};
`;

export const MemberTableBodyRow = styled(TableBodyRow)`
	color:black;
	${media.lg`
		flex-direction: column;
	`};
	${media.md`
		background: rgba(255,255,255,0);
		padding: 1em;
	`};
`;

const RemarkHintWrapper = styled.span`
	font-style: italic;
	font-size: 13px;
	color: ${theme('palette.primary-background')};
	margin: 0 0.5em;
`;

// Used in HistoryTable -> Description col

export const RemarkItemTime = styled.div`
	font-size: 11px;
	color: black;
	margin-top: 0.25em;
`;

export const RemarkItemWrapper = styled.div`
	padding: 0.25em 0.5em;
	border-radius: 2px;
	/* border: 1px solid rgba(128, 128, 128, 0.5); */
	background: #FFFFFF;
	color: rgba(36, 36, 36, 0.54);
	padding: 6px 15px;
	display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 5px;

	/* &:not(:last-child) {
		margin-bottom: 0.5em;
	} */
`;

export const RemarkSectionTitle = styled.div`
	/* margin-bottom: 0.25em; */
	font-style: bold;
	background: #FFFFFF;
	color: rgba(36, 36, 36, 0.54);
	padding: 6px 15px;
	border-radius: 2px;
`;

export const RemarkSectionWrapper = styled.div`
	display: flex;
	align-items: center;
	flex-direction: row;
	background: #EDEFF8;
	padding: 5px 10px;
    border-radius: 3px;
    gap: 10px;
`;
