import React from "react";

type Props = {};

export default function Facebook({}: Props) {
  return (
    <svg
      width="21"
      height="21"
      viewBox="0 0 21 21"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clip-path="url(#clip0_805_210)">
        <path
          d="M19.841 21C20.481 21 20.9999 20.481 20.9999 19.841V1.15899C20.9999 0.518754 20.4809 0 19.841 0H1.159C0.518757 0 0 0.518833 0 1.15899V19.8409C0 20.481 0.518757 20.9999 1.159 20.9999L19.841 21Z"
          fill="#485A96"
        />
        <path
          d="M14.4896 20.9999V12.8676H17.2193L17.6279 9.69825H14.4895V7.67478C14.4895 6.75719 14.7443 6.1319 16.0602 6.1319L17.7384 6.13111V3.2965C17.4481 3.25795 16.4519 3.17163 15.293 3.17163C12.8732 3.17163 11.2166 4.64858 11.2166 7.36099V9.69825H8.47998V12.8676H11.2166V20.9998H14.4896V20.9999Z"
          fill="white"
        />
      </g>
      <defs>
        <clipPath id="clip0_805_210">
          <rect width="21" height="21" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
}
