import React from 'react'

type Props = {}

export default function GeneralIcon(props) {

  const _fill=window.location.host;

  return (
    <svg fill="none" xmlns="" viewBox="0 0 18 22"><g clip-path="url(#a)" fill="#fff"><path d="M9.068 19.797c1.79-.032 3.62-.223 5.367-.91.534-.211 1.043-.467 1.465-.87.252-.24.372-.518.354-.875-.096-1.881-.772-3.51-2.091-4.862-.217-.222-.366-.465-.306-.785.06-.323.245-.548.567-.646.333-.101.609.002.847.24a8.797 8.797 0 0 1 1.926 2.87c.407.977.607 2 .676 3.057.07 1.088-.412 1.874-1.246 2.493-.97.72-2.082 1.112-3.239 1.393-2.085.506-4.199.622-6.332.412-1.604-.159-3.171-.47-4.637-1.17a7.242 7.242 0 0 1-.998-.584c-.964-.663-1.424-1.556-1.318-2.756.194-2.202 1.018-4.104 2.569-5.686.269-.275.628-.348.945-.203.316.145.523.468.46.817-.032.186-.13.39-.261.525-1.121 1.162-1.802 2.536-2.03 4.134-.022.158-.017.321-.048.476-.124.606.12 1.04.605 1.382.79.558 1.688.854 2.611 1.086 1.327.332 2.682.447 4.114.462Z"></path><path d="M3.334 6.05C3.333 2.937 5.861.413 8.982.413c3.166 0 5.657 2.524 5.656 5.73-.002 3.048-2.556 5.575-5.635 5.574A5.645 5.645 0 0 1 3.335 6.05h-.001Zm1.614 0c.003 2.23 1.822 4.056 4.035 4.05a4.052 4.052 0 0 0 4.04-4.046c0-2.213-1.832-4.03-4.06-4.027-2.187.003-4.017 1.837-4.015 4.023Z"></path></g><defs><clipPath id="a"><path fill="#fff" transform="translate(.088 .413)" d="M0 0h17.791v21H0z"></path></clipPath></defs></svg>
  )
}


