import { useState } from "react";
import styled from "styled-components";


type StyleProps = {
    direction: string;
    active: boolean;
}


const ImageSlider = ({ data, selectedIndex }) => {
  const [currentIndex, setCurrentIndex] = useState(selectedIndex);

  const prevImage = () => {
    setCurrentIndex((prev) => (prev === 0 ? data.length - 1 : prev - 1));
  };

  const nextImage = () => {
    setCurrentIndex((prev) => (prev === data.length - 1 ? 0 : prev + 1));
  };

  return (
    <div>
      <SliderWrapper>
        <MainImageWrapper>
          <ArrowButton direction="left" onClick={prevImage}>
            ❮
          </ArrowButton>
          <Image src={data[currentIndex]?.filename1} alt="Selected" />
          <ArrowButton direction="right" onClick={nextImage}>
            ❯
          </ArrowButton>
        </MainImageWrapper>
      </SliderWrapper>

      <ThumbnailContainer>
        {data.slice(0, 5).map((img, index) => (
          <Thumbnail
            key={index}
            src={img?.filename1}
            alt={`Thumbnail ${index + 1}`}
            active={index === currentIndex}
            onClick={() => setCurrentIndex(index)}
          />
        ))}
      </ThumbnailContainer>
    </div>
  );
};

export default ImageSlider;



const SliderWrapper = styled.div`
  width: 600px;
  margin: auto;
  position: relative;
  overflow: hidden;
  border-radius: 10px;
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.2);
`;

const Image = styled.img`
  width: 100%;
  height: 350px;
  object-fit: contain;
  transition: opacity 0.5s ease-in-out;
`;

const MainImageWrapper = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const ArrowButton = styled.button<Pick<StyleProps, "direction">>`
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  background: rgba(0, 0, 0, 0.5);
  color: white;
  border: none;
  padding: 10px;
  cursor: pointer;
  font-size: 20px;
  border-radius: 50%;
  transition: 0.3s;
  
  &:hover {
    background: rgba(0, 0, 0, 0.8);
  }

  ${(props) => (props.direction === "left" ? "left: 10px;" : "right: 10px;")}
`;

const ThumbnailContainer = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 10px;
  gap: 5px;
`;

const Thumbnail = styled.img<Pick<StyleProps, "active">>`
  width: 80px;
  height: 50px;
  cursor: pointer;
  border: 2px solid ${(props) => (props.active ? "#ffffff" : "transparent")};
  opacity: ${(props) => (props.active ? "1" : "0.6")};
  transition: all 0.3s;
  object-fit: fill;
  
  &:hover {
    opacity: 1;
  }
`;