
import PlaceholderImage from 'src/components/Placeholder';
import config from 'src/config/config';
import { withAppContext } from 'src/store/initAppContext';
import styled, { withTheme } from 'styled-components';
import { theme } from 'styled-tools';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import FooterContainer from "src/components/footer/footer";

const DoubleCashBackPromo = (props) => {

    const { app: { brandId }, theme } = props;

    const domain = brandId == 31 || brandId == 32 ? "Yolo247" : "BaaziAdda";

    const teams = [
        { imageSvg: `${config.imageV3Host}${theme.image.kkr}` },
        { imageSvg: `${config.imageV3Host}${theme.image.kxip}` },
        { imageSvg: `${config.imageV3Host}${theme.image.srh}` },
        { imageSvg: `${config.imageV3Host}${theme.image.dc}` },
        { imageSvg: `${config.imageV3Host}${theme.image.lsg}` },
        { imageSvg: `${config.imageV3Host}${theme.image.rcb}` },
        { imageSvg: `${config.imageV3Host}${theme.image.csk}` },
        { imageSvg: `${config.imageV3Host}${theme.image.mi}` },
        { imageSvg: `${config.imageV3Host}${theme.image.rr}` },
        { imageSvg: `${config.imageV3Host}${theme.image.gt}` },
    ]
  return (
    <>
        <MainWrapper>
            <ContentWrapper>
                <TeamsSection>
                    {
                        teams.map((elm) => (
                            <LazyLoadImage src={elm.imageSvg} effect="none" placeholder={<PlaceholderImage />} />
                        ))
                    }
                </TeamsSection>
                <CashBackTextWrapper>
                    <PlayerImageWrapper>
                     <img src={`${config.imageV3Host}/static/cashsite/${theme.image.DoubleCashBackPlayers}`} alt='ipl'/> 
                    </PlayerImageWrapper>
                    <TextWrapper>
                        <h1>Free Hit Cashback!</h1>
                        <Text>
                            <p>
                                This IPL, embark on the ultimate cricketing adventure : Boost your thrill with daily deposits, <span className='TextBold'>seize a turbocharged weekly double cashback upto 10%</span>, and elevate the excitement with {domain}!
                            </p>
                            <p>
                                As cricket fever sweeps the nation with the IPL, {domain} has a sensational offer that'll leave you stumped. Get ready to score big and boost your scorecard with an astounding cashback bonus,  with our unbeatable Daily Deposit Bonus, you can bag up to an astonishing 10% cashback! 
                            </p>
                            <p>
                                At the end of each week, you can turn your losses into wins with our sensational cashback bonus. You can avail up to an incredible 10% cashback bonus on your net losses incurred throughout the week.
                            </p>
                            <p>
                                The cashback bonus amount will be credited to your account every Monday. 
                            </p>
                        </Text>
                    </TextWrapper>
                </CashBackTextWrapper>
                <DepositWrapper>
                    <h1>Daily Deposit Scorecard for IPL 2024</h1>
                    <TableWrapper>
                        <LazyLoadImage
                            src={`${config.imageV3Host}/static/cashsite/${theme.image.DoubleCashBackTable}`}
                            alt="jetxPromoStepThree"
                            effect="none"
                            placeholder={<PlaceholderImage />}
                        /> 
                    </TableWrapper>
                </DepositWrapper>
                <TermsAndConsdition>
                            <h1>Terms & Conditions</h1>
                            <ul>
                                <li>This promotion is valid for all new & existing {domain} members.</li>
                                <li>This bonus amount will be capped at INR 7,000</li>
                                <li>Bonus amount will be issued into your Main wallet as a cashback bonus every Monday only if you have successfully deposited all 7 days in a week (Mon-Sun).</li>
                                <li>Multiple accounts will not be considered for this promotion. All players will be subject to duplicate checking. {domain} reserves the right to disqualify players if any collusion or foul play takes place in the promotion.</li>
                                <li>This promotion cannot be used in conjunction with, or to contribute to the eligibility for, any other promotion.</li>
                                <li>{domain} reserves the right to amend, cancel, reclaim, or refuse any promotion at its own discretion.</li>
                                <li> In the event that any fraudulent activity of the player(s) has been identified, {domain} reserves the right to withhold your funds and reject your withdrawals.</li>
                                <li>{domain} General Terms & Conditions apply.</li>
                            </ul>
                    </TermsAndConsdition>
            </ContentWrapper>
        </MainWrapper>
        <FooterContainer />
    </>
)
}

export default withTheme(withAppContext(DoubleCashBackPromo));

//Styled Components

const MainWrapper = styled.div`
    width: 98%;
    height: auto;
    border: 1px solid white;
    display: flex;
    border-radius: 1px;
    background: rgba(111, 111, 111, 0.5);
    margin: 0em 1.2em;
    -webkit-backdrop-filter: brightness(0.5);
    backdrop-filter: brightness(0.5);
`;

const ContentWrapper = styled.div`
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background: url(${config.imageV3Host}/static/cashsite/${theme("image.DoubleCashBackBackGround")});
    background-position: top;
    background-size: cover;
    background-repeat: no-repeat;
    padding: 0 10% 15.5% 10%;

    .lazy-load-image-background {
      display: flex !important;
      justify-content: center !important;
    }
`;

const TeamsSection = styled.div`
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: row;
    padding: 0rem 15rem;
    margin: 3.5rem 0;
    gap: 20px;

    img{
        height: 84px;
        width: 84px;
    }
`;

const CashBackTextWrapper = styled.div `
    display: flex;
    flex-direction: row;
    padding: 4rem 2.3rem;
    border: 2px solid #007940;
    border-radius: 10px;
    padding-bottom: 4.5rem;
    background: url(${config.imageV3Host}/static/cashsite/${theme("image.DCplayerBG")});
    background-size: cover;
    background-position: center;
    /* justify-content: space-evenly; */
    justify-content: flex-start;
    gap: 5rem;
    position: relative;

    ::before {
        content: "";
        background: url(${config.imageV3Host}/static/cashsite/${theme("image.DoubleCashBackCoinTwo")});
        background-position: top;
        background-size: cover;
        background-repeat: no-repeat;
        object-fit: cover;
        height: 74px;
        width: 85px;
        position: absolute;
        right: 10.5%;
        bottom: -5%;
    }

    ::after {
        content: "";
        background: url(${config.imageV3Host}/static/cashsite/${theme("image.DoubleCashBackCoinOne")});
        background-position: top;
        background-size: cover;
        background-repeat: no-repeat;
        object-fit: cover;
        height: 74px;
        width: 70px;
        position: absolute;
        right: -2.5%;
    }
`;

// const PlayerImageWrapper = styled.div`
//     width: 30%;
//     height: auto; /* Added */
//     flex-shrink: 1; /* Added to prevent shrinking */
//     display: flex; /* Added */
//     align-items: center; /* Added */
//     justify-content: center; /* Added */
//     margin-right: 2rem; /* Adjusted */
  
//     & img {
//         max-width: 100%; /* Added */
//         height: auto; /* Added */
//     }
// `;

// const TextWrapper = styled.div`
//     display: flex;
//     flex-direction: column;
//     /* gap: 37px; */
//     width: calc(70% - 2rem); /* Adjusted */

//     h1 {
//         margin-bottom: 0;
//         font-size: 28px;
//         font-style: normal;
//         font-weight: 700;
//         line-height: 35px; /* 125% */
//         text-transform: capitalize;
//         color: #00B560;
//     }
// `;

const PlayerImageWrapper = styled.div `
width: 30%;
    & img {
        /* height: 50%; */
        width: 100%;
    }
`;

const TextWrapper = styled.div `
    display: flex;
    flex-direction: column;
    gap: 37px;
    width: 65%;

    h1 {
        margin-bottom: 0;
        font-size: 28px;
        font-style: normal;
        font-weight: 700;
        line-height: 35px; /* 125% */
        text-transform: capitalize;
        color: #00B560;
    }
`;

const Text = styled.div `
    display: flex;
    flex-direction: column;

    p {
        margin: 0;
        padding-bottom: 1.5rem;
        color: #FFF;
        font-size: 18px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
        text-transform: capitalize;
    }

    span.TextBold {
        font-weight: 700;
    }
`;

const DepositWrapper = styled.div `
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding-top: 4.5rem;

    h1 {
        font-size: 28px;
        font-style: normal;
        font-weight: 700;
        line-height: 35px; /* 125% */
        text-transform: capitalize;   
        color: #00B560;
        align-items: center;
        padding-bottom: 2%;
    }
`;

const TableWrapper = styled.div `
    display: flex;

    img {
        width: 80%;
    }
`;

const TermsAndConsdition = styled.div `
    display: flex;
    flex-direction: column;
    padding-top: 4.5rem;

    h1 {
        font-family: Inter;
        font-size: 28px;
        font-style: normal;
        font-weight: 700;
        line-height: 35px; /* 125% */
        text-transform: capitalize;
        color: #00B560;
    }

    ul {
        padding-left: 20px;
        li {
            color: #FFF;
            text-align: justify;
            font-family: Inter;
            font-size: 18px;
            font-style: normal;
            font-weight: 400;
            line-height: 32px; /* 177.778% */
            text-transform: capitalize;
        }
    }
`;