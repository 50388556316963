import React from 'react';
import styled from 'styled-components';
import { ifProp, theme } from 'styled-tools';
// Styles
import { inputStyle } from '../common/commonCSSStyles';

// Util
import oddsUtil from '../../util/oddsUtil';

interface INumberInputProps {
	// 必須是歐洲盤
	initialNumber: number;
	onInputChange?: (value: string) => void;
	mobileMode?: boolean;
	// 歐洲盤或美國盤
	oddType?: string;
}
interface INumberInputState {
	// 必須是歐洲盤
	number: string;
}

enum EChangeType {
	Increment = 'increment',
	Decrement = 'decrement',
}
export enum EOddsType {
	EU = 'EU',
	AM = 'AM',
}

export default class NumberInput extends React.PureComponent<INumberInputProps, INumberInputState> {
	static defaultProps = {
		initialNumber: 0,
	};
	constructor(props: INumberInputProps) {
		super(props);
		const { initialNumber } = props;
		this.state = {
			number: typeof initialNumber === 'number' ? initialNumber.toString() : '0',
		};
	}

	// 為行動裝置寬度時會只有單筆顯示
	static getDerivedStateFromProps = (nextProps: INumberInputProps, prevState: INumberInputState) => {
		if (Number(nextProps.initialNumber) !== Number(prevState.number)) {
			return {
				number: nextProps.initialNumber.toString(),
			};
		}
		return null;
	};

	changeNumber = (type: EChangeType) => {
		this.setState((prevState, props) => {
			let result;
			const preNumber = Number(prevState.number);
			const incrementAmount = getIncrementAmount(preNumber);
			switch (type) {
				case EChangeType.Increment:
					result = preNumber + incrementAmount;
					break;
				case EChangeType.Decrement:
					result = preNumber - incrementAmount;
					break;
				default:
					result = preNumber;
					console.warn('NumberInput: type of `changeNumber` should be either increment or decrement!');
			}
			result = (Math.round(result * 100) / 100).toString();
			this.props.onInputChange && this.props.onInputChange(result);
			return {
				number: result,
			};
		});
	};
	increment = () => {
		this.changeNumber(EChangeType.Increment);
	};
	decrement = () => {
		this.changeNumber(EChangeType.Decrement);
	};
	handleNumberChange = (e: any) => {
		if (/^(\d*\.?\d*)$/.test(e.target.value)) {
			this.setState({ number: e.target.value });
			this.props.onInputChange && this.props.onInputChange(e.target.value);
		}
	};

	handleOnClickInput(e: any) {
		e.target.select();
	}

	render() {
		const { mobileMode, oddType } = this.props;
		return (
			<NumberInputWrapper mobileMode={mobileMode}>
				<ChangeButton onClick={this.decrement} mobileMode={mobileMode}>
					-
				</ChangeButton>
				<Input
					value={oddType === EOddsType.AM ? oddsUtil.EUtoAM(Number(this.state.number)) : this.state.number}
					onChange={this.handleNumberChange}
					type="number"
					onClick={this.handleOnClickInput}
					mobileMode={mobileMode}
					// 關閉數字區間提示
					step="any"
				/>
				<ChangeButton onClick={this.increment} mobileMode={mobileMode}>
					+
				</ChangeButton>
			</NumberInputWrapper>
		);
	}
}

const getIncrementAmount = (p: number) => {
	if (p < 2) {
		return 0.01;
	}
	if (2 <= p && p < 3) {
		return 0.02;
	}
	if (3 <= p && p < 4) {
		return 0.05;
	}
	if (4 <= p && p < 5) {
		return 0.1;
	}
	if (5 <= p && p < 10) {
		return 0.2;
	}
	if (10 <= p && p < 20) {
		return 0.5;
	}
	if (20 <= p && p < 30) {
		return 1.0;
	}
	if (20 <= p && p < 30) {
		return 1.0;
	}
	if (30 <= p && p < 50) {
		return 2.0;
	}
	if (50 <= p && p < 100) {
		return 5.0;
	}
	if (100 <= p) {
		return 10.0;
	}
	return 0.01;
};

// Styled components
const Input: any = styled.input.attrs(props => ({
	type: 'number',
}))`
	${inputStyle};
	text-align: center;
	font-size: 13px;
	color: ${theme('palette.body-foreground')};
	font-size: ${ifProp('mobileMode', '16px')};
	width: 4.4em;
	flex-grow: ${ifProp('mobileMode', 1, 0)};
	min-width: ${ifProp('mobileMode', '0')};
`;

const ChangeButton: any = styled.button.attrs(props => ({
	// focus 在 input field 時，按 tab 不 focus 在 increment/decrement 按鈕上
	tabIndex: -1,
}))`
	display: flex;
	justify-content: center;
	align-items: center;
	flex-shrink: 0;
	// width: 1.25em;
	width: 1.5em;
	color: ${theme('palette.betSlipChangeButtonColor')};
	background: ${theme('palette.betSlipChangeButtonBgColor')};
	border: 0;
	cursor: pointer;
	touch-action: manipulation;
	&:focus {
		outline: 0;
	}

	font-size: ${ifProp('mobileMode', '18px')};
	text-shadow: ${ifProp('mobileMode', '0 1px 0 rgba(0, 0, 0, 0.25)', 'none')};
	padding: ${ifProp('mobileMode', '0.5em', '0.25em')};
	${ifProp('mobileMode', 'width: 32px')};
`;

const NumberInputWrapper: any = styled.div`
	display: flex;
	border-radius: 2px;
	overflow: hidden;
	flex-grow: ${ifProp('mobileMode', 1, 0)};
`;
