import React, { useEffect } from 'react'
import { withAppContext } from 'src/store/initAppContext';
import styled, { withTheme } from 'styled-components';
import { LazyLoadImage } from "react-lazy-load-image-component";
import config from "src/config/config";
import PlaceholderImage from 'src/components/Placeholder';
import { theme } from 'styled-tools';
import { Divider, UnderLine } from './FomoWelcomeBonus';

function FomoWReferralBonus(props) {
    const { app: {
        brandId
    }, theme } = props;

    useEffect(() => {
        window.scrollTo(0, 0)
    }, [])


    return (
        <InformationWrapper>
            <TitleText>
                <h6>An offer that you cannot refuse!</h6>
                <h6>FOMO7 offers the highest online casino referral bonus - 21%.</h6>
            </TitleText>
            <Divider></Divider>
            <LoyaltyWrapper>
                <ReferralText>
                    <h1>FOMO7 Referral Bonus | Best Online Betting</h1>
                    <h1>Referral Bonus in India</h1>
                </ReferralText>
                <UnderLine></UnderLine>
                <HeadingText className="joiningTxt">
                    <p className='bonusText'>At FOMO7, we value your feedback. Through word-of-mouth, we have expanded thus far. That is when</p>
                    <p className='bonusText'>we decided to reward every referral with a whopping 21% bonus.</p>
                    <p>Firstly, we thank you for being a valuable customer. The refer-a-friend program is curated exclusively for you</p>
                </HeadingText>
            </LoyaltyWrapper>
            <JoiningWrapper>
                <JoiningContainer>
                    <JoiningContent>
                        <LazyLoadImage
                            src={`${config.imageV3Host}/static/cashsite/${theme.image.FomoReffImgTwo}`}
                            alt="jetxPromoStepThree"
                            effect="none"
                            placeholder={<PlaceholderImage />}
                        />
                        <p>Fomo7 Referral Bonus is available for all users: <span>Old users & New users.</span></p>
                    </JoiningContent>
                    <JoiningContent>
                        <LazyLoadImage
                            src={`${config.imageV3Host}/static/cashsite/${theme.image.FomoReffImgOne}`}
                            alt="jetxPromoStepThree"
                            effect="none"
                            placeholder={<PlaceholderImage />}
                        />
                        <p>Refer <span>Easy </span>Earn <span>Easier.</span></p>
                    </JoiningContent>
                </JoiningContainer>
                <HeadingText className='referraltext'>
                    <h1>How to get a 21% Referral Bonus on Fomo7?</h1>
                </HeadingText>
                <UnderLine></UnderLine>
                <HeadingText>
                    <p>Here is how you can earn the Fomo7 Referral Bonus.</p>
                </HeadingText>
                <StepsWrapper>
                    <LazyLoadImage
                        src={`${config.imageV3Host}/static/cashsite/${theme.image.ReferralBonusStepsFomo}`}
                        alt="jetxPromoStepThree"
                        effect="none"
                        placeholder={<PlaceholderImage />}
                    />
                </StepsWrapper>
            </JoiningWrapper>
            <ROWrapper>
                <LazyLoadImage
                    src={`${config.imageV3Host}/static/cashsite/${theme.image.RoReqReferralImgFomoDesk}`}
                    alt="jetxPromoStepThree"
                    effect="none"
                    placeholder={<PlaceholderImage />}
                />
            </ROWrapper>
            <FormulaWrapper>
                <FormulaHeadingText>
                    <h1>What is the RO requirement for FOMO7</h1>
                    <h1>Referral Bonus?</h1>
                </FormulaHeadingText>
                <UnderLine></UnderLine>
                <FormulaContent>
                    <p>Like all online casinos, we also have an RO (Rollover) requirement. However, the advantage you get on Fomo7 is that the Rollover is just 3x of the deposit amount.</p>
                    <p>HAssume that your friend registers on Fomo7 with your referral code.</p>
                    <ul>
                        <li>Your friend deposits Rs.1000.</li>
                        <li>Your eligible referral bonus is 7% = Rs.70.</li>
                        <li>Your friend completes the RO requirement of 3x the deposit amount. (3 x Rs.1000 = Rs.3000)</li>
                    </ul>
                    <p>You will receive Rs.70 in your wallet instantly. The exact process continues when your friend redeposits for the second and the third time.</p>
                    <p>So, you will receive a total of 21% for all three redeposits. The bonus will expire in 7 days after you receive the amount.</p>
                </FormulaContent>
            </FormulaWrapper>
            <PlWrapper>
                <LazyLoadImage
                    src={`${config.imageV3Host}/static/cashsite/${theme.image.RoReqRererralImgFomoDesk}`}
                    alt="jetxPromoStepThree"
                    effect="none"
                    placeholder={<PlaceholderImage />}
                />
            </PlWrapper>
            <Termsandconditions>
                <TermsandconditionsHeading>
                    <h1>Terms & Conditions</h1>
                </TermsandconditionsHeading>
                <UnderLine></UnderLine>
                <TermsandconditionsBody>
                    <ol>
                        <li>This bonus applies only to the first three deposits made by the referred user.</li>
                        <li>Only one referral bonus will be active at a time. If the bonus of the first deposit is active, we will queue the bonus of the second deposit.</li>
                        <li>You will receive the bonus in your main wallet after the completion of the first deposit and rollover requirements by your friend. It applies to the second and the third deposits as well.</li>
                        <li>3x of the deposit amount is the Rollover. Your friend can use it for both casino games and sports betting.</li>
                        <li>The referral bonus will expire after seven days from the date of activation. The validity period for the referral bonus will be three months. The bonus scheme will automatically expire if the user does not deposit within this period.</li>
                        <li>The valid Rollover is calculated by "market" for sports or by "round" for casinos. So, you could place multiple bets in 1 market in sports or multiple bets in 1 round of casino. However, the total Profit and Loss to the total stake ratio must be greater than or equal to 70%. Please note that in exchange, match odds provided by Betfair and multiple bookmakers will be considered as one market.</li>
                        <li>Void/canceled/draw and cashed-out bets do not count towards the rollover requirements.</li>
                        <li>FOMO7 does not consider multiple accounts for this promotion. All players will be subject to duplicate checking. FOMO7 reserves the right to disqualify players if collusion or foul play occurs in the promotion.</li>
                        <li>This promotion cannot be used in conjunction with or to contribute to the eligibility for any other promotion.</li>
                        <li>FOMO7 reserves the right to amend, cancel, reclaim or refuse any promotion at its discretion.</li>
                        <li>You cannot withdraw the bonus amount, but the winnings from the bonus are withdrawable.</li>
                        <li>If any fraudulent activity of the player(s) has been identified, FOMO7 reserves the right to withhold your funds and reject your withdrawals.</li>
                        <li>FOMO7 general terms & conditions apply.</li>
                        <li>Rollover is not valid for any of the P2P games.</li>
                    </ol>
                </TermsandconditionsBody>
            </Termsandconditions>
        </InformationWrapper>
    )
}



export default withTheme(withAppContext(FomoWReferralBonus));

export const InformationWrapper = styled.div`
    width: 98%;
    height: auto;
    padding: 3rem 3rem 8rem 3rem;
    border: 1px solid #E5B358;
    border-radius: 0 0 20px 20px;
    border-top-color: transparent;
    background: rgba(111, 111, 111, 0.5);
    margin: 0em 1.2em 1.2em 1.2em;
    backdrop-filter: brightness(0.5);    
    display: flex;
    flex-direction: column;
    background: url(${config.imageV3Host}/static/cashsite/${theme("image.fomoReferralBonusBgDesktop")});
    background-position: top;
    background-size: cover;
    background-repeat: no-repeat;
`;

const TitleText = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    padding: 1rem 0 4rem 0;

    h6 {
        margin: 0;
        padding: 0;
        color: #FFF;
        text-align: center;
        font-size: 16px;
        font-style: normal;
        font-weight: 600;
        line-height: 160.3%; /* 25.648px */
    }
`;

const LoyaltyWrapper = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background: radial-gradient(37.24% 47.13% at 50% 0%, #051829 0%, rgba(1, 4, 6, 0.00) 100%);
    backdrop-filter: blur(6.849999904632568px);
    padding: 3rem;
    margin: 0 -2rem;

    .joiningTxt {
        padding-top: 1.5rem;
    }
`;

const HeadingText = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;

    h1 {
        text-align: center;
        font-size: 25px;
        font-style: normal;
        font-weight: 600;
        line-height: normal;
        letter-spacing: 1.25px;
        background: linear-gradient(180deg, #FED546 18.06%, #FE7C23 91.67%);
        background-clip: text;
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
    }

    p {
        color: #FFF;
        text-align: center;
        font-size: 16px;
        font-style: normal;
        font-weight: 500;
        line-height: 120.3%; /* 19.248px */
    }

    .bonusText {
        margin: 0;
        padding: 0;
    }
`;

const ReferralText = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    padding: 1rem;

    h1 {
        margin: 0;
        text-align: center;
        font-size: 25px;
        font-style: normal;
        font-weight: 600;
        line-height: normal;
        letter-spacing: 1.25px;
        background: linear-gradient(180deg, #FED546 18.06%, #FE7C23 91.67%);
        background-clip: text;
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
    }
`;

const BenifitText = styled.div`
    display: flex;
    flex-direction: column;
    padding-top: 1.2rem;


    p {
        margin: 0;
        padding: 0;
        color: #FFF;
        text-align: center;
        font-size: 16px;
        font-style: normal;
        font-weight: 500;
        line-height: 120.3%; /* 19.248px */
    }
`;

const JoiningWrapper = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    margin: 0 -3rem;

    .referraltext {
        padding-top: 3rem;
    }
`;

const JoiningContainer = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    gap: 3rem;
    padding-top: 2rem;
    align-items: stretch;
`;

const JoiningContent = styled.div`
    padding: 2rem 3rem;
    width: 36%;
    border-radius: 20px;
    background: radial-gradient(71.58% 74.35% at 50.18% -11.77%, #112333 0%, #010406 100%);
    position: relative;
    display: flex;
    flex-direction: column;
    text-align: center;

    ::after {
        position: absolute;
        top: -1px; bottom: 0px;
        left: -1px; right: -1px;
        background: linear-gradient(180deg, #666, transparent);
        content: '';
        z-index: -1;
        border-radius: 20px;
    }

    p {
        margin: 0;
        color: #FFF;
        text-align: center;
        font-size: 16px;
        font-style: normal;
        font-weight: 500;
        line-height: 120.3%; /* 19.248px */
    }

    span {
        color: #FEBA3B;
    }

    img {
        width: 50%;
    }
`;

const StepsWrapper = styled(JoiningContainer)`
    padding: 3rem 0;
    justify-content: center;
    align-items: center;
    text-align: center;
    position: relative;
    
    img {
        width: 80%;
    }
`;

const ROWrapper = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    position: relative;
    img {
        width: 90%;
    }
`;

const PlWrapper = styled(ROWrapper)`
    padding-bottom: 4rem;
`;


const FormulaWrapper = styled(JoiningWrapper)`
    justify-content: flex-start;
    align-items: flex-start;
    background: url(${config.imageV3Host}/static/cashsite/${theme("image.formulaRoBgReferralDesktop")});
    background-position: top;
    background-size: cover;
    background-repeat: no-repeat;
    text-align: left;
    padding: 6rem 0 6rem 10rem;
`;

const FormulaHeadingText = styled(HeadingText)`
    align-items: flex-start;
    padding-bottom: 1.5rem;
    h1 {
        margin: 0;
    }
`;

const FormulaContent = styled(BenifitText)`
    padding-top: 2.2rem;
    width: 37%;
    text-align: left;

    p {
        margin: 0;
        padding: 0.8rem 0;
        text-align: left;
    }
    ul {
        margin: 0 0 0 1rem;
        padding: 0;
        color: white;
    }
    li {
        padding: 0.5rem 0 0 0;
    }
`;

const Termsandconditions = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    border-radius: 30px;
    width: 85%;
    height: auto;
    margin: 1rem 7.5% 3rem 7.5%;
    padding: 5rem 3rem;
    position: relative;
    background: linear-gradient(#020405, rgba(0, 0, 0, 0.935), #020405);
    background-clip: padding-box;
    border: 2px solid transparent;

    ::after {
        position: absolute;
        top: -2px; bottom: -2px;
        left: -2px; right: -2px;
        background: linear-gradient(90deg, #53C1FF, black, #53C1FF, black, #53C1FF);
        content: '';
        z-index: -1;
        border-radius: 30px;
    }
`;

const TermsandconditionsHeading = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;    
    background: linear-gradient(180deg, #FED546 18.06%, #FE7C23 121.53%);
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    padding-bottom: 2rem;

    h1 {
        margin: 0;
        padding: 0;
        text-align: center;
        font-size: 25px;
        font-style: normal;
        font-weight: 600;
        line-height: normal;
        letter-spacing: 1.25px;
    }
`;

const TermsandconditionsBody = styled.div`
    ol {
        display: flex;
        flex-direction: column;
        justify-content: center;
        gap: 1rem;
        padding-top: 1rem;
    }
    li {
        color: #FFF;
        font-size: 16px;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
    }
`;