import React, { useState, useEffect } from 'react'
import {
    SvgIcon,
    TableBodyWrapper,
    TableHeadRow,
    TableWrapper,
    TypeCol,
} from 'src/components/udb/commonStyle';
import Intl from '../common/Intl';
import groupBy from 'lodash/groupBy';

// Store
import styled, { css } from 'styled-components';
import { ifProp } from 'styled-tools';
import { TableSubheadTexts2 } from 'src/pages/udb/betdetails/TurnoverStatement';
import EmptyRow from '../common/table/feature/EmptyRow';
import TooltipIcon from '../icon/TooltipIcon';

type StyleProps = {
    status?: string;
    isOpened?: boolean;
    displayState?: boolean;
}


type turnoverDatas = {
    BetAmount: string,
    TurnOver: string,
    UserName: string,
    WinLossAmount: string,
    eventId: string,
    game: string,
    platform: string,
    provider: string,
    settleDate: string,
    settleTime: string
}

type turnoverDataArrayProps = {
    turnoverDataArray: {
        data: turnoverDatas[];
        total_turnover: string;
    }
}

const TurnoverStatementTable = ({ turnoverDataArray }: turnoverDataArrayProps) => {
    const [tableData, setTableData] = useState({});

    const [toggleState, setToggleState] = useState({
        initialOpen: false
    })

    useEffect(() => {
        var resultData = groupBy(turnoverDataArray, "settleDate");
        setTableData(resultData)
    }, [turnoverDataArray])

    const dataWiseRecords = Object.keys(tableData);

    return (
        <TurnoverTableWrapper>
            <TableBodyWrapper style={{ padding: "0px" }}>
                <>
                    <TurnoverTableWrapper>
                        <TableHeadRow style={{ backgroundColor: "#ffffff", boxShadow: "0px 0px 5px rgb(19 14 74 / 40%)" }}>
                            <TypeCol >
                                <TableHeads><Intl langKey="TUNOVERStatement@Platform" /></TableHeads>
                            </TypeCol>
                            <TypeCol>
                                <TableHeads><Intl langKey="TUNOVERStatement@Provider" /></TableHeads>
                            </TypeCol>
                            <TypeCol>
                                <TableHeads><Intl langKey="TUNOVERStatement@GameName" /></TableHeads>
                            </TypeCol>
                            <TypeCol>
                                <TableHeads><Intl langKey="TUNOVERStatement@BetAmount" /></TableHeads>
                            </TypeCol>
                            <TypeCol>
                                <TableHeads><Intl langKey="TUNOVERStatement@Winloss" /></TableHeads>
                            </TypeCol>
                            <TypeCol>
                                <TableHeads><Intl langKey="TUNOVERStatement@Turnover" /></TableHeads>
                            </TypeCol>
                            <TypeCol>
                                <TableHeads><Intl langKey="TUNOVERStatement@SettleTime" /></TableHeads>
                            </TypeCol>
                        </TableHeadRow>
                        <TableBodyWrapper style={{ padding: "0px" }}>

                            {dataWiseRecords.length > 0 &&
                                dataWiseRecords.map((date) => {
                                    const records = tableData[date] || [];
                                    return (
                                        <>
                                            <TableDateSec>{date}</TableDateSec>
                                            {records.length > 0 && records.map((record) => (<React.Fragment key={record.event_id}>
                                                <TableSubheadRow style={{ padding: "5px 5px" }} isOpened={toggleState.initialOpen}>
                                                    <TypeCol>
                                                        <TableSubheadTexts2>{record.platform}</TableSubheadTexts2>
                                                    </TypeCol>
                                                    <TypeCol>
                                                        <TableSubheadTexts2>
                                                            {record.provider}
                                                        </TableSubheadTexts2>
                                                    </TypeCol>
                                                    <TypeCol>
                                                  
                                                        <ToolTipSec >
                                                            <TableSubheadTexts2 className="hideGameName">{record.game}</TableSubheadTexts2>
                                                            {/* <TooltipIcon /> */}
                                                            <h6>
                                                                {record.game}
                                                            </h6>
                                                        </ToolTipSec>
                                                    </TypeCol>
                                                    <TypeCol>
                                                        <TableSubheadTexts2>{Number(record.BetAmount).toFixed(2)}</TableSubheadTexts2>
                                                    </TypeCol>
                                                    <TypeCol>
                                                        <WinLossText status={record.WinLossAmount > 0 ? "won" : "fail"}>{Number(record.WinLossAmount).toFixed(2)}</WinLossText>
                                                    </TypeCol>
                                                    <TypeCol>
                                                        <TableSubheadTexts2>{Number(record.TurnOver).toFixed(2)}</TableSubheadTexts2>
                                                    </TypeCol>
                                                    <TypeCol>
                                                        <TableSubheadTexts2>{record.settleTime}</TableSubheadTexts2>
                                                    </TypeCol>
                                                </TableSubheadRow></React.Fragment>))}
                                        </>
                                    )
                                })}

                        </TableBodyWrapper>
                        {!dataWiseRecords.length && <EmptyRow />}
                    </TurnoverTableWrapper>
                </>
            </TableBodyWrapper>
        </TurnoverTableWrapper>
    )
}

export default TurnoverStatementTable

// Styled components
export const MarketColContentWrapper = styled.div`
	display: flex;
	flex-direction: column;
	align-items: flex-start;
	color: #647186;
    font-size: 12px;

	> div {
		justify-content: flex-start;
    	width: 170px;
		font-size: 12px;
		text-align: left;
		font-weight: 600;
	}
`;
export const TableSubheadRow = styled.div<Pick<StyleProps, "isOpened">>`
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
	font-weight: bold;
	text-transform: uppercase;

	color: #524976;
	margin: 0px 0px 5px 0px;
	font-size: 13px;
	border-radius: 3px;

    ${ifProp(
    /* Default is circle-up-chevron */
    'isOpened',
    css`
			background-color: #A6C2F940;
		`,
    css`
		 	background: #ffffff;
		`,
)};
`;
const TurnoverTableWrapper = styled(TableWrapper)`
    margin: 5px 15px 0px 0px;
    background-color: transparent !important;
    border: none;
`
const WinLossText = styled.div<Pick<StyleProps, "status">>`
      font-size: 11px;
      color: ${props => props.status === "won" ? "#ffffff" : props.status === "fail" ? "#ffffff" : "#505050"};
      background-color: ${props => props.status === "won" ? "#03BC58" : "#EC476B"};
      padding: 5px;
`
const TableDateSec = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    font-weight: bold;
    text-transform: uppercase;
    justify-content: center;
    color: #625e5e;
    background: linear-gradient(90deg,rgb(83 112 157 / 0%) 0%,rgb(209 213 215) 57.17%,rgb(130 158 201 / 0%) 100%);
    padding: 5px;
    font-size: 13px;
    border-radius: 3px;
    margin: 10px 0px 5px 0px;
`

export const TableHeads = styled.span`
    color: #000000;
`

const ToolTipSec = styled.div`
    width: auto;
    position: relative;
    transform: 700ms;
    height: auto;
    /* min-height: 22px; */

    & h6 {
            font-size: 12px;
            margin: 3px 0px;
            color: white;
            width: 200px;
            height: auto;
            padding: 10px;
            background-color: #6778E3;
            display: none;
            position: absolute;
            right: 0px;
            top: 20px;
            cursor: pointer;
            z-index: 99;
    }

    :hover{
             h6 {
                display: block;
            }
        }
    & svg {
        cursor: pointer;
        width: 20px;
        height: 20px;
    }

    & .hideGameName{
        width: 100px;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        display: block;
    }
`