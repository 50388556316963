import React from 'react'

type Props = {}

const UPIIcon = (props: Props) => {
  return (
    <svg width="22" height="21" viewBox="0 0 22 21" fill="none" xmlns="http://www.w3.org/2000/svg">
<g clip-path="url(#clip0_4316_3897)">
<path fill-rule="evenodd" clip-rule="evenodd" d="M7.64795 19.0402L17.8733 9.71236L13.0298 0.0625L7.64795 19.0402Z" fill="#097939"/>
<path fill-rule="evenodd" clip-rule="evenodd" d="M4.20361 19.0402L14.4289 9.71236L9.58543 0.0625L4.20361 19.0402Z" fill="#ED752E"/>
</g>
<defs>
<clipPath id="clip0_4316_3897">
<rect width="20.704" height="20.704" fill="white" transform="translate(0.814453 0.0625)"/>
</clipPath>
</defs>
</svg>

  )
}

export default UPIIcon