// import isEqual from 'lodash/isEqual';
import { useSubscription } from '@apollo/client';
import { useIdleTimeout } from '../eu-content/IdleTimeout';
import { SELECTION_INFO } from '../../graphql/subscription';
import { IOrderTicket } from '../../store/OrderListContext';
import util from '../../util/util';


interface IProps {
    variables: IOrderTicket[];
    children: (param: { data: IOrderTicket[]; loading?: boolean }) => any;
}

const SubscribeOrderSlip: React.FC<IProps> = props => {
    const { data, loading } = useSubscription(
        SELECTION_INFO,
        {
            variables: { input: props.variables },
            shouldResubscribe: true
        },
    );
    const { isSessionStop } = useIdleTimeout('3');

    if (!(global as any).document || props.variables.length < 1) {
        return props.children({ data: props.variables });
    }

    const variables = props.variables.map((item: IOrderTicket) => ({
        marketId: item.marketInfo.marketId,
        selectionId: item.selectionInfo
            ? item.selectionInfo.selectionId
            : item.fancyInfo
                ? item.fancyInfo.selectionId
                : '',
        side: item.type.toUpperCase(),
        marketType: item.marketInfo.marketType,
        marketSource: item.marketInfo.marketSource,
    }));

    if (isSessionStop) {
        return props.children({ data: props.variables, loading: false })
    }
    else {
        let newBetArray: any = props.variables;
        if (data && data.selectionInfo) {
            newBetArray = newBetArray.map((item: any) => {
                const targetData =
                    data.selectionInfo.find(
                        (g: any) =>
                            g.marketId === item.marketInfo.marketId &&
                            g.selectionId === item.selectionInfo.selectionId &&
                            util.getValue(g, ['side'], '') === item.type,
                    ) || {};
                return { ...item, price: targetData.price || item.price };
            });
        }
        return props.children({ data: newBetArray, loading });
    }



    // return isSessionStop ? (
    // 	props.children({ data: props.variables, loading: false })
    // ) : (
    // 	<Subscription subscription={SELECTION_INFO} variables={{ input: variables }} shouldResubscribe={true}>
    // {({ data, loading }) => {
    // 	let newBetArray: any = props.variables;
    // 	if (data && data.selectionInfo) {
    // 		newBetArray = newBetArray.map((item: any) => {
    // 			const targetData =
    // 				data.selectionInfo.find(
    // 					(g: any) =>
    // 						g.marketId === item.marketInfo.marketId &&
    // 						g.selectionId === item.selectionInfo.selectionId &&
    // 						util.getValue(g, ['side'], '') === item.type,
    // 				) || {};
    // 			return { ...item, price: targetData.price || item.price };
    // 		});
    // 	}
    // 	return props.children({ data: newBetArray, loading });
    // }}
    // 	</Subscription>
    // );
};

export default SubscribeOrderSlip;
