import React from 'react';

// Dependent Components
import { Income } from '../../../common/NumberDisplay';
import Icon from '../../../common/PNGIcon';
import Spinner from '../../../common/Spinner';
import NumberInput from '../../NumberInput';
import CountDown from '../CountDown';
import UnmatchedSlipContainer, {
	IUnmatchedSlipProps,
} from './UnmatchedSlipContainer';
import Intl from '../../../common/Intl';
import {
	ActionButtonContainer,
	CancelButtonWrapper,
	ConfirmSection,
	FieldGroupWrapper,
	FieldLabel,
	FieldWrapper,
	IconButtonWrapper,
	InputField,
	InputSection,
	MessageWrapper,
	PlaceButtonWrapper,
	ProfitText,
	SlipEventName,
	SlipHeadingWrapper,
	SlipMask,
	SlipSelectionName,
	SlipTitleWrapper,
	SlipWrapper,
	StakeButton,
	StakeButtonWrapper,
	StakeSection,
} from './slipStyles';

// Utils
import { ESlipState } from './OrderSlipContainer';
import { calculateProfitLoss } from '../../../../util/formulae';
import { getGTMId } from '../../../../util/gtmUtil';

// will be placed inside of MatchListContextConsumer & OrderListContext, rendered with unmatched data
// Inside of OrderSlipList component
const DesktopUnmatchedSlip: React.SFC<IUnmatchedSlipProps> = props => {
	return (
		<UnmatchedSlipContainer {...props}>
			{unmatchedSlipStore => {
				const {
					// container props
					side,
					competitionId,
					eventId,
					selectionName,
					eventName,
					quickStakes,
					orderNumber,
					betDelay,
					isMatchLoading,
					provider,
					// keyName, 
					// container state
					isUpdating,
					isCanceling,
					currentPrice,
					currentStake,
					message,

					// container methods
					goToEvent,
					handleOddsChange,
					handleStakeChange,
					handleOnClickInput,
					handleTrashClick,
					handleQuickStake,
					handleUpdateOrder,

					// others
					slipRef,
				} = unmatchedSlipStore;
				return (
					<SlipWrapper type={side} ref={slipRef}>
						{isMatchLoading &&
							// 有任何 unmatched order 正在更新
							(isUpdating ? (
								// 這張 unmatched order 正在更新
								<CountDown startFrom={betDelay} />
							) : (
								// 其他 unmatched order
								<SlipMask />
							))}
						{isCanceling && <Spinner mode="client" />}
						{!isUpdating && message && (
							<MessageWrapper type={ESlipState.Error}>
								<Icon name="circle-x-color" />
								{message}
							</MessageWrapper>
						)}
						<SlipHeadingWrapper type={side}>
							<SlipTitleWrapper>
								<SlipEventName onClick={goToEvent} hasPath={competitionId && eventId}>
									{eventName}
								</SlipEventName>
								<SlipSelectionName>{selectionName}</SlipSelectionName>
							</SlipTitleWrapper>
							{!orderNumber.includes('unmatched') && (
								<IconButtonWrapper
									onClick={handleTrashClick}
									id={getGTMId(`remove_unmatched_order_${orderNumber}`)}
								>
									<Icon name="x-orderslip" />
								</IconButtonWrapper>
							)}
						</SlipHeadingWrapper>
						<InputSection type={side}>
							<FieldGroupWrapper>
								<FieldWrapper>
									<FieldLabel>
										<Intl langKey="Odds" />
									</FieldLabel>
									<NumberInput initialNumber={currentPrice} onInputChange={handleOddsChange} />
								</FieldWrapper>
								<FieldWrapper>
									<FieldLabel>
										<Intl langKey="Stake" />
									</FieldLabel>
									<InputField
										value={currentStake}
										onChange={handleStakeChange}
										onClick={handleOnClickInput}
										type="number"
										pattern="\d*"
									/>
								</FieldWrapper>
								<FieldWrapper>
									<FieldLabel>
										<Intl
											langKey={
												side === 'BACK'
													? 'Profit'
													: side === 'LAY'
													? 'Loss'
													: 'GLOBAL@PLACEHOLDER'
											}
										/>
									</FieldLabel>
									<ProfitText type={side}>
										<Income>{calculateProfitLoss(currentPrice, Number(currentStake), side)}</Income>
									</ProfitText>
								</FieldWrapper>
							</FieldGroupWrapper>
						</InputSection>
						<ConfirmSection>
							<ActionButtonContainer isActive={true} isColumn={true}>
								<StakeSection>
									{quickStakes.map((stakeValue, i) => {
										return (
											<StakeButtonWrapper key={i} onClick={handleQuickStake(stakeValue)}>
												<StakeButton>+{stakeValue}</StakeButton>
											</StakeButtonWrapper>
										);
									})}
									<StakeButtonWrapper onClick={handleQuickStake(0)}>
										<StakeButton>
											<Intl langKey="Clear" />
										</StakeButton>
									</StakeButtonWrapper>
								</StakeSection>
								<div style={{ display: 'flex' }}>

								<CancelButtonWrapper
										onClick={handleTrashClick}
										id={getGTMId(`remove_unmatched_order_button_${orderNumber}`)}
									>
										<Icon name="trash-orderslip" />
										<Intl langKey="CANCEL" />
									</CancelButtonWrapper>
									
									<PlaceButtonWrapper
										onClick={handleUpdateOrder}
										disabled={
											!currentStake ||
											parseFloat(currentStake) <= 0 ||
											!orderNumber ||
											orderNumber.includes('unmatched')
										}
										id={getGTMId(`update_unmatched_order_${orderNumber}`)}
									>
										<Icon name="check-white" />
										<Intl langKey="UPDATE" />
									</PlaceButtonWrapper>
									
								</div>
							</ActionButtonContainer>
						</ConfirmSection>
					</SlipWrapper>
				);
			}}
		</UnmatchedSlipContainer>
	);
};

export default DesktopUnmatchedSlip;
