import { format } from 'date-fns';
import groupBy from 'lodash/groupBy';
import React, { Fragment } from 'react';
import styled from 'styled-components';
import { withProp, theme } from 'styled-tools';

// Components
import { Amount, Income } from '../../components/common/NumberDisplay';
import Openable, { IOpenableDelegation } from '../../components/common/Openable';
import { Col } from '../../components/common/table/baseTableStyles';
import {
	TableBodyWrapper,
	TableHeadRow,
	TableWrapper,
	TypeCol,
} from 'src/components/udb/commonStyle';
import EmptyRow from '../../components/common/table/feature/EmptyRow';
import Intl from '../common/Intl';
import DetailTable, { ITotalInfo } from '../../components/member/DetailTable';
import { color } from 'src/sass-files/variable';

// Store
import { TimeZone } from '../../store/TimeZoneContext';

// Utils
import { IOrder, ITransaction } from '../../graphql/schema';
import { diffColor } from '../../util/colorUtil';
import media from '../../util/mediaUtil';
import util from '../../util/util';
import Spinner from '../common/Spinner';
import { MemberTableBodyRow, EventNameWithChevron, MobileHead, TimeText } from './membertablestyles';
import { DescriptionCol } from 'src/pages/udb/betdetails/MyBetsTable';

const { secondarycolor } = color;

interface IProps {
	transactions?: ITransaction[];
	loading: boolean;
	error: any;
}

class ProfitLossTable extends React.PureComponent<IProps> {
	render() {
		const { transactions = [], loading = false, error } = this.props;
		return (
			<TableWrapper>
				{loading && <Spinner mode="client" />}
				<TableHeadRow>
					<DescriptionCol>
						<span><Intl langKey="PROFIT_LOSS_TABLE@MARKET" /></span>
					</DescriptionCol>
					<TypeCol>
						<span><Intl langKey="PROFIT_LOSS_TABLE@START_TIME" /></span>
					</TypeCol>
					<TypeCol>
						<span><Intl langKey="PROFIT_LOSS_TABLE@SETTLE_TIME" /></span>
					</TypeCol>
					<TypeCol>
						<span><Intl langKey="PROFIT_LOSS_TABLE@COMMISSION" /></span>
					</TypeCol>
					<TypeCol>
						<span><Intl langKey="PROFIT_LOSS_TABLE@NET" /></span>
					</TypeCol>
				</TableHeadRow>
				<TableBodyWrapper>
					{error ? (
						<div>error</div>
					) : transactions.length > 0 ? (
						<TimeZone>
							{targetTimeZoneOffsetSystem => {
								const dateGroup = groupBy(transactions, transaction => {
									const order = transaction.order as IOrder;
									return format(
										// 取得的 timestamp 先 offset，因為分組會因時區不同而有改變
										// Example:
										// === Taipei (+8) ===
										// 2019-03-10 00:10:00
										// 2019-03-09 23:50:00
										// 切成印度時區會變成
										// === India (+5:30) ===
										// 2019-03-09  21:40:00
										// 2019-03-09  21:20:00
										new Date(order.settledAt || 0).getTime() + targetTimeZoneOffsetSystem,
										'yyyy-MM-dd',
									);
								});
								return Object.keys(dateGroup)
									.sort((a, b) => {
										return +new Date(b) - +new Date(a);
									})
									.map(key => {
										const marketGroup = groupBy(dateGroup[key], tt => {
											return `${util.getValue(tt, ['order', 'settleNumber'])}}`;
										});
										return (
											<Fragment key={key}>
												<TableSubheadRow>{key}</TableSubheadRow>
												{Object.keys(marketGroup)
													.sort((a, b) => {
														return (
															util.getValue(
																marketGroup[b],
																['0', 'order', 'settledAt'],
																0,
															) -
															util.getValue(
																marketGroup[a],
																['0', 'order', 'settledAt'],
																0,
															)
														);
													})
													.map(marketKey => {
														return (
															<MarketRow
																marketTransactions={marketGroup[marketKey]}
																key={marketKey}
															/>
														);
													})}
											</Fragment>
										);
									});
							}}
						</TimeZone>
					) : (
						<EmptyRow />
					)}
				</TableBodyWrapper>
			</TableWrapper>
		);
	}
}

interface IMarketProps {
	marketTransactions: ITransaction[];
}

const MarketRow: React.SFC<IMarketProps> = (props: IMarketProps) => {
	const { marketTransactions } = props;
	const orderedTransaction = marketTransactions.sort(
		(a, b) => util.getValue(a, ['order', 'settledAt']) - util.getValue(b, ['order', 'settledAt']),
	);
	const orders = orderedTransaction.reduce((result, current) => {
		if (current.order) {
			result = [...result, current.order];
		}
		return result;
	}, [] as IOrder[]);

	const exchangeRate = util.getValue(orders, ['0', 'exchangeRate'], 1);
	let totalInfo = orders.reduce(
		(result, current) => {
			if (current.side) {
				const name = current.side.toLocaleLowerCase() as keyof ITotalInfo;
				const winloss = util.getValue(current, ['winlossCredit'], 0);
				result[name] += util.numMultiply(winloss, exchangeRate);
			}
			return result;
		},
		{
			back: 0,
			lay: 0,
			commission: 0,
			net: 0,
		},
	);
	const commission = util.numMultiply(util.getValue(orderedTransaction, ['0', 'commission'], 0), exchangeRate);
	const net = util.numMultiply(util.getValue(orderedTransaction, ['0', 'winloss'], 0), exchangeRate);
	totalInfo = {
		...totalInfo,
		commission,
		net,
	};

	return (
		<MemberTableBodyRow>
			{
				<OpenableBodyRow>
					<Openable>
						{(delegation: IOpenableDelegation) => {
							const { isOpened, toggleOpen } = delegation;
							return (
								<>
									<DescriptionCol>
										<MarketColContentWrapper>
											{util.getValue(orderedTransaction, ['0', 'order', 'marketName'], '')}
											<EventNameWithChevron
												isOpened={isOpened}
												onClick={toggleOpen}
												isShow={true}
												withRemark={orders.some(order => !!order.clientRemark)}
												historyFilterType=""
												historyTableType=""
											>
												{util.getValue(orderedTransaction, ['0', 'order', 'eventName'], '')}
											</EventNameWithChevron>
										</MarketColContentWrapper>
									</DescriptionCol>
									<TypeCol>
										<MobileHead>
											<span>START TIME</span>
										</MobileHead>
										<TimeText>
											<TimeZone format="HH:mm:ss">
												{util.getValue(orderedTransaction, ['0', 'order', 'marketStartTime'], 0)}
											</TimeZone>
										</TimeText>
									</TypeCol>
									<TypeCol>
										<MobileHead>
											<span>SETTLE TIME</span>
										</MobileHead>
										<TimeText>
											<TimeZone format="HH:mm:ss">
												{util.getValue(orderedTransaction, ['0', 'order', 'settledAt'], 0)}
											</TimeZone>
										</TimeText>
									</TypeCol>
									<TypeCol>
										<MobileHead>
											<span>COMMISSION</span>
										</MobileHead>
										<Amount>{commission.toFixed(2)}</Amount>
									</TypeCol>
									<TypeCol>
										<MobileHead>
											<span>NET</span>
										</MobileHead>
										<Income>{net.toFixed(2)}</Income>
									</TypeCol>
									{isOpened && <DetailTable orders={orders} totalInfo={totalInfo} />}
								</>
							);
						}}
					</Openable>
				</OpenableBodyRow>
			}
		</MemberTableBodyRow>
	);
};

export default ProfitLossTable;

// Styled components
const OpenableBodyRow = styled.div`
	width:100%;
	display: flex;
	flex-wrap: wrap;
	align-items: center;
	justify-content: space-between;
`;

export const TableSubheadRow = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
	font-weight: bold;
	text-transform: uppercase;
	/* padding: 0.5em 1em; */
	justify-content: center;
	background: linear-gradient(90deg, rgba(245, 249, 255, 0) 0%, rgba(245, 249, 255, 0.97) 54.17%, rgba(245, 249, 255, 0) 100%);
	color: #524976;
    /* border-bottom: 1px solid #d5d9ff; */
	margin: 5px 0px;
	font-size: 13px;
	border-radius: 3px;
`;

const BodyRow = styled.div`
  width:100%;
//   display: flex;
  align-items: center;
  justify-content: space-between;
  font-size: 12px;
  text-transform: capitalize;
  background: ${secondarycolor};
  color: #505050;
  border-bottom: none;
  margin-bottom: 5px;
//   flex-wrap:wrap;

  ${TableHeadRow} {
	// background: #D0B99F;
	background: ${theme('palette.secondary-background-color-new')};
    color: #fff;
    border-bottom: none;
    box-shadow: rgb(0 0 0 / 10%) 0px 3px 5px;
  }
`;

const MarketCol = styled(Col)`
	flex-basis: 32%;
	font-size:13px;
	text-transform: capitalize;
	${media.lg`
		background-color: ${withProp('theme.palette.panel-background', diffColor(0.1))}
	`};
`;

const TimeCol = styled(Col)`
	flex-basis: 16%;
	justify-content: center;
	font-size:14px;

	${media.lg`
		justify-content: space-between;
	`};
`;

const CommissionCol = styled(Col)`
	flex-basis: 16%;
	justify-content: center;
	font-size:14px;
	${media.lg`
		justify-content: space-between;
	`};
`;

const NetCol = styled(Col)`
	flex-basis: 20%;
	justify-content: center;
	font-size:14px;
	${media.lg`
		justify-content: space-between;
	`};
`;

export const MarketColContentWrapper = styled.div`
	display: flex;
	flex-direction: column;
	align-items: flex-start;
	color: #647186;
    font-size: 12px;

	> div {
		justify-content: flex-start;
    	width: 170px;
		font-size: 12px;
		text-align: left;
		font-weight: 600;
	}
`;