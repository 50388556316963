import React from 'react'
import styled from 'styled-components'

type Props = {}

const PlaceholderImage = (props: Props) => {
  return (
    <PlaceholderWrap>
        <p>loading...</p>
    </PlaceholderWrap>
  )
}

export default PlaceholderImage;

const PlaceholderWrap = styled.div`
     width: 100%;
     height: 100%;

     & img {
         height: 100%;
         width: 100%;
     }
`