import React, { useEffect, useState } from 'react'
import styled from 'styled-components';


function OTPTimer() {
    const [counter, setCounter] = useState(75)

    useEffect(() => {
        const timer =
        counter > 0 && setInterval(() => setCounter(counter - 1), 1000);
        return () => clearInterval(timer);
    }, [counter])

    return (
        <Timer> 00: {counter} Sec</Timer>
    )
    
}

export default OTPTimer

const Timer = styled.div`
    color: #FF6060

`;
