import React, { Fragment, useState } from 'react'
import { useQuery } from '@apollo/client';
import DatePicker from "react-datepicker";
import { color } from 'src/sass-files/variable';
import "react-datepicker/dist/react-datepicker.css";
import styled from "styled-components";
import { GET_ACCOUNT_STATEMENT } from 'src/graphql/member';
import { IOrder, ITransaction, ITransactionType } from 'src/graphql/schema';
import util from 'src/util/util';
// import { Col, TableHeadRow, TableSubheadRow } from 'src/components/common/table/baseTableStyles';
import { TimeZone } from 'src/store/TimeZoneContext';
import { groupBy } from 'lodash';
import { format } from 'date-fns';
import { EventNameWithChevron, MobileHead, TimeText } from 'src/components/member/membertablestyles';
import Openable, { IOpenableDelegation } from 'src/components/common/Openable';
import { Amount, Income } from 'src/components/common/NumberDisplay';
import DetailTable from 'src/components/member/DetailTable';
// import DatePickerIcon from 'src/images/calendar.png';
import Spinner from 'src/components/common/Spinner';
import EmptyRow from 'src/components/common/table/feature/EmptyRow';
import Intl from 'src/components/common/Intl';
import { theme, withProp } from 'styled-tools';
import {
    TableBodyWrapper,
    TableHeadRow,
    TableWrapper,
    TypeCol,
} from 'src/components/udb/commonStyle';
import { DescriptionCol } from 'src/pages/udb/betdetails/MyBetsTable';
import { MarketColContentWrapper, TableSubheadRow } from 'src/components/member/ProfitLossTable';
import { BlockPick, SvgIcon } from 'src/components/udb/DatePickerUdb';
import CalenderIcon from 'src/components/icon/CalenderIcon';

const { secondarycolor } = color;

interface IAccountStatementProps {
    startAt: Date;
    endAt: Date;
    type: ITransactionType;
}

export default function AccountStatementTable(props: IAccountStatementProps) {
    const { type } = props;
    const [startAt, setStartAt] = useState(new Date(new Date().setHours(0, 0, 0)));
    const [endAt, setEndAt] = useState(new Date(new Date().setHours(23, 59, 59)));

    function getSettleTime(group: any, key: string) {
        return key.includes('BET')
            ? util.getValue(group[key], ['0', 'order', 'settledAt'], 0)
            : util.getValue(group[key], ['0', 'createdAt'], 0);
    }

    const { data, loading, error } = useQuery(GET_ACCOUNT_STATEMENT, {
        variables: {
            input: { type, period: { start: startAt.valueOf(), end: endAt.valueOf() } },
        }
    }
    );
    const transactions: ITransaction[] = util.getValue(data, ['exchange', 'transactions'], []);


    const handleDateChangeRaw = (e) => {
        e.preventDefault();
    }

    var date = new Date();
    const minDate = date.setDate((new Date()).getDate() - 90);
    return (
        <>
            {loading && <Spinner mode="agent" />}

            <DatePickerWrapper>
                <SvgIcon>
                    <CalenderIcon />
                    {/* <Intl langKey= "DATE_PICKER@TITLE" /> */}
                </SvgIcon>
                <BlockPick>
                    {/* <Intl langKey= "DATE_PICKER@FROM" /> */}
                    <DatePicker
                        selected={startAt}
                        onChange={(date: Date | null) => setStartAt(date)}
                        onChangeRaw={handleDateChangeRaw}
                        minDate={minDate}
                        maxDate={new Date()}
                        placeholderText="From"
                    />
                </BlockPick>
                <BlockPick>
                    {/* <Intl langKey= "DATE_PICKER@TO" /> */}
                    <DatePicker
                        selected={endAt}
                        onChange={(date: Date | null) => setEndAt(date)}
                        onChangeRaw={handleDateChangeRaw}
                        minDate={minDate}
                        maxDate={new Date()}
                        placeholderText="To"
                    />
                </BlockPick>
            </DatePickerWrapper>

            <TableWrapper>
                <TableHeadRow>
                    <TypeCol>
                        <span><Intl langKey="ACCOUNT_STATEMENT_TABLE@TIME" /> </span>
                    </TypeCol>
                    <TypeCol>
                        <span><Intl langKey="ACCOUNT_STATEMENT_TABLE@TYPE" /> </span>
                    </TypeCol>
                    <DescriptionCol>

                        <span><Intl langKey="ACCOUNT_STATEMENT_TABLE@DESCRIPTION" /> </span>
                    </DescriptionCol>
                    <TypeCol>
                        <span><Intl langKey="ACCOUNT_STATEMENT_TABLE@AMOUNT" /> </span>
                    </TypeCol>
                    {/* <TypeCol>
                        <span><Intl langKey= "ACCOUNT_STATEMENT_TABLE@CREDIT_LIMIT" /> </span>
                    </TypeCol> */}
                    <TypeCol>
                        <span><Intl langKey="ACCOUNT_STATEMENT_TABLE@BALANCE" /> </span>
                    </TypeCol>
                </TableHeadRow>

                <TableBodyWrapper>
                    {error ? (
                        <div>error</div>
                    ) : transactions.length !== 0 ? (
                        <TimeZone>
                            {targetTimeZoneOffsetSystem => {
                                const dateGroup = groupBy(transactions, transaction => {
                                    return format(
                                        new Date(
                                            transaction.type === 'WS'
                                                ? util.getValue(transaction, ['order', 'settledAt'], 0)
                                                : transaction.createdAt,
                                        ).getTime() + targetTimeZoneOffsetSystem,
                                        'yyyy-MM-dd',
                                    );
                                });
                                return Object.keys(dateGroup)
                                    .sort((a, b) => {
                                        return +new Date(b) - +new Date(a);
                                    })
                                    .map(key => {
                                        const marketGroup = groupBy(dateGroup[key], m => {
                                            if (m.type === 'WS') {
                                                return `BET:${util.getValue(m, ['order', 'marketId'], '')}`;
                                            }
                                            return `OTHER:${String(m.createdAt)}`;
                                        });

                                        return (
                                            <Fragment key={key}>
                                                <TableSubheadRow>{key}</TableSubheadRow>
                                                {Object.keys(marketGroup)
                                                    .sort((a, b) => {
                                                        return (
                                                            getSettleTime(marketGroup, b) -
                                                            getSettleTime(marketGroup, a)
                                                        );
                                                    })
                                                    .map(marketKey => {
                                                        return (
                                                            <MarketRow
                                                                marketTransactions={marketGroup[marketKey]}
                                                                key={marketKey}
                                                            />
                                                        );
                                                    })}
                                            </Fragment>
                                        );
                                    });
                            }}
                        </TimeZone>
                    ) : (
                        <EmptyRow />
                    )}
                </TableBodyWrapper>
            </TableWrapper>
        </>
    )
};

interface IProps {
    marketTransactions: ITransaction[];
}

const MarketRow: React.SFC<IProps> = (props: IProps) => {
    const { marketTransactions } = props;
    // 內部群組用下注時間排序
    let orderedTransaction = marketTransactions.sort((a, b) => b.createdAt - a.createdAt);
    let type = util.getValue(orderedTransaction, ['0', 'type'], '');
    const exchangeRate = util.getValue(orderedTransaction, ['0', 'exchangeRate'], 1);
    const platformExchangeRate = util.getValue(orderedTransaction, ['0', 'platformExchangeRate'], 0);
    const orders = orderedTransaction.reduce((result, current) => {
        if (current.order) {
            result = [...result, current.order];
        }
        return result;
    }, [] as IOrder[]);
    const showOrder = type === 'WS' && orders && orders.length > 0;

    interface ITotalInfo {
        back: number;
        lay: number;
        commission: number;
        net: number;
    }

    let totalInfo: ITotalInfo = {
        back: 0,
        lay: 0,
        commission: 0,
        net: 0,
    };

    let transferCredit = 0;
    let exchangedCredit = 0;
    if (showOrder) {
        totalInfo = orders.reduce(
            (result, current) => {
                if (current.side) {
                    const name = current.side.toLocaleLowerCase() as keyof ITotalInfo;
                    const winloss = util.getValue(current, ['winlossCredit'], 0);
                    result[name] += util.numMultiply(winloss, exchangeRate);
                }
                return result;
            },
            {
                back: 0,
                lay: 0,
                commission: 0,
                net: 0,
            },
        );
        const commission = util.numMultiply(util.getValue(orderedTransaction, ['0', 'commission'], 0), exchangeRate);
        const net = util.numMultiply(util.getValue(orderedTransaction, ['0', 'winloss'], 0), exchangeRate);
        totalInfo = {
            ...totalInfo,
            commission,
            net,
        };
    } else {
        transferCredit = util.numMultiply(util.getValue(orderedTransaction, ['0', 'winloss'], 0), exchangeRate);
        exchangedCredit = util.numMultiply(
            util.getValue(orderedTransaction, ['0', 'winloss'], 0),
            platformExchangeRate,
        );
    }

    const remark = util.getValue(orderedTransaction, ['0', 'clientRemark'], '');
    let description = '';
    switch (type) {
        case 'WS':
            type = 'Betting P&L';
            break;
        case 'TI':
        case 'TO':
            type = 'Settling';
            description = transferCredit !== 0 ? (remark ? remark : transferCredit > 0 ? 'Deposit' : 'Withdraw') : '';
            break;
        case 'CI':
            type = 'Credit Initial';
            break;
        case 'CA':
            type = 'Credit Adjustment';
            break;
        default:
            break;
    }

    let availableBalance = 0;
    const hasWinOrder = orderedTransaction.some(o => util.getValue(o, ['profitLoss'], 0) > 0);
    orderedTransaction = hasWinOrder
        ? orderedTransaction.filter(o => util.getValue(o, ['profitLoss'], 0) > 0)
        : orderedTransaction;

    orderedTransaction.forEach(o => {
        const currentCredit = util.getValue(o, ['availableCredit'], 0);
        if (availableBalance === 0) {
            availableBalance = currentCredit;
        }

        if (hasWinOrder && currentCredit > availableBalance) {
            availableBalance = currentCredit;
        }

        if (!hasWinOrder && currentCredit < availableBalance) {
            availableBalance = currentCredit;
        }
    });

    return (
        <BodyRow>
            <Openable>
                {(delegation: IOpenableDelegation) => {
                    const { isOpened, toggleOpen } = delegation;
                    const availableCredit = util.getValue(orderedTransaction, ['0', 'availableCredit'], -1);
                    return (
                        <>
                            <TypeCol>
                                <MobileHead>
                                    <span>TIME</span>
                                </MobileHead>
                                <TimeText>
                                    <TimeZone format="HH:mm:ss">
                                        {util.getValue(orderedTransaction, ['0', 'order', 'settledAt'], 0) ||
                                            util.getValue(orderedTransaction, ['0', 'createdAt'], 0)}
                                    </TimeZone>
                                </TimeText>
                            </TypeCol>
                            <TypeCol>
                                <MobileHead>
                                    <span>TYPE</span>
                                </MobileHead>
                                {type}
                            </TypeCol>

                            {description ? (
                                <DescriptionCol>
                                    <MarketColContentWrapper>{description}</MarketColContentWrapper>
                                </DescriptionCol>
                            ) : (
                                <DescriptionCol>
                                    <MarketColContentWrapper>
                                        {util.getValue(orderedTransaction, ['0', 'order', 'marketName'], '')}
                                        <EventNameWithChevron
                                            onClick={toggleOpen}
                                            isOpened={isOpened}
                                            isShow={showOrder}
                                            withRemark={!!remark}
                                            historyFilterType=""
                                            historyTableType=""
                                        >
                                            {util.getValue(orderedTransaction, ['0', 'order', 'eventName'], '')}
                                        </EventNameWithChevron>
                                    </MarketColContentWrapper>
                                </DescriptionCol>
                            )}
                            <TypeCol>
                                <MobileHead>
                                    <span>AMOUNT</span>
                                </MobileHead>
                                <AmountBlock>
                                    {showOrder ? (
                                        <Income>{totalInfo.net.toFixed(2)}</Income>
                                    ) : (
                                        <>
                                            <Income>{transferCredit.toFixed(2)}</Income>
                                            {!!platformExchangeRate && (
                                                <span>
                                                    <Income>{exchangedCredit.toFixed(2)}</Income>
                                                </span>
                                            )}
                                        </>
                                    )}
                                </AmountBlock>
                            </TypeCol>
                            {/* <TypeCol>
								<MobileHead>
                                <span>CREDIT_LIMIT</span>
								</MobileHead>
								{showOrder ? (
									'-'
								) : (
									<Amount>
										{util.numMultiply(
											util.getValue(orderedTransaction, ['0', 'limitCredit'], 0),
											exchangeRate,
										)}
									</Amount>
								)}
							</TypeCol> */}
                            <TypeCol>
                                <MobileHead>
                                    <span>BALANCE</span>
                                </MobileHead>
                                {availableCredit === -1 ? (
                                    '-'
                                ) : (
                                    <Amount>
                                        {util.numMultiply(
                                            util.getValue(orderedTransaction, ['0', 'availableCredit'], 0),
                                            exchangeRate,
                                        )}
                                    </Amount>
                                )}
                            </TypeCol>
                            {isOpened && showOrder && <DetailTable orders={orders} totalInfo={totalInfo} />}
                        </>
                    );
                }}
            </Openable>
        </BodyRow>
    );
};

const BodyRow = styled.div`
  width:100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-size: 12px;
  text-transform: capitalize;
  background: ${theme('palette.accTableBG')};
  color: #505050;
  border-bottom: none;
  margin-bottom: 5px;
  flex-wrap:wrap;


  /* homeExchangeBgColor */

`;

const StatementTableWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
//   background: #FDF2E5;
  background:#F1F4F8;
  border-radius: 5px;
  padding: 10px;
`;

// const TypeCol = styled(Col)`
// 	flex-basis: 10%;
// 	justify-content: center;
// 	align-items: flex-start;
// 	padding: 12px 5px;
// `;

// const TimeCol = styled(Col)`
//   flex-basis: 10%;
//   padding: 12px 5px;
//   justify-content: center;
//   align-items: flex-start;
// `;

// const ProfitLossCol = styled(Col)`
// 	flex-basis: 16%;
// 	justify-content: center;
// 	align-items: flex-start;
// 	padding: 12px 5px;
//     font-weight:600;
//     font-size:14px;

// `;

// const DescriptionCol = styled(Col)`
// 	flex-basis: 32%;
// 	justify-content: center;
// 	align-items: flex-start;
// 	padding: 12px 5px;
// 	text-align: center;
// `;

// const CreditLimitCol = styled(Col)`
// 	flex-basis: 16%;
// 	justify-content: center;
// 	align-items: flex-start;
// 	padding: 12px 5px;
// `;

// const BalanceCol = styled(Col)`
// 	flex-basis: 16%;
// 	justify-content: center;
// 	align-items: flex-start;
// 	padding: 12px 5px;
//     font-size:14px;
//     font-weight:600;

// `;

const HeadingRow = styled.div`
    width:100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    font-size: 14px;
    font-weight: bold;
    text-transform: capitalize;
    letter-spacing: 0.66px;
    background: ${theme('palette.secondary-background-color-new')};
    color: ${secondarycolor};
    border-bottom: none;
    box-shadow: rgb(0 0 0 / 10%) 0px 3px 5px;

`;

// const TableBodyWrapper = styled.div`
// 	width: 100%;
// 	position: relative;
// 	overflow-y: scroll;
// 	// height: 100vh;
// `;

const AmountBlock = styled.div`
	display: flex;
	flex-direction: column;
`;

const DatePickerWrapper = styled.div`
//   width: 50%;
  /* display: flex;
  flex-direction: row;
  justify-content: flex-start; */
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;
  background: ${theme('palette.datepickerbg')};
  box-shadow: 0px 0px 3.06233px rgba(0, 0, 0, 0.15);
  border-radius: 5px;
  padding: 8px;
  position: absolute;
  right: 0;
  top: 0;
`;

// const CalenderIcon = styled.div`
//   width: 24px;
//   height: 24px;
//   display: flex;
//   flex-direction: row;
//   align-items: center;
//   background-image: url(${DatePickerIcon});
//   background-size: auto;
//   background-repeat: no-repeat;
//   margin-right: 10px;

// `;

const DateSelector = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  position:relative;
  font-family: 'robotoregular';
  font-weight: bold;
`;

export const SearchButtonWrapper = styled.button`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  background: linear-gradient(180deg, #D0B99F 0%, #DFC7A3 100%);
  border-radius: 5px;
  padding: 15px 30px;
  color: ${secondarycolor};
  border: none;

  :hover {
    background: linear-gradient(180deg, #DFC7A3 0%, #D0B99F 100%);
  }
`;

// const BlockPick = styled.div`
//   display: flex;
//   flex-direction: row;
//   align-items: center;
//   background: ${theme('palette.new-whitelable-member-background')};
//   border-radius: 5px;
//   padding: 0px 10px;
//   margin-right: 10px;
//   text-transform: capitalize;
//   color: ${theme('palette.secondary-background-color-new')};
//   font-size: 12px;

//   input {
//     border: none;
//     background: none;
//     display: flex;
//     height: 47px;
//     text-align: center;
//   }

//   .react-datepicker__day--outside-month {
//   color: transparent !important;
//   pointer-events: none;
// }
// `;

const Tittle = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  color: ${theme('palette.secondary-background-color-new')};
  font-family: 'robotobold';
  font-weight: 500;
  font-size: 15px;
  line-height: 18px;
  text-transform: capitalize;
  padding-bottom: 10px;
`;

const Emptydiv = styled.div`
    position: relative;
	min-height: 3em;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 14px;
    font-weight: bold;
    text-transform: capitalize;
    letter-spacing: 0.66px;
    background: #D0B99F;
    color: #fff;
    border-bottom: none;
    box-shadow: rgb(0 0 0 / 10%) 0px 3px 5px;
`;