import { EEventEmit } from './utilModel';
import util from './util';
// const { Router } = require('./server/route');


// // 短解 safari 切頁時內容消失問題
// 為解決換夜置頂問題先暫時註解
// Router.events.on('routeChangeComplete', () => {
// 	const MajorWrapper = document.getElementById('major-wrapper');
// 	if (MajorWrapper) {
// 		MajorWrapper.setAttribute('style', 'overflow: visible');
// 		setTimeout(() => {
// 			MajorWrapper.setAttribute('style', '');
// 		}, 500);
// 	}
// });

/**
 * Router 專用
 *
 * @export
 * @class routerUtil
 */
export default class routerUtil {
	/**
	 * 指定route push位置
	 *
	 * @param {any} obj 物件
	 * @returns {void}
	 */
	public static push = (...arg: any) => {
		if (!util.isClient) {
			console.error('router push cant using in server side.');
		}
		const [newPath, param, option] = arg;
		const targetUrl = decodeURIComponent(`${newPath.replace('/eu-content', '/betting-exchange')}`);
		//Router.pushRoute(targetUrl, param, option);
	};

	/**
	 * 使用原始next的router push，server/route內定義的無效
	 *
	 * @param {any} obj 物件
	 * @returns {void}
	 */
	public static originPush = (...arg: any) => {
		if (!util.isClient) {
			console.error('router push cant using in server side.');
		}
		const [newPath, param, option] = arg;
		//Router.router.push(newPath, param, option);
	};

	public static pushTop = (path: string) => {
		window.top.postMessage({ type: EEventEmit.EVENT_ROUTER_PUSH, path }, '*');
	};

	public static pushIframe = (path: string) => {
		(window.frames as any)['snk-iframe']
			? (window.frames as any)['snk-iframe'].postMessage({ type: EEventEmit.EVENT_ROUTER_PUSH, path }, '*')
			: console.log('snk-iframe not exist.');
	};

	public static back = () => {
		//Router.router.back();
	};

	public static pushAndKeepOriginPath = (path: string) => {
		routerUtil.push(path, path);
	};
}
