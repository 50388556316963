import React, { useEffect } from 'react'
import { useNavigate } from 'react-router-dom';
import config from "src/config/config";
import { withAppContext } from 'src/store/initAppContext'
import styled, { withTheme } from 'styled-components'
import { theme } from 'styled-tools';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import PlaceholderImage from 'src/components/Placeholder';
import Footer from 'src/components/footer/footer';

const DailyRewardsBazzi = (props) => {
    const {
        app: { brandId },
        theme,
    } = props;

    const navigate = useNavigate();

    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    const domain = brandId == 31 || brandId == 32 ? "Yolo247" : "iw247";

    return (
        <>
            <MainWrapper>
                <ContentWrapper>
                    <TitleWrapper>
                        <p>This T20 World Cup, walk the red carpet like a cricket superstar only on <span className='boldRed'>{domain}!</span></p>
                        <p>We present the offer of the year for all cricket fans! Every day is a chance to win on <span className='boldRed'>{domain}</span></p>
                    </TitleWrapper>
                    <ExpWrapper>
                        <HeadWrapper>
                        <h1>Experience the thrill of rewards</h1>
                        <h1>– 100% Loss back</h1>
                        </HeadWrapper>
                        <BoxContainer>
                            <BoxContent>
                                <LazyLoadImage src={`${config.imageV3Host}/static/cashsite/${theme.image.DailyRewardsLBImageOne}`} effect="none" placeholder={<PlaceholderImage />} />
                                <h1>Warm-up</h1>
                                <p>If you're not already part of the {domain} family, sign up now. Existing members can log in with ease!</p>
                            </BoxContent>
                            <BoxContent>
                                <LazyLoadImage src={`${config.imageV3Host}/static/cashsite/${theme.image.DailyRewardsLBImageThree}`} effect="none" placeholder={<PlaceholderImage />} className="imgTwo" />
                                <h1>Bowl at Top Speed</h1>
                                <p>Deposit in your account. Top it up and ready for action throughout the T20 WC.</p>
                            </BoxContent>
                            <BoxContent>
                                <LazyLoadImage src={`${config.imageV3Host}/static/cashsite/${theme.image.DailyRewardsLBImageTwo}`} effect="none" placeholder={<PlaceholderImage />} className="imgThree"/>
                                <h1>York the batsman</h1>
                                <p>Dive into the excitement of the  World Cup matches by placing your bets on our cutting-edge exchange platform.</p>
                            </BoxContent>
                            <BoxContent>
                                <LazyLoadImage src={`${config.imageV3Host}/static/cashsite/${theme.image.DailyRewardsLBImageFour}`} effect="none" placeholder={<PlaceholderImage />} />
                                <h1>Claim your Prize</h1>
                                <p>For cumulative losses of the day, we'll reward you with an 100% additional loss back bonus the very next day.</p>
                            </BoxContent>
                        </BoxContainer>
                    </ExpWrapper>
                    <TermsAndCondition>
                        <h4>Terms & Conditions</h4>
                        <ul>
                            <li>The loss back bonus will be applied to ‘lost World Cup EXCHANGE bets - for the day’, placed during the WC 2024.</li>
                            <li>The bonus amount for each qualifying bet will be capped at INR 50.</li>
                            <li>The bonus will be credited to your {domain} account the next day.</li>
                            <li>Bonus will be expired within 24-48 hours.</li>
                            <li>There is no limit to the number of bets you can place to earn the 100% loss back bonus, ‘for the day’.</li>
                            <li>The loss back bonus can be used to place future bets on {domain} as this bonus cannot be withdrawn. Only winnings can be withdrawn.</li>
                            <li>This promotion is valid only for the T20 World Cup 2024.</li>
                            <li>{domain} reserves the right to disqualify any user found engaging in fraudulent activities.</li>
                            <li>{domain} General Terms & Conditions apply.</li>
                        </ul>
                    </TermsAndCondition>
                </ContentWrapper>
            </MainWrapper>
            <Footer />
        </>
    )
}

export default withTheme(withAppContext(DailyRewardsBazzi));

//Styled Components

const MainWrapper = styled.div`
    width: 98%;
    height: auto;
    border: 1px solid #E5C573;
    border-top: 0;
    display: flex;
    border-radius: 1px;
    background: rgba(111, 111, 111, 0.5);
    margin: 0em 1.2em;
    -webkit-backdrop-filter: brightness(0.5);
    backdrop-filter: brightness(0.5);
`;

const ContentWrapper = styled.div`
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background: url(${config.imageV3Host}/static/cashsite/${theme("image.DailyRewardsBackgrounddBazzi")});
    background-position: top;
    background-size: cover;
    background-repeat: no-repeat;
    padding: 2rem 2rem 5rem 2rem;

    .lazy-load-image-background {
      display: flex !important;
      justify-content: center !important;
    }

    .secondTitle {
        padding-top: 2rem;
    }
`;

const TitleWrapper = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 73%;
    padding: 1rem 0 3rem 0;
    gap: 1rem;

    p {
        margin: 0;
        color: #FFF;
        text-align: center;
        font-size: 18px;
        font-style: normal;
        font-weight: 400;
        line-height: 150%; /* 27px */
        text-transform: capitalize;
    }
    
    .boldRed {
        color: #F91212;
        font-size: 14px;
        font-style: normal;
        font-weight: 700;
        line-height: normal;
        text-transform: capitalize;
    }
`;

const ExpWrapper = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 7rem;
    padding: 0 0 4rem 0;
    width: 80%;
`;

const HeadWrapper = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    h1 {
        margin: 0;
        color: #F00;
        text-align: center;
        -webkit-text-stroke-width: 0.5;
        -webkit-text-stroke-color: #E4B129;
        font-size: 28px;
        font-style: normal;
        font-weight: 700;
        line-height: 109.5%; /* 30.66px */
        letter-spacing: 0.84px;
        text-transform: capitalize;
    }
`

const BoxContainer = styled.div`
    display: flex;
    justify-content: center;
    align-items: stretch;
    gap: 3.5rem;
    position: relative;
`;

const BoxContent = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    border-radius: 20px;
    border: 0.5px solid #F91212;
    background: radial-gradient(circle at top, rgba(255, 0, 0, 0.30) 0%, rgba(0, 0, 0, 0.00) 70%), rgba(0, 0, 0, 0.50);
    box-shadow: 0px 0px 10px 0px rgba(255, 0, 0, 0.25);
    width: 20%;
    padding: 6.5rem 1.5rem 1.5rem 1.5rem;


    h1 {
        color: #F00;
        text-align: center;
        font-size: 18px;
        font-style: normal;
        font-weight: 700;
        line-height: normal;
        text-transform: capitalize;
    }

    p {
        color: #FFF;
        text-align: center;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
        text-transform: capitalize;
    }

    img {
        width: 10.25rem;
        position: absolute;
        top: -4rem;
    }

    .imgTwo {
        width: 12.5rem;
        position: absolute;
        top: -4rem;
    }
    .imgThree {
        width: 9rem;
        position: absolute;
        top: -3.5rem;
    }
`;

const TermsAndCondition = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 2rem;
    width: 65%;
    border-radius: 29.187px;
    border: 2px solid #D19304;
    background: #000;

    & li {
        color: #FFF;
        text-align: justify;
        font-size: 18px;
        font-style: normal;
        font-weight: 400;
        line-height: 21px; /* 116.667% */
        text-transform: capitalize;
        list-style-type: decimal;
        padding-bottom: 1.5rem;

        .boldWhite {
            color: #FFF;
            font-size: 18px;
            font-style: normal;
            font-weight: 700;
            line-height: 21px;
            text-transform: capitalize;
        }
    }

    & h4 {
        color: #F00;
        text-align: center;
        font-size: 28px;
        font-style: normal;
        font-weight: 700;
        line-height: 41.09px; /* 146.751% */
        text-transform: capitalize;
    }
`;