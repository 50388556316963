import React from "react";
import styled from "styled-components";
import { BankDropDown, BankInputField } from "./Bankdetail";
import config from "src/config/config";
import { theme } from "styled-tools";

// const BankIcon =  `${config.imageV3Host}/static/media/bankDetailIcon.svg`;

type PasswordInputFieldProps = {
  placeholder: string;
  req?: boolean;
  error: { message?: string };
  name?: string;
} & Omit<React.ComponentProps<"input">, "placeholder">;

type BankDropDownProps = {
  data?: {
    bank_name: string;
    bank_code: string;
    bankName?: string;
    account_number?: string;
  }[];
  placeholder: string;
  req?: boolean;
} & Omit<React.ComponentProps<"select">, "placeholder">;


const BankIcon =  `${config.imageV3Host}/static/media/banksvg1.svg`;


export const PasswordInputField = ({ placeholder, req, onChange, error, type, ...props }: PasswordInputFieldProps) => {
  return (
    <>
      <Wrapper className={error ? 'invalid' : ''}>
        <BankInputField placeholder={placeholder} req={req} onChange={onChange} type={type} {...props} />
        <PasswordStrength>
          {error && error.message}
        </PasswordStrength>
      </Wrapper>
    </>
  )
}



export const PasswordSectionFieldSecond = ({ placeholder, req, onChange, error, type, ...props }: PasswordInputFieldProps) => {
  return (
    <>
      <Wrapper2 className={error ? 'invalid' : ''}>

        <BankInputField placeholder={placeholder} req={req} onChange={onChange} type={type} {...props} />

      </Wrapper2>
    </>
  )
}


export const BankDetailField = ({ placeholder,data, req,  onChange, ...props }: BankDropDownProps) => {
  return (
    <>  
      <Wrapper2 >

        <BankDropDown style={{paddingLeft:"22px"}} placeholder={placeholder} onChange={onChange} req={req} icon={BankIcon} data={data} {...props}  />

      </Wrapper2>
    </>
  )
}

const Wrapper = styled.div`

    display: flex;
    /* width:470px; */
    width: 100%;
    flex-direction: column;
    input::-webkit-outer-spin-button,
        input::-webkit-inner-spin-button {
          -webkit-appearance: none;
          margin: 0;
        }
    > label { width: 100% !important}
    &.invalid{
      label { 
        border: 1px solid red;
        background: #efdfdf;
        color: rgb(53, 53, 53);
      }

      input {
        color: rgb(53, 53, 53);
      }
    }
`;

const Wrapper2 = styled(Wrapper)`

    width:380px;

    img{
      width: 21px;
      height: 21px;
      position: absolute;
      left: 10px;
    }

    .extraSpace {
      padding-left: 25px;
    }


`;

const PasswordStrength = styled.div`
  display: flex;
  align-items: center;
  letter-spacing: 0.01em;
  text-transform: capitalize;
  font-weight: 400;
  font-size: 10px;
  color: ${theme('palette.nametext')};
  // width: 30%;
  // margin-left: 1em;
`;
