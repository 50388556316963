import React, { useState } from 'react';
import { useQuery } from '@apollo/client'
// gql
import { CONFIG } from '../../graphql/system';

// Util
import util from '../../util/util';
import { fancyInfo } from 'src/graphql/events';

interface IProps {
	children: any;
}


export default function SpecailFancyConfig(props: IProps) {
	const { children } = props;
	const fancyConfig: any = [
		{
			"type": "ELECTION",
			"name": "special_fancy",
			"langKey": "TITLE@SPECIALEVENT",
			"events": [
				{
					"id": "14226",
					"name": "IPL 2020",
					"displayName": "TITLE@IPL_WINNER_2020",
					"menuName": "IPL 2020",
					"flipflagName": "IPL 2020",
					"message": "",
					"tableHeading": "IPL 2020",
					"img": "ipl",
					"m-img": "ipl-m",
					"m-href": "/sports/SPECIAL_FANCY/e/14226",
					"disabled": true
				},
				{
					"id": "14227",
					"name": "Bihar Elections",
					"displayName": "TITLE@BIHAR_ELECTIONS",
					"menuName": "Bihar Elections",
					"flipflagName": "Bihar Elections",
					"message": "",
					"tableHeading": "Bihar Elections",
					"img": "election",
					"m-img": "election-m",
					"m-href": "/sports/SPECIAL_FANCY/e/14227",
					"disabled": true
				},
				{
					"id": "14228",
					"name": "BigBoss 2020",
					"displayName": "TITLE@BIG_BOSS_2020",
					"menuName": "BigBoss 2020",
					"flipflagName": "BigBoss",
					"message": "",
					"tableHeading": "BigBoss 2020",
					"img": "bigboss",
					"m-img": "bigboss-m",
					"m-href": "/sports/SPECIAL_FANCY/e/14228",
					"disabled": true
				},
				{
					"id": "513125807",
					"name": "delhi",
					"displayName": "TITLE@DELHI",
					"menuName": "Delhi",
					"flipflagName": "Delhi Election",
					"message": "",
					"tableHeading": "Delhi Election",
					"img": "spevent-01",
					"m-img": "election_1",
					"m-href": "/sports/SPECIAL_FANCY/e/513125807",
					"disabled": true
				},
				{
					"id": "513125809",
					"name": "jharkhand",
					"displayName": "TITLE@JHARKHAND",
					"menuName": "Jharkhand",
					"flipflagName": "Jharkhand Election",
					"message": "",
					"tableHeading": "Jharkhand Election",
					"img": "spevent-01",
					"m-img": "election_1",
					"m-href": "/sports/SPECIAL_FANCY/e/513125809",
					"disabled": true
				},
				{
					"id": "23081",
					"name": "US Election",
					"displayName": "TITLE@US_ELECTION",
					"menuName": "US Election",
					"flipflagName": "US Election",
					"message": "",
					"tableHeading": "US Election",
					"img": "us-election",
					"m-img": "us-election-m",
					"m-href": "/sports/SPECIAL_FANCY/e/23081",
					"disabled": true
				},
				{
					"id": "71366",
					"name": "West Bengal Election",
					"displayName": "TITLE@WB_ELECTION",
					"menuName": "West Bengal Election",
					"flipflagName": "West Bengal Election",
					"message": "",
					"tableHeading": "West Bengal Election",
					"img": "westBangal",
					"m-img": "westBangal-m",
					"m-href": "/sports/SPECIAL_FANCY/e/71366",
					"disabled": true
				},
				{
					"id": "5889",
					"name": "IPL cup winner",
					"displayName": "TITLE@IPL_ELECTION",
					"menuName": "IPL cup winner",
					"flipflagName": "IPL cup winner",
					"message": "",
					"tableHeading": "IPL cup winner",
					"img": "menuipl",
					"m-img": "menuipl",
					"m-href": "/sports/SPECIAL_FANCY/e/5889",
					"disabled": true
				},
				{
					"id": "66678",
					"name": "UP Elections",
					"displayName": "TITLE@UPELECTION",
					"menuName": "UP Elections",
					"flipflagName": "UP Elections",
					"message": "",
					"tableHeading": "UP Elections",
					"img": "election",
					"m-img": "election-m",
					"m-href": "/sports/SPECIAL_FANCY/e/66678",
					"disabled": false
				},
				{
					"id": "66045",
					"name": "Punjab Elections",
					"displayName": "TITLE@PUNJABELECTION",
					"menuName": "Punjab Elections",
					"flipflagName": "Punjab Elections",
					"message": "",
					"tableHeading": "Punjab Elections",
					"img": "election",
					"m-img": "election-m",
					"m-href": "/sports/SPECIAL_FANCY/e/66045",
					"disabled": false
				},
				// {
				// 	"id": "74570",
				// 	"name": "PSL",
				// 	"displayName": "TITLE@PSL_ELECTION",
				// 	"menuName": "PSL cup winner",
				// 	"flipflagName": "PSL cup winner",
				// 	"message": "",
				// 	"tableHeading": "PSL cup winner",
				// 	"img": "PSL",
				// 	"m-img": "PSL-m",
				// 	"m-href": "/sports/SPECIAL_FANCY/e/74570",
				// 	"disabled": false
				// },
				// {
				// 	"id": "244539",
				// 	"name": "THE HUNDRED",
				// 	"displayName": "TITLE@HUNDRED",
				// 	"menuName": "The Hundred",
				// 	"flipflagName": "The Hundred",
				// 	"message": "",
				// 	"tableHeading": "The Hundred",
				// 	"img": "Hundred",
				// 	"m-img": "Hundred",
				// 	"m-href": "/market/100/-1/244539/1",
				// 	"disabled": false
				// },
				// {
				// 	"id": "256388",
				// 	"name": "ASIA CUP",
				// 	"displayName": "TITLE@ASIACUP",
				// 	"menuName": "Asia Cup",
				// 	"flipflagName": "Asia Cup",
				// 	"message": "",
				// 	"tableHeading": "Asia Cup",
				// 	"img": "asiaCup",
				// 	"m-img": "asiaCup",
				// 	"m-href": "/market/100/-1/256388/1",
				// 	"disabled": false
				// },
				// {
				// 	"id": "314858",
				// 	"name": "BIG BASH",
				// 	"displayName": "TITLE@bigbash",
				// 	"menuName": "BIG BASH",
				// 	"flipflagName": "BIG BASH",
				// 	"message": "",
				// 	"tableHeading": "BIG BASH",
				// 	"img": "GjELe",
				// 	"m-img": "GjELe",
				// 	"m-href": "/market/100/-1/314858/1",
				// 	"disabled": false
				// },
				// {
				// 	"id": "303503",
				// 	"name": "Bigg Boss",
				// 	"displayName": "bigboss@bb",
				// 	"menuName": "BiggBoss",
				// 	"flipflagName": "Bigg-Boss",
				// 	"message": "",
				// 	"tableHeading": "Bigg-Boss",
				// 	"img": "asiaCup",
				// 	"m-img": "asiaCup",
				// 	"m-href": "/market/100/-1/303503/1",
				// 	"disabled": false
				// }
				
				{
					"id": "353084",
					"name": "ipl",
					"displayName": "TITLE@IPL_ELECTION",
					"menuName": "ipl",
					"flipflagName": "ipl",
					"message": "",
					"tableHeading": "ipl",
					"img": "ipl",
					"m-img": "ipl",
					"m-href": "/market/100/-1/353084/1",
					"disabled": false
				},
				{
					"id": "605897",
					"name": "US ELECTIONS 2024",
					"displayName": "TITLE@ELECTION_2024",
					"menuName": "US ELECTIONS 2024",
					"flipflagName": "US ELECTIONS 2024",
					"message": "",
					"tableHeading": "US ELECTIONS 2024",
					"img": "US-ELECTIONS",
					"m-img": "US-ELECTIONS",
					"m-href": "/market/100/-1/605897/1",
					"disabled": false
				},
				{
					"id": "609209",
					"name": "HARYANA ELECTION 2024",
					"displayName": "TITLE@HARYANA_ELECTION_2024",
					"menuName": "HARYANA ELECTION 2024",
					"flipflagName": "HARYANA ELECTION 2024",
					"message": "",
					"tableHeading": "HARYANA ELECTION 2024",
					"img": "HARYANA-ELECTIONS",
					"m-img": "HARYANA-ELECTIONS",
					"m-href": "/market/100/-1/609209/1",
					"disabled": false
				}
			]
		}
	]
	return (
		children(fancyConfig)
	)
}


// export default class SpecailFancyConfig extends React.PureComponent<IProps> {
// 	render() {
// 		const { children } = this.props;
// 		return (
// 			<>
// 				<Query
// 					query={CONFIG}
// 					variables={{
// 						input: {
// 							key: 'SETTING:SPECIAL_FANCY',
// 						},
// 					}}
// 				>
// 					{({ data: fancyData }: any) => {
// 						// Changed By - Manoj
// 						// const fancyConfig = JSON.parse(util.getValue(fancyData, ['system', 'config', 'value'], '[]'));
						// const fancyConfig = [
						// 	{
						// 		"type": "ELECTION",
						// 		"name": "special_fancy",
						// 		"langKey": "TITLE@SPECIALEVENT",
						// 		"events": [
						// 		   {
						// 			  "id": "14226",
						// 			  "name": "IPL 2020",
						// 			  "displayName": "TITLE@IPL_WINNER_2020",
						// 			  "menuName": "IPL 2020",
						// 			  "flipflagName": "IPL 2020",
						// 			  "message": "",
						// 			  "tableHeading": "IPL 2020",
						// 			  "img": "ipl",
						// 			  "m-img": "ipl-m",
						// 			  "m-href": "/sports/SPECIAL_FANCY/e/14226",
						// 			  "disabled": true
						// 		   },
						// 		   {
						// 			  "id": "14227",
						// 			  "name": "Bihar Elections",
						// 			  "displayName": "TITLE@BIHAR_ELECTIONS",
						// 			  "menuName": "Bihar Elections",
						// 			  "flipflagName": "Bihar Elections",
						// 			  "message": "",
						// 			  "tableHeading": "Bihar Elections",
						// 			  "img": "election",
						// 			  "m-img": "election-m",
						// 			  "m-href": "/sports/SPECIAL_FANCY/e/14227",
						// 			  "disabled": true
						// 		   },
						// 		   {
						// 			  "id": "14228",
						// 			  "name": "BigBoss 2020",
						// 			  "displayName": "TITLE@BIG_BOSS_2020",
						// 			  "menuName": "BigBoss 2020",
						// 			  "flipflagName": "BigBoss",
						// 			  "message": "",
						// 			  "tableHeading": "BigBoss 2020",
						// 			  "img": "bigboss",
						// 			  "m-img": "bigboss-m",
						// 			  "m-href": "/sports/SPECIAL_FANCY/e/14228",
						// 			  "disabled": true
						// 		   },
						// 		   {
						// 			  "id": "513125807",
						// 			  "name": "delhi",
						// 			  "displayName": "TITLE@DELHI",
						// 			  "menuName": "Delhi",
						// 			  "flipflagName": "Delhi Election",
						// 			  "message": "",
						// 			  "tableHeading": "Delhi Election",
						// 			  "img": "spevent-01",
						// 			  "m-img": "election_1",
						// 			  "m-href": "/sports/SPECIAL_FANCY/e/513125807",
						// 			  "disabled": true
						// 		   },
						// 		   {
						// 			  "id": "513125809",
						// 			  "name": "jharkhand",
						// 			  "displayName": "TITLE@JHARKHAND",
						// 			  "menuName": "Jharkhand",
						// 			  "flipflagName": "Jharkhand Election",
						// 			  "message": "",
						// 			  "tableHeading": "Jharkhand Election",
						// 			  "img": "spevent-01",
						// 			  "m-img": "election_1",
						// 			  "m-href": "/sports/SPECIAL_FANCY/e/513125809",
						// 			  "disabled": true
						// 		   },
						// 		   {
						// 			  "id": "23081",
						// 			  "name": "US Election",
						// 			  "displayName": "TITLE@US_ELECTION",
						// 			  "menuName": "US Election",
						// 			  "flipflagName": "US Election",
						// 			  "message": "",
						// 			  "tableHeading": "US Election",
						// 			  "img": "us-election",
						// 			  "m-img": "us-election-m",
						// 			  "m-href": "/sports/SPECIAL_FANCY/e/23081",
						// 			  "disabled": true
						// 		   },
						// 		   {
						// 			  "id": "71366",
						// 			  "name": "West Bengal Election",
						// 			  "displayName": "TITLE@WB_ELECTION",
						// 			  "menuName": "West Bengal Election",
						// 			  "flipflagName": "West Bengal Election",
						// 			  "message": "",
						// 			  "tableHeading": "West Bengal Election",
						// 			  "img": "westBangal",
						// 			  "m-img": "westBangal-m",
						// 			  "m-href": "/sports/SPECIAL_FANCY/e/71366",
						// 			  "disabled": true
						// 		   },
						// 		   {
						// 			  "id": "5889",
						// 			  "name": "IPL cup winner",
						// 			  "displayName": "TITLE@IPL_ELECTION",
						// 			  "menuName": "IPL cup winner",
						// 			  "flipflagName": "IPL cup winner",
						// 			  "message": "",
						// 			  "tableHeading": "IPL cup winner",
						// 			  "img": "menuipl",
						// 			  "m-img": "menuipl",
						// 			  "m-href": "/sports/SPECIAL_FANCY/e/5889",
						// 			  "disabled": false
						// 		   },
						// 		   {
						// 			"id": "66678",
						// 			"name": "UP Elections",
						// 			"displayName": "TITLE@UP_ELECTION",
						// 			"menuName": "UP Elections",
						// 			"flipflagName": "UP Elections",
						// 			"message": "",
						// 			"tableHeading": "UP Elections",
						// 			"img": "election",
						// 			"m-img": "election-m",
						// 			"m-href": "/sports/SPECIAL_FANCY/e/66678",
						// 			"disabled": false
						// 		 },
						// 		 {
						// 			"id": "66045",
						// 			"name": "Punjab Elections",
						// 			"displayName": "TITLE@PUNJAB_ELECTION",
						// 			"menuName": "Punjab Elections",
						// 			"flipflagName": "Punjab Elections",
						// 			"message": "",
						// 			"tableHeading": "Punjab Elections",
						// 			"img": "election",
						// 			"m-img": "election-m",
						// 			"m-href": "/sports/SPECIAL_FANCY/e/66045",
						// 			"disabled": false
						// 		 },
						// 		   {
						// 			  "id": "74570",
						// 			  "name": "PSL cup winner",
						// 			  "displayName": "TITLE@PSL_ELECTION",
						// 			  "menuName": "PSL cup winner",
						// 			  "flipflagName": "PSL cup winner",
						// 			  "message": "",
						// 			  "tableHeading": "PSL cup winner",
						// 			  "img": "PSL",
						// 			  "m-img": "PSL-m",
						// 			  "m-href": "/sports/SPECIAL_FANCY/e/74570",
						// 			  "disabled": true
						// 		   },
						// 		]
// 							 }
// 						];
// 						return children(fancyConfig);
// 					}}
// 				</Query>
// 			</>
// 		);
// 	}
// }
