import { useState,useEffect } from "react";
import styled from "styled-components";
import { color, bgcolor } from '../../../sass-files/variable';
// import Poker1 from '../../../images/casino-images/poker1.png';
import Jackpot from '../../../images/casino-images/jackpot.png';
import Baccarat1 from '../../../images/casino-images/baccarat1.png';
import SpinAWin1 from '../../../images/casino-images/spin-a-win.png';
import Baccarat2 from '../../../images/casino-images/baccarat2.png';
import Roulette1 from '../../../images/casino-images/roulette1.png';
import Wheel1 from '../../../images/casino-images/wheel1.png';
// import { Link } from "react-router-dom";
// import Testing from '../../../images/casino-images/poker_laptop.jpg'
// import Modal from 'src/components/Modal';
// import CasinoGames from 'src/components/eu-content/casino-page/casino-slider-Games';
// import casinoGameList1 from "./data";
import Loading from "src/pages/loading";
import LeftArrow from "../../../images/Left_arrow.png";
import RightArrow from "../../../images/Right_arrow.png";
// import Slider from "react-slick";
import "../../../../node_modules/slick-carousel/slick/slick.css";
import "../../../../node_modules/slick-carousel/slick/slick-theme.css";
// import CasinoFirst from '../../../images/bet_war_of_bets.png';
// import CasinoSecond from '../../../images/evo_dreamcatcher.png';
// import CasinoThird from '../../../images/ezugi_dragon tiger.png';
// import CasinoFourth from '../../../images/Netent_blackjack.png';
// import CasinoFifth from '../../../images/Super7_teenpatti.png';
// import CasinoSixth from '../../../images/Superspade_andharbahar.png';
// import CasinoSeventh from '../../../images/Superspade_dragontiger.png';
import cookieUtil from "src/util/cookieUtil";
import { ECookieName } from "src/util/utilModel";
// import Login from "src/pages/auth/Login";
import login from "src/components/login-signup/login";
// import LoginContent from "src/components/login-signup/login/login-content";
import Intl from '../../common/Intl'
import { ONE_CLICK_RECYCLE } from "src/graphql/system";
import { useMutation } from '@apollo/client';
import { useNavigate } from "react-router-dom";
import { ifProp, theme, withProp } from 'styled-tools';
import { BackButton, DemoButton, FirstColoumn, MsgWrap, NoGamesMessage, PlayButton, RowFirstExtented } from "./all-games";
import config from "src/config/config";
import { LazyLoadImage } from 'react-lazy-load-image-component';
import PlaceholderImage from 'src/components/Placeholder';

import {SRTM} from 'src/util/sportradarEvent';
import { withAppContext } from "src/store/initAppContext";

const { secondarycolor, menucolor } = color;

function PlayDemoGames(props: any) {
    const isAuth = cookieUtil.get(ECookieName.COOKIE_TOKEN);
    const Language = cookieUtil.get(ECookieName.COOKIE_LANG);
    // const [filteredData, setfilteredData] = useState([]);
    const [search, setSearch] = useState('')
    const [isGameTrigger, setGameTrigger] = useState(false)
    const [gameDetails, setGameDetails] = useState({})
    const [modelState, setModelState] = useState(false);

    const [triggerOneClickRecycle, { recycleLoading, recycleError, recycleData }]: any = useMutation(ONE_CLICK_RECYCLE)
    // const [isGameTrigger, setGameTrigger] = useState(false);
    const [disabled, setDisabled] = useState(true);

    const { app: { brandId }} = props
    //for sportradar
    useEffect(()=>{
       SRTM.init(brandId)
    },[])

    const showModal = () => {
        login(modelState)
    };

    const hideModal = () => {
        setModelState(false);
    };

    const loadGame = (event, game) => {
        setGameTrigger(true)
        setGameDetails(game)
        setDisabled(false)

        //for sport Radar
        if(game && typeof game === 'object'){
            SRTM.retargetCasinoEvent(brandId,{
                id:game.id || '',
                name:game.game,
                category:game.type,
                image:game.imgsrc[0]
            })
        }
       
    }

    const emitHideModel = (flag) => {
        setModelState(flag)
    };

    // const [selectedGame, setSelectedGame] = useState([]);

    let navigate: any = useNavigate();
    const backButton = () => {
        window.location.reload();
        triggerOneClickRecycle()
        .then((response) => {
            if (response) {
                return;
            }
        })
        .catch((error) => {
            console.log("Error in one click recycle");
        });
        // setDisabled(true)
        setTimeout(() => {
            setDisabled(true)
        }, 1000)
    }

    return (
        <>

            <BackButton onClick={backButton} disabled={disabled}> <img src={LeftArrow} alt="LeftArrow" />
                <Intl langKey="BUTTON@BACK" />
            </BackButton>

            <div style={{ width: "100%" }}>
                {
                    isGameTrigger ? <Loading modalState={props.data} platformDetails={gameDetails} /> : <>
                        <SectionWrapper>
                            <FirstColoumn>
                                <RowFirstExtented>
                                    {props.data?.length !== 0 ? props.data.map((each, index) => {

                                        //Modification Required as imgsrc might be an array
                                        let _imgSrc = each.imgsrc;
                                        if(typeof _imgSrc === 'object' && Array.isArray(_imgSrc)){
                                            _imgSrc = (Language === 'hindi-ind') ?  _imgSrc[0] : _imgSrc[1]
                                        }

                                        return (
                                            <div key={index} className="blocks"  >
                                                <div className="subblocks">
                                                    <span className="coltenth">
                                                        <LazyLoadImage src={`${config.commonImgS3Host}${_imgSrc}`}  effect="none" height="100%" width="100%"  placeholder={<PlaceholderImage/>} alt="ico"/>
                                                        <div className="overlay">
                                                            <div className="buttons">
                                                                {
                                                                    cookieUtil.get(ECookieName.COOKIE_TOKEN) ? <PlayButton>
                                                                        <button onClick={(event) => loadGame(event, each)}><Intl langKey="TITLE@PLAY" /></button>
                                                                    </PlayButton> : <DemoButton>
                                                                        <button onClick={(event) => loadGame(event, each)}><Intl langKey="TITLE@DEMO" /></button>
                                                                    </DemoButton>
                                                                }
                                                            </div>
                                                        </div>
                                                    </span>
                                                </div>
                                            </div>
                                        )
                                    }) : <MsgWrap><NoGamesMessage><Intl langKey="Games@NoGamesMessage" /> <br /> <Intl langKey="Games@NoGamesMessage2" /></NoGamesMessage></MsgWrap>
                                    }
                                </RowFirstExtented>
                            </FirstColoumn>
                        </SectionWrapper>
                    </>
                }
            </div>
        </>
    )
}

export default withAppContext(PlayDemoGames);


const SectionWrapper = styled.div`
    width: 100%;
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    flex-direction: row;

    img {
        height:100%;
        width: 100%;

    }

    .overlay {
        position: absolute;
        bottom: 0;
        left: 0;
        right: 0;
        opacity: 0.9;
        background: ${theme('palette.overlayBGColor')};
        overflow: hidden;
        width: 100%;
        height: 100%;
        -webkit-transform: scale(0);
        -ms-transform: scale(0);
        transform: scale(0);
        -webkit-transition: .5s ease;
        transition: .5s ease;
      }
      
      .buttons {
        color: #fff;
        font-size: 20px;
        position: absolute;
        top: 50%;
        left: 50%;
        -webkit-transform: translate(-50%, -50%);
        -ms-transform: translate(-50%, -50%);
        transform: translate(-50%, -50%);
        text-align: center;
        display: flex;
        align-items: center;
        justify-content: center;
      }
`;



export const RowSecond = styled.link`
    width: 20%;
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: space-between;
    background-color: ${secondarycolor};
    flex-wrap: wrap;
`;

export const GameName = styled.div`
    display: none;
    align-items: center;
    justify-content: center;
    letter-spacing: 0.05em;
    text-transform: uppercase;
    color: ${menucolor};
    padding: 10px;
    font-size: 0px;
    
    font-weight: 600;
    line-height: 20px;
`;

export const Col1: any = styled.div`
    width: 170px;
    height: 170px; 
    background-image: url(${(props: any) => props.imageSrc});
    background-size: cover;
    background-repeat: no-repeat;
`;

export const Col2 = styled.link`
    width: 100%;
    height: 170px;
    display: flex;
    background-image: url(${Baccarat1});
    background-size: cover;
    background-repeat: no-repeat;
`;

export const Col3 = styled.link`
    width: 100%;
    height: 550px;
    display: flex;
    background-image: url(${Jackpot});
    background-size: cover;
    background-repeat: no-repeat;
`;

export const Col4 = styled.link`
    width: 100%;
    height: 290px;
    display: flex;
    background-image: url(${SpinAWin1});
    background-size: cover;
    background-repeat: no-repeat;
`;

export const Col5 = styled.link`
    width: 100%;
    height: 264px;
    display: flex;
    background-image: url(${Baccarat2});
    background-size: cover;
    background-repeat: no-repeat;
`;

export const Col6 = styled.link`
    width: 100%;
    height: 197px;
    display: flex;
    background-image: url(${Roulette1});
    background-size: cover;
    background-repeat: no-repeat;
`;

export const Col7 = styled.link`
    width: 100%;
    height: 222px;
    display: flex;
    background-image: url(${Wheel1});
    background-size: cover;
    background-repeat: no-repeat;
`;