import React, { useEffect } from 'react'
import PlaceholderImage from 'src/components/Placeholder';
import { MainInfoWrapper } from 'src/components/udb/commonStyle';
import config from 'src/config/config';
import { withAppContext } from 'src/store/initAppContext';
import styled, { withTheme } from 'styled-components';
import { theme } from 'styled-tools';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import Intl from 'src/components/common/Intl';
import FooterContainer from "src/components/footer/footer";
import { FifaFeverFirstSecHead, PlayNowButtonNew2, Section } from './crazyCrashPromo';
import { useNavigate } from 'react-router-dom';
function AviatorBonus(props) {
    const { app: {
        brandId
    }, theme } = props;

    useEffect(() => {
        window.scrollTo(0, 0)
    }, [])

    const navigate = useNavigate();

    return (
        <MainInfoWrapper>
            <BannerWrapper>
                <LazyLoadImage src={`${config.imageV3Host}/static/cashsite/${theme.image.aviatorBonusBanner}`} effect="none" height="100%" width="100%" placeholder={<PlaceholderImage />} />
            </BannerWrapper>
            <ContentWrapper>
                <NoBorderTop>
                    <p>Introducing <StepTittle>Aviator's Skybound Miles-</StepTittle> a thrilling opportunity for all aviator enthusiasts to take their gaming  experience to new heights!</p>
                    <p>Join Aviator's Skybound Miles and immerse yourself in the thrilling world of virtual aviation! Whether you're an experienced pilot or new to the adventure, this program is tailored to captivate and engage players of all skill levels. Earn miles with every flight and unlock a treasure trove of exciting prizes.
                        Embrace the challenge, push your limits, and let the rewards soar as you embark on this exhilarating journey. Don't miss your chance to claim your well-deserved prizes—start earning miles today!</p>
                </NoBorderTop>
                <FifaFeverFirstSecHead1 className="aviator">How to Enter Aviator's Skybound Miles ?</FifaFeverFirstSecHead1>
                <Section>
                    <ImageWrapper>
                        <LazyLoadImage src={`${config.imageV3Host}/static/cashsite/${theme.image.gameaviator}`} effect="none" width="100%" placeholder={<PlaceholderImage />} />
                    </ImageWrapper>
                    <ProcessColumn>
                        <li>
                            <LazyLoadImage src={`${config.imageV3Host}/static/cashsite/${theme.image.RegisterIconAviator}`} effect="none" placeholder={<PlaceholderImage />} />
                            <LiTextContent>
                                <StepTittle>REGISTER</StepTittle>
                                <TextContent>Create a Yolo247 account if you do not have one yet. Register with your name and mobile number.</TextContent>
                            </LiTextContent>
                        </li>
                        <li>
                            <LazyLoadImage src={`${config.imageV3Host}/static/cashsite/${theme.image.GameIconAviator}`} effect="none" placeholder={<PlaceholderImage />} />
                            <LiTextContent>
                                <StepTittle>DEPOSIT USING ANY METHOD AND START PLAYING YOUR FAVOURITE GAMES!</StepTittle>
                                <TextContent>Deposit & place minimum of 5,000 bets or more to win the Aviator Bonanza Bonus of INR 250,000 pool!</TextContent>
                            </LiTextContent>
                        </li>
                    </ProcessColumn>
                </Section>
                <FifaFeverFirstSecHead1 className="aviatorChallenge">How to take part in Aviator's Skybound Miles Challenge?</FifaFeverFirstSecHead1>

                <Section>
                    <ProcessColumn className='row-reverse'>
                        <li>
                            <TextContent className='textC'>Embark on an exhilarating journey in Aviator's Skybound Miles, where every bet you place brings you closer to incredible rewards. For example for every INR 10 wagered, you'll earn 10 miles, propelling you towards thrilling victories.</TextContent>
                        </li>
                        <li>
                            <TextContent className='textC'>Get ready for an exhilarating experience in Aviator's Skybound Miles! With every mile you accumulate, you'll unlock a world of unmatched prizes. Don't miss out on the opportunity to fuel your passion for aviator and claim the rewards you deserve.</TextContent>
                        </li>
                        <li>
                            <TextContent className='textC'>Start collecting miles today and embark on an incredible journey of excitement and fulfilment.Your Collected Miles will give you an opportunity to get rewarded every month from June to August.</TextContent>
                        </li>
                        <li>
                            <TextContent className='textC'>Not just that over a period of 3 months ( June to August ) with all your accumulated miles you stand a chance to  win our MEGA JACKPOT Prizes !</TextContent>
                        </li>
                    </ProcessColumn>

                    <ImageWrapper className='imgWrap'>
                        <LazyLoadImage src={`${config.imageV3Host}/static/cashsite/${theme.image.gameaviatorcompass}`} effect="none" width="100%" placeholder={<PlaceholderImage />} />
                    </ImageWrapper>
                </Section>
                <FifaFeverFirstSecHead1 className="aviatorPrize">Prize Allocation</FifaFeverFirstSecHead1>

                <JackPotTable>
                    <LazyLoadImage src={`${config.imageV3Host}/static/cashsite/${theme.image.AviatorTable}`} effect="none" height="100%" width="100%" placeholder={<PlaceholderImage />} />
                </JackPotTable>
                <JackPotTable>
                    <LazyLoadImage src={`${config.imageV3Host}/static/cashsite/${theme.image.AviatorTable2}`} effect="none" height="100%" width="100%" placeholder={<PlaceholderImage />} />
                </JackPotTable>
                <TermsConditionPoints>
                    <StepTittle>Terms & Conditions </StepTittle>
                    <ul>
                        <li><TextContent> This promotion is valid for all new & existing players.</TextContent></li>
                        <li><TextContent>Aviator's Skybound Miles is valid from <StepTittle>1st June to 31st August 2023 Monthly Winners will be
                            announced on 5th July, 5th August & 5th September 2023</StepTittle> on Yolo247 social media handles (
                            Instagram & telegram)</TextContent></li>
                        <li><TextContent><StepTittle>100 lucky Jackpot winners </StepTittle>will be announced on <StepTittle>10th September 2023</StepTittle> on Yolo247 social media handles ( Instagram & telegram)</TextContent></li>
                        <li> <TextContent>No Minimum bet amount required to enter this contest</TextContent></li>
                        <li><TextContent> No Minimum odds to be maintained</TextContent></li>
                        <li><TextContent>Void/Cancelled/Draw Bets, Cashed-Out Bets Do Not Count Towards The Deposit Or Bonus Rollover Requirements.</TextContent></li>
                        <li><TextContent>Multiple Accounts Will Not Be Considered For This Promotion Eligibility / Criteria.
                            All Players Will Be Subject For Duplicate Checking. Yolo247 Reserves The Right To Disqualify Players If Collusion Or Any Foul Play Takes Place In The Promotion.</TextContent></li>
                        <li><TextContent>This Promotion Cannot Be Used In Conjunction With, Or To Contribute To The Eligibility For, Any Other Promotion.</TextContent></li>
                        <li><TextContent>Yolo247 Reserves The Right To Amend, Cancel And Reclaim Or Refuse Any Promotion At Its Own Discretions.</TextContent></li>
                        <li><TextContent>Yolo247 General Terms & Conditions Apply.</TextContent></li>
                    </ul>
                </TermsConditionPoints>
                <PlayNowButtonNew2 onClick={() => navigate("/instant-games/spribe/aviator")}>Play Now</PlayNowButtonNew2>
            </ContentWrapper>
            <FooterContainer />
        </MainInfoWrapper>
    )
}

export default withTheme(withAppContext(AviatorBonus));

const FifaFeverFirstSecHead1 = styled(FifaFeverFirstSecHead)`
text-transform: uppercase;
font-size: 30px;
`
const LiTextContent = styled.div`
    width: 90%;
    display: flex;
    flex-direction: column;

    p {
        margin: 0;
        margin-top: 10px;
    }

    span {
        text-transform: uppercase;
        font-size: 18px;
    }
`;

const NoBorderTop = styled.div`
    width: 100%;
    display: flex;
    flex-direction: column;
    padding: 0rem 17rem 2.5rem 17rem;

    p {
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
        color: ${theme('palette.catch99TextContentColor')};
        font-size: 21px;
        gap: 5px;
        text-align: center;
        margin: 13px 0;
    }
    span{
        font-size: 21px;
    }
`;

const TermsConditionPoints = styled.div`
    width: 90%;
    display: flex;
    flex-direction: column;
    gap: 15px;
    padding-bottom: 2rem;
    border: ${theme('palette.AviatorjackPotWrapperBgBorder')};
    border-radius: 16px;
    padding: 3rem;
    background: ${theme('palette.AviatorTermsConditionPointsBg')};

    p {
        padding-left: 1rem;
        text-transform: capitalize;
    }

    ul {
        list-style: number;
        color: #fff;
        padding-left: 35px;
        margin-top: 0;

        li {
            p{
                display: flex;
                gap: 5px;
                padding-left: 0rem;
                margin: 0 0 10px 0;
            }
        }
    }
`;

const JackPotTable = styled.div`
    width: 70%;
    display: flex;
padding-bottom: 3rem;
    img {
        max-width: 100%;
    }
`;

export const ImageWrapper = styled.div`
     width: 35%;
    display: flex;
    padding-top: 47px;

    img {
        max-width: 100%;
    }
`;

export const TextContent = styled.p`
    display: flex;
    color: ${theme('palette.catch99TextContentColor')};
    font-size: 17px;
`;

const StepTittle = styled.span`
    display: flex;
    color: ${theme('palette.aviatorStepTittleColor')};
    font-size: 17px;
    font-family: 'Inter-Bold';
    display: contents;
`;

export const ProcessColumn = styled.ul`
    width: 50%;
    padding: 2rem;
    position: relative;
    justify-content: center;
    display: flex;
    flex-direction: column;
    gap: 1.5rem;
    li {
        border:2px solid #E50539;
        display: flex;
        align-items: center;
        flex-direction: row;
        gap: 1.5rem;
        padding: 1.5rem;
        border-radius: 8.09632px;
        background: linear-gradient(180deg, rgba(255, 255, 255, 0.05) 0%, rgba(255, 255, 255, 0.005) 100%);
        mix-blend-mode: normal;
        backdrop-filter: blur(42.6023px);
    }

    img {
        width: 98px;
        height: 98px;
    }
 `;

export const ContentWrapper = styled.div`
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    background: url(${config.imageV3Host}/static/cashsite/${theme('image.AviatorContentWrapperBg')});
    background-position: 100% 5%;
    background-size: cover;
    background-repeat: no-repeat;
    padding: 4rem 0;
    
`;
export const BannerWrapper = styled.div`
    width: 100%;
    display: flex;
    transition: 700ms;
    img {
        max-width: 100%;
        transition: 700ms;
    }
`;