import React from "react";
import styled from "styled-components";
import {
  BodyWrapper as SocialBodyWrapper,
  FirstCol,
  Header,
  MainInfoWrapper,
} from "../../../components/udb/commonStyle";
import Google from "src/components/icon/Google";
import Telegram from "src/components/icon/Telegram";
import Facebook from "src/components/icon/Facebook";
import Intl from "src/components/common/Intl";
import UDBHeader from "src/components/UDBHeader";

type Props = {};

export default function SocialMedia({ }: Props) {
  return (
    <MainInfoWrapper>
      <Header>
        <FirstCol>
           <UDBHeader title="SOCIALMEDIA@LinkSocialMedia"/>
        </FirstCol>
      </Header>
      <BodyWrapper>
        <TextInside><Intl langKey="SOCIALMEDIA@LinkAcc" /></TextInside>
        <ButtonWrap>
          <SocialMediaButtons>
            <Svg>
              <Google />
            </Svg>
            Google
          </SocialMediaButtons>
          <SocialMediaButtons>
            <Svg>
              <Facebook />
            </Svg>
            Facebook
          </SocialMediaButtons>
          <SocialMediaButtons>
            <Svg>
              <Telegram />
            </Svg>
            Telegram
          </SocialMediaButtons>
        </ButtonWrap>
        <TextInsideForFooter>
          <Intl langKey="SOCIALMEDIA@LinkFast" />
        </TextInsideForFooter>
      </BodyWrapper>
    </MainInfoWrapper>
  );
}

//Styled Components
const BodyWrapper = styled(SocialBodyWrapper)`
  width: 58%;
  justify-content: flex-start;
`;
const TextInside = styled.div`
  display: flex;
  padding: 0px 0px 10px;
  color: #f4a322;
  font-size: 13px;
`;

const TextInsideForFooter = styled.div`
  font-size: 15px;
  display: flex;
  color: white;
  font-size: 11px;
  padding: 20px 0px 10px;
`;

const SocialMediaButtons = styled.button`
  width: 120px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 16px;
  font-weight: 600;
  padding-top: 5px;
  border: 1px solid white;
  border-radius: 7px;
  padding: 14px 5px;
  color: #3e3999;
  margin-right: 10px;
  cursor: pointer;
`;

export const ButtonWrap = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  border-bottom: 1px solid #e1e1e1;
  padding-bottom: 17px;

  button:hover {
    background: linear-gradient(180deg, #ffffff 0%, #ffffff 100%);
  }
`;

export const Svg = styled.div`
  display: flex;
  align-items: center;
  margin: -14px;
  padding-right: 21px;
  svg {
    width: 18px;
    height: 18px;
  }
`;
