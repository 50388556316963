import { useEffect } from 'react'
import PlaceholderImage from 'src/components/Placeholder';
import config from 'src/config/config';
import { withAppContext } from 'src/store/initAppContext';
import styled, { css, withTheme } from 'styled-components';
import { theme } from 'styled-tools';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import FooterContainer from "src/components/footer/footer";
import YellowTickIcon from 'src/components/icon/YellowTickIcon';


const SmartSoftJetxCelestialPromo = (props) => {
    const { app: { brandId
    }, theme } = props;

    useEffect(() => {
        window.scrollTo(0, 0)
    }, [])

    const domain = (brandId == 31 || brandId == 32) ? "Yolo247" : (brandId == 39 || brandId == 40) ? "FOMO7" : 'BaaziAdda';
    const classBorder = brandId === 33 ? "borderActive" : null;
    return (
        <>
            <InnerPromoWrapper>
                <Catch99ContentWrapper>
                    <MainHeading>
                        <h1>Instant Prizes Tournament with</h1>
                        <h1 className='seconddRow'>{domain}</h1>
                        <span className='underlines'></span>
                    </MainHeading>
                    <JetXCRTopSec>
                        <JetXTopSecInnerBox className={classBorder}>
                            <h4>Unleash the Turbo</h4>
                            <h4 className='seconddRow'>Boost:</h4>
                            <span className='underlines'></span>
                            <p>
                                Engage the turbo boost and navigate through the cosmic challenges. The faster you go, the bigger the rewards! Strap in for an exhilarating ride where every decision counts.
                            </p>
                        </JetXTopSecInnerBox>
                        <JetXTopSecInnerBox className={classBorder}>
                            <h4>Strategy Meets</h4>
                            <h4 className='seconddRow'>Speed</h4>
                            <span className='underlines'></span>
                            <p>
                                It&apos;s not just about speed; it&apos;s about strategy. Plot your course wisely, dodge obstacles, and seize opportunities to emerge as the ultimate betting maverick. The galaxy is your playground; conquer it with your strategic prowess.
                            </p>
                        </JetXTopSecInnerBox>
                    </JetXCRTopSec>

                    <JetXCRTopSec>
                        <JetXTopSecInnerBox className={classBorder}>
                            <h4>Wager and Win</h4>
                            <span className='underlines'></span>
                            <p>
                                Place your bets as you navigate through the cosmic arena. The stakes are high, and the rewards are astronomical. Will you emerge as the top pilot, claiming victory and stacking up winnings?
                            </p>
                        </JetXTopSecInnerBox>

                        <JetXTopSecInnerBox className={`bottomAlign ${classBorder}`}>
                            <h4>Valid from</h4>
                            <span className='underlines'></span>
                            <p>1st July to 30th July 2024</p>
                        </JetXTopSecInnerBox>

                        <JetXTopSecInnerBox className={classBorder}>
                            <h4>Pool prize</h4>
                            <span className='underlines'></span>
                            <p className=' bold'>INR 30 Lacs</p>
                        </JetXTopSecInnerBox>
                    </JetXCRTopSec>

                    <JetXCRSecondSec>
                        <h2>JetX Celestial Rush: Soar and Score at {domain}</h2>
                        <span className='underlines'></span>
                        <p>How to Participate</p>
                    </JetXCRSecondSec>

                    <JetXCRThirdSec>
                        <LazyLoadImage src={`${config.imageV3Host}/static/cashsite/${theme.image.SmartSoftjetxIllus}`} alt="jetxPromoIllus" effect="none" placeholder={<PlaceholderImage />} />
                    </JetXCRThirdSec>

                    <JetXCRFourthSec>
                        <JetXCRFourthTop>
                            <JetXCRTopLeft>
                                <h2>Additional Rules</h2>
                                <span className='underlines'></span>
                                <TextWrapper>
                                    <span><YellowTickIcon /></span>
                                    <p>Players have 30 seconds to spin the prize wheel after it appears. If there is no activity within this time, the wheel will automatically spin, and a prize will be awarded randomly.</p>
                                </TextWrapper>
                                <TextWrapper>
                                    <span><YellowTickIcon /></span>
                                    <p>The auto&#45;spin function will be disabled when the prize wheel appears</p>
                                </TextWrapper>
                                <TextWrapper>
                                    <span><YellowTickIcon /></span>
                                    <p>We also reserve the right not to pay out a prize if we suspect cheating or collusion with other players</p>
                                </TextWrapper>
                                <TextWrapper>
                                    <span><YellowTickIcon /></span>
                                    <p>We reserve the right to void prize payments or not pay out a prize if there are any obvious errors, mistakes, or technical faults (including incorrect game payouts) caused by a machine or human error in any of the participating games</p>
                                </TextWrapper>
                                <TextWrapper>
                                    <span><YellowTickIcon /></span>
                                    <p>All decisions made by the Casino management team are final</p>
                                </TextWrapper>
                                <TextWrapper>
                                    <span><YellowTickIcon /></span>
                                    <p>Prize chances on the wheel are randomly generated at the start of each spin</p>
                                </TextWrapper>
                                <TextWrapper>
                                    <span><YellowTickIcon /></span>
                                    <p>Any unused spins will be lost when the promotion ends</p>
                                </TextWrapper>
                                <TextWrapper>
                                    <span><YellowTickIcon /></span>
                                    <p>Only real money bets placed on desktop, mobile, or mobile app platforms qualify for the promotion</p>
                                </TextWrapper>
                                <TextWrapper>
                                    <span><YellowTickIcon /></span>
                                    <p>In case of loss of connection or technical issues, affected rounds may be invalid</p>
                                </TextWrapper>
                                <TextWrapper>
                                    <span><YellowTickIcon /></span>
                                    <p>Upon winning a Prize Drop wheel spin, a nominal bet amount may be temporarily deducted from the player's funds, depending on the casino's policies. This deduction is temporary, and the deducted amount will be credited back to the player after spinning the wheel</p>
                                </TextWrapper>
                                <TextWrapper>
                                    <span><YellowTickIcon /></span>
                                    <p>Smartsoft &amp; {domain} reserves the right to change, suspend, or cancel the promotion and its prizes at any time</p>
                                </TextWrapper>
                                <TextWrapper>
                                    <span><YellowTickIcon /></span>
                                    <p>By participating, users agree to the terms and conditions of Smartsoft Gaming &amp; Casino.</p>
                                </TextWrapper>
                            </JetXCRTopLeft>
                        </JetXCRFourthTop>
                        <JetXCRTopRight>
                            <LazyLoadImage src={`${config.imageV3Host}/static/cashsite/${theme.image.jetxPromoPlane1}`} alt="jetxPromoIllus" effect="none" placeholder={<PlaceholderImage />} />
                        </JetXCRTopRight>
                    </JetXCRFourthSec>

                    <JetXCRFinalSec>
                        <JetXCRFinalSecLeft>
                            <LazyLoadImage src={`${config.imageV3Host}/static/cashsite/${theme.image.jetxPromoPlane2}`} alt="jetxPromoPlane2" effect="none" placeholder={<PlaceholderImage />} />
                        </JetXCRFinalSecLeft>

                        <JetXCRFinalSecRight>
                            <JetXCRTermsTitle>
                                <LazyLoadImage src={`${config.imageV3Host}/static/cashsite/${theme.image.jetxPromoTC}`} alt="jetxPromoT&C" effect="none" placeholder={<PlaceholderImage />} />
                                <h6>Terms &amp; Conditions</h6>
                            </JetXCRTermsTitle>
                            <JetXTermsBody>
                                <ol>
                                    <li>This promotion is valid for all new &amp; existing players.</li>
                                    <li>This promotion is only applicable for Jetx, Balloon, PlinkoX, FootballX, CricketX, GeniesBonanza, BurningICE40, Multihotways, JetX3, BurningICE, BurningICE10, Jungles, MagicGarden40, Cappadocia or ones available on the website</li>
                                    <li>Void/Cancelled/Draw bets, Cashed&#45;Out bets do not count towards the deposit or bonus rollover requirements.</li>
                                    <li>Multiple accounts will not be considered for this promotion eligibility / criteria. All players will be subject for duplicate checking. {domain} reserves the right to disqualify players if collusion or any foul play takes place in the promotion.</li>
                                    <li>{domain} reserves the right to amend, cancel and reclaim or refuse any promotion at its own discretions.</li>
                                    <li>{domain} General Terms &amp; Conditions apply.</li>
                                    <li>Valid from 1st July to 30th July 2024</li>
                                </ol>
                            </JetXTermsBody>
                        </JetXCRFinalSecRight>
                    </JetXCRFinalSec>
                </Catch99ContentWrapper>
            </InnerPromoWrapper>
            <FooterContainer />
        </>
    )
}

export default withTheme(withAppContext(SmartSoftJetxCelestialPromo));

//Styled Components

const Catch99ContentWrapper = styled.div`
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background: url(${config.imageV3Host}/static/cashsite/${theme('image.SmartSoftJetxCelestialBackground')});
    background-position: top;
    background-size: cover;
    background-repeat: no-repeat;
    gap: 2em;

    .m-0 {
        margin: 0;
    }
    .p-0 {
        padding: 0rem 20rem;

        span{
            color: #E5A20D;
        }
    }

    a {
        width: 100%;
        height: auto;

        & span {
            display: flex !important;
            justify-content: center !important;

            & img {
                width: 60%;
                height: 80%;
            }
        }
    }

    .lazy-load-image-background {
        display: flex !important;
        justify-content: center !important;
    }
`;

const MainHeading = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    margin-top: 4rem;

    h1 {
        color: #FEDE11;
        text-align: center;
        text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
        font-size: 25.235px;
        line-height: 3.59375rem; /* 110.87% */
        font-style: italic;
        font-weight: 900;
        line-height: 28px; /* 95.104% */
        text-transform: capitalize;
        margin-bottom: 0;
    }

    .seconddRow {
        margin: 0;
    }

    & .underlines {
        width: 20%;
        height: 2px;
        background-color: #FEDE11;
        margin-top: 1em;
    }
`;

const TextWrapper = styled.div`
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    background: #4E535F;
    margin: 3px 0px;
    border-radius: 0.3125rem;
    padding: 5px;


    p {
        margin: 0;
        color: #FFF;
        text-align: justify;
        font-size: 0.875rem;
        font-style: normal;
        font-weight: 500;
        line-height: 120.5%; /* 0.86188rem */
        padding-left: 5px;
    }
`;

const InnerPromoWrapper = styled.div`
    width: 98%;
    height: auto;
    border: 1px solid white;
    border-radius: 1px;
    background: rgba(111,111,111,0.5);
    margin: 0em 1.2em;
    -webkit-backdrop-filter: brightness(0.5);
    backdrop-filter: brightness(0.5);
`;

const JetXCRTopSec = styled.div`
   width: 85%;
   display: flex;
   justify-content: space-between;
   align-items: center;

   .bottomAlign {
    align-self: flex-end;
   }

   .BazziAddaBorder {
    border-radius: 35px;
    border: 0.5px solid rgba(255, 255, 255, 0.50);
    background: rgba(255, 255, 255, 0.05);
    backdrop-filter: blur(12.5px);
   }
`;

const JetXTopSecInnerBox = styled.div`
    width: 25%;
    height: auto;
    padding: 2em;
    border-radius: 34.283px;
    background: linear-gradient(180deg, rgba(0, 0, 0, 0.10) 0%, rgba(255, 255, 255, 0.00) 100%, rgba(0, 0, 0, 0.00) 100%);
    backdrop-filter: blur(39.56802749633789px);
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;

    &.borderActive {
        border: 0.5px solid rgba(255, 255, 255, 0.50);
    }

    & h4 {
        color: #FEDE11;
        font-size: 1.4375rem;
        font-style: italic;
        font-weight: 900;
        line-height: 1.59375rem; /* 110.87% */
        text-transform: capitalize;
        text-align: left;
        margin: 5px 0px;
    }

    & p {
        color: #FFF;
        text-align: justify;
        font-size: 0.875rem;
        font-style: normal;
        font-weight: 500;
        line-height: 128.5%; /* 1.12438rem */
        text-align: left !important;
        padding: 0em;
    }

    .bold {
        font-weight: bolder;
        text-transform: uppercase;
    }

    & .underlines {
        width: 30%;
        height: 2px;
        background-color: #FEDE11;
        margin-top: 1em;
    }

    .seconddRow {
        margin: 0;
    }
`;

const JetXCRSecondSec = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 90%;
    height: auto;
    margin: 2rem 0 1rem 0;

    & .underlines {
        width: 4.5em;
        height: 2px;
        background-color: #FEDE11;
        margin-top: 1em;
    }

    & h2 {
        color: #FEDE11;
        text-align: center;
        font-size: 1.4375rem;
        font-style: italic;
        font-weight: 800;
        line-height: 1.59375rem; /* 110.87% */
        text-transform: capitalize;
        margin: 3px 0px;
    }

    & p {
        color: #FFF;
        text-align: center;
        font-size: 15.361px;
        font-style: italic;
        font-weight: 400;
        line-height: normal;
    }
`;

const JetXCRThirdSec = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    height: auto;
    margin: 2rem 0rem;

    & .underlines {
        width: 4.5em;
        height: 2px;
        background-color: #FEDE11;
        margin-top: 1em;
    }

    & h2 {
        color: #FEDE11;
        text-align: center;
        font-size: 1.4375rem;
        font-style: italic;
        font-weight: 800;
        line-height: 1.59375rem; /* 110.87% */
        text-transform: capitalize;
        margin: 3px 0px;
    }

    & img {
        width: 100%;
        height: auto;
    }
`;

const JetXCRFourthSec = styled.div`
    display: flex;
    width: 100%;
    justify-content: flex-start;
    align-items: flex-start;
    position: relative;
    padding: 0 0 0 10rem;
    z-index: 0;

    & img {
        width: 100%;
        height: auto;
    } 
`;

const JetXCRFourthTop = styled.div`
    display: flex;
    width: 50%;
    border-radius: 38.402px;
    background: radial-gradient(ellipse at left top, #3A3636, #22141A 50%);
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 20px;
    height: auto;
    padding: 3.2rem 2rem 2rem 2rem;
    position: relative;

    & > .underlines {
        width: 6.5em;
        height: 2px;
        background-color: #FEDE11;
        margin-top: 1em;
    }
    .underlines{
        width: 6.5em;
        height: 2px;
        background-color: #FEDE11;
        margin-top: 0.5em;
    }

    & h2 {
        color: #FEDE11;
        text-align: left;
        font-size: 1.4375rem;
        font-style: italic;
        font-weight: 800;
        line-height: 1.59375rem; /* 110.87% */
        text-transform: capitalize;
        margin: 3px 0px;
    }

    & h6 {
        color: #FFF;
        text-align: justify;
        font-size: 0.875rem;
        font-style: normal;
        font-weight: 500;
        line-height: 120.5%; /* 0.86188rem */
        margin: 3px 0px;
        border-radius: 0.3125rem;
        background: #4E535F;
        padding: 5px;
    }

    ::after {
        position: absolute;
        top: 0; bottom: -1.821px;
        left: -1.821px; right: -1.821px;
        background: linear-gradient(180deg, transparent, #96977D);
        backdrop-filter: blur(44.321685791015625px);
        content: '';
        z-index: -1;
        border-radius: 38.402px;
    }
`;

const JetXCRTopLeft = styled.div`
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 10px;
`;

const JetXCRTopRight = styled.div`
     width: 40%;
     position: absolute;
     right: 13rem;
     top: 6rem;
     z-index: -1;

     & img {
        width: 550px;
        height: auto;
    } 
`;

const JetXCRFinalSec = styled.div`
    width: 100%;
    height: auto;
    display: flex;
    justify-content: flex-end;
    gap: 10px;
    margin: 2em 0em;
    position: relative;
    padding-right: 12%;
`;

const JetXCRFinalSecLeft = styled.div`
     width: 40%;
     height: auto;
     position: absolute;
     left: 14vw;
     top: 0px;
     z-index: 1;

    & img {
        width: 750px;
        height: auto;
    } 

    @media only screen and (min-width: 1500px){
        left: 20vw;
    }

    @media only screen and (min-width: 1900px){
        left: 24vw;
    }
`;

const JetXCRFinalSecRight = styled.div`
    width: 40%;
    height: auto;
    padding: 1em;
    display: flex;
    flex-direction: column;
    align-items: end;
    border-radius: 38.402px;
    border: 2.922px solid #695D55;
    background: radial-gradient(39.66% 109.3% at 12.19% 6.02%, rgba(66, 58, 59, 0.72) 0%, rgba(0, 0, 0, 0.72) 100%);
    background: radial-gradient(ellipse at left 0%, rgba(66, 58, 59, 0.72) 0%, rgba(0, 0, 0, 0.72) 100%);
    backdrop-filter: blur(144.321685791015625px);
`;

const JetXCRTermsTitle = styled.div`
   padding: 1em;

   & img {
        width: 100%;
        height: auto;
    } 

    h6 {
        color: #FFF;
        text-align: center;
        font-size: 1.10781rem;
        font-style: normal;
        font-weight: 700;
        line-height: 1.48819rem; /* 134.333% */
        letter-spacing: 0.09969rem;
        text-transform: capitalize;
        margin: 3px 0px;
    }
`;

const JetXTermsBody = styled.div`
    width: 100%;
    height: auto;
  
    & ol {
        padding: 1em 2em;
        margin: 0px;
    }

    & li {
       color: #FFF;
       text-align: justify;
       font-size: 0.875rem;
       font-style: normal;
       font-weight: 600;
       line-height: normal;
       letter-spacing: -0.035rem;
       text-transform: capitalize;
       margin: 15px 0px;
    }
`;