import React, { useEffect, useRef, useState } from 'react'
import styled from 'styled-components';
import { theme } from 'styled-tools';
import CurrencyIcon from './svg/Currency';
import config from 'src/config/config';
import { BetAmount, Payout, SectionWrapeer, ShimmerEff, Table, User, UserName } from './Bets';
import { Heading, Rank, toLocalNumber, Turnover } from './MyRanks';
import Intl from 'src/components/common/Intl';
import { getLeaderBoardData } from 'src/config/api';
import { Notify } from '../Notification';
import { useNavigate } from 'react-router-dom';

type DataProps = {
    my_ranks: any;
    top_rankers: any[];
    total_price: number
}

type Props = {
    data: DataProps;
    selectedTab: string;
    isloading: boolean;
    brand?: number,
    numrows: number
}

const getISTDate = () => {
    var currentTime = new Date();
    var currentOffset = currentTime.getTimezoneOffset();
    var ISTOffset = 330;   // IST offset UTC +5:30 
    var ISTTime = new Date(currentTime.getTime() + (ISTOffset + currentOffset) * 60000);
    return ISTTime;
}

const getDiffTime = () => {
    const ISTDate = getISTDate();
    const YYYY = ISTDate.getFullYear();
    const month = ISTDate.getMonth();
    const date = ISTDate.getDate();
    const hour = ISTDate.getHours();
    const mins = ISTDate.getMinutes();
    const second = ISTDate.getSeconds();

    let startDate = new Date(YYYY, month, date, hour, mins, second);
    let endDate = new Date(YYYY, month, date, 24, 0, 0);
    let diff = endDate.getTime() - startDate.getTime();
    let hours = Math.floor(diff / 1000 / 60 / 60);
    diff -= hours * 1000 * 60 * 60;
    let minutes = Math.floor(diff / 1000 / 60);

    diff -= minutes * 1000 * 60;
    let ss: any = Math.floor(diff / 1000);

    // If using time pickers with 24 hours format, add the below line get exact hours
    if (hours < 0)
        hours = hours + 24;

    return (hours <= 9 ? "0" : "") + hours + " : " + (minutes <= 9 ? "0" : "") + minutes + " : " + (ss <= 9 ? "0" : "") + ss;
}

const DailyTimeRemaining = () => {
    const [balanceHr, setBalanceHr] = useState("00 : 00 : 00");

    useEffect(() => {
        let t = setInterval(() => {
            const timeremaining = getDiffTime();
            setBalanceHr(timeremaining);
        }, 1000)

        return () => clearInterval(t);

    }, [])

    return (
        <Timer>{balanceHr}</Timer>
    )
}

const RankSufix = ({ rank }: { rank: number }) => {
    if (rank === 1) {
        return <span>{rank}<sup>st</sup></span>
    }
    else if (rank === 2) {
        return <span>{rank}<sup>nd</sup></span>
    }
    else if (rank === 3) {
        return <span>{rank}<sup>rd</sup></span>
    }
    else if (rank > 3 && rank <= 99) {
        return <span>{rank}<sup>th</sup></span>
    }

    return (<span>{rank}</span>)
}

export default function LeaderTable(props: Props) {
    const {
        data,
        selectedTab,
        isloading,
        brand,
        numrows
    } = props;

    const [remainDays, setRemaiDays] = useState(0);
    const [lbState, setLbState] = useState({} as any);
    const refElm = useRef<HTMLDivElement>();
    const tRef = useRef<any>(0);
    const delay = 300000; //5 Mins
     const navigate = useNavigate();

    useEffect(() => {
        clearInterval(tRef.current);
        if (data.my_ranks) {
            setLbState(data);
            try {
                const sectionWrapper = refElm.current;
                const observer = new IntersectionObserver((entries) => {
                    if (entries[0].isIntersecting) {
                        tRef.current = setInterval(() => { getLatestBetsData() }, delay);
                    }
                    else {
                        clearInterval(tRef.current);
                    }
                });

                observer.observe(sectionWrapper);

            } catch (error) {
                console.log('IntersectionObserver issue');
                tRef.current = setInterval(() => { getLatestBetsData() }, delay);
            }
        }

        return () => {
            clearInterval(tRef.current);
        };

    }, [data])


    const {
        my_ranks = {},
        top_rankers = [],
        total_price=0
    } = lbState;

    let _myRank = my_ranks.daily_race_rank;
    let _prizeIco = 'currency';

    if (selectedTab === 'WEEKLYRACE') {
        _myRank = my_ranks.weekly_race_rank
        _prizeIco = 'trophy'
    }
    else if (selectedTab === 'TOURNAMENT') {
        _myRank = my_ranks.tournament_rank;
        _prizeIco = 'award'
    }

    useEffect(() => {
        const ISTDate = getISTDate();
        if (selectedTab === 'WEEKLYRACE') {
            const remainDays = 6 - ISTDate.getDay();
            setRemaiDays(remainDays)
        }
        else if (selectedTab === 'TOURNAMENT') {
            const YYYY = ISTDate.getFullYear();
            const month = ISTDate.getMonth();
            const date = ISTDate.getDate();
            let startDate: any = new Date(YYYY, month, date);
            let endDate: any = new Date(YYYY, 4, 25);
            const ms_per_day = 1000 * 60 * 60 * 24;
            const remainDays: any = Math.floor((endDate - startDate) / ms_per_day);
            setRemaiDays(remainDays <= 0 ? 0 : remainDays)
        }

    }, [isloading]);

    const getLatestBetsData = () => {
        const param = `type=${selectedTab}&limit=${numrows}&brand_id=${brand}`;
        getLeaderBoardData(param).then(res => {
            const { data = {} } = res.data;
            setLbState(data);

        }).catch(e => {
            const { data } = e.response || {};
            let message = 'Something Went Wrong, Please Try Again';
            if (data && data.error) {
                message = data.error;
            }
            Notify.error(message);
        });
    }

    const promoPageHandle = () => {
        let href = '/promotions/daily-leaderboard';
        if(selectedTab === 'WEEKLYRACE'){
            href = '/promotions/weekly-leaderboard'
        }
        else if(selectedTab === 'TOURNAMENT'){
            href = '/promotions/ipl-jackpot'
        }

        navigate(href);

    }

    const Days = remainDays > 1 ? `${remainDays} Days ` : `${remainDays} Day`;
    const _user = brand === 46 || brand === 47 ? 'u47' : '';

    return (<>
        <TopSection ref={refElm}>
            <PrizeBox>
                <TextWrapper className={_prizeIco}>
                    <Heading><Intl langKey="totalPrize" /></Heading>
                    <Turnover>
                        {/* <CurrencyIcon /> */}
                        <span>₹{toLocalNumber(total_price||0)}</span></Turnover>
                    <Rank><Intl langKey="yourRank" /> : <span> {_myRank}</span></Rank>
                </TextWrapper>

            </PrizeBox>
            <TimerBox>
                <TextWrapper className={selectedTab === 'DAILYRACE' ? 'watch' : 'flag'}>
                    <Heading><Intl langKey="timeRemaining" /></Heading>
                    <>
                        {selectedTab === 'DAILYRACE' && <DailyTimeRemaining />}
                        {selectedTab !== 'DAILYRACE' && <Timer>{Days}</Timer>}
                    </>
                </TextWrapper>
                <How2WorkBtn onClick={promoPageHandle}><Intl langKey="howitworks" />?</How2WorkBtn>
            </TimerBox>

        </TopSection>
        <SectionWrapeer>

            <Table>
                <thead>
                    <tr>
                        <th><Intl langKey="rank" /></th>
                        <th><Intl langKey="user" /></th>
                        <th><Intl langKey="wagered" /></th>
                        <th><Intl langKey="prize" /></th>
                    </tr>
                </thead>
                {
                    isloading && <ShimmerEff />
                }
                {!isloading && top_rankers.length > 0 ? (<tbody>
                    {
                        top_rankers.map(elm => {
                            const {
                                TTO,
                                weekly_TTO,
                                daily_rank,
                                weekly_rank,
                                comp_rank,
                                comp_TTO,
                                username,
                                price
                            } = elm;

                            const _tto = selectedTab === 'DAILYRACE' ? TTO : selectedTab === 'WEEKLYRACE' ? weekly_TTO : comp_TTO;
                            const _rank: number = selectedTab === 'DAILYRACE' ? daily_rank : selectedTab === 'WEEKLYRACE' ? weekly_rank : comp_rank;
                            const _wagered = _tto && Math.abs(Number(_tto));

                            return (
                                <tr key={username}>
                                    <td><strong><RankSufix rank={_rank} /></strong></td>
                                    <td><User className={_user}><UserName>{username}</UserName></User></td>
                                    <td><BetAmount>₹{toLocalNumber(_wagered)}</BetAmount></td>
                                    <td><Payout>
                                        {/* <CurrencyIcon /> */}
                                        ₹{toLocalNumber(price)}
                                        </Payout></td>
                                </tr>

                            )
                        })
                    }

                </tbody>) :
                    (!isloading && <tbody>
                        <tr>
                            <td colSpan={4} className='noRecord'><Intl langKey="nodata" /></td>
                        </tr>
                    </tbody>)
                }
            </Table>

        </SectionWrapeer>
    </>
    )
}


//styled Component
const TopSection = styled.div`
    display: flex;
    gap: 10px;
    padding-right: 27px;
    padding-bottom: 15px;
`;

const PrizeBox = styled.div`
    flex-shrink: 0;
    border-radius: 8px;
    border: ${theme('palette.lbBoxWrapperBorder')};
    background:${theme('palette.lbBoxWrapperBg')};
    margin-right: 12px;
    position: relative;
    width: 50%;
    height: 100px;
    padding: 0 26px;
    align-items: center;
    display: flex;
    font-size: 13px;
`;

const TimerBox = styled(PrizeBox)`
    justify-content: space-between;
`;

const TextWrapper = styled.div`
    display: flex;
    padding-left: 75px;
    flex-direction: column;
    > p {
        padding-bottom: 3px;
        margin: 0;
        display: flex;
        align-items: center;
    }
    &.currency {
        background: url('${(props: any) => `${config.imageV3Host}/static/snk${props.theme.image.lbMoneySm}`}') no-repeat 0 center;
    }
    &.trophy {
        background: url('${(props: any) => `${config.imageV3Host}/static/snk${props.theme.image.lbTrophySm}`}') no-repeat 0 center;
    }
    &.award {
        background: url('${(props: any) => `${config.imageV3Host}/static/snk${props.theme.image.lbAwardSm}`}') no-repeat 0 center;
    }
    &.watch {
        background: url('${(props: any) => `${config.imageV3Host}/static/snk${props.theme.image.lbWatchSm}`}') no-repeat 0 0;
    }
    &.flag {
        background: url('${(props: any) => `${config.imageV3Host}/static/snk${props.theme.image.lbFlagSm}`}') no-repeat 0 0;
    }
`;

const Timer = styled.p`
    color: #FFF;
    font-size: 18px;
    font-style: normal;
    font-weight: 700;
    line-height: 16.818px;
`;

const How2WorkBtn = styled.div`
    width: 133px;
    height: 36px;
    flex-shrink: 0;
    background: linear-gradient(180deg, #FFEA00 -82.29%, #E29000 100%);
    color: #fff;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 3px;
    cursor: pointer;
`;