import React, { useEffect } from 'react'
import { withAppContext } from 'src/store/initAppContext';
import styled, { withTheme } from 'styled-components';
import { LazyLoadImage } from "react-lazy-load-image-component";
import config from "src/config/config";
import PlaceholderImage from 'src/components/Placeholder';
import { theme } from 'styled-tools';
import FooterContainer from 'src/components/footer/footer';
import { Link } from 'react-router-dom';

function LightningCashback2k25PromoFun(props: any) {
    const { app: {
        brandId
    }, theme } = props;

    useEffect(() => {
        window.scrollTo(0, 0)
    }, [])

    return (
        <>
            <InformationWrapper>
                <TitleText>
                    <h1>Lightning Cashback - Get 1% Back Instantly! ⚡</h1>
                    <p>Feel the thrill of Lightning Games and enjoy guaranteed cashback! <br />
                        Play your favorite Evolution games and instantly receive a 1% cashback —no strings attached!</p>
                </TitleText>
                <PromoPeriodWrapper>
                    <LazyLoadImage
                        src={`${config.imageV3Host}/static/cashsite/${theme.image.PromotionPeriodImg}`}
                        alt="jetxPromoStepThree"
                        effect="none"
                        placeholder={<PlaceholderImage />}
                        className="promotionPeriodImg"
                    />
                    <h2>Eligible Games</h2>
                    <div className='eligibleGames'>
                        <Link to="/popular-games/lightning-roulette">
                            <LazyLoadImage
                                src={`${config.imageV3Host}/static/cashsite/brand/6/image/eligibleGames1.png`}
                                alt="Eligible Games"
                                effect="none"
                                placeholder={<PlaceholderImage />}
                            />
                        </Link>
                        <Link to="/live-casino/lightning-roulette">
                            <LazyLoadImage
                                src={`${config.imageV3Host}/static/cashsite/brand/6/image/eligibleGames2.png`}
                                alt="Eligible Games"
                                effect="none"
                                placeholder={<PlaceholderImage />}
                            />
                        </Link>
                        <Link to="/popular-games/Ldragontiger">
                            <LazyLoadImage
                                src={`${config.imageV3Host}/static/cashsite/brand/6/image/eligibleGames3.png`}
                                alt="Eligible Games"
                                effect="none"
                                placeholder={<PlaceholderImage />}
                            />
                        </Link>
                    </div>
                </PromoPeriodWrapper>

                <HowToWorksWrapper>
                    <h2>How It Works</h2>
                    <p>Experience the excitement of instant cashback on your favorite <strong>Evolution games</strong>.</p>
                    <div className='eligibleGames'>
                        <LazyLoadImage
                            src={`${config.imageV3Host}/static/cashsite/brand/6/image/HowToWorksWrapperStep1Fun.png`}
                            alt="Step Images"
                            effect="none"
                            placeholder={<PlaceholderImage />}
                        />
                        <LazyLoadImage
                            src={`${config.imageV3Host}/static/cashsite/brand/6/image/HowToWorksWrapperStep2Fun.png`}
                            alt="Step Images"
                            effect="none"
                            placeholder={<PlaceholderImage />}
                        />
                        <LazyLoadImage
                            src={`${config.imageV3Host}/static/cashsite/${theme.image.LightningCashbackStepImgThree}`}
                            alt="Step Images"
                            effect="none"
                            placeholder={<PlaceholderImage />}
                        />
                    </div>
                    <p>Rewards are distributed on a <strong>first-come, first-served basis,</strong> so act fast!</p>
                </HowToWorksWrapper>

                <Container>
                    <LeftSection>
                        <LazyLoadImage
                            src={`${config.imageV3Host}/static/cashsite/${theme.image.BenefitsImagesOne}`}
                            alt="Step Images"
                            effect="none"
                            placeholder={<PlaceholderImage />}
                        />
                    </LeftSection>
                    <RightSection>
                        <Title>Why Participate?</Title>
                        <BenefitCard>
                            <BenefitTitle>Instant Cashback</BenefitTitle>
                            <BenefitDescription>
                                Get your cashback immediately without any waiting period.
                            </BenefitDescription>
                        </BenefitCard>
                        <BenefitCard>
                            <BenefitTitle>No Wagering Needed</BenefitTitle>
                            <BenefitDescription>
                                Use your cashback right away without any restrictions.
                            </BenefitDescription>
                        </BenefitCard>
                        <BenefitCard>
                            <BenefitTitle>Daily Rewards</BenefitTitle>
                            <BenefitDescription>
                                Claim your cashback every day for continuous rewards!
                            </BenefitDescription>
                        </BenefitCard>
                        <BenefitCard>
                            <BenefitTitle>Massive Prize Pool</BenefitTitle>
                            <BenefitDescription>
                                <Highlight>₹27,24,000</Highlight> <strong>is up for grabs!</strong> 
                            </BenefitDescription>
                        </BenefitCard>
                    </RightSection>
                </Container>

                <Container>
                    <RightSectionMore>
                        <BenefitCardBold>
                            <h2>Important Details</h2>
                            <p>Limited Daily Budget - The daily prize pool is capped at </p>
                            <p><span>₹36,256</span>, so hurry before it runs out!</p>
                            <p>Each player can claim the rebate once per day.</p>
                            <Link to ="/live-casino/evolution">Play Now!</Link>
                        </BenefitCardBold>
                    </RightSectionMore>
                    <LeftSectionMore>
                        <LazyLoadImage
                            src={`${config.imageV3Host}/static/cashsite/${theme.image.BenefitsImagesTwo}`}
                            alt="Step Images"
                            effect="none"
                            placeholder={<PlaceholderImage />}
                        />
                    </LeftSectionMore>
                </Container>
                <h3 className='hTitle'>🔥 Don’t miss out on guaranteed cashback! <br /> 
                Play now and claim your rewards! 🎉</h3>

                <AviatrixTermsAndCondition>
                    <h3>Terms & Conditions:</h3>
                    <ul>
                        <li>The Lightning CashBack is valid for all new and existing players.</li>
                        <li>Multiple accounts will not be eligible for this promotion. All players will undergo  duplicate checking.</li>
                        <li>Fun88 reserves the right to disqualify players involved in collusion or any foul play related to this promotion.</li>
                        <li>Fun88 reserves the right to amend, cancel, reclaim, or refuse any promotion at its discretion.</li>
                        <li>Fun88 General Terms & Conditions apply.</li>
                    </ul>
                </AviatrixTermsAndCondition>
            </InformationWrapper>
            <FooterContainer />
        </>
    )
}

export default withTheme(withAppContext(LightningCashback2k25PromoFun));


// Styled Component.
const BenefitCardBold = styled.div`
    display: flex;
    flex-direction: column;
    gap: 1rem;

    h2 {
        margin: 0;
    }

    p {
        color: #1E1E1E;
        font-size: 18px;
        font-style: normal;
        font-weight: 400;
        margin: 0;

        span {
            color: #2B8CFF;
            font-size: 48px;
            font-style: normal;
            font-weight: 400;
        }
    }

    a {
        display: flex;
        align-items: center;
        justify-content: center;
        height: 57px;
        max-width: 290px;
        border-radius: 8px;
        background: #2B8CFF;
        color: #fff;
    }
`;

const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  /* max-width: 1200px;
  margin: 0 auto; */
  color: #fff;
  padding: 20px;
  padding-top: 70px;
`;

const LeftSection = styled.div`
  display: flex;
  justify-content: center;

  img {
    width: 520px;
    max-width: 100%;
    height: 635px;
  }
`;

const LeftSectionMore = styled.div`
  display: flex;
  justify-content: center;

  img {
    width: 520px;
    max-width: 100%;
    height: 666px;
  }
`;

const RightSectionMore = styled.div`
    display: flex;
    align-items: flex-start;
    justify-content: center;
    flex-direction: column;
    gap: 20px;
    max-width: 520px;
    height: 666px;
    padding: 2rem;
    padding-left: 4rem;
    background: #fff;
`;

const RightSection = styled.div`
    display: flex;
    align-items: flex-start;
    justify-content: center;
    flex-direction: column;
    gap: 20px;
    max-width: 520px;
    height: 635px;
    padding: 2rem;
    padding-left: 4rem;
    background: #fff;
`;

const Title = styled.h2`
  font-size: 28px;
  font-weight: bold;
`;

const BenefitCard = styled.div`
    width: 100%;
    background: #F9C613;
    padding: 16px 24px;
    border-radius: 32px;
`;

const BenefitTitle = styled.h3`
  font-size: 18px;
  margin: 0;
  color: #2B8CFF;
`;

const BenefitDescription = styled.p`
  font-size: 14px;
  margin: 5px 0 0;
  color: #00003F;

  strong {
    color: #00003F !important;
    font-weight: 700 !important;
  }
`;

const Highlight = styled.span`
  font-weight: bold;
  color: #2B8CFF;
`;

const HowToWorksWrapper = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    padding: 2rem;
    background: #fff;
    color: #B7B7C7;

    p {
        color: #1F1F1F;
    }
    
    h2 {
        font-size: 56px;
        font-style: normal;
        font-weight: 700;
        margin: 0;
    }

    .promotionPeriodImg {
        width: 227px;
    }

    .eligibleGames {
        display: flex;
        align-items: center;
        justify-content: center;
        gap: 2rem;
        padding-top: 1rem;

        img {
            width: 360px;
        }
    }
`;

const PromoPeriodWrapper = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    padding-top: 1rem;
    padding-bottom: 2rem;

    .promotionPeriodImg {
        width: 227px;
    }

    .eligibleGames {
        display: flex;
        align-items: center;
        justify-content: center;
        gap: 1rem;

        img {
            width: 155px;
        }
    }
`;

const InformationWrapper = styled.div`
    width: 100%;
    height: auto;
    padding: 1rem 0rem 8rem 0rem;
    margin: 0em;
    border: 1px solid #fff;
    border-top-color: transparent;
    backdrop-filter: brightness(0.5);    
    display: flex;
    flex-direction: column;
    background: #EBEBEB;

    h2 {
        color: #2B8CFF;
        font-size: 36px;
        font-style: normal;
        font-weight: 700;
        text-transform: capitalize;
    }

    .hTitle {
        font-size: 36px;
        font-style: normal;
        font-weight: 700;
        color: #2B8CFF;
        text-align: center;
        text-transform: capitalize;
    }

    strong {
        color: #2B8CFF;
        font-weight: 400;
    }
`;

const TitleText = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    padding: 1rem 0 1rem 0;

    h1 {
        font-size: 36px;
        color: #2B8CFF;
        font-weight: 700;
        text-transform: capitalize;
    }

    p {
        color: ${theme('palette.LightningCashback2k25PromoTextColor')};
        text-align: center;
        font-size: 18.818px;
        font-style: normal;
        font-weight: 700;
        text-transform: capitalize;
        margin: 0;
    }
`;

const AviatrixTermsAndCondition = styled.div`
    width: 100%;
    max-width: 1200px;
    margin: 0 auto;
    height: auto;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    padding: 0;
    position: relative;


    & li {
        color: #444;
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: 22.8px; /* 142.5% */
        text-transform: capitalize;
        list-style-type: '🔹';
        margin: 1rem 0;
        padding-left: 5px;
    }

    & h3 {
        color: #2B8CFF;
        font-size: 36px;
        font-style: normal;
        font-weight: 700;
        line-height: 179.5%; /* 53.85px */
        text-transform: capitalize;
        margin: 10px 0px 0px 25px;
    }

    .bold {
        color: #FFF;
        font-weight: 700;
    }
`;
