import gql from 'graphql-tag';

const categoryProfitLoss = gql`
	fragment categoryProfitLoss on CategoryProfitLoss {
		name
		weekly
		monthly
	}
`;

const topMemberInfo = gql`
	fragment topMemberInfo on TopMemberInfo {
		name
		credit
		memberId
		type
	}
`;

const downlineDetail = gql`
	fragment downlineDetail on DownlineDetail {
		id
		brandId
		currencyId
		currencyRate
		username
		password
		status
		lockerName
		allowUnlock
		lastLoginIP
		lastLoginedAt
		creator
		createdAt
		updater
		updatedAt
		type
		remark
		disablerLevel
		limitCredit
		exposureCredit
		balanceUpCredit
		balanceDownCredit
		winlossCredit
		availableBalance
		distributedCredit
		downlineMaxCredit
		clientMaxCredit
		country
		orderSetting {
			categoryId
			minBet
			maxBet
			maxMarket
		}
		# Code Changed - Manoj ( removed ptList)
		# ptList {
		# 	categoryId
		# 	givePT
		# 	takePT
		# 	maxDistributedPT
		# }
		isAdjustPT
	}
`;

const accountStatement = gql`
	fragment accountStatement on AccountStatement {
		memberId
		sendMemberId
		receiveMemberId
		sendUsername
		receiveUsername
		transactionNumber
		referNumber
		categoryId
		transactionTypeId
		exchangeRate
		transactionCredit
	#	commissionCredit {/* changed by malyaban*/}
		availableCredit
		createdAt
		marketTypeId
		marketId
		eventId
		competitionId
		eventName
		fancyName
		isThirdParty
		displayRate
	}
`;

// Changed Made  - Manoj
// Removed info attribute & changed AgentPersonaInput to AgentPersonaTargetInput 
// export const GET_DASHBOARD_INFO_BALANCE: any = gql`
// 	query getDashboardInfoBalance($input: AgentPersonaInput!) {
// 		agentMember {
// 			info(input: $input) {
// 				balance {
// 					exposureCredit
// 					balanceDownCredit
// 					balanceUpCredit
// 					limitCredit
// 					availableCredit
// 					availableBalance
// 					distributedCredit
// 					agentDistributed
// 				}
// 			}
// 		}
// 	}
// `;
export const GET_DASHBOARD_INFO_BALANCE: any = gql`
	query getDashboardInfoBalance($input: AgentPersonaTargetInput!) {
		agentMember {
			balance(input: $input) {
					exposureCredit
					balanceDownCredit
					balanceUpCredit
					limitCredit
					availableCredit
					availableBalance
					distributedCredit
					agentDistributed
				}
		}
	}
`;
export const GET_DASHBOARD_INFO_TOP: any = gql`
	query getDashboardInfoTop($input: AgentPersonaInput!) {
		agentMember {
			info(input: $input) {
				top {
					winner {
						...topMemberInfo
					}
					loser {
						...topMemberInfo
					}
					exposure {
						...topMemberInfo
					}
				}
			}
		}
	}
	${topMemberInfo}
`;

export const GET_DASHBOARD_INFO_STATISTIC: any = gql`
	query getDashboardInfoStatistic($input: AgentPersonaInput!) {
		agentMember {
			info(input: $input) {
				statistic {
					football {
						...categoryProfitLoss
					}
					tennis {
						...categoryProfitLoss
					}
					cricket {
						...categoryProfitLoss
					}
					horseRacing {
						...categoryProfitLoss
					}
					greyhoundRacing {
						...categoryProfitLoss
					}
				}
			}
		}
	}
	${categoryProfitLoss}
`;

export const GET_ACCOUNT_BALANCE = gql`
	query getAccountBalance($input: AgentPersonaTargetInput!) {
		agentMember {
			balance(input: $input) {
				exposureCredit
				balanceDownCredit
				balanceUpCredit
				availableCredit
				availableBalance
				hideBalance
			}
		}
	}
`;

export const ADD_ASSOCIATED_ACCOUNT = gql`
	mutation addAssociatedAccount($input: AddAssociatedAccountInput!) {
		agentMember {
			addAssociatedAccount(input: $input) {
				success
				code
				message
				data {
					username
					memberId
				}
			}
		}
	}
`;

export const ADD_SUB_ACCOUNT = gql`
	mutation addSubAccount($input: CreateSubAccountInput!) {
		agentMember {
			createSubAccount(input: $input) {
				success
				code
				message
				data {
					memberId
				}
			}
		}
	}
`;

export const LIST_SUB_ACCOUNT = gql`
	query listSubAccount($input: AgentPersonaInput!) {
		agentMember {
			listSubAccount(input: $input) {
				memberId
				username
				status
			}
		}
	}
`;

export const UPDATE_SUB_ACCOUNT = gql`
	mutation updateSubAccount($input: UpdateSubAccountInput!) {
		agentMember {
			updateSubAccount(input: $input) {
				success
				code
				message
				data
			}
		}
	}
`;

export const GET_WATCH_LIST = gql`
	query getWatchList($input: WatchListInput!) {
		agentExchange {
			watchlist(input: $input) {
				mainExposure {
					eventId
					marketId
					marketTypeId
					categoryId
					eventName
					totalOutcome
					competitionId
					selectionExposure
					isSnkMain
				}
				fancyExposure {
					eventId
					eventName
					competitionId
					categoryId
					marketId
					marketTypeId
					fancyId
					fancyName
					stake
					isBFFancy
					maxWin
					maxLoss
				}
			}
		}
	}
`;

// export const PT_LIST = gql`
// 	query ptList($input: AgentPersonaTargetInput!) {
// 		agentMember {
// 			listPt(input: $input) {
// 				memberId
// 				username
// 				type
// 				cricketPT
// 				cricketPTTake
// 				soccerPT
// 				soccerPTTake
// 				tennisPT
// 				tennisPTTake
// 				horsePT
// 				horsePTTake
// 				greyhoundPT
// 				greyhoundPTTake
// 				specialFancyPT
// 				specialFancyPTTake
// 				downline {
// 					memberId
// 					username
// 					type
// 					cricketPT
// 					cricketPTTake
// 					soccerPT
// 					soccerPTTake
// 					tennisPT
// 					tennisPTTake
// 					horsePT
// 					horsePTTake
// 					greyhoundPT
// 					greyhoundPTTake
// 					specialFancyPT
// 					specialFancyPTTake
// 				}
// 			}
// 		}
// 	}
// `;
export const PT_LIST = gql`
	query ptList($input: PtListInput!) {
		agentMember {
			listPt(input: $input) {
				username
				memberId
				type
				exchangePT {
					categoryId
					providerId
					takePT
					givePT
					provider
					categoryName
					maxDistributed
					positionTakingType
				}
				thirdPartyPT {
					categoryId
					providerId
					takePT
					givePT
					provider
					categoryName
					maxDistributed
					positionTakingType
				}
				downline {
					username
					memberId
					type
					exchangePT {
						categoryId
						providerId
						takePT
						givePT
						provider
						categoryName
						maxDistributed
						positionTakingType
					}
					thirdPartyPT {
						categoryId
						providerId
						takePT
						givePT
						provider
						categoryName
						maxDistributed
						positionTakingType
					}
				}
				# memberId
				# username
				# type
				# cricketPT
				# cricketPTTake
				# soccerPT
				# soccerPTTake
				# tennisPT
				# tennisPTTake
				# horsePT
				# horsePTTake
				# greyhoundPT
				# greyhoundPTTake
				# specialFancyPT
				# specialFancyPTTake
				# downline {
				# 	memberId
				# 	username
				# 	type
				# 	cricketPT
				# 	cricketPTTake
				# 	soccerPT
				# 	soccerPTTake
				# 	tennisPT
				# 	tennisPTTake
				# 	horsePT
				# 	horsePTTake
				# 	greyhoundPT
				# 	greyhoundPTTake
				# 	specialFancyPT
				# 	specialFancyPTTake
				# }
			}
		}
	}
`;
export const AI_PT_LIST = gql`
	query AIPtList($input: AIPTInput!) {
		agentMember {
			listAIPt(input: $input) {
				pages {
					totalPage
					totalData
				}
				ptList {
					cricketPT
					soccerPT
					tennisPT
					downline {
						memberId
						username
						type
						PT {
							categoryId
							current
							recommend
							max
							isAcceptAI
						}
					}
				}
			}
		}
	}
`;

export const AI_PT_UPDATE = gql`
	mutation updateAIPT($input: UpdateAIPTInput!) {
		agentMember {
			updateAIPt(input: $input) {
				success
				message
			}
		}
	}
`;

export const AI_PT_REMOVE = gql`
	mutation removeAIPT($input: AgentTargetInput!) {
		agentMember {
			removeAIPt(input: $input) {
				success
				message
			}
		}
	}
`;

export const ALL_DOWNLINE_LIST = gql`
	query allDownlineList($input: AgentTargetInput!) {
		agentMember {
			listAllDownlineAccount(input: $input) {
				memberId
				username
				type
			}
		}
	}
`;

export const CURRENCY_LIST = gql`
	query currencyList {
		agentMember {
			currencyList {
				id
				currencyCode
				currencyName
				description
				exchangeRate
				minPerOrderCredit
			}
		}
	}
`;

// Code Change - Manoj ( DownlineListInput changed to DownlineInput & downlineList to downline)
export const DOWNLINE = gql`
	query downline($input: DownlineInput!) {
		agentMember {
			downline(input: $input) {
				...downlineDetail
			}
		}
	}
	${downlineDetail}
`;

// Changed Made - Manoj
// added data variable extra
export const DOWNLINE_LIST = gql`
	query downlineList($input: DownlineListInput!) {
		agentMember {
			downlineList(input: $input) {
				data {
					...downlineDetail
				}
			}
		}
	}
	${downlineDetail}
`;

export const ALLDOWNLINE_LIST = gql`
	query allDownlineList($input: AllDownlineListInput!) {
		agentMember {
			allDownlineList(input: $input) {
				dataJson
			}
		}
	}
`;

export const MEMBER_UPPERLINE = gql`
	query memberUpperline($input: AgentPersonaTargetInput!) {
		agentMember {
			getAccountUpperLineTree(input: $input) {
				memberId
				username
				type
			}
		}
	}
`;

export const ALLUPPERLINE_LIST = gql`
	query allUpperlineList($input: AllUpperlineListInput!) {
		agentMember {
			allUpperlineList(input: $input) {
				id
				username
				type
			}
		}
	}
`;

export const CREATE_DOWNLINE = gql`
	mutation createDownline($input: CreateAccountInput!) {
		agentMember {
			createAccount(input: $input) {
				success
				code
				message
				data {
					...downlineDetail
				}
			}
		}
	}
	${downlineDetail}
`;

export const GET_ACTIVE_USERS = gql`
	query getActiveUsers($input: AgentPersonaInput!) {
		agentMember {
			listActiveAccount(input: $input) {
				username
				memberId
				type
				ip
				lastLoginAt
				country
			}
		}
	}
`;

export const GET_PAST_IP_ADDRESS = gql`
	query getPastIPAddress($input: AgentPersonaTargetInput!) {
		agentMember {
			listPastIpAddress(input: $input) {
				ip
				lastLoginedAt
				country
			}
		}
	}
`;

export const UPDATE_DOWNLINE = gql`
	mutation updateDownline($input: UpdateAccountInput!) {
		agentMember {
			updateAccount(input: $input) {
				success
				code
				message
				data
			}
		}
	}
`;

export const ACCOUNT_STATEMENT = gql`
	query getAccountStatement($input: AccountStatementInput!) {
		agentExchange {
			accountStatement(input: $input) {
				optionsJson
				casinoOptionJson
				groupedDataJson
			}
		}
	}
`;

export const SETTLING_STATEMENT = gql`
	query getSettlingStatement($input: SettlingStatementInput!) {
		agentExchange {
			settlingStatement(input: $input) {
				date
				data {
					...accountStatement
				}
			}
		}
	}
	${accountStatement}
`;

export const EVENT_DETAIL = gql`
	query getEventDetail($input: EventDetailInput!) {
		agentExchange {
			eventDetail(input: $input) {
				memberId
				username
				orderNumber
				transactionNumber
				settleNumber
				source
				competitionId
				eventId
				eventName
				marketId
				marketName
				marketTypeId
				marketTypeName
				selectionId
				selectionName
				backValue
				layValue
				categoryId
				inplay
				side
				orderPrice
				price
				orderCredit
				matchedCredit
				unmatchedCredit
				createdAt
				settledAt
				companyAdminPt
				superAdminPt
				adminPt
				superPt
				masterPt
				winlossCredit
				isOneClickOrder
				status
				cancelledAt
				checkoutAt
				companyAdminWinlossCredit
				superAdminWinlossCredit
				adminWinlossCredit
				superWinlossCredit
				masterWinlossCredit
				commissionCredit
				displayRate
			}
		}
	}
`;

export const RECALCULATE = gql`
	mutation recalculate($input: AgentPersonaTargetInput!) {
		agentMember {
			recalculate(input: $input) {
				success
				code
				message
			}
		}
	}
`;

export const ASSOCIATIONS = gql`
	query getAssociations {
		agentMember {
			associations {
				username
				type
				isEnable
				memberId
				currencyId
				exchangeRate
			}
		}
	}
`;

export const SETTLE_DOWNLINE_LIST = gql`
	query settleDownlineList($downlineInput: DownlineListInput!, $creditInput: AgentPersonaTargetInput!) {
		agentMember {
			downlineList(input: $downlineInput) {
				#code Changed - Manoj ( added data attribute since query is changed)
				data {
				...downlineDetail
				}
				dataJson
			}
			balance(input: $creditInput) {
				availableCredit
			}
		}
	}
	${downlineDetail}
`;

export const GET_EVENT_NEWS = gql`
	query eventNews($input: AgentMarketEventNewsInput!) {
		agentMarketMgmt {
			eventNews(input: $input) {
				id
				eventId
				text
				isEnable
				isInplay
				updater
				updatedAt
			}
		}
	}
`;

export const UPDATE_EVENT_NEWS = gql`
	mutation updateEventNews($input: AgentMarketUpdateEventNewsInput!) {
		agentMarketMgmt {
			updateEventNews(input: $input) {
				success
				code
				message
				data {
					id
				}
			}
		}
	}
`;

export const GET_EVENT_NEWS_CLIENT = gql`
	query eventNews($input: MarketEventNewsInput!) {
		markets {
			eventNews(input: $input) {
				id
				eventId
				text
			}
		}
	}
`;
