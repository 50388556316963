import React from 'react';
import { SubscribeToMoreOptions } from '@apollo/client';
import isEqual from 'lodash/isEqual';
import util from '../../util/util';

interface IProps {
	variables: any;
	subscribeToMore: (options: SubscribeToMoreOptions<any, any>) => () => void;
	query: any; //change string to any //Manish
	originDataStructure: string[];
	subDataStructure: string[];
	formatMarket: (previousData: any, subscribeData: any) => {};
	loading: boolean;
}
interface IState {
	subscriptionParam: any;
	unsubscribe: () => void;
}

export default class SubscribedMarketList extends React.Component<IProps, IState> {
	state = {
		subscriptionParam: {},
		unsubscribe: () => {},
	};

	static getDerivedStateFromProps(nextProps: IProps, prevState: IState) {
		if (!nextProps.loading) {
			const {
				variables,
				query,
				originDataStructure,
				subDataStructure,
				formatMarket,
				subscribeToMore,
			} = nextProps;
			if (prevState.unsubscribe) {
				if (isEqual(prevState.subscriptionParam, variables)) {
					return null;
				}
				prevState.unsubscribe();
			}

			return {
				unsubscribe: subscribeToMore({
					document: query,
					variables,
					updateQuery: (previous: any, { subscriptionData }: { subscriptionData: any }) => {
						const previousList = util.getValue(previous, originDataStructure);
						if ([undefined, null].indexOf(previousList) > -1) {
							return previous;
						}

						const subList = util.getValue(subscriptionData, subDataStructure);

						if (!subList) {
							return previous;
						}

						return formatMarket(previous, subscriptionData);
					},
				}),
				subscriptionParam: variables,
			};
		}

		return null;
	}
	componentWillUnmount() {
		this.state.unsubscribe && this.state.unsubscribe();
	}
	render() {
		return this.props.children;
	}
}
