import React, { useEffect } from 'react'
import { withAppContext } from 'src/store/initAppContext';
import styled, { withTheme } from 'styled-components';
import { LazyLoadImage } from "react-lazy-load-image-component";
import config from "src/config/config";
import PlaceholderImage from 'src/components/Placeholder';
import { theme } from 'styled-tools';
import FooterContainer from 'src/components/footer/footer';
import Slider from "react-slick";
import { useNavigate } from 'react-router-dom';

interface Game {
    id: number;
    image: string;
    url: string;
    gameName: string;
};

const GAMES_LIST: Game[] = [
    { id: 1, image: "brand/6/image/JJLPGameList02.png", url: "/slots/jili-slot/super-ace", gameName: "Super Ace" },
    { id: 2, image: "brand/6/image/JJLPGameList03.png", url: "/slots/jili-slot/money-coming", gameName: "Money Coming" },
    { id: 3, image: "brand/6/image/JJLPGameList04.png", url: "/slots/jili-slot/boxing-king", gameName: "Boxing King" },
    { id: 4, image: "brand/6/image/JJLPGameList05.png", url: "/slots/jili-slot/fortune-gems", gameName: "Fortune Gems" },
    { id: 5, image: "brand/6/image/JJLPGameList06.png", url: "/slots/jili-slot/fortune-gems 2", gameName: "Fortune Gems 2" },
    { id: 6, image: "brand/6/image/JJLPGameList07.png", url: "/slots/jili-slot/fortune-gems 3", gameName: "Fortune Gems 3" },
    { id: 7, image: "brand/6/image/JJLPGameList08.png", url: "/slots/jili-slot/crazy777", gameName: "Crazy777", },
    { id: 8, image: "brand/6/image/JJLPGameList09.png", url: "/slots/jili-slot/super ace deluxe", gameName: "Super Ace Deluxe" },
    { id: 9, image: "brand/6/image/JJLPGameList10.png", url: "/slots/jili-slot/money coming expand bets", gameName: "Money Coming Expand Bets" },
    { id: 10, image: "brand/6/image/JJLPGameList11.png", url: "/slots/jili-slot/golden-empire", gameName: "Golden Empire" },
];

const SLIDER_SETTINGS = {
    loop: true,
    arrows: true,
    autoplay: true,
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 4,
    slidesToScroll: 4,
    initialSlide: 0,
    centerPadding: '20px'
};

function JiliJackpotLeaguePromoFun88(props: any) {
    const { app: {
        brandId
    }, theme } = props;

    const navigate = useNavigate();

    useEffect(() => {
        window.scrollTo(0, 0)
    }, []);

    const CheckMarkIcon = () => {
        return (
            <svg xmlns="http://www.w3.org/2000/svg" width="27" height="27" viewBox="0 0 27 27" fill="none">
                <rect width="26.8527" height="26.8527" rx="4" fill="url(#paint0_linear_2028_1206)" />
                <path d="M22.0576 8.12485L9.56289 20.1395L3.83614 14.6328L5.30427 13.2211L9.56289 17.306L20.5895 6.71313L22.0576 8.12485Z" fill="white" />
                <defs>
                    <linearGradient id="paint0_linear_2028_1206" x1="13.4264" y1="0" x2="13.4264" y2="26.8527" gradientUnits="userSpaceOnUse">
                        <stop stop-color="#22D838" />
                        <stop offset="1" stop-color="#17AA0A" />
                    </linearGradient>
                </defs>
            </svg>
        )
    };

    const handleGameRedirect = (e: React.MouseEvent, url: string) => {
        e.preventDefault();
        url && navigate(url);
    };

    const RenderGameSlider = () => (
        <Slider {...SLIDER_SETTINGS}>
            {GAMES_LIST.map((game) => (
                <GameSlider key={game.id}>
                    <EachGame>
                        <LazyLoadImage
                            src={`${config.imageV3Host}/static/cashsite/${game.image}`}
                            alt={game.gameName}
                            effect="none"
                            placeholder={<PlaceholderImage />}
                            onClick={(e) => handleGameRedirect(e, game.url)}
                            role="button"
                            tabIndex={0}
                        />
                    </EachGame>
                    <p>{game.gameName}</p>
                </GameSlider>
            ))}
        </Slider>
    );

    return (
        <>
            <InformationWrapper>
                <HeaderSection>
                    <h1>Get ready for an exciting adventure with JILI!</h1>
                    <p>Log in and play any featured games to grab 20 Free Spins — <strong>no extra steps are required!</strong> <br /> <strong>Spin, win, and unlock</strong> massive rewards while enjoying thrilling gameplay.</p>
                    <ImgTextWrapper>
                        <LazyLoadImage
                            src={`${config.imageV3Host}/static/cashsite/${theme.image.JiliSpinWinLeagueIconOne}`}
                            alt="Jili jackpot league"
                            effect="none"
                            className="calImg"
                            placeholder={<PlaceholderImage />}
                        />
                        <p><strong>Event Period</strong> : March 15, 2025 – April 15, 2025</p>
                    </ImgTextWrapper>
                </HeaderSection>
                <GameSection>
                    <SliderContainer>
                        <ImgTextWrapper>
                            <LazyLoadImage
                                src={`${config.imageV3Host}/static/cashsite/${theme.image.JiliSpinWinLeagueIconTwo}`}
                                alt="Jili jackpot league"
                                effect="none"
                                className="slotImg"
                                placeholder={<PlaceholderImage />}
                            />
                            <h3>Eligible games</h3>
                        </ImgTextWrapper>
                        <SliderWrapper>
                            <RenderGameSlider />
                        </SliderWrapper>
                    </SliderContainer>
                    <ROLeftWrapper>
                        <LazyLoadImage
                            src={`${config.imageV3Host}/static/cashsite/${theme.image.JiliSpinWinLeagueGirl}`}
                            alt="Jili jackpot league"
                            effect="none"
                            placeholder={<PlaceholderImage />}
                        />
                    </ROLeftWrapper>
                </GameSection>
                <InfroWrapper>
                    <CheckmarkSection>
                        <h3>How It Works?</h3>
                        <CheckmarkItem>
                            <CheckMarkIcon />
                            <p>Log in and play any of the games mentioned above.</p>
                        </CheckmarkItem>
                        <CheckmarkItem>
                            <CheckMarkIcon />
                            <p>Instantly receive 20 Free Spins—winnings go into your Bonus Pool.</p>
                        </CheckmarkItem>
                        <CheckmarkItem>
                            <CheckMarkIcon />
                            <p>Once all spins are used, the wagering phase begins.</p>
                        </CheckmarkItem>
                        <CheckmarkItem>
                            <CheckMarkIcon />
                            <p>Meet the 5x wagering requirement to unlock and claim your accumulated rewards!</p>
                        </CheckmarkItem>
                    </CheckmarkSection>
                    <CheckmarkSection>
                        <h3>Why You Should Join?</h3>
                        <CheckmarkItem>
                            <CheckMarkIcon />
                            <p>Instant Free Spins – Log in and start spinning!</p>
                        </CheckmarkItem>
                        <CheckmarkItem>
                            <CheckMarkIcon />
                            <p>Risk-Free Gameplay – Play top JILI slots without spending a dime.</p>
                        </CheckmarkItem>
                        <CheckmarkItem>
                            <CheckMarkIcon />
                            <p>Bonus Pool System – Winnings from Free Spins go into the Bonus Pool, ready to be unlocked.</p>
                        </CheckmarkItem>
                        <CheckmarkItem>
                            <CheckMarkIcon />
                            <p>Wager & Win – Keep playing to meet the wagering requirement and claim your full rewards.</p>
                        </CheckmarkItem>
                        <CheckmarkItem>
                            <CheckMarkIcon />
                            <p>Limited-Time Offer – Don’t miss out on this exclusive promotion!</p>
                        </CheckmarkItem>
                    </CheckmarkSection>
                </InfroWrapper>
                <RulesSection>
                    <RulesGraphImg>
                        <LazyLoadImage
                            src={`${config.imageV3Host}/static/cashsite/${theme.image.JiliSpinWinLeagueGraph}`}
                            alt="Jili jackpot league"
                            effect="none"
                            placeholder={<PlaceholderImage />}
                        />
                    </RulesGraphImg>
                    <RulesContent>
                        <h2>Important Rules!</h2>
                        <ul>
                            <li>Spin Bonus winnings are collected in the Bonus  Pool, not credited directly to your balance.</li>
                            <li>Once Free Spins are used, players must enter the wagering phase and continue betting to progress.</li>
                            <li>Complete the 5x wagering requirement to claim all winnings from the Bonus Pool.</li>
                            <li>The Spin Bonus has an expiration date—use it before it expires!</li>
                            <li>Rewards are limited—no further bonuses will be credited once the quota is reached.</li>
                            <li>Each player can claim this offer only once— duplicate claims are not allowed.</li>
                        </ul>
                    </RulesContent>
                </RulesSection>
                <TermsAndCondition>
                    <h3>Terms & Conditions</h3>
                    <ul>
                        <li>The JILI: Spin & Wheel promotion is valid for all new and existing players.</li>
                        <li>The bigger the game turnover, the greater the chances of winning.</li>
                        <li>Multiple accounts will not be eligible for this promotion. All players will undergo duplicate checking.</li>
                        <li>FUN88 reserves the right to disqualify players involved in collusion or any foul play related to this promotion.</li>
                        <li>FUN88 & JILI reserve the right to amend, cancel, reclaim, or refuse any promotion at its discretion.</li>
                        <li>FUN88 general Terms & Conditions apply.</li>
                    </ul>
                </TermsAndCondition>
            </InformationWrapper>
            <FooterContainer />
        </>
    )
}

export default withTheme(withAppContext(JiliJackpotLeaguePromoFun88));


// Styled Component.
const InformationWrapper = styled.div`
    width: 100%;
    height: auto;
    padding: 3rem;
    background: rgba(111, 111, 111, 0.5);
    margin: 0em;
    border-top-color: transparent;
    backdrop-filter: brightness(0.5);    
    display: flex;
    flex-direction: column;
    background-color: #EBEBEB;
`;

const HeaderSection = styled.div`
    width: 90%;
    height: auto;
    margin: auto;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    padding: 2rem 0;
    gap: 1rem;
    border-radius: 9.945px;
    background: #FFF;
    margin-bottom: 1.5rem;

    h1 {
        color: #06A6FF;
        text-align: center;
        font-size: 25px;
        font-style: normal;
        font-weight: 700;
        line-height: normal;
        text-transform: capitalize;
        margin: 0;
    }

    p {
        color: #444;
        text-align: center;
        font-size: 14px;
        font-style: normal;
        font-weight: 500;
        line-height: 200.3%;
        margin: 0;
    }
`;

const GameSection = styled.div`
    width: 90%;
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
    text-align: center;
    height: auto;
    margin: auto;
`;

const ROLeftWrapper = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    width: auto;

    img {
        width: 26.25rem;
        text-align: center;
    }
`;

const SliderContainer = styled.div`
    width: 70%;
    display: flex;
    flex-direction: column;
    gap: 1.5rem;
    border-radius: 9.233px;
    background: #FFF;
    padding: 2rem 0 1rem 0;
    align-self: center;
    margin-bottom: 1rem;
`;

const SliderWrapper = styled.div`
    width: 95%;
    margin: 0 auto;

    .slick-slide {
        padding: 10px;
    }
    
    .slick-slide img {
        display: block;
        width: 100%;
        transition: transform .5s; /* Animation */
    }

    .slick-prev {
        left: 3rem;
        top: -2.4rem;
        cursor: pointer;
    }

    .slick-next {
        right: 3rem;
        top: -2.4rem;
        cursor: pointer;
    }

    .slick-prev:before {
        content: "";
        width: 53px;
        height: 59px;
        display: inline-block;
        background: url(${config.imageV3Host}/static/cashsite/${theme("image.JiliSpinWinLeagueIconLeft")});
        background-size: cover;
        background-position:center center;
        background-repeat: no-repeat;
        border-radius: 9.945px;
        opacity: unset;
        position: absolute;
        top: -2rem;
        left: -1.5rem;
    }

    .slick-next:before {
        content: "";
        width: 53px;
        height: 59px;
        display: inline-block;
        background: url(${config.imageV3Host}/static/cashsite/${theme("image.JiliSpinWinLeagueIconRight")});
        background-size: cover;
        background-position:center center;
        background-repeat: no-repeat;
        border-radius: 9.945px;
        opacity: unset;
        position: absolute;
        top: -2rem;
        right: -1.5rem;
    }

    .slick-dots {
        bottom: -40px;

        li button:before {
            color: transparent;
            font-size: 15px;
            opacity: unset;
        }

        li.slick-active button:before {
            opacity: unset;
            color: transparent;
        }
    }
`;

const GameSlider = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;

    p {
        text-align: center;
        font-size: 19.074px;
        font-style: normal;
        font-weight: bolder;
        line-height: 150%; /* 28.611px */
        text-transform: capitalize;
        background: linear-gradient(357deg, #5FE7FF -1.31%, #4353FF 86.52%);
        background-clip: text;
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
        margin: 0;
    }
`;

const EachGame = styled.div`
    display: flex;
    position: relative;
    border: 4px solid #3493FF;
    border-radius: 20px;
    overflow: hidden;

    img {
        width: 100%;
        cursor: pointer;
    }

    ::after {
        content: "";
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background: linear-gradient(360deg, #029CFB 0%, rgba(2, 156, 251, 0.00) 35%);
        pointer-events: none;
    }
`;

const InfroWrapper = styled.div`
    width: 90%;
    margin: auto;
    display: flex;
    justify-content: center;
    align-items: stretch;
    margin-bottom: 1.5rem;
    gap: 1.5rem;
`;

const CheckmarkSection = styled.div`
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    flex-direction: column;
    width: 50%;
    border-radius: 9.233px;
    background: #FFF;
    gap: 1.5rem;
    padding: 2.5rem 2rem;

    h3 {
        color: #06A6FF;
        font-size: 25px;
        font-style: normal;
        font-weight: 700;
        line-height: normal;
        margin-top: 0;
    }
`;

const CheckmarkItem = styled.div`
    display: flex;
    justify-content: center;
    align-items: flex-start;
    gap: 1rem;

    svg {
        flex-shrink: 0;
    }

    p {
        color: #444;
        font-size: 17.599px;
        font-style: normal;
        font-weight: 700;
        line-height: 150%; /* 26.398px */
        text-transform: capitalize;
        margin: 0;
    }
`;

const RulesSection = styled.div`
    width: 90%;
    margin: auto;
    display: flex;
    justify-content: flex-end;
    align-items: stretch;
    margin-bottom: 1.5rem;
    gap: 1.5rem;
`;

const RulesContent = styled.div`
    width: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    border-radius: 9.233px;
    background: #FFF;
    gap: 1rem;
    padding: 1.25rem 0 1rem 0;

    h2 {
        width: 100%;
        background: linear-gradient(180deg, #FFCA37 0%, #F09400 100%);
        color: #FFF;
        font-size: 25px;
        font-style: normal;
        text-align: center;
        font-weight: 900;
        line-height: normal;
        text-transform: capitalize;
        margin: 0.5rem 0 0 0;
        padding: 1rem;
    }

    ul {
        padding-right: 2rem;
    }

    ul li {
        color: #444;
        font-size: 17.599px;
        font-style: normal;
        font-weight: 700;
        line-height: 150%;
        text-transform: capitalize;
        padding-bottom: 1.5rem;
        list-style: none;
        position: relative;
        padding-left: 20px;
        color: #444;
    }

    ul li::before {
        content: "";
        position: absolute;
        left: 0;
        top: 9px;
        width: 10px;
        height: 10px;
        background: linear-gradient(180deg, #FFCA37 0%, #F09400 100%);
        border-radius: 50%;
    }
`;

const RulesGraphImg = styled(RulesContent)`
    background: none;

    img {
        width: 35rem;
        height: auto;
    }
`;

const TermsAndCondition = styled.div`
    width: 90%;
    height: auto;
    margin: auto;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    padding: 2rem 4rem;
    position: relative;
    border-radius: 9.233px;
    background: #FFF;

    ul li {
        color: #444;
        font-size: 17.599px;
        font-style: normal;
        font-weight: 700;
        line-height: 150%;
        text-transform: capitalize;
        padding-bottom: 2rem;
        list-style: none;
        position: relative;
        padding-left: 20px;
    }

    ul li::before {
        content: "";
        position: absolute;
        left: 0;
        top: 7px;
        width: 12px;
        height: 12px;
        background: linear-gradient(180deg, #FFCA37 0%, #F09400 100%);
        border-radius: 50%;
    }

    ul li:last-child {
        padding-bottom: 0;
    }

    & h3 {
        width: 100%;
        color: #06A6FF;
        text-align: center;
        font-size: 25px;
        font-style: normal;
        font-weight: 900;
        line-height: normal;
        margin-top: 0;
    }
`;


const ImgTextWrapper = styled.div`
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 5px;

    p {
        color: #444;
        text-align: center;
        font-size: 14px;
        font-style: normal;
        font-weight: 500;
        line-height: 200.3%;
        text-transform: capitalize;
        margin: 0;
    }

    h3 {
        color: #06A6FF;
        text-align: center;
        font-size: 25px;
        font-style: normal;
        font-weight: 900;
        line-height: normal;
        text-transform: capitalize;
        margin: 0;
    }

    .calImg {
        width: 28.246px;
        height: 30.172px;
    }

    .slotImg {
        width: 42px;
        height: 47px;
    }
`;