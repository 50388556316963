import React, { useState, useEffect } from 'react'
import styled, { withTheme } from 'styled-components';
import { SvgIcon } from '../commonStyle';
import UploadIcon from '../../icon/UploadIcon';
import ApprovedIcon from '../../icon/ApprovedIcon';
import { XCircleIcon } from '../../icon/DeleteIcon';
import Intl from "../../common/Intl";
import { Player } from '@lottiefiles/react-lottie-player';
import config from 'src/config/config';
import { theme } from 'styled-tools';
import { withAppContext } from 'src/store/initAppContext';
import { Outlet, useLocation } from "react-router-dom";

type FileUploadProps = {
    name: string;
    label: string;
    isReq: boolean;
    accept?: string;
    capture?: 'environment' | 'user';
    onUpload: (file: any) => void;
    maxSizeMB: number;
    isReset?: boolean;
    value?: Blob | null
    theme?: any;
    isDeposit: boolean;
    app?: any;

}

type FilePreviewProps = {
    bg: Blob;
    showDelete: boolean;
    onDelete?: () => void;
    theme: any;
}

type FileViewProps = {
    file: any
}

const validFileType = (file) => {
    const fileTypes = [
        "image/apng",
        "image/bmp",
        // "image/gif",
        "image/jpeg",
        "image/pjpeg",
        "image/png",
        "image/svg+xml",
        "image/tiff",
        "image/webp",
        "image/x-icon"
    ];
    return fileTypes.includes(file.type)
}

export const FileView = ({ file }) => {
    return (<UploadSucessfully>
        <img style={{ maxWidth: '325px', maxHeight: '116px' }} src={`data:image/;base64,${file}`} alt="" />

        {/* <ApprovedIcon />
        Uploaded Sucessfully */}
    </UploadSucessfully>)
}

export const FilePreview = ({ bg, showDelete, onDelete, theme }: FilePreviewProps) => {
    const clickHandler = () => {
        if (onDelete && typeof onDelete == 'function') {
            onDelete();
        }
    }
    const url = URL.createObjectURL(bg);
    const {config:{showBlurBg}} = theme;

    return (
        <UploadSucessfullyWrapper>
            <UploadSucessfully className='cfstyle' bg={url}>
                {showBlurBg && <BlurBg></BlurBg>}
                {showDelete && <XCircleWrapper onClick={clickHandler}><XCircleIcon /></XCircleWrapper>}
                <ApprovedIcon />
                {/* Attached Successfully */}
                <span><Intl langKey="DASHBOARD@uploadFileSuccessMsg" /></span>
            </UploadSucessfully>
        </UploadSucessfullyWrapper>    
    )
}

export const FileUploadInnerDOM = ({ label, isReq, brandId }) => {
    return (
        <>
            <Span>{label}{isReq && <i className='req'>*</i>}</Span>
            <SvgIcon className='newUploadIcon'>
                {/* <UploadIcon /> */}
                <>
                   { brandId === 46 || brandId === 47 ? 
                   <img className='fileUploadImg' src={`${config.imageV3NonOptHost}/static/cashsite/brand/6/image/depositUploadIconFun88.svg`} alt="UploadImage" /> :
                    <Player
                      autoplay
                      loop
                      controls
                      src={`${config.imageV3NonOptHost}/static/cashsite/brand/6/json/depositUploadIcon.json`}
                      style={{ height: 'auto', width: '50px' }}
                    ></Player>
                    }
                </>
            </SvgIcon>
            <DropYourFile>
                Drop your File to UPLOAD Or <Browse>Browse</Browse>
            </DropYourFile>
        </>

    )
}

function FileUpload(props: FileUploadProps) {
    const { name, label, isReq, accept = 'image/*', capture = 'user', maxSizeMB, onUpload, isReset = false, value = null, isDeposit= true } = props;
    const [fileUploadState, setFileUploadState] = useState<{ error: boolean, message: string }>({
        error: false,
        message: ''
    });
    const { theme, app: {brandId} } = props;
    const {config:{showInfinityAnimation}} = theme;

    const [files, setFiles] = useState(null);

    useEffect(() => {
        if (value) {
            setFiles(value)
        }
    }, [])

    useEffect(() => {
        if (isReset) {
            setFiles(null)
            setFileUploadState({
                error: false,
                message: ''
            })
        }
    }, [isReset])

    const handleFileUpload = (e) => {
        const files = e.target.files;
        if (files.length) {
            const firstFile = files[0];
            const allowedSize = Number(maxSizeMB) * (1024 * 1024);
            const currFSize = firstFile.size;

            if (!validFileType(firstFile)) {
                setFileUploadState({
                    error: true,
                    message: `File name ${firstFile.name}: Not a valid file type.`
                });
                return;
            }
            else if (currFSize > allowedSize) {
                setFileUploadState({
                    error: true,
                    message: `Image Exceeds ${maxSizeMB} mb`
                });
                return;
            }
            else {
                setFiles(firstFile);
                setFileUploadState({
                    error: false,
                    message: ''
                });
                onUpload(firstFile);
            }
        }
    }

    const deleteHandler = () => {
        setFiles(null);
        onUpload(null);
    }

    const { config: { showFastBankCountCheckBox } } = theme;

    const { pathname } = useLocation();
    let currPage = pathname.split("/").pop();

    return (
        <UploadBox>
         {!(files && files.name) && showInfinityAnimation && !isDeposit && 
            <>
                { showFastBankCountCheckBox && <Player
                    autoplay
                    loop
                    controls
                    src={`${config.imageV3NonOptHost}/static/cashsite/${theme.image.depositjsonInsideUploader}`}
                    style={{ height: '170px', width: '100%', mixBlendMode: "multiply" }}
                    ></Player>
                }
            </>
            }
            {!(files && files.name) ? (<InputLabel>
                <FileUploadInnerDOM label={label} isReq={isReq}  brandId={brandId}/>
                <FileInput type="file" value="" name={name} accept={accept} capture={capture} onChange={handleFileUpload} />
            </InputLabel>) : <FilePreview bg={files} showDelete={true} onDelete={deleteHandler} theme={theme} />
            }
            {fileUploadState.error && <FileUploadErrorMsg className = {(currPage === "deposit") ? "depoUploadErrorMsg" : ""}>{fileUploadState.message}</FileUploadErrorMsg>}
        </UploadBox>
    )
}

export default withTheme(withAppContext(FileUpload));

const BlurBg = styled.div`
    width: 100%;
    height: 100%;
    background-color: #000000b0;
    position: absolute;
    top: 0;
`;

const UploadBox = styled.div`
  display: flex;
  flex:1;
  border-radius: 12px;
  font-weight: 500;
  font-size: 12px;
  flex-direction: column;
  align-items: center;
  position: relative;
  justify-content: center;

  /* :hover {
    background: rgb(38 35 69);
  } */
  svg {
    /* width: 20px; */
    width: ${theme('palette.UDBFileUploadApprovedIconSize')};
    height: ${theme('palette.UDBFileUploadApprovedIconSize')};;
    margin-top: 1em;
    margin-bottom: 1em;
  }

  & #lottie {
    position: relative;
  }
`;
const InputLabel = styled.div`
    display:flex;
    flex:1;
    align-items: center;
    justify-content: center;
    flex-direction: column; 
    /* position: absolute; */
    position: ${theme('palette.UDBFileUploadInputLabelPosition')};
    /* top: 30%; */
    top: unset;
    cursor: pointer;
    gap: ${theme('palette.UDBFileUploadInputLabelGap')};

    .fileUploadImg {
        width: 20px;
        height: 20px;
    }
`;
const Span = styled.span`
    font-weight: 500;
    font-size: .75rem;
    line-height: 13px;
    letter-spacing: 0.01em;
    text-transform: capitalize;
    color:${theme('palette.UDBFileUploadTextColorSecondary')};

    i.req { 
        color:red; 
        padding-left:3px
    }
`;
const DropYourFile = styled.div`
  display: flex;
  align-items: center;
  padding-right: 5px;
  font-weight: 500;
  font-size: 12px;
  color:${theme('palette.UDBFileUploadTextColor')};
`;
const Browse = styled.span`
  width: auto;
  display: flex;
  letter-spacing: 0.01em;
  text-decoration-line: underline;
  text-transform: uppercase;
  color: ${theme('palette.DepositTextColorSecondary')};
  padding-left: 5px;
`;
const UploadSucessfully = styled.div<{ bg?: string }>`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  width:100%;
  /* color: #B0BFD6; */
  color: ${theme('palette.UDBFileUploadSucessfullyTextColor')};
  letter-spacing: 0.01em;
  position:relative;
  background:${props => props.bg ? `url(${props.bg}) no-repeat 0 0` : ''};
  background-size: contain;
  height:100%;
  border-radius: 12px;
  background-position: 33px center;
`;
const UploadSucessfullyWrapper = styled.div`
    position: absolute;
    top: 0%;
    width: 100%;
    height: 100%;
    left: 0;
    z-index: 9999;

`
const XCircleWrapper = styled.span`
position: absolute;
right: 5px;
top: 5px;
width: 20px;
height: 20px;
overflow: hidden;
display:inline-block;
cursor: pointer;
>svg {
    width:100%;
    height:100%;
    margin:0!important;
}
`;
const FileInput = styled.input`
  font-size: 18px;
  display: block;
  width: 100%;
  border: none;
  text-transform: none;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  opacity: 0;
  z-index: 999;
  cursor: pointer;

  &:focus {
    outline: none;
  }
`;
const FileUploadErrorMsg = styled.p`
    color: ${theme('palette.ErrorMsgColor')};
    bottom: -16px;
    bottom: ${theme('palette.FileUploadErrorMsgBottom')};
    /* left: 5px; */
    padding: 0;
    margin: 0;
    position: absolute;

    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
`
