import React, { useEffect } from 'react'
import { withAppContext } from 'src/store/initAppContext';
import styled, { withTheme } from 'styled-components';
import { LazyLoadImage } from "react-lazy-load-image-component";
import config from "src/config/config";
import PlaceholderImage from 'src/components/Placeholder';
import { theme } from 'styled-tools';
import FooterContainer from 'src/components/footer/footer';
import Slider from "react-slick";

function LiveJackPot2025PromoFun(props) {
    const { app: {
        brandId
    }, theme } = props;

    useEffect(() => {
        window.scrollTo(0, 0)
    }, [])

    const settings = {
        loop: true,
        arrows: true,
        autoplay: false,
        dots: true,
        infinite: true,
        speed: 500,
        slidesToShow: 6,
        slidesToScroll: 6,
        initialSlide: 0,
        centerPadding: '20px'
    };

    const domain = (brandId == 46 || brandId == 47) ? "FUN88" : 'Website';

    return (
        <>
            <InformationWrapper>
                <TitleText>
                    <p>Get ready for <span className='boldTextBlack'>"The Fun jackpot"</span> at {domain}, with a whopping potential prize of up to <strong>7 Lakh!</strong> Each 1,000 INR wagered gives you one point in the Battle. <br />Play more, earn more points, and increase your chances to win!</p>
                </TitleText>
                <ImageWrapper>
                    <p>Prize Pool : <strong>7 Lakh</strong></p>
                    <Container>
                        <Card>
                            <LazyLoadImage
                                src={`${config.imageV3Host}/static/cashsite/${theme.image.pathToYourChipImage}`}
                                alt="Prize Pool Interest"
                                effect="none"
                                placeholder={<PlaceholderImage />}
                            />
                            <Title>Prize Pool Interest</Title>
                            <Subtitle>0.015% Of Each Bet</Subtitle>
                        </Card>

                        <Card>
                            <LazyLoadImage
                                src={`${config.imageV3Host}/static/cashsite/${theme.image.pathToYourCoinImage}`}
                                alt="Promo Point"
                                effect="none"
                                placeholder={<PlaceholderImage />}
                            />
                            <Title>Promo Point</Title>
                            <Subtitle>1,000 INR Turnover In Mentioned Games</Subtitle>
                        </Card>

                        <Card>
                            <LazyLoadImage
                                src={`${config.imageV3Host}/static/cashsite/${theme.image.pathToYourTrophyImage}`}
                                alt="Prizes"
                                effect="none"
                                placeholder={<PlaceholderImage />}
                            />
                            <Title>Prizes</Title>
                            <Subtitle>50 Winners</Subtitle>
                        </Card>

                        <Card>
                            <LazyLoadImage
                                src={`${config.imageV3Host}/static/cashsite/${theme.image.pathToYourGiftImage}`}
                                alt="Random Giveaways"
                                effect="none"
                                placeholder={<PlaceholderImage />}
                            />
                            <Title className='textHighlight'>Random <br /> Participation</Title>
                            <Subtitle>Giveaways Weekly</Subtitle>
                        </Card>
                    </Container>
                </ImageWrapper>
                <ContentWrapper>
                    <div className='bgRadius'>
                        <p>Get in on the action and boost your points!</p>
                        <p>Prizes will be credited to your Balance within 3 days after the final day of the tournament.</p>
                    </div>
                    <div className='bgRadius'>
                        <h2 className='boldText'>Participating Games :</h2>
                        <p>All Live Dealer Games :</p>
                        <div className='contntImg'>
                            <LazyLoadImage
                                src={`${config.imageV3Host}/static/cashsite/brand/6/image/providerNameImgs.png`}
                                alt="Random Giveaways"
                                effect="none"
                                placeholder={<PlaceholderImage />}
                            />
                        </div>
                        <p>Event Dates : <strong>Starting 15th January 2025 to 15th March 2025</strong></p>
                    </div>
                </ContentWrapper>

                <PriceBreakDown>
                    <h2 className='boldText'>Prize Breakdown : Place | Prize</h2>
                    <SliderWrapper>
                        <Slider {...settings}>
                                <div><img src={`${config.imageV3Host}/static/cashsite/${theme.image.priceJP1}`}/></div>
                                <div><img src={`${config.imageV3Host}/static/cashsite/${theme.image.priceJP2}`}/></div>
                                <div><img src={`${config.imageV3Host}/static/cashsite/${theme.image.priceJP3}`}/></div>
                                <div><img src={`${config.imageV3Host}/static/cashsite/${theme.image.priceJP4}`}/></div>
                                <div><img src={`${config.imageV3Host}/static/cashsite/${theme.image.priceJP5}`}/></div>
                                <div><img src={`${config.imageV3Host}/static/cashsite/${theme.image.priceJP6}`}/></div>
                                <div><img src={`${config.imageV3Host}/static/cashsite/${theme.image.priceJP7}`}/></div>
                                <div><img src={`${config.imageV3Host}/static/cashsite/${theme.image.priceJP8}`}/></div>
                                <div><img src={`${config.imageV3Host}/static/cashsite/${theme.image.priceJP9}`}/></div>
                                <div><img src={`${config.imageV3Host}/static/cashsite/${theme.image.priceJP10}`}/></div>
                                <div><img src={`${config.imageV3Host}/static/cashsite/${theme.image.priceJP11}`}/></div>
                                <div><img src={`${config.imageV3Host}/static/cashsite/${theme.image.priceJP12}`}/></div>
                        </Slider>
                    </SliderWrapper>
                </PriceBreakDown>
                
                <BigBashTermsAndCondition>
                    <h4>Terms & Conditions</h4>
                    <ul>
                        <li>This promotion is valid for all new and existing players.</li>
                        <li>Winners will be announced on {domain}'s social media handles (Instagram & Telegram) as well as in your respective {domain} inbox.</li>
                        <li>This promotion applies <span className='boldText'>only to live dealer games</span> from <strong>Evolution, Ezugi, Vivo, Supernova, and AgTeenPatti</strong>.</li>
                        <li>Winners will be determined by those with the <span className='boldText'>highest wager</span> every month.</li>
                        <li>Random Participation giveaways weekly.</li>
                        <li>Void, canceled, or draw bets, as well as cashed-out bets, do not count towards deposit or bonus rollover requirements.</li>
                        <li>Multiple accounts will not be eligible for this promotion. All players will undergo duplicate checking. {domain} reserves the right to disqualify players involved 
                        in collusion or any foul play related to this promotion.</li>
                        <li>{domain} reserves the right to amend, cancel, reclaim, or refuse any promotion at its discretion.</li>
                        <li>General Terms & Conditions of {domain} apply.</li>
                    </ul>
                </BigBashTermsAndCondition>
            </InformationWrapper>
            <FooterContainer />
        </>
    )
}



export default withTheme(withAppContext(LiveJackPot2025PromoFun));

const SliderWrapper = styled.div`
    width: 85%;
    margin: 0 auto;

    .slick-slide {
        padding: 0 15px;
    }

    .slick-slide img {
        display: block;
        width: 100%;
        transition: transform .5s; /* Animation */

        :hover {
            transform: scale(0.8);
            border: 3px solid #ffffff;
            border-radius: 1rem;
        }

        :focus-visible {
            outline: none;
        }
        :focus {
            outline: none;
        }
    }

    .slick-prev {
        left: -65px;
        top: 43%;
    }

    .slick-next {
        right: -25px;
        top: 43%;
    }

    .slick-prev:before {
        content: "";
        width: 60px;
        height: 60px;
        display: inline-block;
        background: url(${config.imageV3Host}/static/cashsite/brand/6/image/prevSlideJackpotFun.png);
        background-size: cover;
        background-position:center center;
        background-repeat: no-repeat;
        opacity: unset;
    }

    .slick-next:before {
        content: "";
        width: 60px;
        height: 60px;
        display: inline-block;
        background: url(${config.imageV3Host}/static/cashsite/brand/6/image/nextSlideJackpotFun.png);
        background-size: cover;
        background-position:center center;
        background-repeat: no-repeat;
        opacity: unset;
    }

    .slick-dots {
        bottom: -40px;

        li button:before {
            color: #EBEBEB;
            font-size: 15px;
            opacity: unset;
        }

        li.slick-active button:before {
            opacity: unset;
            color: ${theme('palette.slickActiveButtonClr')};
        }
    }
`;

const InformationWrapper = styled.div`
    width: 100%;
    height: auto;
    padding: 1.5rem 0 2rem 0;
    display: flex;
    flex-direction: column;
    background: #EBEBEB;
    overflow: hidden;

    .boldText {
        color: ${theme('palette.informationWrapperPromoBoldText')};
        font-weight: 700;
    }

    .boldTextBlack {
        color: #000;
        font-weight: 700;
    }

    h2 {
        color: #E6FF00;
        text-align: center;
        font-size: 20px;
        font-style: normal;
        font-weight: 700;
        line-height: normal;
        text-transform: capitalize;
    }

    .bgRadius {
        width: 1120px;
        margin: 0 auto;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        border-radius: 11.188px;
        background: #FFF;
        text-align: center;
        padding-bottom: 1rem;
        margin-top: 1rem;
    }
`;

const PriceBreakDown = styled.div`
    width: 99%;
    border-radius: 11.188px;
    background: #FFF;
    padding: 3rem 0 4rem 0;
    margin: 1rem auto;
`;

const Subtitle = styled.div`
  font-size: 16px;
  font-weight: bold;
  margin-top: 5px;
  color: ${theme('palette.informationWrapperPromoBoldText')};
`;

const Card = styled.div`
    width: 25%;
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    color: #444;

    :last-child {
        ${Subtitle} {
            margin-top: 0px;
        }
    }
`;

const Title = styled.div`
  font-size: 14px;
  margin-top: 10px;

  &.textHighlight {
    font-size: 16px;
    font-weight: bold;
    color: ${theme('palette.informationWrapperPromoBoldText')};
  }
`;

const TitleText = styled.div`
    width: 1120px;
    margin: 0 auto;
    border-radius: 11.188px;
    background: #FFF;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    padding: 1rem 0 1rem 0;
    margin-bottom: 1rem;

    p {
        color: #444;
        text-align: center;
        font-size: 15px;
        font-style: normal;
        font-weight: 400;
        line-height: 28px; /* 186.667% */
        text-transform: capitalize;
        margin: 0;
    }
`;

const ImageWrapper = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
    height: 320px;
    margin-bottom: 2rem;
    width: 1120px;
    margin: 0 auto;
    border-radius: 11.188px;
    background: #FFF;

    img {
        width: 93px;
    }
`;

const ContentWrapper = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    padding-top: 0;
    color: #444;

    p {
        margin: 1em 0 0 0;
    }

    .contntImg {
        width: 885px;
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 1.5rem 0;
        position: relative;

        img {
            max-width: 100%;
        }
    }
`;

const Container = styled.div`
    /* max-width: 70%; */
    width: 70%;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 1em;
`;

const BigBashTermsAndCondition = styled.div`
    height: auto;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    padding: 3rem 7rem;
    position: relative;
    width: 1120px;
    margin: 0 auto;
    border-radius: 11.188px;
    background: #FFF;

    & li {
        color: #444;
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: 22.8px; /* 142.5% */
        text-transform: capitalize;
        margin: 10px 0;
    }

    & h4 {
        color: #06A6FF;
        font-size: 20px;
        font-style: normal;
        font-weight: 700;
        line-height: 35px; /* 175% */
        text-transform: capitalize;
        margin: 10px 0px 0px 15px;
    }
`;