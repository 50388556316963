import React, { useEffect } from 'react'
import { MainInfoWrapper } from 'src/components/udb/commonStyle';
import config from 'src/config/config';
import { withAppContext } from 'src/store/initAppContext';
import styled, { withTheme } from 'styled-components';
import { theme } from 'styled-tools';
import { motion } from 'framer-motion';
import FooterContainer from "src/components/footer/footer";
import { TextContent } from './aviatorBonusPage';
import { ContentWrapper } from './dailyDepositBonus';
import { FifaFeverFirstSecHead1, NoborderTop1 } from './evolutionPromo';


type Props = {}

const PlatinumRush = (props) => {


    const { app: {
        brandId
    }, theme } = props;

    const domain = brandId == 31 || brandId == 32 ? 'Yolo247' : 'Betdaily';


    return (
        <motion.div
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
        >
            <MainInfoWrapper>
                <ContentWrapperPl>

                    <TextContent className='header'>Are you ready to experience the World Cup like never before? At {domain}, we believe that every player should feel like a Platinum Tier Champion during this epic tournament. That's why we're thrilled to introduce our exclusive {domain} World Cup Platinum rush!
                    </TextContent>

                    <CardDetailsWrap>

                        <ImageWrap>
                            <img src={`${config.imageV3Host}/static/cashsite/${theme.image.wcccards}`} />
                        </ImageWrap>

                        <DetailWrap>
                            <h4>
                                Platinum-Upgrade
                            </h4>
                            <p>
                                Every existing customer will be upgraded to Platinum tier for the duration of this World Cup!
                            </p>
                            <p className='break'>
                                Get the Platinum Treatment: We're rolling out the red carpet for you with a phenomenal 5% Refill Bonus on all your deposits throughout the entire World Cup season & 5% cashback bonus every week to cover your weekly loses . It's our way of ensuring that your gaming journey is filled with excitement and rewards!
                            </p>
                            <p className='break'>
                                More Action, More Rewards: Whether you're a seasoned player or a newbie, everyone deserves a chance to elevate their World Cup experience. With our 5% Refill Bonus & 5% cashback bonus , you'll have more funds to play with, increasing your chances of winning big while enjoying every moment of the World Cup action.
                            </p>
                        </DetailWrap>
                    </CardDetailsWrap>

                    <BoxWrap>
                        <FifaFeverFirstSecHead1 className="platinum"><p>How to Claim Your Bonus:</p></FifaFeverFirstSecHead1>
                        <NoborderTop2 className='ul'>
                            <ul>
                                <li><span>★ </span> Log in to your {domain} account during the World Cup.</li>
                                <li><span>★ </span> Make a deposit using your preferred payment method.</li>
                                <li><span>★ </span> Enjoy an instant 5% Refill Bonus added to your account automatically!</li>
                                <li><span>★ </span>Every Monday get an instant 5% cashback bonus on your weekly loses.</li>
                            </ul>
                        </NoborderTop2>
                    </BoxWrap>

                    <LastWrap>
                        <FifaFeverFirstSecHead1 className="platinum1"><p>Limited-Time Offer</p></FifaFeverFirstSecHead1>
                        <h3>
                            This exclusive bonus is available only for the duration of the World Cup, so don't miss out on this incredible opportunity to boost your gaming adventure.
                        </h3>
                    </LastWrap>

                </ContentWrapperPl>

            </MainInfoWrapper>
            <FooterContainer />
        </motion.div>
    )
}

export default withTheme(withAppContext(PlatinumRush))

export const ContentWrapperPl = styled(ContentWrapper)`
     background: url(${config.imageV3Host}/static/cashsite/${theme('image.platinumrushbginner')});
     padding: 5rem 7rem;
     background-size: cover;
     .header{
        padding:1rem 20rem;
     }
     h4{
        color: #FCF29E;
text-align: center;
font-size: 21px;
font-style: normal;
font-weight: 700;
line-height: 29.1px;
text-transform: capitalize;
width: 100%;
margin-block-start: 0;
     }
`
const LastWrap = styled.div`
    h3{
        color: #FFC51B;
text-align: center;
font-size: 20px;
font-style: normal;
font-weight: 700;
line-height: 29.1px;
text-transform: uppercase;
width: 100%;
padding: 2rem 8rem;
    }
`

const NoborderTop2 = styled(NoborderTop1)`
padding: 0;
list-style-type: none;
`
const CardDetailsWrap = styled.div`
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0rem 9rem;
    padding-top: 4rem;
    gap:2rem;
    padding-bottom: 7rem;
`


const BoxWrap = styled.div`
    width: 55%;
    border-radius: 10.526px;
border: 2px solid var(--AC-B, #F28500);
background: linear-gradient(100deg, rgba(0, 0, 0, 0.05) 0%, rgba(17, 17, 17, 0.05) 102.81%);
backdrop-filter: blur(86.5px);
padding: 3rem;
margin-bottom: 5rem;

.ul{
    ul {
        width: 100%;
    text-align: center;
    line-height: 36px;
    }
    ul li {
    color:#fff;
    font-size: 20px;
    padding: 0;
    list-style-type: none;
}
}

span{
    color: #FFC51B;
}
`


const ImageWrap = styled.div`
width: 50%;
display: flex;
align-items: center;
justify-content: center;
padding: 2rem;
img{
    max-width: 100%;

}

`
const DetailWrap = styled.div`
color:white;    
width: 50%;
h4{
font-size: 39px;
font-style: italic;
font-weight: 900;
font-family: 'inter-bold';
line-height: 53px; /* 147.222% */
letter-spacing: 0.72px;
text-transform: uppercase;
     background: var(--Ticket, linear-gradient(104deg, #D08D00 0%, #F3E35B 50.52%, #E9B701 100%));
background-clip: text;
-webkit-background-clip: text;
-webkit-text-fill-color: transparent;
margin: 0;

}
.break{
    color: #FFF;
text-align: justify;
font-size: 18px;
font-style: normal;
font-weight: 400;
line-height: 179.5%;
text-transform: capitalize;
}

p{
    color: #FFC51B;
font-size: 20px;
font-style: normal;
font-weight: 500;
text-transform: uppercase;
}
`

const FifaFeverFirstSecHead2 = styled(FifaFeverFirstSecHead1)`
    text-align: center;
font-size: 100px;
font-style: italic;
font-weight: 900;
line-height: normal;
letter-spacing: 2px;
text-transform: uppercase;
opacity: 0.29;
background: var(--Ticket, linear-gradient(104deg, #D08D00 0%, #F3E35B 50.52%, #E9B701 100%));
background-clip: text;
-webkit-background-clip: text;
-webkit-text-fill-color: transparent;

`