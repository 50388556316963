import React from "react";
import styled from "styled-components";

// Imported Components
import BetSlip from "../BetSlip/index";
// import BetSlipInner from "../BetSlip/betslip-inner";
import {color} from '../../sass-files/variable';

import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import { withAppContext } from '../../store/initAppContext';

// images
//import BetslipBackground from "../../images/Rectangle 211.png";
//import Mybet from "../../images/Rectangle 45.png";
import OrderslipList from "../eu-content/order/OrderslipList";
import MatchedslipList from "../eu-content/order/OrderslipList";
import LiveTv from "../eu-content/order/orderslip/LiveTv";
import cookieUtil from "src/util/cookieUtil";
import { ECookieName } from "src/util/utilModel";
import Intl from '../../components/common/Intl';



const {tabsignup} = color


const BetSlip_myBet_tab=(props)=>{
    return(
            <>
             {props.app.token && props.app.showLiveTv && <LiveTv/>}
            <BetlistTabs>
                <Tabs>
                    {/* <TabList> */}
                        {/* <Tab className="newtab1">
                           <div className="betslip"><Intl langKey="ORDERSLIP@TITLE" /></div> 
                        </Tab> */}
                        {/* <Tab className="newtab2">
                            <div className="betslip">My Bets</div> 
                        </Tab> */}
                    {/* </TabList> */}
                    
                    {/* <TabPanel>
                        <BetSlip/> 
                    </TabPanel> */}
                    <TabPanel>
                        {/* <BetSlipInner/>  */}
                        <OrderslipList />
                       
                    </TabPanel>
                    <TabPanel>
                        {/* <MyBetInner/> */}
                        <MatchedslipList/>
                    </TabPanel>
                    
                </Tabs>
            </BetlistTabs>
            </>
    )
};
export default withAppContext(BetSlip_myBet_tab);


const BetlistTabs = styled.div`
    display: flex;
    width: 100%;
    justify-content: flex-start;
    align-items: flex-start;

    .react-tabs {
        width: 100%;
        margin-top: 0px;
    }

    .newtab1 {
        letter-spacing: 0.04em;
        text-transform: capitalize;
        font-size: 14px;
        color: ${tabsignup};
        
        list-style: none;
        background: #D0D9EB;
        margin-right: 6px;
        text-transform: uppercase;
        border-radius: 5px;
        transform: skewX(-30deg);
        margin-left: -19px;

        .betslip {
            transform: skewX(30deg);
            cursor: pointer;
            padding: 7px 22px 5px 25px;
        }
    }
    
    .newtab1.react-tabs__tab--selected{

            letter-spacing: 0.04em;
            font-size: 14px;
            color: #fff;
            
            list-style: none;
            background:#214076;
            // padding: 7px 22px 5px 25px;
            text-transform: uppercase;
            // margin-top:5px;
    }

    .newtab2{

            letter-spacing: 0.04em;
            font-size: 14px;
            color: ${tabsignup};
            
            list-style: none;
            background: #D0D9EB;
            text-transform: uppercase;
            // margin-top:5px;
            border-radius: 5px;
            transform: skewX(-30deg);

            .betslip {
                transform: skewX(30deg);
                cursor: pointer;
                padding: 7px 22px 5px 25px;
            }
    }

    .newtab2.react-tabs__tab--selected{

        letter-spacing: 0.04em;
        font-size: 14px;
        color: #fff;
        
        list-style: none;
        background:#214076;
        text-transform: uppercase;
        // margin-top:5px;
}

.react-tabs__tab-list{
    display: flex;
    background:#fff;
    padding: 3px 0px 3px 7px;
    overflow: hidden;
    margin: 0;
    border: none;
    margin-bottom: 2px;
}
    
`;