import React from 'react';
import styled, { css } from 'styled-components';
import { ifNotProp, ifProp, switchProp, theme, withProp } from 'styled-tools';
// ruyruytv
// Consumers
import { MainLayoutConsumer } from '../../store/MainLayoutContext';

// Utils
import media from '../../util/mediaUtil';

// Styles
import { GradientFont, iOSClickableFix, scrollbarContainerStyle } from './commonCSSStyles';
import config from '../../config/config';
import { IconWrapper } from './PNGIcon';
import { isMemberType } from '../../util/styleUtil';

// Layout Sizes
export const headerHeight = '72px';
export const clientHeaderHeight = '70px';
export const mobileHeaderHeight = ' 36px';
export const mobileClientHeaderHeight = '55px';
export const sidebarWidth = '240px';
export const mobileSidebarWidth = '100vw';
export const bettingWidth = '300px';
export const mobileBettingWidth = '100%';
export const mobileFooterMenu = '48px';

export const MajorMask = () => {
	return (
		<MainLayoutConsumer>
			{store => {
				return (
					<MajorMaskWrapper isBettingOpened={store.isBettingOpened} isSidebarOpened={store.isSidebarOpened} />
				);
			}}
		</MainLayoutConsumer>
	);
};

const MajorMaskWrapper: any = styled.div`
	${iOSClickableFix};
	position: absolute;
	top: 0;
	right: 0;
	bottom: 0;
	left: 0;
	z-index: 99;
	display: none;

	${media.xl`
		${ifProp(
			'isSidebarOpened',
			css`
				display: block;
			`,
		)};
	`};

	${media.md`
		${ifProp(
			'isBettingOpened',
			css`
				display: block;
			`,
		)}
	`};
`;

// Wrapping MajorHead, MajorMask and Core content (event tables, match tables, etc)

export const MajorWrapper: any = styled.section`
	${scrollbarContainerStyle};
	&::-webkit-scrollbar {
		width: 10px;
		height: 6px;
		background-color: none;
		${media.lg`
			width: 0;
		`};
	}
	&::-webkit-scrollbar-button:decrement:start {
		display: block;
		width: 10px;
		height: 15px;
		background: url(${config.cdnHost}/static/snk/icon/png/chevron-up.png) no-repeat center center;
		background-size: 10px auto;
	}
	&::-webkit-scrollbar-button:decrement:end {
		display: none;
	}
	&::-webkit-scrollbar-button:increment:start {
		display: none;
	}
	&::-webkit-scrollbar-button:increment:end {
		display: block;
		width: 10px;
		height: 15px;
		background: url(${config.cdnHost}/static/snk/icon/png/chevron-down.png) no-repeat center center;
		background-size: 10px auto;
	}
	padding: 12px 15px 2em;
	flex-grow: 1;
	position: relative;

	${media.md`
		width: 100%;
		flex-shrink: 0;
		padding: 10px;
	`};
`;

export const PlatformMainWrapper = styled(MajorWrapper)`
	padding: 0;
	background-color: ${theme('palette.panel-outer-background')};
	${media.md`
		padding: 0;
	`};
`;
/**
 * Wrapping MajorWrapper & Order section (only in ContentContextLayout for Client)
 */
export const Content = (props: { children: any }) => {
	return (
		<MainLayoutConsumer>
			{store => {
				return <ContentWrapper isBettingOpened={store.isBettingOpened}>{props.children}</ContentWrapper>;
			}}
		</MainLayoutConsumer>
	);
};

// Wrapping Major content and Betting section
export const ContentWrapper: any = styled.div`
	display: flex;
	flex-grow: 1;
	/* Prevent shrinking on column direction */
	flex-shrink: 0;
	height: 100%;

	${media.md`
		width: 100%;
		overflow: visible;
		${ifProp(
			'isBettingOpened',
			css`
				transition: transform 0.275s ease-out;
				will-change: transform;
				transform: translateX(calc(${bettingWidth} * -1));
			`,
			css`
				transition: transform 0.225s ease-in;
				will-change: transform;
				transform: translateX(0);
			`,
		)}
	`};

	${media.sm`
		${ifProp(
			'isBettingOpened',
			css`
				transform: translateX(calc(${mobileBettingWidth} * -1));
			`,
			css`
				transform: translateX(0);
			`,
		)}
	`};
`;

// Wrapping Content section
// TODO: previously this element wraps the header & content wrapper
// and layout *vertically*, but now the design has been changed, thus
// combines it with the ContentWrapper component
export const MainWrapper: any = styled.main`
	flex-grow: 1;
	overflow: hidden;
	overflow-y: auto;
	/* Use momentum scrolling on iOS */
	-webkit-overflow-scrolling: touch;
	position: static;
	-webkit-transform: translate3d(0, 0, 0);

	${media.xl`
		/* Push Sidebar out of the screen */
		width: 100%;
		flex-shrink: 0;
	`};
`;

export const Body = (props: { children: any }) => {
	return (
		<MainLayoutConsumer>
			{store => {
				return <BodyWrapper isSidebarOpened={store.isSidebarOpened}>{props.children}</BodyWrapper>;
			}}
		</MainLayoutConsumer>
	);
};

export const MainBody = (props: { children: any }) => {
	return (
		<MainLayoutConsumer>
			{store => {
				return <MainBodyWrapper>{props.children}</MainBodyWrapper>;
			}}
		</MainLayoutConsumer>
	);
};

export const NewMainBody = (props: { children: any }) => {
	return (
		<MainLayoutConsumer>
			{store => {
				return <NewBodyWrapper isSidebarOpened={store.isSidebarOpened}>{props.children}</NewBodyWrapper>;
			}}
		</MainLayoutConsumer>
	);
};
// Wrapping Sidebar & Main
// When sidebar is *not* opened, translate it to the left in order to hide sidebar
export const BodyWrapper: any = styled.div`
	display: flex;
	width: 100%;
	height: 100%;
	overflow: visible;

	${media.xl`
		${ifProp(
			'isSidebarOpened',
			css`
				transition: transform 0.275s ease-out;
				will-change: transform;
				transform: translateX(0);
			`,
			css`
				transition: transform 0.225s ease-in;
				will-change: transform;
				transform: translateX(calc(${sidebarWidth} * -1));
			`,
		)}
	`};

	${media.sm`
		${ifNotProp(
			'isSidebarOpened',
			css`
				transform: translateX(calc(${mobileSidebarWidth} * -1));
			`,
		)}
	`};
`;

export const MainBodyWrapper: any = styled.div`
	display: flex;
	width: 100%;
	height: 100%;
	overflow: visible;
`;

export const NewBodyWrapper: any = styled.div`
	display: flex;
	width: 100%;
	height: 100%;
	overflow: visible;
	position: relative;
	z-index: 1;

	${media.md`
		${ifProp(
			'isSidebarOpened',
			css`
				transition: transform 0.275s ease-out;
				will-change: transform;
				transform: translateX(0);
			`,
			css`
				transition: transform 0.225s ease-in;
				will-change: transform;
				transform: translateX(calc(${sidebarWidth} * -1));
			`,
		)}
	`};

	${media.sm`
		${ifNotProp(
			'isSidebarOpened',
			css`
				transform: translateX(calc(${mobileSidebarWidth} * -1));
			`,
		)}
	`};
`;

// Wrapping the body content or iframe
// taking the rest space of the screen (window height - header height)
// Code Changed - Manoj ( Added AppBodyWrapper type any)
export const AppBodyWrapper:any = styled.div<{ hasMenu: string }>`
	height: 100%;
	min-height: 0;
	flex-grow: 1;
	flex-shrink: 1;
	background: ${theme('palette.body-background')};
	position: relative;
	${switchProp('hasMenu', {
		// margin-top commented by ambu
		NeedMenuLayout: css`
			// margin-top: 122px;
			${media.md`
				// margin-top: 59px;
			`};
		`,
		NoMenuLayout: css`
			margin-top: 70px;
			${media.md`
				margin-top: 59px;
			`};
		`,
		AgentLayout: css`
			/* margin-top: 20px; */
		`,
	})};
`;

// Wrapping Header & the AppBodyWrapper
// should be direct parent of div.__next
export const AppWrapper = styled.div`
	display: flex;
	flex-direction: column;
	width: 100%;
	height: 100%;
	// ${media.md`
	// 	height: calc(100% - 48px);
	// `}
	${isMemberType(
		'CLIENT',
		css`
		${media.md`
			height: calc(100% - 48px);
		`};
		`,

		
	)};

	

`;
export const MemberAppWrapper = styled(AppWrapper)`
	position: relative;

	${media.md`

	background: url('${config.cdnHost}/static/snk/bg/mobile_dropdown-bg.jpg') fixed;
	background-size: cover;
	`};

	${AppBodyWrapper} {
		background: rgba(0, 0, 0, 0);
	}
`;
export const MemberLayoutWrapper = styled.div`
	position: relative;
	display: flex;
	flex-direction: column;
	width: 100%;
	height: 100%;
`;

export const PageTitleName: any = styled.div`
	display: none;
	${media.md`
	font-size: 16px;
	font-weight: bold;
	display: block;
	text-align: center;
	text-transform: uppercase;
	${GradientFont};
		margin: 0 0 0.5em;
	`};
`;
