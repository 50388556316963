import React from 'react'

type Props = {}

const DepositCloseIcon = (props: Props) => {
  return (
    <svg
    width={21}
    height={21}
    viewBox="0 0 21 21"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M19.5 19.5L1.5 1.5M19.5 1.5L1.5 19.5"
      stroke="#F8F8F8"
      strokeWidth={2}
      strokeLinecap="round"
    />
  </svg>
  )
}

export default DepositCloseIcon