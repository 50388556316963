import React, { PureComponent } from 'react';
import styled, { css } from 'styled-components';
import { prop, theme, withProp } from 'styled-tools';

// Dependent Components
import { lighten } from 'polished';
import Openable from '../common/Openable';
import Icon, { IconWrapper } from '../common/PNGIcon';
import CricketScoreIFrame from './live/CricketScoreIFrame';
import MatchLiveScore from '../eu-content/sportradar/MatchLiveScore';
import SportRadar from '../eu-content/sportradar/SportRadar';
import Intl from '../common/Intl';

// Utils
import config from '../../config/config';
import { diffColor } from '../../util/colorUtil';
import media from '../../util/mediaUtil';
import util from '../../util/util';
import { EEventTypeId } from '../../util/utilModel';

// Schema
import { ILiveInfo, IScoreInfo } from '../../graphql/schema';
import { shadowLG } from '../common/commonCSSStyles';

//images
import favouriteIcon from '../../images/FavoriteDisabled.png';


interface ILiveProps {
	scoreInfo: IScoreInfo;
	liveInfo?: ILiveInfo | null;
	eventId: string;
	betradarId?: string;
	matchType?: string;
}

interface ILiveState {
	isUsingCricketScoreIFrame: boolean;
	isUsingFloat: boolean;
}

class Live extends PureComponent<ILiveProps, ILiveState> {
	state = {
		isUsingCricketScoreIFrame: false,
		isUsingFloat: true,
	};

	componentDidMount() {
		this.checkCricketScoreIFrame();
	}

	componentDidUpdate(prevProps: ILiveProps) {
		const { eventId: prevEventId } = prevProps;
		const {
			eventId,
			scoreInfo: { categoryId },
		} = this.props;

		categoryId === EEventTypeId.cricket
			? prevEventId !== eventId && this.checkCricketScoreIFrame()
			: this.setState({ isUsingCricketScoreIFrame: false });
	}

	checkCricketScoreIFrame = () => {
		const {
			scoreInfo: { categoryId },
			eventId,
		} = this.props;

		// 如果是板球而且 Nick 的 score iframe 有資料
		categoryId === EEventTypeId.cricket
			? fetch(`${config.cricketScoreHost}${eventId}`).then(res => {
					const { status } = res;
					this.setState({
						isUsingCricketScoreIFrame: status === 200,
					});
			  })
			: this.setState({ isUsingCricketScoreIFrame: false });
	};

	render() {
		const { betradarId, scoreInfo, liveInfo = {} as ILiveInfo, eventId, matchType } = this.props;
		const { isUsingCricketScoreIFrame } = this.state;
		const { home: homeScore, away: awayScore } = scoreInfo;

		// flags
		const hasBetFairScore = homeScore && awayScore;
		const hasVideo = !!liveInfo && liveInfo.url && liveInfo.streamName;
		const hasScoreInfo = isUsingCricketScoreIFrame || betradarId || hasBetFairScore;
		// if(!isUsingCricketScoreIFrame) {
		// 	return null;
		// }
		// Early escape when nothing to show
		if (!hasScoreInfo && !betradarId && !hasVideo) {
			return null;
		}
		
		return (
			<LiveWrapper>
				{hasScoreInfo && (
					<Openable initialOpen={false}>
						{({ isOpened, toggleOpen }) => {
							return (
								<>
									{/* <TabBarWrapper onClick={toggleOpen}>
										<TabWrapper> */}
										<div className="Live-Score-field" onClick={toggleOpen}>
											<LiveScoreButton>
												<Icon name="score-live"/>
												<LiveScore>	
													<Intl langKey="HEADER@LIVESCORE"/>
												</LiveScore>
											</LiveScoreButton>
											{/* <LiveScore>	
												<Intl langKey="HEADER@LIVESCORE"/>
											</LiveScore> */}
										</div>											
											{/* <TabIconWrapper>
												<Icon name={isOpened ? 'chevron-up' : 'chevron-down'} />
											</TabIconWrapper> */}
										{/* </TabWrapper>
									</TabBarWrapper> */}
									{isOpened && (
										<div>
											{isUsingCricketScoreIFrame && <CricketScoreIFrame eventId={eventId} />}
											{betradarId && (
												<SportRadar
													betradarId={betradarId}
													categoryId={scoreInfo.categoryId}
													isUsingCricketScoreIFrame={isUsingCricketScoreIFrame}
													isUsingFloat={this.state.isUsingFloat}
												/>
											)}
											{!isUsingCricketScoreIFrame && !betradarId && hasBetFairScore && (
												<MatchLiveScore scoreInfo={scoreInfo} matchType={matchType} />
											)}
										</div>
									)}
								</>
							);
						}}
					</Openable>
				)}
			</LiveWrapper>
		);
	}
}

export default Live;

// Styled Components

const LiveWrapper: any = styled.div`
	// margin: 2em auto 1.5em;
	width: 100%;
	max-width: 100%;
	/* border-radius: 4px; */
	overflow: hidden;
	font-size: 12px;
	/* color: ${theme('palette.table-head-text')}; */
	color: #fff;
	margin-top: 0;
	${shadowLG};
	${media.md`
		max-width: 100%;
		margin-top: 1em;
		margin-bottom: 1em;
	`};

	#lscontent > section {
		width: 100%;
		display: block;
		height: 100%;
		margin: auto;
		transform: scale(1);
		padding: 10px;
		background: linear-gradient(
	180deg, #e6d8c1 0%, rgba(79, 149, 214, 0.13) 100%);
	}
`;

// Styled Components
const LiveScoreButton = styled.div`
	display: flex;
	align-items: center;
	justify-content: center;
	position: absolute;
    right: 90px;
    font-size: 14px;
    top: -40px;
	width: 60px;
    padding: 4px 4px;
    border-radius: 5px;
	height: 35px;
	:hover {
		background: ${theme('palette.backBtnhover')};
    /* background: #6462d5bf;   */
}
	
	// @media only screen and (max-width: 1320px) {
	// 	left: 31%;
	// }

	// @media only screen and (max-width: 1600px) {
	// 	left: 33%;
	// }
`;

const LiveScore = styled.div`
	// position: absolute;
    // left: 370px;
    // top: -50px;
	font-size: 10px;
	font-weight: 900;
    display: flex;
    flex-wrap: wrap;
	width: auto;
	padding-left: 3px;
`;

const TabIconWrapper = styled.div`
	font-size: 12px;
	position: absolute;
	right: 0.75em;
	top: 50%;
	transform: translateY(-50%);
`;

const TabWrapper: any = styled.div`
	font-size: 14px;
	display: flex;
	justify-content: center;
	align-items: center;
	padding: 0.5em 0.25em;
	width: calc(100% / ${prop('totalTabAmount', 1)});
	cursor: pointer;
	position: relative;
	background-color: #e7f0fd;

	&:not(:last-of-type) {
		border-right: 1px solid rgba(128, 128, 128, 0.25);
	}

	/* IconWrapper */
	> div:first-of-type {
		margin-right: 0.5em;
		filter: drop-shadow(1px 1px 3px rgba(0, 0, 0, 0.4));
	}
`;

const TabBarWrapper: any = styled.div`
	display: flex;
	position: relative;
	background: ${theme('palette.live-bar-color')};

	${(props: any) => {
		const { currentIndex } = props;
		return currentIndex === null
			? ''
			: css`
					&::before {
						content: '';
						display: block;
						position: absolute;
						z-index: 1;
						left: 0;
						bottom: 0;
						width: calc(100% / ${prop('totalTabAmount')});
						height: 2px;
						background-color: ${theme('palette.primary-background')};
						transform: translateX(calc(100% * ${prop('currentIndex')}));
						transition: transform 0.3s ease-out;
						will-change: transform;
					}
			  `;
	}};
`;

const VideoIFrameContainer = styled.div`
	display: flex;
	justify-content: center;
	background-color: #000;
	width: 100%;
`;

const VideoIFrame = styled.iframe.attrs(props => ({
	scrolling: 'no',
	id: 'fp_embed_player',
}))`
	/* Must be 300:168 */
	width: 450px;
	height: 252px;
	border: 0;

	${media.sm`
		width: 357px;
		height: 200px;
	`};
`;