import * as React from 'react';
import styled from 'styled-components';

// Dependent component
import { withAppContext } from '../../store/initAppContext';

import config from '../../config/config';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import PlaceholderImage from 'src/components/Placeholder';

import favouriteIcon from '../../images/FavoriteDisabled.png'

// Styled Components
export const IconWrapper: any = styled.div`
	display: flex;
	justify-content: center;
	align-items: center;
	// Commented by ambu
	width: 1.25em;
	height: 1.25em;
	// width: 1em;
	// height: 1em;
	flex-shrink: 0;

	> img {
		width: 100%;
		height: auto;
	}
`;

interface IconProps {
	name: string;
	root?: string;
	// from AppContext
	app: any;
	theme: { view: string };
	onClick: () => void;
}
export class PNGIcon extends React.PureComponent<IconProps> {
	render() {
		const {
			app: { view = 'main', brandId , type = 1 },
			name,
		} = this.props;

		

		let root: string;
		switch (type) {

			//    code changed - malyaban  || company_admin commented to make  client icons run

				// case 'COMPANY_ADMIN':
				// 	root = `${config.cdnHost}/static/${view}/brand/0/icon/png/`;
				// 	break;
			case 'CLIENT':
				root = `${config.imageS3Host}/static/${'snk'}/brand/31/icon/png/`;
				break;
			default:
				root = `${config.imageS3Host}/static/${'snk'}/brand/1/icon/png-agent/`;
				break;
		}
		// const root =
		// 	type === 'COMPANY_ADMIN'
		// 		? `${config.cdnHost}/static/${view}/brand/0/icon/png/`
		// 		: `${config.cdnHost}/static/${view}/brand/${brandId}/icon/png/`;
		if (!name || typeof name !== 'string') {
			console.warn('[PNGIcon]: name prop is invalid!');
			return null;
		} else {
			return (
				<IconWrapper {...this.props}>
					<LazyLoadImage src={`${root}${name.toLowerCase()}.png`} effect="none" height="100%" width="100%"  placeholder={<PlaceholderImage/>}/>
					{/* <img src={favouriteIcon}/> */}
					{this.props.children}
				</IconWrapper>
			);
		}
	}
}

export default withAppContext(PNGIcon);
