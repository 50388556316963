import gql from 'graphql-tag';

// TODO: change the name of the variable to MEMBER_FAVORITE
export const TOGGLE_FAVORITE: any = gql`
	mutation memberFavorite($input: FavoriteInput!) {
		member {
			favorite(input: $input) {
				success
				message
			}
		}
	}
`;

export const LOG_OUT: any = gql`
	mutation memberLogout {
		member {
			logout {
				success
				message
			}
		}
	}
`;

export const creditInfo = gql`
	fragment creditInfo on CreditInfo {
		limitCredit
		availableCredit
		distributedCredit
		winlossCredit
		exposureCredit
	}
`;

export const GET_CREDIT: any = gql`
	query getCredit($input: CreditInput) {
		member {
			credit(input: $input) {
				...creditInfo
			}
		}
	}
	${creditInfo}
`;

export const GENERATE_OTP: any = gql`
	query GenerateOTP {
		member {
			generateOTP {
				authOtp
				secretCode
			}
		}
	}
`;

export const GET_GAAUTHINFO: any = gql`
	query getGaAuthInfo {
		member {
			getGaAuthInfo {
				isHadAuth
				lastAuthAt
			}
		}
	}
`;

export const VALID_OTP: any = gql`
	mutation validOTP($input: ValidOTPRequest!) {
		member {
			validOTP(input: $input) {
				success
				code
				message
			}
		}
	}
`;

export const BIND_OTP: any = gql`
	mutation bindOTP($input: BindOTPRequest!) {
		member {
			bindOTP(input: $input) {
				success
				code
				message
			}
		}
	}
`;

export const REMOVE_OTP: any = gql`
	mutation memberremoveOTP {
		member {	
			removeOTP {
				success
				message
			}
		}
	}
`;

export const CHANGE_PASSWORD: any = gql`
	mutation changePassword($input: PasswordInput!) {
		member {
			password(input: $input) {
				success
				message
			}
		}
	}
`;

export const transactionOrder = gql`
	fragment transactionOrder on Order {
		marketId
		marketName
		eventId
		eventName
		settleNumber
		categoryId
		orderNumber
		exchangeRate
		selectionName
		marketStartTime
		price
		stake
		winlossCredit
		side
		createdAt
		settledAt
		status
		clientRemark
	}
`;

export const myOrder = gql`
	fragment myOrder on Order {
		marketId
		marketName
		eventId
		eventName
		settleNumber
		categoryId
		orderNumber
		exchangeRate
		selectionName
		marketStartTime
		marketType
		price
		stake
		winlossCredit
		side
		createdAt
		updatedAt
		settledAt
		status
		clientRemark
		oddType
		liability
	}
`;

export const myOrderGroup = gql`
	fragment myOrderGroup on OrderGroup {
		matched {
			...myOrder
		}
		unmatched {
			...myOrder
		}
	}
	${myOrder}
`;

export const GET_PROFIT_LOSS = gql`
	query getProfitLoss($input: ProfitLossInput!) {
		exchange {
			profitLoss(input: $input) {
				type
				winloss
				commission
				limitCredit
				availableCredit
				createdAt
				exchangeRate
				order {
					...transactionOrder
				}
				platform
			}
		}
	}
	${transactionOrder}
`;

export const GET_ACCOUNT_STATEMENT = gql`
	# Code Changed - Manoj ( query getAccountStatement )
	query getAccountTransactions($input: TransactionInput!) {
		exchange {
			transactions(input: $input) {
				type
				winloss
				commission
				limitCredit
				availableCredit
				createdAt
				clientRemark
				exchangeRate
				platformExchangeRate
				order {
					...transactionOrder
				}
			}
		}
	}
	${transactionOrder}
`;

export const GET_MY_ORDERS = gql`
	query getMyOrderList($input: MyOrderInput!) {
		exchange {
			myOrders(input: $input) {
				...myOrderGroup
			}
		}
	}

	${myOrderGroup}
`;

export const GET_USERINFO = gql`
	query getUserInfo($input: UserInfoInput!) {
		member {
			userInfo(input: $input) {
				memberId
				username
				isSubAccount
				orderLimit {
					... on OrderLimit {
						eventTypeId
						minOrder
						maxOrder
						maxMarket
					}
				}
				exchangeRate
				type
				betfairInfo {
					... on BetfairInfo {
						categoryId
						betfairPt
					}
				}
			}
		}
	}
`;

export const GET_STATEMENT = gql`
	query gameStatement($input: GameStatementInput!) {
		game {
			statement(input: $input) {
				success
				code
				message
				data {
					url
				}
			}
		}
	}
`;

export const GET_AGENTREPORT = gql`
	query agentReportList($input: ThirdPartyStatementInput!) {
		agentReport {
			thirdPartyOrder(input: $input) {
				totalData
				totalPage
				orderInfo {
					agent
					vendorPlayerName
					wagerId
					platform
					platformId
					roundId
					turnover
					winloss
					gameCode
					gameName
					productTypeId
					vendorBetTime
					vendorSettleTime
					status
					eventId
					yourBet
					# leagueName
					# awayTeam
					# homeTeam
					# lineTypeName
					provider
					providerId
					stake
					# displayRate
					tpDisplayRate
					commission
				}
			}				
		}
	}		
`;
