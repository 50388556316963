import { lighten } from 'polished';
import React, { PureComponent } from 'react';
import styled, { css } from 'styled-components';
import { theme, withProp } from 'styled-tools';

// Components
import { Col, Row } from '../../../components/common/table/baseTableStyles';

// Utils
import media from '../../../util/mediaUtil';
import { isMemberType } from '../../../util/styleUtil';
import util from '../../../util/util';

// Styles
import { titleFont } from '../../common/commonCSSStyles';

// Schema
import { IScoreInfo, IScoreType } from '../../../graphql/schema';

// Header
interface ICricketHeaderProps {
	matchType?: string;
}
const CricketTestHeader: React.SFC = () => {
	return (
		<>
			<CricketTitleCol>
				Inning1
				<InningWrapper>
					<div>Runs</div>
					<div>Wicket</div>
				</InningWrapper>
				<InningWrapperMobile>
					<div>R</div>
					<div>W</div>
				</InningWrapperMobile>
			</CricketTitleCol>
			<CricketTitleCol>
				Inning2
				<InningWrapper>
					{}
					<div>Runs</div>
					<div>Wickets</div>
				</InningWrapper>
				<InningWrapperMobile>
					<div>R</div>
					<div>W</div>
				</InningWrapperMobile>
			</CricketTitleCol>
			<CricketTitleCol>Overs</CricketTitleCol>
		</>
	);
};

const CricketHeader: React.SFC<ICricketHeaderProps> = props => {
	const { matchType } = props;

	return matchType === 'TEST' ? (
		<CricketTestHeader />
	) : (
		<>
			<CricketTitleCol>Runs</CricketTitleCol>
			<CricketTitleCol>Wicket</CricketTitleCol>
			<CricketTitleCol>Overs</CricketTitleCol>
		</>
	);
};

interface IMatchLiveScoreProps {
	scoreInfo: IScoreInfo;
	matchType?: string;
}

class MatchLiveScore extends PureComponent<IMatchLiveScoreProps> {
	render() {
		const { scoreInfo, matchType } = this.props;
		const { categoryId } = scoreInfo;
		const homeScore: IScoreType = scoreInfo.home || ({} as IScoreType);
		const awayScore: IScoreType = scoreInfo.away || ({} as IScoreType);

		return (
			<MatchLiveScoreWrapper>
				<MatchLiveScoreTableWrapper>
					<HeadRow>
						<TitleCol>Selections</TitleCol>
						{categoryId === 1 && (
							// Footbal
							<ScoreCol>Score</ScoreCol>
						)}
						{categoryId === 2 && (
							// Tennis
							<>
								<ScoreCol>Set</ScoreCol>
								<ScoreCol>Game</ScoreCol>
								<ScoreCol>Points</ScoreCol>
							</>
						)}
						{categoryId === 4 && (
							// Cricket

							<CricketHeader matchType={matchType} />
						)}
					</HeadRow>

					<BodyRow>
						<TitleCol>
							<span>{homeScore.name}</span>
						</TitleCol>
						{categoryId === 1 && <ScoreCol>{homeScore.score}</ScoreCol>}
						{categoryId === 2 && (
							<>
								<ScoreCol>{homeScore.sets}</ScoreCol>
								<ScoreCol>{homeScore.games}</ScoreCol>
								<ScoreCol>{homeScore.score}</ScoreCol>
							</>
						)}
						{categoryId === 4 && (
							<>
								<CricketScoreCol>
									<div>{util.getValue(homeScore, 'cricket.inning1.runs', '-')}</div>
									{matchType === 'TEST' ? (
										<div> {util.getValue(homeScore, 'cricket.inning2.runs', '-')}</div>
									) : null}
								</CricketScoreCol>
								<CricketScoreCol>
									<div>{util.getValue(homeScore, 'cricket.inning1.wickets', '-')}</div>
									{matchType === 'TEST' ? (
										<div>{util.getValue(homeScore, 'cricket.inning2.wickets', '-')}</div>
									) : null}
								</CricketScoreCol>
								<CricketScoreCol>
									<div>{util.getValue(homeScore, 'cricket.inning1.overs', '-')}</div>
								</CricketScoreCol>
							</>
						)}
					</BodyRow>
					<BodyRow>
						<TitleCol>
							<span>{awayScore.name}</span>
						</TitleCol>
						{categoryId === 1 && <ScoreCol>{awayScore.score}</ScoreCol>}
						{categoryId === 2 && (
							<>
								<ScoreCol>{awayScore.sets}</ScoreCol>
								<ScoreCol>{awayScore.games}</ScoreCol>
								<ScoreCol>{awayScore.score}</ScoreCol>
							</>
						)}
						{categoryId === 4 && (
							<>
								<CricketScoreCol>
									<div>{util.getValue(awayScore, 'cricket.inning1.runs', '-')}</div>
									{matchType === 'TEST' ? (
										<div>{util.getValue(awayScore, 'cricket.inning2.runs', '-')}</div>
									) : null}
								</CricketScoreCol>
								<CricketScoreCol>
									<div>{util.getValue(awayScore, 'cricket.inning1.wickets', '-')}</div>
									{matchType === 'TEST' ? (
										<div>{util.getValue(awayScore, 'cricket.inning2.wickets', '-')}</div>
									) : null}
								</CricketScoreCol>
								<CricketScoreCol>
									<div>{util.getValue(awayScore, 'cricket.inning1.overs', '-')}</div>
								</CricketScoreCol>
							</>
						)}
					</BodyRow>
				</MatchLiveScoreTableWrapper>
			</MatchLiveScoreWrapper>
		);
	}
}

export default MatchLiveScore;

// Styled Components
const TitleCol = styled(Col)`
	flex-grow: 1;
	align-items: center;
	min-width: 0;
	> span {
		white-space: nowrap;
		overflow: hidden;
		text-overflow: ellipsis;
	}
`;

const ScoreCol = styled(Col)`
	width: 96px;
	flex-shrink: 0;
	justify-content: center;
	border-left: 1px solid rgba(128, 128, 128, 0.2);

	&:first-of-type {
		margin-left: auto;
	}

	${media.sm`
		width: 64px;
	`};
`;

// cricket title column
const CricketTitleCol = styled(Col)`
	display: flex;
	flex-direction: column;
	align-items: center;
	width: 128px;
	flex-shrink: 0;
	justify-content: center;
	border-left: 1px solid rgba(128, 128, 128, 0.2);

	&:first-of-type {
		margin-left: auto;
	}

	${media.lg`
		width: 108px;
	`};

	${media.sm`
		width: 70px;
		padding-bottom:0.25em;
	`};
`;
// Inning tiltle wrapper
const InningWrapper = styled.div`
	display: flex;
	align-items: center;
	font-size: 9px;
	color: ${withProp('theme.palette.sidebar-heading', lighten(0.08))};
	> div {
		margin: 2px 6px;
	}
	${media.lg`
		> div {
			margin: 2px 2px 0px;
		}
	`};

	${media.sm`
		display: none;
	`};
`;
// Inner tittle wrapper when sm size
const InningWrapperMobile = styled(InningWrapper)`
	display: none;

	${media.sm`
		display: flex;
		justify-content: space-around;
		width: 100%;

		> div {
			font-size: 7px;
		}
	`};
`;
// cricket score column
const CricketScoreCol = styled(ScoreCol)`
	width: 128px;
	justify-content: space-around;

	> div {
		margin: 0px 3px;
	}

	${media.lg`
		width: 108px;

		> div {
		margin: 0px 8px;
	}
	`};

	${media.sm`
		width:70px;

		> div {
			margin: 0px 6px;
		}
	`};
`;

const HeadRow = styled(Row)`
	${titleFont};

	${isMemberType(
		'CLIENT',
		css`
			background-color: ${withProp('theme.palette.sidebar-background', lighten(0.075))};
			color: ${withProp('theme.palette.sidebar-heading', lighten(0.075))};
			&:first-child {
				color: ${theme('palette.sidebar-heading')};
			}
		`,
		css`
			background-color: ${theme('palette.sidebar-background')};
			color: ${theme('palette.primary-background')};
		`,
	)};

	${media.sm`
		font-size: 12px;
	`};
`;

const BodyRow: any = styled(Row)`
	background-color: ${theme('palette.panel-background')};
	border-bottom: 1px solid rgba(128, 128, 128, 0.2);
	font-size: 12px;
`;

const MatchLiveScoreTableWrapper = styled.div`
	display: flex;
	flex-direction: column;
	width: 100%;
	font-size: 14px;
	z-index: 0;
`;

const MatchLiveScoreWrapper = styled.div`
	color: ${theme('palette.text-dark')};
`;
