import React from 'react'
import { Link, useLocation, useParams } from 'react-router-dom'
import { useState, useEffect } from 'react';
import { Breadcrumbs } from 'src/components/udb/commonStyle';
import Intl from "src/components/common/Intl";
import { string } from 'yup';
import styled, { withTheme } from 'styled-components';
import { theme } from 'styled-tools';


const labels = {
  home: "BreadCrumb@Homepage",
  member: "SIDEBAR@MYDASHBOARD",
  profile: "SIDEBAR@PROFILE",
  accountinfo: "SIDEBAR@ACCOUNTINFO",
  bankdetails: "SIDEBAR@BANKDETAIL",
  upiform: "SIDEBAR@UPIDETAIL",
  bankaccountform: "SIDEBAR@BANKDETAIL",
  password: "SIDEBAR@PASSWORD",
  kycpage: "SIDEBAR@KYCVERIFICATION",
  linksocialmedia: "SIDEBAR@Linksocial",
  finance: "SIDEBAR@FINANCES",
  deposit: "SIDEBAR@Deposit",
  withdrawal: "SIDEBAR@Withdrawal",
  betDetails: "SIDEBAR@BETDETAIL",
  mybets: "SIDEBAR@MyBets",
  profitandloss: "SIDEBR@ProfitLoss",
  casinostatement: "SIDEBAR@Casino",
  bonus: "SIDEBAR@Bonus",
  bonusDetails: "SIDEBAR@Bonusdetails",
  history: "SIDEBAR@History",
  sportbookstatement: "SIDEBAR@SPORTBOOK_STATEMENT",
  transfer: "DASHBOARD@Transfer",
  accountStatement: "SIDEBAR@ACCOUNTSTATEMENT",
  message: "SIDEBAR@Msg",
  affiliate: "DASHBOARD@AFFILIATE",
  promotions: "SIDEBAR@PROMOTIONS",
  virtualSportsStatement : "SIDEBAR@VIRTUALSPORTSTATEMENT",
  turnoverStatement : "SIDEBAR@TurnoverStatement",
  premimSportsBookStatement: "SIDEBAR@PREMIUM_SPORTBOOK_STATEMENT",
  splitThePotStatement: "SIDEBAR@STP",
  yoloAppDownload: "page@yoloAppDownload",
  premiumSportsBookStatement:"SIDEBAR@PREMIUM_SPORTBOOK_STATEMENT",
  galaxyStatement:"SIDEBAR@GALAXY",
  aviatrixStatement:'SIDEBAR@AVIATRIX',
  evoplayStatement:'SIDEBAR@EVOPLAY',
  jiliStatement:'SIDEBAR@JILI',
  turbogamesStatement:'SIDEBAR@TURBOGAMES',
  sabaSportsStatement:'SIDEBAR@SabaSports',
  indiaLotteryStatement:'SIDEBAR@INDIALOTTERY',
}

export const getUrl = (pathName: string, param: object) => {
  const paramsKey = Object.keys(param);
  let path = pathName;
  if (paramsKey.length) {
    path = paramsKey.reduce((curr, prev) => {
      let _id = param[prev]
      if (curr.includes(_id)) {
        return curr.replace(_id, '')
      }
      return curr;
    }, pathName);
  }
  return path;
}

function Breadcrumb(props) {
  const { theme } = props;
  const { pathname } = useLocation();
  const params = useParams();
  const [pathArr, setpathArr] = useState([]);
  useEffect(() => {
    let path = getUrl(pathname, params);
    let links = path.split("/").filter((elm) => elm);
    setpathArr(links);
  }, [pathname]);

  return (
    <Breadcrumbs>
      <Link to={'/'}>{<Intl langKey={labels['home']} />}</Link>&nbsp;&gt;&nbsp;
      {
        pathArr.map((elm, i) => {
          if ((pathArr.length - 1) === i) { return <LableText key={i}>{<Intl langKey={labels[elm]} />}</LableText> }
          else {
            let prevArr = pathArr.slice(0, i).join('/');
            return <React.Fragment key={i}><Link to={`/${prevArr ? prevArr + '/' : ''}${elm}`}>{<Intl langKey={labels[elm]} />}</Link>&nbsp;&gt;&nbsp;</React.Fragment>
          }
        })
      }
    </Breadcrumbs>
  );
}

export default withTheme(Breadcrumb)

// Styled Component

const LableText = styled.span`
  width: auto;
  color: ${theme('palette.betDetailsLabelText')} !important;
`;