import React from 'react';
import styled, { css, keyframes } from 'styled-components';
import { switchProp, theme } from 'styled-tools';
import config from '../../config/config';
import { withAppContext } from '../../store/initAppContext';
import LoadingSpinner from '../../images/Loadergif.gif'

interface ISpinnerProps {
	mode: 'client' | 'agent' | 'dark';
	isWholePage?: boolean;
	app: {
		type: string;
	};
}

const Spinner = (props: ISpinnerProps) => {
	const { isWholePage = false, app } = props;
	const { type } = app;
	// let mode = type === 'CLIENT' ? 'client' : 'agent';
	let mode = 'client';
	switch (type) {
		case 'COMPANY_ADMIN':
			mode = `agent`;
			break;
		case 'CLIENT':
			mode = `client`;
			break;
		default:
			mode = `agent`;
			break;
	}

	return (
		<SpinnerWrapper isWholePage={isWholePage}>
			<SpinnerLogo mode={mode} type={type} />
		</SpinnerWrapper>
	);
};

export default withAppContext(Spinner);

export const Ring: React.SFC<ISpinnerProps> = props => {
	const { mode } = props;
	return (
		<RingWrapper mode={mode}>
			{[...Array(4)].map((_, i) => (
				<div key={i} />
			))}
		</RingWrapper>
	);
};

// Styled components

const RingKeyFrame = keyframes`
	0% {
		transform: rotate(0deg);
	}
	100% {
		transform: rotate(360deg);
	}
`;

const RingWrapper: any = styled.div`
	display: inline-block;
	position: relative;
	width: 32px;
	height: 32px;

	& div {
		color: ${switchProp('mode', {
	light: css`
				${theme('palette.primary-background')};
			`,
	dark: '#fff',
})};
		box-sizing: border-box;
		display: block;
		position: absolute;
		width: 30px;
		height: 30px;
		margin: 6px;
		border: 3px solid currentColor;
		border-radius: 50%;
		animation: ${RingKeyFrame} 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
		border-color: currentColor transparent transparent transparent;
	}
	& div:nth-child(1) {
		animation-delay: -0.45s;
	}
	& div:nth-child(2) {
		animation-delay: -0.3s;
	}
	& div:nth-child(3) {
		animation-delay: -0.15s;
	}
`;

export const SpinnerLogo: any = styled.img.attrs((props: any) => {
	const mode = props.mode;
	const view = props.theme.view;
	const image = props.theme.image;

	return {
		// Code Changed - Manoj ( src made empty)
		// src: `${LoadingSpinner}`,
		src: `${config.imageV3NonOptHost}/static/${view}${image[`loading-spinner-${mode}`]}`,
		// src: ``,
	};
})`
	z-index: 1;
	width: 120px !important;
	height: auto;
`;

const Spin = keyframes`
	from {
		transform: rotate(0deg);
	}
	to {
		transform: rotate(360deg);
	}
`;

export const SpinnerCircle: any = styled.div`
	position: relative;
	width: 24px;
	height: 24px;
	margin: 32px;

	&::before,
	&::after {
		content: '';
		display: block;
		position: absolute;
		top: 0;
		right: 0;
		bottom: 0;
		left: 0;
		border-radius: 50%;
		border: 2px solid
			${switchProp(
	'mode',
	{
		light: 'hsl(0, 0%, 50%)',
		dark: '#fff',
	},
	'light',
)};
		border-top-color: transparent;
	}

	&::before {
		animation: ${Spin} 0.75s infinite linear;
	}

	&::after {
		animation: ${Spin} 1.5s infinite ease;
	}
`;

export const SpinnerWrapper: any = styled.div`
	position: ${(props: any) => (props.isWholePage ? 'fixed' : 'absolute')};
	z-index: 10000;
	top: 0;
	right: 0;
	bottom: 0;
	left: 0;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	height: 100vh;

	&::before {
		content: '';
		display: block;
		position: absolute;
		z-index: -1;
		top: 0;
		right: 0;
		bottom: 0;
		left: 0;
		background-color: rgba(128, 128, 128, 0.2);
	}
`;
