import React from 'react'

type Props = {}

const Step1Icon = (props: Props) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 48 48"><circle cx="24" cy="24" r="24" fill="url(#a)"/><g clip-path="url(#b)"><path fill="#fff" d="M26.274 32.735A2.27 2.27 0 0 1 24 35a2.27 2.27 0 0 1-2.274-2.265A2.27 2.27 0 0 1 24 30.471a2.27 2.27 0 0 1 2.274 2.264Zm4.118-3.72c.49-.49.49-1.282 0-1.77a9.01 9.01 0 0 0-2.873-1.93 9.014 9.014 0 0 0-3.52-.708c-1.219 0-2.403.238-3.518.708a9.01 9.01 0 0 0-2.873 1.93 1.248 1.248 0 0 0 0 1.77 1.26 1.26 0 0 0 1.776 0A6.497 6.497 0 0 1 24 27.11c1.743 0 3.382.676 4.615 1.904.245.245.567.367.889.367.321 0 .642-.122.888-.367ZM15.265 24.91A12.297 12.297 0 0 1 24 21.306c3.3 0 6.402 1.28 8.735 3.604a1.26 1.26 0 0 0 1.777 0c.49-.488.49-1.28 0-1.77a14.958 14.958 0 0 0-4.725-3.172A14.826 14.826 0 0 0 24 18.804a14.827 14.827 0 0 0-8.313 2.53 14.95 14.95 0 0 0-2.199 1.807 1.248 1.248 0 0 0 0 1.77c.245.244.567.366.888.366.322 0 .643-.122.889-.367Zm23.367-4.104c.49-.488.49-1.28 0-1.77a20.73 20.73 0 0 0-6.577-4.417A20.662 20.662 0 0 0 24 13.001a20.66 20.66 0 0 0-14.632 6.037 1.248 1.248 0 0 0 0 1.77 1.26 1.26 0 0 0 1.777 0c3.433-3.42 7.999-5.304 12.855-5.304 4.856 0 9.422 1.883 12.855 5.303.246.245.567.367.889.367.321 0 .643-.122.888-.366Z"/></g><defs><linearGradient id="a" x1="24" x2="24" y1="0" y2="48" gradientUnits="userSpaceOnUse"><stop stop-color="#6765E0"/><stop offset="1" stop-color="#322DB8"/></linearGradient><clipPath id="b"><path fill="#fff" d="M9 13h30v22H9z"/></clipPath></defs></svg>
  )
}

export const FomoStep1Icon = (props: Props) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 48 48"><circle cx="24" cy="24" r="24" fill="url(#a)"/><g clip-path="url(#b)"><path fill="#fff" d="M26.274 32.735A2.27 2.27 0 0 1 24 35a2.27 2.27 0 0 1-2.274-2.265A2.27 2.27 0 0 1 24 30.471a2.27 2.27 0 0 1 2.274 2.264Zm4.118-3.72c.49-.49.49-1.282 0-1.77a9.01 9.01 0 0 0-2.873-1.93 9.014 9.014 0 0 0-3.52-.708c-1.219 0-2.403.238-3.518.708a9.01 9.01 0 0 0-2.873 1.93 1.248 1.248 0 0 0 0 1.77 1.26 1.26 0 0 0 1.776 0A6.497 6.497 0 0 1 24 27.11c1.743 0 3.382.676 4.615 1.904.245.245.567.367.889.367.321 0 .642-.122.888-.367ZM15.265 24.91A12.297 12.297 0 0 1 24 21.306c3.3 0 6.402 1.28 8.735 3.604a1.26 1.26 0 0 0 1.777 0c.49-.488.49-1.28 0-1.77a14.958 14.958 0 0 0-4.725-3.172A14.826 14.826 0 0 0 24 18.804a14.827 14.827 0 0 0-8.313 2.53 14.95 14.95 0 0 0-2.199 1.807 1.248 1.248 0 0 0 0 1.77c.245.244.567.366.888.366.322 0 .643-.122.889-.367Zm23.367-4.104c.49-.488.49-1.28 0-1.77a20.73 20.73 0 0 0-6.577-4.417A20.662 20.662 0 0 0 24 13.001a20.66 20.66 0 0 0-14.632 6.037 1.248 1.248 0 0 0 0 1.77 1.26 1.26 0 0 0 1.777 0c3.433-3.42 7.999-5.304 12.855-5.304 4.856 0 9.422 1.883 12.855 5.303.246.245.567.367.889.367.321 0 .643-.122.888-.366Z"/></g><defs><linearGradient id="a" x1="24" x2="24" y1="0" y2="48" gradientUnits="userSpaceOnUse"><stop stop-color="#FF8351" /><stop offset="0.479533" stop-color="#FF5F1F" /><stop offset="1" stop-color="#BA3500" /></linearGradient><clipPath id="b"><path fill="#fff" d="M9 13h30v22H9z"/></clipPath></defs></svg>
  )
}

export default Step1Icon;