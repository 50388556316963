import React, { useEffect } from 'react'
import { withAppContext } from 'src/store/initAppContext';
import styled, { withTheme } from 'styled-components';
import { LazyLoadImage } from "react-lazy-load-image-component";
import config from "src/config/config";
import PlaceholderImage from 'src/components/Placeholder';
import { theme } from 'styled-tools';
import FooterContainer from 'src/components/footer/footer';
import { Link } from 'react-router-dom';
import TickYellowIcon from 'src/components/icon/TickYellowIcon';

function GalaxsysTournament(props: any) {
    const { app: {
        brandId
    }, theme } = props;

    useEffect(() => {
        window.scrollTo(0, 0)
    }, [])


    return (
        <>
            <InformationWrapper>
                  <TopContentWrap>
                       <p className='mb-10'><span>Get Ready for the Ultimate Showdown!</span></p>
                       <p className='mb-10'>
                          Brace yourself for an electrifying tournament, running from <strong>March 15 - May 31</strong>, where excitement meets massive rewards! 
                          With a <br /> whopping <span>₹45,00,000</span> prize pool and <strong>520 winners</strong>, every bet brings you closer to victory.
                       </p>
                       <p className='mt-10'>
                         Play, compete, and claim your share -<strong>the more you play, the bigger the rewards!</strong>
                       </p>
                  </TopContentWrap>
                  <HTCSecWrap>
                             <LazyLoadImage
                                src={`${config.imageV3Host}/static/cashsite/${theme.image.galaxsysTourFirstIllus}`}
                                alt="Eligible Games"
                                effect="none"
                                placeholder={<PlaceholderImage />}
                            />
                  </HTCSecWrap>
                  <HTCSmallSecs>
                        <HTCSmallBox>
                             <LazyLoadImage
                                src={`${config.imageV3Host}/static/cashsite/${theme.image.galaxsysTour02Illus}`}
                                alt="Eligible Games"
                                effect="none"
                                placeholder={<PlaceholderImage />}
                            />
                            <p>
                                Play more, climb higher, and grab your share of ₹45,00,000!
                            </p>
                        </HTCSmallBox>
                        <HTCSmallBox>
                             <LazyLoadImage
                                src={`${config.imageV3Host}/static/cashsite/${theme.image.galaxsysTour03Illus}`}
                                alt="Eligible Games"
                                effect="none"
                                placeholder={<PlaceholderImage />}
                            />
                            <p>
                               The race to the top starts March 15
                            </p>
                        </HTCSmallBox>
                  </HTCSmallSecs>

                  <AYITitle><Title>Are you in?</Title></AYITitle>
                  
                  <PrizeDetailsWrap>
                       <h3>Tournament Breakdown</h3>
                       <p>The competition is split into <strong> 6 batches</strong>, each with its own prize pool:</p>
                       <PrizeBoxWrap>
                             <LazyLoadImage
                                src={`${config.imageV3Host}/static/cashsite/${theme.image.galaxsysTourPrizeTable01}`}
                                alt="prize"
                                effect="none"
                                placeholder={<PlaceholderImage />}
                            />
                             <LazyLoadImage
                                src={`${config.imageV3Host}/static/cashsite/${theme.image.galaxsysTourPrizeTable02}`}
                                alt="prize"
                                effect="none"
                                placeholder={<PlaceholderImage />}
                            />
                             <LazyLoadImage
                                src={`${config.imageV3Host}/static/cashsite/${theme.image.galaxsysTourPrizeTable03}`}
                                alt="prize"
                                effect="none"
                                placeholder={<PlaceholderImage />}
                            />
                             <LazyLoadImage
                                src={`${config.imageV3Host}/static/cashsite/${theme.image.galaxsysTourPrizeTable04}`}
                                alt="prize"
                                effect="none"
                                placeholder={<PlaceholderImage />}
                            />
                             <LazyLoadImage
                                src={`${config.imageV3Host}/static/cashsite/${theme.image.galaxsysTourPrizeTable05}`}
                                alt="prize"
                                effect="none"
                                placeholder={<PlaceholderImage />}
                            />
                             <LazyLoadImage
                                src={`${config.imageV3Host}/static/cashsite/${theme.image.galaxsysTourPrizeTable06}`}
                                alt="prize"
                                effect="none"
                                placeholder={<PlaceholderImage />}
                            />
                       </PrizeBoxWrap>
                  </PrizeDetailsWrap>

                  <TermsAndConditionWrap>
                      <Title className='textCenter'>Terms & Conditions</Title>
                      <ol>
                        <li>The Galaxsys Tournament promotion is valid for all new and existing players.</li>
                        <li>Multiple accounts will not be eligible for this promotion. All players will undergo duplicate checking.</li>
                        <li>FUN88 reserves the right to disqualify players involved in collusion or any foul play related to this promotion.</li>
                        <li>FUN88 reserves the right to amend, cancel, reclaim, or refuse any promotion at its discretion.</li>
                        <li>FUN88 General Terms & Conditions apply.</li>
                      </ol>
                  </TermsAndConditionWrap>
            </InformationWrapper>
            <FooterContainer />
        </>
    )
}

export default withTheme(withAppContext(GalaxsysTournament));


// Styled Component.
const InformationWrapper = styled.div`
    width: 98%;
    height: auto;
    padding: 4rem 0rem 4rem 0rem;
    background: rgba(111, 111, 111, 0.5);
    margin: 0em 1.2em 0 1.2em;
    border: 1px solid #fff;
    border-top-color: transparent;
    backdrop-filter: brightness(0.5);    
    display: flex;
    flex-direction: column;
    background-color: #ebebeb;


    .mb-10 {
       margin-bottom: 20px;
    }

    .mt-10 {
       margin-top: 20px;
    }

    .textCenter {
        text-align: center;
        width: 100%;
    }
`;

const Title = styled.h2`
    color: #06A6FF;
    text-align: center;
    font-size: 25px !important;
    font-style: normal;
    font-weight: 700 !important;
    line-height: 179.5%; /* 50.26px */
    margin: 2px 0px;
`


const TopContentWrap = styled.div`
  width: 90%;
  margin: auto;
  height: auto;
  padding: 10px 0px;
  border-radius: 11.188px;
  background: #FFF;


  & h2 {
    color: ${theme('palette.galaxyTourText')};
    text-align: center;
    font-size: 25px;
    font-style: normal;
    font-weight: 700;
    line-height: 179.5%; /* 50.26px */
    text-transform: capitalize;
    margin: 2px 0px;
  }

  & p {
    color: #444;
    text-align: center;
    font-size: 17px;
    font-style: normal;
    font-weight: 500;
    line-height: 150%; /* 27px */
    text-transform: capitalize;
    margin: 2px 0px;

    & span {
      color: #444;
      font-weight: 700;
    }
  }
`

const HTCSecWrap = styled.div`
  display: flex;
  justify-content: center;
  gap: 5px;
  margin: 4em auto 2em auto;
  width: 90%;
  height: auto;
  border-radius: 11.188px;
  background: #FFF;
  padding: 20px 0px 50px 0px;

  & span {
    width: 90%;

    & img {
        width: 100%;
    }
  }
`


const HTCSmallSecs = styled.div`
  width: 90%;
  margin: auto;
  height: auto;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 3rem;
  gap: 15px;
  border-radius: 11.188px;
  background: #FFF;
  padding: 5rem 0 3rem 0px;
`

const HTCSmallBox = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 200px;
  height: 170px;
  border-radius: 20px;
  border: 0.47px solid #06A6FF;
  background: #06A6FF;
  justify-content: center;
  padding: 10px;
 

  & img {
    width: 85%;
    height: auto;
    object-fit: contain;
    margin-top: -90px;
  }

  & p {
    color: #FFF;
    text-align: center;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 145%; /* 23.2px */
    text-transform: capitalize;
    margin: 2px 0px;
  }
`

const AYITitle = styled.div`
  margin: 3rem auto 2rem auto;
  width: 90%;
  height: auto;
  border-right: none;
  border-left: none;
  border-radius: 11.188px;
  background: #FFF;
  padding: 10px 0px;
  text-transform: none;
`

const PrizeDetailsWrap = styled.div`
  width: 90%;
  margin: auto;
  height: auto;
  border-radius: 11.188px;
  background: #FFF;
  padding: 2rem 0 1rem 0;

  h3 {
    color: #06A6FF;
    text-align: center;
    font-size: 24px;
    font-style: normal;
    font-weight: 800;
    line-height: 179.5%; /* 50.26px */
    text-transform: capitalize;
    margin: 1px 0px;
  }

  & p {
    color: #444;
    text-align: center;
    font-size: 18px;
    font-style: normal;
    font-weight: 500;
    line-height: 150%; /* 27px */
    text-transform: capitalize;
    margin: 1px 0px;
  }
`

const PrizeBoxWrap = styled.div`
  margin: 2rem 0 2rem 0;
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  justify-content: center;
  gap: 20px;

  & span {
    width: 45%;
    height: auto;
    margin: 2em 0;

    & img {
      width: 100%;
    }
  }
`

const TermsAndConditionWrap = styled.div`
  width: 90%;
  margin: 4rem auto 2rem auto;
  height: auto;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 15px 10px;
  border-radius: 11.188px;
  background: #FFF;

  & ol {
    padding-left: 70px;

    & li {
        color: #444;
        text-align: justify;
        font-size: 18px;
        font-style: normal;
        font-weight: 400;
        line-height: 175%;
        text-transform: capitalize;
        margin: 5px 0px;
    }
  }
`