import React, { useEffect } from 'react';
import { Transition } from 'react-transition-group';
import styled, { css } from 'styled-components';
import { ifProp, switchProp, theme, withProp } from 'styled-tools';

// Dependent Components
import ClickOutside from './ClickOutside';
import { shadowSM } from './commonCSSStyles';
import Openable from './Openable';
import Icon, { IconWrapper } from './PNGIcon';
import ViewportMask from './ViewportMask';
import DownArrow from "src/images/downarrow_blue.png";
// import { CountWrapper } from 'src/components/eu-content/order/orderslip/Matchedslip';

// Util
import { diffColor } from '../../util/colorUtil';
import media from '../../util/mediaUtil';

export type DisplayInfo = {
	displayName: string;
	[key: string]: any;
};

export type Option = {
	value: any;
	displayInfo: DisplayInfo;
};

interface ISelectProps {
	optionList: Option[];
	placeholder: string;
	isDisabled?: boolean;
	defaultOptionIndex?: number;
	// set the option value elsewhere
	propagate: (value: any) => void;
	currentValue: any;
	SelectDisplayComponent?: React.SFC<DisplayInfo>;
	OptionDisplayComponent?: React.SFC<DisplayInfo>;
	id?: string;
	type?:any
}

const Select: React.SFC<ISelectProps> = props => {
	const {
		optionList,
		placeholder = 'select',
		defaultOptionIndex,
		isDisabled,
		currentValue,
		propagate,
		SelectDisplayComponent,
		OptionDisplayComponent,
		id,
	} = props;
	const selectedOption = optionList.find(option => option.value === currentValue);

	useEffect(() => {
		typeof defaultOptionIndex === 'number' && propagate(optionList[defaultOptionIndex].value);
	}, [defaultOptionIndex]);

	return (
		<SelectWrapper>
			<Openable>
				{openableStore => {
					const { isOpened, toggleOpen } = openableStore;
					return (
						<>
							{isOpened && <ViewportMask />}
							<ClickOutside callback={toggleOpen} shouldTriggerCallback={isOpened}>
								{containerRef => {
									return (
										<SelectButtonWrapper
											onClick={toggleOpen}
											ref={containerRef}
											isDisabled={isDisabled}
											id={id}
										>
											{selectedOption ? (
												typeof SelectDisplayComponent === 'function' ? (
													<SelectDisplayComponent {...selectedOption.displayInfo} />
												) : (
													selectedOption.displayInfo.displayName
												)
											) : (
												placeholder
											)}
											<SelectionIconWrapper isActive={isOpened}>
												{/* <Icon name="chevron-dropdown" /> */}
												<img src={DownArrow} alt="DownArrow" />
											</SelectionIconWrapper>
										</SelectButtonWrapper>
									);
								}}
							</ClickOutside>
							<Transition in={isOpened} timeout={{ enter: 50, exit: 100 }}>
								{transitionStatus => {
									return (
										<TransitionWrapper transitionStatus={transitionStatus}>
											<OptionListWrapper>
												{optionList.map((option, i) => {
													const {
														value: optionValue,
														displayInfo: { displayName: optionDisplayName },
													} = option;
													return (
														<OptionWrapper
															onClick={() => propagate(optionValue)}
															key={`option-${optionValue}-${i}`}
														>
															{typeof OptionDisplayComponent === 'function' ? (
																<OptionDisplayComponent {...option.displayInfo} />
															) : (
																optionDisplayName
															)}
														</OptionWrapper>
													);
												})}
											</OptionListWrapper>
										</TransitionWrapper>
									);
								}}
							</Transition>
						</>
					);
				}}
			</Openable>
		</SelectWrapper>
	);
};

export default Select;

// Styled Components

const OptionWrapper = styled.div`
	font-size: 12px;
	padding: 1em 0.5em;
	cursor: pointer;
	position: relative;

	&::before {
		content: '';
		display: block;
		position: absolute;
		top: 0;
		bottom: 0;
		left: 0;
		width: 2px;
		background-color: ${theme('palette.primary-background')};
		opacity: 0;
		transition: opacity 0.2s ease;
	}

	&:hover {
		&::before {
			opacity: 1;
		}
	}

	&:nth-of-type(2n) {
		background-color: ${withProp('theme.palette.panel-background', diffColor(0.05))};
	}
`;

const OptionListWrapper = styled.div`
	${shadowSM};
	background-color: ${theme('palette.panel-background')};
	border-radius: 2px;
	width: 100%;
	overflow: hidden;
	background-color: #fff;
`;

const TransitionWrapper: any = styled.div`
	width: 100%;
	position: absolute;
	z-index: 1;
	top: 100%;
	left: 0;
	transition: all 0.25s ease;

	${switchProp('transitionStatus', {
		exiting: css`
			display: block;
			opacity: 0;
		`,
		exited: css`
			display: none;
			opacity: 0;
		`,
		entering: css`
			display: block;
			opacity: 0;
		`,
		entered: css`
			display: block;
			opacity: 1;
			transform: translateY(0.5em);
		`,
	})};
`;

export const SelectionIconWrapper: any = styled.div`
	flex-shrink: 0;

	transition: transform 0.25s ease;
	transform: ${ifProp(
		'isActive',
		css`
		rotate(-180deg);
	`,
		css`
		rotate(0);
	`,
	)};
	${IconWrapper} {
		margin-left: 3px;
	}

	img {
		width: 10px;
		height: 7px;
	}
`;

const SelectButtonWrapper: any = styled.button.attrs(props => ({
	type: 'button',
}))`
	border-radius: 2px;
	font-size: 12px;
	background-color: ${theme('palette.panel-background')};
	color: ${theme('palette.panel-foreground')};
	display: flex;
	align-items: center;
	padding: 0.5em 1em;
	border: 1px solid ${withProp('theme.palette.panel-background', diffColor(0.1))};
	cursor: pointer;
	width: 100%;

	// {
	// 	width: 14px;
	// 	height: 14px;
	// 	background-color: #E71F52;
	// 	color: #fff;
	// 	display: flex;
	// 	align-items: center;
	// 	justify-content: center;
	// 	margin: 0px 5px;
	// }

	* {
		pointer-events: none;
	}

	&:focus {
		outline: 0;
		box-shadow: 0 0 5px ${theme('palette.primary-background')};
		border-color: transparent;
	}

	${ifProp(
		'isDisabled',
		css`
			pointer-events: none;
			cursor: not-allowed;
			opacity: 0.65;
			${SelectionIconWrapper} {
				filter: grayscale(80%);
				opacity: 0.5;
			}
		`,
	)};

	${media.md`
	padding: 0.75em 1em;
	`};
`;

// Wrapping select button & option list
const SelectWrapper = styled.div`
	position: relative;
`;