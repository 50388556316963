import React from 'react'

type Props = {}

export default function SmartPhone(props) {
  return (
    <svg viewBox="0 0 21 21" fill="none" xmlns="">
<path fill-rule="evenodd" clip-rule="evenodd" d="M3.71875 2.625C3.71875 1.29607 4.79607 0.21875 6.125 0.21875H14.875C16.2039 0.21875 17.2812 1.29607 17.2812 2.625V18.375C17.2812 19.704 16.2039 20.7812 14.875 20.7812H6.125C4.79607 20.7812 3.71875 19.704 3.71875 18.375V2.625ZM6.125 1.53125C5.52093 1.53125 5.03125 2.02093 5.03125 2.625V18.375C5.03125 18.9791 5.52093 19.4688 6.125 19.4688H14.875C15.4791 19.4688 15.9688 18.9791 15.9688 18.375V2.625C15.9688 2.02093 15.4791 1.53125 14.875 1.53125H6.125Z" fill="white"/>
<path fill-rule="evenodd" clip-rule="evenodd" d="M6.44176 0.529989C6.56134 0.336515 6.77256 0.21875 7 0.21875H14C14.2274 0.21875 14.4386 0.336515 14.5583 0.529989C14.6778 0.723462 14.6887 0.965055 14.587 1.16848L13.9538 2.43479C13.6945 2.95356 13.1642 3.28125 12.5843 3.28125H8.41578C7.83578 3.28125 7.30557 2.95356 7.04619 2.43479L6.41303 1.16848C6.31131 0.965055 6.32219 0.723462 6.44176 0.529989ZM8.06184 1.53125L8.22013 1.84782C8.25718 1.92194 8.33292 1.96875 8.41578 1.96875H12.5843C12.6671 1.96875 12.7428 1.92194 12.7799 1.84782L12.9382 1.53125H8.06184ZM8.09375 17.5C8.09375 17.1376 8.38757 16.8438 8.75 16.8438H12.25C12.6124 16.8438 12.9063 17.1376 12.9063 17.5C12.9063 17.8624 12.6124 18.1562 12.25 18.1562H8.75C8.38757 18.1562 8.09375 17.8624 8.09375 17.5Z" fill="white"/>
</svg>
  )
}


export  function SmartPhonef88(props) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="21" height="22" viewBox="0 0 21 22" fill="none">
    <g clip-path="url(#clip0_2406_15206)">
    <path fill-rule="evenodd" clip-rule="evenodd" d="M3.71875 2.73438C3.71875 1.40544 4.79607 0.328125 6.125 0.328125H14.875C16.2039 0.328125 17.2812 1.40544 17.2812 2.73438V18.4844C17.2812 19.8133 16.2039 20.8906 14.875 20.8906H6.125C4.79607 20.8906 3.71875 19.8133 3.71875 18.4844V2.73438ZM6.125 1.64062C5.52093 1.64062 5.03125 2.13031 5.03125 2.73438V18.4844C5.03125 19.0885 5.52093 19.5781 6.125 19.5781H14.875C15.4791 19.5781 15.9688 19.0885 15.9688 18.4844V2.73438C15.9688 2.13031 15.4791 1.64062 14.875 1.64062H6.125Z" fill="black"/>
    <path fill-rule="evenodd" clip-rule="evenodd" d="M6.44176 0.639364C6.56134 0.44589 6.77256 0.328125 7 0.328125H14C14.2274 0.328125 14.4386 0.44589 14.5583 0.639364C14.6778 0.832837 14.6887 1.07443 14.587 1.27786L13.9538 2.54417C13.6945 3.06294 13.1642 3.39062 12.5843 3.39062H8.41578C7.83578 3.39062 7.30557 3.06294 7.04619 2.54417L6.41303 1.27786C6.31131 1.07443 6.32219 0.832837 6.44176 0.639364ZM8.06184 1.64062L8.22013 1.9572C8.25718 2.03131 8.33292 2.07812 8.41578 2.07812H12.5843C12.6671 2.07812 12.7428 2.03131 12.7799 1.9572L12.9382 1.64062H8.06184ZM8.09375 17.6094C8.09375 17.247 8.38757 16.9531 8.75 16.9531H12.25C12.6124 16.9531 12.9063 17.247 12.9063 17.6094C12.9063 17.9718 12.6124 18.2656 12.25 18.2656H8.75C8.38757 18.2656 8.09375 17.9718 8.09375 17.6094Z" fill="black"/>
    </g>
    <defs>
    <clipPath id="clip0_2406_15206">
    <rect width="21" height="21" fill="white" transform="translate(0 0.109375)"/>
    </clipPath>
    </defs>
    </svg>
  )
}