import React from 'react'

type Props = {}

const TurnoverIcon = (props: Props) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="12" height="13" viewBox="0 0 12 13" fill="none">
       <circle cx="5.95679" cy="6.45239" r="5.27603" stroke="#FFDDA6" stroke-width="0.8"/>
       <circle cx="5.95679" cy="6.4519" r="3.53687" fill="#FFDDA6"/>
    </svg>
  )
}

export default TurnoverIcon