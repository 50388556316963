import React from 'react';
import { Accordion, AccordionWrapper } from '../seo-content/seo_content';
import { withTheme } from 'styled-components';
import { withAppContext } from 'src/store/initAppContext';
import { Link } from 'react-router-dom';

type Props = {
    app: any;
};

const BetgamesSEO46 = (props: Props) => {
    const { app: { brandId } } = props;
    return (
        <>
            <AccordionWrapper style={{ width: '100%' }}>
                <Accordion>
                    <input type="checkbox" id="section1" className="accordion__input" />
                    <label htmlFor="section1" className="accordion__label"><h1>Betgames - Your Ultimate Choice for Live TV Games</h1></label>
                    <div className="accordion__content">
                        <p>Betgames is an innovative and industry-leading provider of live-dealer betting products. They provide a strikingly simple, elegant, and entertaining portfolio, suitable for all audiences.</p>
                        <p>Betgames adds WOW to gaming since nothing engages or excites like a live broadcast. We at FUN88 fill the gap between <strong><Link to={'/betting-exchange'}>sports betting</Link></strong> and <strong><Link to={'/live-casino'}>casinos</Link></strong>, providing players with secure gaming fun.&nbsp;</p>
                        <p>From <strong>card games</strong> to lotteries, from the iconic <strong>Wheel of Fortune</strong> to popular dice play, Betgames connects with its audiences all over the world, adding a real, human touch to the time they spend with us - and making it their experience to enjoy.</p>
                        <h2><strong> What is Betgames?</strong></h2>
                        <p>BetgamesTV was started in 2012 and has quickly grown to employ 100 experts and serve consumers in over 40 countries. The two CEOs' extensive experience in the betting sector as well as assistance from investors with over 50 years of experience in the lottery business, were undoubtedly beneficial.</p>
                        <p>This Malta-based company has taken a novel approach to live gaming, resulting in a variety of very distinct gambling alternatives, all streamed live from studios in Malta and Lithuania. They also have a broad range of respected partners, including Betsson, Jetbull, and Casino Estrella. The company is licensed in the United Kingdom, Malta, and Curacao.</p>
                        <h2><strong> Games Offered by Betgames</strong></h2>
                        <p>BetgamesTV's portfolio is unlike any other. The company now offers nine different products, three of which consist of lottery-style games, two of which are dice games, and one of that is a wheel of fortune variant, all of which are broadcast live from the developer's studios every 3 to 5 minutes.</p>
                        <p>The remaining three casino games, <strong><Link to={'/live-casino/betgames/bet-on-poker'}>Bet On Poker</Link></strong>, <strong><Link to="/live-casino/betgames/bet-on-baccarat">Bet On Baccarat</Link></strong>, and <strong><Link to="/live-casino/betgames/war-of-bets">War of Bets</Link></strong>, are webcast 24 hours a day, seven days a week. The streaming quality is excellent, there is no freezing, and the audio and video are nicely synced.</p>
                        <p>The developer came up with a new approach for betting controls, and instead of placing them in the live feed area like the others, they chose to display them below the screen, similar to a sportsbook betting slip.</p>
                        <h3> 1) Bet on poker</h3>
                        <p>A live game with rules and gameplay comparable to the most popular type of poker, Texas Hold'em.</p>
                        <ul>
                            <li>We used one big 52-card deck.</li>
                            <li>The dealer manually shuffles the cards at the table.</li>
                            <li>There are six player positions that can be wagered on.</li>
                            <li>The best five-card poker hand wins.</li>
                            <li>The game is played against other hands, not the dealer.</li>
                            <li>The odds for each playing position are revised following each dealing round.</li>
                            <li>Bets for each betting round are locked in at the selected odds. Bets placed in subsequent rounds have no bearing on previous round bets.</li>
                            <li>You gamble on which hand would prevail in the final showdown.</li>
                            <li>You can also wager on the winning hand combination.</li>
                        </ul>
                        <h3> 2) Dice Duel</h3>
                        <ul>
                            <li>This is possibly the simplest straightforward dice game you'll ever come across.</li>
                            <li>It is played with two dice, which the presenter rolls once.</li>
                            <li>The game runs every four minutes.</li>
                            <li>Bets can be put on which dice will win, the numbers, the odds/evenness, and the total.</li>
                            <li>The odds vary from 1.14 to 34/1.</li>
                        </ul>
                        <h3> 3) Lucky 5</h3>
                        <p>This is a lottery-style game in which 5 numbered balls are picked from 36. The difference is the amount of possible betting combinations. There are over seventy.</p>
                        <ul>
                            <li>From 36 balls, 5 are drawn (9 white, 9 green, 9 red, and 9 blue).</li>
                            <li>Every 5 minutes, a new game begins.</li>
                            <li>Bets can be made on a variety of numbers, white balls, green balls, red balls, blue balls, and combinations thereof.</li>
                        </ul>
                        <h3> 4) Bet on Baccarat</h3>
                        <p>Bet on Baccarat is a live game that is similar to Baccarat or "Punto Banco". The distinction is that a betting round is held after each card is dealt.</p>
                        <ul>
                            <li>We played with six decks of enormous cards.</li>
                            <li>The cards are manually shuffled on the table.</li>
                            <li>You can wager on the main hand of Banker, Player, or Tie.</li>
                            <li>You can also place side bets on Pairs (all combinations) and Big/Small.</li>
                            <li>Bet on the card colors, such as whether the next card will be red or black, or if an equal number of colors will be drawn.</li>
                            <li>Bet on the suit of the cards, whether they are all of the same suit, what the following card will be, and so on.</li>
                            <li>Finally, you can bet on Total of the hand.</li>
                        </ul>
                        <h3> 5) Lucky 6</h3>
                        <p>This is roughly based on a lottery-style game in which six balls are picked from a pool of sixty. There are 30 Red Balls and 30 Blue Balls, with each 10 numbered 0&ndash;9.</p>
                        <ul>
                            <li>Six balls are picked from a pool of sixty (30 red and 30 blue).</li>
                            <li>The balls are divided into three two-ball sectors: A, B, and C.</li>
                            <li>Every 5 minutes, a new game begins.</li>
                            <li>Bets can be put on combinations of numbers, counts, colors, odds/evens, and total sums.</li>
                            <li>The odds vary from 1.01 to 90/1.</li>
                        </ul>
                        <h3> 6) Lucky 7</h3>
                        <p>This is a lottery-style game in which 7 numbered balls are picked from 42. The difference is the amount of possible betting combinations. There are over ninety.</p>
                        <h2><strong> How To Bet on Online Betgames With FUN88?</strong></h2>
                        <ul>
                            <li>To place a wager, simply select your amount and an outcome from the TV Games betting sheet. Before you put your bet, you can see how much you stand to earn. Once you are satisfied with your wager, click the 'Place Bet' option.</li>
                            <li>The Recent wagers feed, located below your betting slip, displays the results of your previous wagers. This displays the previous stakes and the actual winning outcome.&nbsp;</li>
                            <li>Most live dealer games will offer you plenty of time to gamble, although this depends on the game. The average time to place a bet is 30 seconds. As the game develops, you will have more chances to bet.&nbsp;</li>
                            <li>FUN88 TV games also include a variety of virtual sports betting games. These are computer-generated simulations of actual sporting events.&nbsp;</li>
                            <li>You may place bets on these live TV games all day and night. These sports include football, tennis, basketball, and horse racing. Every 5 minutes, a live lottery draw is held.</li>
                        </ul>
                        <h2><strong>Betgames TV Games FAQ's</strong></h2>
                        <h3>Can I use the FUN88 App to play TV games on my mobile?</h3>
                        <p>Yes, the <strong><Link to={'/'}>FUN88</Link></strong> TV games are totally responsive.</p>
                        <h3>What games can I play online on my Smart TV?</h3>
                        <p>Betgames, our official TV games partner, provides 12 games that you can play on your Smart TV. These include card games using cards, wheels, and dice, as well as lottery-style games like Dice Duel, Andar Bahar, War of Bets, Speedy 7, and many more.</p>
                        <h3>What is the best online TV game on FUN88?</h3>
                        <p>War of Bets is the best online TV game since it has the best odds and makes it easy to observe who wins and who loses. The TV game may be played 24 hours a day, and the game rounds are quick for betting sessions.</p>
                    </div>
                </Accordion>
            </AccordionWrapper>
        </>
    );
};

export default withTheme(withAppContext(BetgamesSEO46));