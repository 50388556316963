import { IOrder } from '../../../../graphql/schema'
import { IOrderTicket } from '../../../../store/OrderListContext'
import util from '../../../../util/util';

export const calExposure = (
	orderList: Array<IOrderTicket | IOrder>,
	exposureBySelectionNames?: { [selectionNames: string]: number },
) => {
	return orderList.reduce((pv, item) => {
		let price: number;
		let size: number;
		let type: string;
		let selectionName: string;

		// 判斷是 IOrderTicket 還是 IOrder
		if ('selectionName' in item) {
			// is IOrder
			price = item.price;
			size = item.stake;
			type = item.side;
			selectionName = item.selectionName;
		} else {
			// is IOrderTicket
			price = item.currentPrice || 0;
			size = item.currentSize || 0;
			type = item.type;
			selectionName =
				util.getValue(item, ['selectionInfo', 'name']) || util.getValue(item, ['fancyInfo', 'selectionName']);
		}

		if (!selectionName || !(selectionName in exposureBySelectionNames)) {
			return pv;
		}

		Object.keys(pv).forEach(name => {
			let profit;
			if (type === 'BACK') {
				profit =
					name === selectionName
						? // (price - 1) * size
						  util.numMultiply(util.numAdd(price, -1), size)
						: // size * -1
						  util.numMultiply(-1, size);
			} else if (type === 'LAY') {
				profit =
					name === selectionName
						? // ((price - 1) * size) * -1
						  util.numMultiply(-1, util.numMultiply(util.numAdd(price, -1), size))
						: size;
			}
			if (profit) {
				pv[name] = util.numAdd(pv[name], profit);
			}
		});
		return pv;
	}, exposureBySelectionNames);
};

export const calFancyExposure = (list: any[]) => {
	const expandedList = list.reduce((pv, item) => {
		const selectValue = Number(item.selectionName);
		const oldData1 = pv.get(selectValue - 1) || [];
		const oldData2 = pv.get(selectValue) || [];

		pv.set(selectValue - 1, item.side === 'LAY' ? oldData1.concat(item) : oldData1);
		pv.set(selectValue, item.side === 'BACK' ? oldData2.concat(item) : oldData2);
		return pv;
	}, new Map().set(0, []));
	// Code Changed - Manoj ( made number into any)
	const selectScores: { [key: string]: any } = Array.from(expandedList).reduce(
		(pv: { [key: string]: number }, item: any) => {
			const keyName = item[0].toString();
			pv[keyName] !== 0 && (pv[keyName] = 0); 
			return pv;
		},
		{} as { [key: string]: number },
	);

	// 算各分數的曝險
	expandedList.forEach((value: any, orderScore: any) => {
		value.map((item: any) => {
			Object.keys(selectScores).map(score => {
				const isWin = ({
					BACK: score >= orderScore,
					LAY: score <= orderScore,
				} as any)[item.side];
				if (item.side === 'BACK') {
					isWin
						? (selectScores[score] = util.numAdd(
								selectScores[score],
								util.numMultiply(item.stake, util.numAdd(item.price, -1)),
						  ))
						: (selectScores[score] = util.numAdd(selectScores[score], util.numMultiply(item.stake, -1)));
				} else if (item.side === 'LAY') {
					isWin
						? (selectScores[score] = util.numAdd(selectScores[score], item.stake))
						: (selectScores[score] = util.numAdd(
								selectScores[score],
								util.numMultiply(item.stake, util.numAdd(item.price, -1) * -1),
						  ));
				}
			});
		});
	});
	// 整理要顯示的資料，先根據分數sort再處理
	let calculateList = Object.keys(selectScores).map(item => ({
		score: Number(item),
		exposure: selectScores[item],
	}));
	calculateList = calculateList.sort((a, b) => a.score - b.score);

	// 將周遭一樣exposure的合併放在同一個array內，由此知道分數區間對應的exposure
	const groupData = calculateList.reduce(
		(pv, item, i) => {
			const preExposure = i !== 0 ? calculateList[i - 1].exposure : undefined;
			const currExposure = calculateList[i].exposure;
			!pv && (pv = []);
			if (preExposure !== currExposure) {
				pv.push([item]);
			} else {
				!pv[pv.length - 1] && pv.push([]);
				pv[pv.length - 1].push(item);
			}
			return pv;
		},
		[] as any,
	);

	// 將groupData整理出顯示的資料
	return groupData.map((item: any, i: number) => {
		const firstScore = i === groupData.length - 1 ? `${item[0].score} +` : item[0].score;
		const secondScore = item.length > 1 ? item[item.length - 1].score : '';
		return {
			describe: `${firstScore}${secondScore ? ` - ${secondScore}` : ''}`,
			exposure: item[0].exposure,
		};
	});
};
