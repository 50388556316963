import { ApolloLink, Observable } from '@apollo/client';
import gqlSchema from '../../graphql';
import memcachedUtil from '../../util/memcachedUtil';
import util from '../..//util/util';
/**
 * 需要 memcache 的 list
 */
const needMemcachedList = [gqlSchema.events.GET_EVENTS, gqlSchema.menu.GET_MENU, gqlSchema.events.GET_INNER_EVENTS].map(
	gql => gql.definitions[0].name.value,
);
const minSecTime = 10;
const maxSecTime = 60 * 60 * 24 * 30;
const getMemcachedKey = (operation: typeof gqlSchema.events.GET_EVENTS, isForever: boolean) => {
	const variable = operation.variables.input || operation.variables;
	return `${isForever ? 'forever' : ''}:${operation.operationName}:${Object.keys(variable)
		.map(key => `${key}&${variable[key]}`)
		.join('&')}`;
};
/**
 * memcached Link
 * @param params
 */
const memcachedDataGetLink = (params: any): ApolloLink =>
	new ApolloLink((operation, forward: any) => {
		return new Observable(observer => {
			let sub: any;
			const processFunction = () => {
				sub = forward(operation).subscribe({
					next: (result: any) => {
						observer.next(result);
					},
					error: (networkError: any) => {
						observer.error(networkError);
					},
				});
			};
			if (util.isClient || needMemcachedList.indexOf(operation.operationName) === -1) {
				return processFunction();
			}
			const gqlMaxKey = getMemcachedKey(operation, true);
			const gqlMinKey = getMemcachedKey(operation, false);
			const foreverMemcached = memcachedUtil.get(gqlMaxKey, params.lang);
			foreverMemcached.then(foreverData => {
				if (foreverData === null) {
					processFunction();
				} else {
					observer.next(foreverData || {});
					memcachedUtil.get(gqlMinKey, params.lang).then(minData => {
						if (!minData) {
							processFunction();
						}
					});
				}
			});
			return () => sub && sub.unsubscribe();
		});
	});

const memcachedDataSetLink = (params: any): ApolloLink =>
	new ApolloLink((operation, forward: any) => {
		return new Observable(observer => {
			const gqlMaxKey = getMemcachedKey(operation, true);
			const gqlMinKey = getMemcachedKey(operation, false);
			const isNeedMemcached = !util.isClient && needMemcachedList.indexOf(operation.operationName) > -1;
			const sub = forward(operation).subscribe({
				next: (result: any) => {
					if (isNeedMemcached) {
						result && memcachedUtil.set(gqlMinKey, result, minSecTime, params.lang);
						memcachedUtil.set(gqlMaxKey, result, maxSecTime, params.lang);
					}
					observer.next(result);
				},
				error: (networkError: any) => {
					isNeedMemcached && memcachedUtil.set(gqlMinKey, {}, minSecTime, params.lang);
					observer.error(networkError);
				},
			});
			return () => sub && sub.unsubscribe();
		});
	});

export default (params: any) => memcachedDataGetLink(params).concat(memcachedDataSetLink(params));
