export default class eventUtil {

    public static getValue(obj: any, array: any, defaultValue?: any): any {
        let result: any = obj;
        array.some(
            (key: string): any => {
                result = result[key];
                if (!result) {
                    return true;
                } else {
                    return false;
                }
            },
        );
        return result ? result : defaultValue;
    }

    public static on(eventName: string, func: any): void {
        if (!window.document) {
            return;
        }
        const elem: any = window.document;
        if (elem.addEventListener) {
            // W3C DOM
            elem.addEventListener(eventName, func);
        } else if (elem.attachEvent) {
            // IE DOM
            elem.attachEvent(`on${eventName}`, func);
        } else {
            elem[eventName] = func;
        }
    }

    public static emit(eventName: string, data: any): void {
        if (!window.document) {
            return;
        }
        const event: any = new window.CustomEvent(eventName, {
            detail: data,
        });
        window.document.dispatchEvent(event);
    }

    public static remove(eventName: string, func: any): void {
        if (!window.document) {
            return;
        }
        window.document.removeEventListener(eventName, func);
    }
}
