import React from 'react'
import { Accordion, AccordionWrapper } from '../seo-content/seo_content'
import { withTheme } from 'styled-components';
import { withAppContext } from 'src/store/initAppContext';

type Props = {app:any}

const SUPERNOWASEO = (props: Props) => {

    const { app: { brandId } } = props;
    const domainName = (brandId === 31 || brandId === 32) ? 'Yolo247' : brandId === 33 ? 'iw247' : (brandId === 39 || brandId === 40) ? 'FOMO7': (brandId === 46 || brandId === 47) ? 'FUN88':  'Betdaily' ;
    return (
        <>
            <AccordionWrapper>
                <Accordion>
                    <input type="checkbox" id="section1" className="accordion__input" />
                    <label htmlFor="section1" className="accordion__label"><h1>SUPERNOWA</h1></label>
                    <div className="accordion__content">
                        <h2>A Brief History of Supernowa Casino Games</h2>
                        <p>One of the best, if not the best, Supernowa provides live casino games to its clients worldwide. Supernowa penetrated new emerging markets like India, where they reached out to casino gaming enthusiasts through {domainName}. Unlike some competitors, Supernowa provides users with standalone PC and mobile games.</p>
                        <p>Supernowa develops games for Android and iPhone users, which means they have the upper hand in developing and designing games. Most of Supernowa's rivals are only into live casino games, but Supernowa has also captured market share in offline games. Live casino dealers are available 24*7 from their studios, which makes the game realistic.</p>
                        <p>The company operates in three primary areas besides the live casino dealer games:</p>
                        <ul>
                            <li>Desktop Casino games</li>
                            <li>Mobile Casino games</li>
                            <li>Power games</li>
                        </ul>
                        <p>Supernowa has a license from trusted authorities. Here are some of the salient features that make Supernowa unique:</p>
                        <ul>
                            <li>One-stop solution</li>
                            <li>Singlepoint integration</li>
                            <li>Well-trained dealers</li>
                            <li>24*7 Live casinos</li>
                            <li>Technical support</li>
                            <li>Own Studios</li>
                        </ul>
                        <p>In short, Supernowa games offer an enthralling experience.</p>

                        <h2>Supernowa Casino Games on {domainName} | Complete List of Live Dealer Games</h2>
                        <p>Supernowa gaming provides engaging games on {domainName}. They are as follows:</p>
                        <h3>Baccarat</h3>
                        <p>One of the Supernowa casino games available on the platform is the most prevalent, basic, and easy to understand. In the game, the side with a higher card value will win the round.</p>
                        <h3>Akbar Romeo Walter</h3>
                        <p>Another game provided by {domainName} Supernowa casino games is an interesting one. Known as Akbar Romeo Walter, this is named after Indian characters. The dealer begins by drawing the first card, then takes out the second card and puts it in the dragon position to start the first round.</p>
                        <p>Players will now predict the value of the next card as they place their bets. Now the dealer picks the base card for the next round, which is nothing but the result card of the first round. Akbar is the winner if the card is an Ace, 2,3,4,5, or 6. Romeo wins if the card is 7,8,9, or 10 of all suits. If the card is Jack, Queen, or King, Walter wins.</p>
                        <h3>32 Cards</h3>
                        <p>We will look at yet another {domainName} Supernowa game: 32 cards. The game begins with four virtual players on the table. You will have to bet on which player will have the highest value after the end of the round.</p>
                        <h3>Andar Bahar Classic</h3>
                        <p>The next {domainName} Supernowa game is the smash hit Andar Bahar. This game is easy to play: Andar (Inside) and Bahar (outside) sides. The game begins when the dealer takes out the first card, which is also known as the Joker. Following this, the player bets whether the next card with the same value as the Joker will fall in the Andar or Bahar boxes.</p>

                        <h2>Why Play Supernowa Casino Games On {domainName}?</h2>
                        <p>The reasons could be innumerable. Following are some reasons to play Supernowa casino games on {domainName}:</p>
                        <ul>
                            <li>User-friendly</li>
                            <p>If you need help figuring out the games you want to play and bet on, we suggest you play the Supernowa casino games on {domainName}. A simple and smoother interface to get started, and that is what {domainName} offers.</p>
                            <li>Navigation</li>
                            <p>{domainName} compliments Supernowa's user-friendliness with seamless navigation. A user can play real-world sports or go for casino games. Whatever the choices are, the easy navigation directs a player to the right place with lots of options when it comes to playing Supernowa casino games.</p>
                            <li>Ease of Transactions</li>
                            <p>{domainName} guarantees ease of transactions. You can pay through multiple modes and withdraw your winnings quickly.</p>
                            <li>Round-the-clock Customer Support</li>
                            <p>The round-the-clock customer support always ensures that someone can attend to your issues and queries. Irrespective of the nature of your questions and problems encountered, you are guaranteed to be heard.</p>
                        </ul>
                        <p>These features help casino gaming enthusiasts with an enriching experience on {domainName}.</p>

                        <h2>How to Get Started with Supernowa Casino Games on {domainName}?</h2>
                        <p>You are excited to place your bets and can't wait for the action to begin, right? Well, that's great! So, here are the step-by-step instructions on how to get started.</p>
                        <ol>
                            <li>Log in to your {domainName} account via the website or download {domainName} app</li>
                            <li>Select the Live Casino option from the Casino section</li>
                            <li>Once you do that, you reach a page where all the game providers are available on the left side</li>
                            <li>Click on Supernowa and pick the game of your choice</li>
                            <li>Place your bets</li>
                        </ol>
                        <p>Exciting rewards await casino gaming enthusiasts on Supernowa games.</p>

                        <h2>FAQs about Supernowa Casino Games</h2>
                        <p>Which are the Supernowa games available on {domainName}?</p>
                        <p>Baccarat, Poker, Worli Matka, Akbar Romeo Walter, 32 Cards, Kings Race, and Andar Bahar are the Supernowa games available on {domainName}.</p>

                        <p>Are Supernowa games rewarding?</p>
                        <p>Yes, exciting cash rewards are up for grabs in the Supernowa games.</p>
                    </div>
                </Accordion>
            </AccordionWrapper>
        </>
    )
}

export default withTheme(withAppContext(SUPERNOWASEO)) ;