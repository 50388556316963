import { TrackingImgWrp } from 'src/pages/auth/Signup/Signup';

type Props = {
  channelId: string;
  page: string;
  event: string;
};

const pixelUrls = {
  channel59: {
    PageView: 'https://amigomedia11064574.o18.click/p?o=21192517&m=12618&t=i&event=PageView',
    Lead: 'https://amigomedia11064574.o18.click/p?o=21192517&m=12618&t=i&event=Lead',
    BaaziDeposit: 'https://amigomedia11064574.o18.click/p?o=21192517&m=12618&t=i&event=BaaziDeposit'
  },
  channel60: {
    PageView: 'https://amigomedia11064574.o18.click/p?o=21194414&m=12618&t=i&event=PageView',
    Lead: 'https://amigomedia11064574.o18.click/p?o=21194414&m=12618&t=i&event=Lead',
    BaaziDeposit: 'https://amigomedia11064574.o18.click/p?o=21194414&m=12618&t=i&event=BaaziDeposit'
  },
  channel61: {
    PageView: 'https://amigomedia11064574.o18.click/p?o=21194415&m=12618&t=i&event=PageView',
    Lead: 'https://amigomedia11064574.o18.click/p?o=21194415&m=12618&t=i&event=Lead',
    BaaziDeposit: 'https://amigomedia11064574.o18.click/p?o=21194415&m=12618&t=i&event=BaaziDeposit'
  },
  channel62: {
    PageView: 'https://amigomedia11064574.o18.click/p?o=21194416&m=12618&t=i&event=PageView',
    Lead: 'https://amigomedia11064574.o18.click/p?o=21194416&m=12618&t=i&event=Lead',
    BaaziDeposit: 'https://amigomedia11064574.o18.click/p?o=21194416&m=12618&t=i&event=BaaziDeposit'
  },
  channel63: {
    PageView: 'https://amigomedia11064574.o18.click/p?o=21194418&m=12618&t=i&event=PageView',
    Lead: 'https://amigomedia11064574.o18.click/p?o=21194418&m=12618&t=i&event=Lead',
    BaaziDeposit: 'https://amigomedia11064574.o18.click/p?o=21194418&m=12618&t=i&event=BaaziDeposit'
  },
  channel64: {
    PageView: 'https://amigomedia11064574.o18.click/p?o=21194419&m=12618&t=i&event=PageView',
    Lead: 'https://amigomedia11064574.o18.click/p?o=21194419&m=12618&t=i&event=Lead',
    BaaziDeposit: 'https://amigomedia11064574.o18.click/p?o=21194419&m=12618&t=i&event=BaaziDeposit'
  }
};

const PixelsTrigger = ({ channelId, page, event }: Props) => {
  const url = pixelUrls[channelId]?.[event];
  const isRelevantPage = page === 'signup' || page === 'deposit';

  return (
    <div>
      <TrackingImgWrp>
        {url && isRelevantPage && <img src={url} width="0px" height="0px" alt="Tracking Pixel" />}
      </TrackingImgWrp>
    </div>
  );
};

export default PixelsTrigger;
