import { useEffect, useState } from 'react'
import HistoryTable, { EHistoryFilterType, EHistoryTableType } from '../Statements/HistoryTable';
// Styled components
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import styled, { css, withTheme } from 'styled-components';
import { ifProp, prop, theme, withProp } from 'styled-tools';
import { color } from 'src/sass-files/variable';
//Images
import MyBetsIcon from "src/images/mybets_icon.png";
import DatePickerIcon from 'src/images/calendar.png';
import UnmatchedIcon from 'src/images/unmatched_icon.png';
import MatchedIcon from 'src/images/matched_icon.png';
import MatchedYellowIcon from 'src/images/Radial_fillico.png';
import Intl from '../../components/common/Intl';
import config from 'src/config/config';

import DatePicker from "react-datepicker";
import SegmentedControl from 'src/components/common/SegmentedControl';
import { SegmentedWrapper, SegmentedControlWrapper } from 'src/components/common/SegmentedControl';
import MemberSidebar from './member-sidebar';
import {motion} from 'framer-motion';
import Bg from '../../images/iMAGE1_iexch.png'
import { withAppContext } from "src/store/initAppContext";
const { secondarycolor } = color;
type MyOrderSegments = EHistoryTableType.current | EHistoryTableType.past;

interface IState {
  startAt: Date;
  endAt: Date;
  type: string;
}

function MyOrder(props) {
  const {app: {
    brandId
}, theme} = props
  useEffect(() => {
    window.scrollTo(0,0)
})
  const types = [EHistoryFilterType.matched, EHistoryFilterType.unmatched, EHistoryFilterType.both];
  const [startAt, setStartAt] = useState(new Date());
  const [endAt, setEndAt] = useState(new Date());
  const [selectedInd, setSelectedInd] = useState(0);
  const [type, setType] = useState(EHistoryFilterType.matched)


  // const handleDayChange = (from: Date, to: Date) => {
  //   setStartAt(from);
  //   setEndAt(to);
  // };

  const handleDateChangeRaw = (e) => {
    e.preventDefault();
}

  var date = new Date();
  const minDate = date.setDate((new Date()).getDate() - 90);  

  const handleTypeChange = (id: number) => {
    const types = [EHistoryFilterType.matched, EHistoryFilterType.unmatched, EHistoryFilterType.both];
    const type = types[id];
    if (type) {
      setType(type);
    }
  };

  return (
    <motion.div 
        initial={{opacity: 0}}
        animate={{opacity: 1}}
        exit={{opacity: 0}}
        >
    <StatementWrapper >
      <MemberSidebar />
      <MyBetsWrapper theme={props.app.theme} customStyle={props.theme.image}>
        <Tittle1 theme={props.app.theme} customStyle={props.theme.palette}>
            <TittleIcon></TittleIcon>
            <Intl langKey="MEMBER@MY_ORDER" />
        </Tittle1>
          <DatePickerWrapper>
            <Tittle theme={props.app.theme} customStyle={props.theme.palette}>
              <CalenderIcon></CalenderIcon>
              <Intl langKey= "DATE_PICKER@TITLE" /> 
            </Tittle>
            <DateSelector>
              <BlockPick>
              <Intl langKey= "DATE_PICKER@FROM" /> <DatePicker selected={startAt} onChange={(from: Date | null) => setStartAt(from)} onChangeRaw={handleDateChangeRaw} minDate={minDate} maxDate={new Date()} />
              </BlockPick>
              <BlockPick>
              <Intl langKey= "DATE_PICKER@TO" /><DatePicker selected={endAt} onChange={(to: Date | null) => setEndAt(to)} onChangeRaw={handleDateChangeRaw} minDate={minDate} maxDate={new Date()} />
              </BlockPick>
            </DateSelector>
          </DatePickerWrapper>
        <SegmentedControl<MyOrderSegments>
          segments={[EHistoryTableType.current, EHistoryTableType.past]}
          langKeys={["MY_ORDER@CURRENT", "MY_ORDER@PAST"]}
          id={['myorder_current', 'myorder_past']}
        >
          {({ currentSegment }) => {
            console.log('currentSegment',currentSegment)
            return (
              <>
              {currentSegment === EHistoryTableType.current && (
                <FilterSection>
                  <TypesTab theme={props.app.theme} customStyle={props.theme.palette} style={props.theme.image}>
                    <Tabs defaultIndex={selectedInd} onSelect={handleTypeChange} style={props.theme.image}>
                      <TabList style={props.theme.image}>
                        <Tab><Intl langKey="MY_ORDER@MATCHED" /></Tab>
                        <Tab><Intl langKey="MY_ORDER@UNMATCHED" /></Tab>
                        <Tab><Intl langKey="MY_ORDER@BOTH" /></Tab>
                      </TabList>
                    </Tabs>
                  </TypesTab>
                </FilterSection>
              )}
                <HistoryTable mode={currentSegment} query={{ from: startAt, to: endAt, type }} />
              </>
            )
          }}
        </SegmentedControl>
      </MyBetsWrapper>    
    </StatementWrapper>
    </motion.div>
  ); 
}

export default withTheme(withAppContext(MyOrder));

export const SearchButtonWrapper = styled.button`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  background: linear-gradient(180deg, #D0B99F 0%, #DFC7A3 100%);
  border-radius: 5px;
  padding: 15px 30px;
  color: ${secondarycolor};
  border: none;

  :hover {
    background: linear-gradient(180deg, #DFC7A3 0%, #D0B99F 100%);
  }
`;

const MyBetsWrapper:any = styled.div`
    width: calc(100% - 250px);
    display: flex;
    padding: 10px;
    // background: #fff;
    align-items: flex-start;
    justify-content: flex-start;
    flex-direction: column;
    
    position: relative;
    padding-top: 20px;
    background-image: url('${(props:any) => (props.theme === 'Dark' ? `${config.imageS3Host}/static/snk/${props.customStyle['new_whitelable_dark_background']}` : `${config.imageS3Host}/static/snk/${props.customStyle['new_whitelable_light_background']}`)}');
    background-size: cover;
    background-position: 100%;

    ${SegmentedWrapper} {
      width: 135px;
      display: flex;
      justify-content: center;
      align-items: center;
      position: relative;
      font-weight: 600;
      font-size: 13px;
      text-transform: capitalize;
      padding: 1em 0;
      cursor: pointer;
      // background: #EEF7FF;
      border: 0.25px solid ${theme('palette.secondary-background-color-new')};
      border-radius: 3px;
      color: ${theme('palette.secondary-background-color-new')};
      margin-right: 10px;
     
      ${ifProp(
        'isMybets',
        css`
          color: ${theme('palette.secondary-background-color-new')};
          background: #143583;
          background-size: cover;
          background-image: url('${(props:any) => (props.theme === 'Dark' ? `${config.imageS3Host}/static/snk/${props.customStyle['new_whitelable_dark_background']}` : `${config.imageS3Host}/static/snk/${props.customStyle['new_whitelable_light_background']}`)}');
          `,
      

      )};
    }

    ${SegmentedControlWrapper} {
      margin-bottom: 2em;
      box-shadow: none;
    }
`;

const StatementTableWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  background: #FDF2E5;
  border-radius: 5px;
  padding: 10px;
`;

const TittleIcon = styled.div`
    width: 20px;
    height: 20px;
    display: flex;
    flex-direction: row;
    align-items: center;
    background-image: url(${MyBetsIcon});
    background-size: auto;
    background-repeat: no-repeat;
    margin-right: 5px;
`;

const HeaderBlock = styled.div`
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    padding-bottom: 10px;
`;

const StatementWrapper = styled.div`
    width: 100%;
    display: flex;
    
`;

const FilterSection = styled.div`
  width: 100%;
	display: flex;
	justify-content: space-between;
	align-items: center;
	font-size: 12px;
	margin-bottom: 0.5em;
`;

const TypesTab:any = styled.div`
    display: flex;
    width: auto;
    justify-content: flex-start;
    align-items: flex-start;

    .react-tabs {
        width: 100%;
    }

    .react-tabs__tab {
        letter-spacing: 0.04em;
        text-transform: capitalize;
        font-size: 14px;
        color: ${(props:any) => (props.theme === 'Dark' ? props.customStyle['new_whitelable_dark_font_color'] : props.customStyle['secondary-background-color-new'])};
        
        padding: 0px;
        padding-right: 10px;
        border: none;
        font-weight: 600;
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: row;

        :before {
          content: "";
          display: flex;
          width: 16px;
          height: 16px;
          margin-right: 5px;
          background-size: contain;
          background-image: url('${(props:any) => (props.theme === 'Dark' ? `${config.imageS3Host}/static/snk/${props.style['unmatchedradioButton']}` : `${config.imageS3Host}/static/snk/${props.style['unmatchedradioButton2']}`)}');
          background-repeat: no-repeat;
        }
    }

    .react-tabs__tab--selected {
        background: transparent;
        color: ${theme('palette.secondary-background-color-new')};
        border-radius: 0;
        border: none;

        :before {
          background-image: url('${(props:any) => (props.theme === 'Dark' ? `${config.imageS3Host}/static/snk/${props.style['matchedradioButton']}` : `${config.imageS3Host}/static/snk/${props.style['matchedradioButton2']}`)}');
        }
    }

    .react-tabs__tab-list {
      border-bottom: none;
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: row;
    }

    .react-tabs__tab:focus {
      box-shadow: none;
      border-color: transparent;
    }

  
`;

export const DateSelector = styled.div`
  position:relative;
  top:3px;
  
  font-weight: bold;
  display:inline-flex;
`;

const DatePickerWrapper = styled.div`
  width: 50%;
  display: flex;
  flex-direction: column;
  align-items: centre;
  justify-content: flex-start;
  position: absolute;
  right: 0;
  top: 12px;
`;

export const BlockPick = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  background: ${theme('palette.new-whitelable-member-background')};
  border-radius: 5px;
  padding: 0px 10px;
  margin-right: 10px;
  text-transform: capitalize;
  color: ${theme('palette.secondary-background-color-new')};
  font-size: 12px;

  input {
    border: none;
    background: none;
    display: flex;
    height: 47px;
    text-align: center;
  }

  .react-datepicker__day--outside-month {
  color: transparent !important;
  pointer-events: none;
}
`;

const Tittle:any = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  color: ${(props:any) => (props.theme === 'Dark' ? props.customStyle['new_whitelable_dark_font_color'] : props.customStyle['secondary-background-color-new'])};
  
  font-weight: 500;
  font-size: 15px;
  line-height: 18px;
  text-transform: capitalize;
  padding-bottom: 10px;
`;

const CalenderIcon = styled.div`
  width: 24px;
  height: 24px;
  display: flex;
  flex-direction: row;
  align-items: center;
  background-image: url(${DatePickerIcon});
  background-size: auto;
  background-repeat: no-repeat;
  margin-right: 10px;
`;

const Tittle1:any = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  color: ${(props:any) => (props.theme === 'Dark' ? props.customStyle['new_whitelable_dark_font_color'] : props.customStyle['secondary-background-color-new'])};
  
  font-weight: 500;
  font-size: 15px;
  line-height: 18px;
  text-transform: capitalize;
  padding-bottom: 10px;
`;

