import React from "react";

type Props = {};

const NotificationIcon = (props: Props) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="500"
      height="500"
      viewBox="0 0 500 500"
      fill="none"
    >
      <ellipse
        cx="249.994"
        cy="336.523"
        rx="21.328"
        ry="21.1975"
        fill="#AAAAAA"
      />
      <ellipse
        cx="249.994"
        cy="170.29"
        rx="21.328"
        ry="21.1975"
        fill="#DADADA"
      />
      <path
        d="M335.404 320.683C321.212 318.136 314.936 307.189 313.571 302.034L302.201 312.041L298.106 325.459L309.476 327.961L335.404 329.098V320.683Z"
        fill="#EFEFEF"
      />
      <path
        d="M163.011 320.683C177.203 318.136 183.479 307.189 184.843 302.034L196.214 312.041L200.309 325.459L188.938 327.961L163.011 329.098V320.683Z"
        fill="#EFEFEF"
      />
      <path
        d="M154.166 328.568C154.166 324.141 157.755 320.552 162.183 320.552H337.816C342.243 320.552 345.832 324.141 345.832 328.568C345.832 332.996 342.243 336.585 337.816 336.585H162.183C157.755 336.585 154.166 332.996 154.166 328.568Z"
        fill="#EFEFEF"
      />
      <path
        d="M184.774 226.277C184.774 193.016 211.737 166.053 244.998 166.053H253.543C286.804 166.053 313.767 193.016 313.767 226.277V320.552H184.774V226.277Z"
        fill="#EFEFEF"
      />
    </svg>
  );
};

export default NotificationIcon;
