import React, { useState,useRef } from 'react'
import {
    SecondCol,
    FirstCol,
    Header,
    MainInfoWrapper,
    TableBodyWrapper,
    TableHeadRow,
    TableBodyRow,
    TableWrapper,
    TypeCol,
    TableFooter,
} from 'src/components/udb/commonStyle'
import Intl from '../../../components/common/Intl'
import styled from 'styled-components';

import DatePickerIcon from 'src/images/calendar.png';
import { useQuery } from '@apollo/client';
import { GET_AGENTREPORT } from 'src/graphql/events';
import util from 'src/util/util';
import { TimeZone, TimeZoneConsumer } from 'src/store/TimeZoneContext';
import { Query } from '@apollo/react-components';
import Spinner from 'src/components/common/Spinner';
import { Col } from 'src/components/common/table/baseTableStyles';
import EmptyRow from 'src/components/common/table/feature/EmptyRow';
import Pagination from 'src/components/common/Pagination';
import { IAppContextStore, withAppContext } from 'src/store/initAppContext';
import DatePickerUdb from 'src/components/udb/DatePickerUdb';
import DatePicker from 'src/components/udb/DatePickerUdb';
import UDBHeader from 'src/components/UDBHeader';
import { RefIdCol } from './SportbookStatement';
import SEOContents from 'src/components/SEOContents';

const STATUS_CODE = [
    {
        id: 0,
        value: 'Bet'
    },
    {
        id: 1,
        value: 'Confirmed'
    },
    {
        id: 2,
        value: 'Settled'
    },
    {
        id: 3,
        value: 'Cancel'
    },
    {
        id: 4,
        value: 'Delete'
    }
]

interface props {
    app: IAppContextStore;
    targetMember: { target: { memberId: number; type: string }; persona: string };
    theme: any
}

function CasinoStatementUDB(props: any) {
    const [startAt, setStartAt] = useState(new Date(new Date().setHours(0, 0, 0)));
    const [endAt, setEndAt] = useState(new Date(new Date().setHours(23, 59, 59)));
    const [page, setPage] = useState(1);
    const devRef = useRef<HTMLDivElement>(null);

    const {
        app: { memberId, type },
    } = props;

    var date = new Date();
    const minDate = date.setDate((new Date()).getDate() - 90);

    const setStatus = (code) => {
        let data = STATUS_CODE.filter((status) => {
            if (status.id === code) {
                return status.value
            } else {
                return ''
            }
        })
        return data[0].value
    }

    const handleChangePageIndex = (index: number) => {
        setPage(index)
        devRef.current.scrollIntoView();
    };

    return (
        <MainInfoWrapper ref={devRef}>
            <SEOContents pageName="memberCasinoStatement" />
            <Header>
                <FirstCol>
                    <UDBHeader title="DASHBOARD@CASINOSTMT" />
                </FirstCol>
                <SecondCol>
                    <DatePickerUdb setStartAt={setStartAt} setEndAt={setEndAt} />
                </SecondCol>
            </Header>
            <TimeZoneConsumer>
                {({ currentTimeZone }) => {
                    return (
                        <Query
                            query={GET_AGENTREPORT}
                            ssr={false}
                            variables={{
                                input: {
                                    startDate: new Date(util.fixedFrom(startAt).getTime() - currentTimeZone),
                                    endDate: new Date(util.fixedTo(endAt).getTime() - currentTimeZone),
                                    count: 20,
                                    page: page || 1,
                                    venderPlayer: "",
                                    platformId: null,
                                    platform: '',
                                    productTypeId: 2,
                                    status: -1,
                                    target: util.getTargetString({ memberId: memberId, type: type }),
                                    persona: "",
                                },
                            }}
                        >
                            {({ data, loading }): any => {
                                const casinoState: any = util.getValue(data, ['agentReport', 'thirdPartyOrder'], []);
                                if (loading) {
                                    return <Spinner mode="client" />;
                                }
                                return (
                                    <>
                                        <TableWrapper>
                                            <TableHeadRow>
                                                <TypeCol>
                                                    <span> <Intl langKey="CASINO_STATEMENT_TABLE@PROVIDER" /></span>
                                                </TypeCol>
                                                <RefIdCol className='evolution'>
                                                    <span><Intl langKey="CASINO_STATEMENT_TABLE@REFID" /></span>
                                                </RefIdCol>
                                                <TypeCol>
                                                    <span><Intl langKey="CASINO_STATEMENT_TABLE@STAKE" /></span>
                                                </TypeCol>
                                                <ProfitLossCol>
                                                    <span><Intl langKey="CASINO_STATEMENT_TABLE@WINLOSS" /></span>
                                                </ProfitLossCol>
                                                <TypeCol>
                                                    <span><Intl langKey="CASINO_STATEMENT_TABLE@COMMISION" /></span>
                                                </TypeCol>
                                                <TypeCol>
                                                    <span><Intl langKey="CASINO_STATEMENT_TABLE@GAME" /></span>
                                                </TypeCol>
                                                <TypeCol>
                                                    <span><Intl langKey="CASINO_STATEMENT_TABLE@STATUS" /></span>
                                                </TypeCol>
                                                <TimeCol>
                                                    <span><Intl langKey="CASINO_STATEMENT_TABLE@PLACEDTIME" /></span>
                                                </TimeCol>
                                                <TimeCol>
                                                    <span><Intl langKey="CASINO_STATEMENT_TABLE@SETTLEDTIME" /></span>
                                                </TimeCol>
                                            </TableHeadRow>


                                            <TableBodyWrapper>
                                                {casinoState.orderInfo && casinoState.orderInfo.length > 0 ?
                                                    casinoState.orderInfo.map((game, index) => {
                                                        return (
                                                            <TableBodyRow>
                                                                <TypeCol>
                                                                    {game.provider}
                                                                </TypeCol>
                                                                <RefIdCol className='evolution'>
                                                                    {game.wagerId}
                                                                </RefIdCol>
                                                                <TypeCol>
                                                                    {game.stake.toFixed(2)}
                                                                </TypeCol>
                                                                <ProfitLossCol>
                                                                    <WinLossResult className={game.winloss && game.winloss > 0 ? 'win' : 'loss'}>{game.winloss.toFixed(2)}</WinLossResult>
                                                                </ProfitLossCol>
                                                                <TypeCol>
                                                                    {game.charges === null ? '0.00' : game.charges.toFixed(2)}
                                                                </TypeCol>
                                                                <TypeCol>
                                                                    {game.gameName}
                                                                </TypeCol> 
                                                                <TypeCol>
                                                                    {
                                                                        setStatus(game.status)
                                                                    }
                                                                </TypeCol>
                                                                <TimeCol>
                                                                    <TimeZone format="yyyy-MM-dd HH:mm:ss">
                                                                        {game.vendorBetTime}
                                                                    </TimeZone>
                                                                </TimeCol>
                                                                <TimeCol className="timelast">
                                                                    <TimeZone format="yyyy-MM-dd HH:mm:ss">
                                                                        {game.vendorSettleTime === null ? 'NA' : game.vendorSettleTime}
                                                                    </TimeZone>
                                                                </TimeCol>
                                                            </TableBodyRow>
                                                        )
                                                    }
                                                    ) : (
                                                        <EmptyRow />
                                                    )
                                                }
                                            </TableBodyWrapper>
                                            <TableFooter>
                                                <Pagination
                                                    numberPerPage={20}
                                                    totalLength={casinoState.totalData || 0}
                                                    onClick={handleChangePageIndex}
                                                    currentIndex={page}
                                                />
                                            </TableFooter>
                                        </TableWrapper>
                                    </>
                                )
                            }}
                        </Query>
                    );
                }}
            </TimeZoneConsumer>
        </MainInfoWrapper >
    )
}

export default withAppContext(CasinoStatementUDB);

export const DateSelector = styled.div`
  position:relative;
  top:110px;
  left: 25px;
  
  font-weight: bold;
  display:inline-flex;
`;

const DatePickerWrapper = styled.div`
  width: 50%;
  display: flex;
  flex-direction: row;
  align-items: centre;
  justify-content: flex-start;
  position: absolute;
  right: 0;
  top: 12px;
`;

const BlockPick = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  background: #E9F1F8;
  border-radius: 5px;
  padding: 0px 10px;
  margin-right: 10px;
  text-transform: capitalize;
  color: #203974;
  font-size: 12px;

  input {
    border: none;
    background: none;
    display: flex;
    height: 47px;
    text-align: center;
  }

  .react-datepicker__day--outside-month {
  color: transparent !important;
  pointer-events: none;
}
`;

const CalenderIcon = styled.div`
  width: 24px;
  height: 24px;
  display: flex;
  flex-direction: row;
  align-items: center;
  background-image: url(${DatePickerIcon});
  background-size: auto;
  background-repeat: no-repeat;
  margin-right: 10px;
`;

const BodyRow = styled.div`
  width:100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-size: 12px;
  text-transform: capitalize;
  background: #fff;
  color: #505050;
  border-bottom: none;
  margin-bottom: 5px;
`;

const StatementTableWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  // background: #FDF2E5;
  background:#f1f4f8;
  border-radius: 5px;
  padding: 10px;
`;

// const TypeCol = styled(Col)`
// 	flex-basis: 10%;
// 	justify-content: center;
//   align-items: flex-start;
//   padding: 12px 5px;

// `;

const ProfitLossCol = styled(Col)`
	flex-basis: 16%;
    justify-content: center;
`;

const WinLossResult = styled.div`
    background: #EE6172;
    border-radius: 2px;
    padding: 2px 4px;
    color: #fff;
    &.win {
        background:#38BC7D 
    }
`;

const TimeCol = styled(Col)`
  flex-basis: 10%;
  padding: 12px 0px;
  justify-content: center;
`;

// const TableBodyWrapper = styled.div`
//   width:100%;
//   display: flex;
//   flex-direction: column;
//   overflow-y: overlay;
//   // height: 100vh;
// `;

const HeadingRow = styled.div`
    width:100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    font-size: 14px;
    font-weight: bold;
    text-transform: capitalize;
    letter-spacing: 0.66px;
    color: #000;
    border-bottom: none;
    box-shadow: rgb(0 0 0 / 10%) 0px 3px 5px;
`;

const DownWrapper = styled.div`
	margin-top: 15px;
	width: 100%;
	display: flex;
	margin-bottom: 10px;
`;