import React, { useEffect } from 'react';
import { Link } from 'react-router-dom';
import config from 'src/config/config';
import { withAppContext } from 'src/store/initAppContext';
import styled, { withTheme } from 'styled-components';
import { theme } from 'styled-tools';
import { SRTM } from 'src/util/sportradarEvent';
import { Helmet } from "react-helmet";
import SEOContents from './SEOContents';
import getSchemaData from './schema';

const structuredData = (brandId: any) => {
    const brandUrlConfig = {
        32: 'https://www.yolo247.club',
        33: 'https://www.iw247.com',
        34: 'https://www.betdaily.club',
        39: 'https://www.fomo7.com',
        40: 'https://www.fomo7.xyz',
        46: 'https://www.fun88.site',
        47: 'https://www.gofun88.in',
    };

    const domainURL = brandUrlConfig[brandId] || 'https://www.yolo247.site';

    const otherschemaData = getSchemaData('sponsorships');

    return JSON.stringify([
        {
            "@context": "https://schema.org",
            "@type": "BreadcrumbList",
            "itemListElement": [
                {
                    "@type": "ListItem",
                    "item": `${domainURL}`,
                    "name": "Homepage",
                    "position": 1
                },
                {
                    "@type": "ListItem",
                    "item": `${domainURL}/sponsors`,
                    "name": "Sponsorships",
                    "position": 2
                }
            ],
            "name": "Breadcrumbs"
        },
        ...otherschemaData
    ]);
};

const groupImages = (images: any, chunkSize: number = 4) => {
    const chunks = [];
    for (let i = 0; i < images.length; i = i + chunkSize) {
        chunks.push(images.slice(i, i + chunkSize));
    }
    return chunks;
};

const imageSets = {
    YOLO: [
        { "id": "1", "image": "static/cashsite/brand/6/image/yoloSponsorsShipImageOne.png" },
        { "id": "2", "image": "static/cashsite/brand/6/image/yoloSponsorsShipImageTwo.png" },
        { "id": "3", "image": "static/cashsite/brand/6/image/yoloSponsorsShipImageThree.png" },
        { "id": "4", "image": "static/cashsite/brand/6/image/yoloSponsorsShipImageFive.png" },
        { "id": "5", "image": "static/cashsite/brand/6/image/yoloSponsorsShipImageSix.png" },
        { "id": "6", "image": "static/cashsite/brand/6/image/yoloSponsorsShipImageSeven.png" }
    ],
    FOMO: [
        { "id": "1", "image": "static/cashsite/brand/6/image/fomoSponsorsShipImageOne.png" },
        { "id": "2", "image": "static/cashsite/brand/6/image/fomoSponsorsShipImageTwo.png" },
        { "id": "3", "image": "static/cashsite/brand/6/image/fomoSponsorsShipImageThree.png" },
        { "id": "4", "image": "static/cashsite/brand/6/image/fomoSponsorsShipImageFive.png" },
        { "id": "5", "image": "static/cashsite/brand/6/image/fomoSponsorsShipImageSix.png" },
        { "id": "6", "image": "static/cashsite/brand/6/image/fomoSponsorsShipImageSeven.png" },
        { "id": "7", "image": "static/cashsite/brand/6/image/fomoSponsorsShipImageEight.png" },
        { "id": "8", "image": "static/cashsite/brand/6/image/fomoSponsorsShipImageNine.png" }
    ],
    FUN: [
        { "id": "1", "image": "static/cashsite/brand/6/image/funSponsorsShipImageOne.png" },
        { "id": "2", "image": "static/cashsite/brand/6/image/funSponsorsShipImageTwo.png" },
        { "id": "3", "image": "static/cashsite/brand/6/image/funSponsorsShipImageThree.png" },
        { "id": "4", "image": "static/cashsite/brand/6/image/funSponsorsShipImageFour.png" },
        { "id": "5", "image": "static/cashsite/brand/6/image/funSponsorsShipImageFive.png" },
        { "id": "6", "image": "static/cashsite/brand/6/image/funSponsorsShipImageSix.png" }
    ]
};

const getSponsorImages = (brandId: any) => {
    if ([31, 32].includes(brandId)) return imageSets.YOLO;
    if ([39, 40].includes(brandId)) return imageSets.FOMO;
    if ([46, 47].includes(brandId)) return imageSets.FUN;
    return imageSets.YOLO;
};

const getDomainName = (brandId: any) => {
    if ([31, 32].includes(brandId)) return 'Yolo247';
    if ([39, 40].includes(brandId)) return 'Fomo7';
    if ([46, 47].includes(brandId)) return 'Fun88';
    return 'IW247';
};


function Sponsorships(props: any) {
    const { app: { brandId }, theme } = props;

    useEffect(() => {
        SRTM.init(brandId);
    }, [brandId]);

    const sponsorImages = getSponsorImages(brandId);
    const groupedImages = groupImages(sponsorImages, 4);
    const domainName = getDomainName(brandId);

    return (
        <MainInfoWrapper>
            <Helmet>
                <script type="application/ld+json">
                    {structuredData(brandId)}
                </script>
            </Helmet>
            <SEOContents pageName="sponsorships" />
            <BannerWrapper>
                <img src={`${config.imageV3Host}/static/cashsite/${theme.image.sponsorshipBannerImage}`} alt="Banner Image" />
            </BannerWrapper>
            <SponsorShipsWrapper>
                <SponsorShipsProgramsText>{domainName} Sponsorships</SponsorShipsProgramsText>
                <ImageWrapper>
                    {groupedImages.map((chunk, chunkIndex) => (
                        <ImageRow key={chunkIndex}>
                            {chunk.map((item: any) => (
                                <img key={item.id} src={`${config.imageV3Host}/${item.image}`} alt="Sponsor Image" />
                            ))}
                        </ImageRow>
                    ))}
                </ImageWrapper>
            </SponsorShipsWrapper>
        </MainInfoWrapper>
    );
}

export default withTheme(withAppContext(Sponsorships));


// Styled components

const MainInfoWrapper = styled.div`
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background: ${theme('palette.sponsorshipBackground')};
`;

const BannerWrapper = styled.div`
    width: 100%;
    display: flex;

    img {
        max-width: 100%;
    }
`;

const SponsorShipsWrapper = styled.div`
    margin: 0;
    padding: 0 5% 5% 5%;
    background-image: url(${config.imageV3Host}/static/cashsite/${theme('image.sponsorshipBackgroungImage')});
    background-repeat: no-repeat;
    background-size: cover;
    background-position: top right;
    flex-direction: column;
`;

const SponsorShipsProgramsText = styled.h1`
    color: ${theme('palette.sponsorshipTitleColor')};
    text-align: center;
    font-size: 30px;
    font-weight: 600;
    margin: 0;
    padding: 3% 0;
`;

const ImageWrapper = styled.div`
    display: flex;
    flex-direction: column;
    gap: 2rem;
`;

const ImageRow = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 2rem;

    img {
        width: 15%;
        max-width: 15%;
        height: auto;
        cursor: pointer;
        transition: transform 0.3s ease-in-out;

        &:hover {
            transform: scale(1.1);
        }
    }
`;