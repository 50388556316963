import React, { useEffect } from 'react'
import { withAppContext } from 'src/store/initAppContext';
import styled, { withTheme } from 'styled-components';
import { LazyLoadImage } from "react-lazy-load-image-component";
import config from "src/config/config";
import PlaceholderImage from 'src/components/Placeholder';
import { theme } from 'styled-tools';
import FooterContainer from 'src/components/footer/footer';

function BigBashBigCashPromoFun(props) {
    const { app: {
        brandId
    }, theme } = props;

    useEffect(() => {
        window.scrollTo(0, 0)
    }, [])

    const domain = (brandId == 46 || brandId == 47) ? "FUN88": "Website";

    return (
        <>
            <InformationWrapper>
                <TitleText>
                    <FomoHeading>
                        <h2>Risk Free Bet&#33;</h2>
                        {/* <UnderLine></UnderLine> */}
                    </FomoHeading>
                    <p>This Big Bash, play like a cricket superstar only on <strong>{domain}&#33;</strong></p>
                    <p>We present the offer of the year for all crazy cricket fans&#33; Lost the last match? No worries, <strong>{domain}</strong> gives you a chance to play RISK FREE&#33;</p>
                </TitleText>
                <CashbackHeading>
                    <Divider></Divider>
                    
                    <UnderLine></UnderLine>
                </CashbackHeading>
                <FomoBodyWrapper>
                    <RightWrapper>
                        <LazyLoadImage
                            src={`${config.imageV3Host}/static/cashsite/${theme.image.BigBashBigCashTeamImage}`}
                            alt="bigBashBigCashteams"
                            effect="none"
                            placeholder={<PlaceholderImage />}
                        />
                    </RightWrapper>
                    <LeftWrapper>
                        <RoContent>
                            <h3>Experience the thrill of daily bonus &#45; 100% Loss back</h3>
                            <TextWrapper>
                                <p><span className='labelHeaderFun'>Deposit &#58; </span>Deposit in your <strong>{domain}</strong> account. Keep it loaded and ready for action throughout Dec 24 & Jan 25.</p>
                            </TextWrapper>
                            <TextWrapper>
                                <p><span className='labelHeaderFun'>Play &#58; </span>Dive into the excitement of Cricket matches by placing your bets on our cutting&#45;edge exchange platform
                                </p>
                            </TextWrapper>
                            <TextWrapper>
                                <p><span className='labelHeaderFun'>Claim your prize &#58; </span> For cumulative losses of the day, we&apos;ll reward you with an 100% additional loss back bonus the very next day</p>
                            </TextWrapper>
                        </RoContent>
                    </LeftWrapper>
                </FomoBodyWrapper>
                <Termsandconditions>
                    <TermsandconditionsHeading>
                        <h2>Terms & Conditions</h2>
                    </TermsandconditionsHeading>
                    <TermsandconditionsBody>
                        <ul>
                            <li>The loss back bonus will be applied to 'Big Bash bets &#45; for the day', placed during the match.</li>
                            <li>The bonus amount for each qualifying bet will be capped at INR 50.</li>
                            <li>The bonus will be credited to your <strong>{domain}</strong> account the next day.</li>
                            <li>Bonus will be expired within 24&#45;48 hours.</li>
                            <li>There is no limit to the number of bets you can place to earn the 100% Daily bonus, 'for the day'.</li>
                            <li>The daily bonus can be used to place future bets on <strong>{domain}</strong> as this bonus cannot be withdrawn. Only winnings can be withdrawn.</li>
                            <li>This promotion is valid only for Big Bash.</li>
                            <li><strong>{domain}</strong> reserves the right to disqualify any user found engaging in fraudulent activities.</li>
                            <li><strong>{domain}</strong> General Terms & Conditions apply.</li>
                        </ul>
                    </TermsandconditionsBody>
                </Termsandconditions>
                <Divider></Divider>
            </InformationWrapper>
            <FooterContainer />
        </>
    )
}



export default withTheme(withAppContext(BigBashBigCashPromoFun));

const InformationWrapper = styled.div`
    width: 100%;
    height: auto;
    padding: 3rem;
    border-radius: 1px;
    background: #EBEBEB;   
    display: flex;
    flex-direction: column;

    h2, h3 {
        text-align: center;
        font-size: 25px;
        font-style: normal;
        font-weight: 600;
        line-height: 107.7%; /* 26.925px */
        letter-spacing: 1.25px;
        color: #06A6FF;
        margin: 0;
        padding: 1.5rem 0;
    }

    h3 {
        padding-bottom: 1rem;
        text-align: left;
    }
`;

const TitleText = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    padding: 2rem 7rem 2rem 7rem;
    background: #fff;
    border-radius: 12px;

    h1 {
        color: #FFDE00;
        text-align: center;
        font-size: 20px;
        font-style: normal;
        font-weight: 700;
        line-height: normal;
        text-transform: capitalize;
        padding-bottom: 1rem;
    }

    p {
        margin: 0;
        padding: 0;
        color: #000;
        font-size: 16px;
        font-style: normal;
        text-align: center;
        font-weight: 500;
        line-height: 160.3%;
        text-transform: capitalize;
    }
`;

const Divider = styled.div`
    height: 2px;
    width: 100%;
    background: radial-gradient(#04a6ff 30%,#00000000 55%);
`;

const FomoHeading = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
`;

const CashbackHeading = styled(FomoHeading)`
    padding: 0 0 2rem 0;
`;

const BodyWrapper = styled.div`
    display: flex;
    justify-content: space-evenly;
    align-items: flex-start;
    width: 100%;
    gap: 1rem;
    padding: 0 5rem;
`;

const FomoBodyWrapper = styled(BodyWrapper)`
    align-items: center;
    padding-top: 1rem;
    gap: 2rem;

    h1 {
        background: linear-gradient(180deg, #FED546 18.06%, #FE7C23 91.67%);
        background-clip: text;
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
        margin-bottom: 0.25rem !important;
    }

    .fomoBullet {
        color: #FEBA3B;
    }
`;

const LeftWrapper = styled.div`
    display: flex;
    flex-direction: row;
    /* width: 45%; */
    width: 50%;
`;

const RightWrapper = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    position: relative;
    align-self: center;

    img {
        width: 35rem;
    }
`;

const RoContent = styled.div`
    display: flex;
    flex-direction: column;
    gap: 1rem;
    width: 100%;
    padding: 0rem 2rem 2rem;
    background: #fff;
    border-radius: 12px;

    h1 {
        margin: 0 0 1.5rem 0;
        color: #FFDE00;
        font-size: 20px;
        font-style: normal;
        font-weight: 700;
        line-height: normal;
        text-transform: capitalize;
    }
`;

const TextWrapper = styled.div`
    display: flex;
    text-transform: capitalize;

    .labelHeaderFun {
        color: #000;
        font-weight: 700;
        font-family: 'Inter-Bold';
    }

    p {
        margin: 0;
        color: #000;
        font-size: 16px;
        font-style: normal;
        font-weight: 500;
        line-height: 170.3%; /* 19.248px */
        text-align: left;
    }
`;


const UnderLine = styled.div`
    height: 2px;
    width: 184px;
    background: radial-gradient(#FFF 0%, rgba(153, 153, 153, 0.00) 80%);
`;

const Termsandconditions = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    border-radius: 12px;
    width: 65%;
    height: auto;
    margin: 0;
    padding: 3rem;
    position: relative;
    background: #fff;
    margin: 0 auto;
    margin-top: 2rem;

    ::after {
        position: absolute;
        top: -2px; bottom: -2px;
        left: -2px; right: -2px;
        background: linear-gradient(90deg, #53C1FF, black, #53C1FF, black, #53C1FF);
        content: '';
        z-index: -1;
        border-radius: 30px;
    }
`;

const TermsandconditionsHeading = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;    

    h2 {
        padding-bottom: 0;
    }

    h1 {
        margin: 0;
        padding: 0;
        text-align: center;
        font-size: 25px;
        font-style: normal;
        font-weight: 600;
        line-height: normal;
        letter-spacing: 1.25px;
        background: linear-gradient(180deg, #FED546 18.06%, #FE7C23 91.67%);
        background-clip: text;
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
    }
`;

const TermsandconditionsBody = styled.div`
    ul {
        display: flex;
        flex-direction: column;
        justify-content: center;
        gap: 10px;
        padding-top: 1rem;
    }

    li {
        color: #000;
        font-size: 14px;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
        text-transform: capitalize;
    }
`;