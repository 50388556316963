import React, { useEffect } from 'react'
import { withAppContext } from 'src/store/initAppContext';
import styled, { withTheme } from 'styled-components';
import { LazyLoadImage } from "react-lazy-load-image-component";
import config from "src/config/config";
import PlaceholderImage from 'src/components/Placeholder';
import { theme } from 'styled-tools';
import FooterContainer from 'src/components/footer/footer';

function SmartSoftPriceDrop2k25Promo(props: any) {
    const { app: {
        brandId
    }, theme } = props;

    useEffect(() => {
        window.scrollTo(0, 0)
    }, [])

    const domain = (brandId == 31 || brandId == 32) ? "YOLO247" : (brandId == 39 || brandId == 40) ? "FOMO7" : 'IW247';

    return (
        <>
            <InformationWrapper>
                <Section>
                    <LazyLoadImage
                        src={`${config.imageV3Host}/static/cashsite/${theme.image.SmartSoftPriceDropSectionFirstImg}`}
                        alt="Promotion"
                        effect="none"
                        placeholder={<PlaceholderImage />}
                        className="secOneImg"
                    />
                </Section>
                <LazyLoadImage
                    src={`${config.imageV3Host}/static/cashsite/${theme.image.SmartSoftPriceDropSectionTwoImg}`}
                    alt="Promotion"
                    effect="none"
                    placeholder={<PlaceholderImage />}
                    className="secTwoImg"
                />
                <Section>
                    <LazyLoadImage
                        src={`${config.imageV3Host}/static/cashsite/${theme.image.AdditionalRulesContentImg}`}
                        alt="Promotion"
                        effect="none"
                        placeholder={<PlaceholderImage />}
                        className="secThreeImg"
                    />
                </Section>
                <Section>
                    <div className='textLine'>Keep spinning, keep winning! Dive into Jet<div className='brandXColor'>X</div> now and claim your share of <br /> the <div className='brandColor'>₹31,00000</div> Prize Pool!</div>
                </Section>
                <LazyLoadImage
                    src={`${config.imageV3Host}/static/cashsite/${theme.image.SmartSoftPriceDropSectionTermsImg}`}
                    alt="Promotion"
                    effect="none"
                    placeholder={<PlaceholderImage />}
                    className="secTwoImg"
                />
            </InformationWrapper>
            <FooterContainer />
        </>
    )
}

export default withTheme(withAppContext(SmartSoftPriceDrop2k25Promo));


// Styled Component.

const Section = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0 5rem;

    .secOneImg {
        width: 1177px;
        /* height: 420px; */
        max-width: 100%;
    }

    .secThreeImg {
        width: 1177px;
        max-width: 100%;
        padding-top: 4rem;
    }

    .textLine {
        width: 1177px;
        max-width: 100%;
        display: inline-block;
        text-align: center;
        margin: 0 auto;
        padding-top: 8rem;
        font-size: 29.51px;
        font-style: normal;
        font-weight: 900;
        text-transform: capitalize;
        background: linear-gradient(180deg, #FFF 56.89%, #5AA2C5 141.42%);
        background-clip: text;
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
        font-family: 'Inter-Bold';
    }
`;

const InformationWrapper = styled.div`
    width: 98%;
    height: auto;
    /* padding: 3rem 3rem 8rem 3rem; */
    padding: 3rem 0 0 0;
    background: rgba(111, 111, 111, 0.5);
    margin: 0em 1.2em 0 1.2em;
    border: 1px solid #fff;
    border-top-color: transparent;
    backdrop-filter: brightness(0.5);    
    display: flex;
    flex-direction: column;
    background: url(${config.imageV3Host}/static/cashsite/${theme("image.SmartSoftPriceDropBgImg")});
    background-position: top;
    background-size: cover;
    background-repeat: no-repeat;
    padding-top: 5rem;
    border-bottom: 0;

    .brandColor {
        display: inline-block;
        background: ${theme('palette.SmartSoftPriceDropPromoBrandColor')};
        background-clip: text;
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
    }

    .brandXColor {
        display: inline-block;
        background: ${theme('palette.SmartSoftPriceDropPromoBrandXColor')};
        background-clip: text;
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
    }

    .secTwoImg {
        width: 100%;
        /* height: 810px; */
        max-width: 100%;
    }

    h2 {
        color: #fff;
        font-size: 36px;
        font-style: normal;
        font-weight: 700;
        text-transform: capitalize;
    }
`;
