import React, { useState, useEffect, useMemo } from 'react';
import { useQuery, useLazyQuery, useMutation } from "@apollo/client";
import { motion } from 'framer-motion'
import MemberSidebar from './member-sidebar';
import styled, { withTheme } from 'styled-components';
import { withAppContext } from "src/store/initAppContext";
import Bg from '../../images/iMAGE1_iexch.png';
import ChangePasswordIcon from "src/images/change_password.png";
import media from '../../util/mediaUtil';
import { panelStyle, shadowSM } from '../../components/common/commonCSSStyles';
import AppStoreLogo from '../../images/app_store.png';
import PlayStoreLogo from '../../images/google_play.png';
import { GENERATE_QR_CODE, BIND_GAUTH } from '../../graphql/login';
import {VALID_OTP, REMOVE_OTP} from '../../graphql/member';
import util from '../../util/util';
import { verify } from 'crypto';
import { Notify } from '../../components/Notification';
import cookieUtil from 'src/util/cookieUtil';
import Intl from '../../components/common/Intl';
import { ECookieName, EEventEmit, ETimeZone } from '../../util/utilModel';
import config from 'src/config/config';
import { theme, withProp } from 'styled-tools';

function Security(props) {
    const [isLoadingQr, setLoadingQr] = useState(false);
    const [isError, setError] = useState(false);
    const [qRImage, setQrImage] = useState('');
    const [dog , setDog] = useState(false)
    const [qrSecert, setQrSecert] = useState('');
    const [isHadAuth, setIsHadAuth] = useState(cookieUtil.get(ECookieName.COOKIE_IS_HAD_AUTH) === 'true' ? true : false);
    const [verificationCode, setVerificationCode] = useState('');
    const [bindGAuth, { loading, error, data }] = useMutation(BIND_GAUTH);
    const [validOTP, {loading: validOtpLoading, data: validOtpData}] = useMutation(VALID_OTP);
    const Verification = <Intl langKey="SECURITY@VERIFICATIONCODE" />

    const { loading: payeesLoading, data: payeesData } = useQuery(GENERATE_QR_CODE, {
        variables: {
            input: {}
        },
    })
    const [removeOtp, {loading: removeOtpLoading, data: removeOtpData}] = useMutation(REMOVE_OTP,{
        variables: {
            input: {}
        }
    })
    
    const [getDog, { loading: dLoading, data: dData }] = useLazyQuery(GENERATE_QR_CODE,{
        fetchPolicy: 'no-cache',
        onCompleted: dData => {
            let result = util.getValue(dData, ['member', 'generateOTP'])
            setQrSecert(result.secretCode)
            setQrImage(result.imgSrc)
            setDog(true)
        }
    });
    useEffect((): any => {
        getDog()
    }, [])

  if (dLoading) return <p>Loading ...</p>;

  if (dData && dData.member) {
    let result = util.getValue(dData, ['member', 'generateOTP'])
            // setQrSecert(result.secretCode)
            // setQrImage(result.imgSrc)
            // setDog(true)
  }
    const verify = async (event) => {
        event.preventDefault();
        try {
            const gAuthInfo = await bindGAuth({
                variables: {
                    input: {
                        authOtp: verificationCode,
                        secretCode: qrSecert
                    },
                },
            })
            if (gAuthInfo.data.member.bindOTP.success) {
                let enableIsHadAuth: any = 'true'
                cookieUtil.set(ECookieName.COOKIE_IS_HAD_AUTH, enableIsHadAuth, 30);
                setIsHadAuth(true)
                setVerificationCode('')
            } else {
                Notify.error('Error Wrong Code Entered')
            }
        } catch (error) {
            Notify.error('Error Wrong Code Entered')
        }
    }
    const removeGoogleAuth = async (event) => {
        event.preventDefault();
        event.stopPropagation();
        try {
            const gAuthInfo = await validOTP({
                variables: {
                    input: {
                        authOtp: verificationCode,
                    },
                },
            })
           const result = util.getValue(gAuthInfo,['data', 'member', 'validOTP'])
           if(gAuthInfo.data.member.validOTP.success) {
            let disableIsHadAuth:any = 'false'
                cookieUtil.set(ECookieName.COOKIE_IS_HAD_AUTH, disableIsHadAuth, 30);
                setIsHadAuth(false)
                setVerificationCode('')
                removeOtp({
                    variables: {
                        input: {},
                    },  
                });
                // graphqlApiUtil.query(REMOVE_OTP, { target: ''}).then((data: any) => true);
           }else {
            Notify.error('Error Wrong Code Entered')
           }
            
        } catch (error) {
            Notify.error('Error Wrong Code Entered, Try Again')
        }
    }
    return (
        <motion.div
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
        >
            
            
            <StatementWrapper>
                <MemberSidebar />
                <SecurityWrapper theme={props.app.theme} customStyle={props.theme.image}>
                    <HeaderBlock>
                        <Tittle theme={props.app.theme} customStyle={props.theme.palette}>
                            <TittleIcon></TittleIcon>
                            <Intl langKey="MEMBER@SECURITY" /></Tittle>
                    </HeaderBlock>
                    {
                        dog &&   <SecurityBodyWrapper theme={props.app.theme} customStyle={props.theme.palette}>

                    
                        {
                            isHadAuth ? <div>
                                <PanelWrapper>
                                    <PanelFooter>
                                        <div className="verifyinput">
                                        <Intl langKey="SECURITY@VERIFICATIONCODE">
                                                {(message: string) => {
                                                    return (
                                                    <StyledInput onChange={e => setVerificationCode(e.target.value)} placeholder={message} />
                                                    );
                                                }}
                                                </Intl>
                                        </div>
                                        <div className="verifybtn">
                                            <button onClick={(event) => removeGoogleAuth(event)}> <Intl langKey="BUTTON@DISABLEVERIFY" /> </button>
                                        </div>
                                    </PanelFooter>
                                </PanelWrapper>
                            </div> :
                                <PanelWrapper>
                                    <GAuthWrapper style={{ display: 'flex' }}>
                                        <GAuthLeft>
                                            <div>
                                                <ul _ngcontent-mkp-c7="" className="steps-list">
                                                    <li _ngcontent-mkp-c7=""><b _ngcontent-mkp-c7=""><Intl langKey="TITLE@STEP1" /></b><Intl langKey="TITLE@SETP1_INFO" /> </li>
                                                    <li _ngcontent-mkp-c7="" className="services-links">
                                                        <a className="appstore" _ngcontent-mkp-c7="" target="_blank" href="https://apps.apple.com/ru/app/google-authenticator/id388497605">
                                                            <div _ngcontent-mkp-c7="" className="app-store-img">
                                                                <img src={`${config.imageS3Host}${AppStoreLogo}`} alt="AppStoreLogo" />
                                                            </div>
                                                        </a>
                                                        <a className="playstore" _ngcontent-mkp-c7="" target="_blank" href="https://play.google.com/store/apps/details?id=com.google.android.apps.authenticator2&amp;gl=US">
                                                            <div _ngcontent-mkp-c7="" className="google-play-img">
                                                                <img src={`${config.imageS3Host}${PlayStoreLogo}`} alt="PlayStoreLogo" />
                                                            </div>
                                                        </a>
                                                    </li>
                                                    <li _ngcontent-mkp-c7=""><b _ngcontent-mkp-c7=""><Intl langKey="TITLE@STEP2" /></b><Intl langKey="TITLE@SETP2_INFO" /> </li>
                                                    <li _ngcontent-mkp-c7=""><b _ngcontent-mkp-c7=""><Intl langKey="TITLE@STEP3" /></b><Intl langKey="TITLE@SETP3_INFO" /> </li></ul>
                                            </div>
                                            <PanelFooter>
                                                <div className="verifyinput">
                                                <Intl langKey="SECURITY@VERIFICATIONCODE">
                                                {(message: string) => {
                                                    return (
                                                    <StyledInput onChange={e => setVerificationCode(e.target.value)} placeholder={message} />
                                                    );
                                                }}
                                                </Intl>
                                                </div>
                                                <div className="verifybtn">
                                                    <button onClick={verify}><Intl langKey="BUTTON@VERIFY" /></button>
                                                </div>
                                            </PanelFooter>
                                        </GAuthLeft>
                                        <GAuthRight>
                                            <img src={qRImage} alt="qRImage" />
                                        </GAuthRight>
                                    </GAuthWrapper>
                                </PanelWrapper>
                        }
                    </SecurityBodyWrapper>
                    }

                  
                </SecurityWrapper>
            </StatementWrapper>
        </motion.div>
    );
}

export default withTheme(withAppContext(Security));



const StatementWrapper = styled.div`
    width: 100%;
    display: flex;
    
`;


const SecurityWrapper:any = styled.div`
    width: calc(100% - 250px);
    display: flex;
    padding: 10px;
    background: #fff;
    align-items: flex-start;
    justify-content: flex-start;
    flex-direction: column;
    padding-top: 20px;
    background-image: url('${(props:any) => (props.theme === 'Dark' ? `${config.imageS3Host}/static/snk/${props.customStyle['new_whitelable_dark_background']}` : `${config.imageS3Host}/static/snk/${props.customStyle['new_whitelable_light_background']}`)}');
    background-size: cover;
    background-position: 100%;
`;

const HeaderBlock = styled.div`
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    padding-bottom: 10px;
`;

const Tittle:any = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    color: ${(props:any) => (props.theme === 'Dark' ? props.customStyle['new_whitelable_dark_font_color'] : props.customStyle['secondary-background-color-new'])};    
    font-weight: 500;
    font-size: 15px;
    line-height: 18px;
    text-transform: capitalize;
    padding-bottom: 10px;
`;

const TittleIcon = styled.div`
    width: 20px;
    height: 20px;
    display: flex;
    flex-direction: row;
    align-items: center;
    background-image: url(${ChangePasswordIcon});
    background-size: auto;
    background-repeat: no-repeat;
    margin-right: 5px;
`;

const SecurityBodyWrapper: any = styled.div`
    width: 100%;
    display: flex;
    flex-direction: column;
    padding: 2em;
    // background: linear-gradient(180deg, rgba(253, 242, 229, 0.85) -22.78%, rgba(253, 242, 229, 0.85) 42.15%, rgba(229, 212, 185, 0.85) 118.89%);
    // background-color: ${theme('palette.securityBlkBg')};
    background-color: ${(props:any) => (props.theme === 'Dark' ? 'rgb(241, 244, 248)' : props.customStyle['securityBlkBg'])};
    border-radius: 10px;
`;

const PanelWrapper: any = styled.form`
	${panelStyle};
	${shadowSM};
	padding: 2em;
	width: 100%;
	margin-left: 0.5em;
	${media.xl`
	margin: 0 auto;
	`};
	${media.md`
	background: rgba(255,255,255,.5);
	padding: 1.5em;
	`};
`;

const GAuthWrapper = styled.div`
    display: flex;
    width: 100%;
    align-items: center;
    justify-content: center;

    ${media.md`
        flex-direction: column-reverse;
	`};
`;

const GAuthLeft = styled.div`
    margin-right: 15px;

    .appstore {
        width: 116px;
        height: 40px;
        display: flex;
        margin-right: 4px;

        img {
            width: 100%;
            height: 100%;
        }
    }

    .playstore {
        width: 120px;
        height: 40px;
        display: flex;

        img {
            width: 100%;
            height: 100%;
        }
    }

    .steps-list {
        list-style: none;
        margin: 0;
        font-size: 14px;
        padding: 20px;
        background-color: #e5eef7;
        border-radius: 5px;
        margin-bottom: 10px;

        li {
            // padding-bottom: 3px;
        }
    }

    .services-links {
        display: flex;
        align-items: center;
        padding-bottom: 8px;
        padding-top: 4px;
    }

    ${media.md`
        margin-right: 0px;
        margin-top: 15px;
    `};
`;


const PanelFooter = styled.div`
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: flex-start;

    .verifyinput {
        width: 50%;
        margin-right: 10px;

        input {
            height: 40px;
            border: 1px solid #a7bcd1;
            border-radius: 5px;
            margin-bottom: 0;
        }


        ${media.md`
            width: 100%;
            padding-bottom: 5px;
            margin-right: 0;
        `};
    }

    .verifybtn {
        width: 50%;

        button {
            margin: 0;
            height: 40px;
            border: none;
            border-radius: 5px;
            background: ${theme('palette.verifyBtnBg')};
            color:  ${theme('palette.verifyBtnColor')};
            width: auto;
            cursor: pointer;
        ${media.md`
            width: 100%;
            font-size: 13px;
        `};
        &:hover {
            color:  ${theme('palette.primary-button-color-hover')};
        }
        }

        ${media.md`
            width: 100%;
        `};
    }

    ${media.md`
        flex-direction: column;
    `};
`;

const GAuthRight = styled.div`
    
    background-color: #fff;
    border-radius: 5px;
    // padding: 10px;
    box-shadow: rgb(0 0 0 / 8%) 5px 5px 4px, rgb(0 0 0 / 12%) 0px 0px 17px;
`;

const StyledInput = styled.input`
    width: 100%;
    height: 50px;
    margin-bottom: 12px;
    padding: 15px 12px;
    border: 1px solid #225c9d;
    font-size: 16px;    
`;