import gql from 'graphql-tag';

export const UPDATE_SINGLE_TRANSFER: any = gql`
	mutation updateSingleTransfer($input: SingleTransferInput!) {
		transaction {
			singleTransfer(input: $input) {
				success
				message
			}
		}
	}
`;

export const UPDATE_BATCH_TRANSFER: any = gql`
	mutation updateBatchTransfer($input: BatchTransferInput!) {
		transaction {
			batchTransfer(input: $input) {
				success
				message
			}
		}
	}
`;
