import React from "react";

type Props = {};

export default function Google({}: Props) {
  return (
    <svg
      width="21"
      height="21"
      viewBox="0 0 21 21"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clip-path="url(#clip0_805_202)">
        <path
          d="M20.5102 10.6964C20.5102 9.83604 20.4404 9.20821 20.2893 8.55713H10.4648V12.4403H16.2316C16.1154 13.4054 15.4875 14.8587 14.0923 15.8353L14.0728 15.9653L17.1791 18.3717L17.3943 18.3932C19.3708 16.5678 20.5102 13.882 20.5102 10.6964Z"
          fill="#4285F4"
        />
        <path
          d="M10.465 20.928C13.2902 20.928 15.662 19.9978 17.3944 18.3933L14.0924 15.8354C13.2088 16.4516 12.0229 16.8818 10.465 16.8818C7.69786 16.8818 5.34931 15.0564 4.51212 12.5334L4.38941 12.5439L1.15943 15.0436L1.11719 15.161C2.8379 18.5793 6.37238 20.928 10.465 20.928Z"
          fill="#34A853"
        />
        <path
          d="M4.51205 12.5334C4.29115 11.8824 4.1633 11.1847 4.1633 10.4639C4.1633 9.74301 4.29115 9.04544 4.50043 8.39436L4.49458 8.2557L1.22411 5.71582L1.1171 5.76672C0.407912 7.18518 0.000976562 8.77805 0.000976562 10.4639C0.000976562 12.1498 0.407912 13.7425 1.1171 15.161L4.51205 12.5334Z"
          fill="#FBBC05"
        />
        <path
          d="M10.465 4.04601C12.4299 4.04601 13.7553 4.89474 14.5111 5.60401L17.4642 2.72061C15.6505 1.03477 13.2903 0 10.465 0C6.37242 0 2.83791 2.34854 1.11719 5.76672L4.50052 8.39435C5.34934 5.8714 7.69791 4.04601 10.465 4.04601Z"
          fill="#EB4335"
        />
      </g>
      <defs>
        <clipPath id="clip0_805_202">
          <rect width="20.5212" height="21" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
}
