import React, { useEffect, useState } from 'react';
import styled, { withTheme } from 'styled-components';
import { HeadingTextWrapper } from 'src/components/common/Headings';
import { bgcolor, color } from '../../../sass-files/variable';
import { withAppContext } from 'src/store/initAppContext';
import { useMutation } from '@apollo/client';
import { ONE_CLICK_RECYCLE } from 'src/graphql/system';
import { useLocation, useParams } from "react-router-dom";
// import PlayDemoGames from './bingo-slotGames';

import Tabs from './casino-tab';
import {motion} from 'framer-motion';
import config from 'src/config/config';
import Intl from 'src/components/common/Intl'
import { theme } from 'styled-tools';
import SEOContents from 'src/components/SEOContents';
import { allIcon } from './casino-tab-games';
import instantGameData from "src/static/game-data/instantgames.json"
import AllGames from './all-games';
import {SRTM} from 'src/util/sportradarEvent';
import SPRIBESEO from 'src/components/SEOComponents/spribeSEO';
import { Helmet } from "react-helmet";
import { CRMPageVisit } from 'src/util/crmUtil';
import AviatrixSEO from 'src/components/SEOComponents/AviatrixSEO';
import AviatorSEO from 'src/components/SEOComponents/AviatorSEO';
import PlinkoxSEO from 'src/components/SEOComponents/PlinkoxSEO';
import BetgamesSEO from "src/components/SEOComponents/betgames";
import InstantGamesf88 from "src/components/SEOComponents/InstantGamesF88";
import SpribeSEOf88 from 'src/components/SEOComponents/SpribeSEOf88';
import AviatorF88 from 'src/components/SEOComponents/AviatorF88';
import DiceF88 from 'src/components/SEOComponents/DiceF88';
import Goalf88 from 'src/components/SEOComponents/Goalf88';
import KenoF88 from 'src/components/SEOComponents/KenoF88';
import MinesF88 from 'src/components/SEOComponents/MinesF88';
import Plinkof88 from 'src/components/SEOComponents/Plinkof88';
import getSchemaData from 'src/components/schema';
import Skywardf88 from 'src/components/SEOComponents/Skywardf88';


const spribeIcon = `${'/static/media/SPRIBEProviderIconNew.png'}`;
const STPicon = `${'/static/media/STPProviderIconNew.png'}`;
const smartsofticon = `${'/static/media/SmartSoftProviderIconNew.png'}`;
const galaxyGamesIcon = `${'/static/media/galaxyGameIcon.png'}`;
const aviatrixicon = `${'/static/media/aviatrixicon.png'}`;
const evolutionIcon = `${'/static/media/evolution_icon.png'}`;
const bettvIcon = `${'/static/media/bettv.png'}`;
const evoPlayIcon = `${'/static/media/Evoplay_icon_desk.png'}`;
const turboGamesIcon = `${'/static/media/TurboGames_icon_desk.png'}`;


let ALLGAMES = <Intl langKey="GAME@ALL"/>;
// let POPULAR = <Intl langKey="TITLE@populargames"/>;
let SMARTSOFT = <Intl langKey="TITLE@smartsoft"/>;
let SPRIBE = <Intl langKey="GAME@SPRIBE"/>;
let STP = <Intl langKey="GAME@STP"/>;
let GALAXY = <Intl langKey="GAME@GALAXYS"/>;
let AVIATRIX = <Intl langKey="GAME@AVIATRIX" />;
let EVOGAMES = <Intl langKey="GAME@EVOGAMES" />;
let BETGAMES = <Intl langKey="GAME@BETGAMES" />;
let EVOPLAY = <Intl langKey="GAME@EVOPLAY" />;
let TURBOGAMES = <Intl langKey="GAME@TURBOGAMES" />;


const providerTab = [ALLGAMES, GALAXY, SMARTSOFT, BETGAMES, SPRIBE, STP, AVIATRIX, EVOGAMES, EVOPLAY, TURBOGAMES];
const providerList = ['all','galaxsys','smartsoft','betgames', 'spribe', 'stp',  'aviatrix',  'evolution', 'evoplay', 'turbogames'];

const structuredData = (brandId: any, providerName: string, gameName: string) => {
    const brandUrlConfig = {
        32: 'https://www.yolo247.club',
        33: 'https://www.iw247.com',
        34: 'https://www.betdaily.club',
        39: 'https://www.fomo7.com',
        40: 'https://www.fomo7.xyz',
        46: 'https://www.fun88.site',
        47: 'https://www.gofun88.in',
    };

    const domainURL = brandUrlConfig[brandId] || 'https://www.yolo247.site';

    // const schemaName: string = providerName && gameName ? gameName : providerName ? providerName : 'instant-games';
    let schemaName: string = gameName || providerName || 'instant-games';

    if(providerName === 'betgames' && gameName === 'skyward') {
        schemaName = 'instantskyward';
    }

    const otherschemaData = getSchemaData(schemaName);

    const itemLists = [
        {
            "@type": "ListItem",
            "item": domainURL,
            "name": "Homepage",
            "position": 1
        },
        {
            "@type": "ListItem",
            "item": `${domainURL}/instant-games`,
            "name": "Instant Casino Games",
            "position": 2
        }
    ];

    if (providerName) {
        itemLists.push({
            "@type": "ListItem",
            "item": `${domainURL}/instant-games/${providerName}`,
            "name": providerName,
            "position": 3
        });

        if (gameName) {
            itemLists.push({
                "@type": "ListItem",
                "item": `${domainURL}/instant-games/${providerName}/${gameName}`,
                "name": `Play ${gameName}`,
                "position": 4
            });
        }
    }

    const data = [
        {
            "@context": "https://schema.org",
            "@type": "BreadcrumbList",
            "itemListElement": itemLists,
            "name": "Breadcrumbs"
        },
        ...otherschemaData
    ];

    return JSON.stringify(data);
};

const providerIcons = [
    {
        id: 1,
        name: ALLGAMES,
        icon: allIcon
    },
    {
        id: 2,
        name: GALAXY,
        icon: galaxyGamesIcon
    },
    {
        id: 3,
        name: SMARTSOFT,
        icon: smartsofticon
    },
    {
        id: 4,
        name: BETGAMES,
        icon: bettvIcon
    },
    {
        id: 5,
        name: SPRIBE,
        icon: spribeIcon
    },
    {
        id: 6,
        name: STP,
        icon: STPicon
    },
    {
        id: 7,
        name: AVIATRIX,
        icon: aviatrixicon
    },
    {
        id: 8,
        name: EVOGAMES,
        icon: evolutionIcon
    },
    {
        id: 9,
        name: EVOPLAY,
        icon: evoPlayIcon
    },
    {
        id: 10,
        name: TURBOGAMES,
        icon: turboGamesIcon
    }
]

const {titleHeadColor} = color

const { secondarycolor, menucolor } = color;
const {loginbghover} = bgcolor
const {signupbghover} = bgcolor

function InstantGames(props: any) {
    // const [isGameTrigger, setGameTrigger] = useState(false)
    // const [gameDetails, setGameDetails] = useState({})
    const [reload,setReload] = useState(false)
    const [triggerOneClickRecycle]: any = useMutation(ONE_CLICK_RECYCLE)
    // const [filterBingoImages,setFilterBingoImages] = useState([])

    const [casinoImages, setCasinoImages] = useState(instantGameData);
    const [filteredData, setfilteredData] = useState(instantGameData);
    const [search,setSearch]=useState('');
    // const { id, provider } = useParams();
    const { id: gameName, provider: providerName } = useParams();
    const { app: { brandId },theme} = props
    const {showSEOfooterForYolo,showSeofooterFun88}=theme.config
    const location = useLocation();
    // const getLastqueryString = location.pathname.split("/").pop();

    const _id = providerName && gameName ? gameName : providerName;
 
    useEffect(() => {
        // Now Lazy load game data 
        // import('src/static/game-data/instantgames.json').then(data => data.default).then(data => {
            // setCasinoImages(data);

            if(gameName){
                searchData(gameName);
            } else {
                setfilteredData(instantGameData)
            }

        // }).catch(err => console.log('Something went wrong. Unable to fetch game data', err))
        window.scrollTo(0,0);

        SRTM.init(brandId);
        //for CRM
        CRMPageVisit();
    },[])

     const backButton = () => {
      
        window.location.reload();
        triggerOneClickRecycle()
            .then((response) => {
                if (response) {
                    return;
                }
            })
            .catch((error) => {
                console.log("Error in one click recycle");
            });
    }

    const searchData = (val) => {
       setSearch(val);
    
        if (val !== "") {
            let _val = val && val.replace(/[\_\-\s]/gi, '');
            const filteredItems = casinoImages.filter((item) => {
                // Clean up search value and item fields for better comparison
                let type = item.type.toLowerCase() && item.type.replace(/[\_\-\s]/gi, '');
                let slug = item.slug ? item.slug.replace(/[\_\-\s]/gi, '') : '';
                let category = item.catagoryName ? item.catagoryName.replace(/[\_\-\s]/gi, '') : '';

    
                // Check if either type or slug matches the search value
                if (type.toLowerCase().includes(_val.toLowerCase()) || slug.toLowerCase().includes(_val.toLowerCase())) {
                    return item;
                }
                else if ((item.game.replace(/[\_\-\s]/gi, '').toLowerCase()).includes(_val.toLowerCase())) {
                    //searchOn = 'catagoryName'
                    return item;
                  }
                //   else if ((category.toLowerCase()).startsWith(_val.toLowerCase())) {
                //     //searchOn = 'catagoryName'
                //     return item;
                //   }
                //  else {
                //     const {platformCode, ...rest } = item;
                //     console.log('AAAAA-rest',rest);
                //     console.log('AAAAAA-',Object.values(rest).join('').toLowerCase().includes(val.toLowerCase()))
                //     // Fallback: Search through all other fields in the object
                //     return Object.values([platformCode]).join('').toLowerCase().includes(val.toLowerCase());
                // }
            });
    
            setfilteredData(filteredItems);
        } else {
            // Reset to original data when search input is empty
            setfilteredData(casinoImages);
        }
    };


    const budgetData = ()=>{
      
    }


    return (
        <>
            <Helmet>
                <script type="application/ld+json">
                    {
                        structuredData(brandId, providerName, gameName)
                    }
                </script>
            </Helmet>

            <motion.div
                initial={{ opacity: 0 }}
                animate={{ opacity: 1 }}
                exit={{ opacity: 0 }}
            >
                {_id && (<SEOContents pageName={
                    _id == 'spribe' ? 'SPRIBE'
                        : _id == 'aviator' ? 'aviator'
                            : _id == 'cash-show' ? 'cashshow'
                                : _id == 'jetx' ? 'jetx'
                                    : _id == 'plinko-x' ? 'plinkox'
                                        : _id == 'football-x' ? 'footballx'
                                            : _id == 'balloon' ? 'balloon'
                                             : (_id == 'mines' && providerName==='turbo-games') ? 'mines-turbo'
                                                : _id == 'mines' ? 'mines'
                                                    : _id == 'ninja-crash'  ? 'ninjacrash'
                                                    : _id == 'ROCKETON'  ? 'rocketon'
                                                    : _id == 'StockMarket'  ? 'stockmarket'
                                                    : _id == 'aviatrix' ? 'aviatrix'
                                                        : _id == 'cricket-x' ? 'cricketx'
                                                            : _id == 'rock-paper-scissors' ? 'rockpaperscissors'
                                                                : _id === 'betgames' ? "betgames"
                                                                : _id === 'skyward' ? "instantskyward"
                                                                : (_id === 'turbo-mines' && providerName==='turbo-games' ) ? "turbo-mines-turbo"
                                                                :_id
                            
                } 
                />)}
                {
                    !_id && <SEOContents pageName="instantGames" />
                }
                <CasinoWrapper theme={props.app.theme} customStyle={props.theme.image}>
                    <TitleHead theme={props.app.theme} customStyle={props.theme.palette}><Intl langKey="TITLE@InstantGames" /></TitleHead>
                    <TabListSection>
                        <Tabs
                            searchText={search}
                            providerIcons={providerIcons}
                            tabs={providerTab}
                            onSearch={searchData}
                            isTabsHidden={false}
                            budgetClick={budgetData}
                            minWidthInContent={'initial'}
                            providerList={providerList}
                            render={(index) => {

                                return (
                                    <>
                                        {
                                            providerTab[index] === ALLGAMES && <AllGames data={filteredData} />

                                        }
                                        {
                                            providerTab[index] === SMARTSOFT && <AllGames data={filteredData.filter((element, index) => {
                                                return element.type.toLowerCase() === 'smartsoft'
                                            })} />
                                        }
                                        {
                                            providerTab[index] === SPRIBE && <AllGames data={filteredData.filter((element, index) => {
                                                return element.type.toLowerCase() === 'spribe'
                                            })} />
                                        }
                                        {
                                            providerTab[index] === STP && <AllGames data={filteredData.filter((element, index) => {
                                                return element.type.toLowerCase() === 'stp'
                                            })} />
                                        }
                                        {
                                            providerTab[index] === GALAXY && <AllGames data={filteredData.filter((element, index) => {
                                                return element.type.toLowerCase() === 'galaxsys'
                                            })} />
                                        }
                                        {
                                            providerTab[index] === AVIATRIX && <AllGames data={filteredData.filter((element, index) => {
                                                return element.type.toLowerCase() === 'aviatrix'
                                            })} />
                                        }
                                        {
                                            providerTab[index] === BETGAMES && <AllGames data={filteredData.filter((element, index) => {
                                                return element.type.toLowerCase() === 'betgames'
                                            })} />
                                        }
                                        {
                                            providerTab[index] === EVOGAMES && <AllGames data={filteredData.filter((element, index) => {
                                                return element.type.toLowerCase() === 'evolution';
                                            })} />
                                        }
                                        {
                                            providerTab[index] === EVOPLAY && <AllGames data={filteredData.filter((element, index) => {
                                                return element.type.toLowerCase() === 'evoplay'
                                            })} />
                                        }
                                        {
                                            providerTab[index] === TURBOGAMES && <AllGames data={filteredData.filter((element, index) => {
                                                return element.type.toLowerCase() === 'turbogames'
                                            })} />
                                        }
                                    </>
                                )
                            }}
                        />
                    </TabListSection>

                    {
                        (_id == 'spribe' && showSEOfooterForYolo) ? <SPRIBESEO /> :
                        (_id == 'aviatrix' && showSEOfooterForYolo) ? <AviatrixSEO /> :
                        (_id == 'aviator' && showSEOfooterForYolo) ? <AviatorSEO /> :
                        ((window.location.pathname == '/instant-games/spribe/dice') && showSeofooterFun88) ? <DiceF88 /> :
                        ((window.location.pathname == '/instant-games/spribe/goal') && showSeofooterFun88) ? <Goalf88 /> :
                        ((window.location.pathname == '/instant-games/spribe/keno') && showSeofooterFun88) ? <KenoF88 /> :
                        ((window.location.pathname == '/instant-games/spribe/mines') && showSeofooterFun88) ? <MinesF88 /> :
                        ((window.location.pathname == '/instant-games/spribe/plinko') && showSeofooterFun88) ? <Plinkof88 /> :
                        ((window.location.pathname == '/instant-games/betgames/skyward') && showSeofooterFun88) ? <Skywardf88 /> :
                        (_id == 'plinko-x' && showSEOfooterForYolo && (brandId === 39)) ? <PlinkoxSEO /> :
                        (_id == 'betgames' && showSEOfooterForYolo) ? <BetgamesSEO /> :
                        ((window.location.pathname === '/instant-games') && showSeofooterFun88) ? <InstantGamesf88 /> :
                        <></>
                    }

                </CasinoWrapper>
            </motion.div>
        </>
    )
}

export default withTheme(withAppContext(InstantGames)); 



const TabListSection = styled.div `
    width: 100%;
    display: flex;
    align-items: flex-start;
    
`;

const CasinoWrapper:any = styled.div`
    width: 100%;
    display: flex;
    align-items: flex-start;
    flex-direction: column;
    /* margin-top: -5px; */
    background-image: url('${(props:any) => `${config.imageV3Host}/static/cashsite/${props.customStyle['homeExchangeBgImage']}`}');
    /* background-image: url('${(props:any) => (props.theme === 'Dark' ? `${config.imageS3Host}/static/snk/${props.customStyle['new_whitelable_dark_background']}` : `${config.imageS3Host}/static/snk/${props.customStyle['new_whitelable_light_background']}`)}'); */
    background-position: 100%;
    background-size: cover;
`;

const TitleHead:any = styled(HeadingTextWrapper)`
    font-size: 28px;
    letter-spacing: 3px;
    text-transform: uppercase;
    white-space: nowrap;
    font-weight: bold;
    /* color: ${(props:any) => (props.theme === 'Dark' ? '#FFF' : '#F4A322')}; */
    color: ${(props:any) => (props.theme === 'Dark' ? '#FFF' : props.customStyle['casinoTitleHeadTextColor'])};
    margin-left: 1em;
    padding-top: 10px;
    
    position: relative;
    margin-bottom: 10px;
    padding-bottom: 5px;

    :after {
        content: "";
        width: 500px;
        height: 2px;
        background: ${(props:any) => (props.customStyle['casinoTitleHeadBorderBtmLineColor'])};
        position: absolute;
        bottom: 0;
        left: 0;
    }
	
	@media only screen and (max-width: 992px){
		font-size: 30px;
	}	
`;

const SectionWrapper = styled.div`
    width: 100%;
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    flex-direction: row;

    img {
        height:100%;
        width: 100%;

    }

    .overlay {
        position: absolute;
        bottom: 0;
        left: 0;
        right: 0;
        background-color: #0a285edb;
        overflow: hidden;
        width: 100%;
        height: 100%;
        -webkit-transform: scale(0);
        -ms-transform: scale(0);
        transform: scale(0);
        -webkit-transition: .5s ease;
        transition: .5s ease;
      }
      
      .buttons {
        color: #fff;
        font-size: 20px;
        position: absolute;
        top: 50%;
        left: 50%;
        -webkit-transform: translate(-50%, -50%);
        -ms-transform: translate(-50%, -50%);
        transform: translate(-50%, -50%);
        text-align: center;
        display: flex;
        align-items: center;
        justify-content: center;
      }
`;

const PlayButton = styled.div`
    transform: skew(340deg);
    border: 1px solid #CB9640;
   
    box-shadow: 3px 5px 4px rgba(0, 0, 0, 0.25);

    :hover {
        background: ${theme('palette.primary-background-color-linear-new')};
        transition: color 0.1s linear 0s;
    }

    button {
        width: 80px;
        text-align: center;
        display: block;
        text-decoration: none;
        padding: 10px 0px;
        font-size: 14px;
        transform: skew(-340deg);
        color: ${secondarycolor};
        
        text-transform: uppercase;
        background-color: transparent;
        border: none;
        cursor: pointer;
    }
`;

const DemoButton = styled.div`
    transform: skew(340deg);
    border: 1px solid #CB9640;
    margin-left: 10px;
    background: linear gradient(#37B8BB(60% opacity),#1C5C5E(40% opacity));
    box-shadow: 3px 5px 4px rgba(0, 0, 0, 0.25);
    transition: color 0.1s linear 0s;

    :hover {
        background: ${theme('palette.primary-background-color-linear-new')};
        transition: color 0.1s linear 0s;
    }

    button {
        width: 80px;
        text-align: center;
        display: block;
        text-decoration: none;
        padding: 10px 0px;
        font-size: 14px;
        transform: skew(-340deg);
        color: ${secondarycolor};
        
        text-transform: uppercase;
        background-color: transparent;
        border: none;
        cursor: pointer;
    }
`;

export const RowFirst = styled.div`
display: flex;
flex-wrap: wrap;
// background-color: ${secondarycolor};
background-image: url('${(props:any) => (props.theme === 'Dark' ? `${config.imageS3Host}/static/snk/${props.customStyle['new_whitelable_dark_background']}` : `${config.imageS3Host}/static/snk/${props.customStyle['new_whitelable_light_background']}`)}');

width:100%;


    .blocks {
        /* box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25), inset 4px 4px 4px rgba(0, 0, 0, 0.25); */
        padding: 2px;
        border-radius: 5px;
        width: 25%;
        box-sizing: border-box;
        flex: 0 0 24%; /* explanation below */
        margin: 0px 5px;
        
        // height: 100px;
    }

    .blocks_fullwidth {
        width: 100%;
        /* box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25), inset 4px 4px 4px rgba(0, 0, 0, 0.25); */
        padding: 2px;
        margin: 3px;
        border-radius: 5px;
    }

    .top_up {
        position: relative;
        top: -25px;
    }
`;

const FirstColoumn = styled.div`
    width: 100%;
    display: flex;
    align-items: center;
    flex-direction: column;

    span {
        width: 100%;
        display: flex;
        align-items: center;
        flex-direction: row;
        background-size: cover;
        background-repeat: no-repeat;
        position: relative;  
        margin: 5px 5px 0px 0px;
        position: relative;
        cursor: default;   
    }

    span:hover .overlay {
        -webkit-transform: scale(1);
        -ms-transform: scale(1);
        transform: scale(1);
    }

    .coltenth {
        height: 230px;
       
        align-items: flex-end;

        h4 {
            bottom: 30px;
        }
    }
   

    h4 {
        position: absolute;
        left: 1.5em;
        margin: 0;
        font-size: 30px;
        color: ${secondarycolor};
        
        text-transform: uppercase;
    }
`;

const BackButton= styled.button`
    display: flex;
    font-size: 15px;
    color: #D0B99F;
    -webkit-box-align: center;
    align-items: center;
    -webkit-box-pack: start;
    justify-content: flex-start;
    padding: -0.1em 1.7em;
    background: ${theme('palette.backButtonColor')};
    border-radius: 3px;
    margin-left: 150px;
    position: absolute;
    top: 125px;
    right: 0px;
    cursor:pointer;
    border: 0.25px solid #D0B99F;
    // box-shadow: 0 0 3px #786B57;

img{
    padding-right:5px;
    color:#fff;
    
}

`;
