import { darken, lighten } from 'polished';
import React from 'react';
import styled, { css } from 'styled-components';
import { ifNotProp, theme, withProp } from 'styled-tools';
import { diffColor } from '../../../util/colorUtil';
import ClickOutside from '../../common/ClickOutsideJIT'

// Components

import OneClick from '../order/OneClick';
import OrderslipList from '../order/OrderslipList';
import { MainLayoutConsumer } from '../../../store/MainLayoutContext';

// Styles
import { scrollbarContainerStyle, shadow2F, shadowLeft2F } from '../../common/commonCSSStyles';


// Utils
import media from '../../../util/mediaUtil';
import { isMemberType } from '../../../util/styleUtil';
import BookmakerOrderClick from './BookmakerOrder';

export const bettingWidth = '300px';
export const mobileBettingWidth = '100%';

const EnhancedOrderWrapper: React.SFC = ({ children }) => {
	return (
		<MainLayoutConsumer>
			{layoutStore => {
				const { isBettingOpened, deactivateBetting } = layoutStore;

				return (
					<ClickOutside callback={deactivateBetting} shouldRegisterCallback={isBettingOpened}>
						{(containerRef: any) => {
							return <OrderWrapper ref={containerRef}>{children}</OrderWrapper>;
						}}
					</ClickOutside>
				);
			}}
		</MainLayoutConsumer>
	);
};

const Order: React.SFC = () => {
	return (
		<EnhancedOrderWrapper>
			<OneClick />
			<BookmakerOrderClick/> 
			<OrderslipList />
		</EnhancedOrderWrapper>
	);
};

export default Order;

// Styled components

export const SectionHeading = styled.h2`
	display: flex;
	justify-content: space-between;
	align-items: center;
	font-size: 13px;
	text-transform: capitalize;
	margin: 0;
	// margin-bottom: 0.5em;
	padding-left: 5px;
	color: rgba(221,232,252,0.97);
	// background-color: #f6e8d3;
	background: #DEC59D;
    padding: 10px;

	& span {
		color: black;
	}
`;

export const MatchListSectionHeading = styled(SectionHeading)`
	${media.sm`
		display: none;
	`};
	color: #000000;
	// margin-bottom: 5px;
`;

// 只在手機上顯示
export const OpenedBetsSectionHeading = styled(SectionHeading)`
	display: none;

	${media.sm`
		display: block;
	`};
`;

// Wrapping SectionHeading and panels
export const SectionWrapper = styled.section`
	position: relative;
	z-index: 0;
	// margin-bottom: 2em;
	font-size: 12px;
	background: ${theme('palette.onclickAcceptOddBg')};
	padding: 10px;
    border-radius: 5px;
	// margin-top: 2%;
`;

// 包括 unmatched list & matched list
export const MatchListSectionWrapper: any = styled(SectionWrapper)`
	${media.sm`
		${ifNotProp(
			'isDisplayedOnMobile',
			css`
				display: none;
			`,
		)}
	`};
`;

// Wrapping My Wallet, 1-Click Betting, Betslip sections
export const OrderWrapper: any = styled.section`
	${scrollbarContainerStyle};
	font-size: 14px;
	width: ${bettingWidth};
	padding: 12px 0.75em 0px;
	flex-shrink: 0;
	// padding-top: 2em;

	&:focus {
		outline: 0;
	}
	${media.sm`
		width: ${mobileBettingWidth};
	`};

	
`;
