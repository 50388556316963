//import { darken } from 'polished';
import React from 'react';
import styled, { css } from 'styled-components';
import { ifProp, prop, switchProp, theme, withProp } from 'styled-tools';
import {motion} from 'framer-motion'
// Components
import Icon, { IconWrapper } from '../../common/PNGIcon';
import EditDefaultStake from './EditDefaultStake';
import {
	MatchListSectionHeading,
	MatchListSectionWrapper,
	OpenedBetsSectionHeading,
	SectionHeading,
	SectionWrapper,
} from './Order';
import DesktopOrderSlip from './orderslip/DesktopOrderSlip';
import DesktopPendingSlip from './orderslip/DesktopPendingSlip';
import DesktopUnmatchedSlip from './orderslip/DesktopUnmatchedSlip';
import MatchedslipList from './orderslip/Matchedslip';
import SimpleUnmatchedSlip from './orderslip/SimpleUnmatchedSlip';
import BetSlip from 'src/components/BetSlip/index';
import BookmakerOrder from 'src/components/BetSlip/BookmakerOrder'
import {
	ActionButtonContainer,
	CancelAllButtonWrapper,
	CancelAllUnmatchButtonWrapper,
	PlaceAllButtonWrapper,
} from './orderslip/slipStyles';
import Intl from '../../common/Intl';

import { Notify } from '../../Notification';
import SubscribeOrderSlip from '../../subscription/SubscribeOrderSlip';
import { IOrder } from '../../../graphql/schema';

//Image
import EditIcon from 'src/images/edit_stake.png';
import EditCancelIcon from 'src/images/cancel_stake.png';

// Context
import { withAppContext } from '../../../store/initAppContext';
import { IMatchListContext, withMatchListContext } from '../../../store/MatchListContext';
import { IInput, IOrderListContext, withOrderListContext } from '../../../store/OrderListContext';

// Utils
import { getGTMId } from '../../../util/gtmUtil';
import media from '../../../util/mediaUtil';
import cookieUtil from 'src/util/cookieUtil';
import { ECookieName } from 'src/util/utilModel';

enum EResultSection {
	unmatched = 'UNMATCHED',
	matched = 'MATCHED',
	pending = 'PENDING',
}

const NoOrderSlip: React.SFC = props => {
	return (
		<NoBetText>
			{/* <Intl langKey="ORDERSLIP@EMPTY" /> */}
			{/* No Bets */}
			<BetSlip/>
			<BookmakerOrder/>
		</NoBetText>
	);
};

const EditStakeButton: React.SFC<{ onClick: () => void; isDisplayed: boolean; id?: string }> = props => {
	const { onClick, isDisplayed, id } = props;
	return (
		<EditStakeButtonWrapper onClick={onClick} isDisplayed={isDisplayed} id={id}>
			<Intl langKey="ORDERSLIP@EDIT" />
			{/* Edit Stakes */}
			<img src={EditIcon} alt="EditIcon" />
			{/* <Icon name="gear-edit-stake-red" /> */}
		</EditStakeButtonWrapper>
	);
};

const CancelEditStakeButton: React.SFC<{ onClick: () => void; isDisplayed: boolean; id?: string }> = props => {
	const { onClick, isDisplayed, id } = props;
	return (
		<EditStakeButtonWrapper onClick={onClick} isDisplayed={isDisplayed} id={id}>
			<Intl langKey="ORDERSLIP@CANCEL" />
			{/* Cancel Editing */}
			<img src={EditCancelIcon} alt="EditCancelIcon" />
			{/* <Icon name="x-edit-stake-red" /> */}
		</EditStakeButtonWrapper>
	);
};

interface IOrderslipListState {
	isEditingStake: boolean;
	displayedResultSection: EResultSection;
	isInCancelAllLoading: boolean;
	unmatchCount: number;
}

interface IAppContext {
	app: {
		isMobileWidth: boolean;
	};
}

const pageVariants = {
	initial: {
		opacity: 0,
		x: "-0vw",
		scale: 0.8
	},
	in :{
		opacity: 1,
		x: 0,
		scale: 1
	},
	out: {
		opacity: 0,
		x: "0vw",
		scale: 1.2
	}
}

const pageTransition = {
	type: "tween",
	ease : "anticipate",
	duration: 0.5
}



class OrderslipList extends React.Component<IOrderListContext & IMatchListContext & IAppContext, IOrderslipListState> {
	state = {
		isEditingStake: false,
		displayedResultSection: EResultSection.unmatched,
		isInCancelAllLoading: false,
		unmatchCount: 0,
	};
	changeEditingState = (state: boolean) => () => {
		this.setState(() => ({
			isEditingStake: state,
		}));
	};

	componentDidUpdate(prevProps: IOrderListContext & IMatchListContext) {
		const {
			orderListContext: { isOrderLoading },
			matchListContext: { unmatched, matched, pending },
		} = this.props;
		const {
			orderListContext: { isOrderLoading: wasOrderLoading },
			matchListContext: {
				refetch: refetchMatchList,
				unmatched: prevUnmatched,
				matched: prevMatched,
				pending: prevPending,
				isMatchLoading,
			},
		} = prevProps;

		// 1. refetch
		!isMatchLoading && !isOrderLoading && wasOrderLoading && refetchMatchList();

		if (
			(prevUnmatched.length === 0 && unmatched.length > 0) ||
			(this.state.unmatchCount === 0 && prevUnmatched.length === unmatched.length && unmatched.length > 0)
		) {
			this.setState({ unmatchCount: unmatched.length });
		}

		// 2. switch displayed list when unmatched/matched list updated
		// 確認 unmatched 與 prevUnmatched 相比有沒有任何變動
		(unmatched.length !== prevUnmatched.length ||
			unmatched.some(item => !prevUnmatched.find(prevItem => prevItem.orderNumber === item.orderNumber))) &&
			this.switchDisplayedResultSection(EResultSection.unmatched);
		// 確認 matched 與 prevMatched 相比有沒有任何變動
		(matched.length !== prevMatched.length ||
			matched.some(item => !prevMatched.find(prevItem => prevItem.orderNumber === item.orderNumber))) &&
			this.switchDisplayedResultSection(EResultSection.matched);
		(pending.length !== prevPending.length ||
			pending.some(item => !prevPending.find(prevItem => prevItem.orderNumber === item.orderNumber))) &&
			this.switchDisplayedResultSection(EResultSection.pending);
		if (pending.length > 0 && prevPending.length === 0) {
			Notify.info('Unable to confirm bet. Please check again in 30 seconds to see if bet accepted.');
		}
		// pending 單過期掛 lapsed 跳通知訊息
		if (pending.length < prevPending.length) {
			const pendingSet = new Set(pending);
			const matchOrderNumbers = matched.map(item => item.orderNumber);
			const unmatchOrderNumbers = unmatched.map(item => item.orderNumber);
			const intersectPendingOrder = prevPending.filter(e => !pendingSet.has(e));
			if (
				intersectPendingOrder.length > 0 &&
				!intersectPendingOrder.some(
					item =>
						matchOrderNumbers.includes(item.orderNumber) || unmatchOrderNumbers.includes(item.orderNumber),
				)
			) {
				Notify.info('Bet has been cancelled from provider. Place bet again.');
			}
		}
	}

	handleTrashClick = (key: string) => () => {
		// 延遲 betslip 被移除的時間，避免意外 trigger ClickOutside 元件的 callback
		setTimeout(() => {
			this.props.orderListContext.removeBetEvent([key]);
		});
	};

	saveAndClose = (quickStakes: number[]) => {
		const {
			orderListContext: { saveQuickStakes },
		} = this.props;
		saveQuickStakes(quickStakes);
		this.setState({ isEditingStake: false });
	};

	handlePriceSizeChange = (idx: number) => (params: IInput) => {
		this.props.orderListContext.handlePriceSizeChange(idx)(params);
	};

	switchDisplayedResultSection = (section: EResultSection) => {
		this.setState({ displayedResultSection: section });
	};

	handleCancelAllUnmatchOrders = async (unmatched: IOrder[]) => {
		this.setState({ isInCancelAllLoading: true });
		const {
			matchListContext: { cancelAllUnmatchedOrder },
		} = this.props;
		const result = await cancelAllUnmatchedOrder(unmatched);
		result && this.setState({ isInCancelAllLoading: false, unmatchCount: 0 });
	};
	render() {
		const { isMobileWidth } = this.props.app;
		const isAuth = cookieUtil.get(ECookieName.COOKIE_TOKEN)
		const { isEditingStake, displayedResultSection, unmatchCount } = this.state; //false UNMATCHED 0
	
		const {
			betArray,
			hiddenBetArray,
			betResults,
			placeOrder,
			isOrderLoading,
			placeAllOrder,
			cancelAllOrder,
			exchangeRate,
			quickStakes,
			placingOrderKeyName,
			placeOrderAction,
			isUserLoggedIn
		} = this.props.orderListContext;

		
		const {
			isMatchLoading,
			matched,
			unmatched,
			pending,
			error,
			cancelOrder,
			updateOrder,
			refetch,
			openMobileUnmatchedSlip,
			syncUpdatedUnmatchedSlip,
			removeUpdatedUnmatchedSlip,
		} = this.props.matchListContext;

		return (
			<>
				<SectionWrapper>
					{/* <SectionHeading> */}
						<span>
							{/* <Intl langKey="ORDERSLIP@TITLE" /> */}
						</span>
						{/* <EditStakeButton
							onClick={this.changeEditingState(true)}
							isDisplayed={!isEditingStake}
							id={getGTMId('default_stake_edit')}
						/>
						<CancelEditStakeButton
							onClick={this.changeEditingState(false)}
							isDisplayed={isEditingStake}
							id={getGTMId('default_stake_cancel')}
						/> */}
					{/* </SectionHeading> */}
					{/* <EditDefaultStake
						onSave={this.saveAndClose}
						quickStakes={quickStakes}
						isActive={this.state.isEditingStake}
						changeEditingState={this.changeEditingState}
					/> */}
					{/* The display position of the desktop and mobile devices is different*/}
					{!isMobileWidth && (
						<SlipWrapper>
							{/* <NoOrderSlip /> */}
							<SubscribeOrderSlip variables={betArray}>
								{({ data }: { data: any[] }) => {
									if (!Array.isArray(data)) {
										return null;
									} else if (data.length === 0) {
										return <NoOrderSlip />;
									} else {
										return data.map((item, i: number) => {
											return (
												<div key={i}>
												{/* <motion.div
												initial="initial"
												animate="in"
												exit="out"
												variants={pageVariants}
												transition={pageTransition}
												> */}
												{/* <NoOrderSlip /> */}
												<DesktopOrderSlip
													{...item}
													quickStakes={quickStakes}
													handleTrashClick={this.handleTrashClick(item.keyName)}
													placeOrder={placeOrder}
													isOrderLoading={isOrderLoading}
													handlePriceSizeChange={this.handlePriceSizeChange(i)}
													key={item.keyName}
													keyName={item.keyName}
													betResult={betResults[item.keyName]}
													exchangeRate={exchangeRate}
													isHidden={hiddenBetArray.includes(
														item.selectionInfo && item.selectionInfo.selectionId,
													)}
													placingOrderKeyName={placingOrderKeyName}
													placeOrderAction={placeOrderAction}
													isUserLoggedIn={isUserLoggedIn}
												/>
												{/* </motion.div> */}
												</div>
											);
										});
									}
								}}
							</SubscribeOrderSlip>
							{betArray.length > 1 && (
								<ActionButtonContainer isActive={true}>
									
									<CancelAllButtonWrapper
										onClick={cancelAllOrder}
										disabled={isOrderLoading}
										id={getGTMId('cancel_all_order')}
									>
										<Icon name="trash" />
										<Intl langKey="ORDERSLIP@CANCEL_ALL" />
										{/* Clear All */}
										{/* <Intl langKey="ORDERSLIP@CANCEL_ALL" /> */}
									</CancelAllButtonWrapper>

									<PlaceAllButtonWrapper
										onClick={placeAllOrder}
										disabled={!isAuth || betArray.some(bet => Number(bet.currentSize) <= 0) || isOrderLoading}
										id={getGTMId('place_all_order')}
									>
										<Icon name="check-white" />
										<Intl langKey="ORDERSLIP@PLACE_ALL" />
										{/* Place all the BET */}
										{/* <Intl langKey="ORDERSLIP@PLACE_ALL" />  */}
									</PlaceAllButtonWrapper>
								</ActionButtonContainer>
							)}
						</SlipWrapper>
					)}
				</SectionWrapper>
				<>
					<OpenedBetsSectionHeading>
						<span>
							{/* TODO: add intl */}
							Opened Bets2
						</span>
					</OpenedBetsSectionHeading>
					{/** Code changed - Manoj ( removed error whennot login) */}
					{/* {error && 'error'} */}
					<MatchListTabContainer currentDisplayedList={displayedResultSection}>
						{pending && pending.length > 0 && (
							<MatchListTabWrapper
								onClick={() => this.switchDisplayedResultSection(EResultSection.pending)}
								isActive={displayedResultSection === EResultSection.pending}
								amount={pending.length}
							>
								Pending
							</MatchListTabWrapper>
						)}
						<MatchListTabWrapper
							onClick={() => this.switchDisplayedResultSection(EResultSection.unmatched)}
							isActive={displayedResultSection === EResultSection.unmatched}
							amount={unmatched.length}
						>
							Unmatched
						</MatchListTabWrapper>
						<MatchListTabWrapper
							onClick={() => this.switchDisplayedResultSection(EResultSection.matched)}
							isActive={displayedResultSection === EResultSection.matched}
							amount={matched.length}
						>
							Matched
						</MatchListTabWrapper>
						{/* pending slip */}
					</MatchListTabContainer>
					{!this.state.isInCancelAllLoading && pending && pending.length > 0 && (
						<MatchListSectionWrapper
							isDisplayedOnMobile={displayedResultSection === EResultSection.pending}
						>
							{/* Hide on mobile */}
							<MatchListSectionHeading>
								<span>
									<Intl langKey="PENDING_ORDER@TITLE" />
									{/* Pending Order */}
								</span>
							</MatchListSectionHeading>

							{pending.map((item: IOrder) => {
								return (
									<DesktopPendingSlip
										{...item}
										key={item.orderNumber}
										isMatchLoading={isMatchLoading}
										quickStakes={quickStakes}
										cancelOrder={cancelOrder}
										updateOrder={updateOrder}
										refetch={refetch}
										syncUpdatedUnmatchedSlip={syncUpdatedUnmatchedSlip}
										removeUpdatedUnmatchedSlip={removeUpdatedUnmatchedSlip}
										provider={item.provider}
										p={item.p}
									/>
								);
							})}
						</MatchListSectionWrapper>
					)}
					{/* unmatch slip */}
					{!this.state.isInCancelAllLoading && unmatched && unmatched.length > 0 && unmatchCount > 0 && (
						<MatchListSectionWrapper
							isDisplayedOnMobile={displayedResultSection === EResultSection.unmatched}
						>
							{/* Hide on mobile */}
							<MatchListSectionHeading>
								<span>
									<Intl langKey="UNMATCHED_ORDER@TITLE" />
									{/* UNMATCHED BET */}
								</span>
							</MatchListSectionHeading>
							{unmatched.length > 1 && (
								<ActionButtonContainer isActive={true}>
									<CancelAllUnmatchButtonWrapper
										onClick={async () => await this.handleCancelAllUnmatchOrders(unmatched)}
										disabled={isOrderLoading}
										id={getGTMId('cancel_all_order')}
									>
										<Icon name="trash-orderslip" />
										<Intl langKey="ORDERSLIP@CANCEL_ALL" />
									</CancelAllUnmatchButtonWrapper>
								</ActionButtonContainer>
							)}
							{unmatched.map((item: IOrder) => {
								return isMobileWidth ? (
									<SimpleUnmatchedSlip
										{...item}
										key={item.orderNumber}
										isMatchLoading={isMatchLoading}
										quickStakes={quickStakes}
										cancelOrder={cancelOrder}
										updateOrder={openMobileUnmatchedSlip}
										refetch={refetch}
										removeUpdatedUnmatchedSlip={removeUpdatedUnmatchedSlip}
										provider={item.provider}
										p={item.p}
									/>
								) : (
									<DesktopUnmatchedSlip
										{...item}
										key={item.orderNumber}
										isMatchLoading={isMatchLoading}
										quickStakes={quickStakes}
										cancelOrder={cancelOrder}
										updateOrder={updateOrder}
										refetch={refetch}
										syncUpdatedUnmatchedSlip={syncUpdatedUnmatchedSlip}
										removeUpdatedUnmatchedSlip={removeUpdatedUnmatchedSlip}
										provider={item.provider}
										p={item.p}
									/>
								);
							})}
						</MatchListSectionWrapper>
					)}
					{/* match slip */}
					{matched && matched.length > 0 && (
						<>
							{/* Hide on mobile */}
							<MatchListSectionWrapper
								isDisplayedOnMobile={displayedResultSection === EResultSection.matched}
							>
								{/* <MatchListSectionHeading>
									<span>
										<Intl langKey="MATCHED_ORDER@TITLE" />
										Matched Bet
									</span>
								</MatchListSectionHeading> */}
								<MatchedslipList matchedslipList={matched} />
							</MatchListSectionWrapper>
						</>
					)}
				</>
			</>
		);
	}
}
export default withAppContext(
	withMatchListContext(
		withOrderListContext(OrderslipList, [
			'removeBetEvent',
			'handlePriceSizeChange',
			'placeAllOrder',
			'betArray',
			'hiddenBetArray',
			'betResults',
			'placeOrder',
			'cancelAllOrder',
			'isOrderLoading',
			'exchangeRate',
			'quickStakes',
			'saveQuickStakes',
			'placingOrderKeyName',
			'placeOrderAction',
			'isUserLoggedIn'
		]),
		[
			// 'pending',
			'matched',
			'unmatched',
			'pending',
			'loading',
			'error',
			'cancelOrder',
			'updateOrder',
			'cancelAllUnmatchedOrder',
			'refetch',
			'isMatchLoading',
			'openMobileUnmatchedSlip',
			'syncUpdatedUnmatchedSlip',
			'removeUpdatedUnmatchedSlip',
		],
	),
);

// Styled components

const NoBetText: any = styled.div `
	width: 100%;
	display: flex;
	align-items: center;
	justify-content: space-between;
`;

const SlipWrapper: any = styled.div`
	position: relative;
    z-index: 0;
`;

const EditStakeButtonWrapper: any = styled.button`
	font-size: 14px;
	display: ${ifProp('isDisplayed', 'flex', 'none')};
	align-items: center;
	background-color: transparent;
	border: 0;
	cursor: pointer;
	color: #09285F;
	color: ${ifProp('isDisplayed', '#09285F', '#D22149')};
	text-decoration: underline;
	font-weight: bold;

	img {
		width: 10px;
		height: 10px;
		margin-left: 4px;
	}

	&:focus {
		outline: 0;
	}

	${IconWrapper} {
		margin-left: 0.5em;
	}
`;

const MatchListTabWrapper: any = styled.button`
	border: 0;
	border-radius: 0;
	padding: .8em;
	cursor: pointer;
	font-size: 13px;
	display: flex;
	justify-content: center;
	align-items: center;
	width: 50%;
	background-color: transparent;
	position: relative;
	z-index: 0;

	&:focus {
		outline: 0;
	}

	&::after {
		content: '${prop('amount')}';
		display: flex;
		justify-content: center;
		align-items: center;
		font-size: 10px;
		width: 1.75em;
		height: 1.75em;
		margin-left: 1em;
		border-radius: 50%;
	}


`;

const MatchListTabContainer: any = styled.div`
	display: none;
	margin-bottom: 1em;
	position: relative;
	z-index: 0;

	&::before,
	&::after {
		content: '';
		display: block;
		position: absolute;
		bottom: 0;
		left: 0;
		height: 2px;
	}

	&::before {
		z-index: 0;
		right: 0;
	}

	&::after {
		z-index: 0;
		width: 50%;
		transition: transform 0.5s ease;
		transform: translateX(
			${switchProp('currentDisplayedList', {
				[EResultSection.unmatched]: 0,
				[EResultSection.matched]: '100%',
			})}
		);
	}

	${media.sm`
		display: flex;
	`};
`;
