export type Maybe<T> = T | null;

export interface IMainExposureInput {
	
}

export interface IMarketEventInput {
	categoryId?: Maybe<number>;

	isInplay?: Maybe<boolean>;

	isFavorite?: Maybe<boolean>;
	/** 是否為 bookmaker (default: false) */
	isBookmaker?: Maybe<boolean>;

	competitionId?: Maybe<string>;

	eventId?: Maybe<string>;

	isOrder?: Maybe<boolean>;
}

export interface IMarketTypeInput {
	isOnlyMatchOdds?: Maybe<boolean>;
}

export interface ISearchInput {
	keyword: string;
}

export interface IHotEventsInput {
	categoryId?: Maybe<number>;
}

export interface IValidateInput {
	memberId: number;

	token?: Maybe<string>;
}

export interface ICreditInput {
	currentMember?: Maybe<string>;
}

export interface IUserInfoInput {
	token: string;
}

export interface ITransactionInput {
	/** 交易類型 */
	type: ITransactionType;
	/** 時間區間 */
	period: IPeriod;
}

export interface IPeriod {
	/** 起始時間 */
	start: Date;
	/** 結束時間 */
	end: Date;
}

export interface IProfitLossInput {
	/** 下注種類 */
	categoryId?: Maybe<number>;
	/** 時間區間 */
	period: IPeriod;
}

export interface IMyOrderInput {
	isSettled: boolean;

	period: IPeriod;
}

export interface IPositionInput {
	/** 版位名稱 */
	code: string;
}

export interface ISystemInfoInput {
	/** 品牌 ID */
	brandId: number;
}

export interface ISystemConfigInput {
	key: string;
}

export interface IDownlineInput {
	/** 關聯帳號資訊(非關聯帳號可不帶) */
	persona?: Maybe<string>;
	/** 會員 */
	target: string;
}

export interface IDownlineListInput {
	/** 關聯帳號資訊(非關聯帳號可不帶) */
	persona?: Maybe<string>;
	/** 上線 */
	target: string;
}

export interface IAllDownlineListInput {
	/** 關聯帳號資訊(非關聯帳號可不帶) */
	persona?: Maybe<string>;
}

export interface IAllUpperlineListInput {
	/** 關聯帳號資訊(非關聯帳號可不帶) */
	persona?: Maybe<string>;
	/** 會員 */
	target?: Maybe<string>;
}

export interface IAgentPersonaInput {
	/** 關聯帳號資訊(非關聯帳號可不帶) */
	persona?: Maybe<string>;
}

export interface IAgentPersonaTargetInput {
	/** 關聯帳號資訊(非關聯帳號可不帶) */
	persona?: Maybe<string>;
	/** 會員 */
	target: string;
}

export interface ISportOrderListInput {
	/** 關聯帳號資訊(非關聯帳號可不帶) */
	persona?: Maybe<string>;

	target?: Maybe<string>;
	/** 0:Unmatch, 1:Matched, 2:Settled, 3: Checkout, 4:Cancelled, 5:Matched & Unmatch */
	matchStatus: number;
	/** 搜尋起始時間 */
	startDate: Date;
	/** 搜尋結束時間 */
	endDate: Date;
	/** 搜尋的賽事, 預設為-1(全部) */
	sportType?: Maybe<number>;
	/** 搜尋的市場, 預設為-1(全部) */
	marketType?: Maybe<number>;
	/** 搜尋賠率最小值, 預設為-1(全部) */
	priceFrom?: Maybe<number>;
	/** 搜尋賠率最大值, 預設為-1(全部) */
	priceTo?: Maybe<number>;
	/** 搜尋下注數量最小值, 預設為-1(全部) */
	stakeFrom?: Maybe<number>;
	/** 搜尋下注數量最大值, 預設為-1(全部) */
	stakeTo?: Maybe<number>;
	/** 搜尋賽事名稱, 預設為空(全部) */
	eventName?: Maybe<string>;
	/** 搜尋下線名稱, 預設為空(全部) */
	searchName?: Maybe<string>;
	/** 排序, 0: BetList, 1: Market, 2: Agent, 預設為0 */
	orderType?: Maybe<number>;
	/** 是否只找有延後轉帳記號, 預設為false(全部) */
	isFlagOnly?: Maybe<boolean>;
	/** 搜尋下注IP, 預設為空(全部) */
	searchIp?: Maybe<string>;
	/** 搜尋注單單號, 預設為空(全部) */
	searchOrderNumber?: Maybe<string>;

	page: number;

	count: number;
	/** 時區誤差 下載用 */
	timezoneOffset?: Maybe<number>;
}

export interface ISportOrderMatchListInput {
	/** 注單編號 */
	orderNumber: string;
	/** 是否已經結算 */
	isSettled: boolean;
	/** 來源 */
	marketSource: IMarketSource;
}

export interface IWatchListInput {
	/** 關聯帳號資訊(非關聯帳號可不帶) */
	persona?: Maybe<string>;

	marketId?: Maybe<string>;
}

export interface IFancyExposureInput {
	/** 關聯帳號資訊(非關聯帳號可不帶) */
	persona?: Maybe<string>;

	eventId?: Maybe<string>;

	competitionId?: Maybe<string>;
}

export interface IMyActivityInput {
	persona?: Maybe<string>;

	target?: Maybe<string>;
	/** 搜尋起始時間 */
	startDate: Date;
	/** 搜尋結束時間 */
	endDate: Date;

	sportInput: ISportPlInfoInput;

	exposureType: IExposureType;

	timezone: number;
}

export interface ISportPlInfoInput {
	/** 搜尋的賽事, 預設為-1(全部) */
	sportType?: Maybe<number>;
	/** 搜尋的市場, 預設為-1(全部) */
	marketType?: Maybe<number>;
	/** 搜尋賽事名稱, 預設為空(全部) */
	eventName?: Maybe<string>;
	/** 搜尋下線名稱, 預設為空(全部) */
	searchName?: Maybe<string>;
	/** 排序, 0: BetList, 1: Market, 2: Agent, 預設為0 */
	orderType?: Maybe<number>;
}

export interface IAccountStatementInput {
	/** 關聯帳號資訊(非關聯帳號可不帶) */
	persona?: Maybe<string>;
	/** 會員 */
	target: string;
	/** 開始時間 */
	from: Date;
	/** 結束時間 */
	to: Date;
	/** 時區的差值 */
	timezone: number;
	/** 是否為Betting PL頁 */
	isPL: boolean;
}

export interface ISettlingStatementInput {
	/** 關聯帳號資訊(非關聯帳號可不帶) */
	persona?: Maybe<string>;
	/** 會員 */
	target: string;
	/** 開始時間 */
	from: Date;
	/** 結束時間 */
	to: Date;
	/** 時區的差值 */
	timeZoneOffset: number;
}

export interface IEventDetailInput {
	/** 關聯帳號資訊(非關聯帳號可不帶) */
	persona?: Maybe<string>;
	/** 會員 */
	target: string;
	/** 賽事 */
	eventId: string;
	/** 聯賽 */
	competitionId: string;
	/** 賭盤 */
	marketId: string;
}

export interface IAgentNetExposureInput {
	persona?: Maybe<string>;

	target?: Maybe<string>;

	isPt: boolean;
}

export interface ISportMarketDataInput {
	/** 關聯帳號資訊(非關聯帳號可不帶) */
	persona?: Maybe<string>;
	/** 會員 */
	target?: Maybe<string>;

	isFancy?: Maybe<boolean>;

	marketId?: Maybe<string>;

	fancyId?: Maybe<string>;

	competitionId?: Maybe<string>;

	eventId?: Maybe<string>;

	categoryId?: Maybe<number>;

	marketTypeId?: Maybe<number>;

	isPt: boolean;
}

export interface ISportProfitLossInput {
	/** 切換的代理人 決定下線的資料 */
	persona: string;
	/** 登入的代理人類別 決定能看的資料 */
	agentType: string;
	/** 特定代理下線的資料 */
	targetId: number;

	targetType: string;

	targetDate?: Maybe<Date>;
	/** report類別 */
	reportLevel: IReportLevel;
	/** 彙整完的資料 */
	isGroupByDay: boolean;
	/** 時區差距時間 localeOffset - timezoneOffset, 用來顯示前台時間 */
	timezoneOffset: number;
	/** 搜尋起始時間 */
	startDate: Date;
	/** 搜尋結束時間 */
	endDate: Date;
	/** 搜尋category, 預設為-1(全部) */
	categoryId?: Maybe<number>;
	/** 搜尋market type, 預設為-1(全部) */
	marketTypeId?: Maybe<number>;
	/** 搜尋event name, 預設為空(全部) */
	eventName?: Maybe<string>;
	/** 搜尋client name, 預設為空(全部) */
	searchName?: Maybe<string>;
	/** 搜尋ip, 預設為空(全部) */
	searchIp?: Maybe<string>;
}

export interface ISportProfitLossOrderInput {
	/** 切換的代理人 決定下線的資料 */
	persona: string;
	/** 登入的代理人類別 決定能看的資料 */
	agentType: string;
	/** 搜尋起始時間 */
	startDate: Date;
	/** 搜尋結束時間 */
	endDate: Date;
	/** 過濾event Id */
	eventId: string;
	/** 特定Id注單 */
	targetId?: Maybe<number>;
	/** 時區差距時間 localeOffset - timezoneOffset, 用來顯示前台時間 */
	timezoneOffset: number;
	/** 搜尋market type, 預設為-1(全部) */
	marketTypeId?: Maybe<number>;
	/** 過濾market Id, 預設為-1(全部) */
	marketId?: Maybe<string>;
	/** 搜尋client name, 預設為空(全部) */
	searchName?: Maybe<string>;
	/** 搜尋ip, 預設為空(全部) */
	searchIp?: Maybe<string>;
}

export interface INotificationListInput {
	id?: Maybe<number>;
	/** 版位 */
	positionCode?: Maybe<IPositionCode>;
	/** 建立者 */
	creator?: Maybe<string>;
	/** 是否啟用 */
	enable?: Maybe<boolean>;
	/** 起始日 */
	startDate: Date;
	/** 到期日 */
	endDate: Date;
}

export interface IAgentMarketListInput {
	/** 關聯帳號資訊(非關聯帳號可不帶) */
	persona?: Maybe<string>;
	/** 開賽時間 */
	from: Date;
	/** 開賽時間 */
	to: Date;
	/** 總下注量 */
	min?: Maybe<number>;
	/** 總下注量 */
	max?: Maybe<number>;
	/** 賽事名稱 */
	eventName?: Maybe<string>;
	/** 球種ID */
	categoryId?: Maybe<number>;

	isPast?: Maybe<boolean>;

	isResettle?: Maybe<boolean>;
}

export interface IAgentMarketFancyListInput {
	/** 賽事ID */
	eventId: string;
	/** 聯賽ID */
	competitionId: string;
}

export interface IAgentMarketFancyMultipleInput {
	/** 賽事ID */
	eventId: string;
	/** 聯賽ID */
	competitionId: string;
}

export interface IAgentMultipleFancyLogsInput {
	/** market id */
	marketId: string;
}

export interface IAgentSelectionLogInput {
	/** selection id */
	selectionId: number;
}

export interface IBalanceIntoInput {
	/** 關聯帳號資訊(非關聯帳號可不帶) */
	persona?: Maybe<string>;
	/** 會員(若不帶則查詢登入者) */
	target?: Maybe<string>;
	/** 平台(若不帶則全給) */
	platformCodes?: Maybe<string[]>;
}

export interface IGameStatementInput {
	/** 關聯帳號資訊(非關聯帳號可不帶) */
	persona?: Maybe<string>;
	/** 會員(若為後台可不帶) */
	target?: Maybe<string>;
	/** 是否為後台顯示報表 */
	isAdmin?: Maybe<boolean>;
	/** 時區 */
	timezone: number;
	/** 類型 */
	type: IStatementType;
}

export interface ILoginInput {
	username?: Maybe<string>;

	password?: Maybe<string>;

	brandId?: Maybe<number>;
	/** 登入資訊 */
	authInfo?: Maybe<string>;
	/** 系統登入時間 (YYYY-MM-DD hh:mm:ss) */
	localCreatedTime?: Maybe<string>;
	/** 裝置類別 (1: desktop size, 2: mobile size) */
	deviceType?: Maybe<number>;
}

export interface ICasinoLogoutInput {
	platformCode: string;
}

export interface IFavoriteInput {
	marketId: string;

	isAdd: boolean;
}

export interface IPasswordInput {
	oldPassword: string;

	newPassword: string;
}

export interface IValidOtpRequest {
	authOtp?: Maybe<string>;
}

export interface IBindOtpRequest {
	authOtp?: Maybe<string>;

	secretCode?: Maybe<string>;
}

export interface IRemoveOtpRequest {
	/** 刪除OTP的目標 */
	target?: Maybe<string>;
}

export interface IPlaceOrdersInput {
	/** 聯賽 id */
	competitionId: string;
	/** 賽事 id */
	eventId: string;
	/** 玩法 id */
	marketId: string;
	/** 下注種類 */
	categoryId: string;
	/** 下注類別 */
	betfairOrderType: IBetfairOrderType;
	/** Selection ID */
	selectionId: string;
	/** Lay/Back */
	side: ISide;
	/** 讓分 */
	handicap?: Maybe<number>;
	/** coin */
	size?: Maybe<number>;
	/** 賠率 */
	price?: Maybe<number>;
	/** 虧損 (?) */
	liability?: Maybe<number>;
	/** 一鍵下注 */
	isOneClickOrder?: Maybe<boolean>;
	/** 玩法類別 (1: main / 2: fancy) */
	marketType?: Maybe<number>;
	/** 賭盤來源 */
	marketSource?: Maybe<IMarketSource>;
	/** Fancy 的 side value */
	sideValue?: Maybe<number>;
	/** 系統登入時間 (YYYY-MM-DD hh:mm:ss) */
	localCreatedTime?: Maybe<string>;
	/** betfair 下注延遲 */
	betDelay?: Maybe<number>;
}

export interface ICancelOrdersInput {
	/** 玩法 id */
	marketId: string;
	/** 取消單號 */
	orderNumber: string;
	/** 系統登入時間 (YYYY-MM-DD hh:mm:ss) */
	localCreatedTime?: Maybe<string>;
}

export interface IUpdateOrderInput {
	/** 聯賽 id */
	competitionId: string;
	/** 賽事 id */
	eventId: string;
	/** 玩法 id */
	marketId: string;
	/** 下注種類 */
	categoryId: string;
	/** 下注類別 */
	betfairOrderType: IBetfairOrderType;
	/** Selection ID */
	selectionId: string;
	/** Lay/Back */
	side: ISide;
	/** 讓分 */
	handicap?: Maybe<number>;
	/** coin */
	size?: Maybe<number>;
	/** 賠率 */
	price?: Maybe<number>;
	/** 虧損 (?) */
	liability?: Maybe<number>;
	/** 一鍵下注 */
	isOneClickOrder?: Maybe<boolean>;
	/** 玩法類別 (1: main / 2: fancy) */
	marketType?: Maybe<number>;
	/** 賭盤來源 */
	marketSource?: Maybe<IMarketSource>;
	/** Fancy 的 side value */
	sideValue?: Maybe<number>;
	/** 更新單號 */
	orderNumber: string;
	/** 系統登入時間 (YYYY-MM-DD hh:mm:ss) */
	localCreatedTime?: Maybe<string>;
	/** betfair 下注延遲 */
	betDelay?: Maybe<number>;
}

export interface ICreateAccountInput {
	/** 關聯帳號資訊(非關聯帳號可不帶) */
	persona?: Maybe<string>;
	/** 使用者資訊(username, password) */
	authInfo: string;
	/** 貨幣ID(建立Super Admin必須帶) */
	currencyId?: Maybe<number>;
	/** 貨幣轉換率(建立Super Admin必須帶) */
	currencyRate?: Maybe<number>;
	/** 帳號狀態 */
	status?: Maybe<IAccountStatus>;
	/** 個人分成 */
	takePt: number;
	/** 下線總分成 */
	givePt: number;
	/** 下線金額 */
	credit: number;
	/** 說明 */
	remark?: Maybe<string>;
}

export interface ICreateSubAccountInput {
	/** 關聯帳號資訊(非關聯帳號可不帶) */
	persona?: Maybe<string>;
	/** 使用者資訊 */
	authInfo: string;
	/** 新使用者帳戶 */
	username?: Maybe<string>;
	/** 新使用者密碼 */
	password?: Maybe<string>;
	/** 帳號狀態 */
	status?: Maybe<IAccountStatus>;
	/** 說明 */
	remark?: Maybe<string>;
}

export interface IUpdateAccountInput {
	/** 關聯帳號資訊(非關聯帳號可不帶) */
	persona?: Maybe<string>;
	/** 更新資訊(password) */
	authInfo: string;
	/** 使用者 */
	target: string;
	/** 帳號狀態 */
	status: IAccountStatus;
	/** 各球種PT分成 */
	ptList: IPtListSetting[];
	/** 貨幣ID(更新Super Admin必須帶) */
	currencyId?: Maybe<number>;
	/** 貨幣轉換率(更新Super Admin必須帶) */
	currencyRate?: Maybe<number>;

	orderLimitList: IOrderLimitSetting[];
	/** 下線最高額度 */
	downlineMaxCredit: number;
	/** 使用者最高額度 */
	clientMaxCredit: number;
	/** 當前額度 */
	currentCreditLimit: number;
	/** 更新額度 */
	modifiedCreditLimit: number;
	/** 說明 */
	remark?: Maybe<string>;
}

export interface IPtListSetting {
	categoryId: number;

	takePt: number;

	givePt: number;
}

export interface IOrderLimitSetting {
	categoryId: number;

	minBet: number;

	maxBet: number;

	maxMarket: number;
}

export interface IUpdateSubAccountInput {
	/** 關聯帳號資訊(非關聯帳號可不帶) */
	persona?: Maybe<string>;
	/** 更新資訊 */
	authInfo: string;
	/** 新使用者密碼 */
	password?: Maybe<string>;
	/** 使用者ID */
	memberId: number;
	/** 帳號狀態 */
	status?: Maybe<IAccountStatus>;
}

export interface IAddAssociatedAccountInput {
	/** 關聯帳號資訊(非關聯帳號可不帶) */
	persona?: Maybe<string>;
	/** 登入資訊 */
	authInfo: string;
	/** 要關聯的帳號 */
	username?: Maybe<string>;
	/** 要關聯的帳號密碼 */
	password?: Maybe<string>;
}

export interface IPendingTransferInput {
	orderNumber: string;

	source: IMarketSource;

	pendingStatus: boolean;
	/** 注單當下狀態 0: current, 1: past */
	orderStatus: number;
}

export interface IUpdateOrderRemarkInput {
	orderNumber: string;

	source: IMarketSource;
	/** 注單當下狀態 0: current, 1: past */
	orderStatus: number;

	clientRemark?: Maybe<string>;

	agentRemark?: Maybe<string>;
}

export interface IVoidInplayInput {
	agentRemark?: Maybe<string>;

	clientRemark?: Maybe<string>;

	target: string;

	voidDatas: IVoidInplayData[];
}

export interface IVoidInplayData {
	marketId: string;

	orderNumber: string;

	source: IMarketSource;
}

export interface IAgentCancelOrderInput {
	agentRemark?: Maybe<string>;

	clientRemark?: Maybe<string>;

	orders: IOrderInfoInput[];
}

export interface IOrderInfoInput {
	orderNumber?: Maybe<string>;

	settleNumber?: Maybe<string>;

	source?: Maybe<IMarketSource>;
}

export interface IAgentMarketUpdateInput {
	/** 賭盤ID */
	marketId: string;
	/** 啟用 */
	isEnable: boolean;
	/** 開盤 */
	isOrder: boolean;
	/** 手動標記 */
	isManual?: Maybe<boolean>;
	/** 賽事ID */
	eventId: string;
	/** 賽事種類ID */
	categoryId: number;
	/** 賭盤種類ID */
	marketTypeId: number;
	/** 賭盤名稱(alert用) */
	name?: Maybe<string>;
}

export interface IAgentEventUpdateInput {
	/** 賽事ID */
	eventId: string;
	/** 啟用 */
	isEnable: boolean;
	/** 開盤 */
	isOrder: boolean;
	/** 賽事種類ID */
	categoryId: number;
	/** 賽事名稱(alert用) */
	name?: Maybe<string>;
	/** event wicket fancy 開關 */
	isSync?: Maybe<boolean>;
}

export interface IAgentCompetitionUpdateInput {
	/** 聯賽ID */
	competitionId: string;
	/** 啟用 */
	isEnable: boolean;
	/** 開盤 */
	isOrder: boolean;
	/** 賽事種類ID */
	categoryId: number;
	/** 聯賽名稱(alert用) */
	name?: Maybe<string>;
}

export interface IAutoSettingUpdateInput {
	/** 球種ID */
	categoryId?: Maybe<number>;

	enableSecond?: Maybe<number>;

	enableMatched?: Maybe<number>;

	orderSecond?: Maybe<number>;

	orderMatched?: Maybe<number>;

	enableSecondFancy?: Maybe<number>;

	enableMatchedFancy?: Maybe<number>;

	orderSecondFancy?: Maybe<number>;

	orderMatchedFancy?: Maybe<number>;
}

export interface IBlacklistUpdateInput {
	/** 賽事ID */
	eventId: string;
	/** 賽事種類ID */
	categoryId: number;
	/** 賭盤種類ID */
	marketTypeId: number;
}

export interface ICompetitionBlacklistUpdateInput {
	/** 賽事ID */
	competitionId: string;
	/** 賽事種類ID */
	categoryId: number;
}

export interface IResettleMarketInput {
	/** 賭盤ID */
	marketId: string;
	/** 賽事ID */
	eventId: string;
	/** BETFAIR 或 SNK */
	source: IMarketSource;
}

export interface ICancelMarketInput {
	source: string;

	marketId: string;

	agentRemark?: Maybe<string>;

	clientRemark?: Maybe<string>;
}

export interface IAgentMarketUpdateFancyInput {
	/** ID */
	id: string;
	/** 標題 */
	title: string;
	/** CategoryId */
	categoryId: number;
	/** ResourceId */
	resourceId?: Maybe<string>;
	/** EventId */
	eventId: string;
	/** CompetitionId */
	competitionId: string;
	/** StartedAt */
	startedAt: Date;
	/** 敘述 */
	description: string;
	/** Runs Back */
	backValue: number;
	/** Runs Lay */
	layValue: number;
	/** backOdds */
	backOdds: number;
	/** layOdds */
	layOdds: number;
	/** back status */
	backStatus: string;
	/** lay status */
	layStatus: string;
	/** back size */
	backSize: number;
	/** lay size */
	laySize: number;
	/** inPlay */
	isPlay: boolean;
	/** isBallRunning */
	isBallRunning: boolean;
	/** 狀態 */
	status: string;
	/** maxPerOrderCredit */
	maxPerOrderCredit: number;
	/** maxPerMarketExposure */
	maxPerMarketExposure: number;
	/** 新增Fancy的人 */
	creator: string;
	/** isDeleteFlag */
	isDeleteFlag: boolean;
	/** settle分數 */
	finalScore?: Maybe<number>;
	/** 排序 */
	sortOrder?: Maybe<number>;
	/** 是否同步 */
	isSync?: Maybe<boolean>;
	/** Resource From  */
	resourceFrom: string
}

export interface IAgentMarketUpdateFancyOrderInput {
	/** Fancy順序資料 */
	strFancyList: string;
	/** 新增Fancy的人 */
	strCreator: string;
	/** 打 pubsub 用 */
	eventId?: Maybe<string>;
}

export interface IUpdateMultiSelectionFancyInput {
	/** ID */
	id: string;
	/** 標題 */
	title: string;
	/** CategoryId */
	categoryId: number;
	/** EventId */
	eventId: string;
	/** CompetitionId */
	competitionId: string;
	/** StartedAt */
	startedAt: Date;
	/** 敘述 */
	description: string;
	/** inPlay */
	isPlay: boolean;
	/** isBallRunning，此值為Interface，db沒有存此欄位（讓所有的selections的isRunning變成true，若null則不修改） */
	isBallRunning?: Maybe<boolean>;
	/** 狀態 */
	status: string;
	/** maxPerOrderCredit */
	maxPerOrderCredit: number;
	/** maxPerMarketExposure */
	maxPerMarketExposure: number;
	/** settle選項ID */
	settleSelectionId?: Maybe<number>;
	/** 盤口種類 */
	oddType: IOddType;
}

export interface IUpdateMultiSelectionInput {
	/** 賭盤ID */
	marketId: string;
	/** 選項ID (若-1則新增) */
	selectionId: number;
	/** 選項名稱 */
	selectionName: string;
	/** layOdds */
	layOdds: number;
	/** backOdds */
	backOdds: number;
	/** isBallRunning 若null則不修改 */
	isBallRunning: boolean;
	/** back狀態 */
	backStatus: string;
	/** lay狀態 */
	layStatus: string;
	/** back總下注量 */
	backSize: number;
	/** lay總下注量 */
	laySize: number;
	/** 原本排序的位置(若新增時則給0) */
	sortPriority: number;
	/** 打 pubsub 用 */
	eventId?: Maybe<string>;

	categoryId?: Maybe<number>;
}

export interface IUpdateMultiSelectionOrderInput {
	/** 選項順序資料('{order},{id}|{order},{id}|....') */
	sortOrderList: string;
	/** 打 pubsub 用 */
	eventId?: Maybe<string>;
	/** 打 pubsub 用 */
	marketId?: Maybe<string>;
}

export interface IGamePlayGameInput {
	/** 遊戲平台 */
	platformCode: string;
	/** 遊戲代碼 */
	gameCode: string;
	/** 時區 */
	timezone?: Maybe<number>;
}

export interface IGameTransferInput {
	/** 轉帳類別 (Deposit / Withdraw) */
	type: string;
	/** 轉帳目標 (pt、ag) */
	platformCode: string;
	/** 存款金額 */
	amount: number;
}

export interface ISingleTransferInput {
	/** 關聯帳號資訊(非關聯帳號可不帶) */
	persona?: Maybe<string>;
	/** 會員 */
	target: string;
	/** 金額 */
	amount: number;
	/** 轉帳種類(WITHDRAW 或 DEPOSIT) */
	transactionType: string;
}

export interface IBatchTransferInput {
	/** 關聯帳號資訊(非關聯帳號可不帶) */
	persona?: Maybe<string>;
	/** 要操作的對象的陣列 */
	items: IItem[];
}

export interface IItem {
	target: string;

	credit: number;

	transactionTypeId: string;

	receiverType: string;
}

export interface IMemberOrderInput {
	memberId: number;
}

export interface IMemberInfoInput {
	token: string;
}

export interface IMemberFavoriteInput {
	eventId?: Maybe<string[]>;

	clientTime?: Maybe<Date>;
}

export interface IMarketListInput {
	categoryId: number;

	competitionId?: Maybe<string>;

	eventId?: Maybe<string>;

	isInplay?: Maybe<boolean>;

	clientTime?: Maybe<Date>;

	deviceType?: Maybe<number>;
}

export interface ISelectionInfoInput {
	marketId: string;

	selectionId: string;

	marketSource: IMarketSource;

	marketType: number;

	side: ISide;
}

export interface IAgentTargetInput {
	/** 關聯帳號資訊(非關聯帳號可不帶) */
	target?: Maybe<string>;
}

export enum IMarketSource {
	Snk = 'SNK',
	Betfair = 'BETFAIR',
}

export enum ISide {
	Back = 'BACK',
	Lay = 'LAY',
}

export enum ITransactionType {
	Betting = 'BETTING',
	Settling = 'SETTLING',
	All = 'ALL',
}

export enum IAccountStatus {
	Active = 'ACTIVE',
	Suspended = 'SUSPENDED',
	Locked = 'LOCKED',
	Inactive = 'INACTIVE',
	Closed = 'CLOSED',
}

export enum IMemberType {
	SuperCompany = 'SUPER_COMPANY',
	CompanyAdmin = 'COMPANY_ADMIN',
	SuperAdmin = 'SUPER_ADMIN',
	Admin = 'ADMIN',
	Super = 'SUPER',
	Master = 'MASTER',
	Client = 'CLIENT',
}

export enum IExposureType {
	Total = 'TOTAL',
	Agent = 'AGENT',
}

export enum IReportLevel {
	Market = 'MARKET',
	Agent = 'AGENT',
	Client = 'CLIENT',
}

export enum IPositionCode {
	MainTopMarquee = 'MAIN_TOP_MARQUEE',
	AgentSideMarquee = 'AGENT_SIDE_MARQUEE',
}

export enum IStatementType {
	Sport = 'SPORT',
	Casino = 'CASINO',
}

export enum IBetfairOrderType {
	Limit = 'LIMIT',
	LimitOnClose = 'LIMIT_ON_CLOSE',
	MarketOnClose = 'MARKET_ON_CLOSE',
}

export enum IOddType {
	Am = 'AM',
	Eu = 'EU',
}

export enum IBetTargetType {
	Payout = 'PAYOUT',
	BackersProfit = 'BACKERS_PROFIT',
}

export enum IOrderType {
	Mix = 'MIX',
	Tp = 'TP',
	Snk = 'SNK',
}

export enum IPersistenceType {
	Lapse = 'LAPSE',
	Persist = 'PERSIST',
	MarketOnClose = 'MARKET_ON_CLOSE',
}

export type Date = any;

/** The `JSON` scalar type represents JSON values as specified by [ECMA-404](http://www.ecma-international.org/publications/files/ECMA-ST/ECMA-404.pdf). */
export type Json = any;

/** The `JSONObject` scalar type represents JSON objects as specified by [ECMA-404](http://www.ecma-international.org/publications/files/ECMA-ST/ECMA-404.pdf). */
export type JsonObject = any;

// ====================================================
// Scalars
// ====================================================

// ====================================================
// Interfaces
// ====================================================

export interface IResponseInterface {
	/** 狀態 */
	success: boolean;
	/** 狀態代碼 */
	code?: Maybe<string>;
	/** 訊息 */
	message: string;
}

export interface IMultipleFancyInterface {
	/** id */
	id: string;
	/** resourceId */
	resourceId?: Maybe<string>;
	/** name */
	name: string;
	/** categoryId */
	categoryId?: Maybe<number>;
	/** EventId */
	eventId?: Maybe<string>;
	/** oddType */
	oddType?: Maybe<string>;
	/** 敘述 */
	description?: Maybe<string>;
	/** maxPerOrderCredit */
	maxPerOrderCredit?: Maybe<number>;
	/** maxPerMarketExposure */
	maxPerMarketExposure?: Maybe<number>;
	/** 狀態 */
	status?: Maybe<string>;
	/** 排序順序 */
	sortOrder?: Maybe<number>;
	/** selection */
	selections?: Maybe<ISelection[]>;
	/** 創建者 */
	creator?: Maybe<string>;
	/** 創立時間 */
	createdAt?: Maybe<Date>;
	/** 更新者 */
	updater?: Maybe<string>;
	/** 更新時間 */
	updatedAt?: Maybe<Date>;
}

export interface ISelectionInterface {
	/** id */
	id: number;
	/** marketId */
	marketId?: Maybe<number>;
	/** 名稱 */
	name: string;
	/** status */
	status: string;
	/** backPrice */
	backPrice?: Maybe<number>;
	/** layPrice */
	layPrice?: Maybe<number>;
	/** isRunning */
	isRunning?: Maybe<boolean>;
	/** sortPriority */
	sortPriority?: Maybe<number>;
	/** backStatus */
	backStatus?: Maybe<string>;
	/** layStatus */
	layStatus?: Maybe<string>;
	/** back尚餘數量 */
	backAvailableSize?: Maybe<number>;
	/** back全部數量 */
	backTotalSize?: Maybe<number>;
	/** lay尚餘數量 */
	layAvailableSize?: Maybe<number>;
	/** lay全部數量 */
	layTotalSize?: Maybe<number>;
	/** 創建者 */
	creator?: Maybe<string>;
	/** 創立時間 */
	createdAt?: Maybe<Date>;
	/** 更新者 */
	updater?: Maybe<string>;
	/** 更新時間 */
	updatedAt?: Maybe<Date>;
}

// ====================================================
// Types
// ====================================================

export interface IQuery {
	/** redis 資料 */
	redis?: Maybe<IRedisQuery>;
	/** markets 賽事列表 */
	markets?: Maybe<IMarketQuery>;
	/** 會員資料 */
	member?: Maybe<IMemberQuery>;
	/** 側邊選單 */
	menu?: Maybe<IMenuQuery>;
	/** 交易 */
	exchange?: Maybe<IExchangeQuery>;
	/** 版位 */
	position?: Maybe<IPositionQuery>;
	/** 系統 */
	system?: Maybe<ISystemQuery>;
	/** 代理站會員資料 */
	agentMember?: Maybe<IAgentMemberQuery>;
	/** 代理站下注資料 */
	agentExchange?: Maybe<IAgentExchangeQuery>;
	/** 代理站市場資料 */
	agentMarketMgmt?: Maybe<IAgentMarketMgmtQuery>;
	/** 遊戲平台 */
	game?: Maybe<IGameQuery>;
}

export interface IRedisQuery {
	/** 新增 Redis 資料 */
	get?: Maybe<IStringResult>;
}

export interface IStringResult {
	value?: Maybe<string>;
}

export interface IMarketQuery {
	list?: Maybe<IEventInfo[]>;

	search?: Maybe<ISearchEventInfo[]>;

	hot?: Maybe<IEventInfo[]>;
}

export interface IEventInfo {
	/** 賽事編號 */
	eventId?: Maybe<string>;
	/** 賽事種類編號 */
	categoryId?: Maybe<number>;
	/** 賽事種類 */
	categoryName?: Maybe<string>;
	/** 賽事名稱 */
	eventName?: Maybe<string>;
	/** 杯賽編號 */
	competitionId?: Maybe<string>;
	/** 杯賽名稱 */
	competitionName?: Maybe<string>;
	/** 開賽時間 */
	openDate?: Maybe<Date>;
	/** 賭盤開始時間 */
	marketStartTime?: Maybe<Date>;
	/** 賽事是否進行中 */
	isInplay?: Maybe<boolean>;
	/** betfair market */
	main?: Maybe<IMarketInfo[]>;
	/** snk market */
	fancy?: Maybe<IMarketInfo[]>;
	/** betradar match id */
	betradarId?: Maybe<string>;

	score?: Maybe<IScoreInfo>;

	timeElapsed?: Maybe<number>;

	matchType?: Maybe<string>;

	live?: Maybe<ILiveInfo>;
	/** 資料從DB取的時間 */
	dataTime?: Maybe<Date>;
	/** competition setting */
	isCompetitionOrder?: Maybe<boolean>;
	/** event setting */
	isEventOrder?: Maybe<boolean>;
	/** Code Changed - Manoj Snk Array Added */
	snk?:any[]
}

export interface IMarketInfo {
	id?: Maybe<string>;
	/** 賭盤Id */
	marketId?: Maybe<string>;
	/** 賭盤名稱 */
	marketName?: Maybe<string>;
	/** 玩法類別 (1: main / 2: fancy) */
	marketType?: Maybe<number>;
	/** 賭盤來源 */
	marketSource?: Maybe<IMarketSource>;
	/** 賭盤開始時間 */
	marketStartTime?: Maybe<Date>;
	/** 是否能下注 */
	isOrder?: Maybe<boolean>;
	/** 是否加入我的最愛 */
	isFavorite?: Maybe<boolean>;
	/** 賭盤狀態 */
	status?: Maybe<string>;
	/** total back price(betfair) */
	totalBack?: Maybe<number>;
	/** total lay price(betfair) */
	totalLay?: Maybe<number>;
	/** 當前總下注量(betfair) */
	totalMatched?: Maybe<number>;
	/** 當前還剩餘可下注之量(betfair) */
	totalAvailable?: Maybe<number>;
	/** max per order credit(snk) */
	maxPerOrderCredit?: Maybe<number>;
	/** max per market exposure(snk) */
	maxPerMarketExposure?: Maybe<number>;
	/** fancy 排序 */
	sortOrder?: Maybe<number>;

	list?: Maybe<IMarketListType[]>;
	/** 下注延遲 */
	betDelay?: Maybe<number>;
	/** odds 盤口 */
	oddType?: Maybe<string>;
	/** market 描述 */
	description?: Maybe<string>;
	/** market 來源 */
	resourceFrom?: Maybe<string>;
	/** fancy 盤種編號 (0: default，1: main) */
	fancyTypeId?: Maybe<number>;
	/** BookmakerMaxPerMarketExposure */
	BookmakerMaxPerMarketExposure?: Maybe<number>;
	/** BookmakerMaxPerOrderCredit */
	BookmakerMaxPerOrderCredit?: Maybe<number>;
}

export interface ISelectionInfo {
	/** 賽事隊伍名稱 */
	name: string;
	/** selectionId */
	selectionId: string;
	/** 賽事隊伍名稱 額外資訊 */
	jockeyName?: Maybe<string>;

	status: string;

	priority: number;

	back?: Maybe<IOddsInfo[]>;

	lay?: Maybe<IOddsInfo[]>;

	bestBack?: Maybe<IOddsInfo>;

	bestLay?: Maybe<IOddsInfo>;

	runner?: Maybe<IRunnerInfo>;
	// Code Changed - Manoj ( added below variable )
	isRunning?: any

	selectionName?: any

	backPrice?: any,
	backSize?: any, 
	backStatus?: any,
	backTotalSize?: any,
	layPrice?: any,
	laySize?: any,
	layStatus?: any,
	layTotalSize?: any,
	backValue?: any,
	layValue?: any
}

export interface IOddsInfo {
	price?: Maybe<number>;

	size?: Maybe<number>;
}

export interface IRunnerInfo {
	jockeyName?: Maybe<string>;

	age?: Maybe<number>;

	trainerName?: Maybe<string>;

	form?: Maybe<string>;

	daysSinceLastRun?: Maybe<number>;

	jockeyClaim?: Maybe<string>;

	wearing?: Maybe<string>;

	coloursFilename?: Maybe<string>;
}

export interface IFancyInfo {
	/** selection 編號 */
	selectionId: string;
	/** selection 名稱 */
	selectionName: string;

	backPrice: number;

	backValue: number;
	/** back 狀態 */
	backStatus: string;

	layPrice: number;

	layValue: number;
	/** lay 狀態 */
	layStatus: string;
	/** 進球(snk) */
	isRunning: boolean;

	sortPriority: number;
	/** 現有 fancy selection size */
	backSize?: Maybe<number>;

	laySize?: Maybe<number>;
	/** 現有 fancy selection total size */
	backTotalSize?: Maybe<number>;

	layTotalSize?: Maybe<number>;
	/** Code Change - Manoj (back சேர்க்கப்பட்டது type Object )*/
	back?:any
	/** Code Change - Manoj (lay சேர்க்கப்பட்டது type Object )*/
	lay?:any
}

export interface IScoreInfo {
	categoryId: number;

	home?: Maybe<IScoreType>;

	away?: Maybe<IScoreType>;
}

export interface IScoreType {
	name: string;

	score?: Maybe<string>;

	halfTimeScore?: Maybe<string>;

	fullTimeScore?: Maybe<string>;

	penaltiesScore?: Maybe<string>;

	penaltiesSequence?: Maybe<string[]>;

	games?: Maybe<string>;

	sets?: Maybe<string>;

	tennis?: Maybe<ITennisInfo>;

	football?: Maybe<IFootballInfo>;

	cricket?: Maybe<ICricketInfo>;
}

export interface ITennisInfo {
	aces?: Maybe<string>;

	doubleFaults?: Maybe<string>;

	gameSequence?: Maybe<string[]>;

	isServing?: Maybe<boolean>;

	highlight?: Maybe<boolean>;

	serviceBreaks?: Maybe<number>;
}

export interface IFootballInfo {
	numberOfYellowCards: number;

	numberOfRedCards: number;

	numberOfCards: number;

	numberOfCorners: number;

	numberOfCornersFirstHalf: number;

	bookingPoints: number;
}

export interface ICricketInfo {
	inning1?: Maybe<IInningInfo>;

	inning2?: Maybe<IInningInfo>;
}

export interface IInningInfo {
	runs: string;

	wickets: string;

	overs: string;
}

export interface ILiveInfo {
	streamName?: Maybe<string>;

	url?: Maybe<string>;

	videoSource?: Maybe<string>;
}

export interface ISearchEventInfo {
	categoryId: number;

	competitionId?: Maybe<string>;

	eventId: string;

	marketName: string;

	eventName: string;
}

export interface IMemberQuery {
	/** 會員驗證 */
	validate?: Maybe<IStatusResponse>;

	favorites?: Maybe<string[]>;

	credit?: Maybe<ICreditInfo>;
	/** jwt過期登出 */
	keepAlive?: Maybe<IStatusResponse>;
	/** 取得會員資訊 */
	userInfo: IUserInfoResponse;
	/** 取得用戶google authentication */
	getGaAuthInfo?: Maybe<IGaAuthInfoResponse>;
	/** 創建新的google authentication qrcode */
	generateOTP?: Maybe<IAuthOtpResponse>;
}

export interface IStatusResponse extends IResponseInterface {
	/** 狀態 */
	success: boolean;
	/** 狀態代碼 */
	code?: Maybe<string>;
	/** 訊息 */
	message: string;
}

export interface ICreditInfo {
	/** The limit of credit */
	limitCredit: number;
	/** Current usable credit of this user */
	availableCredit: number;
	/** Win/Loss credit of current user */
	distributedCredit: number;
	/** Profit / loss of client if positive it become give for agent and upper, if negative it will become take for agent and upper level. */
	winlossCredit: number;
	/** Current credit exposure of client */
	exposureCredit: number;
}

export interface IUserInfoResponse {
	/** 品牌 ID */
	brandId: string;
	/** 用戶 ID */
	memberId: number;
	/** 用戶名稱 */
	username: string;
	/** 下注限制 */
	orderLimit?: Maybe<IOrderLimit[]>;
	/** 匯率 */
	exchangeRate: number;
	/** 用戶種類 */
	type: string;
	/** Betfair 資訊 */
	betfairInfo?: Maybe<IBetfairInfo[]>;
	/** 是否是子帳號 */
	isSubAccount?: Maybe<boolean>;
}

export interface IOrderLimit {
	/** 種類 ID */
	eventTypeId?: Maybe<string>;
	/** 種類 ID */
	categoryId?: Maybe<string>;
	/** 單筆最小下單額 */
	minOrder?: Maybe<number>;
	/** 單筆最大下單額 */
	maxOrder?: Maybe<number>;
	/** 此種類最大下單額 */
	maxMarket?: Maybe<number>;
}

export interface IBetfairInfo {
	/** 遊戲種類 ID */
	categoryId?: Maybe<number>;
	/** Betfair PT */
	betfairPt?: Maybe<number>;
}

export interface IGaAuthInfoResponse {
	isHadAuth?: Maybe<boolean>;

	lastAuthAt?: Maybe<Date>;
}

export interface IAuthOtpResponse {
	authOtp?: Maybe<string>;

	secretCode?: Maybe<string>;
}

export interface IMenuQuery {
	/** 左側選單列表 */
	category?: Maybe<ISportsInfo[]>;
}

/** 左側選單sport資訊 */
export interface ISportsInfo {
	name: string;

	count: number;

	categoryId: number;

	subCategory?: Maybe<ISubCategoryInfo>;

	totalMatched?: Maybe<number>;
}

/** 展開後的選單資訊 */
export interface ISubCategoryInfo {
	/** 盃賽列表 */
	competitions?: Maybe<ICompetitionInfo[]>;
	/** 無盃賽的賽事列表 */
	events?: Maybe<ISportInfo[]>;
}

/** 盃賽資訊 */
export interface ICompetitionInfo {
	competitionId: string;

	name: string;

	count: number;

	events: ISportInfo[];

	totalMatched?: Maybe<number>;
}

/** 賽事資訊 */
export interface ISportInfo {
	eventId: string;

	name: string;

	marketName: string;

	marketStartTime: Date;

	inplay: boolean;

	venue?: Maybe<string>;

	countryCode: string;

	totalMatched?: Maybe<number>;
}

export interface IExchangeQuery {
	/** 會員注單 */
	orders?: Maybe<IOrderGroup>;
	/** 會員交易紀錄 */
	transactions?: Maybe<ITransaction[]>;
	/** 會員輸贏 */
	profitLoss?: Maybe<ITransaction[]>;

	myOrders?: Maybe<IOrderGroup>;
}

export interface IOrderGroup {
	/** 已搓合注單 */
	matched: IOrder[];
	/** 未搓合注單 */
	unmatched: IOrder[];
	/** pending 注單 */
	pending: IOrder[];
}

export interface IOrder {
	/** 訂單單號 */
	orderNumber: string;
	/** 結算單號 */
	settleNumber?: Maybe<string>;
	/** 注單匯率 */
	exchangeRate: number;
	/** 玩法類別 */
	marketType?: Maybe<number>;
	/** 玩法編號 */
	marketId: string;
	/** 玩法名稱 */
	marketName: string;
	/** 分類編號 */
	categoryId: number;
	/** 聯賽編號 */
	competitionId?: Maybe<string>;
	/** 開賽時間 */
	marketStartTime?: Maybe<Date>;
	/** 賽事編號 */
	eventId: string;
	/** 賽事名稱 */
	eventName: string;
	/** 選項編號 */
	selectionId: string;
	/** 選項名稱 */
	selectionName: string;
	/** 投注選項 */
	side: ISide;
	/** 投注賠率 */
	price: number;
	/** 投注額度 */
	stake: number;
	/** 輸贏數量 */
	winlossCredit?: Maybe<number>;
	/** 可能虧損 */
	liability?: Maybe<number>;
	/** 建立時間 */
	createdAt: Date;
	/** 更新時間 */
	updatedAt?: Maybe<Date>;
	/** 結算時間 */
	settledAt?: Maybe<Date>;
	/** 注單狀態 */
	status?: Maybe<string>;
	/** 會員備註 */
	clientRemark?: Maybe<string>;
	/** 下注延時 */
	betDelay?: Maybe<number>;
	/** market odds 盤口 */
	oddType?: Maybe<string>;
	/** 玩法描述 */
	description?: Maybe<string>;
	/** Code Changed - Manoj ( aaded variable below) */
	provider?: Maybe<string>;
	/** Code Changed - Manoj ( aaded variable below) */
	p?: Maybe<string>;
}

export interface ITransaction {
	/** 交易類型 */
	type: string;
	/** 贏錢輸錢 */
	winloss: number;
	/** 佣金 */
	commission?: Maybe<number>;
	/** 下注限制 */
	limitCredit?: Maybe<number>;
	/** 可用credit */
	availableCredit?: Maybe<number>;
	/** 建立時間 */
	createdAt: Date;
	/** 備註 */
	clientRemark?: Maybe<string>;
	/** 交易匯率 */
	exchangeRate?: Maybe<number>;
	/** 平台匯率 */
	platformExchangeRate?: Maybe<number>;
	/** 注單 */
	order?: Maybe<IOrder>;
	/** Casino 平台 */
	platform?: Maybe<string>;
}

export interface IPositionQuery {
	/** 版位 */
	position: IPositionInfo[];
}

export interface IPositionInfo {
	id: number;

	code: string;

	parentId?: Maybe<number>;

	text1?: Maybe<string>;

	text2?: Maybe<string>;

	image1?: Maybe<string>;

	image2?: Maybe<string>;

	url?: Maybe<string>;

	sort: number;
}

export interface ISystemQuery {
	/** 系統資訊 */
	info: ISystemInfoResponse;
	/** Apollo Config */
	config: ISystemConfigResponse;
}

export interface ISystemInfoResponse {
	langVersion: string;
}

export interface ISystemConfigResponse {
	value?: Maybe<string>;
}

export interface IAgentMemberQuery {
	/** 下線(詳細) */
	downline?: Maybe<IDownlineDetail>;
	/** 下線清單(詳細) */
	downlineList: IDownlineDetail[];
	/** 全下線清單 */
	allDownlineList: IAllDownlineListResponse;
	/** 全上線清單 */
	allUpperlineList: IAllUpperlineListResponse[];
	/** 子帳號清單 */
	listSubAccount: IListSubAccountResponse[];

	info?: Maybe<IDashboardInfo>;

	balance?: Maybe<IBalanceInfo>;
	/** 活躍用戶 */
	listActiveAccount: IListActiveAccountResponse[];
	/** 用戶使用過的IP */
	listPastIpAddress: IListPastIpAddressResponse[];
	/** 分成清單 */
	listPt?: Maybe<IListPtResponse>;
	/** 幣別清單 */
	currencyList: ICurrencyListResponse[];
	/** 關連帳號資訊 */
	associations?: Maybe<IAssociationMember[]>;
}

export interface IDownlineDetail {
	id?: Maybe<number>;

	brandId?: Maybe<number>;

	currencyId?: Maybe<number>;

	currencyRate?: Maybe<number>;

	username?: Maybe<string>;

	password?: Maybe<string>;

	status?: Maybe<IAccountStatus>;

	lockerName?: Maybe<string>;

	allowUnlock?: Maybe<boolean>;

	lastLoginIP?: Maybe<string>;

	lastLoginedAt?: Maybe<Date>;

	creator?: Maybe<string>;

	createdAt?: Maybe<Date>;

	updater?: Maybe<string>;

	updatedAt?: Maybe<Date>;

	type?: Maybe<IMemberType>;

	remark?: Maybe<string>;

	disablerLevel?: Maybe<string>;

	limitCredit?: Maybe<number>;

	exposureCredit?: Maybe<number>;

	balanceUpCredit?: Maybe<number>;

	balanceDownCredit?: Maybe<number>;

	winlossCredit?: Maybe<number>;

	availableBalance?: Maybe<number>;

	distributedCredit?: Maybe<number>;

	downlineMaxCredit?: Maybe<number>;

	clientMaxCredit?: Maybe<number>;

	country?: Maybe<string>;

	orderSetting: IOrderSetting[];

	ptList: IPtDetail[];
}

export interface IOrderSetting {
	categoryId?: Maybe<number>;

	minBet?: Maybe<number>;

	maxBet?: Maybe<number>;

	maxMarket?: Maybe<number>;
}

export interface IPtDetail {
	categoryId?: Maybe<number>;

	takePT?: Maybe<number>;

	givePT?: Maybe<number>;

	maxDistributedPT?: Maybe<number>;
}

export interface IAllDownlineListResponse {
	data: IAllDownlineListData[];

	dataJson: string;
}

export interface IAllDownlineListData {
	id?: Maybe<number>;

	username?: Maybe<string>;

	type?: Maybe<string>;

	status?: Maybe<string>;

	lastLoginedAt?: Maybe<string>;

	lastLoginIp?: Maybe<string>;
}

export interface IAllUpperlineListResponse {
	id?: Maybe<number>;

	username?: Maybe<string>;

	type?: Maybe<string>;
}

export interface IListSubAccountResponse {
	memberId: number;

	username: string;

	status: IAccountStatus;
}

export interface IDashboardInfo {
	balance?: Maybe<IBalanceInfo>;

	top?: Maybe<ITopInfo>;

	statistic?: Maybe<IStatisticInfo>;
}

export interface IBalanceInfo {
	exposureCredit?: Maybe<number>;

	balanceUpCredit?: Maybe<number>;

	balanceDownCredit?: Maybe<number>;

	limitCredit?: Maybe<number>;

	availableCredit?: Maybe<number>;

	availableBalance?: Maybe<number>;

	distributedCredit?: Maybe<number>;

	agentDistributed?: Maybe<number>;
	/** Hide balance if has no view balance permission */
	hideBalance?: Maybe<boolean>;
}

export interface ITopInfo {
	winner?: Maybe<ITopMemberInfo[]>;

	loser?: Maybe<ITopMemberInfo[]>;

	exposure?: Maybe<ITopMemberInfo[]>;
}

export interface ITopMemberInfo {
	memberId?: Maybe<number>;

	type?: Maybe<string>;

	name?: Maybe<string>;

	credit?: Maybe<number>;
}

export interface IStatisticInfo {
	football?: Maybe<ICategoryProfitLoss>;

	tennis?: Maybe<ICategoryProfitLoss>;

	cricket?: Maybe<ICategoryProfitLoss>;

	horseRacing?: Maybe<ICategoryProfitLoss>;

	greyhoundRacing?: Maybe<ICategoryProfitLoss>;
}

export interface ICategoryProfitLoss {
	name?: Maybe<string>;

	weekly?: Maybe<number>;

	monthly?: Maybe<number>;
}

export interface IListActiveAccountResponse {
	/** 使用者帳號 */
	username: string;
	/** 使用者ID */
	memberId: number;
	/** 使用者層級 */
	type: IMemberType;
	/** 使用者上次登入IP */
	ip: string;
	/** 使用者上次登入時間 */
	lastLoginAt: Date;
	/** 國別 */
	country?: Maybe<string>;
}

export interface IListPastIpAddressResponse {
	/** 使用者登入IP */
	ip: string;
	/** 使用者最後登入時間 */
	lastLoginedAt: Date;
	/** IP 地理位置 */
	country?: Maybe<string>;
}

export interface IListPtResponse {
	/** 使用者帳號 */
	username: string;
	/** 使用者ID */
	memberId: number;
	/** 使用者層級 */
	type: IMemberType;

	cricketPT: number;

	cricketPTTake: number;

	soccerPT: number;

	soccerPTTake: number;

	tennisPT: number;

	tennisPTTake: number;

	horsePT: number;

	horsePTTake: number;

	greyhoundPT: number;

	greyhoundPTTake: number;

	specialFancyPT: number;

	specialFancyPTTake: number;

	downline: IListPtMemberData[];
}

export interface IListPtMemberData {
	/** 使用者帳號 */
	username: string;
	/** 使用者ID */
	memberId: number;
	/** 使用者層級 */
	type: IMemberType;

	cricketPT: number;

	cricketPTTake: number;

	soccerPT: number;

	soccerPTTake: number;

	tennisPT: number;

	tennisPTTake: number;

	horsePT: number;

	horsePTTake: number;

	greyhoundPT: number;

	greyhoundPTTake: number;

	specialFancyPT: number;

	specialFancyPTTake: number;
}

export interface ICurrencyListResponse {
	id?: Maybe<number>;

	currencyCode?: Maybe<string>;

	currencyName?: Maybe<string>;

	description?: Maybe<string>;

	exchangeRate?: Maybe<number>;

	minPerOrderCredit?: Maybe<number>;
}

export interface IAssociationMember {
	/** 用戶ID */
	memberId?: Maybe<number>;
	/** 用戶名稱 */
	username?: Maybe<string>;
	/** 用戶種類 */
	type?: Maybe<string>;
	/** 幣別Id */
	currencyId?: Maybe<number>;
	/** 匯率 */
	exchangeRate?: Maybe<number>;
	/** 關聯狀態 */
	isEnable?: Maybe<boolean>;
}

export interface IAgentExchangeQuery {
	/** 下注清單 */
	sportOrderList: ISportOrderListResponse;

	sportOrderMatchList: ISportOrderMatchListInfo[];

	watchlist?: Maybe<ISportWatchList>;

	myActivity?: Maybe<IMyActivityResponse>;
	/** 帳務明細(全部) */
	accountStatement: IAccountStatementResponse;
	/** 帳務明細(rettle) */
	settlingStatement: IAccountStatementGroup[];
	/** 帳務明細-賽事 */
	eventDetail: IEventDetail[];

	netExposure?: Maybe<INetExposureInfo[]>;

	sportMarketData?: Maybe<ISportMarketDataInfo>;
	/** sport profitloss => SportProfitLossInfo */
	sportProfitLossList?: Maybe<ISportProfitLossInfoJson>;
	/** sport profitloss orders => SportProfitLossOrderInfo */
	sportProfitLossOrderList?: Maybe<ISportProfitLossOrderInfoJson>;
}

export interface ISportOrderListResponse {
	/** 總單量 */
	totalBets: number;
	/** 總match量 */
	totalStakes: number;
	/** 注單資訊 */
	orderList: ISportOrderResponseData[];
}

export interface ISportOrderResponseData {
	/** 下注使用者ID */
	memberId?: Maybe<string>;
	/** 下注使用者名稱 */
	username?: Maybe<string>;
	/** 幣別 */
	currencyCode?: Maybe<string>;

	companyAdminMemberId?: Maybe<string>;

	companyAdminUsername?: Maybe<string>;

	superAdminMemberId?: Maybe<string>;

	superAdminUsername?: Maybe<string>;

	adminMemberId?: Maybe<string>;

	adminUsername?: Maybe<string>;

	superMemberId?: Maybe<string>;

	superUsername?: Maybe<string>;

	masterMemberId?: Maybe<string>;

	masterUsername?: Maybe<string>;
	/** 下注單號 */
	orderNumber?: Maybe<string>;
	/** 轉帳單號 */
	transactionNumber?: Maybe<string>;
	/** 結算單號 */
	settleNumber?: Maybe<string>;
	/** 下單匯率 */
	exchangeRate?: Maybe<number>;
	/** market來源 */
	source?: Maybe<string>;
	/** 盤口 */
	oddType?: Maybe<string>;
	/** betfair單號 */
	betId?: Maybe<string>;

	competitionId?: Maybe<string>;
	/** 賽事ID */
	eventId?: Maybe<string>;
	/** 賽事名稱 */
	eventName?: Maybe<string>;

	marketId?: Maybe<string>;

	marketName?: Maybe<string>;

	marketTypeId?: Maybe<number>;

	marketTypeName?: Maybe<string>;
	/** 選項ID */
	selectionId?: Maybe<string>;
	/** 選項名稱 */
	selectionName?: Maybe<string>;
	/** 桌機or手機 */
	deviceType?: Maybe<number>;
	/** 球種 */
	categoryId?: Maybe<number>;
	/** 賽事是否正在進行 */
	isInplay?: Maybe<boolean>;
	/** BACK or LAY */
	side?: Maybe<string>;
	/** 下單金額 */
	orderPrice?: Maybe<number>;
	/** 實際match金額 */
	price?: Maybe<number>;
	/** 下單量 */
	orderCredit?: Maybe<number>;
	/** match單量 */
	matchedCredit?: Maybe<number>;
	/** unmatch單量 */
	unmatchedCredit?: Maybe<number>;
	/** 剩餘單量 */
	lapseCredit?: Maybe<number>;
	/** 使用者取消單量 */
	cancelCredit?: Maybe<number>;
	/** betfair 取消單量 */
	voidCredit?: Maybe<number>;
	/** 下單時間 */
	createdAt?: Maybe<Date>;
	/** match時間 */
	matchedAt?: Maybe<Date>;
	/** settle時間 */
	settledAt?: Maybe<Date>;

	companyAdminPt?: Maybe<number>;

	superAdminPt?: Maybe<number>;

	adminPt?: Maybe<number>;

	superPt?: Maybe<number>;

	masterPt?: Maybe<number>;

	winlossCredit?: Maybe<number>;
	/** 是否使用一鍵下單 */
	isOneClickOrder?: Maybe<boolean>;
	/** 是否存在風險 */
	isPendingTransfer?: Maybe<boolean>;
	/** 開啟注單警示使用者ID */
	pendingOwnerId?: Maybe<string>;
	/** 開啟注單警示使用者 */
	pendingOwnerUsername?: Maybe<string>;
	/** 注單狀態 */
	status?: Maybe<string>;
	/** 取消時間 */
	cancelledAt?: Maybe<Date>;
	/** company 取消時間 */
	invalidedAt?: Maybe<Date>;
	/** checkout時間 */
	checkoutAt?: Maybe<Date>;
	/** 前台註記 */
	clientRemark?: Maybe<string>;
	/** 後台註記 */
	agentRemark?: Maybe<string>;
	/** fancy lay值 */
	layValue?: Maybe<number>;
	/** fancy back值 */
	backValue?: Maybe<number>;
	/** 下注IP */
	ip?: Maybe<string>;
}

export interface ISportOrderMatchListInfo {
	matchCredit: number;

	unmatchCredit: number;

	lapseCredit: number;

	voidCredit: number;

	cancelCredit: number;

	averagePrice: number;

	exchangeRate: number;

	createdAt: Date;

	// Code Changed - Manoj 
	tpCurrencyRate: any

	displayRate: any
}

export interface ISportWatchList {
	mainExposure?: Maybe<IMainExposureInfo[]>;

	fancyExposure?: Maybe<IFancyExposureInfo[]>;
}

export interface IMainExposureInfo {
	/** 投注單號 */
	orderNumber?: Maybe<string>;
	/** transaction單號 */
	transactionNumber?: Maybe<string>;

	marketId?: Maybe<string>;

	marketTypeId?: Maybe<number>;
	/** 球種 */
	categoryId?: Maybe<number>;
	/** 杯賽 */
	competitionId?: Maybe<string>;
	/** 賽事名稱 */
	eventName?: Maybe<string>;
	/** 賽事編號 */
	eventId?: Maybe<string>;

	inplay?: Maybe<boolean>;

	averagePrice?: Maybe<number>;
	/** 投注選項 */
	side?: Maybe<string>;
	/** 選項名稱 */
	selectionName?: Maybe<string>;

	totalOutcome?: Maybe<number>;

	sortPriority?: Maybe<number>;

	orderPrice?: Maybe<number>;

	orderCredit?: Maybe<number>;

	exchangeRate?: Maybe<number>;

	homeExposure?: Maybe<number>;

	awayExposure?: Maybe<number>;

	drawExposure?: Maybe<number>;

	companyAdminId?: Maybe<number>;

	companyAdminName?: Maybe<string>;

	companyAdminPt?: Maybe<number>;

	superAdminId?: Maybe<number>;

	superAdminName?: Maybe<string>;

	superAdminPt?: Maybe<number>;

	adminId?: Maybe<number>;

	adminName?: Maybe<string>;

	adminPt?: Maybe<number>;

	superId?: Maybe<number>;

	superName?: Maybe<string>;

	superPt?: Maybe<number>;

	masterId?: Maybe<number>;

	masterName?: Maybe<string>;

	masterPt?: Maybe<number>;

	clientId?: Maybe<number>;

	clientName?: Maybe<string>;

	betTime?: Maybe<Date>;
}

export interface IFancyExposureInfo {
	/** 投注單號 */
	orderNumber?: Maybe<string>;
	/** transaction單號 */
	transactionNumber?: Maybe<string>;
	/** fancy Id */
	fancyId?: Maybe<string>;
	/** fancy 名稱 */
	fancyName?: Maybe<string>;
	/** 球種 */
	categoryId?: Maybe<number>;
	/** 杯賽 */
	competitionId?: Maybe<string>;
	/** 賽事名稱 */
	eventName?: Maybe<string>;
	/** 賽事編號 */
	eventId?: Maybe<string>;

	marketId?: Maybe<string>;

	marketTypeId?: Maybe<number>;

	selectionId?: Maybe<number>;

	inplay?: Maybe<boolean>;

	backValue?: Maybe<number>;

	layValue?: Maybe<number>;

	backPrice?: Maybe<number>;

	layPrice?: Maybe<number>;
	/** 投注選項 */
	side?: Maybe<string>;

	orderPrice?: Maybe<number>;

	orderCredit?: Maybe<number>;

	exchangeRate?: Maybe<number>;

	layExposure?: Maybe<number>;

	backExposure?: Maybe<number>;

	houseEdge?: Maybe<number>;

	companyAdminId?: Maybe<number>;

	companyAdminName?: Maybe<string>;

	companyAdminPt?: Maybe<number>;

	superAdminId?: Maybe<number>;

	superAdminName?: Maybe<string>;

	superAdminPt?: Maybe<number>;

	adminId?: Maybe<number>;

	adminName?: Maybe<string>;

	adminPt?: Maybe<number>;

	superId?: Maybe<number>;

	superName?: Maybe<string>;

	superPt?: Maybe<number>;

	masterId?: Maybe<number>;

	masterName?: Maybe<string>;

	masterPt?: Maybe<number>;

	clientId?: Maybe<number>;

	clientName?: Maybe<string>;

	betTime?: Maybe<Date>;

	isTommy?: Maybe<boolean>;
}

export interface IMyActivityResponse {
	GrandMemberWinToday?: Maybe<number>;

	GrandMemberWin3Days?: Maybe<number>;

	GrandMemberWin7Days?: Maybe<number>;

	GrandMemberWin1Month?: Maybe<number>;

	GrandMemberWin?: Maybe<number>;

	GrandMemberCommissionToday?: Maybe<number>;

	GrandMemberCommission3Days?: Maybe<number>;

	GrandMemberCommission7Days?: Maybe<number>;

	GrandMemberCommission1Month?: Maybe<number>;

	GrandMemberCommission?: Maybe<number>;

	GrandMemberPLToday?: Maybe<number>;

	GrandMemberPL3Days?: Maybe<number>;

	GrandMemberPL7Days?: Maybe<number>;

	GrandMemberPL1Month?: Maybe<number>;

	GrandMemberPL?: Maybe<number>;

	GrandMemberTotalToday?: Maybe<number>;

	GrandMemberTotal3Days?: Maybe<number>;

	GrandMemberTotal7Days?: Maybe<number>;

	GrandMemberTotal1Month?: Maybe<number>;

	GrandMemberTotal?: Maybe<number>;
}

export interface IAccountStatementResponse {
	options: IAccountStatementOption[];

	casinoOption: IAccountStatementOption[];

	groupedData: IAccountStatementGroup[];

	casinoOptionJson: string;

	optionsJson: string;

	groupedDataJson: string;
}

export interface IAccountStatementOption {
	key: string;

	value: number;
}

export interface IAccountStatementGroup {
	date: string;

	data: IAccountStatement[];
}

export interface IAccountStatement {
	memberId?: Maybe<number>;

	sendMemberId?: Maybe<number>;

	receiveMemberId?: Maybe<number>;

	sendUsername?: Maybe<string>;

	receiveUsername?: Maybe<string>;

	transactionNumber?: Maybe<string>;

	referNumber?: Maybe<string>;

	categoryId?: Maybe<number>;

	transactionTypeId?: Maybe<number>;

	exchangeRate?: Maybe<number>;

	transactionCredit?: Maybe<number>;

	commissionCredit?: Maybe<number>;

	availableCredit?: Maybe<number>;

	createdAt?: Maybe<Date>;

	marketTypeId?: Maybe<number>;

	marketId?: Maybe<string>;

	eventId?: Maybe<string>;

	competitionId?: Maybe<string>;

	eventName?: Maybe<string>;

	fancyName?: Maybe<string>;

	isThirdParty?: Maybe<boolean>;
}

export interface IEventDetail {
	memberId?: Maybe<number>;

	username?: Maybe<string>;

	orderNumber?: Maybe<string>;

	transactionNumber?: Maybe<number>;

	settleNumber?: Maybe<string>;

	exchangeRate?: Maybe<number>;

	source?: Maybe<string>;

	competitionId?: Maybe<string>;

	eventId?: Maybe<string>;

	eventName?: Maybe<string>;

	marketId?: Maybe<string>;

	marketName?: Maybe<string>;

	marketTypeId?: Maybe<number>;

	marketTypeName?: Maybe<string>;

	selectionId?: Maybe<string>;

	selectionName?: Maybe<string>;

	backValue?: Maybe<number>;

	layValue?: Maybe<number>;

	categoryId?: Maybe<number>;

	inplay?: Maybe<boolean>;

	side?: Maybe<string>;

	orderPrice?: Maybe<number>;

	price?: Maybe<number>;

	orderCredit?: Maybe<number>;

	matchedCredit?: Maybe<number>;

	unmatchedCredit?: Maybe<number>;

	createdAt?: Maybe<Date>;

	settledAt?: Maybe<Date>;

	companyAdminPt?: Maybe<number>;

	superAdminPt?: Maybe<number>;

	adminPt?: Maybe<number>;

	superPt?: Maybe<number>;

	masterPt?: Maybe<number>;

	winlossCredit?: Maybe<number>;

	isOneClickOrder?: Maybe<boolean>;

	status?: Maybe<string>;

	cancelledAt?: Maybe<Date>;

	checkoutAt?: Maybe<Date>;

	companyAdminWinlossCredit?: Maybe<number>;

	superAdminWinlossCredit?: Maybe<number>;

	adminWinlossCredit?: Maybe<number>;

	superWinlossCredit?: Maybe<number>;

	masterWinlossCredit?: Maybe<number>;

	commissionCredit?: Maybe<number>;
}

export interface INetExposureInfo {
	/** 賽事編號 */
	eventId?: Maybe<string>;

	eventName?: Maybe<string>;

	competitionId?: Maybe<string>;

	categoryId?: Maybe<number>;

	main?: Maybe<INetMainExposureInfo[]>;

	fancy?: Maybe<INetFancyExposureInfo[]>;
}

export interface INetMainExposureInfo {
	marketTypeId?: Maybe<number>;

	marketId?: Maybe<string>;

	marketName?: Maybe<string>;

	totalOutcome?: Maybe<number>;

	stake?: Maybe<number>;

	homeExposure?: Maybe<number>;

	awayExposure?: Maybe<number>;

	drawExposure?: Maybe<number>;
}

export interface INetFancyExposureInfo {
	marketId?: Maybe<string>;

	marketTypeId?: Maybe<number>;

	fancyId?: Maybe<string>;

	fancyName?: Maybe<string>;

	stake?: Maybe<number>;

	isBFFancy?: Maybe<boolean>;

	isTommy?: Maybe<boolean>;

	maxWin?: Maybe<number>;

	maxLoss?: Maybe<number>;
}

export interface ISportMarketDataInfo {
	matchData?: Maybe<IMarketMatchData>;

	netAgentList?: Maybe<IMarketNetAgentInfo[]>;
}

export interface IMarketMatchData {
	main?: Maybe<IMarketMatchMainInfo>;

	fancy?: Maybe<IMarketMatchFancyInfo[]>;
}

export interface IMarketMatchMainInfo {
	eventName?: Maybe<string>;

	totalMatched?: Maybe<number>;

	selection?: Maybe<IMarketMatchSelectionInfo[]>;
}

export interface IMarketMatchSelectionInfo {
	name?: Maybe<string>;

	exposure?: Maybe<number>;

	back?: Maybe<IOddsInfo[]>;

	lay?: Maybe<IOddsInfo[]>;
}

export interface IMarketMatchFancyInfo {
	fancyId?: Maybe<string>;

	fancyName?: Maybe<string>;

	marketTypeId?: Maybe<number>;

	maxWin?: Maybe<number>;

	maxLoss?: Maybe<number>;

	backValue?: Maybe<string>;

	layValue?: Maybe<string>;

	backOdds?: Maybe<number>;

	layOdds?: Maybe<number>;

	oddType?: Maybe<string>;

	selection?: Maybe<IMarketFancySelectionInfo[]>;
}

export interface IMarketFancySelectionInfo {
	name?: Maybe<string>;

	backExposure?: Maybe<number>;

	layExposure?: Maybe<number>;

	exposure?: Maybe<number>;

	houseEdge?: Maybe<number>;

	backOdds?: Maybe<number>;

	layOdds?: Maybe<number>;

	backSize?: Maybe<number>;

	laySize?: Maybe<number>;

	sortPriority?: Maybe<number>;
}

export interface IMarketNetAgentInfo {
	name?: Maybe<string>;

	type?: Maybe<string>;

	main?: Maybe<IMarketNetAgentMainInfo>;

	fancy?: Maybe<IMarketNetAgentFancyInfo>;

	downline?: Maybe<IMarketNetAgentInfo[]>;
}

export interface IMarketNetAgentMainInfo {
	homeExposure?: Maybe<number>;

	awayExposure?: Maybe<number>;

	drawExposure?: Maybe<number>;

	totalOutcome?: Maybe<number>;

	stake?: Maybe<number>;
}

export interface IMarketNetAgentFancyInfo {
	isBFFancy?: Maybe<boolean>;

	isTommy?: Maybe<boolean>;

	fancyName?: Maybe<string>;

	maxWin?: Maybe<number>;

	maxLoss?: Maybe<number>;

	stake?: Maybe<number>;

	clientExpTable?: Maybe<INetAgentFancyClientExpInfo[]>;
}

export interface INetAgentFancyClientExpInfo {
	range?: Maybe<number>;

	upperRange?: Maybe<number>;

	exposureValue?: Maybe<number>;

	maxWin?: Maybe<number>;

	maxLoss?: Maybe<number>;
}

export interface ISportProfitLossInfoJson {
	value?: Maybe<Json>;
}

export interface ISportProfitLossOrderInfoJson {
	value?: Maybe<Json>;
}

export interface IAgentMarketMgmtQuery {
	/** 輪播清單 */
	notificationList: INotificationListResponse[];
	/** 賭盤AutoSetting */
	autoSetting?: Maybe<IAutoSetting[]>;
	/** 賭盤 */
	list?: Maybe<IAgentMarket[]>;
	/** single fancy 列表 */
	listFancys?: Maybe<IAgentMarketFancy[]>;
	/** multiple fancy selection 列表 */
	multipleFancys?: Maybe<IMultipleFancy[]>;
	/** multiple fancy log */
	multipleFancyLogs?: Maybe<IMultipleFancyLog[]>;
	/** selection log */
	selectionLogs?: Maybe<ISelectionLog[]>;
}

export interface INotificationListResponse {
	id: number;

	brandId: number;

	code: string;

	text1?: Maybe<string>;

	text2?: Maybe<string>;

	image1?: Maybe<string>;

	image2?: Maybe<string>;

	isEnable: boolean;

	languageCode: string;

	sort?: Maybe<number>;

	startedAt?: Maybe<Date>;

	endedAt?: Maybe<Date>;

	creator?: Maybe<string>;

	createdAt?: Maybe<Date>;

	updater?: Maybe<string>;

	updatedAt?: Maybe<Date>;
}

export interface IAutoSetting {
	/** 球種名稱 */
	categoryName?: Maybe<string>;
	/** 球種ID */
	categoryId?: Maybe<number>;

	enableSecond?: Maybe<number>;

	enableMatched?: Maybe<number>;

	orderSecond?: Maybe<number>;

	orderMatched?: Maybe<number>;

	enableSecondFancy?: Maybe<number>;

	enableMatchedFancy?: Maybe<number>;

	orderSecondFancy?: Maybe<number>;

	orderMatchedFancy?: Maybe<number>;
}

export interface IAgentMarket {
	/** 賭盤ID */
	marketId?: Maybe<string>;
	/** 賭盤類型編號 */
	marketTypeId?: Maybe<number>;
	/** 賭盤名稱 */
	marketName?: Maybe<string>;
	/** 賽事ID */
	eventId?: Maybe<string>;
	/** 杯賽ID */
	competitionId?: Maybe<string>;
	/** 球種ID */
	categoryId?: Maybe<number>;
	/** 狀態 */
	status?: Maybe<string>;
	/** 賽事名稱 */
	name?: Maybe<string>;
	/** 聯賽名稱 */
	competitionName?: Maybe<string>;
	/** 開賽時間 */
	marketStartTime?: Maybe<Date>;
	/** 當前總下注量(betfair) */
	totalMatched?: Maybe<number>;
	/** 比賽中 */
	isPlay?: Maybe<boolean>;
	/** 啟用 */
	isEnable?: Maybe<boolean>;

	isEventEnable?: Maybe<boolean>;

	isCompetitionEnable?: Maybe<boolean>;
	/** 開盤 */
	isOrder?: Maybe<boolean>;

	isEventOrder?: Maybe<boolean>;

	isCompetitionOrder?: Maybe<boolean>;
	/** 是否手動控制 (若後台手動開關過，則變為true，自動開啟比賽的排程就略過他) */
	isManual?: Maybe<boolean>;
	/** cricket wicket fancy 是否自動更新資料 */
	isSync?: Maybe<boolean>;

	totalFancy?: Maybe<boolean>;
	/** 比賽完畢 */
	isPast?: Maybe<boolean>;

	isResettle?: Maybe<boolean>;
	/** 賭盤被上線關掉 by Event */
	blackListEvent?: Maybe<boolean>;
	/** 賭盤被上線關掉 by Competition */
	blackListCompetition?: Maybe<boolean>;
	/** BL為上線設定的 (Event) */
	upperlineBlacklist?: Maybe<boolean>;
	/** BL為上線設定的 (Competition) */
	upperlineCompetitionBlacklist?: Maybe<boolean>;
	/** 直撥網址 */
	streamURL?: Maybe<string>;

	updatedAt?: Maybe<Date>;

	updater?: Maybe<string>;

	eventUpdatedAt?: Maybe<Date>;

	eventUpdater?: Maybe<string>;

	competitionUpdatedAt?: Maybe<Date>;

	competitionUpdater?: Maybe<string>;
}

export interface IAgentMarketFancy {
	/** ID */
	id?: Maybe<string>;
	/** CategoryId */
	categoryId?: Maybe<number>;
	/** CompetitionId */
	competitionId?: Maybe<string>;
	/** EventId */
	eventId?: Maybe<string>;
	/** resourceId */
	resourceId?: Maybe<string>;
	/** 敘述 */
	description?: Maybe<string>;
	/** StartedAt */
	startedAt?: Maybe<Date>;
	/** 標題 */
	title?: Maybe<string>;
	/** layValue */
	layValue?: Maybe<number>;
	/** backValue */
	backValue?: Maybe<number>;
	/** layOdds */
	layOdds?: Maybe<number>;
	/** backOdds */
	backOdds?: Maybe<number>;
	/** backStatus */
	backStatus?: Maybe<string>;
	/** layStatus */
	layStatus?: Maybe<string>;
	/** back尚餘數量 */
	backAvailableSize?: Maybe<number>;
	/** back全部數量 */
	backTotalSize?: Maybe<number>;
	/** lay尚餘數量 */
	layAvailableSize?: Maybe<number>;
	/** lay全部數量 */
	layTotalSize?: Maybe<number>;
	/** maxPerOrderCredit */
	maxPerOrderCredit?: Maybe<number>;
	/** maxPerMarketExposure */
	maxPerMarketExposure?: Maybe<number>;
	/** isBallRunning */
	isBallRunning?: Maybe<boolean>;
	/** 狀態 */
	status?: Maybe<string>;
	/** settle分數 */
	finalScore?: Maybe<number>;
	/** 排序順序 */
	sortOrder?: Maybe<number>;
	/** 創建者 */
	creator?: Maybe<string>;
	/** 創立時間 */
	createdAt?: Maybe<Date>;
	/** 更新者 */
	updater?: Maybe<string>;
	/** 更新時間 */
	updatedAt?: Maybe<Date>;
	/** 賽事是否進行同步 */
	isSync?: Maybe<boolean>;
	/** Fancy賭盤的第三方來源 */
	resourceFrom?: Maybe<string>;
}

export interface IMultipleFancy extends IMultipleFancyInterface {
	/** id */
	id: string;
	/** resourceId */
	resourceId?: Maybe<string>;
	/** name */
	name: string;
	/** categoryId */
	categoryId?: Maybe<number>;
	/** EventId */
	eventId?: Maybe<string>;
	/** oddType */
	oddType?: Maybe<string>;
	/** 敘述 */
	description?: Maybe<string>;
	/** maxPerOrderCredit */
	maxPerOrderCredit?: Maybe<number>;
	/** maxPerMarketExposure */
	maxPerMarketExposure?: Maybe<number>;
	/** 狀態 */
	status?: Maybe<string>;
	/** 排序順序 */
	sortOrder?: Maybe<number>;
	fancyTypeId?: Maybe<number>;
	/** selection */
	selections?: Maybe<ISelection[]>;
	/** 創建者 */
	creator?: Maybe<string>;
	/** 創立時間 */
	createdAt?: Maybe<Date>;
	/** 更新者 */
	updater?: Maybe<string>;
	/** 更新時間 */
	updatedAt?: Maybe<Date>;
	/** Code Changed - Manoj added  marketTypeId*/
	marketTypeId?: Maybe<number>;
	/** Code Changed - Manoj ( add isSync and resourceFrom) */
	isSync: boolean;

	resourceFrom?: Maybe<string>
	betDelay?: any
}

export interface ISelection extends ISelectionInterface {
	/** id */
	id: number;
	/** marketId */
	marketId?: Maybe<number>;
	/** 名稱 */
	name: string;
	/** status */
	status: string;
	/** backPrice */
	backPrice?: Maybe<number>;
	/** layPrice */
	layPrice?: Maybe<number>;
	/** isRunning */
	isRunning?: Maybe<boolean>;
	/** sortPriority */
	sortPriority?: Maybe<number>;
	/** backStatus */
	backStatus?: Maybe<string>;
	/** layStatus */
	layStatus?: Maybe<string>;
	/** back尚餘數量 */
	backAvailableSize?: Maybe<number>;
	/** back全部數量 */
	backTotalSize?: Maybe<number>;
	/** lay尚餘數量 */
	layAvailableSize?: Maybe<number>;
	/** lay全部數量 */
	layTotalSize?: Maybe<number>;
	/** 創建者 */
	creator?: Maybe<string>;
	/** 創立時間 */
	createdAt?: Maybe<Date>;
	/** 更新者 */
	updater?: Maybe<string>;
	/** 更新時間 */
	updatedAt?: Maybe<Date>;
}

export interface IMultipleFancyLog extends IMultipleFancyInterface {
	/** log id */
	logId: string;
	/** id */
	id: string;
	/** resourceId */
	resourceId?: Maybe<string>;
	/** name */
	name: string;
	/** categoryId */
	categoryId?: Maybe<number>;
	/** EventId */
	eventId?: Maybe<string>;
	/** oddType */
	oddType?: Maybe<string>;
	/** 敘述 */
	description?: Maybe<string>;
	/** maxPerOrderCredit */
	maxPerOrderCredit?: Maybe<number>;
	/** maxPerMarketExposure */
	maxPerMarketExposure?: Maybe<number>;
	/** 狀態 */
	status?: Maybe<string>;
	/** 排序順序 */
	sortOrder?: Maybe<number>;
	/** selection */
	selections?: Maybe<ISelection[]>;
	/** 創建者 */
	creator?: Maybe<string>;
	/** 創立時間 */
	createdAt?: Maybe<Date>;
	/** 更新者 */
	updater?: Maybe<string>;
	/** 更新時間 */
	updatedAt?: Maybe<Date>;
	/** log建立時間 */
	loggingAt?: Maybe<Date>;
}

export interface ISelectionLog extends ISelectionInterface {
	/** log id */
	logId: string;
	/** id */
	id: number;
	/** marketId */
	marketId?: Maybe<number>;
	/** 名稱 */
	name: string;
	/** status */
	status: string;
	/** backPrice */
	backPrice?: Maybe<number>;
	/** layPrice */
	layPrice?: Maybe<number>;
	/** isRunning */
	isRunning?: Maybe<boolean>;
	/** sortPriority */
	sortPriority?: Maybe<number>;
	/** backStatus */
	backStatus?: Maybe<string>;
	/** layStatus */
	layStatus?: Maybe<string>;
	/** back尚餘數量 */
	backAvailableSize?: Maybe<number>;
	/** back全部數量 */
	backTotalSize?: Maybe<number>;
	/** lay尚餘數量 */
	layAvailableSize?: Maybe<number>;
	/** lay全部數量 */
	layTotalSize?: Maybe<number>;
	/** 創建者 */
	creator?: Maybe<string>;
	/** 創立時間 */
	createdAt?: Maybe<Date>;
	/** 更新者 */
	updater?: Maybe<string>;
	/** 更新時間 */
	updatedAt?: Maybe<Date>;
	/** log建立時間 */
	loggingAt?: Maybe<Date>;
}

export interface IGameQuery {
	/** 帳戶資訊 */
	balanceInfo?: Maybe<IGameBalanceInfo[]>;
	/** 帳戶報表 */
	statement: IGameUrlResponse;
}

export interface IGameBalanceInfo {
	/** 遊戲平台 */
	platformCode: string;
	/** 遊戲平台帳號 */
	playerName: string;
	/** 餘額 */
	balance: number;
	/** 使用幣值 */
	exchangeRate?: Maybe<number>;
	/** 可轉小數位 */
	enableFloat?: Maybe<boolean>;
}

export interface IGameUrlResponse extends IResponseInterface {
	/** 狀態 */
	success: boolean;
	/** 狀態代碼 */
	code?: Maybe<string>;
	/** 訊息 */
	message: string;
	/** 回傳資訊 */
	data?: Maybe<IGameUrlResponseData>;
}

export interface IGameUrlResponseData {
	/** URL */
	url: string;
}

export interface IMutation {
	/** Redis */
	redis?: Maybe<IRedisMutation>;
	/** 會員 */
	member?: Maybe<IMemberMutation>;
	/** 交易 */
	exchange?: Maybe<IExchangeMutation>;
	/** 代理人會員資料 */
	agentMember?: Maybe<IAgentMemberMutation>;
	/** 代理站下注資料 */
	agentExchange?: Maybe<IAgentExchangeMutation>;
	/** 代理站市場資料 */
	agentMarketMgmt?: Maybe<IAgentMarketMgmtMutation>;
	/** 遊戲平台 */
	game?: Maybe<IGameMutation>;
	/** 轉帳 */
	transaction?: Maybe<ITransactionMutation>;
}

export interface IRedisMutation {
	/** 新增 Redis 資料 */
	set?: Maybe<IResult>;
}

export interface IResult {
	code: number;

	message?: Maybe<string>;
}

export interface IMemberMutation {
	/** 會員登入 */
	login: ILoginResponse;
	/** 會員登出 */
	logout?: Maybe<IStatusResponse>;
	/** ptLogout */
	casinoLogout?: Maybe<IStatusResponse>;
	/** 新增/刪除 最愛清單 */
	favorite?: Maybe<IStatusResponse>;
	/** 驗證及更新密碼 */
	password?: Maybe<IStatusResponse>;
	/** 驗證google authentication (登入時用) */
	validOTP?: Maybe<IStatusResponse>;
	/** 綁定google authentication (設定時用) */
	bindOTP?: Maybe<IStatusResponse>;
	/** 刪除帳戶的google authentication（移除本人帳戶或移除下限的，根據有沒有送target做判斷） */
	removeOTP?: Maybe<IStatusResponse>;
}

export interface ILoginResponse extends IResponseInterface {
	/** 狀態 */
	success: boolean;
	/** 狀態代碼 */
	code?: Maybe<string>;
	/** 訊息 */
	message: string;
	/** 登入資訊回傳 */
	data?: Maybe<ILoginResponseData>;
}

export interface ILoginResponseData {
	/** 用戶 ID */
	memberId?: Maybe<number>;
	/** 用戶種類 */
	type?: Maybe<string>;
	/** 下注限制 */
	orderLimit?: Maybe<IOrderLimit[]>;
	/** 匯率 */
	exchangeRate?: Maybe<number>;
	/** 是否是子帳號 */
	isSubAccount?: Maybe<boolean>;
	/** token */
	token?: Maybe<string>;
	/** 是否有綁定google authentication登入流程 */
	isHadAuth?: Maybe<boolean>;
	/** Betfair 資訊 */
	betfairInfo?: Maybe<IBetfairInfo[]>;
	/** 登入的用戶ID */
	originalMemberId?: Maybe<number>;
	/** 是否更改預設密碼 */
	isChangedPassword?: Maybe<boolean>;
}

export interface IExchangeMutation {
	/** 下單 */
	placeOrders?: Maybe<IPlaceOrderResponse>;

	placeOrder?: Maybe<IPlaceOrderResponse>;
	/** 取消單 */
	cancelOrder?: Maybe<IStatusResponse>;
	/** 更新單 */
	updateOrder?: Maybe<IUpdateOrderResponse>;
}

export interface IPlaceOrderResponse extends IResponseInterface {
	/** 狀態 */
	success: boolean;
	/** 狀態代碼 */
	code?: Maybe<string>;
	/** 訊息 */
	message: string;
	/** 回傳資訊 */
	data?: Maybe<IPlaceOrderResponseData>;
}

export interface IPlaceOrderResponseData {
	transactionNumber?: Maybe<string>;

	match?: Maybe<number>;

	unmatch?: Maybe<number>;
}

export interface IUpdateOrderResponse extends IResponseInterface {
	/** 狀態 */
	success: boolean;
	/** 狀態代碼 */
	code?: Maybe<string>;
	/** 訊息 */
	message: string;
	/** 回傳資訊 */
	data?: Maybe<IUpdateOrderResponseData>;
}

export interface IUpdateOrderResponseData {
	transactionNumber?: Maybe<string>;
}

export interface IAgentMemberMutation {
	/** 建立下線 */
	createAccount?: Maybe<ICreateAccountResponse>;
	/** 建立子帳號 */
	createSubAccount?: Maybe<ICreateSubAccountResponse>;
	/** 更新下線 */
	updateAccount?: Maybe<IUpdateAccountResponse>;
	/** 更新子帳號 */
	updateSubAccount?: Maybe<IUpdateAccountResponse>;
	/** 新增關聯帳號 */
	addAssociatedAccount?: Maybe<IAddAssociatedAccountResponse>;
	/** 更新用戶所有賭盤注單資訊及重算曝險 */
	recalculate?: Maybe<IStatusResponse>;
}

export interface ICreateAccountResponse extends IResponseInterface {
	/** 狀態 */
	success: boolean;
	/** 狀態代碼 */
	code?: Maybe<string>;
	/** 訊息 */
	message: string;
	/** 登入資訊回傳 */
	data?: Maybe<IDownlineDetail>;
}

export interface ICreateSubAccountResponse extends IResponseInterface {
	/** 狀態 */
	success: boolean;
	/** 狀態代碼 */
	code?: Maybe<string>;
	/** 訊息 */
	message: string;
	/** 登入資訊回傳 */
	data?: Maybe<ICreateAccountResponseData>;
}

export interface ICreateAccountResponseData {
	/** 新建立帳號ID */
	memberId: number;
}

export interface IUpdateAccountResponse extends IResponseInterface {
	/** 狀態 */
	success: boolean;
	/** 狀態代碼 */
	code?: Maybe<string>;
	/** 訊息 */
	message: string;
	/** 修改資訊回傳 */
	data?: Maybe<string>;
}

export interface IAddAssociatedAccountResponse extends IResponseInterface {
	/** 狀態 */
	success: boolean;
	/** 狀態代碼 */
	code?: Maybe<string>;
	/** 訊息 */
	message: string;
	/** 新增關聯帳號資訊回傳 */
	data?: Maybe<IAddAssociatedAccountResponseData>;
}

export interface IAddAssociatedAccountResponseData {
	username: string;

	memberId: number;
}

export interface IAgentExchangeMutation {
	/** 切換是否延後轉帳 */
	pendingTransfer?: Maybe<IPendingTransferResponse>;
	/** 更新註記 */
	updateOrderRemark?: Maybe<IUpdateOrderRemarkResponse>;
	/** 取消未結算注單 */
	voidInplay?: Maybe<IVoidInplayResponse>;
	/** 取消已結算注單 */
	cancelOrders?: Maybe<IAgentCancelOrderResponse>;
}

export interface IPendingTransferResponse extends IResponseInterface {
	/** 狀態 */
	success: boolean;
	/** 狀態代碼 */
	code?: Maybe<string>;
	/** 訊息 */
	message: string;
	/** 資訊回傳 */
	data?: Maybe<string>;
}

export interface IUpdateOrderRemarkResponse extends IResponseInterface {
	/** 狀態 */
	success: boolean;
	/** 狀態代碼 */
	code?: Maybe<string>;
	/** 訊息 */
	message: string;
	/** 資訊回傳 */
	data?: Maybe<string>;
}

export interface IVoidInplayResponse extends IResponseInterface {
	/** 狀態 */
	success: boolean;
	/** 狀態代碼 */
	code?: Maybe<string>;
	/** 訊息 */
	message: string;
	/** 資訊回傳 */
	data?: Maybe<string>;
}

export interface IAgentCancelOrderResponse extends IResponseInterface {
	/** 狀態 */
	success: boolean;
	/** 狀態代碼 */
	code?: Maybe<string>;
	/** 訊息 */
	message: string;
	/** 資訊回傳 */
	data: IOrderInfo[];
}

export interface IOrderInfo {
	orderNumber?: Maybe<string>;

	settleNumber?: Maybe<string>;

	source?: Maybe<IMarketSource>;
}

export interface IAgentMarketMgmtMutation {
	/** 設定賭盤啟用和開盤 */
	update?: Maybe<IUpdateMarketResponse>;
	/** 設定賽事啟用和開盤 */
	updateEvent?: Maybe<IUpdateEventResponse>;
	/** 設定聯賽啟用和開盤 */
	updateCompetition?: Maybe<IUpdateCompetitionResponse>;
	/** 設定賭盤AutoSetting */
	updateAutoSetting?: Maybe<IStatusResponse>;
	/** 對此代理的線新增或取消賽事黑名單 */
	updateBlacklist?: Maybe<IUpdateBlacklistResponse>;
	/** 對此代理的線新增或取消賽事黑名單 */
	updateCompetitionBlacklist?: Maybe<IUpdateCompetitionBlacklistResponse>;
	/** Resettle BF賭盤 */
	resettleMarket?: Maybe<IStatusResponse>;
	/** 取消已結算market, 重新結算 */
	cancelMarket?: Maybe<ICancelMarketResponse>;
	/** 作廢market注單 */
	invalidMarketOrder?: Maybe<ICancelMarketResponse>;
	/** single fancy 新增或更新 */
	updateFancy?: Maybe<IUpdateMarketFancyResponse>;
	/** single fancy 改變排序 */
	updateFancyOrder?: Maybe<IUpdateMarketFancyOrderResponse>;
	/** multiple fancy 新增或更新 */
	updateMultiSelectionFancy?: Maybe<IUpdateMultiSelectionFancyResponse>;
	/** multiple selection 新增或更新 */
	updateMultiSelection?: Maybe<IUpdateMultiSelectionResponse>;
	/** multiple selection 改變排序 */
	updateMultiSelectionOrder?: Maybe<IStatusResponse>;
}

export interface IUpdateMarketResponse {
	/** 狀態 */
	success: boolean;
	/** 訊息 */
	message: string;
	/** 賭盤ID */
	marketId: string;
	/** 啟用 */
	isEnable: boolean;
	/** 開盤 */
	isOrder: boolean;
	/** 手動標記 */
	isManual: boolean;
}

export interface IUpdateEventResponse {
	/** 狀態 */
	success: boolean;
	/** 訊息 */
	message: string;
	/** 賽事ID */
	eventId: string;
	/** 啟用 */
	isEnable: boolean;
	/** 開盤 */
	isOrder: boolean;

	isSync?: Maybe<boolean>;
}

export interface IUpdateCompetitionResponse {
	/** 狀態 */
	success: boolean;
	/** 訊息 */
	message: string;
	/** 賭盤ID */
	competitionId: string;
	/** 啟用 */
	isEnable: boolean;
	/** 開盤 */
	isOrder: boolean;
}

export interface IUpdateBlacklistResponse {
	/** 狀態 */
	success: boolean;
	/** 訊息 */
	message: string;
	/** 更新的賽事ID */
	eventId: string;
}

export interface IUpdateCompetitionBlacklistResponse {
	/** 狀態 */
	success: boolean;
	/** 訊息 */
	message: string;
	/** 更新的聯賽ID */
	competitionId: string;
}

export interface ICancelMarketResponse extends IResponseInterface {
	/** 狀態 */
	success: boolean;
	/** 狀態代碼 */
	code?: Maybe<string>;
	/** 訊息 */
	message: string;
	/** 資訊回傳 */
	data?: Maybe<string>;
}

export interface IUpdateMarketFancyResponse {
	/** ID */
	id: string;
	/** 狀態 */
	success: boolean;
	/** 訊息 */
	message?: Maybe<string>;
	/** 是否為新增 */
	isAdd: boolean;
	/** 送進來的資料 */
	input?: Maybe<IUpdateMarketFancyData>;
}

export interface IUpdateMarketFancyData {
	/** ID */
	id: string;
	/** 標題 */
	title: string;
	/** CategoryId */
	categoryId: number;
	/** ResourceId */
	resourceId?: Maybe<string>;
	/** EventId */
	eventId: string;
	/** CompetitionId */
	competitionId: string;
	/** StartedAt */
	startedAt: Date;
	/** 敘述 */
	description: string;
	/** Runs Back */
	backValue: number;
	/** Runs Lay */
	layValue: number;
	/** backOdds */
	backOdds: number;
	/** layOdds */
	layOdds: number;
	/** back status */
	backStatus: string;
	/** lay status */
	layStatus: string;
	/** back size */
	backSize: number;
	/** lay size */
	laySize: number;
	/** inPlay */
	isPlay: boolean;
	/** isBallRunning */
	isBallRunning: boolean;
	/** 狀態 */
	status: string;
	/** maxPerOrderCredit */
	maxPerOrderCredit: number;
	/** maxPerMarketExposure */
	maxPerMarketExposure: number;
	/** 新增Fancy的人 */
	creator: string;
	/** isDeleteFlag */
	isDeleteFlag: boolean;
	/** settle分數 */
	finalScore?: Maybe<number>;
	/** 排序 */
	sortOrder?: Maybe<number>;
	/** back尚餘數量 */
	backAvailableSize?: Maybe<number>;
	/** lay尚餘數量 */
	layAvailableSize?: Maybe<number>;
	/** 是否同步 */
	isSync?: Maybe<boolean>;
	/** Resource From  */
	resourceFrom: string
}

export interface IUpdateMarketFancyOrderResponse {
	/** 狀態 */
	success: boolean;
	/** 訊息 */
	message: string;
}

export interface IUpdateMultiSelectionFancyResponse {
	/** 狀態 */
	success: boolean;
	/** 訊息 */
	message: string;
	/** 是否為新增 */
	isAdd: boolean;
	/** ID */
	id: string;
	/** 輸入資料 */
	input?: Maybe<IUpdateMultiSelectionFancyData>;
}

export interface IUpdateMultiSelectionFancyData {
	/** ID */
	id: string;
	/** 標題 */
	title: string;
	/** CategoryId */
	categoryId: number;
	/** EventId */
	eventId: string;
	/** CompetitionId */
	competitionId: string;
	/** StartedAt */
	startedAt: Date;
	/** 敘述 */
	description: string;
	/** inPlay */
	isPlay: boolean;
	/** 狀態 */
	status: string;
	/** maxPerOrderCredit */
	maxPerOrderCredit: number;
	/** maxPerMarketExposure */
	maxPerMarketExposure: number;
	/** settle選項ID */
	settleSelectionId?: Maybe<number>;
	/** 盤口種類 */
	oddType: IOddType;
}

export interface IUpdateMultiSelectionResponse {
	/** 狀態 */
	success: boolean;
	/** 訊息 */
	message: string;
	/** 是否為新增 */
	isAdd: boolean;
	/** 新選項ID */
	id: number;
	/** 選項資料 */
	input?: Maybe<IUpdateMultiSelectionData>;
}

export interface IUpdateMultiSelectionData {
	/** 賭盤ID */
	marketId: string;
	/** 選項ID (若-1則新增) */
	selectionId: number;
	/** 選項名稱 */
	selectionName: string;
	/** layOdds */
	layOdds: number;
	/** backOdds */
	backOdds: number;
	/** isBallRunning 若null則不修改 */
	isBallRunning: boolean;
	/** back狀態 */
	backStatus: string;
	/** lay狀態 */
	layStatus: string;
	/** back總下注量 */
	backSize: number;
	/** lay總下注量 */
	laySize: number;
	/** 原本排序的位置(若新增時則給0) */
	sortPriority: number;
	/** back remain的量 */
	backAvailableSize?: Maybe<number>;
	/** lay remain的量 */
	layAvailableSize?: Maybe<number>;
}

export interface IGameMutation {
	/** 開啟遊戲 (PlayGame) */
	playgame?: Maybe<IGamePlayGameResponse>;
	/** 轉帳 */
	transfer?: Maybe<IGameBalanceResponse>;
}

export interface IGamePlayGameResponse extends IResponseInterface {
	/** 狀態 */
	success: boolean;
	/** 狀態代碼 */
	code?: Maybe<string>;
	/** 訊息 */
	message: string;
	/** 回傳資訊 */
	data?: Maybe<IGameLoginResponseData>;
}

export interface IGameLoginResponseData {
	/** 遊戲登入 URL */
	url: string;
}

export interface IGameBalanceResponse extends IResponseInterface {
	/** 狀態 */
	success: boolean;
	/** 狀態代碼 */
	code?: Maybe<string>;
	/** 訊息 */
	message: string;
	/** 回傳資訊 */
	data?: Maybe<IGameBalanceResponseData>;
}

export interface IGameBalanceResponseData {
	/** 存款金額 */
	amount: number;
	/** 交易單號 */
	transactionNumber: string;
	/** 交易時間 */
	transactionDate: string;
}

export interface ITransactionMutation {
	/** 轉一位下線的錢 */
	singleTransfer?: Maybe<ISingleTransferResponse>;
	/** 轉多位下線的錢 */
	batchTransfer?: Maybe<IBatchTransferResponse>;
}

export interface ISingleTransferResponse {
	/** 狀態 */
	success: boolean;
	/** 訊息 */
	message: string;
}

export interface IBatchTransferResponse {
	/** 狀態 */
	success: boolean;
	/** 訊息 */
	message: string;
}

export interface ISubscription {
	memberOrder?: Maybe<IOrderGroup>;

	memberInfo?: Maybe<ISubscribedMemberInfo>;

	memberFavorite?: Maybe<IEventInfo[]>;

	marketList?: Maybe<IEventInfo[]>;

	selectionInfo?: Maybe<ISubscribedSelectionInfo[]>;
}

export interface ISubscribedMemberInfo {
	credit?: Maybe<ICreditInfo>;

	isValid?: Maybe<boolean>;
}

export interface ISubscribedSelectionInfo {
	marketId: string;

	selectionId: string;

	marketSource: IMarketSource;

	marketType: number;

	side: ISide;

	price?: Maybe<number>;
}

export interface IAgentPersonaInfo {
	/** 使用者ID */
	memberId: number;
	/** 使用者名稱 */
	username: string;
	/** 品牌 */
	brandId: number;
	/** 使用者層級 */
	type: IMemberType;
	/** 使用者匯率ID */
	currencyId: number;
	/** 匯率 */
	exchangeRate: number;
	/** 語言 */
	lang: string;
}

export interface IAgentTargetInfo {
	/** 使用者ID */
	memberId: number;
	/** 使用者層級 */
	type: string;
}

export interface IAuthInfo {
	/** 使用者帳號 */
	username: string;
	/** 使用者密碼 */
	password: string;
	/** 品牌 */
	brandId: number;
}

export interface ISportProfitLossInfo {
	memberId: number;

	username: string;

	exchangeRate: number;

	eventId: string;

	eventName: string;

	marketName: string;

	marketTypeId: number;

	marketTypeName: string;

	marketId: string;

	settleNumber: string;

	winlossCredit: number;

	turnOverCredit: number;

	commissionCredit: number;

	agentWinlossCredit: number;

	masterMemberId?: Maybe<number>;

	masterMemberName?: Maybe<string>;

	masterWinlossCredit: number;

	masterCommissionCredit: number;

	superMemberId?: Maybe<number>;

	superMemberName?: Maybe<string>;

	superWinlossCredit: number;

	superCommissionCredit: number;

	adminMemberId?: Maybe<number>;

	adminMemberName?: Maybe<string>;

	adminWinlossCredit: number;

	adminCommissionCredit: number;

	superAdminMemberId?: Maybe<number>;

	superAdminMemberName?: Maybe<string>;

	superAdminWinlossCredit: number;

	superAdminCommissionCredit: number;

	companyAdminWinlossCredit: number;

	companyAdminCommissionCredit: number;

	createdAt: Date;

	clientExposureCredit: number;

	clientLimitCredit: number;

	ip?: Maybe<string>;
}

/** 代理輸贏注單 */
export interface ISportProfitLossOrderInfo {
	memberId: number;

	username: string;

	orderNumber: string;

	transactionNumber?: Maybe<string>;

	settleNumber: string;

	exchangeRate: number;

	source: string;

	currencyCode: string;

	competitionId?: Maybe<string>;

	eventId: string;

	eventName: string;

	marketId: string;

	marketName: string;

	marketTypeId: number;

	marketTypeName: string;

	selectionId: string;

	selectionName: string;

	oddType: string;

	backValue?: Maybe<number>;

	layValue?: Maybe<number>;

	categoryId: number;

	isInplay: boolean;

	side?: Maybe<string>;

	orderPrice: number;

	price?: Maybe<number>;

	orderCredit: number;

	matchedCredit: number;

	unmatchedCredit: number;

	voidCredit?: Maybe<number>;

	cancelCredit?: Maybe<number>;

	lapseCredit?: Maybe<number>;

	createdAt: Date;

	settledAt?: Maybe<Date>;

	winlossCredit: number;

	isOneClickOrder: boolean;

	status: string;

	cancelledAt?: Maybe<Date>;

	checkoutAt?: Maybe<Date>;

	invalidedAt?: Maybe<Date>;

	clientRemark?: Maybe<string>;

	agentRemark?: Maybe<string>;

	isPendingTransfer?: Maybe<boolean>;

	deviceType: number;

	matchedAt?: Maybe<Date>;

	ip: string;
}

// ====================================================
// Arguments
// ====================================================

export interface IGetRedisQueryArgs {
	key: string;
}
export interface IListMarketQueryArgs {
	input: IMarketEventInput;
}
export interface ISearchMarketQueryArgs {
	input: ISearchInput;
}
export interface IHotMarketQueryArgs {
	input: IHotEventsInput;
}
export interface IMainEventInfoArgs {
	input?: Maybe<IMarketTypeInput>;
}
export interface IValidateMemberQueryArgs {
	input: IValidateInput;
}
export interface ICreditMemberQueryArgs {
	input?: Maybe<ICreditInput>;
}
export interface IUserInfoMemberQueryArgs {
	input: IUserInfoInput;
}
export interface ITransactionsExchangeQueryArgs {
	input: ITransactionInput;
}
export interface IProfitLossExchangeQueryArgs {
	input: IProfitLossInput;
}
export interface IMyOrdersExchangeQueryArgs {
	input: IMyOrderInput;
}
export interface IPositionPositionQueryArgs {
	input: IPositionInput;
}
export interface IInfoSystemQueryArgs {
	input: ISystemInfoInput;
}
export interface IConfigSystemQueryArgs {
	input: ISystemConfigInput;
}
export interface IDownlineAgentMemberQueryArgs {
	input: IDownlineInput;
}
export interface IDownlineListAgentMemberQueryArgs {
	input: IDownlineListInput;
}
export interface IAllDownlineListAgentMemberQueryArgs {
	input: IAllDownlineListInput;
}
export interface IAllUpperlineListAgentMemberQueryArgs {
	input: IAllUpperlineListInput;
}
export interface IListSubAccountAgentMemberQueryArgs {
	input: IAgentPersonaInput;
}
export interface IInfoAgentMemberQueryArgs {
	input: IAgentPersonaInput;
}
export interface IBalanceAgentMemberQueryArgs {
	input: IAgentPersonaTargetInput;
}
export interface IListActiveAccountAgentMemberQueryArgs {
	input: IAgentPersonaInput;
}
export interface IListPastIpAddressAgentMemberQueryArgs {
	input: IAgentPersonaTargetInput;
}
export interface IListPtAgentMemberQueryArgs {
	input: IAgentPersonaTargetInput;
}
export interface ISportOrderListAgentExchangeQueryArgs {
	input: ISportOrderListInput;
}
export interface ISportOrderMatchListAgentExchangeQueryArgs {
	input: ISportOrderMatchListInput;
}
export interface IMyActivityAgentExchangeQueryArgs {
	input: IMyActivityInput;
}
export interface IAccountStatementAgentExchangeQueryArgs {
	input?: Maybe<IAccountStatementInput>;
}
export interface ISettlingStatementAgentExchangeQueryArgs {
	input?: Maybe<ISettlingStatementInput>;
}
export interface IEventDetailAgentExchangeQueryArgs {
	input?: Maybe<IEventDetailInput>;
}
export interface INetExposureAgentExchangeQueryArgs {
	input: IAgentNetExposureInput;
}
export interface ISportMarketDataAgentExchangeQueryArgs {
	input: ISportMarketDataInput;
}
export interface ISportProfitLossListAgentExchangeQueryArgs {
	input: ISportProfitLossInput;
}
export interface ISportProfitLossOrderListAgentExchangeQueryArgs {
	input: ISportProfitLossOrderInput;
}
export interface IMainExposureSportWatchListArgs {
	input: IMainExposureInput;
}
export interface IFancyExposureSportWatchListArgs {
	input: IFancyExposureInput;
}
export interface INotificationListAgentMarketMgmtQueryArgs {
	input: INotificationListInput;
}
export interface IListAgentMarketMgmtQueryArgs {
	input: IAgentMarketListInput;
}
export interface IListFancysAgentMarketMgmtQueryArgs {
	input: IAgentMarketFancyListInput;
}
export interface IMultipleFancysAgentMarketMgmtQueryArgs {
	input: IAgentMarketFancyMultipleInput;
}
export interface IMultipleFancyLogsAgentMarketMgmtQueryArgs {
	input: IAgentMultipleFancyLogsInput;
}
export interface ISelectionLogsAgentMarketMgmtQueryArgs {
	input: IAgentSelectionLogInput;
}
export interface IBalanceInfoGameQueryArgs {
	input: IBalanceIntoInput;
}
export interface IStatementGameQueryArgs {
	input: IGameStatementInput;
}
export interface ISetRedisMutationArgs {
	key: string;

	value: string;

	expireSecond: number;
}
export interface ILoginMemberMutationArgs {
	input: ILoginInput;
}
export interface ICasinoLogoutMemberMutationArgs {
	input: ICasinoLogoutInput;
}
export interface IFavoriteMemberMutationArgs {
	input: IFavoriteInput;
}
export interface IPasswordMemberMutationArgs {
	input: IPasswordInput;
}
export interface IValidOtpMemberMutationArgs {
	input: IValidOtpRequest;
}
export interface IBindOtpMemberMutationArgs {
	input: IBindOtpRequest;
}
export interface IRemoveOtpMemberMutationArgs {
	input: IRemoveOtpRequest;
}
export interface IPlaceOrdersExchangeMutationArgs {
	input: IPlaceOrdersInput;
}
export interface IPlaceOrderExchangeMutationArgs {
	input: IPlaceOrdersInput;
}
export interface ICancelOrderExchangeMutationArgs {
	input: ICancelOrdersInput;
}
export interface IUpdateOrderExchangeMutationArgs {
	input: IUpdateOrderInput;
}
export interface ICreateAccountAgentMemberMutationArgs {
	input: ICreateAccountInput;
}
export interface ICreateSubAccountAgentMemberMutationArgs {
	input: ICreateSubAccountInput;
}
export interface IUpdateAccountAgentMemberMutationArgs {
	input: IUpdateAccountInput;
}
export interface IUpdateSubAccountAgentMemberMutationArgs {
	input: IUpdateSubAccountInput;
}
export interface IAddAssociatedAccountAgentMemberMutationArgs {
	input: IAddAssociatedAccountInput;
}
export interface IRecalculateAgentMemberMutationArgs {
	input: IAgentPersonaTargetInput;
}
export interface IPendingTransferAgentExchangeMutationArgs {
	input: IPendingTransferInput;
}
export interface IUpdateOrderRemarkAgentExchangeMutationArgs {
	input: IUpdateOrderRemarkInput;
}
export interface IVoidInplayAgentExchangeMutationArgs {
	input: IVoidInplayInput;
}
export interface ICancelOrdersAgentExchangeMutationArgs {
	input: IAgentCancelOrderInput;
}
export interface IUpdateAgentMarketMgmtMutationArgs {
	input: IAgentMarketUpdateInput;
}
export interface IUpdateEventAgentMarketMgmtMutationArgs {
	input: IAgentEventUpdateInput;
}
export interface IUpdateCompetitionAgentMarketMgmtMutationArgs {
	input: IAgentCompetitionUpdateInput;
}
export interface IUpdateAutoSettingAgentMarketMgmtMutationArgs {
	input?: Maybe<IAutoSettingUpdateInput>;
}
export interface IUpdateBlacklistAgentMarketMgmtMutationArgs {
	input?: Maybe<IBlacklistUpdateInput>;
}
export interface IUpdateCompetitionBlacklistAgentMarketMgmtMutationArgs {
	input?: Maybe<ICompetitionBlacklistUpdateInput>;
}
export interface IResettleMarketAgentMarketMgmtMutationArgs {
	input?: Maybe<IResettleMarketInput>;
}
export interface ICancelMarketAgentMarketMgmtMutationArgs {
	input: ICancelMarketInput;
}
export interface IInvalidMarketOrderAgentMarketMgmtMutationArgs {
	input: ICancelMarketInput;
}
export interface IUpdateFancyAgentMarketMgmtMutationArgs {
	input: IAgentMarketUpdateFancyInput;
}
export interface IUpdateFancyOrderAgentMarketMgmtMutationArgs {
	input: IAgentMarketUpdateFancyOrderInput;
}
export interface IUpdateMultiSelectionFancyAgentMarketMgmtMutationArgs {
	input: IUpdateMultiSelectionFancyInput;
}
export interface IUpdateMultiSelectionAgentMarketMgmtMutationArgs {
	input: IUpdateMultiSelectionInput;
}
export interface IUpdateMultiSelectionOrderAgentMarketMgmtMutationArgs {
	input: IUpdateMultiSelectionOrderInput;
}
export interface IPlaygameGameMutationArgs {
	input: IGamePlayGameInput;
}
export interface ITransferGameMutationArgs {
	input: IGameTransferInput;
}
export interface ISingleTransferTransactionMutationArgs {
	input: ISingleTransferInput;
}
export interface IBatchTransferTransactionMutationArgs {
	input: IBatchTransferInput;
}
export interface IMemberOrderSubscriptionArgs {
	input: IMemberOrderInput;
}
export interface IMemberInfoSubscriptionArgs {
	input: IMemberInfoInput;
}
export interface IMemberFavoriteSubscriptionArgs {
	input: IMemberFavoriteInput;
}
export interface IMarketListSubscriptionArgs {
	input: IMarketListInput;
}
export interface ISelectionInfoSubscriptionArgs {
	input: ISelectionInfoInput[];
}

// ====================================================
// Unions
// ====================================================

export type IMarketListType = ISelectionInfo | IFancyInfo;

// Generated in 2020-01-09T10:06:34+08:00
