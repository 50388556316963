import React, { useEffect } from 'react'
import { withAppContext } from 'src/store/initAppContext';
import styled, { withTheme } from 'styled-components';
import { LazyLoadImage } from "react-lazy-load-image-component";
import config from "src/config/config";
import PlaceholderImage from 'src/components/Placeholder';
import { theme } from 'styled-tools';
import FooterContainer from 'src/components/footer/footer';

function SmartSoftPriceDrop2k25PromoFun(props: any) {
    const { app: {
        brandId
    }, theme } = props;

    useEffect(() => {
        window.scrollTo(0, 0)
    }, [])

    return (
        <>
            <InformationWrapper>
                <Container>
                    <MainTitle>
                        <h2>The thrill is real! Get ready for an electrifying <br /><strong>₹31,00,000</strong> Prize Drop with Smartsoft!</h2>
                        <p>From March 1 to May 31, play JetX and grab your share of massive cash rewards!</p>
                    </MainTitle>
                    <SectionWrapper>
                        <ImgWrapper>
                            <LazyLoadImage
                                src={`${config.imageV3Host}/static/cashsite/brand/6/image/smartSoftPriceDropFunParticipateImg.png`}
                                alt="Img"
                                effect="none"
                                placeholder={<PlaceholderImage />}
                            />
                        </ImgWrapper>
                        <ContentWrapper>
                            <h3>How to Participate?</h3>
                            <ul>
                                <li>Place a minimum bet of ₹50 to qualify</li>
                                <li>Play in real money mode</li>
                                <li>While playing, a Prize Drop Wheel may randomly appear</li>
                                <li>Click SPIN to win instant cash prizes up to ₹50,000!</li>
                                <li>Prizes are credited instantly to your casino balance</li>
                            </ul>
                        </ContentWrapper>
                    </SectionWrapper>
                    
                    <FullWidthImg>
                        <div className='contentWrapper'>
                            <h3>Additional Rules</h3>
                            <ul>
                                <li>Players have 30 seconds to spin the wheel; otherwise, it will spin automatically.</li>
                                <li>Auto-spin will be disabled when the wheel appears.</li>
                                <li>Only real money bets qualify for the promotion.</li>
                                <li>Prizes on the wheel are randomly generated at the start of each spin.</li>
                                <li>FUN88 reserves the right to void winnings in case of cheating, technical issues, or errors.</li>
                            </ul>
                        </div>
                        <ImgWrapper className='noWidth'>
                            <LazyLoadImage
                                src={`${config.imageV3Host}/static/cashsite/brand/6/image/smartSoftPriceDropFunFlightImg.png`}
                                alt="Img"
                                effect="none"
                                placeholder={<PlaceholderImage />}
                            />
                        </ImgWrapper>
                    </FullWidthImg>
                    <MainTitleBold>
                        <p>Keep spinning, keep winning! Dive into JetX now and claim your share of the ₹31,00,000 prize pool!</p>
                    </MainTitleBold>
                    <TermsAndCondition>
                        <h3>Terms & Conditions</h3>
                        <ul>
                            <li>The SmartSoft Prize Drop promotion is valid for all new and existing players.</li>
                            <li>The bigger the game turnover, the greater the chances of winning.</li>
                            <li>Multiple accounts will not be eligible for this promotion. All players will undergo duplicate checking.</li>
                            <li>FUN88 reserves the right to disqualify players involved in collusion or any foul play related to this promotion.</li>
                            <li>FUN88 & SmartSoft reserve the right to amend, cancel, reclaim, or refuse any promotion at its discretion.</li>
                            <li>FUN88 General Terms & Conditions apply.</li>
                        </ul>
                    </TermsAndCondition>
                </Container>
            </InformationWrapper>
            <FooterContainer />
        </>
    )
}

export default withTheme(withAppContext(SmartSoftPriceDrop2k25PromoFun));

// Styled Component.
const InformationWrapper = styled.div`
    width: 100%;
    height: auto;
    padding: 3rem;
    background: rgba(111, 111, 111, 0.5);
    margin: 0em;
    border-top-color: transparent;
    backdrop-filter: brightness(0.5);    
    display: flex;
    flex-direction: column;
    background-color: #EBEBEB;

    h2 {
        color: #06A6FF;
        font-size: 24px;
        font-style: normal;
        font-weight: 700;
        text-transform: capitalize;
        text-align: center;
        margin: 0;
        font-family: 'Inter-Bold';
    }

    .hTitle {
        font-size: 36px;
        font-style: normal;
        font-weight: 700;
        color: #06A6FF;
        text-align: center;
        text-transform: capitalize;
    }

    strong {
        background: linear-gradient(180deg, #FFC937 67.44%, #F09401 123.26%);
        background-clip: text;
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
    }
`;

const Container = styled.div`
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    max-width: 1280px;
    margin: 0 auto;
`;

const MainTitle = styled.div`
    width: 90%;
    height: auto;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    padding: 2rem 0;
    gap: 1rem;
    border-radius: 9.945px;
    background: #FFF;

    h1 {
        color: ${theme('palette.AviatrixSkyHighChallengeTextColor')};
        text-align: center;
        font-size: 20px;
        font-style: normal;
        font-weight: 700;
        line-height: 179.5%; /* 35.9px */
        text-transform: capitalize;
        margin: 0;
    }

    p {
        color: #444;
        text-align: center;
        font-size: 14px;
        font-style: normal;
        font-weight: 500;
        margin: 0;
    }
`;

const MainTitleBold = styled(MainTitle)`
    margin-bottom: 2rem;
    p {
        font-size: 16px;
        font-weight: 700;
    }
`;

const ImgWrapper = styled.div`
    width: 45%;
    display: flex;
    justify-content: center;
    align-items: center;

    &.noWidth {
        width: 474px;

        img {
            max-width: 100%;
            margin-left: -20%;
        }
    }
`;

const SectionWrapper = styled.div`
    width: 90%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: auto;

    img {
        width: 100%;
        max-width: 100%;
        margin-bottom: -4px;
        margin-top: -40px;
    }

    ul {
        margin: 0;
        list-style: none;
        padding-left: 35px;

        li {
            padding: 10px 0;
            color: #131133;
            font-style: normal;
            font-weight: 700;
            text-transform: capitalize;
            position: relative;
            line-height: 150%;
            
            ::before {
                content: "";
                width: 25px;
                height: 25px;
                background: url(${config.imageV3Host}/static/cashsite/brand/6/image/tikIconPromo.svg);
                background-position: center;
                background-size: cover;
                background-repeat: no-repeat;
                position: absolute;
                left: -8%;
            }
        }
    }
`;

const ContentWrapper = styled.div`
    width: 547px;
    height: 391px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 3rem 2rem;
    background: ${theme('palette.AviatrixSkyHighChallengeStagesbackgrond')};
    border-radius: 9.233px;
    gap: 1rem;


    h3 {
        margin: 0;
        color: ${theme('palette.AviatrixSkyHighChallengeTextColor')};
        font-size: 24px;
        font-style: normal;
        font-weight: 700;
        text-transform: capitalize;
        text-align: left;
        font-family: 'Inter-Bold';
    }

    p {
        margin: 0;
        color: #000;
        font-size: 16px;
        font-style: normal;
        text-align: left;
        font-weight: 400;
        line-height: 150%; /* 24px */
        text-transform: capitalize;
    }
`;

const FullWidthImg = styled(ContentWrapper)`
    width: 90%;
    height: auto;
    flex-direction: row;
    margin-bottom: 2rem;

    .contentWrapper {
        display: flex;
        flex-direction: column;
        justify-content: center;
        padding: 0rem 2rem;
        gap: 1rem;
    }

    ul {
        margin: 0;
        list-style: none;
        padding-left: 35px;

        li {
            padding: 10px 0;
            color: #131133;
            font-style: normal;
            font-weight: 700;
            text-transform: capitalize;
            position: relative;
            line-height: 150%;
            
            ::before {
                content: "";
                width: 25px;
                height: 25px;
                background: url(${config.imageV3Host}/static/cashsite/brand/6/image/tikIconPromo.svg);
                background-position: center;
                background-size: cover;
                background-repeat: no-repeat;
                position: absolute;
                left: -6%;
            }
        }
    }
`;

const TermsAndCondition = styled.div`
    width: 90%;
    height: auto;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    padding: 3rem;
    position: relative;
    border-radius: 9.233px;
    border: ${theme('palette.AviatrixSkyHighChallengeTermsBorder')};
    background: ${theme('palette.AviatrixSkyHighChallengeTermsBackground')};

    ul {
        display: flex;
        flex-direction: column;
        justify-content: center;
        gap: 1.5rem;
        list-style: none;
        padding: 0;
    }


    & li {
        display: flex;
        align-items: center;
        gap: 8px;
        color: #444;
        font-size: 17px;
        font-style: normal;
        font-weight: 700;
        text-transform: capitalize;
        margin: 0;
        padding-left: 5px;

        ::before {
                content: "";
                width: 13px;
                height: 13px;
                display: flex;
                background: linear-gradient(180deg, #FFCA37 0%, #F09400 100%);
                border-radius: 30px;
            }
    }

    & h3 {
        color: ${theme('palette.AviatrixSkyHighChallengeTextColor')};
        font-size: 24px;
        text-transform: capitalize;
        margin: 0 auto;
        font-family: 'Inter-Bold';
        padding-bottom: 10px;
    }

    .bold {
        color: #FFF;
        font-weight: 700;
    }
`;
