import React from 'react'

type Props = {}

const RedDone = (props: Props) => {
  return (
    <svg width="41" height="41" viewBox="0 0 41 41" fill="none" xmlns="http://www.w3.org/2000/svg">
    <circle cx="20.5399" cy="20.7722" r="20.1159" fill="#0EAD69"/>
    <path d="M32.0353 12.1514L16.2299 27.9567L9.04565 20.7725" stroke="white" stroke-width="4.59793" stroke-linecap="round" stroke-linejoin="round"/>
    <ellipse cx="20.4203" cy="20.8217" rx="19.867" ry="19.7963" fill="#E30E33"/>
    <path d="M20.4205 30.8203C25.9434 30.8203 30.4205 26.3432 30.4205 20.8203C30.4205 15.2975 25.9434 10.8203 20.4205 10.8203C14.8977 10.8203 10.4205 15.2975 10.4205 20.8203C10.4205 26.3432 14.8977 30.8203 20.4205 30.8203Z" stroke="white" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
    <path d="M23.4205 17.8203L17.4205 23.8203" stroke="white" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
    <path d="M17.4205 17.8203L23.4205 23.8203" stroke="white" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
    </svg>
    
  )
}

export default RedDone