import { useEffect } from "react";
import PlaceholderImage from "src/components/Placeholder";
import config from "src/config/config";
import { withAppContext } from "src/store/initAppContext";
import styled, { withTheme } from "styled-components";
import { theme } from "styled-tools";
import { LazyLoadImage } from "react-lazy-load-image-component";
import FooterContainer from "src/components/footer/footer";
import YellowTickIcon from "src/components/icon/YellowTickIcon";
import { useNavigate } from "react-router-dom";

const EvolutionCasinoCupPromotion = (props) => {
  const {
    app: { brandId },
    theme,
  } = props;

  const navigate = useNavigate()
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const domain = brandId == 31 || brandId == 32 ? "Yolo247" : "BaaziAdda";

  return (
    <>
        <MainWrapper>
            <ContentWrapper>
                {
                    domain === "Yolo247" ? 
                    <TopTextWrapper>
                        <p>This IPL, experience casino games like never before! We bring you an action packed Live casino promotion in association with Evolution! We present</p>
                        <p> the offer of the year for all Live Casino fans! Get your chips & place your bets! {domain}  gives you a chance to go crazy this IPL!</p>
                        <h4>
                            Experience edge of the seat entertainment with Evolution!
                        </h4>
                    </TopTextWrapper> :
                    <TopTextBazziWrapper>
                        <h1>
                            Crazy IPL Bonanza
                        </h1>
                        <p>This IPL, experience casino games like never before! We bring you an action-packed live casino promotion in association with Evolution!</p>
                        <p>We present the offer of the year for all Live Casino fans! Get your chips & place your bets!</p>
                        <p>{domain} gives you a chance to go crazy this IPL!</p>
                    </TopTextBazziWrapper>
                }
                <CasinoCup>
                    {
                        domain === "Yolo247" ? 
                        <CasinoTextWrapper>
                            <h1>PRIZE</h1>
                            <h2>CASINO CUP</h2> 
                        </CasinoTextWrapper> :
                        <CasinoTextWrapperBazzi>
                            <h1>Experience Edge-of-the-Seat</h1>
                            <h1> Entertainment with Evolution!</h1>
                        </CasinoTextWrapperBazzi>    
                    }
                    <CasinoTextList>
                        <ul>
                            <li>
                                Top players will get a share of the weekly ₹ 4,48,000  prize pool!
                            </li>
                            <li>
                                The winners are based on the players who wager in Evolution qualified games during the promotion from 1st  April– 26th May 2024.
                            </li>
                            <li>
                                The Tournament winners are based on the highest wager amount.
                            </li>
                            <li>
                                Winners will be determined on a weekly basis from 1st April– 26th May 2024.
                            </li>
                            <li>
                                This promotion is only applicable for Qualified games : please checkthe Promotion Schedule tables.
                            </li>
                        </ul>
                    </CasinoTextList>
                </CasinoCup>
                <PromotionWrapper>
                    <PlayerImageWrapper>
                        <LazyLoadImage
                            src={`${config.imageV3Host}/static/cashsite/${theme.image.EvolutionCasinoCupPromoPlayerOne}`}
                            alt="jetxPromoStepThree"
                            effect="none"
                            placeholder={<PlaceholderImage />}
                        />  
                    </PlayerImageWrapper>
                    {
                        domain === "Yolo247" ?
                        <PromotionText>
                            <h1>PLAN</h1>
                            <h2>PROMOTION</h2>
                        </PromotionText> : 
                        <PromotionTextBazziAdda>
                            <h1>PLAN</h1>
                            <h2>PROMOTION</h2>
                        </PromotionTextBazziAdda>     
                    }
                    <PlayerImageWrapperTwo>
                        <LazyLoadImage
                            src={`${config.imageV3Host}/static/cashsite/${theme.image.EvolutionCasinoCupPromoPlayerTwo}`}
                            alt="jetxPromoStepThree"
                            effect="none"
                            placeholder={<PlaceholderImage />}
                        />  
                    </PlayerImageWrapperTwo>
                </PromotionWrapper>
                <PromotionTable>
                    <LazyLoadImage
                        src={`${config.imageV3Host}/static/cashsite/${theme.image.EvolutionCasinoCupPromoTableOne}`}
                        alt="jetxPromoStepThree"
                        effect="none"
                        placeholder={<PlaceholderImage />}
                    />  
                </PromotionTable>
                <PromoCradsWrapper>
                    <PromoCard>
                        <LeftCard>
                            <h1>Lucky 1-1-1</h1>
                            <ul>
                                <li>
                                    <span className="list-style">❂</span> Qualified Game : Crazy Time & Funky Time
                                </li>
                                <li>
                                    <span className="list-style">❂</span> Players who win on “1” 3 times ( not continuously) will receive an additional prize of ₹ 100
                                </li>
                                <li>
                                    <span className="list-style">❂</span> The minimum bet amount is ₹ 300 per round.
                                </li>
                                <li>
                                    <span className="list-style">❂</span> Each player can win this promotion once a day (once on Crazy Time & once on Funky Time).
                                </li>
                                <li>
                                    <span className="list-style">❂</span> Evolution reserves the right to modify, suspend, or terminate the promotion at any time without prior notice.
                                </li>
                            </ul>
                        </LeftCard>
                        <RightCard>
                            <h1>Thalaiva 7!</h1>
                            <ul>
                                <li>
                                    Qualified Game : Lightning Roulette & XXXtreme Lightning Roulette.
                                </li>
                                <li>
                                    Players who win on  numbers “7, 17, or 27” will receive an additional prize of ₹ 300
                                </li>
                                <li>
                                    The minimum combined bet amount is ₹ 800.
                                </li>
                                <li>
                                    Each player can win this promotional prize 3 times a week.
                                </li>
                                <li>
                                    Evolution reserves the right to modify, suspend, or terminate the promotion at any time without prior notice.
                                </li>
                            </ul>
                        </RightCard>
                    </PromoCard>
                    <PromoCard>
                    <LeftCard>
                        <h1>Legend 10!</h1>
                        <ul>
                            <li>
                                <span className="list-style">❂</span> Qualified Game : Lightning Roulette & XXXtreme Lightning Roulette.
                            </li>
                            <li>
                                <span className="list-style">❂</span> Players who win on  numbers “10, 20 or 30” will receive additional prize of ₹ 300.
                            </li>
                            <li>
                                <span className="list-style">❂</span> The minimum combined bet amount is ₹ 800.
                            </li>
                            <li>
                                <span className="list-style">❂</span> Each player can win this promotional prize 3 times a week.                  
                            </li>
                            <li>
                                <span className="list-style">❂</span> Evolution reserves the right to modify, suspend or terminate the promotion at anytime without prior notice.
                            </li>
                        </ul>
                    </LeftCard>
                    <RightCard>
                        <h1>Lightning Tournament!</h1>
                        <ul>
                            <li>
                                Qualified Game : Lightning Roulette & XXXtreme Lightning Roulette.
                            </li>
                            <li>
                                Top 200 players will get a share of the weekly  ₹4,48,000 prize pool.
                            </li>
                            <li>
                                The tournament winners are based on the highest wager amount.
                            </li>
                            <li>
                                The qualified members will continue to be eligible for prizes in the next period.
                            </li>
                            <li>
                                Winners will be announced in the week after the promo within 3 working days & bonus will be credited to their wallets therein
                            </li>
                            <li>
                                Evolution reserves the right to modify, suspend, or terminate the promotion at any time without prior notice.
                            </li>
                        </ul>
                        </RightCard>
                    </PromoCard>
                </PromoCradsWrapper>
                {
                    domain === "Yolo247" ?
                    <PromotionTextBottom>
                        <h1>PLAN</h1>
                        <h2>PROMOTION</h2>
                    </PromotionTextBottom> :
                    <PromotionTextBazziAddaBottom>
                        <h1>PLAN</h1>
                        <h2>PROMOTION</h2>
                    </PromotionTextBazziAddaBottom>
                }
                <PromotionTable style={{margin: "0 0 68px 0"}}>
                    <LazyLoadImage
                        src={`${config.imageV3Host}/static/cashsite/${theme.image.EvolutionCasinoCupPromoTableTwo}`}
                        alt="jetxPromoStepThree"
                        effect="none"
                        placeholder={<PlaceholderImage />}
                    />  
                </PromotionTable>
            </ContentWrapper>
        </MainWrapper>
        <FooterContainer />
    </>
  );
};

export default withTheme(withAppContext(EvolutionCasinoCupPromotion));

//Styled Components

const MainWrapper = styled.div`
    width: 98%;
    height: auto;
    /* border: 1px solid white; */
    display: flex;
    border-radius: 1px;
    background: rgba(111, 111, 111, 0.5);
    margin: 0em 1.2em;
    -webkit-backdrop-filter: brightness(0.5);
    backdrop-filter: brightness(0.5);
`;

const ContentWrapper = styled.div`
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background: url(${config.imageV3Host}/static/cashsite/${theme("image.EvolutionCasinoCupPromotionBackGround")});
    background-position: top;
    background-size: cover;
    background-repeat: no-repeat;

    .lazy-load-image-background {
      display: flex !important;
      justify-content: center !important;
    }
`;


const TopTextWrapper = styled.div `
    padding: 77px 113px 72px 113px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    & p {
        margin: 0;
        color: #FFF;
        text-align: center;
        leading-trim: both;
        text-edge: cap;
        font-family: Inter;
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: 155.5%; /* 24.88px */
        text-transform: capitalize;
    }

    & h4 {
        color: #6778E3;
        text-align: center;
        leading-trim: both;
        text-edge: cap;
        font-family: Inter;
        font-size: 20px;
        font-style: normal;
        font-weight: 700;
        line-height: 179.5%; /* 35.9px */
        text-transform: capitalize;
    }
`;

const  TopTextBazziWrapper = styled(TopTextWrapper) `
    & h1 {
        text-align: center;
        leading-trim: both;
        text-edge: cap;
        -webkit-text-stroke-width: 1;
        -webkit-text-stroke-color: #FFBE5D;
        font-family: Inter;
        font-size: 30px;
        font-style: normal;
        font-weight: 700;
        line-height: 179.5%; /* 53.85px */
        letter-spacing: 1.2px;
        text-transform: capitalize;
        background: linear-gradient(180deg, #A75701 18.75%, #F3A700 75%);
        background-clip: text;
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
    }

    & p {
        margin: 0;
        color: #FFF;
        text-align: center;
        leading-trim: both;
        text-edge: cap;
        font-family: Inter;
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: 155.5%; /* 24.88px */
        text-transform: capitalize;
    }
`;

const CasinoCup = styled.div `
    display: flex;
    flex-direction: column;
    align-items: center;
    align-self: flex-start;
    width: 43.63%;
    height: 54.2%;
    margin-left: 113px;
    padding: 53px 37px 44px 51px;
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 15px;
    border-radius: 30px;
    border: 1px solid rgba(255, 255, 255, 0.00);
    background: radial-gradient(50% 50% at 50% 0%, rgba(96, 79, 68, 0.32) 0%, rgba(26, 26, 26, 0.32) 96%);
    backdrop-filter: blur(8.050000190734863px);

    ::before {
        content: "";
        background: url(${config.imageV3Host}/static/cashsite/${theme("image.EvolutionCasinoCupPromoCoin")});
        background-position: top;
        background-size: cover;
        background-repeat: no-repeat;
        position: absolute;
        left: -5%;
        top: -10%;
        width: 96px;
        height: 92px;
    }

    ::before {
        content: "";
        position: absolute;
        top:0;
        left:0;
        width: 100%;
        height:100%;

        background: linear-gradient(180deg, #00000000 , #f9f1eb52) border-box;
        border: inherit;
        border-radius: inherit;
        mask: 
            linear-gradient(white, white) padding-box,
            linear-gradient(white, white);
            mask-composite: exclude;
    }
`;

const CasinoTextWrapper = styled.div `
    position: relative; 
    display: flex;
    align-items: center;
    width: 100%;

    & h1 {
        margin: 0;
        padding: 0 15px 0 0;
        text-align: center;
        font-family: Inter;
        font-size: 100px;
        font-style: italic;
        font-weight: 900;
        line-height: normal;
        letter-spacing: 2px;
        text-transform: uppercase;
        background-color: transparent;
        opacity: 0.15;
        background: linear-gradient(104deg, #006CD0 0%, #5B98F3 50.52%, #01A3E9 100%);
        background-clip: text;
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
    }

    & h2 {
        margin: 0;
        padding: 0;
        color: #6778E3;
        text-align: center;
        font-family: Inter;
        font-size: 30px;
        font-style: normal;
        font-weight: 700;
        line-height: 41.09px; /* 136.967% */
        text-transform: uppercase;
        position: absolute;
        left: 2%;   
    }
`;

const CasinoTextWrapperBazzi = styled.div `
    /* position: relative;  */
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    align-items: center;
    width: 100%;
    padding-bottom: 30px;

    & h1 {
        margin: 0;
        padding: 0 15px 0 0;
        align-self: flex-start;
        leading-trim: both;
        text-edge: cap;
        font-family: Inter;
        font-size: 26px;
        font-style: normal;
        font-weight: 700;
        line-height: 119.5%; /* 31.07px */
        letter-spacing: 1.04px;
        text-transform: capitalize;
        background: linear-gradient(180deg, #A75701 0%, #F3A700 112.24%);
        background-clip: text;
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
    }
`;

const CasinoTextList = styled.div `
    display: flex;

    & ul {
        margin: 0;
        padding: 0;
        padding-left: 15px;
        list-style-type: "\❂";
    }

    & li::marker {
        color: #FFDC40;
    }

    li {
        padding-left: 5px;
        color: #FFF;
        leading-trim: both;
        text-edge: cap;
        font-family: Inter;
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: 179.5%;
        text-transform: capitalize;
    } 
     li .list-style {
        color: #FFDC40;
        leading-trim: both;
        text-edge: cap;
        font-family: Inter;
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: 179.5%; /* 28.72px */
        text-transform: capitalize;
     }
`;

const PromotionWrapper = styled.div `
    display: flex;
    margin-top: 20px;
`;

const PlayerImageWrapper = styled.div `
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-end;

    & img {
        /* height: 100%; */
        width: 286px;
        height: 293px;
    }
`;

const PlayerImageWrapperTwo = styled(PlayerImageWrapper) `
    & img {
        width: 231px;
        height: 259px;
    }
`;

const PromotionText = styled.div `
    position: relative; 
    display: flex;
    align-items: center;
    width: 100%;
    align-self: flex-end;

    & h1 {
        /* margin: 0; */
        padding: 0;
        padding: 0 15px 0 0;
        text-align: center;
        font-family: Inter;
        font-size: 100px;
        font-style: italic;
        font-weight: 900;
        line-height: normal;
        letter-spacing: 2px;
        text-transform: uppercase;
        background-color: transparent;
        opacity: 0.15;
        background: linear-gradient(104deg, #006CD0 0%, #5B98F3 50.52%, #01A3E9 100%);
        background-clip: text;
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
    }

    & h2 {
        margin: 0;
        padding: 0;
        color: #6A7FFF;
        text-align: center;
        font-family: Inter;
        font-size: 30px;
        font-style: normal;
        font-weight: 700;
        line-height: 41.09px; /* 136.967% */
        text-transform: uppercase;
        position: absolute;
        left: 23%;
    }
`;

const PromotionTextBazziAdda = styled(PromotionText) `
     & h1 {
        /* margin: 0; */
        padding: 0;
        padding: 0 15px 0 0;
        text-align: center;
        font-family: Inter;
        font-size: 100px;
        font-style: italic;
        font-weight: 900;
        line-height: normal;
        letter-spacing: 2px;
        text-transform: uppercase;
        background-color: transparent;
        opacity: 0.15;
        opacity: 0.15;
        background: linear-gradient(104deg, #D09600 0%, #F3C85B 50.52%, #E97001 100%);
        background-clip: text;
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
    }

    & h2 {
        margin: 0;
        padding: 0;
        color: #6A7FFF;
        text-align: center;
        font-family: Inter;
        font-size: 30px;
        font-style: normal;
        font-weight: 700;
        line-height: 41.09px; /* 136.967% */
        text-transform: uppercase;
        position: absolute;
        left: 23%;
        background: linear-gradient(180deg, #A75701 0%, #F3A700 112.24%);
        background-clip: text;
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
    }
`;

const PromotionTable = styled.div `
    display: flex;
    justify-content: center;
    align-items: center;

    & img {
        width: 75%;
    }
`;

const PromoCradsWrapper = styled.div `
    display: flex;
    flex-direction: column;
    width: 100%;
    margin-top: 9%;
    gap: 102px;
`;

const PromoCard = styled.div `
    display: flex;
    justify-content: space-between;
    margin: 0 113px;
`;


const LeftCard = styled(CasinoCup) `
    /* width: 26%; */
    width: 35%;
    height: auto;
    margin: 0;
    border: 1px solid rgba(255, 255, 255, 0.00);
    background: rgba(26, 26, 26, 0.60);
    backdrop-filter: blur(8.050000190734863px);

    h1 {
        align-self: flex-end;
        color: ${theme('palette.EvolutionCasinoCupPromoTextColor')};
        background: ${theme('palette.EvolutionCasinoCupPromoBackground')};
        -webkit-text-fill-color: ${theme('palette.EvolutionCasinoCupPromowebkit-text-fill-color')};
        background-clip: text;
        -webkit-background-clip: text;
        leading-trim: both;
        text-edge: cap;
        font-family: Inter;
        font-size: 25px;
        font-style: normal;
        font-weight: 700;
        line-height: 148.5%; /* 37.125px */
        text-transform: uppercase;
    }

    ul {
        padding: 0;
        margin: 0;
    }

    li {
        text-align: right;
        list-style-type: none;
        color: #FFF;
        leading-trim: both;
        text-edge: cap;
        font-family: Inter;
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: 179.5%;
        text-transform: capitalize;
    } 

     li .list-style {
        color: #FFDC40;
        leading-trim: both;
        text-edge: cap;
        font-family: Inter;
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: 179.5%; /* 28.72px */
        text-transform: capitalize;
    }

    /* ::before {
        content: "";
        background: none;
    } */

    ::before {
        content: "";
        position: absolute;
        top:0;
        left:0;
        width: 100%;
        height:100%;

        background: linear-gradient(180deg, #00000000 , #f9f1eb52) border-box;
        border: inherit;
        border-radius: inherit;
        mask: 
            linear-gradient(white, white) padding-box,
            linear-gradient(white, white);
            mask-composite: exclude;
    }


`;

const RightCard = styled(LeftCard) `

    h1 {
        align-self: flex-start;
    }

    li {
        padding-left: 5px;
        text-align: left;
        list-style-type: unset;
    }

    & ul {
        margin: 0;
        padding: 0;
        padding-left: 15px;
        list-style-type: "\❂";
    }

    & li::marker {
        color: #FFDC40;
    }

`;

const PromotionTextBottom = styled(PromotionText) `
    justify-content: center;
    align-items: center;

    h2 {
        left: 45.5%;
    }
`;

const PromotionTextBazziAddaBottom = styled(PromotionTextBazziAdda) `
    justify-content: center;
    align-items: center;

    h2 {
        left: 45.5%;
    }
`;