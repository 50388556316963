import React from "react";

type Props = {};

const TooltipIcon = (props: Props) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="15"
      height="15"
      viewBox="0 0 15 15"
      fill="none"
    >
      <g clip-path="url(#clip0_4658_941)">
        <path
          d="M7.29427 13.279C10.5159 13.279 13.1276 10.6673 13.1276 7.44564C13.1276 4.22398 10.5159 1.6123 7.29427 1.6123C4.07261 1.6123 1.46094 4.22398 1.46094 7.44564C1.46094 10.6673 4.07261 13.279 7.29427 13.279Z"
          stroke="#FF2B2B"
          stroke-width="1.25"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M7.29395 5.1123V7.44564"
          stroke="#FF2B2B"
          stroke-width="1.25"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M7.29395 9.77832H7.30061"
          stroke="#FF2B2B"
          stroke-width="1.25"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
      </g>
      <defs>
        <clipPath id="clip0_4658_941">
          <rect
            width="14"
            height="14"
            fill="white"
            transform="translate(0.293945 0.445312)"
          />
        </clipPath>
      </defs>
    </svg>
  );
};

export default TooltipIcon;
