import React, { useEffect } from 'react'
import { withAppContext } from 'src/store/initAppContext';
import styled, { withTheme } from 'styled-components';
import { LazyLoadImage } from "react-lazy-load-image-component";
import config from "src/config/config";
import PlaceholderImage from 'src/components/Placeholder';
import { theme } from 'styled-tools';
import FooterContainer from 'src/components/footer/footer';
import Slider from "react-slick";

function DailyLeaderboard(props) {
    const { app: {
        brandId
    }, theme } = props;

    const { showOnlyOnFomo } = theme.config;

    useEffect(() => {
        window.scrollTo(0, 0)
    }, [])

    const settings = {
        loop: true,
        arrows: true,
        autoplay: false,
        dots: true,
        infinite: true,
        speed: 500,
        slidesToShow: 5,
        slidesToScroll: 5,
        initialSlide: 0,
        centerPadding: '20px'
    };

    const domain = (brandId == 31 || brandId == 32) ? "YOLO247" : (brandId == 39 || brandId == 40) ? "FOMO7" : 'IW247';

    return (
        <>
            <InformationWrapper>
                    <TitleText>
                        <div className=''>
                            <p className='purple-strong'>Daily Leaderboard Challenge - Bet More, Win More!</p>
                            <p className='purple-strong'>₹2,00,000 in Daily Prizes - Can You Clinch the Top Spot?</p>
                        </div>
                        <div className={showOnlyOnFomo ? 'paddingTopBtm': ''}>
                            <p>Put your betting skills to the test! Wager on your favourite games, climb the Leaderboard and secure your share of the daily Prize Pool.</p>
                            <p>The top 20 players with the highest total bets will walk away with exciting rewards!</p>
                            <p className={showOnlyOnFomo ? "purple-strong mt-10" : "mt-10"}><strong>Daily Prize Pool: ₹2,00,000 The more you bet, the higher you rank! </strong></p>
                        </div>
                    </TitleText>
                    {showOnlyOnFomo && <Divider></Divider>}
                <ImageWrapper>
                    <Container>
                          <MainTitle className={!showOnlyOnFomo ? 'customMargin': ''}>How to Participate</MainTitle>
                          {(showOnlyOnFomo) ? <UnderLine></UnderLine> : ""}
                          {!showOnlyOnFomo ? (
                                <LazyLoadImage
                                        src={`${config.imageV3Host}/static/cashsite/${theme.image.dailyLBFirstSec}`}
                                        alt="How to participate"
                                        effect="none"
                                        placeholder={<PlaceholderImage />}
                                />
                           ) : (
                           <HTPBoxWrap>
                               <HTPBox>
                                   <img src="https://opt-v3-files.raksahb.com/static/cashsite/brand/6/image/dailyLBIcon1.png" alt="" />
                                   <h6>
                                       Bet on ANY game - <br /> no restrictions; all games count!
                                   </h6>
                               </HTPBox>

                               <HTPBox>
                                   <img src="https://opt-v3-files.raksahb.com/static/cashsite/brand/6/image/dailyLBIcon2.png" alt="" />
                                   <h6>
                                       Keep wagering to climb the <br /> Leaderboard -  <br />the more you bet, the better the ranking.
                                   </h6>
                               </HTPBox>
                               
                               <HTPBox>
                                   <img src="https://opt-v3-files.raksahb.com/static/cashsite/brand/6/image/dailyLBIcon3.png" alt="" />
                                   <h6>
                                       Track your progress on <br /> the live Leaderboard throughout  <br />the day.
                                   </h6>
                               </HTPBox>

                               <HTPBox>
                                   <img src="https://opt-v3-files.raksahb.com/static/cashsite/brand/6/image/dailyLBIcon4.png" alt="" />
                                   <h6>
                                       Rewards will instantly be <br /> credited as a bonus the following day.
                                   </h6>
                               </HTPBox>
                           </HTPBoxWrap>
                           )
                        }
                    </Container>
                </ImageWrapper> 

                <PriceBreakDown>
                    <MainTitle>Daily prize amount</MainTitle>
                    {(showOnlyOnFomo) ? <UnderLine className='paddingTopBtm'></UnderLine> : ""}
                    <SliderWrapper>
                        <Slider {...settings}>
                                <div><img src={`${config.imageV3Host}/static/cashsite/${theme.image.dailyLBPrize1}`}/></div>
                                <div><img src={`${config.imageV3Host}/static/cashsite/${theme.image.dailyLBPrize2}`}/></div>
                                <div><img src={`${config.imageV3Host}/static/cashsite/${theme.image.dailyLBPrize3}`}/></div>
                                <div><img src={`${config.imageV3Host}/static/cashsite/${theme.image.dailyLBPrize4}`}/></div>
                                <div><img src={`${config.imageV3Host}/static/cashsite/${theme.image.dailyLBPrize5}`}/></div>
                                <div><img src={`${config.imageV3Host}/static/cashsite/${theme.image.dailyLBPrize6}`}/></div>
                                <div><img src={`${config.imageV3Host}/static/cashsite/${theme.image.dailyLBPrize7}`}/></div>
                                <div><img src={`${config.imageV3Host}/static/cashsite/${theme.image.dailyLBPrize8}`}/></div>
                                <div><img src={`${config.imageV3Host}/static/cashsite/${theme.image.dailyLBPrize9}`}/></div>
                                <div><img src={`${config.imageV3Host}/static/cashsite/${theme.image.dailyLBPrize10}`}/></div>
                                <div><img src={`${config.imageV3Host}/static/cashsite/${theme.image.dailyLBPrize11}`}/></div>
                                <div><img src={`${config.imageV3Host}/static/cashsite/${theme.image.dailyLBPrize12}`}/></div>
                                <div><img src={`${config.imageV3Host}/static/cashsite/${theme.image.dailyLBPrize13}`}/></div>
                                <div><img src={`${config.imageV3Host}/static/cashsite/${theme.image.dailyLBPrize14}`}/></div>
                                <div><img src={`${config.imageV3Host}/static/cashsite/${theme.image.dailyLBPrize15}`}/></div>
                                <div><img src={`${config.imageV3Host}/static/cashsite/${theme.image.dailyLBPrize16}`}/></div>
                                <div><img src={`${config.imageV3Host}/static/cashsite/${theme.image.dailyLBPrize17}`}/></div>
                                <div><img src={`${config.imageV3Host}/static/cashsite/${theme.image.dailyLBPrize18}`}/></div>
                                <div><img src={`${config.imageV3Host}/static/cashsite/${theme.image.dailyLBPrize19}`}/></div>
                                <div><img src={`${config.imageV3Host}/static/cashsite/${theme.image.dailyLBPrize20}`}/></div>
                        </Slider>
                    </SliderWrapper>
                </PriceBreakDown>

                <KeyDetailsSec>
                    <MainTitle>Key Details</MainTitle>
                    {(showOnlyOnFomo) ? <UnderLine className='paddingTopBtm'></UnderLine> : ""}
                    <KeyDetailsSecWrap>
                        <KeyDetailsSecBox>
                             <p>
                                 Bonuses expire within 24 hours - use them fast!
                             </p>
                        </KeyDetailsSecBox>
                             
                        <KeyDetailsSecBox>
                               <p>
                                  No wagering requirements - enjoy your rewards immediately!
                              </p>
                        </KeyDetailsSecBox>

                        <KeyDetailsSecBox>
                              <p>
                                  Leaderboard resets daily - Win daily!
                              </p>
                        </KeyDetailsSecBox>

                        <KeyDetailsSecBox>
                              <p>
                                   {brandId === 33 ? `${domain} Standard promotion terms and conditions apply` : `${domain} General Terms and Conditions apply.`}
                              </p>
                        </KeyDetailsSecBox>
                    </KeyDetailsSecWrap>
                </KeyDetailsSec>
                
            </InformationWrapper>
            <FooterContainer />
        </>
    )
}



export default withTheme(withAppContext(DailyLeaderboard));

const Divider = styled.div`
    height: 3px;
    width: 100%;
    background: radial-gradient(#1182C1 0%, #00000000 50%);
    margin: 0 auto 2em auto;
`

const UnderLine = styled.div`
    height: 2px;
    width: 184px;
    background: radial-gradient(#FFF 0%, rgba(153, 153, 153, 0.00) 80%);

    &.paddingTopBtm {
        margin: 0 auto;
        margin-bottom: 2rem;
    }

    &.marginTop {
        margin: 0 auto;
        margin-top: 1rem;
    }
`;


const SliderWrapper = styled.div`
    width: 85%;
    margin: 0 auto;

    .slick-slide {
        padding: 0 15px;
    }

    .slick-slide img {
        display: block;
        width: 100%;
        transition: transform .5s; /* Animation */

        :hover {
            transform: scale(0.8);
            border: 3px solid #ffffff;
            border-radius: 1rem;
        }

        :focus-visible {
            outline: none;
        }
        :focus {
            outline: none;
        }
    }

    .slick-prev {
        left: -65px;
        top: 43%;
    }

    .slick-next {
        right: -25px;
        top: 43%;
    }

    .slick-prev:before {
        content: "";
        width: 60px;
        height: 60px;
        display: inline-block;
        background: url(${config.imageV3Host}/static/cashsite/brand/6/image/prevSlideJackpot.png);
        background-size: cover;
        background-position:center center;
        background-repeat: no-repeat;
        opacity: unset;
    }

    .slick-next:before {
        content: "";
        width: 60px;
        height: 60px;
        display: inline-block;
        background: url(${config.imageV3Host}/static/cashsite/brand/6/image/nextSlideJackpot.png);
        background-size: cover;
        background-position:center center;
        background-repeat: no-repeat;
        opacity: unset;
    }

    .slick-dots {
        bottom: -40px;

        li button:before {
            color: #fff;
            font-size: 15px;
            opacity: unset;
        }

        li.slick-active button:before {
            opacity: unset;
            color: ${theme('palette.dailyLeaderboardPromosliderActie')};
        }
    }
`;

const InformationWrapper = styled.div`
    width: 98%;
    height: auto;
    padding: 1.5rem 0 5rem 0;
    margin: 0em 1.2em 0 1.2em;
    border: 1px solid #fff;  
    display: flex;
    flex-direction: column;
    background: url(${config.imageV3Host}/static/cashsite/${theme("image.dailyLBMainInnerBg")});
    background-position: top;
    background-size: cover;
    background-repeat: no-repeat;
    overflow: hidden;

    .boldText {
        color: ${theme('palette.informationWrapperPromoBoldText')};
        font-weight: 700;
    }

    .boldTextLite {
        color: ${theme('palette.informationWrapperPromoBoldText')};
        font-weight: 400;
    }

    .shadowLine {
        background: radial-gradient(37.24% 47.13% at 50% 0%, #051829 0%, rgba(1, 4, 6, 0.00) 100%);
        -webkit-backdrop-filter: blur(6.849999904632568px);
        backdrop-filter: blur(6.849999904632568px);
        width: 100%;
        padding-top: 2rem;
    }

    .purple-strong {
        color: ${theme('palette.dailyLeaderboardText')};
        text-align: center;
        font-size: 15px;
        font-style: normal;
        font-weight: 700;
        line-height: 25px; /* 166.667% */
        text-transform: capitalize;
        margin: 3px 0px;
    }

    h2 {
        color: #E6FF00;
        text-align: center;
        font-size: 23px;
        font-style: normal;
        font-weight: 700;
        line-height: normal;
        text-transform: capitalize;
        background: ${theme('palette.LiveJackPot2025InformationWrapperH2Text')};
        background-clip: ${theme('palette.LiveJackPot2025InformationWrapperH2TextClip')};
        -webkit-background-clip: ${theme('palette.LiveJackPot2025InformationWrapperH2WebkitClip')};
        -webkit-text-fill-color: ${theme('palette.LiveJackPot2025InformationWrapperH2TextWebkitFill')};
    }

    .paddingTopBtm {
        margin: 0 auto;
        margin-bottom: 2rem;
    }
`;

const PriceBreakDown = styled.div`
    width: 80%;
    padding: 2rem 0;
    position: relative;
    margin: auto;
`;



const TitleText = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    padding: ${theme('palette.liveJackpotTittleTextPadding')};
    position: relative;
    gap: 15px;
    /* margin-bottom: 2rem; */


    p {
        color: #FFF;
        text-align: center;
        font-size: 15px;
        font-style: normal;
        font-weight: 400;
        line-height: 28px; /* 186.667% */
        text-transform: capitalize;
        margin: 0;
    }

    .mt-10 {
        margin-top: 15px;
    }
`;

const ImageWrapper = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    margin-bottom: 2rem;
    position: relative;


    img {
        width: 93px;
    }

    .customMargin {
        margin: -7px 0px -20px 0px;
    }
`;


const Container = styled.div`
    width: 70%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;

    & img {
      width: 90%;
      height: auto;
      margin-top: 2em;
    }

`;

const MainTitle = styled.h4`
        color: ${theme('palette.dailyLeaderboardText')};
        text-align: center;
        font-size: 20px;
        font-style: normal;
        font-weight: 700;
        line-height: 35px; /* 175% */
        text-transform: capitalize;
        margin: 3px 0px 15px 0px;
`


const KeyDetailsSec = styled.div`
    display: flex;
    flex-direction: column;
    margin: 2rem auto;
    width: 70%;
    height: auto;
    
`

const KeyDetailsSecWrap = styled.div`
    display: flex;
    justify-content: center;
    gap: 10px;
`

const KeyDetailsSecBox = styled.div`
    width: 23%;
    height: 150px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 3px;
    border: 0.5px solid ${theme('palette.dailyLeaderboardBorder')};
    background: ${theme('palette.dailyLeaderboardBg')};
    backdrop-filter: blur(12.5px);

    & p {
        color: #FFF;
        text-align: center;
        font-size: 17px;
        font-style: normal;
        font-weight: 400;
        line-height: 20px; /* 133.333% */
        text-transform: capitalize;
        margin: 3px 0px;
        width: 80%;
    }
`

const HTPBoxWrap = styled.div`
    width: 80%;
    height: auto;
    display: flex;
    justify-content: center;
    gap: 10px;
`

const HTPBox = styled.div`
    display: flex;
    flex-direction: column;
    gap: 5px;
    align-items: center;

    & img {
        width: 100px;
        height: 100px;
        object-fit: contain;
    }

    & h6 {
        color: #FFF;
        text-align: center;
        font-size: 15px;
        font-style: normal;
        font-weight: 400;
        line-height: 21px; /* 140% */
        text-transform: capitalize;
        margin: 3px 0px;
    }
`