import React, { useEffect } from "react";
import { motion } from "framer-motion";
// import { theme } from 'styled-tools';
import { HeadingTextWrapper } from "../../common/Headings";
// import CasinoSlider from './casino-slider';
// import RecommendedGames from './recommended-games';
import CasinoTabGames from "./casino-tab-games";
// import { color } from "../../../sass-files/variable";
// import casinoBg from '../../../images/iexch_casino_bgone.jpg';
// import Intl from "../../common/Intl";
import styled, { withTheme } from "styled-components";
import { withAppContext } from "src/store/initAppContext";
import config from "src/config/config";
import { Link, useParams } from "react-router-dom";
import SEOContents from "src/components/SEOContents";
import { Helmet } from "react-helmet";
import { CRMPageVisit } from "src/util/crmUtil";
import getSchemaData from "src/components/schema";
// import CasinoSlider from "./casino-slider";
// import Slider from "react-slick";
// const {titleHeadColor} = color
// const andarBahar = `${'/static/media/andarBaharslide.jpg'}`
// const AviatorSlide = `${'/static/media/AviatorSlide.jpg'}`
// const CrazyTimeSlide = `${'/static/media/CrazyTimeSlide.jpg'}`
// const DreamCatcherSlide = `${'/static/media/DreamCatcherSlide.jpg'}`
// const goalSlide = `${'/static/media/goalSlide.jpg'}`
// const Hiloslide = `${'/static/media/Hiloslide.jpg'}`
// const LightningRouletteSlide = `${'/static/media/LightningRouletteSlide.jpg'}`
// const TeenpattiSlide = `${'/static/media/TeenpattiSlide.jpg'}`
// const MonopolySlide = `${'/static/media/MonopolySlide.jpg'}`
// const VegasMagic=`${'/static/media/VegasMagicSlide.jpg'}`


// const imageGroup: any = {
//   web: [
//     {
//       img: 'slider_1', name: 'slider_image_1', id: 1, src:AviatorSlide, alt_name: 'aviator', pathName: '/live-casino/aviator'
//     },
//     {
//       img: 'slider_2', name: 'slider_image_2', id: 2, src: andarBahar, alt_name: 'Banner_image_11', pathName: '/live-casino/andar bahar'
//     },
//     {
//       img: 'slider_6', name: 'slider_image_6', id: 6, src:  CrazyTimeSlide, alt_name: 'smartsoft', pathName: '/live-casino/EVOLUTIONBLACKJACK'
//     },
//     {
//       img: 'slider_2', name: 'slider_image_2', id: 2, src:DreamCatcherSlide, alt_name: 'lodo', pathName:'/live-casino/EVOLUTIONBLACKJACK'
//     }, 
//     {
//       img: 'slider_3', name: 'slider_image_3', id: 3, src:  goalSlide, alt_name: 'Banner_image_1', pathName: '/live-casino/goal'
//     },
//     {
//       img: 'slider_3', name: 'slider_image_3', id: 3, src:  Hiloslide, alt_name: 'Banner_image_1', pathName: '/live-casino/hilo'
//     },
//     {
//       img: 'slider_3', name: 'slider_image_3', id: 3, src:  LightningRouletteSlide, alt_name: 'Banner_image_1', pathName: '/live-casino/Lightning'
//     },
//     {
//       img: 'slider_3', name: 'slider_image_3', id: 3, src:  TeenpattiSlide, alt_name: 'Banner_image_1', pathName: '/live-casino/teenpatti'
//     },
//     {
//       img: 'slider_3', name: 'slider_image_3', id: 3, src:  MonopolySlide, alt_name: 'Banner_image_1', pathName: '/live-casino'
//     },
//     {
//       img: 'slider_3', name: 'slider_image_3', id: 3, src:  VegasMagic, alt_name: 'Banner_image_1', pathName: '/live-casino/populargames/vegas'
//     }


//   ]
// }

// const sliderSettings = {
//   dots: true,
//   infinite: true,
//   speed: 500,
//   slidesToShow: 1,
//   slidesToScroll: 1,
//   autoplay: true,
//   arrows: false,
//   pauseOnHover:true
// };
const labelTxt = (id) => {
  let obj = {
    'spribe': 'Spribe Games',
    'supernowa': 'Supernowa Games',
    'super-spade': 'Superspade Games',
    'tvbet': 'TVbet Games',
    'betgames': 'BetGames',
    'bingo': 'Bingo',
    'ezugi': 'Ezugi Games',
    'ag': 'AG Games',
    'evo': 'EVO Games',
    '7MOJOS': '7Mojos Games',
    'macaw': 'Macaw Games',
    'kingmaker': 'Kingmaker Games',
    'sexygaming': 'Sexygaming Games',
    'lightningroulette': 'Play Lightening Roulette',
    'crazytime': 'Crazy Time',
    'monopolylive': 'Play Monopoly Live',
    'funkytime': 'FunkyTime',
    'lightningdice': 'Play LighteningDice',
    'dragontiger': 'Play DragonTiger🐉',
    'blackjack': 'Play Blackjack',
    'roulette': 'Play Roulette',
    'baccarat': 'Play Baccarat',
    'dragon-tiger': 'Play Dragon Tiger',
    'crazy-time': 'Crazy Time',
    'monopoly-live': 'Live Monopoly',
    'funky-time': 'Play funky Time'


  }
  return obj[id] || '';
}
const structuredData = (domain: any, gameName: string, providerName: string) => {

  // let schemaName = providerName && gameName ? gameName : providerName ? providerName : "live-casino";
  let schemaName = gameName || providerName || 'live-casino';

  if (providerName && gameName) {
    if (providerName === 'evolution' && gameName === 'dragon-tiger') {
      schemaName = 'evo-dragon-tiger';
    }
    else if (providerName === 'evolution' && gameName === 'teen-patti') {
      schemaName = 'evo-teen-patti';
    }
    else if (providerName === 'ezugi' && gameName === 'dragon-tiger') {
      schemaName = 'ezugi-dragon-tiger';
    }
    else if (providerName === 'kingmaker' && gameName === 'andar-bahar') {
      schemaName = 'k-andar-bahar';
    }
  };

  const otherschemaData = getSchemaData(schemaName);

  const itemLists = [
    {
      "@type": "ListItem",
      "item": `${domain}`,
      "name": "Homepage",
      "position": 1
    },
    {
      "@type": "ListItem",
      "item": `${domain}/live-casino/`,
      "name": "Play Online Casino Games",
      "position": 2
    },
  ];

  if (providerName) {
    itemLists.push({
      "@type": "ListItem",
      "item": `${domain}/live-casino/${providerName}`,
      "name": `${providerName} Games`,
      "position": 3
    });

    if (gameName) {
      itemLists.push({
        "@type": "ListItem",
        "item": `${domain}/live-casino/${providerName}/${gameName}`,
        "name": `Play ${gameName}`,
        "position": 4
      });
    }
  }

  const data = [
    {
      "@context": "https://schema.org",
      "@type": "BreadcrumbList",
      "name": "Breadcrumbs",
      "itemListElement": itemLists
    },
    ...otherschemaData
  ];

  return JSON.stringify(data)
};



function CasinoPage(props) {
  const { id: gameName, provider: providerName } = useParams();
  const { app: { brandId } } = props;

  let domainURL = 'https://www.yolo247.site';

  if (brandId === 32) {
    domainURL = 'https://www.yolo247.club';
  }
  else if (brandId === 33) {
    domainURL = 'https://www.iw247.com';
  }
  else if (brandId === 34) {
    domainURL = 'https://www.betdaily.club';
  }
  else if (brandId === 39) {
    domainURL = 'https://www.fomo7.com';
  }
  else if (brandId === 40) {
    domainURL = 'https://www.fomo7.xyz';
  }
  else if (brandId === 46) {
    domainURL = 'https://www.fun88.site';
  }
  else if (brandId === 47) {
    domainURL = 'https://www.gofun88.in';
  }

  useEffect(() => {
    //for CRM
    CRMPageVisit();
  }, []);

  const _structuredDataid = providerName && gameName ? gameName : providerName;

  return (
    <motion.div
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
    >
      <Helmet>
        <script type="application/ld+json">
          {
            structuredData(domainURL, gameName, providerName)
          }
        </script>
      </Helmet>

      {/* {id && id === 'blackjack' &&
        <>
          <SEOContents pageName={"blackjack"} />
        </>
      }


      {id && id === 'roulette' &&
        <>
          <SEOContents pageName={"roulette"} />
        </>
      }

      {id && id === 'dragon-tiger' &&
        <>
          <SEOContents pageName={"dragonTiger"} />
        </>
      }

      {id && id === 'baccarat' &&
        <>
          <SEOContents pageName={"baccarat"} />
        </>
      }

      {id && id === 'aviator' &&
        <>
          <SEOContents pageName={"aviator"} />
        </>
      }

      {id && id === 'macludo' &&
        <>
          <SEOContents pageName={"macludo"} />
        </>
      }

      {id && id === 'SPRIBE' &&
        <>
          <SEOContents pageName={"SPRIBE"} />
        </>
      }

      {id && id === '7MOJOS' &&
        <>
          <SEOContents pageName={"7MOJOS"} />
        </>
      }

      {id && id === 'supernowa' &&
        <>
          <SEOContents pageName={"supernowa"} />
        </>
      }

      {id && id === 'super-spade' &&
        <>
          <SEOContents pageName={"superspade"} />
        </>
      }


      {id && id === 'tvbet' &&
        <>
          <SEOContents pageName={"tvbet"} />
        </>
      }

      {id && id === 'betgames' &&
        <>
          <SEOContents pageName={"betgames"} />

        </>
      }

      {id && id === 'sexygaming' &&
        <>
          <SEOContents pageName={"sexygaming"} />
        </>
      }

      {id && id === 'bingo' &&
        <>
          <SEOContents pageName={"bingo"} />

        </>
      }


      {id && id === 'ag' &&
        <>
          <SEOContents pageName={"ag"} />

        </>
      }

      {id && id === 'Funkytime' &&
        <>
          <SEOContents pageName={"Funkytime"} />
        </>
      }
      {id && id === 'macludo' &&
        <>
          <SEOContents pageName={"macludo"} />
        </>
      }
      {id && id === 'evo' &&
        <>
          <SEOContents pageName={"evo"} />

        </>
      }

      {id && id === 'no-limit-city' &&
        <>
          <SEOContents pageName={"nlc"} />
        </>
      }
      {id && id === 'netent' &&
        <>
          <SEOContents pageName={"netent"} />
        </>
      }
      {id && id === 'Funky Time' &&
        <>
          <SEOContents pageName={"Funky Time"} />
        </>
      }
      {id && id === 'funky-time' &&
        <>
          <SEOContents pageName={"Funky Time"} />
        </>
      }
      {id && id === 'first person blackjack' &&
        <>
          <SEOContents pageName={"First-Person-Blackjack"} />
        </>
      }
      {id && id === 'teenpatti' &&
        <>
          <SEOContents pageName={"teenpatti"} />
        </>
      }
      {id && id === 'teenpatti 20 20' &&
        <>
          <SEOContents pageName={"teenpatti2020-2020"} />
        </>
      }
      {id && id === 'funkytime' &&
        <Helmet>
          <link rel="canonical" href={`${domainURL}/live-casino/evolution/funky-time`} />
          <link rel="canonical" href={`${domainURL}/live-casino/evolution/funky-time/`} />
        </Helmet>
      }

      

      {id && id === 'crazy-time' && (
        <>
          <SEOContents pageName={"crazytime"} />
        </>
      )

      } */}


      {/* As per discusion with Vishnu slider is hidded */}
      {/* <SliderWrap>
        <Slider {...sliderSettings}>
          {
              imageGroup['web'].map((each, index) => {
                  return (
                      <div key={index}>
                          <Link className="banner_img" to={each.pathName}>
                              <img src={`${config.imageV3Host}${each.src}`} alt={each.alt_name} height="auto" width="100%" />
                          </Link>
                      </div>
                  )
              })
          }
        </Slider>
      </SliderWrap> */}

      <CasinoWrapper theme={props.app.theme} customStyle={props.theme.image}>
        {/* <TitleHead theme={props.app.theme} customStyle={props.theme.palette}>
          <Intl langKey="TITLE@CASINO" />
        </TitleHead> */}
        {/* <RecommendedGames/> */}
        {/* <CasinoSlider/> */}
        <CasinoTabGames />
      </CasinoWrapper>
    </motion.div>
  );
}

export default withTheme(withAppContext(CasinoPage));

const CasinoWrapper: any = styled.div`
  width: 100%;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  background-image: url("${(props: any) => `${config.imageV3Host}/static/cashsite/${props.customStyle["homeExchangeBgImage"]}`}");
  background-position: 100%;
  background-size: cover;
`;

const TitleHead = styled(HeadingTextWrapper)`
  font-size: 28px;
  letter-spacing: 3px;
  text-transform: uppercase;
  white-space: nowrap;
  font-weight: bold;
  color: ${(props: any) => (props.theme === "Dark" ? "#FFF" : "#F4A322")};
  margin-left: 1em;
  padding-top: 10px;
  position: relative;
  margin-bottom: 10px;
  padding-bottom: 5px;

  :after {
    content: "";
    width: 500px;
    height: 2px;
    background: linear-gradient(90deg, #f7b349 0%, rgba(235, 160, 42, 0) 50%);
    position: absolute;
    bottom: 0;
    left: 0;
  }

  @media only screen and (max-width: 992px) {
    font-size: 30px;
  }
`;

const SliderWrap = styled.div`
  /* min-height: 300x; */
  height: auto;

.slick-dots {
    display: flex!important;
    align-items: center;
    justify-content: center;
    padding: 10px;
    bottom: 4px;
    background-color: transparent;

    li {
        margin: 0px 1px;
    }
    li button:before {
        font-size: 13px;
        color: #c78e35;
    }
    li.slick-active button:before {
        opacity: .75;
        color: #da911f;
    }
}
`
