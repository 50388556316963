import React, { useEffect, useState } from 'react'
import styled from 'styled-components';
import {
    BodyWrapper,
    FirstCol,
    Header,
    SubmitBtn,
    MainInfoWrapper,
    TableFooter,
} from '../../../components/udb/commonStyle';
import DatePicker from "react-datepicker";
import {
    DatePickerWrapper,
    BlockPick,
    SvgIcon,
} from "src/components/udb/DatePickerUdb";
import CalenderIcon from "src/components/icon/CalenderIcon";
import Intl from '../../../components/common/Intl';
import UDBHeader from 'src/components/UDBHeader';
import Pagination from "src/components/common/Pagination";
import MessageComponent from "src/pages/udb/message/indexmsg"
import { getNotificationById, notificationCount } from 'src/config/api'
import { Notify } from 'src/components/Notification';
import moment from "moment";
import SEOContents from 'src/components/SEOContents';
import { theme } from 'styled-tools';


type notificationsData = {
    id: any;
    notification_header: any;
    notification_type: any;
    status: any;
    created: any
}

type notificationFullData = {
    notification_count: number,
    notifications: notificationsData[]
}

export default function Message(props) {
    const date = new Date();
    const [startAt, setStartAt] = useState(new Date(date.getFullYear(), date.getMonth(), 1)); //Date
    const [endAt, setEndAt] = useState(new Date(new Date().setHours(23, 59, 59))); //Data
    const minDate = date.setDate(new Date().getDate() - 90);
    const [page, setPage] = useState(1); //pagination
    const [count, setCount] = useState(0);
    const [pageCount, setPageCount] = useState(0)
    const limit = 5;
    const [messages, setMessages] = useState({} as notificationFullData)
    const [isDateApplied, setIsDateApplied] = useState(false);

    useEffect(() => {
        getNotifications(isDateApplied, null,null, page, limit);
        unreadMessages()
    }, [])

    const getNotifications = (isDate = isDateApplied, startDate, endDate, page, limit) => {
        let params = {
            isDate: isDate,
            startDate,
            endDate,
            page,
            limit
        }
        getNotificationById(params)
            .then((response) => {
                const { data } = response.data
                setMessages(data);
                setPageCount(data.notification_count)
                if (response.data.success === false) {
                    Notify.error(response.data.data.status);
                }
            })
            .catch((err) => {
                Notify.error(err.response.data.data.message)
            });
    }

    function unreadMessages() {
        notificationCount()
            .then((response) => {
                const data = response.data
                setCount(data.count)
            })
            .catch((err) => { 
                Notify.error(err.response.data.data.message)
            })
                
    }

    const handleChangePageIndex = (index: number) => {
        const page = index;
        getNotifications(isDateApplied,moment(startAt).format("YYYY-MM-DD"), moment(endAt).format("YYYY-MM-DD"), page, limit)
    };

    const handlefromDateChange = (startAt: Date | null) => {
        setStartAt(startAt);
        setIsDateApplied(true)
        getNotifications(true, moment(startAt).format("YYYY-MM-DD"), moment(endAt).format("YYYY-MM-DD"), page, limit)
    };

    const handletoDateChange = (endAt: Date | null) => {
        setEndAt(endAt);
        setIsDateApplied(true)
        getNotifications(true, moment(startAt).format("YYYY-MM-DD"), moment(endAt).format("YYYY-MM-DD"), page, limit)
    };

    const handleDateChangeRaw = (e) => {
        e.preventDefault();
    };

    const readCountHandler = () => {
        unreadMessages();
      }    
    
    return (
        <MainInfoWrapper>
            <SEOContents pageName="memberMessages" />
            <Header>
                <FirstCol>
                    <UDBHeader title="SIDEBAR@Msg" />
                </FirstCol>
                <DatePickerWrapper>
                    <SvgIcon>
                        <CalenderIcon />
                    </SvgIcon>
                    <BlockPick>
                        <DatePicker
                            selected={startAt}
                            onChange={handlefromDateChange}
                            onChangeRaw={handleDateChangeRaw}
                            minDate={minDate}
                            maxDate={new Date()}
                            placeholderText="From"
                        />
                    </BlockPick>
                    <BlockPick>
                        <DatePicker
                            selected={endAt}
                            onChange={handletoDateChange}
                            onChangeRaw={handleDateChangeRaw}
                            minDate={minDate}
                            maxDate={new Date()}
                            placeholderText="To"
                        />
                    </BlockPick>
                </DatePickerWrapper>
            </Header>

            <MsgCounter>
                <ViewBtn1>
                    {count} <Intl langKey="Unread@Msg" />
                </ViewBtn1>
            </MsgCounter>
            <MessageBodyWrapper>
                {messages.notifications && messages.notifications.length > 0 ? messages.notifications?.map((notification) => (
                    <MessageComponent
                        id={notification.id}
                        notification_header={notification.notification_header}
                        notification_type={notification.notification_type}
                        status={notification.status}
                        created={notification.created}
                        onRead={readCountHandler}
                        key={notification.id}
                    />
                )) : <Nomessage><Intl langKey="Msg@NoMsgFoundText" /></Nomessage>}

            </MessageBodyWrapper>
            <TableFooter>
                <Pagination
                    numberPerPage={5}
                    totalLength={pageCount}
                    onClick={handleChangePageIndex}
                    currentIndex={page}
                />
            </TableFooter>
        </MainInfoWrapper>
    )
}

export const MessageBodyWrapper = styled(BodyWrapper)`
    width: 100%;
    padding:1em;
    border-bottom: 1px dashed #fff;
`;

const Nomessage = styled.p`
color: #fff;
color: ${theme('palette.NoMessageColor')};
text-align: center;
width: 100%;
`;


const ViewBtn = styled(SubmitBtn)`
width:auto;
background: #4E5587;
border-radius: 5px;
padding: 7px 25px;
`
const ViewBtn1 = styled(ViewBtn)`
background:${theme('palette.unreadMsg')};
cursor: pointer;
color: ${theme('palette.nametext')};
`

const MsgCounter = styled.div`
display: flex;
width: 100%;
justify-content: space-between;
align-items: center;
margin-bottom: 10px;
& h4{
    width:100%;
    color: #fff;
    font-weight: 700;
    font-size: 12px;
    -webkit-letter-spacing: 0.01em;
    -moz-letter-spacing: 0.01em;
    -ms-letter-spacing: 0.01em;
    letter-spacing: 0.01em;
    text-transform: capitalize;
    color: #ffffff;
    background: rgba(255, 255, 255, 0.21);
    -webkit-backdrop-filter: blur(4150px);
    backdrop-filter: blur(4150px);
    border-radius: 3px;
    padding: 4px;
    margin:3px 0px;
}
`