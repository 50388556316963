import React from 'react';
import styled from 'styled-components';
import { theme } from 'styled-tools';


type Props = {
    id: number;
    icon: any;
    titleNumber: string;
    desc: any;
}

const AppBenefits = (props: Props) => {
  return (
    <BenefitsBlock>
      <StepIcon>{props.icon}</StepIcon>
      <StepNumber>{props.titleNumber}</StepNumber>
      <StepDescription>{props.desc}</StepDescription>
    </BenefitsBlock>
  )
}

export default AppBenefits;

//Styled Components
const StepDescription = styled.div`
  font-weight: 500;
  font-size: 15px;
  color: ${theme('palette.appDownloadPageSmallText')};
`;

const StepNumber = styled.div`
  color: ${theme('palette.appDownloadPageStepNumberColor')};
  font-weight: 700;
  font-size: 30px;
  position: absolute;
  top: 2rem;
  right: 2rem;

  @media only screen and (min-width: 1500px){
      font-size: 40px;
    }
`;

const StepIcon = styled.div`
  width: 50px;
  height: 50px;
  background: ${theme('palette.appDownloadPageStepIconBg')};
  border-radius: 40px;
  position: absolute;
  top: -30px;
  left: 4rem;
  border: ${theme('palette.appDownloadPageStepIconBorder')};
`;

const BenefitsBlock = styled.div`
  width: 22%;
  min-height: 238px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 1rem;
  border-right: ${theme('palette.appDownloadBenefitsBlockBordrRght')};
  border-bottom: ${theme('palette.appDownloadBenefitsBlockBordrBotm')};
  border-left: ${theme('palette.appDownloadBenefitsBlockBordrRght')};
  border-top: ${theme('palette.appDownloadBenefitsBlockBordrBotm')};
  background: ${theme('palette.appDownloadBenefitsBlockBackground')};
  border-radius: 15px;
  position: relative;
`;