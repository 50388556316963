import React from 'react'
import { Accordion, AccordionWrapper } from '../seo-content/seo_content'
import { withTheme } from 'styled-components';
import { withAppContext } from 'src/store/initAppContext';
import SuperspadeSEOYolo from './SuperspadeSEOYolo';
import SuperSpadeFun88 from './SuperSpadeFun88';

type Props = { app: any }

const SuperspadeSEO = (props: Props) => {
  const { app: { brandId } } = props;
  const domainName = (brandId === 31 || brandId === 32) ? 'Yolo247' : brandId === 33 ? 'iw247' : (brandId === 39 || brandId === 40) ? 'FOMO7' : (brandId === 46 || brandId === 47) ? 'FUN88' : 'Betdaily';

  return (
    <>
      {
        (brandId == 33 || brandId == 46) ? <></> :
        (brandId == 47) ? <SuperSpadeFun88 /> :
        <SuperspadeSEOYolo />
      }
    </>
  )
}

export default withTheme(withAppContext(SuperspadeSEO));