import React from 'react'

type Props = {}

const PromoCodeIcon = (props: Props) => {
  return (
    <svg
    xmlns="http://www.w3.org/2000/svg"
    width={40}
    height={22}
    viewBox="0 0 40 22"
    fill="none"
    {...props}
  >
    <path
      d="M32.173 16.8627L32.158 5.17158C32.1586 5.07191 32.1395 4.97309 32.1017 4.88086C32.0639 4.78864 32.0082 4.70483 31.9377 4.6343C31.8673 4.56377 31.7836 4.50792 31.6914 4.46999C31.5992 4.43207 31.5004 4.41281 31.4007 4.41334L8.27289 4.4464C8.13953 4.44627 8.00851 4.48141 7.89311 4.54825C7.77772 4.6151 7.68205 4.71127 7.61581 4.82702C7.54921 4.94165 7.51462 5.07206 7.51565 5.20464L7.52867 16.8968C7.52877 17.0305 7.56422 17.1617 7.63141 17.2773C7.69861 17.3929 7.79517 17.4886 7.91129 17.5548C8.02612 17.6201 8.15584 17.6546 8.28791 17.655C8.28791 17.655 30.4352 17.62 31.4188 17.62C31.9416 17.5969 31.9897 17.4927 32.0728 17.3816C32.1492 17.2197 32.1836 17.0413 32.173 16.8627ZM19.8448 13.4571L17.0402 14.9416L17.5741 11.8084L15.2974 9.59782L18.4405 9.13407L19.8428 6.2844L21.2521 9.13206L24.3933 9.58681L22.1195 11.8044L22.6544 14.9366L19.8448 13.4571Z"
      fill="url(#paint0_linear_6443_63)"
    />
    <path
      d="M39.5728 21.9469L39.5617 13.2126C39.5387 13.2126 39.5167 13.2126 39.4946 13.2126C38.8305 13.2133 38.1933 12.9501 37.7232 12.481C37.2532 12.0118 36.9887 11.3752 36.988 10.711C36.9874 10.0469 37.2506 9.40971 37.7197 8.93963C38.1888 8.46955 38.8255 8.20509 39.4896 8.20443C39.5127 8.20443 39.5347 8.20443 39.5567 8.20443L39.5497 0.00600959C39.5497 0.00600959 39.5497 0.00600983 39.5497 0L0 0.0510838L0.0110181 8.23648C0.0320525 8.23648 0.0520856 8.23648 0.07312 8.23648C0.401963 8.23648 0.727586 8.30125 1.0314 8.42709C1.33521 8.55293 1.61126 8.73738 1.84378 8.96991C2.07631 9.20244 2.26076 9.47849 2.3866 9.7823C2.51245 10.0861 2.57722 10.4117 2.57722 10.7406C2.57722 11.0694 2.51245 11.395 2.3866 11.6989C2.26076 12.0027 2.07631 12.2787 1.84378 12.5112C1.61126 12.7438 1.33521 12.9282 1.0314 13.0541C0.727586 13.1799 0.401963 13.2447 0.07312 13.2447H0.0130218L0.0260428 21.993C0.0260428 21.993 0.0260428 21.999 0.0260428 22L39.5657 21.9479L39.5728 21.9469ZM31.3643 19.0922L8.22646 19.1273C7.83178 19.1265 7.44412 19.0229 7.10162 18.8268C6.7583 18.6284 6.47313 18.3432 6.27467 17.9999C6.07621 17.6566 5.97141 17.2672 5.97077 16.8706L5.95775 5.18048C5.95656 4.88361 6.01391 4.58942 6.12652 4.31473C6.23912 4.04004 6.40477 3.79025 6.61399 3.57963C6.82321 3.36902 7.0719 3.20171 7.34583 3.08728C7.61976 2.97286 7.91357 2.91355 8.21044 2.91277L31.3483 2.87871C31.9477 2.87871 32.5226 3.11675 32.9465 3.5405C33.3704 3.96425 33.6087 4.53901 33.609 5.13841L33.624 16.8275C33.6386 17.2714 33.5349 17.7111 33.3235 18.1016C32.972 18.7327 32.2107 19.1113 31.3643 19.0922Z"
      fill="url(#paint1_linear_6443_63)"
    />
    <defs>
      <linearGradient
        id="paint0_linear_6443_63"
        x1={7.21513}
        y1={5.26472}
        x2={32.1559}
        y2={17.635}
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#E1B73D" />
        <stop offset={0.484375} stopColor="#FDF399" />
        <stop offset={1} stopColor="#CE970F" />
      </linearGradient>
      <linearGradient
        id="paint1_linear_6443_63"
        x1={0.00303259}
        y1={0.406671}
        x2={39.5678}
        y2={22.0421}
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#E2BA41" />
        <stop offset={0.5} stopColor="#FDF399" />
        <stop offset={1} stopColor="#D09A13" />
      </linearGradient>
    </defs>
  </svg>
  )
}

export default PromoCodeIcon;