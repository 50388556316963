type Props = {}

const FileUploadIcon = (props: Props) => {
  return (
    <svg
        width={14}
        height={17}
        viewBox="0 0 14 17"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        {...props}
    >
        <path
        d="M6.83333 13.5V8.5M6.83333 8.5L4.33333 10.1667M6.83333 8.5L9.33333 10.1667M7.66667 1H3.66667C2.73333 1 2.26667 1 1.91 1.18167C1.59641 1.34145 1.34145 1.59641 1.18167 1.91C1 2.26667 1 2.73333 1 3.66667V13.3333C1 14.2667 1 14.7333 1.18167 15.09C1.34145 15.4036 1.59641 15.6585 1.91 15.8183C2.26583 16 2.7325 16 3.66417 16H10.0025C10.9342 16 11.4 16 11.7558 15.8183C12.07 15.6583 12.325 15.4033 12.485 15.09C12.6667 14.7333 12.6667 14.2683 12.6667 13.3367V6M7.66667 1C7.905 1.0025 8.055 1.01167 8.19917 1.04583C8.37028 1.0875 8.53083 1.15417 8.68083 1.24583C8.84917 1.34917 8.99333 1.49333 9.28083 1.78167L11.8858 4.38583C12.1742 4.67417 12.3175 4.8175 12.4208 4.98583C12.5125 5.13639 12.5792 5.29694 12.6208 5.4675C12.6542 5.61167 12.6642 5.7625 12.6667 6M7.66667 1V3.33333C7.66667 4.26667 7.66667 4.73333 7.84833 5.09C8.00812 5.40359 8.26308 5.65855 8.57667 5.81833C8.9325 6 9.39917 6 10.3308 6H12.6667"
        stroke="#6B7A90"
        strokeWidth={1.4}
        strokeLinecap="round"
        strokeLinejoin="round"
        />
    </svg>
  )
}

export default FileUploadIcon