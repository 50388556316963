import React, { useEffect } from 'react'
import { withAppContext } from 'src/store/initAppContext';
import styled, { withTheme } from 'styled-components';
import { LazyLoadImage } from "react-lazy-load-image-component";
import config from "src/config/config";
import PlaceholderImage from 'src/components/Placeholder';
import { theme } from 'styled-tools';
import { Divider, UnderLine } from './FomoWelcomeBonus';

function FomoRefillBonus(props) {
    const { app: {
        brandId
    }, theme } = props;

    useEffect(() => {
        window.scrollTo(0, 0)
    }, [])


    return (
        <InformationWrapper>
            <TitleText className='customPadding'>
                <h6>Gear Up with Every Redeposit - 5% Redeposit Bonus on FOMO7 </h6>
            </TitleText>
            {/* <Divider></Divider> */}
            <LoyaltyWrapper>
                <HeadingText>
                    <h1>Redeposit and Receive up to 5% Redeposit Bonus</h1>
                </HeadingText>
                <UnderLine></UnderLine>
                <HeadingText className="joiningTxt">
                    <p>You are special and deserve special treatment, so we offer a 5% <br /> redeposit bonus on every subsequent deposit.</p>
                </HeadingText>
                <StepsWrapper>
                    <LazyLoadImage
                        src={`${config.imageV3Host}/static/cashsite/${theme.image.RefillStepsFomoDesk}`}
                        alt="jetxPromoStepThree"
                        effect="none"
                        placeholder={<PlaceholderImage />}
                    />
                </StepsWrapper>
            </LoyaltyWrapper>
            <JoiningWrapper>
                <RedepositText>
                    <h1>What is a Redeposit Bonus and How to Get It?</h1>
                </RedepositText>
                <UnderLine></UnderLine>
                <HeadingText className="joiningTxt">
                    <p>A redeposit bonus is a bonus that a user gets on every deposit after the first one. To understand how and when the redeposit bonus is <br /> credited to your main wallet, look at the following steps</p>
                </HeadingText>
                <LazyLoadImage
                    src={`${config.imageV3Host}/static/cashsite/${theme.image.RefillPercentageFomoDesk}`}
                    alt="jetxPromoStepThree"
                    effect="none"
                    placeholder={<PlaceholderImage />}
                />
            </JoiningWrapper>
            <JoiningWrapper>
                <RedepositText>
                    <h1>Who is eligible for the FOMO7 Redeposit Bonus?</h1>
                </RedepositText>
                <UnderLine></UnderLine>
                <HeadingText className="joiningTxt">
                    <p>A redeposit bonus is a bonus that a user gets on every deposit after the first one. To understand how and when the redeposit bonus is <br />credited to your main wallet, look at the following steps</p>
                </HeadingText>
                <LazyLoadImage
                    src={`${config.imageV3Host}/static/cashsite/${theme.image.fomoRefillEligiblity}`}
                    alt="jetxPromoStepThree"
                    effect="none"
                    placeholder={<PlaceholderImage />}
                />
            </JoiningWrapper>
            <HeadingText className="customMargin">
                    <p>During your process of redepositing through Regular UPI payment mode, you can find the highlighted channels</p>
            </HeadingText>
            <Termsandconditions>
                <TermsandconditionsHeading>
                    <h1>Benefits of FOMO7 Redeposit Bonus</h1>
                </TermsandconditionsHeading>
                <UnderLine></UnderLine>
                <TermsandconditionsBody>
                    <p>
                        A user will experience multiple benefits when it comes to a redeposit bonus, which are as follows:
                    </p>
                    <ol>
                        <li>Users are motivated to keep playing as they can earn up to a 5% bonus on every redeposit</li>
                        <li>Players can use this bonus to play different games, enhance their casino game skills, and increase their chances of winning.</li>
                    </ol>
                </TermsandconditionsBody>
            </Termsandconditions>
            <TermsandconditionsNew>
                <TermsandconditionsHeadingNew>
                    <h1>Terms and Conditions of redeposit Bonus on FOMO7</h1>
                </TermsandconditionsHeadingNew>
                <UnderLine></UnderLine>
                <TermsandconditionsBody>
                    <ol>
                        <li>Only one bonus is allowed per customer, address, shared computer, shared IP address, and any identical account details that include e-mail address, bank account details, credit card information, and payment system account. Any misuse of this bonus will lead to an account being closed.</li>
                        <li>Only users, who are using the highlighted channels, will be eligible for the redeposit bonus.</li>
                        <li>After every successful redeposit, the bonus amount is credited to the user's main wallet.</li>
                        <li>This promotion is available on every redeposit, and every bonus amount received during the day will expire at 12 AM IST the next day. Example: 1st redeposit was done by the user on 21st September at 9 AM of ₹1,000. So, now the user will receive a ₹20 bonus; thus, the total amount in a user’s account will be ₹1020. Now, consider that a user makes another deposit of ₹2000 at 2 PM on the same day. So now, a user will receive a bonus of ₹40. On 22nd September at midnight at 11:59:59 PM, the bonus amount of ₹60 given to the user will expire.</li>
                        <li>Multiple accounts will not be considered for this promotion. All players will be subject to duplicate checking. FOMO7 reserves the right to disqualify players if foul play takes place.</li>
                        <li>FOMO7 reserves the right to amend, cancel, reclaim, or refuse any promotions at its discretion.</li>
                        <li>The bonus amount cannot be withdrawn, but the winnings from the bonus are withdrawable.</li>
                        <li>FOMO7's general terms and conditions apply.</li>
                        <li>Rollover is not valid for any of the P2P games.</li>
                    </ol>
                </TermsandconditionsBody>
            </TermsandconditionsNew>
        </InformationWrapper>
    )
}



export default withTheme(withAppContext(FomoRefillBonus));

export const InformationWrapper = styled.div`
    width: 98%;
    height: auto;
    padding: 3rem 3rem 8rem 3rem;
    border-radius: 1px;
    background: rgba(111, 111, 111, 0.5);
    margin: 0em 1.2em 1.2em 1.2em;
    border: 1px solid #E5B358;
    border-radius: 0 0 20px 20px;
    border-top-color: transparent;
    backdrop-filter: brightness(0.5);    
    display: flex;
    flex-direction: column;
    background: url(${config.imageV3Host}/static/cashsite/${theme("image.RefillBonusFomoDeskBg")});
    background-position: top;
    background-size: cover;
    background-repeat: no-repeat;

    & .customPadding {
        padding: 1em;
    }

    & .customMargin {
        margin-bottom: 3em;
    }
`;

const TitleText = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    padding: 3rem 0 4rem 0;

    h6 {
        margin: 0;
        padding: 0;
        color: #FFF;
        text-align: center;
        font-size: 16px;
        font-style: normal;
        font-weight: 600;
        line-height: 160.3%; /* 25.648px */
    }
`;

const LoyaltyWrapper = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    /* background: radial-gradient(37.24% 47.13% at 50% 0%, #051829 0%, rgba(1, 4, 6, 0.00) 100%); */
    backdrop-filter: blur(6.849999904632568px);
    padding: 1em 3rem;
    margin: 0 -2rem;

    .joiningTxt {
        padding-top: 1.5rem;
    }
`;

const HeadingText = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;

    h1 {
        text-align: center;
        font-size: 25px;
        font-style: normal;
        font-weight: 600;
        line-height: normal;
        letter-spacing: 1.25px;
        background: linear-gradient(180deg, #FED546 18.06%, #FE7C23 91.67%);
        background-clip: text;
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
    }

    p {
        margin: 0;
        padding: 0.5em 0 0 0;
        color: #FFF;
        text-align: center;
        font-family: Inter;
        font-size: 16px;
        font-style: normal;
        font-weight: 500;
        line-height: 120.3%; /* 19.248px */
    }
`;

const RedepositText = styled(HeadingText)`
    display: flex;
    padding: 1rem 0;
`;

const JoiningWrapper = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    text-align: center;
    /* background: url(${config.imageV3Host}/static/cashsite/${theme("image.RefillBgFomoDesk")}); */
    background-position: top;
    background-size: cover;
    background-repeat: no-repeat;
    z-index: -999;
    margin: 0 -3rem;
    padding-bottom: 5rem;

    img {
        padding-top: 4rem;
        width: 73%;
    }

    .joiningTxt {
        padding-top: 2rem;
    }
`;

const JoiningContainer = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    gap: 3rem;
    padding-top: 2rem;
`;

const StepsWrapper = styled(JoiningContainer)`
    padding: 3rem 0;
    justify-content: center;
    align-items: center;
    text-align: center;
    position: relative;
    flex-direction: column;

    p {
        color: #FFF;
        text-align: center;
        font-size: 16px;
        font-style: normal;
        font-weight: 500;
        line-height: 120.3%; /* 19.248px */
    }
    
    img {
        width: 80%;
    }
`;

const Termsandconditions = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    border-radius: 30px;
    width: 70%;
    height: auto;
    margin: 0 15% 3rem 15%;
    padding: 5rem 3rem;
    position: relative;
    background: linear-gradient(126deg, #01080e 1%, #01080e 40%, #01080e);
    background-clip: padding-box;
    border: 2px solid transparent;

    ::after {
        position: absolute;
        top: -2px; bottom: -2px;
        left: -2px; right: -2px;
        background: linear-gradient(90deg, #53C1FF, black, #53C1FF, black, #53C1FF);
        content: '';
        z-index: -1;
        border-radius: 30px;
    }
`;

const TermsandconditionsNew = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    border-radius: 30px;
    width: 70%;
    height: auto;
    margin: 0 15% 3rem 15%;
    padding: 5rem 3rem;
    position: relative;
    background: linear-gradient(126deg, #01080e 1%, #01080e 40%, #01080e);
    background-clip: padding-box;
    border: 2px solid transparent;

    ::after {
        position: absolute;
        top: -2px; bottom: -2px;
        left: -2px; right: -2px;
        background: linear-gradient(90deg, #53C1FF, black, #53C1FF, black, #53C1FF);
        content: '';
        z-index: -1;
        border-radius: 30px;
    }

    ::before {
        content: "";
        background: url(${config.imageV3Host}/static/cashsite/${theme("image.RefillBonusFomoCardsDesk")});
        width: 506.063px;
        height: 433.768px;
        background-position: top;
        background-size: contain;
        background-repeat: no-repeat;
        position: absolute;
        right: -22%;
        bottom: -29%;
        z-index: 9;
    }
`;

const TermsandconditionsHeading = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;    
    background: linear-gradient(180deg, #FED546 18.06%, #FE7C23 121.53%);
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    padding-bottom: 1.5rem;

    h1 {
        margin: 0;
        padding: 0;
        text-align: center;
        font-size: 25px;
        font-style: normal;
        font-weight: 600;
        line-height: normal;
        letter-spacing: 1.25px;
    }

    ::before {
        content: "";
        background: url(${config.imageV3Host}/static/cashsite/${theme("image.RefillBonusDiceFomoDesk")});
        width: 208px;
        height: 140px;
        background-position: top;
        background-size: contain;
        background-repeat: no-repeat;
        position: absolute;
        left: -17.7%;
        top: -25%;
        z-index: -999;
    }
`;

const TermsandconditionsHeadingNew = styled(TermsandconditionsHeading)`
      ::before {
        content: "";
        background: none;
    }
`

const TermsandconditionsBody = styled.div`
    ol {
        display: flex;
        flex-direction: column;
        justify-content: center;
        gap: 1rem;
        padding-top: 1rem;
    }
    li {
        color: #FFF;
        font-size: 16px;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
    }

    & p {
        color: #FFF;
        font-size: 16px;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
        padding-left: 2%;
    }
`;
