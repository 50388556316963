import React from "react";
import styled, { css } from "styled-components";
import { ifProp, switchProp, theme } from "styled-tools";

// Components
import { bodyFont } from "../../common/commonCSSStyles";
// import { Col } from '~/component/table/baseTableStyles';
import EventTable from "./feature/EventTable";
import MatchTable from "./feature/MatchTable";

// Util
import { IconWrapper } from "../../common/PNGIcon";
import SubscribedMarketList from "../../subscription/SubscribedMarketList";
import EventTypeConfig from "../../../config/eventTypeConfig";
import { IEventInfo } from "../../../graphql/schema";
import { withAppContext } from "../../../store/initAppContext";
import media from "../../../util/mediaUtil";
import routerUtil from "../../../util/routerUtil";
import { isMemberType } from "../../../util/styleUtil";
import util from "../../../util/util";

//import { GET_EVENT_NEWS_CLIENT } from "../../../graphql/agentMember";

interface ITypeTitleProps {
  type: "BACK" | "LAY";
  label: string;
  overround: number | undefined | null;
}
export const TypeTitle = (props: ITypeTitleProps) => {
  return (
    <TypeTitleWrapper>
      {/* Back & Lay name */}
      {/* {props.label} */}
      {/** Code Changed -  Manoj ( Commented below and added props.lable) */}
      {/* <OverroundLabel type={props.type}>{props.overround && props.overround.toFixed(2)}%</OverroundLabel> */}
      <OverroundLabel type={props.type}>{props.label}</OverroundLabel>
    </TypeTitleWrapper>
  );
};

interface IMarketListProps {
  // 是否秀出球種 icon
  isShowSport?: boolean;
  // 運動種類 id
  categoryId: number;
  competitionId?: string;
  option?: { isInplay?: boolean };
  // Market 有更新時執行的 callback
  callback?: (data: any) => void;
  app?: any;
}

class MarketList extends React.PureComponent<IMarketListProps, any> {
  goToCategory = (categoryName: string) => () => {
    routerUtil.push(`/eu-content/${categoryName}`);
  };

  render() {
    const {
      categoryId,
      competitionId,
      option = {},
      callback,
      isShowSport,
      app,
    } = this.props;
    const eventTypeConfig = EventTypeConfig(categoryId);

    if (!eventTypeConfig.eventTypeGroup) {
      return (
        <div>{`Cannot find corresponding table for event type id ${this.props.categoryId}`}</div>
      );
    }

    const formatedCompetitionId = competitionId === "all" ? "" : competitionId;

    const inputProps: any = {
      categoryId,
      ...option,
    };
    competitionId && (inputProps.competitionId = formatedCompetitionId);
    // Code Changed - Manoj ( GET_EVENTS Code is Here)
    return (
      <SubscribedMarketList
        query={eventTypeConfig.gqlQuery}
        variables={{ input: inputProps }}
        option={{ isLive: !!option.isInplay }}
      >
        {({
          loading,
          error,
          data,
          subscribeToMore,
          variables,
          updateQuery,
        }: any) => {
          typeof callback === "function" && callback(data);

          if (error) {
            console.error(error);
          }

          // List of eventInfo, not categorized
          const eventInfoList: IEventInfo[] = util.getValue(
            data,
            ["markets", "list"],
            []
          );
          const eventInfoListBySport = eventInfoList.reduce(
            (pv: any, item: IEventInfo) => {
              const keyName = item.categoryId || 0;
              !pv[keyName] && (pv[keyName] = []);
              pv[keyName].push(item);
              return pv;
            },
            {}
          );
          const sortedEvents = eventTypeConfig.categoryIds.reduce(
            (res: any[], n) => {
              return [...res, ...(eventInfoListBySport[n] || [])];
            },
            []
          );
          const customProps = {
            loading,
            error,
            isShowSport,
            // list of eventInfo only for the sport
            // Code Changed - Manoj ( isSort : true ) for sorting events
            dataList: sortedEvents,
            isSort: false,
          };

          const TableComponent = (
            {
              EventTable,
              MatchTable,
            } as any
          )[eventTypeConfig.eventTypeGroup];

          if (customProps.dataList.length === 0 && categoryId === 0) {
            return null;
          } else {
            return <TableComponent {...customProps} />;
          }
        }}
      </SubscribedMarketList>
    );
  }
}

export default withAppContext(MarketList);

// const TeamCol = styled(Col)`
// 	flex-basis: 32%;
// `;

const Cell: any = styled.div`
  display: flex;
`;

export const TitleCell: any = styled(Cell)`
  padding: 0.6em 0;
  letter-spacing: 0.66px;
`;

export const TeamTitleCell: any = styled(TitleCell)`
  width: ${ifProp("isLong", "70%", "32%")};
  padding-left: 4em;
`;

export const StateTitleCell: any = styled(TitleCell)`
  width: ${ifProp("isLong", "30%", "16%")};
  justify-content: center;
`;

// 1 X 2 or 1 2
export const OddsTitleCell: any = styled(TitleCell)`
  width: 52%;
  justify-content: space-around;
`;

// Wrapping a FavoriteButton and text
export const MatchOddsTitleCell = styled(TitleCell)`
  // flex-basis: 35%;
  // border-right: 1px solid rgba(128, 128, 128, 0.2);
  // padding-left: 1em;
  flex-direction: coloumn;
  align-items: center;
  min-width: 0;
  width: 100%;
  padding: 0;
  justify-content: space-evenly;
  font-size: 14px;

  > span {
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }
`;

export const BackTitleCell = styled(TitleCell)`
  justify-content: flex-end;
  flex-basis: 30%;
  padding: 0.25em 0.5em;
`;

export const LayTitleCell = styled(TitleCell)`
  flex-basis: 30%;
  padding: 0.25em 0.5em;
`;

const OverroundLabel: any = styled.div`
  ${bodyFont};
  font-size: 10px;
  padding: 2px;
  margin-top: 2px;
  color: ${switchProp("type", {
    BACK: theme("palette.back-text-light-more"),
    LAY: theme("palette.lay-text-light-more"),
  })};
`;

// Back/Lay + overround
const TypeTitleWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  font-size: 11px;
  margin: 0 0.5em;
`;

// Content row elements

// 隊伍名稱左方號碼，1 or 2
export const TeamNumber: any = styled.div`
  font-size: 12px;
  color: ${theme("palette.primary-background")};
  font-weight: 600;
  padding: 0 0.5em;
`;

// 隊伍名稱
export const TeamName: any = styled.div`
  font-size: 14px;
  font-weight: normal;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  color: ${theme("palette.table-body-text")};
  text-transform: uppercase;
`;

// Wrapping title number & team name
export const TeamTitleWrapper: any = styled.div`
  display: flex;
  align-items: baseline;
  // Commented by ambu
  // margin: 0.25em;
  margin: 0.12em 0.2em;
`;

// Code Changed - Manoj ( Two Css Add to show the Types of Provider in Table)
export const TeamProviderWrapper: any = styled.div`
  position: absolute;
  // right: 0;
  right: -10px;
  top: 18px;
  color: ${theme("palette.primary-background")};
  font-weight: bold;
  width: 50px;
`;

export const TeamProviderContent: any = styled.span`
  margin: 0px 3px;
  // &::before {
  // 	content: "";
  // 	position: absolute;
  // 	width: 2px;
  // 	height: 8px;
  // 	left: 45%;
  // 	top: 50%;
  // 	transform: translate(-50%, -50%);
  // 	background-color: #5D2263;
  // }
`;

export const TeamsWrapper: any = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  flex-grow: 1;
  cursor: pointer;
  /* Text ellipsis trick */
  min-width: 0;
  font-size: ${ifProp("isBigSize", "16px", "12px")};
  margin: 0.5em 0;
  position: relative;
  ${media.lg`
		flex-direction: row;
		justify-content: flex-start;
		align-items: center;
	`};

  ${media.sm`
		flex-direction: column;
		align-items: stretch;
	`};
`;

export const TeamContentCell = styled(Cell)`
  width: ${ifProp("isLong", "70%", "32%")};
  padding: 0 0.5em;
  ${media.lg`
		width: 70%;
		// commented by ambu
		// padding: 0.75em 0.5em;
		padding: 6px;
	`};
`;
export const StateContentCell: any = styled(Cell)`
  width: ${ifProp("isLong", "30%", "16%")};
  border-right: 1px solid rgba(128, 128, 128, 0.2);
  padding: 0.5em;
  justify-content: center;

  ${media.lg`
		width: 30%;
		border-right: 0;
		justify-content: flex-end;
	`};
`;

export const OddsContentCell: any = styled(Cell)`
  width: 52%;
  // commented by ambu
  // padding: 0.5em 0;
  padding: 0.4em 0px;
  margin-left: auto;
  z-index: 0;
  position: relative;
  ${media.lg`
		width: 100%;
		// commented by ambu
		// padding: 1em 0.5em 0.6em;
		padding: 7px 6px 13px;
	`};
`;

// Team name, draw, etc, in match table
export const SelectionNameCell = styled(Cell)`
  flex-basis: 35%;
  border-right: 1px solid rgba(128, 128, 128, 0.2);
  padding-left: 2em;
  font-size: 14px;
  font-weight: normal;
  display: flex;
  justify-content: flex-start;
  align-items: center;

  ${media.lg`
		padding: 1em;
		flex-basis: 50%;
		border-bottom: 1px solid rgba(128, 128, 128, 0.2);

		${isMemberType(
      "AGENT",
      css`
        background-color: ${theme("palette.sidebar-background")};
      `
    )}

	`};

  ${media.sm`
		flex-basis: 55%;
	`};
`;

export const SelectionExposureWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  font-size: 14px;
  font-weight: normal;
  margin-top: 0.25em;
`;

export const OddsGroupWrapper: any = styled.div`
  display: flex;
  padding: 0 0.25em;
  width: calc(100% / 3);

  ${media.lg`
		position: relative;
		padding-top: 0.5em

		&::before {
			position: absolute;
			top: 0;
			left: 50%;
			transform: translate(-50%, -1em);
			font-size: 11px;
			color: ${theme("palette.primary-background")};
		}
		&:nth-last-child(1) {
			&::before {
				content: '2';
			}
		}
		&:nth-last-child(2) {
			&::before {
				content: 'x';
			}
		}
		&:nth-last-child(3) {
			&::before {
				content: '1';
			}
		}
	`};
`;

export const MatchOddsGroupWrapper: any = styled.div`
  display: flex;
  width: 100%;

  ${media.lg`
		${switchProp("type", {
      BACK: css`
        > div:nth-last-child(n + 2) {
          display: none;
        }
      `,
      LAY: css`
        > div:nth-child(n + 2) {
          display: none;
        }
      `,
    })};
	`};
`;
