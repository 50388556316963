import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { color } from '../../sass-files/variable';

import { Link } from "react-router-dom";
import Testing from '../../../images/casino-images/poker_laptop.jpg'
import Loading from "src/pages/loading";
import cookieUtil from "src/util/cookieUtil";
import { ECookieName } from "src/util/utilModel";

const { primaryColor, menucolor } = color;

export default function SlickSliderGames(props: any) {
    const [isGameTrigger, setGameTrigger] = useState(false)
    const [gameDetails, setGameDetails] = useState({})
    const isAuth = cookieUtil.get(ECookieName.COOKIE_TOKEN);
    useEffect(() => { 
        if(props.open && props.data.length > 0){ 
            loadGame("" , props.data[0]); 
        } }, [props.open , props.data])

    const loadGame = (event, game) => {
        setGameTrigger(true)
        setGameDetails(game)
    }
   
    return (
        <>
            {
                isGameTrigger ? <Loading modalState={props.modalState} platformDetails={gameDetails} /> : <><RowFirst>
                    {
                        props.data.map((each, index) => {
                            return (
                                <div key={index} className="blocks" style={{ border: '1px solid red' }}>
                                    <Content>
                                        <p>Live</p>
                                        <h4 onClick={(event) => loadGame(event, each)}>{each.game}</h4>
                                        {/* <GameName onClick={(event) => loadGame(event,each)}><span>latest Game</span></GameName> */}
                                        <Col1 imageSrc={each.imgsrc}></Col1>
                                    </Content>
                                </div>
                            )
                        })
                    }

                </RowFirst>

                    <RowSecond>

                    </RowSecond> </>
            }

        </>
    )
}

export const RowSecond = styled.link`
    width: 20%;
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: space-between;
    background-color: ${primaryColor};
    flex-wrap: wrap;
`;

export const RowFirst = styled.div`
display: flex;
flex-wrap: wrap;
background-color: ${primaryColor};

    .blocks {
        box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25), inset 4px 4px 4px rgba(0, 0, 0, 0.25);
        // padding: 2px;
        // margin: 2px;
        border-radius: 5px;
        width: 25%;
        box-sizing: border-box;
        flex: 1 0 21%; /* explanation below */
        // margin: 5px;
        // height: 100px;
    }

    .blocks_fullwidth {
        width: 100%;
        box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25), inset 4px 4px 4px rgba(0, 0, 0, 0.25);
        padding: 2px;
        margin: 3px;
        border-radius: 5px;
    }

    .top_up {
        position: relative;
        top: -25px;
    }
`;
export const LatestGame = styled.button `
    width: 250px;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 15px 20px;
    background: linear-gradient(180deg, #CB9640 3.95%, #EBA02A 96.05%);
    border-radius: 5px;
    cursor: pointer;
    border: none;
    transform: skew(-370deg);
    transition: background-color .1s linear 0s;

    position: absolute;
    margin-left: 25px;
    top: 10px;
    height: 60px;
    font-size: 24px;
    color: #F8F8F8;
    text-transform: uppercase;
    
    

    :hover {
        background: linear-gradient(180deg, #b37616 96.05%, #CB9640 3.95%);
    }

    span  {
        font-size: 24px;
        color: #F8F8F8;
        transform: skew(370deg);
        text-transform: uppercase;
        
    }
`;

const Content = styled.div `
    // display: flex;
    // flex-direction: column;
    // justify-content: center;
    // /* align-items: center; */
    // text-transform: uppercase;
    // height: 280px;
    // padding: 40px;

    h4 {
        margin:0px;
        // font-size: 35px;
        
        width: fit-content;
        text-transform: uppercase;
        padding-bottom: 5px;
        color:#fff;
        position: absolute;
        top: 75px;
        margin-left: 30px;
        font-size: 25px;

    }
    
    
    p  {
        position: absolute;
        top: 30px;
        margin-left: 30px;
        text-transform: uppercase;
        margin-bottom: 7px;
        font-size: 21px;
        width: fit-content;
        background: #33abaf;
        background: -webkit-linear-gradient(to bottom, #33abaf 0%, #33abaf 100%);
        background: -moz-linear-gradient(to bottom, #33abaf 0%, #33abaf 100%);
        background: linear-gradient(to bottom, #33abaf 0%, #33abaf 100%);
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
        font-size: 22px;
        font-family: 'poppinslight';
        
    }
`;

export const GameName = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    letter-spacing: 0.05em;
    text-transform: uppercase;
    color: ${menucolor};
    padding: 10px;
    font-size: 18px;
    
    font-weight: 600;
    line-height: 20px;
    
`;

export const Col1:any = styled.div`
    width: 100%;
    // height: 275px;
    height: 500px;
    display: flex;
    background-image: url(${(props: any) => props.imageSrc});
    background-size: cover;
    background-repeat: no-repeat;
`;

export const Col2 = styled.link`
    width: 100%;
    height: 170px;
    display: flex;
    background-size: cover;
    background-repeat: no-repeat;
`;

export const Col3 = styled.link`
    width: 100%;
    height: 550px;
    display: flex;
    background-size: cover;
    background-repeat: no-repeat;
`;

export const Col4 = styled.link`
    width: 100%;
    height: 290px;
    display: flex;
    background-size: cover;
    background-repeat: no-repeat;
`;

export const Col5 = styled.link`
    width: 100%;
    height: 264px;
    display: flex;
    background-size: cover;
    background-repeat: no-repeat;
`;

export const Col6 = styled.link`
    width: 100%;
    height: 197px;
    display: flex;
    background-size: cover;
    background-repeat: no-repeat;
`;

export const Col7 = styled.link`
    width: 100%;
    height: 222px;
    display: flex;
    background-size: cover;
    background-repeat: no-repeat;
`;