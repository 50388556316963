import React from 'react'

interface Props {
    
}

const InformationIcon = (props: Props) => {
    return (
        <div>
            <svg
                width={21}
                height={21}
                viewBox="0 0 21 21"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
                {...props}
            >
                <g filter="url(#filter0_d_6726_60)">
                <circle cx={10.3378} cy={6.7085} r={6.25732} fill="white" />
                </g>
                <g filter="url(#filter1_d_6726_60)">
                <path
                    d="M9.87666 5.17231H10.4849L10.5525 5.176C10.8634 5.20918 11.1055 5.47337 11.1055 5.79286V8.94107L11.1645 8.97978C11.2682 9.05679 11.3451 9.16455 11.384 9.28774C11.423 9.41094 11.4222 9.54329 11.3816 9.66598C11.3411 9.78866 11.2629 9.89544 11.1581 9.97113C11.0534 10.0468 10.9275 10.0875 10.7983 10.0875H10.4972L10.4296 10.0839C10.1187 10.0507 9.87666 9.78649 9.87666 9.467V6.40112L9.80478 6.39682C9.64927 6.37833 9.5067 6.30115 9.40619 6.18106C9.30568 6.06097 9.25482 5.90704 9.264 5.75071C9.27317 5.59438 9.3417 5.44745 9.45557 5.33995C9.56944 5.23245 9.72006 5.17249 9.87666 5.17231Z"
                    fill="black"
                />
                <path
                    d="M10.0566 3.50906C10.1718 3.39383 10.3281 3.3291 10.4911 3.3291C10.654 3.3291 10.8103 3.39383 10.9255 3.50906C11.0407 3.62428 11.1055 3.78056 11.1055 3.94351C11.1055 4.10646 11.0407 4.26273 10.9255 4.37795C10.8103 4.49318 10.654 4.55791 10.4911 4.55791C10.3281 4.55791 10.1718 4.49318 10.0566 4.37795C9.94139 4.26273 9.87666 4.10646 9.87666 3.94351C9.87666 3.78056 9.94139 3.62428 10.0566 3.50906Z"
                    fill="black"
                />
                <path
                    d="M10.4876 5.12239L10.4876 5.12231H10.4849L9.87666 5.12231L9.87661 5.12231C9.70726 5.1225 9.54438 5.18734 9.42125 5.3036C9.29811 5.41985 9.22401 5.57873 9.21408 5.74778C9.20416 5.91683 9.25916 6.08329 9.36785 6.21315C9.47654 6.34301 9.63071 6.42647 9.79887 6.44647L9.79886 6.44656L9.80179 6.44673L9.82666 6.44822V9.467C9.82666 9.81222 10.0882 10.0977 10.4243 10.1336L10.4243 10.1336L10.4269 10.1338L10.4945 10.1375L10.4945 10.1375H10.4972H10.7983C10.938 10.1375 11.0742 10.0935 11.1874 10.0117C11.3007 9.92981 11.3853 9.81434 11.4291 9.68167C11.473 9.549 11.4739 9.40588 11.4317 9.27266C11.3896 9.13944 11.3065 9.0229 11.1943 8.93963L11.1943 8.93956L11.1919 8.93797L11.1555 8.91408V5.79286C11.1555 5.44764 10.8939 5.16215 10.5578 5.12628L10.5578 5.12622L10.5552 5.12607L10.4876 5.12239ZM10.4911 3.2791C10.3149 3.2791 10.1459 3.3491 10.0213 3.4737C9.89666 3.5983 9.82666 3.76729 9.82666 3.94351C9.82666 4.11972 9.89666 4.28871 10.0213 4.41331C10.1459 4.53791 10.3149 4.60791 10.4911 4.60791C10.6673 4.60791 10.8363 4.53791 10.9609 4.41331C11.0855 4.28871 11.1555 4.11972 11.1555 3.94351C11.1555 3.76729 11.0855 3.5983 10.9609 3.4737C10.8363 3.3491 10.6673 3.2791 10.4911 3.2791Z"
                    stroke="black"
                    strokeWidth={0.1}
                />
                </g>
                <defs>
                <filter
                    id="filter0_d_6726_60"
                    x={0.0804443}
                    y={0.451172}
                    width={20.5146}
                    height={20.5146}
                    filterUnits="userSpaceOnUse"
                    colorInterpolationFilters="sRGB"
                >
                    <feFlood floodOpacity={0} result="BackgroundImageFix" />
                    <feColorMatrix
                    in="SourceAlpha"
                    type="matrix"
                    values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                    result="hardAlpha"
                    />
                    <feOffset dy={4} />
                    <feGaussianBlur stdDeviation={2} />
                    <feComposite in2="hardAlpha" operator="out" />
                    <feColorMatrix
                    type="matrix"
                    values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0"
                    />
                    <feBlend
                    mode="normal"
                    in2="BackgroundImageFix"
                    result="effect1_dropShadow_6726_60"
                    />
                    <feBlend
                    mode="normal"
                    in="SourceGraphic"
                    in2="effect1_dropShadow_6726_60"
                    result="shape"
                    />
                </filter>
                <filter
                    id="filter1_d_6726_60"
                    x={4.80687}
                    y={3.22949}
                    width={11.0619}
                    height={15.6702}
                    filterUnits="userSpaceOnUse"
                    colorInterpolationFilters="sRGB"
                >
                    <feFlood floodOpacity={0} result="BackgroundImageFix" />
                    <feColorMatrix
                    in="SourceAlpha"
                    type="matrix"
                    values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                    result="hardAlpha"
                    />
                    <feOffset dy={4.3561} />
                    <feGaussianBlur stdDeviation={2.17805} />
                    <feComposite in2="hardAlpha" operator="out" />
                    <feColorMatrix
                    type="matrix"
                    values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0"
                    />
                    <feBlend
                    mode="normal"
                    in2="BackgroundImageFix"
                    result="effect1_dropShadow_6726_60"
                    />
                    <feBlend
                    mode="normal"
                    in="SourceGraphic"
                    in2="effect1_dropShadow_6726_60"
                    result="shape"
                    />
                </filter>
                </defs>
            </svg>
        </div>
    )
}

export default InformationIcon
