import React, { useEffect } from 'react'
import { withAppContext } from 'src/store/initAppContext';
import styled, { withTheme } from 'styled-components';
import { LazyLoadImage } from "react-lazy-load-image-component";
import config from "src/config/config";
import PlaceholderImage from 'src/components/Placeholder';
import { theme } from 'styled-tools';
import FooterContainer from 'src/components/footer/footer';

function AviatrixSkyHighChallengePromo(props: any) {
    const { app: {
        brandId
    }, theme } = props;

    useEffect(() => {
        window.scrollTo(0, 0)
    }, [])

    const domain = (brandId == 31 || brandId == 32) ? "YOLO247" : (brandId == 39 || brandId == 40) ? "FOMO7" : 'iW247';

    return (
        <>
            <InformationWrapper>
                <TitleText>
                    {(brandId == 33 || brandId == 34) ?
                        <p>Gear up for an exciting three-month competition from March 1st to May 31st, 2025, with a staggering <strong>99 LAKHS</strong> prize pool!</p> :
                        <p>Gear up for an exciting three-month competition from March 1 - May 31 with a staggering <strong>₹99 LAKH</strong> Prize Pool!</p>
                    }
                    <p>Compete daily for your share of the rewards—no minimum bet required!</p>
                </TitleText>
                <ROWrapper>
                    <ROLeftWrapper>
                        <LazyLoadImage
                            src={`${config.imageV3Host}/static/cashsite/${theme.image.AviatrixSkyHighChallengeGirlOne}`}
                            alt="jetxPromoStepThree"
                            effect="none"
                            placeholder={<PlaceholderImage />}
                        />
                    </ROLeftWrapper>
                    <RoContent>
                        <h3>How to Participate:</h3>
                        <p>To enter the daily tournament, follow these two simple steps</p>
                        <TextWrapper>
                            <span>Stage&nbsp;1:</span>
                            <p>Place at least one bet using an NFT aircraft within 24 hours.</p>
                        </TextWrapper>
                        <TextWrapper>
                            <span>Stage&nbsp;2:</span>
                            <p>Claim your reward if your aircraft has won! Winnings are based on a user's overall gaming turnover—more turnover = bigger reward!</p>
                        </TextWrapper>
                    </RoContent>
                </ROWrapper>
                <UnderLine></UnderLine>
                <DetailsWrapper>
                    <HeadingText>
                        <h2>Tournament Mechanics</h2>
                    </HeadingText>
                    <DetailsBodyWrapper>
                        <EachWrapper>
                            <Divider></Divider>
                            <EachBody>
                                <LazyLoadImage
                                    src={`${config.imageV3Host}/static/cashsite/${theme.image.AviatrixFlyAndPlayDetImgOne}`}
                                    alt="jetxPromoStepThree"
                                    effect="none"
                                    placeholder={<PlaceholderImage />}
                                />
                                <p>Each bet earns you points equal to the bet amount. Points will accumulate and determine rankings in the real-time Leaderboard displayed in the Aviatrix game interface.</p>
                            </EachBody>
                        </EachWrapper>
                        <EachWrapper>
                            <Divider></Divider>
                            <EachBody>
                                <LazyLoadImage
                                    src={`${config.imageV3Host}/static/cashsite/${theme.image.AviatrixFlyAndPlayDetImgTwo}`}
                                    alt="jetxPromoStepThree"
                                    effect="none"
                                    placeholder={<PlaceholderImage />}
                                />
                                <p>If multiple players have the same points, the one who reached the score first ranks higher.</p>
                            </EachBody>
                        </EachWrapper>
                        <EachWrapper>
                            <Divider></Divider>
                            <EachBody>
                                <LazyLoadImage
                                    src={`${config.imageV3Host}/static/cashsite/${theme.image.AviatrixFlyAndPlayDetImgThree}`}
                                    alt="jetxPromoStepThree"
                                    effect="none"
                                    placeholder={<PlaceholderImage />}
                                />
                                <p>You can use one game account but an unlimited number of NFT aircraft to participate.</p>
                            </EachBody>
                        </EachWrapper>
                        <EachWrapper>
                            <Divider></Divider>
                            <EachBody>
                                <LazyLoadImage
                                    src={`${config.imageV3Host}/static/cashsite/${theme.image.AviatrixFlyAndPlayDetImgFour}`}
                                    alt="jetxPromoStepThree"
                                    effect="none"
                                    placeholder={<PlaceholderImage />}
                                />
                                {(brandId == 33 || brandId == 34) ?
                                    <p>The more game turnover you generate, the higher your chances of winning!</p> :
                                    <p>The more the game turnover, the higher your chances of winning are!</p>
                                }
                            </EachBody>
                        </EachWrapper>
                    </DetailsBodyWrapper>
                </DetailsWrapper>
                <ROWrapper>
                    <DPDContent>
                        <TextSection>
                            <h3>Daily Prize Distribution</h3>
                            {(brandId == 33 || brandId == 34) ?
                                <p>Every day at 19:00 UTC, winners are selected in two stages</p> :
                                <p>The winners are selected in two stages:</p>
                            }
                            <TextWrapper>
                                <span>Stage&nbsp;1:</span>
                                <p>Players qualify based on total game turnover.</p>
                            </TextWrapper>
                            <TextWrapper>
                                <span>Stage&nbsp;2:</span>
                                <p>All qualified participants win cash prizes, with their winning amount based on their total game turnover.</p>
                            </TextWrapper>
                        </TextSection>
                        <TextSection>
                            <h3>Claiming Rewards:</h3>
                            <p>Hit the "Claim Prize" button to receive your winnings instantly!</p>
                            <p>No wagering required - use your rewards to play or withdraw!</p>
                            <p>Take flight, rack up points, and win big every night!</p>
                        </TextSection>
                    </DPDContent>
                    <ROLeftWrapper>
                        <LazyLoadImage
                            src={`${config.imageV3Host}/static/cashsite/${theme.image.AviatrixSkyHighChallengeGirlTwo}`}
                            alt="jetxPromoStepThree"
                            effect="none"
                            className={(brandId == 39 || brandId == 40) ? 'fomoImgTwo' : 'ImgTwo'}
                            placeholder={<PlaceholderImage />}
                        />
                    </ROLeftWrapper>
                </ROWrapper>
                <AviatrixTermsAndCondition>
                    <h3>Terms & Conditions</h3>
                    {
                        (brandId == 31 || brandId == 32) ? (
                            <ul>
                                <li>The 'Aviatrix: Take A Flight, Win Tonight' promotion is valid for all new and existing players.</li>
                                <li>The bigger the game turnover, the greater the chances of winning.</li>
                                <li>Multiple accounts will not be eligible for this promotion. All players will undergo duplicate checking.</li>
                                <li>{domain} reserves the right to disqualify players involved in collusion or any foul play related to this promotion.</li>
                                <li>{domain} & Aviatrix reserve the right to amend, cancel, reclaim, or refuse any promotion at its discretion.</li>
                                <li>{domain} General Terms & Conditions apply.</li>
                            </ul>
                        ) : (brandId == 33 || brandId == 34) ? (
                            <ul>
                                <li>This promotion is valid for all new and existing players.</li>
                                <li>The greater the amount of game turnover, the greater the chances of winning.</li>
                                <li>Multiple accounts will not be eligible for this promotion. All players will undergo duplicate checking. {domain} reserves the right to disqualify players involved in collusion or any foul play related to this promotion.</li>
                                <li>{domain} & Aviatrix  reserves the right to amend, cancel, reclaim, or refuse any promotion at its discretion.</li>
                                <li>General Terms & Conditions of {domain} apply.</li>
                            </ul>
                        ) : (brandId == 39 || brandId == 40) ? (
                            <ul>
                                <li>The Aviatrix - Fastest Flight to Fortune promotion is valid for all new and existing players.</li>
                                <li>The bigger the game turnover, the greater the chances of winning. </li>
                                <li>Multiple accounts will not be eligible for this promotion. All players will undergo duplicate checking. </li>
                                <li>{domain} reserves the right to disqualify players involved in collusion or any foul play related to this promotion.</li>
                                <li>{domain} & Aviatrix reserve the right to amend, cancel, reclaim, or refuse any promotion at its discretion.</li>
                                <li>{domain} General Terms & Conditions apply.</li>
                            </ul>
                        ) : null
                    }
                </AviatrixTermsAndCondition>
            </InformationWrapper>
            <FooterContainer />
        </>
    )
}

export default withTheme(withAppContext(AviatrixSkyHighChallengePromo));


// Styled Component.
const InformationWrapper = styled.div`
    width: 98%;
    height: auto;
    padding: 3rem 3rem 8rem 3rem;
    background: rgba(111, 111, 111, 0.5);
    margin: 0em 1.2em 0 1.2em;
    border: 1px solid #fff;
    border-top-color: transparent;
    backdrop-filter: brightness(0.5);    
    display: flex;
    flex-direction: column;
    background: url(${config.imageV3Host}/static/cashsite/${theme("image.AviatrixSkyHighChallengeBackground")});
    background-position: top;
    background-size: cover;
    background-repeat: no-repeat;
`;

const TitleText = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    padding: 1rem 0 4rem 0;
    gap: 1.5rem;

    p {
        color: #FFF;
        text-align: center;
        font-size: 18.818px;
        font-style: normal;
        font-weight: 500;
        line-height: 70%;
        text-transform: capitalize;
        margin: 0;
    }
`;

const TextWrapper = styled.div`
    display: flex;

    span {
        color: ${theme('palette.AviatrixSkyHighChallengeTextColor')};
        font-weight: 700;
        padding-top: 1.5px;
    }

    p {
        margin: 0;
        color: #FFF;
        font-size: 16px;
        font-style: normal;
        font-weight: 500;
        line-height: 150.3%; /* 19.248px */
        text-align: left;
        padding-left: 0.5rem;
        text-transform: capitalize;
    }
`;

const DetailsWrapper = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 2rem 3rem 3rem 2rem;
    margin: 0 ;
    gap: 1.5rem;
`;

const DetailsBodyWrapper = styled.div`
    display: flex;
    justify-content: center;
    align-items: stretch;
    gap: 2rem;
`;

const EachWrapper = styled.div`
    display: flex;
    justify-content: flex-start;
    align-items: center;
    flex-direction: column;
    width: 20rem;
    border-radius: 20px;
    border: ${theme('palette.AviatrixSkyHighChallengeTermsBorder')};
    background: ${theme('palette.AviatrixSkyHighChallengeItemsBackground')};
`;

const EachBody = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    padding: 0 1rem 1rem 1rem;
    gap: 1rem;
    position: relative;

    img {
        position: absolute;
        width: 15rem;
        top: -10%;
        left: 50%;
        transform: translateX(-50%);
        text-align: center;
    }

    p {
        margin: 0;
        padding-top: 9rem;
        color: #FFF;
        text-align: center;
        font-size: 15.682px;
        font-style: normal;
        font-weight: 600;
        line-height: 150%; /* 23.523px */
        text-transform: capitalize;
    }
`;

const Divider = styled.div`
    height: 4px;
    width: 60%;
    border-radius: 76px;
    background: ${theme('palette.AviatrixSkyHighChallengeDividerBg')};
    box-shadow: ${theme('palette.AviatrixSkyHighChallengeDividerBoxShadow')};
`;

const HeadingText = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;

    h2 {
        margin: 0;
        color: ${theme('palette.AviatrixSkyHighChallengeTextColor')};
        text-align: center;
        font-size: 24px;
        font-style: normal;
        font-weight: 700;
        line-height: 179.5%; /* 43.08px */
        text-transform: capitalize;
    }
`;

const ROWrapper = styled.div`
    display: flex;
    justify-content: center;
    align-items: flex-end;
    text-align: center;
    gap: 10rem;
    width: 100%;
`;

const ROLeftWrapper = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    width: 30%;

    img {
        width: 35rem;
        text-align: center;
        margin-bottom: -0.3rem;
    }

    .ImgTwo {
        width: 30rem;
    }

    .fomoImgTwo {
        width: 35rem;
    }
`;

const RoContent = styled.div`
    display: flex;
    flex-direction: column;
    gap: 1.5rem;
    width: 40%;
    padding-right: 2rem;
    border-radius: 20px;
    background: ${theme('palette.AviatrixSkyHighChallengeStagesbackgrond')};
    border: ${theme('palette.AviatrixSkyHighChallengeStagesborder')};
    padding: 3rem;
    align-self: center;
    position: relative;


    h3 {
        margin: 0;
        color: ${theme('palette.AviatrixSkyHighChallengeTextColor')};
        font-size: 24px;
        font-style: normal;
        font-weight: 700;
        line-height: 179.5%; /* 43.08px */
        text-transform: capitalize;
        text-align: left;
    }

    p {
        margin: 0;
        color: #FFF;
        font-size: 16px;
        font-style: normal;
        text-align: left;
        font-weight: 400;
        line-height: 150%; /* 24px */
        text-transform: capitalize;
    }

    ::before {
        content: '';
        position: absolute;
        background: url(${config.imageV3Host}/static/cashsite/${theme("image.AviatrixFlyAndPlayDetImgFive")});
        width: 17rem;
        height: 16rem;
        background-position: top;
        background-size: contain;
        background-repeat: no-repeat;
        position: absolute;
        top: -5rem;
        right: -6rem;
    }
`;

const DPDContent = styled(RoContent)`
    margin-bottom: 2.5rem;
    gap: 2.5rem;

    ::before {
        content: none;
    }
`;

const TextSection = styled.div`
    width: 100%;
    display: flex;
    flex-direction: column;
    align-self: center;
    position: relative;
    gap: 1rem;
`;

const UnderLine = styled.div`
    height: 2px;
    width: 100%;
    background: radial-gradient(#FFF 0%, rgba(153, 153, 153, 0.00) 70%);
`;

const AviatrixTermsAndCondition = styled.div`
    width: 90%;
    height: auto;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    margin: 0 5%;
    padding: 5rem 7rem;
    position: relative;
    border-radius: 20px;
    border: ${theme('palette.AviatrixSkyHighChallengeTermsBorder')};
    background: ${theme('palette.AviatrixSkyHighChallengeTermsBackground')};


    & li {
        color: #FFF;
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: 22.8px; /* 142.5% */
        text-transform: capitalize;
        list-style-type: '🔹';
        margin: 1rem 0;
        padding-left: 5px;
    }

    & h3 {
        color: ${theme('palette.AviatrixSkyHighChallengeTextColor')};
        font-size: 30px;
        font-style: normal;
        font-weight: 700;
        line-height: 179.5%; /* 53.85px */
        text-transform: capitalize;
        margin: 10px 0px 0px 15px;
    }

    .bold {
        color: #FFF;
        font-weight: 700;
    }

    ::before {
        content: '';
        position: absolute;
        background: url(${config.imageV3Host}/static/cashsite/${theme("image.AviatrixFlyAndPlayDetImgSix")});
        width: 10rem;
        height: 8rem;
        background-position: top;
        background-size: contain;
        background-repeat: no-repeat;
        position: absolute;
        top: 5rem;
        right: 6rem;
    }

    ::after {
        content: '';
        position: absolute;
        background: url(${config.imageV3Host}/static/cashsite/${theme("image.AviatrixFlyAndPlayDetImgSeveen")});
        width: 6rem;
        height: 5rem;
        background-position: top;
        background-size: contain;
        background-repeat: no-repeat;
        position: absolute;
        bottom: 1rem;
        left: 2rem;
    }
`;
