import React from "react";

type Props = {};

export default function ContactMailIcon({}: Props) {
  return (
    <svg viewBox="0 0 21 17" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M18.9574 0.751953L10.5369 0.772461L2.11641 0.731445H2.1123C0.980273 0.731445 0.0820312 1.79375 0.0820312 3.12266V14.2543C0.0820312 15.5832 0.980273 16.6455 2.1123 16.6455H18.9697C20.1018 16.6455 21 15.5832 21 14.2543V3.12266C21 1.79375 20.11 0.751953 18.9574 0.751953ZM18.2971 2.39258L10.7748 9.2709L10.7461 9.29551L10.7215 9.32422C10.6312 9.42266 10.5246 9.43496 10.4672 9.43496C10.4098 9.43496 10.3031 9.41855 10.217 9.31602L10.1924 9.2873L3.00234 2.35977L10.5369 2.40898L18.2971 2.39258ZM18.9697 15.0049H2.1123C1.96875 15.0049 1.72266 14.7342 1.72266 14.2543V3.38516L9.00703 10.4234C9.37617 10.8336 9.89707 11.0715 10.4508 11.0797H10.4754C11.0209 11.0797 11.5295 10.8541 11.9027 10.4604L19.3676 3.62715V14.2543C19.3594 14.7342 19.1133 15.0049 18.9697 15.0049Z" fill="white"/>
    </svg>
  );
}


export  function ContactMailIconf88({}: Props) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="21" height="22" viewBox="0 0 21 22" fill="none">
    <path d="M18.9574 2.90527L10.5369 2.92578L2.11641 2.88477H2.1123C0.980273 2.88477 0.0820312 3.94707 0.0820312 5.27598V16.4076C0.0820312 17.7365 0.980273 18.7988 2.1123 18.7988H18.9697C20.1018 18.7988 21 17.7365 21 16.4076V5.27598C21 3.94707 20.11 2.90527 18.9574 2.90527ZM18.2971 4.5459L10.7748 11.4242L10.7461 11.4488L10.7215 11.4775C10.6312 11.576 10.5246 11.5883 10.4672 11.5883C10.4098 11.5883 10.3031 11.5719 10.217 11.4693L10.1924 11.4406L3.00234 4.51309L10.5369 4.5623L18.2971 4.5459ZM18.9697 17.1582H2.1123C1.96875 17.1582 1.72266 16.8875 1.72266 16.4076V5.53848L9.00703 12.5768C9.37617 12.9869 9.89707 13.2248 10.4508 13.233H10.4754C11.0209 13.233 11.5295 13.0074 11.9027 12.6137L19.3676 5.78047V16.4076C19.3594 16.8875 19.1133 17.1582 18.9697 17.1582Z" fill="black"/>
    </svg>
  );
}
