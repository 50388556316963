import React, { useEffect } from 'react'
import config from 'src/config/config';
import { withAppContext } from 'src/store/initAppContext';
import styled, { withTheme } from 'styled-components';
import { theme } from 'styled-tools';
import FooterContainer from "src/components/footer/footer";
import { LazyLoadImage } from 'react-lazy-load-image-component';
import PlaceholderImage from 'src/components/Placeholder';

const AviatrixFBEPromo = (props:any) => {

    const { theme } = props;

    useEffect(() => {
        window.scrollTo(0, 0)
    }, [])

  return (
        <>
            <InnerPromoWrapper>
                    <AviatrixFBEontentWrapper>
                        <InnerBox>
                            <MainHeads><h3>Aviatrix – Skyrocket your wins!</h3></MainHeads>
                            <SecondRow>
                                    <LiTextContent>
                                        <TextContent> 
                                        iw247 introduces free bets on your favourite game – Aviatrix a game that takes your entertainment quotient to new heights. Brace yourself for an edge of the seat adventure, where strategy meets skills. A total pool prize of <span>₹ 50,00,000 </span> worth of free bets await!
                                        </TextContent>
                                    </LiTextContent>
                                    <LiTextContent>
                                        <TextContent>
                                              Get your gaming wits on and Soar high!
                                        </TextContent>
                                    </LiTextContent>                                  
                            </SecondRow>

                            <AviatrixFBEBox>
                                <AviatrixFBEBoxWrap>
                                    <AviatrixFBEBoxLeft>
                                        <LazyLoadImage src={`${config.imageV3Host}/static/cashsite/${theme.image.aviatrixFBECharacter}`} effect="none" width="100%" placeholder={<PlaceholderImage />} />
                                    </AviatrixFBEBoxLeft>
                                    <AviatrixFBEBoxRight>
                                        <h4>Embark on a high flying adventure</h4>
                                        <p>
                                            <span>Stage 1: </span>  Make at least 1 bet with NFT aircraft in 24 hours.
                                        </p>
                                        <p>
                                            <span>Stage 2: </span> Cheer for your aircraft to win the daily tournament. Claim the reward after victory!
                                        </p>
                                        <p>
                                            <span>Stage 3: </span>  Earn points with each bet. Points are equal to your bet amount!
                                        </p>
                                        <p>
                                            <span>Bet & Win:  </span> Win everyday at Midnight!
                                        </p>
                                    </AviatrixFBEBoxRight>
                                </AviatrixFBEBoxWrap>
                            </AviatrixFBEBox>

                            <AviatrixFBEStepsSec>
                               <AviatrixFBEStepsBox>
                                  <AviatrixFBEStepsBoxLeft>
                                     <h5>Visit iw247</h5>
                                     <p>
                                        Log in to your iw247 account and head to aviatrix!
                                     </p>
                                  </AviatrixFBEStepsBoxLeft>
                                  <AviatrixFBEStepsBoxRight>
                                      <LazyLoadImage src={`${config.imageV3Host}/static/cashsite/${theme.image.aviatrixFBEIllus01}`} effect="none" width="100%" placeholder={<PlaceholderImage />} />
                                  </AviatrixFBEStepsBoxRight>
                               </AviatrixFBEStepsBox>

                               <AviatrixFBEStepsBox>
                                  <AviatrixFBEStepsBoxLeft>
                                     <h5>Deposit & Dive In</h5>
                                     <p>
                                       Select your preferred deposit method and dive headfirst into the immersive world of Aviatrix. Let the excitement fuel your every move as you chase victory and fortune!
                                     </p>
                                  </AviatrixFBEStepsBoxLeft>
                                  <AviatrixFBEStepsBoxRight>
                                      <LazyLoadImage src={`${config.imageV3Host}/static/cashsite/${theme.image.aviatrixFBEIllus03}`} effect="none" width="100%" placeholder={<PlaceholderImage />} />
                                  </AviatrixFBEStepsBoxRight>
                               </AviatrixFBEStepsBox>

                               <AviatrixFBEStepsBox>
                                  <AviatrixFBEStepsBoxLeft>
                                     <h5>Place Your Bets</h5>
                                     <p>
                                         Zero down on your strategy, get your game face on, and launch into the thrilling world of Aviatrix.
                                     </p>
                                  </AviatrixFBEStepsBoxLeft>
                                  <AviatrixFBEStepsBoxRight>
                                      <LazyLoadImage src={`${config.imageV3Host}/static/cashsite/${theme.image.aviatrixFBEIllus02}`} effect="none" width="100%" placeholder={<PlaceholderImage />} />
                                  </AviatrixFBEStepsBoxRight>
                               </AviatrixFBEStepsBox>

                               <AviatrixFBEStepsBox>
                                  <AviatrixFBEStepsBoxLeft>
                                     <h5>Soar to Victory</h5>
                                     <p>
                                          Navigate through the challenges, plan your bets, and win free bets.
                                     </p>
                                  </AviatrixFBEStepsBoxLeft>
                                  <AviatrixFBEStepsBoxRight>
                                      <LazyLoadImage src={`${config.imageV3Host}/static/cashsite/${theme.image.aviatrixFBEIllus04}`} effect="none" width="100%" placeholder={<PlaceholderImage />} />
                                  </AviatrixFBEStepsBoxRight>
                               </AviatrixFBEStepsBox>
                            </AviatrixFBEStepsSec>

                            <AviatrixFBETerms>
                                <h4>Terms and Conditions:</h4>
                                    <ul>
                                        <li>This promotion is valid for all new & existing players.</li>
                                        <li>This promotion is valid from <span>22nd Mar 2024 to 31st May 2024.</span></li>
                                        <li>Each Player can only claim the bonus once during promotional period.</li>
                                        <li>Points are equal to the bet amount.</li>
                                        <li>Void/Cancelled/Draw bets, Cashed-Out bets do not count towards the deposit or bonus rollover requirements.</li>
                                        <li>
                                            Multiple accounts will not be considered for this promotion. All players will be subject for duplicate checking. iw247 reserves 
                                            the right to disqualify players if collusion or any foul play takes place in the promotion.
                                        </li>
                                        <li><span>iw247</span> reserves the right to amend, cancel, and reclaim or refuse any promotion at its own discretions
                                        </li>
                                        <li><span>iw247</span> General Terms & Conditions apply.</li>
                                    </ul>
                            </AviatrixFBETerms>

                        </InnerBox>
                    </AviatrixFBEontentWrapper>
            </InnerPromoWrapper>
            <FooterContainer />
        </>
  )
}

export default withTheme(withAppContext(AviatrixFBEPromo));


//Styled Components
const AviatrixFBEontentWrapper = styled.div`
    width: 100%;
    background: url(${config.imageV3Host}/static/cashsite/${theme('image.aviatrixFBEInnerBg')});
    background-position: top;
    background-size: cover;
    background-repeat: no-repeat;
    gap: 2em;

    p {
        color: #FFF;
        text-align: center;
        font-size: 21px;
        font-style: italic;
        font-weight: 400;
        line-height: normal;
        margin: 5px 0px;
    }

    .m-0 {
        margin: 0;
    }

    .mt-5 {
        margin-top: 2em;
        width: 100%;
        margin-bottom: 0em;
    }

    .p-0 {
        padding: 0rem 20rem;

        span{
            color: #E5A20D;
        }
    }

    a {
        width: 100%;
        height: auto;

        & span {
            display: flex !important;
            justify-content: center !important;

            & img {
                width: 60%;
                height: 80%;
            }
        }
    }

    .lazy-load-image-background {
        display: flex !important;
        justify-content: center !important;
    }

    .container {
        max-width: 1620px;
        margin: auto;
        display: flex;
        flex-direction: column;
        align-items: center;
    }
`;


const InnerPromoWrapper = styled.div`
    width: 98%;
    height: auto;
    border: 1px solid white;
    border-radius: 1px;
    background: rgba(111,111,111,0.5);
    margin: 0em 1.2em;
    -webkit-backdrop-filter: brightness(0.5);
    backdrop-filter: brightness(0.5);
`

const AviatrixFBEBox = styled.div`
   width: 90%;
   height: auto;
   border-radius: 20px;
   border: 2px solid #F90;
   background: rgba(0, 0, 0, 0.60);
   margin-top: 6em;
   padding: 10px 10px 0px 10px;
   position: relative;

   & > h4 {
        color: #FF9900;
        text-align: center;
        font-size: 26px;
        font-style: normal;
        font-weight: 700;
        line-height: 179.5%; /* 50.26px */
        text-transform: capitalize;
        margin: 3px 0px;
   }

   & p {
        color: #FFF;
        font-size: 18px;
        font-style: normal;
        font-weight: 400;
        line-height: 24.5px;
        text-align: left;
        text-transform: capitalize;

        & span {
            color: #FF9900;
            text-align: justify;
            font-size: 20px;
            font-style: normal;
            font-weight: 600;
            line-height: 24.5px; /* 122.5% */
        }
    }

    ::before {
        content: '';
        background: url(${config.imageV3Host}/static/cashsite/${theme('image.aviatrixFBEBeforePlane')});
        position: absolute; 
        top: 0px;
        left: 0px;
        height: 50px;
        width: 30px;
    }
`


const AviatrixFBETerms = styled.div`
    width: 80%;
    height: auto;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 10px;
    margin: 5em 0em 2em 0em;


    & li {
        color: #FFF;
        text-align: justify;
        font-size: 18px;
        font-style: normal;
        font-weight: 400;
        line-height: 165%;
        letter-spacing: -0.8px;
        text-transform: capitalize;

        & span {
            color: #ffffff;
            font-weight: 900;
        }
    }

    & h4 {
        text-align: left;
        font-size: 26px;
        font-style: normal;
        font-weight: 900;
        line-height: normal;
        letter-spacing: 1.3px;
        text-transform: capitalize;
        color: #FF9900;
        margin: 10px 0px 0px 15px;
    }
`


const SecondRow = styled.div`
    width: 90%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: space-evenly;
    gap: 10px;
`

const LiTextContent = styled.div`
    width: 100%;
    display: flex;
    gap: 20px;

    p {
        color: #FFF;
        font-size: 18px;
        font-style: normal;
        font-weight: 400;
        line-height: 200%;
        text-transform: capitalize;
        margin: 3px 0px;
        text-align: left;
    }

    span {
        text-transform: uppercase;
        font-size: 18px;
    }
`;


const TextContent = styled.p`
    color: ${theme('palette.catch99TextContentColor')};
    font-size: 20px;

    & span {
        color: #FF9900 !important;
        font-size: 20px !important;
        font-style: normal;
        font-weight: 600;
        line-height: 130%;
        text-transform: capitalize;
        margin: 0px;
        display: contents !important;
    }
`;

const MainHeads = styled.div`
    display: flex;
    justify-content: flex-start;
    width: 90%;
    padding-top: 15px;

    & h3 {
        color: #FF9900;
        text-align: left;
        font-size: 24px;
        font-style: normal;
        font-weight: 900;
        line-height: 179.5%; /* 50.26px */
        text-transform: capitalize;
        margin: 0px 0px 10px;
    }
`

const InnerBox = styled.div`
    padding: 3em 0em;
    margin: auto;
    width: 95%;
    height: auto;
    display: flex;
    flex-direction: column;
    align-items: center;
`

const AviatrixFBEBoxWrap = styled.div`
    display: flex;
    align-items: center;
    width: 100%;
    height: auto;
`
const AviatrixFBEBoxLeft = styled.div`
    width: 50%;
    height: auto;

     img {
        width: 80%;
        height: auto;
        margin-top: -10%;
    }
`
const AviatrixFBEBoxRight = styled.div`
    width: 50%;
    height: auto;
    display: flex;
    flex-direction: column;
    gap: 20px;
    padding: 20px 10px;

    & h4 {
        color: #F90;
        font-size: 26px;
        font-style: normal;
        font-weight: 700;
        line-height: 179.5%; /* 50.26px */
        text-transform: capitalize;
        margin: 3px 0px;
    }

    & p {
        color: #FFF;
        font-size: 18px;
        font-style: normal;
        font-weight: 400;
        line-height: 24.5px;
        text-align: left;
        text-transform: capitalize;

        & span {
            color: #FF9900;
            text-align: justify;
            font-size: 20px;
            font-style: normal;
            font-weight: 600;
            line-height: 24.5px; /* 122.5% */
        }
    }
`

const AviatrixFBEStepsSec = styled.div`
    width: 90%;
    height: auto;
    display: flex;
    align-items: center;
    gap: 40px;
    flex-wrap: wrap;
    justify-content: center;
    margin-top: 5em;
`

const AviatrixFBEStepsBox = styled.div`
    width:45%;
    height: 25vh;
    min-height: 200px;
    border-radius: 20px;
    border: 1px solid #F90;
    background: radial-gradient(1165.69% 282.76% at -64.11% -58.63%, #795929 0%, #131312 39.94%, #131211 66.87%, #40321D 100%);
    display: flex;
    align-items: center;
    padding: 1em 2em;
    position: relative;
`

const AviatrixFBEStepsBoxLeft = styled.div`
    width: 70%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 5px;

    & h5 {
        color: #FF9C07;
        font-size: 21px;
        font-style: normal;
        font-weight: 700;
        line-height: normal;
        text-transform: capitalize;
        margin: 3px 0px;
    }

    & p {
        color: #FFF;
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
        text-transform: capitalize;
        margin: 3px 0px;
        text-align: left;
    }
`

const AviatrixFBEStepsBoxRight = styled.div`
    width: 30%;

   & img {
        width: 200px;
        height: 250px;
        object-fit: contain;
        position: absolute;
        right: 0px;
        top: -68px;
   }
` 