import React, { useState, useEffect, useContext } from 'react';
import { getAccountInfoData, setAccountInfoData } from 'src/config/api';
import styled, { withTheme } from 'styled-components';
import DatePicker from "react-datepicker";
import QRCode from "react-qr-code";
import "react-datepicker/dist/react-datepicker.css";
import { subYears } from 'date-fns';
// import { withAppContext } from "src/store/initAppContext";
import Intl from '../../../components/common/Intl';
import {
  BodyWrapper as BodyWrapperAccountInfo,
  FirstCol,
  SecondCol,
  Header,
  MainInfoWrapper,
  Button,
  SvgIcon
} from '../../../components/udb/commonStyle';
import { ifProp, theme, withProp } from 'styled-tools';
import GeneralIcon from 'src/components/icon/GeneralIcon';
import UserNameIcon from 'src/components/icon/UserNameIcon';
import SmartPhone, { SmartPhonef88 } from 'src/components/icon/SmartPhoneIcon';
import ContactMailIcon, { ContactMailIconf88 } from 'src/components/icon/ContactMailIcon';
import TelegramIcon, { TelegramIconf88 } from 'src/components/icon/TelegramIcon';
// import SkypeIcon from 'src/components/icon/SkypeIcon';
import WhatsappIcon, { WhatsappIconf88 } from 'src/components/icon/WhatsappIcon';
import CodeLinkIcon, { CodeLinkIconf88 } from 'src/components/icon/CodeLinkIcon';
import WebIcon, { WebIconf88 } from 'src/components/icon/WebIcon';
import { UserContext } from '../index';
import { Notify } from '../../../components/Notification';
import UDBHeader from 'src/components/UDBHeader';
import Instagram, { Instagramf88 } from 'src/components/icon/Instagram';
import CopyIcon, { CopyIconf88 } from 'src/components/icon/Copy'
import FlagIcon from 'src/components/icon/IndianFlag'
import AccountInfoTierComponent from './AccountInfoTierComponent';
import { withAppContext } from 'src/store/initAppContext';
import SEOContents from 'src/components/SEOContents';
import GeneralIconFun88 from 'src/components/icon/GeneralIconFun88';
import UserNameIconFun88 from 'src/components/icon/UserNameIconFun88';

export type AccountInfoProps = {
  username: string;
  type: string;
  firstName: string;
  lastName: string;
  email: string;
  phoneNumber: string;
  referralCode: string;
  telegram_id: string;
  // skype_id: string;
  instagram_id: string;
  whatsapp_number: string,
  dob: string,
  is_WP: boolean,
  memberId: number,
  uuid: string,
  phoneCountry: string,
  agent_user_id: Number,
  tier: string,
  created?: string;
  is_ftd_requested: any;
  is_ftd_done?:boolean;
  redeposit_percentage?:string| number;
  phoneNumberVerified?: boolean;
  is_lifetime_kyc_approved?: boolean;
  max_deposit_transaction_details?: max_deposit_transaction_details;
}

type max_deposit_transaction_details = {
  max_deposit_account_not_found?: boolean;
  upload_receipt_status?: any;
  transaction_sn?: string;
  date?: any;
  max_deposit_txn_amount?: any;
  upload_receipt_attempts?: any;
}

export function copyToClipboard(textToCopy) {
  if (navigator.clipboard && window.isSecureContext) {
    return navigator.clipboard.writeText(textToCopy);
  } else {
    let textArea = document.createElement("textarea");
    textArea.value = textToCopy;
    textArea.style.position = "fixed";
    textArea.style.left = "-999999px";
    textArea.style.top = "-999999px";
    document.body.appendChild(textArea);
    textArea.focus();
    textArea.select();
    return new Promise((res, rej) => {
      document.execCommand('copy') ? res(true) : rej();
      textArea.remove();
    });
  }
}

const defaultAccountInfoProps: AccountInfoProps = {
  username: '',
  type: '',
  firstName: '',
  lastName: '',
  email: '',
  phoneNumber: '',
  referralCode: '',
  telegram_id: '',
  instagram_id: '',
  whatsapp_number: '',
  dob: '',
  is_WP: false,
  memberId: 0,
  uuid: '',
  phoneCountry: 'in',
  agent_user_id: null,
  tier: '',
  is_ftd_requested:false,
  phoneNumberVerified: false
}

function AccountInfo(props) {
  const [accountInfo, setAccountInfo] = useState(defaultAccountInfoProps);
  const [DOB, setDOB] = useState(null);
  const [isEdit, setIsEdit] = useState(false);
  const [errorState, setErrorState] = useState({
    email: '',
    telegram_id: '',
    // skype_id: '',
    whatsapp_number: '',
    instagram_id: '',
    phoneNumber:'',
    firstName: '',
    lastName: ''
  });
  const { app: {
    brandId
  }, theme } = props;

  const { config: { showtier = true, showReferral, showDBAccountInfoContact, showFirstNameLastName, showCountryCode, showreffralLink, showAccountInfoIcons, showOnlyOnFun } } = theme;

  const { setUserInfo } = useContext(UserContext);

  const getAccountData = () => {
    const getData = getAccountInfoData();
    getData.then(response => {
      const { data: { data } } = response;
      let dob = data.dob; //dd-mm-yyyy
      if (dob) {
        dob = dob.split('-') //need to convert it mm-dd-yyyy
        dob = `${dob[1]}/${dob[0]}/${dob[2]}`;
      }
      dob = dob ? new Date(dob) : null;
      setDOB(dob);
      setAccountInfo(data);
      if(data.agent_user_id !== null){
        setAccountInfo(prevS => ({...prevS, phoneCountry: 'in'}));
      }
      setUserInfo(data);
    }).catch(err => console.log('error', err.message))
  }

  useEffect(() => {
    getAccountData()
  }, []);

  const onChangeHandler = (e: React.ChangeEvent<HTMLInputElement>, pattern?: string) => {
    const elm = e.target;
    const elmName = elm.name;
    let value = elm.value;
    if (pattern && pattern === 'number') {
      const regExp = /^\d+$/;
      const preV = accountInfo[elmName] || '';
      if (value) { value = regExp.test(value) ? value : preV; }

    }
    setAccountInfo(prevState => ({ ...prevState, [elmName]: value }));
  }

  const onDateChange = (date, key) => {
    let newDOB = '';
    if (date) {
      let dd = date.getDate();
      dd = String(dd).length < 2 ? '0' + dd : dd;
      let mm = (date.getMonth()) + 1;
      mm = String(mm).length < 2 ? '0' + mm : mm;
      let yy = date.getFullYear();
      newDOB = `${dd}-${mm}-${yy}`;
    }
    setDOB(date);
    setAccountInfo(prevState => ({ ...prevState, [key]: newDOB }));
  }

  const onEditClick = () => setIsEdit(true);

  const onSave = () => {
    for (const [key, value] of Object.entries(errorState)) {
      if (value) {
        return false
      }
    }
    const params = JSON.stringify(accountInfo);
    setAccountInfoData(params).then(res => {
      if (res?.data?.success) {
        getAccountData();
        Notify.success('Account details updated successfully');
        setIsEdit(false)
      }
    }).catch(e => {
      let error = 'Something went wrong please try again'
      if (e.response && e.response.data) {
        error = e.response.data.error;
      }
      Notify.error(error);
      //setIsEdit(false)
    });

  }

  const focusHandle = (name) => {
    setErrorState(prevProp => ({ ...prevProp, [name]: '' }))
  }

  const validateEmail = (e: React.FocusEvent<HTMLInputElement, Element>) => {
    const _value = e.target.value;
    const _regEx = /^([a-zA-Z0-9_\.\-])+\@(([a-zA-Z0-9\-])+\.)+([a-zA-Z0-9]{2,4})+$/;
    if (_value && !_regEx.test(_value)) {
      setErrorState(prevS => ({ ...prevS, email: 'Enter a valid email address' }));
    }
    if (_value && _value.length > 50) {
      setErrorState(prevS => ({ ...prevS, email: 'Max 50 characters are allowed' }));
    }
  }

  const validateTelegram = (e: React.FocusEvent<HTMLInputElement>) => {
    const _value = e.target.value.trim();
    const _regEx = /[!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?]+/; //spacial Chars
    if (_value && _value.length < 6) {
      setErrorState(prevS => ({ ...prevS, telegram_id: 'Enter minimum 6 characters' }));
    }
    else if (_value && _regEx.test(_value)) {
      setErrorState(prevS => ({ ...prevS, telegram_id: 'special characters are not allowed' }))
    }
  }

  // const validateSkype = (e: React.FocusEvent<HTMLInputElement>) => {
  //   const _value = e.target.value.trim();
  //   //const _regEx = /[!@#$%^&*()+\-=\[\]{};':"\\|,.<>\/?]+/; //spacial Chars
  //  // if (_value && _regEx.test(_value)) {
  //    // setErrorState(prevS => ({ ...prevS, skype_id: 'special characters are not allowed' }))
  //   //}
  //   if(!_value) {
  //     setErrorState(prevS => ({ ...prevS, skype_id: 'special characters are not allowed' }))
  //   }
  // }

  const validateWhatsapp = (e: React.FocusEvent<HTMLInputElement>) => {
    const _value = e.target.value.trim();
    const _regEx = /^\d{10}$/; //spacial Chars
    if (_value && !_regEx.test(_value)) {
      setErrorState(prevS => ({ ...prevS, whatsapp_number: 'Enter a valid number' }))
    }
  }

  const validatePhone = (e: React.FocusEvent<HTMLInputElement>) => {
    const _value = e.target.value.trim();
    const _regEx = /^\d{10}$/; //spacial Chars
    if (_value && !_regEx.test(_value)) {
      setErrorState(prevS => ({ ...prevS, phoneNumber: 'Enter a valid number' }))
    }
  }

  const validateUsername = (e: React.FocusEvent<HTMLInputElement>) => {
   
    const _elm = e.target;
    const _value = _elm.value.trim();
    const _name = _elm.name;
    const _regEx =  /^[a-zA-Z0-9\b]+$/ //spacial Chars
    if (_value && !_regEx.test(_value)) {
      setErrorState(prevS => ({ ...prevS, [_name] : 'Enter a valid name' }))
    } else if(_value && (_value.length <2 || _value.length >18 )) {
      setErrorState(prevS => ({ ...prevS, [_name] : 'Enter a valid name' }))
    }
  }

  const copyClipboard = (text) => {
    // if (navigator && navigator.clipboard && navigator.clipboard.writeText){
    //   navigator.clipboard.writeText(text);
    // }
    copyToClipboard(text)
      .then(() => Notify.success('Text copied '))
      .catch(() => console.log('error'));
  }

  const refUrl = `${window.location.origin}/signup/${accountInfo.referralCode}`;

  return (
    <MainInfoWrapper>
      <SEOContents pageName="memberProfileAccountInfo"/>
      <Header>
        <FirstCol>
          <UDBHeader title="TITLE@AccountInfo" />
        </FirstCol>
        <SecondCol>
          {isEdit ? <Button onClick={onSave}><Intl langKey="TITLE@Save" /></Button> : <Button onClick={onEditClick}><Intl langKey="TITLE@Edit" /></Button>}
        </SecondCol>
      </Header>
      <BodyWrapper>
        {showtier && <AccountInfoTierComponent data={accountInfo} />}
        <AccountInfoWrapper>
          <BlockCol className='posR'>
            <ProfileName>
              <SubTittle><Intl langKey="TITLE@General" /></SubTittle>
              <SvgIcon>
                {showAccountInfoIcons ? <GeneralIconFun88 /> : <GeneralIcon /> }
              </SvgIcon>
              <NameDate>
                
              {showFirstNameLastName ?
                  <>
                      {isEdit ? 
                          <input placeholder="First Name" type="text" name='firstName' className={errorState.firstName && 'error'}  value={accountInfo.firstName} onChange={(e) => onChangeHandler(e)} onFocus={() => focusHandle('firstName')} onBlur={(e) => validateUsername(e)}/> 
                          : <p>{accountInfo.firstName} {accountInfo.lastName}</p>
                      }
                      {errorState.firstName && <ErrorContainer>{errorState.firstName}</ErrorContainer>}
                      {isEdit ? 
                          <input placeholder="Last Name" type="text" name='lastName' className={errorState.lastName && 'error'}  value={accountInfo.lastName} onChange={(e) => onChangeHandler(e)} onFocus={() => focusHandle('lastName')} onBlur={(e) => validateUsername(e)} /> 
                          : <></>
                      }
                      {errorState.lastName && <ErrorContainer>{errorState.lastName}</ErrorContainer>}
                  </>
                  :
                  <>
                    <p>{`${accountInfo.firstName} ${accountInfo.lastName}`}</p>
                  </>
                }
                {isEdit ?
                  <DatePicker
                    selected={DOB}
                    dateFormat="dd-MM-yyyy"
                    maxDate={subYears(new Date(), 18)}
                    minDate={subYears(new Date(), 100)}
                    onChange={(date) => onDateChange(date, 'dob')}
                    placeholderText="DD-MM-YYYY"
                    showDisabledMonthNavigation
                  />
                  : <span>{accountInfo.dob ? accountInfo.dob : 'DD-MM-YYYY'}</span>
                }
              </NameDate>
            </ProfileName>

            <ProfileName>
              <SubTittle><Intl langKey="TITLE@UserName" /></SubTittle>
              <SvgIcon>
                {showAccountInfoIcons ? <UserNameIconFun88 /> : <UserNameIcon />}
              </SvgIcon>
              <NameDate>
                <p>{accountInfo.username}</p>
              </NameDate>
            </ProfileName>
          </BlockCol>

          <CentreCol>
            <ProfileName>
              <SubTittle><Intl langKey="TITLE@ContactDetails" /></SubTittle>
              <ContactWrap>
                <SvgIcon>
                  {showAccountInfoIcons ? <SmartPhonef88/> : <SmartPhone />}
                  

                  
                </SvgIcon>
                {!showCountryCode ?
                   <>&nbsp; <FlagIcon /> &nbsp;</>
                  :
                  <input type="text" readOnly value="+91" className={isEdit ? "readonly-input-active" : "readonly-input"} />
                }
                <NameDate>
                {isEdit && showDBAccountInfoContact ? <input placeholder="" type="text" name='phoneNumber' className={errorState.phoneNumber ? 'phoneNumberInp error' : 'phoneNumberInp'} value={accountInfo.phoneNumber} onChange={(e) => onChangeHandler(e, 'number')} onFocus={() => focusHandle('phoneNumber')} onBlur={(e) => validatePhone(e)} /> :  
                <PhoneNumber>
                  {showOnlyOnFun && <span className='countryCode'>+91</span>}
                  {accountInfo.phoneNumber}
                  {(showOnlyOnFun && accountInfo.phoneNumber && accountInfo.phoneNumberVerified) && <VerifiedSecRight><p className='verifiedText'>Verified</p></VerifiedSecRight>}
                </PhoneNumber>
                }
                {errorState.phoneNumber && <ErrorContainer>{errorState.phoneNumber}</ErrorContainer>}
                </NameDate>
                </ContactWrap>
            </ProfileName>

            <ProfileName>
              <SubTittle><Intl langKey="TITLE@Email" /></SubTittle>
              <EmailWrap>
                <SvgIcon>
                  {showAccountInfoIcons ? <ContactMailIconf88/> : <ContactMailIcon />}
                  
                </SvgIcon>
                <NameDate>
                  {isEdit ? <input placeholder="-" type="email" name='email' className={errorState.email ? 'emailinput error' : 'emailinput'} value={accountInfo.email} onChange={(e) => onChangeHandler(e, 'email')} onFocus={() => focusHandle('email')} onBlur={(e) => validateEmail(e)} /> : <span>{accountInfo.email}</span>}
                  {errorState.email && <ErrorContainer>{errorState.email}</ErrorContainer>}
                </NameDate></EmailWrap>
            </ProfileName>
          </CentreCol>

          <BlockCol>
            <ProfileName className={brandId === 46 || brandId === 47 ? "profileNameAlign" : ""}>
              <SubTittle><Intl langKey="TITLE@Apps" /></SubTittle>
              {/* <SocialSvgIcon>
                {showAccountInfoIcons ? <TelegramIconf88/> : <TelegramIcon />}
              </SocialSvgIcon> */}
              {showAccountInfoIcons ? <SocialSvgIcon><TelegramIconf88/></SocialSvgIcon> : <SvgIcon><TelegramIcon /></SvgIcon>}
              <NameDate className={brandId === 46 || brandId === 47 ? "nameDataAlign" : ""}>
                <p><Intl langKey="TITLE@TelegramUsername" /></p>
                {isEdit ? <input placeholder="-" type="text" name='telegram_id' className={errorState.telegram_id && 'error'} value={accountInfo.telegram_id} onChange={(e) => onChangeHandler(e)} onFocus={() => focusHandle('telegram_id')} onBlur={(e) => validateTelegram(e)} /> : <span>{accountInfo.telegram_id}</span>}
                {errorState.telegram_id && <ErrorContainer>{errorState.telegram_id}</ErrorContainer>}
              </NameDate>
            </ProfileName>

            <ProfileName className={brandId === 46 || brandId === 47 ? "profileNameAlign" : ""}>
              {/* <SocialSvgIcon>
                {showAccountInfoIcons ? <Instagramf88/> : <Instagram />}            
              </SocialSvgIcon> */}
              {showAccountInfoIcons ? <SocialSvgIcon><Instagramf88/></SocialSvgIcon> : <SvgIcon><Instagram /></SvgIcon>}
              <NameDate className={brandId === 46 || brandId === 47 ? "nameDataAlign" : ""}>
                <p><Intl langKey="TITLE@instagram" /></p>
                {isEdit ? <input placeholder="-" type="text" name='instagram_id' className={errorState.instagram_id && 'error'} value={accountInfo.instagram_id} onChange={(e) => onChangeHandler(e)} /> : <span>{accountInfo.instagram_id}</span>}
                {errorState.instagram_id && <ErrorContainer>{errorState.instagram_id}</ErrorContainer>}
              </NameDate>
            </ProfileName>

            <ProfileName className={brandId === 46 || brandId === 47 ? "profileNameAlign" : ""}>
              {/* <SocialSvgIcon>
                {showAccountInfoIcons ? <WhatsappIconf88 /> : <WhatsappIcon />}
              </SocialSvgIcon> */}
              {showAccountInfoIcons ? <SocialSvgIcon><WhatsappIconf88/></SocialSvgIcon> : <SvgIcon><WhatsappIcon /></SvgIcon>}
              <NameDate className={brandId === 46 || brandId === 47 ? "nameDataAlign" : ""}>
                <p><Intl langKey="TITLE@WhatsApp" /></p>
                {isEdit ? <input placeholder="-" type="text" name='whatsapp_number' className={errorState.whatsapp_number && 'error'} value={accountInfo.whatsapp_number} onFocus={() => focusHandle('whatsapp_number')} onChange={(e) => onChangeHandler(e, 'number')} onBlur={(e) => validateWhatsapp(e)} /> : <span>{accountInfo.whatsapp_number}</span>}
                {errorState.whatsapp_number && <ErrorContainer>{errorState.whatsapp_number}</ErrorContainer>}
              </NameDate>
            </ProfileName>
          </BlockCol>
          <BottomRow>
           {showReferral && (<LongCol>
              <CodeLink>
                <ProfileName>
                  <SubTittle><Intl langKey="TITLE@Referral" /></SubTittle>
                  <SvgIcon>
                    {showAccountInfoIcons ? <CodeLinkIconf88/> : <CodeLinkIcon />}
                  </SvgIcon>
                  <NameDate>
                    <p className="DiffColor"><Intl langKey="TITLE@Code" /></p>
                    <CodeNumber>{accountInfo.referralCode}
                      <Copy onClick={() => copyClipboard(accountInfo.referralCode)}>
                        {showAccountInfoIcons ? <CopyIconf88/> : <CopyIcon />}
                      </Copy>
                    </CodeNumber>
                  </NameDate>
                </ProfileName>

                {showreffralLink && <ProfileName>
                  <><SvgIcon>
                   {brandId==46|| brandId==47?<WebIconf88/>: <WebIcon />}
                  </SvgIcon><NameDate>
                      <p className="DiffColor"><Intl langKey="TITLE@Link" /></p>
                      <ProfileUrl>{refUrl} <Copy onClick={() => copyClipboard(refUrl)}>
                        {showAccountInfoIcons ? <CopyIconf88/> : <CopyIcon />}
                        </Copy>
                      </ProfileUrl>
                    </NameDate></>
                </ProfileName>}
              </CodeLink>

              {showreffralLink && <QRcodeWrapper>
                <QRCode value={refUrl} size={118} />
                <QRCodeText><Intl langKey="scanQRCode" /></QRCodeText>
              </QRcodeWrapper>}
            </LongCol>)}

            {/* <BlockCol>

              <ProfileName>
                <SubTittle><Intl langKey="TITLE@CompleteProfile" /></SubTittle>
                <ProfileBar></ProfileBar>
              </ProfileName>

              <ProfileName>
                <SubTittle><Intl langKey="TITLE@PendingItems" /></SubTittle>
                <HistoryData>
                  <AdditionLInk><Intl langKey="TITLE@SetWithdrawalPassword" /></AdditionLInk>
                  <AdditionLInk><Intl langKey="TITLE@CompleteKYC" /></AdditionLInk>
                  <AdditionLInk><Intl langKey="TITLE@AddBankDetails" /></AdditionLInk>
                  <AdditionLInk><Intl langKey="TITLE@AddAppDetails" /></AdditionLInk>
                </HistoryData>
              </ProfileName>
            </BlockCol> */}
          </BottomRow>
        </AccountInfoWrapper>
      </BodyWrapper>
    </MainInfoWrapper>


  )
}
//no need to wrap 
//export default withAppContext(AccountInfo);
export default withTheme(withAppContext(AccountInfo))

const SocialSvgIcon = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  margin-right: 10px;

  svg {
    width: 27px;
    height: 27px;
  }
`;

const ContactWrap: any = styled.div`
display: flex;
padding:9px;
line-height: 14px;
align-items: center;

& .readonly-input {
        border: none;
        outline: none;
        background: transparent;
        color: white;
        min-width: 25px;
        width: auto;
        max-width: 38px;
        padding: 0px;
        margin-top: 0px;
    }

    & .readonly-input-active {
        border: none;
        outline: none;
        background: transparent;
        color: white;
        min-width: 25px;
        width: auto;
        max-width: 38px;
        padding: 0px;
        margin-top: 3px;
        background: linear-gradient(126.22deg,rgba(0,0,0,0.5) 0%,rgba(0,0,0,0.25) 100%);
        border-radius: 5px;
        padding: 7px;
        margin-right: 3px;
    }
`
const EmailWrap = styled(ContactWrap)`
gap:8px;
`

const BodyWrapper = styled(BodyWrapperAccountInfo)`
  width: 100%;
  background: no-repeat;
  box-shadow: none;
  padding: 0;
`;

const AdditionLInk = styled.a`
    color: #2E2BBB;
    letter-spacing: 0.03em;
    text-transform: capitalize;
    font-size: 10px;
    background: #e5e5e5;
    border-radius: 3px;
    padding: 5px;
    margin: 2px 4px 2px 0px;
`;
const HistoryData = styled.div`
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    flex-wrap: wrap;

`;

const ProfileBar = styled.div`

`;

const QRcodeWrapper = styled.div`
    width: 30%;
    height: 190px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;

    p {
        color: #9C96B2;
        font-size: 9px;
        letter-spacing: 0.01em;
    }
`;

const CodeLink = styled.div`
    width: 70%;
    display: flex;
    flex-direction: column;
    align-items: center;
`;

const ProfileUrl = styled.span`
    color: white;
    font-weight: 350;
    font-size: 14px;
    letter-spacing: 0.01em;
`;

const CodeNumber = styled.div`
     color:${theme('palette.nametext')};
    font-size: 14px;
    letter-spacing: 0.01em;
`;

const LongCol = styled.div`
    width: 75%;
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: space-between;
    /* background: linear-gradient(126.22deg, rgba(0, 0, 0, 0.3) 0%, rgba(0, 0, 0, 0.15) 100%); */
    background: ${theme('palette.UdbInfo_General_TabBG')};
    /* box-shadow: 0px 0px 40px rgba(14, 9, 67, 0.44); */
    border-radius: 5px;
    backdrop-filter: blur(1px);
`;

const BottomRow = styled.div`
    width: 100%;
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    padding-top: 2em;
`;

const SubTittle = styled.div`
    width: 100%;
    color:${theme('palette.profilecardTexts')};
    font-size: 14px;
    letter-spacing: 0.01em;
    text-transform: capitalize;
    font-weight: 500;
    padding-bottom: 8px;
`;

const VerifiedSecRight = styled.div`
    display: flex;
    gap: 5px;
    align-items: center;
    position: absolute;
    right: 20px;

    p.verifiedText {
        color: #00C137;
    }
`

const PhoneNumber = styled.div`
  color:${theme('palette.nametext')};
  letter-spacing: 0.01em;
  font-weight: 500;
  font-size: 14px;
  display: flex;

    .countryCode {
      color: #8D8D8D!important;
      margin-right: 5px;
      font-size: 14px;
    }
`;

const NameDate = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;

  &.nameDataAlign {
    p {
      font-size: 11px;
      color: #8D8D8D;
    }
  }
    p{
        margin: 0;
        color:${theme('palette.nametext')};
        letter-spacing: 0.01em;
        font-weight: 500;
        font-size: 14px;
    }
    input.error {
      border:1px solid red;
    }

    span {      
      color:${theme('palette.nametext')}!important;
      letter-spacing: 0.01em;
      font-weight: 500;
      font-size: 12px;
    }

    .emailinput{
      width: 300px!important;
    }
    .phoneNumberInp {
      width: 280px!important;
    }


    .react-datepicker__day--keyboard-selected {
      background: ${theme('palette.secondary-background-color-linear-new1')};
    }
    .react-datepicker__day--selected {
      background: ${theme('palette.secondary-background-color-linear-new1')};
    }

`;

const IconWrap = styled.div`

`;

const ProfileName = styled.div`
    width: 100%;
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    padding: 1em;
    border-bottom: ${theme('palette.borderbottom-bg')};
   
    &.profileNameAlign {
      padding: 10px 1em;
    }

    span {
      color:#ffff;
      font-size: 12px;
      font-weight: 500;
      letter-spacing: 0.03em;
    }

    :last-child {
        border: none;
    }

    .DiffColor {
      color:${theme('palette.profilecardTexts')};
    }

    input {
      /* background: linear-gradient(126.22deg, rgba(0, 0, 0, 0.5) 0%, rgba(0, 0, 0, 0.25) 100%); */
      background: ${theme('palette.AccountInfoProfileNameEditBg')};
      /* box-shadow: 0px 0px 40px rgba(14, 9, 67, 0.44); */
      border-radius: 5px;
      padding: 7px;
      border: none;
      /* color: rgba(255, 255, 255, 0.7); */
      color: ${theme('palette.AccountInfoProfileNameEditTextColor')};
      letter-spacing: 0.01em;
      // text-transform: capitalize;
      font-weight: 500;
      font-size: 12px;
      margin-top: 5px;
      width: 100%;

      ::-webkit-input-placeholder {
        font-size: 10px;
    }
    }
`;

const CentreCol = styled.div`
    width: 49%;
    /* min-height: 179px; */
    min-height: 235px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    /* background: linear-gradient(126.22deg, rgba(0, 0, 0, 0.3) 0%, rgba(0, 0, 0, 0.15) 100%); */
    background: ${theme('palette.UdbInfo_TabBG')};
    /* box-shadow: 0px 0px 40px rgba(14, 9, 67, 0.44); */
    border-radius: 5px;
    backdrop-filter: blur(1px);


`;

const BlockCol = styled.div`
    width: 23%;
    /* min-height: 185px; */
    min-height: 235px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    /* background: linear-gradient(126.22deg, rgba(0, 0, 0, 0.3) 0%, rgba(0, 0, 0, 0.15) 100%); */
    background: ${theme('palette.UdbInfo_General_TabBG')};
    /* box-shadow: 0px 0px 40px rgba(14, 9, 67, 0.44); */
    border-radius: 5px;
    backdrop-filter: blur(1px);
    &.posR {
      position:relative;
      z-index:1
    }

`;

const AccountInfoWrapper = styled.div`
    width: 100%;
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    flex-wrap: wrap;
`;

const ErrorContainer = styled.span`
    display:block;
    /* color:#FF9FB0!important; */
    color: red!important;
    font-size: .8rem;
`
const QRCodeText = styled.span`
color: ${theme('palette.nametext')};
font-size: 11px;
padding-top: 5px;
`
const QRCodeBorder = styled.span`
padding:2px;
border:1px solid #fff;
border-radius:3px;
`
export const Copy = styled.span`
    display: inline-block;
    padding: 0px 5px;
    cursor: pointer;
    position:relative;
    top: 0px;
`
