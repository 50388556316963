import React, { useState, useEffect } from "react";
import styled, { withTheme } from "styled-components";
import Navbar from "../../components/udb/Navbar";
import { Outlet, useLocation } from "react-router-dom";
import config from "src/config/config";
import { AccountInfoProps } from './profile/AccountInfo'
import { getAccountInfoData } from "src/config/api";
import QuickLinks from '../../components/udb/QuickLinks'
import ProBonus from '../../components/udb/ProBonus';
import { theme } from "styled-tools";
import WhatsappIco from "src/components/WhatsappIco";
import { withAppContext } from "src/store/initAppContext";

type ContextProps = {
  userInfo: AccountInfoProps;
  setUserInfo: React.Dispatch<React.SetStateAction<AccountInfoProps>>;
}

const quickLinks = [
  {
    title: "QuickLink@Inplay",
    imageName: 'infinite',
    count: '',
    url: '/betting-exchange'
  },
  {
    title: 'QuickLink@livecasino',
    imageName: 'liveCasino',
    count: '',
    url: '/live-casino'
  },
  {
    title: 'QuickLink@History',
    imageName: 'History',
    count: '',
    url: '/member/finance/history'
  },
  {
    title: "QuickLink@Message",
    imageName: 'Message',
    count: '',
    url: '/member/message',
    authKey:'showDBMessageLink'
  }
]

export const UserContext = React.createContext({} as ContextProps)

function Dashboard(props) {
  const { theme, app: { brandId } } = props;
  const { config: { showDBBonusBox = true } } = theme;

  const [userInfo, setUserInfo] = useState({} as AccountInfoProps);
  const [showLoader, setShowLoader] = useState(true);

  const { pathname } = useLocation();
  let currPage = pathname.split("/").pop();

  useEffect(() => {
    const getData = getAccountInfoData();
    getData.then(response => {
      const { data: { data } } = response;
      setUserInfo(data);
      setShowLoader(false);
    }).catch(err => console.log('error', err.message))
  }, []);


  return (
    // <MainWrapper className="contxx">
    <MainWrapper className = {(currPage === "deposit" || currPage === "withdrawal") || currPage === "finance" ? "despositBg" : "contxx"}>
      <Navbar />
      <SectionWrapper>
        <UserContext.Provider value={{ userInfo, setUserInfo }}>
          <Container>
            {showLoader ? <div>Loading...</div> : <Outlet />}
          </Container>

          <AsideBar>
            <QuickLinks heading="Quicklink@Top" data={quickLinks} />
            {showDBBonusBox && <ProBonus />}
          </AsideBar>
          
        </UserContext.Provider>
        {/* whatsApp Icon Support  */}
        {brandId === 32 || brandId === 40 && (currPage === "accountinfo" || currPage === "deposit" || currPage === "withdrawal" || currPage === "profile" || currPage === "finance" ) && <WhatsappIco rightSpace="20px"/> }
      </SectionWrapper>
    </MainWrapper>
  );
}

export default withTheme(withAppContext(Dashboard))

const Container = styled.div`
    flex: 1;
    display: flex;
    align-items: flex-start;
    background: ${theme('palette.profileCardContainerBG')};
    border: ${theme('palette.profileBgBorder')};
    /* background: radial-gradient(100% 178.67% at 0% 0.96%, rgba(63, 59, 106, 0.8) 0%, rgba(6, 0, 68, 0.8) 100%); */
    /* box-shadow: 0px 0px 10px rgba(16, 12, 71, 0.4); */
    border-radius: 10px;
    /* border: 1px solid #8a9ec5f7; */
    overflow-y: auto;
    padding: 1.5rem;
    backdrop-filter: contrast(0.5);
    /* backdrop-filter: ${theme('palette.profileBgBlur')}; */

::-webkit-scrollbar {
    width: 8px;
}
::-webkit-scrollbar-thumb {
    border-radius: 5px;
    background: #9b9b9b;
    -webkit-box-shadow: inset 0 0 6px rgb(0 0 0 / 50%);
}

::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 6px rgb(0 0 0 / 30%);
    border-radius: 5px;
}
`;

export const MainWrapper = styled.div`
    width: 100%;
    /* height: calc(100vh - 70px); */
    height: calc(100vh - 100px);
    display: flex;
    background: #fff;
    flex-direction:row;
     background-color: ${theme('palette.headerBgColor')};
    background-image: url(${config.imageV3Host}/static/cashsite/${theme('image.dashboardBg')});
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;

    &.despositBg {
      background-image: url(${config.imageV3Host}/static/cashsite/${theme('image.dashboardDepositBg')});

      ${Container} {
        /* background:  transparent;
        backdrop-filter: contrast(0.9);
        box-shadow: 0px 0px 10.801px 0px rgba(16, 12, 71, 0.40);
        border: 1.08px solid #8a9ec521; */
        background:  ${theme('palette.despositBgContainerBg')};
        backdrop-filter: ${theme('palette.despositBgContainerBackdropFilter')};
        box-shadow: ${theme('palette.despositBgContainerBoxShadow')};
        border: ${theme('palette.despositBgContainerBorder')};

        button[disabled]:hover {
          background: #3a454c !important;
        }

        .classic .classic {
          border-radius: 17.959px;
        }

        .showAnimation .showAnimation {
          border-radius: 17.959px;
        }

        #couponApplyBtn[disabled]:hover {
          background: #d0d0d0 !important;
          cursor: not-allowed;
        }

        /* >.showAnimation {
          background: url(${config.imageV3NonOptHost}/static/cashsite${theme('image.DepositRadioActiveBgBorderYolo')});
          background-size: 125%;
          background-repeat: no-repeat;
          background-position: center;
        } */

        .staticEnhance {
          label {
            background: ${theme('palette.depositInputBG')};
            border: ${theme('palette.depositInputBorder')};
            box-shadow: ${theme('palette.depositInputBoxShadow')};
            border-radius: ${theme('palette.depositInputBorderRadius')};
            color: ${theme('palette.depositInputPlaceholderLabelColor')};
            /* margin: 0; */

            input {
              color: ${theme('palette.depositInputPlaceholderColor')};
            }
          }
        }

        .error.staticEnhance {
          label {
            border: 1px solid ${theme('palette.ErrorMsgColor')};
            background: ${theme('palette.DepositLabelTextColor')};
          }
        }
        
      }

      .depoUploadErrorMsg {
          position: absolute;
          bottom: 1px;
          color: #FF0000;
      }

      .showAnimation {
        overflow: visible;
        border: #FFFFFF;
        /* background-size:100%; */
        /* border-radius: 10px; */
        /* background: linear-gradient(180deg, #62696B 0%, rgba(98, 105, 107, 0.00) 100%); */
        outline: none;
        display: flex;
        justify-content: center;
        border-radius: 25px;

        ::before {
          background: url(${config.imageV3NonOptHost}/static/cashsite/${theme('image.topChoiceNew')});
          background-size: contain;
          background-position: left;
          background-repeat: no-repeat;
          left: unset;
          top: -20%;
          width: 100px;
          height: 35px;
          transform: none;
        }
      }

      .deposit_crypto {
        overflow: visible;
        display: flex;
        justify-content: center;
        outline: none;

        ::before {
          background: url(${config.imageV3NonOptHost}/static/cashsite/${theme('image.CryplimitedOffer')});
          background-size: contain;
          background-position: left;
          background-repeat: no-repeat;
          left: unset;
          top: -20%;
          width: 100px;
          height: 35px;
          transform: none;
        }
      }
    }
`;


const AsideBar = styled.div`
  width: 20%;
  margin-left: 20px;
`;

const SectionWrapper = styled.div`
  width: 85%;
  display: flex;
  flex-direction: row;
  padding: 1.5em;
//   background: linear-gradient(
//       0deg,
//       rgba(17, 12, 86, 0.7),
//       rgba(17, 12, 86, 0.7)
//     ),
//     url(login_background.jpg);
//   box-shadow: 0px 0px 100px rgba(0, 7, 66, 0.77);
`;
