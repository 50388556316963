import { PureComponent } from 'react';

interface IOpenableProps {
	initialOpen: boolean;
	children: (delegation: IOpenableDelegation) => React.ReactNode;
}

interface IOpenableState {
	isOpened: boolean;
}

export interface IOpenableDelegation {
	isOpened: boolean;
	toggleOpen: () => void;
}

export default class Openable extends PureComponent<IOpenableProps, IOpenableState> {
	static defaultProps = {
		initialOpen: false,
	};
	state = {
		isOpened: this.props.initialOpen,
	};

	toggleOpen = () => {
		this.setState(prevState => ({
			isOpened: !prevState.isOpened,
		}));
	};

	render() {
		const { children } = this.props;
		const { isOpened } = this.state;
		const delegation: IOpenableDelegation = {
			isOpened,
			toggleOpen: this.toggleOpen,
		};

		if (typeof children !== 'function') {
			console.error('[Openable]: the children prop is expected to be a function!');
			return null;
		}

		return children(delegation);
	}
}
