// import { darken, lighten } from 'polished';
import styled, { css } from 'styled-components';
import { ifProp, theme, withProp } from 'styled-tools';

// Components
import { IconWrapper } from '../../components/common/PNGIcon';

// Styles
import { panelStyle, scrollbarStyle, shadowMD, shadowSM, titleFont } from '../../components/common/commonCSSStyles';

// Utils
import { diffColor } from '../../util/colorUtil';
import media from '../../util/mediaUtil';
import { isMemberType } from '../../util/styleUtil';

export const PeriodButtonWrapper: any = styled.button`
	font-size: 12px;
	display: flex;
	justify-content: center;
	align-items: center;
	width: 2em;
	height: 2em;
	cursor: pointer;
	border-radius: 2px;
	transition: all 0.2s ease;
	margin-bottom: 1em;

	&:focus {
		outline: 0;
	}

	&:not(:last-of-type) {
		margin-right: 1em;
	}

	${ifProp(
		'isActive',
		css`
			background-color: ${theme('palette.primary-background')};
			color: #fff;
			border: 0;
			transform: translateY(-1px);
			${shadowSM};

			&:hover {
				${shadowMD};
			}
		`,
		css`
			background-color: transparent;
			color: hsl(0, 0%, 50%);
			border: 1px solid hsl(0, 0%, 88%);

			&:hover {
				color: hsl(0, 0%, 20%);
				border-color: ${theme('palette.primary-background')};
			}
		`,
	)};
	background-color: 0;
`;

export const PeriodButtonContainer = styled.div`
	display: flex;
	align-items: center;
	flex-wrap: wrap;
`;

export const SectionTitle: any = styled.h2`
	${titleFont};
	text-transform: uppercase;
	font-size: 12px;
	margin-top: 0;
	margin-bottom: 1em;
	${media.md`
	font-size: 13px;
	margin-bottom: .5em;
	`};
`;

// background-color changed by ambu
export const SearchButtonWrapper = styled.button`
	display: flex;
	align-items: center;
	background-image: ${theme('palette.primary-gradient-background')};
	border: 0;
	border-radius: 2px;
	padding: 0.5em 1em;
	color: ${theme('palette.searchfontMybet')};
	font-size: 12px;
	cursor: pointer;
	transition: all 0.15s ease-out;
	// background-color: #5D2263;
	background-color: rgb(201, 157, 108);

	${IconWrapper} {
		margin-right: 0.5em;
	}

	&:focus {
		outline: 0;
	}

	&:hover {
		text-shadow: 0px 0px 5px rgba(0, 0, 0, 0.5);
		box-shadow: 0 0 5px rgba(0, 0, 0, 0.2);
		background: ${theme('palette.red-gradient-background-reverse')};
	}
	${media.md`
		padding: 0.75em 1em;
	`};
`;

// TODO: change it to `date` later
export const DatePickerInput = styled.input.attrs(props => ({ type: 'text', placeholder: 'dd/mm/yyyy' }))`
	background-color: #fff;
	border: 0;
	border-radius: 2px;
	padding: 0.5em 1em;
	font-size: 12px;
	width: 128px;
	box-shadow: inset 0 0 4px rgba(0, 0, 0, 0.25);
`;

export const DatePickerTitle = styled.span`
	font-size: 11px;
	margin-bottom: 0.25em;
	color: ${withProp('theme.palette.primary-background', diffColor(0.023))};
`;

export const DatePickerFieldWrapper = styled.div`
	display: flex;
	flex-direction: column;
	font-size: 12px;
	position: relative;
	width: 130px;
	max-width: calc(50% - 1em - 43px);

	${media.sm`
		max-width: calc(50% - 0.5em - 43px);
	`};
`;

export const DatePickerContainer = styled.section`
	display: flex;
	flex-wrap: wrap;
	align-items: flex-end;
	position: relative;
	z-index: 5;
	font-size: 0;

	> *:not(:last-child) {
		margin-right: 1em;
	}


	${media.sm`
		> *:not(:last-child) {
			margin-right: .5em;
		}
	`}

	/* DayPicker style customization */
	.DayPickerInput {
		input {
			color: ${theme('palette.body-foreground')};
			border: 0;
			border-radius: 2px;
			padding: 0.5em 1em;
			font-size: 12px;
			width: 100%;
			outline: none;

			&:focus {
				/* outline: 1px solid ${theme('palette.primary-background')}; */
				box-shadow: none;
			}
			${media.md`
			padding: 0.75em 1em;
					`};

			${isMemberType(
				'CLIENT',
				css`
					background-color: green;
					box-shadow: inset 0 0 4px rgba(0, 0, 0, 0.35);
					${media.md`
						box-shadow: inset 0 0 4px rgba(0, 0, 0, 0);
						background: rgba(255,255,255,.8);
					`};
				`,
				css`
					background-color: green;
					box-shadow: inset 0 0 4px rgba(0, 0, 0, 0.25);
				`,
			)};

		}

		.DayPicker {
			background-color: ${theme('palette.panel-background')};
		}

		.DayPicker-wrapper {
			background-color: ${theme('palette.panel-background')};
			font-size: 12px;
			border-radius: 4px;
			overflow: hidden;
		}

		.DayPicker-Day {
			&--today {
				color: ${theme('palette.primary-background')};
			}

			&--selected {
				background-color: ${theme('palette.primary-background')};
				color: ${theme('palette.primary-foreground')};
			}

			&:hover {
				background: none;
				border-color: ${theme('palette.primary-background')};
			}
		}

		.DayPickerInput-Overlay {
			${shadowMD};
			top: 0.5em;
			background-color: ${theme('palette.panel-background')};
			border-radius: 4px;
			overflow: hidden;
		}

		.DayPicker:not(.DayPicker--interactionDisabled)
			.DayPicker-Day:not(.DayPicker-Day--disabled):not(.DayPicker-Day--selected):not(.DayPicker-Day--outside):hover {
			color: black;
		}
	}
`;

export const TypeSelectionSeparator = styled.div`
	/* width: 1px;
	margin: 0 1em; */
	width: 80%;
	margin: 0 auto;
	background-color: ${withProp('theme.palette.body-background', diffColor(0.1))};
		${media.md`
			background-color: rgba(0,0,0,.2);
			/* margin: 0 .5em; */
		`};
`;

export const TypeSectionTitle: any = styled.div`
	/* display: flex;
    align-items: center;
    justify-content: center;
	${titleFont};
	font-size: 13px;
	font-weight: bold;
	margin-bottom: 10px;
	background: ${theme('palette.new-whitelable-member-background')};
	padding: 6px 38px;
	border-top-left-radius: 5px; */

    display: flex;
    align-items: center;
    justify-content: center;
    border-top-left-radius: 5px;
    color: #505050;
    letter-spacing: 0.03em;
    text-transform: capitalize;
    font-weight: 600;
    font-size: 13px;
    border: 1px solid #FFFFFF;
	padding: 4px 8px;
    background: radial-gradient(50% 50% at 50% 50%, rgba(255, 255, 255, 0.56) 0%, rgba(255, 255, 255, 0.56) 100%);
    box-shadow: 0px 0px 5px rgb(19 14 74 / 40%);
    border-radius: 5px;
    margin: 10px;
	z-index: 9;
`;

export const TypeButtonAmountWrapper = styled.div`
	font-size: 13px;
	margin-top: 0.25em;
`;

export const TypeButtonWrapper: any = styled.div`
	font-size: 13px;
	color:#44556F;
	padding: 15px 7px;
	background-color: transparent;
	border: 0;
	position: relative;
	z-index: 0;
	text-transform: capitalize;
	cursor: pointer;
	display: flex;
    flex-direction: row;
    width: 100%;
    justify-content: space-between;
	align-items: center;
	color: ${theme('palette.primary-background-color-new')};
	border-bottom: 1px solid #ddd;

	:last-child {
		border-bottom: none;
	}

	> span {
		white-space: nowrap;
		color: ${theme('palette.secondary-background-color-new')};
	}
	&:focus {
		outline: 0;
	}

	// &:not(:last-of-type) {
	// 	margin-right: 1.25em;
	// }

	&::before {
		content: '';
		display: block;
		position: absolute;
		right: -4px;
		left: -4px;
		bottom: -4px;
		top: -4px;
		border-radius: 2px;
		z-index: -1;
	}

	${ifProp(
		'isSelected',
		css`
			${isMemberType(
				'CLIENT',
				css`
					/* color: ${theme('palette.text-dark')}; */
					/* background: ${theme('palette.base-table-head-row')}; */
					background: ${theme('palette.base-table-head-row')};
					font-weight: 600;

					:after {
						content: '';
    					display: block;
						position: absolute;
						right: -13px;
						border-left: 8px solid transparent;
						border-right: 8px solid transparent;
						border-top: 8px solid #ffffff;
						border-top: ${theme('palette.TypeButtonWrapperPandLselectedTabAfter')};
						transform: rotate(270deg);
					}
					
					>span{
						color: ${theme('palette.primary-background-color-new')};
					}
					> div span {
						font-weight: 500;
						
					}
				`,
				css`
					/* color: ${theme('palette.primary-background')}; */
					font-weight:600;
					// color:#203974;
					background-color:#E9F1F8;
				`,
			)};
		`,
		css`
			color: ${theme('palette.body-foreground')};
			opacity: 0.8;
			transition: opacity 0.15s ease;

			&::before {
				opacity: 0;
			}

			&:hover {
				opacity: 1;
				background: #fff;
			}
		`,
	)};

	${media.lg`
		flex-shrink: 0;
	`};
	${media.md`
	&:not(:last-of-type) {
		margin-right: 0;
	}
	&::before{
		display: none;
	}
	`};
`;

export const TypeButtonContainer = styled.div`
	width: 100%;
	display: flex;
	align-items: start;
    justify-content: space-evenly;
	flex-direction: column;
	height: 100%;
	padding-top: 2px;

	${media.lg`
		${scrollbarStyle};
		overflow-x: auto;
		overflow-y: hidden;
	`};
`;

// Wrapping Section title and main content
export const SectionWrapper = styled.section`
	display: flex;
	flex-direction: column;
	height:100%;

	&:not(:last-of-type) {
		margin-bottom: 1em;
	}

	.gamesHeader {
		height: 43px;
		display: flex;
    	align-items: center;
		justify-content: center;
		border-radius: 5px;
    	color: #505050;
		letter-spacing: 0.03em;
		text-transform: capitalize;
		font-weight: 600;
		font-size: 13px;
		background: radial-gradient(50% 50% at 50% 50%,rgba(255,255,255,0.56) 0%,rgba(255,255,255,0.56) 100%);
		border: 1px solid #FFFFFF;
		box-shadow: 0px 0px 50px rgb(19 14 74 / 41%);
		padding: 0.5em;
		z-index: 9;
	}
`;

export const DatePickerWrapper = styled.div`
	display: flex;
	flex-direction: column;
`;

export const CategoryPickerTotal: any = styled.div`
	${titleFont};
	flex-basis: 50%;
	font-weight: bold;
	font-size: 32px;
	margin-bottom: 0.25em;

	${(props: any) => {
		const { children } = props;
		if (typeof children !== 'number') {
			console.error('[CategoryPickTotal]: expects the children is number');
			return '';
		}

		if (children === 0) {
			return '';
		} else if (children > 0) {
			return css`
				color: ${theme('palette.profit')};

				&::before {
					content: '+';
				}
			`;
		} else {
			return css`
				color: ${theme('palette.loss')};
			`;
		}
	}};
`;

export const CategoryPickerList = styled.div`
	flex-basis: 50%;
	display: flex;
	flex-wrap: wrap;
	padding: 0.5em 0;
`;

export const CategoryPickerItem: any = styled.div`
	display: flex;
	align-items: center;
	cursor: pointer;
	font-size: 13px;
	border-radius: 2px;
	border: 1px solid hsl(0, 0%, 80%);
	padding: 0.5em 0.5em;
	margin-bottom: 1em;

	${ifProp(
		'isActive',
		css`
			opacity: 1;
			border-color: transparent;
			box-shadow: 0 0 0 2px ${theme('palette.primary-background')};
		`,
		css`
			opacity: 0.5;
		`,
	)};

	&:not(:last-of-type) {
		margin-right: 1.5em;
	}

	${IconWrapper} {
		margin-right: 1em;
	}
`;

// Wrapping Total & List
export const CategoryPickerContent = styled.div``;

export const PanelWrapper = styled.div`
	${panelStyle};
	${shadowSM};
	display: flex;
	flex-direction: column;
	padding: 1.5em 2em;
	flex-grow: 1;
	width: 50%;

	&:not(:first-child) {
		margin-left: 2em;
	}
`;

export const FilterSection = styled.section`
	margin-bottom: 0.5em;
`;
