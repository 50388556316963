import React, { useState, useEffect } from "react";
import { GET_EVENTS } from "../../graphql/events";
import { useQuery } from "@apollo/client";
import styled, { css } from "styled-components";
import { bodyFont } from "../common/commonCSSStyles";
import { ifProp, switchProp, theme } from "styled-tools";
import media from "../../util/mediaUtil";
import util from "../../util/util";
import SubscribedMarketList from "../subscription/SubscribedMarketList";
import { IEventInfo } from "../../graphql/schema";
import EventTypeConfig from "../../config/eventTypeConfig";
// Component List
import EventTable from "./table/feature/EventTable";
import MatchTable from "./table/feature/MatchTable";
import { useParams, useLocation } from "react-router-dom";
import { expr } from "jquery";
import axios from "axios";
import { withAppContext } from "../../store/initAppContext";
interface MarketListProps {
  isFavorite?: boolean;

  // 是否秀出球種 icon
}
function MarketList(props: any) {
  const [eventData, setEventData] = useState([]);
  const [gameData, setGameData] = useState([]);
  const [liveTvData, setLiveTvData]: any = useState([]);
  const [catId, setCatId] = useState(0);
  // const { loading, error, data } = useQuery(GET_EVENTS, {
  // 	variables: { input: props.isFavorite ? { categoryId: 0, isFavorite: true } : { categoryId: 0, isInplay: true } },
  // 	//pollInterval: 5000,
  // });
  let location = useLocation();
  let { pathname } = location;

  useEffect(() => {
    (async () => {
      let liveApi = await axios
        .get(
          "https://sfhlyrbh7a.execute-api.eu-west-1.amazonaws.com/default/job-livetv"
        )
        .then((response) => {
          localStorage.setItem("liveTv", JSON.stringify(response.data));
        })
        .catch((error) => {
          console.error("Error");
        });
    })();
  }, []);

  useEffect(() => {
    if (pathname.includes("/betting-exchange/volleyball-betting")) {
      setCatId(12);
    } else if (pathname.includes("/betting-exchange/online-cricket-satta-bazar")) {
      setCatId(4);
    } 
    else if (pathname.includes("/betting-exchange/online-cricket-betting")) {
      setCatId(4);
    } 
    else if (pathname.includes("/betting-exchange/tennis-betting")) {
      setCatId(2);
    } else if (pathname.includes("/betting-exchange/football-betting")) {
      setCatId(1);
    } else if (pathname.includes("/specialMarket")) {
      setCatId(100);
    } else {
      setCatId(0);
    }
  }, [pathname]);

  const inputProps: any = {
    categoryId: catId,
    isInplay: true,
    // isFavorite: props.isFavorite,
    singleFancy: true,
    // eventId: !(sport && competitionId && eventId) && fancyQuery ? fancyQuery.eventId : eventId,
  };
  const categoryId = catId;
  const eventTypeConfig = EventTypeConfig(categoryId);
  const returnMax = (arr) => {
    return arr.sort((a, b) => b.price - a.price)[0];
  };
  return (
    <MarketListContainer>
      {/* <EventTable dataList={eventData} isFavorite={props.isFavorite} /> */}
      <SubscribedMarketList
        query={GET_EVENTS}
        variables={{ input: inputProps }}
        option={{ isLive: false }}
      >
        {({
          loading,
          error,
          data,
          subscribeToMore,
          variables,
          updateQuery,
        }: any) => {
          // typeof callback === 'function' && callback(data);

          if (error) {
            console.error(error);
          }

          // List of eventInfo, not categorized
          const eventInfoList: IEventInfo[] = util.getValue(
            data,
            ["markets", "list"],
            []
          );
          const eventInfoListBySport = eventInfoList.reduce(
            (pv: any, item: IEventInfo) => {
              const keyName = item.categoryId || 0 || 100;
              !pv[keyName] && (pv[keyName] = []);
              pv[keyName].push(item);
              return pv;
            },
            {}
          );
          const sortedEvents = eventTypeConfig.categoryIds.reduce(
            (res: any[], n) => {
              return [...res, ...(eventInfoListBySport[n] || [])];
            },
            []
          );


          const cricketData =
            (pathname.includes(
              "/betting-exchange/online-cricket-satta-bazar"
            ) &&
              sortedEvents?.filter((val) => {
                return val.categoryId == 4;
              })) ||
              (pathname.includes(
                "/betting-exchange/online-cricket-betting"
              ) &&
                sortedEvents?.filter((val) => {
                  return val.categoryId == 4;
                })) ||   
            (pathname.includes("/betting-exchange/tennis-betting") &&
              sortedEvents?.filter((val) => {
                return val.categoryId == 2;
              })) ||
            (pathname.includes("/betting-exchange/football-betting") &&
              sortedEvents?.filter((val) => {
                return val.categoryId == 1;
              })) ||
            (pathname.includes("/betting-exchange/volleyball-betting") &&
              sortedEvents?.filter((val) => {
                return val.categoryId == 12;
              })) ||  
            (pathname.includes("/specialMarket") &&
              sortedEvents?.filter((val) => {
                return val.categoryId == 100;
              })) ||
            (pathname.includes("sports/:5") && sortedEvents) || 
            sortedEvents 

           

          const customProps = {
            loading,
            error,
            isShowSport: true,
            // list of eventInfo only for the sport
            // Code Changed - Manoj ( isSort : true ) for sorting events
            // dataList: sortedEvents,
            dataList: cricketData,
            // tennis: sortedTennis,
            isSort: false,
            liveTvData: liveTvData,
            app: props,
            homePage: true,
          };

          const TableComponent = (
            {
              EventTable,
              MatchTable,
            } as any
          )[eventTypeConfig.eventTypeGroup];

          if (customProps.dataList.length === 0 && categoryId === 0) {
            return null;
          } else {
            return <TableComponent {...customProps} />;
          }
        }}
      </SubscribedMarketList>
    </MarketListContainer>
  );
}

export default withAppContext(MarketList);

export const MarketListContainer = styled.div``;

const Cell: any = styled.div`
  display: flex;
`;

export const TitleCell: any = styled(Cell)`
  padding: 0.6em 0;
  letter-spacing: 0.66px;
`;

export const TeamTitleCell: any = styled(TitleCell)`
  width: ${ifProp("isLong", "70%", "32%")};
  padding-left: 4em;
`;

export const StateTitleCell: any = styled(TitleCell)`
  width: ${ifProp("isLong", "30%", "16%")};
  justify-content: center;
`;

// 1 X 2 or 1 2
export const OddsTitleCell: any = styled(TitleCell)`
  width: 52%;
  justify-content: space-around;
`;

// Wrapping a FavoriteButton and text
export const MatchOddsTitleCell = styled(TitleCell)`
  flex-basis: 35%;
  border-right: 1px solid rgba(128, 128, 128, 0.2);
  padding-left: 1em;
  align-items: center;
  min-width: 0;

  > span {
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }
`;

export const BackTitleCell = styled(TitleCell)`
  justify-content: flex-end;
  flex-basis: 30%;
  padding: 0.25em 0.5em;
`;

export const LayTitleCell = styled(TitleCell)`
  flex-basis: 30%;
  padding: 0.25em 0.5em;
`;

const OverroundLabel: any = styled.div`
  ${bodyFont};
  font-size: 10px;
  padding: 2px;
  margin-top: 2px;
  color: ${switchProp("type", {
    BACK: theme("palette.back-text-light-more"),
    LAY: theme("palette.lay-text-light-more"),
  })};
`;

// Back/Lay + overround
const TypeTitleWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  font-size: 11px;
  margin: 0 0.5em;
`;

// Content row elements

// 隊伍名稱左方號碼，1 or 2
export const TeamNumber: any = styled.div`
  font-size: 13px;
  // color: ${theme("palette.primary-background")};
  color: ${theme("palette.secondary-background-color-new")};
  font-weight: 600;
  padding: 0 0.5em;
`;

// 隊伍名稱
export const TeamName: any = styled.div`
  font-size: 14px;
  font-weight: 500;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  // color: ${theme("palette.table-body-text")};
  color: ${theme("palette.secondary-background-color-new")};
  text-transform: lowercase !important;
  &:first-letter {
    text-transform: capitalize !important;
  }
`;

// Wrapping title number & team name
export const TeamTitleWrapper: any = styled.div`
  display: flex;
  align-items: baseline;
  margin: 0.12em 0.2em;
  padding: 3px 0px;
  // width: 120px;
  width: 100%;
  max-width: 160px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  text-transform: capitalize;
  color: #214076;
`;

// Code Changed - Manoj ( Two Css Add to show the Types of Provider in Table)
export const TeamProviderWrapper: any = styled.div`
  // position: absolute;
  right: 0;
  // right: -10px;
  // top: 20px;
  color: ${theme("palette.primary-background")};
  width: 54px;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: row;
  flex-wrap: wrap;
  text-align: center;
  z-index: 9;
  background: ${theme("palette.TeamProviderWrapperBg")};
  border-radius: 0px 0px 8px 0px;
  cursor: pointer;
`;

export const MoreButton: any = styled.div`
  background: ${theme("palette.morePlus-background")};
  border: none;
  font-size: 11px;
  width: 54px;
  padding: 3px 0px;
  font-weight: bold;
  color: ${theme("palette.morePlusBtnColor")};
  line-height: 13px;
`;

export const TeamProviderWrap: any = styled.div`
  // width: 54px;
  height: 26px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: row;
`;

export const TeamProviderContent: any = styled.span`
  display: flex;
  align-items: center;
  justify-content: center;
  color: ${theme("palette.ProviderContentTextColor")};
  font-size: 13px;
  font-weight: bold;
  line-height: 10px;
  padding: 5px 0px;
  letter-spacing: 0.1em;

  :nth-child(3):before {
    content: " ";
    width: 1px;
    height: 10px;
    background: ${theme("palette.ProviderContentLastBg")};
    display: flex;
    align-items: center;
    justify-content: center;
    line-height: normal;
    margin: 0px 3px;
    position: relative;
    top: -1px;
  }
`;

export const TeamsWrapper: any = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  flex-grow: 1;
  cursor: pointer;
  /* Text ellipsis trick */
  min-width: 0;
  font-size: ${ifProp("isBigSize", "16px", "12px")};
  margin: 0.5em 0;
  // position: relative;
  ${media.lg`
		flex-direction: row;
		justify-content: flex-start;
		align-items: center;
	`};

  ${media.sm`
		flex-direction: column;
		align-items: stretch;
	`};
`;

export const TeamContentCell = styled(Cell)`
  // width: ${ifProp("isLong", "70%", "32%")};
  width: ${ifProp("isLong", "70%", "calc(100%/3)")};
  // padding: 0 0.5em;
  padding-right: 0.5em;
  ${media.lg`
		width: 70%;
		// commented by ambu
		// padding: 0.75em 0.5em;
		padding: 6px;
	`};
`;
export const StateContentCell: any = styled(Cell)`
  width: ${ifProp("isLong", "30%", "16%")};
  border-right: 1px solid rgba(128, 128, 128, 0.2);
  padding: 0.5em;
  justify-content: center;

  ${media.lg`
		width: 30%;
		border-right: 0;
		justify-content: flex-end;
	`};
`;

export const OddsContentCell: any = styled(Cell)`
  // width: 35%;
  width: calc(100% / 3);
  // width: 350px;
  padding: 0.4em 0px;
  z-index: 0;
  position: relative;

  ${media.lg`
		width: 100%;
		// commented by ambu
		// padding: 1em 0.5em 0.6em;
		padding: 7px 6px 13px;
	`};
`;

export const ManyMoreOdds: any = styled.div`
  // width: 120px;
  width: 11%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  text-transform: uppercase;
  font-size: 10px;

  a {
    color: #35495e;
    padding: 1px;
  }
`;

// Team name, draw, etc, in match table
export const SelectionNameCell = styled(Cell)`
  flex-basis: 35%;
  border-right: 1px solid rgba(128, 128, 128, 0.2);
  padding-left: 2em;
  font-size: 14px;
  font-weight: normal;
  display: flex;
  justify-content: flex-start;
  align-items: center;

  ${media.lg`
		padding: 1em;
		flex-basis: 50%;
		border-bottom: 1px solid rgba(128, 128, 128, 0.2);
		background-color: ${theme("palette.sidebar-background")};

	`};

  ${media.sm`
		flex-basis: 55%;
	`};
`;

export const SelectionExposureWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  font-size: 14px;
  font-weight: normal;
  margin-top: 0.25em;
`;

export const OddsGroupWrapper: any = styled.div`
  display: flex;
  padding: 0 0.25em;
  width: calc(100% / 3);
  justify-content: center;

  ${media.lg`
		position: relative;
		padding-top: 0.5em

		&::before {
			position: absolute;
			top: 0;
			left: 50%;
			transform: translate(-50%, -1em);
			font-size: 11px;
			color: ${theme("palette.primary-background")};
		}
		&:nth-last-child(1) {
			&::before {
				content: '2';
			}
		}
		&:nth-last-child(2) {
			&::before {
				content: 'x';
			}
		}
		&:nth-last-child(3) {
			&::before {
				content: '1';
			}
		}
	`};
`;

export const MatchOddsGroupWrapper: any = styled.div`
  display: flex;
  width: 100%;

  ${media.lg`
		${switchProp("type", {
      BACK: css`
        > div:nth-last-child(n + 2) {
          display: none;
        }
      `,
      LAY: css`
        > div:nth-child(n + 2) {
          display: none;
        }
      `,
    })};
	`};
`;
