import _sortBy from 'lodash/orderBy';
import React, { Fragment, useState } from 'react';
import styled, { css } from 'styled-components';
import { prop, switchProp, theme, withProp } from 'styled-tools';

// Components
import { lighten } from 'polished';
import { panelStyle, shadow1F, shadowLG, shadowSM } from '../../../common/commonCSSStyles';
import { Amount, Income } from '../../../common/NumberDisplay';
import { EOddsType } from '../../NumberInput';
import { MatchListSectionHeading } from 'src/components/eu-content/order/Order';
// util
import Intl from '../../../common/Intl';
import getEventTypeConfig from '../../../../config/eventTypeConfig';
import { IOrder } from '../../../../graphql/schema';
import { diffColor } from '../../../../util/colorUtil';
import { calculateProfitLoss } from '../../../../util/formulae';
import { getGTMId } from '../../../../util/gtmUtil';
import oddsUtil from '../../../../util/oddsUtil';
import routerUtil from '../../../../util/routerUtil';
import { isMemberType } from '../../../../util/styleUtil';
import util from '../../../../util/util';
import { EMarketType } from '../../../../util/utilModel';
import { useNavigate } from "react-router-dom";
import SegmentedControl, { SegmentedWrapper } from '../../../common/SegmentedControl';
import Select, { DisplayInfo, Option } from '../../../common/Select';
//import { eventNameMap } from '../../table/SportList';


export const eventNameMap: { [num: number]: string } = {
	17: 'Multi Selection Fancy',
	1: 'Match Odds',
	2: 'Fancy',
	3: 'Completed Match',
	4: 'Tied Match',
	5: 'Over/Under 0.5 Goals',
	6: 'Over/Under 1.5 Goals',
	7: 'Over/Under 2.5 Goals',
	8: 'Over/Under 3.5 Goals',
	9: 'Over/Under 4.5 Goals',
	10: 'Over/Under 5.5 Goals',
	11: 'Over/Under 6.5 Goals',
	12: 'Over/Under 7.5 Goals',
	13: 'Over/Under 8.5 Goals',
	14: 'To Qualify',
	15: 'Innings Runs',
	16: 'Super Over',
};


interface IMatchedslipProps {
	// The content of info list should be handled by the container
	list: IOrder[];
	isByOrderTime?: boolean;
}



const Matchedslip = (props: IMatchedslipProps) => {
	const { list = [], isByOrderTime } = props;
	let navigate = useNavigate()
	const gotoEventPage = (matchData: any) => (): void => {
		const { categoryId, eventId, competitionId } = matchData;
		// const eventConfig = getEventTypeConfig(categoryId);
		// debugger
		// const path = `/market/${categoryId}/${competitionId}/${eventId}`;

		// routerUtil.pushAndKeepOriginPath(path);
		navigate(`${'/market/' + categoryId + '/' + competitionId + '/' + eventId + '/' + '1'}`, {})
	};
	const listByMarket = getGroupDataArray(list, ['marketId']);
	return (
		<SlipWrapper>
			{!isByOrderTime && (
				<SlipTitleWrapper onClick={gotoEventPage(list[0])}>
					{util.getValue(list, ['0', 'eventName'])}
				</SlipTitleWrapper>
			)}
			{listByMarket.map((marketList: IOrder[]) => {
				const marketType = marketList[0].marketType || 1;
				return (
					<UnitWrapper key={marketList[0].marketId}>
						<SelectionSectionRow>
							{/* {marketType === 2 ? marketList[0].marketName : eventNameMap[marketList[0].marketType || 1]} */}
							{marketType === 2 || 17
								? marketList[0].marketName
								: eventNameMap[marketList[0].marketType || 1]}
						</SelectionSectionRow>
						{getMatchedContent(marketList, isByOrderTime)}
					</UnitWrapper>
				);
			})}
		</SlipWrapper>
	);
};

const getMatchedContent = (list: IOrder[], isByOrderTime?: boolean) => {
	if (list.length === 0) {
		return null;
	}
	const listContent = list.map((slip, i) => {
		const { orderNumber, eventName, selectionName, price, stake, side = '', marketId, oddType } = slip;
		const type = side;
		const oddsDisplay = oddType === EOddsType.AM ? oddsUtil.EUtoAM(price).toFixed(2) : price;
		// 加0.01是為了讓num.99可以進位
		const calculatedStake = (stake * slip.exchangeRate).toFixed(2);
		const calculatedProfitLoss = calculateProfitLoss(price, calculatedStake, type as any);

		return (
			<BodyRow type={type} key={`${orderNumber}_${selectionName}_${side}_${marketId}_${i}`}>
				{isByOrderTime && <RowEventName>{eventName}</RowEventName>}
				<SelectionCell>
					<span>{selectionName}</span>
				</SelectionCell>
				<OddsCell>
					<Amount>{oddsDisplay}</Amount>
				</OddsCell>
				<StakeCell>
					<Amount>{calculatedStake}</Amount>
				</StakeCell>
				<PLCell>
					<Income fractionDigits={calculatedProfitLoss > 100000000 ? 0 : undefined}>
						{calculatedProfitLoss}
					</Income>
				</PLCell>
			</BodyRow>
		);
	});
	return (
		<Fragment>
			<HeadRow>
				<SelectionCellHead>
					{list.length > 0
						? util.getValue(list, ['0', 'marketType']) === EMarketType.FANCY
							? 'Runs'
							: 'Selection'
						: ''}
				</SelectionCellHead>
				<OddsCell>
					<Intl langKey="GLOBAL@ODDS" />
				</OddsCell>
				<StakeCell>
					<Intl langKey="GLOBAL@STAKE" />
				</StakeCell>
				<PLCell>
					<Intl langKey="GLOBAL@PL" />
					{/* <Intl langKey="P&L" /> */}
				</PLCell>
			</HeadRow>
			{listContent}
		</Fragment>
	);
};

interface IMatchedslipListProps {
	matchedslipList: IOrder[];
}

enum ESegmentType {
	Consolidate = 'CONSOLIDATE',
	Average = 'AVERAGE',
	EventDate = 'EVENTDATE',
}
const MatchedslipList = (props: IMatchedslipListProps) => {
	const { matchedslipList = [] } = props;
	const segments: ESegmentType[] = [ESegmentType.Consolidate, ESegmentType.Average, ESegmentType.EventDate];
	const optionAll: Option = { value: 'all', displayInfo: { displayName: 'All', count: matchedslipList.length } };
	const options = matchedslipList.reduce(
		(acc, cur) => {
			const { eventId, eventName } = cur;
			const option = acc.find(opt => opt.value === eventId);
			option
				? (option.displayInfo.count += 1)
				: acc.push({ value: eventId, displayInfo: { displayName: eventName, count: 1 } });
			return acc;
		},
		[optionAll] as Option[],
	);

	// Hook
	const [filterOptionValue, setFilterOptionValue] = useState('all');

	const filteredMatchedSlipList =
		filterOptionValue === 'all'
			? matchedslipList
			: matchedslipList.filter(item => item.eventId === filterOptionValue);

	return (
		<>
			<SpecialEventWrapper>
				{/* <MatchListSectionHeading>
					<span>
						<Intl langKey="MATCHED_ORDER@TITLE" />
						Matched Bet
					</span>
				</MatchListSectionHeading> */}
				<FilterSection>
					<span><Intl langKey="ORDERLIST@SELECTED" /></span>
					<SelectWrapper>
						<Select
							optionList={options}
							placeholder="Select"
							propagate={setFilterOptionValue}
							currentValue={filterOptionValue}
							defaultOptionIndex={0}
							SelectDisplayComponent={OptionDisplay}
							OptionDisplayComponent={OptionDisplay}
							id={getGTMId('matched_order_select_event')}
						/>
					</SelectWrapper>
				</FilterSection>
			</SpecialEventWrapper>
			<SegmentedControl<ESegmentType>
				segments={segments}
				langKeys={['MATCHED_ORDER@CONSOLIDATE', 'MATCHED_ORDER@AVERAGE', 'MATCHED_ORDER@EVENT_DATE']}
				// langKeys={['Consolidated', 'Average', 'Event Date']}
				allowEmptySegment={true}
				initialSegment={segments[2]}
				id={'matched_order_view'}

			>
				{({ currentSegment }) => {
					// 最外層用event group
					const contentByEvent = getGroupDataArray(filteredMatchedSlipList, ['eventId']);
					switch (currentSegment) {
						case ESegmentType.Consolidate:
							return contentByEvent.map((oriListsByEventId: IOrder[]) => {
								const list = sortBySideSelectionName(oriListsByEventId);
								return getMatchedSlip(list);
							});
						case ESegmentType.Average:
							return contentByEvent.map((oriListsByEventId: IOrder[]) => {
								const listObj = oriListsByEventId.reduce((pv: any, item: any) => {
									const keyName = `${item.marketId}_${item.selectionName}_${item.side}_${item.eventId}`;
									if (!pv[keyName]) {
										pv[keyName] = { ...item };
									} else {
										const total =
											util.numMultiply(pv[keyName].price, pv[keyName].stake) +
											util.numMultiply(item.price, item.stake);
										const totalStake = util.numAdd(pv[keyName].stake, item.stake);
										pv[keyName].price = total / totalStake;
										pv[keyName].stake += item.stake;
									}
									return pv;
								}, {});
								const list = sortBySideSelectionName(
									Object.keys(listObj).map(keyName => listObj[keyName]),
								);

								return getMatchedSlip(list);
							});
						case ESegmentType.EventDate:
							return contentByEvent.map((oriListsByEventId: IOrder[]) => {
								const list = oriListsByEventId.sort((a, b) => {
									if (b.marketStartTime !== a.marketStartTime) {
										return b.marketStartTime - a.marketStartTime;
									} else {
										return b.updatedAt - a.updatedAt;
									}
								});
								return getMatchedSlip(list);
							});
						case 'empty':
						default:
							const listData = filteredMatchedSlipList.sort((a, b) => b.updatedAt - a.updatedAt);
							return getMatchedSlip(listData, true);
					}
				}}
			</SegmentedControl>
		</>
	);
};

export default MatchedslipList;

const OptionDisplay: React.SFC<DisplayInfo> = props => {
	const { displayName, count } = props;

	return (
		<OptionDisplayWrapper>
			<span>{displayName}</span>
			<CountWrapper length={count.toString().length}>{count}</CountWrapper>
		</OptionDisplayWrapper>
	);
};

const getMatchedSlip = (list: IOrder[], isByOrderTime?: boolean) => (
	<Matchedslip
		list={list}
		isByOrderTime={isByOrderTime}
		key={list[0] && `${list[0].eventId} _${ESegmentType.Consolidate}_${list[0].marketId}`}
	/>
);
const getGroupData = (list: any[], path: string[]) => {
	return list.reduce((pv, item) => {
		const key = util.getValue(item, path);
		!pv[key] && (pv[key] = []);
		pv[key].push(item);
		return pv;
	}, {});
};
const getGroupDataArray = (list: any[], path: string[]) => {
	const data = getGroupData(list, path);
	const assginToArray = Object.keys(data).map(key => data[key]);
	// 原本只有by market Id做group排序，但market slip也需要從最後被下單的開始往下排，這邊就是在做重新排序market by update time
	const sortMarketbyUpdate = _sortBy(assginToArray, d => d[0].updatedAt).reverse();
	return sortMarketbyUpdate;
};

const sortBySideSelectionName = (arr: IOrder[]) => {
	return arr.sort((a, b) => {
		const map: any = { BACK: 0, LAY: 1 };
		if (a.marketId !== b.marketId) {
			return a.marketId.localeCompare(b.marketId);
		} else if (a.side !== b.side) {
			return map[a.side] - map[b.side];
		} else {
			return b.selectionName.toLowerCase().localeCompare(a.selectionName.toLowerCase());
		}
	});
};

// Styled components 
const SpecialEventWrapper: any = styled.div`
	width: 100%;
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	align-items: center;
	background: ${theme('palette.headerBgColor')};
	padding: 5px 10px;
	text-transform: capitalize;
	margin-bottom: 5px;
`;

export const CountWrapper: any = styled.div`
	font-family: monospace;
	font-size: 11px;
	color: white;
	display: flex;
	justify-content: center;
	align-items: center;
	// width: calc(1.5em * ${prop('length')});
	// height: 1.5em;
	border-radius: 99em;
	margin: 0 0.25em;
	flex-shrink: 0;
	background: #E71F52;
    width: 17px;
    height: 17px;
    margin: 0px 5px;
`;

const OptionDisplayWrapper = styled.div`
	display: flex;
	justify-content: space-between;
	align-items: center;
	flex-grow: 1;
	text-align: left;
	min-width: 0;

	/* option name */
	> span {
		min-width: 0;
		white-space: nowrap;
		overflow: hidden;
		text-overflow: ellipsis;
	}
`;

const SelectWrapper = styled.div`
	flex-grow: 1;
	margin-left: 5px;
	// min-width: 0;
	max-width: 140px;
`;

const FilterSection = styled.div`
	display: flex;
	justify-content: space-between;
	align-items: center;
	font-size: 12px;

	/* select event text */
	> span {
		flex-shrink: 0;
		/* color: ${theme('palette.homeTable-background')}; */
		color: #fff;
		font-size: 14px;
		font-weight: 500;
	}
`;

const Cell = styled.div`
	display: flex;
	align-items: center;
	padding: 4px;
`;

// Define columns
const SelectionCell = styled(Cell)`
	width: 28%;
	flex-direction: column;
	align-items: stretch;
	justify-content: center;
	// color: #3CACEC;
	font-style: italic;
`;

const SelectionCellHead = styled(Cell)`
	width: 28%;
	flex-direction: column;
	align-items: stretch;
	justify-content: center;
	/* color: #A28F72; */
	font-weight: bold;
`;

const OddsCell = styled(Cell)`
	width: 18%;
	justify-content: center;
	font-weight: bold;
`;

const StakeCell = styled(Cell)`
	width: 20%;
	justify-content: center;
	font-weight: bold;
`;

const PLCell = styled(Cell)`
	width: 34%;
	justify-content: flex-end;
	font-weight: bold;
`;

const Row = styled.div`
	display: flex;
	padding: 0 4px;
`;

const HeadRow = styled(Row)`
	font-size: 12px;
	text-transform: capitalize;
	/* color: #a09c97; */
	background: rgba(97, 96, 95, 0.346);
`;

const BodyRow: any = styled(Row)`
	font-size: 13px;
	position: relative;
	flex-wrap: wrap;

	&::before {
		content: '';
		position: absolute;
		width: 2px;
		top: 0;
		left: 0;
		bottom: 0;
	}

	${switchProp('type', {
	BACK: css`
			&::before {
			}
		`,
	LAY: css`
			&::before {
			}
		`,
})};



	${SelectionCell} {
		font-weight: bold;
		color: ${switchProp('type', {
	BACK: ('#3CACEC'),
	LAY: ('#fd6c8d;'),
})};
		
		> span {
			overflow: hidden;
			white-space: nowrap;
			text-overflow: ellipsis;
			min-width: 0;
		}
	}
`;

const RowEventName = styled(Cell)`
	font-size: 12px;
	margin-bottom: 0.25em;
	flex-basis: 100%;
`;

const SelectionSectionRow = styled(Row)`
	border-top-left-radius: 4px;
	border-top-right-radius: 4px;
	justify-content: center;
	font-size: 13px;
    padding: 0.50em;
    font-weight: 500;
    text-transform: capitalize;
    color: rgb(63 88 132 / 89%);

`;

const SlipTitleWrapper = styled.div`
	cursor: pointer;
	padding: 4px 8px;
	font-size: 13px;
	font-weight: bold;
	background: ${theme('palette.headerBgColor')};
	text-transform: capitalize;
	color: white;
	text-align: center;
`;

const SlipWrapper: any = styled.div`
	display: flex;
	flex-direction: column;
	margin-bottom: 1em;
	${panelStyle};
	overflow: visible;

	${SegmentedWrapper} {
		color: red;
	}
`;
const UnitWrapper = styled.div`
	${shadow1F};
	&:not(:last-of-type) {
		margin-bottom: 15px;
	}
	border-bottom-right-radius: 4px;
	border-bottom-left-radius: 4px;
`;
