import React, { useState, useEffect, useContext } from 'react';
import styled from 'styled-components';
import { useLocation, useNavigate } from 'react-router-dom';

import {
  BodyWrapper,
  ButtonWrapper,
  FirstCol,
  Header,
  Input,
  InputWrapper,
  MainInfoWrapper,
  PasswordStrength,
  ResetBtn,
  SubmitBtn,
  InputWrapperHalf,
} from '../../../components/udb/commonStyle';
import { BankInputField } from '../../../components/udb/Bankdetail';
import Intl from '../../../components/common/Intl';
import { addUPIDetails, updateUPIDetails } from '../../../config/api';
import { Notify } from '../../../components/Notification'
import { UPIdataProps } from './Bankdetails';
// import { UserContext } from '../index';
import UDBHeader from 'src/components/UDBHeader';


type UPIdetailsStateProps = {
  upiId: any;
  remarks?: string;
  isDefault: boolean;
  mode: string;
  uuid: number
}

type locationStateProps = {
  mode: string;
  data?: UPIdataProps;
}

const defaultUPIState: UPIdetailsStateProps = {
  upiId: '',
  remarks: '',
  isDefault: false,
  mode: 'create',
  uuid: 1
}

const defaultUPIErrorState = {
  upiId: false,
}


export default function UpiDetails() {
  const [UpiState, setUpiState] = useState(defaultUPIState);
  const [errorState, setErrorState] = useState(defaultUPIErrorState);
  const [errorMessage, setErrorMessage] = useState('')
  const location = useLocation();
  const navigate = useNavigate();
  const { mode, data } = location.state as locationStateProps;
  

  useEffect(() => {
    if (mode === 'update' && data) {
      prefilledBankDetail(data);
    }
  },[]);

  const prefilledBankDetail = (data) => {
    setUpiState({
      upiId: data.upiId,
      remarks: data.remarks,
      isDefault: data.isDefault,
      mode: mode,
      uuid: data.uuid
    })
  }

  const resetBankDetail = (e) => {
    e.preventDefault();
    if (mode === 'update' && data) {
      prefilledBankDetail(data);
    }
    else {
      setUpiState({ ...defaultUPIState })
    }
    setErrorState(defaultUPIErrorState);
    setErrorMessage("")
  }

  const changeHandler = (e: React.ChangeEvent<HTMLSelectElement | HTMLInputElement>) => {
    const _elm = e.target;
    const _val = _elm.value;
    const _name = _elm.name;
    setUpiState(prevProp => ({ ...prevProp, [_name]: _val }));
    setErrorState(prevProp => ({ ...prevProp, [_name]: !_val }));
  }

  const setDefaultUpiHandle = () => {
    setUpiState(prevProp => ({ ...prevProp, isDefault: !prevProp.isDefault }));
  }


  const openChatBox = () => {
    const isChat = window.$zoho;
    if (typeof isChat === 'object') {
      window.$zoho.salesiq.floatwindow.visible("show");
    }
  }

  const numberHandler = (e) => {
    const regExp = /^[\w\.\-_]{3,}@[a-zA-Z]{3,}$/;
    const _elm = e.target;
    const preValue = UpiState.upiId;
    const val = e.target.value;
    const _name = _elm.name;
    setUpiState(prevProp => ({ ...prevProp, [_name]: val }));
    if(!regExp.test(val)) {
      setErrorMessage("Invalid UPI ID");
      setErrorState(prev => ({ ...prev, upiId: true }));
    }
    else {
      let _val = regExp.test(val) ? val : preValue
      setUpiState(prevProp => ({ ...prevProp, [_name]: _val }));
      setErrorMessage("");
      setErrorState(prev => ({ ...prev, upiId: false }));
    }
  }

  const submitBankDetails = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    if (errorState.upiId) {
      return;
    }
    else {
      const { mode, ...props } = UpiState;
      const params = JSON.stringify(props);

      if (mode === 'create') {
        addUPIDetails(params).then(res => {
          if (res.data && res.data.success) {
            setUpiState(defaultUPIState);
            Notify.success('UPI details added successfully');
            navigate('/member/profile/bankdetails?source=upi', { replace: true })
          }
        }).catch(e => {
          const { data } = e.response || {};
          let message = 'Something Went Wrong, Please Try Again'
          if (data && data.error) {
            message = data.error;
          }
          Notify.error(message);
        });
      }
      else if (mode === 'update') {
        updateUPIDetails(params).then(res => {
          if (res.data && res.data.success) {
            setUpiState(defaultUPIState);
            Notify.success('UPI details updated successfully');
            navigate('/member/profile/bankdetails?source=upi', { replace: true })
          }
        })
          .catch(e => {
          const { data } = e.response || {};
          let message = 'Something Went Wrong, Please Try Again'
          if (data && data.error) {
            message = data.error;

          }
          Notify.error(message);
        });
      }
    }
  }



  const submitBtndiabledState = !!(UpiState.upiId);
  const submitBtnState = !submitBtndiabledState ? { disabled: true } : {}

  return (
    <BankDetailsWrapper>
      <MainInfoWrapper>
        <Header>
          <FirstCol>
            <UDBHeader title="DASHBOARD@BANKDETAILS" />
          </FirstCol>
        </Header>
        <form onSubmit={submitBankDetails}>
          <BodyWrapperNew>
            {/* <WholeWrap> */}
            <InputWrap>

              <InputWrapper className={errorState.upiId ? 'error' : ''}>
                <BankInputField placeholder="UPI ID*" maxLength={25} value={UpiState.upiId} name="upiId"  onChange={(e) => numberHandler(e)} autoComplete="off"/>
              </InputWrapper>
              {errorMessage && <p className="errorMsg">{errorMessage}</p>}

              <InputWrap1>
                <InputWrapperHalf>
                  <BankInputField placeholder="Remarks" maxLength={26} name="remarks" onChange={changeHandler} value={UpiState.remarks} autoComplete="off"/>
                </InputWrapperHalf>

                <RadioBtnWrapper style={{ padding: "15px" }}>
                  <BtnText>
                    <Input type="radio" name='isDefault' checked={UpiState.isDefault} onClick={setDefaultUpiHandle} />
                    Set Default Account</BtnText>
                </RadioBtnWrapper>
              </InputWrap1>
            </InputWrap>

            <PasswordStrength>
              <span><Intl langKey="DASHBOARD@Messageshow" /> </span>
              <CustomeServiceLink onClick={openChatBox}><Intl langKey="DASHBOARD@customrService" /> </CustomeServiceLink>
              {/* <Link to={''}><Intl langKey="DASHBOARD@customrService" /> </Link> */}
            </PasswordStrength>
            <ButtonWrapper>
              <SubmitBtn {...submitBtnState} type="submit">{UpiState.mode === 'update' ? <Intl langKey="DASHBOARD@UpdateAcc" /> : <Intl langKey="DASHBOARD@AddAcc" />}</SubmitBtn>
              <ResetBtn onClick={resetBankDetail}><Intl langKey="DASHBOARD@reset" /> </ResetBtn>
            </ButtonWrapper>
          </BodyWrapperNew>
        </form>

      </MainInfoWrapper>
    </BankDetailsWrapper>
  )
}

const BodyWrapperNew = styled(BodyWrapper)`
width:100%;

.errorMsg {
       color: red;
       font-size: 12px;
       text-align:left;
       margin: -5px 0px 3px 0px;
   }
`
const WholeWrap = styled.div`
display: flex;
justify-content: space-between;
width: 80%;
align-items: baseline;

`

const InputWrap = styled.div`
  display: flex;
  flex-direction: column;
  width: 75%;
`
const InputWrap1 = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: center;
`

const BtnText = styled.label`
    display: flex;
    align-items: center;
    letter-spacing: 0.01em;
    text-transform: capitalize;
    color: #6665BD;
    font-weight: 600;
    font-size: 11px;
`;
const RadioBtnWrapper = styled.div`
    width: 49%;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 15px;
    // background: radial-gradient(50% 50% at 50% 50%,rgba(255,255,255,0.56) 0%,rgba(255,255,255,0.56) 100%);
    background: #fff;
    border: 1px solid #FFFFFF;
    box-shadow: 0px 0px 50px rgb(194 199 233 / 50%);
    border-radius: 7px;

    ${Input} {
        width: 18px!important;
        height: 18px!important;
        border: 1px solid #4643C2;
        margin: 0;
        margin-right: 5px;
    }
`;
const BankDetailsWrapper = styled.div`
    width: 100%;
    
    ${PasswordStrength} {
        display: inline-block;
        padding: 6px 0px;

        a {
            color: #FF5A76;
        }
    }

    .error {
      label {
        border:1px solid red!important;
        background: #efdfdf;
      }

    }

    ${InputWrapperHalf} {
      margin: 0;
      padding: 0;
      background: none;
      border: none;
      box-shadow: none;
    }
`;

const CustomeServiceLink = styled.span`
color: #FF5A76;
cursor: pointer;
`