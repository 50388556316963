import React from 'react';
//const { Router } = require('~/server/route');

// Utils
import { mql } from '../util/mediaUtil';

interface IMainLayoutProviderProps {
	children: any;
}

interface IMainLayoutProviderState {
	isSidebarOpened: boolean;
	isBettingOpened: boolean;
	isConnectedToProvider: boolean;
}

export interface IMainLayoutProviderStore extends IMainLayoutProviderState {
	toggleSidebar: () => void;
	deactivateSidebar: () => void;
	toggleBetting: () => void;
	activateBetting: () => void;
	deactivateBetting: () => void;
}

const initialStore: IMainLayoutProviderStore = {
	isSidebarOpened: false,
	isBettingOpened: false,
	isConnectedToProvider: false,
	toggleSidebar() {},
	deactivateSidebar() {},
	toggleBetting() {},
	activateBetting() {},
	deactivateBetting() {},
};

export const MainLayoutContext = React.createContext(initialStore);

export class MainLayoutProvider extends React.PureComponent<IMainLayoutProviderProps, IMainLayoutProviderState> {
	state = {
		isSidebarOpened: false,
		isBettingOpened: false,
		isConnectedToProvider: true,
	};

	componentDidMount() {
		//Router.events.on('routeChangeComplete', () => {
			// majorWrapper 的內容先捲動至頂部
			const majorWrapper = document.querySelector('#major-wrapper');
			majorWrapper && majorWrapper.scrollTo(0, 0);

			// 任何 router push 都關閉兩側 sidebar
			this.deactivateSidebar();
			this.deactivateBetting();
		//});
	}

	toggleSidebar = () => {
		// Sidebar 只在 viewport < 1200px 才會收合，這時才需要 toggle
		if (!mql.xl.matches) {
			return;
		}
		this.setState(prevState => {
			return {
				isSidebarOpened: !prevState.isSidebarOpened,
			};
		});
		this.deactivateBetting();
	};

	deactivateSidebar = () => {
		if (!mql.xl.matches) {
			return;
		}
		this.setState(() => ({
			isSidebarOpened: false,
		}));
	};

	toggleBetting = () => {
		// Betting area 只在 viewport < 768px 才會收合，這時才需要 toggle
		if (!mql.md.matches) {
			return;
		}
		this.setState(prevState => ({
			isBettingOpened: !prevState.isBettingOpened,
		}));
		this.deactivateSidebar();
	};

	activateBetting = () => {
		if (!mql.md.matches) {
			return;
		}
		this.setState(() => ({
			isBettingOpened: true,
		}));
	};

	deactivateBetting = () => {
		if (!mql.md.matches) {
			return;
		}

		this.setState(() => ({
			isBettingOpened: false,
		}));
	};

	render() {
		const store: IMainLayoutProviderStore = {
			isSidebarOpened: this.state.isSidebarOpened,
			isBettingOpened: this.state.isBettingOpened,
			isConnectedToProvider: this.state.isConnectedToProvider,
			toggleSidebar: this.toggleSidebar,
			deactivateSidebar: this.deactivateSidebar,
			toggleBetting: this.toggleBetting,
			activateBetting: this.activateBetting,
			deactivateBetting: this.deactivateBetting,
		};
		return <MainLayoutContext.Provider value={store}>{this.props.children}</MainLayoutContext.Provider>;
	}
}

// Just an alias
export const MainLayoutConsumer = MainLayoutContext.Consumer;

export function withMainLayoutContext<U extends keyof IMainLayoutProviderStore>(
	WrappedComponent: any,
	properties: U[],
) {
	return class MainLayoutHOC<P> extends React.PureComponent<P> {
		render() {
			return (
				<MainLayoutConsumer>
					{layoutStore => {
						const pickedProps: Pick<IMainLayoutProviderStore, U> = properties.reduce((store, key) => {
							store[key] = layoutStore[key];
							return store;
						}, {} as any);
						return <WrappedComponent {...this.props} mainLayoutContext={pickedProps} />;
					}}
				</MainLayoutConsumer>
			);
		}
	};
}
