import { useState } from 'react'
import Breadcrumb from 'src/components/udb/Breadcrumb';
import { FirstCol, Header, MainInfoWrapper, Tittle, SecondCol, Select, TableBodyRow as BetDetailTableBodyRow, DetailMainWrapper, EventNameWrap, OpenWrapper, OpenWrapperContent } from 'src/components/udb/commonStyle'
import styled, { css } from 'styled-components';
import Intl from '../../../components/common/Intl';
import { EHistoryFilterType, EHistoryTableType } from 'src/pages/Statements/HistoryTable';
import SegmentedControl from 'src/components/common/SegmentedControl';
import { useQuery } from '@apollo/client';
import { GET_MY_ORDERS } from 'src/graphql/member';
import { IOrder, IOrderGroup } from 'src/graphql/schema';
import util from 'src/util/util';
import { Col } from 'src/components/common/table/baseTableStyles';
import DatePickerUdb from 'src/components/udb/DatePickerUdb';
import { ChooseDocument } from '../finance/History';
import Spinner from 'src/components/common/Spinner';
import {
  TableBodyWrapper,
  TableHeadRow,
  TableWrapper, BodyWrapper
} from 'src/components/udb/commonStyle';

import { Amount, Income } from 'src/components/common/NumberDisplay';
import EmptyRow from 'src/components/common/table/feature/EmptyRow';
import { EOddsType } from 'src/components/eu-content/NumberInput';
import { MobileHead, DateText, TimeText, TypeLabel, StatusText, DetailWrapper, EventNameWithChevron, RemarkItemTime, RemarkItemWrapper, RemarkSectionTitle, RemarkSectionWrapper, SelectionInfoWrapper } from 'src/components/member/membertablestyles';
import { TimeZone } from 'src/store/TimeZoneContext';
import oddsUtil from 'src/util/oddsUtil';
import { EMarketType } from 'src/util/utilModel';
import Openable from 'src/components/common/Openable';
import UDBHeader from 'src/components/UDBHeader';
import { ifProp, theme } from 'styled-tools';
import SEOContents from 'src/components/SEOContents';
import { ContainerWrapper } from './TurnoverStatement';


const Description: React.SFC<{
  eventName: string;
  marketName: string;
  selectionName: string;
  orderNumber: string;
  marketType: number;
  matchedTime: string | number | Date;
  isSettled: boolean;
  remark: string;
  type: string;
  mode: string;
}> = props => {
  const {
    eventName,
    marketName,
    selectionName,
    matchedTime,
    orderNumber,
    isSettled,
    remark,
    marketType = EMarketType.MAIN,
    type,
    mode,
  } = props;
  return (
    <Openable>
      {({ isOpened, toggleOpen }) => {
        const hasRemark = !!remark;
        const remarks = (hasRemark && remark.split('||')) || [];
        return (
          <>
         
            <EventNameWithChevron
              onClick={toggleOpen}
              isOpened={isOpened}
              withRemark={hasRemark}
              historyFilterType={type}
              historyTableType={mode}
            >
              <EventNameWrap>{eventName}</EventNameWrap>
            </EventNameWithChevron>
            {isOpened && (
              <OpenWrapper>
                <OpenWrapperContent>
                  <SelectionInfoWrapper>
                    {marketType === EMarketType.MAIN || EMarketType.Multi_Selection_Fancy ? (
                      <>
                        <span>{marketName}</span>
                        <span>{selectionName}</span>
                      </>
                    ) : (
                      <span>
                        {selectionName} - runs ({marketName})
                      </span>
                    )}
                  </SelectionInfoWrapper>
                  <DetailMainWrapper>
                    <DetailWrapper>
                      <span>
                        Bet Number
                      </span>
                      <span className='orderBoldOne'> {orderNumber}</span>
                    </DetailWrapper>
                    <DetailWrapper>
                      <span>
                        {isSettled ? <span>Placed</span> : <span>Matched</span>}
                      </span>
                      <span>
                        <TimeZone format="dd/MM/yyyy HH:mm:ss">{matchedTime}</TimeZone>
                      </span>
                    </DetailWrapper>
                  </DetailMainWrapper>
                  {Array.isArray(remarks) &&
                    remarks.map((r, i) => {
                      const remarkInfo = (r && r.split('|')) || [];

                      if (remarkInfo.length <= 0) {
                        return <span />;
                      }
                      const date = remarkInfo[0] || (new Date().toISOString());
                      return (
                        <RemarkSectionWrapper>
                          <RemarkSectionTitle>Remarks</RemarkSectionTitle>
                          <div>
                            <RemarkItemWrapper>
                              {(remarkInfo[1] && remarkInfo[1].split(':')[1]) || ''}
                              <RemarkItemTime>
                                <TimeZone format="dd/MM/yyyy">{date}</TimeZone>
                                {/* {` ${remarkInfo[1]}`} */}
                              </RemarkItemTime>
                            </RemarkItemWrapper>
                          </div>
                        </RemarkSectionWrapper>
                      );
                    })}
                </OpenWrapperContent>
              </OpenWrapper>
            )}
          </>
        );
      }}
    </Openable>
  );
};

function MyBets(props: any) {
  const [startAt, setStartAt] = useState(new Date());
  const [endAt, setEndAt] = useState(new Date());
  const [type, setType] = useState(EHistoryFilterType.matched)
  type MyOrderSegments = EHistoryTableType.current | EHistoryTableType.past;
  const [mode, setMode] = useState(EHistoryTableType.current);

  const handleChange = (e) => {
    setType(e.target.value)
  }

  const selectCurrent = () => {
    setMode(EHistoryTableType.current)
  }

  const selectPast = () => {
    setMode(EHistoryTableType.past)
  }

  function showStatus(status: string) {
    switch (status) {
      case 'SETTLED':
        return 'SETTLING';

      default:
        return status;
    }
  }

  let timeColLangKey: any;
  switch (mode) {
    case EHistoryTableType.current:
      timeColLangKey = <Intl langKey='HISTORY_TABLE@PLACED' />;
      break;
    case EHistoryTableType.past:
      timeColLangKey = <Intl langKey='HISTORY_TABLE@SETTLED' />;
      break;
    default:
      console.warn('[HistoryTable]: mode prop is invalid!');
      timeColLangKey = 'TIME';
  }

  const isSettled = mode === EHistoryTableType.past;

  const { data, loading, error } = useQuery(GET_MY_ORDERS, {
    variables: {
      input: { isSettled, period: { start: startAt.valueOf(), end: endAt.valueOf() } },
    }
  });

  const orderGroup: IOrderGroup = util.getValue(data, ['exchange', 'myOrders'], {
    matched: [],
    unmatched: [],
  });
  let orders = isSettled
    ? orderGroup.matched
    : type === EHistoryFilterType.both
      ? [...orderGroup.matched, ...orderGroup.unmatched].sort((a, b) => b.createdAt - a.createdAt)
      : type === EHistoryFilterType.matched
        ? orderGroup.matched
        : orderGroup.unmatched;
  orders = orders.slice().sort((a, b) => b.settledAt - a.settledAt)

  return (
    <MainInfoWrapper>
       <SEOContents pageName="memberMyBets" />
      <Header>
        <FirstCol>
          <UDBHeader title="DASHBOARD@MY_ORDER" />
        </FirstCol>
        <SecondCol>
          <DatePickerUdb setStartAt={setStartAt} setEndAt={setEndAt} />
        </SecondCol>
      </Header>
      {/* <BodyWrapperInherited> */}


      <FilterSegment>

        <BetTypeButton>
          <button className={mode === EHistoryTableType.current ? "Active" : "betButton"} onClick={selectCurrent}>
            <Intl langKey="MY_ORDER@CURRENT" />
          </button>
          <button className={mode === EHistoryTableType.past ? "Active" : "betButton"} onClick={selectPast}>
            <Intl langKey="MY_ORDER@PAST" />
          </button>
        </BetTypeButton>
        <DropDown>
          {mode === EHistoryTableType.current && (

            <SelectBtn className="select">
              <select
                value={type}
                onChange={handleChange}
              >
                <option value="MATCHED">{EHistoryFilterType.matched}</option>
                <option value="UNMATCHED">{EHistoryFilterType.unmatched}</option>
                <option value="BOTH">{EHistoryFilterType.both}</option>
              </select>
            </SelectBtn>
          )}
        </DropDown>
      </FilterSegment>

      {loading && <Spinner mode="agent" />}
      <>
      <ContainerWrapper1 >
        <TableWrapper>
          <TableHeadRow>
            <DescriptionCol>
              <span><Intl langKey="ACCOUNT_STATEMENT_TABLE@DESCRIPTION" /></span>
            </DescriptionCol>
            <TypeCol>
              <span>{timeColLangKey}</span>
            </TypeCol>
            <TypeCol>
              <span><Intl langKey="ACCOUNT_STATEMENT_TABLE@TYPE" /></span>
            </TypeCol>
            <TypeCol>
              <span><Intl langKey="DETAIL_TABLE@ODDS" /></span>
            </TypeCol>
            <TypeCol>
              <span><Intl langKey="DETAIL_TABLE@STAKE" /></span>
            </TypeCol>
            {mode === EHistoryTableType.current && (
              <>
                <TypeCol>
                  <span><Intl langKey="HISTORY_TABLE@LIABILITY" /></span>
                </TypeCol>
                <TypeCol>
                  <span><Intl langKey="HISTORY_TABLE@POTENTIAL_PROFIT" /></span>
                </TypeCol>
              </>
            )}
            {mode === EHistoryTableType.past && (
              <>
                <TypeCol>
                  <span><Intl langKey="MEMBER@P2P_PROFIT_LOSS" /></span>
                </TypeCol>
                <TypeCol>
                  <span><Intl langKey="HISTORY_TABLE@STATUS" /></span>
                </TypeCol>
              </>
            )}
          </TableHeadRow>

          <TableBodyWrapper>
            {(error ? (
              <div>error</div>
            ) :
              (orders.map((item: IOrder, i: number) => (
                <TableBodyRow key={i} className="tableBody">
                  <DescriptionCol>
                    <Description
                      mode={mode}
                      type={type}
                      eventName={item.eventName}
                      marketName={item.marketName}
                      selectionName={item.selectionName}
                      marketType={item.marketType || EMarketType.MAIN}
                      orderNumber={item.orderNumber}
                      remark={item.clientRemark || ''}
                      matchedTime={isSettled ? item.createdAt : item.updatedAt}
                      isSettled={isSettled}
                    />
                  </DescriptionCol>
                  <TypeCol>
                    <MobileHead>
                      <span>{timeColLangKey}</span>
                    </MobileHead>
                    <TimeColContentWrapper>
                      <DateText>
                        <TimeZone format={'dd/MM/yyyy'}>
                          {isSettled ? item.settledAt : item.createdAt}
                        </TimeZone>
                      </DateText>
                      {/* &nbsp; */}
                      <TimeText>
                        <TimeZone format={'HH:mm:ss'}>
                          {isSettled ? item.settledAt : item.createdAt}
                        </TimeZone>
                      </TimeText>
                    </TimeColContentWrapper>
                  </TypeCol>
                  <TypeCol>
                    <MobileHead>
                      <span>TYPE</span>
                    </MobileHead>
                    <TypeLabel>
                      {[EMarketType.MAIN, EMarketType.Multi_Selection_Fancy].includes(
                        item.marketType || -1,
                      )
                        ? item.side
                        : item.side === 'BACK'
                          ? 'YES'
                          : 'NO'}
                    </TypeLabel>
                  </TypeCol>
                  <TypeCol>
                    <MobileHead>
                      <span>ODDS</span>
                    </MobileHead>
                    {item.oddType === EOddsType.AM
                      ? oddsUtil.EUtoAM(item.price).toFixed(2)
                      : item.price.toFixed(2)}
                  </TypeCol>
                  <TypeCol>
                    <MobileHead>
                      <span>STAKE</span>
                    </MobileHead>
                    <Amount>{util.numMultiply(item.stake, item.exchangeRate)}</Amount>
                  </TypeCol>
                  {!isSettled ? (
                    // Current
                    <>
                      <TypeCol>
                        <MobileHead>
                          <span>LIABILITY</span>
                        </MobileHead>
                        {item.liability ? (
                          <Income>
                            {util.numMultiply(item.liability, item.exchangeRate)}
                          </Income>
                        ) : (
                          '-'
                        )}
                      </TypeCol>
                      <TypeCol>
                        <MobileHead>
                          <span>POTENTIAL PROFIT</span>
                        </MobileHead>
                        {item.winlossCredit ? (
                          <Income>
                            {util.numMultiply(item.winlossCredit, item.exchangeRate)}
                          </Income>
                        ) : item.status === 'VOIDED' ? (
                          '0'
                        ) : (
                          '-'
                        )}
                      </TypeCol>
                    </>
                  ) : (

                    <>
                      <TypeCol>
                        <MobileHead>
                          <span>PROFIT LOSS</span>
                        </MobileHead>
                        {item.status === 'CHECKOUT' && item.winlossCredit ? (
                          <Income>
                            {util.numMultiply(item.winlossCredit, item.exchangeRate)}
                          </Income>
                        ) : (
                          '-'
                        )}
                      </TypeCol>
                      <TypeCol>
                        <MobileHead>
                          <span>STATUS</span>
                        </MobileHead>
                        <StatusText>
                          {item.status !== 'CHECKOUT'
                            ? showStatus(item.status || '')
                            : (item.winlossCredit || 0) > 0
                              ? 'WON'
                              : 'LOST'}
                        </StatusText>
                      </TypeCol>
                    </>
                  )}
                </TableBodyRow>

              ))
              ))}
            {orders.length === 0 && <EmptyWrapper><EmptyRow /></EmptyWrapper>}
          </TableBodyWrapper>
        </TableWrapper>
        </ContainerWrapper1>
      </>

      {/* </BodyWrapperInherited> */}

    </MainInfoWrapper >
  )
}

export default MyBets


export const ContainerWrapper1=styled(ContainerWrapper)`
  padding:10px;
  padding: ${theme('palette.myBetsContainerWrapper1Padding')};
  background: ${theme('palette.myBetsContainerWrapper1Bg')};
`
const SelectBtn = styled(Select)`
padding: 7px;
`

export const BodyWrapperInherited = styled(BodyWrapper)`
  width:100%;
  height: auto;
  overflow-y: scroll;
  ::-webkit-scrollbar {
    width: 8px;
    background:darkgrey;
}


::-webkit-scrollbar-thumb {
    border-radius: 5px;
    /* background: #9b9b9b; */
    -webkit-box-shadow: inset 0 0 6px rgb(0 0 0 / 50%);
}

::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 6px rgb(0 0 0 / 30%);
    border-radius: 5px;
  }
  
`

const TableBodyRow = styled(BetDetailTableBodyRow)`
  align-items: flex-start;
`;

export const DateSelector = styled.div`
  position:relative;
  top:110px;
  left: 25px;
  // font-family: 'Roboto';
  font-weight: bold;
  display:inline-flex;
`;

export const TimeCol = styled(Col)`
	flex-basis: 10%;
`;

export const DescriptionCol = styled(Col)`
	flex-basis: 32%;
    display: flex;
    flex-direction: column;
`;

export const TypeCol = styled(Col)`
	flex-basis: 10%;
	justify-content: center;
	align-items: flex-start;

`;

export const OddsCol = styled(Col)`
	flex-basis: 10%;
	justify-content: center;
`;

export const StakeCol = styled(Col)`
	flex-basis: 10%;
	justify-content: center;

`;

export const LiabilityCol = styled(Col)`
	flex-basis: 12%;
	justify-content: center;
`;

export const ProfitCol = styled(Col)`
	flex-basis: 20%;
	justify-content: center;
	text-align: center;
`;

export const ProfitLossCol: any = styled(Col)`
	flex-basis: 16%;
	justify-content: center;
`;

export const StatusCol = styled(Col)`
	flex-basis: 16%;
	justify-content: center;
`;

export const TimeColContentWrapper = styled.div`
	display: flex;
	flex-direction: column;
`;

const EmptyWrapper = styled.div`
    width: 100%;
    /* justify-content: center;  */
    text-transform: capitalize;
    letter-spacing: 0.66px;
    // background: #FDF2E5;
    border-bottom: none;
    position:relative;
`;

const FilterSegment = styled.div`
display: flex;
justify-content: flex-end;
width:inherit;
align-items: baseline;
`;

const BetTypeButton = styled.button`
    display: flex;
    overflow: hidden;
    margin-bottom: 1em;
    background: transparent;
    border: none;
    flex:4;

  .betButton{
    width: 135px;
    display: flex;
    -webkit-box-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    align-items: center;
    position: relative;
    font-weight: 600;
    font-size: 13px;
    text-transform: capitalize;
    padding: 1em 0px;
    cursor: pointer;
    border: 0.25px solid;
    border-radius: 3px;
    margin-right: 10px;
  }

  .Active{
    width: 135px;
    display: flex;
    -webkit-box-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    align-items: center;
    position: relative;
    font-weight: 600;
    font-size: 13px;
    text-transform: capitalize;
    padding: 1em 0px;
    cursor: pointer;
    border: 0.25px solid;
    border-radius: 3px;
    margin-right: 10px;
    background: ${theme('palette.secondary-background-color-linear-new1')};
    color: #fff;
  }
`;

const DropDown = styled.div`
  flex:1;
  z-index: 0;
`;











