import React from 'react'

type Props = {}

const Viewbank = (props: Props) => {
  return (
<svg width="44" height="41" viewBox="0 0 44 41" fill="none" xmlns="http://www.w3.org/2000/svg">
<rect x="5.97803" y="17.5908" width="5.34854" height="12.0671" rx="1.06157" fill="white"/>
<path d="M0.615782 14.4705V10.8535C0.615782 10.3406 0.615782 10.0762 1.02137 9.85831C6.79397 6.75756 18.4404 0.484606 18.8453 0.198815C19.2502 -0.0869752 19.8089 0.079736 20.0376 0.198815C25.3417 3.05548 36.1737 8.8939 37.0692 9.39431C37.9647 9.89473 38.2095 10.431 38.22 10.6366V14.4268C38.22 15.8751 37.5034 16.2293 37.1451 16.2254H36.2513H2.26615C0.833732 16.2254 0.569064 15.0554 0.615782 14.4705Z" fill="white"/>
<path fill-rule="evenodd" clip-rule="evenodd" d="M19.4872 31.0196L1.68803 31.0195C1.10175 31.0195 0.626465 31.4948 0.626465 32.0811L0.626465 36.5999C0.626465 37.1862 1.10174 37.6614 1.68803 37.6614L22.2154 37.6615C20.7297 35.8001 19.7507 33.5165 19.4872 31.0196Z" fill="white"/>
<path fill-rule="evenodd" clip-rule="evenodd" d="M22.1117 21.7846V18.6504C22.1117 18.0641 21.6364 17.5889 21.0502 17.5889H17.8248C17.2385 17.5889 16.7632 18.0641 16.7632 18.6504V28.5944C16.7632 29.1807 17.2385 29.656 17.8247 29.656H19.4173C19.4173 26.6899 20.4229 23.9587 22.1117 21.7846Z" fill="white"/>
<path d="M43.5937 29.6567C43.5937 35.6286 38.7526 40.4697 32.7807 40.4697C26.8089 40.4697 21.9678 35.6286 21.9678 29.6567C21.9678 23.6849 26.8089 18.8438 32.7807 18.8438C38.7526 18.8438 43.5937 23.6849 43.5937 29.6567Z" fill="#6778E3"/>
<g clip-path="url(#clip0_4418_5181)">
<path d="M32.7813 31.4826C33.7902 31.4826 34.6081 30.6647 34.6081 29.6558C34.6081 28.647 33.7902 27.8291 32.7813 27.8291C31.7724 27.8291 30.9546 28.647 30.9546 29.6558C30.9546 30.6647 31.7724 31.4826 32.7813 31.4826Z" fill="white"/>
<path d="M39.6041 29.5011C39.067 28.1117 38.1345 26.9101 36.9219 26.0449C35.7093 25.1797 34.2698 24.6888 32.7812 24.6328C31.2927 24.6888 29.8531 25.1797 28.6406 26.0449C27.428 26.9101 26.4955 28.1117 25.9584 29.5011C25.9221 29.6014 25.9221 29.7113 25.9584 29.8116C26.4955 31.201 27.428 32.4026 28.6406 33.2678C29.8531 34.133 31.2927 34.6239 32.7812 34.6799C34.2698 34.6239 35.7093 34.133 36.9219 33.2678C38.1345 32.4026 39.067 31.201 39.6041 29.8116C39.6404 29.7113 39.6404 29.6014 39.6041 29.5011ZM32.7812 32.6248C32.1941 32.6248 31.6202 32.4507 31.1321 32.1245C30.6439 31.7984 30.2634 31.3347 30.0387 30.7923C29.8141 30.2499 29.7553 29.6531 29.8698 29.0772C29.9844 28.5014 30.2671 27.9725 30.6822 27.5573C31.0974 27.1422 31.6263 26.8595 32.2021 26.7449C32.7779 26.6304 33.3748 26.6892 33.9172 26.9139C34.4596 27.1385 34.9232 27.519 35.2494 28.0072C35.5756 28.4953 35.7497 29.0692 35.7497 29.6564C35.7485 30.4433 35.4354 31.1976 34.8789 31.754C34.3225 32.3105 33.5682 32.6236 32.7812 32.6248Z" fill="white"/>
</g>
<defs>
<clipPath id="clip0_4418_5181">
<rect width="14.6139" height="14.6139" fill="white" transform="translate(25.4746 22.3486)"/>
</clipPath>
</defs>
</svg>

  )
}

export default Viewbank;