import React, { useState } from 'react'
import GlobalModal from 'src/components/GlobalModal';
import styled, { withTheme } from 'styled-components';
import config from 'src/config/config';
import { theme } from 'styled-tools';
import { Controller } from 'react-hook-form';
import { LoginInput } from 'src/components/Auth/Login/Login';
import { useForm } from "react-hook-form";
import Intl from "src/components/common/Intl";
import { SubmitBtn } from 'src/components/udb/commonStyle';
import { useNavigate } from "react-router-dom";
import { checkPhoneNumber, sendOtp, setAccountInfoData } from 'src/config/api';
import { Notify } from 'src/components/Notification';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import { withAppContext } from 'src/store/initAppContext';
import VerifiedIconFun from 'src/components/icon/VerifiedIconFun';
import { AES, pad, mode, enc } from "crypto-js";
import { OtpButton, PhoneNumberAndOtpWrap as UserRegisterPopupPhNuAndOtpWrap, PhoneNumberAndVerifyWrap } from 'src/components/Auth/commonStyles';
import OTPTimer from 'src/pages/auth/Signup/OTPTimer';
import HideIcon from "src/components/icon/Hide";
import VerifyIcon from "../../../images/check-circle.png";
import ArrowIcon from "../../../images/arrow-right-circle.png";


type UserDataModelProps = {
    successhandler: () => void;
    userData: any
    theme?: any
    app?: any
}

type UserOptions = {
    firstName: string;
    lastName: string;
    email?: string;
    phoneNumber?: number;
    otpCode?: number
};

function UserRegisterPopup(props: UserDataModelProps) {
    const [loading, setLoader] = useState(false);
    const [otpHandle, setOtpHandle] = useState(false);
    const [otpStatus, setOtpStatus] = useState(false);
    const [showTimer, setShowTimer] = useState(false);
    const [showResend, setShowResend] = useState(false);
    const [otpLoader, setOtpLoader] = useState(false);
    const [isSuccess, setIsSuccess] = useState(false);
    const [networkMessage, setNetworkMessage] = useState("");
    const [isExistingPhoneNumber, setIsExistingPhoneNumber] = useState(false);
    const { successhandler, userData, theme, app: { brandId } } = props;

    const { config: { showOnlyOnFun } } = theme;

    const NoneIcon = `${"static/Image/"}`;

    const Icons = { ArrowIcon, VerifyIcon, NoneIcon };

    const TOKEN_SECRET_KEY = config.secretKey;

    const navigate = useNavigate();

    const {
        handleSubmit,
        control,
        reset,
        setValue,
        formState: { isValid, errors },
        getValues,
    } = useForm({
        mode: "all",
    });

    const [previousValue, setPreviousValue] = useState(userData.phoneNumber ? userData.phoneNumber : "");

    const onCloseHandler = () => {
        navigate('/member/profile/bankdetails');
    }

    const submitHandler = (formData: any, event: any) => {
        event.preventDefault();
        event.stopPropagation();
        setLoader(true);

        const {
            firstName,
            lastName,
            email,
            phoneNumber,
            otpCode
        }: UserOptions = formData;

        let mobileNumber = (userData.phoneNumber && brandId === 46 && !userData.phoneNumberVerified) ? userData.phoneNumber : phoneNumber;

        const params = JSON.stringify({
            ...userData,
            firstName: userData.firstName && (userData.firstName === firstName) ? userData.firstName : firstName,
            lastName:  userData.lastName && (userData.lastName === lastName) ? userData.lastName : lastName,
            email,
            ...(showOnlyOnFun &&
            {
                phoneNumber: mobileNumber,
                otp: otpCode
            })
        });


        setAccountInfoData(params).then(res => {
            if (res?.data?.success) {
                Notify.success('Account details updated successfully');
                successhandler();
                reset();
            }


        }).catch((e: any) => {
            setLoader(false);
            // reset(
            //     {
            //         otpCode: "",
            //     },
            //     {
            //         keepErrors: false, // Clears all errors
            //         keepDirty: false,  // Resets all fields to pristine state
            //         keepTouched: false, // Resets all touched fields
            //         keepValues: false, // Resets values to initial/default values
            //     })
            let error = 'Something went wrong please try again2'
            if (e.response && e.response.data) {
                error = e.response.data.error;
            }
            Notify.error(error);
        });
    }

    const sendOTP = async (event: React.MouseEvent<HTMLElement>, formData: any) => {
        event.preventDefault();
        event.stopPropagation();

        setOtpLoader(true);
        setShowResend(false);
        setOtpStatus(true);

        const formvalue = formData();
        const info = JSON.stringify({
            phoneNumber: (userData.phoneNumber && brandId === 46 && !userData.phoneNumberVerified) ? userData.phoneNumber : formvalue.phoneNumber,
            phoneCountry: "in",
            brandId: brandId
        });

        const encryptedTarget = enc.Utf8.parse(info);
        const encryptSecretKey = enc.Utf8.parse(TOKEN_SECRET_KEY);
        const authInfo = AES.encrypt(encryptedTarget, encryptSecretKey, {
            iv: enc.Utf8.parse(""),
            padding: pad.Pkcs7,
            mode: mode.CBC,
        }).ciphertext.toString();

        let data = {
            registerInfo: authInfo,
        };

        sendOtp(data)
            .then((response: any) => {
                setOtpHandle(true);
                //setOtpStatus(true);
                setOtpLoader(false);

                // setShowTimer(true);
                // setTimeout(() => {
                //   setShowTimer(false);
                //   setShowResend(true);
                // }, 75000);

                setNetworkMessage('');
                //for CRM
                // CRMOTPBtnClick(formvalue.phoneNumber, brandId, formvalue.userName) //for CRM
            })
            .catch((error: any) => {
                let errorResponse = error.response?.data;
                setOtpHandle(false);
                //setOtpStatus(false);
                setIsSuccess(false);
                setNetworkMessage(
                    errorResponse?.success === false && errorResponse?.error
                );
                setOtpLoader(false);
            })
            .finally(() => {

                setShowTimer(true);
                setTimeout(() => {
                    setShowTimer(false);
                    setShowResend(true);
                }, 75000);

            })
    };

    const formatInput = (e) => {
        // Prevent characters that are not numbers ("e", ".", "+" & "-") ✨
        let checkIfNum;
        if (e.key !== undefined) {
            // Check if it's a "e", ".", "+" or "-"
            checkIfNum =
                e.key === "e" ||
                e.key === "." ||
                e.key === "+" ||
                e.key === "-" ||
                e.key === "ArrowUp" ||
                e.key === "ArrowDown" ||
                e.key === "E";
        } else if (e.keyCode !== undefined) {
            // Check if it's a "e" (69), "." (190), "+" (187) or "-" (189)
            checkIfNum =
                e.keyCode === 69 ||
                e.keyCode === 190 ||
                e.keyCode === 187 ||
                e.keyCode === 189;
        }
        return checkIfNum && e.preventDefault();
    };

    const formatTextInput = (e: any, allowSpecialChars: boolean) => {
        let disallowedKeys;
    
        if (allowSpecialChars) {
            // Allow spaces and dots if `allowSpecialChars` is true
            disallowedKeys =
                e.key === "+" ||
                e.key === "-" ||
                e.key === "ArrowUp" ||
                e.key === "ArrowDown";
        } else {
            // Disallow spaces, dots, and other special characters
            disallowedKeys =
                e.key === " " ||
                e.key === "." ||
                e.key === "+" ||
                e.key === "-" ||
                e.key === "ArrowUp" ||
                e.key === "ArrowDown";
        }
    
        // Prevent input if the key is disallowed
        if (disallowedKeys) {
            e.preventDefault();
        }
    };


    const resetPopup = () => {
        reset(
            {
                firstName: "",
                lastName: "",
                email: "",
                phoneNumber: "",
                otpCode: "",
            },
            {
                keepErrors: false, // Clears all errors
                keepDirty: false,  // Resets all fields to pristine state
                keepTouched: false, // Resets all touched fields
                keepValues: false, // Resets values to initial/default values
            }
        );
        setIsExistingPhoneNumber(false);
        setPreviousValue("");
        setNetworkMessage("");
        setOtpStatus(false);
        setShowResend(false);
        setShowTimer(false);
        setOtpHandle(false);
    };   

    const handleValueChange = (value: any) => {
        // Clear states if value matches the existing phone number
        if (userData.phoneNumber === value) {
            setIsExistingPhoneNumber(false);
            setNetworkMessage("");
            setPreviousValue("");
            return;
        }


         // Call validation if input has 10 digits
        if (value.length === 10 && value !== previousValue) {
            setPreviousValue(value); // Update the previous value
            handlePhoneNumberValidation(value); // Trigger API validation
        } else {
            setPreviousValue(value); // Update only the previous value
        }
    };
  
    //phone number existing check for phone number verification
    const handlePhoneNumberValidation = (value: string) => {
       
        let data = {
            phoneNumber: (userData.phoneNumber && brandId === 46 && !userData.phoneNumberVerified) ? userData.phoneNumber : value,
        };

        if (data.phoneNumber !== "" && data.phoneNumber.length === 10) {
            checkPhoneNumber(data).then((res: any) => {
                const { message } = res.data;
                if (message && message.is_phone_number_exists) {
                    setIsExistingPhoneNumber(true)
                    setNetworkMessage("Phone Number already exists")
                } else {
                    setNetworkMessage("")
                    setIsExistingPhoneNumber(false)
                }
            }).catch((err: any) => {
                console.error(err.data)
            })
        }
    }


    let isDisabled = true;
    let isOtpHandled = false;

    if (showOnlyOnFun && userData.phoneNumberVerified && !isExistingPhoneNumber){
        isOtpHandled = true;
    } else {
        isOtpHandled = otpHandle;
    }

    if (showOnlyOnFun ? (isOtpHandled && isValid && !loading) : (isValid && !loading)) {
        isDisabled = false;
    }



    return (
        <GlobalPopUpWrapper>
            <GlobalModal isModalOpen={true}>
                <PopUpcontainer>
                    <button
                        className="close"
                        type="button"
                        onClick={() => onCloseHandler()}
                    ></button>
                    <PopUpActionDiv>
                        <ImgSection>
                            <LazyLoadImage src={`${config.imageV3Host}/static/cashsite/${theme.image.userPopupCashImg}`} />
                        </ImgSection>
                        <Typo><Intl langKey="USERREGPOPUP@Text" /></Typo>
                        <form onSubmit={handleSubmit(submitHandler)}>
                            <PopUpInputWrapper>
                                <Controller
                                    name="firstName"
                                    control={control}
                                    defaultValue={userData.firstName ? userData.firstName : ""}
                                    rules={{
                                        required: {
                                            value: (!userData.firstName && !userData.phoneNumberVerified) ? true : (!userData.firstName && userData.phoneNumberVerified) ? true : false,
                                            message: " *Please provide the first name",
                                        },
                                        pattern: {
                                            value: brandId === 47
                                                ? /^[a-zA-Z0-9 .]+$/
                                                : /^[a-zA-Z0-9]+$/,
                                            message: brandId === 47
                                                ? "* Name can only contain letters, numbers, spaces, and dots"
                                                : "* Name must not contain special characters and spaces",
                                        },
                                        minLength: { value: 4, message: "* Minimum Character : 4" },
                                        maxLength: { value: 18, message: "* Maximum Character : 18" },
                                    }}

                                    render={({

                                        field: { onChange, value },
                                        fieldState: { error },

                                    }) => (
                                        <>
                                            <LoginInput
                                                id="firstName"
                                                onChange={onChange}
                                                value={value}
                                                error={error}
                                                placeholder={<Intl langKey="SIGNUP@FIRSTNAME" />}
                                                onKeyDown={(e) => formatTextInput(e, brandId === 47)}
                                                type="text"
                                                req={true}
                                                readonly={(userData.firstName && userData.phoneNumberVerified)? true : false}
                                                autoComplete="new-password"

                                                name={'firstName'}
                                            />
                                            <h3>{error && error.message}</h3>
                                        </>
                                    )}
                                />
                                <Controller
                                    name="lastName"
                                    control={control}
                                    defaultValue={userData.lastName ? userData.lastName : ""}
                                    rules={{
                                        required: {
                                            value: (!userData.lastName && !userData.phoneNumberVerified) ? true : (!userData.lastName && userData.phoneNumberVerified) ? true : false,
                                            message: " *Please provide the last name",
                                        },
                                        pattern: {
                                            value: brandId === 47
                                                ? /^[a-zA-Z0-9 .]+$/
                                                : /^[a-zA-Z0-9]+$/,
                                            message: brandId === 47
                                                ? "* Name can only contain letters, numbers, spaces, and dots"
                                                : "* Name must not contain special characters and spaces",
                                        },
                                        minLength: { value: 4, message: "* Minimum Character : 4" },
                                        maxLength: { value: 18, message: "* Maximum Character : 18" },
                                    }}
                                    render={({
                                        field: { onChange, value },
                                        fieldState: { error },
                                    }) => (
                                        <>
                                            <LoginInput
                                                id="lastName"
                                                onChange={onChange}
                                                // onKeyDown={formatTextInput}
                                                value={value}
                                                error={error}
                                                placeholder={<Intl langKey="SIGNUP@LASTNAME" />}
                                                onKeyDown={(e) => formatTextInput(e, brandId === 47)}
                                                type="text"
                                                req={true}
                                                readonly={(userData.lastName && userData.phoneNumberVerified) ? true : false}
                                                autoComplete="new-password"
                                                name={'lastName'}
                                            />
                                            <h3>{error && error.message}</h3>
                                        </>
                                    )}
                                />
                                <Controller
                                    name="email"
                                    control={control}
                                    defaultValue={""}
                                    rules={{
                                        required: {
                                            value: false,
                                            message: " *Please provide valid email",
                                        },
                                        pattern: {
                                            value:
                                                /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
                                            message: "* Email is invalid",
                                        },
                                    }}
                                    render={({
                                        field: { onChange, value },
                                        fieldState: { error },
                                    }) => (
                                        <>
                                            <LoginInput
                                                id="email"
                                                onChange={onChange}
                                                value={value}
                                                error={error}
                                                placeholder={<Intl langKey="TITLE@Email" />}
                                                type="text"
                                                req={false}
                                                autoComplete="new-password"
                                                name={'email'}
                                            />
                                            <h3>{error && error.message}</h3>
                                        </>
                                    )}
                                />
                                {brandId === 47 && !userData.phoneNumberVerified ?
                                    <>
                                        <Controller
                                            name="phoneNumber"
                                            control={control}
                                            defaultValue={userData.phoneNumber ? userData.phoneNumber : ""}
                                            rules={{
                                                required: {
                                                    value: !userData.phoneNumber && true,
                                                    message: "Please provide mobile number",
                                                },
                                                pattern: { value: /^[6-9]\d{9}$/, message: "Enter valid number" },
                                                minLength: { value: 10, message: "* Minimum Character : 10" },
                                                maxLength: { value: 10, message: "* Too Many Characters" },
                                            }}
                                            render={({
                                                field: { onChange, value },
                                                fieldState: { error, isTouched },
                                                formState: { isValid },
                                            }) => (
                                                <>
                                                    <PhoneNumberAndOtpWrap>
                                                        <p>+91</p>
                                                        <LoginInput
                                                            id="phoneNumber"
                                                            onChange={(e) => {
                                                                const inputValue = e.target.value;
                                                                onChange(inputValue); // Update form state
                                                                handleValueChange(inputValue); // Custom logic
                                                            }}                                        
                                                            onKeyDown={formatInput}
                                                            onWheel={(e) => e.target.blur()}
                                                            value={value}
                                                            error={error}
                                                            placeholder={<Intl langKey="CREATE@PHONENUMBER" />}
                                                            type="number"
                                                            // onBlur={() => handleValueChange(value)}
                                                            req={true}
                                                            onPaste={(e) => {
                                                                e.preventDefault();
                                                                return false;
                                                            }}
                                                            onCopy={(e) => {
                                                                e.preventDefault();
                                                                return false;
                                                            }}
                                                            readonly={showTimer ? true : false}
                                                            className={"customInput"}
                                                            autoComplete="new-password"
                                                        />
                                                        <OtpButton
                                                            // disabled={(userData.phoneNumber !== null && userData.phoneNumber.length === 10 || value.length === 10) && !userData.phoneNumberVerified ? false : !/^\d+$/.test(value)}
                                                            disabled={
                                                                (!userData.phoneNumberVerified && !isExistingPhoneNumber 
                                                                && userData.phoneNumber !== "" && value.length === 10  && !otpHandle) ? false : true 
                                                            }
                                                            // isActive={(userData.phoneNumber !== null && userData.phoneNumber.length === 10 || value.length === 10) && !userData.phoneNumberVerified ? true : (/^\d+$/.test(value) && !(error && error.message))}
                                                            isActive={
                                                                (!userData.phoneNumberVerified && !isExistingPhoneNumber &&
                                                                value.length === 10  && !(error && error.message)) ? true
                                                                : false
                                                            }
                                                            className="otp-button"
                                                            id="signup_otpbutton"
                                                            onClick={(event) => {
                                                                if (!(error && error.message)) { return sendOTP(event, getValues) }
                                                                event.preventDefault();
                                                                event.stopPropagation();
                                                            }}
                                                        >
                                                            {!isExistingPhoneNumber && otpStatus ? (
                                                                <>
                                                                    <Intl langKey="AUTH@SENT" />{" "}
                                                                    <img className="send-otp" src={Icons.VerifyIcon} alt="send otp" />
                                                                </>
                                                            ) : (
                                                                <>
                                                                    {otpLoader ? (
                                                                        <Intl langKey="AUTH@SENDING" />
                                                                    ) : (
                                                                        <>
                                                                            {" "}
                                                                            <Intl langKey="AUTH@OTP" />{" "}
                                                                            <img className="send-otp" src={Icons.ArrowIcon} alt="ArrowIcon" />
                                                                        </>
                                                                    )}
                                                                </>
                                                            )}
                                                        </OtpButton>
                                                    </PhoneNumberAndOtpWrap>
                                                    <h3>{error && error.message}</h3>
                                                </>
                                            )}
                                        />
                                        <div className="message">{showTimer ? <OTPTimer /> : null}</div>

                                        {!isExistingPhoneNumber && showResend ? (
                                            <div className="message">
                                                {" "}
                                                <p>
                                                    *<Intl langKey="AUTH@RESENDOTP" />
                                                </p>
                                                <span onClick={(event) => sendOTP(event, getValues)}>
                                                    <Intl langKey="AUTH@RESEND" />
                                                </span>
                                            </div>
                                        ) : null}

                                        {otpHandle && (
                                            <>
                                                <Controller
                                                    name="otpCode"
                                                    control={control}
                                                    defaultValue={""}
                                                    rules={{
                                                        required: { value: true, message: "Otp is required" },
                                                        minLength: { value: 6, message: "*Minimum 6 Numbers" },
                                                    }}
                                                    render={({
                                                        field: { onChange, value },
                                                        fieldState: { error, isTouched },
                                                        formState: { isValid },
                                                    }) => (
                                                        <>
                                                            <PhoneNumberAndVerifyWrap>
                                                                <LoginInput
                                                                    id="otpCode"
                                                                    placeholder={<Intl langKey="LOGIN@ENTEROTP" />}
                                                                    type="number"
                                                                    onChange={onChange}
                                                                    onKeyDown={formatInput}
                                                                    onWheel={(e) => e.target.blur()}
                                                                    value={value}
                                                                    error={error}
                                                                    req={true}
                                                                    onPaste={(e) => {
                                                                        e.preventDefault();
                                                                        return false;
                                                                    }}
                                                                    onCopy={(e) => {
                                                                        e.preventDefault();
                                                                        return false;
                                                                    }}
                                                                    autoComplete="new-password"
                                                                />
                                                            </PhoneNumberAndVerifyWrap>
                                                            <h3>{error && error.message}</h3>
                                                        </>
                                                    )}
                                                />
                                            </>
                                        )}
                                    </>
                                    : brandId === 47 && userData.phoneNumberVerified ?
                                        <PhoneNumberAndOtpWrap>
                                            <VerifiedSec>
                                                <VerifiedSecLeft>
                                                    <h6>{userData.phoneNumber}</h6>
                                                </VerifiedSecLeft>

                                                <VerifiedSecRight>
                                                    <VerifiedIconFun />
                                                    <span>Verified</span>
                                                </VerifiedSecRight>

                                            </VerifiedSec>
                                        </PhoneNumberAndOtpWrap>
                                        : <></>
                                }
                            </PopUpInputWrapper>

                            {networkMessage && (
                                <div className={`${isSuccess ? "success-wrap" : "error-wrap"}`}>
                                    {networkMessage && networkMessage.length !== 0 && (
                                        <p className="username-error1">{networkMessage}</p>
                                    )}
                                </div>
                            )}
                            <ButtonWrapper>
                                <SubmitButton
                                    type="submit"
                                    disabled={isDisabled}
                                // onClick={onSubmit}
                                >
                                    <Intl langKey="DASHBOARD@submit" />
                                </SubmitButton>
                                <ResetBtn type="button" onClick={() => resetPopup()}>
                                    <Intl langKey="DASHBOARD@reset" />
                                </ResetBtn>
                            </ButtonWrapper>
                        </form>
                    </PopUpActionDiv>
                </PopUpcontainer>
            </GlobalModal>
        </GlobalPopUpWrapper>
    )
}

export default withTheme(withAppContext(UserRegisterPopup));

const PhoneNumberAndOtpWrap = styled(UserRegisterPopupPhNuAndOtpWrap)`
    
    p {
        width: 40px;
    }
`;

const VerifiedSec = styled.div`
    width:100%;
    border-radius: 5px;
    height: 51px;
    margin-top: 6.7px;
    color: white;
    display: flex;
    justify-content: space-between;
    align-items: center;
    border: 1px solid rgb(229 216 216);
    outline: none;
    background-color: #fff;
    height: 51px;
    margin-top: 0px !important;
    color: white;
    padding: 10px 15px;


    & svg {
        width: 20px;
        height: 20px;
    }

    & span {
        color: #33C85D;
    }

    & h6 {
        font-size: 12px;
        font-style: normal;
        font-weight: 600;
        line-height: normal;
        color: #000;
    }
`

const VerifiedSecLeft = styled.div`
    
`

const VerifiedSecRight = styled.div`
    display: flex;
    gap: 5px;
    align-items: center;

    span {
        color: #33C85D;
    }
`

const GlobalPopUpWrapper = styled.div`
    width: 100%;

    /* .modal {
        position: absolute;
    } */

    & .message {
    display: flex;
    justify-content: flex-end;
    /* margin-top: -4px; */
    font-size: 13px;

    p {
        width: 100%;
      font-size: 12px;
      margin: 0;
      color: #969595;
    }

    span {
      font-size: 12px;
      margin: 0;
      color: #04a6ff;
      text-decoration: underline;
      text-transform: uppercase;
      margin-left: 5px;
      cursor: pointer;
    }
  }
`;

const SubmitButton = styled(SubmitBtn)`
    background: ${theme('palette.UserRegisterPopUpSubmitButtonBg')};
    font-weight: 700;
    color: ${theme('palette.UserRegisterPopUpSubmitButtonColor')};
    width: 40%;

    :disabled {
        cursor: not-allowed;
        color: ${theme('palette.UserRegisterPopUpSubmitDisabledButtonColor')};
        background: ${theme('palette.UserRegisterPopUpSubmitDisabledButtonBg')};
        opacity: 0.8;
    }
`;

const ResetBtn = styled.button`
    width: 40%;
    display: flex;
    align-items: center;
    justify-content: center;
    padding-top: 5px;
    background:${theme('palette.UDBResetBtnBG')}; 
    border: none;
    box-shadow: ${theme('palette.UDBResetBtnBoxShadow')}; 
    border-radius: 7px;
    font-size: 14px;
    font-weight: 600;
    padding: 14px 30px;
    color: ${theme('palette.UDBResetBtnColor')};  
    cursor: pointer;

    :hover {
        color: ${theme('palette.UDBResetBtnHoverColor')};  
    }
`;

export const PopUpcontainer: any = styled.div`
    display: flex;
    width: 24rem;
    height: auto;
    flex-shrink: 0;
    position: relative;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    /* background: #182A3E; */
    background: ${theme('palette.UserRegisterPopUpcontainerBg')};
    border-radius: 15px;
    /* border: 0.3px solid #757E8F; */
    border: ${theme('palette.UserRegisterPopUpcontainerBorder')};

    .close {
        width: 25px;
        height: 25px;
        background-image: url(${config.imageV3Host}/static/cashsite/${theme('image.closeIcon')});
        background-size: cover;
        background-repeat: no-repeat;
        background-color: transparent;
        cursor: pointer;
        border: none;
        display: inline-block;
        margin: 0;
        padding: 0;
        position: absolute;
        right: -8px;
        top: -7px;
        border-radius: 5px;
        cursor: pointer;
        z-index: 1;
    }

`

const ImgSection = styled.div`
    display: flex;
    align-self: center;
    background-repeat: no-repeat;
    background-size: contain;
    background-position: center;
    width: 60%;
    height: 60%;

    img {
        width: 100%;
        height: 100%;
    }
`;

const PopUpInputWrapper = styled.div`
    display: flex;
    flex-direction: column;

    h3 {
        display: flex;
        margin: 0;
        padding: 0;
        color: red;
        align-items: flex-start;
        font-size: 11px;
    }

    label {
        /* background: ${theme('palette.bglabel')};
        border:${theme('palette.borderradiusLablel')};
        border-radius: 6.9px; */
        /* border: 1px solid rgb(229 216 216);
        background: linear-gradient(0deg, #FFF 0%, #FFF 100%), linear-gradient(180deg, rgba(255, 255, 255, 0.00) 0%, rgba(255, 255, 255, 0.11) 51.8%, rgba(255, 255, 255, 0.00) 100%);
        border-radius: 6.9px; */

        span {
            text-transform: capitalize;
        }
    }
`;


export const PopUpActionDiv = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100%;
    cursor: pointer;
    color: white;
    padding: 1.5rem 1.5rem 2.5rem 1.5rem;

    & .error-wrap {
    & p {
      font-size: 15px;
      text-align: center;
      color: white;
      font-weight: 500;
      background-color: #c91414;
      padding: 5px;
      border-radius: 30px;
    }
  }
  & .success-wrap {
    & p {
      font-size: 15px;
      text-align: center;
      color: white;
      font-weight: 500;
      background-color: green;
      padding: 5px;
      border-radius: 30px;
    }
  }
`

const Typo = styled.p`
    /* color: #FF5C00; */
    color: ${theme('palette.UserRegisterPopUpTypoColor')};
    text-align: center;
    font-size: 15px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    letter-spacing: 0.15px;
    text-transform: capitalize;
    padding: 1rem 2.5rem;
    margin: -3rem 0 0 0; 
`;

const ButtonWrapper = styled.div`
    margin-top: 1rem;
    display: flex;
    justify-content: flex-start;
`;
