import React from 'react'
import { withAppContext } from 'src/store/initAppContext';
import { Accordion, AccordionWrapper } from '../seo-content/seo_content';
import { Link } from 'react-router-dom';

const SportsExchangeSeoFun88 = (props: any) => {
    return (
        <AccordionWrapper>
            <Accordion>
                <input type="checkbox" id="section1" className="accordion__input" />
                <label htmlFor="section1" className="accordion__label"><h1>FUN88 BETTING EXCHANGE - THE BEST BETTING EXCHANGE SITES FOR SPORTS BETTING</h1></label>
                <div className="accordion__content">
                    <p>It's time for your passion to pay back with online sports betting with FUN88 India. We&rsquo;re the perfect <Link to="/betting-exchange">sports betting site in India</Link> with more than 10,000 games to play from. We make your entry into this arena of sports gambling even more exciting with <Link to="/promotions">personalised bonuses and promotions!</Link></p>
                    <p>Whether you still play gully cricket on the streets or enjoy cheering in the packed cricket stadium or reliving the action inside the 22-yard cricket field in front of your television screen, now is the time to make the most of the game. If you&rsquo;re just a cricket follower or an ardent fan, FUN88 offers you the best way to put your love for the game to the test with multiple betting options and a variety of bet types.</p>

                    <h2>BEST MATCH ODDS, PREMIUM BETS &amp; MORE ON FUN88 INDIA</h2>
                    <p>With pre-match betting, live-match-betting and outcome betting, FUN88 makes <Link to="/betting-exchange/online-cricket-betting">online cricket betting</Link> simpler, easier and more exciting than ever before with special bet type combinations. From the pitch to the toss to the top match scorer to the winning side, every nuance of the cricketing game is available for you to punt on. Easily accessible online on your mobile phone or laptop as you watch the match on the screen, FUN88&rsquo;s online betting app is your perfect cricket companion!</p>
                    <p>Sports betting on FUN88 covers international cricketing from across the world, encompassing all formats of the game &ndash; be it T20, test cricket and also premier leagues. With pre-match and in-play markets, competitive rates (odds), tips and analysis, FUN88 is packed with features that make it the best online betting destination. We bring our support on the ground too as we partner with the Hero Caribbean Premier League 2020 as their official betting partner.</p>

                    <h2>BET AWAY! ONLINE SPORTS BETTING MADE BETTER</h2>
                    <p>Along with cricket, there is live football sports action to gamble on as we cover everything from Premier leagues to International fixtures and youth tourneys. With betting combinations available on live games, match results, goal-scoring, events or accumulators, FUN88 has lined up a thrilling betting experience for football lovers.</p>
                    <p>With a comprehensive online sportsbook, competitive odds, on-the-go experience on our secure website and mobile app available for iOS and Android users, football fans will be hooked. Not to miss our fast settlement process and seamless payouts.</p>

                    <h2>SOCCER TO KABADDI- BET MONEY ON SPORTS, WIN REAL MONEY!</h2>
                    <p>Soccer fans will also love the support that FUN88 offers to their favourite clubs - Newcastle United Football Club, Tottenham Hotspur Football Club and others -with tickets, t-shirt giveaways and competitions! Being the official shirt sponsor and sports betting partner, FUN88 has enhanced these two sporting club brands and helped engage football fans in multiple ways.</p>
                    <p>FUN88 also has kabaddi lined up amongst the major sporting events one can bet on. One of India&rsquo;s most popular contact sports is the Pro Kabaddi League and global kabaddi games in which punters can experience real money gambling. Enjoy this spectator sport with an edge, as you can bet on kabaddi match statistics, game-winners, players and more!</p>

                    <h3>FUN88- TOP BACK LAY BETTING SITES IN INDIA</h3>
                    <p>Our entire focus on <Link to="/betting-exchange">sports betting</Link> and <Link to="/live-casino">live casino</Link> is backed by the best of technology, well-known partners and offering our fans all the analysis and competitive odds. All of this on the go with the <Link to="/app-download">FUN88 app</Link> and a reliable transaction process.</p>
                    <p>Don&rsquo;t forget to claim your free <Link to="/promotions/welcome-bonus">welcome bonus here</Link>!</p>
                    <p>Betting Exchange providers &gt; Betfair | 9wickets | Lotus</p>
                    <p>&nbsp;</p>
                </div>
            </Accordion>
        </AccordionWrapper>
    )
}

export default withAppContext(SportsExchangeSeoFun88)