export const myAccountData39 = [
    {
        id: "1",
        question: "How to sign up with FOMO7?",
        answer: "Follow these steps to sign up with FOMO7:",
        lists: [
            {
                id: "1",
                content: `Select the "Sign Up" option located at the top of the website.`
            },
            {
                id: "2",
                content: "Fill in the required details."
            },
            {
                id: "3",
                content: "Verify your phone number through OTP (One-Time Password)."
            },
            {
                id: "4",
                content: "Agree to the Terms and Conditions and then proceed by submitting the form."
            }
        ]
    },
    {
        id: 2,
        question: "How do I log in to my FOMO7 account?",
        answer: "Follow these steps to log in with FOMO7: ",
        lists: [
            {
                id: "1",
                content: "Use the login information provided during the sign-up process.",
            },
            {
                id: "2",
                content: "Enter your username and password.",
            },
            {
                id: "3",
                content: "You're all set for the best online gaming journey on FOMO7!",
            }
        ]
    },
    {
        id: "3",
        question: "Why FOMO7?",
        answer: "At FOMO7, you can enjoy the best of both worlds - Casino Games and Sports Betting.",
        lists: [
            {
                id: "1",
                content: "700% Welcome Bonus",
            },
            {
                id: "2",
                content: "21% Referral Bonus",
            },
            {
                id: "3",
                content: "5% Redeposit Bonus",
            },
            {
                id: "4",
                content: "Other Special Promotions (Check out our FOMO7 Bonus Page)",
            },
            {
                id: "5",
                content: "24x7 Customer Support (Best in the Industry)",
            },
        ]
    },
    {
        id: "4",
        question: "How Can I update my personal information?",
        answer: "Follow these steps to update your personal details -",
        lists: [
            {
                id: "1",
                content: "Log in to your FOMO7 account:",
            },
            {
                id: "2",
                content: "Click the dropdown arrow beside your username.",
            },
            {
                id: "3",
                content: `Select "Profile."`,
            },
            {
                id: "4",
                content: `Navigate to "Account Info."`,
            },
            {
                id: "5",
                content: `Choose the "Edit" option to update your details, then click "Save."`,
            },
        ]
    },
    {
        id: "5",
        question: "How do I reset my FOMO7 account password?",
        answer: "Follow these steps to reset your account password - ",
        lists: [
            {
                id: "1",
                content: "Log in to your FOMO7 account.",
            },
            {
                id: "2",
                content: "Locate the dropdown arrow beside your username.",
            },
            {
                id: "3",
                content: `Choose "Profile."`,
            },
            {
                id: "4",
                content: `Select the "Password" option.`,
            },
            {
                id: "5",
                content: "Enter your current account password, new account password, and confirm the new account password.",
            },
            {
                id: "6",
                content: `Click "Reset" to update your password.`,
            },
        ],
        redirect: ""
    },
    {
        id: "6",
        question: "Where to find my betting history?",
        answer: "To check your past betting details, follow the instructions - ",
        lists: [
            {
                id: "1",
                content: "Log in to your FOMO7 account.",
            },
            {
                id: "2",
                content: "Click the dropdown arrow located next to your username.",
            },
            {
                id: "3",
                content: `Choose "Bet Details" to see your Current Bet and Past Bet.`,
            }
        ]
    },
    {
        id: "7",
        question: "Where can I find the OTP to register with FOMO7, as I haven't received it yet?",
        answer: "Please verify the mobile number you entered. If the issue continues, even if your mobile number is within network coverage, please contact customer care for further assistance.",
        lists: [],
    },
    {
        id: "8",
        question: "Where do I find my account balance?",
        answer: "Upon logging in to your FOMO7 account, your balance will be displayed next to your username. If you cannot see your balance on the homepage, please contact customer care for help.",
        lists: []
    },
    {
        id: "9",
        question: "What happens if I lose internet connection during gameplay?",
        answer: "The current game session may end if you lose your internet connection during gameplay. However, once you reconnect, you can resume playing new games.",
        lists: []
    },
    {
        id: "10",
        question: "How often can I play on FOMO7?",
        answer: "There are no limitations on game duration at FOMO7. Players can enjoy casino games for an unlimited period.",
        lists: []
    },
];

export const withDrawal39 = [
    {
        id: "1",
        question: "How do I withdraw my balance?",
        answer: "To withdraw your balance:",
        lists: [
            {
                id: "1",
                content: "Click on the dropdown arrow next to your username",
            },
            {
                id: "2",
                content: `Choose the "Finances" option`,
            },
            {
                id: "3",
                content: `Select "Withdrawal"`,
            }
        ],
        redirect: ""
    },
    {
        id: "2",
        question: "Can I get my winnings directly to my bank account?",
        answer:
            "Players can transfer their winnings to their bank account once they fulfill the withdrawal limit requirements.",
        lists: [],
        redirect: ""
    },
    {
        id: "3",
        question: "Is it possible to transfer funds from one FOMO7 account to another?",
        answer: "No, you cannot transfer money from one FOMO7 account to another.",
        lists: [],
        redirect: ""
    },
    {
        id: "4",
        question: "What should I do if my funds are stuck?",
        answer: "",
        lists: [
            {
                id: "1",
                content: "Click on the dropdown arrow beside your username.",
            },
            {
                id: "2",
                content: `Choose "Finances" from there. `,
            },
            {
                id: "3",
                content: `Select "Wallet Transfer" and opt to transfer all funds to your main wallet.`,
            }
        ],
        redirect: ""
    },
    {
        id: "5",
        question: "Does INR apply to all payment methods?",
        answer: "Yes, INR is applicable to all payment methods.",
        lists: [],
        redirect: ""
    },
    {
        id: "6",
        question: "Can I withdraw my entire balance?",
        answer: "Once the roll-over requirements are met, you can withdraw the entire amount anytime. Just ensure to review the withdrawal limit before proceeding with the withdrawal.",
        lists: [],
        redirect: ""
    },
    {
        id: "7",
        question: "Can I keep some money and withdraw some money?",
        answer: " Yes, You can keep some money and take out the rest.",
        lists: [],
        redirect: ""
    },
    {
        id: "8",
        question: "Can I cancel my withdrawal?",
        answer: "Yes, you can cancel your withdrawal. For further assistance, contact the customer service. ",
        lists: [],
        redirect: ""
    },
    {
        id: "9",
        question: "How much time does the withdrawal process usually take?",
        answer: "The Insta withdrawal takes 15-20 minutes, while regular withdrawals may take 1 to 2 days. Additionally, withdrawal requests between 12:00 AM and 6:00 AM might experience extended processing times.",
        lists: [],
        redirect: ""
    },
    {
        id: "10",
        question: "How Many Withdrawal Accounts Can I Have?",
        answer: "You can have 3 withdrawal accounts.",
        lists: [],
        redirect: ""
    },
    {
        id: "11",
        question: "Why was my withdrawal placed on hold? ",
        answer: "Most withdrawals are either held or canceled for the following reasons:",
        lists: [
            {
                id: "1",
                content: "Betting criteria weren't met",
            },
            {
                id: "2",
                content: "Withdrawal was requested for a different account",
            },
            {
                id: "3",
                content: "The withdrawal amount wasn't correctly filled out in the form!",
            }
        ],
        redirect: ""
    },
    {
        id: "12",
        question: "How To Add A New Withdrawal Account?",
        answer: "To add a new withdrawal account, follow these steps:",
        lists: [
            {
                id: "1",
                content: "Click on the dropdown arrow beside your username",
            },
            {
                id: "2",
                content: `Choose the "Finances" option`,
            },
            {
                id: "3",
                content: `Select "Withdrawal"`,
            },
            {
                id: "4",
                content: `You can now add or edit your account information from here.`,
            }
        ],
        redirect: ""
    }
];

export const deposit39 = [
    {
        id: "1",
        question: "How often can I make deposits?",
        answer: "Players can deposit as many times as they wish to enjoy their favorite casino games and sports betting.",
        lists: [],
        redirect: ""
    },
    {
        id: "2",
        question: "Which deposit option is good?",
        answer: "FOMO7 offers a range of deposit options, including Fast Bank (NEFT, RTGS, and IMPS), Fast UPI, Regular UPI, and Net Banking. All of these options ensure hassle-free deposits. Choose the option that suits you best. ",
        lists: [],
        redirect: ""
    },
    {
        id: "3",
        question: "What should I do if my deposit isn't credited?",
        answer: "There's no need to worry if your deposit hasn't been credited. Your funds are secure with us. If you still don't see the credited amount, please check again in an hour or contact our customer service team for further assistance.",
        lists: [],
        redirect: ""
    },
    {
        id: "4",
        question: "How can I deposit?",
        answer: "",
        lists: [
            {
                id: "1",
                content: "Login to your FOMO7 account",
            },
            {
                id: "2",
                content: `Click on ‘Deposit’`,
            },
            {
                id: "3",
                content: `Choose your preferred payment mode`,
            },
            {
                id: "4",
                content: `Enter the amount and deposit `,
            }
        ],
        redirect: ""
    },
    {
        id: "5",
        question: `When will my wallet be credited if the status is 'Initiated'? `,
        answer: "After the status indicates 'Initiated,' wait for your amount to be credited to your wallet; if not, please contact customer service.",
        lists: [],
        redirect: ""
    },
    {
        id: "6",
        question: "How many ways can deposits be made?",
        answer: "Multiple deposit modes, such as Fast Bank, Fast UPI, Regular UPI, and Net Banking, are available.",
        lists: [],
        redirect: ""
    },
    {
        id: "7",
        question: "With one deposit, can I bet on games and sports?",
        answer: "With sufficient balance, you can place bets on various games and sports using a single deposit.",
        lists: [],
        redirect: ""
    },
    {
        id: "8",
        question: "Is it possible to deposit at any time of the day?",
        answer: "Yes, you can deposit any time of the day.",
        lists: [],
        redirect: ""
    },
    {
        id: "9",
        question: "Where Can I See My Transaction History?",
        answer: "",
        lists: [
            {
                id: "1",
                content: "Tap on the dropdown arrow next to your username",
            },
            {
                id: "2",
                content: "Choose ‘Finances’",
            },
            {
                id: "3",
                content: "Select the ‘History’ option to find out your transaction details",
            }
        ],
        redirect: ""
    }
];

export const bonusAndPromotion39 = [
    {
        id: "1",
        question: "What are the bonuses available on FOMO7?",
        answer: "FOMO7 provides several bonuses to players, such as the Welcome Bonus, Redeposit Bonus, and Referral Bonus. Explore the Bonus page for more details on available bonuses.",
        lists: []
    },
    {
        id: 2,
        question: "Can I claim all the bonuses available on FOMO7?",
        answer: "You can receive a bonus if you meet the Terms and Conditions associated with that particular bonus.",
        lists: []
    },
    {
        id: "3",
        question: "How much is the FOMO7 Welcome Bonus?",
        answer: "At FOMO7, the Welcome Bonus is 700%.",
        lists: []
    },
    {
        id: "4",
        question: "How much is the FOMO7 Redeposit Bonus?",
        answer: "At FOMO7, the redeposit bonus is up to 5%.",
        lists: []
    },
    {
        id: "5",
        question: "How much is the Referral Bonus?",
        answer: "At FOMO7, the referral bonus is up to 21%.",
        lists: [],
        redirect: ""
    },
    {
        id: "6",
        question: "Is there any validity for Bonuses?",
        answer: "The availability of bonuses depends on the selected bonus program, and they may expire if not utilized within the designated time frames. Check the bonus page for more details. ",
        lists: []
    },
    {
        id: "7",
        question: "What is RO on FOMO7?",
        answer: " RO stands for Roll Over, which requires you to fulfill a valid Roll Over condition to access your bonus. The valid Roll Over calculation is as follows:",
        lists: [
            {
                id: "1",
                content: "| PL | ≥ 50% TOTAL STAKE",
            },
        ],
    },
    {
        id: "8",
        question: "How much is the Roll Over?",
        answer: "Roll Over requirements differ for each promotion. Review the details of all promotions to understand the specific Roll Over for each.",
        lists: []
    },
    {
        id: "9",
        question: "How do I claim my FOMO7 bonus amount?",
        answer: "You don’t have to claim; the bonuses will be automatically credited to your account. While most users receive their bonuses on time, if you experience any delays, please contact the FOMO7 Customer Care team.",
        lists: []
    },
    {
        id: "10",
        question: "Is it possible to monitor the total amount of bonuses I've received?",
        answer: "",
        lists: [
            {
                id: "1",
                content: "Sign in to your FOMO7 account.",
            },
            {
                id: "2",
                content: "Select the dropdown arrow next to your username.",
            },
            {
                id: "3",
                content: `Choose "Promotions."`,
            },
            {
                id: "4",
                content: "View all the bonuses you've received so far.",
            },
        ]
    },
    {
        id: "11",
        question: "Can I receive regular updates regarding FOMO7 new promotions?",
        answer: "Yes, you can stay informed about new promotions by regularly visiting the website/app or following FOMO7 on social media platforms.",
        lists: []
    },
];

export const appQuery39 = [
    {
        id: "1",
        question: "Can I use the same login credentials for both the FOMO7 website and app?",
        answer: "Yes, you can use the same credentials for the FOMO7 website and app access.",
        lists: []
    },
    {
        id: 2,
        question: "How do I sign up on the app?",
        answer: "To sign up on the app:",
        lists: [
            {
                id: "1",
                content: "Download the FOMO7 app onto your device.",
            },
            {
                id: "2",
                content: `Tap on "Sign Up."`,
            },
            {
                id: "3",
                content: "Enter the necessary details, including username, password, mobile number, and OTP.",
            },
            {
                id: "4",
                content: `Tap "Submit" to create your FOMO7 account.`,
            },
        ]
    },
    {
        id: "3",
        question: "Is it possible to get my wallet balance on the website?",
        answer: "Yes, you can get your wallet balance from the website.",
        lists: []
    },
    {
        id: "4",
        question: "How many casino games are there on the FOMO7 app?",
        answer: "The FOMO7 app offers 700+ casino games, sports, virtual sports, and more for betting, providing the same options on the website.",
        lists: []
    },
    {
        id: "5",
        question: "Can I access the cricket live betting option on the app?",
        answer: "Yes, the cricket live betting option is available on the FOMO7 app.",
        lists: [],
        redirect: ""
    },
    {
        id: "6",
        question: "How do I deposit through the FOMO7 app?",
        answer: "To deposit funds on the FOMO7 app:",
        lists: [
            {
                id: "1",
                content: "Download the FOMO7 app on your device.",
            },
            {
                id: "2",
                content: "Tap the dropdown arrow next to your username.",
            },
            {
                id: "3",
                content: `Select "Deposit."`,
            },
            {
                id: "4",
                content: "Choose your preferred deposit method and enter the preferred betting amount.",
            },
            {
                id: "5",
                content: "Complete the deposit process and begin playing your favorite games.",
            }
        ]
    },
    {
        id: "7",
        question: "How to withdraw through the FOMO7 app?",
        answer: "To withdraw funds using the FOMO7 app:",
        lists: [
            {
                id: "1",
                content: "Install the FOMO7 app on your device.",
            },
            {
                id: "2",
                content: "Tap the dropdown arrow next to your username.",
            },
            {
                id: "3",
                content: `Select "Withdrawal."`,
            },
            {
                id: "4",
                content: "Choose your preferred withdrawal method and enter the withdrawal amount.",
            }
        ],
    },
    {
        id: "8",
        question: "Is customer support on the app available around the clock?",
        answer: "FOMO7’s customer support is available 24/7 to assist its valuable players.",
        lists: []
    },
    {
        id: "9",
        question: "Can I use UPI option to deposit on the FOMO7 App?",
        answer: "Yes, you can deposit using the UPI payment option. You will also have all the other popular payment options.",
        lists: []
    },
    {
        id: "10",
        question: "How to download the FOMO7 app?",
        answer: "",
        lists: [
            {
                id: "1",
                content: "If browning FOMO7 through the website, scan the QR code located at the top right corner of the homepage or click on ‘Download’ to install the app Download the latest version of the FOMO7 app now!",
            },
            {
                id: "2",
                content: `If browsing using your mobile device, navigate to the Menu and choose "Download Android App." Follow the instructions to install the app.`,
            }
        ]
    },
];

export const casinoGames39 = [
    {
        id: "1",
        question: "How many games are available on FOMO7?",
        answer: "On FOMO7, there are 700+ casino games available.",
        lists: []
    },
    {
        id: 2,
        question: "Can I place bets on every casino game?",
        answer: "Yes, you can bet on all casino games.",
        lists: []
    },
    {
        id: "3",
        question: "How do I locate Casino Games?",
        answer: "",
        lists: [
            {
                id: "1",
                content: "Sign in to your FOMO7 account via the website or app.",
            },
            {
                id: "2",
                content: `Navigate to the 'Casino,' 'Instant Games,' or 'Hot Games' sections.`,
            },
            {
                id: "3",
                content: "Browse the available casino games and choose the one you wish to play.",
            }
        ]
    },
    {
        id: "4",
        question: "Which provider is the best for casino games?",
        answer: "On the FOMO7 platform, there are multiple casino game providers and all of them are good, providing top casino games.",
        lists: []
    },
    {
        id: "5",
        question: "What are the popular casino games on FOMO7?",
        answer: "All the casino games offered on FOMO7 are popular, with the most frequently played ones including JetX, Aviator, Roulette, Dragon Tiger, Teenpatti, Andar Bahar, Crazy Time, Dream Catcher, and many others.",
        lists: [],
        redirect: ""
    },
    {
        id: "6",
        question: "Should I deposit funds into each individual casino game, or should I only top up my FOMO7 wallet?",
        answer: "You can play any casino game or make any bet by adding money to your wallet.",
        lists: []
    },
    {
        id: "7",
        question: "Can I get a bonus for casino games?",
        answer: "",
        lists: [
            {
                id: "1",
                content: "The Welcome bonus applies to both casino and sports betting. All bonuses can be used for casino games, too. You can use the bonus amount in your wallet to play any casino game or place bets on any sport.",
            },
            {
                id: "2",
                content: "However, there may be some special promotions that are exclusive to specific categories, so be sure to check the terms and conditions.",
            }
        ],
    },
    {
        id: "8",
        question: "Are there any seasonal promotions related to the casino?",
        answer: "Yes, FOMO7 provides a range of seasonal bonuses related to the casino. Explore the Bonus page to discover these exciting promotions.",
        lists: []
    },
    {
        id: "9",
        question: "Are all the casino games real time?",
        answer: "Yes, all the casino games on FOMO7 are played in real time.",
        lists: []
    },
    {
        id: "10",
        question: "Playing casino games on FOMO7 is safe or not?",
        answer: "Yes, playing casino games on FOMO7 is completely safe and secure. ",
        lists: []
    },
    {
        id: "11",
        question: "Where can I find tips for casino games?",
        answer: "Go through the casino game blogs to discover tips and strategies to enhance your gameplay.",
        lists: []
    },
    {
        id: "12",
        question: "Where can I find the rules of all the casino games?",
        answer: "",
        lists: [
            {
                id: "1",
                content: "Select the casino game you want to play on FOMO7.",
            },
            {
                id: "2",
                content: `Once the game starts, locate the "Rules" section. This section is available for all games, but the section name may vary, such as "Help," "Instructions," or "Game Rules."`,
            }
        ]
    },
];

export const sportsBetting39 = [
    {
        id: "1",
        question: "Does FOMO7 cover all sports?",
        answer: "FOMO7 provides various sports options, including cricket, football, tennis, volleyball, badminton, etc. Check out the ‘Sports’ section for all the sports events.",
        lists: []
    },
    {
        id: 2,
        question: "Is cricket betting available on FOMO7?",
        answer: "Yes, on FOMO7, cricket betting is available.",
        lists: []
    },
    {
        id: "3",
        question: "Is cricket betting available for all matches?",
        answer: "Yes, cricket betting is offered on all major cricket matches.",
        lists: []
    },
    {
        id: "4",
        question: "How many sports betting categories are there on FOMO7?",
        answer: "Three major sports betting categories are Exchange, Sportsbook, and Premium Sportsbook.",
        lists: []
    },
    {
        id: "5",
        question: "Which one is better - Exchange or Sportsbook?",
        answer: "Both Exchange and Sportsbook are excellent options, providing numerous benefits to players.",
        lists: [],
        redirect: ""
    },
    {
        id: "6",
        question: "Can I place bets on live matches?",
        answer: "Yes, players can bet on live matches.",
        lists: []
    },
    {
        id: "7",
        question: "What are the odds for cricket matches?",
        answer: "Before a match, odds are set based on several factors, such as teams, players, venue, weather conditions, etc. FOMO7 offers odds for the overall match outcome (win, lose, draw), individual player performances (top run-scorer, top wicket-taker), and other betting markets. These odds may fluctuate during the match. ",
        lists: [],
    },
    {
        id: "8",
        question: "Do the odds fluctuate during a match?",
        answer: "Yes, the odds can change during matches.",
        lists: []
    },
    {
        id: "9",
        question: "Is it safe to place bets on live matches?",
        answer: "Yes, betting on live matches is completely safe on FOMO7.",
        lists: []
    },
    {
        id: "10",
        question: "How do I bet on sports on FOMO7?",
        answer: "",
        lists: [
            {
                id: "1",
                content: "Sign in to your account.",
            },
            {
                id: "2",
                content: `Navigate to the 'Sports' section.`,
            },
            {
                id: "3",
                content: `Pick from Exchange, Sportsbook, or Premium Sportsbook.`,
            },
            {
                id: "4",
                content: `Choose your desired match, betting market, and place your bet.`,
            },
        ]
    },
    {
        id: "11",
        question: "Where can I find previews and predictions for upcoming matches?",
        answer: "Players can check out the FOMO7 blogs for previews and predictions of upcoming matches.",
        lists: []
    },
    {
        id: "12",
        question: "Does FOMO7 provide regular updates for cricket?",
        answer: "Yes, players can stay updated on cricket by visiting the FOMO7 website/app or following the social media platforms. ",
        lists: []
    },
];

export const security39 = [
    {
        id: "1",
        question: "Is FOMO7 safe?",
        answer: "FOMO7 is India’s leading, reliable, and trustworthy gaming platform. Employing cutting-edge technology and strict policies, FOMO7 prioritizes the protection of users' privacy. Hence, betting on this platform is safe and secure.",
        lists: []
    },
    {
        id: 2,
        question: "Is FOMO7 licensed and compliant with regulations?",
        answer: "FOMO7 is a licensed gaming company regulated by Curacao and authorized to operate worldwide. Rest assured, your information remains confidential at FOMO7 and is not disclosed to any third party.",
        lists: []
    },
    {
        id: "3",
        question: "What is the purpose of collecting my personal data during registration?",
        answer: "FOMO7 asks for your personal information to confirm your identity and safeguard your account against fraud or malicious actions.",
        lists: []
    },
    {
        id: "4",
        question: "Which option, casino or sports betting, offers a safer and more secure experience?",
        answer: "Betting on both casinos and sports is equally safe. Experience top-notch gambling by placing your bets on FOMO7.",
        lists: []
    },
    {
        id: "5",
        question: "Do the casino games use a Random Number Generator (RNG)?",
        answer: "Yes, casino games employ Random Number Generators (RNGs) to guarantee fair and unpredictable game outcomes.",
        lists: [],
        redirect: ""
    }
];