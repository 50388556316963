import React from 'react'
import { Accordion, AccordionWrapper } from '../seo-content/seo_content'
import { withTheme } from 'styled-components'
import { withAppContext } from 'src/store/initAppContext'

type Props = {
    app: any;
}

const EvolutionSEOYolo = (props: Props) => {
    const { app: { brandId } } = props;
    const domainName = (brandId === 31 || brandId === 32) ? 'Yolo247' : brandId === 33 ? 'IW247' : (brandId === 39 || brandId === 40) ? 'FOMO7' : (brandId === 46 || brandId === 47) ? 'FUN88' : 'Betdaily';

    return (
        <>
            <AccordionWrapper>
                <Accordion>
                    <input type="checkbox" id="section1" className="accordion__input" />
                    <label htmlFor="section1" className="accordion__label"><h1>EVOLUTION GAMING</h1></label>
                    <div className="accordion__content">
                        <p>
                            Evolution Gaming is much more than Blackjack, Lightning Dice, or Roulette. It has become one of the best casino game providers for many reasons. It has many game variants so that you can play your favourite games. Founded in 2006, the gaming provider has done a superb job making a name for itself.

                            Let us dive deep into Evolution Gaming and see some of the best available casino games.
                        </p>
                        <h2>Evolution Gaming - A Brief History</h2>
                        <p>Evolution Gaming was founded in 2006, but the gaming provider made rapid strides quickly thanks to its super-effective games. These games stood apart because of their real-life graphics and gameplay.</p>
                        <p>Today, games like Live Blackjack and Live Roulette are good examples of top-quality games offered by Evolution. The company has seen tremendous growth, becoming one of the best casinos in the world. The gaming provider has won multiple awards, including a double victory at the 2020 Gaming Intelligence Awards.</p>

                        <h2>Evolution Gaming: A Plethora of Casino Games</h2>
                        <p>Evolution Gaming has a plethora of casino games. Let us go through some {domainName} Evolution casino games. And we are sure you will be thrilled to play these games.</p>
                        <ul>
                            <li><h3>Live Blackjack:</h3> Live Blackjack is perhaps one of the most entertaining casino games. However, despite being played online, Blackjack's powerful graphics ensure you feel like you are in a real casino. The game begins with a dealer sitting at a table. The objective of the game is to get to 21 or close to the same number without getting over it.</li>
                            <li><h3>Lightning Dice:</h3> Lightning Dice is a compelling and exciting version of the classic dice game. You get three dice, and you roll them all. The whole point is to bet on the predicted outcomes. Just play it from the comfort of your home.</li>
                            <li><h3>Live Roulette:</h3> Roulette is one of the most simple and popular games played in a casino. In this game, you bet on the number and other outcomes. Again, you don't have to be bothered by what time or geography you are in. Live Roulette on {domainName} Evolution Gaming is available round-the-clock from the comfort of your home.</li>
                            <li><h3>Side Bet City:</h3> This is yet another exciting game offered by {domainName} Evolution games that can be played from home. In this game, the player bets whether they will win with 3, 5, or 7 cards to make the best of a three-to-five-card poker hand.</li>
                            <li><h3>Lightning Roulette:</h3> Once you enter Evolution Gaming's Lightning Roulette, you get the impression that it is their traditional Roulette game. However, this game is different from the traditional one. It follows the usual rules of Roulette. There are many betting options available in the Lightning Roulette game.</li>
                        </ul>
                        <p>Apart from the games above, the other Evolution games available on {domainName} are Dream Catcher, Fan Tan, Cash or Crash, Crazy Coin Flip, Sic Bo, etc.</p>

                        <h2>Reasons to Play Casino Games on {domainName} Evolution Gaming</h2>
                        <ul>
                            <li><h3>Compatibility:</h3> Evolution gaming, one of the modern game providers, is up-to-date with modern technology. That's why they have ensured their games are compatible with every platform, especially the smartphone. Meanwhile, when it comes to platforms, you can access the game regardless of your smartphone's operating system. It could be Android or iOS; you can play any casino game. Besides, the user interface is seamless. The new users will love it; they just have to log in and start playing.</li>
                            <li><h3>Safe & Secure:</h3> Unlike some competitors, Evolution is fully licensed and has received certifications from various gaming regulators. That's why users across the globe trust the gaming provider and play their games. It means it is a fair gaming company that you can trust. It is one of the fastest-moving companies in the industry. When it comes to expanding into new territories, Evolution outpaces its competitors. It has already expanded its markets in many countries, including India.</li>
                            <li><h3>An Array of Exciting Games:</h3> It has multiple games on {domainName}. Games such as Blackjack, Roulette, Dragon Tiger, Crazy Time, and more keep players entertained and provide an opportunity to win massive rewards. Find the games that suit your playing style, bet on them, and win exciting prizes. These features have attracted plenty of gamers to {domainName} to play Evolution games.</li>
                        </ul>

                        <h2>How to Play Evolution Games on {domainName}?</h2>
                        <p>To play Evolution games on {domainName}, follow the quick and simple steps given below:</p>
                        <ol>
                            <li>Visit the {domainName} website or app</li>
                            <li>Hover on Casino and select Live Casino Games</li>
                            <li>Under Providers on the left-hand side, choose Evolution</li>
                            <li>Pick a game</li>
                            <li>Place your bets</li>
                        </ol>

                        <p>With many games available on Evolution, casino gaming enthusiasts will have an immersive experience on {domainName}.</p>

                        <h3>FAQs</h3>
                        <p><strong>Why is Evolution among the best casino game providers in India?</strong></p>
                        <p> Evolution Gaming is among the best casino game providers in India because it has state-of-the-art technology under its wings. It has already taken some of the best interactive studios on board, which helps develop games with exceptional graphics.</p>

                        <p><strong>When was Evolution Gaming founded?</strong></p>
                        <p> Evolution was founded in 2006. Since then, it has increased, signing contracts with live dealers. The gaming platform continues to grow at a rapid rate, often expanding across various countries.</p>

                    </div>
                </Accordion>
            </AccordionWrapper>
        </>
    )
}

export default withTheme(withAppContext(EvolutionSEOYolo));