import React from 'react'

type Props = {}

const YellowTickIcon = (props: Props) => {
  return (
    <svg
    width={16}
    height={13}
    viewBox="0 0 16 13"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M2 7.8484L5.65388 11.5023L14.4232 2.0022"
      stroke="#FEDE11"
      strokeWidth={2.19233}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
  )
}

export default YellowTickIcon