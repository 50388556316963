import React, { useEffect, useState } from 'react';
import { useQuery } from '@apollo/client'
import styled from 'styled-components';
import {motion} from 'framer-motion';
// Dependent Component
import Intl from '../../components/common/Intl';
import PlatformBalanceList from '../../components/member/PlatformBalanceList';
// import TransferPanel from 'src/components/member/TransferPanel';

// GQL
import { GET_BALANCE_INFO } from 'src/graphql/game';
import { GET_CREDIT } from 'src/graphql/member';

// Utils
import media from 'src/util/mediaUtil';
import util from 'src/util/util';

// Context
import { PageTitleName } from '../../components/common/Layout';
import { IAppContextStore, withAppContext } from 'src/store/initAppContext';

// data from query
interface IBalanceInfo {
    platformCode: string;
    playerName: string;
    balance: number;
    exchangeRate?: number;
    enableFloat?: boolean;
}

export interface IPlatformInfo extends Pick<IBalanceInfo, 'platformCode' | 'balance' | 'exchangeRate' | 'enableFloat'> {
    displayName: string;
    nickName?: string;
}

const ownPlatform: IPlatformInfo = {
    platformCode: 'snk',
    displayName: 'Main Wallet',
    balance: 0,
};

const platformCodeNameMap: { [code: string]: { name: string; nickName?: string } } = {
    PlayTech: { name: 'PlayTech' },
    WACS: { name: 'We are casino', },
    BTiSports: { name: 'Fixed Odds Wallet' },
    VIRTUALSPORTS : {name: 'VIRTUALSPORTS'},
    Teenpatti: { name: 'Exchange Card Games' },
    SLottery: { name: 'Lottery' }
};

interface ITransferProps {
    app: IAppContextStore;
    triggerPage: any
}

const Transfer: React.SFC<ITransferProps> = props => {
    const {
        app: { exchangeRate, type, memberId },
    } = props;
    const [refresh, setRefresh] = useState(false)
    const [platformInfoList , setPlatformInfoList] = useState([])
    const [isQueryLoading, setIsQueryLoading] = useState(false)
    const [randomNum, setRandomNum] = useState(0);

    const PLATFORM_CODE = ['SLottery', 'WACS', 'AgTeenpatti', 'SUPERNOWA']

    const refreshTransfer = (value) => {
        setRefresh(!value)
    }

    const { loading: creditLoading, error: creditError, data: creditData, refetch: refetchCredit } = useQuery(GET_CREDIT, {
        variables: { input: { currentMember: util.getTargetString({ memberId }) } },
    });
    const {
        loading: balanceInfoLoading,
        error: balanceInfoError,
        data: balanceInfoData,
        refetch: refetchBalanceInfo,
    }: any = useQuery(GET_BALANCE_INFO, {
        variables: {
            input: {
                platformCodes: []
            }
        },
    });
    useEffect(() => {
        let list = creditData?.member?.credit;
        if (list) {
            const { limitCredit = 0, exposureCredit = 0, winlossCredit = 0 }: any = util.getValue(
                creditData,
                ['member', 'credit'],
                {},
            );
            ownPlatform.balance = (limitCredit + exposureCredit + winlossCredit) * exchangeRate;

        }
    }, [randomNum])

    useEffect(() => {
        let list = balanceInfoData?.game?.balanceInfo;
        if (list) {
            const isQueryLoading = creditLoading || balanceInfoLoading;

           
            const balanceInfoList: IBalanceInfo[] = util.getValue(
                balanceInfoData,
                ['game', 'balanceInfo'],
                [],
            );
            const platformInfoList: IPlatformInfo[] = [
                ownPlatform,
                ...balanceInfoList.map(balanceInfo => {
                    const {
                        platformCode,
                        balance,
                        exchangeRate: platformExchangeRate,
                        enableFloat,
                    } = balanceInfo;
                    return {
                        displayName: util.getValue(platformCodeNameMap, [platformCode, 'name']),
                        nickName: util.getValue(platformCodeNameMap, [platformCode, 'nickName']),
                        platformCode,
                        balance,
                        exchangeRate: platformExchangeRate,
                        enableFloat,
                    };
                }),
            ];
            setPlatformInfoList(platformInfoList)
            setIsQueryLoading(isQueryLoading)
        }
    }, [randomNum])

    const refetchCreditAndBalanceInfo = () => {
        let value = Math.random()
        refetchCredit();
        refetchBalanceInfo();
        setRandomNum(value)
    };

    return (
        <>
        <motion.div 
        initial={{opacity: 0}}
        animate={{opacity: 1}}
        exit={{opacity: 0}}
        >
            <PageTitleName>
                <Intl langKey="MEMBER@TRANSFER" />
            </PageTitleName>
            {
                refresh && ''
            }
            
            <Wrapper>
                <PlatformBalanceList
                    platformInfoList={platformInfoList}
                    isQueryLoading={isQueryLoading}
                    platformCode={PLATFORM_CODE}
                    ownPlatform={ownPlatform}
                    refresh={refetchCreditAndBalanceInfo}
                />
            </Wrapper>
            </motion.div>
        </>
    );
};

export default withAppContext(Transfer);

// Styled Components

const Wrapper = styled.div`
	display: flex;
	align-items: flex-start;
	${media.md`
	 	display: block;
	`};
`;
