import React from 'react'

export default function WhatsappIcon() {
    return (
        <svg viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M16.6 14.0001C16.4 13.9001 15.1 13.3001 14.9 13.2001C14.7 13.1001 14.5 13.1001 14.3 13.3001C14.1 13.5001 13.7 14.1001 13.5 14.3001C13.4 14.5001 13.2 14.5001 13 14.4001C12.3 14.1001 11.6 13.7001 11 13.2001C10.5 12.7001 10 12.1001 9.6 11.5001C9.5 11.3001 9.6 11.1001 9.7 11.0001C9.8 10.9001 9.9 10.7001 10.1 10.6001C10.2 10.5001 10.3 10.3001 10.3 10.2001C10.4 10.1001 10.4 9.9001 10.3 9.8001C10.2 9.7001 9.7 8.5001 9.5 8.0001C9.4 7.3001 9.2 7.3001 9 7.3001C8.9 7.3001 8.7 7.3001 8.5 7.3001C8.3 7.3001 8 7.5001 7.9 7.6001C7.3 8.2001 7 8.9001 7 9.7001C7.1 10.6001 7.4 11.5001 8 12.3001C9.1 13.9001 10.5 15.2001 12.2 16.0001C12.7 16.2001 13.1 16.4001 13.6 16.5001C14.1 16.7001 14.6 16.7001 15.2 16.6001C15.9 16.5001 16.5 16.0001 16.9 15.4001C17.1 15.0001 17.1 14.6001 17 14.2001C17 14.2001 16.8 14.1001 16.6 14.0001ZM19.1 4.9001C15.2 1.0001 8.9 1.0001 5 4.9001C1.8 8.1001 1.2 13.0001 3.4 16.9001L2 22.0001L7.3 20.6001C8.8 21.4001 10.4 21.8001 12 21.8001C17.5 21.8001 21.9 17.4001 21.9 11.9001C22 9.3001 20.9 6.8001 19.1 4.9001ZM16.4 18.9001C15.1 19.7001 13.6 20.2001 12 20.2001C10.5 20.2001 9.1 19.8001 7.8 19.1001L7.5 18.9001L4.4 19.7001L5.2 16.7001L5 16.4001C2.6 12.4001 3.8 7.4001 7.7 4.9001C11.6 2.4001 16.6 3.7001 19 7.5001C21.4 11.4001 20.3 16.5001 16.4 18.9001Z" fill="white"/>
        </svg>
    )
}


export  function WhatsappIconf88() {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="26" height="26" viewBox="0 0 26 26" fill="none">
            <path d="M5.05725 0H20.9427C23.7365 0 26 2.26353 26 5.05725V20.9427C26 23.7365 23.7365 26 20.9427 26H5.05725C2.26353 26 0 23.7365 0 20.9427V5.05725C0 2.26353 2.26353 0 5.05725 0Z" fill="url(#paint0_linear_4872_909)"/>
            <path d="M15.4572 17.3948C15.0698 17.405 14.7027 17.3132 14.3356 17.1909C13.7647 16.9971 13.1937 16.783 12.6635 16.4873C11.9701 16.0999 11.3482 15.6105 10.7772 15.0599C10.0737 14.3767 9.4823 13.612 8.95211 12.7861C8.77877 12.5109 8.63603 12.2152 8.52387 11.8991C8.25877 11.1752 8.24858 10.4614 8.56466 9.74772C8.7176 9.40105 8.95211 9.11556 9.21721 8.87086C9.37015 8.72811 9.56387 8.65674 9.77799 8.65674C9.94113 8.65674 10.1043 8.66693 10.2674 8.66693C10.4407 8.66693 10.5631 8.7485 10.6447 8.90144C10.7568 9.10537 10.8282 9.32968 10.92 9.5438C11.1035 9.97203 11.2666 10.4003 11.4603 10.8183C11.5215 10.9509 11.5113 11.0732 11.4603 11.2058C11.3278 11.5116 11.0933 11.7462 10.8792 11.9909C10.8588 12.0112 10.8486 12.0316 10.8282 12.0418C10.7058 12.1744 10.6956 12.2865 10.7874 12.4395C11.4807 13.6222 12.429 14.5399 13.7035 15.0905C13.8054 15.1312 13.9074 15.1822 14.0094 15.223C14.2133 15.3046 14.3254 15.2842 14.4682 15.1211C14.6925 14.856 14.9168 14.5807 15.1309 14.3054C15.1615 14.2646 15.1921 14.2136 15.2227 14.1728C15.3145 14.0505 15.4368 14.0403 15.5796 14.0811C15.7631 14.132 15.9262 14.2136 16.0894 14.2952C16.5482 14.5195 17.0172 14.7438 17.476 14.9681C17.7513 15.1007 17.7717 15.1414 17.7615 15.4371C17.7513 15.7328 17.6799 16.0081 17.578 16.2834C17.5168 16.4465 17.4047 16.5791 17.2721 16.6912C16.9458 16.9767 16.589 17.1909 16.1709 17.3132C15.9262 17.3846 15.6917 17.3948 15.4572 17.3948Z" fill="#FEFEFE"/>
            <path d="M13.0609 3.58887C7.93231 3.58887 3.78251 7.76926 3.78251 12.9285C3.78251 14.6108 4.23114 16.1912 5.00604 17.5575L3.66016 22.4108L8.59506 21.1159C9.92055 21.85 11.45 22.2681 13.0711 22.2681C18.1998 22.2681 22.3496 18.0877 22.3496 12.9285C22.3496 7.76926 18.1896 3.58887 13.0609 3.58887ZM13.0609 20.6367C11.5111 20.6367 10.0633 20.1677 8.86016 19.3724L5.93388 20.1575L6.72918 17.2618C5.8931 16.0281 5.40369 14.5395 5.40369 12.9285C5.40369 8.67671 8.82957 5.22024 13.0609 5.22024C17.2923 5.22024 20.7182 8.66651 20.7182 12.9285C20.7182 17.1904 17.2923 20.6367 13.0609 20.6367Z" fill="white"/>
            <defs>
                <linearGradient id="paint0_linear_4872_909" x1="30.218" y1="-12.7382" x2="2.91442" y2="28.0764" gradientUnits="userSpaceOnUse">
                <stop stop-color="#5FFB7B"/>
                <stop offset="1" stop-color="#27D145"/>
                </linearGradient>
            </defs>
        </svg>
    )
}
