import React from 'react'
import { Accordion, AccordionWrapper } from '../seo-content/seo_content'
import { withTheme } from 'styled-components'
import { withAppContext } from 'src/store/initAppContext'
import { Link } from 'react-router-dom'

type Props = {
    app: any
}

const SuperSpadeFun88 = (props: Props) => {
    const { app: { brandId } } = props;
    return (
        <AccordionWrapper style={{ width: '100%' }}>
            <Accordion>
                <input type="checkbox" id="section1" className="accordion__input" />
                <label htmlFor="section1" className="accordion__label">
                    <h1>Place Bet on Super Spade Live Casino Games</h1></label>
                <div className="accordion__content">
                    <p>FUN88 is privileged to have Super Spade&rsquo;s games, which are popular among online games, especially in the Indian market like <strong><Link to="/live-casino/dragon-tiger">Dragon Tiger</Link></strong> and Teen Patti. Super Spade games are a popular live dealer provider and they bring the <strong><Link to="/live-casino">live casino</Link></strong> right to your screen, offering the comfort and excitement of live gaming.</p>

                    <h2>Play Super Spade Games on FUN88 and Win Real Money</h2>
                    <p>FUN88 and Super Spade Games have come together to bring you an exclusive gaming experience. The website provides a range of games specially designed for Indian players, which means not only fun but a good chance to make some real money. These games are a treat for all types of players, from the strategic intricacies of Teen Patti to the fast suspense of Dragon Tiger.</p>

                    <h2>How to Play Super Spade Games on FUN88?</h2>
                    <p>The procedure of playing the Super Spade games on FUN88 is easy. Begin by having an active FUN88 account and proceed to the Super Spade section. Select your game of choice. There is an &lsquo;info&rsquo; section in every game which tells you how to play and the rules, so even beginners can easily begin.</p>

                    <h2>Super Spade Games Rules</h2>
                    <p>The rules vary a lot from one game to the other. Here are some basic rules from a couple of games from Super Spade games on FUN88:</p>
                    <ul>
                        <li>Dragon Tiger: A simplified version of Baccarat, Dragon Tiger is a two-card game that is played between two sides &ndash; Dragon and Tiger. Bettors bet on which one is going to get a higher card or if it&rsquo;s going to be a tie.</li>
                        <li>Teen Patti: Referred to as Indian Poker, Teen Patti begins with each player wagering. All players are dealt three cards and the highest hand wins the pot.</li>
                    </ul>

                    <h2> Super Spade Games Tips and Tricks</h2>
                    <ul>
                        <li><strong>Understand the Rules:</strong> The first step to mastery is understanding. <strong><Link to="/live-casino/teen-patti">Teen Patti</Link></strong> or Dragon Tiger, the rules give you a base to build strategies.</li>
                        <li><strong>Manage Your Bankroll:</strong> Choose the amount of the wager and put the limit. Good bankroll management can significantly prolong the time of playing and pleasure.</li>
                    </ul>

                    <h2>Super Spade Games Payouts &amp; Odds</h2>
                    <p>The payouts and odds of the Super Spade games may vary greatly. Overall, RTP (Return to Player) rates are attractive and hence, the players have a fair chance to win. The odds in Teen Patti kind of games depend on the hand you play, while in Dragon Tiger kind of games, you have simple odds, with a win on a tie bet offering the best payout.</p>

                    <h2> Super Spade Games Login/Register</h2>
                    <p>FUN88 is the place where you can have fun but you need to <strong><Link to="/signup">sign up</Link></strong> or log in. Playing Super Spade games that you like is fast and secure, so you can begin in a moment.</p>

                    <h2>Super Spade Games Bonus</h2>
                    <p>Super Spade games players are offered <strong><Link to="/promotions">special bonuses</Link></strong> by FUN88. They could be anything from deposit bonuses to free bets and are a good opportunity to increase your playing funds.</p>

                    <h2>Why should you play Super Spade Games at FUN88?</h2>
                    <p>FUN88 offers a wide range of Super Spade games and provides a safe and friendly atmosphere to play in. Along with appealing bonuses and the possibility to play from any place, it is ideal for any game lover.</p>

                    <h2>Live Casino By Super Spade FAQs</h2>
                    <h3>What do I need to start playing the Super Spade games on FUN88?</h3>
                    <p>All that is required of you is a stable internet connection and an account at FUN88.</p>

                    <h3>Do Super Spade games play fair?</h3>
                    <p>The games of Super Spade use RNG (Random Number Generator) technology to ensure the fairness of all the results.</p>

                    <h3>Do Super Spade games work on mobiles?</h3>
                    <p>Yes, FUN88 is a mobile-friendly site so that players can play Super Spade games on the go.</p>

                    <h3>How can you win in Super Spade games?</h3>
                    <p>Luck plays a significant role in gambling, but knowing the rules and responsible gambling behavior can make your fun better.</p>
                </div>
            </Accordion>
        </AccordionWrapper>
    )
}

export default withTheme(withAppContext(SuperSpadeFun88));