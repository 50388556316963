import React, { useEffect, useState } from "react";
import styled, { withTheme } from "styled-components";
import { useNavigate, useSearchParams } from "react-router-dom";
import {
  MainInfoWrapper,
  Header,
  FirstCol,
  BodyWrapper,
} from "../../../components/udb/commonStyle";
import { BankList, UPIList } from "../../../components/udb/Bankdetail";
import Intl from "../../../components/common/Intl";
import {
  getBankDetails,
  deleteBankDetails,
  deleteUPIDetails,
} from "../../../config/api";
import UDBHeader from "src/components/UDBHeader";
import CustomRadioButton from "src/components/udb/kyc/CustomRadioButton";
import { ChooseDocument } from "./KYCPage";
import { Notify } from "src/components/Notification";
import { theme } from "styled-tools";
import SEOContents from "src/components/SEOContents";
import { withAppContext } from "src/store/initAppContext";

export type BankDataProps = {
  accountName: string;
  accountNumber: string;
  bankAddress: string;
  bankName: string;
  branchName: string;
  isDefault: boolean;
  id: number;
  ifscCode: string;
  remarks: string;
  uuid: string;
};

export type UPIdataProps = {
  isDefault: any;
  id: any;
  remarks: any;
  upiId: any;
  uuid: any;
};

type BankInfoProps = {
  data: BankDataProps[];
  allow2add: boolean;
};

type UPIInfoProps = {
  upiData: UPIdataProps[];
  allow2addUpi: boolean;
};

// maximum number of bank account that user can add
const MAX_Num_BANK_AC = 5;
const MAX_Num_UPI = 5;

const BankType = [
  {
    value: "1",
    label: "BANK",
    methodType: "bank",
    isDefault: true,
  },
  // {
  //   value: "2",
  //   label: "UPI",
  //   methodType: "upi",
  //   isDefault: false,
  // },
];

 function Bankinfo(props:any) {
  const [bankState, setBankState] = useState({
    data: [],
    allow2add: true,
  } as BankInfoProps);

  const [Upistate, setUpistate] = useState({
    upiData: [],
    allow2addUpi: true,
  } as UPIInfoProps);

  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const source = searchParams.get("source")
  const [checkedState, setCheckedState] = useState("bank")
  const { app: { brandId }, theme } = props

  useEffect(() => {
    if(source === "bank") {
      setCheckedState("bank")
    } else if (source === "upi"){
      setCheckedState("upi")
    } else {
      setCheckedState("bank")
    }
  },[])
  
 
  useEffect(() => {
    getBankRecords();
  }, []);

  const getBankRecords = () => {
    getBankDetails().then((res) => {
      const {
        data: {
          data: { user_bank_details = [], user_upi_details = [] },
        },
      } = res;
      if (user_bank_details || user_upi_details) {
        setBankState({
          data: user_bank_details,
          allow2add: user_bank_details.length < MAX_Num_BANK_AC,
        });
        setUpistate({
          upiData: user_upi_details,
          allow2addUpi: user_upi_details.length < MAX_Num_UPI,
        });
      } else {
        addNewBankAccount();
      }
    });
  };

  const addNewBankAccount = () => {
    navigate("/member/profile/bankaccountform", { state: { mode: "create" } });
  };

  const editBankAccount = (data) => {
    navigate("/member/profile/bankaccountform", {
      state: { mode: "update", data },
    });
  };

  const addNewUPI = () => {
    navigate("/member/profile/upiform", { state: { mode: "create" } });
  };

  const editUPI = (data) => {
    navigate("/member/profile/upiform", { state: { mode: "update", data } });
  };

  const deleteBankDetail = (uuid: string) => {
    if (uuid) {
      deleteBankDetails({ uuid }).then((res) => {
        if (res.data.success) {
          getBankRecords();
        }
      });
    }
  };

  const deleteUpiDetail = (uuid: any) => {
    if (uuid) {
      deleteUPIDetails({ uuid })
        .then((res) => {
          if (res.data.success) {
            getBankRecords();
          }
        })
        .catch((e) => {
          const { data } = e.response || {};
          let message = "Something Went Wrong, Please Try Again";
          if (data && data.error) {
            message = data.error;
          }
          Notify.error(message);
        });
    }
  };
  // const { methodId, amount, methodType, paymentGatewayId, EWData: { exp_date, cvv, card_num }, transactionId, transactionImg } = depositState;
  const { data, allow2add } = bankState;
  const { upiData, allow2addUpi } = Upistate;

  return (
    <MainInfoWrapper>
      <SEOContents pageName="memberProfileBankDetails"/>
      <Header>
        <FirstCol>
          <UDBHeader title="DASHBOARD@BANKDETAILS" />
        </FirstCol>
      </Header>
      <ChooseDocument>
        {BankType.map((elm) => (
          <CustomRadioButton
            key={elm.methodType}
            name="methodType"
            checked={checkedState === elm.methodType}
            label={<Intl langKey={elm.label} />}
            value={elm.value}
            onChange={(e) => setCheckedState(elm.methodType)}
          />
        ))}
      </ChooseDocument>

        {checkedState === "bank" ? (
          <>
            <MainBodyWrapper>
              {data.map((list) => (
                <BankList
                  key={list.id}
                  listData={list}
                  onEdit={editBankAccount}
                  onDelete={deleteBankDetail}
                  brandId={brandId}
                />
              ))}
            </MainBodyWrapper>
            <Bankbtnwrap>
              {allow2add && (
                <AddNewBankAccount onClick={addNewBankAccount}>
                  <Intl langKey="SOCIALMEDIA@AddNewBank" />
                </AddNewBankAccount>
              )}
            </Bankbtnwrap>
          </>
        ) : (
          <>
            <MainBodyWrapper>
              {upiData.map((list) => (
                <UPIList
                  key={list.id}
                  upilistData={list}
                  onEdit={editUPI}
                  onDelete={deleteUpiDetail}
                />
              ))}
            </MainBodyWrapper>
            <Bankbtnwrap>
              {allow2addUpi && (
                <AddNewBankAccount onClick={addNewUPI}>
                  <Intl langKey="DASHBOARD@ADDUPIACC" />
                </AddNewBankAccount>
              )}
            </Bankbtnwrap>
          </>
        )}
    </MainInfoWrapper>
  );
}

export default withTheme(withAppContext(Bankinfo));

//Styled Components

const Bankbtnwrap = styled.div`
  display: flex;
  justify-content: center;
`;

const MainBodyWrapper = styled(BodyWrapper)`
  width: 100%;
  align-items: flex-start;
  background: none;
  box-shadow: none;
  padding: 0;
`;
const AddNewBankAccount = styled.button`
  width: calc(100% / 2);
  display: flex;
  align-items: center;
  justify-content: center;
  background: ${theme('palette.secondary-background-color-linear-new1')};
  border-radius: 2px;
  letter-spacing: 0.01em;
  text-transform: uppercase;
  font-weight: 600;
  font-size: 15px;
  color: #fff;
  padding: 1.5em 1em;
  border: none;
  cursor: pointer;
  margin-bottom: 10px;

  :hover {
    background: rgb(0 0 0 / 89%);
  }
`;
