import {
	defaultDataIdFromObject,
	InMemoryCache,
	NormalizedCacheObject,
	ApolloClient,
	ApolloLink,
	from,
	Observable,
	split,
	createHttpLink
} from '@apollo/client'

import { setContext } from '@apollo/client/link/context';
import { onError } from '@apollo/client/link/error';
import { RetryLink } from '@apollo/client/link/retry';
import ApolloLinkTimeout from 'apollo-link-timeout';
import { WebSocketLink } from "@apollo/client/link/ws";
import fetch from 'isomorphic-fetch';
import { SubscriptionClient } from 'subscriptions-transport-ws';

// util
import config from '../config/config';
//import apiLog from '../util/apiLog';
import cookieUtil from '../util/cookieUtil';
import eventUtil from '../util/eventUtil';
import util from '../util/util';
import { ECookieName, EEventEmit } from '../util/utilModel';
//import apiLogLink from './link/apiLogLink';
import memcachedLink from './link/memcachedLink';
import { getIsSocket } from './link/tool';

let apolloClient: ApolloClient<NormalizedCacheObject>;
let location = window.location;

// Adjusting this parameter can force the requested URL to change and initialize the cache
export const API_PREFIX = '2';

export const logout = () => {
	cookieUtil.remove(ECookieName.COOKIE_MEMBERID);
	cookieUtil.remove(ECookieName.COOKIE_AGENT_TOKEN);
	cookieUtil.remove(ECookieName.COOKIE_TOKEN);
	cookieUtil.remove(ECookieName.COOKIE_IS_DEFAULT_PASSWORD);
	cookieUtil.remove(ECookieName.COOKIE_IS_HAD_AUTH);
	cookieUtil.remove(ECookieName.COOKIE_IS_ONE_CLICK_ACTIVE);
	cookieUtil.remove(ECookieName.COOKIE_IS_ACCEPT_ALL);
	if(location.search !== '?e=t'){
		location.href = `${config.pageRedirect.withoutPermissionPage}?e=t`;
	}
	//location.href = config.pageRedirect.withoutPermissionPage;
};

const authLink: any = ({ lang, username, res }: any): any =>
	setContext((_, { headers }) => ({
		headers: {
			...headers,
			'x-language-code': 'en-us',
			'x-username': username || '',

			// The client is treated as a new client request and the traceId is not recorded
			'X-Amzn-Trace-Id': util.isClient
				? ''
				: res &&
				decodeURIComponent(
					cookieUtil.get(ECookieName.COOKIE_XRAY_HEADER, {
						res,
					}) || '',
				),
		},
	}));

const errorLink = onError(({ graphQLErrors, networkError, operation }) => {
	if (graphQLErrors) {
		graphQLErrors.map(({ message, locations, path }: any) => {
			console.error(
				`${Date.now()} [GraphQL error]: operationName: ${operation.operationName}, Message: ${message}`,
			);
		});
	}
	if (networkError) {
		console.error(`${Date.now()} [Network error]: operationName: ${operation.operationName}, ${networkError}`);
		if ((networkError as any).statusCode === 401 && util.isClient && config.isAuthCheck && operation.operationName !== 'getOrderList') {
			logout();
		} else if ((networkError as any).statusCode === 403) {
			const alertify = require('alertifyjs');
			alertify.error('No Permission');
		} else if ((networkError as any).statusCode >= 500) {
			const alertify = require('alertifyjs');
			alertify.error('No Permission');
		} else if ((networkError as any).statusCode === 406) {
			if(location.search !== '?e=t'){
				location.href = `${config.pageRedirect.withoutPermissionPage}?e=t`;
			}
			//location.href = config.pageRedirect.withoutPermissionPage;
		}
	}
});

const httpLink = (req: any, token: string) =>{
	return createHttpLink({
		fetch: (uri: string, options: any) => {
			const isTest = util.isClient
				? window.location.search.indexOf('test=') > -1
				: req.url.indexOf('test=') > -1;
			const { operationName } = JSON.parse(options.body);
			return fetch(`${uri}?${operationName}=${API_PREFIX}${isTest ? '&test=1' : ''}`, {
				...options,
				headers: { ...options.headers, 'x-authentication': cookieUtil.get(ECookieName.COOKIE_TOKEN) },
			});
		},
		uri: config.gqlHost,
	});}

const timeoutLink = new ApolloLinkTimeout(config.apiTimeout);

function webSocketLink({ memberId }: { memberId: number }, token) {
	// const wsClient = new SubscriptionClient(config.gqlWebSocketHost + `?p=${cookieUtil.get(ECookieName.COOKIE_TOKEN)}`, {
	// 	reconnect: true,
	// 	timeout: 5000,
	// 	connectionParams: {
	// 		memberId,
	// 	},
	// 	lazy: true,
	// 	inactivityTimeout: 3000,

	// 	connectionCallback: (error: any = {}) => {
	// 		if (error.message && error.message === 'token invalid') {
	// 			location.search.indexOf('test=1') < 0 && logout()
	// 		}
	// 	},
	// });
	// let pollingStart: any;
	// util.isClient &&
	// 	(window.onbeforeunload = () => {
	// 		wsClient.close();
	// 	});
	// (wsClient as any).maxConnectTimeGenerator.duration = () => (wsClient as any).maxConnectTimeGenerator.max;
	// wsClient.onReconnected(() => {
	// 	pollingStart && clearTimeout(pollingStart);
	// 	eventUtil.emit(EEventEmit.EVENT_PUSH_ERROR, { isConnected: true });
	// });
	// wsClient.onError(event => {
	// 	pollingStart = setTimeout(() => eventUtil.emit(EEventEmit.EVENT_PUSH_ERROR, { isConnected: false }), 3000);
	// });
	// return new WebSocketLink(wsClient);
	return new WebSocketLink({
		uri: config.gqlWebSocketHost + `?p=${cookieUtil.get(ECookieName.COOKIE_TOKEN)}`,
		options: {
		  reconnect: true,
		  timeout: 5000,
		  connectionParams: {
			memberId,
			authToken: cookieUtil.get(ECookieName.COOKIE_TOKEN),
		  },
		  lazy: true,
		  inactivityTimeout: 3000,
		  connectionCallback: (error: any = {}) => {
			if (error.message && error.message === 'token invalid') {
				location.search.indexOf('test=1') < 0 && logout()
			}
		},
		}
	  });
}

const splitLink = ({
	memberId,
	req,
	token,
	originalMemberId,
}: {
	memberId: number | undefined;
	req: any;
	token: string;
	originalMemberId?: number;
}) =>
	split(
		getIsSocket,
		util.isClient && (originalMemberId || memberId) && !config.isUseMockData
			? webSocketLink({ memberId: originalMemberId || (memberId as number)}, token)
			: new ApolloLink((operation: any, forward: any) => {
					// server side斷開ws link
					return new Observable(observer => {
						observer.complete();
					});
			  }),
		httpLink(req, token),
	);

let shouldBlockTraffic = false;
export const setShouldBlockTraffic = (b: boolean) => {
	shouldBlockTraffic = b;
};

const splitBlockTrafficLink = ({
	memberId,
	req,
	token,
	originalMemberId,
}: {
	memberId: number | undefined;
	req: any;
	token: string;
	originalMemberId?: number;
}) =>
	split(
		() => {
			return shouldBlockTraffic;
		},
		new ApolloLink((operation: any, forward: any) => {
			return new Observable(observer => {
				observer.error('session timeout');
			});
		}),
		splitLink({
			memberId,
			req,
			token,
			originalMemberId,
		}),
	);

// const fragmentMatcher = new IntrospectionFragmentMatcher({
// 	introspectionQueryResultData: {
// 		__schema: {
// 			types: [],
// 		},
// 	},
// });



const retryLink = new RetryLink({
	delay: (count, operation, error) => {
		return 1000;
	},
	attempts: {
		max: 1, 
		retryIf: (error, _operation) => {
			if (error.message === 'Network request failed') {
				if (_operation.operationName === 'memberFavorite') {
					return true;
				}
			}
			return false;
		},
	},
});


function getCombinedLink(params: IApolloParams) {
	const { lang, memberId, res, username, req, originalMemberId, token } = params;
	const linkGroup = util.isClient
		? [timeoutLink, errorLink, authLink({ lang, username, res }),retryLink]
		: [
			timeoutLink,
			memcachedLink({ lang }),
			errorLink,
			authLink({ lang, username, res }),
		];
		
	return from([...linkGroup, splitBlockTrafficLink({ memberId, req, token, originalMemberId })]);
}

function create(initialState: any, params: IApolloParams) {
	return new ApolloClient({
		connectToDevTools: true,
		ssrMode: !util.isClient, // Disables forceFetch on the server (so queries are only run once)
		link: getCombinedLink(params),
		cache: new InMemoryCache({
			possibleTypes: {
				types: [],
			},
			dataIdFromObject: (object: any) => {
				switch (object.__typename) {
					case 'MultipleFancyLog':
					case 'SelectionLog':
						return object.logId;
					default:
						return defaultDataIdFromObject(object);
				}
			},
		}).restore(initialState || {}),
	});
}

interface IApolloParams {
	lang: string;
	token: string;
	memberId?: number;
	res?: Response;
	req?: Request;
	username?: string;
	originalMemberId?: number;
	type?: any;
	brandId?:any
}                   

let websocketRegisterParam: any = {};

export default function initApollo(params: IApolloParams, initialState?: any): any {
	if (!util.isClient) {
		return create(initialState, params);
		
	}
	if (!apolloClient || websocketRegisterParam.token !== params.token || websocketRegisterParam.memberId !== params.memberId) {
		websocketRegisterParam = params;
		apolloClient = create(initialState, params);
	}
	return apolloClient;
}
