
import { MainInfoWrapper } from 'src/components/udb/commonStyle';
import config from 'src/config/config';
import { withAppContext } from 'src/store/initAppContext';
import styled, { withTheme } from 'styled-components';
import { theme } from 'styled-tools';
import { motion } from 'framer-motion';
import FooterContainer from "src/components/footer/footer";
import { TextContent } from './aviatorBonusPage';
import { FifaFeverFirstSecHead1 } from './evolutionPromo';
import { ContentWrapperPl } from './PlatinumRush';
import { PlayNowButtonNew2 } from './crazyCrashPromo';
import { useNavigate } from 'react-router-dom';
import { Coloumn, ColoumnWrap, TextContent1 } from './dailyDepositBonus';
import { TermsConditionPoints } from './Funkytimepromo';


const WCbetBoost = (props) => {

    const { app: {
        brandId
    }, theme } = props;

    const domain = brandId == 33 ? 'iw247' : brandId == 31 || brandId == 32 ? 'Yolo247' : 'Betdaily';

    const navigate = useNavigate();

    const data = [
        {
            step: 'https://opt-v3-files.raksahb.com/static/cashsite/brand/6/image/signinwc.png',
            secondLine: 'Sign In or Register',
            desc: `If you are not already part of the ${domain} family, sign up for your account. Existing members can log in with ease`
        }, {
            step: 'https://opt-v3-files.raksahb.com/static/cashsite/brand/6/image/topupwc.png',
            secondLine: 'Top Up Your Account',
            desc: `Ensure your ${domain} account is loaded and ready for action throughout the Cricket World Cup.`
        }, {
            step: 'https://opt-v3-files.raksahb.com/static/cashsite/brand/6/image/exchwc.png',
            secondLine: 'Place Exchange Bets',
            desc: 'Dive into the excitement of the World Cup matches by placing your bets on our cutting-edge exchange platform.'
        }, {
            step: 'https://opt-v3-files.raksahb.com/static/cashsite/brand/6/image/claim.png',
            secondLine: 'Claim Your 100% Cashback',
            desc: 'For cumulative losses of the day, we will reward you with an 100% additional lossback bonus the very next day.'
        }
    ]


    return (


        <motion.div
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
        >
            <MainInfoWrapper>
                <ContentWrapperP2>
                    <h4>
                        Get 100% lossback bonus this World Cup
                    </h4>
                    <TextContent className='header'>The Cricket World Cup has arrived, and we're rolling out the red carpet for all cricket enthusiasts. At {domain}, we're proud to present an electrifying offer that'll make your cricketing thrill even more exhilarating. Get ready to dive into the action and enjoy an exclusive offer:

                    </TextContent>

                    <h4 style={{ paddingTop: '1.8rem', marginBlockEnd: 0 }}>
                        Instant additional 100% Cashback Bonus on All WC Exchange Bets!
                    </h4>
                    <PlayNowButtonNew2 onClick={() => navigate("/betting-exchange/online-cricket-betting")}>BET NOW</PlayNowButtonNew2>
                    <FifaFeverFirstSecHead1 className="wcbetboost"><p>How to Play and Win ?</p></FifaFeverFirstSecHead1>

                    <ColoumnWrap1>
                        {data.map((elm) => (
                            <Coloumn1>
                                <p className='step'>
                                    <img src={elm.step} />
                                </p>
                                <p className='stepafter' style={{ textAlign: 'center' }}>
                                    {elm.secondLine}
                                </p>
                                <p className='desc' style={{ textAlign: 'center' }}>{elm.desc}</p>
                            </Coloumn1>
                        ))}
                    </ColoumnWrap1>
                    <FifaFeverFirstSecHead1 className="wcbetboost2"><p>bonus Terms & Conditions</p></FifaFeverFirstSecHead1>

                    <TermsConditionPoints>
                        <ul>
                            <TextContent1>• The lossback bonus will be applied to ‘lost EXCHANGE bets - for the day’ placed during the World Cup 2023.</TextContent1>
                            <TextContent1>• The Bonus Amount For Qualifying bets placed during the day will Be Capped At INR 50.</TextContent1>
                            <TextContent1>• The bonus will be credited to your {domain} account the next day.</TextContent1>
                            <TextContent1>• Bonus will be expired within 24-48 hours.</TextContent1>
                            <TextContent1>• There is no limit to the number of bets you can place to earn the 100% lossback bonus. ‘for the day’.</TextContent1>
                            <TextContent1>• The lossback bonus can be used to place future bets on {domain} as this bonus cannot be withdrawn. Only winnings can be withdrawn.</TextContent1>
                            <TextContent1>• This promotion is valid only for the World Cup 2023.</TextContent1>
                            <TextContent1>• {domain} reserves the right to disqualify any user found engaging in fraudulent activities.</TextContent1>
                            <TextContent1>• {domain} General Terms & Conditions apply.</TextContent1>

                        </ul>
                    </TermsConditionPoints>
                </ContentWrapperP2>

            </MainInfoWrapper>
            <FooterContainer />
        </motion.div>
    )
}

export default withTheme(withAppContext(WCbetBoost))

const ContentWrapperP2 = styled(ContentWrapperPl)`
  background: url(${config.imageV3Host}/static/cashsite/${theme('image.wcbetbginner')});
  background-position: center;
    background-size: cover;
`
const ColoumnWrap1 = styled(ColoumnWrap)`
    flex-wrap  :nowrap ;
    padding: 3rem;
    p{
        text-align: center;
    }

`

const Coloumn1 = styled(Coloumn)`
  width: 24%;
  min-height: 500px;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  padding: 22px;
    align-items: center;
    justify-content: space-around;
`