/* eslint-disable */
import React, { useEffect, useState } from 'react';
import styled, { css } from 'styled-components';
import { ifProp, theme, withProp } from 'styled-tools';

// Components
import { desaturate, lighten } from 'polished';
import { bodyFont, GradientFont, titleFont } from './commonCSSStyles';
import Icon, { IconWrapper } from './PNGIcon';
import { diffColor, diffColorReversed } from '../../util/colorUtil';

// Utils
import media from '../../util/mediaUtil';
import routerUtil from '../../util/routerUtil';
import util from 'src/util/util';
import { useQuery } from '@apollo/client';
import { GET_EVENTS } from 'src/graphql/events';
import NewsEvent from 'src/pages/eu-content/news-event';

interface IBreadcrumbLabelProps {
	name: string;
	iconName: string;
}
const BreadcrumbLabel = (props: IBreadcrumbLabelProps) => {
	return (
		<BreadcrumbLabelWrapper>
			<Icon name={props.iconName} />
			{props.name}
		</BreadcrumbLabelWrapper>
	);
};

interface IBreadcrumbs {
	breadcrumbs: IBreadcrumbLabelProps[];
}
const Breadcrumbs = (props: IBreadcrumbs) => {
	return (
		<BreadcrumbWrapper>
			{props.breadcrumbs.map((breadcrumb, i) => {
				return (
					<>
						<BreadcrumbLabel name={breadcrumb.name} iconName={breadcrumb.iconName} />
						<Icon name="chevron-right" />
					</>
				);
			})}
		</BreadcrumbWrapper>
	);
};

interface IHeading1Prop {
	iconName?: string;
	children: any;
}

export const Heading1: React.SFC<IHeading1Prop> = (props: IHeading1Prop): JSX.Element => {
	const { iconName, children }: IHeading1Prop = props;
	return (
		<Heading1Wrapper>
			{iconName && <Icon name={iconName} />}
			<HeadingTextWrapper>{children}</HeadingTextWrapper>
		</Heading1Wrapper>
	);
};

interface IHeading2Prop {
	title: string;
	iconName?: string;
	subtitle?: string;
	eventHref?: string;
	competitionHref?: string;
	isInPlay?: boolean;
}

// Exchange/Sport
export const Heading2: React.SFC<IHeading2Prop> = props => {
	const [isShowInPlay, SetIsShowInPlay] = useState(true);
	const [popularNewsEvent, setPopularNewsEvent] = useState([]);
	const [results, setResults] = useState([]);
	const [categoryId, setCategoryId] = useState(0);
	const [competitionId, setcompetitionId] = useState('');
    const [eventId, seteventId] = useState(0);
	const [results_popular, setResults_popular] = useState([])
	const { title, iconName, subtitle, eventHref = '', competitionHref = '', isInPlay }: IHeading2Prop = props;
	const hasEventHref = !!eventHref;
	const hasCompetitionHref = !!competitionHref;

	const { loading, error, data }= useQuery(GET_EVENTS, {
        variables: {input: { categoryId: 0, isInplay: true }}
        });

	useEffect(() => {
		const marketList = util.getValue(data, ['markets', 'list'], []);
		const result = []
		const filtermarketList = marketList.map((market) => result.push(Number(market.eventId)))
		const categoryId = marketList.length > 0 && marketList[0].categoryName || ''
		const isCurrentShowInPlay = marketList.length > 0;
		isCurrentShowInPlay && categoryId !== categoryId &&
	
					SetIsShowInPlay(isCurrentShowInPlay),
					setResults(result);
					setCategoryId(categoryId)
	}, [])

	return (
		<>
		<NewsEvent data={results}  popular_data={results_popular}/>
		<Heading2Wrapper>
			{iconName && <Icon name={iconName} />}
			<div>
				{/* <Heading2TitleWrapper hasHref={hasEventHref} onClick={() => routerUtil.push(eventHref)}>
					{title}
					{isInPlay && <Icon name="stop-watch-in-play" />}
				</Heading2TitleWrapper> */}
				{subtitle && (
					<Heading2SubtitleWrapper
						hasHref={hasCompetitionHref}
						// onClick={() => routerUtil.push(competitionHref)}
					>
						{subtitle}
					</Heading2SubtitleWrapper>
				)}
			</div>
		</Heading2Wrapper>
		</>
	);
};

// No subtitle header
export const Heading3: React.SFC<IHeading2Prop> = props => {
	const { title, iconName, eventHref = '', isInPlay }: IHeading2Prop = props;
	const hasEventHref = !!eventHref;
	// const hasCompetitionHref = !!competitionHref;

	return (
		<Heading2Wrapper>
			{iconName && <Icon name={iconName} />}
			<div>
				{/* <Heading2TitleWrapper hasHref={hasEventHref} onClick={() => routerUtil.push(eventHref)}>
					{title}
					{isInPlay && <Icon name="stop-watch-in-play" />}
				</Heading2TitleWrapper> */}
			</div>
		</Heading2Wrapper>
	);
};

// For matches involves 2 teams
interface IMatchHeadingProps {
	team1: string;
	team2: string;
	eventTypeId: number;
	competitionName?: string;
}

export const MatchHeading = (props: IMatchHeadingProps) => {
	return (
		<MatchHeadingWrapper>
			<Breadcrumbs
				breadcrumbs={[
					{ name: 'Cricket', iconName: 'cricket' },
					{ name: 'JLT One-Day Cup', iconName: 'trophy' },
				]}
			/>
			<Heading1Wrapper>
				{props.team1}
				<VSWrapper>VS</VSWrapper>
				{props.team2}
			</Heading1Wrapper>
		</MatchHeadingWrapper>
	);
};

// Styled compopnents

const VSWrapper = styled.div`
	${titleFont};
	background-color: ${theme('platte.primary-background')};
	color: ${theme('palette.primary-foreground')};
	border-radius: 2px;
	font-size: 11px;
	width: 2.5em;
	height: 2.5em;
	display: flex;
	justify-content: center;
	align-items: center;
	margin: 0 1.5em;
	flex-shrink: 0;
	letter-spacing: 1px;
`;

export const HeadingDescription: any = styled.span`
	${bodyFont};
	font-size: 14px;
	font-style: italic;
	font-weight: normal;
	text-transform: none;
	letter-spacing: 0;
	margin-left: 1em;
`;

export const HeadingTextWrapper: any = styled.div`
	${GradientFont};
	display: flex;
	align-items: baseline;
`;

// Wrapping Icon and text
export const Heading1Wrapper: any = styled.div`
	${titleFont};
	display: flex;
	align-items: center;
	font-size: 20px;
	font-weight: 600;
	margin-bottom: 0.6em;
	text-transform: uppercase;
	letter-spacing: 1px;
	font-weight: 800;
	letter-spacing: 0.1em;
	color: green;

	${IconWrapper} {
		margin-right: 5px;
		margin-top: -3px;
	}
	${media.md`
		margin-bottom: 8px;
		font-size: 18px;
	`};
`;

const Heading2Wrapper: any = styled.div`
	${titleFont};

	display: flex;
	align-items: flex-start;
	font-size: 14px;
	font-weight: 600;
	margin-bottom: 10px;
	margin-top: 0;
	text-transform: uppercase;
	letter-spacing: 0.1em;
	color: green;

	${IconWrapper} {
		font-size: 0.9em;
		margin-right: 1em;
		margin-top: 0.1em;
	}
	> div {
		${GradientFont};
	}

	${media.sm`
		margin-bottom: 5px;
	`};
`;

export const Heading2TitleWrapper: any = styled.div`
	display: flex;
	align-items: center;

	${ifProp(
		'hasHref',
		css`
			cursor: pointer;

			&:hover {
				text-decoration: underline;
			}
		`,
		css`
			pointer-events: none;
		`,
	)};

	${IconWrapper} {
		font-size: 14px;
		margin-left: 0.5em;
		margin-top: -0.1em;
	}
`;

export const Heading2SubtitleWrapper: any = styled.div`
	font-size: 0.8em;
	margin-top: 10px;
	color: "#3c3232";
	${ifProp(
		'hasHref',
		css`
			cursor: pointer;

			&:hover {
				text-decoration: underline;
			}
		`,
		css`
			pointer-events: none;
		`,
	)};
`;

// Wrapping icon and text
const BreadcrumbLabelWrapper = styled.div`
	${titleFont};
	display: flex;
	align-items: center;
	font-size: 14px;
	margin: 0 1em;
	position: relative;
	z-index: 0;
	cursor: pointer;

	${IconWrapper} {
		&:first-of-type {
			margin-right: 0.5em;
		}
	}

	&::after {
		content: '';
		display: block;
		position: absolute;
		z-index: -1;
		top: -0.5em;
		right: -0.5em;
		bottom: -0.5em;
		left: -0.5em;
		background-color: hsl(0, 0%, 85%);
		border-radius: 2px;
		opacity: 0;
	}

	&:hover {
		&::after {
			opacity: 1;
		}
	}
`;

const BreadcrumbWrapper = styled.div`
	display: flex;
	align-items: center;
	margin-bottom: 1em;

	/* Adjust the size of right chevron icon */
	> ${IconWrapper} {
		font-size: 12px;
	}
`;

// Wrapping breadcrumbs (sport / competition) and heading
const MatchHeadingWrapper = styled.div`
	display: flex;
	flex-direction: column;
`;
