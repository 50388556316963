import React from 'react'
import { Accordion, AccordionWrapper } from '../seo-content/seo_content'
import styled, { withTheme } from 'styled-components'
import { withAppContext } from 'src/store/initAppContext'
import { Link } from 'react-router-dom'

type Props = {
    app: any
}

const CrazytimeSEO46 = (props: Props) => {
    const { app: { brandId } } = props;
    return (
        <>
            <AccordionWrapper style={{ width: '100%' }}>
                <Accordion>
                    <input type="checkbox" id="section1" className="accordion__input" />
                    <label htmlFor="section1" className="accordion__label">
                        <h1>Play Crazy Time Live Casino Game by Evolution Gaming
                        </h1></label>
                    <div className="accordion__content">

                        <p>The Crazy Time Live Casino game combines entertainment with opportunities to win real money. You can place your bets on numbers and symbols on the wheel and have a chance to win big cash.</p>
                        <p>The excitement with every spin is what makes this game nail-biting.&nbsp; Additionally, the bonus rounds enhance the overall live casino experience. FUN88 offers you a smooth, reliable, and trustworthy platform to play Crazy Time Casino game. With our user-friendly interface, you can get the best possible gaming experience. Also, with our safe withdrawal procedure, your winning are safely credited to your account in no time.&nbsp;</p>
                        <p>Security is a top priority in Crazy Time game. Player data and money are safe. Auditors keep auditing the game for fairness. Every wheel spin is random and unbiased. Whether playing to relax or aiming for the big win, Crazy Time Evolution game offers a fun and secure environment for all. When you think of trying a <strong><Link to={'/live-casino'}>live casino game</Link></strong> next time, give Crazy Time a go. It is simple, fun, and rewarding.</p>

                        <h2><strong> Crazy Time Live Stream</strong></h2>
                        <p>FUN88 incorporates innovative technologies to ensure you get the best possible experience. With a live game tracking facility, you can easily monitor spin history and top winnings. Using this data you can make your strategies and place your bets in a better way. It also gives you a real-time gaming experience.&nbsp;</p>
                        <p>Additionally, this feature lets you enjoy the real casino. Instead of juggling through mere graphics, you feel a casino-like environment on FUN88 .</p>
                        <p>You can also do live chat and interact with fellow players. It again gives you a real casino feeling and ensures the authenticity of the game.</p>
                        
                        <p><Link to={'/live-casino/evolution'}>Play Evolution Games Now</Link> </p>
                        <h2><strong> How to Play Crazy Time Game</strong></h2>
                        
                        <p>In the Crazy Time casino game, you try to guess the spot on the 54-segment wheel where it will stop when the game presenter spins it. If you make a successful guess, you have a chance to win a multiplier on your prize!</p>
                        <ul>
                            <li >To start the game, all players must place their bets on any of the four games, including The Pachinko, Cash Hunt, Crazy Time, and Coin Flip.</li>
                            <li >After all players have placed their bets, the Crazy Time wheel will spin and determine which game will be played.</li>
                            <li >Depending on the game chosen, players will then be asked to make additional bets on the outcome of the game.</li>
                            <li >Once all players have made their bets, the game will begin and the outcome of the game will be determined.</li>
                            <li >If players have guessed correctly, they will win the corresponding amount according to the odds of the game. If not, they will lose their bet.</li>
                            <li >After the game is over, the Crazy Time wheel will spin again to determine which game will be played next.</li>
                            <li >The game continues until all players have won or lost their bets.</li>
                        </ul>
                        <p>Click here to learn in detail&nbsp; <strong>how to play Crazy Time</strong>,&nbsp; the most popular game of all time.</p>
                        <h2><strong> Crazy Time Game Features and Functionality</strong></h2>
                        <p>The distinct features and functionality of Crazy Time by Evolution gaming are as follows:</p>
                        <ul>
                            <li> Evolution launched Crazy Time on the Dream Catcher Wheel Money concept. The latter is a very successful game of Evolution. A vibrant wheel with 54 segments is the main element of the game. Every segment has three numbers: 1, 2, and 5. There are also very rewarding bonus rounds that you can choose from.</li>
                            <li> Engaging live dealers to host the game, adding a social and entertaining aspect to the gaming experience.</li>
                            <li> The average return to player (RTP) for the game is 96.50%.</li>
                            <li> To play Crazy Time, you need not be logged in on your laptop or personal computer. The game is compatible with Android and iOS mobile phones; hence, it can be played easily anywhere.</li>
                        </ul>
                        <h2><strong> The Benefits of Playing Crazy Time Live</strong></h2>
                        <p>The advantages of playing Crazy Time Live are as follows:</p>
                        <ol>
                            <li> You get to experience not one but 4 bonus rounds, which can help you win big amounts.</li>
                            <li> Crazy Time&rsquo;s vibrant interface creates a welcoming atmosphere that makes you want to return again and again.</li>
                            <li> The game has live hosts, which makes the game experience interactive.</li>
                            <li> There are certain elements in this Evolution game that you can customise, such as your flapper for a bonus round.</li>
                        </ol>
                        <h2><strong> Crazy Time Live Online Gameplay Guide</strong></h2>
                        
                        <p>The comprehensive yet easy-to-understand guide to playing Crazy Time on FUN88 is as follows:</p>
                        <ol>
                            <li><strong> Placing Bets: </strong>Choose your bets from various options available. The advice is to diversify your bets to improve your chances of winning.</li>
                            <li><strong> Spinning the Wheel: </strong>After you place your bets, the host will spin the wheel.</li>
                            <li><strong> Winning or losing: </strong>When the wheel stops, you will see if what you had put your money into is the outcome.</li>
                            <li><strong> Bonus Rounds: </strong>The four bonus rounds of Crazy Time add a different layer of fun for you. Every round has different rules and rewards, which can be very substantial.</li>
                            <li><strong> Repeat or Cash Out: </strong>When each round ends, you have two options: cash out your winnings or start all over again.</li>
                        </ol>
                        <h2><strong> Crazy Time Casino Game Rules</strong></h2>
                        <p>To play Crazy TIme, you can follow these rules:</p>
                        <ul>
                            <li >Place wagers on the designated number or Bonus segment.</li>
                            <li >The Top Slot mini-game randomly selects and multiplies a bet spot.</li>
                            <li >Match the Top Slot multiplier with the bet spot for increased winnings.</li>
                            <li >The winning bet corresponds to the segment indicated by the wheel.</li>
                            <li >Payouts for number segments are equivalent to the number indicated.</li>
                            <li >Bonus game payouts are determined during the Bonus side games.</li>
                        </ul>
                        <h2><strong> Crazy Time Bonus Game Rounds</strong></h2>
                        
                        <p>If you place a bet on any of the four bonus games in Crazy Time Live, you will have the opportunity to participate in the game if the wheel stops on that game. When you select your bet amount, you can tap the multi-select button on the betting interface if you would like to place a bet on all of the bonus rounds at once.&nbsp;</p>
                        <h3><strong> Crazy Time Live Offers Four Bonus Games</strong></h3>
                        <h3><strong> Coin Flip</strong></h3>
                        <p>A coin with a blue and a red side will be flipped to decide which multiplier will be won! Before the coin is tossed, two multipliers will be created - one for the red and one for the blue sides. The multiplier that will be won is determined by the side of the coin facing up when it lands. Further, know everything about Crazy Time Flip Game including how to play &amp; where to play with lucrative multipliers!</p>
                        <h3><strong> Cash Hunt</strong></h3>
                        <p>In Cash Hunt, players are presented with a large screen featuring 108 random multipliers hidden behind random symbols and shuffled before each player takes their shot. When the countdown timer starts, every player can aim for the target where they think the highest multiplier is located. Upon the timer expiring, the cannon will shoot at the target and reveal the won multiplier. This interactive bonus round allows each player to select their own target, resulting in different multipliers being won.</p>
                        <h3><strong> Pachinko</strong></h3>
                        <p>The Pachinko bonus game offers a vast wall with multiple pegs. The presenter will drop a puck, and the players will be rewarded with a multiplier depending on where the puck drops. If the puck lands on a 'Double' value, the multipliers below the wall will be increased to twice their original amount, and the puck will be released once more until a multiplier is won! The process can be repeated until the multipliers reach the maximum 10,000x multiplier.</p>
                        <h3><strong> Crazy Time</strong></h3>
                        <p>Push the big red button to open the door to a massive virtual money wheel! Players must choose from one of three flappers (blue, green, or yellow) before the game commences. When the wheel stops spinning, the player wins the multiplier that the flapper points to. If the flapper points to a &lsquo;Double&rsquo; or &lsquo;Triple&rsquo; on the wheel, the multipliers will be doubled/tripled, and the wheel will spin once more. Only those players who chose the right flapper can participate in this round. The wheel can be spun repeatedly until the multipliers reach the maximum 20,000x multiplier.</p>
                        <h2><strong> Best Crazy Time Game Strategy</strong></h2>
                        
                        <h3><strong> The Martingale Strategy</strong></h3>
                        <p>The <strong>Martingale strategy</strong> approach has been around for a while in traditional roulette, and it can be applied to the game of Crazy Time as well. To begin with, you should set aside a budget to cover potential losses. You should then place a bet on each of the four bonus fields with your initial stake. If you hit, you will be taken to the bonus round, where you can take your winnings. After this, you can start over with your initial bet. If none of the bonus fields hit, you should continue to double your bet for each round.</p>
                        <h3><strong> The Low-volatility Strategy</strong></h3>
                        <p>If you want to maximize your chances of winning, you should spread your bets across as many areas as possible, depending on your budget. For example, if you have ₹100, you could bet ₹10 for each round or spread the money over four to six rounds. Additionally, it would be beneficial to focus more money on the numbers that appear more frequently on the wheel and less on the numbers that show up less often. Additionally, make sure to leave some room in your budget for the bonus rounds. This will give you coverage of around 50% of the field, making your chances of winning higher.</p>
                        <h3><strong> The Probabilistic Strategy</strong></h3>
                        <p>Players eager to challenge themselves can benefit from looking at the results of the last 60 rounds and computing the probability based on those numbers. The history tab of the game will provide the numbers. It is possible to observe which fields are more frequently hit. A successful strategy will require both patience and thorough observation.</p>
                        <h2><strong> Tips and Tricks of Playing Crazy Time Game Online</strong></h2>
                        <p>The standard things to be kept in mind while playing Evolution&rsquo;s Crazy Time on FUN88 are:</p>
                        <ul>
                            <li> Wise betting is the key to enjoying every game, and it applies to Crazy Time as well. Spread your best properly to balance out the risk and reward ratio.</li>
                            <li> You should always stay within your decided budget to manage your losses effectively if that is the case.</li>
                            <li> Always understand how the numerous multipliers work and which can give you the highest payout. This way, you can win big even with a small wager.</li>
                            <li> Enjoy the four bonus rounds: Cash Hunt, Pachinko, Coin Flip, and Crazy Time. You can win lucratively if you play bonus rounds well.</li>
                            <li> Play responsibly and enjoy the excitement of Crazy Time.</li>
                        </ul>
                        <h2><strong> Crazy Time Casino Game Payouts &amp; RTP</strong></h2>
                        
                        <p>Get ready for some crazy payouts as the name of the game goes!</p>
                        <p>The Money Wheel is divided into several sections for each result, each with a different reward. Here is a list of the base game and bonus game rewards.</p>
                        
                        <Table>
                            <tbody>
                                <tr>
                                    <td>
                                        <p><strong>Wheel Segment</strong></p>
                                    </td>
                                    <td>
                                        <p><strong>No. of Segments</strong></p>
                                    </td>
                                    <td>
                                        <p><strong>Payout</strong></p>
                                    </td>
                                    <td>
                                        <p><strong>RTP</strong></p>
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        <p>1</p>
                                    </td>
                                    <td>
                                        <p>21</p>
                                    </td>
                                    <td>
                                        <p>1:1</p>
                                    </td>
                                    <td>
                                        <p>96.08%</p>
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        <p>2</p>
                                    </td>
                                    <td>
                                        <p>13</p>
                                    </td>
                                    <td>
                                        <p>2:1</p>
                                    </td>
                                    <td>
                                        <p>95.95%</p>
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        <p>5</p>
                                    </td>
                                    <td>
                                        <p>7</p>
                                    </td>
                                    <td>
                                        <p>5:1</p>
                                    </td>
                                    <td>
                                        <p>95.78%</p>
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        <p>10</p>
                                    </td>
                                    <td>
                                        <p>4</p>
                                    </td>
                                    <td>
                                        <p>10:1</p>
                                    </td>
                                    <td>
                                        <p>95.73%</p>
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        <p>Coin Flip</p>
                                    </td>
                                    <td>
                                        <p>4</p>
                                    </td>
                                    <td>
                                        <p>Up to ₹5,00,00,000</p>
                                    </td>
                                    <td>
                                        <p>95.70%</p>
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        <p>Cash Hunt</p>
                                    </td>
                                    <td>
                                        <p>2</p>
                                    </td>
                                    <td>
                                        <p>Up to ₹5,00,00,000</p>
                                    </td>
                                    <td>
                                        <p>95.27%</p>
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        <p>Pachinko</p>
                                    </td>
                                    <td>
                                        <p>2</p>
                                    </td>
                                    <td>
                                        <p>Up to ₹5,00,00,000</p>
                                    </td>
                                    <td>
                                        <p>94.33%</p>
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        <p>Crazy Time</p>
                                    </td>
                                    <td>
                                        <p>1</p>
                                    </td>
                                    <td>
                                        <p>Up to ₹5,00,00,000</p>
                                    </td>
                                    <td>
                                        <p>94.41%</p>
                                    </td>
                                </tr>
                            </tbody>
                        </Table>
                        <h2><strong> Play Crazy Time for Real Money</strong></h2>
                        
                        <p>Crazy Time is a captivating <strong>live online game show</strong> that allows participants to bag real cash, all while indulging in a lively and interactive gaming session. The game takes place in a vibrant and expansive studio that comprises a central money wheel, a Top Slot positioned over this wheel, and four thrilling bonus games, Cash Hunt, Pachinko, Coin Flip, and the eponymous Crazy Time. The game is brought to life by real-time dealers, allowing players to engage with Crazy Time in real time.</p>
                        <p>To venture into Crazy Time with real stakes, players initiate by placing bets on the distinct wheel segments, which span Numbers, <strong><Link to={'/live-casino/evolution/crazy-coin-flip'}>Crazy Coin Flip</Link></strong>, Cash Hunt, and Pachinko. Each segment has distinct payout ratios and betting boundaries, subject to variations from one casino to another. The online version of Crazy Time showcases 54 divisions, encapsulating numbers 1, 2, 5, and 10, complemented by the four bonus areas.</p>
                        <p>The game is user-friendly, as players merely allocate their stakes on chosen numbers or the bonus rounds. Two such bonus rounds give players choices, enhancing the exhilaration and ensuring that prizes aren't repetitive. The Crazy Time bonus is the most rewarding, directing players to an alternative wheel with the potential to win up to 200x their wager.</p>
                        <p>Many online gaming platforms offer Crazy Time for genuine stakes, ensuring the game is played in real time and with actual bets. Players can revel in the game from their abode's tranquillity, eyeing rewards. Nonetheless, it is vital to approach the game judiciously, determine a spending limit, and firmly adhere to it.</p>
                        <p>Crazy Time stands out as one of <strong><Link to={'/live-casino/evolution'}>Evolution Gaming's</Link></strong> premier offerings, known for its engaging gameplay and lucrative payout opportunities. With its interactive wheel and diverse betting options, Evolution Crazy Time delivers an exhilarating gaming experience. Explore the exciting world of Evolution Gaming and uncover a variety of thrilling games that bring the magic of the casino right at your fingertips.</p>
                        <h2><strong> Reasons to Play Crazy Time Casino Game on </strong>FUN88&nbsp;</h2>
                        <ul>
                            <li >Crazy Time Evolution Game offers an exciting and unique gaming experience with its unique wheel of fortune feature.</li>
                            <li >The game offers a variety of bonus rounds and multipliers that can significantly increase your winnings.</li>
                            <li >It is easy to understand and play, making it suitable for both novice and experienced players.</li>
                            <li >The game has a high RTP (Return to Player) rate, which makes it a very rewarding game to play.</li>
                            <li >The game is visually appealing and immersive, allowing players to be fully engaged in the experience.</li>
                            <li >Crazy Time Casino Game offers an array of prizes and rewards that can be won.</li>
                            <li >Completely licensed &amp; legitimate casinos. FUN88 holds the E Gambling Montenegro license and the Isle of Man Gambling Supervisory Commission.</li>
                            <li >Deposit &amp; withdraw money without leaving the game.</li>
                            <li >If you ever get stuck while playing, you can use our &ldquo;Live Chat&rdquo; feature.</li>
                            <li >We have 24 / 7 <strong>customer care</strong> which is accessible through Whatsapp, Telegram, Email, and Phone.</li>
                        </ul>
                        <h2><strong> How to Deposit &amp; Withdraw to Play Crazy Time Game Online?</strong></h2>
                        <p>What&rsquo;s easier than making an account on FUN88 ? Adding and withdrawing funds. The process is quick and takes merely a couple of minutes.</p>
                        <p>When depositing, click on &ldquo;deposit&rdquo; on your screen. After this, choose the payment option you want to use, such as debit cards, credit cards, NEFT, UPI or E-wallet.&nbsp; The next step is to add the necessary details to make the payment. The deposited funds will be reflected in your account when payment is verified.</p>
                        <p>Just like depositing, withdrawing is also very quick, but depending on the option chosen, the time taken for funds to reflect in your account could be some time. You can choose from four different payment options. If you withdraw for the first time, you will need a password to initiate the process.</p>
                        <h2><strong> How to Bet on Crazy Time at </strong>FUN88&nbsp;</h2>
                        <ul>
                            <li ><strong>Register and Fund Your Account: </strong>Become a member of FUN88 by creating an account. Use your mobile number and password to log in to your account.</li>
                            <li ><strong>Check Bet Limits: </strong>Look at the bet limits panel to see the minimum and maximum bets you can place. These limits can change, so check them before you start.</li>
                        </ul>
                        <Table>
                            <tbody>
                                <tr>
                                    <td>
                                        <p>Minimum Bet</p>
                                    </td>
                                    <td>
                                        <p>₹10</p>
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        <p>Maximum Bet</p>
                                    </td>
                                    <td>
                                        <p>₹500,000</p>
                                    </td>
                                </tr>
                            </tbody>
                        </Table>
                        <ul>
                            <li ><strong>Ensure Sufficient Funds: </strong>Make sure you have enough money in your FUN88 account to cover your bets. You can see your balance on the screen.</li>
                            <li ><strong>Betting Timer:</strong>In the Immersive view, a timer shows how much time you have to place your bets. When the time runs out, no more bets are allowed. In the Classic view, traffic lights indicate the betting status: GREEN means you can bet, YELLOW means betting time is almost over, and RED means betting time has expired.</li>
                            <li ><strong>Choose Chip Value: </strong>Select the value of the chip you want to bet. Only chip values that you can afford with your current balance will be available.</li>
                        </ul>
                        <ul>
                            <li ><strong>Place Your Bets: </strong>Click or tap on the bet spot to place your bet. Each click/tap increases your bet by the chip value until you reach the maximum limit. Once you hit the maximum, no more bets can be placed, and you'll see a message indicating you've reached the limit.</li>
                        </ul>
                        <p>With these steps, you can easily place your bets on Crazy Time at FUN88 . Enjoy the game and good luck!</p>
                        <h2><strong> Play the Crazy Time on </strong>FUN88&nbsp;</h2>
                        <p>FUN88 is a reliable, trustworthy, and user-friendly platform where you can play the Crazy Time game online, along with several other fantastic games. Here are the steps to initiate:</p>
                        <p><strong>Create an Account:</strong></p>
                        <ul>
                            <li >If you are new, you need to create an account on Fu88 and verify it</li>
                        </ul>
                        <ul>
                            <li >Enter your username and password to log in</li>
                        </ul>
                        <ul>
                            <li >Go to the "Deposit" section, choose a payment method, and deposit funds</li>
                        </ul>
                        <ul>
                            <li >Go to the "Casino" or "Live Casino" section and select Crazy Time</li>
                            <li >Choose your bet amount and place bets on the game board</li>
                            <li >Watch the live dealer spin the wheel and wait for the outcome</li>
                            <li >Go to the "Withdraw" section, choose a withdrawal method, and withdraw your winnings</li>
                        </ul>
                        <h2><strong> Download </strong>FUN88&rsquo;s <strong>App &amp; Play Crazy Time Game on Your Mobile</strong></h2>
                        <p>Play online casino games like Crazy Time,<Link to={'/live-casino/andar-bahar'}>andar bahar online</Link> ,<Link to={'/live-casino/teen-patti'}>teen patti cash games</Link> , <Link to={'/live-casino/teen-patti'}>roulette casino games</Link>,<Link to={'/live-casino/live-blackjack'}>Blackjack games</Link> , <Link to={'/live-casino/live-baccarat'}>Baccarat games</Link> and  from anywhere by downloading FUN88&rsquo;s mobile app, available in both Android and iOS variants.</p>
                        
                        <p><strong> <Link to={'/app-download'}>Download Crazy Time App Now</Link></strong></p>
                        <h2><strong> Frequently Asked Questions (FAQs) About Crazy Time Game</strong></h2>
                        <p><strong> Where can I play Crazy Time casino in India?</strong><strong><br /></strong>You can play Crazy Time online at the FUN88 casino! Just register on FUN88 and start playing.</p>
                        <p><strong> How to play Crazy time casino games at </strong>FUN88 <strong>casino?</strong><strong><br /></strong>All you have to do is <Link to={'/signup'}>register</Link> on the FUN88 website or<Link to={'/app-download'}>Download App Now</Link>, which is available in both Android and iOS variants. Having done that, you have to go to our website's "Live Casino" tab, and you'll be able to play Crazy Time live.</p>
                        <p><strong> What is the minimum bet in Crazy Time?</strong><strong><br /></strong>The minimum bet varies depending on the platform but typically starts from as low as &pound;0.10.</p>
                        <p><strong> What is the maximum bet in Crazy Time?</strong><strong><br /></strong>Based on the casino's terms, the maximum bet can go up to &pound;1,000 or more.</p>
                        <p><strong> When do I get a bonus when playing the Crazy Time game?</strong><strong><br /></strong>According to Evolution Gaming, a bonus round will pop up approximately once every sixth spin of the wheel, providing numerous chances to win. How awesome! This game also offers Crazy Time results to help players make an informed decision.</p>
                        <p><strong> Is there a Crazy Time live casino dealer on </strong>FUN88 <strong>?</strong><strong><br /></strong>Yes, the live dealers in the Crazy Time casino game are known as Game Presenters. These game presenters are different from conventional live casino dealers wherein they entertain you and maintain a high energy level during the game.</p>
                        <p><strong> How do I track the results of the Crazy Time game?</strong><strong><br /></strong>You can track Crazy Time evolution easily by examining the summary results of the Crazy Time tracker's last spins to establish Crazy Time stats and the upcoming Crazy Time results.</p>
                        <p><strong> What is the betting time for Crazy Time?</strong><strong><br /></strong>The betting time is 13 seconds, therefore you should bet instantly.</p>
                        <p><strong> What's the bonus prize payout of Crazy Time?</strong><strong><br /></strong>Most bonus prizes will be paid out between 10x and 25x.</p>
                        <p><strong> What is a Crazy Time history tracker?</strong><strong><br /></strong>Crazy Time live trackers show the outcome &amp; insights into how the game is progressing. The history tracker shows the game statistics distinctly. It displays the Crazy Time results, the number of winners, and the total reward.</p>
                        <p><strong> Who is the provider of live Crazy Time casino?</strong><strong><br /></strong>Evolution Gaming is a leading live dealer casino gaming company that created and operates this Crazy Time live casino game. The game is only available through online casinos like FUN88, who have a partnership with Evolution Gaming.</p>
                        <p><strong> How many bonus games are there in Crazy Time?</strong><strong><br /></strong>Crazy-Time features four exciting bonus games: Cash Hunt, Pachinko, Coin Flip, and Crazy-Time.</p>
                    </div>
                </Accordion>
            </AccordionWrapper>
        </>
    )
}

export default withTheme(withAppContext(CrazytimeSEO46));

const Table = styled.table`
    border-collapse: collapse;
    tr, td {
        border: 1px solid black;
        border-collapse: collapse;
        text-align: left;
        padding: 0.5rem 1rem;
    }
    
    thead, th {
        border: 1px solid black;
        border-collapse: collapse;
        text-align: center;
        padding: 0.5rem 1rem;
    }
`;