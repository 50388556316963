import { gql } from '@apollo/client';


export const LOGIN = gql`
	mutation memberLogin($input: LoginInput!) {
		member {
			login(input: $input) {
				success
				code
				message
				data {
					... on LoginResponseData {
						memberId
						originalMemberId
						orderLimit {
							eventTypeId
							minOrder
							maxOrder
							maxMarket
						}
						exchangeRate
						type
						token
						isSubAccount
						isChangedPassword
						isHadAuth
						betfairInfo {
							... on BetfairInfo {
								categoryId
								betfairPt
							}
						}
					}
				}
			}
		}
	}
`;

export const LOGOUT = gql`
	mutation memberLogout {
		member {
			logout {
				success
				message
			}
		}
	}
`;

export const VALIDATE = gql`
	query memberValidate($input: ValidateInput!) {
		member {
			validate(input: $input) {
				success
				message
			}
		}
	}
`;

export const KEEPALIVE = gql`
	query memberKeepAlive {
		member {
			keepAlive {
				success
				message
			}
		}
	}
`;

export const GENERATE_QR_CODE = gql`
	query membergenerateOTP {
		member {
			generateOTP {
				imgSrc
				secretCode
			}
		}
	}
`;

export const BIND_GAUTH = gql`
	mutation memberbindOTP($input: BindOTPRequest!) {
		member {
			bindOTP(input: $input) {
				success
				message
			}
		}
	}
`;
