import React from 'react'

type Props = {}

const BetdailyAppStepThreeIcon = (props: Props) => {
  return (
    <svg
    width={48}
    height={48}
    viewBox="0 0 48 48"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <g filter="url(#filter0_b_702_23)">
      <circle cx={24} cy={24} r={24} fill="url(#paint0_linear_702_23)" />
      <circle cx={24} cy={24} r={24} fill="url(#paint1_linear_702_23)" />
    </g>
    <g clipPath="url(#clip0_702_23)">
      <g filter="url(#filter1_b_702_23)">
        <path
          d="M30.1101 12.6542H16.89C16.6138 12.6542 16.3899 12.8828 16.3899 13.1649V34.835C16.3899 35.1171 16.6138 35.3458 16.89 35.3458H30.1101C30.3863 35.3458 30.6102 35.1171 30.6102 34.835V13.1649C30.6102 12.8828 30.3863 12.6542 30.1101 12.6542Z"
          fill="url(#paint2_linear_702_23)"
        />
        <path
          d="M30.1101 12.6542H16.89C16.6138 12.6542 16.3899 12.8828 16.3899 13.1649V34.835C16.3899 35.1171 16.6138 35.3458 16.89 35.3458H30.1101C30.3863 35.3458 30.6102 35.1171 30.6102 34.835V13.1649C30.6102 12.8828 30.3863 12.6542 30.1101 12.6542Z"
          fill="url(#paint3_linear_702_23)"
        />
      </g>
      <path
        d="M29.0358 8H17.9642C16.3272 8 15 9.35549 15 11.0274V36.9726C15 38.6447 16.3272 40 17.9642 40H29.0358C30.6729 40 32 38.6445 32 36.9726V11.0274C32 9.35549 30.6729 8 29.0358 8ZM30.6102 34.835C30.6102 35.1172 30.3862 35.3458 30.1101 35.3458H16.8901C16.6138 35.3458 16.39 35.1171 16.39 34.835V13.165C16.39 12.8828 16.614 12.6542 16.8901 12.6542H30.1101C30.3864 12.6542 30.6102 12.8829 30.6102 13.165V34.835Z"
        fill="white"
      />
      <path
        d="M24.9546 10.7238H22.0454C21.8159 10.7238 21.6299 10.5338 21.6299 10.2994C21.6299 10.065 21.8159 9.875 22.0454 9.875H24.9546C25.1842 9.875 25.3702 10.065 25.3702 10.2994C25.3702 10.5338 25.1842 10.7238 24.9546 10.7238Z"
        fill="white"
      />
      <g filter="url(#filter2_b_702_23)">
        <path
          d="M24.9546 10.7238H22.0454C21.8159 10.7238 21.6299 10.5338 21.6299 10.2994C21.6299 10.065 21.8159 9.875 22.0454 9.875H24.9546C25.1842 9.875 25.3702 10.065 25.3702 10.2994C25.3702 10.5338 25.1842 10.7238 24.9546 10.7238Z"
          fill="url(#paint4_linear_702_23)"
        />
        <path
          d="M24.9546 10.7238H22.0454C21.8159 10.7238 21.6299 10.5338 21.6299 10.2994C21.6299 10.065 21.8159 9.875 22.0454 9.875H24.9546C25.1842 9.875 25.3702 10.065 25.3702 10.2994C25.3702 10.5338 25.1842 10.7238 24.9546 10.7238Z"
          fill="url(#paint5_linear_702_23)"
        />
      </g>
    </g>
    <defs>
      <filter
        id="filter0_b_702_23"
        x={-4150}
        y={-4150}
        width={8348}
        height={8348}
        filterUnits="userSpaceOnUse"
        colorInterpolationFilters="sRGB"
      >
        <feFlood floodOpacity={0} result="BackgroundImageFix" />
        <feGaussianBlur in="BackgroundImageFix" stdDeviation={2075} />
        <feComposite
          in2="SourceAlpha"
          operator="in"
          result="effect1_backgroundBlur_702_23"
        />
        <feBlend
          mode="normal"
          in="SourceGraphic"
          in2="effect1_backgroundBlur_702_23"
          result="shape"
        />
      </filter>
      <filter
        id="filter1_b_702_23"
        x={-4133.61}
        y={-4137.35}
        width={8314.22}
        height={8322.69}
        filterUnits="userSpaceOnUse"
        colorInterpolationFilters="sRGB"
      >
        <feFlood floodOpacity={0} result="BackgroundImageFix" />
        <feGaussianBlur in="BackgroundImageFix" stdDeviation={2075} />
        <feComposite
          in2="SourceAlpha"
          operator="in"
          result="effect1_backgroundBlur_702_23"
        />
        <feBlend
          mode="normal"
          in="SourceGraphic"
          in2="effect1_backgroundBlur_702_23"
          result="shape"
        />
      </filter>
      <filter
        id="filter2_b_702_23"
        x={-4128.37}
        y={-4140.12}
        width={8303.74}
        height={8300.85}
        filterUnits="userSpaceOnUse"
        colorInterpolationFilters="sRGB"
      >
        <feFlood floodOpacity={0} result="BackgroundImageFix" />
        <feGaussianBlur in="BackgroundImageFix" stdDeviation={2075} />
        <feComposite
          in2="SourceAlpha"
          operator="in"
          result="effect1_backgroundBlur_702_23"
        />
        <feBlend
          mode="normal"
          in="SourceGraphic"
          in2="effect1_backgroundBlur_702_23"
          result="shape"
        />
      </filter>
      <linearGradient
        id="paint0_linear_702_23"
        x1={0}
        y1={0}
        x2={47.0669}
        y2={2.39871}
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#6F6DE6" />
        <stop offset={0.489583} stopColor="#5451D4" />
        <stop offset={1} stopColor="#312DB9" />
      </linearGradient>
      <linearGradient
        id="paint1_linear_702_23"
        x1={24}
        y1={0}
        x2={24}
        y2={48}
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#B21318" />
        <stop offset={1} stopColor="#7D0204" />
      </linearGradient>
      <linearGradient
        id="paint2_linear_702_23"
        x1={16.3899}
        y1={12.6542}
        x2={30.3558}
        y2={13.1002}
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#6F6DE6" />
        <stop offset={0.489583} stopColor="#5451D4" />
        <stop offset={1} stopColor="#312DB9" />
      </linearGradient>
      <linearGradient
        id="paint3_linear_702_23"
        x1={23.5001}
        y1={12.6542}
        x2={23.5001}
        y2={35.3458}
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#B21318" />
        <stop offset={1} stopColor="#7D0204" />
      </linearGradient>
      <linearGradient
        id="paint4_linear_702_23"
        x1={21.6299}
        y1={9.875}
        x2={25.1304}
        y2={10.6611}
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#6F6DE6" />
        <stop offset={0.489583} stopColor="#5451D4" />
        <stop offset={1} stopColor="#312DB9" />
      </linearGradient>
      <linearGradient
        id="paint5_linear_702_23"
        x1={23.5}
        y1={9.875}
        x2={23.5}
        y2={10.7238}
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#B21318" />
        <stop offset={1} stopColor="#7D0204" />
      </linearGradient>
      <clipPath id="clip0_702_23">
        <rect width={17} height={32} fill="white" transform="translate(15 8)" />
      </clipPath>
    </defs>
  </svg>
  )
}

export default BetdailyAppStepThreeIcon