import * as React from 'react';
import { createPortal } from 'react-dom';
import styled from 'styled-components';
import { theme } from 'styled-tools';

interface IProps {
	overflow?: string;
	onOverlayClick?: () => void;
}

// 將內容顯示畫面中間，背景加深
class CenterPortal extends React.PureComponent<IProps> {
	modalRoot: any;
	modalNode: any;
	ref: any;

	constructor(props: IProps) {
		super(props);
		this.modalNode = document.createElement('div');
		this.modalRoot = document.body;
	}

	componentDidMount() {
		this.modalRoot && this.modalRoot.appendChild(this.modalNode);
	}

	componentWillUnmount() {
		this.modalRoot && this.modalRoot.removeChild(this.modalNode);
	}

	handleClick = (e: any) => {
		const { onOverlayClick }: IProps = this.props;
		if (e.target === this.ref) {
			onOverlayClick && onOverlayClick();
		}
	};

	render() {
		return createPortal(
			<ModalContainer>
				<ModalOverlay />
				<ModalContentWrapper
					ref={(ref: any) => {
						this.ref = ref;
					}}
					onMouseDown={this.handleClick}
				>
					<ModalContent overflow={this.props.overflow}>{this.props.children}</ModalContent>
				</ModalContentWrapper>
			</ModalContainer>,
			this.modalNode,
		);
	}
}

export default CenterPortal;

const ModalContainer = styled.div``;
const ModalContentWrapper = styled.div`
	position: fixed;
	display: flex;
	z-index: 998;
	top: 0;
	left: 0;
	height: 100%;
	width: 100%;
	align-items: center;
	justify-content: center;
`;
const ModalOverlay = styled.div`
	position: fixed;
	z-index: 998;
	opacity: 0.75;
	top: 0;
	left: 0;
	height: 100%;
	width: 100%;
	background: ${theme('palette.body-background')};
`;
const ModalContent = styled.div`
	overflow: ${(props: IProps) => (props.overflow ? props.overflow : 'initial')};
`;
