import React, { Suspense, useEffect } from "react";
// import Banners from 'src/components/Banner/index';
// import MultipleItems from 'src/components/slick-slider';
// import TopGames from "src/components/top-games/index";
// import HomeExchange from "src/components/home-exchange/index";
//import HomePopular from "src/components/home-popular/index";
// import CenterMode from "src/components/LiveDealerCraousel/index"
// import LiveGamesWrapper from "src/components/home-live-games/index";
// import BBBEdge from 'src/components/BBBEdge/index';
// import FooterContainer from "src/components/footer/footer";
// import FlagsContainer from "src/components/flags/flags";
import { withAppContext } from "src/store/initAppContext";
import withSystem from "src/HOC/withSystem";
import { withTheme } from "styled-components";
// import BonusHomepage from 'src/components/udb/bonus/BonusHomepage';
// import LoyaltyHomeComponent from 'src/components/Loyalty/HomeLoyalty';
// import styled from "styled-components";
import { Helmet } from "react-helmet";
import { CRMLoginSuccess, CRMPageVisit, CRMRegistrationSuccess } from "src/util/crmUtil";
import getSchemaData from "src/components/schema";
// import LeaderBoard from "src/components/leaderboard";

const Banners = React.lazy(() => import("src/components/Banner/index"));
const MultipleItems = React.lazy(() => import("src/components/slick-slider"));
const TopGames = React.lazy(() => import("src/components/top-games/index"));
const HomeExchange = React.lazy(
  () => import("src/components/home-exchange/index")
);
const BonusHomepage = React.lazy(
  () => import("src/components/udb/bonus/BonusHomepage")
);
const LoyaltyHomeComponent = React.lazy(
  () => import("src/components/Loyalty/HomeLoyalty")
);
const CenterMode = React.lazy(
  () => import("src/components/LiveDealerCraousel/index")
);
const LiveGamesWrapper = React.lazy(
  () => import("src/components/home-live-games/index")
);
const FlagsContainer = React.lazy(() => import("src/components/flags/flags"));
const FooterContainer = React.lazy(
  () => import("src/components/footer/footer")
);
const BBBEdge = React.lazy(() => import("src/components/BBBEdge/index"));
const SeoContentHomePage = React.lazy(() => import("src/components/seo-content/seo_content"));

const WhatsappIco = React.lazy(() => import("src/components/WhatsappIco"));



// const LeaderBoard = 

const structuredData = () => {
  const data = {
    "@context": "http://schema.org",
    "@type": "Product",
    "brand": {
      "@type": "Brand",
      "name": "Yolo247"
    },
    "aggregateRating": {
      "@type": "AggregateRating",
      "ratingValue": "4.3",
      "bestRating": "5",
      "reviewCount": "742"
    }
  }

  return JSON.stringify(data)
}

const structuredDatafun88Schema47 = () => {
  const otherschemaData = getSchemaData('default');
  const data = [
    { 
      "@context": "https://schema.org/",
      "@type": "BreadcrumbList", 
      "itemListElement": [{ 
          "@type": "ListItem",
          "position": 1, 
          "name": "Fun88", 
          "item": "https://www.gofun88.in"
        }
      ]
    },
    ...otherschemaData
  ]

  return JSON.stringify(data)
};

const structuredDatafun88BCL = () => {
  const data = { 
    "@context": "https://schema.org/",
    "@type": "BreadcrumbList", 
    "itemListElement": [
      { "@type": "ListItem",
         "position": 1, 
         "name": "Fun88", 
         "item": "https://www.gofun88.in"
        }
      ] 
    } 

  return JSON.stringify(data)
}

const structuredDatafun88Org = () => {
  const data = {
    "@context": "https://schema.org",
    "@type": "Organization",
    "name": "Fun88",
    "url": "https://www.gofun88.in",
    "logo": "https://www.gofun88.in/static/images/header/logo.svg?2",
    "sameAs": [
          "https://www.facebook.com/Fun88India",
          "https://www.instagram.com/fun88indofficial/",
          "https://twitter.com/Fun88India"
    ],
    "contactPoint": [
          {
            "@type": "ContactPoint",
            "telephone": "+91 22 50323347",
            "contactType": "customer service",
            "email": "cs@fun88india.com",
            "areaServed": "IN"
          }
    ]
  }

  return JSON.stringify(data)
}

const structuredDatafun88Nav = () => {
  const data = {
    "@context":"http://schema.org",
    "@type":"SiteNavigationElement",
    "name":["Fun88", "Promotions", "Live Casino", "Instant Games", "Virtual Sports", "Slots", "Sponsorships", "Refer A Friend", "VIP", "Rewards", "Contact Us", "Fun88 App", "Affiliate", "FAQ", "Blog", "Hindi Blog", "Telugu Blog"],
    "url":["https://www.gofun88.in",
    "https://www.gofun88.in/promotions",
    "https://www.gofun88.in/live-casino",
    "https://www.gofun88.in/instant-games",
    "https://www.gofun88.in/sports",
    "https://www.gofun88.in/virtualSports",
    "https://www.gofun88.in/slots",
    "https://www.gofun88.in/promotions/referral-bonus",
    "https://www.gofun88.in/loyalty",
    "https://www.gofun88.in/rewards",
    "https://www.gofun88.in/contact-us",
    "https://www.gofun88.in/app-download",
    "https://www.gofun88.in/affiliates",
    "https://www.gofun88.in/faq",
    "https://www.gofun88.in/blog/",
    "https://www.gofun88.in/hi/blog/",
    "https://www.gofun88.in/te/blog/"]
    }

  return JSON.stringify(data)
}

const structuredDatafun88BCL46 = () => {
  const otherschemaData = getSchemaData('default');

  const data = [
    {
      "@context": "https://schema.org/",
      "@type": "BreadcrumbList",
      "itemListElement": [
        {
          "@type": "ListItem",
          "position": 1,
          "name": "Fun88 ID",
          "item": "https://www.fun88.site"
        }]
    },
    ...otherschemaData
  ]

  return JSON.stringify(data)
}

const structuredDatafun88Nav46 = () => {
  const data = {
    "@context":"http://schema.org",
    "@type":"SiteNavigationElement",
    "name":["Fun88 ID", "Live Casino ID", "Cricket Betting ID"],
    "url":["https://www.gofun88.club",
    "https://www.gofun88.club/live-casino",
    "https://www.gofun88.club/betting-exchange/online-cricket-betting"]
    }

  return JSON.stringify(data)
}

const structuredDatafun88Webpage = () => {
  const data =  { "@context": "https://schema.org/", "@type": "WebPage", "name": "Fun88 Online Betting ID", "description": "Get your Fun88 online betting ID instantly through WhatsApp in just one click! Fast, secure, and easy. Get yours now!", "url": "https://www.gofun88.club"}

  return JSON.stringify(data)
}


function Home(props) {
  const { theme, app:{brandId} } = props;
  const { config: { 
    showBBEedge, 
    showCenterMode, 
    showHomebanners, 
    showLiveGamesWrapper, 
    showSEOdropdown,
    showcasinoslider,
    showTopGamesSection,
    showFooter,
    showFlags,
    showWhatsAppAfterLogin,
    showNotcasinoslider
  } } = theme;


  useEffect(() => {
    const t = setTimeout(()=> {
      CRMPageVisit();
      //Adding Some CRM Event here
      const isjustLogin = sessionStorage.getItem('uin');
      if (isjustLogin) {
        const { brandId, username, memberId, isreg } = JSON.parse(decodeURI(isjustLogin));
        //fire CRM
        //CRM Login Succes
        if (isreg) {
          CRMRegistrationSuccess(brandId, username, memberId);
        }
        else {
          CRMLoginSuccess(brandId, username, memberId);
        }
  
       sessionStorage.removeItem('uin');
      }

    },300);

    return ()=> clearTimeout(t);
   
  }, []);


  

  return (
    <>
    {brandId && brandId == 31 && <Helmet>
        <script type="application/ld+json">
          {
            structuredData()
          }
        </script>
        <meta property="og:title" content="Online Betting ID | Best Site for Casino & Sports | Yolo247" />
        <meta property="og:site_name" content="Yolo247" />
        <meta property="og:url" content="https://www.yolo247.site" />
        <meta property="og:description" content="Online betting ID makes casino & sports betting experience better. On Yolo247, Get betting ID through WhatsApp instantly. Faster deposit & withdrawal." />
        <meta property="og:type" content="website" />
        <meta property="og:image" content="https://opt-v3-files.raksahb.com/static/cashsite/brand/6/logo/yoloLogo.png" />
      </Helmet>}

      {brandId && brandId == 32 && <Helmet>
        <script type="application/ld+json">
          {
            structuredData()
          }
        </script>
        <meta property="og:title" content="Online Betting ID | Best Site for Casino & Sports | Yolo247" />
        <meta property="og:site_name" content="Yolo247 Club" />
        <meta property="og:url" content="https://www.yolo247.club" />
        <meta property="og:description" content="Online betting ID makes casino & sports betting experience better. On Yolo247, Get betting ID through WhatsApp instantly. Faster deposit & withdrawal." />
        <meta property="og:type" content="website" />
        <meta property="og:image" content="https://opt-v3-files.raksahb.com/static/cashsite/brand/6/logo/yoloLogo.png" />
      </Helmet>
      }

      {brandId && brandId == 33 && <Helmet>
        {/* <script type="application/ld+json">
          {
            structuredData()
          }
        </script> */}
        <meta property="og:title" content="Your Ultimate Destination for Online Betting in India | IW247" />
        <meta property="og:site_name" content="IW247" />
        <meta property="og:url" content="https://www.iw247.com" />
        <meta property="og:description" content="Experience the thrill of online betting and gambling in India with IW247, the trusted site for online betting and real money gambling. Join now!." />
        <meta property="og:type" content="website" />
        <meta property="og:image" content="https://opt-v3-files.raksahb.com/static/cashsite/brand/6/logo/iw247logo.png" />
      </Helmet>}

      {brandId && (brandId == 39) && <Helmet>
        {/* <script type="application/ld+json">
          {
            structuredData()
          }
        </script> */}
        <meta property="og:title" content="Your Ultimate Destination for Online Betting in India | fomo7" />
        <meta property="og:site_name" content="fomo7" />
        <meta property="og:url" content="https://www.fomo7.com" />
        <meta property="og:description" content="Experience the thrill of online betting and gambling in India with fomo7, the trusted site for online betting and real money gambling. Join now!." />
        <meta property="og:type" content="website" />
        <meta property="og:image" content="https://opt-v3-files.raksahb.com/static/cashsite/brand/6/logo/fomo7.png" />
      </Helmet>}
      {brandId && (brandId == 40) && <Helmet>
        {/* <script type="application/ld+json">
          {
            structuredData()
          }
        </script> */}
        <meta property="og:site_name" content="fomo7" />
        <meta property="og:url" content="https://www.fomo7.xyz" />
      </Helmet>}

      {brandId && brandId == 47 && <Helmet>
        <script type="application/ld+json">
          {
            structuredDatafun88Schema47()
          }
        </script>
       <meta property="og:title" content="Fun88" />
       <meta property="og:description" content="Fun88 is a trusted online betting site in India with 100+ online casino games, slots games, &amp; 10K different sports with the best betting odds. Join now for exciting betting online!"/>
       <meta property="og:url" content="https://www.gofun88.in" />
       <meta property="og:locale" content="en_US" />
       <meta property="og:type" content="website" />
        <meta property="og:site_name" content="Fun88" />
        <meta property="og:image" content="https://www.gofun88.in/fimg/i202202f0ca4a8c7be644e4b3ed32cfed3d2586.png" />
        <meta name="facebook-domain-verification"content="d03sv42ebdn66oz0hpimwajzpkdpih" />
      </Helmet>}

      {brandId && brandId == 46 && <Helmet>
        <script type="application/ld+json">
          {
           structuredDatafun88BCL46()

          }
        </script>
        {/* <script type="application/ld+json">
          {
            structuredDatafun88Webpage()

          }
        </script>
        <script type="application/ld+json">
          {
           structuredDatafun88Nav46()

          }
        </script> */}
       <meta property="og:title" content="Get Your Online Betting ID Instantly on WhatsApp | Fun88" />
       <meta property="og:description" content="Get your Fun88 online betting ID instantly through WhatsApp in just one click! Fast, secure, and easy. Get yours now!"/>
       <meta property="og:url" content="https://www.gofun88.club" />
       <meta property="og:locale" content="en_US" />
       <meta property="og:type" content="website" />
      </Helmet>}

      <Suspense fallback={<div>Loading...</div>}>
       {showcasinoslider && <MultipleItems />}
      </Suspense>
      <Suspense fallback={<div>Loading...</div>}>
        {showHomebanners && <Banners />}
      </Suspense>
      {<Suspense fallback={<div>Loading...</div>}>
       {showNotcasinoslider && <MultipleItems />}
      </Suspense>}
      <Suspense fallback={<div>Loading...</div>}>
        {showTopGamesSection && <TopGames />}
      </Suspense>
      <Suspense fallback={<div>Loading...</div>}>
        <HomeExchange />
      </Suspense>
     
      <Suspense fallback={<div>Loading...</div>}>
        <BonusHomepage />
      </Suspense>


      {/* <HomePopular /> */}
      {/* <LoyaltyHomeComponent/> */}

      <Suspense fallback={<div>Loading...</div>}>
        <LoyaltyHomeComponent />
      </Suspense>
      <Suspense fallback={<div>Loading...</div>}>
        {showCenterMode && <CenterMode />}
      </Suspense>
       <Suspense fallback={<div>Loading...</div>}>
      {showLiveGamesWrapper && <LiveGamesWrapper />}
      </Suspense>
     <Suspense fallback={<div>Loading...</div>}>
       { showBBEedge && <BBBEdge />}
      </Suspense>
      <Suspense fallback={<div>Loading...</div>}>
       { showFlags && <FlagsContainer />}
      </Suspense>
      <Suspense fallback={<div>Loading...</div>}>
       {showSEOdropdown && <SeoContentHomePage/>}
      </Suspense>
      <Suspense fallback={<div>Loading...</div>}>
        {showFooter && <FooterContainer />}
      </Suspense>
     {showWhatsAppAfterLogin &&  <Suspense fallback={<div>Loading...</div>}> <WhatsappIco /></Suspense>}
     
    </>
  );
}

export default withAppContext(withTheme(Home));


