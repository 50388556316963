import React from 'react';
import eventUtil from '../util/eventUtil';
import { EEventEmit } from '../util/utilModel';
import DocumentActive from './DocumentActive';

interface IDocumentActiveProps {
	children: (pollingTime: undefined | number) => React.ReactNode;
	pollingTime: number;
	isOnlyUsePageVisable?: boolean;
}

interface IDocumentActiveState {
	isPolling: boolean;
}

export default class GQLPolling extends React.Component<IDocumentActiveProps, IDocumentActiveState> {
	state = {
		isPolling: false,
	};
	togglePollingWhenPushError = ({ detail = {} }: any) => {
		const { isConnected = false } = detail;
		this.setState({ isPolling: !isConnected });
	};
	componentDidMount() {
		eventUtil.on(EEventEmit.EVENT_PUSH_ERROR, this.togglePollingWhenPushError);
	}

	componentWillUnmount() {
		eventUtil.remove(EEventEmit.EVENT_PUSH_ERROR, this.togglePollingWhenPushError);
	}

	render() {
		if (typeof this.props.children !== 'function') {
			console.warn('GQLPolling must provide function as children.');
			return null;
		}
		const { isPolling } = this.state;
		const { pollingTime, isOnlyUsePageVisable } = this.props;
		return (
			<DocumentActive>
				{(isPageVisible: boolean) =>
					this.props.children(isOnlyUsePageVisable || isPolling ? (isPageVisible ? pollingTime : 60000) : 0)
				}
			</DocumentActive>
		);
	}
}
