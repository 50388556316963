import React, { useEffect, useState } from 'react';
import styled, { withTheme } from 'styled-components';
import { HeadingTextWrapper } from '../../common/Headings';
// import CasinoSlider from './casino-slider';
// import RecommendedGames from './recommended-games';
// import CasinoTabGames, { allIcon } from './casino-tab-games';
import { bgcolor, color } from '../../../sass-files/variable';
// import BingoMachine from '../../../images/casino-images/bingo_machine_laptop.jpg';
// import goldenegglaptop from '../../../images/casino-images/golden_egg_laptop.jpg'
import { withAppContext } from 'src/store/initAppContext';
// import Loading from '../../../pages/loading'
// import LeftArrow from "../../../images/arrow-left_gold.png"
import { useMutation } from '@apollo/client';
import { ONE_CLICK_RECYCLE } from 'src/graphql/system';
import { useNavigate, useParams } from "react-router-dom";
import popularGamesData from "src/static/game-data/populargames.json";
import PlayDemoGames from './bingo-slotGames';

import Tabs from './casino-tab';
import {motion} from 'framer-motion';
import config from 'src/config/config';
import Intl from '../../common/Intl'
import { theme } from 'styled-tools';


// import andaarbahar from 'src/images/casino-images/teenpattiandarbahar.jpg';

// import SPAviator from 'src/images/casino-images/SP-AVIATOR.jpg';
// import SPGoal from 'src/images/casino-images/SP-GOAL.jpg';
// import dragontiger from 'src/images/casino-images/teenpattidragontiger.jpg';
// import SPHilo from 'src/images/casino-images/SP-HILO.jpg';
import SEOContents from 'src/components/SEOContents';      
import PopularGamesSEO from 'src/components/SEOComponents/popularGamesSEO';
// const Language = cookieUtil.get(ECookieName.COOKIE_LANG);

import { Helmet } from "react-helmet";

const structuredData = (domain) => {
    const data = {
        "@context": "https://schema.org",
        "@type": "BreadcrumbList",
        "itemListElement":
            [
                {
                    "@type": "ListItem",
                    "item": `${domain}`,
                    "name": "Homepage",
                    "position": 1
                },
                {
                    "@type": "ListItem",
                    "item": `${domain}/popular-games`,
                    "name": "Popular Games",
                    "position": 2
                }],
        "name": "Breadcrumbs"
    }

    return JSON.stringify(data)
}


const popularicon = `${'/static/media/popularicon.png'}`;


let POPULAR = <Intl langKey="TITLE@populargames"/>;
let SMARTSOFT = <Intl langKey="TITLE@smartsoft"/>;

const providerTab = [POPULAR];


const providerIcons = [
    {
        id:1,
        name: POPULAR,
        icon: popularicon
    },
       
    // {
    //     id:2,
    //     name: SMARTSOFT,
    //     icon: smartsoft
    // },
 

]

const {titleHeadColor} = color

const { secondarycolor, menucolor } = color;
const {loginbghover} = bgcolor
const {signupbghover} = bgcolor

function PopularGames(props: any) {
    const { app: {
    }, theme } = props;
    const { showSEOfooterForYolo } = theme.config;
    
    // showPopulargamesfooter
    // const [isGameTrigger, setGameTrigger] = useState(false)
    // const [gameDetails, setGameDetails] = useState({})
    const [reload,setReload] = useState(false)
    const [triggerOneClickRecycle]: any = useMutation(ONE_CLICK_RECYCLE)
    // const [filterBingoImages,setFilterBingoImages] = useState([])

    const [casinoImages, setCasinoImages] = useState(popularGamesData);
    const [filteredData, setfilteredData] = useState([]);
    const [search,setSearch]=useState('');
    const {id} = useParams();

    useEffect(() => {
        // Now Lazy load game data 
        // import('src/static/game-data/populargames.json').then(data => data.default).then(data => {
        //     setCasinoImages(data);

            if(id){
                searchData(id);
            } else {
                setfilteredData(casinoImages)
            }

        // }).catch(err => console.log('Something went wrong. Unable to fetch game data', err))
        window.scrollTo(0,0)
    },[])

     const backButton = () => {
        window.location.reload();
        triggerOneClickRecycle()
            .then((response) => {
                if (response) {
                    return;
                }
            })
            .catch((error) => {
                console.log("Error in one click recycle");
            });
    }

    const searchData=(val:any)=>{
        setSearch(val)
        if(val !== ""){
            
            const filteredItems= casinoImages.filter((item)=>{
                const { imgsrc, ...rest } = item;
                return  Object.values(rest).join('').toLowerCase().includes(val.toLowerCase())
              })
              setfilteredData(filteredItems)
        }
        else{
            setfilteredData(casinoImages)
        }
    }



    const budgetData = ()=>{
      
    }

    const { app: { brandId } } = props;

    let domainURL = 'https://www.yolo247.site';

    if (brandId === 32) {
        domainURL = 'https://www.yolo247.club';
    }
    else if (brandId === 33) {
        domainURL = 'https://www.iw247.com';
    }
    else if (brandId === 34) {
        domainURL = 'https://www.betdaily.club';
    }
    else if (brandId === 39) {
        domainURL = 'https://www.fomo7.com';
    }
    else if (brandId === 40) {
        domainURL = 'https://www.fomo7.xyz';
    }
    else if (brandId === 46) {
        domainURL = 'https://www.fun88.site';
    }
    else if (brandId === 47) {
        domainURL = 'https://www.gofun88.in';
    }

    return (
        <motion.div 
        initial={{opacity: 0}}
        animate={{opacity: 1}}
        exit={{opacity: 0}}
        >
        <SEOContents pageName={"popular-games"}/>
        <Helmet>
                <script type="application/ld+json">
                    {
                        structuredData(domainURL)
                    }
                </script>
            </Helmet>
        <CasinoWrapper theme={props.app.theme} customStyle={props.theme.image}>
            <TitleHead theme={props.app.theme} customStyle={props.theme.palette}><Intl langKey="TITLE@populargames" /></TitleHead>
            {/* <BackButton onClick={backButton}> <img src={LeftArrow}/>
            <Intl langKey="BUTTON@BACK" />            
            </BackButton> */}
            
            
            <TabListSection>
            <Tabs
                    searchText={search}
                    providerIcons={providerIcons} 
                    tabs={providerTab}
                    onSearch={searchData}
                    isTabsHidden={false}
                    budgetClick={budgetData}
                    minWidthInContent={'initial'}
                    render={(index) => {
                        return (
                            <>                           
                                { 
                                    providerTab[index] === POPULAR && <PlayDemoGames data={filteredData}/>
                                    
                                }
                               {/* {
                                    providerTab[index] === SMARTSOFT && <PlayDemoGames data={filteredData.filter((element, index)=>{
                                        return element.type === 'SMARTSOFT'
                                    })}/>
                                } */}
                                    
                            </>
                        )
                    }}
                />                  
            </TabListSection>




            </CasinoWrapper>
           {(showSEOfooterForYolo && !(brandId == 39 || brandId == 40)) && <PopularGamesSEO/>}
        </motion.div>
    )
}

export default withTheme(withAppContext(PopularGames)); 



const TabListSection = styled.div `
    width: 100%;
    display: flex;
    align-items: flex-start;
    
`;

const CasinoWrapper:any = styled.div`
    width: 100%;
    display: flex;
    align-items: flex-start;
    flex-direction: column;
    /* margin-top: -5px; */
    background-image: url('${(props:any) => `${config.imageV3Host}/static/cashsite/${props.customStyle['homeExchangeBgImage']}`}');
    /* background-image: url('${(props:any) => (props.theme === 'Dark' ? `${config.imageS3Host}/static/snk/${props.customStyle['new_whitelable_dark_background']}` : `${config.imageS3Host}/static/snk/${props.customStyle['new_whitelable_light_background']}`)}'); */
    background-position: 100%;
    background-size: cover;
`;

const TitleHead:any = styled(HeadingTextWrapper)`
    font-size: 28px;
    letter-spacing: 3px;
    text-transform: uppercase;
    white-space: nowrap;
    font-weight: bold;
    /* color: ${(props:any) => (props.theme === 'Dark' ? '#FFF' : '#F4A322')}; */
    color: ${(props:any) => (props.theme === 'Dark' ? '#FFF' : props.customStyle['casinoTitleHeadTextColor'])};
    margin-left: 1em;
    padding-top: 10px;
    
    position: relative;
    margin-bottom: 10px;
    padding-bottom: 5px;

    :after {
        content: "";
        width: 500px;
        height: 2px;
        background: ${(props:any) => (props.customStyle['casinoTitleHeadBorderBtmLineColor'])};
        position: absolute;
        bottom: 0;
        left: 0;
    }
	
	@media only screen and (max-width: 992px){
		font-size: 30px;
	}	
`;

const SectionWrapper = styled.div`
    width: 100%;
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    flex-direction: row;

    img {
        height:100%;
        width: 100%;

    }

    .overlay {
        position: absolute;
        bottom: 0;
        left: 0;
        right: 0;
        background-color: #0a285edb;
        overflow: hidden;
        width: 100%;
        height: 100%;
        -webkit-transform: scale(0);
        -ms-transform: scale(0);
        transform: scale(0);
        -webkit-transition: .5s ease;
        transition: .5s ease;
      }
      
      .buttons {
        color: #fff;
        font-size: 20px;
        position: absolute;
        top: 50%;
        left: 50%;
        -webkit-transform: translate(-50%, -50%);
        -ms-transform: translate(-50%, -50%);
        transform: translate(-50%, -50%);
        text-align: center;
        display: flex;
        align-items: center;
        justify-content: center;
      }
`;

const PlayButton = styled.div`
    transform: skew(340deg);
    border: 1px solid #CB9640;
   
    box-shadow: 3px 5px 4px rgba(0, 0, 0, 0.25);

    :hover {
        background: ${theme('palette.primary-background-color-linear-new')};
        transition: color 0.1s linear 0s;
    }

    button {
        width: 80px;
        text-align: center;
        display: block;
        text-decoration: none;
        padding: 10px 0px;
        font-size: 14px;
        transform: skew(-340deg);
        color: ${secondarycolor};
        
        text-transform: uppercase;
        background-color: transparent;
        border: none;
        cursor: pointer;
    }
`;

const DemoButton = styled.div`
    transform: skew(340deg);
    border: 1px solid #CB9640;
    margin-left: 10px;
    background: linear gradient(#37B8BB(60% opacity),#1C5C5E(40% opacity));
    box-shadow: 3px 5px 4px rgba(0, 0, 0, 0.25);
    transition: color 0.1s linear 0s;

    :hover {
        background: ${theme('palette.primary-background-color-linear-new')};
        transition: color 0.1s linear 0s;
    }

    button {
        width: 80px;
        text-align: center;
        display: block;
        text-decoration: none;
        padding: 10px 0px;
        font-size: 14px;
        transform: skew(-340deg);
        color: ${secondarycolor};
        
        text-transform: uppercase;
        background-color: transparent;
        border: none;
        cursor: pointer;
    }
`;

export const RowFirst = styled.div`
display: flex;
flex-wrap: wrap;
// background-color: ${secondarycolor};
background-image: url('${(props:any) => (props.theme === 'Dark' ? `${config.imageS3Host}/static/snk/${props.customStyle['new_whitelable_dark_background']}` : `${config.imageS3Host}/static/snk/${props.customStyle['new_whitelable_light_background']}`)}');

width:100%;


    .blocks {
        /* box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25), inset 4px 4px 4px rgba(0, 0, 0, 0.25); */
        padding: 2px;
        border-radius: 5px;
        width: 25%;
        box-sizing: border-box;
        flex: 0 0 24%; /* explanation below */
        margin: 0px 5px;
        
        // height: 100px;
    }

    .blocks_fullwidth {
        width: 100%;
        /* box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25), inset 4px 4px 4px rgba(0, 0, 0, 0.25); */
        padding: 2px;
        margin: 3px;
        border-radius: 5px;
    }

    .top_up {
        position: relative;
        top: -25px;
    }
`;

const FirstColoumn = styled.div`
    width: 100%;
    display: flex;
    align-items: center;
    flex-direction: column;

    span {
        width: 100%;
        display: flex;
        align-items: center;
        flex-direction: row;
        background-size: cover;
        background-repeat: no-repeat;
        position: relative;  
        margin: 5px 5px 0px 0px;
        position: relative;
        cursor: default;   
    }

    span:hover .overlay {
        -webkit-transform: scale(1);
        -ms-transform: scale(1);
        transform: scale(1);
    }

    .coltenth {
        height: 230px;
       
        align-items: flex-end;

        h4 {
            bottom: 30px;
        }
    }
   

    h4 {
        position: absolute;
        left: 1.5em;
        margin: 0;
        font-size: 30px;
        color: ${secondarycolor};
        
        text-transform: uppercase;
    }
`;

const BackButton= styled.button`
    display: flex;
    font-size: 15px;
    color: #D0B99F;
    -webkit-box-align: center;
    align-items: center;
    -webkit-box-pack: start;
    justify-content: flex-start;
    padding: -0.1em 1.7em;
    background: ${theme('palette.backButtonColor')};
    border-radius: 3px;
    margin-left: 150px;
    position: absolute;
    top: 125px;
    right: 0px;
    cursor:pointer;
    border: 0.25px solid #D0B99F;
    // box-shadow: 0 0 3px #786B57;

img{
    padding-right:5px;
    color:#fff;
    
}

`;
