import React from 'react'

type Props = {}

const ArrowRight = (props: Props) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 25"><path d="m9 18.98 6-6-6-6" stroke="#000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/></svg>
  )
}

export default ArrowRight