import React, { Component } from 'react';
import { Transition, TransitionGroup } from 'react-transition-group';
import { TransitionStatus } from 'react-transition-group/Transition';
import styled, { css } from 'styled-components';
import { switchProp } from 'styled-tools';

import media from '../util/mediaUtil';
import Icon, { IconWrapper } from './common/PNGIcon';
import Intl from './common/Intl';
type RenderProp = (removeNotificationFn: () => void) => React.ReactNode;
type NotificationContent = React.ReactNode | RenderProp;

type NotificationInfo = {
	id: number;
	content: NotificationContent;
	type: NotificationType;
};

type NotificationType = 'info' | 'warning' | 'error' | 'success' | 'custom';
type HorizontalAlignment = 'left' | 'center' | 'right';
type VerticalAlignment = 'top' | 'bottom';
type Alignment = {
	horizontal: HorizontalAlignment;
	vertical: VerticalAlignment;
};

interface INotificationCenterProps {
	alignment: Alignment;
	isMobile: boolean;
}

interface INotificationCenterState {
	notifications: NotificationInfo[];
}

const NOTIFICATION_HEIGHT = 80 /* px */;

const defaultNotify = (content: React.ReactNode, duration?: number) => {
	console.error('[Nofification]: NotificationCenter component is not mounted!');
};

export const Notify: Record<NotificationType, typeof defaultNotify> = {
	info: defaultNotify,
	warning: defaultNotify,
	error: defaultNotify,
	success: defaultNotify,
	custom: defaultNotify,
};

let notificationId = 0;

class NotificationCenter extends Component<INotificationCenterProps, INotificationCenterState> {
	static defaultProps = {
		alignment: {
			horizontal: 'right',
			vertical: 'top',
		},
	};

	constructor(props: INotificationCenterProps) {
		super(props);
		this.state = {
			notifications: [],
		};
	}

	addNotification = (content: NotificationContent, duration: number = 5000, type: NotificationType = 'info') => {
		const { notifications: prevNotifications } = this.state;
		const newNotification: NotificationInfo = {
			id: notificationId,
			content,
			type,
		};
		const notifications = [...prevNotifications, newNotification];
		this.setState(
			{
				notifications,
			},
			() => {
				notificationId++;
				const { id } = newNotification;
				setTimeout(() => {
					this.removeNotification(id);
				}, duration);
			},
		);
	};

	removeNotification = (targetNotificationId: number) => {
		const { notifications: prevNotifications } = this.state;
		const notifications = [...prevNotifications].filter(notification => {
			const { id } = notification;
			return id !== targetNotificationId;
		});
		this.setState({
			notifications,
		});
	};

	componentDidMount() {
		(Object.keys(Notify) as NotificationType[]).forEach(notificationType => {
			Notify[notificationType] = (content: NotificationContent, duration?: number) =>
				this.addNotification(content, duration, notificationType);
		});
	}

	render() {
		const { alignment, isMobile } = this.props;
		const { notifications } = this.state;

		return (
			<TransitionGroup component={null}>
				{notifications.map((notification, position) => {
					const { id, content, type } = notification; 
					return (
						<Transition timeout={{ enter: 100, exit: 300 }} key={id}>
							{transitionStatus => {
								return (
									<NotificationItemContainer
										transitionStatus={transitionStatus}
										position={position}
										amount={notifications.length}
										alignment={alignment}
										isMobile={isMobile}
									>
										<NotificationItemWrapper type={type}>
											{type === 'custom' ? (
												typeof content === 'function' ? (
													content(() => this.removeNotification(id))
												) : (
													console.error(
														'[Notification] When using custom notification, the content prop must be a function!',
													)
												)
											) : typeof content !== 'function' ? (
												<>
													<NotificationIcon>
														<Icon name={`notification-${type}`} />
													</NotificationIcon>
													<ContentWrapper>
															<Intl langKey={content} />
													</ContentWrapper>
													<DismissButton onClick={() => this.removeNotification(id)}>
														<Icon name="x" />
													</DismissButton>
												</>
											) : (
												console.error(
													'[Notification]: info, warning, error and success notification does not accepts a render prop content!',
												)
											)}
										</NotificationItemWrapper>
									</NotificationItemContainer>
								);
							}}
						</Transition>
					);
				})}
			</TransitionGroup>
		);
	}
}

export default NotificationCenter;

// Styled components
const NotificationIcon = styled.div`
	width: ${(NOTIFICATION_HEIGHT / 3) * 2}px;
	border-right: 1px solid rgba(0, 0, 0, 0.1);
	background-color: rgba(0, 0, 0, 0.035);
	display: flex;
	justify-content: center;
	align-items: center;

	${IconWrapper} {
		filter: drop-shadow(0px 1px 0px rgba(0, 0, 0, 0.35));
	}
`;

const ContentWrapper = styled.div`
	padding: 0.5em;
	display: flex;
	align-items: center;
	font-size: 14px;
`;

const DismissButton = styled.button`
	margin-left: auto;
	background-color: rgba(0, 0, 0, 0.035);
	border: 0;
	cursor: pointer;
	border-left: 1px solid rgba(0, 0, 0, 0.1);
	padding: 0 0.5em;

	&:focus {
		outline: 0;
	}
`;

const NotificationItemWrapper = styled.div<{ type: NotificationType }>`
	display: flex;
	border-radius: 4px;
	/* overflow: hidden; */
	width: 100%;
	height: 100%;
	box-shadow: 0 3px 8px rgba(0, 0, 0, 0.16);
	position: relative;
	${media.md`
		height: calc(100% - 4px);
		&::before {
			content: '';
			position: absolute;
			top: 100%;
			right: 10vw;
			margin-right: -8px;
			width: 0;
			height: 0;
			border-style: solid;
			border-width: 8px 7.5px 0 7.5px;
		}
	`} ${switchProp('type', {
		info: css`
			background-color: #fff;
			${media.md`
				&::before {
					border-color: #fff transparent transparent transparent;
				}
			`};
		`,
		error: css`
			background-color: #f14452;
			${media.md`
				&::before {
				border-color: #f14452 transparent transparent transparent;
				}
			`};
		`,
		warning: css`
			background-color: #ff8740;
			${media.md`
				&::before {
					border-color: #ff8740 transparent transparent transparent;
				}
			`};
		`,
		success: css`
			background-color: #4de684;
			${media.md`
				&::before {
					border-color: #4de684 transparent transparent transparent;
				}
			`};
		`,
		custom: css`
			display: block;
			background-color: #fff;
			${media.md`
				&::before {
					border-color: #fff transparent transparent transparent;
				}
			`};
		`,
	})};
`;

// 負責淡入淡出效果
const TransitionWrapper = styled.div<{ transitionStatus: TransitionStatus }>`
	transition: opacity 0.3s ease;
	${switchProp('transitionStatus', {
		entering: css`
			display: block;
			opacity: 0;
		`,
		entered: css`
			display: block;
			opacity: 1;
		`,
		exiting: css`
			display: block;
			opacity: 0;
		`,
		exited: css`
			display: none;
			opacity: 0;
		`,
	})};
`;

// 負責定位
const NotificationItemContainer = styled(TransitionWrapper)<{
	position: number;
	amount: number;
	alignment: Alignment;
	isMobile: boolean;
}>`
	width: 300px;
	height: ${NOTIFICATION_HEIGHT}px;
	padding: 0.25em;
	position: fixed;
	z-index: 99999999;
	transition: top 0.3s ease, bottom 0.3s ease, opacity 0.3s ease;

	/* Horizontal alignment */
	${props => {
		const {
			alignment: { horizontal },
		} = props;
		switch (horizontal) {
			case 'left':
				return css`
					left: 0;
				`;
			case 'center':
				return css`
					left: 50%;
					transform: translateX(-50%);
				`;
			case 'right':
			default:
				return css`
					right: 0;
				`;
		}
	}}

	/* Vertical alignment */
	${props => {
		const {
			alignment: { vertical },
			position,
			amount,
			isMobile,
		} = props;
		if (isMobile) {
			return css`
                ${vertical}: ${NOTIFICATION_HEIGHT * (amount - position - 1) + 48}px;
            `;
		} else {
			return css`
                ${vertical}: ${NOTIFICATION_HEIGHT * (amount - position - 1) || 0}px;
            `;
		}
	}}

	
`;
