import React from 'react';
import styled, { css } from 'styled-components';


// Components
import Icon, { IconWrapper } from '../common/PNGIcon';
import { SectionHeading, SectionWrapper } from '../eu-content/order/Order';
import Switch from '../eu-content/Switch';
import Intl from '../common/Intl';
import { IOrderListContext, withOrderListContext } from '../../store/OrderListContext';

// util
import cookieUtil from '../../util/cookieUtil';
import { getGTMId } from '../../util/gtmUtil';
import { ECookieName } from '../../util/utilModel';
import { isMemberType } from '../../util/styleUtil';
import { ifNotProp, withProp } from 'styled-tools';
import { diffColor } from '../../util/colorUtil';
const { theme, ifProp } = require('styled-tools');

interface IOneClickState {
	isLoading: boolean;
    errorMessage: string;
}

class BookmakerOrderClick extends React.PureComponent<IOrderListContext, IOneClickState> {
	state = {
		isEditing: false,
		isLoading: false,
        errorMessage: '',
	};


	render(): JSX.Element {
        const {
            isAcceptAllPrice,
            toggleAllBookmakerOrder
        } = this.props.orderListContext;
		const { errorMessage } = this.state;

		return (
			<SectionWrapperBookmaker>
				<SectionHeadingSwitch >
					<Switch
						currentActiveState={isAcceptAllPrice}
						handleClick={toggleAllBookmakerOrder}
						id={getGTMId(`is_bookmaker_odds_enabled`)}
					/>
					<span >
						<Intl langKey="ACCEPT_BOOKMAKER_ODDS" />
                        {/* Accept Bookmaker Odds */}
					</span>
				</SectionHeadingSwitch>
			</SectionWrapperBookmaker>
		);
	}
}


export default withOrderListContext(BookmakerOrderClick, [
    'toggleAllBookmakerOrder',
    'isAcceptAllPrice'
]);

export const SectionHeadingSwitch = styled.h2`
	display: flex;
	justify-content: space-between;
	align-items: center;
    font-size: 11px;
    text-transform: capitalize;
	margin-top: 0;
	margin-bottom: 0.5em;
	padding: 5px 0px;
	// color: #35495E;

	${isMemberType(
		'CLIENT',
		css`
			color: ${theme('palette.secondary-background-color-new')};
		`,
		css`
			// color: ${withProp('theme.palette.heading-color', diffColor(0.023))};
			color: #35495E;
		`,
	)};
`;

// Styled components

const SectionWrapperBookmaker = styled.div `
	background: transparent;
	width: auto;
    position: absolute;
    right: 0;
    top: 0;
`;

const ConfirmButton = styled.button`
	cursor: pointer;
	display: flex;
	justify-content: center;
	align-items: center;
	background-color: transparent;
	font-size: 13px;
	width: 50%;
	border: 0;
	padding: 0.5em 0.25em;

	&:focus {
		outline: 0;
	}

	&:hover {
		color: ${theme('palette.primary-background')};
	}

	&:not(:last-of-type) {
		border-right: 1px solid rgba(128, 128, 128, 0.25);
	}
`;

const ConfirmButtonWrapper = styled.div`
	display: flex;
	border-top: 1px solid rgba(128, 128, 128, 0.25);
`;

const ConfirmMessageWrapper = styled.div`
	font-size: 12px;
	display: flex;
	align-items: flex-start;
	padding: 1em 0.5em;

	${IconWrapper} {
		margin-top: 0.1em;
		margin-right: 0.5em;
	}
`;

const ConfirmWrapper: any = styled.div`
	display: ${ifProp('isActive', 'block', 'none')};
	background-color: ${theme('palette.panel-background')};
	border-radius: 2px;
	border: 1px solid rgba(128, 128, 128, 0.25);
`;

const ErrorMessage = styled.div`
	display: flex;
	align-items: center;
	font-size: 12px;
	color: ${theme('palette.error-text')};
	margin-bottom: 0.5em;

	${IconWrapper} {
		margin-right: 0.5em;
	}
`;

const SaveButton = styled.button`
	cursor: pointer;
	display: flex;
	align-items: center;
	font-style: italic;
	font-size: 10px;
	background-color: transparent;
	border: 0;
	flex-shrink: 0;
	color: ${theme('palette.body-foreground')};

	&:focus {
		outline: 0;
	}

	${IconWrapper} {
		font-size: 14px;
		margin-right: 0.5em;
	}
`;

const InputStack: any = styled.input.attrs(props => ({
	type: 'text',
}))`
	width: 33%; /* nullify default width from user agent */
	display: flex;
	justify-content: center;
	align-items: center;
	cursor: pointer;
	flex-basis: 100%;
	flex-grow: 1;
	margin-right: 0.5em;
	border-radius: 99em;
	padding: 0.5em;
	border: 0;
	box-shadow: inset 0 0 3px rgba(0, 0, 0, 0.25);
	caret-color: ${theme('palette.primary-background')};
	color: ${theme('palette.text-dark')};
	text-align: center;
	min-width: 0;

	${ifProp(
		'isInvalid',
		css`
			border: 1px solid ${theme('palette.error-text')};
		`,
	)};

	&:focus {
		outline: 0;
	}
`;

// Wrapping feather icon
const EditIconWrapper = styled.div`
	display: flex;
	justify-content: center;
	align-items: center;
	width: 2.25em;
	height: 2.25em;
	border-radius: 50%;
	background-color: ${theme('palette.primary-background')};
`;

const EditButton = styled.button`
	cursor: pointer;
	display: flex;
	align-items: center;
	font-style: italic;
	font-size: 10px;
	background-color: transparent;
	border: 0;
	flex-shrink: 0;
	color: ${theme('palette.body-foreground')};

	&:focus {
		outline: 0;
	}

	${EditIconWrapper} {
		margin-right: 0.5em;
	}
`;

const StackButton: any = styled.button`
	display: flex;
	justify-content: center;
	align-items: center;
	cursor: pointer;
	flex-basis: 100%;
	flex-grow: 1;
	margin-right: 0.3em;
	border-radius: 99em;
	padding: 0.5em;
	color: ${theme('palette.panel-foreground')};
	font-size: 13px;
	${ifProp(
		'isSelected',
		css`
			border: 0;
			background-image: ${theme('palette.primary-gradient')};
			color: ${theme('palette.primary-foreground')};
		`,
		css`
			border: 1px solid ${theme('palette.primary-background')};
			background-color: transparent;
		`,
	)};

	&:focus {
		outline: none;
	}
`;

// Wrapping stack buttons
const StackButtonWrapper = styled.div`
	display: flex;
	align-items: center;
	flex-grow: 1;
`;

const StackWrapper: any = styled.div`
	display: ${ifProp('isEditing', 'none', 'flex')};
	align-items: center;
	justify-content: space-between;
`;
