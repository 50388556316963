import React from 'react';

// Dependent Components
import { Amount, Income } from '../../../common/NumberDisplay';
import Icon from '../../../common/PNGIcon';
import NumberInput from '../../NumberInput';
import { EOddsType } from '../../NumberInput';
import CountDown from '../CountDown';
import {
	CancelButton,
	ExternalWrapper,
	FieldLabel,
	HeaderWrapper,
	InputSectionWrapper,
	InputWrapper,
	MaxInfo,
	MaxSection,
	MessageSection,
	PLWrapper,
	StakeButton,
	StakeButtonContainer,
	StakeButtonWrapper,
	StakeInput,
	SubmitButton,
	TitleWrapper,
	TypeLabelWrapper,
} from './mobileSlipStyles';
import { ESlipState, ISlipProps, OrderSlipContainer } from './OrderSlipContainer';
import { MessageWrapper, SlipMask } from './slipStyles';
import Intl from '../../../common/Intl';

// Context
import { IMainLayoutProviderStore, withMainLayoutContext } from '../../../../store/MainLayoutContext';
import { IOrderListProviderStore, IOrderTicket, withOrderListContext } from '../../../../store/OrderListContext';

// Util
import { calculateProfitLoss } from '../../../../util/formulae';
import oddsUtil from '../../../../util/oddsUtil';
import util from '../../../../util/util';
import { EMarketType } from '../../../../util/utilModel';

// Type
import { IMarketSource } from '../../../../graphql/schema';
import { getGTMId } from '../../../../util/gtmUtil';

interface IMobileOrderSlipProps extends ISlipProps {
	selfMarketId?: string;
	selfSelectionId?: string;
	orderListContext: IOrderListProviderStore;
	mainLayoutContext: IMainLayoutProviderStore;
}

const MobileOrderSlip: React.SFC<IMobileOrderSlipProps> = props => {
	const {
		selfMarketId,
		selfSelectionId,
		orderListContext: {
			betArray,
			quickStakes,
			removeBetEvent,
			placeOrder,
			isOrderLoading,
			placingOrderKeyName,
			handlePriceSizeChange,
			betResults,
			exchangeRate,
			placeOrderAction,
			isUserLoggedIn,
			saveQuickStakes
		},
		mainLayoutContext: { activateBetting },
	} = props;

	const [firstBet = {} as IOrderTicket] = betArray;

	const {
		type,
		price,
		// size,
		fancyInfo,
		selectionInfo,
		marketInfo = {},
		eventInfo = {},
		keyName,
		currentPrice,
		currentSize,
	} = firstBet;

	const { marketId } = marketInfo;
	const selectionId = fancyInfo ? fancyInfo.selectionId : selectionInfo ? selectionInfo.selectionId : '';
	const selectionName = fancyInfo ? fancyInfo.selectionName : selectionInfo ? selectionInfo.name : '';

	// event外頁是用marketId、match內頁是用selectionId做判斷是否顯示
	return (selfMarketId && selfMarketId === marketId) || (selfSelectionId && selfSelectionId === selectionId) ? (
		<OrderSlipContainer
			type={type}
			quickStakes={quickStakes}
			handleTrashClick={() => removeBetEvent([keyName])}
			eventInfo={eventInfo}
			marketInfo={marketInfo}
			selectionInfo={selectionInfo}
			price={price}
			currentPrice={currentPrice}
			currentSize={currentSize}
			placeOrder={placeOrder}
			isOrderLoading={isOrderLoading}
			handlePriceSizeChange={handlePriceSizeChange(0)}
			betResult={betResults[keyName]}
			exchangeRate={exchangeRate}
			marketSource={marketInfo.marketSource || undefined}
			activateBetting={activateBetting}
			isMobileWidth={true}
			placingOrderKeyName={placingOrderKeyName}
			keyName={keyName}
			fancyInfo={fancyInfo}
			placeOrderAction={placeOrderAction}
			isUserLoggedIn={isUserLoggedIn}
			saveQuickStakes={saveQuickStakes}
		>
			{slipStore => {
				const {
					isFancyType,
					isLoading,
					slipRef,
					handleOddsChange,
					handleStakeChange,
					handleOnClickInput,
					handleQuickStake,
					handleBetting,
					handleTrashClick,
					message,
					slipState,
					marketInfo: { betDelay, oddType },
					orderLimit
				} = slipStore;
				return (
					<ExternalWrapper ref={slipRef}>
						{isOrderLoading &&
							(placingOrderKeyName === keyName ? (
								// 正在下的單
								<CountDown
									startFrom={betDelay}
									isFancy={marketInfo.marketSource === IMarketSource.Snk}
								/>
							) : (
								// 其他佇列的單 disable
								<SlipMask />
							))}
						{!isOrderLoading &&
							message && (
								<MessageSection>
									<MessageWrapper type={slipState}>
										<Icon
											name={
												slipState === ESlipState.Error
													? 'circle-x-color'
													: slipState === ESlipState.Expired
														? 'alert'
														: ''
											}
										/>
										<Intl langKey={message}/>
									</MessageWrapper>
								</MessageSection>
							)}
						<HeaderWrapper>
							<TitleWrapper>
								<TypeLabelWrapper type={type}>{type}</TypeLabelWrapper>
								{selectionName}
							</TitleWrapper>
							<PLWrapper>
								<Intl
									langKey={
										type === 'BACK'
											? 'GLOBAL@PROFIT'
											: type === 'LAY'
												? 'GLOBAL@LOSS'
												: 'GLOBAL@PLACEHOLDER'
									}
								>
									{(msg: string) => {
										return <FieldLabel>{msg}</FieldLabel>;
									}}
								</Intl>
								: <Income>{calculateProfitLoss(currentPrice || 0, currentSize || 0, type)}</Income>
							</PLWrapper>
						</HeaderWrapper>
						{/** Code Changed - Ambu isFancytype && ( added for FancyMarketInfoSection) */}
						{/* {isFancyType && ( */}
							<MaxSection>
								<MaxInfo>
									Max Bet:
									<Amount fractionDigits={0}>
										{util.numMultiply(marketInfo.maxPerOrderCredit || 0, exchangeRate)}
									</Amount>
								</MaxInfo>
								<MaxInfo>
									Max Mkt:
									<Amount fractionDigits={0}>
										{util.numMultiply(marketInfo.maxPerMarketExposure || 0, exchangeRate)}
									</Amount>
								</MaxInfo>
							</MaxSection>
						{/* )} */}
						<InputSectionWrapper>
							<InputWrapper>
								{marketInfo.marketType === EMarketType.FANCY ? (
									<StakeInput
										value={
											type === 'BACK'
												? fancyInfo && fancyInfo.backValue
												: fancyInfo && fancyInfo.layValue
										}
										readOnly
									/>
								) : marketInfo.marketType === EMarketType.Multi_Selection_Fancy ? (
									<StakeInput
										value={
											type === 'BACK'
												? oddType === EOddsType.AM
													? fancyInfo && oddsUtil.EUtoAM(fancyInfo.backPrice)
													: fancyInfo && fancyInfo.backPrice
												: oddType === EOddsType.AM
													? fancyInfo && oddsUtil.EUtoAM(fancyInfo.layPrice)
													: fancyInfo && fancyInfo.layPrice
										}
										readOnly
									/>
								) : (
									<NumberInput
										initialNumber={currentPrice}
										onInputChange={handleOddsChange}
										mobileMode={true}
									/>
								)}
							</InputWrapper>
							<InputWrapper>
								<StakeInput
									placeholder={`${'Min:' + (orderLimit.minOrder * exchangeRate).toFixed(2)}`}
									value={currentSize}
									onChange={handleStakeChange}
									type="number"
									pattern="\d*"
									onClick={handleOnClickInput}
								/>
							</InputWrapper>
						</InputSectionWrapper>
						<InputSectionWrapper>
							<StakeButtonContainer>
								{quickStakes.map((stake, i) => {
									return (
										<StakeButtonWrapper key={i} onClick={handleQuickStake(stake)}>
											<StakeButton>+{stake}</StakeButton>
										</StakeButtonWrapper>
									);
								})}
								<StakeButtonWrapper>
									<StakeButton onClick={() => handleStakeChange({ target: { value: '0' } })}>
										<Intl langKey="ORDERSLIP@CLEAR" />
									</StakeButton>
								</StakeButtonWrapper>
							</StakeButtonContainer>
						</InputSectionWrapper>
						<InputSectionWrapper>
							<InputWrapper>
								<CancelButton onClick={handleTrashClick} id={getGTMId(`cancel_order_${keyName}`)}>
									<Intl langKey="ORDERSLIP@CANCEL_ORDER" />
								</CancelButton>
							</InputWrapper>
							<InputWrapper>
								<SubmitButton
									onClick={handleBetting}
									disabled={!currentSize || currentSize <= 0 || isLoading}
									id={getGTMId(`place_order_${keyName}`)}
								>
									<Intl langKey="ORDERSLIP@PLACE" />
								</SubmitButton>
							</InputWrapper>
						</InputSectionWrapper>
					</ExternalWrapper>
				);
			}}
		</OrderSlipContainer>
	) : null;
};

export default withOrderListContext(withMainLayoutContext(MobileOrderSlip, ['activateBetting']), [
	'betArray',
	'quickStakes',
	'removeBetEvent',
	'placeOrder',
	'isOrderLoading',
	'placingOrderKeyName',
	'handlePriceSizeChange',
	'betResults',
	'exchangeRate',
	'placeOrderAction',
	'isUserLoggedIn'
]);
