import React from 'react'

type Props = {}

const PromoRed = (props: Props) => {
  return (
    <svg width="44" height="44" viewBox="0 0 44 44" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M19.0883 7.36055C16.8003 13.871 12.7428 17.767 11 18.9012C11.4467 19.1894 11.8646 19.8575 12.0176 20.1555C12.4085 21.2254 13.29 23.6459 13.6898 24.7683C14.0896 25.8908 14.2 26.9137 14.2052 27.2849C19.0582 27.0087 25.1705 29.6028 27.62 30.9345L19.0883 7.36055Z" fill="white"/>
    <path d="M30.11 19.631L28.2437 14.5394C29.6582 14.5394 30.4811 15.6854 30.7158 16.2584C31.2921 17.8694 30.5521 19.178 30.11 19.631Z" fill="white"/>
    <path d="M19.6776 5.24634L29.2989 31.4886C30.642 30.1914 31.8224 29.5067 32.2447 29.3265L22.8667 3.73283C22.5874 3.37257 21.7694 2.74395 20.7316 3.1115C19.6938 3.47905 19.5965 4.68788 19.6776 5.24634Z" fill="white"/>
    <rect x="29.917" y="12.0117" width="11.2999" height="1.46857" rx="0.734283" transform="rotate(-76.2301 29.917 12.0117)" fill="white"/>
    <rect x="31.876" y="13.4141" width="7.68492" height="1.46857" rx="0.734283" transform="rotate(-30.1316 31.876 13.4141)" fill="white"/>
    <rect x="32.8589" y="15.1953" width="7.68492" height="1.46857" rx="0.734283" transform="rotate(-3.81893 32.8589 15.1953)" fill="white"/>
    <rect x="33.0894" y="18.0039" width="11.284" height="1.46857" rx="0.734283" transform="rotate(42.2255 33.0894 18.0039)" fill="white"/>
    <path fill-rule="evenodd" clip-rule="evenodd" d="M3.18581 20.8339C2.08308 21.2259 1.50688 22.4375 1.89883 23.5403L3.78781 28.8549C4.17976 29.9576 5.39142 30.5338 6.49415 30.1419L11.3849 28.4035C12.4876 28.0116 13.0638 26.7999 12.6718 25.6972L10.7829 20.3826C10.3909 19.2799 9.17925 18.7037 8.07653 19.0956L3.18581 20.8339ZM3.73538 22.8555C3.37154 22.9867 3.18298 23.3881 3.31422 23.7519C3.44546 24.1158 3.8468 24.3043 4.21064 24.1731L9.25683 22.3529C9.62067 22.2216 9.80923 21.8203 9.67799 21.4565C9.54675 21.0926 9.14541 20.9041 8.78157 21.0353L3.73538 22.8555ZM4.11512 25.7542C3.98388 25.3903 4.17244 24.989 4.53627 24.8577L9.58247 23.0375C9.9463 22.9063 10.3476 23.0949 10.4789 23.4587C10.6101 23.8225 10.4216 24.2239 10.0577 24.3551L5.01153 26.1753C4.64769 26.3066 4.24635 26.118 4.11512 25.7542ZM5.29289 26.8455C4.92905 26.9768 4.74049 27.3781 4.87173 27.742C5.00297 28.1058 5.40431 28.2943 5.76815 28.1631L10.8143 26.3429C11.1782 26.2117 11.3667 25.8103 11.2355 25.4465C11.1043 25.0827 10.7029 24.8941 10.3391 25.0253L5.29289 26.8455Z" fill="white"/>
    <path d="M27.6291 34.1239C27.7046 33.3567 28.4997 32.1259 28.8878 31.6064H20.7073C20.2543 31.6064 20.1412 32.0681 20.1413 32.299C20.1466 32.9438 20.1539 34.3531 20.1413 34.8312C20.1288 35.3093 20.6188 35.4394 20.8654 35.4446H21.8252C22.102 34.5764 23.1256 34.2024 23.6028 34.1239H27.6291Z" fill="white"/>
    <path d="M27.4074 38.0083C27.2301 37.5805 27.2601 36.1209 27.2973 35.4446H23.7585C23.2804 35.4446 23.0455 35.9479 22.9878 36.1995V38.0083H27.4074Z" fill="white"/>
    <path d="M30.9148 43.2614C29.4804 42.4184 28.2725 40.3308 27.8478 39.3923H20.8654C20.2496 39.3923 20.1261 39.8432 20.1413 40.0687V42.5851C20.1413 43.2457 20.8654 43.3401 20.8654 43.2614C20.8654 43.1985 27.565 43.2352 30.9148 43.2614Z" fill="white"/>
    <path d="M8.23127 32.8401C10.0887 33.5183 11.1492 31.0835 11.4472 29.7813C12.6627 29.4765 13.4267 28.4342 13.6567 27.9512L15.0634 29.4915C16.7067 31.7779 16.2989 34.1209 15.8895 35.0066C15.5472 35.7732 14.7106 37.4135 14.1033 37.8423C13.4959 38.271 12.9869 39.3457 12.8082 39.8295L12.3393 42.1963H5.84206L7.4271 38.1995V36.7928C7.38245 36.5919 7.14132 35.9533 6.53399 35.0066C5.92666 34.0598 5.81965 32.3793 5.84206 31.6573L6.91431 31.434C7.50377 32.5236 8.03789 32.8254 8.23127 32.8401Z" fill="white"/>
    <path d="M9.85873 30.3848L8.22119 30.996L8.46868 31.4509C9.01963 31.8771 9.30464 31.5418 9.37827 31.3209L9.85873 30.3848Z" fill="white"/>
    <path d="M15.3945 38.3252L16.6035 36.5439C17.1895 37.1801 17.4079 38.2338 16.6035 39.4552C15.96 40.4324 14.7063 40.5554 14.16 40.4948C14.0456 39.8087 14.9353 38.7625 15.3945 38.3252Z" fill="white"/>
    <rect x="5.53174" y="4.43945" width="7.17236" height="7.04421" fill="white"/>
    <path d="M15.8716 7.7007L14.6893 6.41008L15.1959 4.73449L13.5693 4.08697L13.2987 2.35727L11.5522 2.4769L10.5578 1.03613L9.0371 1.90443L7.51638 1.03613L6.52199 2.4769L4.77557 2.35724L4.50494 4.08692L2.87829 4.73443L3.38492 6.41002L2.20264 7.7007L3.38492 8.99132L2.87829 10.6669L4.50494 11.3144L4.77557 13.0441L6.52199 12.9245L7.51638 14.3652L9.0371 13.497L10.5578 14.3652L11.5522 12.9244L13.2986 13.0441L13.5692 11.3144L15.1959 10.6669L14.6893 8.99132L15.8716 7.7007ZM7.59781 5.55363C8.09015 5.55363 8.48925 5.95276 8.48925 6.44508C8.48925 6.93743 8.09015 7.33652 7.59781 7.33652C7.10548 7.33652 6.70636 6.9374 6.70636 6.44508C6.70634 5.95276 7.10543 5.55363 7.59781 5.55363ZM7.60104 10.1894L6.90736 9.62951C8.2999 7.90436 9.08063 6.93714 10.4732 5.21199L11.1668 5.77191L9.38394 7.98067L7.60104 10.1894ZM10.4764 10.1145C9.98405 10.1145 9.58495 9.71542 9.58495 9.2231C9.58495 8.73075 9.98405 8.33166 10.4764 8.33166C10.9687 8.33166 11.3678 8.73075 11.3678 9.2231C11.3679 9.71539 10.9687 10.1145 10.4764 10.1145Z" fill="#B21318"/>
    <circle cx="35.8348" cy="36.4286" r="6.83483" fill="#B21318"/>
    <path d="M38.3978 34.9392L38.1191 36.0004H33L33.2749 34.9392H38.3978ZM35.7371 40.818L33.126 37.7106L33.1298 36.7563H34.4201C34.7076 36.7563 34.9481 36.7028 35.1415 36.596C35.335 36.4891 35.48 36.344 35.5767 36.1608C35.6734 35.9775 35.7231 35.7689 35.7256 35.5347C35.7231 35.1835 35.62 34.8972 35.4164 34.6758C35.2153 34.4519 34.8832 34.3399 34.4201 34.3399H33L33.313 33H34.4201C35.069 33 35.6098 33.098 36.0424 33.2939C36.4776 33.4874 36.8034 33.7635 37.0197 34.1223C37.2385 34.4811 37.3493 34.9087 37.3518 35.4049C37.3493 35.8452 37.2754 36.2295 37.1304 36.5578C36.9879 36.8861 36.7627 37.1597 36.4547 37.3785C36.1493 37.5974 35.751 37.7603 35.2599 37.8671L35.1988 37.89L37.535 40.7493V40.818H35.7371ZM38.4054 33L38.1191 34.0765H33.9047L34.191 33H38.4054Z" fill="white"/>
    </svg>
    
  )
}

export const PromoOrange = (props: Props) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="55" height="55" viewBox="0 0 55 55" fill="none">
      <path d="M23.7432 8.4715C20.8527 16.6961 15.727 21.6178 13.5254 23.0506C14.0897 23.4146 14.6176 24.2586 14.811 24.6352C15.3047 25.9868 16.4183 29.0444 16.9233 30.4624C17.4284 31.8804 17.5679 33.1726 17.5745 33.6415C23.7052 33.2926 31.4267 36.5698 34.5211 38.252L23.7432 8.4715Z" fill="white" />
      <path d="M37.6667 23.9725L35.309 17.5404C37.0959 17.5404 38.1355 18.9881 38.432 19.712C39.16 21.7471 38.2252 23.4003 37.6667 23.9725Z" fill="white" />
      <path d="M24.4877 5.80065L36.642 38.952C38.3388 37.3133 39.83 36.4483 40.3635 36.2207L28.5164 3.88866C28.1636 3.43355 27.1302 2.63942 25.8192 3.10375C24.5081 3.56807 24.3852 5.09515 24.4877 5.80065Z" fill="white" />
      <rect x="37.4219" y="14.3477" width="14.275" height="1.85521" rx="0.927606" transform="rotate(-76.2301 37.4219 14.3477)" fill="white" />
      <rect x="39.8965" y="16.1191" width="9.70821" height="1.85521" rx="0.927606" transform="rotate(-30.1316 39.8965 16.1191)" fill="white" />
      <rect x="41.1387" y="18.3691" width="9.70821" height="1.85521" rx="0.927606" transform="rotate(-3.81893 41.1387 18.3691)" fill="white" />
      <rect x="41.4297" y="21.918" width="14.2549" height="1.85521" rx="0.927606" transform="rotate(42.2255 41.4297 21.918)" fill="white" />
      <path fill-rule="evenodd" clip-rule="evenodd" d="M3.65419 25.4919C2.26113 25.9871 1.53323 27.5177 2.02837 28.9108L4.41468 35.6246C4.90982 37.0177 6.4405 37.7456 7.83355 37.2505L14.0119 35.0545C15.4049 34.5593 16.1329 33.0287 15.6377 31.6356L13.2514 24.9218C12.7563 23.5287 11.2256 22.8008 9.83253 23.2959L3.65419 25.4919ZM4.34692 28.045C3.8873 28.2108 3.64909 28.7178 3.81488 29.1774C3.98068 29.6371 4.48768 29.8753 4.94731 29.7095L11.3221 27.41C11.7817 27.2443 12.0199 26.7373 11.8541 26.2776C11.6883 25.818 11.1813 25.5798 10.7217 25.7456L4.34692 28.045ZM4.82657 31.7065C4.66077 31.2469 4.89898 30.7399 5.35861 30.5741L11.7334 28.2747C12.193 28.1089 12.7 28.3471 12.8658 28.8067C13.0316 29.2663 12.7934 29.7733 12.3338 29.9391L5.95899 32.2386C5.49936 32.4044 4.99236 32.1662 4.82657 31.7065ZM6.31369 33.0853C5.85406 33.2511 5.61585 33.7581 5.78164 34.2178C5.94744 34.6774 6.45444 34.9156 6.91407 34.7498L13.2888 32.4504C13.7485 32.2846 13.9867 31.7776 13.8209 31.3179C13.6551 30.8583 13.1481 30.6201 12.6884 30.7859L6.31369 33.0853Z" fill="white" />
      <path d="M34.533 42.2818C34.6283 41.3126 35.6328 39.7578 36.1231 39.1016H25.7887C25.2165 39.1016 25.0737 39.6848 25.0738 39.9764C25.0804 40.7911 25.0897 42.5713 25.0738 43.1753C25.0579 43.7794 25.677 43.9436 25.9885 43.9502H27.201C27.5507 42.8535 28.8438 42.3809 29.4466 42.2818H34.533Z" fill="white" />
      <path d="M34.2529 47.1889C34.0288 46.6484 34.0668 44.8046 34.1138 43.9502H29.6433C29.0393 43.9502 28.7426 44.586 28.6697 44.9039V47.1889H34.2529Z" fill="white" />
      <path d="M38.6837 53.8251C36.8716 52.7601 35.3457 50.1229 34.8092 48.9373H25.9885C25.2106 48.9373 25.0545 49.5069 25.0738 49.7917V52.9707C25.0738 53.8052 25.9885 53.9245 25.9885 53.8251C25.9885 53.7456 34.452 53.792 38.6837 53.8251Z" fill="white" />
      <path d="M10.0275 40.6594C12.3739 41.5163 13.7136 38.4404 14.0902 36.7953C15.6257 36.4103 16.5908 35.0936 16.8814 34.4834L18.6584 36.4292C20.7344 39.3176 20.2192 42.2775 19.7021 43.3963C19.2696 44.3648 18.2127 46.437 17.4455 46.9786C16.6783 47.5202 16.0352 48.8779 15.8095 49.489L15.2172 52.479H7.00928L9.01163 47.4299V45.6529C8.95522 45.399 8.6506 44.5923 7.88337 43.3963C7.11614 42.2004 6.98096 40.0773 7.00928 39.1653L8.36382 38.8832C9.10849 40.2597 9.78323 40.6409 10.0275 40.6594Z" fill="white" />
      <path d="M12.0823 37.5586L10.0137 38.3307L10.3263 38.9054C11.0223 39.4438 11.3824 39.0203 11.4754 38.7412L12.0823 37.5586Z" fill="white" />
      <path d="M19.0761 47.5891L20.6035 45.3389C21.3437 46.1425 21.6196 47.4736 20.6035 49.0167C19.7905 50.2511 18.2068 50.4065 17.5166 50.3299C17.3721 49.4632 18.4961 48.1416 19.0761 47.5891Z" fill="white" />
      <rect x="6.61523" y="4.78223" width="9.06071" height="8.89882" fill="white" />
      <path d="M19.6798 8.90164L18.1863 7.27123L18.8263 5.15448L16.7713 4.33649L16.4295 2.15139L14.2233 2.30251L12.9671 0.482422L11.046 1.57933L9.12486 0.482422L7.86867 2.30251L5.66245 2.15135L5.32057 4.33642L3.26565 5.15441L3.90566 7.27116L2.41211 8.90164L3.90566 10.5321L3.26565 12.6488L5.32057 13.4668L5.66245 15.6519L7.86867 15.5007L9.12486 17.3208L11.046 16.224L12.9671 17.3208L14.2233 15.5007L16.4295 15.6519L16.7713 13.4668L18.8262 12.6488L18.1862 10.5321L19.6798 8.90164ZM9.22772 6.1893C9.8497 6.1893 10.3539 6.6935 10.3539 7.31544C10.3539 7.93741 9.8497 8.44158 9.22772 8.44158C8.60578 8.44158 8.10158 7.93738 8.10158 7.31544C8.10155 6.6935 8.60572 6.1893 9.22772 6.1893ZM9.23181 12.0456L8.3555 11.3383C10.1147 9.15893 11.101 7.93705 12.8601 5.7577L13.7364 6.46504L11.4841 9.25532L9.23181 12.0456ZM12.8642 11.951C12.2422 11.951 11.7381 11.4468 11.7381 10.8249C11.7381 10.2029 12.2422 9.69872 12.8642 9.69872C13.4861 9.69872 13.9903 10.2029 13.9903 10.8249C13.9904 11.4468 13.4862 11.951 12.8642 11.951Z" fill="url(#paint0_linear_2022_17263)" />
      <circle cx="44.8999" cy="45.1939" r="8.63431" fill="url(#paint1_linear_2022_17263)" />
      <path d="M48.1372 43.3121L47.7852 44.6527H41.3184L41.6656 43.3121H48.1372ZM44.776 50.7386L41.4775 46.8132L41.4823 45.6076H43.1123C43.4756 45.6076 43.7794 45.54 44.0237 45.405C44.2681 45.27 44.4513 45.0867 44.5735 44.8553C44.6957 44.6238 44.7583 44.3602 44.7616 44.0644C44.7583 43.6207 44.6281 43.259 44.3709 42.9793C44.117 42.6964 43.6974 42.555 43.1123 42.555H41.3184L41.7138 40.8623H43.1123C43.9321 40.8623 44.6153 40.9861 45.1618 41.2336C45.7116 41.478 46.1231 41.8268 46.3964 42.2801C46.6728 42.7334 46.8127 43.2735 46.8159 43.9004C46.8127 44.4566 46.7195 44.9421 46.5362 45.3568C46.3562 45.7715 46.0716 46.1171 45.6826 46.3936C45.2968 46.6701 44.7937 46.8758 44.1732 47.0109L44.0961 47.0398L47.0474 50.6518V50.7386H44.776ZM48.1469 40.8623L47.7852 42.2222H42.4613L42.823 40.8623H48.1469Z" fill="white" />
      <defs>
        <linearGradient id="paint0_linear_2022_17263" x1="11.046" y1="0.482422" x2="11.046" y2="17.3208" gradientUnits="userSpaceOnUse">
          <stop stop-color="#FF9A60" />
          <stop offset="1" stop-color="#D45007" />
        </linearGradient>
        <linearGradient id="paint1_linear_2022_17263" x1="44.8999" y1="36.5596" x2="44.8999" y2="53.8282" gradientUnits="userSpaceOnUse">
          <stop stop-color="#FE995F" />
          <stop offset="1" stop-color="#CD520F" />
        </linearGradient>
      </defs>
    </svg>
  )
}

export default PromoRed