import React from 'react'
// import { withAppContext } from 'src/store/initAppContext';
// import styled, { withTheme } from 'styled-components';
// import { theme } from 'styled-tools';
import { Accordion, AccordionWrapper } from '../seo-content/seo_content';
import { Helmet } from "react-helmet";
import { withAppContext } from 'src/store/initAppContext';
import SportsExchangeSEO31 from './SportsExchangeSEO31';
import SportsExchangeSEODefault from './SportsExchangeSEODefault';
import SportsExchangeSeoFun88 from './SportsExchangeSeoFun88';

const structuredData = (domain) => {
    const data = {
        "@context": "https://schema.org",
        "@type": "BreadcrumbList",
        "itemListElement":
            [
                {
                    "@type": "ListItem",
                    "item": `${domain}`,
                    "name": "Homepage",
                    "position": 1
                },
                {
                    "@type": "ListItem",
                    "item": `${domain}/betting-exchange/`,
                    "name": "Bet on Sports",
                    "position": 2
                }],
        "name": "Breadcrumbs"
    }

    return JSON.stringify(data)
}

const SportsExchangeSEO = (props) => {
    const { app: { brandId } } = props;

    let domainURL = 'https://www.yolo247.site';

    if (brandId === 32) {
        domainURL = 'https://www.yolo247.club';
    }
    else if (brandId === 33) {
        domainURL = 'https://www.iw247.com';
    }
    else if (brandId === 34) {
        domainURL = 'https://www.betdaily.club';
    }
    else if (brandId === 39) {
        domainURL = 'https://www.fomo7.com';
    }   
    else if (brandId === 40) {
        domainURL = 'https://www.fomo7.xyz';
    }  
    else if (brandId === 46) {
        domainURL = 'https://www.fun88.site';
    }
    else if (brandId === 47) {
        domainURL = 'https://www.gofun88.in';
    }

    const domain = (brandId === 31 || brandId === 32) ? 'Yolo247' : brandId === 33 ? 'iw247' : (brandId === 39 || brandId === 40) ? 'FOMO7': (brandId === 46 || brandId === 47) ? 'FUN88':  'Betdaily' ;

    return (
        <>
            <Helmet>
                <script type="application/ld+json">
                    {
                        structuredData(domainURL)
                    }
                </script>
            </Helmet>
            {
                (brandId == 33 || brandId == 46) ? <></> :
                (brandId == 31 || brandId == 32) ? <SportsExchangeSEO31 /> :
                (brandId == 47) ? <SportsExchangeSeoFun88 /> :
                <SportsExchangeSEODefault />
            }
        </>
    )
}

export default withAppContext(SportsExchangeSEO)