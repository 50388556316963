import React, { useEffect } from 'react'
import { MainInfoWrapper } from 'src/components/udb/commonStyle';
import config from 'src/config/config';
import { withAppContext } from 'src/store/initAppContext';
import styled, { withTheme } from 'styled-components';
import { theme } from 'styled-tools';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import { BannerWrapper, FifaFeverFirstSecHead, StepTittle, TextContent } from './BblBonusInner';
import PlaceholderImage from 'src/components/Placeholder';
import cookieUtil from 'src/util/cookieUtil';
import { ECookieName } from 'src/util/utilModel';
import FooterContainer from "src/components/footer/footer";
import { useNavigate } from "react-router-dom";
import Intl from 'src/components/common/Intl';
import { ContentWrapper } from './aviatorBonusPage';
import { BannerWrapper1 } from './PSLPromo';
import { ItalicTextFull, OffersSection, PlayNowButtonNew, TermsConditionPoints } from './crazyCrashPromo';
import IPLseoBD from 'src/components/SEOComponents/IPLseoBD';
import { BetnowBTN1 } from './ipl-promo';


const Ipl_matkaBD = (props) => {

    const { app: { brandId }, theme } = props
    const teams = [
        { imageSvg: `${config.imageV3Host}${theme.image.kkr}` },
        { imageSvg: `${config.imageV3Host}${theme.image.kxip}` },
        { imageSvg: `${config.imageV3Host}${theme.image.srh}` },
        { imageSvg: `${config.imageV3Host}${theme.image.dc}` },
        { imageSvg: `${config.imageV3Host}${theme.image.lsg}` },
        { imageSvg: `${config.imageV3Host}${theme.image.rcb}` },
        { imageSvg: `${config.imageV3Host}${theme.image.csk}` },
        { imageSvg: `${config.imageV3Host}${theme.image.mi}` },
        { imageSvg: `${config.imageV3Host}${theme.image.rr}` },
        { imageSvg: `${config.imageV3Host}${theme.image.gt}` },
    ]

    const prizes = [
        { image1: `${config.imageV3Host}/static/cashsite${theme.image.image1}` },
        { image1: `${config.imageV3Host}/static/cashsite${theme.image.image2}` },
        { image1: `${config.imageV3Host}/static/cashsite${theme.image.image3}` },
        { image1: `${config.imageV3Host}/static/cashsite${theme.image.image4}` },
        { image1: `${config.imageV3Host}/static/cashsite${theme.image.image5}` },
        { image1: `${config.imageV3Host}/static/cashsite${theme.image.image6}` },
        { image1: `${config.imageV3Host}/static/cashsite${theme.image.image7}` },
        { image1: `${config.imageV3Host}/static/cashsite${theme.image.image8}` },
    ]

    const Language = cookieUtil.get(ECookieName.COOKIE_LANG)

    useEffect(() => {
        window.scrollTo(0, 0)
    }, [])

    const navigate = useNavigate()

    return (
        <MainInfoWrapper>
            <BannerWrap>
                <LazyLoadImage src={`${config.imageV3Host}/static/cashsite/${theme.image.IPLInnerLandingBannerEng}`} effect="none" height="100%" width="100%" placeholder={<PlaceholderImage />} />
                <BetnowBTN onClick={() => navigate('/betting-exchange/online-cricket-satta-bazar')}>Bet Now</BetnowBTN>
            </BannerWrap>
            <ContentWrapper1>
                <TextDiv>
                    The most exciting time of the year for every cricket fan is when the Indian Premier League is underway. That’s why IPL lovers will receive a special <span>IPL Satta Matka BONUS </span> on BETDAILY to enjoy this popular competition even more.
                    <br></br>
                    <br></br>
                    Results  for IPL  will be declared on BETDAILY social media (Instagram & Telegram handles) on  <span>5 June, 2023</span>
                </TextDiv>
                <TeamsSection>
                    {teams.map((elm) => (
                        <LazyLoadImage src={elm.imageSvg} effect="none" height="100%" width="100%" placeholder={<PlaceholderImage />} />))}
                </TeamsSection>
                <FifaFeverFirstSecHead className="enterPromo">Three unique programs launched:</FifaFeverFirstSecHead>
                <ImgSection>
                    <LazyLoadImage src={ `${config.imageV3Host}/static/cashsite/${theme.image.entryrule1}`} />
                    <LazyLoadImage src={ `${config.imageV3Host}/static/cashsite/${theme.image.entryrule2}`} />
                    <LazyLoadImage src={`${config.imageV3Host}/static/cashsite/${theme.image.entryrule3}`} className='lastImg' />
                </ImgSection>
                <OffersSection>
                    <FifaFeverFirstSecHead className='readMoreOffer'>IPL khela paisa jeeto</FifaFeverFirstSecHead>
                </OffersSection>
                <TextSection className='infosec'>
                    IPL Khelo, Cash Jeeto! Increase karo apne Chances to Win Exciting Cash Rewards by taking part in our <span>IPL Satta Matka Bonanza 2023 </span> held from <span>31st March to 28th May 2023.</span>
                    <br></br>
                    <br></br>
                    100 lucky players stand a chance to WIN through <span>IPL Satta Matka.</span>
                    All you need to do is earn a minimum of 100 points to win IPL Satta Pool Prize of <span>1.6 Crore.</span>
                    <br></br>
                    <br></br>
                    <span>✪</span> Minimum bet amount to enter this contest is <span>INR 500</span> <br></br>
                    <span>✪</span> Go through the Points Chart below :
                </TextSection>
                <ImgSection className='table'>
                    <LazyLoadImage src={`${config.imageV3Host}/static/cashsite/${theme.image.BDtable}`} />
                </ImgSection>
                <FifaFeverFirstSecHead className="priceAlloc">Player of the IPL Satta Matka Series</FifaFeverFirstSecHead>
                <TextSection className='infosec'>
                    In every IPL season, one player emerges as the Player of the Series. Why can’t IPL betting enthusiasts have a chance to win an award for making the right predictions? Here & Now, we have that chance!
                    <br></br>
                    <br></br>
                    One player who guesses the IPL trophy winner gets a chance to win this contest and become the <span>“Player of the IPL Satta Matka Series”.</span>
                    <br></br>
                    <br></br>
                    All you need to do is place a bet on the team you feel will WIN this IPL & stand a chance to win Free Tickets To IPL FINALS.  You can place your bets from <span> 31st MARCH to 28th MAY 2023 to win Title of Player of the IPL Satta Matka Series</span>
                    <br></br>
                    <br></br>
                    <span>✪</span> Minimum bet amount is INR 1,000
                </TextSection>
                <ImgSection className='ticket'>
                    <LazyLoadImage src={`${config.imageV3Host}/static/cashsite/${theme.image.finalsticket}`} />
                </ImgSection>
                <FifaFeverFirstSecHead className='bonus'>free-hit bonus</FifaFeverFirstSecHead>
                <ImgSection className='sky'>
                    <LazyLoadImage src={`${config.imageV3Host}/static/cashsite/${theme.image.skyText}`} />
                </ImgSection>

                <FifaFeverFirstSecHead className='prizeBD'>IPL Satta Matka Bonanza 2023 Prize Allocation</FifaFeverFirstSecHead>
                <ParaTag>This 16th Edition of IPL, get in to the contest with the Prize Pool of <span>1.6 Crore.</span> </ParaTag>
                <ImgSection className='tableBD'>
                    <LazyLoadImage src={`${config.imageV3Host}/static/cashsite/${theme.image.tableBD}`} />
                </ImgSection>
                <TextSection className='infosec1'>
                    Rules for winning the Player of the <span>IPL Satta Matka Series:</span>
                    <br></br>
                    <br></br>
                    <span>✪</span> The Player must place a bet on Special Market during the IPL on the Winning Team.<br></br>
                    <span>✪</span> The Player must also place 1 Fancy Bet.<br></br>
                    <span>✪</span> Player must place a bet in all IPL matches on BetDaily
                    <br></br>
                    <br></br>
                    Rules for <span>Free-Hit Bonus:</span>
                    <br></br>
                    <br></br>
                    <span>✪</span> Player must place a bet in all IPL matches on BetDaily<br></br>
                    <span>✪</span> Net Deposit a minimum Rs.5000 in one match<br></br>
                    <span>✪</span> Player must place at least 1 bet in fancy market in every IPL match<br></br>
                </TextSection>
                <FifaFeverFirstSecHead className="enter">how to enter IPL?</FifaFeverFirstSecHead>
                <InformationRegisterWrap>
                    <ImgSection className='reg'>
                        <LazyLoadImage src={`${config.imageV3Host}/static/cashsite/${theme.image.mobilePicRight}`} />
                    </ImgSection>
                    <RegisterWrap>
                        <BorderInfo>
                            <ImgDiv>
                                <LazyLoadImage src={`${config.imageV3Host}/static/cashsite/${theme.image.registericon}`} />
                            </ImgDiv>
                            <TextContentWrap>
                                <span>REGISTER</span>
                                <p>Create a BetDaily account if you do not have one yet. Register with your name and mobile number.</p>
                            </TextContentWrap>
                        </BorderInfo>
                        <BorderInfo>
                            <ImgDiv>
                                <LazyLoadImage src={`${config.imageV3Host}/static/cashsite/${theme.image.depositicon}`} />
                            </ImgDiv>
                            <TextContentWrap>
                                <span>DEPOSIT NOW</span>
                                <p>Deposit using any method and start playing IPL !</p>
                            </TextContentWrap>
                        </BorderInfo>
                    </RegisterWrap>
                </InformationRegisterWrap>
                <FifaFeverFirstSecHead className='offerclass'>Read more about the offer below:</FifaFeverFirstSecHead>
                <PrizesWrap>
                    <Imageprizes>
                        {prizes.map((elm) => (
                            <LazyLoadImage src={elm.image1} />
                        ))}
                    </Imageprizes>
                </PrizesWrap>
                <FifaFeverFirstSecHead className='tcClass'>Terms & Conditions for IPL Satta Matka Bonanza 2023</FifaFeverFirstSecHead>
                <TermsConditionPoints1>
                    <ul>
                        <li><TextContent><Intl langKey="ipl@GeneralTermsConditionFirstBD" /></TextContent></li>
                        <li><TextContent><Intl langKey="ipl@GeneralTermsConditionSecondBD" /> <StepTittle><Intl langKey="ipl@GeneralTermsConditionSecondWinnersWillBeBD" /></StepTittle></TextContent></li>
                        <li><TextContent><Intl langKey="ipl@GeneralTermsConditionThirdBD" /></TextContent></li>
                        {/* <li><TextContent><Intl langKey="ipl@GeneralTermsConditionThirdextBD" /></TextContent></li> */}
                        <li><TextContent><Intl langKey="ipl@GeneralTermsConditionFourthBD" /></TextContent></li>
                        <li><TextContent><Intl langKey="ipl@GeneralTermsConditionFifthBD" /></TextContent></li>
                        <li><TextContent><Intl langKey="ipl@GeneralTermsConditionSixthBD" /></TextContent></li>
                        <li><TextContent><Intl langKey="ipl@GeneralTermsConditionSeventhBD" /></TextContent></li>
                        <li><TextContent><Intl langKey="ipl@GeneralTermsConditionEighthBD" /></TextContent></li>
                        <li><TextContent><Intl langKey="ipl@GeneralTermsConditionNinethBD" /></TextContent></li>
                    </ul>
                   
                </TermsConditionPoints1>
                 <BetnowBTN1 onClick={()=>navigate('/betting-exchange/online-cricket-satta-bazar')}>Bet Now</BetnowBTN1>  
            </ContentWrapper1>
            <IPLseoBD/>
            <FooterContainer />
        </MainInfoWrapper>
    )
}

export default withTheme(withAppContext(Ipl_matkaBD));

const InformationRegisterWrap = styled.div`
width: 100%;
display: flex;
flex-direction: row;
margin-bottom: 3rem;
`
const ParaTag = styled.p`
font-size: 16px;
text-align: center;
text-transform: capitalize;
color: #FFFFFF;
span{
    color: #DABA57;
}
`
const Imageprizes = styled.div`
    width: 100%;
    display: flex;
    -webkit-flex-wrap: wrap;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
    gap: 4rem;
`
const PrizesWrap = styled.div`
width: 100%;
margin-bottom: 3rem;
img{
    width: 40%;
}
`
const TextContentWrap = styled.div`
    width: 70%;
    display: flex;
    flex-direction: column;
    align-items: baseline;
    justify-content: flex-start;

span{
    font-weight: 900;
font-size: 20px;
text-transform: uppercase;
color: #DABA57;
}
p{
    font-size: 17px;
    text-transform: capitalize;
    color: #FFFFFF;
    padding:0rem;
}
`
const ImgDiv = styled.div`
    width: 23%;
    display: flex;
    align-items: center;
    justify-content: center;
`

const RegisterWrap = styled.div`
    width: 50%;
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: center;
    padding-right: 7rem;
    gap:1.4rem;
`
const BorderInfo = styled.div`
    width: 100%;
    height: 160px;
    border: 1px solid red;
    display: flex;
    align-items: center;
    justify-content: center;
    border: 1px solid #D1AD46;
    border-radius: 10px;
`
const BannerWrap = styled(BannerWrapper1)`
margin-bottom: -6px;
position: relative;
`
const TermsConditionPoints1 = styled(TermsConditionPoints)`
position: relative;
 ::after{
            content: '';
            background: url(${config.imageV3Host}/static/cashsite/${theme('image.linesright')});
            position: absolute;
            right: -5%;
    top: 25%;
    height: 500px;
    width: 100%;
    background-size: contain;
    background-position: right;
    background-repeat: no-repeat;

        }
`
const TextSection = styled.div`
display: block;
font-weight: 500;
font-size: 21px;
line-height: 148.5%;
text-align: center;
text-transform: capitalize;
color: #FFFFFF;
padding: 0rem 15rem;
span{
    color: #D1AD46;
}
`
const BetnowBTN = styled(PlayNowButtonNew)`
background: linear-gradient(183.17deg, #CEA63D 0%, #FDF3A0 50.52%, #CFA840 100%), #EF4123;
border: 2px solid #FCF29E;
box-shadow: 0px 0px 30px rgba(247, 233, 147, 0.5);
border-radius: 38.7857px;
right: 44%;
bottom: 8%;
padding: 18px 50px;
font-size: 25.8571px;
line-height: 31px;
display: flex;
align-items: center;
letter-spacing: 0.01em;
text-transform: uppercase;
color: #000000;
`
const ContentWrapper1 = styled(ContentWrapper)`
background: url(${config.imageV3Host}/static/cashsite/${theme('image.iplBGpromo')});
background-repeat: no-repeat;
background-size: cover;
.infosec{
    display: block;
    width: 100%;
    gap:3rem;
    padding-top: 3rem;
}
.infosec1{
    display: block;
    width: 100%;
    gap:3rem;
}
.ipl1{
      width:20%;
    }

    .mobile{
        width: 100%;
    }
    .ipl2{
        width:55%
    }

    .tcClass{
        background: url(${config.imageV3Host}/static/cashsite${theme('image.t&cbg')});
        width: 346px;
        height:70px;
    background-repeat: no-repeat;
    background-position: center;
    background-size: contain;
    font-weight: 700;
    font-size: 29px;
    text-align: center;
    text-transform: uppercase;
    white-space: nowrap; 
       color: #D1AD46; 
       margin-top: 2rem;
    margin-bottom: 3rem;
    }
    .offerclass{
        background: url(${config.imageV3Host}/static/cashsite${theme('image.offersBgBet')});
        width: 346px;
        height:70px;
    background-repeat: no-repeat;
    background-position: center;
    background-size: contain;
    font-weight: 700;
    font-size: 29px;
    text-align: center;
    text-transform: uppercase;
    white-space: nowrap; 
       color: #D1AD46; 
       margin-top: 2rem;
    margin-bottom: 5rem;
    }

    .bonus{
        background: url(${config.imageV3Host}/static/cashsite${theme('image.BonusBGBD')});
        width: 346px;
        height:70px;
    background-repeat: no-repeat;
    background-position: center;
    background-size: contain;
    font-weight: 700;
    font-size: 29px;
    text-align: center;
    text-transform: uppercase;
    white-space: nowrap; 
       color: #D1AD46;
    }
    .prizeBD{
        background: url(${config.imageV3Host}/static/cashsite${theme('image.prizeBg')});
        width: 346px;
        height:70px;
    background-repeat: no-repeat;
    background-position: center;
    background-size: contain;
    font-weight: 700;
    font-size: 29px;
    text-align: center;
    text-transform: uppercase;
    white-space: nowrap; 
       color: #D1AD46;
       margin-top: 2rem;
    margin-bottom: 2rem;
    }

    .readMoreOffer{
        background: url(${config.imageV3Host}/static/cashsite${theme('image.ipltextbg')});
        width: 346px;
        height:70px;
    background-repeat: no-repeat;
    background-position: center;
    background-size: contain;
    font-weight: 700;
    font-size: 29px;
    text-align: center;
    text-transform: uppercase;
    white-space: nowrap; 
       color: #D1AD46;
    }

    .reg{
        padding: 0rem;
        width:50%;
        img{
            width: 70%;
        }
    }
    .table{
        padding:3rem 19rem 4rem 19rem;
        position: relative;
        img{
            width: 70%;
        }
        ::before{
            content: '';
            background: url(${config.imageV3Host}/static/cashsite/${theme('image.linesleft')});
            position: absolute;
            position: absolute;
            left: 0%;
            top:-25%;
            height: 1289px;
            width: 100%;
            background-size: contain;
            background-position: left;
            background-repeat: no-repeat;
                }
    }
    .ticket{
        img{
            width: 50%;
            padding-top: 3rem;
        }
    }
    .sky{
        img{
            width: 85%;
        }
    }
    .tableBD{
        img{
            width: 90%;
        }
    }
    .enterPromo{
        background: url(${config.imageV3Host}/static/cashsite/${theme('image.crazyCrashEnterHead')});
        width: 346px;
        height:70px;
    background-repeat: no-repeat;
    background-position: center;
    background-size: contain;
    font-weight: 700;
    font-size: 29px;
    text-align: center;
    text-transform: uppercase;
    white-space: nowrap;
    color: #D1AD46;  
     }

     .enter{
        background: url(${config.imageV3Host}/static/cashsite${theme('image.enterBG')});
        width: 346px;
        height:70px;
    background-repeat: no-repeat;
    background-position: center;
    background-size: contain;
    font-weight: 700;
    font-size: 29px;
    text-align: center;
    text-transform: uppercase;
    white-space: nowrap;
    color: #D1AD46;  
    margin-top: 5rem;
    margin-bottom: 2rem;
     }

     .priceAlloc{
        background: url(${config.imageV3Host}/static/cashsite${theme('image.player')});
        height:70px;
        width: 360px;
        background-repeat: no-repeat;
        background-position: center;
        background-size: contain;
        font-size: 30px;
        line-height: 36px;
        text-align: center;
        text-transform: uppercase;
        white-space: nowrap;
        color:#D1AD46
     }     
     .terms{
        background: url(${config.imageV3Host}/static/cashsite/${theme('image.crazyCrashTCHead')});
        height:70px;
        width: 200px;
        background-repeat: no-repeat;
        background-position: center;
        background-size: contain;
        font-size: 30px;
        display: flex;
        align-items: center;
        text-align: center;
        text-transform: uppercase;
        white-space: nowrap;
        color:#D1AD46
     }

     .lastImg{
        margin-top: 4rem;
     }
`
const ImgSection = styled.div`
width: 100%;
display: flex;
align-items: center;
justify-content: space-around;
flex-direction: row;
padding: 0rem 10rem 4rem 10rem;
flex-wrap: wrap;
    img{
        width: 45%;
        display: flex;
        align-items: center;
        justify-content: space-around;
    }
    
&::after{
    content: '';
            background: url(${config.imageV3Host}/static/cashsite/${theme('image.linesleftBD')});
            position: absolute;
            right: 0%;
            bottom:100%;
            height: 1289px;
            width: 100%;
            background-size: contain;
            background-position: right;
            background-repeat: no-repeat;
                }

`
const TextDiv = styled.div`
font-size: 19px;
text-align: center;
text-transform: capitalize;
padding: 5rem 14rem 2rem 14rem;
color: #FFFFFF;

span{
font-style: normal;
font-weight: 700;
font-size: 19px;
text-align: center;
text-transform: capitalize;
color: #DABA57;
}
`
export const TeamsSection = styled.div`
width: 100%;
display: flex;
align-items: center;
justify-content: center;
flex-direction: row;
padding: 0rem 15rem;
margin-bottom: 3rem;
img{
    width:100%; 
}
`