import React, { useEffect } from 'react'
import { withAppContext } from 'src/store/initAppContext';
import styled, { withTheme } from 'styled-components';
import { LazyLoadImage } from "react-lazy-load-image-component";
import config from "src/config/config";
import PlaceholderImage from 'src/components/Placeholder';
import { theme } from 'styled-tools';
import FooterContainer from 'src/components/footer/footer';
import { Link } from "react-router-dom";


function EvolutionCrazyPrizeDropPromo(props: any) {
    const { app: {
        brandId
    }, theme } = props;

    useEffect(() => {
        window.scrollTo(0, 0)
    }, []);

    const domain = (brandId == 31 || brandId == 32) ? "YOLO247" : (brandId == 39 || brandId == 40) ? "FOMO7" : (brandId == 46 || brandId == 47) ? 'FUN88' : 'IW247';
    const customListStyle = (brandId == 39 || brandId == 40) ? "fomoListStyle" : (brandId == 46 || brandId == 47) ? "funListStyle" : "baaziListStyle";

    //Custom Bullet Icon.
    const FomoBulletIcon = () => {
        return (
            <SvgWrapper>
                <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 18 18" fill="none">
                    <g clip-path="url(#clip0_38_2970)">
                        <path d="M8.93298 17.3791C11.1785 17.3791 13.332 16.4871 14.9198 14.8993C16.5076 13.3115 17.3996 11.158 17.3996 8.91247C17.3996 6.66697 16.5076 4.51344 14.9198 2.92563C13.332 1.33782 11.1785 0.445801 8.93298 0.445801C6.68748 0.445801 4.53395 1.33782 2.94614 2.92563C1.35833 4.51344 0.466309 6.66697 0.466309 8.91247C0.466309 11.158 1.35833 13.3115 2.94614 14.8993C4.53395 16.4871 6.68748 17.3791 8.93298 17.3791ZM7.61006 11.5583H8.40381V9.44164H7.61006C7.17019 9.44164 6.81631 9.08775 6.81631 8.64788C6.81631 8.20801 7.17019 7.85413 7.61006 7.85413H9.19756C9.63743 7.85413 9.99131 8.20801 9.99131 8.64788V11.5583H10.2559C10.6958 11.5583 11.0496 11.9122 11.0496 12.3521C11.0496 12.7919 10.6958 13.1458 10.2559 13.1458H7.61006C7.17019 13.1458 6.81631 12.7919 6.81631 12.3521C6.81631 11.9122 7.17019 11.5583 7.61006 11.5583ZM8.93298 4.67913C9.21366 4.67913 9.48285 4.79064 9.68133 4.98911C9.87981 5.18759 9.99131 5.45678 9.99131 5.73747C9.99131 6.01816 9.87981 6.28735 9.68133 6.48582C9.48285 6.6843 9.21366 6.7958 8.93298 6.7958C8.65229 6.7958 8.3831 6.6843 8.18462 6.48582C7.98615 6.28735 7.87464 6.01816 7.87464 5.73747C7.87464 5.45678 7.98615 5.18759 8.18462 4.98911C8.3831 4.79064 8.65229 4.67913 8.93298 4.67913Z" fill="#CB4008" />
                    </g>
                    <defs>
                        <clipPath id="clip0_38_2970">
                            <path d="M0.466309 0.445801H17.3996V17.3791H0.466309V0.445801Z" fill="white" />
                        </clipPath>
                    </defs>
                </svg>
            </SvgWrapper>
        )
    }

    const FunBulletIcon = () => {
        return (
            <SvgWrapper>
                <svg xmlns="http://www.w3.org/2000/svg" width="15" height="15" viewBox="0 0 15 15" fill="none">
                    <path d="M7.30002 14.707C9.13002 14.707 10.8851 13.98 12.1791 12.686C13.4731 11.392 14.2 9.63698 14.2 7.80698C14.2 5.97699 13.4731 4.22195 12.1791 2.92795C10.8851 1.63394 9.13002 0.906982 7.30002 0.906982C5.47003 0.906982 3.71499 1.63394 2.42099 2.92795C1.12699 4.22195 0.400024 5.97699 0.400024 7.80698C0.400024 9.63698 1.12699 11.392 2.42099 12.686C3.71499 13.98 5.47003 14.707 7.30002 14.707ZM6.2219 9.96323H6.86877V8.23823H6.2219C5.86342 8.23823 5.57502 7.94983 5.57502 7.59136C5.57502 7.23288 5.86342 6.94448 6.2219 6.94448H7.51565C7.87413 6.94448 8.16253 7.23288 8.16253 7.59136V9.96323H8.37815C8.73663 9.96323 9.02503 10.2516 9.02503 10.6101C9.02503 10.9686 8.73663 11.257 8.37815 11.257H6.2219C5.86342 11.257 5.57502 10.9686 5.57502 10.6101C5.57502 10.2516 5.86342 9.96323 6.2219 9.96323ZM7.30002 4.35698C7.52877 4.35698 7.74815 4.44785 7.9099 4.6096C8.07165 4.77135 8.16253 4.99073 8.16253 5.21948C8.16253 5.44823 8.07165 5.66761 7.9099 5.82936C7.74815 5.99111 7.52877 6.08198 7.30002 6.08198C7.07128 6.08198 6.8519 5.99111 6.69015 5.82936C6.5284 5.66761 6.43752 5.44823 6.43752 5.21948C6.43752 4.99073 6.5284 4.77135 6.69015 4.6096C6.8519 4.44785 7.07128 4.35698 7.30002 4.35698Z" fill="#046EFE" />
                </svg>
            </SvgWrapper>
        )
    }

    const BaaziBulletIcon = () => {
        return (
            <SvgWrapper>
                <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 18 18" fill="none">
                    <g clip-path="url(#clip0_150_238)">
                        <path d="M8.93313 17.3791C11.1786 17.3791 13.3322 16.4871 14.92 14.8993C16.5078 13.3115 17.3998 11.158 17.3998 8.91247C17.3998 6.66697 16.5078 4.51344 14.92 2.92563C13.3322 1.33782 11.1786 0.445801 8.93313 0.445801C6.68763 0.445801 4.5341 1.33782 2.94629 2.92563C1.35848 4.51344 0.466461 6.66697 0.466461 8.91247C0.466461 11.158 1.35848 13.3115 2.94629 14.8993C4.5341 16.4871 6.68763 17.3791 8.93313 17.3791ZM7.61021 11.5583H8.40396V9.44164H7.61021C7.17034 9.44164 6.81646 9.08775 6.81646 8.64788C6.81646 8.20801 7.17034 7.85413 7.61021 7.85413H9.19771C9.63758 7.85413 9.99146 8.20801 9.99146 8.64788V11.5583H10.256C10.6959 11.5583 11.0498 11.9122 11.0498 12.3521C11.0498 12.7919 10.6959 13.1458 10.256 13.1458H7.61021C7.17034 13.1458 6.81646 12.7919 6.81646 12.3521C6.81646 11.9122 7.17034 11.5583 7.61021 11.5583ZM8.93313 4.67913C9.21382 4.67913 9.48301 4.79064 9.68148 4.98911C9.87996 5.18759 9.99146 5.45678 9.99146 5.73747C9.99146 6.01816 9.87996 6.28735 9.68148 6.48582C9.48301 6.6843 9.21382 6.7958 8.93313 6.7958C8.65244 6.7958 8.38325 6.6843 8.18477 6.48582C7.9863 6.28735 7.87479 6.01816 7.87479 5.73747C7.87479 5.45678 7.9863 5.18759 8.18477 4.98911C8.38325 4.79064 8.65244 4.67913 8.93313 4.67913Z" fill="#CB0808" />
                    </g>
                    <defs>
                        <clipPath id="clip0_150_238">
                            <path d="M0.466461 0.445801H17.3998V17.3791H0.466461V0.445801Z" fill="white" />
                        </clipPath>
                    </defs>
                </svg>
            </SvgWrapper>
        )
    }

    const BulletIcon = () => {
        if (brandId === 39 || brandId === 40) {
            return <FomoBulletIcon />;
        } else if (brandId === 46 || brandId === 47) {
            return <FunBulletIcon />;
        } else if (brandId === 33 || brandId === 34) {
            return <BaaziBulletIcon />;
        } else {
            return null;
        }
    };

    return (
        <>
            <InformationWrapper>
                <TopWrapper>
                    <TopLeftContent>
                        <h1>Crazy Prize Drop 🎊</h1>
                        <p>Double the Fun with 2% Cashback!</p>
                        <InfoWrapper>
                            <InfoContent>
                                <LazyLoadImage
                                    src={`${config.imageV3Host}/static/cashsite/${theme.image.EvolutionCrazyPrizeDropInfoIconOne}`}
                                    alt="Crazy-prize-drop"
                                    effect="none"
                                    placeholder={<PlaceholderImage />}
                                />
                                <p>Promotion Period</p>
                                <p className='boldPara'>March 15 - May 31, 2025</p>
                            </InfoContent>
                            <InfoContent>
                                <LazyLoadImage
                                    src={`${config.imageV3Host}/static/cashsite/${theme.image.EvolutionCrazyPrizeDropInfoIconTwo}`}
                                    alt="Crazy-prize-drop"
                                    effect="none"
                                    placeholder={<PlaceholderImage />}
                                />
                                <p>Daily Prize Pool</p>
                                <p className='boldPara'>₹3,63,000</p>
                            </InfoContent>
                            <InfoContent>
                                <LazyLoadImage
                                    src={`${config.imageV3Host}/static/cashsite/${theme.image.EvolutionCrazyPrizeDropInfoIconThree}`}
                                    alt="Crazy-prize-drop"
                                    effect="none"
                                    placeholder={<PlaceholderImage />}
                                />
                                <p>Eligible Games</p>
                                <p className='boldPara'>3 Games</p>
                            </InfoContent>
                        </InfoWrapper>
                        <Link to="/live-casino/evolution">Claim Your Cashback Now!</Link>
                    </TopLeftContent>
                    <TopRightContent>
                        <LazyLoadImage
                            src={`${config.imageV3Host}/static/cashsite/${theme.image.EvolutionCrazyPrizeDropGirl}`}
                            alt="Crazy-prize-drop"
                            effect="none"
                            placeholder={<PlaceholderImage />}
                        />
                    </TopRightContent>
                </TopWrapper>
                <GamesWrapper>
                    <h2>Eligible Games</h2>
                    <GamesContent>
                        <Games>
                            <Link to="/live-casino/evolution/crazy-time">
                                <LazyLoadImage
                                    src={`${config.imageV3Host}/static/cashsite/${theme.image.EvolutionCrazyPrizeDropGameOne}`}
                                    alt="Crazy-prize-drop"
                                    effect="none"
                                    placeholder={<PlaceholderImage />}
                                />
                            </Link>
                        </Games>
                        <Games>
                            <Link to="/live-casino/evolution/funky-time">
                                <LazyLoadImage
                                    src={`${config.imageV3Host}/static/cashsite/${theme.image.EvolutionCrazyPrizeDropGameTwo}`}
                                    alt="Crazy-prize-drop"
                                    effect="none"
                                    placeholder={<PlaceholderImage />}
                                />
                            </Link>
                        </Games>
                        <Games>
                            <Link to="/live-casino/evolution/lightning-storm">
                                <LazyLoadImage
                                    src={`${config.imageV3Host}/static/cashsite/${theme.image.EvolutionCrazyPrizeDropGameThree}`}
                                    alt="Crazy-prize-drop"
                                    effect="none"
                                    placeholder={<PlaceholderImage />}
                                />
                            </Link>
                        </Games>
                    </GamesContent>
                </GamesWrapper>
                <BigImageWrapper>
                    <LazyLoadImage
                        src={`${config.imageV3Host}/static/cashsite/${theme.image.EvolutionCrazyPrizeDropBigImg}`}
                        alt="Crazy-prize-drop"
                        effect="none"
                        placeholder={<PlaceholderImage />}
                    />
                </BigImageWrapper>
                <RewardsWrapper>
                    <h2>How to Claim Your Reward</h2>
                    <RewardsContent>
                        <Rewards>
                            <LazyLoadImage
                                src={`${config.imageV3Host}/static/cashsite/${theme.image.EvolutionCrazyPrizeDropStepIconOne}`}
                                alt="Crazy-prize-drop"
                                effect="none"
                                placeholder={<PlaceholderImage />}
                            />
                            <h5>Step 1</h5>
                            <p>Wager ₹5,555 or more on eligible games</p>
                        </Rewards>
                        <Rewards>
                            <LazyLoadImage
                                src={`${config.imageV3Host}/static/cashsite/${theme.image.EvolutionCrazyPrizeDropStepIconTwo}`}
                                alt="Crazy-prize-drop"
                                effect="none"
                                placeholder={<PlaceholderImage />}
                            />
                            <h5>Step 2</h5>
                            <p>Receive ₹110 cashback instantly</p>
                        </Rewards>
                        <Rewards>
                            <LazyLoadImage
                                src={`${config.imageV3Host}/static/cashsite/${theme.image.EvolutionCrazyPrizeDropStepIconThree}`}
                                alt="Crazy-prize-drop"
                                effect="none"
                                placeholder={<PlaceholderImage />}
                            />
                            <h5>Step 3</h5>
                            <p>Claim once per day during promo</p>
                        </Rewards>
                        <Rewards>
                            <LazyLoadImage
                                src={`${config.imageV3Host}/static/cashsite/${theme.image.EvolutionCrazyPrizeDropStepIconFour}`}
                                alt="Crazy-prize-drop"
                                effect="none"
                                placeholder={<PlaceholderImage />}
                            />
                            <h5>Step 4</h5>
                            <p>Enjoy your instant rewards!</p>
                        </Rewards>
                    </RewardsContent>
                </RewardsWrapper>
                <PromoWrapper>
                    <PromoImageWrapper>
                        <PromoImageLeft>
                            <LazyLoadImage
                                src={`${config.imageV3Host}/static/cashsite/${theme.image.EvolutionCrazyPrizeDropPromoImgTwo}`}
                                alt="Crazy-prize-drop"
                                effect="none"
                                className="leftImg"
                                placeholder={<PlaceholderImage />}
                            />
                        </PromoImageLeft>
                        <PromoImageRight>
                            <LazyLoadImage
                                src={`${config.imageV3Host}/static/cashsite/${theme.image.EvolutionCrazyPrizeDropPromoImgOne}`}
                                alt="Crazy-prize-drop"
                                effect="none"
                                className="rightImg"
                                placeholder={<PlaceholderImage />}
                            />
                        </PromoImageRight>
                    </PromoImageWrapper>
                    <h2>Why You'll Love This Promo</h2>
                </PromoWrapper>
                <PromoContentWrapper>
                    <PromoContent>
                        <LazyLoadImage
                            src={`${config.imageV3Host}/static/cashsite/${theme.image.EvolutionCrazyPrizeDropPromoIconOne}`}
                            alt="Crazy-prize-drop"
                            effect="none"
                            placeholder={<PlaceholderImage />}
                        />
                        <h6>No Delays</h6>
                        <p>Instant credit to your wallet, no waiting time!</p>
                    </PromoContent>
                    <PromoContent>
                        <LazyLoadImage
                            src={`${config.imageV3Host}/static/cashsite/${theme.image.EvolutionCrazyPrizeDropPromoIconTwo}`}
                            alt="Crazy-prize-drop"
                            effect="none"
                            placeholder={<PlaceholderImage />}
                        />
                        <h6>No Wagering Requirements</h6>
                        <p>Use your cashback immediately without restrictions</p>
                    </PromoContent>
                    <PromoContent>
                        <LazyLoadImage
                            src={`${config.imageV3Host}/static/cashsite/${theme.image.EvolutionCrazyPrizeDropPromoIconThree}`}
                            alt="Crazy-prize-drop"
                            effect="none"
                            placeholder={<PlaceholderImage />}
                        />
                        <h6>Daily Opportunities</h6>
                        <p>Come back every day for more rewards</p>
                    </PromoContent>
                    <PromoContent>
                        <LazyLoadImage
                            src={`${config.imageV3Host}/static/cashsite/${theme.image.EvolutionCrazyPrizeDropPromoIconFour}`}
                            alt="Crazy-prize-drop"
                            effect="none"
                            placeholder={<PlaceholderImage />}
                        />
                        <h6>Huge Prize Pool</h6>
                        <p>₹27,24,000 total prizes available!</p>
                    </PromoContent>
                </PromoContentWrapper>
                {(brandId == 31 || brandId == 32) ?
                    (<TermsWrapper>
                        <h2>Terms & Conditions</h2>
                        <ul className={customListStyle}>
                            <li>The Crazy Prize Drop promotion is valid for all new and existing players.</li>
                            <li>Multiple accounts will not be eligible for this promotion. All players will undergo duplicate checking. </li>
                            <li>{domain} reserves the right to disqualify players involved in collusion or any foul play related to this promotion.</li>
                            <li>{domain} reserves the right to amend, cancel, reclaim, or refuse any promotion at its discretion.</li>
                            <li>{domain} General Terms & Conditions apply.</li>
                        </ul>
                    </TermsWrapper>) :
                    (<TermsWrapper>
                        <h2>Terms & Conditions</h2>
                        <ul className={customListStyle}>
                            <li>
                                <BulletIcon />
                                <p>The Crazy Prize Drop promotion is valid for all new and existing players.</p>
                            </li>
                            <li>
                                <BulletIcon />
                                <p>Multiple accounts will not be eligible for this promotion. All players will undergo duplicate checking.</p>
                            </li>
                            <li>
                                <BulletIcon />
                                <p>{domain} reserves the right to disqualify players involved in collusion or any foul play related to this promotion.</p>
                            </li>
                            <li>
                                <BulletIcon />
                                <p>{domain} reserves the right to amend, cancel, reclaim, or refuse any promotion at its discretion.</p>
                            </li>
                            <li>
                                <BulletIcon />
                                <p>{domain} General Terms & Conditions apply.</p>
                            </li>
                        </ul>
                    </TermsWrapper>)
                }
            </InformationWrapper>
            <FooterContainer />
        </>
    )
}

export default withTheme(withAppContext(EvolutionCrazyPrizeDropPromo));

//Styled Component.
const InformationWrapper = styled.div`
    width: 98%;
    height: auto;
    padding: 3rem 0 5rem 0;
    margin: 0 1.2em 0 1.2em;
    display: flex;
    flex-direction: column;
    border: 2px solid #E5E7EB;
    background: ${theme('palette.EvolutionCPDMainBg')};
    gap: 0;
`;

const TopWrapper = styled.div`
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    background: url(${config.imageV3Host}/static/cashsite/${theme("image.EvolutionCrazyPrizeDropBackgroundOne")});
    background-position: top;
    background-size: cover;
    background-repeat: no-repeat;
    padding: 0 2rem;
`;

const TopRightContent = styled.div`
    width: 40%;
    display: flex;
    justify-content: center;
    align-items: flex-end;

    img {
        width: 80%;
        height: auto;
    }
`;

const TopLeftContent = styled.div`
    width: 60%;
    height: auto;
    display: flex;
    justify-content: center;
    align-items: flex-start;
    flex-direction: column;
    gap: 1.5rem;
    padding-left: 4rem;

    h1 {
        color: ${theme('palette.EvolutionCPDPromoCommonTextColor')};
        font-size: 57.542px;
        font-style: normal;
        font-weight: 900;
        line-height: 57.542px; /* 100% */
        padding-bottom: 0.5rem;
        margin: 0;
    }

    p {
        color: ${theme('palette.EvolutionCPDPromoCashPara')};
        font-size: 23.017px;
        font-style: normal;
        font-weight: 400;
        line-height: 23.017px; /* 100% */
        margin: 0;
    }

    a {
        border-radius: 9589.318px;
        padding: 1rem;
        border: 0px solid #E5E7EB;
        background: ${theme('palette.EvolutionCPDButtonBg')};
        color: #FFF;
        text-align: center;
        font-size: 19.181px;
        font-style: normal;
        font-weight: 700;
        line-height: normal;
        cursor: pointer;
    }
`;

const InfoWrapper = styled.div`
    display: flex;
    justify-content: flex-start;
    align-items: center;
    gap: 2.5rem;
    border-radius: 16.206px;
    border: 0px solid #E5E7EB;
    background: ${theme('palette.EvolutionCPDInfoBgColor')};
    padding: 2rem;
`;

const InfoContent = styled.div`
    display: flex;
    justify-content: center;
    align-items:  center;
    flex-direction: column;
    gap: 10px;
    text-align: center;

    img {
        width: 55%;
    }

    p {
        color: ${theme('palette.EvolutionCPDPromoCommonTextColor')};
        font-size: 18.907px;
        font-style: normal;
        font-weight: 400;
        line-height: 18.907px; /* 100% */
        margin: 0;
        pad: 0;
    }

    .boldPara {
        color: ${theme('palette.EvolutionCPDPromoCommonTextColor')};
        font-size: 21.607px;
        font-style: normal;
        font-weight: 600;
        line-height: 21.607px; /* 100% */
    }
`;

const GamesWrapper = styled.div`
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    gap: 2rem;
    padding: 4rem 2rem 5px 2rem;

    h2 {
        color: ${theme('palette.EvolutionCPDPromoCommonTextColor')};
        text-align: center;
        font-size: 34.525px;
        font-style: normal;
        font-weight: 900;
        line-height: 34.525px; /* 100% */
        margin: 0;
    }
`;

const GamesContent = styled.div`
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 1.5rem;
`;

const Games = styled.div`
    width: 20%;
    display: flex;
    justify-content: center;
    align-items: center;

    img {
        width: 100%;
    }
`;

const BigImageWrapper = styled.div`
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0 5rem;

    img {
        width: 100%;
    }
`;

const RewardsWrapper = styled.div`
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    background: ${theme('palette.EvolutionCPDRewardBgColor')};
    gap: 3rem;
    padding: 4rem;

    h2 {
        color: ${theme('palette.EvolutionCPDPromoCommonTextColor')};
        text-align: center;
        font-size: 34.525px;
        font-style: normal;
        font-weight: 900;
        line-height: 34.525px; /* 100% */
        margin: 0;
    }
`;

const RewardsContent = styled.div`
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: stretch;
    gap: 1.5rem;
`;

const Rewards = styled.div`
    width: 20%;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    flex-direction: column;
    gap: 1rem;
    padding: 1.5rem;
    border-radius: 11.508px;
    background: ${theme('palette.EvolutionCPDRewardContentBgColor')};

    h5 {
        color: ${theme('palette.EvolutionCPDPromoCommonTextColor')};
        text-align: center;
        font-size: 19.181px;
        font-style: normal;
        font-weight: 700;
        line-height: normal;
        margin: 0;
    }

    p {
        color: ${theme('palette.EvolutionCPDPromoCashPara')};
        text-align: center;
        font-size: 15.344px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
        margin: 0;
    }

    img {
        width: 61px;
        height: 61px;
        flex-shrink: 0;
    }
`;

const PromoWrapper = styled.div`
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    gap: 2rem;
    padding: 0.5rem 2rem 2rem 2rem;

    h2 {
        color: ${theme('palette.EvolutionCPDPromoCommonTextColor')};
        text-align: center;
        font-size: 34.525px;
        font-style: normal;
        font-weight: 900;
        line-height: 34.525px; /* 100% */
        margin: 0;
    }
`;

const PromoImageWrapper = styled.div`
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: flex-start;
    position: relative;
    gap: 5rem;
`;

const PromoImageLeft = styled.div`
    width: 25%;
    text-align: right;

    .leftImg {
        position: absolute;
        top: 0;
        left: 2rem;
        width: ${theme('palette.EvolutionCPDLeftImgWidth')};
    }
`;

const PromoImageRight = styled.div`
    width: 70%;
    text-align: left;

    .rightImg {
        width: 85%;
    }
`;

const PromoContentWrapper = styled.div`
    width: 100%;
    margin: auto;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: stretch;
    padding: 1rem 0 10% 0;
    gap: 1.5rem;
    z-index: 9;
`;

const PromoContent = styled.div`
    width: 45%;
    display: flex;
    justify-content: center;
    align-items: flex-start;
    flex-direction: column;
    border-radius: 11.508px;
    background: ${theme('palette.EvolutionCPDPromoConBg')};
    padding: 2rem;
    gap: 5px;

    h6 {
        color: ${theme('palette.EvolutionCPDPromoCommonTextColor')};
        font-size: 23.017px;
        font-style: normal;
        font-weight: 900;
        line-height: normal;
        margin: 0;
    }

    p {
        color: ${theme('palette.EvolutionCPDPromoCashPara')};
        font-size: 15.344px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
        margin: 0;
    }

    img {
        width: 25.174px;
        height: 28.771px;
        flex-shrink: 0;
    }
`;

const TermsWrapper = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    gap: 2rem;
    padding: 3rem;
    background: ${theme('palette.EvolutionCPDTermsBgColor')};

    h2 {
        color: ${theme('palette.EvolutionCPDPromoCommonTextColor')};
        text-align: center;
        font-size: 34.525px;
        font-style: normal;
        font-weight: 900;
        line-height: 34.525px; /* 100% */
        margin: 0;
    }

    & ul {
        width: 80%;
        margin: auto;
        border-radius: 11.508px;
        background: ${theme('palette.EvolutionCPDTermsListBgColor')};
        padding: 10px 40px;
        z-index: 1;
    }

    & li {
        width: 100%;
        color: ${theme('palette.EvolutionCPDPromoCashPara')};
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: 22.8px; /* 142.5% */
        text-transform: capitalize;
        list-style-type: '🔹';
        margin: 1rem 0;
        padding-left: 5px;
    }

    & .fomoListStyle, .funListStyle, .baaziListStyle {
        & li {
            width: 100%;
            display: flex;
            justify-content: flex-start;
            align-items: center;
            gap: 10px;
            list-style-type: none;

            p {
                color: ${theme('palette.EvolutionCPDPromoCashPara')};
                font-size: 16px;
                font-style: normal;
                font-weight: 400;
                line-height: 22.8px; /* 142.5% */
                margin: 0;
            }
        }
    }
`;

const SvgWrapper = styled.span`
    padding-top: 5px;
`;