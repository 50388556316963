import gql from 'graphql-tag';

export const GET_MARQUEE = gql`
	query getMarquee($input: PositionInput!) {
		position {
			position(input: $input) {
				text1
				code
				sort
			}
		}
	}
`;
