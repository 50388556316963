import React from 'react'

type Props = {}

const AndroidIcon = (props: Props) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 17 21"><g clip-path="url(#a)"><path fill="#000" d="m12.338 1.744 1.17-1.127a.353.353 0 0 0 0-.511.385.385 0 0 0-.53 0l-1.252 1.207a6.165 6.165 0 0 0-3.24-.91 6.165 6.165 0 0 0-3.238.91L3.997.106a.385.385 0 0 0-.53 0 .353.353 0 0 0 0 .511l1.169 1.127C3.306 2.811 2.459 4.42 2.459 6.217v.137h12.056v-.137c0-1.798-.847-3.406-2.177-4.473ZM2.458 15.486c0 .354.298.642.665.642h1.833v3.495c0 .76.64 1.377 1.428 1.377.789 0 1.428-.616 1.428-1.377v-3.495h1.377v3.495c0 .76.64 1.377 1.428 1.377.789 0 1.428-.616 1.428-1.377v-3.495h1.833a.654.654 0 0 0 .665-.642V6.714H2.458v8.772ZM1.775 7.57c0-.473-.397-.856-.887-.856S0 7.097 0 7.57v6.385c0 .473.397.856.888.856.49 0 .887-.383.887-.856V7.57ZM16.112 6.714c-.49 0-.887.383-.887.856v6.385c0 .473.397.856.887.856s.888-.383.888-.856V7.57c0-.473-.397-.856-.888-.856Z"/><path fill="#fff" d="M5.784 4.415a.546.546 0 0 0 0-.791.596.596 0 0 0-.82 0 .546.546 0 0 0 0 .791.596.596 0 0 0 .82 0ZM11.892 4.109a.562.562 0 0 0-.48-.642.578.578 0 0 0-.666.463.563.563 0 0 0 .48.642.578.578 0 0 0 .666-.463Z"/></g><defs><clipPath id="a"><path fill="#fff" d="M0 0h17v21H0z"/></clipPath></defs></svg>    
  )
}

export default AndroidIcon;