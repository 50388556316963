import React from 'react'

type Props = {}

export default function Instagram({}: Props) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="100" fill="#bebdc8" height="100" viewBox="0 0 551.034 551.034" >
       <path id="XMLID_17_" d="M386.878,0H164.156C73.64,0,0,73.64,0,164.156v222.722 c0,90.516,73.64,164.156,164.156,164.156h222.722c90.516,0,164.156-73.64,164.156-164.156V164.156 C551.033,73.64,477.393,0,386.878,0z M495.6,386.878c0,60.045-48.677,108.722-108.722,108.722H164.156 c-60.045,0-108.722-48.677-108.722-108.722V164.156c0-60.046,48.677-108.722,108.722-108.722h222.722 c60.045,0,108.722,48.676,108.722,108.722L495.6,386.878L495.6,386.878z"/>
   
       <path id="XMLID_81_" fill="#bebdc8" d="M275.517,133C196.933,133,133,196.933,133,275.516 s63.933,142.517,142.517,142.517S418.034,354.1,418.034,275.516S354.101,133,275.517,133z M275.517,362.6 c-48.095,0-87.083-38.988-87.083-87.083s38.989-87.083,87.083-87.083c48.095,0,87.083,38.988,87.083,87.083 C362.6,323.611,323.611,362.6,275.517,362.6z"/>
   
       <circle id="XMLID_83_" fill="#bebdc8" cx="418.306" cy="134.072" r="34.149"/>

</svg>
  )
}

export  function Instagramf88({}: Props) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="26" height="26" viewBox="0 0 26 26" fill="none">
      <path d="M26 4.72167V21.2681C26 23.8752 23.8855 26 21.2681 26H4.73194C2.12475 26 0 23.8855 0 21.2681V4.72167C0.0102645 2.11449 2.12475 0 4.73194 0H21.2783C23.8855 0 26 2.11449 26 4.72167Z" fill="url(#paint0_linear_4872_919)"/>
      <path d="M18.2092 23.167H7.80103C5.06041 23.167 2.83301 20.9396 2.83301 18.199V7.79078C2.83301 5.05015 5.06041 2.82275 7.80103 2.82275H18.2092C20.9499 2.82275 23.1773 5.05015 23.1773 7.79078V18.199C23.1773 20.9396 20.9499 23.167 18.2092 23.167ZM7.80103 4.18793C5.81998 4.18793 4.19819 5.79946 4.19819 7.79078V18.199C4.19819 20.18 5.80972 21.8018 7.80103 21.8018H18.2092C20.1903 21.8018 21.8121 20.1903 21.8121 18.199V7.79078C21.8121 5.80973 20.2006 4.18793 18.2092 4.18793H7.80103Z" fill="white"/>
      <path d="M13.0048 18.5995C9.9152 18.5995 7.40039 16.0847 7.40039 12.995C7.40039 9.90543 9.9152 7.39062 13.0048 7.39062C16.0944 7.39062 18.6092 9.90543 18.6092 12.995C18.6092 16.0847 16.0944 18.5995 13.0048 18.5995ZM13.0048 8.7558C10.6645 8.7558 8.76557 10.6547 8.76557 12.995C8.76557 15.3354 10.6645 17.2343 13.0048 17.2343C15.3451 17.2343 17.2441 15.3354 17.2441 12.995C17.2441 10.6547 15.3451 8.7558 13.0048 8.7558Z" fill="white"/>
      <path d="M19.0714 8.42703C19.6667 8.42703 20.1492 7.9445 20.1492 7.34926C20.1492 6.75402 19.6667 6.27148 19.0714 6.27148C18.4762 6.27148 17.9937 6.75402 17.9937 7.34926C17.9937 7.9445 18.4762 8.42703 19.0714 8.42703Z" fill="white"/>
      <defs>
        <linearGradient id="paint0_linear_4872_919" x1="-6.5" y1="-2" x2="26" y2="29.5" gradientUnits="userSpaceOnUse">
          <stop stop-color="#FCD65B"/>
          <stop offset="0.473" stop-color="#DC486A"/>
          <stop offset="0.9637" stop-color="#672FC8"/>
        </linearGradient>
      </defs>
    </svg>
  )
}