import React from "react";
import styled, { withTheme } from "styled-components";
import { color } from 'src/sass-files/variable';
import DownArrow from "../../images/down_arrow.png";
import AppArrow from "../../images/up_arrow.png";
// import TransferIcon from "../../images/transfer_icon.png";
// import ChangePasswordIcon from "../../images/change_password.png";
// import MyBetsIcon from "../../images/mybets_icon.png";
// import ProfitLossIcon from "../../images/profit_loss.png";
// import AccountStatementIcon from "../../images/account_statement.png";
// import CasinoStatementIcon from "../../images/casino_statement.png";
// import SportsStatementIcon from "../../images/sports_statement.png";
import { NavLink, Link } from "react-router-dom";
import { useNavigate, useParams } from "react-router-dom";
import Intl from '../../components/common/Intl';
import {withAppContext} from '../../store/initAppContext'
import { theme } from 'styled-tools';
import config from 'src/config/config';
// images
import LeftArrow from "../../images/arrow-left_gold.png"


const { tabsignup, innerGameTittle, secondarycolor } = color

const CHANGE_PASSWORD_HIDDEN_USERS = ["demo2001","demo2002","demo2003", "demo2004", "demo2005", "demo2006", "demo2007", "demo2008", "demo2009", "demo2010", "demo2011", "demo2012", "demo2013", "demo2014", "demo2015", "demo2016", "demo2017", "demo2018", "demo2019", "demo2020", "demo2021", "demo2022", "demo2023", "demo2024", "demo2025", "demo2026", "demo2027", "demo2028", "demo2029", "demo2030", "demo2031", "demo2032", "demo2033", "demo2034", "demo2035", "demo2036", "demo2037", "demo2038", "demo2039", "demo2040", "demo2041", "demo2042", "demo2043", "demo2044", "demo2045", "demo2046", "demo2047", "demo2048", "demo2049", "demo2050", "demo2051", "demo2052", "demo2053", "demo2054", "demo2055", "demo2056", "demo2057", "demo2058", "demo2059", "demo2060", "demo2061", "demo2062", "demo2063", "demo2064", "demo2065", "demo2066", "demo2067", "demo2068", "demo2069", "demo2070", "demo2071", "demo2072", "demo2073", "demo2074", "demo2075", "demo2076", "demo2077", "demo2078", "demo2079", "demo2080", "demo2081", "demo2082", "demo2083", "demo2084", "demo2085", "demo2086", "demo2087", "demo2088", "demo2089", "demo2090", "demo2091", "demo2092", "demo2093", "demo2094", "demo2095", "demo2096", "demo2097", "demo2098", "demo2099"]

const MemberSidebar = (props) => {
    const {
        app: {
            username
        },theme
    } = props
    
    const [visible_member, setVisible] = React.useState(true);
    const [visible_main_dashboard, setVisibleMain] = React.useState(false);
    const [visible_dashboard, setVisiblePoker] = React.useState(false);

    let navigate:any = useNavigate();

    const backButton = () => {
        navigate(-1)
    }
    return (
        <>
            <SidebarWrapper>
                <Member onClick={() => setVisible(!visible_member)} >
                <Intl langKey="MEMBER@MEMBER" />
                    {visible_member ? <img src={AppArrow} alt="" /> : <img src={DownArrow} alt="" />}
                </Member>
                {visible_member && 
                    <LinkWrap  >
                    
                        <NavLink to='/balancetransfer' style={({ isActive }) => ({ background: isActive ? 'white' : '' })} >
                            {/* <img src={TransferIcon}/> */}
                            <img src={`${config.imageS3Host}/static/snk/${theme.image.transferIcon}`} alt="" />
                            <Intl langKey="MEMBER@TRANSFER" />
                        </NavLink>
                        {
                            !CHANGE_PASSWORD_HIDDEN_USERS.includes(username) && 
                            <NavLink to='/changepassword' style={({ isActive }) => ({ background: isActive ? 'white' : '' })} >
                            {/* <img src={ChangePasswordIcon}/> */}
                            <img src={`${config.imageS3Host}/static/snk/${theme.image.passwordIcon}`} alt="" />
                            <Intl langKey="MEMBER@CHANGE_PASSWORD" />
                            </NavLink>
                        }
                        
                        <NavLink to='/myorder' style={({ isActive }) => ({ background: isActive ? 'white' : '' })}>
                            {/* <img src={MyBetsIcon}/> */}
                            <img src={`${config.imageS3Host}/static/snk/${theme.image.mybetsIcon}`} alt="" />
                            <Intl langKey="MEMBER@MY_ORDER" />
                        </NavLink>

                        <NavLink to='/profitloss' style={({ isActive }) => ({ background: isActive ? 'white' : '' })}>
                            {/* <img src={ProfitLossIcon}/> */}
                            <img src={`${config.imageS3Host}/static/snk/${theme.image.profitIcon}`} alt="" />
                            <Intl langKey="MEMBER@PROFIT_LOSS" />
                        </NavLink>

                        <NavLink to='/accountstatement' style={({ isActive }) => ({ background: isActive ? 'white' : '' })}>
                            {/* <img src={AccountStatementIcon}/> */}
                            <img src={`${config.imageS3Host}/static/snk/${theme.image.accountIcon}`} alt="" />
                            <Intl langKey="MEMBER@ACCOUNT_STATEMENT" />
                        </NavLink>

                        <NavLink to='/casinostatement' style={({ isActive }) => ({ background: isActive ? 'white' : '' })}>
                            {/* <img src={CasinoStatementIcon}/> */}
                            <img src={`${config.imageS3Host}/static/snk/${theme.image.casinoIcon}`} alt=""/>
                            <Intl langKey="MEMBER@CASINO_STATEMENT" />
                        </NavLink>
                        
                        <NavLink to='/sportbookstatement' style={({ isActive }) => ({ background: isActive ? 'white' : '' })}>
                            {/* <img src={CasinoStatementIcon}/> */}
                            <img src={`${config.imageS3Host}/static/snk/${theme.image.sportsBookIcon}`} alt="" />
                            <Intl langKey="MEMBER@SPORTBOOK_STATEMENT" />
                        </NavLink>

                        <NavLink to='/virtualSportsStatement' style={({ isActive }) => ({ background: isActive ? 'white' : '' })}>
                            {/* <img src={CasinoStatementIcon}/> */}
                            <img src={`${config.imageS3Host}/static/snk/${theme.image.sportsBookIcon}`} alt="" />
                            <Intl langKey="MEMBER@VIRTUALSPORT_STATEMENT" />
                        </NavLink>

                        <NavLink to='/security' style={({ isActive }) => ({ background: isActive ? 'white' : '' })}>
                            {/* <img src={ChangePasswordIcon}/> */}
                            <img src={`${config.imageS3Host}/static/snk/${theme.image.securityIcon}`} alt="" />
                            <Intl langKey="MEMBER@SECURITY" />
                        </NavLink>
                        
                    </LinkWrap>
                }

                <BackButton onClick={backButton}> <img src={LeftArrow} alt="" />
                <Intl langKey="BUTTON@BACK" />
                </BackButton>
            </SidebarWrapper>

            
        </>
    )
}
  
export default withTheme(withAppContext(MemberSidebar));

const BackButton= styled.button`
    display: flex;
    font-size: 15px;
    color: ${theme('palette.accountBackColor')};
    -webkit-box-align: center;
    align-items: center;
    -webkit-box-pack: start;
    justify-content: flex-start;
    padding: 0.9em 1.7em;
    // background-color: #786B57;
    background-color: ${theme('palette.secondary-background-color-new')};

    border-radius: 3px;
    margin-left: 150px;
    position: absolute;
    top: 50px;
    right: 7px;
    cursor:pointer;
    border: 0.25px solid #D0B99F;
    // box-shadow: 0 0 3px #786B57;

img{
    padding-right:5px;
    color:#fff;
    
}




`;

const LinkWrap: any = styled.div`
    width: 100%;

    a {
        letter-spacing: 0.03em;
        text-transform: capitalize;
        color: ${theme('palette.secondary-background-color-new')};
        padding: 15px 20px;
        display: flex;
        align-items: center;
        justify-content: flex-start;
        width: 100%;
        cursor: pointer;
        position: relative;

        img {
            width: 20px;
            height: 20px;
            margin-right: 10px;
        }

        :hover {
            background: ${secondarycolor};
            box-shadow: 0px 0px 12px rgba(0, 0, 0, 0.1);

        }

        :hover:before {
            content: "";
            width: 5px;
            height: 50px;
            display: flex;
            position: absolute;
            left: 0px;
            top: 0px;
            background-color: ${theme('palette.primary-background-color-new')};
        }

    }
`;

const Member = styled.button `
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    padding: 15px 20px;
    // background: #786B57;
    background: ${theme('palette.secondary-background-color-new')};
    border: none;
    // color: ${theme('palette.primary-background-color-new')};
    color: ${theme('palette.accountBackColor')};
    letter-spacing: 0.03em;
    text-transform: uppercase;
    font-weight: 500;
    border-bottom: 1px solid #5a5a5a;

    #active{
        background-color:red;
    }
`;

const PokerDashboard = styled.button `
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    padding: 15px 20px;
    background: #786B57;
    border: none;
    color: #D0B99F;
    letter-spacing: 0.03em;
    text-transform: uppercase;
    font-weight: 500;
    border-bottom: 1px solid #5a5a5a;
`;

const MainDashboard = styled.button `
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    padding: 15px 20px;
    background: #786B57;
    border: none;
    color: #D0B99F;
    letter-spacing: 0.03em;
    text-transform: uppercase;
    font-weight: 500;
    border-bottom: 1px solid #5a5a5a;
`;

const SidebarWrapper = styled.div `
    width: 250px;
    height: calc(100vh - 63px);
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    
    padding-top: 9em;
    font-size: 14px;
    // background: linear-gradient(180deg, #EDE0D4 0%, #E4D2B5 100%);
    background: ${theme('palette.new-whitelable-member-background')};
    overflow: overlay;
    position:relative;
`;


