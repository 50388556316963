import React from 'react'

type Props = {}

const Addbank = (props: Props) => {
  return (
<svg width="44" height="44" viewBox="0 0 44 44" fill="none" xmlns="http://www.w3.org/2000/svg">
<rect x="5.41748" y="19.5908" width="5.34854" height="12.0671" rx="1.06157" fill="white"/>
<path d="M0.055235 16.4705V12.8535C0.055235 12.3406 0.055235 12.0762 0.460821 11.8583C6.23342 8.75756 17.8799 2.48461 18.2848 2.19882C18.6897 1.91302 19.2483 2.07974 19.477 2.19882C24.7811 5.05548 35.6132 10.8939 36.5087 11.3943C37.4042 11.8947 37.649 12.431 37.6594 12.6366V16.4268C37.6594 17.8751 36.9429 18.2293 36.5846 18.2254H35.6907H1.70561C0.273185 18.2254 0.00851682 17.0554 0.055235 16.4705Z" fill="white"/>
<path fill-rule="evenodd" clip-rule="evenodd" d="M18.9267 33.0196L1.12749 33.0195C0.5412 33.0195 0.0659183 33.4948 0.0659183 34.0811L0.065918 38.5999C0.0659179 39.1862 0.541197 39.6614 1.12748 39.6614L21.6548 39.6615C20.1691 37.8001 19.1901 35.5165 18.9267 33.0196Z" fill="white"/>
<path fill-rule="evenodd" clip-rule="evenodd" d="M21.5507 23.7842V20.6504C21.5507 20.0641 21.0754 19.5889 20.4891 19.5889H17.2637C16.6774 19.5889 16.2021 20.0641 16.2021 20.6504V30.5944C16.2021 31.1807 16.6774 31.656 17.2637 31.656H18.856C18.856 28.6897 19.8617 25.9584 21.5507 23.7842Z" fill="white"/>
<path fill-rule="evenodd" clip-rule="evenodd" d="M43.0332 31.6567C43.0332 37.6286 38.192 42.4697 32.2202 42.4697C26.2484 42.4697 21.4072 37.6286 21.4072 31.6567C21.4072 25.6849 26.2484 20.8438 32.2202 20.8438C38.192 20.8438 43.0332 25.6849 43.0332 31.6567ZM30.9358 27.6751C30.9358 26.9283 31.5412 26.3229 32.288 26.3229C33.0349 26.3229 33.6403 26.9283 33.6403 27.6751V30.2567H36.2209C36.9677 30.2567 37.5731 30.8621 37.5731 31.6089C37.5731 32.3557 36.9677 32.9611 36.2209 32.9611H33.6403V35.5417C33.6403 36.2885 33.0349 36.8939 32.288 36.8939C31.5412 36.8939 30.9358 36.2885 30.9358 35.5417V32.9611H28.3543C27.6075 32.9611 27.0021 32.3557 27.0021 31.6089C27.0021 30.8621 27.6075 30.2567 28.3543 30.2567H30.9358V27.6751Z" fill="#6778E3"/>
<rect x="30.937" y="26.3203" width="2.70443" height="10.571" rx="1.35222" fill="white"/>
<rect x="27.0039" y="32.958" width="2.70443" height="10.571" rx="1.35222" transform="rotate(-90 27.0039 32.958)" fill="white"/>
</svg>

      
  )
}

export default Addbank;