import React from 'react'

type Props = {}

const PromoCodeFunIcon = (props: Props) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="26" height="15" viewBox="0 0 26 15" fill="none">
      <g filter="url(#filter0_b_3691_1993)">
        <path d="M20.8368 10.9223L20.8271 3.35046C20.8275 3.28591 20.8151 3.22191 20.7906 3.16218C20.7661 3.10245 20.73 3.04817 20.6844 3.00249C20.6388 2.95681 20.5846 2.92064 20.5249 2.89607C20.4652 2.87151 20.4012 2.85904 20.3366 2.85938L5.35764 2.88079C5.27126 2.88071 5.18641 2.90347 5.11167 2.94676C5.03693 2.99005 4.97497 3.05234 4.93207 3.1273C4.88894 3.20155 4.86653 3.28601 4.8672 3.37187L4.87564 10.9444C4.8757 11.031 4.89866 11.116 4.94218 11.1909C4.9857 11.2657 5.04823 11.3277 5.12345 11.3706C5.19782 11.4129 5.28183 11.4352 5.36737 11.4355C5.36737 11.4355 19.7113 11.4128 20.3483 11.4128C20.6869 11.3979 20.7181 11.3304 20.7719 11.2584C20.8213 11.1536 20.8437 11.038 20.8368 10.9223ZM12.8523 8.71669L11.0359 9.67809L11.3817 7.64889L9.90712 6.21717L11.9428 5.91681L12.851 4.07119L13.7638 5.91551L15.7982 6.21003L14.3256 7.6463L14.672 9.67485L12.8523 8.71669Z" fill="url(#paint0_linear_3691_1993)" />
        <path d="M25.6297 14.2141L25.6226 8.55729C25.6076 8.55729 25.5934 8.55729 25.5791 8.55729C25.149 8.55772 24.7363 8.38726 24.4318 8.08342C24.1274 7.77957 23.9561 7.36723 23.9557 6.9371C23.9552 6.50698 24.1257 6.09429 24.4295 5.78984C24.7334 5.48539 25.1457 5.31411 25.5759 5.31368C25.5908 5.31368 25.605 5.31368 25.6193 5.31368L25.6148 0.00389217C25.6148 0.00389217 25.6148 0.00389233 25.6148 0L0 0.0330849L0.00713598 5.33444C0.0207591 5.33444 0.0337337 5.33444 0.0473569 5.33444C0.260335 5.33444 0.471228 5.37639 0.667994 5.45789C0.864761 5.53939 1.04355 5.65885 1.19415 5.80945C1.34474 5.96005 1.46421 6.13884 1.54571 6.3356C1.62721 6.53237 1.66916 6.74326 1.66916 6.95624C1.66916 7.16922 1.62721 7.38011 1.54571 7.57688C1.46421 7.77365 1.34474 7.95243 1.19415 8.10303C1.04355 8.25363 0.864761 8.37309 0.667994 8.45459C0.471228 8.5361 0.260335 8.57805 0.0473569 8.57805H0.0084337L0.0168669 14.244C0.0168669 14.244 0.0168669 14.2479 0.0168669 14.2485L25.6252 14.2148L25.6297 14.2141ZM20.3134 12.3653L5.32795 12.388C5.07233 12.3875 4.82126 12.3204 4.59944 12.1934C4.37708 12.0648 4.19239 11.8802 4.06385 11.6578C3.93532 11.4355 3.86744 11.1832 3.86703 10.9264L3.8586 3.35519C3.85783 3.16292 3.89497 2.97238 3.9679 2.79448C4.04083 2.61657 4.14811 2.45479 4.28362 2.31838C4.41912 2.18198 4.58019 2.07362 4.7576 1.99951C4.93502 1.9254 5.1253 1.88699 5.31757 1.88648L20.303 1.86443C20.6912 1.86443 21.0636 2.0186 21.3381 2.29304C21.6127 2.56748 21.767 2.93973 21.7672 3.32794L21.7769 10.8985C21.7864 11.186 21.7192 11.4708 21.5823 11.7237C21.3546 12.1324 20.8616 12.3776 20.3134 12.3653Z" fill="url(#paint1_linear_3691_1993)" />
      </g>
      <defs>
        <filter id="filter0_b_3691_1993" x="-4" y="-4" width="33.6299" height="22.2485" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
          <feFlood flood-opacity="0" result="BackgroundImageFix" />
          <feGaussianBlur in="BackgroundImageFix" stdDeviation="2" />
          <feComposite in2="SourceAlpha" operator="in" result="effect1_backgroundBlur_3691_1993" />
          <feBlend mode="normal" in="SourceGraphic" in2="effect1_backgroundBlur_3691_1993" result="shape" />
        </filter>
        <linearGradient id="paint0_linear_3691_1993" x1="4.86719" y1="3.89442" x2="20.3874" y2="5.56958" gradientUnits="userSpaceOnUse">
          <stop stop-color="#06A6FF" />
          <stop offset="1" stop-color="#0366FD" />
        </linearGradient>
        <linearGradient id="paint1_linear_3691_1993" x1="6.62523e-07" y1="1.71964" x2="24.9258" y2="4.31827" gradientUnits="userSpaceOnUse">
          <stop stop-color="#06A6FF" />
          <stop offset="1" stop-color="#0366FD" />
        </linearGradient>
      </defs>
    </svg>
  )
}

export default PromoCodeFunIcon;