import { useEffect } from 'react'
import { withAppContext } from 'src/store/initAppContext';
import styled, { withTheme } from 'styled-components';
import { LazyLoadImage } from "react-lazy-load-image-component";
import config from "src/config/config";
import PlaceholderImage from 'src/components/Placeholder';
import { theme } from 'styled-tools';
import FooterContainer from 'src/components/footer/footer';

function CaribbeanRoyalBonus(props) {
    const { app: {
        brandId
    }, theme } = props;

    useEffect(() => {
        window.scrollTo(0, 0)
    }, [])

    return (
        <>
            <InformationWrapper>
                <TitleText>
                    {
                        brandId === 31 || brandId === 32 ?            
                        <>
                            <h6>
                                <span>This CPL, walk the red carpet like a cricket superstar only on <strong className='blue'>Yolo<span className='yellow'>247</span></strong></span>
                            </h6>
                            <h6>Lost the last bet? No worries, <strong>Yolo<span>247</span></strong> gives you a chance <br />to play again!</h6>
                        </>      
                      : brandId === 33 || brandId === 34 ?
                         <h6>Lost the last bet? No worries, <strong className='bolder'>BaaziAdda</strong> gives you a chance <br /> to play again!</h6>
                      : 
                      <>
                        <h6>Lost the last bet on CPL? No worries <b>FOMO</b><span className='yellow'>7</span> gives you a chance to <span className='yellow'>play again!</span></h6>
                      </>
                    }
                </TitleText>

                <LoyaltyWrapper>
                    <HeadingText className='titleText'>
                        <h1>Claim your Prize</h1>
                    </HeadingText>
                    <UnderLine></UnderLine>
                    {brandId === 39 || brandId === 40 ? 
                    <p>For cumulative losses of the day, we'll reward you with an <b>100%</b> additional loss back bonus the very next day <br />on <b>Exchange bets</b></p> 
                    : <p>For cumulative losses of the day, we'll reward you with an 100% additional loss back <br /> bonus the very next day.</p>
                    }
                </LoyaltyWrapper>
               
                <BoxContainer>
                    <BoxContent>
                        <LazyLoadImage src={`${config.imageV3Host}/static/cashsite/${theme.image.CRBPromoTeam1}`} effect="none" placeholder={<PlaceholderImage />} />
                        <h1>Barbados Royals</h1>
                    </BoxContent>
                    <BoxContent className='redBg'>
                        <LazyLoadImage src={`${config.imageV3Host}/static/cashsite/${theme.image.CRBPromoTeam2}`} effect="none" placeholder={<PlaceholderImage />} className="imgTwo" />
                        <h1>Antigua & Barbuda Falcons</h1>
                    </BoxContent>
                    <BoxContent className='blueBg'>
                        <LazyLoadImage src={`${config.imageV3Host}/static/cashsite/${theme.image.CRBPromoTeam3}`} effect="none" placeholder={<PlaceholderImage />} className="imgThree" />
                        <h1>Saint Lucia Kings</h1>
                    </BoxContent>
                    <BoxContent className='greenBg'>
                        <LazyLoadImage src={`${config.imageV3Host}/static/cashsite/${theme.image.CRBPromoTeam4}`} effect="none" placeholder={<PlaceholderImage />} />
                        <h1>Guyana Amazon Warriors</h1>
                    </BoxContent>
                    <BoxContent className='yellowBg'>
                        <LazyLoadImage src={`${config.imageV3Host}/static/cashsite/${theme.image.CRBPromoTeam5}`} effect="none" placeholder={<PlaceholderImage />} />
                        <h1>St Kitts & Nevis Patriots</h1>
                    </BoxContent>
                    <BoxContent className='blueBg'>
                        <LazyLoadImage src={`${config.imageV3Host}/static/cashsite/${theme.image.CRBPromoTeam6}`} effect="none" placeholder={<PlaceholderImage />} />
                        <h1>Trinbago Knight Riders</h1>
                    </BoxContent>
                </BoxContainer>
                <h6>
                    For cumulative losses of the day, we'll reward you with an <b>100%</b> additional loss back bonus the very next day on <b>Exchange bets</b>.
                </h6>
                    <Termsandconditions>
                        <TermsandconditionsHeading>
                            <h1>Terms & Conditions</h1>
                        </TermsandconditionsHeading>
                        <UnderLine></UnderLine>
                        <TermsandconditionsBody>
                            <ol>
                                <li>
                                    The loss back bonus will be applied to 'lost <strong>CPL EXCHANGE bets - for the day'</strong>, placed during the 
                                    <strong>{brandId === 33 || brandId === 34 ? 'CPL & WC 2024' : 'CPL\'24'}</strong>.
                                </li>
                                <li>
                                    The bonus amount for each qualifying bet will be capped at <b className='yellow'>INR 50</b>.
                                </li>
                                <li>
                                    The bonus will be credited to your <strong>{brandId === 31 || brandId === 32 ? <><span className='blue'>Yolo</span><span className='yellow'>247</span></> : brandId === 33 ? 'BaaziAdda' : <><strong>FOMO</strong><span className='yellow'>7</span></>}</strong> account the next day.
                                </li>
                                <li>Bonus will expire within <b>24-48</b> hours.</li>
                                <li>There is no limit to the number of bets you can place to earn the <span className='yellow'>100% lossback bonus</span> 'for the day'.</li>
                                <li>
                                    The loss back bonus can be used to place future bets on <strong>{brandId === 31 || brandId === 32 ? <><span className='blue'>Yolo</span><span className='yellow'>247</span></> : brandId === 33 ? 'BaaziAdda' : <><strong>FOMO</strong><span className='yellow'>7</span></>}</strong> as this bonus cannot be withdrawn. Only winnings can be withdrawn.
                                </li>
                                <li>This promotion is valid only for the <strong>CPL</strong>.</li>
                                <li>
                                    <strong>{brandId === 31 || brandId === 32 ? <><span className='blue'>Yolo</span><span className='yellow'>247</span></> : brandId === 33 ? 'BaaziAdda' : <><strong>FOMO</strong><span className='yellow'>7</span></>}</strong> reserves the right to disqualify any user found engaging in fraudulent activities.
                                </li>
                                <li>
                                    <strong>{brandId === 31 || brandId === 32 ? <><span className='blue'>Yolo</span><span className='yellow'>247</span></> : brandId === 33 ? 'BaaziAdda' : <><strong>FOMO</strong><span className='yellow'>7</span></>}</strong> General Terms & Conditions apply.
                                </li>
                            </ol>
                        </TermsandconditionsBody>
                    </Termsandconditions>
            </InformationWrapper>
            <FooterContainer />
        </>
    )
}



export default withTheme(withAppContext(CaribbeanRoyalBonus));

export const InformationWrapper = styled.div`
    width: 98%;
    height: auto;
    padding: 3rem 3rem 8rem 3rem;
    border-radius: 1px;
    background: rgba(111, 111, 111, 0.5);
    margin: 0em 1.2em 0 1.2em;
    border: 1px solid #E5B358;
    border-radius: 0 0 20px 20px;
    border-top-color: transparent;
    backdrop-filter: brightness(0.5);    
    display: flex;
    flex-direction: column;
    background: url(${config.imageV3Host}/static/cashsite/${theme("image.CRBPromoInnerBg")});
    background-position: top;
    background-size: cover;
    background-repeat: no-repeat;

    .yoloTitle {
        margin-top: 0;
        text-align: center;
        font-size: 25px;
        font-style: normal;
        font-weight: 700;
        line-height: 35px; /* 140% */
        text-transform: capitalize;
        background: linear-gradient(90deg, #3CB9FC 31.85%, #3CB9FC 68.07%);
        background-clip: text;
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
    }
`;

const TitleText = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    padding: 1rem 0 4rem 0;

    h6 {
        margin: 0;
        padding: 0;
        color: #FFF;
        font-size: 16px;
        font-style: normal;
        font-weight: 500;
        line-height: 160.3%;
        text-transform: capitalize;
        text-align: center;

        & .bolder {
            color: #fff;
            font-weight: 700;
        }

        & strong {
            color: #3A76D8;
            font-size: 16px;
            font-style: normal;
            font-weight: 800;
            line-height: 160.3%;
            text-transform: capitalize;
            margin: 0px;

            & > span {
                color: #F4C33B;
            }
        }

        & > .yellow {
            color: #FEA734;
            font-size: 16px;
            font-style: normal;
            font-weight: 800;
            line-height: 160.3%;
            text-transform: capitalize;
        }
    }

    .yellow {
        color: #FEBA3B;
        font-weight: 700;
    }

    .white {
        color: #FFF;
        font-weight: 700;
    }
`;

const LoyaltyWrapper = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    backdrop-filter: blur(6.849999904632568px);
    padding: 0rem 3rem 3rem 3rem;
    margin: 0 -2rem;

    .joiningTxt {
        padding-top: 1.5rem;
    }

    .titleText {
        padding: 1rem 0 0.2rem 0;
        h1 {
            margin-top: 0;
        }
    }
    & p {
        color: #FFF;
        text-align: center;
        font-size: 16px;
        font-style: italic;
        font-weight: 500;
        line-height: 160.3%; /* 25.648px */
        margin: 15px 0px 0px 0px;
    }
`;

const HeadingText = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;

    h1 {
        text-align: center;
        font-size: 25px;
        font-style: normal;
        font-weight: 600;
        line-height: normal;
        letter-spacing: 1.25px;
        background: linear-gradient(180deg, #FED546 18.06%, #FE7C23 91.67%);
        background-clip: text;
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
    }

    p {
        color: #FFF;
        text-align: center;
        font-family: Inter;
        font-size: 16px;
        font-style: normal;
        font-weight: 500;
        line-height: 120.3%; /* 19.248px */
    }
`;

export const UnderLine = styled.div`
    height: 2px;
    width: 184px;
    background: radial-gradient(#FFF 0%, rgba(153, 153, 153, 0.00) 80%);
`;

export const Divider = styled.div`
    height: 2px;
    width: 100%;
    background: radial-gradient(#1182C1 0%, #00000000 50%);
`;

const BoxContainer = styled.div`
    display: flex;
    justify-content: center;
    align-items: stretch;
    gap: 3.5rem;
    position: relative;
    flex-wrap: wrap;

    .yellowBg {
        border-radius: 20px;
        border-bottom: 2px solid rgba(253, 205, 0);
        background: linear-gradient(180deg, rgba(176, 176, 176, 0.10) 70%, rgba(253, 205, 0, 0.40) 100%);
        backdrop-filter: blur(12.5px);
    }

    .blueBg {
        border-radius: 20px;
        border-bottom: 2px solid rgba(1, 224, 255);
        background: linear-gradient(180deg, rgba(176, 176, 176, 0.10) 70%, rgba(1, 224, 255, 0.40) 100%);
        backdrop-filter: blur(12.5px);
    }

    .redBg {
        border-radius: 20px;
        border-bottom: 2px solid rgba(255, 80, 108);
        background: linear-gradient(180deg, rgba(176, 176, 176, 0.10) 70%, rgba(255, 80, 108, 0.50) 100%);
        backdrop-filter: blur(12.5px);
    }

    .orangeBg {
        border-radius: 20px;
        border-bottom: 2px solid rgba(254, 110, 0);
        background: linear-gradient(180deg, rgba(176, 176, 176, 0.10) 70%, rgba(254, 110, 0, 0.50) 100%);
        backdrop-filter: blur(12.5px);  
    }

    .greenBg {
        border-radius: 20px;
        border-bottom: 2px solid rgba(0, 169, 78);
        background: linear-gradient(180deg, rgba(176, 176, 176, 0.10) 70%, rgba(0, 169, 78, 0.50) 100%);
        backdrop-filter: blur(12.5px);
    }
`;

const BoxContent = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    width: 20%;
    padding: 3.5rem 1.5rem 1.5rem 1.5rem;
    border-radius: 20px;
    border-bottom: 2px solid rgb(190 72 254);
    background: linear-gradient(180deg, rgba(176, 176, 176, 0.10) 70%, rgba(190, 72, 254, 0.40) 100%);
    backdrop-filter: blur(12.5px);


    h1 {
        color: #FFF;
        text-align: center;
        font-size: 20px;
        font-style: normal;
        font-weight: 700;
        line-height: normal;
        text-transform: capitalize;
    }

    p {
        color: #FFF;
        text-align: center;
        font-size: 18px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
    }

    img {
        width: 7rem;
    }
`;

const Termsandconditions = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    border-radius: 30px;
    width: 60%;
    height: auto;
    margin: 3rem 20% 3rem 20%;
    padding: 5rem 3rem;
    position: relative;
    background: linear-gradient(#020405, rgba(0, 0, 0, 0.935), #020405);
    background-clip: padding-box;
    border: 2px solid transparent;

    ::after {
        position: absolute;
        top: -2px; bottom: -2px;
        left: -2px; right: -2px;
        background: linear-gradient(90deg, #53C1FF, black, #53C1FF, black, #53C1FF);
        content: '';
        z-index: -1;
        border-radius: 30px;
    }
`;

const TermsandconditionsHeading = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;    
    padding-bottom: 2rem;

    h1 {
        margin: 0;
        padding: 0;
        text-align: center;
        font-size: 25px;
        font-style: normal;
        font-weight: 600;
        line-height: normal;
        letter-spacing: 1.25px;
        background: linear-gradient(180deg, #FED546 18.06%, #FE7C23 121.53%);
        background-clip: text;
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
    }
`;

const TermsandconditionsBody = styled.div`
    ol {
        display: flex;
        flex-direction: column;
        justify-content: center;
        gap: 1rem;
        padding-top: 1rem;
    }

    li {
        color: #FFF;
        font-size: 16px;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
    }

    .yellow {
        color: #FEBA3B;
        font-weight: 700;
    }

    .blue {
        color: #407CFF;
        font-weight: 700;
    }
`;
