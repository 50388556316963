import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
// import TimeToggleSection from  '../eu-content/inner-game-details/game-details';
import { ifProp, theme, withProp } from 'styled-tools';

const Clock = (props) => {
    useEffect(() => {
        let isMounted = true;
        return () => { isMounted = false };
    })
    let date = new Date()
    let dateString = new Date(date.getTime() - (date.getTimezoneOffset() * 60000))
        .toISOString()
        .split("T")[0];
    let time = new Date().toLocaleTimeString();
    const [currentTime, setCurrentTime] = useState(time);

    const updateTime = () => {
        let time = new Date().toLocaleTimeString();
        setCurrentTime(time);

    };
    setInterval(updateTime, 1000);
    return (
        <MainWrapper>
            <DateWrapper>{dateString}</DateWrapper>
            <TimeWrapper>{currentTime}</TimeWrapper>
        </MainWrapper>
    )
};
export default Clock;


export const MainWrapper = styled.div`
    font-weight: 400;  
    // color: ${theme('palette.dateTimeColor')};
    color: #fff;
    // position: absolute;
    // width: 100%;
    width: 160px;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    // font-size: 13px;
    font-size: 10px;
    padding-top:5px;
`;

export const DateWrapper = styled.div`
    margin-right: 10px;
`;

const TimeWrapper = styled.div`
`;