import React from 'react'

type Props = {}

const FaqRed = (props: Props) => {
  return (
    <svg width="44" height="44" viewBox="0 0 44 44" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M29.3879 26.7412H37.0959C37.0581 26.6972 36.8125 26.4504 36.1324 25.8156C35.4523 25.1809 35.6349 24.4176 35.8112 24.1152L29.3879 26.7412Z" fill="#B21318"/>
<path d="M38.5555 17.8915C38.5555 22.7882 34.586 26.7577 29.6894 26.7577C24.7928 26.7577 20.8232 22.7882 20.8232 17.8915C20.8232 12.9949 24.7928 9.02539 29.6894 9.02539C34.586 9.02539 38.5555 12.9949 38.5555 17.8915Z" fill="#B21318"/>
<path d="M6.75289 34.9901H14.7498C15.2602 34.1563 16.2775 32.4524 16.263 32.3072C16.2484 32.162 11.2235 30.9763 8.7129 30.4016L7.40616 31.9808C8.24828 32.9245 8.10792 33.5719 7.93248 33.7776L6.75289 34.9901Z" fill="#B21318"/>
<path d="M18.8981 18.6875C17.5149 17.796 15.8677 17.2783 14.0999 17.2783C9.20349 17.2783 5.23389 21.2479 5.23389 26.1443C5.23389 31.0411 9.20349 35.0107 14.0999 35.0107C18.9518 35.0107 22.8937 31.1132 22.9655 26.2781C20.6711 24.4446 19.1385 21.742 18.8981 18.6875Z" fill="#B21318"/>
<rect x="8.96875" y="22.9189" width="9.2529" height="2.36788" rx="1.18394" fill="white"/>
<rect x="8.96875" y="26.998" width="9.2529" height="2.36788" rx="1.18394" fill="white"/>
<path fill-rule="evenodd" clip-rule="evenodd" d="M18.8696 2.83663L17.817 0.749453L17.8168 0.749023H26.0111L24.9313 2.83663H18.8696ZM17.0364 3.63521C16.6733 2.90913 16.0261 1.62266 15.5861 0.749023H10.6035C9.92366 0.749023 9.37256 1.30012 9.37256 1.97993V16.1346C10.698 15.5977 12.1521 15.3013 13.6773 15.3013C15.578 15.3013 17.3682 15.7616 18.9367 16.5739C19.5833 11.1468 24.3225 6.93382 30.0728 6.93382C31.6019 6.93382 33.0595 7.23172 34.3877 7.77115V1.97993C34.3877 1.30012 33.8366 0.749023 33.1568 0.749023H28.1932L26.8188 3.63521C26.607 4.05869 25.9331 4.90565 24.9313 4.90565C23.9294 4.90565 20.4727 4.84516 18.8696 4.81491C18.446 4.79676 17.4865 4.53541 17.0364 3.63521ZM9.37256 37.1219V42.2319C9.37256 42.9117 9.92365 43.4628 10.6035 43.4628H33.1568C33.8366 43.4628 34.3877 42.9117 34.3877 42.2319V28.7544H30.1293L30.0728 28.7545L30.0163 28.7544H28.6463V28.6671C27.2761 28.4977 25.9831 28.0877 24.8134 27.482C24.2211 32.453 20.1952 36.4054 15.1038 37.0346V37.1219H13.7341L13.6773 37.1221L13.6204 37.1219H9.37256ZM19.5271 40.3948C19.0648 40.3948 18.69 40.7696 18.69 41.2319C18.69 41.6942 19.0648 42.0689 19.5271 42.0689H24.2351C24.6974 42.0689 25.0722 41.6942 25.0722 41.2319C25.0722 40.7696 24.6974 40.3948 24.2351 40.3948H19.5271Z" fill="white"/>
<path d="M28.3298 19.3526V19.1355C28.3298 18.6378 28.369 18.2412 28.4474 17.9456C28.5258 17.6501 28.6419 17.4133 28.7957 17.2354C28.9496 17.0544 29.1381 16.8915 29.3612 16.7468C29.5543 16.6201 29.7262 16.4979 29.877 16.3803C30.0308 16.2627 30.1515 16.1375 30.2389 16.0048C30.3294 15.8721 30.3746 15.7213 30.3746 15.5524C30.3746 15.4016 30.3384 15.2689 30.2661 15.1543C30.1937 15.0397 30.0957 14.9507 29.972 14.8874C29.8483 14.824 29.7111 14.7924 29.5603 14.7924C29.3974 14.7924 29.2466 14.8301 29.1079 14.9055C28.9722 14.9809 28.8621 15.0849 28.7776 15.2176C28.6962 15.3503 28.6555 15.5041 28.6555 15.6791H26.3392C26.3452 15.0155 26.496 14.4772 26.7916 14.064C27.0871 13.6478 27.4792 13.3431 27.9678 13.1501C28.4564 12.9541 28.9933 12.856 29.5784 12.856C30.2238 12.856 30.7999 12.9511 31.3066 13.1411C31.8133 13.3281 32.2129 13.6116 32.5055 13.9916C32.798 14.3686 32.9443 14.8406 32.9443 15.4076C32.9443 15.7726 32.881 16.0938 32.7543 16.3713C32.6307 16.6457 32.4572 16.8885 32.234 17.0996C32.0139 17.3077 31.756 17.4978 31.4604 17.6697C31.2433 17.7963 31.0608 17.9275 30.913 18.0633C30.7652 18.196 30.6536 18.3483 30.5782 18.5202C30.5028 18.6891 30.4651 18.8942 30.4651 19.1355V19.3526H28.3298ZM29.4336 22.3928C29.0838 22.3928 28.7837 22.2707 28.5333 22.0264C28.286 21.779 28.1639 21.479 28.1669 21.1261C28.1639 20.7822 28.286 20.4882 28.5333 20.2439C28.7837 19.9996 29.0838 19.8774 29.4336 19.8774C29.7654 19.8774 30.058 19.9996 30.3113 20.2439C30.5677 20.4882 30.6974 20.7822 30.7004 21.1261C30.6974 21.3613 30.6355 21.5755 30.5149 21.7685C30.3973 21.9585 30.2434 22.1108 30.0534 22.2254C29.8634 22.337 29.6568 22.3928 29.4336 22.3928Z" fill="white"/>
</svg>

  )
}

export const FaqOrange = (props: Props) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="55" height="55" viewBox="0 0 55 55" fill="none">
      <path d="M36.7551 33.2531H46.4925C46.4448 33.1975 46.1344 32.8857 45.2753 32.0839C44.4161 31.282 44.6468 30.3177 44.8696 29.9358L36.7551 33.2531Z" fill="url(#paint0_linear_2022_17290)" />
      <path d="M48.3364 22.0735C48.3364 28.2593 43.3218 33.2739 37.136 33.2739C30.9502 33.2739 25.9355 28.2593 25.9355 22.0735C25.9355 15.8877 30.9502 10.873 37.136 10.873C43.3218 10.873 48.3364 15.8877 48.3364 22.0735Z" fill="url(#paint1_linear_2022_17290)" />
      <path d="M8.16112 43.6738H18.2635C18.9083 42.6205 20.1934 40.468 20.175 40.2846C20.1567 40.1012 13.8088 38.6033 10.6372 37.8772L8.98637 39.8721C10.0502 41.0644 9.87291 41.8822 9.65127 42.1421L8.16112 43.6738Z" fill="url(#paint2_linear_2022_17290)" />
      <path d="M23.5039 23.0791C21.7566 21.9528 19.6757 21.2988 17.4424 21.2988C11.2569 21.2988 6.24219 26.3136 6.24219 32.4991C6.24219 38.6851 11.2569 43.6998 17.4424 43.6998C23.5718 43.6998 28.5516 38.7762 28.6422 32.6681C25.7438 30.3519 23.8076 26.9377 23.5039 23.0791Z" fill="url(#paint3_linear_2022_17290)" />
      <rect x="10.9609" y="28.4248" width="11.689" height="2.9913" rx="1.49565" fill="white" />
      <rect x="10.9609" y="33.5781" width="11.689" height="2.9913" rx="1.49565" fill="white" />
      <path fill-rule="evenodd" clip-rule="evenodd" d="M23.4667 3.05524L22.137 0.418545L22.1367 0.417969H32.4885L31.1243 3.05524H23.4667ZM21.1509 4.06407C20.6922 3.14681 19.8746 1.52161 19.3188 0.417969H13.0257C12.1669 0.417969 11.4707 1.11416 11.4707 1.97295V19.8535C13.1447 19.1757 14.9811 18.8015 16.9073 18.8015C19.3094 18.8015 21.5718 19.3835 23.5538 20.4104C24.37 13.5536 30.3572 8.23071 37.622 8.23071C39.5533 8.23071 41.3943 8.6069 43.0718 9.28808V1.97295C43.0718 1.11416 42.3757 0.417969 41.5169 0.417969H35.2451L33.5088 4.06407C33.2413 4.59904 32.3899 5.66899 31.1243 5.66899C29.8587 5.66899 25.4919 5.59257 23.4667 5.55436C22.9317 5.53143 21.7195 5.20127 21.1509 4.06407ZM11.4707 46.3671V52.8225C11.4707 53.6813 12.1669 54.3774 13.0257 54.3774H41.5169C42.3757 54.3774 43.0718 53.6813 43.0718 52.8225V35.7963H37.6926L37.622 35.7964L37.5513 35.7963H35.8199V35.6859C34.088 35.4719 32.4538 34.9535 30.9755 34.1876C30.2279 40.4681 25.1418 45.4618 18.7094 46.2567V46.3671H16.9788L16.9073 46.3672L16.8359 46.3671H11.4707ZM24.2977 50.5017C23.7137 50.5017 23.2402 50.9752 23.2402 51.5592C23.2402 52.1432 23.7137 52.6167 24.2977 52.6167H30.2452C30.8293 52.6167 31.3027 52.1432 31.3027 51.5592C31.3027 50.9752 30.8293 50.5017 30.2452 50.5017H24.2977Z" fill="white" />
      <path d="M35.4196 23.7602V23.4859C35.4196 22.8572 35.4691 22.3562 35.5682 21.9828C35.6673 21.6094 35.814 21.3103 36.0083 21.0855C36.2026 20.8569 36.4407 20.6512 36.7227 20.4683C36.9665 20.3083 37.1837 20.154 37.3742 20.0054C37.5685 19.8568 37.7209 19.6986 37.8314 19.531C37.9457 19.3634 38.0029 19.1729 38.0029 18.9595C38.0029 18.769 37.9572 18.6013 37.8657 18.4565C37.7743 18.3118 37.6504 18.1994 37.4942 18.1194C37.338 18.0393 37.1647 17.9993 36.9741 17.9993C36.7684 17.9993 36.5779 18.047 36.4026 18.1422C36.2312 18.2375 36.0921 18.3689 35.9854 18.5366C35.8825 18.7042 35.8311 18.8985 35.8311 19.1195H32.9049C32.9125 18.2813 33.1031 17.6012 33.4764 17.0792C33.8498 16.5534 34.3452 16.1686 34.9624 15.9247C35.5796 15.6771 36.2578 15.5532 36.997 15.5532C37.8124 15.5532 38.5401 15.6732 39.1802 15.9133C39.8203 16.1495 40.3252 16.5077 40.6947 16.9877C41.0643 17.464 41.2491 18.0603 41.2491 18.7766C41.2491 19.2376 41.1691 19.6434 41.0091 19.9939C40.8529 20.3407 40.6338 20.6474 40.3518 20.9141C40.0737 21.177 39.7479 21.417 39.3745 21.6342C39.1002 21.7942 38.8697 21.96 38.683 22.1314C38.4963 22.2991 38.3553 22.4915 38.2601 22.7086C38.1648 22.922 38.1172 23.1811 38.1172 23.4859V23.7602H35.4196ZM36.8141 27.6009C36.3721 27.6009 35.993 27.4465 35.6768 27.1379C35.3644 26.8255 35.2101 26.4464 35.2139 26.0006C35.2101 25.5662 35.3644 25.1948 35.6768 24.8861C35.993 24.5775 36.3721 24.4232 36.8141 24.4232C37.2332 24.4232 37.6028 24.5775 37.9229 24.8861C38.2467 25.1948 38.4106 25.5662 38.4144 26.0006C38.4106 26.2978 38.3325 26.5683 38.1801 26.8122C38.0315 27.0522 37.8371 27.2446 37.5971 27.3894C37.3571 27.5304 37.0961 27.6009 36.8141 27.6009Z" fill="white" />
      <defs>
        <linearGradient id="paint0_linear_2022_17290" x1="37.136" y1="10.873" x2="37.136" y2="33.2739" gradientUnits="userSpaceOnUse">
          <stop stop-color="#FF9B63" />
          <stop offset="1" stop-color="#D24D02" />
        </linearGradient>
        <linearGradient id="paint1_linear_2022_17290" x1="37.136" y1="10.873" x2="37.136" y2="33.2739" gradientUnits="userSpaceOnUse">
          <stop stop-color="#FF9B63" />
          <stop offset="1" stop-color="#D24D02" />
        </linearGradient>
        <linearGradient id="paint2_linear_2022_17290" x1="17.4422" y1="21.2988" x2="17.4422" y2="43.6998" gradientUnits="userSpaceOnUse">
          <stop stop-color="#FF9B63" />
          <stop offset="1" stop-color="#D14C01" />
        </linearGradient>
        <linearGradient id="paint3_linear_2022_17290" x1="17.4422" y1="21.2988" x2="17.4422" y2="43.6998" gradientUnits="userSpaceOnUse">
          <stop stop-color="#FF9B63" />
          <stop offset="1" stop-color="#D14C01" />
        </linearGradient>
      </defs>
    </svg>

  )
}

export default FaqRed