import React from 'react'
import { Accordion, AccordionWrapper } from '../seo-content/seo_content'
import { withTheme } from 'styled-components'
import { withAppContext } from 'src/store/initAppContext'
import EvolutionSeoFun88 from './EvolutionSeoFun88'
import EvolutionSEOYolo from './EvolutionSEOYolo'

type Props = {
    app:any;
}

const EvolutionSEO = (props: Props) => {
    const { app :{brandId}}=props;
    const domainName = (brandId === 31 || brandId === 32) ? 'Yolo247' : brandId === 33 ? 'IW247' : (brandId === 39 || brandId === 40) ? 'FOMO7': (brandId === 46 || brandId === 47) ? 'FUN88':  'Betdaily' ;
    
    return (
        <>
        {
            (brandId === 46 || brandId == 33) ? <></> :
            (brandId === 47) ? <EvolutionSeoFun88 /> : 
            <EvolutionSEOYolo />
        }
        </>
    )
}

export default withTheme(withAppContext(EvolutionSEO));