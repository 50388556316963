import React, { useEffect } from 'react'
import { withAppContext } from 'src/store/initAppContext';
import styled, { withTheme } from 'styled-components';
import { LazyLoadImage } from "react-lazy-load-image-component";
import config from "src/config/config";
import PlaceholderImage from 'src/components/Placeholder';
import { theme } from 'styled-tools';
import { BonusInfoDesc, BorderSec } from 'src/components/Bonus';

function FomoWelcomeBonus(props) {
    const { app: {
        brandId
    }, theme } = props;

    useEffect(() => {
        window.scrollTo(0, 0)
    }, [])


    return (
        <InformationWrapper>
            <TitleText>
                <h6>Welcome to FOMO7, India’s leading online betting platform! You are now eligible for a whopping</h6>
                <h6>700% Welcome bonus on your first deposit amount.</h6>
            </TitleText>
            <Divider></Divider>
            <LoyaltyWrapper>
                <HeadingText className='titleText'>
                    <h1>Online Betting Welcome Bonus </h1>
                    <h1>on FOMO7</h1>
                </HeadingText>
                <UnderLine></UnderLine>
                <HeadingText className="joiningTxt">
                    <p>Let’s understand India's best Welcome bonus for online betting.</p>
                </HeadingText>
                <BenifitsWrapper>
                    <BenifitsContent>
                        <LazyLoadImage
                            src={`${config.imageV3Host}/static/cashsite/${theme.image.fomoUsersIcon}`}
                            alt="jetxPromoStepThree"
                            effect="none"
                            placeholder={<PlaceholderImage />}
                        />
                        <BenifitText>
                            <p>It is available for all users</p>
                        </BenifitText>
                    </BenifitsContent>
                    <BenifitsContent>
                        <LazyLoadImage
                            src={`${config.imageV3Host}/static/cashsite/${theme.image.fomoAvailIcon}`}
                            alt="jetxPromoStepThree"
                            effect="none"
                            placeholder={<PlaceholderImage />}
                        />
                        <BenifitText>
                            <p>You can avail it only once.</p>
                        </BenifitText>
                    </BenifitsContent>
                    <BenifitsContent>
                        <LazyLoadImage
                            src={`${config.imageV3Host}/static/cashsite/${theme.image.fomoWalletIcon}`}
                            alt="jetxPromoStepThree"
                            effect="none"
                            placeholder={<PlaceholderImage />}
                        />
                        <BenifitText>
                            <p>You will receive it directly</p>
                            <p>in your wallet</p>
                        </BenifitText>
                    </BenifitsContent>
                </BenifitsWrapper>
            </LoyaltyWrapper>
            <JoiningWrapper>
                <JoiningContainer>
                    <JoiningContent>
                        <p>If you deposit within 30 minutes of registration, you will get a <span>700% </span>Welcome bonus. </p>
                    </JoiningContent>
                    <JoiningContent>
                        <p>If you deposit after 30 minutes of registration, you will get a <span>500% </span> Welcome bonus. </p>
                    </JoiningContent>
                </JoiningContainer>
                <RewardText>
                    <p>The clock is ticking! Don’t miss out on the perks. Deposit </p>
                    <p>now & grab the maximum reward.</p>
                </RewardText>
                <HeadingText>
                    <h1>How to get FOMO7 Welcome Bonus?</h1>
                </HeadingText>
                <UnderLine></UnderLine>
                <StepsWrapper>
                    <LazyLoadImage
                        src={`${config.imageV3Host}/static/cashsite/${theme.image.JoiningBonusSteps}`}
                        alt="jetxPromoStepThree"
                        effect="none"
                        placeholder={<PlaceholderImage />}
                    />
                </StepsWrapper>
            </JoiningWrapper>
            <ROWrapper>
                <LazyLoadImage
                    src={`${config.imageV3Host}/static/cashsite/${theme.image.RoReqImgFomoDesk}`}
                    alt="jetxPromoStepThree"
                    effect="none"
                    placeholder={<PlaceholderImage />}
                />
            </ROWrapper>
            <FormulaWrapper>
                <FormulaHeadingText>
                    <h1>Formula to Calculate FOMO7 Welcome</h1>
                    <h1>Bonus Roll Over</h1>
                </FormulaHeadingText>
                <UnderLine></UnderLine>
                <FormulaContent>
                    <BonusInfoDesc>
                        <PLWrap>
                            <PlLeft>
                                | PROFIT & LOSS |
                                <BorderSec></BorderSec>
                                TOTAL STAKE
                            </PlLeft>
                            <PlRight>
                                ≥ 70%
                            </PlRight>
                        </PLWrap>
                    </BonusInfoDesc>
                    <p>The ratio of the absolute value of your Profit and Loss to the total stake must be greater than or equal to 70%.</p>
                    <p>Here is an example:</p>
                    <p>You bet Rs.100 on any casino game or sports betting.</p>
                    <ul>
                        <li>The odds should be 1.70 or above.</li>
                        <li>If it is less than 1.70, we will not consider it for RO requirement.</li>
                    </ul>
                    <p>Why wait any longer? Deposit now and get your Welcome bonus.</p>
                </FormulaContent>
            </FormulaWrapper>
            <Termsandconditions>
                <TermsandconditionsHeading>
                    <h1>Terms & Conditions for FOMO7</h1>
                    <h1>Welcome Bonus</h1>
                </TermsandconditionsHeading>
                <UnderLine></UnderLine>
                <TermsandconditionsBody>
                    <ol>
                        <li>Only one bonus is allowed per customer, family, address, shared computer, shared IP address, and any identical account details, including e-mail address, bank account details, credit card information and payment system account. Any misuse of this bonus offer will lead to an account being closed.</li>
                        <li>This promotion is valid only for all newly registered players for the first successful deposit.</li>
                        <li>After completing the rollover requirements, you will receive the bonus in your main wallet.</li>
                        <li>This promotion is only available once per player.</li>
                        <li>The Welcome bonus is capped at a maximum of 27,000 INR.</li>
                        <li>The bonus amount must be rolled over 47 times.</li>
                        <li>You must meet the rollover requirements within seven days after your first deposit. The bonus will expire if the rollover requirement is not completed within seven days.</li>
                        <li>We calculate the valid Rollover by "market" for sports or by "round" for casinos. So, you could place multiple bets in 1 market in sports or multiple bets in 1 round of casino. However, the total Profit and Loss to the total stake ratio must be greater than or equal to 70%. Please note that in exchange, match odds provided by Betfair and multiple bookmakers will be considered as one market.</li>
                        <li>Void/canceled/draw and cashed-out bets do not count towards the deposit or bonus turnover requirements.</li>
                        <li>FOMO7 does not consider multiple accounts for this promotion. All players will be subject to duplicate checking. FOMO7 reserves the right to disqualify players if collusion or foul play occurs in the promotion.</li>
                        <li>This promotion cannot be used in conjunction with or to contribute to the eligibility for any other promotion.</li>
                        <li>FOMO7 reserves the right to amend, cancel, reclaim or refuse any promotion at its discretion.</li>
                        <li>You cannot withdraw the bonus amount, but the winnings from the bonus are withdrawable.</li>
                        <li>If any fraudulent activity of the player(s) has been identified, FOMO7 reserves the right to withhold your funds and reject your withdrawals.</li>
                        <li>FOMO7 general terms & conditions apply.</li>
                        <li>Rollover is not valid for any of the P2P games.</li>
                    </ol>
                </TermsandconditionsBody>
            </Termsandconditions>
        </InformationWrapper>
    )
}



export default withTheme(withAppContext(FomoWelcomeBonus));

export const InformationWrapper = styled.div`
    width: 98%;
    height: auto;
    padding: 3rem 3rem 8rem 3rem;
    border-radius: 1px;
    background: rgba(111, 111, 111, 0.5);
    margin: 0em 1.2em 1.2em 1.2em;
    border: 1px solid #E5B358;
    border-radius: 0 0 20px 20px;
    border-top-color: transparent;
    backdrop-filter: brightness(0.5);    
    display: flex;
    flex-direction: column;
    background: url(${config.imageV3Host}/static/cashsite/${theme("image.fomoWelconeBonusBgDesktop")});
    background-position: top;
    background-size: cover;
    background-repeat: no-repeat;
`;

const TitleText = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    padding: 1rem 0 4rem 0;

    h6 {
        margin: 0;
        padding: 0;
        color: #FFF;
        text-align: center;
        font-size: 16px;
        font-style: normal;
        font-weight: 600;
        line-height: 160.3%; /* 25.648px */
    }
`;

const LoyaltyWrapper = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background: radial-gradient(37.24% 47.13% at 50% 0%, #051829 0%, rgba(1, 4, 6, 0.00) 100%);
    backdrop-filter: blur(6.849999904632568px);
    padding: 3rem;
    margin: 0 -2rem;

    .joiningTxt {
        padding-top: 1.5rem;
    }

    .titleText {
        padding: 1rem 0 0.2rem 0;
        h1 {
            margin: 0;
        }
    }
`;

const HeadingText = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;

    h1 {
        text-align: center;
        font-size: 25px;
        font-style: normal;
        font-weight: 600;
        line-height: normal;
        letter-spacing: 1.25px;
        background: linear-gradient(180deg, #FED546 18.06%, #FE7C23 91.67%);
        background-clip: text;
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
    }

    p {
        color: #FFF;
        text-align: center;
        font-family: Inter;
        font-size: 16px;
        font-style: normal;
        font-weight: 500;
        line-height: 120.3%; /* 19.248px */
    }
`;

export const UnderLine = styled.div`
    height: 2px;
    width: 184px;
    background: radial-gradient(#FFF 0%, rgba(153, 153, 153, 0.00) 80%);
`;

export const Divider = styled.div`
    height: 2px;
    width: 100%;
    background: radial-gradient(#1182C1 0%, #00000000 50%);
`;

const BenifitsWrapper = styled.div`
    display: flex;
    justify-content: center;
    align-items: flex-start;
    gap: 9rem;
    padding-top: 1rem;
`;

const BenifitsContent = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    text-align: center;

    img {
        height: 100%;
        width: 49%;
    }
`;

const BenifitText = styled.div`
    display: flex;
    flex-direction: column;
    padding-top: 1.2rem;


    p {
        margin: 0;
        padding: 0;
        color: #FFF;
        text-align: center;
        font-size: 16px;
        font-style: normal;
        font-weight: 500;
        line-height: 120.3%; /* 19.248px */
    }
`;

const JoiningWrapper = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    background: url(${config.imageV3Host}/static/cashsite/${theme("image.fomoJoiningBonusBgDesktop")});
    background-position: top;
    background-size: cover;
    background-repeat: no-repeat;
    z-index: -999;
    margin: 0 -3rem;
`;

const JoiningContainer = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    gap: 3rem;
    padding-top: 2rem;
`;

const JoiningContent = styled.div`
    padding: 2rem 3rem;
    width: 23%;
    border-radius: 20px;
    background: radial-gradient(71.58% 74.35% at 50.18% -11.77%, #112333 0%, #010406 100%);
    position: relative;

    ::after {
        position: absolute;
        top: -1px; bottom: 0px;
        left: -1px; right: -1px;
        background: linear-gradient(180deg, #666, transparent);
        content: '';
        z-index: -1;
        border-radius: 20px;
    }

    p {
        margin: 0;
        color: #FFF;
        text-align: center;
        font-size: 16px;
        font-style: normal;
        font-weight: 500;
        line-height: 120.3%; /* 19.248px */
    }

    span {
        color: #FEBA3B;
    }
`;

const RewardText = styled(HeadingText)`
    padding: 3rem 0 8rem 0;

    p {
        margin: 0;
    }
`;

const StepsWrapper = styled(JoiningContainer)`
    padding: 3rem 0;
    justify-content: center;
    align-items: center;
    text-align: center;
    position: relative;
    
    img {
        width: 80%;
    }

    ::before {
        content: "";
        background: url(${config.imageV3Host}/static/cashsite/${theme("image.fomoWelocomeROBefore")});
        width: 247px;
        height: 448px;
        background-position: top;
        background-size: contain;
        background-repeat: no-repeat;
        position: absolute;
        left: 0%;
        bottom: -56%;
        z-index: -999;
    }
`;

const ROWrapper = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    position: relative;
    img {
        width: 90%;
    }
`;


const FormulaWrapper = styled(JoiningWrapper)`
    justify-content: flex-start;
    align-items: flex-start;
    background: url(${config.imageV3Host}/static/cashsite/${theme("image.formulaRoBgDesktop")});
    background-position: top;
    background-size: cover;
    background-repeat: no-repeat;
    text-align: left;
    padding: 6rem 0 6rem 10rem;
`;

const FormulaHeadingText = styled(HeadingText)`
    align-items: flex-start;
    padding-bottom: 1.5rem;
    h1 {
        margin: 0;
    }
`;

const PLWrap = styled.div`
   display: flex;
   gap: 10px;
   align-items: center;
`
const PlLeft = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
`
const PlRight = styled.div`
    color: #FFF;
    font-size: 27.213px;
    font-style: normal;
    font-weight: 700;
    line-height: 120.3%; /* 32.737px */
`

const FormulaContent = styled(BenifitText)`
    padding-top: 2.2rem;
    width: 37%;
    text-align: left;

    p {
        margin: 0;
        padding: 0.5rem 0;
        text-align: left;
    }
    ul {
        margin: 0 0 0 1rem;
        padding: 0;
        color: white;
    }
    li {
        padding: 0.5rem 0;
    }
`;

const Termsandconditions = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    border-radius: 30px;
    width: 70%;
    height: auto;
    margin: 1rem 10% 3rem 20%;
    padding: 5rem 3rem;
    position: relative;
    background: linear-gradient(#020405, rgba(0, 0, 0, 0.935), #020405);
    background-clip: padding-box;
    border: 2px solid transparent;

    ::after {
        position: absolute;
        top: -2px; bottom: -2px;
        left: -2px; right: -2px;
        background: linear-gradient(90deg, #53C1FF, black, #53C1FF, black, #53C1FF);
        content: '';
        z-index: -1;
        border-radius: 30px;
    }

    ::before {
        content: "";
        background: url(${config.imageV3Host}/static/cashsite/${theme("image.fomoWelocomeTermsBefore")});
        width: 279px;
        height: 288px;
        background-position: top;
        background-size: contain;
        background-repeat: no-repeat;
        position: absolute;
        left: -13%;
        bottom: -9%;
        z-index: 1;
    }
`;

const TermsandconditionsHeading = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;    
    background: linear-gradient(180deg, #FED546 18.06%, #FE7C23 121.53%);
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    padding-bottom: 2rem;

    h1 {
        margin: 0;
        padding: 0;
        text-align: center;
        font-size: 25px;
        font-style: normal;
        font-weight: 600;
        line-height: normal;
        letter-spacing: 1.25px;
    }
`;

const TermsandconditionsBody = styled.div`
    ol {
        display: flex;
        flex-direction: column;
        justify-content: center;
        gap: 1rem;
        padding-top: 1rem;
    }
    li {
        color: #FFF;
        font-size: 16px;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
    }
`;