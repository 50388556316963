import React from 'react'

type Props = {
    type?: 'Up' | 'down'
    downColor?: string
    upColor?:string 
}

export default function GraphIcon({ type,downColor='#828798', upColor='#fff'}: Props) {
 
    if (type === 'down') {
        return (
            <svg width="17" height="14" viewBox="0 0 17 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M0.17 12.0123H16.83C16.9235 12.0123 17 12.0888 17 12.1823V13.3723C17 13.4658 16.9235 13.5423 16.83 13.5423H0.17C0.0764999 13.5423 0 13.4658 0 13.3723V12.1823C0 12.0888 0.0764999 12.0123 0.17 12.0123ZM15.8589 9.35184L15.0153 10.1912C14.9494 10.2571 14.841 10.2571 14.7751 10.1912L9.78775 5.22084L7.71375 7.28846C7.58597 7.41506 7.41337 7.48609 7.2335 7.48609C7.05363 7.48609 6.88103 7.41506 6.75325 7.28846L1.139 1.69546C1.07312 1.62959 1.07312 1.52121 1.139 1.45534L1.98263 0.613838C2.01458 0.582201 2.05772 0.564453 2.10269 0.564453C2.14765 0.564453 2.1908 0.582201 2.22275 0.613838L7.23137 5.60759L9.3075 3.53996C9.43528 3.41336 9.60788 3.34234 9.78775 3.34234C9.96762 3.34234 10.1402 3.41336 10.268 3.53996L15.8589 9.11171C15.8748 9.12741 15.8874 9.1461 15.896 9.16671C15.9046 9.18732 15.9091 9.20944 15.9091 9.23178C15.9091 9.25411 15.9046 9.27623 15.896 9.29684C15.8874 9.31745 15.8748 9.33614 15.8589 9.35184Z" fill={downColor} />
            </svg>


        )

    }
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="17" height="14" viewBox="0 0 17 14" fill="none">
            <path d="M16.83 12.0123H0.17C0.0765 12.0123 0 12.0888 0 12.1823V13.3723C0 13.4658 0.0765 13.5423 0.17 13.5423H16.83C16.9235 13.5423 17 13.4658 17 13.3723V12.1823C17 12.0888 16.9235 12.0123 16.83 12.0123ZM1.14112 9.35184L1.98475 10.1912C2.05062 10.2571 2.159 10.2571 2.22487 10.1912L7.21225 5.22084L9.28625 7.28846C9.41403 7.41506 9.58663 7.48609 9.7665 7.48609C9.94637 7.48609 10.119 7.41506 10.2468 7.28846L15.861 1.69546C15.9269 1.62959 15.9269 1.52121 15.861 1.45534L15.0174 0.613838C14.9854 0.582201 14.9423 0.564453 14.8973 0.564453C14.8523 0.564453 14.8092 0.582201 14.7773 0.613838L9.76863 5.60759L7.6925 3.53996C7.56472 3.41336 7.39212 3.34234 7.21225 3.34234C7.03238 3.34234 6.85978 3.41336 6.732 3.53996L1.14112 9.11171C1.12523 9.12741 1.11261 9.1461 1.104 9.16671C1.09538 9.18732 1.09095 9.20944 1.09095 9.23178C1.09095 9.25411 1.09538 9.27623 1.104 9.29684C1.11261 9.31745 1.12523 9.33614 1.14112 9.35184Z" fill={upColor} />
        </svg>

    )

}