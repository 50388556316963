import React from 'react'
import styled, { withTheme } from "styled-components";
import { Link } from 'react-router-dom'
import QuickLinkIcons from "../icon/QuikLinkIcons";
import { SvgIcon } from "src/components/udb/commonStyle"
import Intl from "src/components/common/Intl";
import { ifProp, theme, withProp } from 'styled-tools';

type ReusableCmpProps = {
  title: string;
  imageName: string;
  count?: number | string;
  url: string;
  authKey?:string;

}
type QuickLinksProps = {
  heading: string;
  data: ReusableCmpProps[],
  theme?:any
}

const ReusableCmp = ({ title, imageName, count, url }: ReusableCmpProps) => {
  return (
    <ReusableWrap>
      <InProcess>
        <Link to={url}>
          <QuickLinkIcons iconName={imageName} />
          {count ? <Para>{count}</Para> : <></>}
        </Link>
      </InProcess>
      <Title><Intl langKey={title} /></Title>
    </ReusableWrap>
  );
};

const QuickLinks = ({ heading, data, theme }: QuickLinksProps) => {
  const themeConfig = theme.config;

  return (
    <Boxed>
      <QuickLink><Intl langKey={heading} /></QuickLink>
      <IconWrapper>
        {data.map((elm, i) => {
          const authKey = elm.authKey;
          const toShow = authKey && themeConfig.hasOwnProperty(authKey) ? themeConfig[authKey] : true;
          return toShow && <ReusableCmp key={i} {...elm} />
        })}
      </IconWrapper>
    </Boxed>
  )
}

export default withTheme(QuickLinks);

export const Boxed = styled.div`
  /* background: radial-gradient(
    75.89% 50% at 50% 50%,
    rgba(14, 11, 77, 0.49) 0%,
    rgba(28, 22, 83, 0.21) 100%
  ); */
  /* background: rgb(0 0 0 / 54%); */
  background: ${theme('palette.profileQuickLinksBg')};
  /* box-shadow: 0px 0px 30px rgba(13, 7, 77, 0.25); */
  border-radius: 13px;
  margin-bottom: 20px;
  /* border: 1px solid white; */
  border: ${theme('palette.profileQuickLinksBorderBg')};
  width: 100%;
`;

export const lbel = styled.label`
  /* font-family: "Roboto"; */
  font-style: normal;
  font-weight: 600;
  font-size: 13px;
  line-height: 16px;
  display: flex;
  align-items: center;
  letter-spacing: 0.03em;
  text-transform: capitalize;
`;

export const QuickLink = styled.div`
  padding: 16px 15px;
  /* font-family: "Roboto"; */
  font-style: normal;
  font-weight: 600;
  font-size: 13px;
  display: flex;
  align-items: center;
  letter-spacing: 0.03em;
  text-transform: capitalize;

  color: ${theme('palette.nametext')};
  /* background: #35353591; */
  background: ${theme('palette.profileQuickLinkTitleBg')};
  /* background: ${theme('palette.headerBgColor')}; */
  /* box-shadow: 0px 0px 50px rgba(152, 158, 213, 0.4); */
  border-radius: 13px 13px 0px 0px;
  backdrop-filter: contrast(0.5);
`;

export const IconWrapper = styled.div`
  display: flex;
  justify-content: space-around;
  padding: 19px 8px 8px;
  width: 100%;
  flex-wrap: wrap;

`;

export const InProcess = styled.div`
  border: 1px solid #ffffff;
  box-shadow: 0px 0px 50px rgba(152, 158, 213, 0.4);
  border-radius: 11px;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  background: ${theme('palette.QuickLinksInProcessIconBG')};

  a{
    display: flex;
    padding: 12px 14px;
  }

  &:hover {
  filter: brightness(50%);
  cursor: pointer;
  background: ${theme('palette.UdbSideBarIconBGHover')};
}
`


export const Title = styled.h5`
  margin: 6px 0px;
  text-align: center;
  /* font-family: "Roboto"; */
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  display: flex;
  align-items: center;
  letter-spacing: 0.03em;
  text-transform: capitalize;
  color:${theme('palette.nametext')};
`;

export const Para = styled.p`
  position: absolute;
  background-color: red;
  border-radius: 50px;
  right: 11px;
  width: 12px;
  font-size: 9px;
  text-align: center;
  color: wheat;
`;

export const ReusableWrap = styled.div`
  width: 50%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding-block-end: 10px;
`;
