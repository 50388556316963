import React from 'react'
import { Accordion, AccordionWrapper } from '../seo-content/seo_content'
import { withTheme } from 'styled-components'
import { withAppContext } from 'src/store/initAppContext'

type Props = {app:any}


const Mojos7 = (props: Props) => {
const { app: { brandId } } = props;

    const domainName = (brandId === 31 || brandId === 32) ? 'Yolo247' : brandId === 33 ? 'iw247' : (brandId === 39 || brandId === 40) ? 'FOMO7': (brandId === 46 || brandId === 47) ? 'FUN88':  'Betdaily' ;


    return (
        <>
            <AccordionWrapper>
                <Accordion>
                    <input type="checkbox" id="section1" className="accordion__input" />
                    <label htmlFor="section1" className="accordion__label"><h1>7MOJOS </h1></label>
                    <div className="accordion__content">
                        <h2>Exciting Live Casino Games by 7Mojos Games on {domainName}</h2>
                        <p>If you want an extraordinary gaming experience, you must play 7Mojos' popular live casino games. Here are some thrilling games you can enjoy on {domainName}:</p>
                        <ul>
                            <li>Andar Bahar</li>
                            <li>Dragon Tiger</li>
                            <li>Teen Patti Face Off</li>
                            <li>Turkish Roulette</li>
                        </ul>
                        <p>Each game will have specific rules, strategies to ace the game, and the thrills of playing. Get ready to experience the action-packed world of 7Mojos live casino games on {domainName} and embark on an unforgettable gaming journey.</p>

                        <h3>Andar Bahar</h3>
                        <p>Andar Bahar is a traditional Indian card game that has entered the online gambling world. The game is simple yet incredibly engaging, making it a favorite among players. The objective of Andar Bahar is to predict which side - Andar or Bahar, will have a card of the same value as the Joker card.</p>

                        <h3>Dragon Tiger</h3>
                        <p>Dragon Tiger is a casino card game that originated in Cambodia but is known worldwide for its simple gameplay. It involves a battle between two powerful creatures, the Dragon and the Tiger. Players predict which hand, the Dragon or the Tiger, will have a higher-ranked card.</p>

                        <h3>Teen Patti</h3>
                        <p>Teen Patti is a popular casino card game in India that has become a sensation in online gaming. It is a variation of the 'Three Card Brag' British game played with a standard 52-card deck. In the Teen Patti face-off, player A's hand squares off against player B's. Each hand will have a rank, and a player needs to bet on which hand will be higher.</p>

                        <h3>Turkish Roulette</h3>
                        <p>Turkish Roulette is an exciting variation of the classic casino game Roulette with a Turkish twist. It provides players with an immersive and culturally rich experience that combines the thrill of Roulette with Turkish elements.</p>

                        <h2>Why Choose {domainName} for Playing 7Mojos Games?</h2>
                        <ul>
                            <li>Extensive Game Selection</li>
                            <li>Effortless Navigation</li>
                            <li>Secure and Fair Gameplay</li>
                            <li>Live Dealer Interaction</li>
                            <li>Attractive Bonuses and Promotions</li>
                            <li>Swift and Secure Payouts</li>
                        </ul>
                        <p>Regarding playing 7Mojos games, {domainName} stands out as the perfect platform for an unparalleled gaming experience. Here are some reasons why choosing {domainName} to play games offered by 7Mojos:</p>

                        <h3>Extensive Game Selection</h3>
                        <p>{domainName} provides multiple 7Mojos live casino games, including popular casino games like Andar Bahar, Dragon Tiger, Teen Patti Face Off, and Turkish Roulette. You'll have various opportunities to get a different collection of captivating, high-quality games that suit your taste.</p>

                        <h3>Effortless Navigation</h3>
                        <p>If you are a newbie, you will feel the smooth navigation on {domainName}. When you land on the homepage, the prominently placed 'Casino' section catches your attention. It also provides well-organized subsections where you can easily explore different sections, such as Live Casino, under which you have reliable providers like 7Mojos.</p>

                        <h3>Secure and Fair Gameplay</h3>
                        <p>{domainName} prioritizes players' security and fairness; therefore, the platform employs advanced security measures that can protect your personal and financial information. Even the games offered on {domainName} undergo rigorous testing to ensure fair outcomes that adhere to our industry standards.</p>

                        <h3>Live Dealer Interaction</h3>
                        <p>Our live casino games feature provides professional and friendly live dealers, enhancing your gaming experience. You can interact with the dealers through the chat feature, adding a social element to your gameplay.</p>

                        <h3>Attractive Bonuses and Promotions</h3>
                        <p>You can get various attractive bonuses and promotions, including welcome and deposit bonuses.</p>

                        <h3>Swift and Secure Payouts</h3>
                        <p>When it comes to receiving your winnings from 7Mojos games on {domainName}, there is no need for hesitation. Our website accepts multiple payment methods with convenient modes, including Paytm, Gpay, and PhonePe. The exciting part is the prompt disbursal of your funds. You can receive your winnings quickly. We value your time and prioritize secure and efficient payout processes.</p>
                        <h2>Getting Started on {domainName} with 7Mojos: A Step-by-Step Guide</h2>
                        <p>If you are ready to get into the amazing world of 7Mojos games on {domainName}, then you must follow these simple and easy steps to get started:</p>
                        <ol>
                            <li>Go to the {domainName} website or open the {domainName} app.</li>
                            <li>On the homepage, locate the Casino section and click on the Live Casino section prominently displayed.</li>
                            <li>Various subsections in the live casino section represent different game providers.</li>
                            <li>Look for and select the game provider called 7Mojos.</li>
                            <li>Now it's time to explore and immerse yourself in the exciting games offered by 7Mojos on {domainName}.</li>
                        </ol>

                        <h2>FAQs about 7Mojos games</h2>
                        <p><strong>How can I play 7Mojos live casino games?</strong></p>
                        <p>To play the 7Mojos live casino games, visit the {domainName} website and navigate to the live casino section. Select the game provider "7Mojos" from there and explore the available games.</p>

                        <p><strong>Can I chat with the live dealers and other players during the games?</strong></p>
                        <p>Yes, {domainName} provides a chat feature where you can interact with live dealers and fellow players while playing games offered by 7Mojos.</p>

                        <p><strong>Is there a demo mode available for 7Mojos live casino games?</strong></p>
                        <p>Yes, {domainName} offers a demo for the 7Mojos live casino games. It allows you to know the gameplay before playing for real money.</p>
                    </div>
                </Accordion>
            </AccordionWrapper>
        </>
    )
}

export default withTheme(withAppContext(Mojos7)) ;