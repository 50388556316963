import React from 'react';
import styled from 'styled-components';
import config from 'src/config/config';

type customRadioButtonProps = {
    name: string;
    label: string
    value: string;
    icon: string;
    onChange: (e: React.ChangeEvent<HTMLInputElement>, value?: string) => void;
} & Omit<React.ComponentProps<'input'>, 'name' | 'onChange' | 'value'>

const PaymentProviderRadioButton = ({ icon, name, label, value, onChange, ...props }: customRadioButtonProps) => {
  
    return (
        <Container>
            <input type="radio" name={name} value={value} onChange={(e)=>onChange(e,value)} {...props} />
            <span className="checkmark">
                <img src={`${config.imageV3Host}/static/media/${icon}.svg`} alt="Image"/>
                {label}
            </span>
        </Container>
    )
}

export default PaymentProviderRadioButton;

const IconMap = styled.div`

`;

const Container = styled.label`
    width: 120px!important;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    padding-left: 0px;
    cursor: pointer;
    user-select: none;
    font-size: 13px;
    color: #2A2A2B;
    margin-right: 1em;

input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
}
.checkmark {
    // position: absolute;
    display: flex;
    align-items: center;
    justify-content: center;
    // top: 0;
    // left: 0;
    width: 100%;
    background: #d7d6d6;
    border: 1px solid #d7d6d6;
    border-radius: 3px;
    padding: 7px 5px;

    img {
        width: 25px;
        height: 25px;
        margin-right: 5px;
    }
}
&:hover input:not([disabled]) ~ .checkmark {
    background: #fff;
    border: 1px solid #FFD085;
    color: #242424;
}
input:checked ~ .checkmark {
    background: #fff;
    border: 1px solid #FFD085;
    color: #242424;
}
.checkmark:after {
    content: "";
    position: absolute;
    display: none;
}
`