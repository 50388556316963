import React from 'react';
import GlobalModal from '../GlobalModal';
import styled from "styled-components";
import Intl from 'src/components/common/Intl'
import { palette, theme } from 'styled-tools';

type Props = {
  modalShow: boolean;
  cancelHandler?: () => void;
  deleteHandel: () => void;
  labels?: [any, any];
  heading?: string;
  subHeading?: string | React.ReactNode;
}

export default function Confirm({ modalShow, cancelHandler, deleteHandel, labels = ['Delete', 'Cancel'], heading = '', subHeading = '' }: Props) {
  return (
    <GlobalModal isModalOpen={modalShow}>
      <DeletePopup>
        <TextInsidePopup><Intl langKey={heading} /></TextInsidePopup>
        <TextInsidePopupDelete>
          {subHeading}
        </TextInsidePopupDelete>
        <PopUpButtonWrapper>
          <DeleteBtn onClick={deleteHandel}><Intl langKey={labels[0]} /> </DeleteBtn>
          {labels[1] &&
            <CancelBtn onClick={cancelHandler}><Intl langKey={labels[1]} /></CancelBtn>
          }
        </PopUpButtonWrapper>
      </DeletePopup>
    </GlobalModal>
  )
}

const TextInsidePopup = styled.div`
  flex-direction: row;
  /* font-family: "Roboto"; */
  font-style: normal;
  font-weight: 400;
  font-size: 22px;
  color: white;
  text-align: center;
  margin: 0 auto;
  height: 20px;
  margin-top: 46px;
`;

const TextInsidePopupDelete = styled.div`
  flex-direction: row;
  font-style: normal;
  text-align: center;
  color: white;
  font-weight: 360;
  font-size: 15px;
  margin: 0 auto;
  margin-top: 19px;
  text-transform: capitalize;
`;
const SecondText = styled.div`
  font-style: normal;
  text-align: center;
  color: white;
  font-weight: 350;
  font-size: 15px;
  margin: 0 auto;
  margin-top: 9px;
`;

export const DeleteBtn = styled.button`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  padding-top: 5px;
  background: #ed5a74;
  border: none;
  border-radius: 4px;
  font-size: 14px;
  font-weight: 400;
  padding: 14px 38px;
  color: #ffffff;
  cursor: pointer;

  :hover {
    color: #e8e40d;
  }
`;

export const CancelBtn = styled.button`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  padding-top: 5px;
  background: #aeaeae;
  border: none;
  border-radius: 4px;
  font-size: 14px;
  font-weight: 400;
  padding: 14px 38px;
  color: #ffffff;
  cursor: pointer;

  :hover {
    color: #e8e40d;
  }
`;

const PopUpButtonWrapper = styled.div`
  display: flex;
  justify-content: center;
  padding: 24px 15px;
  gap: 10px;
`;

const DeletePopup = styled.div`
  width: 600px;
  height: 245px;
  background: #333;
  /* background: ${theme('palette.UdbInfo_TabBG')}; */
  border-radius: 5px;
  border: 1px solid #ffffff73;
`;