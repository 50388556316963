import React from 'react'
import styled from 'styled-components';
// import Crown from '../icon/crown';
import { SvgIcon as MoreWidth } from '../udb/commonStyle';
// import Intl from '../../components/common/Intl';
import config from 'src/config/config';
// import ArrowLinkIcon from '../icon/ArrowLink';
import { theme } from 'styled-tools';
import Language from '../Lang-Selector/index';



export default function TierComponent({ tier,showTier }) {
    const tierClass = tier.replace(' ', '').toLowerCase();
    return (
        <Section>
            {/* <CrownIcon>
            <SvgIcon>
                <Crown/>
            </SvgIcon>
        </CrownIcon> */}
            <Language />
           { showTier && <ButtonTier className={tierClass} href="/loyalty">
                {tier}
            </ButtonTier>}
            {/* <ArrowLink href="/loyalty">
                <SvgIcon>
                    <ArrowLinkIcon />
                </SvgIcon>
            </ArrowLink> */}
        </Section>
    )
}

//Styled Components
const SvgIcon = styled(MoreWidth)`
    margin-right: 0;
    svg{
        width: 22px;
        height: 22px;
        justify-content: center;
        padding: 2px;
    }
`;

const CrownIcon = styled.a`
    display: flex;
    align-items: center;
    background: #FEC006;
    border-radius: 20px;
    padding: 3px;
`;

const ArrowLink = styled.a`
    display: flex;
    align-items: center;
    background: #FEC006;
    border-radius: 3px;
    padding: 1px;
`;

const ButtonTier = styled.a`
    display: flex;
    align-items: center;
    padding: 7px 30px;
    background: linear-gradient(270.02deg, #2F3C8C 0.02%, #5767CA 106.13%);
    // background-image: url(${config.imageV3Host}/static/cashsite/${theme('image.AccBlueTierBG')});
    background-position: 100%;
    background-size: cover;
    background-repeat: no-repeat;
    border-radius: 12px;
    color: #fff!important;
    font-weight: 500;
    font-size: 13px;
    text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);

    &.silvertier {
        background: linear-gradient(270.02deg, #666666 0.02%, #B1B1B1 106.13%);
        color: #fff!important;
        
    }
    &.goldtier {
        background: linear-gradient(270.02deg, #AC802C 0.02%, #F8D47E 106.13%);
        color:#000!important;
    }
    &.platinumtier {
        background: linear-gradient(269.8deg, #50B3E2 -1.79%, #8AD2F6 103.42%);
        color:#000!important;
        
    }
    &.elitetier {
        background: linear-gradient(269.8deg, #C7B5DE -1.79%, #CFC2E2 103.42%);
        color:#000!important;
    }
`;

const Section = styled.section`
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 10px 5px;
`;