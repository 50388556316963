import React from 'react'

type Props = {}

export const BankIcon = (props: Props) => {
  return (
    <svg fill="none" xmlns="" viewBox="0 0 22 22"><path d="M4.479 8.967v6.125h2.625V8.967H4.479Zm5.25 0v6.125h2.625V8.967H9.729Zm-7 10.5h16.625v-2.625H2.729v2.625Zm12.25-10.5v6.125h2.625V8.967h-2.625ZM11.04 1.092 2.729 5.467v1.75h16.625v-1.75L11.04 1.092Z" fill="#E8E8E8" /></svg>
  )
}

export const BankIconFun88 = (props: Props) => {
  return (
    <svg fill="none" xmlns="" viewBox="0 0 22 22"><path d="M4.479 8.967v6.125h2.625V8.967H4.479Zm5.25 0v6.125h2.625V8.967H9.729Zm-7 10.5h16.625v-2.625H2.729v2.625Zm12.25-10.5v6.125h2.625V8.967h-2.625ZM11.04 1.092 2.729 5.467v1.75h16.625v-1.75L11.04 1.092Z" fill="#000"></path></svg>
  )
}