import React from 'react'
type Props = {}

export default function DeleteIcon({ }: Props) {
    return (
        <svg fill="none" xmlns="" viewBox="0 0 15 15"><g clip-path="url(#a)" stroke="#E8E8E8" stroke-width="1.25" stroke-linecap="round" stroke-linejoin="round"><path d="M7.258 3.138H3.175a1.167 1.167 0 0 0-1.167 1.166v8.167a1.167 1.167 0 0 0 1.167 1.167h8.167a1.167 1.167 0 0 0 1.166-1.167V8.388"/><path d="M11.633 2.263a1.237 1.237 0 0 1 1.75 1.75L7.842 9.554l-2.334.584.584-2.334 5.541-5.541Z"/></g><defs><clipPath id="a"><path fill="#fff" transform="translate(.842 .804)" d="M0 0h14v14H0z"/></clipPath></defs></svg>
    )
}

export const EditIconFun88 = () => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="15" height="15" viewBox="0 0 15 15" fill="none">
        <g clip-path="url(#clip0_4182_717)">
          <path d="M6.875 3.34961H2.5C2.16848 3.34961 1.85054 3.48131 1.61612 3.71573C1.3817 3.95015 1.25 4.26809 1.25 4.59961V13.3496C1.25 13.6811 1.3817 13.9991 1.61612 14.2335C1.85054 14.4679 2.16848 14.5996 2.5 14.5996H11.25C11.5815 14.5996 11.8995 14.4679 12.1339 14.2335C12.3683 13.9991 12.5 13.6811 12.5 13.3496V8.97461" stroke="#7C7C7C" stroke-width="1.25" stroke-linecap="round" stroke-linejoin="round"/>
          <path d="M11.5625 2.41176C11.8111 2.16312 12.1484 2.02344 12.5 2.02344C12.8516 2.02344 13.1889 2.16312 13.4375 2.41176C13.6861 2.6604 13.8258 2.99763 13.8258 3.34926C13.8258 3.70089 13.6861 4.03812 13.4375 4.28676L7.5 10.2243L5 10.8493L5.625 8.34926L11.5625 2.41176Z" stroke="#7C7C7C" stroke-width="1.25" stroke-linecap="round" stroke-linejoin="round"/>
        </g>
        <defs>
          <clipPath id="clip0_4182_717">
            <rect width="15" height="15" fill="white"/>
          </clipPath>
        </defs>
      </svg>
    )
}
