import React from 'react'

type Props = {}

const Step6Icon = (props: Props) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 48 48"><circle cx="24" cy="24" r="24" fill="url(#a)"/><g fill="#fff"><path d="M34.916 17.506C34.143 13.754 31.034 11 27.45 11c-2.919 0-5.582 1.832-6.861 4.639a5.277 5.277 0 0 0-1.91-.362c-2.888 0-5.279 2.352-5.641 5.387C10.207 21.017 8 23.64 8 26.812 8 30.224 10.552 33 13.688 33h20.15C37.787 33 41 29.505 41 25.208c0-3.868-2.626-7.131-6.084-7.702Zm-1.078 13.811h-20.15c-2.284 0-4.141-2.02-4.141-4.505 0-2.484 1.848-4.495 4.124-4.505a.727.727 0 0 0 .634-.236.88.88 0 0 0 .232-.601v-.02c.007-2.477 1.861-4.49 4.14-4.49.687 0 1.342.179 1.946.531a.717.717 0 0 0 .64.045.812.812 0 0 0 .452-.496c.88-2.606 3.185-4.357 5.734-4.357 3.023 0 5.622 2.454 6.045 5.709.05.394.35.695.715.722 2.94.21 5.245 2.887 5.244 6.094 0 3.369-2.519 6.109-5.615 6.109Z"/><path d="M28.88 19.425c-.055-.227-.226-.277-.38-.11l-.619.673c-1.948-2.053-5.066-2.035-6.99.059a5.572 5.572 0 0 0-1.351 2.7c-.09.455.177.902.595 1a.707.707 0 0 0 .162.018c.357 0 .678-.27.755-.666.139-.705.461-1.35.933-1.863 1.32-1.437 3.458-1.455 4.803-.058l-.635.69c-.153.166-.107.353.102.414l3.206.934c.21.06.334-.076.279-.304l-.86-3.486ZM28.902 24.36c-.418-.097-.828.192-.918.646a3.845 3.845 0 0 1-.932 1.863c-.65.706-1.512 1.095-2.431 1.095s-1.745-.375-2.39-1.054l.616-.669c.153-.167.107-.353-.103-.414l-3.205-.934c-.209-.06-.334.076-.278.304l.858 3.486c.056.228.228.278.38.111l.639-.694c.937.997 2.17 1.547 3.483 1.547 1.332 0 2.584-.564 3.525-1.589a5.566 5.566 0 0 0 1.35-2.7c.09-.454-.176-.902-.594-.998Z"/></g><defs><linearGradient id="a" x1="24" x2="24" y1="0" y2="48" gradientUnits="userSpaceOnUse"><stop stop-color="#6765E0"/><stop offset="1" stop-color="#322DB8"/></linearGradient><clipPath id="b"><path fill="#fff" d="M8 11h33v22H8z"/></clipPath></defs></svg>
  )
}

export const FomoStep6Icon = (props: Props) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 48 48"><circle cx="24" cy="24" r="24" fill="url(#a)"/><g fill="#fff"><path d="M34.916 17.506C34.143 13.754 31.034 11 27.45 11c-2.919 0-5.582 1.832-6.861 4.639a5.277 5.277 0 0 0-1.91-.362c-2.888 0-5.279 2.352-5.641 5.387C10.207 21.017 8 23.64 8 26.812 8 30.224 10.552 33 13.688 33h20.15C37.787 33 41 29.505 41 25.208c0-3.868-2.626-7.131-6.084-7.702Zm-1.078 13.811h-20.15c-2.284 0-4.141-2.02-4.141-4.505 0-2.484 1.848-4.495 4.124-4.505a.727.727 0 0 0 .634-.236.88.88 0 0 0 .232-.601v-.02c.007-2.477 1.861-4.49 4.14-4.49.687 0 1.342.179 1.946.531a.717.717 0 0 0 .64.045.812.812 0 0 0 .452-.496c.88-2.606 3.185-4.357 5.734-4.357 3.023 0 5.622 2.454 6.045 5.709.05.394.35.695.715.722 2.94.21 5.245 2.887 5.244 6.094 0 3.369-2.519 6.109-5.615 6.109Z"/><path d="M28.88 19.425c-.055-.227-.226-.277-.38-.11l-.619.673c-1.948-2.053-5.066-2.035-6.99.059a5.572 5.572 0 0 0-1.351 2.7c-.09.455.177.902.595 1a.707.707 0 0 0 .162.018c.357 0 .678-.27.755-.666.139-.705.461-1.35.933-1.863 1.32-1.437 3.458-1.455 4.803-.058l-.635.69c-.153.166-.107.353.102.414l3.206.934c.21.06.334-.076.279-.304l-.86-3.486ZM28.902 24.36c-.418-.097-.828.192-.918.646a3.845 3.845 0 0 1-.932 1.863c-.65.706-1.512 1.095-2.431 1.095s-1.745-.375-2.39-1.054l.616-.669c.153-.167.107-.353-.103-.414l-3.205-.934c-.209-.06-.334.076-.278.304l.858 3.486c.056.228.228.278.38.111l.639-.694c.937.997 2.17 1.547 3.483 1.547 1.332 0 2.584-.564 3.525-1.589a5.566 5.566 0 0 0 1.35-2.7c.09-.454-.176-.902-.594-.998Z"/></g><defs><linearGradient id="a" x1="24" x2="24" y1="0" y2="48" gradientUnits="userSpaceOnUse"><stop stop-color="#FF8351" /><stop offset="0.479533" stop-color="#FF5F1F" /><stop offset="1" stop-color="#BA3500" /></linearGradient><clipPath id="b"><path fill="#fff" d="M8 11h33v22H8z"/></clipPath></defs></svg>
  )
}

export default Step6Icon;