import React, { PureComponent, useState } from 'react';
import { Query } from 'react-apollo';
import { GET_EVENT_NEWS_CLIENT } from '../../graphql/agentMember';
import util from '../../util/util';
import Ticker from 'react-ticker';
import styled, { withTheme } from 'styled-components';
import config from '../../config/config';
import { useQuery } from '@apollo/client';
import { theme } from 'styled-tools';

const NewsEvent = (props: any) => {

    const [isPlay, setIsPlay] = useState(true);

    const marqueeMove = () => {
        setIsPlay(!isPlay)
    }
   
    const { data } = useQuery(GET_EVENT_NEWS_CLIENT, {
        variables: { input: { events: props.data } },
        pollInterval: 20000
    });

    const news_event: any = util.getValue(data, ['markets', 'eventNews'], []);
    if (news_event.length === 0) {
        return null
    }

    // const { data } = useQuery(GET_EVENT_NEWS_CLIENT, {
    //     variables: { input: { events: props.popular_data } }
    // });

    const news_event_popular: any = util.getValue(data, ['markets', 'eventNews','0'], []);
    if (news_event.length === 0) {
        return null
    }
    return (
                <TickerWrapper>
                    <Ticker mode={'smooth'} move={isPlay}>
                        {
                            (): any => {
                                const joinElement = news_event.map((each) => each.text)
                                console.log('Whats Join', joinElement)
                                return <p style={{ whiteSpace: "nowrap" }}>{joinElement.join(" +++ ")} +++ </p>
                            }
                        }
                    </Ticker>
                    <button onClick={() => marqueeMove()}>{!isPlay ? <Play /> : <Stop />}</button>
                </TickerWrapper>
    );
}

export default withTheme(NewsEvent);

const Play: any = styled.div`
    width: 20px;
    height: 20px;
    background-image: url('${config.imageS3Host}/static/snk/brand/1/icon/png-agent/play-button.png');
    display: block;
    background-repeat: no-repeat;
    background-size: contain;

    @media only screen and (max-width: 991px){
        width: 15px;
        height: 15px;
	}
`;

const Stop: any = styled.div`
    width: 20px;
    height: 20px;
    background-image: url('${config.imageS3Host}/static/snk/brand/1/icon/png-agent/stopping.png');
    display: block;
    background-repeat: no-repeat;
    background-size: contain;

    @media only screen and (max-width: 991px){
        width: 15px;
        height: 15px;
	}
`;

const TickerWrapper = styled.div`
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-around;
    white-space: nowrap;
    box-shadow: 2px 2px 10px rgb(0 0 0 / 30%), -1px 0px 4px rgb(0 0 0 / 8%);
    font-size: 14px;
    color: #242629;
    border-radius: 4px;
    padding: 3px 10px;
    margin-bottom: 15px;
    position: relative;
    background: ${theme('palette.NewsEventTickerWrapperBg')};

    @media only screen and (max-width: 991px){
        font-size: 13px;
        padding: 0px 10px;
    }

    .ticker {
        width: 95%;

        p {
            margin: 8px 0px;
            color: rgb(239 26 26);
        }
    }

    button {
        border-radius: 4px;
        border: 0;
        cursor: pointer;
        background: linear-gradient(to top,#000559,#234294);
        color: #fff;
        padding: 6px;
        font-size: 12px;
    }

    @media only screen and (max-width: 991px){
        button {
            padding: 5px;
        }
	}
`;
