// animation
import isEqual from "lodash/isEqual";
import * as React from "react";
import styled, { css } from "styled-components";
import { theme, withProp } from "styled-tools";
import { Income } from "../../../common/NumberDisplay";
import { TimeZone } from "../../../../store/TimeZoneContext";
import { color } from "src/sass-files/variable";

// PNG Icon
import Icon from "../../../common/PNGIcon";
import TabDoubleOpen from "src/images/tab_double_open.png";
// import Spinner from '~/component/common/Spinner';
import FavoriteButton from "../../../eu-content/FavoriteButton";
import UpcomingContainer from "../../../eu-content/table/feature/Upcoming";
import UpdateInView from "../../../eu-content/UpdateInView";
import Intl from "../../../common/Intl";
import {
  IMatchListContext,
  withMatchListContext,
} from "../../../../store/MatchListContext";
import {
  IOrderListContext,
  IOrderTicket,
  withOrderListContext,
} from "../../../../store/OrderListContext";

import {
  Col,
  ColMask,
  MobileMatchTableHeading,
  TableBodyRow,
  TableBodyWrapper,
  TableHeadRow,
  TableWrapper,
} from "../../../common/table/baseTableStyles";
import EmptyTable from "../../../common/table/feature/EmptyTable";
import MobileOrderSlip from "../../../eu-content/order/orderslip/MobileOrderSlip";
// import MobileUnmatchedSlip from '../../../eu-content/order/orderslip/MobileUnmatchedSlip';
import Odd from "../../../eu-content/table/feature/Odd";
import {
  BackTitleCell,
  LayTitleCell,
  MatchOddsGroupWrapper,
  MatchOddsTitleCell,
  SelectionExposureWrapper,
  SelectionNameCell,
  TypeTitle,
} from "../../../eu-content/table/MarketList";

// Config
import EventTypeConfig from "../../../../config/eventTypeConfig";

// Gql
import {
  IEventInfo,
  IMarketInfo,
  IOrder,
  ISelectionInfo,
} from "../../../../graphql/schema";
import { diffColor } from "../../../../util/colorUtil";

// Util
import util from "../../../../util/util";
import { EEventMode, EEventTypeId } from "../../../../util/utilModel";

import { withAppContext } from "../../../../store/initAppContext";
import media from "../../../../util/mediaUtil";
import { isMemberType } from "../../../../util/styleUtil";

import { calExposure } from "./exposureCalculate";

const {
  tabsignup,
  innerBackHeadBg,
  innerLayHeadBg,
  secondarycolor,
  innerOddsLayColor,
  innerBackLightBg,
  innerBackDarkBg,
  innerLayDarkBg,
  innerLayLightBg,
  titleHeadColor,
} = color;

const WinnerLabel: React.SFC = () => {
  return (
    <PulseAnimate>
      <CrownImg>
        <Icon name="crown" />
      </CrownImg>
      <WinnerText>Winner</WinnerText>
    </PulseAnimate>
  );
};

class SelectionNameWithRunnerInfo extends React.PureComponent<ISelectionInfo> {
  handleFocus = () => {
    // iOS Safari 在有 focus 的情況下，點擊其他並不會 blur...
    window.addEventListener(
      "touchstart",
      () => {
        document.activeElement && (document.activeElement as any).blur();
      },
      { once: true }
    );
  };

  render() {
    const { name, runner } = this.props;
    const filteredKeys = ["coloursFilename", "__typename"];
    return (
      <SelectionName onFocus={this.handleFocus}>
        {runner && runner.coloursFilename && (
          <HorseSilkColorIcon
            src={`http://content-cache.betfair.com/feeds_images/Horses/SilkColours/${runner.coloursFilename}`}
          />
        )}
        {name}
        {runner && (
          <RunnerInfoWrapper>
            {Object.keys(runner)
              .filter((key) => !filteredKeys.includes(key))
              .map((key) => {
                return (
                  <RunnerInfoItem key={key}>
                    <span>{key}</span>{" "}
                    <span>{(runner as any)[key] || "-"}</span>
                  </RunnerInfoItem>
                );
              })}
          </RunnerInfoWrapper>
        )}
      </SelectionName>
    );
  }
}

class SelectionNameCol extends React.Component<any> {
  shouldComponentUpdate(nextProps: any) {
    return !isEqual(this.props, nextProps);
  }
  render() {
    const {
      selection,
      matchUnmatchListBySelection,
      orderListBySelection,
      exchangeRate,
      orderList,
      matchUnmatchList,
      categoryId,
    } = this.props;
    // judge value of crown
    const { status } = selection;
    const currentExposure = util.numMultiply(
      matchUnmatchListBySelection[selection.name],
      exchangeRate
    );
    const currentBetExposure = orderListBySelection[selection.name];
    const isHorseRacing = categoryId === EEventTypeId.horseracing;

    // {/* Competition Name Column */}

    return (
      <SelectionNameCell>
        <div>
          <SelectionNameWrapper>
            {isHorseRacing ? (
              <>
                <SelectionNameWithRunnerInfo {...selection} />
                <JockeyNameWrapper>{selection.jockeyName}</JockeyNameWrapper>
              </>
            ) : (
              <h1>{selection.name}</h1>
            )}
          </SelectionNameWrapper>
          <SelectionExposureWrapper>
            {matchUnmatchList.length > 0 && <Income>{currentExposure}</Income>}
            {orderList.length > 0 && (
              <>
                {" "}
                ➟{" "}
                <Income>
                  {util.numAdd(currentBetExposure, currentExposure)}
                </Income>
              </>
            )}
          </SelectionExposureWrapper>
        </div>
        {/* CrownIcon Position */}
        {[EEventTypeId.horseracing, EEventTypeId.greyhoundracing].indexOf(
          categoryId as any
        ) > -1 &&
          status === "WINNER" && <WinnerLabel />}
      </SelectionNameCell>
    );
  }
}
class TableRowComponent extends React.Component<{
  selection: ISelectionInfo;
  matchUnmatchListBySelection: { [selectionName: string]: number };
  orderListBySelection: { [selectionName: string]: number };
  exchangeRate: number;
  market: IMarketInfo;
  orderList: IOrderTicket[];
  matchUnmatchList: IOrder[];
  eventInfo: IEventInfo;
  isMobileWidth: boolean;
}> {
  render() {
    const {
      selection,
      matchUnmatchListBySelection,
      orderListBySelection,
      exchangeRate,
      market,
      orderList,
      matchUnmatchList,
      eventInfo,
      isMobileWidth,
    } = this.props;
    // judge value of crown
    const { isCompetitionOrder, isEventOrder } = eventInfo;
    const { isOrder, resourceFrom } = market;
    const backOdds = getOddsFromSelection(selection, "BACK");
    const layOdds = getOddsFromSelection(selection, "LAY");
    const selectionNameAreaProps = {
      selection,
      matchUnmatchListBySelection,
      orderListBySelection,
      exchangeRate,
      orderList,
      matchUnmatchList,
      categoryId: eventInfo.categoryId,
    };
    // console.log('What is Selection', selectionNameAreaProps)
    return (
      <UpdateInView scrollableContainerId="major-wrapper">
        {(refObj) => (
          <MatchTableBodyRow
            key={`${selection.name}_${selection.selectionId}`}
            ref={refObj}
          >
            <SelectionNameCol {...selectionNameAreaProps} />
            <OddsCol>
              {(!isOrder ||
                (eventInfo.categoryId !== EEventTypeId.fancybet &&
                  (isCompetitionOrder === false || isEventOrder === false)) ||
                market.status === "SUSPENDED") && (
                <ColMask>
                  {market.status === "SUSPENDED" ? (
                    <Intl langKey="TABLE@SUSPENDED" />
                  ) : selection.isRunning ? (
                    <Intl langKey="TABLE@BALL_RUNNING" />
                  ) : (
                    ""
                  )}
                </ColMask>
                // <ColMask>{market.status === 'SUSPENDED' ? 'SUSPENDED' : selection.isRunning ? 'BALL RUNNING' : '' }</ColMask>
              )}
              {(!isOrder ||
                selection.isRunning ||
                market.status === "SUSPENDED") && (
                <ColMask>
                  {selection.status === "SUSPENDED" ? (
                    <Intl langKey="TABLE@SUSPENDED" />
                  ) : // 'SUSPENDED'
                  selection.isRunning ? (
                    <Intl langKey="TABLE@BALL_RUNNING" />
                  ) : (
                    // 'BALL RUNNING'
                    ""
                  )}
                </ColMask>
              )}
              <MatchOddsGroupWrapper type="BACK">
                {backOdds.map((odd, i) => {
                  return createOddComponent(
                    eventInfo,
                    odd,
                    "BACK",
                    selection,
                    market,
                    `${market.marketId}_BACK_${selection.selectionId}_${i}`
                  );
                })}
              </MatchOddsGroupWrapper>
              <MatchOddsGroupWrapper type="LAY">
                {layOdds.map((odd, i) => {
                  return createOddComponent(
                    eventInfo,
                    odd,
                    "LAY",
                    selection,
                    market,
                    `${market.marketId}_LAY_${selection.selectionId}_${i}`
                  );
                })}
              </MatchOddsGroupWrapper>
            </OddsCol>

            {/* 行動裝置寬度時才可以直接在賠率下方下注 Code Changed - Manoj (added provider Here) */}
            {isMobileWidth && (
              <MobileOrderSlip selfSelectionId={selection.selectionId} />
            )}
            {/* {isMobileWidth && <MobileUnmatchedSlip selfSelectionId={selection.selectionId} provider={resourceFrom} />} */}
          </MatchTableBodyRow>
        )}
      </UpdateInView>
    );
  }
}

interface ITableProps {
  eventInfo: IEventInfo;
  market: IMarketInfo;
  orderList: IOrderTicket[];
  matchUnmatchList: IOrder[];
  app: {
    exchangeRate: number;
    isMobileWidth: boolean;
  };
}

class TableComponent extends React.Component<ITableProps, any> {
  constructor(props) {
    super(props);
    this.state = {
      showMatchInfo: true,
    };
    this.toggleMatchInfo = this.toggleMatchInfo.bind(this);
  }
  toggleMatchInfo = (eventId) => {
    this.setState((prevState) => {
      return {
        ...prevState,
        showMatchInfo: !prevState.showMatchInfo,
      };
    });
  };
  render() {
    const {
      eventInfo,
      market,
      orderList = [],
      matchUnmatchList = [],
      app,
    } = this.props;
    const selectionInfoList: ISelectionInfo[] = util.getValue(
      market,
      ["list"],
      []
    );
    const initExposureBySelectionNames = selectionInfoList.reduce(
      (pv, selectionInfo) => {
        const { name } = selectionInfo;
        name && (pv[name] = 0);
        return pv;
      },
      {} as { [selectionName: string]: number }
    );

    const orderListBySelection = calExposure(orderList, {
      ...initExposureBySelectionNames,
    });
    const matchUnmatchListBySelection = calExposure(matchUnmatchList, {
      ...initExposureBySelectionNames,
    });
    const { categoryId, eventId } = eventInfo;
    const { resourceFrom } = market;
    const isRacingEvent =
      categoryId && EventTypeConfig(categoryId).eventMode === EEventMode.racing;
    const title = isRacingEvent ? eventInfo.eventName : market.marketName;
    // 賽馬賽狗多顯示比賽時間
    const subtitle = isRacingEvent ? (
      <>
        {" "}
        - <TimeZone>{market.marketStartTime}</TimeZone>
      </>
    ) : (
      ""
    );
    return (
      <>
        {/* <MobileMatchTableHeading>
					<FavoriteButton marketId={market.marketId} eventId={eventId}  isFavoriteProp={market.isFavorite} />
					{title} {subtitle}
				</MobileMatchTableHeading> */}
        {/* <TableWrapper key={market.marketId}> */}
        <RowWrap key={market.marketId}>
          {/* <TabSection title={title}>
						<MatchOddsTitleCell>
							<FavoriteButton marketId={market.marketId} eventId={eventId} isFavoriteProp={market.isFavorite} />
							<span>
								{title} {subtitle}
							</span>
							<TabOpenIcon></TabOpenIcon>
						</MatchOddsTitleCell>
					</TabSection> */}
          <TabBody>
            <TableHeadRow page="inner">
              <ToggleContent
                onClick={() => this.toggleMatchInfo(market.marketId)}
              >
                {this.state.showMatchInfo ? (
                  <Icon name="minus-square" />
                ) : (
                  <Icon name="plus-square" />
                )}
              </ToggleContent>
              <SlantDiv>
                <ActiveBM>
                  <FavoriteButton
                    marketId={market.marketId}
                    eventId={eventId}
                    isFavoriteProp={market.isFavorite}
                  />
                  <MatchContent>
                    {title} {subtitle}
                    {/* <span style={{ fontSize: "10px", fontWeight: "lighter" }}>
                      {"Note: This is a pre-play market"}
                    </span> */}
                  </MatchContent>
                  {/* Active Bm - 
								<span>(06/10)</span> */}
                </ActiveBM>
              </SlantDiv>
              {resourceFrom === "DRINS" && (
                <div>
                  <DrinsSpanContainer>
                    {"Note: This is a pre-play market"}
                  </DrinsSpanContainer>
                </div>
              )}

              <OddsCol>
                {/* <Intl langKey="GLOBAL@BACK"> */}
                {/* <span style={{ fontSize: "10px", fontWeight: "lighter" }}>
                      {"Note: This is a pre-play market"}
                    </span> */}
                <BackTypeTitle>
                  <Intl langKey="GLOBAL@BACK" />

                  {(message: string) => {
                    return (
                      <TypeTitle
                        label={message}
                        type="BACK"
                        overround={market.totalBack}
                      />
                    );
                  }}
                </BackTypeTitle>
                {/* </Intl> */}
                {/* <Intl langKey="GLOBAL@LAY"> */}
                <LayTypeTitle>
                  <Intl langKey="GLOBAL@LAY" />
                  {(message: string) => {
                    return (
                      <TypeTitle
                        label={message}
                        type="LAY"
                        overround={market.totalLay}
                      />
                    );
                  }}
                </LayTypeTitle>
                {/* </Intl> */}
              </OddsCol>
            </TableHeadRow>
            {this.state.showMatchInfo && (
              <>
                {selectionInfoList.map(
                  (
                    selection: ISelectionInfo = {} as ISelectionInfo,
                    idx: number
                  ): JSX.Element => {
                    const p = {
                      selection,
                      matchUnmatchListBySelection,
                      orderListBySelection,
                      exchangeRate: app.exchangeRate,
                      market,
                      orderList,
                      matchUnmatchList,
                      eventInfo,
                      isMobileWidth: app.isMobileWidth,
                    };
                    return (
                      <TableRowComponent
                        {...p}
                        key={`${idx}_${selection.selectionId}`}
                      />
                    );
                  }
                )}
              </>
            )}
          </TabBody>
        </RowWrap>
      </>
    );
  }
}
const Table = withAppContext(TableComponent);

const LoadingTable = () => {
  return (
    <TableWrapper>
      <TableHeadRow>
        <MatchOddsTitleCell />
        <BackTitleCell />
        <LayTitleCell />
      </TableHeadRow>
      <TableBodyWrapper>
        {/* <Spinner mode="client" /> */}
        {/* {'LOADING...'} */}
      </TableBodyWrapper>
    </TableWrapper>
  );
};

interface IMatchTableProps {
  loading: boolean;
  error: any;
  dataList: IEventInfo[];
  categoryId: number;
  // market 多於一個時，從第二個開始收合
  collapseMarkets: boolean;
  allowScroll?: boolean;
}

class MatchTable extends React.PureComponent<
  IMatchTableProps & IMatchListContext & IOrderListContext
> {
  static defaultProps = {
    collapseMarkets: true,
    allowScroll: true,
  };

  componentDidMount() {
    // window.scrollTo(0, 0)
    if (this.props.allowScroll) {
      window.scrollTo(0, 0);
    }
  }

  componentWillReceiveProps() {}

  render() {
    const { loading, dataList, collapseMarkets } = this.props;
    const { betArray } = this.props.orderListContext;
    const {
      matched = [],
      unmatched = [],
      updatedUnmatchedSlips = {},
      openedMobileUnmatchedSlipOrderNumber,
    } = this.props.matchListContext;
    const updatedOrderTickets = unmatched.reduce((pv, unmatchedOrder) => {
      const { orderNumber } = unmatchedOrder;
      const updatedUnmatchedSlip: any =
        updatedUnmatchedSlips[orderNumber] || {};
      const isUnmatchedSlipUpdated =
        updatedUnmatchedSlip.price && updatedUnmatchedSlip.size;
      const isMobileUnmatchedSlipOpened =
        unmatchedOrder.orderNumber === openedMobileUnmatchedSlipOrderNumber;
      (isUnmatchedSlipUpdated || isMobileUnmatchedSlipOpened) &&
        pv.push(
          createUpdatedUnmatchedOrderTicket(
            unmatchedOrder,
            updatedUnmatchedSlip
          )
        );
      return pv;
    }, [] as any);
    const marketBetArray = [...betArray, ...updatedOrderTickets].reduce(
      (pv, order) => {
        const {
          marketInfo: { marketId },
        } = order;
        marketId &&
          (pv[marketId] ? pv[marketId].push(order) : (pv[marketId] = [order]));
        return pv;
      },
      {} as { [marketId: string]: IOrderTicket[] }
    );
    const marketMatchedList = matched.reduce((pv, matchedOrder) => {
      const { marketId } = matchedOrder;
      marketId &&
        (pv[marketId]
          ? pv[marketId].push(matchedOrder)
          : (pv[marketId] = [matchedOrder]));
      return pv;
    }, {} as { [marketId: string]: IOrder[] });
    if (!Array.isArray(dataList) || dataList.length === 0) {
      if (loading) {
        return <LoadingTable />;
      } else {
        return <EmptyTable />;
      }
    } else {
      // .sort((a: any, b: any) => {
      // 	return a.isInplay === b.isInplay ? a.marketStartTime - b.marketStartTime : a.isInplay ? -1 : 1;
      // })
      return dataList.map((eventInfo) => {
        // Code Changed - Manoj ( removed main and made it snk )
        // const markets = eventInfo.main || [];
        const markets = eventInfo.snk || [];
        return markets.map((market, i) => {
          if (market === null) {
            return;
          }
          let { marketId } = market && market;
          typeof marketId !== "string" && (marketId = "");
          if (i === 0 || !collapseMarkets) {
            return (
              <Table
                market={market}
                eventInfo={eventInfo}
                orderList={marketBetArray[marketId]}
                matchUnmatchList={marketMatchedList[marketId]}
                key={market.marketId}
              />
            );
          } else {
            return (
              <UpcomingContainer
                title={`${market.marketName}`}
                marketStartTime={market.marketStartTime}
                key={marketId}
              >
                <Table
                  market={market}
                  eventInfo={eventInfo}
                  orderList={marketBetArray[marketId]}
                  matchUnmatchList={marketMatchedList[marketId]}
                  key={market.marketId}
                />
              </UpcomingContainer>
            );
          }
        });
      });
    }
  }
}

export default withMatchListContext(
  withOrderListContext(MatchTable, ["betArray"]),
  [
    "matched",
    "unmatched",
    "updatedUnmatchedSlips",
    "openedMobileUnmatchedSlipOrderNumber",
  ]
);

const createOddComponent = (
  eventInfo: IEventInfo,
  selectionOdd: any,
  type: "BACK" | "LAY",
  selection: any,
  market: any,
  key: string
) => (
  <Odd
    type={type}
    price={selectionOdd.price}
    size={selectionOdd.size}
    key={key}
    isBest={selectionOdd.isBest}
    selectionInfo={selection}
    marketInfo={market}
    eventInfo={eventInfo}
  />
);

const getOddsFromSelection = (
  selection: ISelectionInfo,
  side: "BACK" | "LAY"
) => {
  const result = [];
  // pick out the odds of the side
  let odds: any[];
  switch (side) {
    case "BACK":
      odds = selection.back || [];
      for (let i = 0; i < 3; i++) {
        const odd = odds[odds.length - 1 - i];
        if (odd && odd.price) {
          result.unshift(odd);
        } else {
          result.unshift({});
        }
      }

      result.sort((a, b) => {
        let aPrice = parseFloat(a.price);
        let bPrice = parseFloat(b.price);

        // if the price is empty, move it to the START of the array
        if (isNaN(aPrice)) {
          aPrice = -1;
        }

        if (isNaN(bPrice)) {
          bPrice = -1;
        }

        return aPrice - bPrice;
      });

      // Code Changed - Manoj
      // Add `isBest`
      // if (result.length > 0 && result[2] && result[2].price) {
      // 	result[2].isBest = true;
      // }
      break;
    case "LAY":
      odds = selection.lay || [];
      for (let i = 0; i < 3; i++) {
        const odd = odds[i];
        if (odd && odd.price) {
          result.unshift(odd);
        } else {
          result.unshift({});
        }
      }
      result.sort((a, b) => {
        let aPrice = parseFloat(a.price);
        let bPrice = parseFloat(b.price);

        // If the price is empty, move it to the END of the array
        if (isNaN(aPrice)) {
          aPrice = Number.MAX_SAFE_INTEGER;
        }

        if (isNaN(bPrice)) {
          bPrice = Number.MAX_SAFE_INTEGER;
        }

        return aPrice - bPrice;
      });

      // Code Changed - manoj

      // if (result.length > 0 && result[0] && result[0].price) {
      // 	result[0].isBest = true;
      // }
      break;
    default:
      console.error("[getOdds]: side arg should be either BACK or LAY");
      odds = [];
      break;
  }
  return result;
};

const createUpdatedUnmatchedOrderTicket = (
  unmatchedOrder: IOrder,
  updatedUnmatchedSlip: { price: number; size: number }
) => {
  return {
    price: unmatchedOrder.price,
    size: unmatchedOrder.stake,
    type: unmatchedOrder.side,
    currentPrice: updatedUnmatchedSlip.price || unmatchedOrder.price,
    currentSize:
      updatedUnmatchedSlip.size ||
      Math.round(unmatchedOrder.stake * unmatchedOrder.exchangeRate * 10) / 10,
    marketInfo: { marketId: unmatchedOrder.marketId },
    selectionInfo: { name: unmatchedOrder.selectionName },
    keyName: `unmatched_${unmatchedOrder.orderNumber}_${unmatchedOrder.marketId}_${unmatchedOrder.selectionName}_${unmatchedOrder.side}_${unmatchedOrder.eventId}`,
  };
};

const ToggleContent = styled.div`
  position: absolute;
  right: 15px;
  top: 15px;
  z-index: 99;
  cursor: pointer;
  img {
    width: 20px;
    height: 20px;
  }
`;
const MatchContent = styled.div`
  margin-left: 10px;
`;

const SlantDiv = styled.div`
  height: auto;
  background: ${theme("palette.headerBgColor")};
  width: 300px;
  line-height: 35px;
  position: relative;
  color: black !important;
  &::after {
    width: 50px;
    background: ${theme("palette.headerBgColor")};
    position: absolute;
    height: 100%;
    content: " ";
    right: -22px;
    top: 0;
    transform: skew(45deg);
  }
`;

// Styled components
const BackTypeTitle = styled.div`
  // width: 20%;
  width: 16.01%;
  height: 40px;
  padding: 5px 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  // background: ${innerBackHeadBg};
  letter-spacing: 0.07em;
  text-transform: capitalize;
  font-size: 13px;

  margin-right: 1px;
  color: #0375cc;
`;

const LayTypeTitle = styled.div`
  // width: 20%;
  width: 16.01%;
  height: 40px;
  padding: 5px 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  // background: ${innerLayHeadBg};
  letter-spacing: 0.07em;
  text-transform: capitalize;
  font-size: 13px;

  margin-left: 1px;
  color: #e03c3c;
`;

const ActiveBM = styled.div`
  display: flex;
  align-items: center;
  letter-spacing: 0.07em;
  text-transform: uppercase;
  color: rgba(255, 255, 255);
  font-weight: 500;
  font-size: 12px;
  padding-left: 20px;

  span {
    color: #ffffff;
  }
`;

const TabOpenIcon = styled.div`
  width: 25px;
  height: 25px;
  background-image: url(${TabDoubleOpen});
  background-size: auto;
  background-repeat: no-repeat;
  background-position: center;
  cursor: pointer;
  margin: 0px 10px;
  border-radius: 5px;
`;

const RowWrap = styled.div`
  width: 100%;
  display: flex;
  flex-direction: coloumn;
  align-items: flex-start;
  background: #eaf4fd;
  margin-bottom: 10px;
`;

const TabBody = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
`;

const TabSection: any = styled.div`
  width: 25%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  /* background: linear-gradient(90deg, #F02B2B 43.81%, #BE1616 100%); */
  color: ${secondarycolor};
  padding: 20px 10px;

  ${(props: any) => {
    switch (props.title) {
      case "Match Odds":
        return css`
          background: linear-gradient(90deg, #f02b2b 43.81%, #be1616 100%);
        `;

      default:
        return css`
          background: linear-gradient(90deg, #4bc2f4 43.81%, #2298ca 100%);
        `;
        break;
    }
  }};
`;

const HorseSilkColorIcon = styled.img`
  position: relative;

  /* 只在 icon 沒讀到時出現 */
  &::before {
    content: "";
    display: block;
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background-image: url(${(props) =>
      `'/static/${props.theme.view}/icon/png/horseracing.png'`});
    background-size: contain;
    background-repeat: no-repeat;
    background-color: white;
  }
`;

const RunnerInfoItem = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;

  > span:first-of-type {
    font-weight: bold;
    text-transform: uppercase;
  }

  &:not(:last-of-type) {
    margin-bottom: 0.5em;
  }
`;

const RunnerInfoWrapper = styled.div`
  position: absolute;
  top: 100%;
  left: 50%;
  width: 240px;
  background-color: white;
  border-radius: 2px;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.25);
  font-size: 11px;
  padding: 0.5em;
  pointer-events: none;

  transform: scale(0.95);
  opacity: 0;
  transition: all 0.15s ease;
  will-change: transform, opacity;
`;

const SelectionName = styled.div.attrs((props) => ({
  tabIndex: -1,
}))`
  display: flex;
  align-items: center;

  position: relative;
  z-index: 0;
  cursor: pointer;
  outline: 0;

  > ${HorseSilkColorIcon} {
    margin-right: 0.75em;
    width: 1.5em;
    height: auto;
  }

  /* 裝置有滑鼠的話 hover 顯示 runner info，無滑鼠的裝置 focus 開啟 */
  @media screen and (pointer: fine) {
    &:hover {
      z-index: 1;

      ${RunnerInfoWrapper} {
        transform: scale(1);
        opacity: 1;
      }
    }
  }

  @media screen and (pointer: coarse) {
    &:focus {
      z-index: 1;
      outline: 0;

      ${RunnerInfoWrapper} {
        transform: scale(1);
        opacity: 1;
      }
    }
  }
`;

const OddsCol = styled(Col)`
  flex-basis: 65%;
  justify-content: center;
  position: relative;
  min-width: 0;
  padding: 0;

  ${media.lg`
		flex-basis: 50%;
		border-bottom: 1px solid rgba(128, 128, 128, 0.2);

		${isMemberType(
      "AGENT",
      css`
        background-color: ${theme("palette.sidebar-background")};
      `
    )};
	`};

  ${media.sm`
		flex-basis: 45%;
	`};
`;

const JockeyNameWrapper = styled.div`
  font-size: 11px;
  color: ${withProp("theme.palette.panel-foreground", diffColor(0.2))};
`;

const SelectionNameWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  font-size: 12px;
  h1 {
    text-transform: lowercase;
    font-size: 14px;
    font-weight: 500;
    margin: 0;
    &:first-letter {
      text-transform: capitalize;
    }
  }
`;

// Crown Animation Label

const CrownImg = styled.div`
  font-size: 14px;
  ${media.sm`
	font-size: 12px;
		`};
`;

const WinnerText = styled.div`
  font-size: 0.8em;
  color: ${theme("palette.primary-background")};
  margin-left: 0.75em;
  color: white;
  ${media.sm`
		display:none;
		`};
`;

const PulseAnimate = styled.div`
  margin-left: 1.5em;
  display: flex;
  justify-content: center;
  align-items: center;
  width: auto;
  height: auto;
  padding: 0.25em 0.5em;
  border: 1px solid
    ${withProp("theme.palette.primary-background", diffColor(0.2))};
  background-color: ${withProp(
    "theme.palette.primary-background",
    diffColor(0.05)
  )};
  border-radius: 99em;
  box-shadow: 0 0 0 rgba(245, 205, 136, 0.4);

  ${media.sm`
		width:24px;
		height:24px;
		`};
`;

const MatchTableBodyRow = styled(TableBodyRow)`
  &:nth-last-child(-n + 3) {
    ${RunnerInfoWrapper} {
      top: auto;
      bottom: 100%;
    }
  }
`;

const teamNameMatch = styled.div`
  text-transform: lowercase;
`;

const DrinsSpanContainer = styled.span`
  font-size: 9px;
  font-weight: lighter;
  color: #fff;
  position: absolute;
  left: 47px;
  top: 25px;
`;
