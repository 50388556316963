import React from 'react'

type Props = {}

const BetdailyAppStepFiveIcon = (props: Props) => {
  return (
    <svg
    width={48}
    height={48}
    viewBox="0 0 48 48"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <g filter="url(#filter0_b_702_27)">
      <circle cx={24} cy={24} r={24} fill="url(#paint0_linear_702_27)" />
      <circle cx={24} cy={24} r={24} fill="url(#paint1_linear_702_27)" />
    </g>
    <g clipPath="url(#clip0_702_27)">
      <path
        d="M21.5668 13.2754C21.2655 13.2754 21.0217 13.0243 21.0217 12.7144V10.561C21.0217 10.2511 21.2658 10 21.5668 10C21.8677 10 22.1118 10.2511 22.1118 10.561V12.7144C22.1118 13.0243 21.8677 13.2754 21.5668 13.2754Z"
        fill="white"
      />
      <path
        d="M24.3444 14.4588C24.2049 14.4588 24.0653 14.4041 23.9589 14.2944C23.7459 14.0752 23.7459 13.7203 23.9589 13.5012L25.4392 11.9784C25.6522 11.7593 25.9972 11.7593 26.2103 11.9784C26.4233 12.1976 26.4233 12.5525 26.2103 12.7717L24.73 14.2944C24.6236 14.4038 24.484 14.4588 24.3444 14.4588Z"
        fill="white"
      />
      <path
        d="M27.5884 17.3162H25.4947C25.1935 17.3162 24.9496 17.065 24.9496 16.7551C24.9496 16.4452 25.1938 16.1941 25.4947 16.1941H27.5884C27.8896 16.1941 28.1335 16.4452 28.1335 16.7551C28.1335 17.065 27.8893 17.3162 27.5884 17.3162Z"
        fill="white"
      />
      <path
        d="M18.7893 14.4588C18.6497 14.4588 18.5101 14.4041 18.4038 14.2944L16.9235 12.7717C16.7105 12.5525 16.7105 12.1976 16.9235 11.9784C17.1365 11.7593 17.4816 11.7593 17.6946 11.9784L19.1749 13.5012C19.3879 13.7203 19.3879 14.0752 19.1749 14.2944C19.0685 14.4038 18.9289 14.4588 18.7893 14.4588Z"
        fill="white"
      />
      <path
        d="M17.6387 17.3162H15.5451C15.2438 17.3162 15 17.065 15 16.7551C15 16.4452 15.2441 16.1941 15.5451 16.1941H17.6387C17.94 16.1941 18.1838 16.4452 18.1838 16.7551C18.1838 17.065 17.9397 17.3162 17.6387 17.3162Z"
        fill="white"
      />
      <path
        d="M31.3701 24.5865C30.47 24.5865 29.7403 25.3371 29.7403 26.2631V24.9241C29.7403 23.9981 29.0106 23.2475 28.1105 23.2475C27.2104 23.2475 26.4807 23.9981 26.4807 24.9241V23.5707C26.4807 22.6448 25.751 21.8942 24.8508 21.8942C23.9507 21.8942 23.221 22.6448 23.221 23.5707V16.8337C23.221 15.9077 22.4913 15.1571 21.5912 15.1571C20.6911 15.1571 19.9614 15.9077 19.9614 16.8337L20.0336 28.1289C20.0345 28.2592 19.8786 28.322 19.7934 28.2259L18.2445 26.4816C17.6511 25.813 16.657 25.7278 15.9642 26.2861L15.9261 26.3169C15.2012 26.9006 15.0686 27.9748 15.6245 28.7298C16.9042 30.4679 18.1868 31.9592 19.0866 33.3054C19.4976 33.9199 19.7568 34.7538 20.1152 35.4022C20.6575 36.3834 22.4433 38.9999 26.0631 38.9999C30.5184 38.9999 32.1494 35.5756 32.3827 34.1732C32.7906 31.7205 33.0003 29.0617 33.0006 26.2671C33.0006 25.3434 32.2688 24.5868 31.3708 24.5868L31.3701 24.5865Z"
        fill="white"
      />
    </g>
    <defs>
      <filter
        id="filter0_b_702_27"
        x={-4150}
        y={-4150}
        width={8348}
        height={8348}
        filterUnits="userSpaceOnUse"
        colorInterpolationFilters="sRGB"
      >
        <feFlood floodOpacity={0} result="BackgroundImageFix" />
        <feGaussianBlur in="BackgroundImageFix" stdDeviation={2075} />
        <feComposite
          in2="SourceAlpha"
          operator="in"
          result="effect1_backgroundBlur_702_27"
        />
        <feBlend
          mode="normal"
          in="SourceGraphic"
          in2="effect1_backgroundBlur_702_27"
          result="shape"
        />
      </filter>
      <linearGradient
        id="paint0_linear_702_27"
        x1={0}
        y1={0}
        x2={47.0669}
        y2={2.39871}
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#6F6DE6" />
        <stop offset={0.489583} stopColor="#5451D4" />
        <stop offset={1} stopColor="#312DB9" />
      </linearGradient>
      <linearGradient
        id="paint1_linear_702_27"
        x1={24}
        y1={0}
        x2={24}
        y2={48}
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#B21318" />
        <stop offset={1} stopColor="#7D0204" />
      </linearGradient>
      <clipPath id="clip0_702_27">
        <rect
          width={18}
          height={29}
          fill="white"
          transform="translate(15 10)"
        />
      </clipPath>
    </defs>
  </svg>
  )
}

export default BetdailyAppStepFiveIcon