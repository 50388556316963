import React, { useEffect } from 'react'
import { useNavigate } from 'react-router-dom';
import config from "src/config/config";
import { withAppContext } from 'src/store/initAppContext'
import styled, { withTheme } from 'styled-components'
import { theme } from 'styled-tools';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import PlaceholderImage from 'src/components/Placeholder';
import Footer from 'src/components/footer/footer';

const WorldCupDoubleCashBackBazzi = (props) => {
    const {
        app: { brandId },
        theme,
    } = props;

    const navigate = useNavigate()
    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    const domain = brandId == 31 || brandId == 32 ? "Yolo247" : "IW247";

    return (
        <>
            <MainWrapper>
                <ContentWrapper>
                    {/* <TeamsSection>
                        <LazyLoadImage src={`${config.imageV3Host}/static/cashsite/${theme.image.WorldCupDoubleCashBackTeamsImage}`} effect="none" placeholder={<PlaceholderImage />} />
                    </TeamsSection> */}
                    <HeadingText>
                        <h1>10% Weekly Double Cashback on IW247 | Daily Deposit Bonus</h1>
                    </HeadingText>
                    <TitleText>
                        <p>Consistency is the key to success for any player, and that is what IW247 believes in. Our daily depositors get an incredible weekly cashback of up to 10%. </p>
                    </TitleText>
                    <TitleText>
                        <p>Raise your game, deposit daily, play daily, and win daily on IW247, your favorite online sports betting and casino games platform.</p>
                    </TitleText>
                    <CashBackWrapper>
                        <LeftImgContent>
                            <LazyLoadImage src={`${config.imageV3Host}/static/cashsite/${theme.image.WorldCupDoubleCashBackMobileImage}`} effect="none" placeholder={<PlaceholderImage />} />
                        </LeftImgContent>
                        <BorderLine></BorderLine>
                        <RightTextContent>
                            <BonusTitle>
                                <h1>Deposit Daily for 10% Weekly Double Cashback</h1>
                            </BonusTitle>
                            <BonusBody>
                                <BonusContent>
                                    <ul>
                                        <li>Remain consistent and show that you are one of the top players by depositing daily.</li>
                                        <li>Receive the additional cashback by maintaining the deposit streak for all seven days of the week.</li>
                                        <li>Once you deposit daily for the entire week, you will be eligible for the additional cashback. Stay on top of your game!</li>
                                    </ul>
                                </BonusContent>
                            </BonusBody>
                        </RightTextContent>
                    </CashBackWrapper>
                    <WeeklyBonusWrapper>
                        <WeeklyBonusContent>
                            <h1>Losing isn’t our Thing!</h1>
                            <p>IW247 is a platform where there is no such thing as losing! The daily deposit bonus will give you up to 10% cashback on your weekly losses. <br /> <br /> Show your Skills! Be a Champion!</p>
                        </WeeklyBonusContent>
                        <WeeklyBonusContent>
                            <h1>Monday Blues Put to Rest !</h1>
                            <p>With an additional bonus credited each Monday, your IW247 account and you will be happier than ever on a Monday morning!</p>
                        </WeeklyBonusContent>
                    </WeeklyBonusWrapper>
                    <Divider></Divider>
                    <DailyBonusWrapper>
                        <DailyBonusTitle>
                            <h1>Tier-Wise Daily Deposit Bonus</h1>
                        </DailyBonusTitle>
                        <LazyLoadImage src={`${config.imageV3Host}/static/cashsite/${theme.image.WorldCupDoubleCashBackWeeklyBonusTable}`} effect="none" placeholder={<PlaceholderImage />} />
                    </DailyBonusWrapper>
                    <Divider></Divider>
                    <TermsAndCondition>
                    <h4>Terms & Conditions</h4>
                        <ul>
                            <li>This promotion is valid for all new and existing <span className='boldWhite'>{domain}</span> users</li>
                            <li>The bonus amount will be capped at INR <span className='boldWhite'>7000</span>.</li>
                            <li>The bonus amount will be credited to your main wallet as a cashback bonus every Monday, provided that you have deposited all 7 days a week (Mon-Sun).</li>
                            <li>Multiple accounts will not be considered for this promotion. All players will be subject to duplicate checking. <span className='boldWhite'>{domain}</span> reserves the right to disqualify players if any collusion or foul play occurs in the promotion.</li>
                            <li>This promotion cannot be used in conjunction with or contribute to the eligibility for any other promotion.</li>
                            <li><span className='boldWhite'>{domain}</span> reserves the right to amend, cancel, and reclaim or refuse any promotion at its discretion.</li>
                            <li>If any fraudulent activity of the player(s) is identified, <span className='boldWhite'>{domain}</span> reserves the right to withhold the funds and reject the withdrawals.</li>
                            <li><span className='boldWhite'>{domain}</span> General Terms & Conditions apply.</li>
                        </ul>
                    </TermsAndCondition>
                </ContentWrapper>
            </MainWrapper>
            <Footer />
        </>
    )
}

export default withTheme(withAppContext(WorldCupDoubleCashBackBazzi));

//Styled Components

const MainWrapper = styled.div`
    width: 98%;
    height: auto;
    border: 1px solid white;
    border-top: 0;
    display: flex;
    border-radius: 1px;
    background: rgba(111, 111, 111, 0.5);
    margin: 0em 1.2em;
    -webkit-backdrop-filter: brightness(0.5);
    backdrop-filter: brightness(0.5);
`;

const ContentWrapper = styled.div`
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background: url(${config.imageV3Host}/static/cashsite/${theme("image.WorldCupDoubleCashBackBackground")});
    background-position: top;
    background-size: cover;
    background-repeat: no-repeat;
    padding: 2rem;

    .lazy-load-image-background {
      display: flex !important;
      justify-content: center !important;
    }

    .secondTitle {
        padding-top: 2rem;
    }
`;

const TeamsSection = styled.div`
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: row;
    padding: 0rem 15rem;
    margin: 3.5rem 0;
    gap: 20px;
    position: relative;

    img{
        width: 100%;
    }
`;

const HeadingText = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;

    h1 {
        margin-top: 0;
        text-align: center;
        font-size: 24px;
        font-style: normal;
        font-weight: 700;
        line-height: normal;
        text-transform: capitalize;
        color: #F91212;
    }
`

const TitleText = styled.div `
    display: flex;
    justify-content: center;
    align-items: center;
    width: 73%;

    p {
        color: #FFF;
        text-align: center;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
        text-transform: capitalize;
    }
    
    .boldRed {
        color: #F91212;
        font-size: 14px;
        font-style: normal;
        font-weight: 700;
        line-height: normal;
        text-transform: capitalize;
    }
`;

const CashBackWrapper = styled.div`
    display: flex;
    padding: 2rem 8rem;
    width: 100%;
    justify-content: stretch;
    align-items: center;
`;

const LeftImgContent = styled.div`
    display: flex;
    width: 40%;
    text-align: right;
    justify-content: center;
    align-items: flex-end;

    img {
        width: 55%;
        align-self: flex-end;
    }
`;

const BorderLine = styled.div`
    width: 2px;
    background: linear-gradient(transparent, #F91212, transparent);
    height: 70%;
    align-self: center;
`;

const RightTextContent = styled.div`
    display: flex;
    flex-direction: column;
    width: 60%;
    background: radial-gradient(circle at left, rgba(249, 18, 18, 0.12) 0%, rgba(2, 13, 2, 0.00) 20%);
    padding: 1rem 1rem 1rem 4rem;
`;

const BonusTitle = styled.div`
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    width: 80%;

    h1 {
        font-size: 24px;
        font-style: normal;
        font-weight: 700;
        line-height: normal;
        text-transform: capitalize;
        color: #F91212;
    }
`;

const BonusBody = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
`;

const BonusContent = styled(BonusBody)`
    width: 90%;
    h1 {
        font-size: 14px;
        font-style: normal;
        font-weight: 600;
        line-height: normal;
        text-transform: capitalize;
        margin-bottom: 0;
        color: #F91212;
    }
    p {
        color: #FFF;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
        text-transform: capitalize; 
        padding-left: 1rem;
    }

    & ul {
        padding-left: 2%;
        margin: 5px 0px;
        display: flex;
        flex-direction: column;
        gap: 10px;

        & li {
            color: #FFF;
            font-size: 14px;
            font-style: normal;
            font-weight: 400;
            line-height: normal;
            text-transform: capitalize; 
            padding-left: 1rem;
        }
    }
`;

const WeeklyBonusWrapper = styled.div`
    display: flex;
    justify-content: center;
    align-items: stretch;
    gap: 3rem;
    width: 80%;
    padding: 3rem;
`;

const WeeklyBonusContent = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    border: 1px solid #597AA1;
    border-radius: 8px;
    width: 50%;
    padding: 1rem 2rem;
    background: url(${config.imageV3Host}/static/cashsite/${theme("image.WorldCupDoubleCashBackWeeklyBackground")});
    background-position: top;
    background-size: cover;
    background-repeat: no-repeat;

    h1 {
        text-align: center;
        font-size: 16px;
        font-style: normal;
        font-weight: 600;
        line-height: normal;
        text-transform: capitalize;
        color: #F91212;
    }

    p {
        color: #FFF;
        text-align: justify;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
        text-transform: capitalize;
    }
`;

const DailyBonusWrapper = styled.div`
    display: flex;
    flex-direction: column;
    padding: 2rem 2rem 3rem 2rem;
    background: radial-gradient(53.7% 45.18% at 50% 0%, rgba(249, 18, 18, 0.12) 0%, rgba(2, 13, 2, 0.00) 100%);

    img {
        width: 70%;
    }
`;

const Divider = styled.div`
    height: 2px;
    width: 100%;
    background: radial-gradient(#F91212 0%, #00000000 50%);
`;


const DailyBonusTitle = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    padding-bottom: 1rem;
    position: relative;

    ::before {
        content: "";
        background: url(${config.imageV3Host}/static/cashsite/${theme("image.WcDoublecashBackFireBall")});
        background-position: top;
        background-size: cover;
        background-repeat: no-repeat;
        position: absolute;
        right: 0%;
        bottom: 10%;
        width: 15rem;
        height: 9rem;
    }
    
    h1 {
        text-align: center;
        font-size: 28px;
        font-style: normal;
        font-weight: 700;
        line-height: normal;
        text-transform: capitalize;
        margin-top: 0;
        padding-bottom: 1rem;
        color: #F91212;
    }
`

const TermsAndCondition = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 2rem;
    background: radial-gradient(53.7% 45.18% at 50% 0%, rgba(249, 18, 18, 0.12) 0%, rgba(2, 13, 2, 0.00) 100%);
    width: 65%;

    & li {
        color: #FFF;
        text-align: justify;
        font-size: 18px;
        font-style: normal;
        font-weight: 400;
        line-height: 21px; /* 116.667% */
        text-transform: capitalize;
        list-style-type: decimal;
        padding-bottom: 1.5rem;

        .boldWhite {
            color: #FFF;
            font-size: 18px;
            font-style: normal;
            font-weight: 700;
            line-height: 21px;
            text-transform: capitalize;
        }
    }

    & h4 {
        color: #F91212;
        text-align: center;
        font-size: 28px;
        font-style: normal;
        font-weight: 700;
        line-height: normal;
        text-transform: capitalize;
    }
`;
