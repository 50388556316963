import React from 'react';

interface IDocumentActiveProps {
	children: (isPageVisible: boolean) => React.ReactNode;
}

interface IDocumentActiveState {
	isPageVisible: boolean;
}

export default class DocumentActive extends React.Component<IDocumentActiveProps, IDocumentActiveState> {
	state = {
		isPageVisible: window.document ? !document.hidden : false,
	};

	handleDocumentFocus = (e: any) => {
		if (document.hidden) {
			this.setState({ isPageVisible: false });
		} else {
			this.setState({ isPageVisible: true });
		}
	};

	componentDidMount() {
		document.addEventListener('visibilitychange', this.handleDocumentFocus);
	}

	componentWillUnmount() {
		document.removeEventListener('visibilitychange', this.handleDocumentFocus);
	}

	render() {
		if (typeof this.props.children !== 'function') {
			console.warn('DocumentActive must provide function as children.');
			return null;
		}
		return this.props.children(this.state.isPageVisible);
	}
}
