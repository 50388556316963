import React from 'react';

// Dependent Components
import { Income } from '../../../common/NumberDisplay';
import Icon from '../../../common/PNGIcon';
import { ESlipState } from './OrderSlipContainer';
import UnmatchedSlipContainer, {
	IUnmatchedSlipProps,
} from './UnmatchedSlipContainer';
import Intl from '../../../common/Intl';
import {
	FieldGroupWrapper,
	FieldLabel,
	FieldWrapper,
	InputField,
	InputSection,
	MessageWrapper,
	ProfitText,
	SlipEventName,
	SlipHeadingWrapper,
	SlipSelectionName,
	SlipTitleWrapper,
	SlipWrapper,
} from './slipStyles';

// Utils
import { calculateProfitLoss } from '../../../../util/formulae';

// will be placed inside of MatchListContextConsumer & OrderListContext, rendered with pending data
// Inside of OrderSlipList component

const DesktopPendingSlip: React.SFC<IUnmatchedSlipProps> = props => {
	return (
		<UnmatchedSlipContainer {...props}>
			{unmatchedSlipStore => {
				const {
					side,
					slipRef,
					message,
					eventName,
					selectionName,
					currentPrice,
					currentStake,
				} = unmatchedSlipStore;
				return (
					<SlipWrapper type={side} ref={slipRef}>
						{message && (
							<MessageWrapper type={ESlipState.Error}>
								<Icon name="circle-x-color" />
								{message}
							</MessageWrapper>
						)}
						<SlipHeadingWrapper type={side}>
							<SlipTitleWrapper>
								<SlipEventName>{eventName}</SlipEventName>
								<SlipSelectionName>{selectionName}</SlipSelectionName>
							</SlipTitleWrapper>
						</SlipHeadingWrapper>
						<InputSection type={side}>
							<FieldGroupWrapper>
								<FieldWrapper>
									<FieldLabel>
										{/* <Intl langKey="GLOBAL@ODDS" /> */}
										Odds
									</FieldLabel>
									<InputField value={currentPrice} type="number" pattern="\d*" disabled="disabled" />
									{/* <NumberInput initialNumber={currentPrice} /> */}
								</FieldWrapper>
								<FieldWrapper>
									<FieldLabel>
										{/* <Intl langKey="GLOBAL@STAKE" /> */}
										Stake
									</FieldLabel>
									<InputField value={currentStake} type="number" pattern="\d*" disabled="disabled" />
								</FieldWrapper>
								<FieldWrapper>
									<FieldLabel>
										<Intl
											langKey={
												side === 'BACK'
													// ? 'GLOBAL@PROFIT'
													? 'Profit'
													: side === 'LAY'
													// ? 'GLOBAL@LOSS'
													? 'Loss'
													// : 'GLOBAL@PLACEHOLDER'
													: '-'
											}
										/>
									</FieldLabel>
									<ProfitText type={side}>
										<Income>{calculateProfitLoss(currentPrice, Number(currentStake), side)}</Income>
									</ProfitText>
								</FieldWrapper>
							</FieldGroupWrapper>
						</InputSection>
					</SlipWrapper>
				);
			}}
		</UnmatchedSlipContainer>
	);
};
export default DesktopPendingSlip;
